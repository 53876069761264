// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ResourcesTabController, {
  Props
} from "./ResourcesTabController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import CustomTable, { tableColumnTypes } from "../../../../components/src/CustomTable.web";

// Customizable Area End
const resourcesTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.TEXT

  },
  {
    id: "Subject",
    columnId: 2,
    label: "Subject",
    type: tableColumnTypes.TEXT
  },
  {
    id: "Grade",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT
  },
  {
    id: "LessonsPerDay",
    columnId: 4,
    label: "No. Lessons Per Day",
    type: tableColumnTypes.TEXT
  },
  {
    id: "Status",
    columnId: 5,
    label: "Status",
    type: tableColumnTypes.STATUS_CHIP
  },
]
const resourcesData = [
  {
    id: 1,
    Title: "Resource Title 1",
    Subject: "Science",
    Grade: "Grade 3",
    LessonsPerDay: "10",
    Status: "Scheduled",
  },
  {
    id: 2,
    Title: "Resource Title 2",
    Subject: "Science",
    Grade: "Grade 3",
    LessonsPerDay: "10",
    Status: "Late",
  },
  {
    id: 3,
    Title: "Resource Title 3",
    Subject: "Science",
    Grade: "Grade 3",
    LessonsPerDay: "10",
    Status: "Completed",
  },
  {
    id: 4,
    Title: "Resource Title 4",
    Subject: "Science",
    Grade: "Grade 3",
    LessonsPerDay: "10",
    Status: "Scheduled",
  },
  {
    id: 5,
    Title: "Resource Title 5",
    Subject: "Science",
    Grade: "Grade 3",
    LessonsPerDay: "10",
    Status: "Completed",
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginBottom: "10px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    resourcesTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }
    }
  });

// Customizable Area End

// Customizable Area Start
export class ResourcesTab extends ResourcesTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedNameValue,
      selectedSubjectValue,
      selectedGradeValue,
      selectedNoOfLessonsPerDayValue,
      selectedStatusValue
    } = this.state;

    return (
      <Box style={{ padding: "10px 25px 25px 25px" }}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  Title
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldId="titleDropdown"
                  fieldName="selectedNameValue"
                  fieldValue={selectedNameValue}
                  handleChange={this.handleChangeDropdownValue}
                  fieldError={false}
                  renderValue={this.renderTitleDropdownValue}
                  fieldMenuItems={this.getDropdownItemsArray("Title")}
                  fieldStyles={dropdownStyle}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Subject
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="subjectDropdown"
                  fieldName="selectedSubjectValue"
                  fieldValue={selectedSubjectValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderSubjectDropdownValue}
                  fieldError={false}
                  fieldMenuItems={this.getDropdownItemsArray("Subject")}
                  fieldStyles={dropdownStyle}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Grade
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="gradeDropdown"
                  fieldName="selectedGradeValue"
                  fieldValue={selectedGradeValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderGradeDropdownValue}
                  fieldError={false}
                  fieldMenuItems={this.getDropdownItemsArray("Grade")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  No. of lessons per day
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="noOfLessonsPerDayDropdown"
                  fieldName="selectedNoOfLessonsPerDayValue"
                  fieldValue={selectedNoOfLessonsPerDayValue}
                  renderValue={this.renderNoOfLessonsPerDayValue}
                  handleChange={this.handleChangeDropdownValue}
                  fieldMenuItems={this.getDropdownItemsArray("0")}
                  fieldError={false}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Status
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="statusDropdown"
                  fieldName="selectedStatusValue"
                  handleChange={this.handleChangeDropdownValue}
                  fieldValue={selectedStatusValue}
                  fieldError={false}
                  renderValue={this.renderStatusDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                  fieldMenuItems={this.getDropdownItemsArray("Status")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleResourceSearchButton} isDisabled={false} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.dividerLine} />
        <Grid container>
          <Grid item xs={12}>
            <Box className={`${classes.customTableStyles} ${classes.resourcesTableRow}`} >
              <CustomTable
                tableId={"coursesTable"}
                tableHeaderColumns={resourcesTableColumns}
                tableBodyData={resourcesData}
                showMenuIcon={true}
                showResourceMenu={true}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px"
} as const;

const dropdownStyle = {
  width: "100%"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ResourcesTab);
// Customizable Area End
