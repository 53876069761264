// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import CustomTextField from "../../../components/src/CustomTextField.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import NewCurriculumDialogController, {
  Props,
} from "./NewCurriculumDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomMultiSelectDropDown from "../../../components/src/CustomMultiSelectDropDown.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    closeDialogIcon: {
      cursor: "pointer",
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(4.4),
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(4),
        width: theme.spacing(1.5),
        top: theme.spacing(5),
      },
    },
    termTextFieldRoot: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    termTextField: {
      width: theme.spacing(32.5),
    },
    inputLabel: {
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    disableDateFields: {
      width: "260px",
    },
    paper: {
      height: "auto",
      width: "880px",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "440px",
      },
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    formFields: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    customLoader: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
      zIndex: 100,
      display: "block",
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewCurriculumDialog extends NewCurriculumDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, handleClose, gradesList, subjectsList, isEdit, loading } =
      this.props;
    const {
      curriculumTitle,
      selectCurriculumError,
      selectCurriculumErrorMsg,
      selectSubject,
      selectSubjectError,
      selectSubjectErrorMsg,
      selectGrade,
      selectGradeError,
      selectGradeErrorMsg,
      customFieldConfig,
    } = this.state;
    return (
      <Box>
        <Dialog
          open={open}
          scroll={"body"}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          className={classes.dialogRoot}
        >
          <Box>
            {loading && (
              <Box className={classes.customLoader}>
                <Box className={classes.circularContainer}>
                  <CircularProgress className={`${classes.textButton}`} />
                </Box>
              </Box>
            )}
            <DialogTitle
              id="new-curriculum-dialog-title"
              className={`${classes.bgColor}`}
            >
              <Typography
                gutterBottom
                component={"span"}
                className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
              >
                {isEdit ? "Edit Curriculum" : "Add New Curriculum"}
              </Typography>
              <CloseIcon
                id="An icon to close Dialog"
                className={`${classes.closeDialogIcon} ${classes.textPrimaryColor}`}
                onClick={handleClose}
              />
            </DialogTitle>
            <DialogContent className={`${classes.bgColor}`}>
              <Box>
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Box>
                      <Typography
                        component={"span"}
                        className={classes.inputLabel}
                      >
                        Curriculum Title
                      </Typography>
                      <CustomTextField
                        placeHolderText="Enter Curriculum Title"
                        fieldName="curriculumTitle"
                        fieldId="curriculum-title-field"
                        textFieldStyles={{
                          padding: "10px 0",
                          width: "400px",
                          maxWidth: "100%",
                        }}
                        fieldValue={curriculumTitle}
                        fieldWidth={"100%"}
                        fieldType="text"
                        handleChange={this.handleChange}
                        fieldError={selectCurriculumError}
                        helperText={selectCurriculumErrorMsg}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Box>
                      <Typography
                        className={classes.inputLabel}
                        component={"span"}
                      >
                        Grade{" "}
                      </Typography>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-grade"
                        fieldValue={selectGrade}
                        fieldName="selectGrade"
                        handleChange={this.handleChangeDropdownValue}
                        renderValue={this.renderGradeValue}
                        fieldError={selectGradeError}
                        fieldMenuItems={gradesList}
                        fieldStyles={{ maxWidth: "100%", width: "400px" }}
                        helperText={selectGradeErrorMsg}
                        placeHolderText="Select Grade"
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Box>
                      <Typography
                        component={"span"}
                        className={classes.inputLabel}
                      >
                        Subject
                      </Typography>
                      <CustomMultiSelectDropDown
                        label="Select Subjects"
                        fieldMenuItems={subjectsList}
                        fieldStyles={{
                          width: "100%",
                        }}
                        selectedItems={selectSubject}
                        handleChange={this.handleChangeSubject}
                        fieldError={selectSubjectError}
                        helperText={selectSubjectErrorMsg}
                        isDisabled={isEdit || !selectGrade}
                      />
                    </Box>
                  </Grid>
                  {/* Custom Fields */}
                  {customFieldConfig.map((configItem, index) => (
                    <Grid
                      style={{ padding: "10px" }}
                      key={`${configItem?.label}-${configItem?.id}`}
                      item
                      lg={6}
                      md={6}
                      sm={12}
                    >
                      <Box>
                        <Typography
                          className={classes.inputLabel}
                          component={"span"}
                        >
                          {configItem.label}
                        </Typography>
                        <CustomTextField
                          fieldName={configItem.name}
                          fieldWidth={"100%"}
                          fieldId={`add-curriculum-custom-field-${configItem.id}`}
                          fieldValue={configItem.value}
                          isDisabled={configItem?.isDisabled}
                          textFieldStyles={{
                            width: "100%",
                            padding: "8px 0px",
                          }}
                          placeHolderText={`${configItem.placeHolderText}`}
                          fieldType="text"
                          handleChange={(e: any) =>
                            this.handleCustomFieldTextChange(e)
                          }
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                style={{
                  margin: "30px 0",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CustomButton
                  btnStyle={cancelCurriculumBtn}
                  btnText="Cancel"
                  buttonId="curriculum-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleClose()}
                />
                <CustomButton
                  btnStyle={addCurriculumBtn}
                  btnText={isEdit ? "Save" : "Add"}
                  buttonId="curriculum-add-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleAddCurriculum()}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addCurriculumBtn = {
  borderRadius: "12px",
  padding: "15px 36px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const cancelCurriculumBtn = {
  borderRadius: "12px",
  marginRight: "15px",
  padding: "15px 36px",
  fontWeight: 600,
  fontSize: "16px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewCurriculumDialog);
// Customizable Area End
