// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ArticleTaskIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill} fillRule="evenodd">
                <g transform="translate(-448.000000, -730.000000)" fill={props.fill} fillRule="nonzero" strokeWidth="0.1">
                    <g transform="translate(340.000000, 160.000000)">
                        <g transform="translate(0.000000, 102.000000)">
                            <g transform="translate(0.000000, 106.000000)">
                                <g transform="translate(82.005303, 68.000000)">
                                    <g transform="translate(0.000000, 81.000000)">
                                        <g transform="translate(0.000000, 191.000000)">
                                            <g transform="translate(1.994697, 0.000000)">
                                                <g transform="translate(25.014625, 23.003605)">
                                                    <path d="M18.2626641,2.13001953 L17.3409336,2.13001953 L17.3409336,1.20808594 C17.3409336,0.541988281 16.7987422,0 16.1324414,0 L1.20808594,0 C0.541785156,0 0,0.541988281 0,1.20808594 L0,22.6544805 C0,23.3207812 0.541785156,23.8627695 1.20808594,23.8627695 L2.12981641,23.8627695 L2.12981641,24.7847031 C2.12981641,25.4508008 2.67200781,25.9927891 3.33830859,25.9927891 L18.2626641,25.9927891 C18.9289648,25.9927891 19.47075,25.4508008 19.47075,24.7847031 L19.47075,3.33830859 C19.47075,2.67200781 18.9290156,2.13001953 18.2626641,2.13001953 Z M0.812246094,22.6544805 L0.812246094,1.20808594 C0.812246094,0.989726562 0.989929687,0.812246094 1.20808594,0.812246094 L16.1324414,0.812246094 C16.3509531,0.812246094 16.5286875,0.989726562 16.5286875,1.20808594 L16.5286875,22.6544805 C16.5286875,22.8728398 16.3510039,23.0505234 16.1324414,23.0505234 L1.20808594,23.0505234 C0.989929687,23.0505234 0.812246094,22.8727891 0.812246094,22.6544805 Z M18.6585039,24.7847031 C18.6585039,25.0030625 18.4808203,25.180543 18.2626641,25.180543 L3.33830859,25.180543 C3.11974609,25.180543 2.9420625,25.0030625 2.9420625,24.7847031 L2.9420625,23.8627695 L16.1324414,23.8627695 C16.7987422,23.8627695 17.3409336,23.3207813 17.3409336,22.6544805 L17.3409336,2.94231641 L18.2626641,2.94231641 C18.4808203,2.94231641 18.6585039,3.12 18.6585039,3.33835938 L18.6585039,24.7847031 Z" id="Shape"></path>
                                                    <path d="M14.5293281,2.25438281 L2.81277344,2.25438281 C2.36777734,2.25438281 2.00565625,2.61650391 2.00565625,3.0615 L2.00565625,5.26571094 C2.00565625,5.71070703 2.36777734,6.07282812 2.81277344,6.07282812 L14.5292773,6.07282812 C14.9742734,6.07282812 15.3363945,5.71070703 15.3363945,5.26571094 L15.3363945,3.0615 C15.3363945,2.61650391 14.9743242,2.25438281 14.5293281,2.25438281 Z M14.5241484,5.26053125 L2.81795312,5.26571094 L2.81277344,3.06667969 L14.5241484,3.0615 L14.5241484,5.26053125 Z" id="Shape"></path>
                                                    <path d="M14.9302969,7.75703906 L14.2005195,7.75703906 C13.9760156,7.75703906 13.7943711,7.93888672 13.7943711,8.1631875 C13.7943711,8.38748828 13.9760156,8.56933594 14.2005195,8.56933594 L14.9302969,8.56933594 C15.1548008,8.56933594 15.3364453,8.38748828 15.3364453,8.1631875 C15.3364453,7.93888672 15.1548008,7.75703906 14.9302969,7.75703906 Z" id="Path"></path>
                                                    <path d="M2.41180469,8.56933594 L11.8997227,8.56933594 C12.1242266,8.56933594 12.3058711,8.38748828 12.3058711,8.1631875 C12.3058711,7.93888672 12.1242266,7.75703906 11.8997227,7.75703906 L2.41180469,7.75703906 C2.18730078,7.75703906 2.00565625,7.93888672 2.00565625,8.1631875 C2.00565625,8.38748828 2.18730078,8.56933594 2.41180469,8.56933594 Z" id="Path"></path>
                                                    <path d="M2.41180469,11.353418 L3.14158203,11.353418 C3.36608594,11.353418 3.54773047,11.1715703 3.54773047,10.9472695 C3.54773047,10.7229688 3.36608594,10.5411211 3.14158203,10.5411211 L2.41180469,10.5411211 C2.18730078,10.5411211 2.00565625,10.7229688 2.00565625,10.9472695 C2.00565625,11.1715703 2.18730078,11.353418 2.41180469,11.353418 L2.41180469,11.353418 Z" id="Path"></path>
                                                    <path d="M14.9302969,10.5411211 L5.44237891,10.5411211 C5.217875,10.5411211 5.03623047,10.7229688 5.03623047,10.9472695 C5.03623047,11.1715703 5.217875,11.353418 5.44237891,11.353418 L14.9302969,11.353418 C15.1548008,11.353418 15.3364453,11.1715703 15.3364453,10.9472695 C15.3364453,10.7229688 15.1548008,10.5411211 14.9302969,10.5411211 Z" id="Path"></path>
                                                    <path d="M14.9302969,13.3254063 L14.2005195,13.3254063 C13.9760156,13.3254063 13.7943711,13.5072539 13.7943711,13.7315547 C13.7943711,13.9558555 13.9760156,14.1377031 14.2005195,14.1377031 L14.9302969,14.1377031 C15.1548008,14.1377031 15.3364453,13.9558555 15.3364453,13.7315547 C15.3364453,13.5072539 15.1548008,13.3254063 14.9302969,13.3254063 Z" id="Path"></path>
                                                    <path d="M2.41180469,14.1376523 L11.8997227,14.1376523 C12.1242266,14.1376523 12.3058711,13.9558047 12.3058711,13.7315039 C12.3058711,13.5072031 12.1242266,13.3253555 11.8997227,13.3253555 L2.41180469,13.3253555 C2.18730078,13.3253555 2.00565625,13.5072031 2.00565625,13.7315039 C2.00565625,13.9558047 2.18730078,14.1376523 2.41180469,14.1376523 Z" id="Path"></path>
                                                    <path d="M14.9302969,16.1096406 L9.8856875,16.1096406 C9.66118359,16.1096406 9.47953906,16.2914883 9.47953906,16.5157891 C9.47953906,16.7400898 9.66118359,16.9219375 9.8856875,16.9219375 L14.9302969,16.9219375 C15.1548008,16.9219375 15.3364453,16.7400898 15.3364453,16.5157891 C15.3364453,16.2914883 15.1548008,16.1096406 14.9302969,16.1096406 L14.9302969,16.1096406 Z" id="Path"></path>
                                                    <path d="M14.9302969,18.4520781 L9.8856875,18.4520781 C9.66118359,18.4520781 9.47953906,18.6339258 9.47953906,18.8582266 C9.47953906,19.0825273 9.66118359,19.264375 9.8856875,19.264375 L14.9302969,19.264375 C15.1548008,19.264375 15.3364453,19.0825273 15.3364453,18.8582266 C15.3364453,18.6339258 15.1548008,18.4520781 14.9302969,18.4520781 L14.9302969,18.4520781 Z" id="Path"></path>
                                                    <path d="M14.9302969,20.7943125 L9.8856875,20.7943125 C9.66118359,20.7943125 9.47953906,20.9761602 9.47953906,21.2004609 C9.47953906,21.4247617 9.66118359,21.6066094 9.8856875,21.6066094 L14.9302969,21.6066094 C15.1548008,21.6066094 15.3364453,21.4247617 15.3364453,21.2004609 C15.3364453,20.9761602 15.1548008,20.7943125 14.9302969,20.7943125 L14.9302969,20.7943125 Z" id="Path"></path>
                                                    <path d="M6.69728516,16.1080664 L2.81119922,16.1080664 C2.36620312,16.1080664 2.00408203,16.4701875 2.00408203,16.9151836 L2.00408203,20.8010664 C2.00408203,21.2460625 2.36620312,21.6081836 2.81119922,21.6081836 L6.69728516,21.6081836 C7.14228125,21.6081836 7.50440234,21.2460625 7.50440234,20.8010664 L7.50440234,16.9151836 C7.50440234,16.4701875 7.14228125,16.1080664 6.69728516,16.1080664 Z M2.81637891,20.8010664 L2.81119922,16.9203633 L6.69210547,16.9203633 L6.69728516,20.7959375 L2.81637891,20.8010664 Z" id="Shape"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default ArticleTaskIcon;
// Customizable Area End