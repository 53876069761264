// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export interface DropDownValues {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: any;
  handleAddHoliday: any;
  handleUpdateHoliday: any;
  isEdit?: boolean;
  schoolYear: string;
  curriculum: string;
  holidayDetails: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  holidayTitle: any;
  holidayTitleErrorMsg: string;
  holidayTitleError: boolean;
  holidayStartDate: any;
  holidayStartDateError: boolean;
  holidayStartDateErrorMsg: string;
  holidayEndDate: any;
  holidayEndDateError: boolean;
  holidayEndDateErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddAnotherHolidayPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      holidayTitle: "",
      holidayTitleError: false,
      holidayTitleErrorMsg: "",
      holidayStartDate: null,
      holidayStartDateError: false,
      holidayStartDateErrorMsg: "",
      holidayEndDate: null,
      holidayEndDateError: false,
      holidayEndDateErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { isEdit } = this.props;
    if (isEdit) this.handleSetInitialHolidayValue(true);
    // Customizable Area End
  }
  // Customizable Area Start

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        holidayTitleError: false,
        holidayTitleErrorMsg: "",
      };
    });
  };

  handleSetStartDate = (date: any) => {
    const setDateFormat = moment(date).toLocaleString();
    const { holidayEndDate } = this.state;
    this.setState({
      holidayStartDateError: false,
      holidayStartDateErrorMsg: "",
      holidayEndDateError: false,
      holidayEndDateErrorMsg: "",
    });
    if (moment(date).isBefore(moment(new Date()), "day")) {
      this.setState({
        holidayStartDateError: true,
        holidayStartDateErrorMsg: "Start Date cannot be a previous date",
      });
    } else if (holidayEndDate) {
      if (moment(date).isAfter(moment(holidayEndDate))) {
        this.setState({
          holidayStartDateError: true,
          holidayStartDateErrorMsg: "Start Date cannot be after End Date",
        });
      }
    }
    this.setState({ holidayStartDate: setDateFormat });
  };

  handleSetEndDate = (date: any) => {
    const setDateFormat = moment(date).toLocaleString();
    const { holidayStartDate } = this.state;
    this.setState({
      holidayEndDateError: false,
      holidayEndDateErrorMsg: "",
      holidayStartDateError: false,
      holidayStartDateErrorMsg: "",
    });
    if (holidayStartDate) {
      if (moment(date).isBefore(moment(holidayStartDate))) {
        this.setState({
          holidayEndDateError: true,
          holidayEndDateErrorMsg: "End Date cannot be before Start Date",
        });
      }
    }
    this.setState({ holidayEndDate: setDateFormat });
  };

  handleVerifyHolidayDates = () => {
    const { holidayEndDate, holidayStartDate } = this.state;
    let isValid = true;
    if (!holidayStartDate) {
      this.setState({
        holidayStartDateError: true,
        holidayStartDateErrorMsg: "Please select holiday start date!",
      });
      isValid = false;
    }
    if (!holidayEndDate) {
      this.setState({
        holidayEndDateError: true,
        holidayEndDateErrorMsg: "Please select holiday end date!",
      });
      isValid = false;
    }
    if (holidayStartDate && holidayEndDate) {
      if (moment(holidayEndDate).isBefore(moment(holidayStartDate))) {
        this.setState({
          holidayEndDateError: true,
          holidayEndDateErrorMsg: "End Date cannot be before Start Date",
        });
        isValid = false;
      }
      if (moment(holidayStartDate).isBefore(moment(new Date()), "day")) {
        this.setState({
          holidayStartDateError: true,
          holidayStartDateErrorMsg: "Start Date cannot be a previous date",
        });
        isValid = false;
      }
    }
    return isValid;
  };

  handleValidateHolidayTitle = () => {
    const { holidayTitle } = this.state;
    let isValid = true;
    if (!holidayTitle) {
      this.setState({
        holidayTitleError: true,
        holidayTitleErrorMsg: "Please enter a title!",
      });
      isValid = false;
    }
    return isValid;
  };

  handleAddHoliday = () => {
    const { holidayEndDate, holidayStartDate, holidayTitle } = this.state;
    const { isEdit, holidayDetails } = this.props;
    if (this.handleValidateHolidayTitle() && this.handleVerifyHolidayDates()) {
      const data: any = {
        name: holidayTitle,
        start_date: holidayStartDate,
        end_date: holidayEndDate,
        index: holidayDetails?.index,
      };
      if (isEdit) {
        data.id = holidayDetails?.id;
        this.props.handleUpdateHoliday(data);
      } else {
        this.props.handleAddHoliday(data);
      }
    }
  };

  handleSetInitialHolidayValue = (isEdit?: boolean) => {
    const { holidayDetails } = this.props;
    if (isEdit) {
      this.setState({
        holidayTitle: holidayDetails?.holiday,
        holidayTitleError: false,
        holidayTitleErrorMsg: "",
        holidayStartDate: holidayDetails?.startDate,
        holidayStartDateError: false,
        holidayStartDateErrorMsg: "",
        holidayEndDate: holidayDetails?.endDate,
        holidayEndDateError: false,
        holidayEndDateErrorMsg: "",
      });
    } else {
      this.setState(
        {
          loading: false,
          holidayTitle: "",
          holidayTitleError: false,
          holidayTitleErrorMsg: "",
          holidayStartDate: null,
          holidayStartDateError: false,
          holidayStartDateErrorMsg: "",
          holidayEndDate: null,
          holidayEndDateError: false,
          holidayEndDateErrorMsg: "",
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleClosePopup = () => {
    this.handleSetInitialHolidayValue();
  };

  // Customizable Area End
}
// Customizable Area End
