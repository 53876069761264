// Customizable Area Start
import React from "react";
import { Box, Typography, Button, Link, TextField } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import OTPAuthController, { Props } from "./OTPAuthController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import AuthLayout from "../../../components/src/AuthLayout.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { hideEmail } from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    textFieldWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: theme.spacing(40),
    },
    text: {
      fontSize: 16,
      fontWeight: 400,
      color: "#888888",
      textAlign: "left",
      marginBottom: theme.spacing(1.25),
    },
    textFieldRoot: {
      margin: "0 0.5rem",
      "& .MuiInputBase-root": {
        color:"green",
        borderColor: "green",
        "&.Mui-focused": {
          color: "green",
        },
        "& input": {
          "&:valid + fieldset": {
            color: "gray",
            borderColor: "gray",
          },
        },
      },
      "& .MuiFormLabel-root": {
        "&.Mui-focused": {
          color: "green",
        },
        "& input": {
          "&:valid + fieldset": {
            color: "green",
            borderColor: "green",
          },
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "green",
        },
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
    },
    textFieldRootErr: {
      margin: "0 0.5rem",
      "& .MuiInputBase-root": {
        "&.Mui-focused": {
          color: "green",
        },
        "& input": {
          "&:valid + fieldset": {
            color: "green",
          },
        },
      },
      "& .MuiFormLabel-root": {
        "&.Mui-focused": {
          color: "green",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "green",
        },
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
    },
    
    textFieldRootErr1: {
      margin: "0 0.5rem",
      "& .MuiInputBase-root": {
        color: "red",
        "&.Mui-focused": {
          color: "red",
        },
        "& input": {
          "&:valid + fieldset": {
            color: "red",
            borderColor: "red",
          },
        },
      },
      "& .MuiFormLabel-root": {
        "&.Mui-focused": {
          color: "red",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "red",
        },
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
    },
    wrapperBox: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    resenCodeText: {
      fontSize: 16,
      fontWeight: 500,
      color: "#88888866",
    },
    timerText: {
      fontSize: 16,
      fontWeight: 500,
      color: "#009DFF",
    },
    verfifyCodeBtn: {
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      width: "100%",
      height: theme.spacing(7.5),
      borderRadius: "12px",
      fontSize: 20,
      fontWeight: 500,
      textTransform: "capitalize",
      marginTop: theme.spacing(4.5),
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(40),
      },
    },
    resendCodeBtn: {
      color: "#009DFF",
      border: "1px solid #009DFF",
      width: "100%",
      height: theme.spacing(7.5),
      borderRadius: "12px",
      fontSize: 20,
      fontWeight: 500,
      textTransform: "capitalize",
      marginTop: theme.spacing(2.5),
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(40),
      },
    },
    loginLink: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      fontWeight: 500,
      fontSize: 16,
      color: "#0D46BC",
      cursor: "pointer",
    },
  });
// Customizable Area End

// Customizable Area Start
export class OTPAuth extends OTPAuthController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { emailAddress, otp, minutes, seconds , filledInputs , isValid} = this.state;
    const getCssClassname = (index: number) => {
      if (isValid) {
        if (filledInputs[index]) {
          return classes.textFieldRootErr;
        } else {
          return classes.textFieldRoot;
        }
      } else {
        return classes.textFieldRootErr1;
      }
    } 

    return (
      <AuthLayout
        title="Enter Verification Code"
        subTitle={`Enter 4 digit verification code sent on your registered email address ${hideEmail(emailAddress)}`}
      >
        <Box className={classes.wrapperBox}>
          <Box className={classes.textFieldWrapper}>
            {otp?.map((digit, index) => (
              <TextField
                key={index}
                type="number"
                id={`otp-field-${index}`}
                className={getCssClassname(index)}           
                 variant="outlined"
                size="small"
                value={digit}
                name={index == 0 ? "otp_code" : `otp${index + 1}`}
                onPaste={this.handleOTPPaste}
                 onFocus={(e) => this.resetOTPValidation()}
                onKeyDown={(e) => this.handleKeydown(e, index)}
                onChange={(e) => this.handleOTPChange(e, index)}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1);
                }}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    fontSize: "1.5rem",
                  },
                }}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            data-test-id="verify-code-btn"
            className={classes.verfifyCodeBtn}
            onClick={() => this.handleVerifyCode()}
          >
            Verify Code
          </Button>
          {minutes == 0 && seconds == 0 ? (
            <Button
              variant="outlined"
              data-test-id="resend-code-btn"
              className={classes.resendCodeBtn}
              onClick={() => this.handleResendCode()}
            >
              Resend Code
            </Button>
          ) : (
            <Box
              component={"span"}
              style={{ display: "inherit", marginTop: "15px" }}
            >
              <Typography className={classes.resenCodeText}>
                Resend Code in
              </Typography>
              &nbsp;
              <Typography className={classes.timerText}>
                {minutes}:{seconds}
              </Typography>
            </Box>
          )}
          <Typography
            data-test-id="get-back-to-login"
            onClick={() => this.handleGetBackToLogin()}
            className={classes.loginLink}
          >
            Back to Login
          </Typography>
        </Box>
      </AuthLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(OTPAuth);
// Customizable Area End
