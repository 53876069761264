// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  graphData: Array<any>,
  labels: Array<any>,
  chartColors: Array<string>,
  chartPosition?:string,
  lableFontSize?:string,
  graphCenterValue?: string,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  options: any
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomDoughnutChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ]

    this.state = {
      options: {
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              padding: 25,
              color: this.getLegendTextColor(),
              font: {
                weight: 'bold',
                size: 18
              },
            }
          },
          datalabels: {
            color: '#ffffff',
            clamp: true,
            borderWidth: 0,
            padding: 0,
            font: {
              weight: 'bold',
              size: 16
            },
            formatter: function (value: any) {
              if (value < 2) {
                return "";
              } else {
                return Math.round(value) + '%';
              }
            },
          },
        },
        responsive: true,
        cutout: "80%",
        maintainAspectRatio: true,
        animation: {
          animateScale: true,

        },
        elements: {
          arc: {
            borderWidth: 0
          },
          line: {
            fill: false
          },
          point: {
            hoverRadius: 7,
            radius: 5
          }
        }
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.addEventListener("resize", this.removeDataLabelInSmallScreen.bind(this));
    this.removeDataLabelInSmallScreen()
    // Customizable Area End
  }

  // Customizable Area Start
  removeDataLabelInSmallScreen = () => {
    if ((window.innerWidth <= 760)) {
      this.setState({
        options: {
          ...this.state.options,
          plugins: {
            legend: {
              display: true,
              position: "right",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                padding: 25
              }
            },
            datalabels: {
              display: false
            },
          }
        }
      })
    } else if (window.innerWidth > 760 && window.innerWidth < 996) {
      this.setState({
        options: {
          ...this.state.options,
          plugins: {
            ...this.state.options?.plugins,
            datalabels: {
              color: '#ffffff',
              clamp: true,
              borderWidth: 0,
              padding: 0,
              font: {
                weight: 'bold',
                size: 12
              },
              formatter: function (value: any) {
                if (value < 2) {
                  return "";
                } else {
                  return Math.round(value) + '%';
                }
              },
            },
          }
        }
      })
    } else {
      this.setState({
        options: {
          ...this.state.options,
          plugins: {
            ...this.state.options?.plugins,
            datalabels: {
              color: '#ffffff',
              clamp: true,
              borderWidth: 0,
              padding: 0,
              font: {
                weight: 'bold',
                size: 16
              },
              formatter: function (value: any) {
                if (value < 2) {
                  return "";
                } else {
                  return Math.round(value) + '%';
                }
              },
            },
          }
        }
      })
    }
  }

  getLegendTextColor = () => {
    const isDarkTheme =
      localStorage.getItem("dark_mode") || JSON.stringify(false);
    return JSON.parse(isDarkTheme) ? "#ffffff" : "#090909";
  }

  componentWillUnmount(): any {
    window.removeEventListener("resize", this.removeDataLabelInSmallScreen.bind(this));
  }
  // Customizable Area End
}