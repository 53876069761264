import React from "react";

// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  createStyles,
  createTheme,
  Theme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import TenantStatisticsDashboardTabController, {
  Props,
} from "./TenantStatisticsDashboardTabController.web";
import CustomTableWeb from "../../../components/src/CustomTable.web";
import {
  topTenCoursesTableHeaderMock,
  userTypesTableHeaderMock,
} from "../assets/tenantDashboardMock";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainTenantStatisticsDashboardTabBox: {
      padding: "2px 20px",
    },
    userTypesTable: {
      padding: "15px 20px",
      borderRadius: "10px",
    },
    courseDetailsBox: {
      display: "flex",
      width: "82%",
      borderRadius: "10px",
      flexDirection: "column",
      padding: "20px 10px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "96%",
      },
    },
    fileStorageDetailsBox: {
      display: "flex",
      flexDirection: "column",
      width: "82%",
      borderRadius: "10px",
      padding: "20px 10px",
      [theme.breakpoints.down("xs")]: {
        width: "96%",
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: "15px",
      },
    },
    sections: {
      paddingBottom: "25px",
    },
    topTenCoursesTable: {
      padding: "15px 20px",
    },
    topTenCoursesBox: {
      marginTop: "20px",
      borderRadius: "10px",
    },
    marginFromLabel: {
      marginTop: "15px",
    },
    topTenCoursesLabel: {
      padding: "15px 20px",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  });

const tableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "13px !important",
        paddingBottom: "13px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "6px 0px",
      },
    },
  },
});

const topTenCoursesTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});
// Customizable Area End

export class TenantStatisticsDashboardTab extends TenantStatisticsDashboardTabController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      userTypesList,
      coursesDetails,
      fileStorageDetails,
      topTenCoursesList,
    } = this.state;
    return (
      <Box className={`${classes.mainTenantStatisticsDashboardTabBox}`}>
        <Box className={`${classes.sections}`}>
          <Typography
            component={"span"}
            className={`${classes.fontText28} ${classes.fontBold600} ${classes.textPrimaryColor}`}
          >
            Users
          </Typography>
          <ThemeProvider theme={tableTheme}>
            <Box
              className={`${classes.userTypesTable} ${classes.marginFromLabel} ${classes.secondaryBgColor}`}
            >
              <CustomTableWeb
                showMenuIcon={false}
                tableBodyData={userTypesList}
                tableHeaderColumns={userTypesTableHeaderMock}
                tableId="tenant-dashboard-statistics-user-types-table"
              />
            </Box>
          </ThemeProvider>
        </Box>
        <Box className={`${classes.sections}`}>
          <Typography
            component={"span"}
            className={`${classes.fontText28} ${classes.fontBold600} ${classes.textPrimaryColor}`}
          >
            Courses
          </Typography>
          <Grid
            className={`${classes.marginFromLabel}`}
            container
            direction="row"
          >
            {coursesDetails.map((item: any) => (
              <Grid key={`${item.label}-${item.count}`} item xs={12} sm={4} md={3} lg>
                <Box
                  className={`${classes.courseDetailsBox} ${classes.secondaryBgColor}`}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${classes.textCommonColor}`}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${classes.textPrimaryColor}`}
                  >
                    {item.count}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box
            className={`${classes.topTenCoursesBox} ${classes.secondaryBgColor}`}
          >
            <Box className={`${classes.topTenCoursesLabel}`}>
              <Typography
                component={"span"}
                className={`${classes.fontText22} ${classes.fontBold600} ${classes.textPrimaryColor}`}
              >
                Top 10 Courses
              </Typography>
            </Box>
            <Box className={`${classes.topTenCoursesTable}`}>
              <ThemeProvider theme={topTenCoursesTableTheme}>
                <CustomTableWeb
                  showMenuIcon={false}
                  tableBodyData={topTenCoursesList}
                  tableHeaderColumns={topTenCoursesTableHeaderMock}
                  tableId="tenant-dashboard-statistics-top-ten-courses-table"
                />
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
        <Box className={`${classes.sections}`}>
          <Typography
            component={"span"}
            className={`${classes.fontText28} ${classes.fontBold600} ${classes.textPrimaryColor}`}
          >
            File Storage
          </Typography>
          <Grid
            className={`${classes.marginFromLabel}`}
            container
            direction="row"
          >
            {fileStorageDetails.map((fileStorageItem: any) => (
              <Grid key={`${fileStorageItem.label}-${fileStorageItem.count}`} item xs={12} sm={4} md={3} lg>
                <Box
                  className={`${classes.fileStorageDetailsBox} ${classes.secondaryBgColor}`}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${classes.textCommonColor}`}
                  >
                    {fileStorageItem.label}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${classes.textPrimaryColor}`}
                  >
                    {fileStorageItem.count}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantStatisticsDashboardTab);
// Customizable Area End
