// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { articleImg } from "./assets";
import ArticleActivityTaskBox from "./ArticleActivityTaskBox.web";
import QuizActivityComponent from "./QuizActivityComponent.web";
import ExamActivityComponent from "./ExamActivityComponent.web";
import AssignmentActivityComponent from "./AssignmentActivityComponent.web";
import ProjectActivityComponent from "./ProjectActivityComponent.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
const instructionsList = [
  {
    id: 1,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    text: "Cras sit amet tincidunt ex. Aliquam sollicitudin non eros ullamcorper pellentesque.",
  },
  {
    id: 3,
    text: "Vivamus vulputate sapien sed ipsum scelerisque condimentum.",
  },
  {
    id: 4,
    text: "Sed ligula lectus, consequat sed gravida ac, dignissim vitae eros. Morbi vel dictum urna.",
  },
  {
    id: 5,
    text: "Fusce id sem risus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
  {
    id: 6,
    text: "Ut mattis ipsum eu orci gravida lacinia. Sed pellentesque vitae eros rutrum efficitur.",
  },
  {
    id: 7,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 8,
    text: "Cras sit amet tincidunt ex. Aliquam sollicitudin non eros ullamcorper pellentesque.",
  },
  {
    id: 9,
    text: "Vivamus vulputate sapien sed ipsum scelerisque condimentum.",
  },
  {
    id: 10,
    text: "Sed ligula lectus, consequat sed gravida ac, dignissim vitae eros. Morbi vel dictum urna.",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  currentLessonActivityList: any;
  selectedCourseSubject: any;
  selectedCourseId: any;
  selectedTopic: any;
  selectedTopicId: any;
  selectedLesson: any;
  selectedtLessonId: any;
  selectedActivity: any;
  selectedActivityId: any;
  selectedActivityTaskDetail: any;
  selectedActivityTaskDetailId: any;
  searchTopic: "";
  searchLesson: "";
  searchActivity: "";
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyCoursesActivityDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseActivityJourneyDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      currentLessonActivityList: [
        {
          id: 1,
          typeOfActivity: "text",
          activityTitle: "Activity Title",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          activityImage: articleImg,
          activityCompletionProgress: 100,
          expanded: false,
        },
        {
          id: 2,
          typeOfActivity: "project",
          activityTitle: "Intelligence Project",
          about:
            " It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          activityCompletionProgress: 100,
          expanded: false,
          dueDate: "10/24/2023",
          instructions: instructionsList,
          allowedAttempts: 3,
          maxScore: 30,
        },
        {
          id: 3,
          typeOfActivity: "audio",
          activityTitle: "Audio Lec Title",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          audioUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
          activityCompletionProgress: 60,
          mediaProgress: 0,
          expanded: false,
        },
        {
          id: 4,
          typeOfActivity: "task",
          activityTitle: "Task Title",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          activityImage: articleImg,
          activityCompletionProgress: 0,
          expanded: false,
        },
        {
          id: 5,
          typeOfActivity: "video",
          activityTitle: "Video Lec Title",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          videoUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
          activityCompletionProgress: 29,
          expanded: false,
        },
        {
          id: 6,
          typeOfActivity: "assignment",
          activityTitle: "Fiction Assignment",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          videoUrl: "https://samplelib.com/lib/preview/mp3/sample-15s.mp3",
          activityCompletionProgress: 30,
          expanded: false,
          dueDate: "05/24/2023",
          instructions: instructionsList,
          allowedAttempts: 3,
          maxScore: 30,
        },
        {
          id: 7,
          typeOfActivity: "quiz",
          activityTitle: "Graphing Quiz",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          activityCompletionProgress: 0,
          expanded: false,
          dueDate: "05/24/2023",
          instructions: instructionsList,
          allowedAttempts: 3,
          maxScore: 30,
        },
        {
          id: 8,
          typeOfActivity: "exam",
          activityTitle: "Differentiability Exam",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          activityCompletionProgress: 0,
          expanded: false,
          dueDate: "05/24/2023",
          instructions: instructionsList,
          allowedAttempts: 3,
          maxScore: 25,
        },
      ],
      selectedCourseSubject: null,
      selectedCourseId: null,
      selectedLesson: null,
      selectedtLessonId: null,
      selectedTopic: null,
      selectedTopicId: null,
      selectedActivity: null,
      selectedActivityId: null,
      selectedActivityTaskDetail: null,
      selectedActivityTaskDetailId: null,
      searchTopic: "",
      searchLesson: "",
      searchActivity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetDataFromHistory();
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    const {
      currentLessonActivityList,
      selectedActivityTaskDetailId,
      selectedActivityTaskDetail,
    } = this.state;
    const updatedCurrentLessonActivityList = currentLessonActivityList.map(
      (task: any) => {
        if (
          task.id === selectedActivityTaskDetailId &&
          (task.typeOfActivity === "audio" || task.typeOfActivity === "video")
        ) {
          task.mediaProgress = selectedActivityTaskDetail?.mediaProgress;
        }
        return task;
      }
    );
    this.setState({
      currentLessonActivityList: updatedCurrentLessonActivityList,
    });
  }

  handleSetDataFromHistory = () => {
    const historyLocationState = this.props.navigation?.getHistoryState();
    const {
      selectedSubjectName,
      subjectId,
      selectedActivity,
      selectedLesson,
      selectedTopic,
    } = historyLocationState;
    if (historyLocationState) {
      this.setState({
        selectedCourseSubject: selectedSubjectName,
        selectedCourseId: subjectId,
        selectedActivity: selectedActivity,
        selectedActivityId: selectedActivity.id || null,
        selectedLesson: selectedLesson,
        selectedtLessonId: selectedLesson?.id || null,
        selectedTopic: selectedTopic,
        selectedTopicId: selectedTopic.id || null,
      });
      this.handleSetSelectedActivityTask();
    } else {
      this.handleGoBackToMyCourse();
    }
  };

  handleSetSelectedActivityTask = () => {
    const { currentLessonActivityList } = this.state;
    this.setState({
      selectedActivityTaskDetail: currentLessonActivityList?.[0],
      selectedActivityTaskDetailId: currentLessonActivityList?.[0]?.id,
    });
  };

  handleGoBack = () => {
    this.props.navigation?.goBack();
  };

  handleGoBackToMyCourse = () => {
    this.props.navigation?.navigate("StudentMyCourses");
  };

  handleSelectActivityTask = (activityTaskId: any) => {
    const {
      currentLessonActivityList,
      selectedActivityTaskDetailId,
      selectedActivityTaskDetail,
    } = this.state;
    const updatedCurrentLessonActivityList = currentLessonActivityList.map(
      (task: any) => {
        if (
          task.id === selectedActivityTaskDetailId &&
          (task.typeOfActivity === "audio" || task.typeOfActivity === "video")
        ) {
          task.mediaProgress = selectedActivityTaskDetail?.mediaProgress;
        }
        return task;
      }
    );
    const currentSelectedActivityTaskDetail: any =
      currentLessonActivityList.find((task: any) => task.id === activityTaskId);
    this.setState(
      {
        selectedActivityTaskDetail: currentSelectedActivityTaskDetail,
        selectedActivityTaskDetailId: activityTaskId,
        currentLessonActivityList: updatedCurrentLessonActivityList,
      },
      () => {
        this.handleExpandCurrentActivity(activityTaskId);
      }
    );
  };

  handleUpdateAudioProgressTime = (timeUpdate: any) => {
    const { selectedActivityTaskDetail } = this.state;
    const formattedTimeUpdate = Number(timeUpdate.toFixed(2));
    const updatedActivity = {
      ...selectedActivityTaskDetail,
      mediaProgress: formattedTimeUpdate,
    };
    this.setState({ selectedActivityTaskDetail: updatedActivity });
  };

  handleMarkActivityCompleted = () => {
    const { selectedActivityTaskDetail, currentLessonActivityList } =
      this.state;
    const updatedActivityCompletionProgress =
      selectedActivityTaskDetail?.activityCompletionProgress === 100 ? 0 : 100;
    const updatedActivity = {
      ...selectedActivityTaskDetail,
      activityCompletionProgress: updatedActivityCompletionProgress,
    };
    const updatedActivitiesList = currentLessonActivityList.map(
      (activity: any) => {
        if (activity.id === selectedActivityTaskDetail.id) {
          activity.activityCompletionProgress =
            updatedActivityCompletionProgress;
        }
        return activity;
      }
    );
    this.setState({
      selectedActivityTaskDetail: updatedActivity,
      currentLessonActivityList: updatedActivitiesList,
    });
  };

  handleExpandCurrentActivity = (itemId: any) => {
    const { currentLessonActivityList } = this.state;
    const updatedActivityList = currentLessonActivityList?.map((item: any) => {
      if (item.id === itemId) {
        item.expanded = !item?.expanded;
      } else {
        item.expanded = false;
      }
      return item;
    });
    this.setState({ currentLessonActivityList: updatedActivityList });
  };

  handleActivityDetailRender = (selectedActivityTaskDetail: any, smallScreen: boolean) => {
    const { navigation } = this.props;
    let isInDom: boolean = false;
    const { selectedLesson } = this.state;
    if (smallScreen) isInDom = selectedActivityTaskDetail?.expanded;

    switch (selectedActivityTaskDetail?.typeOfActivity) {
      case "quiz":
        return (
          <QuizActivityComponent
            navigation={navigation}
            forTeacher={false}
            detail={{
              about: selectedActivityTaskDetail?.about,
              title: selectedActivityTaskDetail?.activityTitle,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              type: selectedActivityTaskDetail?.typeOfActivity,
              course: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              lessonName: selectedLesson?.lessonTitle,
              instructions: selectedActivityTaskDetail?.instructions,
              maxScore: selectedActivityTaskDetail?.maxScore,
              dueDate: selectedActivityTaskDetail?.dueDate,
            }} />
        );

      case "exam":
        return (
          <ExamActivityComponent
            forTeacher={false}
            navigation={navigation}
            detail={{
              about: selectedActivityTaskDetail?.about,
              title: selectedActivityTaskDetail?.activityTitle,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              objectiveType: selectedActivityTaskDetail?.objectiveType,
              type: selectedActivityTaskDetail?.typeOfActivity,
              lessonName: selectedLesson?.lessonTitle,
              course: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              maxScore: selectedActivityTaskDetail?.maxScore,
              dueDate: selectedActivityTaskDetail?.dueDate,
              instructions: selectedActivityTaskDetail?.instructions,
            }} />
        );

      case "assignment":
        return (
          <AssignmentActivityComponent
            forTeacher={false}
            detail={{
              title: selectedActivityTaskDetail?.activityTitle,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              maxScore: selectedActivityTaskDetail?.maxScore,
              lessonName: selectedLesson?.lessonTitle,
              type: selectedActivityTaskDetail?.typeOfActivity,
              objectiveType: selectedActivityTaskDetail?.objectiveType,
              about: selectedActivityTaskDetail?.about,
              course: selectedActivityTaskDetail?.typeOfActivity,
              instructions: selectedActivityTaskDetail?.instructions,
              dueDate: selectedActivityTaskDetail?.dueDate,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
            }}
            navigation={navigation}
          />
        );

      case "project":
        return (
          <ProjectActivityComponent
            detail={{
              title: selectedActivityTaskDetail?.activityTitle,
              about: selectedActivityTaskDetail?.about,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              course: selectedActivityTaskDetail?.typeOfActivity,
              type: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              lessonName: selectedLesson?.lessonTitle,
              instructions: selectedActivityTaskDetail?.instructions,
              maxScore: selectedActivityTaskDetail?.maxScore,
              dueDate: selectedActivityTaskDetail?.dueDate,
            }}
            forTeacher={false}
            navigation={navigation}
          />
        );

      default:
        return (
          <ArticleActivityTaskBox
            isInDom={isInDom}
            detail={{
              title: selectedActivityTaskDetail?.activityTitle || "",
              about: selectedActivityTaskDetail?.about || "",
              type: selectedActivityTaskDetail?.typeOfActivity,
              activityImage: selectedActivityTaskDetail?.activityImage || "",
              isCompleted: selectedActivityTaskDetail?.activityCompletionProgress === 100,
              mediaProgress: selectedActivityTaskDetail?.mediaProgress || 0
            }}
            handleUpdateMediaProgressTime={this.handleUpdateAudioProgressTime}
            handleMarkActivityComplete={this.handleMarkActivityCompleted}
          />
        );
    }
  }
  // Customizable Area End
}
// Customizable Area End
