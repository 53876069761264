// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AddAnotherHolidayPopupController, {
  Props,
} from "./AddAnotherHolidayPopupController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    closeDialogIcon: {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(2.6),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(1.5),
        right: theme.spacing(4),
        top: theme.spacing(5),
      },
    },
    inputLabel: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    paper: {
      width: "880px",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    holidayTitle: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#090909",
    },
    buttonBox: {
      margin: "30px 0",
      display: "flex",
      justifyContent: "flex-end",
      width: "98%",
    },
  });
// Customizable Area End

// Customizable Area Start
export class AddAnotherHolidayPopup extends AddAnotherHolidayPopupController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, curriculum, schoolYear, isEdit } = this.props;
    const {
      holidayEndDate,
      holidayEndDateError,
      holidayEndDateErrorMsg,
      holidayStartDate,
      holidayStartDateError,
      holidayStartDateErrorMsg,
      holidayTitle,
      holidayTitleError,
      holidayTitleErrorMsg,
    } = this.state;
    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.paper }}
        className={classes.dialogRoot}
      >
        <Box>
          <DialogTitle
            id="add-another-holiday-dialog-title"
            className={`${classes.bgColor}`}
          >
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
            >
              {isEdit ? "Edit Holiday" : "Add another Holiday"}
            </Typography>
            <CloseIcon
              id="add-another-holiday-close-icon"
              className={`${classes.closeDialogIcon} ${classes.textCommonColor}`}
              onClick={this.props.handleClose}
            />
          </DialogTitle>
          <DialogContent className={`${classes.bgColor}`}>
            <Grid container direction="row">
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.inputLabel}>
                    School Year Title
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter School Year"
                    fieldName="schoolYear"
                    fieldId="schoolYear-title-field"
                    textFieldStyles={{
                      width: "96%",
                      padding: "10px 0px",
                    }}
                    fieldValue={schoolYear}
                    fieldWidth={"100%"}
                    fieldType="text"
                    handleChange={this.handleChange}
                    isDisabled
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.inputLabel}>
                    Curriculum
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter Curriculum"
                    fieldName="curriculum"
                    fieldId="curriculum-title-field"
                    textFieldStyles={{
                      width: "96%",
                      padding: "10px 0px",
                    }}
                    fieldValue={curriculum}
                    fieldWidth={"100%"}
                    fieldType="text"
                    handleChange={this.handleChange}
                    isDisabled
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography className={classes.inputLabel}>
                    Holiday Title
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter Holiday Title"
                    fieldName="holidayTitle"
                    fieldId="holidayTitle-title-field"
                    textFieldStyles={{
                      width: "98%",
                      padding: "10px 0px",
                    }}
                    fieldValue={holidayTitle}
                    fieldWidth={"100%"}
                    fieldType="text"
                    handleChange={this.handleChange}
                    fieldError={holidayTitleError}
                    helperText={holidayTitleErrorMsg}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.inputLabel}>
                    Start Date
                  </Typography>
                  <CustomDatepicker
                    disableFutureDate={false}
                    disablePastDate={false}
                    placeHolderText={"Select Date"}
                    format="DD/MM/YYYY"
                    fieldError={holidayStartDateError}
                    fieldErrorMsg={holidayStartDateErrorMsg}
                    fieldStyles={{ padding: "10px 0px", width: "96%" }}
                    isDisabled={false}
                    fieldId={"holiday-start-date"}
                    selectedDate={holidayStartDate}
                    handleChangeDate={(date: any) =>
                      this.handleSetStartDate(date)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.inputLabel}>
                    End Date
                  </Typography>
                  <CustomDatepicker
                    fieldError={holidayEndDateError}
                    fieldErrorMsg={holidayEndDateErrorMsg}
                    disableFutureDate={false}
                    disablePastDate={false}
                    placeHolderText={"Select Date"}
                    format="DD/MM/YYYY"
                    fieldStyles={{ padding: "10px 0px", width: "96%" }}
                    isDisabled={false}
                    fieldId={"holiday-end-date"}
                    selectedDate={holidayEndDate}
                    handleChangeDate={(date: any) =>
                      this.handleSetEndDate(date)
                    }
                  />
                </Box>
              </Grid>
              <Box className={classes.buttonBox}>
                <CustomButton
                  btnStyle={cancelHolidayBtn}
                  btnText="Cancel"
                  buttonId="holiday-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleClosePopup()}
                />
                <CustomButton
                  btnStyle={addHolidayBtn}
                  btnText={"Save"}
                  buttonId="holiday-add-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleAddHoliday()}
                />
              </Box>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addHolidayBtn = {
  width: "120px",
  height: "50px",
  borderRadius: "12px",
  padding: "15px 36px",
  fontWeight: 500,
  fontSize: "16px",
} as const;

const cancelHolidayBtn = {
  width: "120px",
  height: "50px",
  borderRadius: "12px",
  marginRight: "15px",
  padding: "15px 36px",
  fontWeight: 500,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddAnotherHolidayPopup);
// Customizable Area End
