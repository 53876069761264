import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export const courseDetailTabs = [
    {
        id: 1,
        label: "Courses",
        value: "courses"
    },
    {
        id: 2,
        label: "Journey",
        value: "journey"
    },
    {
        id: 3,
        label: "Participants",
        value: "participants"
    }
]

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    selectedTabIndex: number;
    selectedTabName: string;
    currentCourseName: string;
    selectedUserDropdownValue: string;
    userDropdownItems: Array<any>;
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CoursesPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
        ];

        this.state = {
            selectedTabIndex: 0,
            txtSavedValue: "A",
            currentCourseName: "",
            selectedTabName: "Resources",
            selectedUserDropdownValue: '',
            userDropdownItems: [
                {
                    id: 1,
                    label: 'Milton peery',
                    value: 'Milton peery',
                },
                {
                    id: 2,
                    label: 'Prescott MacCaff',
                    value: 'Prescott MacCaff',
                },
                {
                    id: 3,
                    label: 'Jason Bailey',
                    value: 'Jason Bailey',
                }
            ],
        };
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.setState({ currentCourseName: 'Physics' })
    }

    handleRedirectBack = () => {
        this.props.navigation.navigate('CourseManagement', {});
    }

    handleChangeAssessmentCategoryTab = (event: React.ChangeEvent<{}>, tabIndex: number) => {
        this.setState({ selectedTabIndex: tabIndex });
        if (tabIndex === 0) {
            this.setState({ selectedTabName: "Resources" });
        } else if (tabIndex === 1) {
            this.setState({ selectedTabName: "Journey" });
        } else if (tabIndex === 2) {
            this.setState({ selectedTabName: "Participants" });
        }
    };

    handleChangeDropdownValue = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };

    renderUserDropdownValue = (selected: any) => {
        const dropdownName = "User";
        const dropdownItems = this.state.userDropdownItems;
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
        if (!selected || selected.length === 0) {
            return 'Milton peery';
        }
        const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
        return selctedOption?.label;
    };

    openJourneyForCourse = () => {
        this.handleChangeAssessmentCategoryTab({} as React.ChangeEvent<any>, 1);
    }
    // Customizable Area End
}
