import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Checkbox,
    Popover,
    MenuItem
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        mainContainerPadding: {
            padding: "25px 40px 40px 40px"
        },
        pageName: {
            marginTop: "16px",
            marginBottom: "28px"
        },
        attendanceHeading: {
            color: "#888",
            marginBottom: "15px"
        },
        attendanceBox: {
            height: "122px",
            borderRadius: "12px",
            border: "1px solid #888",
            padding: "0px 25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
        },
        attendanceItem: {
            height: "100%",
        },
        attendanceItemText: {
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "15px",
            fontWeight: 400,
        },
        centerBorder: {
            width: "100%",
            height: "50%",
            borderBottom: "1px solid #888",
            opacity: "0.5",
            position: "absolute",
            top: "0px",
            left: "0px",
        },
        attendanceLabel: {
            color: "#888",
        },
        dividerLine: {
            height: "1px",
            background: "#F1F1F1",
            marginTop: "25px",
            marginBottom: "25px",
            marginLeft: "-40px",
            marginRight: "-40px",
        },
        customTextEditorStyle: {
            "& .CustomTextEditor-customTextEditor-2535": {
                padding: "0px !important",
                overflow: "auto",
            },
            "& .cke_inner, & .cke_contents": {
                borderRadius: "10px",
            },
        },
        behaviourHeader: {
            height: "61px",
            borderRadius: "12px 12px 0px 0px",
            background: "#DBDBDB",
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
        behaviourHeaderItem: {
            textAlign: "center",
        },
        behaviourDataItem: {
            height: "61px",
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #F7F7F7",
        },
        behaviourDataBlock: {
            height: "100%",
            textAlign: "center",
            border: "1px solid #E4E4E4",
            borderRight: 'none',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none"
        },
        subItemBlockTitle: {
            height: "100%",
            paddingLeft: "25px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            minWidth: "300px",
            width: "100%",
            background: "#fff",
        },
        behaviourItemTitle: {
            paddingLeft: "25px",
            minWidth: "300px",
            width: "100%",
        },
        behaviourMainGrid: {
            marginBottom: "25px",
            borderRadius: "12px",
            border: "2px solid #DDDDDD",
            overflow: "hidden",
            overflowX: "auto",
            background: "#DBDBDB",
            "&::-webkit-scrollbar": {
                background: "#fff",
            }
        },
        evaluationKeyBox: {
            borderRadius: "12px",
            border: "1px solid #888",
            overflow: "hidden",
        },
        evaluationKeyRow: {
            height: "55px",
            display: "flex",
            flexFlow: "row",
            justifyContent: "start",
            alignItems: "center",
            borderBottom: "1px solid #888",
        },
        commentText: {
            color: "#888",
            fontWeight: 400,
            padding: "15px",
            border: "1px solid #888",
            borderRadius: "12px"
        },
        subskillGradeBtn: {
            background: "transparent",
            border: "none",
            flexGrow: 1,
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: 600,
            width: "-webkit-fill-available",
            padding: 0,
            "& p" : {
                width: "calc(100% - 12px)",
                padding: "0 5px",
                textAlign: "center"
            }
        },
        mainSkillTable: {
            minWidth: "fit-content",
        },
        mainSkillHeader: {
            display: "flex",
        },
        mainSkillTitle: {
            background: "#F1F1F1",
            display: "flex",
            alignItems: "center",
            height: "61px",
            paddingLeft: "25px",
            minWidth: "300px",
            borderBottom: "1px solid #F7F7F7",
        },
        cellWithCheckbox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "& .quarterName": {
                marginRight: "5px",
            },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#FFD92E",
            },
            "& .MuiCheckbox-root": {
                color: "#888",
            },
            "& .Mui-checked .MuiIconButton-label": {
                background: "#fff",
            },
            "& .MuiIconButton-label": {
                width: "16px",
                height: "16px",
            }
        },
    })
// Customizable Area End

import AttendanceBehaviourTabController, {
    Props,
} from "./AttendanceBehaviourTabController";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";

export class AttendanceBehaviourTab extends AttendanceBehaviourTabController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const editAttendance = this.showInEditableMode();

        const attendanceData = [
            {
                title: 'Q1',
                value: '50',
            },
            {
                title: 'Q2',
                value: '50',
            },
            {
                title: 'Q3',
                value: '50',
            },
            {
                title: 'Q4',
                value: '50',
            },
            {
                title: 'Total',
                value: '200',
            }
        ];
        
        const evaluationKeysData = [
            {
                title: "Observation Title 1",
                key1: "A",
                key2: "B",
                key3: "C",
                key4: "D",
            },
            {
                title: "Observation Title 2",
                key1: "Excellent",
                key2: "Good",
                key3: "Needs Improvement",
                key4: "NA",
            },
        ]

        const getMainSkillGrades = (menu: any) => {
            const options = menu;
            if (options.length < 4) {
                for (let i = 0; i < (4 - options.length); i++) {
                    options.push(options[i]);
                }
            }
            return options.map((option: any) => (
                <Box className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`} minWidth={"110px"} maxWidth={"130px"} width={"100%"} height={"61px !important"}>
                    <Typography style={{ padding: "2px", fontWeight: 600 }} className={`${classes.textPrimaryColor}`}>{option}</Typography>
                </Box>
            )).slice(0, 4);
        };

        return (
            // Customizable Area Start
            <Box className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.bgColor}`} height={"100%"}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                            Attendance
                        </Typography>
                        <Box className={classes.attendanceBox}>
                            <Box className={classes.centerBorder}></Box>
                            {
                                attendanceData.map((att) => (
                                    <Box className={classes.attendanceItem}>
                                        <Typography className={`${classes.attendanceItemText} ${classes.attendanceLabel} ${classes.textCommonColor}`}>{att.title}</Typography>
                                        <Typography className={`${classes.attendanceItemText} ${classes.textPrimaryColor}`}>{att.value}</Typography>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={`${classes.dividerLine}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                            {this.state.userRole === 'teacher' ? 'Teacher’s' : this.state.userRole === 'parentTeacher' ? 'Parent Teacher’s' : ''} Comments
                        </Typography>
                        <Box className={classes.customTextEditorStyle}>
                            {
                                editAttendance ?
                                    <CustomTextEditor
                                        editorId="gradebook-comment-textbox"
                                        editorContent={this.state.commentData}
                                        placeHolderText="Enter Comment"
                                        onWysEditorStateChange={(event: any) => this.onCommentEdit(event)}
                                        editorError={false}
                                        helperText=""
                                    /> :
                                    <Typography className={`${classes.commentText} ${classes.textCommonColor}`}>
                                        Jimy is an exceptional student who consistently demonstrates a strong work ethic and a genuine passion for learning. Their exceptional academic achievements are a testament to their dedication and commitment to excellence. Their ability to think critically, creatively, and collaboratively sets them apart from their peers.
                                    </Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.dividerLine} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                            Behaviours
                        </Typography>
                        <Grid container direction="row">
                            {
                                this.state?.studentBehaviorData.map((behaviourItem, index) => (
                                    <Grid key={`behaviour-${behaviourItem.id}`} item xs={12} className={classes.behaviourMainGrid}>
                                        <Box className={classes.behaviourHeader}>
                                            <Box className={`${classes.behaviourItemTitle}`}><Typography>{behaviourItem.name}</Typography></Box>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className={`${classes.cellWithCheckbox} ${classes.behaviourHeaderItem}`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                <Typography className="quarterName">Q1</Typography>
                                                {editAttendance && (
                                                    <Checkbox
                                                        checked={behaviourItem.isQ1Selected}
                                                        color="primary"
                                                        data-test-id={`q1Check${index}`}
                                                        onChange={(event: any) => this.onSelectDeselectQuarters(behaviourItem, 'q1')}
                                                        inputProps={{ 'aria-label': 'quater checkbox' }}
                                                    />
                                                )}
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className={`${classes.cellWithCheckbox} ${classes.behaviourHeaderItem}`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                <Typography className="quarterName">Q2</Typography>
                                                {editAttendance && (
                                                    <Checkbox
                                                        checked={behaviourItem.isQ2Selected}
                                                        color="primary"
                                                        data-test-id={`q2Check${index}`}
                                                        onChange={(event: any) => this.onSelectDeselectQuarters(behaviourItem, 'q2')}
                                                        inputProps={{ 'aria-label': 'quater checkbox' }}
                                                    />
                                                )}
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className={`${classes.cellWithCheckbox} ${classes.behaviourHeaderItem}`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                <Typography className="quarterName">Q3</Typography>
                                                {editAttendance && (
                                                    <Checkbox
                                                        data-test-id={`q3Check${index}`}
                                                        checked={behaviourItem.isQ3Selected}
                                                        color="primary"
                                                        onChange={(event: any) => this.onSelectDeselectQuarters(behaviourItem, 'q3')}
                                                        inputProps={{ 'aria-label': 'quater checkbox' }}
                                                    />
                                                )}
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className={`${classes.cellWithCheckbox} ${classes.behaviourHeaderItem}`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                <Typography className="quarterName">Q4</Typography>
                                                {editAttendance && (
                                                    <Checkbox
                                                        data-test-id={`q4Check${index}`}
                                                        checked={behaviourItem.isQ4Selected}
                                                        color="primary"
                                                        onChange={(event: any) => this.onSelectDeselectQuarters(behaviourItem, 'q4')}
                                                        inputProps={{ 'aria-label': 'quater checkbox' }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                        {
                                            behaviourItem.data.map((mainSkill: any, index: number) => (
                                                <Box className={`${classes.mainSkillTable}`}>
                                                    <Box className={`${!editAttendance ? classes.mainSkillHeader : ''}`}>
                                                        <Box className={`${classes.mainSkillTitle} ${classes.bgColor} ${classes.textPrimaryColor} ${classes.fontBold500}`} width={editAttendance ? '-webkit-fill-available' : '60%'}>
                                                            <Typography>{mainSkill.title}</Typography>
                                                        </Box>
                                                        {!editAttendance && getMainSkillGrades(behaviourItem.menu)}
                                                    </Box>
                                                    {editAttendance && mainSkill.subSkills.map((subskill: any) => (
                                                        <Box className={classes.behaviourDataItem}>
                                                            <Box borderBottom={index === behaviourItem.data.length - 1 ? 'none !important' : ""} className={`${classes.subItemBlockTitle} ${classes.bgColor}`} width={"60%"}>
                                                                <Typography style={{ padding: "2px", fontWeight: 400 }} className={`${classes.textCommonColor}`}>{subskill.title}</Typography>
                                                            </Box>
                                                            <Box className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                                {editAttendance ? (
                                                                    <Box display="flex" width="100%" height="100%">
                                                                        <button data-test-id='subskillGradeBtnQ1' className={`${classes.subskillGradeBtn}`} onClick={(e) => this.updateBehaviourTableCellData(e.currentTarget, 'q1Target', subskill, mainSkill.id, behaviourItem, `q1-${index}`)}>
                                                                            <Typography className={`${classes.textPrimaryColor}`}>{subskill.q1}</Typography>
                                                                        </button>
                                                                        <Popover id={`q1-${index}`} open={!!subskill.q1Target} anchorEl={subskill.q1Target}
                                                                            data-test-id='updateBehaviourCloseQ1'
                                                                            anchorOrigin={{
                                                                                horizontal: 'left',
                                                                                vertical: 'bottom',
                                                                            }}
                                                                            onClose={(e) => this.updateBehaviourTableCellData(null, 'q1Target', subskill, mainSkill.id, behaviourItem)}
                                                                            transformOrigin={{
                                                                                horizontal: 'left',
                                                                                vertical: 'top',
                                                                            }}>

                                                                            {behaviourItem.menu.map((option: any, index: number) => <MenuItem key={`${option}-${index}`} data-test-id='updateBehaviourItemQ1' onClick={(e) => this.updateBehaviourValueAndCloseDropdown(option, 'q1', subskill, mainSkill.id, behaviourItem, 'q1Target')}>{option}</MenuItem>)}
                                                                        </Popover>
                                                                    </Box>
                                                                ) : (
                                                                    <Typography style={{ padding: "2px", fontWeight: 600 }} className={`${classes.textPrimaryColor}`}>{subskill.q1}</Typography>
                                                                )}
                                                            </Box>
                                                            <Box className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                                {editAttendance ? (
                                                                    <Box display="flex" width="100%" height="100%">
                                                                        <button data-test-id='subskillGradeQ2' className={`${classes.subskillGradeBtn}`} onClick={(e) => this.updateBehaviourTableCellData(e.currentTarget, 'q2Target', subskill, mainSkill.id, behaviourItem, `q2-${index}`)}>
                                                                            <Typography className={`${classes.textPrimaryColor}`}>{subskill.q2}</Typography>
                                                                        </button>
                                                                        <Popover data-test-id='updateBehaviourCloseQ2' id={`q2-${index}`} anchorEl={subskill.q2Target} open={!!subskill.q2Target}
                                                                            onClose={(e) => this.updateBehaviourTableCellData(null, 'q2Target', subskill, mainSkill.id, behaviourItem)}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            anchorOrigin={{
                                                                                horizontal: 'left',
                                                                                vertical: 'bottom',
                                                                            }}
                                                                        >

                                                                            {behaviourItem.menu.map((option: any, index: number) => <MenuItem data-test-id='updateBehaviourItemQ2' onClick={(e) => this.updateBehaviourValueAndCloseDropdown(option, 'q2', subskill, mainSkill.id, behaviourItem, 'q2Target')} key={`${option}-${index}`}>{option}</MenuItem>)}
                                                                        </Popover>
                                                                    </Box>
                                                                ) : (
                                                                    <Typography style={{ padding: "2px", fontWeight: 600 }} className={`${classes.textPrimaryColor}`}>{subskill.q2}</Typography>
                                                                )}
                                                            </Box>
                                                            <Box className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                                {editAttendance ? (
                                                                    <Box display="flex" width="100%" height="100%">
                                                                        <button data-test-id='subskillGradeBtnQ3' className={`${classes.subskillGradeBtn}`} onClick={(e) => this.updateBehaviourTableCellData(e.currentTarget, 'q3Target', subskill, mainSkill.id, behaviourItem, `q3-${index}`)}>
                                                                            <Typography className={`${classes.textPrimaryColor}`}>{subskill.q3}</Typography>
                                                                        </button>
                                                                        <Popover data-test-id='updateBehaviourCloseQ3' open={!!subskill.q3Target} anchorEl={subskill.q3Target} id={`q3-${index}`}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            onClose={(e) => this.updateBehaviourTableCellData(null, 'q3Target', subskill, mainSkill.id, behaviourItem)}
                                                                        >

                                                                            {behaviourItem.menu.map((option: any, index: number) => <MenuItem data-test-id='updateBehaviourItemQ3' onClick={(e) => this.updateBehaviourValueAndCloseDropdown(option, 'q3', subskill, mainSkill.id, behaviourItem, 'q3Target')} key={`${option}-${index}`}>{option}</MenuItem>)}
                                                                        </Popover>
                                                                    </Box>
                                                                ) : (
                                                                    <Typography style={{ padding: "2px", fontWeight: 600 }} className={`${classes.textPrimaryColor}`}>{subskill.q3}</Typography>
                                                                )}
                                                            </Box>
                                                            <Box className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>
                                                                {editAttendance ? (
                                                                    <Box display="flex" width="100%" height="100%">
                                                                        <button data-test-id='subskillGradeBtnQ4' className={`${classes.subskillGradeBtn}`} onClick={(e) => this.updateBehaviourTableCellData(e.currentTarget, 'q4Target', subskill, mainSkill.id, behaviourItem, `q4-${index}`)}>
                                                                            <Typography className={`${classes.textPrimaryColor}`}>{subskill.q4}</Typography>
                                                                        </button>
                                                                        <Popover data-test-id='updateBehaviourCloseQ4' id={`q4-${index}`} open={!!subskill.q4Target} anchorEl={subskill.q4Target}
                                                                            onClose={(e) => this.updateBehaviourTableCellData(null, 'q4Target', subskill, mainSkill.id, behaviourItem)}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            }}>

                                                                            {behaviourItem.menu.map((option: any, index: number) => <MenuItem data-test-id='updateBehaviourItemQ4' onClick={(e) => this.updateBehaviourValueAndCloseDropdown(option, 'q4', subskill, mainSkill.id, behaviourItem, 'q4Target')} key={`${option}-${index}`}>{option}</MenuItem>)}
                                                                        </Popover>
                                                                    </Box>
                                                                ) : (
                                                                    <Typography style={{ padding: "2px", fontWeight: 600 }} className={`${classes.textPrimaryColor}`}>{subskill.q4}</Typography>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                    
                                                </Box>
                                            ))
                                        }
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>

                    {!editAttendance && (
                        <Grid item xs={12}>
                            <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                                Evaluation Keys
                            </Typography>
                            <Box className={classes.evaluationKeyBox}>
                                <Box className={classes.evaluationKeyRow}>
                                    <Box width={"22.5%"} paddingLeft={"25px"}>
                                        <Typography className={`${classes.attendanceLabel}`}>Title</Typography>
                                    </Box>
                                    <Box width={"22.5%"}>
                                        <Typography className={`${classes.attendanceLabel}`}>Key 1</Typography>
                                    </Box>
                                    <Box width={"22.5%"}>
                                        <Typography className={`${classes.attendanceLabel}`}>Key 2</Typography>
                                    </Box>
                                    <Box width={"22.5%"}>
                                        <Typography className={`${classes.attendanceLabel}`}>Key 3</Typography>
                                    </Box>
                                    <Box width={"10%"}>
                                        <Typography className={`${classes.attendanceLabel}`}>Key 4</Typography>
                                    </Box>
                                </Box>
                                {
                                    evaluationKeysData.map((keyData, index) => (
                                        <Box borderBottom={index === evaluationKeysData.length - 1 ? 'none !important' : ''} className={classes.evaluationKeyRow}>
                                            <Box width={"22.5%"} paddingLeft={"25px"}>
                                                <Typography style={{ color: "#888" }}>{keyData.title}</Typography>
                                            </Box>
                                            <Box width={"22.5%"}>
                                                <Typography className={`${classes.textPrimaryColor}`}>{keyData.key1}</Typography>
                                            </Box>
                                            <Box width={"22.5%"}>
                                                <Typography className={`${classes.textPrimaryColor}`}>{keyData.key2}</Typography>
                                            </Box>
                                            <Box width={"22.5%"}>
                                                <Typography className={`${classes.textPrimaryColor}`}>{keyData.key3}</Typography>
                                            </Box>
                                            <Box width={"10%"}>
                                                <Typography className={`${classes.textPrimaryColor}`}>{keyData.key4}</Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Grid>
                    )}
                    
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
};

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AttendanceBehaviourTab);
// Customizable Area End
