// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import TeacherAddNewTopicController, {
  Props,
} from "./TeacherAddNewTopicController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addNewTopicModalCss: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "100%",
        width: "880px",
        overflowY: "hidden",
        overflowX: "hidden",
        minHeight: "500px",
      },
    },

    requiredTopicField: {
      color: "red",
    },
    TextareaAutosizes: {
      "&::placeholder": {
        fontSize: "16px",
        color: "#888888",
        opacity: "0.9",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    customLoader: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
      zIndex: 100,
      display: "block",
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherAddNewTopicModal extends TeacherAddNewTopicController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit } = this.props;
    const {
      topicTitleValue,
      topicTitleError,
      topicTitleMsg,
      subTopicValue,
      descriptionValue,
      descriptionError,
      descriptionErrorMsg,
      loading
    } = this.state;
    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addNewTopicModalCss}`}
          aria-labelledby="add-new-course-confirm-dialog-title"
          open={open}
          scroll={"body"}
          data-test-id="AddedCourse"
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box padding={"30px"} className={`${classes.bgColor}`} data-test-id="ParentBox">
            <Grid direction="row" container data-test-id="ConatinerGrid">
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.textPrimaryColor} ${classes.fontBold600}`}
                  component={"span"}
                  data-test-id="Add_New_Topic"
                >
                  {isEdit ? 'Edit Topic' : 'Add New Topic'}
                </Typography>
                <Button
                  onClick={this.props.onCancel}
                  className={classes.textPrimaryColor}
                  style={{ float: "right" }}
                  data-test-id="Add_New_Topic_X"
                >
                  {" "}
                  X{" "}
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
              data-test-id="Add_New_Topic_Content"
            >
              <Grid container spacing={2} direction="row" data-test-id="DailogContetn">
                <Grid xs={6} item>
                  <Box marginBottom={"10px"} width={"100%"} data-test-id="TopicContent">
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="TopicContentTitleBox"
                    >
                      Topic Title
                      <Typography
                        component={"span"}
                        className={classes.requiredTopicField}
                        data-test-id="TopicContentRequired"
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      fieldName="topicTitleValue"
                      fieldId="topic-title-input-field"
                      textFieldStyles={topicModalFields}
                      placeHolderText="Enter Topic Title"
                      fieldWidth={"100%"}
                      fieldType="text"
                      fieldValue={topicTitleValue}
                      fieldError={topicTitleError}
                      helperText={topicTitleMsg}
                      handleChange={this.handleInputChange}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} item data-test-id="Sub_Topic">
                  <Box marginBottom={"10px"} width={"99%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Sub_Topic_Name"
                    >
                      Sub Topic
                    </Typography>
                    <CustomTextField
                      textFieldStyles={topicModalFields}
                      placeHolderText="Enter Sub Topic"
                      fieldId="sub-topic-input-field"
                      fieldWidth={"100%"}
                      fieldName="subTopicValue"
                      fieldType="text"
                      fieldValue={subTopicValue}
                      handleChange={this.handleInputChange}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item data-test-id="Activity_Counst">
                  <Box marginBottom={"10px"} width={"100%"} data-test-id="Activity_Box">
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Activity_Description"
                    >
                      Description
                      <Typography
                        component={"span"}
                        className={classes.requiredTopicField}
                        data-test-id="Activity_Required"
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Description"
                      fieldName="descriptionValue"
                      multiline
                      fieldId="description-input-field"
                      fieldValue={descriptionValue}
                      textFieldStyles={{ padding: "10px 0", height: "170px" }}
                      helperText={descriptionErrorMsg}
                      fieldWidth={"99%"}
                      fieldError={descriptionError}
                      handleChange={this.handleInputChange}
                      fieldType="text"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box marginBottom={"10px"} style={{ width: "100%" }}>
                    <CustomButton
                      btnText="Submit"
                      btnStyle={submitBtnStyles}
                      buttonId={`my-add-topic-mobile`}
                      buttonVariant={"contained"}
                      type={"button"}
                      handleButtonClick={this.handleSubmitForm}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      btnStyle={outlinedBtnStyles}
                      buttonId={`my-cpurse-search-mobile`}
                      buttonVariant={"outlined"}
                      type={"button"}
                      handleButtonClick={this.props.onCancel}
                      isDisabled={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );

    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const outlinedBtnStyles = {
  width: "120px",
  padding: "10px 10px",
  height: "50px",
  borderRadius: "12px",
  fontSize: "18px",
  textAlign: "center",
  fontWeight: 500,
  marginTop: "32px",
  marginLeft: "15px",
  float: "right",
} as const;

const submitBtnStyles = {
  padding: "10px 10px",
  height: "50px",
  width: "120px",
  fontSize: "18px",
  textAlign: "center",
  borderRadius: "12px",
  marginTop: "32px",
  float: "right",
  marginLeft: "15px",
  fontWeight: 500,
} as const;

const topicModalFields = {
  width: "100%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;

const activityCountField = {
  width: "99%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAddNewTopicModal);
// Customizable Area End
