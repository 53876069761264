// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReturnGradesDialogController, {Props} from "./ReturnGradesDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    returnGradesSubmissionDialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiPaper-root": {
        width: "calc(100% - 400px)",
        maxWidth: "1200px",
      }
    },
    returnGradesTitleText: {
      margin: "10px 0",
    },
    returnReasonTextarea: {
      width: "100%",
      resize: "none",
      border: "1px solid #0000001f",
      borderRadius: "7px",
      padding: "15px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "16px",
      "&::-webkit-input-placeholder": {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        color: "#888",
        fontSize: "16px",
        letterSpacing: "0.17px"
      },
    },
    returnTextareaContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      flexWrap: "wrap",
    },
    returnTextareaBox: {
      flexBasis: "calc(50% - 10px)",
    },
    returnGradesNoteBox: {
      display: "flex",
      marginTop: "20px",
      gap: "5px",
    },
    returnGradesBtnBox: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "20px",
      marginTop: "35px"
    },
    closeIcon: {
      cursor: "pointer",
    }
  });

// Customizable Area End

// Customizable Area Start
export class ReturnGradesDialog extends ReturnGradesDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <Dialog
        className={`${classes.highlightBgColor} ${
          classes.returnGradesSubmissionDialog
        }`}
        aria-labelledby="quiz-view-answer-confirm-dialog-title"
        open={open}
      >
        <Box padding={"30px"} className={`${classes.bgColor}`}>
          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
          >
            <Typography className={`${classes.textPrimaryColor} ${classes.fontText22} ${classes.fontBold600}`}>
              Return Grade Submission
            </Typography>
            <CloseIcon
              className={`${classes.textCommonColor} ${classes.closeIcon}`}
              id="returnGradesCloseIcon"
              onClick={this.props.onCancel}
            />
          </Box>

          <DialogContent style={{ padding: "0px" }}>
            <Box>
              <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.returnGradesTitleText}`}>
                {`You are able to return the selected grades of Milton Perry, please specify the reason you have rejected.`}
              </Typography>
            </Box>

            <Box className={`${classes.returnTextareaContainer}`}>
              <Box data-test-id="forScience" className={`${classes.returnTextareaBox}`}>
                <Typography className={`${classes.fontText18} ${classes.textCommonColor}`}>Science</Typography>
                <Box>
                  <textarea className={`${classes.returnReasonTextarea} ${classes.primaryBorder} ${classes.bgColor} ${classes.textPrimaryColor}`} rows={5} placeholder="Input reason for returned submission"></textarea>
                </Box>
              </Box>

              <Box data-test-id="forMaths" className={`${classes.returnTextareaBox}`}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText18}`}>Maths</Typography>
                <Box>
                  <textarea className={`${classes.bgColor} ${classes.primaryBorder} ${classes.returnReasonTextarea} ${classes.textPrimaryColor}`} rows={5} placeholder="Input reason for returned submission"></textarea>
                </Box>
              </Box>

              <Box data-test-id="forHistory" className={`${classes.returnTextareaBox}`}>
                <Typography className={`${classes.fontText18} ${classes.textCommonColor}`}>History</Typography>
                <Box>
                  <textarea className={`${classes.returnReasonTextarea} ${classes.textPrimaryColor} ${classes.bgColor} ${classes.primaryBorder}`} rows={5} placeholder="Input reason for returned submission"></textarea>
                </Box>
              </Box>

              <Box data-test-id="forEnglish" className={`${classes.returnTextareaBox}`}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText18}`}>English</Typography>
                <Box>
                  <textarea className={`${classes.returnReasonTextarea} ${classes.primaryBorder} ${classes.textPrimaryColor} ${classes.bgColor}`} rows={5} placeholder="Input reason for returned submission"></textarea>
                </Box>
              </Box>

              <Box data-test-id="forFilipino" className={`${classes.returnTextareaBox}`}>
                <Typography className={`${classes.fontText18} ${classes.textCommonColor}`}>Filipino</Typography>
                <Box>
                  <textarea className={`${classes.primaryBorder} ${classes.returnReasonTextarea} ${classes.bgColor} ${classes.textPrimaryColor}`} rows={5} placeholder="Input reason for returned submission"></textarea>
                </Box>
              </Box>

              <Box data-test-id="forMapeh" className={`${classes.returnTextareaBox}`}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText18}`}>MAPEH</Typography>
                <Box>
                  <textarea className={`${classes.returnReasonTextarea} ${classes.bgColor} ${classes.primaryBorder} ${classes.textPrimaryColor}`} rows={5} placeholder="Input reason for returned submission"></textarea>
                </Box>
              </Box>
            </Box>

            <Box className={`${classes.textCommonColor} ${classes.returnGradesNoteBox}`}>
              <Typography className={`${classes.fontBold600}`}>Note:</Typography>
              <Typography>Only comment on subjects that have pending or incomplete requirements. The entirety of the gradebook will be returned.</Typography>
            </Box>

            <Box className={`${classes.returnGradesBtnBox}`}>
              <CustomButton btnStyle={cancelButton} btnText='Cancel' buttonId='returnGradesCancelBtn' buttonVariant={"outlined"} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
              <CustomButton btnStyle={confirmButton} btnText='Return' buttonId='returnGradesSubmitBtn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
            </Box>

          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const confirmButton = {
  height: "50px",
  width: "100%",
  padding: "5px",
  fontSize: "16px",
  borderRadius: "10px",
  fontWeight: 600,
  maxWidth: "190px",
  background: "#ff0000",
} as const;

const cancelButton = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid",
  fontWeight: 600,
  fontSize: "16px",
  maxWidth: "190px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ReturnGradesDialog);
// Customizable Area End
