// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import MyPathOverdueController, { Props } from "./MyPathOverdueController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import MyPathItemCardWeb from "./MyPathItemCard.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    myPathItemGrid: {
      display: "flex",
      justifyContent: "center",
    },
    dropdownGrid: {
      gap: "12px",
      marginBottom: "30px",
    },
    dateButton: {
      backgroundColor: "transparent",
      padding: "5px",
      fontSize: "12px",
      borderRadius: "6px",
      height: "26px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      marginLeft: "8px",
    },
    dateBtnDiv: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        marginTop: "20px",
      },
    },
    dateBtnRegular: {
      border: "1px solid #888888",
      color: "#888888",
    },
    dateBtnSelected: {
      border: "1px solid #0D46BC",
      color: "#0D46BC",
    },
    dateBtnBox: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    myPathFilterDiv: {
      display: "flex",
      alignItems: "flex-end",
      padding: "0px 10px",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0px !important",
      },
    },
    myPathFilterSelectDiv: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    marginFilter: {
      marginRight: "10px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class MyPathOverdue extends MyPathOverdueController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, myPathItemList, myPathCourseCategory,paginationDueList } = this.props;
    const {
      completionSortingDateList,
      dueDateSortingList,
      gradingComponent,
    } = this.state;

    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          {myPathCourseCategory === "overdue" && (
            <Grid item xs={12}>
              <Grid className={classes.dropdownGrid} container direction="row">
                <Grid item xs={12} sm={12} md={4}>
                  <Box marginLeft={"12px"}>
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component={"span"}
                      >
                        Sort by Due Date
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-my-path-dueDate"
                        fieldValue={this.props.selectedSortByDueDateDDValue}
                        fieldName="selectedSortByDueDateDDValue"
                        handleChange={this.props.handleChangeDropdownValue}
                        renderValue={this.props.renderDueDateComponent}
                        fieldError={false}
                        fieldMenuItems={dueDateSortingList}
                        fieldStyles={selectDropdownStyles}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box> 
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {myPathCourseCategory === "upcoming" && (
            <Grid item xs={12}>
              <Grid container className={classes.dropdownGrid} direction="row">
                <Grid item xs={12}>
                  <Box className={classes.myPathFilterDiv}>
                    <Box className={`${classes.myPathFilterSelectDiv}`}>
                      <Box className={`${classes.marginFilter}`}>
                        <Box marginBottom={"10px"}>
                          <Typography
                            className={`${classes.fontText16} ${classes.textCommonColor} `}
                            component={"span"}
                          >
                            Grading Component
                          </Typography>
                        </Box>
                        <Box>
                          <CustomSelectDropdown
                            fieldId="select-dropdown-my-path-grading-component"
                            fieldValue={this.props.selectedGradingComponentDDValue}
                            fieldName="selectedGradingComponentDDValue"
                            handleChange={this.props.handleChangeDropdownValue}
                            renderValue={this.props.renderGradingComponent}
                            fieldError={false}
                            fieldMenuItems={gradingComponent}
                            fieldStyles={selectDropdownStyles}
                            iconComponent={
                              <DropdownIcon
                                width="24"
                                fill={"none"}
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                height="24"
                                stroke={"#888888"}
                              />
                            }
                          />
                        </Box>
                      </Box>
                      <Box>
                        <CustomButton
                          type={"button"}
                          btnText="Search"
                          buttonId={`my-path-upcoming-search`}
                          buttonVariant={"contained"}
                          handleButtonClick={this.props.handleSearchMyPathCourses}
                          btnStyle={searchBtnStyle}
                          isDisabled={false}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.dateBtnDiv}>
                      <Box
                        onClick={() => this.props?.handleChangeDateFilter?.("tomorrow")}
                        className={
                          this.props.selectedDateFilterText === "tomorrow"
                            ? `${classes.dateButton} ${classes.dateBtnSelected}`
                            : `${classes.dateButton} ${classes.dateBtnRegular}`
                        }
                        data-testid="my-path-upcoming-tomorrow-btn"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText14}`}
                        >
                          Tomorrow
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => this.props.handleChangeDateFilter?.("this_week")}
                        className={
                         this.props.selectedDateFilterText === "this_week"
                            ? `${classes.dateButton} ${classes.dateBtnSelected}`
                            : `${classes.dateButton} ${classes.dateBtnRegular}`
                        }
                        data-testid="my-path-upcoming-this-week-btn"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText14}`}
                        >
                          This Week
                        </Typography>
                      </Box>
                      <Box
                        className={`${classes.dateBtnRegular} ${classes.dateButton}`}
                        data-testid="my-path-upcoming-date-picker"
                      >
                        <CalendarIcon />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {myPathCourseCategory === "review" && (
            <Grid item xs={12}>
              <Box
                className={`${classes.dropdownGrid} ${classes.myPathFilterDiv}`}
              >
                <Box className={`${classes.myPathFilterSelectDiv}`}>
                  <Box className={`${classes.marginFilter}`}>
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component={"span"}
                      >
                        Sort by Completion Date
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldValue={this.props.selectedSortByCompletionDateDDValue}
                        fieldId="select-dropdown-my-path-completion-date"
                        fieldName="selectedSortByCompletionDateDDValue"
                        handleChange={this.props.handleChangeDropdownValue}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                        renderValue={this.props.renderDueDateComponent}
                        fieldMenuItems={completionSortingDateList}
                        fieldStyles={selectDropdownStyles}
                        fieldError={false}
                      />
                    </Box>
                  </Box>
                  <Box className={`${classes.marginFilter}`}>
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component={"span"}
                      >
                        Sort By Grade
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-my-path-grade-sorting"
                        fieldValue={this.props.selectedSortByGradeDDValue}
                        fieldName="selectedSortByGradeDDValue"
                        handleChange={this.props.handleChangeDropdownValue}
                        fieldMenuItems={dueDateSortingList}
                        renderValue={this.props.renderDueDateComponent}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                        fieldError={false}
                        fieldStyles={selectDropdownStyles}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <CustomButton
                      btnStyle={searchBtnStyle}
                      btnText="Search"
                      buttonVariant={"contained"}
                      type={"button"}
                      buttonId={`my-path-review-search`}
                      isDisabled={false}
                      handleButtonClick={this.props.handleSearchMyPathInReview}
                    />
                  </Box>
                </Box>
                <Box className={classes.dateBtnDiv}>
                  <Box className={classes.dateBtnBox}>
                    <Box
                      onClick={() =>
                        this.props.handleChangeDateReviewFilter?.("review_today")
                      }
                      className={
                        this.props.selectedDateReviewFilterText === "review_today"
                          ? `${classes.dateButton} ${classes.dateBtnSelected}`
                          : `${classes.dateButton} ${classes.dateBtnRegular}`
                      }
                      data-testid="my-path-review-today-btn"
                    >
                      <Typography
                        component={"span"}
                        className={`${classes.fontText14}`}
                      >
                        Today
                      </Typography>
                    </Box>
                    <Box
                      onClick={() =>
                        this.props.handleChangeDateReviewFilter?.("review_yesterday")
                      }
                      className={
                        this.props.selectedDateReviewFilterText === "review_yesterday"
                          ? `${classes.dateButton} ${classes.dateBtnSelected}`
                          : `${classes.dateButton} ${classes.dateBtnRegular}`
                      }
                      data-testid="my-path-review-yesterday-btn"
                    >
                      <Typography
                        component={"span"}
                        className={`${classes.fontText14}`}
                      >
                        Yesterday
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.dateBtnBox}>
                    <Box
                      onClick={() =>
                        this.props.handleChangeDateReviewFilter?.("review_last_week")
                      }
                      className={
                        this.props.selectedDateReviewFilterText === "review_last_week"
                          ? `${classes.dateButton} ${classes.dateBtnSelected}`
                          : `${classes.dateButton} ${classes.dateBtnRegular}`
                      }
                      data-testid="my-path-review-last-week-btn"
                    >
                      <Typography
                        component={"span"}
                        className={`${classes.fontText14}`}
                      >
                        Last Week
                      </Typography>
                    </Box>
                    <Box
                      onClick={() =>
                        this.props.handleChangeDateReviewFilter?.("review_last_month")
                      }
                      className={
                        this.props.selectedDateReviewFilterText === "review_last_month"
                          ? `${classes.dateButton} ${classes.dateBtnSelected}`
                          : `${classes.dateButton} ${classes.dateBtnRegular}`
                      }
                      data-testid="my-path-review-last-month-btn"
                    >
                      <Typography
                        component={"span"}
                        className={`${classes.fontText14}`}
                      >
                        Last Month
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            {myPathItemList?.length > 0 ? (
              myPathItemList.map((myPathItem: any, index: number) => (
                <MyPathItemCardWeb
                  key={myPathItem?.id}
                  myPathItem={myPathItem}
                  myPathCourseCategory={myPathCourseCategory}
                />
              ))
            ) : (
              <CustomNoDataComponent
                noDataText={`No items in ${myPathCourseCategory} yet.`}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {myPathItemList.length > 0 &&
            <Box className={classes.paginationBox}>
              <Box className={classes.paginationRoot}>
                <Pagination
                  shape="rounded"
                  variant="outlined"
                  count={Math.ceil(paginationDueList?.total_count / paginationDueList?.per_page)}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={this.props.handlePaginationChange}
                  page={paginationDueList?.current_page}
                />
              </Box>
            </Box>
          }
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtnStyle = {
  width: "130px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const selectDropdownStyles = {
  width: "235px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MyPathOverdue);
// Customizable Area End
