// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentProgressJourneyDesktopController, {
  Props,
} from "./StudentProgressJourneyDesktopController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import StudentMyCoursesJourneyBox from "./StudentMyCoursesJourneyBox.web";
import StudentMyCoursesActivityJourneyBox from "./StudentMyCoursesActivityJourneyBox.web";
import MyCoursesInfoDialog from "./MyCoursesInfoDialog.web";
import CustomAccordion from "../../../components/src/CustomAccordion.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    columnDataList: {
      borderTop: "2px solid #f1f1f1",
    },
    columnHeader: {
      backgroundColor: "rgba(0, 157, 255, 0.05)",
      padding: "10px 15px",
    },
    borderRight: {
      borderRight: "2px solid #f1f1f1",
    },
    topicLessonBox: {
      borderRadius: "6px",
      maxWidth: "98%",
      width: "94%",
      overflow: "auto",
      maxHeight: "810px",
      padding: "2%",
    },
    searchTextBox: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentProgressJourneyMobileView extends StudentProgressJourneyDesktopController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      openCourseInfoDialog,
      selectedActivityId,
      selectedLesson,
      subjectTopicList,
    } = this.state;
    return (
      <>
        {/* Topic Lessons Activity Box */}
        <Box className={`${classes.bgColor} ${classes.topicLessonBox}`}>
        </Box>
        {/* Lesson info Dialog */}
        <MyCoursesInfoDialog
          open={openCourseInfoDialog}
          courseInfo={{
            instruction:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce commodo augue lectus, sed vestibulum ipsum tristique non. ",
            materials: "NA",
            objectives:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce commodo augue lectus, sed vestibulum ipsum tristique non. ",
            information:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce commodo augue lectus, sed vestibulum ipsum tristique non. In hac habitasse platea dictumst. Ut metus odio, pharetra vel elit vel, porta maximus quam. Nam sollicitudin, lacus ut euismod elementum, lorem nisl auctor mi, sed malesuada tortor ipsum et erat. Nunc eros risus,",
            title: `${selectedLesson?.lessonTitle} Information`,
          }}
          onClose={this.handleCloseCourseInfoDialogs}
        />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProgressJourneyMobileView);
// Customizable Area End
