// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedQuizAssessmentId: any;
  selectedQuizAssessmentDetails: any;
  currentPageQuestionsList: Array<any>;
  totalPageNumber: number;
  currentPage: number;
  category: string;
  assessmentTitle: string;
  assessmentId:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCompletedQuizMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentActivityDetails:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedQuizAssessmentId: null,
      assessmentId:"",
      selectedQuizAssessmentDetails:[],
      currentPageQuestionsList: [],
      totalPageNumber: 1,
      currentPage: 1,
      category: "",
      assessmentTitle: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetCompletedCurrentQuestionsList(1);
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetCompletedCurrentQuestionsList = (pageNum?: number) => {
    const { selectedQuizAssessmentDetails } = this.state;
    const paramAssessmentCategory = this.props.navigation?.getParam("category");
    const paramAssessmentTitle = this.props.navigation?.getParam("title");
    const paramAssessmentId = this.props.navigation?.getParam("activityId");
    const currentPageQuestionsList =
      selectedQuizAssessmentDetails?.data?.filter(
        (item: any) => item.pageNo === pageNum
      );
    const totalPageNumber = selectedQuizAssessmentDetails?.meta?.totalPages;
    this.setState({
      currentPageQuestionsList,
      totalPageNumber,
      category: paramAssessmentCategory,
      assessmentTitle: paramAssessmentTitle,
      assessmentId:paramAssessmentId,
      currentPage: 1,
      loading: false,
      // selectedQuizAssessmentDetails: "",
      selectedQuizAssessmentId: ""
    },()=>{
      this.handleGetStudentActivityApi()
    });
  };

  handleGoBackToGradeStatistics = () => {
    this.props.navigation?.goBack();
  };

  checkIfQuestionAttempted = (index: number) => {
    const { selectedQuizAssessmentDetails } = this.state;
    const questions = selectedQuizAssessmentDetails;
    const isQuestionAttempted = !!questions[index]?.studentAnswerId;
    return isQuestionAttempted;
  };

  handleGetStudentActivityApi = async () => {
    showCustomLoader();
   
    let apiConfigEndpoint = configJSON.studentAssessmentEndpoint;
    const studentId = await getStorageData("user_id")

    const { assessmentId } = this.state;
    const requestEndPoint = apiConfigEndpoint + `?activity_id=${assessmentId}&student_id=${studentId}`;

    const token = await getStorageData("token");
    const header = {
        token,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentActivityDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};


handleGetStudentActivityResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
        const questionActivityList = responseJson.data.map((item: any, index: number) => {
          
      
            const activityItem = item.attributes;
            const indexAnswer = activityItem.options.find((option: { text: any; }) => option.text === activityItem.student_answer.answer);
            const answersList = activityItem.options.map((option: { id: any; text: any; }) => ({
              id: option.id,
              option: option.text,
              correct_answer: option.text === activityItem.correct_option,
              
            }));
            return {
                id: item.id,
                question: activityItem.question,
                answers: answersList,
                attachment: activityItem.attachments,
                maxScore: activityItem?.marks,
                studentAnswerId: indexAnswer?.id,
                explanation:activityItem?.explanation
            }
        });
        this.setState({
          selectedQuizAssessmentDetails:questionActivityList
            
        });
    } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
};

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apiGetStudentActivityDetails) {
          this.handleGetStudentActivityResponse(
            responseJson,
            errorResponse
        );
        }
      }
  }
      console.log("quiz completed apiRequestCallId");

      // Customizable Area Start

      // Customizable Area End
  }

  // Customizable Area End
}
// Customizable Area End
