export const FIELD_TYPES = {
  TEXT: "text",
  SELECT: "select_dropdown",
  FILE_UPLOAD_TEXT: "textfield_file_upload",
  MULTI_SELECT: "multi_select_dropdown",
  DATE: "date",
  COLOR: "color",
};

export const USER_ROLES = {
  TENANT_ADMIN: "school admin",
  STUDENT: "student",
  TEACHER: "teacher",
  PARENT: "parent",
  PARENT_TEACHER: "parent teacher",
  REGISTRAR: "registrar",
};

export const USER_ROLES_CAMEL = {
  TENANT_ADMIN: "School Admin",
  STUDENT: "Student",
  TEACHER: "Teacher",
  PARENT: "Parent",
  PARENT_TEACHER: "Parent Teacher",
  REGISTRAR: "Registrar",
};

export const TOASTER_NOTIFICATION_MSG = {
  LOGIN_SUCCESS: "You have successfully logged in!",
  EMPTY_LOGIN_FIELDS: "Please enter both email and password",
  EMPTY_FORGOT_PWD_EMAIL: "Please enter email!",
  INCORRECT_EMAIL_FORMAT: "Please enter a valid email!",
  CHANGE_PASSWORD_OTP_SENT:
    "A 4-digit Verification Code has been sent to your email!",
  SEND_OTP_EMPTY_EMAIL: "Please enter email!",
  INVALID_OTP: "Please enter correct otp!",
  INCORRECT_OTP: "Incorrect OTP!",
  EMPTY_OLD_PASSWORD: "Please enter old password!",
  RESET_PASSWORD_SUCCESSFUL: "Your password has been reset successfully!",
  EMPTY_RESET_PASSWORD: "Please enter password!",
  EMPTY_RESET_CONFIRM_PASSWORD: "Please enter confirm password!",
  PASSWORD_STRENGTH:
    "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character!",
  CONFIRM_PASSWORD_MISMATCH: "Password does not match with Confirm Password!",
  SCHOOL_YEAR_CREATION_SUCCESS: "School year was added successfully!",
  SCHOOL_YEAR_CREATION_FAILURE:
    "Something went wrong while adding the school year!",
  SCHOOL_YEAR_FETCH_ERROR:
    "Something went wrong while fetching the school years!",
  ADD_NEW_TEACHER_SUCCESS: "New Teacher has been added successfully!",
  EDIT_TEACHER_SUCCESS: "Teacher has been updated successfully!",
  ADD_NEW_TEACHER_AS_DRAFT: "New Teacher has been added as a draft",
  ADD_NEW_PARENT_AS_DRAFT: "New Parent has been added as a draft",
  ADD_NEW_TEACHER_PARENT_AS_DRAFT: "New Teacher has been added as a draft",
  ADD_NEW_PARENT_SUCCESS: "New Parent has been added successfully!",
  EDIT_PARENT_SUCCESS: "Parent has been updated successfully!",
  ADD_NEW_PARENT_TEACHER_SUCCESS: "New Parent Teacher has been added successfully!",
  EDIT_PARENT_TEACHER_SUCCESS: "Parent Teacher has been updated successfully!",
  ADD_NEW_PARENT_TEACHER_DRAFT: "New Parent Teacher has been added as a Draft",
  ADD_NEW_STUDENT_SUCCESS: "New Student has been added successfully!",
  EDIT_STUDENT_SUCCESS: "Student has been updated successfully!",
  ADD_NEW_STUDENT_SUCCESS_DRAFT: "New Student has been added as a draft",
  GRADE_CREATION_SUCCESS: "Grade was added successfully!",
  GRADE_CREATION_FAILURE: "Something went wrong while adding the grade!",
  GRADE_FETCH_ERROR: "Something went wrong while fetching the grades!",
  GRADE_DELETE_SUCCESS: "Grade deleted successfully!",
  GRADE_DELETE_FAILURE: "Grade could not be deleted!",
  GRADE_UPDATE_SUCCESS: "Grade updated successfully",
  GRADE_UPDATE_FAILURE: "Something went wrong while updating the grade!",
  SCHOOL_YEAR_DELETE_SUCCESS: "School year deleted successfully!",
  SCHOOL_YEAR_DELETE_FAILURE: "School year could not be deleted!",
  SCHOOL_YEAR_STATUS_UPDATE_SUCCESS: "School year status updated successfully!",
  SCHOOL_YEAR_STATUS_UPDATE_FAILURE:
    "Something went wrong while updating the status!",
  SCHOOL_YEAR_UPDATE_SUCCESS: "School year was updated successfully!",
  SCHOOL_YEAR_UPDATE_FAILURE:
    "Something went wrong while updating the school year!",
  COURSE_CREATION_SUCCESS: "Course created successfully!",
  COURSE_CREATION_FAILURE: "Something went wrong while creating the course!",
  COURSE_UPDATION_SUCCESS: "Course updated successfully!",
  COURSE_UPDATION_FAILURE: "Something went wrong while updating the course!",
  COURSE_LIST_FETCH_FAILURE: "Something went wrong while fetching course list!",
  SUBJECT_CREATION_SUCCESS: "Subject was added successfully!",
  SUBJECT_CREATION_FAILURE: "Something went wrong while adding the subject!",
  SUBJECT_FETCH_ERROR: "Something went wrong while fetching the subjects!",
  SUBJECT_DELETE_SUCCESS: "Subject deleted successfully!",
  SUBJECT_DELETE_FAILURE: "Subject could not be deleted!",
  SUBJECT_UPDATE_SUCCESS: "Subject was updated successfully!",
  SUBJECT_UPDATE_FAILURE: "Something went wrong while updating the subject!",
  CURRICULUM_CREATION_SUCCESS: "Curriculum was added successfully!",
  CURRICULUM_CREATION_FAILURE:
    "Something went wrong while adding the curriculum!",
  CURRICULUM_FETCH_ERROR:
    "Something went wrong while fetching the curriculums!",
  CURRICULUM_DELETE_SUCCESS: "Curriculum deleted successfully!",
  CURRICULUM_DELETE_FAILURE: "Curriculum could not be deleted!",
  CURRICULUM_UPDATE_SUCCESS: "Curriculum was updated successfully!",
  CURRICULUM_UPDATE_FAILURE:
    "Something went wrong while updating the curriculum!",
  RESOURSE_CREATION_SUCCESS: "Resourse created successfully!",
  RESOURSE_CREATION_FAILURE:
    "Something went wrong while creating the resourse!",
  TOPIC_CREATION_SUCCESS: "Topic was added successfully!",
  TOPIC_CREATION_FAILURE: "Something went wrong while adding the topic!",
  SCHOOL_CALENDAR_CREATION_SUCCESS: "School Calendar was added successfully!",
  SCHOOL_CALENDAR_CREATION_FAILURE:
    "Something went wrong while adding the school calendar!",
  SCHOOL_CALENDAR_FETCH_ERROR:
    "Something went wrong while fetching the school calendars!",
  SCHOOL_CALENDAR_DELETE_SUCCESS: "School Calendar deleted successfully!",
  SCHOOL_CALENDAR_DELETE_FAILURE: "School Calendar could not be deleted!",
  SCHOOL_CALENDAR_UPDATE_SUCCESS: "School Calendar was updated successfully!",
  SCHOOL_CALENDAR_UPDATE_FAILURE:
    "Something went wrong while updating the school calendar!",
  TOPIC_FETCH_ERROR: "Something went wrong while fetching the topics!",
  TOPIC_UPDATE_SUCCESS: "Topic was updated successfully!",
  TOPIC_DELETE_SUCCESS: "Topic deleted successfully!",
  TOPIC_DELETE_FAILURE: "Topic could not be deleted!",
  LESSON_CREATION_SUCCESS: "Lesson was added successfully!",
  LESSON_CREATION_FAILURE: "Something went wrong while adding the lesson!",
  LESSON_FETCH_ERROR: "Something went wrong while fetching the lessons!",
  LESSON_UPDATE_SUCCESS: "Lesson was updated successfully!",
  LESSON_DELETE_SUCCESS: "Lesson deleted successfully!",
  LESSON_DELETE_FAILURE: "Lesson could not be deleted!",
  RESOURSE_FETCH_ERROR: "Something went wrong while fetching the Resourse!",
  RESOURSE_UPDATE_SUCCESS: "Resourse was updated successfully!",
  RESOURSE_DELETE_SUCCESS: "Resourse deleted successfully!",
  RESOURSE_DELETE_FAILURE: "Resourse could not be deleted!",
  RESOURSE_UPDATE_FAILURE: "Something went wrong while updating the Resourse!",
  TEACHER_FETCH_ERROR: "Something went wrong while fetching teachers!",
  ACTIVITY_CREATION_SUCCESS: "Activity was added successfully!",
  ACTIVITY_CREATION_FAILURE: "Something went wrong while adding the activity!",
  ACTIVITY_FETCH_ERROR: "Something went wrong while fetching the activities!",
  ACTIVITY_UPDATE_SUCCESS: "Activity was updated successfully!",
  ACTIVITY_MARK_COMPLETED: "Activity was marked as completed!",
  ACTIVITY_UPDATE_FAILURE: "Something went wrong while updating the Activity!",
  ACTIVITY_DELETE_SUCCESS: "Activity deleted successfully!",
  ACTIVITY_DELETE_FAILURE: "Activity could not be deleted!",
  EVENT_CREATION_SUCCESS: "Event was added successfully!",
  EVENT_CREATION_FAILURE: "Something went wrong while adding the event!",
  EVENT_FETCH_ERROR: "Something went wrong while fetching the events!",
  EVENT_DELETE_SUCCESS: "Event deleted successfully!",
  EVENT_DELETE_FAILURE: "Event could not be deleted!",
  EVENT_UPDATE_SUCCESS: "Event was updated successfully!",
  EVENT_UPDATE_FAILURE: "Something went wrong while updating the event!",
  QUESTION_CREATION_SUCCESS: "Question was added successfully!",
  QUESTION_CREATION_FAILURE: "Something went wrong while adding the question!",
  QUESTION_FETCH_ERROR: "Something went wrong while fetching the questions!",
  QUESTION_DELETE_SUCCESS: "Question deleted successfully!",
  QUESTION_DELETE_FAILURE: "Question could not be deleted!",
  QUESTION_UPDATE_SUCCESS: "Question was updated successfully!",
  QUESTION_UPDATE_FAILURE: "Something went wrong while updating the question!",
  CLASS_CREATION_SUCCESS: "Class was added successfully!",
  CLASS_CREATION_FAILURE:
    "Something went wrong while adding the class!",
  CLASS_FETCH_ERROR:
    "Something went wrong while fetching the classes!",
  CLASS_DELETE_SUCCESS: "Class deleted successfully!",
  CLASS_DELETE_FAILURE: "Class could not be deleted!",
  CLASS_UPDATE_SUCCESS: "Class was updated successfully!",
  CLASS_UPDATE_FAILURE:
    "Something went wrong while updating the class!",
  CATALOG_CREATION_SUCCESS: "Catalog created successfully!",
  CATALOG_CREATION_FAILURE: "Something went wrong while creating the catalog!",
  CATALOG_LIST_FETCH_FAILURE: "Something went wrong while fetching catalog list!",
  TEACHER_PUBLISH_COURSE_SUCCESS: "Course published successfully!",
  TEACHER_COPY_COURSE_SUCCESS: "Course copied successfully!",
  CATALOG_UPDATION_SUCCESS: "Catalog updated successfully!",
  TEACHER_ASSIGNMENT_CREATION_SUCCESS: "Teacher assignment was created successfully!",
  TEACHER_ASSIGNMENT_CREATION_FAILURE:
    "Something went wrong while adding the teacher assignment!",
  TEACHER_ASSIGNMENT_FETCH_ERROR:
    "Something went wrong while fetching the teacher assignments!",
  TEACHER_ASSIGNMENT_DELETE_SUCCESS: "Teacher assignment deleted successfully!",
  TEACHER_ASSIGNMENT_DELETE_FAILURE: "Teacher assignment could not be deleted!",
  TEACHER_ASSIGNMENT_UPDATE_SUCCESS: "Teacher assignment was updated successfully!",
  TEACHER_ASSIGNMENT_UPDATE_FAILURE:
    "Something went wrong while updating the teacher assignment!",
  REQUEST_ACCEPT_SUCCESS: "Request accepted successfully!",
  REQUEST_REJECT_SUCCESS: "Request rejected successfully!",
  ASSIGN_CATELOG_TEACHER_SUCCESS: "Catalog assign to teacher successfully",
  STUDENT_SUBMIT_ASSESSMENT_ANSWER_SUCCESS: "Your answers were submitted successfully!",
  STUDENT_SUBMIT_ASSESSMENT_ANSWER_FAILURE: "Something went wrong while submitting the answers. Please try again!",
  REMOVE_COURSE_CATALOG_SUCCESS: "Course has been removed from this catalog",
  REMOVE_COURSE_CATALOG_FAIL: "Failed to remove course",
  PROFILE_UPDATED_SUCCESS:"Profile updated successfully!"
};

export const IMAGE_FILETYPES = [
  "image",
  "jpg",
  "png",
  "jpeg",
  "image/jpeg",
  "image/png",
];

export const weekdaysList = [
  {
    id: "M",
    value: "Monday",
    label: "M",
    active: false,
  },
  {
    id: "T",
    value: "Tuesday",
    label: "T",
    active: false,
  },
  {
    id: "W",
    value: "Wednesday",
    label: "W",
    active: false,
  },
  {
    id: "TH",
    value: "Thursday",
    label: "TH",
    active: false,
  },
  {
    id: "F",
    value: "Friday",
    label: "F",
    active: false,
  },
  {
    id: "S",
    value: "Saturday",
    label: "S",
    active: false,
  },
  {
    id: "SU",
    value: "Sunday",
    label: "SU",
    active: false,
  },
];

export interface IDropdownMenuItemProps {
  id: any;
  label: string;
  value: any;
}

export interface IInputConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  label: string;
  placeHolderText?: string;
  menuItems?: any;
  fieldType: string;
  isDisabled?: boolean;
  fieldError?: boolean;
  helperText?: string;
}

export interface ICustomTableProps {
  id: string;
  label: string;
  type: string;
  columnId: number | string;
  isSortingColumn?: boolean;
  sortByOrder?: string;
  width?: string;
  maxWidth?: string;
  actionButton?: any;
  icon?: any;
  isSelected?: boolean;
}

export interface ITableActionBtnConfigProps {
  action: (value?: any) => void;
  label: string;
  isDisabled?: boolean;
}

export interface IStepperStepProps {
  id: any;
  label: string;
  optional?: boolean;
}

export const status: Array<{ label: string; value: string; id: string }> = [
  {
    label: "Draft",
    value: "1",
    id: "draft",
  },
  {
    label: "Active",
    value: "0",
    id: "active",
  },
  {
    label: "Archived",
    value: "2",
    id: "archived",
  },
];

export const defaultQuestionOptions: Array<any> = [
  {
    id: 1,
    optionLetter: "A",
    label: "Option A",
    value: "A",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
  {
    id: 2,
    optionLetter: "B",
    label: "Option B",
    value: "B",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
  {
    id: 3,
    optionLetter: "C",
    label: "Option C",
    value: "C",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
  {
    id: 4,
    optionLetter: "D",
    label: "Option D",
    value: "D",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
];

export const recentActivityTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: "recent_activity_icon_text",
  },
  {
    id: "Type",
    columnId: 2,
    label: "Type",
    type: "text",
  },
  {
    id: "Time_Spent",
    columnId: 3,
    label: "Time Spent",
    type: "text",
  },
  {
    id: "lastActivity",
    columnId: 43,
    label: "Last Activity",
    type: "duedate",
  },
];

export const pendingActivitiesColumn = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: "pending_activity_icon_text",
  },
  {
    id: "Type",
    columnId: 2,
    label: "Type",
    type: "text",
  },
  {
    id: "Assigned_On",
    columnId: 3,
    label: "Assigned On",
    type: "duedate",
  },
  {
    id: "DueDate",
    columnId: 2,
    label: "Due Date",
    type: "duedate",
  },
  {
    id: "Due_Days",
    columnId: 2,
    label: "Due Days",
    type: "duedate",
  },
];

export const gradedActivitiesColumn = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: "stud_progress_icon",
  },
  {
    id: "Grading_Component",
    columnId: 2,
    label: "Grading Component",
    type: "text",
  },
  {
    id: "Graded_On",
    columnId: 3,
    label: "Graded On",
    type: "duedate",
  },
  {
    id: "Points",
    columnId: 4,
    label: "Points",
    type: "duedate",
  },
  {
    id: "Grade",
    columnId: 5,
    label: " Grade",
    type: "duedate",
  },
  {
    id: "Average",
    columnId: 6,
    label: "Average",
    type: "duedate",
  },
];
