// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const ntc = require("ntcjs");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm:any;
  open:boolean;
  details?:any
  eventTime?:string;
  eventTitle?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  searchEditStudent:any;
  editEndDate: any,
  colorChange: any;
  validationEditErrors: {
    searchEditStudent: string;
    colorEditChange: string;
    Editdescription: string;
    eventEditTime: string;
    selectEditEndDate:string;
  };
  isvalidationEditErrors: {
    searchEditStudent: false;
    colorEditChange: false;
    Editdescription: false;
    eventEditTime: false;
    selectEditEndDate:false;
  };
  colorEditChange:any;
  selectedColorName:string
  eventEditTime:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class DeleteEditEventCommanPopUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      searchEditStudent:'',
      editEndDate: new Date(),
      colorChange: 'red',
      validationEditErrors:{
        searchEditStudent: "",
        colorEditChange:"",
        Editdescription: "",
        eventEditTime: "",
        selectEditEndDate:""
      },
      isvalidationEditErrors:{
        searchEditStudent:false,
        colorEditChange:false,
        Editdescription: false,
        eventEditTime: false,
        selectEditEndDate:false
      },
      colorEditChange:"",
      selectedColorName:"",
      eventEditTime:this.getCurrentTime()
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.details !== this.props.details){
      const { details } = this.props; 
      const n_match = ntc.name(details.textColor);
     
    this.setState({
      searchEditStudent: details.title || '',
      editEndDate: new Date(details.date) || new Date(),
      colorEditChange: details.textColor || '',
      selectedColorName:n_match[1],
      eventEditTime: this.convertTo24HourFormat(details.time) || '',
    });
  }
  }
  convertTo24HourFormat = (timeString: string) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = modifier === 'AM' ? '00' : '12'; 
    } else if (modifier === 'PM') {
      hours = String(parseInt(hours, 10) + 12); 
    }
  
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };
  getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
   
    return `${hours}:${minutes}`;
  };
  handleEditTimeChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;

    this.setState((prevState): any => {
      const validationEditErrors = { ...prevState.validationEditErrors };
      const isvalidationEditErrors = { ...prevState.isvalidationEditErrors };
  
      if (value) {
        validationEditErrors.eventEditTime = ""; 
        isvalidationEditErrors.eventEditTime = false; 
      }
  
      return {
        eventEditTime: value,
        validationEditErrors,
        isvalidationEditErrors,
      };
    });
  };
  handleEditChange = async (event: any) => {
    const value = event.target.value;

    this.setState((prevState):any => {
      const errors = { ...prevState.validationEditErrors };
      const isErrors = { ...prevState.isvalidationEditErrors };
  
    
      if (value.trim() !== "") {
        errors.searchEditStudent = "";  
        isErrors.searchEditStudent = false; 
      }
  
      return {
        searchEditStudent: value,
        validationEditErrors: errors,
        isvalidationEditErrors: isErrors,
      };
    });
  };
  colorChangeUpdate = async (color: any) => {
    const n_match = ntc.name(color);
    this.setState((prevState):any => {
      const validationEditErrors = { ...prevState.validationEditErrors };
      const isvalidationEditErrors = { ...prevState.isvalidationEditErrors };
      if (color) {
        validationEditErrors.colorEditChange = ""; 
        isvalidationEditErrors.colorEditChange = false; 
      }
  
      return {
        colorEditChange: color,
        selectedColorName: n_match[1],
        validationEditErrors,
        isvalidationEditErrors,
      };
    });
  };
  editEndDate = (date: any) => {
    this.setState({
      editEndDate: date,
    });
  };
  EditChangeColor = async (event: any) => {
    this.setState({
      colorChange: event.target.value,
    });
  };
  openColorPicker = () => {
    let el = document.getElementById("ColorChange");
    if (el) {
      el.click();
    }
  };
  validateEventData = () => {
    const { searchEditStudent, colorEditChange } = this.state;
    const errors:any = {};
    const isErrors:any ={}

    if (!searchEditStudent || searchEditStudent.trim() === "") {
      errors.searchEditStudent = "Event title is required.";
      isErrors.searchEditStudent=true;
    }
    if (!colorEditChange) {
      errors.colorEditChange = "Event color is required.";
      isErrors.colorEditChange=true;
    }
   
    if (Object.keys(errors).length > 0) {
      this.setState({ validationEditErrors: errors, isvalidationEditErrors: isErrors });
      return false; 
    }
  
    return true;

  };
  handleEditEvent = ()=>{
    const isValid = this.validateEventData();

    if (!isValid) {
      return;
    }
    const requestPayload={
      data: {
        title: this.state.searchEditStudent ,
        color: this.state.colorEditChange ,
        start_date_and_time: this.formatDateTimeWithTime(new Date(this.state.editEndDate), this.state.eventEditTime),  
      }
    }
    
    this.props.onConfirm(requestPayload,this.props?.details?.id);
   
    
  }
  formatDateTimeWithTime = (date: { getFullYear: () => any; getMonth: () => number; getDate: () => any; }, time: string) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    
   
    const formattedTime = time || '00:00:00';
    
    return `${year}-${month}-${day} ${formattedTime}`;
  };
  // Customizable Area End
}
// Customizable Area End