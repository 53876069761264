import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { logo } from "./assets";
import { customBrandingDetailsInputConfigMock } from "../assets/themeBlockMock.web";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import { RefObject, createRef } from "react";
import { toast } from "react-toastify";
import { toBase64 } from "../../../components/src/CommonHelper.web";
import { ThemeAttributes } from "./AppearanceManagementMainController.web";

export const brandingList = [
  {
    id: 1,
    name: "Default Branding",
    value: "default_branding",
  },
  {
    id: 2,
    name: "Customized Branding",
    value: "customized_branding",
  },
];

interface IDefaultBrandingProps {
  company: string;
  address: string;
  contact: string;
  email: string;
  website: string;
  logo: string;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  handleSubmitAppearance:(requestBody:any)=>void
  themeData:ThemeAttributes
  rolePermissions?:any 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedBrandingType: any;
  defaultBrandingDetails: IDefaultBrandingProps;
  customizedLogo: any;
  customAddress: string;
  customCompanyName: string;
  customContactNumber: string;
  customWebsite: string;
  customEmail: string;
  customBrandingInputConfigs: Array<IInputConfigProps>;
  isDragging: boolean;
  errors:any;
  isError:any;
  selectedIconName: string;
  selectedIconType: string;
  selectedIconUrl: string;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BrandingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  readonly customLogoInputRef: RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedBrandingType: "default_branding",
      defaultBrandingDetails: {
        company: "Thynker Technologies Inc.",
        address: "68 Singapore Road #02-01, Singapore 049422",
        contact: "+12 3456789",
        email: "info@thynker.tech",
        website: "Thynker.tech",
        logo: logo,
      },
      customizedLogo: "",
      customCompanyName: "",
      customEmail: "",
      customAddress: "",
      customContactNumber: "",
      customWebsite: "",
      customBrandingInputConfigs: customBrandingDetailsInputConfigMock,
      isDragging: false,
      errors: {
        customCompanyName: '',
        customAddress: '',
        customContactNumber: '',
        customEmail: '',
        customWebsite: '',
      },
      isError: {
        customCompanyName: false,
        customAddress: false,
        customContactNumber: false,
        customEmail: false,
        customWebsite: false,
      },
      selectedIconName: "",
      selectedIconType: "",
      selectedIconUrl: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.customLogoInputRef = createRef<HTMLInputElement>();
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetGetData()
    // Customizable Area End
  }


  // Customizable Area Start
  componentDidUpdate(prevProps: { themeData: ThemeAttributes; }) {
  
    if (prevProps.themeData !== this.props.themeData) {
    
      this.handleSetGetData();
    }
  }

  // Customizable Area Start
  handleSetGetData = () => {
    const { customized_branding, customized_data } = this.props.themeData;
    this.setState({selectedBrandingType:customized_branding?"customized_branding":"default_branding"})
    if (customized_branding && customized_data) {
      const configData:any ={
        customAddress: customized_data.address || '',
        customCompanyName: customized_data.company || '',
        customContactNumber: customized_data.contact_number || '',
        customWebsite: customized_data.website || '',
        customEmail: customized_data.email || '',
    }

      
      const updatedConfigs = this.state.customBrandingInputConfigs.map((config) => {
        return {
          ...config,
          value: configData[config.fieldName] || '', 
        };
      });
  
     
      this.setState({
        customBrandingInputConfigs: updatedConfigs,
        customAddress: customized_data?.address ,
        customCompanyName: customized_data?.company ,
        customContactNumber: customized_data?.contact_number ,
        customWebsite: customized_data?.website ,
        customEmail: customized_data?.email ,
        selectedIconName: this.props.themeData?.logo_url.filename,
        selectedIconType: this.props.themeData?.logo_url.content_type,
        selectedIconUrl: this.props.themeData?.logo_url.url,
      });
    } else {
      this.setState({
        customBrandingInputConfigs: customBrandingDetailsInputConfigMock,
        customAddress: "" ,
        customCompanyName: "" ,
        customContactNumber: "" ,
        customWebsite: "",
        customEmail: "" ,
        selectedIconName: "",
        selectedIconType:"",
        selectedIconUrl:"",
      });
    }
  }
  
  // Customizable Area End

  // Customizable Area Start
  handleChangeBrandingType = (value: string) => {
    this.setState({ selectedBrandingType: value, 
      errors: {
      customCompanyName: '',
      customAddress: '',
      customContactNumber: '',
      customEmail: '',
      customWebsite: '',
    },
    isError: {
      customCompanyName: false,
      customAddress: false,
      customContactNumber: false,
      customEmail: false,
      customWebsite: false,
    }, 
  });
  };
  handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
  
    this.setState((prevState) => {
      const updatedInputConfig = prevState.customBrandingInputConfigs.map((item) => {
        if (item.name === name) {
          return { ...item, value }; 
        }
        return item;
      });
  
      return {
        ...prevState,
        [name]: value, 
        isError: {
          ...prevState.isError,
          [name]: false, 
        },
        errors: {
          ...prevState.errors,
          [name]: '', 
        },
        customBrandingInputConfigs: updatedInputConfig, 
      };
    });
  };
  
  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isDragging: true })
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  handleDragLeave = () => {
    this.setState({ isDragging: false })
  };

  handleSetFile = (file: any) => {
    if (file.type.includes('image')) {
      this.setState({ customizedLogo: file,
        selectedIconName: file?.name || "",
        selectedIconType: file?.type || "",
        isError:{...this.state.isError,customizedLogo:false} ,
        errors:{...this.state.errors,customizedLogo:""}
      });
    } else {
      toast.error('Invalid file type. Only png, jpg files are allowed.');
    }
  }

  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isDragging: false })
    const file = e.dataTransfer.files[0];
    this.handleSetFile(file);
  };

  handleOpenFileBrowse = () => {
    if (this.customLogoInputRef.current) {
      this.customLogoInputRef.current.click();
    }
  };

  handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files && e?.target?.files[0];
    this.handleSetFile(file);
  };
  validateRequiredFields = () => {
    const { 
      customizedLogo, 
      customCompanyName, 
      customEmail, 
      selectedIconUrl
    } = this.state;
  
    const errors: Record<string, string> = {};
    const isError: Record<string, boolean> = {};
  
    if (!customCompanyName) {
      errors.customCompanyName = 'Company Name is required';
      isError.customCompanyName = true;
    } else {
      isError.customCompanyName = false;
    }
  

    if (!customEmail) {
      errors.customEmail = 'Email is required';
      isError.customEmail = true;
    } else {
      isError.customEmail = false;
    }
  
    if (!selectedIconUrl && !customizedLogo) {
      errors.customizedLogo = 'Logo is required';
      isError.customizedLogo = true;
    } else {
      isError.customizedLogo = false;
    }
  
    this.setState({
      errors,
      isError,
    });
  
   
    return Object.values(isError).every(value => !value);
  };

  handleSaveDetails = async () => {
   
    const { customizedLogo,
    customCompanyName,
    customEmail,
    customAddress,
    customContactNumber,
    selectedBrandingType,
    defaultBrandingDetails,
    selectedIconName,
    selectedIconType,
    customWebsite} = this.state

    const requestType = selectedBrandingType === "customized_branding" ? true : false

    if(requestType){
      const isValid = this.validateRequiredFields();
      if(isValid){
        let base64Url: any = "";
        if (customizedLogo) {
          base64Url = await toBase64(customizedLogo);
        }
        const requestBodyCustomized:any ={
          type: "Branding",
           theme:
           {
               customized_branding:true,
               customized_data:
                   {
                       company:customCompanyName,
                       address:customAddress,
                       contact_number:customContactNumber,
                       email:customEmail,
                       website:customWebsite
                    },
                    content_type:selectedIconType,
                    filename:selectedIconName,
                   
           } 
         }
         if (base64Url) {
          requestBodyCustomized.theme.icon=base64Url
        }
        this.props.handleSubmitAppearance(requestBodyCustomized)
      }
    }
    else{
      const requestBodyDefault ={
        type: "Branding",
        theme:
        {
            customized_branding:false,
            customized_data:
                {
                    company:defaultBrandingDetails.company,
                    address:defaultBrandingDetails.address,
                    contact_number:defaultBrandingDetails.contact,
                    email:defaultBrandingDetails.email,
                    website:defaultBrandingDetails.website
                 },
                content_type:selectedIconType,
                filename:selectedIconName,
                icon:""
        } 
      }
      this.setState({customizedLogo:""})
      this.props.handleSubmitAppearance(requestBodyDefault)
    }
  }
  // Customizable Area End
}
