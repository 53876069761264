import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,

  // Customizable Area Start
  Paper,
  Tabs, Tab, FormLabel, Grid, FormControlLabel, Checkbox, Menu, MenuItem, IconButton
  // Customizable Area End

} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Customizable Area Start


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

interface ResponseGetData {
  attributes: {
    id: number;
    title: string;
    description: string;
    created_at: string;
    due_date: string;
    status: string;
  }
}
interface Data {
  id: number;
  title: string;
  description: string;
  created_at: string;
  due_date: number;
  status: string;
}
interface MyEventAnchor {
  currentTarget: HTMLElement;
}
// Customizable Area End

import SmartcategorisationController, {
  Props,
} from "./SmartcategorisationController";
import { TaskList } from "./Types";

export default class Smartcategorisation extends SmartcategorisationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {

    const isDisabled = this.state.activeTab === 'today' || this.state.activeTab === "tomorrow" || this.state.activeTab === "past_dues";
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Container maxWidth='md' >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px",

            }}
          >
            <Box style={webStyle.boxTabs} mt={2}>
              <Tabs data-test-Id='Tab' value={this.state.activeTab} onChange={this.handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'black',
                  },
                }}
              >
                <Tab id="yesterday" style={webStyle.tabPast} value="past_dues" label="Yesterday" />
                <Tab id='today' style={webStyle.tabToday} value="today" label="Today" />
                <Tab id="tomorrow" style={webStyle.tabTomorrow} value="tomorrow" label="Tomorrow" />
                <Tab data-test-Id="future_dues" style={webStyle.tabFuture} value="future_dues" label="Custom" />
              </Tabs>
            </Box>
            <Grid container style={webStyle.gridContainer}>
              <Grid item xs={2}>
                <FormLabel component="legend" >
                  Start date*
                </FormLabel>
                <input
                  type="date"
                  id="startDate"
                  style={webStyle.inputDate}
                  value={this.state.startDate}
                  max={this.state.endDate}
                  disabled={isDisabled}
                  onChange={this.handleDateChange}
                />
              </Grid>
              <Grid item xs={2}  >
                <FormLabel component="legend"  >
                  End date*
                </FormLabel>
                <input
                  style={webStyle.inputDate}
                  type="date"
                  id="endDate"
                  disabled={this.state.activeTab === 'today' || this.state.activeTab === "tomorrow" || this.state.activeTab === "past_dues"}
                  value={this.state.endDate}
                  min={this.state.startDate}
                  onChange={this.handleEndChange}
                />
              </Grid>
              <Grid item xs={4} style={{ marginTop: '10px' }}>
                <Box display='flex'>
                  <FormControlLabel
                    style={{ display: "table" }}
                    data-test-id='CheckBox'
                    control={
                      <div style={{ display: "table-cell" }}>
                        <Checkbox
                          checked={this.state.completed}
                          onChange={this.toggleSwitch}
                          name="completed"
                        />
                      </div>
                    }
                    label={<div className="disclaimerandnotes">View completed tasks</div>}
                  />
                </Box>
              </Grid>
              <Grid item xs={2} >
                <Box >
                  <Button variant="contained"
                   data-test-id={`create`}
                    onClick={() => { this.navigateToCreateTaskScreen('create') }}
                    style={{ backgroundColor: 'blue' }}> +
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box>
              {
                this.state.responseData.map((item: TaskList, index: number) => {
                  
                  return (
                    <React.Fragment key={index}>
                      <Box display='flex' justifyContent='center'
                      >
                        <Box mb={2} width={409} component={Paper} p={2} >
                          <Box mb={1} width={400} display='flex' justifyContent='space-between' >
                            <div style={{
                              backgroundColor: item.attributes.status == 'complete' ? 'green' : 'grey',
                              borderRadius: '50%',
                              width: '30px',
                              height: '30px',
                            }}></div>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              data-test-id={`MapMenu-${index}`}
                              aria-haspopup="true"
                              onClick={(event:MyEventAnchor)=>{this.handleanchorEl(event,item)}}
                             
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              data-test-id={`MapClose-${index}`} 
                              onClose={this.handleMenuClose}
                              keepMounted
                            >
                              <MenuItem data-test-id={`MapEdit-${index}`} onClick={()=>{this.navigateToCreateTaskScreen('edit',this.state.taskSelected)}}>Edit</MenuItem>
                              <MenuItem data-test-id={`MapDel-${index}`} onClick={() => { this.performAction('delete',this.state.taskSelected)}} >{this.state.todayDate <= item.attributes.due_date ? 'Delete':''}</MenuItem>
                              {this.state.taskSelected.status !== 'complete' && this.state.todayDate <= item.attributes.due_date ?
                              <MenuItem data-test-id={`MapCom-${index}`} 
                              onClick={() => { this.performAction('complete',this.state.taskSelected)}}
                              >Complete
                              </MenuItem>:null}
                            </Menu>
                          </Box>
                          <Box data-test-id={`BoxMap-${index}`}
                            onClick={() => { this.openModal(item.attributes) }}>
                            <Typography > Title : <span style={{ fontWeight: 'bold' }}>{item.attributes.title}</span>  </Typography>
                            <Typography id='desc'> description : {item.attributes.description.substring(0, 17)} </Typography>
                            <Typography > created at : {item.attributes.created_at}</Typography>
                            <Typography > due date : {item.attributes.due_date}</Typography>
                            {item.attributes.status === 'complete' ?  <Typography > completion date : {item.attributes.created_at}</Typography> :''}
                 
                          </Box>
                        </Box>
                      </Box>
                    </React.Fragment>
                  )
                })
              }
            </Box>
            {this.state.responseData.length > 0 ? (
              <>
              </>
            ) : (
              <>
                <p style={{ color: 'red' }}>{this.state.errorMsg}</p>
              </>
            )}
          </Box>
        </Container>
        <React.Fragment>
          <Dialog
            fullWidth
            maxWidth="sm"
            id="two"
            PaperProps={{
              style: {
                height: "261px",
                width: '400px',
                borderRadius: '5%',
                padding: '3px',
              }
            }}
            open={this.state.showModal}
            onClose={this.closeModal}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogContent style={{ overflow: "hidden", padding: '0px', margin: '0px' }}>

              <Box display='flex' justifyContent='center'
              >
                <Box p={2} >
                  <Box display='flex' justifyContent='space-between'>
                    <div style={{
                      backgroundColor: this.state.taskSelected.status == 'complete' ? 'green' : 'grey',
                      borderRadius: '50%',
                      width: '30px',
                      height: '30px',
                    }}></div>
                  </Box>
                  <Typography > title : <span style={{ fontWeight: 'bold' }}>{this.state.taskSelected.title}</span>  </Typography>
                  <Typography > description : {this.state.taskSelected.description} </Typography>
                  <Typography > created at : {this.state.taskSelected.created_at}</Typography>
                  <Typography > due date : {this.state.taskSelected.due_date}</Typography>
                 {this.state.taskSelected.status === 'complete' ?  <Typography > completion date : {this.state.taskSelected.created_at}</Typography> : null} 
                  <Box display='flex' justifyContent='end'>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  boxTabs: {
    display: 'flex',
    gap: '10px'
  },
  tabToday: {
    marginLeft: '20px',
    backgroundColor: 'pink'
  },
  tabTomorrow: {
    marginLeft: '20px',
    backgroundColor: 'yellow'
  },
  tabFuture: {
    marginLeft: '20px',
    backgroundColor: '#89d589'
  },
  tabPast: {
    marginLeft: '20px',
    backgroundColor: 'darkorange'
  },
  inputDate: {
    height: '31px'
  },
  gridContainer: {
    marginTop: '39px',
    marginBottom: '39px',
    display: "flex",
    justifyContent: "center",
    marginLeft: "82px"
  },
};
export { Smartcategorisation }
// Customizable Area End
