// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import ObservationController, { Props } from "./ObservationController.web";
import { Delete, Edit } from "@material-ui/icons";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    customLoader: {
      zIndex: 100,
      display: "block",
      top: 0,
      right: 0,
      position: "absolute",
      left: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
    },
    addCourseBox: {
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "100%",
        overflowY: "hidden",
        // minHeight: "380px",
        height:"auto",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
      },
      overflowX: "hidden",
      // "& .MuiPaper-rounded": {
      //   borderRadius: "8px",
      // },
    },
    requiredNotation: {
      color: "red",
    },
    circularContainer: {
      top: "40%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      left: "50%",
    },
  });
  const schoolYearTableTheme = createTheme({
    overrides: {
      MuiTableCell: {
        body: {
          paddingTop: "16px !important",
          paddingBottom: "16px !important",
          paddingRight: "16px !important",
          paddingLeft: "16px !important",
        },
        head: {
          padding: "16px !important",
        },
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class Observation extends ObservationController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, onCancel, behaviourDetails,handleDeleteBehaviourSkills } = this.props;
    const {
      loading,
    } = this.state;

    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addCourseBox}`}
          aria-labelledby="observation-modal-title"
          open={open}
          scroll={"body"}
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box
            className={`${classes.bgColor}`}
            padding={"30px"}
            data-test-id="observationmodalBox"
          >
            <Grid direction="row" container>
              <Grid
                item
                xs={12}
                className={`${classes.headerGrid}`}
                data-test-id="observationmodalGrid"
              >
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="observationtitleCourse"
                >
                  {behaviourDetails.attributes.obeservation_title}
                </Typography>
                <Button
                  onClick={onCancel}
                  className={classes.textPrimaryColor}
                  id="observation-close-modal-icon-btn"
                  style={{ float: "right" }}
                >
                  <CloseIcon
                    className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                    id="add-new-course-close-modal-icon"
                  />
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ marginTop: "24px", padding: "0px",border: "1px solid #c8c5c5",borderRadius:"8px", overflow: "hidden" }}
              data-test-id="observationcontent"
             
            >
                {behaviourDetails.attributes.skills.map((skill) => (
                  <ThemeProvider theme={schoolYearTableTheme}>
                    <TableContainer  className={`${classes.bgColor}`}component={Paper} style={{ width: "100%"}}>
                  <Table aria-label="skill table" key={skill.id}>
                    <TableHead>
                      <TableRow className={`${classes.gradeTableHeadingRow}`}>
                        <TableCell colSpan={2}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography className={`${classes.textCommonColor} ${classes.fontText16}`}
                                  component={"span"}>
                              {skill.skill_title}
                            </Typography>
                            <Box>
                              <IconButton onClick={()=>handleDeleteBehaviourSkills(skill.id,behaviourDetails.attributes.id)} aria-label="delete">
                                <Delete color="error" />
                              </IconButton>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {skill.sub_skills.map((subSkill) => (
                        <TableRow key={subSkill.id}>
                          <TableCell   component="span"
                            className={`${classes.fontText15} ${classes.textPrimaryColor}`}>{subSkill.title}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  </TableContainer>
                  </ThemeProvider>
                ))}

             
            </DialogContent>
          </Box>
        </Dialog>

      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(Observation);
// Customizable Area End
