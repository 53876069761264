// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const AudioTopTextBottomIcon: any = (props: any) => {
    return (
        <SvgIcon style={{ width: "45px", height: "70px" }} {...props}>
            <rect x="-1.5" y="0" width="40" height="70" rx="3" strokeWidth="1" fill={props.fill} stroke={props.stroke} />
            <path d="M17.5034 43.3191C17.038 43.3191 16.6603 42.9428 16.6603 42.4791V41.6391H8.22918V42.4791C8.22918 42.9428 7.85147 43.3191 7.38608 43.3191C6.92068 43.3191 6.54297 42.9428 6.54297 42.4791V40.799C6.54297 40.3353 6.92068 39.959 7.38608 39.959H17.5034C17.9688 39.959 18.3465 40.3353 18.3465 40.799V42.4791C18.3465 42.9428 17.9688 43.3191 17.5034 43.3191Z" fill="#D0D0D0" />
            <path d="M12.4447 51.7195C11.9793 51.7195 11.6016 51.3432 11.6016 50.8795V40.799C11.6016 40.3353 11.9793 39.959 12.4447 39.959C12.9101 39.959 13.2878 40.3353 13.2878 40.799V50.8795C13.2878 51.3432 12.9101 51.7195 12.4447 51.7195Z" fill="#D0D0D0" />
            <path d="M14.1316 51.7191H10.7591C10.2937 51.7191 9.91602 51.3428 9.91602 50.8791C9.91602 50.4154 10.2937 50.0391 10.7591 50.0391H14.1316C14.5969 50.0391 14.9747 50.4154 14.9747 50.8791C14.9747 51.3428 14.5969 51.7191 14.1316 51.7191Z" fill="#D0D0D0" />
            <path d="M29.3067 41.6391H22.5619C22.0965 41.6391 21.7188 41.2627 21.7188 40.799C21.7188 40.3353 22.0965 39.959 22.5619 39.959H29.3067C29.7721 39.959 30.1498 40.3353 30.1498 40.799C30.1498 41.2627 29.7721 41.6391 29.3067 41.6391Z" fill="#D0D0D0" />
            <path d="M29.3067 46.6781H22.5619C22.0965 46.6781 21.7188 46.3018 21.7188 45.8381C21.7188 45.3744 22.0965 44.998 22.5619 44.998H29.3067C29.7721 44.998 30.1498 45.3744 30.1498 45.8381C30.1498 46.3018 29.7721 46.6781 29.3067 46.6781Z" fill="#D0D0D0" />
            <path d="M29.3067 51.7191H22.5619C22.0965 51.7191 21.7188 51.3428 21.7188 50.8791C21.7188 50.4154 22.0965 50.0391 22.5619 50.0391H29.3067C29.7721 50.0391 30.1498 50.4154 30.1498 50.8791C30.1498 51.3428 29.7721 51.7191 29.3067 51.7191Z" fill="#D0D0D0" />
            <path d="M29.3069 56.7602H7.38608C6.92068 56.7602 6.54297 56.3838 6.54297 55.9201C6.54297 55.4564 6.92068 55.0801 7.38608 55.0801H29.3069C29.7723 55.0801 30.15 55.4564 30.15 55.9201C30.15 56.3838 29.7723 56.7602 29.3069 56.7602Z" fill="#D0D0D0" />
            <path d="M29.3069 61.7992H7.38608C6.92068 61.7992 6.54297 61.4229 6.54297 60.9592C6.54297 60.4955 6.92068 60.1191 7.38608 60.1191H29.3069C29.7723 60.1191 30.15 60.4955 30.15 60.9592C30.15 61.4229 29.7723 61.7992 29.3069 61.7992Z" fill="#D0D0D0" />
            <path d="M5.81123 15.668C5.31143 15.668 4.90625 16.0731 4.90625 16.573V25.6228C4.90625 26.1226 5.31143 26.5278 5.81123 26.5278C6.31104 26.5278 6.71622 26.1226 6.71622 25.6228V16.573C6.71622 16.0731 6.31104 15.668 5.81123 15.668Z" fill="#D0D0D0" />
            <path d="M9.43038 11.1426C8.93057 11.1426 8.52539 11.5478 8.52539 12.0476V30.1473C8.52539 30.6471 8.93057 31.0522 9.43038 31.0522C9.93018 31.0522 10.3354 30.6471 10.3354 30.1473V12.0476C10.3354 11.5478 9.93018 11.1426 9.43038 11.1426Z" fill="#D0D0D0" />
            <path d="M20.2917 15.668C19.7919 15.668 19.3867 16.0731 19.3867 16.573V25.6228C19.3867 26.1226 19.7919 26.5278 20.2917 26.5278C20.7915 26.5278 21.1967 26.1226 21.1967 25.6228V16.573C21.1967 16.0731 20.7915 15.668 20.2917 15.668Z" fill="#D0D0D0" />
            <path d="M16.6706 11.1426C16.1708 11.1426 15.7656 11.5478 15.7656 12.0476V30.1473C15.7656 30.6471 16.1708 31.0522 16.6706 31.0522C17.1704 31.0522 17.5756 30.6471 17.5756 30.1473V12.0476C17.5756 11.5478 17.1704 11.1426 16.6706 11.1426Z" fill="#D0D0D0" />
            <path d="M13.0515 7.52344C12.5517 7.52344 12.1465 7.92861 12.1465 8.42842V33.768C12.1465 34.2678 12.5517 34.673 13.0515 34.673C13.5513 34.673 13.9565 34.2678 13.9565 33.768V8.42842C13.9565 7.92861 13.5513 7.52344 13.0515 7.52344Z" fill="#D0D0D0" />
            <path d="M31.1511 15.668C30.6513 15.668 30.2461 16.0731 30.2461 16.573V25.6228C30.2461 26.1226 30.6513 26.5278 31.1511 26.5278C31.6509 26.5278 32.0561 26.1226 32.0561 25.6228V16.573C32.0561 16.0731 31.6509 15.668 31.1511 15.668Z" fill="#D0D0D0" />
            <path d="M27.53 11.1426C27.0302 11.1426 26.625 11.5478 26.625 12.0476V30.1473C26.625 30.6471 27.0302 31.0522 27.53 31.0522C28.0298 31.0522 28.435 30.6471 28.435 30.1473V12.0476C28.435 11.5478 28.0298 11.1426 27.53 11.1426Z" fill="#D0D0D0" />
            <path d="M23.9108 7.52344C23.411 7.52344 23.0059 7.92861 23.0059 8.42842V33.768C23.0059 34.2678 23.411 34.673 23.9108 34.673C24.4107 34.673 24.8158 34.2678 24.8158 33.768V8.42842C24.8158 7.92861 24.4107 7.52344 23.9108 7.52344Z" fill="#D0D0D0" />
        </SvgIcon>
    );
}

export default AudioTopTextBottomIcon;
// Customizable Area End