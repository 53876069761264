// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core';
import SubmitScoreDialogController, {
    Props
} from "./SubmitScoreDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import { exclamationIcon } from './assets';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        quizViewAnswerConfirmDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiBox-root": {
                width: "460px"
            }
        },
    })

// Customizable Area End

// Customizable Area Start
export class SubmitScoreDialog extends SubmitScoreDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, maxScore, receivedScore, studentName, activityName } = this.props;
        return (
            <Dialog className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open}>
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Box id="quiz-view-answer-confirm-dialog-title">
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <img src={exclamationIcon} alt="success icon" />
                        </Box>
                    </Box>
                    <DialogContent style={{ padding: "0px" }}>
                        <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}>
                                Score will be submitted
                            </Typography>
                        </Box>
                        <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                Are you sure want to submit <b>{studentName}'s</b> <br />
                                score <b>{receivedScore}/{maxScore}</b> for <b>{activityName}</b>?
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gridGap={"20px"} justifyContent={"space-between"}>
                            <CustomButton btnStyle={cancelButton} btnText='Cancel' buttonId='quiz-view-answer-cancel-btn' buttonVariant={"outlined"} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
                            <CustomButton btnStyle={confirmButton} btnText='Yes' buttonId='quiz-view-answer-cancel-btn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={this.props.onConfirm} />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const confirmButton = {
    height: "50px",
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
} as const;

const cancelButton = {
    padding: "5px",
    width: "100%",
    height: "50px",
    borderRadius: "10px",
    border: "1px solid",
    fontWeight: 600,
    fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(SubmitScoreDialog);
// Customizable Area End
