export const customThemeStyles = (theme: any) => ({
    textCommonColor: {
        /* grey in both mode */
        color: `${theme?.customText?.commonColor} !important`
    },
    textLightGreyColor: {
        /* grey in both mode */
        color: `${theme?.customText?.lightGreyColor} !important`
    },
    textBlackColor: {
        /* black in both mode */
        color: "#090909"
    },
    textClickColors: {
        /* color white in dark mode , black in light mode */
        color: `${theme?.customText?.textClickColor} !important`
    },
    textPrimaryColor: {
        /* color white in dark mode , black in light mode */
        color: `${theme?.customText?.primaryColor} !important`
    },
    textSecondaryColor: {
        /* color grey in dark mode , black in light mode */
        color: `${theme?.customText?.secondaryColor} !important`
    },
    highlightBgColor: {
        "& ::selection": {
            backgroundColor: theme?.customBackground?.highlightBgColor,
        }
    },
    bgColor: {
        /* background color black in dark mode , white in light mode */
        backgroundColor: `${theme?.customBackground?.bgColor} !important`
    },
    secondaryBgColor: {
        /* background color #333436 in dark mode , #f1f1f1  in light mode */
        backgroundColor: theme?.customBackground?.secondaryBgColor,
    },
    cardBgColor: {
        /* background color #EDEDED in light mode , #090909  in dark mode */
        backgroundColor: theme?.customBackground?.cardBgColor,
    },
    cardBgColor02: {
        /* background color #EDEDED in light mode , #090909  in dark mode */
        backgroundColor: theme?.customBackground?.cardBgColor02,
    },
    gradeBgGreenColor: {
        /* background color #effbf9 in light mode , #255e54  in dark mode */
        backgroundColor: theme?.customBackground?.gradeBgGreenColor,
    },
    audioPlayerCardBgColor: {
        /* background color light blue in light mode , #090909  in dark mode */
        backgroundColor: theme?.customBackground?.audioPlayerCardBgColor,
    },
    secondaryInfoBgColor: {
        /* background color #e2e4e933 in light mode , #161719  in dark mode */
        backgroundColor: theme?.customBackground?.secondaryInfoBgColor,
    },
    primaryBorder: {
        border: "1px solid #888888 !important"
    },
    childMainWrapperPadding: {
        padding: "20px 0px 10px 35px",
        [theme.breakpoints.down('xs')]: {
            padding: "20px 10px 10px 30px",
        },
    },
    disabledFieldBgColor: {
        backgroundColor: "f3f3f3"
    },
    primaryColor2: {
        /*black */
        color: "#090909"
    },
    primaryColor3: {
        color: "#FDD72F"
    },
    primaryColor4: {
        /*green */
        color: "#01C996"
    },
    primaryColor5: {
        /*deep blue */
        color: "#0D46BC"
    },
    primaryColor6: {
        /*red */
        color: "#FF0000"
    },
    primaryColor7: {
        /*light blue */
        color: "#019AFC"
    },
    primaryColor8: {
        /*deep yellow */
        color: "#FFA500"
    },
    primaryColor9: {
        /*ligt buish green */
        color: "#5FD9C4"
    },
    primaryColor10: {
        /*brownish yellow */
        color: "#DF9D33"
    },
    primaryColor11: {
        /*bright yellow */
        color: "#FBD82C"
    },
    primaryColor12: {
        /*light grey */
        color: "#D6D6D6"
    },
    primaryColor13: {
        /*orange yellow */
        color: "#FC9301"
    },
    primaryColor14: {
        /*sky blue */
        color: "#009DFF"
    },
    primaryColor15: {
        /*dark red */
        color: "#CF0000"
    },
    primaryColor16: {
        /* light blue */
        color: "#2C98EE",
    },
    primaryColor17: {
        color: "#FBDD49",
    },
    primaryColor18: {
        color: "#0947BF",
    },
    primaryColor19: {
        color: "#ED1111",
    },
    fontText34: {
        fontSize: "34px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "28px",
        },
    },
    fontText32: {
        fontSize: "32px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "26px",
        },
    },
    fontText30: {
        fontSize: "30px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "24px",
        },
    },
    fontText28: {
        fontSize: "28px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "22px",
        },
    },
    fontText26: {
        fontSize: "26px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "22px",
        },
    },
    fontText24: {
        fontSize: "24px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "22px",
        },
    },
    fontText22: {
        fontSize: "22px",
        [theme?.breakpoints?.down('xs')]: {
            fontSize: "20px",
        },
    },
    fontText20: {
        fontSize: "20px"
    },
    fontText18: {
        fontSize: "18px"
    },
    fontText16: {
        fontSize: "16px"
    },
    fontText14: {
        fontSize: "14px"
    },
    fontText13: {
        fontSize: "13px"
    },
    fontText12: {
        fontSize: "12px"
    },
    fontBold600: {
        fontWeight: 600
    },
    fontBold500: {
        fontWeight: 500
    },
    cursorPointer: {
        cursor: "pointer"
    },
    flexDisplay: {
        display: "flex"
    },
    alignCenter: {
        alignItems: "center"
    },
    alignEnd: {
        alignItems: "flex-end"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyEnd: {
        justifyContent: "flex-end"
    },
    modalHeadingText: {
        color: "#090909",
        fontSize: 24,
        fontWeight: 600,
    },
    customTextEditor: {
        padding: "0 0 !important",
        overflow: "auto",
        borderRadius: "10px",
        "& .cke": {
            background: "#fff",
            "& .cke_top": {
                borderBottom: "none",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                "& .cke_toolbar_break": {
                    display: "none"
                }
            },
            "& .cke_inner": {
                background: `${theme?.customBackground?.bgColor}`,
            },
            "& .cke_bottom": {
                display: "none"
            },
            "& .cke_contents": {
                background: `${theme?.customBackground?.bgColor}`,
                "& .cke_wysiwyg_frame": {
                    background: `${theme?.customBackground?.bgColor}`,
                    "& body": {
                        color: `${theme?.customText?.primaryColor}`,
                        background: "inherit"
                    }
                },
                "& .cke_editable": {
                    color: `${theme?.customText?.primaryColor}`,
                    background: "inherit"
                }
            },
        },
        "& .cke_chrome": {
            borderRadius: "0px",
            border: "none",
        }
    },
    outlinedButton: {
        color: theme?.customButton?.customizedColor,
        backgroundColor: "inherit",
        border: `1px solid ${theme?.customButton?.customizedColor}`,
        "&:hover": {
            backgroundColor: "inherit",
        },
        "&.MuiButton-contained.Mui-disabled": {
        color: theme?.customButton?.customizedColor,
        backgroundColor: "inherit",
        border: `1px solid ${theme?.customButton?.customizedColor}`,
        }
    },
    customizedColor:{
        color: theme?.customButton?.customizedColor,
    },
    containedButton: {
        color: "ffffff",
        backgroundColor: theme?.customButton?.customizedColor,
        border: "none",
        "&:hover": {
            backgroundColor: theme?.customButton?.customizedColor,
        },
        "&.MuiButton-contained.Mui-disabled": {
        color: "ffffff",
        backgroundColor: theme?.customButton?.customizedColor, 
        border: "none",
        }
    },
    textButton: {
        color: theme?.customButton?.primaryColor,
        "&:hover": {
            color: theme?.customButton?.primaryColor,
        }
    },
    customTabs: {
        "&.MuiTabs-root": {
            "& .MuiTabScrollButton-root": {
                color: theme?.customButton?.primaryColor
            }
        }
    },
    customTabButton: {
        backgroundColor: "transparent",
        padding: "10px 4px",
        display: "inline-block",
        textDecoration: "none",
        opacity: 1,
        "&.Mui-selected": {
            color: `${theme?.customButton?.primaryColor} !important`,
            outline: "none",
            backgroundColor: "transparent",
            borderBottom: `1px solid ${theme?.customButton?.primaryColor}`,
            fontWeight: 600,
        },
        "&.MuiTab-root": {
            minHeight: "60px",
        },
    },
    customTabBorderButton: {
        "&.Mui-selected": {
            border: `1px solid ${theme?.customButton?.primaryColor}`,
        },
    },    
    customFontFamily: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    },
    helperText: {
        "&.MuiFormHelperText-root":{
            color: "red"
        }
    },
    paginationRoot: {
        display: "flex",
        justifyContent: "flex-end",
        "& > *": {
          marginTop: theme.spacing(3),
        },
        "& .Mui-selected": {
          color: "#FFFFFF",
          backgroundColor: "#FFD92E !important",
        },
        "& .MuiPaginationItem-root": {
          borderRadius: "6px",
        },
        "& .MuiPaginationItem-icon": {
          color: "#0D46BC",
        },
        "& .MuiPaginationItem-page": {
          backgroundColor: "#FFFFFF",
        },
      },
})