// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  completedQuestion:any;
  index:number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentAnswer:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCompletedQuizController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      studentAnswer:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
   await super.componentDidMount();
    // Customizable Area Start
    this.handleSetStudentStudentAnswer()
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleCheckIsCorrectQuizOption = (studentAnswerId :  any , answer : any) => {
    return (answer?.correct_answer && answer?.id === studentAnswerId) ;
  }

  handleReturnCorrectAnswerText = () => {
    const { completedQuestion } = this.props;
    let correctAnswer = "";
    completedQuestion?.answers?.find((option : any , index : number) => {
      const optionSequence = String.fromCharCode(65 + index);
      if(option?.correct_answer){
        correctAnswer = `${optionSequence}. ${option?.option}`;
      }
    });
    return correctAnswer ;
  }

  handleCheckIsStudentAnswerCorrect = () => {
    const { completedQuestion } = this.props;
    const correctAnswer = completedQuestion?.answers?.find((option:any) => option?.correct_answer);
    return correctAnswer?.id === completedQuestion?.studentAnswerId;
  }

  handleSetStudentStudentAnswer = () => {
    const { completedQuestion } = this.props;
    completedQuestion?.answers?.find((option : any , index : number) => {
      const optionSequence = String.fromCharCode(65 + index);
      if(option?.id === completedQuestion?.studentAnswerId){
        this.setState({studentAnswer : `${optionSequence}. ${option?.option}`})
      }
    });
  }

  // Customizable Area End
}
// Customizable Area End