// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteEditEventCommanPopUpController, {
  Props,
} from "./DeleteEditEventCommanPopUpController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "490px",
      },
      paperWidthSm: {
        maxWidth: "490px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class CreateEventSuccess extends DeleteEditEventCommanPopUpController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor}`}
          aria-labelledby="createTime"
          open={open}
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box display={"flex"} justifyContent={"center"} id="create-id">
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={SuccessIcon} alt="SuccessIcon" />
              </Box>
              <Box width={"6%"}>
                <IconButton
                  data-test-id="success-event"
                  aria-label="more"
                  onClick={this.props.onCancel}
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent
              style={{ padding: "0px" }}
              data-test-id="content-craeate"
            >
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                >
                  Event Created Successfully
                </Typography>
              </Box>
              <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                <Typography
                  data-test-id="text-success"
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                >
                  Event titled <b>{this.props.eventTitle}</b> created
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                >
                  successfully for <b>{this.props.eventTime ? new Date(this.props.eventTime).toLocaleDateString('en-US', {
                  month: 'short',  
                  day: '2-digit',  
                  year: 'numeric'  
                }) : 'No Event Time'}</b>
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                data-test-id="btn-box"
              >
                <CustomButton
                  buttonId="success-btn-id"
                  btnStyle={createEventbtn}
                  btnText="Okay"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onConfirm}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const createEventbtn = {
  padding: "5px",
  color: "#0D46BC",
  width: "100%",
  backgroundColor: "inherit",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #0D46BC",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CreateEventSuccess);
// Customizable Area End
