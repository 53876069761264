import React from "react";

import {
    Box,
    Typography,
    Grid,
    Tabs,
    Tab,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { TabPanel, a11yProps } from "../../../components/src/CustomTabPanel.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area Start
import CoursesTab from "./CoursesTab.web";
import CoursesJourneyTab from "./CoursesJourneyTab.web";
import ParticipantsTab from "./parentteacher/ParticipantsTab.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";

const styles = (theme: Theme) =>
    createStyles({
        mainContainerPadding: {
            padding: "40px",
            height: "auto !important",
        },
        pageName: {
            marginBottom: "20px",
            marginTop: "16px",
        },
        courseDetailMainContainer: {
            maxHeight: "700vh",
            overflowX: "auto",
            overflowY: "hidden",
            borderRadius: "10px",
        },
        tabs: {
            "& div.MuiTabs-scroller": {
                borderBottom: "1px solid #979797",
                "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                    borderBottom: "none",
                }
            }
        },
        tabPanel: {
            padding: "0px",
            "& #scrollable-auto-tabpanel-0": {
                minHeight: "90vh",
            },
            "& #scrollable-auto-tabpanel-1": {
                minHeight: "90vh",
            },
            "& #scrollable-auto-tabpanel-2": {
                minHeight: "90vh",
            }
        },
        tabButton: {
            padding: "20px 4px",
            backgroundColor: "transparent",
            textAlign: "center",
            textTransform: "capitalize",
            textDecoration: "none",
            fontSize: "20px",
            display: "inline-block",
            width: "100%",
            opacity: 1,
            fontWeight: 400,
            "&.Mui-selected": {
                color: "#0D46BC !important",
                backgroundColor: "transparent",
                borderBottom: "1px solid #0D46BC",
                outline: "none",
                fontWeight: 500,
            },
            "&.MuiTab-root": {
                minHeight: "48px"
            }
        },
    })
// Customizable Area End

import CoursesPageController, {
    Props,
    courseDetailTabs
} from "./CoursesPageController";

export class CoursesPage extends CoursesPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { selectedTabIndex, selectedUserDropdownValue, userDropdownItems } = this.state;
        const { classes, navigation } = this.props;
        return (
            // Customizable Area Start
            <Box className={`${classes.highlightBgColor} ${classes.mainContainerPadding} ${classes.secondaryBgColor}`}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box marginTop={"1%"}>
                            <Typography component={"span"} className={`${classes.fontText12} ${classes.textCommonColor}`}>
                                Courses
                            </Typography>
                            {
                                selectedTabIndex === 1 ?
                                    <>
                                        <Typography component={"span"} className={`${classes.fontText12} ${classes.textCommonColor}`}>
                                            {`/ Physics`}
                                        </Typography>
                                        <Typography component={"span"} className={`${classes.fontText12} ${classes.textCommonColor}`}>
                                            {`/ Journey`}
                                        </Typography>
                                        <Typography className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
                                            {` / Lesson Title`}
                                        </Typography>
                                    </>
                                    :
                                    selectedTabIndex === 2 ?
                                        <>
                                            <Typography component={"span"} className={`${classes.fontText12} ${classes.textCommonColor}`}>
                                                {`/ Physics`}
                                            </Typography>
                                            <Typography className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
                                                {` / Participants`}
                                            </Typography>
                                        </> : ''
                            }
                        </Box>
                    </Grid>
                    <Grid className={classes.pageName} item xs={12} container alignItems="center">
                        <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}>
                            Courses
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container>
                        <CustomSelectDropdown
                            fieldId="selectUserDropdown"
                            fieldName="selectedUserDropdownValue"
                            renderValue={this.renderUserDropdownValue}
                            fieldValue={selectedUserDropdownValue}
                            fieldMenuItems={userDropdownItems}
                            handleChange={this.handleChangeDropdownValue}
                            fieldError={false}
                            fieldStyles={dropdownStyle}
                            iconComponent={
                                <DropdownIcon
                                    height="24"
                                    width="24"
                                    stroke={"#888888"}
                                    viewBox="0 0 24 24"
                                    fill={"none"}
                                    strokeWidth="1.5"
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid direction="row" className={`${classes.courseDetailMainContainer} ${classes.bgColor}`} container>
                            <Grid item xs={12}>
                                <Tabs variant="fullWidth" scrollButtons="auto" data-testid="student-assessment-tabs" value={selectedTabIndex} className={`${classes.tabs}`} onChange={this.handleChangeAssessmentCategoryTab} aria-label="courses-tabs"
                                    TabIndicatorProps={{
                                        style: {
                                            background: "none"
                                        }
                                    }}
                                >
                                    {courseDetailTabs.map((tabItem: any, index: number) =>
                                        <Tab data-testid={`student-assessment-${tabItem.label}-tab`} key={tabItem.id} label={tabItem.label} wrapped {...a11yProps(index)} className={`${classes.tabButton} ${classes.textPrimaryColor}`} />
                                    )}
                                </Tabs>
                            </Grid>
                            <Grid className={`${classes.tabPanel}`} item xs={12}>
                                <TabPanel index={0} value={selectedTabIndex}>
                                    <CoursesTab navigation={navigation} openCourseJourney={this.openJourneyForCourse}/>
                                </TabPanel>
                                <TabPanel index={1} value={selectedTabIndex}>
                                    <CoursesJourneyTab navigation={navigation} />
                                </TabPanel>
                                <TabPanel index={2} value={selectedTabIndex} >
                                    <ParticipantsTab />
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const dropdownStyle = {
    borderRadius: "8px",
    width: "200px",
    height: "48px",
    marginBottom: "40px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CoursesPage);
// Customizable Area End
