// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import AddNewParentGuardianDetailsStep1Controller, {
  Props,
} from "./AddNewParentGuardianDetailsStep1Controller.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomCardWithHoverEffectWeb from "../../../components/src/CustomCardWithHoverEffect.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addUserMainBox: {
      maxHeight: "700vh",
      overflowX: "auto",
      padding: "0px 0px 8px 0px",
      borderBottom: "4px solid #f0f0f0",
    },
    parentProfileBox: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: "55px",
      height: "55px",
      borderRadius: '50%',
      marginRight: '10px',
    },
    searchBox: {
      padding: "25px 26px 15px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    searchInputBox: {
      width: "420px",
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        marginRight: "0px",
        marginBottom: "10px",
      },
    },
    parentGuardianMainGridItem: {
      padding: "15px 26px 20px 22px",
    },
    profilePicImg: {
      height: "55px",
      width: "55px",
      objectFit: "contain",
    },
    userName: {
      padding: "3px 0px 15px 0px",
    },
    leftMargin: {
      marginLeft: "8px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    overflowEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    selectTickBox: {
      backgroundColor: "#FFA500",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      height: "35px",
      width: "35px",
      position: "absolute",
      top: "-5px",
      right: "10px",
    },
    selectedTick: {
      color: "#ffffff",
    },
  });
const prevBtn = {
  width: "160px",
  padding: "10px",
  height: "50px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;
// Customizable Area End

// Customizable Area Start
export class AddNewParentGuardianDetailsStep1 extends AddNewParentGuardianDetailsStep1Controller {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, parentList, searchParentText, isItemsPresent } = this.props;
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box className={`${classes.bgColor} ${classes.addUserMainBox}`}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Box className={`${classes.searchBox}`}>
                  <Box className={`${classes.searchInputBox}`}>
                    <CustomTextFieldWeb
                      fieldId="add-new-parent-step-1-search-input"
                      fieldName="searchParentText"
                      fieldType="text"
                      fieldWidth="100%"
                      placeHolderText="Search with parent's First Name , Last Name or SSID"
                      fieldValue={searchParentText}
                      handleChange={this.props.handleChangeSearchParent}
                    />
                  </Box>
                  <CustomButtonWeb
                    btnStyle={searchBtn}
                    btnText="Search"
                    buttonId="add-new-parent-step-1-search-btn"
                    buttonVariant={"contained"}
                    handleButtonClick={this.props.handleSearchParent}
                    isDisabled={false}
                    type={"button"}
                  />
                </Box>
              </Grid>
              <Box width={"100%"} id="teacher-parentTeacher-gallery">
                <Box width={"100%"} display={'flex'} flexWrap={'wrap'} maxHeight={'430px'} overflow={"auto"}>
                 <Grid container direction="row">
                 {parentList?.map((parent) => (
                    <Grid
                      key={`${parent.fullName}-${parent.id}`}
                      item
                      className={`${classes.parentGuardianMainGridItem}`}
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <CustomCardWithHoverEffectWeb
                        fieldId={`card-${parent.fullName}-${parent.id}`}
                        id="carduniqe"
                        handleClick={() => this.handleSelectParent(parent.id, parent.isSelected)}
                        heightOfCard="150px"
                        widthOfCard="96%"
                        hoverButtonHandle={() =>
                          this.handleSelectParent(parent.id, parent.isSelected)
                        }
                        hoverButtonStyle={hoverButtonStyle}
                        hoverButtonStyle2={hoverButtonStyle2}
                        hoverButtonText="Add"
                        hoverButtonText2="Remove"
                        itemId={parent.id}
                        isShowBtnOnHoverEffect
                        isSelected={parent.isSelected}
                      >
                        <Box
                          className={`${classes.userName}`}
                          display={"flex"}
                          alignItems={"center"}
                          position={"relative"}
                        >
                          {parent?.isSelected && (
                            <Box className={`${classes.selectTickBox}`}>
                              <CheckRoundedIcon
                                className={`${classes.selectedTick}`}
                              />
                            </Box>
                          )}
                          <Box className={`${classes.parentProfileBox}`}
                            style={{ 
                              backgroundImage: `url(${parent?.profilePic})`, 
                          }}>
                          </Box>
                          <Box className={`${classes.overflowEllipsis}`}>
                          <Typography
                            component={"span"}
                            className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText24}`}
                          >
                            {parent.fullName}
                          </Typography>
                          </Box>
                        </Box>
                        <Box className={`${classes.overflowEllipsis}`}>
                          <Typography
                            component={"span"}
                            className={`${classes.textCommonColor} ${classes.textCapital} ${classes.fontText16}`}
                          >
                            Email ID:
                          </Typography>
                          <Typography
                            component={"span"}
                            className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText14}`}
                          >
                            {parent.emailId}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component={"span"}
                            className={`${classes.textCommonColor} ${classes.textCapital} ${classes.fontText16}`}
                          >
                            Role:
                          </Typography>
                          <Typography
                            component={"span"}
                            className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText14}`}
                          >
                            {parent.role === "Parent" ? "Parent" : "Parent Teacher"}
                          </Typography>
                        </Box>
                      </CustomCardWithHoverEffectWeb>
                    </Grid>
                  ))}
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center",marginBottom:"10px" }}>
                    <Box>
                    {!isItemsPresent ?
                        <CustomButtonWeb
                          handleButtonClick={this.props.handleLoadMore}
                          btnStyle={prevBtn}
                          btnText={"load more"}
                          buttonId={`loadmore-btn`}
                          buttonVariant={"outlined"}
                          isDisabled={isItemsPresent}
                          type={"button"}
                        /> :""
                      }
                    </Box>
                  </Grid>
                 </Grid>
                </Box>
              </Box>
              <Grid item xs={12}>
                <Box padding={"4px 26px 16px 26px"} display={"flex"}>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                  >
                    Couldn't find the profile?
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.primaryColor5} ${classes.cursorPointer} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                    onClick={this.props.handleGotoStep2}
                  >
                    Add Parent / Parent Teacher Details
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const hoverButtonStyle = {
  width: "160px",
  height: "50px",
  padding: "10px",
  backgroundColor: "#00c996",
  color: "#ffffff",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const hoverButtonStyle2 = {
  width: "160px",
  padding: "10px",
  height: "50px",
  backgroundColor: "#FF0000",
  color: "#ffffff",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  marginRight: "10px",
};

const searchBtn = {
  padding: "7px 10px",
  width: "130px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewParentGuardianDetailsStep1);
// Customizable Area End
