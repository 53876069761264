// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import UserManagementTabComponentController, {
  IDDConfigProps,
  Props,
} from "./UserManagementTabComponentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable from "../../../components/src/CustomTable.web";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    myPathItemGrid: {
      display: "flex",
      justifyContent: "center",
    },
    dropdownGrid: {
      padding: "0px 18px 8px 22px",
      borderBottom: "4px solid #f0f0f0",
    },
    selectDD: {
      marginBottom: "15px",
    },
    selectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    userMngFilterDiv: {
      display: "flex",
      alignItems: "flex-end",
      padding: "0px 10px",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0px !important",
      },
    },
    userMngFilterSelectDiv: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    marginFilter: {
      marginRight: "10px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
      },
    },
    userListTableMainBox: {
      padding: "0px 32px 0px 22px",
    },
    searchBtn: {
      display: "flex",
      alignItems: "flex-end",
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      "& > *": {
        marginTop: theme.spacing(3),
      },
      "& .Mui-selected": {
        color: "#FFFFFF",
        backgroundColor: "#FFD92E !important",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: "6px",
      },
      "& .MuiPaginationItem-icon": {
        color: "#0D46BC",
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
  });

const userTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class UserManagementTabComponent extends UserManagementTabComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, tenantUserMngTabCategory } = this.props;
    const {
      tableBodyData,
      tableHeaderColumns,
      dropdownConfig,
      currentStudentParentListingPage,
      totalStudentParentRecords,
      perPageRecords,
      userManageTableActionButtonConfig,
    } = this.state;

    return (
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid
              className={`${classes.dropdownGrid}`}
              container
              direction="row"
            >
              {dropdownConfig.map((ddItem: IDDConfigProps) => (
                <Grid key={ddItem.id} item xs={12} sm={6} md={4} lg={3}>
                  <Box className={`${classes.selectDD}`}>
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component={"span"}
                      >
                        {ddItem.label}
                      </Typography>
                    </Box>
                    <Box className={`${classes.selectDDWidth}`}>
                      <CustomSelectDropdown
                        fieldId={ddItem.id}
                        fieldValue={ddItem.value}
                        fieldName={ddItem.name}
                        handleChange={this.handleSelectDropdownValue}
                        fieldStyles={dropdownStyle}
                        renderValue={(selected: any) =>
                          this.renderDDMenuItem(
                            selected,
                            ddItem.fieldName,
                            ddItem.label
                          )
                        }
                        isDisabled={
                          ddItem.name === "selectedSubjectHandledDDValue" && !this.state.selectedGradeLevelDDValue
                        }              
                        fieldError={false}
                        fieldMenuItems={ddItem.menuItems}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
              <Grid
                className={`${classes.searchBtn}`}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Box
                  marginBottom={"17px"}
                  className={`${classes.selectDDWidth}`}
                >
                  <CustomButton
                    buttonId={`tenant-user-mng-${tenantUserMngTabCategory}-filter-search-btn`}
                    btnStyle={searchBtnStyle}
                    btnText="Search"
                    buttonVariant={"contained"}
                    handleButtonClick={this.handleSearchUsers}
                    isDisabled={false}
                    type={"button"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.userListTableMainBox}`}>
              <ThemeProvider theme={userTableTheme}>
                {tableBodyData.length !== 0 || this.state.loading ? (
                  <CustomTable
                    tableBodyData={tableBodyData}
                    tableHeaderColumns={tableHeaderColumns}
                    tableId="tenant-user-management-userlist-table"
                    showEditMenuIcon={false}
                    showMenuIcon={true}
                    showUserActionMenu={true}
                    actionButtonConfigstatus={userManageTableActionButtonConfig}
                  />
                ) : (
                  <CustomEmptyResultComponent message="No record found" />
                )}
              </ThemeProvider>
            </Box>
          </Grid>
          {!!tableBodyData.length && (
            <Grid item xs={12}>
              <Box className={classes.paginationBox}>
                <Box width={"50%"} marginTop={"20px"}>
                  <Box display={"flex"} gridGap={"6px"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18}`}
                      component="span"
                    >
                      Records
                    </Typography>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                      component="span"
                    >
                      {totalStudentParentRecords > perPageRecords
                        ? `${
                            currentStudentParentListingPage * perPageRecords -
                            perPageRecords
                          }-
                ${currentStudentParentListingPage * perPageRecords}`
                        : `${
                            currentStudentParentListingPage *
                              totalStudentParentRecords -
                            totalStudentParentRecords
                          }-
                ${currentStudentParentListingPage * totalStudentParentRecords}`}
                    </Typography>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18}`}
                      component="span"
                    >
                      of {totalStudentParentRecords}
                    </Typography>
                  </Box>
                </Box>
                {totalStudentParentRecords > perPageRecords && (
                  <Box className={classes.paginationRoot}>
                    <Pagination
                      count={Math.ceil(
                        totalStudentParentRecords / perPageRecords
                      )}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      siblingCount={0}
                      page={currentStudentParentListingPage}
                      onChange={this.handlePageChange}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtnStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(UserManagementTabComponent);
// Customizable Area End
