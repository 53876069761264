// Customizable Area Start
import React from "react";
import {
  TextField,
  InputAdornment,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomFileUploadTextFieldController, {
  Props,
} from "./CustomFileUploadTextFieldController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      borderRadius: "8px",
      padding: "5px 0px",
      position: "relative",
    },
    textfieldInput: {
      fontWeight: "normal",
      paddingLeft: "15px !important",
      paddingRight: "10px !important",
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    inputAdornment: {
      paddingLeft: "15px",
    },
    textFieldFont: {
      fontSize: "16px",
    },
    fileInput: {
      opacity: 0,
      position: "absolute",
      cursor: "pointer",
      width: "100%",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    endText: {
      paddingRight: "10px",
      whiteSpace: "nowrap",
    },
  });

// Customizable Area End

export class CustomFileUploadTextField extends CustomFileUploadTextFieldController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      fieldName,
      fieldError,
      fieldValue,
      fieldId,
      fieldType,
      startAdornmentIcon,
      placeHolderText,
      isDisabled,
      helperText,
      fieldWidth,
      textFieldStyles,
      removeText,
      uploadText,
      file
    } = this.props;
    const { acceptInput } = this.state;

    const getTextfieldClasses = () => {
      if (fieldError) {
        return `${classes.textField} ${classes.primaryBorder} ${
          classes.bgColor
        } ${classes.errorBorder}`;
      } else if (isDisabled) {
        return `${classes.textField} ${classes.primaryBorder}  ${
          classes.secondaryBgColor
        } `;
      } else {
        return `${classes.textField} ${classes.bgColor} ${
          classes.primaryBorder
        }`;
      }
    };

    return (
      <>
        <TextField
          id={fieldId}
          placeholder={`${placeHolderText}`}
          name={`${fieldName}`}
          style={{
            width: fieldWidth,
            ...textFieldStyles,
          }}
          className={getTextfieldClasses()}
          disabled={isDisabled}
          type={fieldType}
          value={fieldValue}
          error={fieldError}
          InputProps={{
            startAdornment: startAdornmentIcon ? (
              <InputAdornment
                className={classes.inputAdornment}
                position="start"
              >
                {startAdornmentIcon}
              </InputAdornment>
            ) : (
              ""
            ),
            disableUnderline: true,
            readOnly: true,
            endAdornment: (
              <>
                {!(file || fieldValue) && (
                  <input
                    type="file"
                    id="file-input"
                    className={classes.fileInput}
                    onChange={this.handleSelectFile}
                    accept={acceptInput}
                  />
                )}
                {(file || fieldValue) && (
                  <Typography
                    component={"span"}
                    onClick={this.handleRemoveFile}
                    className={`${classes.fontText14} ${
                      classes.primaryColor5
                    } ${classes.cursorPointer} ${classes.endText}`}
                  >
                    {removeText}
                  </Typography>
                )}
                {!(file || fieldValue) && (
                  <Typography
                    component={"span"}
                    className={`${classes.fontText14} ${
                      classes.primaryColor5
                    } ${classes.cursorPointer} ${classes.endText}`}
                  >
                    {uploadText}
                  </Typography>
                )}
              </>
            ),
          }}
          inputProps={{
            className: `${classes.textfieldInput} ${classes.textFieldFont} ${
              classes.textPrimaryColor
            }`,
          }}
        />
        {fieldError && (
          <FormHelperText className={`${classes.helperText}`}>
            {helperText}
          </FormHelperText>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(
  CustomFileUploadTextField
);
// Customizable Area End
