// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Button } from '@material-ui/core';
import AudioPreviewDialogController, {
    Props
} from "./AudioPreviewDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from '../../../components/src/CombineStyles.web';
import CustomAudioPlayer from "../../../components/src/CustomAudioPlayer.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        audioPreviewDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                overflowX: "hidden",
                maxWidth: "100%",
                width: "768px",
                overflowY: "hidden",
            }
        },
        titleRow: {
            justifyContent: "space-between",
            padding: "0px",
            display: "flex",
            flexFlow: "row",
        },
        closeButton: {
            padding: "0px",
            margin: "0px",
            "&.MuiButton-root": {
                minWidth: "0px",
            }
        },
        previewContent: {
            display: "flex",
            marginTop: "20px",
            padding: "0px",
            overflow: "hidden",
            gap: "20px",
        },
        videoTopTextBottom: {
            flexDirection: "column-reverse",
        },
        videoBottomTextTop: {
            flexDirection: "column",
        },
        videoLeftTextRight: {
            flexDirection: "row-reverse",
        },
        videoRightTextLeft: {
            flexDirection: "row",
        },
        videoBox: {
            borderRadius: "12px",
            ocerflow: "hidden",
            objectFit: "cover",
            objectPosition: "top",
        }
    })
// Customizable Area End

// Customizable Area Start
export class AudioPreviewDialog extends AudioPreviewDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, audio, description, activityTitle } = this.props;
        return (
            <Dialog className={`${classes.audioPreviewDialog} ${classes.highlightBgColor}`} open={open} aria-labelledby="audio-preview-dialog-title">
                <Box className={`${classes.bgColor}`} padding={"30px"}>
                    <Box className={classes.titleRow}>
                        <Typography component={"span"} className={`${classes.fontText24} ${classes.textPrimaryColor} ${classes.fontBold500} `}>{activityTitle || 'Types of Graphing'}</Typography>
                        <Button className={`${classes.closeButton} ${classes.textLightGreyColor}`} onClick={this.props.onCancel}> X </Button>
                    </Box>
                    <DialogContent className={this.getClassToArrangeContent()}>
                        <Box width={this.getWidthOfTextBox()}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`} component={"p"}
                                dangerouslySetInnerHTML={{ __html: description }}>
                            </Typography>
                        </Box>
                        <Box width={this.getWidthOfAudioBox()}>
                            <CustomAudioPlayer
                                classes={classes}
                                handleUpdateMediaProgressTime={this.props.handleUpdateMediaProgressTime}
                                currentTrack={{
                                    src: this.getAudioUrl(audio),
                                    isCompleted: false,
                                    audioProgress: 0,
                                }}
                            />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const confirmButton = {
    width: "100%",
    height: "50px",
    padding: "5px",
    borderRadius: "10px",
    fontWeight: 600,
    fontSize: "16px",
} as const;

const cancelButton = {
    padding: "5px",
    height: "50px",
    width: "100%",
    fontWeight: 600,
    borderRadius: "10px",
    fontSize: "16px",
    border: "1px solid",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AudioPreviewDialog);
// Customizable Area End
