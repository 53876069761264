import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  createStyles,
  createTheme,
  Theme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import TenantReportsDashboardTabController, {
  Props,
} from "./TenantReportsDashboardTabController.web";
import CustomTableWeb from "../../../components/src/CustomTable.web";
import { reportsTableHeaderMock } from "../assets/tenantDashboardMock";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainTenantReportsDashboardTabBox: {
      padding: "2px 20px",
    },
    userTypesTable: {
      padding: "15px 20px",
      borderRadius: "10px",
    },
  });

const reportTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "13px !important",
        paddingBottom: "13px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});
// Customizable Area End

export class TenantReportsDashboardTab extends TenantReportsDashboardTabController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { reportsList } = this.state;
    return (
      <Box className={`${classes.mainTenantReportsDashboardTabBox}`}>
        <Box className={`${classes.sections}`}>
          <ThemeProvider theme={reportTableTheme}>
            <Box
              className={`${classes.userTypesTable} ${classes.marginFromLabel} ${classes.secondaryBgColor}`}
            >
              <CustomTableWeb
                showMenuIcon={false}
                tableBodyData={reportsList}
                tableHeaderColumns={reportsTableHeaderMock}
                tableId="tenant-dashboard-reports-list-table"
                handleDownload={(file: any) => this.downloadReport(file)}
              />
            </Box>
          </ThemeProvider>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantReportsDashboardTab);
// Customizable Area End
