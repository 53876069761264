// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { Message } from "../../../framework/src/Message";

const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface ITeacherInfoProps {
  email: string;
  uniqId: string;
  firstName: string;
  lastName: string;
  profileImg: string;
  role: string;
  status: string;
  curriculums: {id?: number, name?: string };
  subjectsHandled: Array<{id?: number, name?: string}>;
  classesList: Array<{id?: number, name?: string}>;
  customFields: Array<{id?: number, label?: string, value?: string}>;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  profileSettingOption: {label: string, clickHandle: () => void}[];
  teacherInfo: ITeacherInfoProps;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      profileSettingOption: [
        {
          label: 'Edit Profile',
          clickHandle: ()  => this.onEditProfileBtn()
        },
        {
          label: 'Change Password',
          clickHandle: () => this.handleGoToChange_Password()
        }
      ],
      teacherInfo: {
        classesList: [],
        curriculums: {},
        customFields: [],
        email: "",
        firstName: "",
        lastName: "",
        profileImg: "",
        role: "",
        status: "",
        subjectsHandled: [],
        uniqId: "",
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  onEditProfileBtn = () => {
    this.props.navigation.navigate("EditProfileTeacher");
  };

  handleGoToChange_Password = () => {
    this.props.navigation.navigate("StudentChangePassword");
  };

  handleGetTeacherInfoResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const subjectsList: any = responseJson?.data?.attributes?.subject?.map((item: any) => {
        return {
          id: item?.id,
          name: item?.name,
        }
      })
      const classesList: any = responseJson?.data?.attributes?.user_classes?.map((item: any) => {
        return {
          id: item?.id,
          name: item?.name,
        }
      })
      const customFields: any = responseJson?.data?.attributes?.user_custom_fields?.data?.map((item: any) => {
        return {
          id: item?.attributes?.id,
          label: item?.attributes?.label,
          value: item?.attributes?.field_value,
        }
      })
      const teacherInfo: ITeacherInfoProps = {
        uniqId: responseJson?.data?.attributes?.id_number?.toString() || "NA",
        email: responseJson?.data?.attributes?.email || "NA",
        firstName: responseJson?.data?.attributes?.first_name || "",
        lastName: responseJson?.data?.attributes?.last_name || "",
        role: responseJson?.data?.attributes?.role?.name || "",
        status: responseJson?.data?.attributes?.status || "",
        profileImg: responseJson?.data?.attributes?.profile_url || "",
        curriculums: {
          id: responseJson?.data?.attributes?.curriculum?.id,
          name: responseJson?.data?.attributes?.curriculum?.name,
        },
        classesList: classesList,
        customFields: customFields,
        subjectsHandled: subjectsList,
      }
      this.setState({ teacherInfo: teacherInfo })
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  handleGetTeacherInfo = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const teacherId = await getStorageData("user_id");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${teacherId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetTeacherInfo();
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiGetUserDetails){
          this.handleGetTeacherInfoResponse(responseJson)
        }
      }
      // Customizable Area Start

      // Customizable Area End
    }
  }
  // Customizable Area End
}
// Customizable Area End
