// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import NewGradingComponentPopController,{Props} from "./NewGradingComponentPopController.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addCourseBox: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        overflowY: "hidden",
        width: "880px",
        overflowX: "hidden",
        maxWidth: "100%",
        minHeight: "500px",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
      },
    },
    requiredNotation: {
      color: "red",
    },
    headerGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    customLoader: {
      zIndex: 100,
      position: "absolute",
      left: 0,
      right: 0,
      bottom: -30,
      top: 0,
      backgroundColor: "rgb(0, 0, 0, .4)",
      display: "block",
    },
    circularContainer: {
      top: "40%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewGradingComponentPop extends NewGradingComponentPopController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit,onCancel } = this.props;
    const {
      selectedGradeDDValue,
      gradesDropdownList,
      courseTitleValue,
      courseTitleValueError,
      courseTitleValueErrorMsg,
      loading,
      minAttempt,
      minAttemptError,
      minAttemptErrorMsg,
      maxAttempt,
      maxAttemptError,
      maxAttemptErrorMsg,
      recommendedValue,
      recommendedValueError,
      recommendedValueErrorMsg,
      selectedGradeError,
      selectedGradeErrorMsg
    } = this.state;

    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addCourseBox}`}
          aria-labelledby="add-new-course-modal-title"
          open={open}
          scroll={"body"}
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box
            padding={"30px"}
            className={`${classes.bgColor}`}
            data-test-id="modalBox"
          >
            <Grid direction="row" container>
              <Grid
                xs={12}
                className={`${classes.headerGrid}`}
                item
                data-test-id="modalGrid"
              >
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="addNewCourse"
                >
                 Add New Grading Component & Weightage 
                </Typography>
                <Button
                  onClick={onCancel}
                  className={classes.textPrimaryColor}
                  id="add-new-course-close-modal-icon-btn"
                  style={{ float: "right" }}
                >
                  <CloseIcon
                    className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                    id="add-new-course-close-modal-icon"
                  />
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
              data-test-id="content"
            >
              <Grid container spacing={2} direction="row" data-test-id="row">

                <Grid xs={12} item data-test-id="grade">
                  <Box>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="SubjectGrade"
                    >
                      Grading Component Title
                    </Typography>
                    <Box marginBottom={"10px"}>
                      
                      <CustomSelectDropdown
                        fieldId="select-title-grade" 
                        fieldName="selectedGradeDDValue"
                        fieldValue={selectedGradeDDValue}
                        handleChange={this.handleDropdownValues}
                        fieldError={selectedGradeError}
                        helperText={selectedGradeErrorMsg}
                        fieldStyles={dropdownStyles}
                        renderValue={this.renderGradeDDValues}
                        fieldMenuItems={gradesDropdownList}
                        isDisabled={false}
                        isLoading={isEdit && gradesDropdownList.length === 0}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Minimum Attempt
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Minimum Attempt"
                      fieldName="minAttempt"
                      fieldId="min-attempt"
                      fieldValue={minAttempt}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={minAttemptError}
                      helperText={minAttemptErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Maximum Attempt
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Maximum Attempt"
                      fieldName="maxAttempt"
                      fieldId="max-attempt"
                      fieldValue={maxAttempt}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={maxAttemptError}
                      helperText={maxAttemptErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Recommended Value
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Recommended Value"
                      fieldName="recommendedValue"
                      fieldId="recommended-value"
                      fieldValue={recommendedValue}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={recommendedValueError}
                      helperText={recommendedValueErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Weightage
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Weightage"
                      fieldName="courseTitleValue"
                      fieldId="weightage"
                      fieldValue={courseTitleValue}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={courseTitleValueError}
                      helperText={courseTitleValueErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid xs={12} item >
                  <Box marginBottom={"10px"} style={{ width: "100%" }} >
                    <CustomButton
                     handleButtonClick={this.handleRequestBody}
                      buttonVariant={"contained"}
                      buttonId={`submit-add-component-pop`}
                      btnText={isEdit?"Edit":"Add"}
                      btnStyle={submitBtnStyles}
                      type={"button"}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      buttonId={`newCourseCanclButton`}
                      buttonVariant={"outlined"}
                      btnStyle={cancelBtnSTyle}
                      isDisabled={false}
                      type={"button"}
                      handleButtonClick={onCancel}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const cancelBtnSTyle = {
  borderRadius: "12px",
  width: "120px",
  textAlign: "center",
  marginLeft: "15px",
  padding: "10px 10px",
  height: "50px",
  fontSize: "18px",
  float: "right",
  fontWeight: 500,
  marginTop: "32px",
} as const;

const submitBtnStyles = {
  marginTop: "32px",
  padding: "10px 10px",
  marginLeft: "15px",
  height: "50px",
  fontSize: "18px",
  textAlign: "center",
  width: "120px",
  borderRadius: "12px",
  float: "right",
  fontWeight: 500,
} as const;



const dropdownStyles = {
  fontWeight: 400,
  height: "50px",
  width: "100%",
  padding: "17px 15px",
  borderRadius: "12px",
  background: "#FFF",
  color: "#888",
  border: "1px solid #888",
  fontSize: "16px",
  marginTop: "10px",
  
} as const;
const textfieldStyles = {
  padding: "8px 0px",
  marginTop: "10px",
  width: "99%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewGradingComponentPop);
// Customizable Area End
