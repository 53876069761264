import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { withStyles } from "@material-ui/core";
import { customThemeStyles } from "./CustomThemeStyles.web";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  classes?: any;
  graphData: any;
  chartLabel: string;
  titleColor: any;
  gridlineColor: string;
}

const alignTitle: any = "start";
const titlePosition: any = "top";

export const CustomBarGraph: React.FC<IProps> = (props) => {
  const { graphData, chartLabel, titleColor, gridlineColor } = props;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: chartLabel,
        position: titlePosition,
        align: alignTitle,
        color: titleColor,
        padding: 20,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: titleColor,
          beginAtZero: true,
        },
      },
      y: {
        ticks: {
          color: titleColor,
          stepSize: 20,
          beginAtZero: true,
        },
        grid: {
          drawTicks: false,
          color: gridlineColor,
          borderDash: [4, 4],
        },
        border: {
          dash: [2, 4],
        },
      },
    },
    animation: {
      duration: 2000,
    },
  };

  return <Bar options={options} data={graphData} />;
};

export default withStyles(customThemeStyles)(CustomBarGraph);
