// Customizable Area Start
import React from "react"
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ThemeNightIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill} fillRule="evenodd">
                <g transform="translate(-1061.000000, -40.000000)">
                    <g transform="translate(1061.000000, 40.000000)">
                        <g transform="translate(9.000000, 7.000000)">
                            <path d="M23.6306601,14.171468 C23.3463251,13.9484926 22.9549951,13.9203547 22.6415764,14.100532 C21.155468,14.9547192 19.5847094,15.3879015 17.9729261,15.3879015 C12.7941281,15.3879015 8.58100493,11.1745419 8.58100493,5.99562562 C8.58100493,4.37674877 9.01477833,2.80811823 9.87038424,1.33347783 C10.0520985,1.02041379 10.0251429,0.628256158 9.80228571,0.342857143 C9.5795468,0.0574581281 9.20559606,-0.0639605911 8.8577734,0.0365320197 C6.3559803,0.757714286 4.10423645,2.28780296 2.51739901,4.34482759 C0.870502463,6.47988177 0,9.0368867 0,11.7395468 C0,18.482601 5.48571429,23.96867 12.2286502,23.96867 C14.9313103,23.96867 17.4889064,23.0985222 19.6250246,21.4520985 C21.683468,19.8656158 23.214266,17.6151724 23.9354483,15.1151527 C24.0357044,14.767803 23.9151133,14.3945616 23.6306601,14.171468 Z M12.2286502,22.1951527 C6.4635665,22.1951527 1.77339901,17.5047488 1.77339901,11.7395468 C1.77339901,7.85012808 3.99901478,4.3106601 7.38159606,2.53182266 C6.99996059,3.65320197 6.80760591,4.81170443 6.80760591,5.99562562 C6.80760591,12.1523941 11.8163941,17.1613005 17.9729261,17.1613005 C19.1551921,17.1613005 20.3157044,16.9681182 21.4425222,16.5851823 C19.6639212,19.9684729 16.1213793,22.1951527 12.2286502,22.1951527 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default ThemeNightIcon;
// Customizable Area End