// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IDDConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  menuItems?: any;
  label: string;
  fieldType: string;
  customField?: boolean;
  customLabel?: string;
  customLabel2?: string;
  isDisabled?: boolean;
  placeHolderText: string;
  
}

export interface IStepperStepProps {
  id: any;
  label: string;
  optional?: boolean;
}

export interface IParentInputConfigProps {
  find?: any;
  id: any;
  label: string;
  role: string;
  ddConfigs: Array<IDDConfigProps>;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;

  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  handleAddNewActivity: any;
  handleUpdateActivity: any;
  selectedBlockType: string
  handleActivityTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDataBlockTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedDataBlockPlacement:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  gridContent: string[];
  
  selectedBlockType:string,
  selectedDataBlockPlacement:string,
  screenName: string;
 
  steps: Array<IStepperStepProps>;
  searchParentText: string;
  isStepWithMultipleScreens?: boolean;
  openAContentType:boolean
  dialogInput:string
 
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ContentTypeModelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addNewUserAPIRequestId: string = "";
  curriculumListAPIRequestId: string = "";
  apiGetGradesListingId: string = "";
  apiGetSchoolYearDataId: string = "";
  searchParentListAPIRequestId: string = "";
  apiGetRoleNamesDataId: string = "";
  apiGetStudentCustomFieldsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      gridContent: Array(6).fill('+Edit'),
    
      openAContentType:false,
      selectedBlockType:"DataBlocks",
      steps: [
        {
          id: "front-page-details",
          label: "Front Page",
        },
        {
          id: "back-page-details",
          label: "Back Page",
        },
      ],
     
      selectedDataBlockPlacement:"Grades",
      screenName: "student",
      searchParentText: "",
      isStepWithMultipleScreens: false,
      dialogInput:""
     
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
  
    // Customizable Area End
  }
  // Customizable Area Start
  handleNextStepper = () => {
    const { screenName } = this.state;

    if (screenName === "student") {
    
    } else {
      this.setState({
        screenName: "parent_step_2",
        isStepWithMultipleScreens: true,
      });
    }
  };
  handleActivityTypeRadioDynamicClass = (activityType: string) => {
    const classes = this.props.classes;
    if (this.props.selectedBlockType === activityType) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }
  handleActivityTypeLabelDynamicClass = (activityType: string) => {
    const classes = this.props.classes;
    if (this.props.selectedBlockType === activityType) {
      return `${classes.radioButtonLabel} ${classes.checkedLabelColor}`;
    } else {
      return `${classes.radioButtonLabel}`;
    }
  }
  handleActivityTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedBlockType: event.target.value });
  }
  handleDataBlockTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedDataBlockPlacement: event.target.value });
  }
  
  handleDataBlockClass = (currentValue: string) => {
    const classes = this.props.classes;
    const { selectedDataBlockPlacement  } = this.props;
    if (selectedDataBlockPlacement === currentValue) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }

  handleOpenContentType= ()=>{
    this.setState({ openAContentType: true })
  }
  handlePreviousStepper = () => {
    const { screenName } = this.state;
    if (screenName === "parent_step_2") {
      this.setState({ screenName: "parent", isStepWithMultipleScreens: false });
    } else {
      this.setState({ screenName: "student" });
    }
  };

  
}
// Customizable Area End
