// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherResoursTabController, {
  Props,
} from "./TeacherResoursTabController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import Pagination from "@material-ui/lab/Pagination";

// Customizable Area End
const teacherResorseTabColumn = [
  {
    id: "Title_Resourse",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Subject_Resourse",
    columnId: 2,
    label: "Subject",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Grade_Resourse",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "LessonsPerDay_Resourse",
    columnId: 4,
    label: "No. Lessons Per Day",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Status_Resourse",
    columnId: 5,
    label: "Status",
    type: tableColumnTypes.STATUS_CHIP,
  },
];
const teacherResourseTabData = [
  {
    id: 1,
    Title_Resourse: "Resource Title 1",
    Subject_Resourse: "Science",
    Grade_Resourse: "Grade 3",
    LessonsPerDay_Resourse: "10",
    Status_Resourse: "Scheduled",
  },
  {
    id: 2,
    Title_Resourse: "Resource Title 2",
    Subject_Resourse: "Science",
    Grade_Resourse: "Grade 3",
    LessonsPerDay_Resourse: "10",
    Status_Resourse: "Late",
  },
  {
    id: 3,
    Title_Resourse: "Resource Title 3",
    Subject_Resourse: "Science",
    Grade_Resourse: "Grade 3",
    LessonsPerDay_Resourse: "10",
    Status_Resourse: "Completed",
  },
  {
    id: 4,
    Title_Resourse: "Resource Title 4",
    Subject_Resourse: "Science",
    Grade_Resourse: "Grade 3",
    LessonsPerDay_Resourse: "10",
    Status_Resourse: "Scheduled",
  },
  {
    id: 5,
    Title_Resourse: "Resource Title 5",
    Subject_Resourse: "Science",
    Grade_Resourse: "Grade 3",
    LessonsPerDay_Resourse: "10",
    Status_Resourse: "Completed",
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    resourseTabDivider: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "20px",
      marginBottom: "10px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    resourseTabTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    paginationBox: {
      display: 'flex',
      alignItems: 'center',
      padding: "10px 20px 10px 21px",
      justifyContent: 'space-between',
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      '& .Mui-selected': {
        backgroundColor: '#FFD92E !important',
        color: "#FFFFFF",
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherCourseMngResourseTab extends TeacherResoursTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, resourceData, pagination } = this.props;
    const {
      selectResourseNameValue,
      selectResourseSubjectValue,
      selectedGradeValue,
      selectedNoOfLessonsPerDayValue,
      selectResourseStatusValue,
      titleDtopdownList,
      subjectDropdownList,
      gradeDropdownList,
      lessonsPerDayDropdownList,
    } = this.state;

    return (
      <Box
        px={"25px"}
        pb={"20px"}
        pt={"20px"}
        className={`${classes.bgColor}`}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          data-test-id="resourseTabgrid"
        >
          <Grid item xs={12} lg={3} md={4}>
            <Box data-test-id="resourseTabBox">
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                  component={"span"}
                  data-test-id="resourseTabTitle"
                >
                  Title
                </Typography>
              </Box>
              <Box data-test-id="resourseTabTitleDD">
                <CustomSelectDropdown
                  fieldId="resourseTitleDropdown"
                  fieldName="selectResourseNameValue"
                  fieldValue={selectResourseNameValue}
                  handleChange={this.handleResoursesChangeDropdownValue}
                  fieldError={false}
                  renderValue={this.renderResourseTitleDropdownValue}
                  fieldMenuItems={titleDtopdownList}
                  fieldStyles={resourseDropdownStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="resourseTabSubject"
                >
                  Subject
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="resourseTabSubjectBox"
              >
                <CustomSelectDropdown
                  fieldId="subjectDropdown"
                  fieldName="selectResourseSubjectValue"
                  fieldValue={selectResourseSubjectValue}
                  handleChange={this.handleResoursesChangeDropdownValue}
                  renderValue={this.renderResourseSubjectDropdownValue}
                  fieldError={false}
                  fieldMenuItems={subjectDropdownList}
                  fieldStyles={resourseDropdownStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="resourseTabGradeGrid">
            <Box data-test-id="resourseTabGradeGridBox">
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="resourseTabGrade"
                >
                  Grade
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="resourseTabGradeDDBox"
              >
                <CustomSelectDropdown
                  fieldId="gradeDropdown"
                  fieldName="selectedGradeValue"
                  fieldValue={selectedGradeValue}
                  handleChange={this.handleResoursesChangeDropdownValue}
                  renderValue={this.renderResourseGradeDropdownValue}
                  fieldError={false}
                  fieldMenuItems={gradeDropdownList}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldStyles={resourseDropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            md={4}
            data-test-id="resourseTabNoOfLessonGrid"
          >
            <Box>
              <Box marginBottom={"8px"} data-test-id="resourseTabNoOfLessonBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="resourseTabNoOfLessonTitle"
                >
                  No. of lessons per day
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="resourseTabNoOfLessonDD"
              >
                <CustomSelectDropdown
                  fieldId="noOfLessonsPerDayDropdown"
                  fieldName="selectedNoOfLessonsPerDayValue"
                  fieldValue={selectedNoOfLessonsPerDayValue}
                  renderValue={this.renderResourseNoOfLessonsPerDayValue}
                  handleChange={this.handleResoursesChangeDropdownValue}
                  fieldMenuItems={lessonsPerDayDropdownList}
                  fieldError={false}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldStyles={resourseDropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="resourseTabStatusGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="resourseTabStatusBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="resourseTabStatusTitle"
                >
                  Status
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="resourseTabStatusTitleDD"
              >
                <CustomSelectDropdown
                  fieldId="statusDropdown"
                  fieldName="selectResourseStatusValue"
                  handleChange={this.handleResoursesChangeDropdownValue}
                  fieldValue={selectResourseStatusValue}
                  fieldError={false}
                  renderValue={this.renderResourseStatusDropdownValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldStyles={resourseDropdownStyle}
                  fieldMenuItems={this.state.ResourseStatusComponentValue}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="resourseTabSerachBtn">
            <Box data-test-id="resourseTabBtnBox">
              <CustomButton
                btnText="Search"
                btnStyle={resourseTabBtnStyle}
                buttonId={`my-assessment-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleResourceTabSearchButton}
                isDisabled={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.resourseTabDivider} />
        {resourceData.length === 0 ?
          <CustomEmptyResultComponent message="No record found" />
          :
          <Grid container data-test-id="TableResourseGrid">
            <Grid item xs={12}>
              <Box
                className={`${classes.customTableStyles} ${classes.resourseTabTableRow}`}
                data-test-id="TableResourseGridBox"
              >
                <CustomTable
                  tableHeaderColumns={teacherResorseTabColumn}
                  tableBodyData={resourceData}
                  showMenuIcon={this.props.isResourseWrite || false}
                  tableId={"coursesTable"}
                  actionButtonConfig={[
                    {
                      label: 'View More',
                      action: () => { }
                    },
                    {
                      label: 'Edit',
                      action: (data: any) => this.handleResourceEditAction(data)
                    },
                    {
                      label: 'Delete',
                      action: (data: any) => this.handleResourceDeleteAction(data)
                    },
                  ]}
                />
              </Box>
            </Grid>
            {resourceData.length > 0 && (
              <Grid item xs={12}>
                <Box className={classes.paginationBox}>

                  <Box style={{ marginTop: '23px', width: '50%' }}>
                    <Box display={"flex"} gridGap={"6px"}>
                      <Typography
                        component="span"
                        className={`${classes.fontText18} ${classes.textCommonColor}`}
                      >
                        Records
                      </Typography>
                      <Typography
                        component="span"
                        className={`${classes.fontBold600} ${classes.textCommonColor} ${classes.fontText18}`}
                      >
                        {(pagination?.current_page * pagination?.per_page) - pagination?.per_page}-{pagination?.current_page * pagination?.per_page}
                      </Typography>
                      <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                        of {pagination?.total_count}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                  </Box>
                  <Box className={classes.paginationRoot}>
                    <Pagination
                      shape="rounded"
                      variant="outlined"
                      count={Math.ceil(pagination?.total_count / pagination?.per_page)}
                      siblingCount={0}
                      boundaryCount={1}
                      onChange={this.handlePagination}
                      page={pagination?.current_page}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        }
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const resourseTabBtnStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px",
} as const;

const resourseDropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCourseMngResourseTab);
// Customizable Area End
