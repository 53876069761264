// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import TenantAssignCatalogToTeacherSuccessModalController, {
  Props,
} from "./TenantAssignCatalogToTeacherSuccessModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const assignCatalogToTeacherSuccess = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "450px",
        height: "380px",
        borderRadius: "10px",
      },
      paperWidthSm: {
        maxWidth: "450px",
        borderRadius: "10px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TenantAssignCatalogToTeacherSuccessModal extends TenantAssignCatalogToTeacherSuccessModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={assignCatalogToTeacherSuccess}>
        <Dialog
          data-test-id="assignCatalogToTeacherSuccessDialog"
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          open={open}
          aria-labelledby="assign-catalog-to-teacher-success-dialog-title"
        >
          <Box padding={"18px 15px"} className={`${classes.bgColor}`}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              id="assign-catalog-to-teacher-success-main-box"
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                width={"94%"}
                data-test-id="assign-catalog-to-teacher-success-icon-box"
              >
                <img src={SuccessIcon} alt="success icon" />
              </Box>
              <Box>
                <IconButton
                  data-test-id="assign-catalog-to-teacher-success-icon"
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent data-test-id="assign-catalog-to-teacher-success-content">
              <Box mt={"20px"} mb={"10px"} textAlign={"center"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                  data-test-id="assign-catalog-to-teacher-success-main-text"
                >
                  Catalog 1 Assigned to Evelyn Allen
                </Typography>
              </Box>
              <Box
                mb={"30px"}
                mt={"10px"}
                textAlign={"center"}
                data-test-id="assign-catalog-to-teacher-success-texts-box"
              >
                <Typography
                  data-test-id="assign-catalog-to-teacher-success-text-1"
                  className={`${classes.fontBold600} ${classes.fontSize18} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  {" "}
                  Catalog 1{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="assign-catalog-to-teacher-success-text-2"
                >
                  {" "}
                  assigned to teacher{" "}
                </Typography>
                <Typography
                  data-test-id="assign-catalog-to-teacher-success-teacher-name"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  Evelyn Allen{" "}
                </Typography>
                <Box>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="assign-catalog-to-teacher-success-text-3"
                  >
                    successfully.
                  </Typography>
                </Box>
              </Box>
              <Box
                data-test-id="assign-catalog-to-teacher-success-okay-btn"
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
              >
                <CustomButton
                  btnStyle={assignCatalogToTeacherSuccessBtn}
                  btnText="Okay"
                  buttonId="assign-catalog-to-teacher-success-okay-btn"
                  isDisabled={false}
                  type={"button"}
                  buttonVariant={"outlined"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const assignCatalogToTeacherSuccessBtn = {
  padding: "5px",
  height: "50px",
  borderRadius: "10px",
  width: "100%",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantAssignCatalogToTeacherSuccessModal
);
// Customizable Area End
