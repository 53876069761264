// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import {
  ScienceColor,
  EnglishColor,
  HistoryColor,
  MathsColor,
} from "./assets";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export interface ITableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  bgColor?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gradedAssessmentComponent: Array<any>;
  selectedClassComponentDDValue: any;
  gradeSubjectList: any;
  searchStudent: string;
  openAddRemarkModal: boolean;
  gradeSubjectTableColumns: Array<ITableProps>;
  menuAnchorEl: any;
  editGrade: boolean;
  selectedRowId: number;
  selectedRowGradeValue: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ParentTeacherGradedAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProfileData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      openAddRemarkModal: false,
      selectedClassComponentDDValue: "",
      searchStudent: "",
      menuAnchorEl: null,
      editGrade: false,
      selectedRowId: -1,
      selectedRowGradeValue: 0,
      gradeSubjectTableColumns: [
        {
          columnId: 1,
          id: "Title",
          type: tableColumnTypes.PROGRESS_TAB_ICON_COURSE,
          label: "Assessment Title",
          width: "200px",
        },
        {
          columnId: 2,
          id: "gradeComponent",
          type: tableColumnTypes.TEXT,
          label: "Grading Component",
          width: "170px",
        },
        {
          columnId: 3,
          id: "gradeDueDate",
          type: tableColumnTypes.TEXT,
          label: "Graded On",
          width: "120px",
        },
        {
          columnId: 4,
          id: "gradePoints",
          type: tableColumnTypes.TEXT,
          label: "Points",
          width: "80px",
        },
        {
          columnId: 5,
          id: "grade",
          type: tableColumnTypes.TEXT,
          label: "Grade",
          width: "80px",
        },
        {
          columnId: 6,
          id: "gradeClassAvg",
          type: tableColumnTypes.ASSIGNED_ICON,
          label: "Average",
          width: "80px",
        },
      ],
      gradedAssessmentComponent: [
        {
          id: 1,
          label: "Class 10A",
          value: 1,
        },
        {
          id: 2,
          label: "Class 10B",
          value: 2,
        },
        {
          id: 3,
          label: "Class 9A",
          value: 3,
        },
        {
          id: 4,
          label: "Assignment",
          value: 4,
        },
      ],
      gradeSubjectList: [
        {
          id: 1,
          Title: {
            name: "Graphing Quiz",
            icon: ScienceColor,
            color: "#01C996",
          },
          subjectId: 1,
          gradeComponent: "Quiz",
          gradeDueDate: "July 20, 2022",
          gradeClassAvg: "90%",
          gradePoints: "30",
          grade: "25",
        },
        {
          id: 2,
          Title: {
            name: "Fiction Assignment",
            icon: MathsColor,
            color: "#0D46BC",
          },
          subjectId: 2,
          gradeComponent: "Assignment",
          gradeClassAvg: "82%",
          gradeDueDate: "July 01, 2022",
          gradePoints: "50",
          grade: "47",
        },
        {
          id: 3,
          Title: {
            name: "Derivatives Exam",
            icon: HistoryColor,
            color: "#FF0000",
          },
          subjectId: 3,
          gradeComponent: "Project",
          gradeClassAvg: "80%",
          gradeDueDate: "July 02, 2022",
          gradePoints: "100",
          grade: "85",
        },
        {
          id: 4,
          Title: {
            name: "Intelligence Project",
            icon: EnglishColor,
            color: "#FBD82C",
          },
          subjectId: 4,
          gradeComponent: "Exam",
          gradeClassAvg: "87%",
          gradeDueDate: "July 03, 2022",
          gradePoints: "150",
          grade: "109",
        },
        {
          id: 5,
          Title: {
            name: "Graphing Quiz",
            icon: EnglishColor,
            color: "#FBD82C",
          },
          subjectId: 5,
          gradeComponent: "Project",
          gradeClassAvg: "97%",
          gradeDueDate: "July 05, 2022",
          gradePoints: "20",
          grade: "19",
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  handleGradedDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };

  handleSearchStudent = (event: any) => {
    this.setState({
      searchStudent: event.target?.value,
    });
  };

  handleEditGrade = () => {
    console.log("error");
  };

  renderGradedComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Class 10A";
    }
    const { gradedAssessmentComponent } = this.state;
    const selectedGradingComponents = gradedAssessmentComponent.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleOpenAddRemarkModal = () => {
    this.setState({ openAddRemarkModal: true });
    this.handleMenuClose();
  };

  handleCloseAddRemarkModal = () => {
    this.setState({ openAddRemarkModal: false });
  };

  handleActionClick = (e: any, rowItemId: any) => {
    this.setState({ selectedRowId: -1, editGrade: false });
    this.setState({ menuAnchorEl: e?.currentTarget, selectedRowId: rowItemId });
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }

  handleEnableEditGrade = () => {
    const grade = this.state.gradeSubjectList.filter((obj: any) => obj.id === this.state.selectedRowId);
    console.log("selected Grade: ", grade)
    this.setState({ editGrade: true, selectedRowGradeValue: grade[0].grade });
    this.handleMenuClose();
  };

  handleDisableEditGrade = () => {
    this.setState({ editGrade: false });
  };

  handleGradeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedRowGradeValue: parseInt(event.target.value) });
  }

  handleGradeInputEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.setState({ editGrade: false });
    }
  }
  // Customizable Area End
}
// Customizable Area End
