// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  dialogInput: string;
  handleEditorChange: (content: any) => void
 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentNameValue: string;
  gradeValue: string;
  gradeError: boolean;
  gradeMsg: string;
  remarkValue: string;
  remarkError: boolean;
  remarkMsg: string;

  dialogInput:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddContentModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      studentNameValue: "",
      gradeValue: "",
      remarkValue: "",
      gradeError: false,
      gradeMsg: '',
      remarkError: false,
      remarkMsg: '',
      dialogInput:""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
   
  }
  // Customizable Area End



  handleEditorChange = (content: any) => {
    this.setState({ dialogInput: content });
    if (content) {
      this.setState({ remarkError: false, remarkMsg: '' });
    }
  }

  
  handleSaveRemark = () => {

  }

  
}
// Customizable Area End