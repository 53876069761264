// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CheckboxUnCheckIcon: any = (props: any) => {
    return (
        <SvgIcon sx={props.style ? props.style : ''} {...props}>
            <rect fill="#fff" x="0.5" y="0.5" width="19" height="19" rx="3.5" />
        </SvgIcon>
    );
}

export default CheckboxUnCheckIcon;
// Customizable Area End