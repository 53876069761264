import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

interface Props {
  options: {label: string, clickHandle: () => void}[];
}

function GearIconDropDown({ options }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <SettingsOutlinedIcon style={{ color: "grey", fontSize: 40 }} />
      </IconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        style={{ marginTop: 50, marginLeft: -60 }}
      >
        {options.map((option) => (
          <MenuItem onClick={option.clickHandle}>{option?.label}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default GearIconDropDown;