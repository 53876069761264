// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentAssignmentTopInfoController, {
  Props
} from "./StudentAssignmentTopInfoController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CalendarIcon from "../../../components/src/SVGIcons/CalendarIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    leftMargin: {
      marginLeft: "1%"
    },
    assignmentDetailsGridMarginBottom: {
      marginBottom:"2%"
    },
    truncate:{
      whiteSpace:"nowrap",
      overflow:"hidden",
      textOverflow:"ellipsis"
    },
    textCapitalize:{
      textTransform:"capitalize"
    },
    completedAssignmentTopInfo:{
      marginBottom:"3%",
      padding:"22px 25px 0px 25px"
    },
  })

// Customizable Area End

// Customizable Area Start
export class StudentAssignmentTopInfo extends StudentAssignmentTopInfoController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, assignmentDetail , categoryOfAssessment } = this.props;
    return (
      <Box className={`${classes.completedAssignmentTopInfo} ${classes.secondaryInfoBgColor}`} display={"flex"} flexDirection={"column"}>
        <Grid container direction="row">
          <Grid className={classes.assignmentDetailsGridMarginBottom} item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>Lesson: </Typography>
            <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}>{assignmentDetail?.lessonName}</Typography>
          </Grid>
          <Grid className={classes.assignmentDetailsGridMarginBottom} item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>Course: </Typography>
            <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}>{assignmentDetail?.courseName}</Typography>
          </Grid>
          <Grid className={classes.assignmentDetailsGridMarginBottom} item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>Score: </Typography>
           {categoryOfAssessment === "graded" ? 
            <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}>
              {assignmentDetail?.obtainedScore}/{assignmentDetail?.maxScore}
              </Typography>
              :
              <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}>
              {assignmentDetail?.maxScore}
              </Typography>
            }
          </Grid>
          <Grid className={`${classes.assignmentDetailsGridMarginBottom} ${classes.truncate}`} item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>Assessment: </Typography>
            <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.textCapitalize}`}>{`${assignmentDetail?.assessmentType} (${assignmentDetail?.evaluationType})`}</Typography>
          </Grid>
         <Grid className={classes.assignmentDetailsGridMarginBottom} item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>Your Attempts: </Typography>
            <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}>{`${assignmentDetail?.leftAttempts}/${assignmentDetail?.maxNoOfAttempts}`}</Typography>
          </Grid>
          <Grid className={classes.assignmentDetailsGridMarginBottom} item xs={12} sm={6} md={4}>
            {categoryOfAssessment !== "graded" ? 
              <Box display={"flex"} alignItems={"center"}>
                <CalendarIcon width="22" height="22" viewBox="0 0 22 22" stroke={"#090909"} strokeWidth="1.5" fill={"none"} />
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}>Submitted Date:</Typography>
                <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.leftMargin}`}>{assignmentDetail?.submittedDate}</Typography>
              </Box>
              :
              <Box display={"flex"} alignItems={"center"}>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}>Time Spent:</Typography>
                <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.leftMargin}`}>{assignmentDetail?.timeSpent}mins</Typography>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentAssignmentTopInfo);
// Customizable Area End
