// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CalendarIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.66699 1.66602V4.16602" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.333 1.66602V4.16602" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.91699 7.57422H17.0837" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.0585 16.0916C12.9419 16.2082 12.8335 16.4249 12.8085 16.5832L12.6502 17.7082C12.5919 18.1166 12.8752 18.3999 13.2835 18.3416L14.4085 18.1833C14.5669 18.1583 14.7919 18.0499 14.9002 17.9332L17.8502 14.9833C18.3585 14.4749 18.6002 13.8833 17.8502 13.1333C17.1085 12.3916 16.5169 12.6332 16.0085 13.1416L13.0585 16.0916Z" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.584 13.5664C15.834 14.4664 16.534 15.1664 17.434 15.4164" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 18.3327H6.66667C3.75 18.3327 2.5 16.666 2.5 14.166V7.08268C2.5 4.58268 3.75 2.91602 6.66667 2.91602H13.3333C16.25 2.91602 17.5 4.58268 17.5 7.08268V9.99935" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.99607 11.4167H10.0036" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.91209 11.4167H6.91957" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.91209 13.9167H6.91957" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default CalendarIcon;
// Customizable Area End