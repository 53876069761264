// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  fieldName: string;
  fieldValue?: any;
  fieldError?: boolean;
  fieldId: string;
  fieldWidth: string;
  fieldType: string;
  isDisabled?: boolean;
  placeHolderText: string;
  startAdornmentIcon?: any;
  helperText?: string;
  textFieldStyles?: any;
  uploadText: string;
  removeText: string;
  file?: any;
  fileType?: string;
  handleFile: (file: any) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedFile: any;
  acceptInput: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomFileUploadTextFieldController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedFile: null,
      acceptInput: this.props.fileType === 'image' ? 'image/*' : '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectFile = (event: any) => {
    if (!this.props.isDisabled) {
      const file = event.target.files[0];
      if (this.props.fileType === 'image' && !file.type.includes('image/')) {
        event.preventDefault();
        toast.error(`Please select image type files.`);
      } else {
        this.setState({
          selectedFile: file,
        });
        this.props.handleFile(file);
        // Handle the selected file here
        console.log(file);
      }
    }
  };

  handleRemoveFile = () => {
    if (!this.props.isDisabled) {
      this.setState({ selectedFile: null });
      this.props.handleFile("");
    }
  };
  // Customizable Area End
}
