// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateFnsUtils from "@date-io/moment";
import { createStyles, withStyles, Theme , alpha } from "@material-ui/core/styles";
import CustomDatepickerController, {
  Props,
  locale,
} from "./CustomDatepickerController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CustomCalendarIcon from "./SVGIcons/CustomCalendarIcon.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      borderRadius: "8px",
      padding: "8px 0px",
      position: "relative",
      width: "100%",
      "&. MuiInputBase-root.Mui-disabled": {
        backgroundColor: "grey !important",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        bottom: "-20px"
      }
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    inputAdornment: {
      paddingLeft: "15px",
    },
    inputAdornmentEnd: {
      paddingRight: "15px",
    },
  });

// Customizable Area End

export class CustomDatepicker extends CustomDatepickerController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      disableFutureDate,
      disablePastDate,
      selectedDate,
      format,
      fieldId,
      placeHolderText,
      fieldError,
      isDisabled,
      fieldStyles,
      fieldErrorMsg
    } = this.props;

    const getTextfieldClasses = () => {
      if (fieldError) {
        return `${classes.textField} ${classes.primaryBorder} ${
          classes.bgColor
        } ${classes.errorBorder}`;
      } else if (isDisabled) {
        return `${classes.textField} ${classes.primaryBorder} ${
          classes.secondaryBgColor
        }`;
      } else {
        return `${classes.textField} ${classes.bgColor} ${
          classes.primaryBorder
        }`;
      }
    };

    return (
      <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
        <Box>
          <KeyboardDatePicker
            className={getTextfieldClasses()}
            disableFuture={disableFutureDate}
            disablePast={disablePastDate}
            variant="inline"
            format={format}
            id={fieldId}
            style={fieldStyles}
            value={selectedDate}
            disabled={isDisabled}
            onChange={this.handleSelectDate}
            placeholder={placeHolderText}
            InputProps={{
              disableUnderline: true, // Remove underline
              onClick: this.handleTextFieldClick,
              className: `${classes.textPrimaryColor} ${classes.inputAdornment} ${classes.inputAdornmentEnd}`
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
              edge: "start",
              disableFocusRipple: true,
              disableTouchRipple: true,
              onClick: this.handleTextFieldClick,
            }}
            PopoverProps={{
              anchorEl: this.state.anchorEl,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              className: "CalendarPopover",
            }}
            keyboardIcon={<CustomCalendarIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke={"#B8B8B8"}
              strokeWidth="1.0"
              fill={"none"}
            />}
            open={this.state.openDialog}
            onClose={this.handleDialogClose} 
            helperText={fieldErrorMsg} 
            error={fieldError}     
          />
        </Box>
      </MuiPickersUtilsProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomDatepicker);
// Customizable Area End
