// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Button } from '@material-ui/core';
import VideoPreviewDialogController, {
    Props
} from "./VideoPreviewDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from '../../../components/src/CombineStyles.web';
import { videoThumbnail } from "./assets";
import CustomVideoPlayer from '../../../components/src/CustomVideoPlayer.web';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        videoPreviewDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                width: "768px",
                minHeight: "500px",
                maxWidth: "100%",
                overflowX: "hidden",
                overflowY: "hidden",
            }
        },
        titleRow: {
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "row",
            padding: "0px",
        },
        closeButton: {
            padding: "0px",
            margin: "0px",
            "&.MuiButton-root": {
                minWidth: "0px",
            }
        },
        previewContent: {
            position: "relative",
            width: "100%",
            height: "400px",
            marginTop: "20px",
            padding: "0px",
            display: "flex",
            gap: "20px",
            overflow: "hidden",
            overflowY: "scroll",
        },
        videoBottomTextTop: {
            flexDirection: "column",
        },
        videoTopTextBottom: {
            flexDirection: "column-reverse",
        },
        videoRightTextLeft: {
            flexDirection: "row",
        },
        videoLeftTextRight: {
            flexDirection: "row-reverse",
        },
        videoBox: {
            borderRadius: "12px",
            ocerflow: "hidden",
            objectFit: "cover",
            objectPosition: "top"
        }
    })

// Customizable Area End

// Customizable Area Start
export class VideoPreviewDialog extends VideoPreviewDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, description, video, activityTitle } = this.props;
        return (
            <Dialog className={`${classes.highlightBgColor} ${classes.videoPreviewDialog}`} aria-labelledby="video-preview-dialog-title" open={open}>
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Box className={classes.titleRow}>
                        <Typography className={`${classes.fontText24} ${classes.fontBold500} ${classes.textPrimaryColor}`} component={"span"}>{activityTitle || 'Types of Graphing'}</Typography>
                        <Button onClick={this.props.onCancel} className={`${classes.closeButton} ${classes.textLightGreyColor}`}> X </Button>
                    </Box>

                    <DialogContent className={this.getClassToArrangeContent()}>
                        <Box width={this.getWidthOfTextBox()}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`} component={"p"}
                                dangerouslySetInnerHTML={{ __html: description }}>
                            </Typography>
                        </Box>
                        <Box width={this.getWidthOfVideoBox()}>
                            <CustomVideoPlayer
                                currentVideo={{
                                    src: this.getVideoUrl(video),
                                    isCompleted: video?.isCompleted ?? false,
                                    videoProgress: video?.mediaProgress ?? 0,
                                    thumbnail: videoThumbnail,
                                }}
                                classes={classes}
                            />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const confirmButton = {
    height: "50px",
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
} as const;

const cancelButton = {
    padding: "5px",
    width: "100%",
    height: "50px",
    borderRadius: "10px",
    border: "1px solid",
    fontWeight: 600,
    fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(VideoPreviewDialog);
// Customizable Area End
