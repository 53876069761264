// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  FIELD_TYPES,
  ICustomTableProps,
  IDropdownMenuItemProps,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import {
  tenantCatalogCreationStep2InputConfigMock,
  tenantCatalogCreationStep2TableHeaderMock,
  availableCoursesListMock,
} from "../assets/tenantCourseManagementMockdata";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import _ from "lodash";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export const step2TabsList = [
  {
    id: 1,
    label: "Available Courses",
    value: "available_courses",
  },
  {
    id: 2,
    label: "Added Courses",
    value: "added Courses",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  subjectDropdownData: Array<any>;
  gradeDropdownData: Array<any>;
  getAddedCourses: any;
  setAddedCourses?: any;
  catalogId?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedStep2TabIndex: number;
  gradeLevelList: Array<any>;
  gradeLevelDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedGradeLevelDDValue: any;
  subjectHandledList: Array<any>;
  subjectHandledDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedSubjectHandledDDValue: any;
  catalogTitleList: Array<any>;
  catalogTitleListDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCatalogTitleListDDValue: any;
  tableHeaderColumns: Array<ICustomTableProps>;
  tableBodyData: Array<ICustomTableProps>;
  addedCoursesList: Array<ICustomTableProps>;
  filteredCoursesList: Array<ICustomTableProps>;
  step2InputConfigs: Array<IInputConfigProps>;
  paginationLimit: number;
  availableCoursesListData: Array<ICustomTableProps>;
  availableCoursePagination: object;
  addedCoursePagination: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantCourseMngCreateCatalogStep2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      selectedStep2TabIndex: 0,
      gradeLevelDDMenuItems: [],
      gradeLevelList: [],
      selectedGradeLevelDDValue: "",
      subjectHandledList: [],
      subjectHandledDDMenuItems: [],
      selectedSubjectHandledDDValue: "",
      catalogTitleList: [],
      catalogTitleListDDMenuItems: [],
      selectedCatalogTitleListDDValue: "",
      tableHeaderColumns: [],
      tableBodyData: [],
      addedCoursesList: [],
      filteredCoursesList: [],
      step2InputConfigs: tenantCatalogCreationStep2InputConfigMock,
      paginationLimit: 10,
      availableCoursesListData: [],
      availableCoursePagination: {},
      addedCoursePagination: {
        per_page: 10,
        current_page: 1,
        next_page: null,
        prev_page: null,
        total_pages: 1,
        total_count: 0,
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null && apiRequestCallId === this.apiGetCourseList) {
        this.handleCourseListResponse(responseJson, errorResponse)
      }
    }
  }
  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetCoursesListTableData();
    this.handleGetCourseListApi({}, {});
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeStep2Tab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      selectedStep2TabIndex: newValue,
      availableCoursesListData: [],
      selectedCatalogTitleListDDValue: "",
      selectedGradeLevelDDValue: "",
      selectedSubjectHandledDDValue: "",
    }, () => {
      if (newValue === 0) {
        this.handleGetCourseListApi({}, {});
      } else {
        const { addedCoursesList, addedCoursePagination } = this.state;
        const titleDropdownData = this.getTitleDropdownData(addedCoursesList);
        let pagination = addedCoursePagination;
        pagination.total_count = addedCoursesList.length;
        this.setState({
          filteredCoursesList: addedCoursesList,
          catalogTitleListDDMenuItems: titleDropdownData,
          addedCoursePagination: pagination,
        }, () => {
          this.handleAddedCoursePagination(1);
          this.handleSetCoursesListTableData();
        });
      }
      this.handleSetCoursesListTableData();
    });
  };

  setStep2DDValue = (selected: any, fieldName: string) => {
    const {
      gradeLevelDDMenuItems,
      subjectHandledDDMenuItems,
      catalogTitleListDDMenuItems,
    } = this.state;

    const { gradeDropdownData, subjectDropdownData } = this.props;

    let label: any = "";

    if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradeDropdownData.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "catalogTitleListDDMenuItems") {
      const selectedItem = catalogTitleListDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else {
      const selectedItem = subjectDropdownData.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderStep2DDMenuItem = (
    selected: any,
    fieldName: string,
    emptyText: string
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${emptyText}`;
    }
    return this.setStep2DDValue(selected, fieldName);
  };

  handleStep2SelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { step2InputConfigs } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const updatedDDConfig = step2InputConfigs.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          step2InputConfigs: updatedDDConfig,
          [name]: fieldValue,
        };
      });
    }
  };

  handleSetCoursesListTableData = () => {
    const {
      selectedCatalogTitleListDDValue,
      catalogTitleListDDMenuItems,
      selectedSubjectHandledDDValue,
      selectedGradeLevelDDValue,
    } = this.state;
    const { subjectDropdownData, gradeDropdownData } = this.props;

    let ddConfigs: Array<IInputConfigProps> = [
      {
        id: "tenant-course-mng-step2-catalog-title-filter",
        name: "selectedCatalogTitleListDDValue",
        fieldName: "catalogTitleListDDMenuItems",
        value: selectedCatalogTitleListDDValue,
        menuItems: catalogTitleListDDMenuItems,
        label: "Title",
        fieldType: FIELD_TYPES.SELECT,
        placeHolderText: "Select Title",
      },
      {
        id: "tenant-course-mng--step2-subject-filter",
        name: "selectedSubjectHandledDDValue",
        fieldType: FIELD_TYPES.SELECT,
        placeHolderText: "Select Subject",
        fieldName: "subjectHandledDDMenuItems",
        value: selectedSubjectHandledDDValue,
        menuItems: subjectDropdownData,
        label: "Subject",
      },
      {
        name: "selectedGradeLevelDDValue",
        fieldName: "gradeLevelDDMenuItems",
        id: "tenant-course-mng--step2-gradeLevel-filter",
        value: selectedGradeLevelDDValue,
        fieldType: FIELD_TYPES.SELECT,
        placeHolderText: "Select Grade Level",
        menuItems: gradeDropdownData,
        label: "Grade",
      },
    ];

    this.setState({
      tableHeaderColumns: tenantCatalogCreationStep2TableHeaderMock,
      step2InputConfigs: ddConfigs,
    });
  };

  handleAvailableCourseSearch = () => {
    const {
      selectedCatalogTitleListDDValue,
      selectedSubjectHandledDDValue,
      selectedGradeLevelDDValue,
    } = this.state;

    let searchQuery: any = {
      title: selectedCatalogTitleListDDValue,
      subject_id: selectedSubjectHandledDDValue,
      grade_id: selectedGradeLevelDDValue,
    }
    console.log("searchQuery: ", searchQuery);
    this.handleGetCourseListApi(searchQuery, {});
  };

  handleAddedCourseSearch = () => {
    const {
      selectedCatalogTitleListDDValue,
      selectedSubjectHandledDDValue,
      selectedGradeLevelDDValue,
      addedCoursesList,
      addedCoursePagination,
    } = this.state;

    let filteredData = addedCoursesList;

    if (selectedCatalogTitleListDDValue) {
      filteredData = filteredData.filter((item: any) =>
        item.title.name === selectedCatalogTitleListDDValue
      );
    }

    if (selectedSubjectHandledDDValue) {
      filteredData = filteredData.filter((item: any) =>
        item.subjectId === selectedSubjectHandledDDValue
      );
    }

    if (selectedGradeLevelDDValue) {
      filteredData = filteredData.filter((item: any) =>
        (item.gradeId).toString() === selectedGradeLevelDDValue.toString()
      );
    }

    let pagination = addedCoursePagination;
    pagination.total_count = filteredData.length;
    this.setState({ filteredCoursesList: filteredData, addedCoursePagination: pagination }, () => {
      this.handleAddedCoursePagination(1);
    });
  };

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }

  handleGetCourseListApi = async (searchQuery: any, pagination: any) => {
    this.showModalLoader();

    const baseUrl = configJSON.getCourseListData + `?per_page=${pagination.limit || this.state.paginationLimit}`;
    let requestUrl = baseUrl;

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    if (searchQuery.title) {
      requestUrl = requestUrl + `&course_title=${searchQuery.title}`;
    }

    if (searchQuery.grade_id) {
      requestUrl = requestUrl + `&grade_id=${searchQuery.grade_id}`;
    }

    if (searchQuery.subject_id) {
      requestUrl = requestUrl + `&subject_id=${searchQuery.subject_id}`;
    }

    if (searchQuery.school_year_id) {
      requestUrl = requestUrl + `&school_year_id=${searchQuery.school_year_id}`;
    }

    if (searchQuery.user_id) {
      requestUrl = requestUrl + `&user_id=${searchQuery.user_id}`;
    }

    if (searchQuery.catalogue_id) {
      requestUrl = requestUrl + `&catalogue_id=${searchQuery.catalogue_id}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetCourseList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCourseListResponse = async (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const courseList = responseJson?.data?.map(
        (item: any) => {
          return this.handleSetCourseItem(item);
        }
      );

      const titleDropdownData = this.getTitleDropdownData(courseList);

      courseList.map((item: any) => {
        const isAdded = this.state.addedCoursesList.find((course: any) => course.id === item.id);
        if (isAdded) {
          item.actionButton = this.setRemoveActionButton(item.id);
        }
      });

      if (this.state.selectedStep2TabIndex === 0) {
        this.setState({
          availableCoursesListData: courseList || [],
          availableCoursePagination: responseJson?.meta?.pagination,
          catalogTitleListDDMenuItems: titleDropdownData,
        }, async () => {
          this.handleSetCoursesListTableData();
          this.handleSetExistingAddedCourses();
        });
      } else {
        this.setState({
          catalogTitleListDDMenuItems: [],
        }, async () => {
          this.handleSetCoursesListTableData();
        });
      }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  setRemoveActionButton = (id: number) => {
    const removeButton = {
      btnStyle: {
        height: "50px",
        width: "160px",
        backgroundColor: "inherit",
        color: "#FF0000",
        border: "1px solid #FF0000",
        padding: "10px",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
      },
      btnText: "Remove",
      btnId: "tenant-course-mng-step-2-availablecourses-remove",
      isDisabled: false,
      btnVariant: "outlined",
      btnType: "button",
      handleAction: () => this.handleRemoveCourse(id),
    }
    return removeButton;
  }

  setAddActionButton = (id: number) => {
    const addButton = {
      btnStyle: {
        width: "160px",
        height: "50px",
        padding: "10px",
        backgroundColor: "#00c996",
        color: "#ffffff",
        borderRadius: "10px",
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
      },
      btnText: "Add",
      btnId: "tenant-course-mng-step-2-availablecourses-add",
      isDisabled: false,
      btnVariant: "contained",
      btnType: "button",
      handleAction: () => this.handleAddCourse(id),
    }
    return addButton;
  }

  handleAddCourse = (id?: any) => {
    const { availableCoursesListData, addedCoursePagination } = this.state;
    let addedCoursesList: any = this.state.addedCoursesList;
    let courseToAdd: any = availableCoursesListData.find((item) => item.id === id);
    if (courseToAdd) {
      courseToAdd.actionButton = this.setRemoveActionButton(id);
      addedCoursesList.push(courseToAdd);
    }

    let pagination: any = addedCoursePagination;
    pagination.total_count += 1;
    pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);

    const titleDropdownData = this.getTitleDropdownData(addedCoursesList);

    this.setState({
      addedCoursesList: addedCoursesList,
      filteredCoursesList: addedCoursesList,
      addedCoursePagination: pagination,
      catalogTitleListDDMenuItems: titleDropdownData
    }, () => {
      this.handleSetCoursesListTableData();
      this.props.getAddedCourses(addedCoursesList);
    });
  };

  handleRemoveCourse = (id?: any) => {
    const { addedCoursePagination } = this.state;
    let addedCoursesList: any = this.state.addedCoursesList;
    let coursesAfterRemoved = addedCoursesList.filter((item: any) => item.id !== id);
    let courseToRemove: any = addedCoursesList.find((item: any) => item.id === id);
    if (courseToRemove) {
      courseToRemove.actionButton = this.setAddActionButton(id);
    }

    let pagination: any = addedCoursePagination;
    pagination.total_count -= 1;
    pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);

    const titleDropdownData = this.getTitleDropdownData(coursesAfterRemoved);

    this.setState({
      addedCoursesList: coursesAfterRemoved,
      filteredCoursesList: coursesAfterRemoved,
      addedCoursePagination: pagination,
      catalogTitleListDDMenuItems: titleDropdownData
    }, () => {
      this.handleSetCoursesListTableData();
      this.props.getAddedCourses(coursesAfterRemoved);
    });
  };

  handleAvailableCoursePagination = (data: number) => {
    const {
      selectedCatalogTitleListDDValue,
      selectedSubjectHandledDDValue,
      selectedGradeLevelDDValue,
    } = this.state;

    let searchQuery: any = {
      title: selectedCatalogTitleListDDValue,
      subject_id: selectedSubjectHandledDDValue,
      grade_id: selectedGradeLevelDDValue,
    }
    console.log('search query in pagination: ', searchQuery);
    this.handleGetCourseListApi(searchQuery, { page: data, limit: this.state.paginationLimit });
  }

  handleAddedCoursePagination = (page: number) => {
    const { filteredCoursesList, addedCoursePagination } = this.state;
    const filteredData = filteredCoursesList.slice((page - 1) * addedCoursePagination.per_page, page * addedCoursePagination.per_page);
    let pagination = addedCoursePagination;
    pagination.current_page = page;
    this.setState({ filteredCoursesList: filteredData, addedCoursePagination: pagination });
  }

  handleSetCourseItem = (item: any) => {
    const course = item.attributes;
    const { selectedStep2TabIndex } = this.state;
    return {
      title: {
        bgColor: course?.subject ? course?.subject?.color : '#000',
        icon: course?.subject ? course?.subject?.icon : '',
        name: course?.course_title,
        boxHeight: "32px",
        boxWidth: "32px",
      },
      id: item?.id,
      gradeId: course?.grade?.id,
      grade: course?.grade?.name,
      subjectId: course?.subject ? course?.subject.id : '0',
      subjectName: course?.subject ? course?.subject.name : '-',
      type: 'Global',
      requestedBy: "-",
      acceptedOn: "July 20, 2022",
      requestedOn: "July 20, 2022",
      createdOn: new Date(course?.created_at).toLocaleDateString(),
      modifiedOn: new Date(course?.updated_at).toLocaleDateString(),
      actionButton: selectedStep2TabIndex === 0 ? this.setAddActionButton(item.id) : this.setRemoveActionButton(item.id),
      isByTenant: false,
    }
  }

  getTitleDropdownData = (courseList: Array<any>) => {
    return courseList.map((item: any) => {
      return {
        id: _.uniqueId(item?.id),
        value: item?.title?.name,
        label: item?.title?.name,
      }
    });
  }

  handleSetExistingAddedCourses = async () => {
    const { setAddedCourses } = this.props;
    const { filteredCoursesList } = this.state;
    if (filteredCoursesList.length === 0 && setAddedCourses && setAddedCourses.length > 0) {
      setAddedCourses.forEach((element: any) => {
        this.handleAddCourse((element.id).toString());
      });
    }
  }
  // Customizable Area End
}
