// Customizable Area Start
import React from "react";
import { Radio } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomColorRadioButtonController, {
  Props,
} from "./CustomColorRadioButtonController.web";
import RadioBtnIcon from "./SVGIcons/RadioBtnIcon.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    buttonCss: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

export class CustomColorRadioButton extends CustomColorRadioButtonController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { fieldName, checked, isDisabled, radioColor } = this.props;
    return (
      <Radio
        checked={checked}
        onChange={this.props.handleChange}
        name={fieldName}
        disabled={isDisabled}
        icon={
          <RadioBtnIcon
            width="36"
            height="36"
            viewBox="0 0 36 36"
            stroke={"#D8D8D8"}
            fill={"#ffffff"}
          />
        }
        checkedIcon={
          <RadioBtnIcon
            width="36"
            height="36"
            viewBox="0 0 36 36"
            stroke={radioColor}
            fill={radioColor}
          />
        }
        inputProps={{ "aria-label": fieldName }}
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomColorRadioButton);
// Customizable Area End
