import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Checkbox,
    Popover,
    MenuItem,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        mainContainerPadding: {
            padding: "25px 40px 40px 40px"
        },
        pageName: {
            marginTop: "16px",
            marginBottom: "28px"
        },
        attendanceHeading: {
            color: "#888",
            marginBottom: "15px"
        },
        attendanceBox: {
            height: "122px",
            borderRadius: "12px",
            border: "1px solid #888",
            padding: "0px 25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
        },
        attendanceItem: {
            height: "100%",
        },
        attendanceItemText: {
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "15px",
            fontWeight: 400,
        },
        centerBorder: {
            width: "100%",
            height: "50%",
            borderBottom: "1px solid #888",
            opacity: "0.5",
            position: "absolute",
            top: "0px",
            left: "0px",
        },
        attendanceLabel: {
            color: "#888",
        },
        dividerLine: {
            height: "1px",
            background: "#F1F1F1",
            marginTop: "25px",
            marginBottom: "25px",
            marginLeft: "-40px",
            marginRight: "-40px",
        },
        customTextEditorStyle: {
            "& .CustomTextEditor-customTextEditor-2535": {
                padding: "0px !important",
                overflow: "auto",
            },
            "& .cke_inner, & .cke_contents": {
                borderRadius: "10px",
            },
        },
        behaviourHeader: {
            height: "61px",
            borderRadius: "12px 12px 0px 0px",
            background: "#DBDBDB",
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
        behaviourHeaderItem: {
            textAlign: "center",
        },
        behaviourDataItem: {
            height: "61px",
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #F7F7F7",
        },
        behaviourDataBlock: {
            height: "100%",
            textAlign: "center",
            border: "1px solid #E4E4E4",
            borderRight: 'none',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "none"
        },
        subItemBlockTitle: {
            height: "100%",
            paddingLeft: "25px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            minWidth: "300px",
            width: "100%",
            background: "#fff",
        },
        behaviourItemTitle: {
            paddingLeft: "25px",
            minWidth: "300px",
            width: "100%",
        },
        behaviourMainGrid: {
            marginBottom: "25px",
            borderRadius: "12px",
            border: "2px solid #DDDDDD",
            overflow: "hidden",
            overflowX: "auto",
            background: "#DBDBDB",
            "&::-webkit-scrollbar": {
                background: "#fff",
            }
        },
        evaluationKeyBox: {
            borderRadius: "12px",
            border: "1px solid #888",
            overflow: "hidden",
        },
        evaluationKeyRow: {
            height: "55px",
            display: "flex",
            flexFlow: "row",
            justifyContent: "start",
            alignItems: "center",
            borderBottom: "1px solid #888",
        },
        commentText: {
            color: "#888",
            fontWeight: 400,
            padding: "15px",
            border: "1px solid #888",
            borderRadius: "12px"
        },
        subskillGradeBtn: {
            background: "transparent",
            border: "none",
            flexGrow: 1,
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: 600,
            width: "-webkit-fill-available",
            padding: 0,
            "& p": {
                width: "calc(100% - 12px)",
                padding: "0 5px",
                textAlign: "center"
            }
        },
        mainSkillTable: {
            minWidth: "fit-content",
        },
        mainSkillHeader: {
            display: "flex",
        },
        mainSkillTitle: {
            background: "#F1F1F1",
            display: "flex",
            alignItems: "center",
            height: "61px",
            paddingLeft: "25px",
            minWidth: "300px",
            borderBottom: "1px solid #F7F7F7",
        },
        cellWithCheckbox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "& .quarterName": {
                marginRight: "5px",
            },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#FFD92E",
            },
            "& .MuiCheckbox-root": {
                color: "#888",
            },
            "& .Mui-checked .MuiIconButton-label": {
                background: "#fff",
            },
            "& .MuiIconButton-label": {
                width: "16px",
                height: "16px",
            }
        },
    })
// Customizable Area End

import AttendanceBehaviourTabController, {
    Props,
} from "./AttendanceBehaviourTabController";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";
import { customThemeStyles } from "../CustomThemeStyles.web";
import { combineStyles } from "../CombineStyles.web";
import CustomTextField from "../CustomTextField.web";
const textfieldStyles = {
    padding: "8px 0px",
    width: "50%",
    marginTop: "10px",
} as const;
export class AttendanceBehaviourTab extends AttendanceBehaviourTabController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes ,isParentTeacher} = this.props;
        const editAttendance = this.props.showInEditableMode();


        const getMainSkillGrades = (menu: any) => {
            const options = menu;
            if (options.length < 4) {
                for (let i = 0; i < (4 - options.length); i++) {
                    options.push(options[i]);
                }
            }
            return options.map((option: any) => (
                <Box className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`} minWidth={"110px"} maxWidth={"130px"} width={"100%"} height={"61px !important"}>
                    <Typography style={{ padding: "2px", fontWeight: 600 }} className={`${classes.textPrimaryColor}`}>{option}</Typography>
                </Box>
            )).slice(0, 4);
        };

        return (
            // Customizable Area Start
            <Box className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.bgColor}`} height={"100%"}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                            Attendance
                        </Typography>
                        <Box className={classes.attendanceBox}>
                            <Box className={classes.centerBorder}></Box>
                         
                            {
                                !editAttendance 
                                ?
                                this.props.attendanceData.map((att) => (
                                    <Box className={classes.attendanceItem}>
                                        <Typography className={`${classes.attendanceItemText} ${classes.attendanceLabel} ${classes.textCommonColor}`}>{att.label}</Typography>
                                        <Typography className={`${classes.attendanceItemText} ${classes.textPrimaryColor}`}>{att.value}</Typography>
                                    </Box>
                                ))
                                :
                                this.props.attendanceData.map((att, index) => (
                                    <Box className={classes.attendanceItem} key={index}>
                                        <Box textAlign={"center"}>
                                            <Typography className={`${classes.attendanceItemText} ${classes.attendanceLabel} ${classes.textCommonColor}`}>
                                                {att.label}
                                            </Typography>
                                            {att.label === "Total" ? (
                                                <Typography className={`${classes.attendanceItemText} ${classes.textPrimaryColor}`}>
                                                    {this.props.calculateTotal()}
                                                </Typography>
                                            ) : (
                                                <CustomTextField
                                                    placeHolderText={`Enter ${att.label}`}
                                                    fieldName={`${att.label}`}
                                                    fieldId={`${att.label}`}
                                                    fieldValue={att.value}
                                                    textFieldStyles={textfieldStyles}
                                                    fieldWidth={"25%"}
                                                    fieldType="number"
                                                    handleChange={(event: any) => this.props.handleChange(index, event)}
                                                    
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                ))
                            }

                        </Box>
                    </Grid>
                    {     
                    this.props.teacherComment &&
                    (
                    <>
                    <Grid item xs={12}>
                        <Box className={`${classes.dividerLine}`} />
                    </Grid>
                  
                    <Grid item xs={12}>
                        <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                            {(this.state.userRole === 'Teacher' || this.state.userRole === 'Parent')  ? 'Teacher’s' : this.state.userRole === 'parentTeacher' ? 'Parent Teacher’s' : ''} Comments
                        </Typography>
                        <Box className={classes.customTextEditorStyle}>
                            {
                                editAttendance ?
                           
                                    <CustomTextEditor
                                        editorId="gradebook-comment-textbox"
                                        editorContent={this.props.commentData}
                                        placeHolderText="Enter Comment"
                                        onWysEditorStateChange={(event: any) => this.props.onCommentEdit(event)}
                                        editorError={false}
                                        helperText=""
                                    />
                                  :
                                    <Typography className={`${classes.commentText} ${classes.textCommonColor}`} 
                                    dangerouslySetInnerHTML={{ __html: this.props.commentData }}>
                                     
                                    </Typography>
                            }
                        </Box>
                    </Grid>
                    </>
                    )
                    }   
                    <Grid item xs={12}>
                        <Box className={classes.dividerLine} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
                            Behaviours
                        </Typography>
                        <Grid container direction="row">

                            {
                                this.props?.studentBehaviorData.map((behaviourItem, index) => (
                                    <Grid key={`behaviour-${behaviourItem.id}`} item xs={12} className={classes.behaviourMainGrid}>
                                        <Box className={classes.behaviourHeader}>
                                            <Box className={`${classes.behaviourItemTitle}`}><Typography>{behaviourItem.name}</Typography></Box>
                                            {this.props.quarters.map((quarter, index) => (
                                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className={`${classes.cellWithCheckbox} ${classes.behaviourHeaderItem}`} minWidth={"110px"} maxWidth={"130px"} width={"100%"}>

                                                    <Typography className="quarterName">{quarter.label}</Typography>
                                                    {editAttendance && (
                                                        <Checkbox
                                                            checked={behaviourItem[`is${quarter.id}Selected`]}
                                                            color="primary"
                                                            data-test-id={`${quarter.id}Check${index}`}
                                                            onChange={() => this.props.onSelectDeselectQuarters(behaviourItem, quarter.id)}
                                                            inputProps={{ 'aria-label': 'quarter checkbox' }}
                                                        />
                                                    )}
                                                </Box>
                                            ))}
                                        </Box>
                                        {
                                            behaviourItem.data.map((mainSkill: any, index: number) => (
                                                <Box className={`${classes.mainSkillTable}`}>
                                                    <Box >
                                                        <Box className={`${classes.mainSkillTitle} ${classes.bgColor} ${classes.textPrimaryColor} ${classes.fontBold500}`} width={'-webkit-fill-available'}>
                                                            <Typography>{mainSkill.title}</Typography>
                                                        </Box>
                                            
                                                    </Box>
                                                    { mainSkill.subSkills.map((subskill: any) => (
                                                        <Box className={classes.behaviourDataItem}>
                                                            <Box borderBottom={index === behaviourItem.data.length - 1 ? 'none !important' : ""} className={`${classes.subItemBlockTitle} ${classes.bgColor}`} width={"60%"}>
                                                                <Typography style={{ padding: "2px", fontWeight: 400 }} className={`${classes.textCommonColor}`}>{subskill.title}</Typography>
                                                            </Box>
                                                            {this.props.quarters.map((quarterObj, index) => {
                                                                const quarter = quarterObj.id; 
                                                               
                                                                return (
                                                                    <Box
                                                                        key={quarter}
                                                                        className={`${classes.behaviourDataBlock} ${classes.secondaryBgColor} behaviourDataBlock`}
                                                                        minWidth={"110px"}
                                                                        maxWidth={"130px"}
                                                                        width={"100%"}
                                                                    >
                                                                        {editAttendance ? (
                                                                            <Box display="flex" width="100%" height="100%">
                                                                                <button
                                                                                    data-test-id='subskillGradeBtnQ1'
                                                                                    className={`${classes.subskillGradeBtn}`}
                                                                                    onClick={(e) => this.props.updateBehaviourTableCellData(
                                                                                        e.currentTarget,
                                                                                        `${quarter}Target`,
                                                                                        subskill,
                                                                                        mainSkill.id,
                                                                                        behaviourItem,
                                                                                        `${quarter}-${index}`
                                                                                    )}
                                                                                    disabled={isParentTeacher?subskill[`${quarter}teacherInput`]:false}
                                                                                >
                                                                                    <Typography className={`${classes.textPrimaryColor}`}>
                                                                                        {subskill[quarter]}
                                                                                    </Typography>
                                                                                </button>

                                                                                <Popover
                                                                                    id={`${quarter}-${index}`}
                                                                                    open={!!subskill[`${quarter}Target`]}
                                                                                    anchorEl={subskill[`${quarter}Target`]} 
                                                                                    data-test-id='updateBehaviourCloseQ1'
                                                                                    anchorOrigin={{
                                                                                        horizontal: 'left',
                                                                                        vertical: 'bottom',
                                                                                    }}
                                                                                    onClose={() => this.props.updateBehaviourTableCellData(
                                                                                        null,
                                                                                        `${quarter}Target`,
                                                                                        subskill,
                                                                                        mainSkill.id,
                                                                                        behaviourItem
                                                                                    )}
                                                                                    transformOrigin={{
                                                                                        horizontal: 'left',
                                                                                        vertical: 'top',
                                                                                    }}
                                                                                >
                                                                                    {behaviourItem.menu.map((option: any, idx: number) => (
                                                                                        <MenuItem
                                                                                            key={`${option}-${idx}`}
                                                                                            data-test-id='updateBehaviourItemQ1'
                                                                                            onClick={() => this.props.updateBehaviourValueAndCloseDropdown(
                                                                                                option,
                                                                                                quarter,
                                                                                                subskill,
                                                                                                mainSkill.id,
                                                                                                behaviourItem,
                                                                                                `${quarter}Target`
                                                                                            )}
                                                                                        >
                                                                                            {option}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Popover>
                                                                            </Box>
                                                                        ) : (
                                                                            <Typography
                                                                                style={{ padding: "2px", fontWeight: 600 }}
                                                                                className={`${classes.textPrimaryColor} ${classes.secondaryBgColor}` }
                                                                            >
                                                                                {subskill[quarter]} 
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                );
                                                            })}

                                                        </Box>
                                                    ))}

                                                </Box>
                                            ))
                                        }
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>

                
                    <Grid item xs={12}>
    <Typography className={`${classes.attendanceHeading} ${classes.fontText16} ${classes.fontBold600}`}>
        Evaluation Keys
    </Typography>
    <Box className={classes.evaluationKeyBox}>
        <Box className={classes.evaluationKeyRow}>
            <Box width={"22.5%"} paddingLeft={"25px"}>
                <Typography className={`${classes.attendanceLabel}`}>Title</Typography>
            </Box>
            {Array.from({ length: this.props.maxEvaluationKeys }, (_, index) => (
                <Box key={`header-key${index + 1}`} width={"22.5%"}>
                    <Typography className={`${classes.attendanceLabel}`}>Key {index + 1}</Typography>
                </Box>
            ))}
        </Box>


        {this.props.evaluationKeysData && this.props.evaluationKeysData.length > 0 ? (
            this.props.evaluationKeysData.map((keyData, index) => (
                <Box
                    key={`row-${index}`}
                    borderBottom={index === this.props.evaluationKeysData.length - 1 ? 'none !important' : ''}
                    className={classes.evaluationKeyRow}
                >
                    <Box width={"22.5%"} paddingLeft={"25px"}>
                        <Typography style={{ color: "#888" }}>{keyData.title}</Typography>
                    </Box>
                    {Array.from({ length: this.props.maxEvaluationKeys }, (_, keyIndex) => (
                        <Box key={`row-${index}-key${keyIndex + 1}`} width={"22.5%"}>
                            <Typography className={`${classes.textPrimaryColor}`}>
                                {keyData[`key${keyIndex + 1}`] || 'NA'}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            ))
        ) : (
            <Typography>No evaluation keys data available.</Typography>
        )}
    </Box>
</Grid>
                            

                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
};

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AttendanceBehaviourTab);
// Customizable Area End




