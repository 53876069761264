// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

interface FormData {
  lesson_id: string;
  activities_title: string;
  activity_type: string;
  evolution_type: string;
  objective_type?: string; 
  grading_component: string;
  task: string;
  description: string;
  instruction: string;
  xapi: boolean; 
  xapi_file?:string,
  video_desc_placement: string;
  audio_desc_placement: string;
  correct_answer: boolean;
  media_file?:string
  duration: string;
  allowed_attempts: string;
  max_score: string;
  due_date: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  handleAddNewActivity: any;
  handleUpdateActivity: any;
  isEdit: boolean;
  activityId?: number;
  lesson: any;
  topic: any;
  course: any;
  selectedtLessonId?: number;
  classIds?: string[];
  currentRole?:string;
  studentId: string | number
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchActivity: string,
  selectedActivityType: string,
  selectXAPI: boolean,
  selectedVideoDescPlacement: string,
  selectedAudioDescPlacement: string,
  selectedMediaFile: any,
  selectedEvaluationType: string,
  selectedObjectiveType: string,
  selectedGradingComponent: string,
  gradingComponentData: Array<any>,
  selectedAllowedAttempts: string,
  allowedAttemptsData: Array<any>,
  showCorrectAnswers: boolean,
  openVideoPreviewDialog: boolean,
  openAudioPreviewDialog: boolean,
  videoPreviewPosition: string,
  textPreviewPosition: string,
  audioPreviewPosition: string,
  audioTextPreviewPosition: string,
  pastUrlValue: string,
  selectedDueDate: string,
  dueDateInputType: string,
  openExistingActivityModal: boolean,
  lessonTitleValue: string;
  activityTitleValue: string;
  taskValue: string;
  descriptionValue: string;
  instructionValue: string;
  xapiMediaFile: any;
  videoDescValue: string,
  audioDescValue: string,
  selectedDurationValue: string,
  maxScoreValue: string;
  dueDateValue: string;
  activityTitleError: boolean;
  activityTitleMsg: string;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  taskError: boolean;
  taskErrorMsg: string;
  mediaFileError: boolean;
  mediaFileErrorMsg: string;
  xampiFileError: boolean;
  xampiFileErrorMsg: string;
  instructionError: boolean;
  instructionErrorMsg: string;
  dueDateError: boolean;
  dueDateErrorMsg: string;
  maxScoreError: boolean;
  maxScoreErrorMsg: string;
  loading: boolean;
  activitySearchData: Array<any>;
  selectedCourseValue: string;
  courseDropdownData: Array<any>;
  selectedTopicValue: string;
  topicDropdownData: Array<any>;
  selectedLessonValue: string;
  lessonDropdownData: Array<any>;
  selectedCourseError: boolean;
  selectedCourseMsg: string;
  selectedTopicError: boolean;
  selectedTopicMsg: string;
  selectedLessonError: boolean;
  selectedLessonMsg: string;
  selectedAllowedAttemptsError:boolean,
  selectedAllowedAttemptsErrorMsg:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddNewAssessmentModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetActivityDetails: string = "";
  apiGetActivityListData: string = "";
  apiGetCourseDropdownData: string = "";
  apiGetTopicDropdownList: string = "";
  apiGetLessonDropdownList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      searchActivity: '',
      selectedActivityType: this.props.isEdit ? '' : 'Assessment',
      selectXAPI: false,
      selectedVideoDescPlacement: 'VideoBottomTextTop',
      selectedAudioDescPlacement: 'AudioBottomTextTop',
      selectedMediaFile: null,
      selectedEvaluationType: 'Objective',
      selectedObjectiveType: 'Selection',
      selectedGradingComponent: 'Quiz',
      selectedAllowedAttempts: '',
      showCorrectAnswers: true,
      openVideoPreviewDialog: false,
      openAudioPreviewDialog: false,
      openExistingActivityModal: false,
      videoPreviewPosition: '',
      textPreviewPosition: '',
      audioPreviewPosition: '',
      audioTextPreviewPosition: '',
      pastUrlValue: '',
      selectedDueDate: '',
      dueDateInputType: "text",
      lessonTitleValue: "Lesson Title",
      activityTitleValue: '',
      taskValue: '',
      descriptionValue: '',
      instructionValue: '',
      xapiMediaFile: null,
      videoDescValue: '',
      audioDescValue: '',
      selectedDurationValue: '',
      maxScoreValue: '',
      dueDateValue: '',
      activityTitleError: false,
      activityTitleMsg: '',
      descriptionError: false,
      descriptionErrorMsg: '',
      taskError: false,
      taskErrorMsg: '',
      mediaFileError: false,
      mediaFileErrorMsg: '',
      xampiFileError: false,
      xampiFileErrorMsg: '',
      instructionError: false,
      instructionErrorMsg: '',
      dueDateError: false,
      dueDateErrorMsg: '',
      maxScoreError: false,
      maxScoreErrorMsg: '',
      loading: false,
      activitySearchData: [],
      selectedCourseValue: "",
      courseDropdownData: [],
      selectedTopicValue: "",
      topicDropdownData: [],
      selectedLessonValue: "",
      lessonDropdownData: [],
      selectedCourseError: false,
      selectedCourseMsg: "",
      selectedTopicError: false,
      selectedTopicMsg: "",
      selectedLessonError: false,
      selectedLessonMsg: "",
      allowedAttemptsData: [
        {
          id: 1,
          label: "1",
          value: "1",
        },
        {
          id: 2,
          label: "2",
          value: "2",
        },
        {
          id: 3,
          label: "3",
          value: "3",
        },
        {
          id: 4,
          label: "4",
          value: "4",
        },
        {
          id: 5,
          label: "5",
          value: "5",
        }
      ],
      gradingComponentData: [
        {
          id: 1,
          label: "Quiz",
          value: "Quiz",
        },
        {
          id: 2,
          label: "Exam",
          value: "Exam",
        },
        {
          id: 3,
          label: "Assignment",
          value: "Assignment",
        },
        {
          id: 4,
          label: "Project",
          value: "Project",
        }
      ],
      selectedAllowedAttemptsError:false,
      selectedAllowedAttemptsErrorMsg:""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetActivityDetails:
            this.handleGetActivityDataByIdResponse(responseJson, errorResponse);
            break;

          case this.apiGetActivityListData:
            this.handleGetActivityListResponse(responseJson, errorResponse);
            break;

          case this.apiGetCourseDropdownData:
            this.handleCourseDropdownDataApiResponse(responseJson, errorResponse);
            break;

          case this.apiGetTopicDropdownList:
            this.handleGetTopicDropdownListResponse(responseJson, errorResponse);
            break;

          case this.apiGetLessonDropdownList:
            this.handleGetLessonDropdownListResponse(responseJson, errorResponse);
            break;
        }
      }

      // Customizable Area End
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.handleVideoDescPlacementChange({ target: { value: "VideoBottomTextTop" } } as React.ChangeEvent<HTMLInputElement>, 'bottom', 'top');
    this.handleAudioDescPlacementChange({ target: { value: "AudioBottomTextTop" } } as React.ChangeEvent<HTMLInputElement>, 'bottom', 'top')
    const { lesson, isEdit, activityId } = this.props;
    if (isEdit && activityId) {
      this.handleGetActivityByIdApi(activityId);
    }
    if (lesson) {
      this.setState({ lessonTitleValue: lesson.lessonTitle });
    }
    this.handleGetCourseDropdownDataApi();
  }

  handleSearchActivity = (event: any) => {
    const searchText = event.target.value;
    this.setState({
      searchActivity: searchText,
    }, () => {
      const searchQuery = { title: this.state.searchActivity };
      if (searchText && searchText.length >= 2) {
        setTimeout(() => {
          this.handleGetActivityListApi(searchQuery);
        }, 1000);
      }
    });
  };

  handleActivityTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedActivityType: event.target.value });
  }

  handleEvaluationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedEvaluationType: event.target.value });
  }

  handleObjectiveTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedObjectiveType: event.target.value });
  }

  handleXAPIChange = (event: any) => {
    this.setState({ selectXAPI: !this.state.selectXAPI });
  }

  handleVideoDescPlacementChange = (event: React.ChangeEvent<HTMLInputElement>, videoPosition: string, textPosition: string) => {
    this.setState({ selectedVideoDescPlacement: event.target.value });
    this.setState({ videoPreviewPosition: videoPosition });
    this.setState({ textPreviewPosition: textPosition });

    switch (event.target.value) {
      case "VideoBottomTextTop":
        this.setState({ videoDescValue: "video_desc_above" })
        break;

      case "VideoTopTextBottom":
        this.setState({ videoDescValue: "video_desc_below" })
        break;

      case "VideoRightTextLeft":
        this.setState({ videoDescValue: "video_desc_left" })
        break;

      case "VideoLeftTextRight":
        this.setState({ videoDescValue: "video_desc_right" })
        break;

      default:
        break;
    }
  }

  handleAudioDescPlacementChange = (event: React.ChangeEvent<HTMLInputElement>, audioPosition: string, textPosition: string) => {
    this.setState({ selectedAudioDescPlacement: event.target.value });
    this.setState({ audioPreviewPosition: audioPosition });
    this.setState({ audioTextPreviewPosition: textPosition });

    switch (event.target.value) {
      case "AudioBottomTextTop":
        this.setState({ audioDescValue: "audio_desc_above" })
        break;

      case "AudioTopTextBottom":
        this.setState({ audioDescValue: "audio_desc_below" })
        break;

      default:
        break;
    }
  }

  handleMediaFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedMediaFile: file,
        mediaFileError: false,
        mediaFileErrorMsg: '',
      });
    }
  };

  handleXapiMediaFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        xapiMediaFile: file,
        xampiFileError: false,
        xampiFileErrorMsg: '',
      })
    }
  };

  handleMediaBrowseClick = () => {
    document?.getElementById('audio-video-file-input')?.click();
  };

  handleXapiMediaBrowseClick = () => {
    document?.getElementById('xapi-file-input')?.click();
  };

  handleDropdownChange = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      }, () => {
        switch (name) {
          case "selectedCourseValue":
            this.setState({ selectedCourseError: false, selectedCourseMsg: "" });
            this.handleGetTopicDropdownListApi();
            break;

          case "selectedTopicValue":
            this.setState({ selectedTopicError: false, selectedTopicMsg: "" });
            this.handleGetLessonDropdownListApi();
            break;

          case "selectedLessonValue":
            this.setState({ selectedLessonError: false, selectedLessonMsg: "" });
            break;
          
          case "selectedAllowedAttempts":
            this.setState({
              selectedAllowedAttemptsError:false,
              selectedAllowedAttemptsErrorMsg:""
            })
            break;

          default:
            break;
        }
      });
    }
  };

  renderGradingComponentDropdownValue = (selected: any) => {
    const dropdownName = "Quiz";
    const dropdownItems = this.state.gradingComponentData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderCourseDropdownValue = (selected: any) => {
    const dropdownName = "Select Course";
    const dropdownItems = this.state.courseDropdownData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderTopicDropdownValue = (selected: any) => {
    const dropdownName = "Select Topic";
    const dropdownItems = this.state.topicDropdownData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderLessonDropdownValue = (selected: any) => {
    const dropdownName = "Select Lesson";
    const dropdownItems = this.state.lessonDropdownData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderAllowedAttemptsDropdownValue = (selected: any) => {
    const dropdownName = "Select Value";
    const dropdownItems = this.state.allowedAttemptsData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
    if (!selected || selected.length === 0) {
      return dropdownName;
    }
    const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
    return selctedOption?.label;
  }

  handleShowCorrectAnswersChange = () => {
    this.setState({ showCorrectAnswers: !this.state.showCorrectAnswers });
  }

  handleActivityTypeRadioDynamicClass = (activityType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedActivityType === activityType) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }

  handleActivityTypeLabelDynamicClass = (activityType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedActivityType === activityType) {
      return `${classes.radioButtonLabel} ${classes.checkedLabelColor}`;
    } else {
      return `${classes.radioButtonLabel}`;
    }
  }

  handleEvaluationTypeRadioDynamicClass = (evaluationType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedEvaluationType === evaluationType) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }

  handleObjectiveTypeRadioDynamicClass = (objectiveType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedObjectiveType === objectiveType) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }

  handleEvaluationTypeLabelDynamicClass = (evaluationType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedEvaluationType === evaluationType) {
      return `${classes.radioButtonLabel} ${classes.checkedLabelColor}`;
    } else {
      return `${classes.radioButtonLabel}`;
    }
  }

  handleObjectiveTypeLabelDynamicClass = (objectiveType: string) => {
    const classes = this.props.classes;
    if (this.state.selectedObjectiveType === objectiveType) {
      return `${classes.radioButtonLabel} ${classes.checkedLabelColor}`;
    } else {
      return `${classes.radioButtonLabel}`;
    }
  }

  handleShowCorrectAnswerDynamicClass = () => {
    const classes = this.props.classes;
    if (this.state.showCorrectAnswers) {
      return `${classes.activeShowCorrecAnswer} ${classes.fontText15}`;
    } else {
      return `${classes.fontText15} ${classes.textCommonColor}`;
    }
  }


  handleVideoAudioDescPlacementClass = (currentValue: string) => {
    const classes = this.props.classes;
    const { selectedVideoDescPlacement, selectedAudioDescPlacement } = this.state;
    if (selectedVideoDescPlacement === currentValue || selectedAudioDescPlacement === currentValue) {
      return `${classes.radio} ${classes.checked}`;
    } else {
      return `${classes.radioUnchecked} ${classes.checked}`;
    }
  }

  handleOpenPreviewDialog = () => {
    if (this.handleFormValidation()) {
      if (this.state.selectedActivityType === "Video") {
        this.handleOpenVideoPreviewDialog();
      } else if (this.state.selectedActivityType === "Audio") {
        this.handleOpenAudioPreviewDialog();
      } else {
        this.handleCloseAudioPreviewDialog();
        this.handleCloseVideoPreviewDialog();
      }
    }
  }

  handleCloseVideoPreviewDialog = () => {
    this.setState({ openVideoPreviewDialog: false });
  }

  handleOpenVideoPreviewDialog = () => {
    this.setState({ openVideoPreviewDialog: true });
  }

  handleCloseAudioPreviewDialog = () => {
    this.setState({ openAudioPreviewDialog: false });
  }

  handleOpenAudioPreviewDialog = () => {
    this.setState({ openAudioPreviewDialog: true });
  }

  handlePasteUrlValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ pastUrlValue: event.target.value });
  }

  handleDueDateChange = (date: any) => {
    this.setState({ dueDateInputType: "date", dueDateValue: date });

    if (date) {
      this.setState({ dueDateError: false, dueDateErrorMsg: "" });
    }
  }

  setDueDateTypeText = () => {
    this.setState({ dueDateInputType: "text" });
  }

  handleCloseExistingActivityModal = () => {
    this.setState({ openExistingActivityModal: false });
  }

  handleOpenExistingActivityModal = () => {
    this.setState({ openExistingActivityModal: true });
  }

  forAssessmentObjective = () => {
    const selectedActivityType = this.state.selectedActivityType;
    const selectedEvaluationType = this.state.selectedEvaluationType;
    if (selectedActivityType === "Assessment" && selectedEvaluationType === "Objective") {
      return true;
    } else {
      return false;
    }
  }

  forAnyEvaluationType = () => {
    const selectedEvaluationType = this.state.selectedEvaluationType;
    const selectedActivityType = this.state.selectedActivityType;
    if (selectedActivityType === "Assessment" && (selectedEvaluationType === "Objective" || selectedEvaluationType === "Subjective" || selectedEvaluationType === "Manual")) {
      return true;
    } else {
      return false;
    }
  }

  setDynamicGridForProject = () => {
    const selectedGradingComponent = this.state.selectedGradingComponent;
    if (selectedGradingComponent === "Project") {
      return 4;
    } else {
      return 6;
    }
  }

  forAnyGradingComponent = () => {
    const selectedGradingComponent = this.state.selectedGradingComponent;
    if (selectedGradingComponent === "Quiz" || selectedGradingComponent === "Exam" || selectedGradingComponent === "Assignment" || selectedGradingComponent === "Project") {
      return true;
    } else {
      return false;
    }
  }

  forObjectiveAssessmentExam = () => {
    const selectedEvaluationType = this.state.selectedEvaluationType;
    const selectedGradingComponent = this.state.selectedGradingComponent;
    if (selectedEvaluationType === "Objective" && (selectedGradingComponent === "Assignment" || selectedGradingComponent === "Exam")) {
      return true;
    } else {
      return false;
    }
  }

  resetActivityType = () => {
    this.setState({ selectedActivityType: '' });
  }

  resetEvaluationType = () => {
    this.setState({ selectedEvaluationType: '' });
  }

  resetObjectiveType = () => {
    this.setState({ selectedObjectiveType: '' });
  }

  resetGradedComponentType = () => {
    this.setState({ selectedGradingComponent: '' });
  }

  resetAllRadioButtonTypes = () => {
    this.setState({ selectedActivityType: '' });
    this.setState({ selectedEvaluationType: '' });
    this.setState({ selectedObjectiveType: '' });
    this.setState({ selectedGradingComponent: '' });
  }

  clearSearchActivityText = () => {
    this.setState({ searchActivity: '' });
  }

  resetSelectedVideoDescPlacement = () => {
    this.setState({ selectedVideoDescPlacement: '' });
  }

  resetSelectedDueDate = () => {
    this.setState({ selectedDueDate: '' });
  }

  resetPastUrlValue = () => {
    this.setState({ pastUrlValue: '' });
  }

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });


    if (name === "activityTitleValue" && value) {
      this.setState({ activityTitleError: false, activityTitleMsg: "" });
    }

    if (name === "dueDateValue" && value) {
      this.setState({ dueDateError: false, dueDateErrorMsg: "" });
    }

    if (name === "maxScoreValue" && value) {
      this.setState({ maxScoreError: false, maxScoreErrorMsg: "" });
    }
  };

  handleTextEditorChange = (content: any) => {
    const { selectedActivityType } = this.state;
    switch (selectedActivityType) {
      case "Task":
        this.setState({ taskValue: content });
        if (content) {
          this.setState({ taskError: false, taskErrorMsg: "" });
        }
        break;

      case "Video":
      case "Audio":
        this.setState({ descriptionValue: content });
        if (content) {
          this.setState({ descriptionError: false, descriptionErrorMsg: "" });
        }
        break;

      case "Assessment":
        this.setState({ instructionValue: content });
        if (content) {
          this.setState({ instructionError: false, instructionErrorMsg: "" });
        }
        break;

      default:
        break;
    }
  }

  handleSubmitForm = () => {
    if (this.handleFormValidation()) {
      const {
        activityTitleValue,
        selectedActivityType,
        taskValue,
        descriptionValue,
        instructionValue,
        selectedMediaFile,
        selectXAPI,
        xapiMediaFile,
        selectedEvaluationType,
        selectedObjectiveType,
        selectedGradingComponent,
        videoDescValue,
        audioDescValue,
        showCorrectAnswers,
        selectedAllowedAttempts,
        selectedDurationValue,
        maxScoreValue,
        dueDateValue,
        selectedLessonValue
      } = this.state;

      const { isEdit } = this.props;

      const requestBody:FormData= {
        lesson_id: selectedLessonValue,
        activities_title: activityTitleValue,
        activity_type: selectedActivityType.toLowerCase(),
        evolution_type: selectedEvaluationType.toLocaleLowerCase(),
        grading_component: selectedGradingComponent.toLocaleLowerCase(),
        task: taskValue,
        description: descriptionValue,
        instruction: instructionValue,
        media_file: selectedMediaFile,
        xapi: selectXAPI,
        xapi_file: xapiMediaFile,
        video_desc_placement: videoDescValue,
        audio_desc_placement: audioDescValue,
        correct_answer: showCorrectAnswers,
        duration: selectedDurationValue,
        allowed_attempts: selectedAllowedAttempts,
        max_score: maxScoreValue,
        due_date: dueDateValue,
      }

      if (isEdit && selectedMediaFile && selectedMediaFile.filename) {
        delete requestBody.media_file;
      }

      if (isEdit && xapiMediaFile && xapiMediaFile.filename) {
        delete requestBody.xapi_file;
      }
      if (requestBody.evolution_type === "objective") {
        requestBody.objective_type = selectedObjectiveType.toLowerCase();
      }
      

      let formData = this.createFormData(requestBody);
      this.handleCallback(formData);
    }
  }

  createFormData(requestBody: object) {
    let formData = new FormData();
    for (const [key, value] of Object.entries(requestBody)) {
      if (key === "media_file" || key === "xapi_file") {
        if (value) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }

  handleCallback = (formData: any) => {
    const { isEdit, handleAddNewActivity, handleUpdateActivity } = this.props;
    if (isEdit) {
      handleUpdateActivity(formData);
    } else {
      handleAddNewActivity(formData);
    }
  }

  handleFormValidation = () => {
    let isValid = true;
    const {
      selectedActivityType,
      activityTitleValue,
      selectedGradingComponent,
      descriptionValue,
      taskValue,
      selectedMediaFile,
      selectXAPI,
      xapiMediaFile,
      instructionValue,
      dueDateValue,
      maxScoreValue,
      selectedCourseValue,
      selectedTopicValue,
      selectedLessonValue,
      selectedAllowedAttempts
    } = this.state;

    const { isEdit } = this.props;

    const checkValidations: Array<any> = [
      { id: 1, validation: this.checkActivityTitle(activityTitleValue) },
      { id: 2, validation: this.checkDescription(descriptionValue, selectedActivityType) },
      { id: 3, validation: this.checkTask(taskValue, selectedActivityType) },
      { id: 4, validation: this.checkInstruction(instructionValue, selectedActivityType) },
      { id: 5, validation: this.checkMediaFile(selectedMediaFile, selectedActivityType, isEdit) },
      { id: 6, validation: this.checkXapiMediaFile(selectXAPI, xapiMediaFile) },
      { id: 7, validation: this.checkDueDate(dueDateValue, selectedActivityType, selectedGradingComponent) },
      { id: 8, validation: this.checkMaxScore(maxScoreValue, selectedActivityType) },
      { id: 9, validation: this.checkCourseValue(selectedCourseValue) },
      { id: 10, validation: this.checkTopicValue(selectedTopicValue) },
      { id: 11, validation: this.checkLessonValue(selectedLessonValue) },
      { id: 12, validation: this.checkselectedAllowedAttemptsValue(selectedAllowedAttempts) },
    ];

    checkValidations.forEach((item: any) => {
      if (item.validation !== undefined) {
        isValid = item.validation;
      }
    });

    return isValid;
  }

  checkActivityTitle(activityTitleValue: string) {
    if (!activityTitleValue.trim()) {
      this.setState({ activityTitleError: true, activityTitleMsg: "Please enter activity title" });
      return false;
    }
  }

  checkDescription(descriptionValue: string, selectedActivityType: string) {
    if (!descriptionValue.trim() && (selectedActivityType === "Video" || selectedActivityType === "Audio")) {
      this.setState({ descriptionError: true, descriptionErrorMsg: "Please enter description", });
      return false;
    }
  }

  checkTask(taskValue: string, selectedActivityType: string) {
    if (!taskValue.trim() && selectedActivityType === "Task") {
      this.setState({ taskError: true, taskErrorMsg: "Please enter task", });
      return false;
    }
  }

  checkInstruction(instructionValue: string, selectedActivityType: string) {
    if (!instructionValue.trim() && selectedActivityType === "Assessment") {
      this.setState({ instructionError: true, instructionErrorMsg: "Please enter instruction", });
      return false;
    }
  }

  checkMediaFile(selectedMediaFile: any, selectedActivityType: string, isEdit: boolean) {
    if (!selectedMediaFile && (selectedActivityType === "Video" || selectedActivityType === "Audio") && !isEdit) {
      this.setState({ mediaFileError: true, mediaFileErrorMsg: "Please select file", });
      return false;
    }
  }

  checkXapiMediaFile(selectXAPI: boolean, xapiMediaFile: any) {
    if (selectXAPI && !xapiMediaFile) {
      this.setState({ xampiFileError: true, xampiFileErrorMsg: "Please select file", });
      return false;
    }
  }

  checkDueDate(dueDateValue: string, selectedActivityType: string, selectedGradingComponent: string) {
    if (!dueDateValue && selectedActivityType === "Assessment" ) {
      this.setState({ dueDateError: true, dueDateErrorMsg: "Please select due date" });
      return false;
    }
  }

  checkMaxScore(maxScoreValue: string, selectedActivityType: string) {
    if (!maxScoreValue && selectedActivityType === "Assessment") {
      this.setState({ maxScoreError: true, maxScoreErrorMsg: "Please enter max score" });
      return false;
    }
  }

  checkCourseValue(value: string) {
    if (!value) {
      this.setState({ selectedCourseError: true, selectedCourseMsg: "Please select course" });
      return false;
    }
  }

  checkTopicValue(value: string) {
    if (!value) {
      this.setState({ selectedTopicError: true, selectedTopicMsg: "Please select topic" });
      return false;
    }
  }

  checkLessonValue(value: string) {
    if (!value) {
      this.setState({ selectedLessonError: true, selectedLessonMsg: "Please select lesson" });
      return false;
    }
  }
  checkselectedAllowedAttemptsValue(value:string){
    if (!value) {
      this.setState({ 
        selectedAllowedAttemptsError:true,
        selectedAllowedAttemptsErrorMsg:"Please select allowed attempts"
       });
      return false;
    }
  }

  handleGetActivityByIdApi = async (activityId: any) => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActivityDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewActivity}/${activityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetActivityDataByIdResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const activity = responseJson?.data?.attributes;
      this.setState({
        activityTitleValue: activity?.activities_title,
        selectedActivityType: this.capitalize(activity?.activity_type),
        selectXAPI: activity?.xapi_file_url ? true : false,
        xapiMediaFile: activity?.xapi_file_url,
        selectedMediaFile: activity?.media_file_url,
        descriptionValue: activity?.description,
        selectedEvaluationType: this.capitalize(activity?.evolution_type),
        selectedObjectiveType: this.capitalize(activity?.objective_type),
        selectedGradingComponent: this.capitalize(activity?.grading_component),
        showCorrectAnswers: activity?.correct_answer ? activity?.correct_answer : false,
        selectedDurationValue: activity?.duration,
        maxScoreValue: activity?.max_score,
        selectedAllowedAttempts: activity?.allowed_attempts || 0,
        dueDateValue: activity?.due_date,
        instructionValue: activity?.instruction,
        taskValue: activity.task,
      }, () => {
        this.handleSetVideoDescValue(activity?.video_desc_placement);
        this.handleSetAudioDescValue(activity?.audio_desc_placement);
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }

  capitalize = (word: string) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return '';
    }
  }

  handleSetVideoDescValue = (apiVideoDescValue: string) => {
    switch (apiVideoDescValue) {
      case "video_desc_above":
        this.setState({
          selectedVideoDescPlacement: "VideoBottomTextTop",
          videoDescValue: "video_desc_above",
          videoPreviewPosition: 'bottom',
          textPreviewPosition: 'top',
        });
        break;

      case "video_desc_left":
        this.setState({
          selectedVideoDescPlacement: "VideoRightTextLeft",
          videoDescValue: "video_desc_left",
          videoPreviewPosition: 'right',
          textPreviewPosition: 'left',
        });
        break;

      case "video_desc_right":
        this.setState({
          selectedVideoDescPlacement: "VideoLeftTextRight",
          videoDescValue: "video_desc_right",
          videoPreviewPosition: 'left',
          textPreviewPosition: 'right',
        });
        break;

      case "video_desc_below":
      default:
        this.setState({
          selectedVideoDescPlacement: "VideoTopTextBottom",
          videoDescValue: "video_desc_below",
          videoPreviewPosition: 'top',
          textPreviewPosition: 'bottom',
        });
        break;
    }
  }

  handleSetAudioDescValue = (apiAudioDescValue: string) => {
    switch (apiAudioDescValue) {
      case "audio_desc_below":
        this.setState({
          selectedAudioDescPlacement: "AudioTopTextBottom",
          audioDescValue: "audio_desc_below",
          audioPreviewPosition: 'top',
          audioTextPreviewPosition: 'bottom',
        });
        break;

      case "audio_desc_above":
      default:
        this.setState({
          selectedAudioDescPlacement: "AudioBottomTextTop",
          audioDescValue: "audio_desc_above",
          audioPreviewPosition: 'bottom',
          audioTextPreviewPosition: 'top',
        });
        break;
    }
  }

  getSelectedMediaFileName = () => {
    const { selectedMediaFile } = this.state;
    if (selectedMediaFile && selectedMediaFile.name) {
      return selectedMediaFile.name;
    } else if (selectedMediaFile && selectedMediaFile.filename) {
      return selectedMediaFile.filename;
    } else {
      return '';
    }
  }

  getXapiMediaFileName = () => {
    const { xapiMediaFile, pastUrlValue } = this.state;
    if (xapiMediaFile && xapiMediaFile.name) {
      return xapiMediaFile.name;
    } else if (xapiMediaFile && xapiMediaFile.filename) {
      return xapiMediaFile.filename;
    } else {
      return pastUrlValue;
    }
  }


  handleGetActivityListApi = async (searchQuery: any) => {
    this.showModalLoader();
    const { lesson } = this.props;
    const baseUrl = `${configJSON.postAddNewActivity}/${lesson.id}/get_activity`;
    let requestUrl = baseUrl;
    if (searchQuery.title) {
      requestUrl = requestUrl + `?title=${searchQuery.title}`
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActivityListData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetActivityListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const { lesson, topic, course } = this.props;
      const courseData = course.attributes;
      const activityListData = responseJson.data.map((item: any) => {
        const activity = item.attributes;
        return {
          id: item.id,
          Title: {
            name: activity?.activities_title,
            icon: courseData?.subject ? courseData?.subject?.icon : '',
            bgColor: courseData?.subject ? courseData?.subject?.color : '#000',
            boxWidth: "32px",
            boxHeight: "32px",
          },
          Lesson: lesson?.lessonTitle,
          Topic: topic?.topicTitle,
          Course: courseData?.course_title,
          Subject: courseData?.subject?.name,
          Grade: courseData?.grade?.name,
        }
      });

      console.log("activity list: ", activityListData);

      this.setState({ activitySearchData: activityListData }, () => {
        this.handleOpenExistingActivityModal();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.hideModalLoader();
  };

  handleCopyExistingActivity = (id: any) => {
    this.setState({ searchActivity: '' });
    this.handleGetActivityByIdApi(id);
  }

  handleGetCourseDropdownDataApi = async () => {
    this.showModalLoader();
    const apiConfig = this.props.currentRole === "parent teacher" ? configJSON.getParentTeacherCourseEndpoint :configJSON.getTeacherCoursesEndpoint ;
    let requestUrl = apiConfig + `?per_page=500&page=1`;
    if(this.props.currentRole !== "parent teacher" && this.props.classIds){
      requestUrl = requestUrl + `&class_id=[${this.props.classIds}]`
    }
    if(this.props.currentRole === "parent teacher" && this.props.studentId){
      requestUrl = requestUrl + `&student_id=${this.props.studentId}`
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCourseDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCourseDropdownDataApiResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      let courseList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: item?.id,
            label: item?.attributes?.course_title,
            value: item?.id,
          }
        }
      );
      this.setState({
        courseDropdownData: courseList || [],
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleGetTopicDropdownListApi = async () => {
    this.showModalLoader();
    this.setState({ topicDropdownData: [], lessonDropdownData: [], selectedTopicValue: "", selectedLessonValue: "" });

    const { selectedCourseValue } = this.state;
    const baseUrl = `${configJSON.postAddNewTopic}?course_id=${selectedCourseValue}&course_management=true`;
    let requestUrl = baseUrl;

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTopicDropdownList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTopicDropdownListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      let topicList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: item?.id,
            label: item?.attributes?.topic_title,
            value: item?.id,
          }
        }
      );
      this.setState({
        topicDropdownData: topicList || [],
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.hideModalLoader();
  };

  handleGetLessonDropdownListApi = async () => {
    this.showModalLoader();
    this.setState({ lessonDropdownData: [], selectedLessonValue: "" });

    const { selectedTopicValue } = this.state;
    const baseUrl = `${configJSON.postAddNewLesson}?topic_id=${selectedTopicValue}&course_management=true`;
    let requestUrl = baseUrl;

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLessonDropdownList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetLessonDropdownListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      let lessonList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: item?.id,
            label: item?.attributes?.lesson_title,
            value: item?.id,
          }
        }
      );
      this.setState({
        lessonDropdownData: lessonList || [],
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.hideModalLoader();
  };
  // Customizable Area End
}
// Customizable Area End