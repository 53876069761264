// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import AssessmentTopPaginationController, {
  Props,
} from "./AssessmentTopPaginationController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    questionPageNum: {
      padding: "22px 25px",
      borderRadius: "12px",
      maxHeight: "800px",
      overflow: "auto",
    },
    unanswered: {
      backgroundColor: "#FBAD66",
      borderRadius: "50%",
      display: "flex",
      opacity: "0.5",
      alignItems: "center",
      justifyContent: "center",
      color: "#ffffff",
      fontWeight: 600,
    },
    answered: {
      backgroundColor: "#FDB06A",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ffffff",
      fontWeight: 600,
    },
    answerColorBox: {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    iconBtn: {
      width: "inherit",
      height: "inherit",
      color: "#ffffff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    topMargin: {
      marginTop: "4%",
    },
    leftMargin: {
      marginLeft: "1%",
    },
    optionGrid: {
      display: "flex",
      alignItems: "center",
    },
    dueQuizMainGrid: {
      width: "98%",
    },
  });

// Customizable Area End

// Customizable Area Start
export class AssessmentTopPagination extends AssessmentTopPaginationController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, totalQuestionNumber, isCurrentQuestionAttempted } =
      this.props;
    return (
      <Box className={`${classes.bgColor} ${classes.questionPageNum}`}>
        <Box>
          <Typography
            className={`${classes.textPrimaryColor} ${classes.fontText20}`}
          >
            Questions
          </Typography>
        </Box>
        <Box
          marginTop={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Box width={"108px"} className={`${classes.answerColorBox}`}>
            <Box marginRight={"5px"}>
              <Box
                width={"20px"}
                height={"20px"}
                className={`${classes.unanswered}`}
              ></Box>
            </Box>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText14}`}
            >
              Unanswered
            </Typography>
          </Box>
          <Box
            marginLeft={"3%"}
            width={"92px"}
            className={`${classes.answerColorBox}`}
          >
            <Box marginRight={"5px"}>
              <Box
                width={"20px"}
                height={"20px"}
                className={`${classes.answered}`}
              ></Box>
            </Box>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText14}`}
            >
              Answered
            </Typography>
          </Box>
        </Box>
        <Box
          marginTop={"20px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {_.range(totalQuestionNumber).map((item: number) => (
            <Box
              data-testid={`page-number-${item + 1}`}
              key={item}
              marginRight={"10px"}
              width={"38px"}
              height={"37px"}
              className={
                isCurrentQuestionAttempted(item)
                  ? `${classes.answered}`
                  : `${classes.unanswered}`
              }
            >
              <Box className={`${classes.fontText18} ${classes.iconBtn}`}>
                <Typography component="span">{item + 1}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentTopPagination);
// Customizable Area End
