// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { createTheme } from "@material-ui/core/styles";
import { setStorageData } from "../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
// Define interface for custom variables
interface CustomBackgroundThemeProps {
  bgColor: string;
  secondaryBgColor: string;
  cardBgColor: string;
  cardBgColor02: string;
  highlightBgColor: string;
  audioPlayerCardBgColor: string;
  secondaryInfoBgColor: string;
  gradeBgGreenColor: string;
}

interface CustomTextColorThemeProps {
  primaryColor: string;
  secondaryColor: string;
  commonColor: string;
  lightGreyColor: string;
  textClickColor: string;
}

interface CustomContainedButtonColorThemeProps {
  primaryColor: string;
  customizedColor?:string
}

declare module "@material-ui/core/styles" {
  interface ThemeOptions {
    customBackground?: CustomBackgroundThemeProps;
    customText?: CustomTextColorThemeProps;
    customButton?: CustomContainedButtonColorThemeProps;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id?: string;
  classes?: any;
  children?: any;
  keepSidebarOpen: boolean;
  isSmallScreen: boolean;
  isDarkMode: boolean;
  handleToggleThemeSettings: any;
  toggleSidebar: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebAppLayoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
