// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  newPassword: string;
  confirmPassword: string;
  isPasswordSaved: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CreateNewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiResetPassword: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      newPassword: "",
      confirmPassword: "",
      isPasswordSaved: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleVerifyPassword = () => {
    const { confirmPassword, newPassword } = this.state;
    let isValid = true;
    if (!newPassword.trim()) {
      toast.error(TOASTER_NOTIFICATION_MSG.EMPTY_RESET_PASSWORD);
      isValid = false;
    } else if (!confirmPassword.trim()) {
      toast.error(TOASTER_NOTIFICATION_MSG.EMPTY_RESET_CONFIRM_PASSWORD);
      isValid = false;
    } else if (!configJSON.passwordRegEx.test(newPassword.replace(" ", "@"))) {
      toast.info(TOASTER_NOTIFICATION_MSG.PASSWORD_STRENGTH);
      isValid = false;
    } else if (confirmPassword !== newPassword) {
      toast.error(TOASTER_NOTIFICATION_MSG.CONFIRM_PASSWORD_MISMATCH);
      isValid = false;
    }
    return isValid;
  };

  handleSave = async () => {
    if (this.handleVerifyPassword()) {
      showCustomLoader();
      const { newPassword } = this.state;
      const otpAuthToken = await getStorageData("otpAuthToken");
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        token: otpAuthToken || "",
      };

      const httpBody = {
        data: {
          attributes: {
            password: newPassword,
          },
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiResetPassword = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resetPassword
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleLogin = () => {
    this.props.navigation.navigate("AccountLogin");
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on receive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiResetPassword) {
          hideCustomLoader();
          if (responseJson?.message) {
            toast.success(TOASTER_NOTIFICATION_MSG.RESET_PASSWORD_SUCCESSFUL);
            setTimeout(() => {
              this.props.navigation.navigate("AccountLogin");
            }, 2000);
            removeStorageData("otpAuthToken");
          } else {
            //Check Error Response
            displayApiErrorMsg(responseJson?.errors, this.props.navigation)
          }
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
