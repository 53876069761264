import React, { useState, useRef, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomSelectDropdown from "./CustomSelectDropdown.web";
import DropdownIcon from "./SVGIcons/DropdownIcon.web";
import CustomButton from './CustomButton.web';
import CloseIcon from '@material-ui/icons/Close';
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";

const useStyles = (theme: Theme) =>
  createStyles({
    dropzone: {
      border: `3px dashed #FFD92E`,
      borderRadius: "12px",
      padding: theme.spacing(2),
      width: 460,
      height: 260,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    dragging: {
      borderColor: theme.palette.text.disabled,
    },
    dropIcon: {
      fontSize: 18,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
    defaultIcon: {
    },
    fileInput: {
      display: 'none',
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
    },
    closeIcon: {
      position: 'absolute',
      cursor: 'pointer',
      top: theme.spacing(2.6),
      right: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(5),
        right: theme.spacing(4),
        width: theme.spacing(1.5),
      },
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      color: "#090909",
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px"
      },
    },
    fileImage: {
      marginBottom: "20px",
    },
    fileUploadText: {
      fontSize: 20,
      fontWeight: 600,
      color: "#090909",
    },
    fileUploadSubText: {
      fontSize: 16,
      fontWeight: 400,
      color: "#888888",
    }
  });

const addBtnStyle = {
  color: "#FFFFFF",
  borderRadius: "12px",
  padding: "15px 36px",
  fontSize: "16px",
  fontWeight: 600
} as const;

const cancelbtnStyle = {
  marginRight: "15px",
  borderRadius: "12px",
  padding: "15px 36px",
  fontSize: "16px",
  fontWeight: 600,
  border: "1px solid",
} as const;


interface CustomDragFileUploadDialogProps {
  onFileDrop: (file: File) => void;
  open: boolean;
  handleClose: () => void;
  classes?: any;
  onConfirm?:any;
  message?:string;
  downloadCSVFile: () => Promise<void>
}

const CustomDragFileUploadDialog: React.FC<CustomDragFileUploadDialogProps> = ({ onFileDrop, open, handleClose, classes,onConfirm,message,downloadCSVFile }) => {

  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectOption, setSelectOption] = useState<string>("Class Management");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false); 
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      onFileDrop(file); 
      setUploadedFile(file);
    } else {
      console.log('Invalid file type. Only CSV files are allowed.');
    }
  };

  const handleOpenFileBrowse = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files && e?.target?.files[0];
    if (file && file.type === 'text/csv') {
      onFileDrop(file);
      setUploadedFile(file);
    } else {
      console.log('Invalid file type. Only CSV files are allowed.');
    }
  };
  const handleSubmit =()=>{
    if (!uploadedFile) {
      console.log("No file uploaded");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", uploadedFile);

    onConfirm(formData)
  }
  useEffect(() => {
    if (open) {
      setUploadedFile(null);
    }
  }, [open]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        className={`${classes.dialogRoot} ${classes.highlightBgColor}`}
      >
        <DialogTitle id="alert-dialog-title" className={`${classes.bgColor}`}>
          <Typography className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}>Import CSV</Typography>
          <CloseIcon
            id="An icon to close Modal"
            className={`${classes.textCommonColor} ${classes.closeIcon}`}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent className={`${classes.bgColor}`}>
          <Box style={{ marginBottom: "20px" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={`${classes.fontText14} ${classes.textCommonColor}`}>Select Option</Typography>
              <Typography  onClick={downloadCSVFile} className={`${classes.fontText14} ${classes.fontBold600} ${classes.customizedColor} ${classes.cursorPointer} ${classes.textAlignRight}`}>Download {message} CSV Template</Typography>
            </Box>
            <CustomSelectDropdown
              fieldId="select-dropdown-option"
              fieldValue={selectOption}
              fieldName="selectOption"
              handleChange={(e: any) => console.log(e)}
              renderValue={() => message}
              fieldError={false}
              fieldMenuItems={[{ id: 1, label: message, value: message }]}
              fieldStyles={{ width: "100%" }}
              iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
            />
          </Box>
          <div
            className={`${classes.dropzone} ${isDragging ? classes.dragging : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleOpenFileBrowse}
          >
            {isDragging ? (
              <div className={classes.dropIcon}>
                <img className={classes.fileImage} alt="csv-file-icon" src={require("./csv-icon.png")} />
                <Typography
                  className={`${classes.fontText20} ${classes.textPrimaryColor
                    } ${classes.fontBold600}`}
                >
                  Browse CSV file to upload{" "}
                </Typography>
                <Typography
                  className={`${classes.fontText18} ${classes.textCommonColor
                    }`}
                >
                  or drag and drop it here
                </Typography>
              </div>
            ) : (
              <>
                {uploadedFile ? (
                  <>
                    <div   className={`${classes.fontText18} ${classes.textCommonColor
                        } ${classes.dropIcon}`} >{uploadedFile.name}</div>
                  </>
                ) : (
                  <div className={`${classes.dropIcon} ${classes.defaultIcon}`}>
                    <img className={classes.fileImage} alt="csv-file-icon" src={require("./csv-icon.png")} />
                    <Typography
                      className={`${classes.fontText20} ${classes.textPrimaryColor
                        } ${classes.fontBold600}`}
                    >
                      Browse CSV file to upload{" "}
                    </Typography>
                    <Typography
                      className={`${classes.fontText18} ${classes.textCommonColor
                        }`}
                    >
                      or drag and drop it here
                    </Typography>
                  </div>
                )}
              </>
            )}
            <input
              type="file"
              ref={fileInputRef}
              className={classes.fileInput}
              accept=".csv"
              onChange={handleFileInputChange}
            />
          </div>
          <Box style={{ display: "flex", margin: "30px 0", justifyContent: "end" }}>
            <CustomButton btnStyle={cancelbtnStyle} btnText='Cancel' buttonId='school-year-cancel-btn' buttonVariant={"outlined"} isDisabled={false} type={"button"} handleButtonClick={handleClose} />
            <CustomButton btnStyle={addBtnStyle} btnText='Upload' buttonId='school-year-add-btn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={handleSubmit} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const combinedStyle = combineStyles(customThemeStyles, useStyles);
export default withStyles(combinedStyle)(CustomDragFileUploadDialog);