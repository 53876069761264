// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TenantCourseManagementMainTabComponentController, {
  Props,
} from "./TenantCourseManagementMainTabComponentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable from "../../../components/src/CustomTable.web";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dropdownGrid: {
      padding: "0px 18px 8px 22px",
      borderBottom: "4px solid #f0f0f0",
    },
    selectDD: {
      marginBottom: "15px",
    },
    courseMngSelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    courseListTableMainBox: {
      padding: "0px 32px 0px 22px",
    },
    courseAvailableListTableMainBox: {
      padding: "0px 15px",
    },
    searchBtn: {
      display: "flex",
      alignItems: "flex-end",
    },
    tableBoxMaxHeight: {
      maxHeight: "100%",
    },
    paginationBox: {
      padding: "10px 20px 10px 21px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      '& .Mui-selected': {
        color: "#FFFFFF",
        backgroundColor: '#FFD92E !important',
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
    },
  });

const tenantCourseTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px",
      },
    },
  },
});

const otherCourseTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "20px !important",
        paddingBottom: "20px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TenantCourseManagementMainTabComponent extends TenantCourseManagementMainTabComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      tenantCourseMngTabCategory,
      tableBodyData,
      tableHeaderColumns,
      showTablePagination,
      inputDDConfigs,
      tableActionButtonConfig,
      showMenuIconInTable,
      addTablePadding,
      pagination,
      tableRowClickAction,
      rowWiseTableActionConfig,
      showRowWiseActionMenu,
      requestIcons
    } = this.props;

    return (
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid
              className={`${classes.dropdownGrid}`}
              container
              direction="row"
            >
              {inputDDConfigs.map((ddItem: IInputConfigProps) => (
                <Grid
                  id={`tenant-course-mng-dd-grid-${ddItem.id}`}
                  key={ddItem.id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <Box
                    id={`tenant-course-mng-dd-box-${ddItem.id}`}
                    className={`${classes.selectDD}`}
                  >
                    <Box marginBottom={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component={"span"}
                        id={`tenant-course-mng-dd-label-${ddItem.id}`}
                      >
                        {ddItem.label}
                      </Typography>
                    </Box>
                    <Box className={`${classes.courseMngSelectDDWidth}`}>
                      <CustomSelectDropdown
                        fieldId={ddItem.id}
                        fieldValue={ddItem.value}
                        fieldName={ddItem.name}
                        handleChange={this.props.handleSelectDropdownValue}
                        fieldStyles={dropdownStyle}
                        renderValue={(selected: any) =>
                          this.props.renderDDMenuItem(
                            selected,
                            ddItem.fieldName,
                            ddItem.label
                          )
                        }
                        fieldError={false}
                        fieldMenuItems={ddItem.menuItems}
                        isDisabled={ddItem.isDisabled}
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
              <Grid
                className={`${classes.searchBtn}`}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Box
                  marginBottom={"17px"}
                  className={`${classes.courseMngSelectDDWidth}`}
                >
                  <CustomButton
                    buttonId={`tenant-course-mng-${tenantCourseMngTabCategory}-filter-search-btn`}
                    btnStyle={searchBtnStyle}
                    btnText="Search"
                    buttonVariant={"contained"}
                    handleButtonClick={this.props.handleSearch}
                    isDisabled={false}
                    type={"button"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={
                addTablePadding
                  ? `${classes.courseListTableMainBox} ${classes.tableBoxMaxHeight}`
                  : `${classes.courseAvailableListTableMainBox} ${classes.tableBoxMaxHeight}`
              }
            >
              {
                tableBodyData.length === 0 ?
                  <CustomEmptyResultComponent message="You don't have any course for filtered options. <br/> Try changing selected options or <b>Add new course</b>" />
                  :
                  <ThemeProvider theme={showMenuIconInTable ? tenantCourseTableTheme : otherCourseTableTheme}>                          
                    <CustomTable
                      tableBodyData={tableBodyData}
                      tableHeaderColumns={tableHeaderColumns}
                      tableId={`${tenantCourseMngTabCategory}-tenant-course-management-table`}
                      showMenuIcon={showMenuIconInTable}
                      handleAction={tableRowClickAction}
                      rowWiseTableActionConfig={rowWiseTableActionConfig}
                      showRowWiseActionMenu={showRowWiseActionMenu}
                      requestIconShow={requestIcons}
                    />                    
                  </ThemeProvider>
              }
            </Box>
          </Grid>
          {tableBodyData.length > 0 && (
            <Grid item xs={12}>
              <Box className={classes.paginationBox}>

                <Box style={{ marginTop: '23px', width: '50%' }}>
                  <Box display={"flex"} gridGap={"6px"}>
                    <Typography
                      component="span"
                      className={`${classes.fontText18} ${classes.textCommonColor}`}
                    >
                      Records
                    </Typography>
                    <Typography
                      component="span"
                      className={`${classes.fontBold600} ${classes.textCommonColor} ${classes.fontText18}`}
                    >
                      {(pagination?.current_page * pagination?.per_page) - pagination?.per_page}-{pagination?.current_page * pagination?.per_page}
                    </Typography>
                    <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                      of {pagination?.total_count}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                </Box>
                <Box className={classes.paginationRoot}>
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    count={Math.ceil(pagination?.total_count / pagination?.per_page)}
                    siblingCount={0}
                    boundaryCount={1}
                    onChange={this.handlePagination}
                    page={pagination?.current_page}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtnStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementMainTabComponent
);
// Customizable Area End
