// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import { getFieldType } from "../../../components/src/CommonHelper.web";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: () => void;
  handleAddNewCurriculum: any;
  handleUpdateCurriculum: any;
  isEdit?: boolean;
  selectedCurriculumData: any;
  subjectsList: Array<IDropdownMenuItemProps>;
  gradesList: Array<IDropdownMenuItemProps>;
  customFields: Array<any>;
  handleGetSubjectsForGradeSelected: any;
  loading: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  curriculumTitle: string;
  selectGrade: any;
  selectGradeErrorMsg: string;
  selectGradeError: boolean;
  selectCurriculumErrorMsg: string;
  selectCurriculumError: boolean;
  selectSubject: Array<any>;
  selectSubjectError: boolean;
  selectSubjectErrorMsg: string;
  customFieldConfig: Array<IInputConfigProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewCurriculumDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      curriculumTitle: "",
      selectGrade: "",
      selectGradeError: false,
      selectGradeErrorMsg: "",
      selectSubject: [],
      selectSubjectError: false,
      selectSubjectErrorMsg: "",
      selectCurriculumErrorMsg: "",
      selectCurriculumError: false,
      customFieldConfig: [],
    };
    this.handleCustomFieldTextChange =
      this.handleCustomFieldTextChange.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      if (name === "selectGrade") {
        this.setState({
          selectGradeError: false,
          selectGradeErrorMsg: "",
          selectSubject: [],
        });
      }
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
      this.props.handleGetSubjectsForGradeSelected(value);
    }
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        selectCurriculumError: false,
        selectCurriculumErrorMsg: "",
      };
    });
  };

  handleCustomFieldTextChange = (event: any) => {
    const { customFieldConfig } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = customFieldConfig.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        customFieldConfig: updatedInputConfig,
      };
    });
  };

  renderGradeValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Value";
    }
    const { gradesList } = this.props;
    const selectedGrade = gradesList.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGrade?.label;
  };

  handleChangeSubject = (event: any) => {
    this.setState({
      selectSubject: event.target.value,
      selectSubjectError: false,
      selectSubjectErrorMsg: "",
    });
  };

  handleVerifyCurriculumData = () => {
    const { curriculumTitle, selectGrade, selectSubject } = this.state;
    let isValid = true;
    if (!curriculumTitle.trim()) {
      this.setState({
        selectCurriculumError: true,
        selectCurriculumErrorMsg: "Please enter curriculum title",
      });
      isValid = false;
    }
    if (!selectGrade) {
      this.setState({
        selectGradeError: true,
        selectGradeErrorMsg: "Please select a grade",
      });
      isValid = false;
    }
    if (!selectSubject.length) {
      this.setState({
        selectSubjectError: true,
        selectSubjectErrorMsg: "Please select at least 1 subject",
      });
      isValid = false;
    }
    return isValid;
  };

  handleAddCurriculum = async () => {
    if (this.handleVerifyCurriculumData()) {
      const { curriculumTitle, selectGrade, selectSubject, customFieldConfig } =
        this.state;
      const { isEdit } = this.props;
      const customFieldAttributes: Array<any> = customFieldConfig?.map(
        (item) => {
          const obj = {
            value: item?.value,
            custom_field_id: item.id,
          };
          return obj;
        }
      );
      const requestObj = {
        data: {
          type: "curriculum",
          attributes: {
            name: curriculumTitle,
            subject_id: selectSubject,
            grade_id: selectGrade,
            curriculum_custom_fields_attributes: customFieldAttributes,
          },
        },
      };
      if (isEdit) {
        this.props.handleUpdateCurriculum(requestObj);
      } else {
        this.props.handleAddNewCurriculum(requestObj);
      }
    }
  };

  handleSetCurriculumInitialState = async (isEdit?: boolean) => {
    const { customFieldConfig } = this.state;
    if (isEdit) {
      const { selectedCurriculumData } = this.props;
      await this.props.handleGetSubjectsForGradeSelected(
        selectedCurriculumData?.gradeId
      );
      const updatedCustomFieldConfigs = customFieldConfig.map((item: any) => {
        if (item?.label in selectedCurriculumData)
          item.value = selectedCurriculumData[item?.label] || "";
        return item;
      });
      this.setState({
        curriculumTitle: selectedCurriculumData?.curriculum,
        selectGrade: selectedCurriculumData?.gradeId,
        selectGradeError: false,
        selectGradeErrorMsg: "",
        selectSubject: selectedCurriculumData?.subjectIds,
        selectSubjectError: false,
        selectSubjectErrorMsg: "",
        selectCurriculumErrorMsg: "",
        selectCurriculumError: false,
        customFieldConfig: updatedCustomFieldConfigs,
      });
    } else {
      const updatedCustomFieldConfigs = customFieldConfig.map((item: any) => {
        item.value = "";
        return item;
      });
      this.setState(
        {
          curriculumTitle: "",
          selectGrade: "",
          selectGradeError: false,
          selectGradeErrorMsg: "",
          selectSubject: [],
          selectSubjectError: false,
          selectSubjectErrorMsg: "",
          selectCurriculumErrorMsg: "",
          selectCurriculumError: false,
          customFieldConfig: updatedCustomFieldConfigs,
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleSetCustomFields = () => {
    const { customFields, isEdit } = this.props;
    const customFieldsList: Array<IInputConfigProps> = customFields.map(
      (item: any, index: number) => {
        const obj = {
          id: item.id,
          name: `customField-${index + 1}`,
          fieldName: `customField-${index + 1}`,
          value: "",
          label: item?.attributes?.label,
          placeHolderText: `Please enter ${item?.attributes?.label}`,
          fieldType: getFieldType(item?.attributes?.custom_field_type),
          isDisabled: false,
        };
        return obj;
      }
    );
    this.setState({ customFieldConfig: customFieldsList || [] }, () => {
      if (isEdit) this.handleSetCurriculumInitialState(true);
    });
  };

  handleClose = () => {
    this.handleSetCurriculumInitialState();
  };
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetCustomFields();
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
