// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  ITableActionBtnConfigProps,
} from "../../../components/src/CommonType.web";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export interface DropDownValues {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: () => void;
  handleAddNewSchoolCalendar: any;
  handleUpdateSchoolCalendar: any;
  isEdit?: boolean;
  selectedSchoolCalendarData: any;
  schoolYearList: Array<IDropdownMenuItemProps>;
  curriculumList: Array<IDropdownMenuItemProps>;
  weekdaysList: Array<any>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectCurriculum: any;
  selectCurriculumTitle: string;
  selectCurriculumErrorMsg: string;
  selectCurriculumError: boolean;
  curriculumItems: Array<DropDownValues>;
  selectSchoolYear: any;
  selectSchoolYearTitle: string;
  selectSchoolYearErrorMsg: string;
  selectSchoolYearError: boolean;
  selectedSchoolCalendarData: any;
  schoolYearTitleItems: Array<DropDownValues>;
  holidays: Array<any>;
  weekdays: Array<any>;
  holidayActionButtonConfig: Array<ITableActionBtnConfigProps>;
  openAddAnotherHoliday: boolean;
  selectedHolidayData: any;
  isEditHoliday: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewSchoolCalendarDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectCurriculum: "",
      selectCurriculumTitle: "",
      selectCurriculumErrorMsg: "",
      selectCurriculumError: false,
      curriculumItems: this.props.curriculumList,
      selectSchoolYear: "",
      selectSchoolYearTitle: "",
      selectSchoolYearErrorMsg: "",
      selectSchoolYearError: false,
      selectedSchoolCalendarData: this.props.selectedSchoolCalendarData || "",
      schoolYearTitleItems: this.props.schoolYearList,
      holidays: [],
      weekdays: this.props.weekdaysList,
      holidayActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenAddAnotherHoliday(value),
        },
        {
          label: "Remove",
          action: (value: any) => this.handleRemoveHoliday(value),
        },
      ],
      openAddAnotherHoliday: false,
      selectedHolidayData: "",
      isEditHoliday: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
    if (name === "selectCurriculum") {
      const { curriculumItems } = this.state;
      const selectedCurriculum: any = curriculumItems.find(
        (curriculum: any) => curriculum?.value === value
      );
      this.setState({
        selectCurriculumError: false,
        selectCurriculumErrorMsg: "",
        selectCurriculumTitle: selectedCurriculum?.label,
      });
    }
    if (name === "selectSchoolYear") {
      const { schoolYearTitleItems } = this.state;
      const selectedSchoolYear: any = schoolYearTitleItems.find(
        (curriculum: any) => curriculum?.value === value
      );
      this.setState({
        selectSchoolYearError: false,
        selectSchoolYearErrorMsg: "",
        selectSchoolYearTitle: selectedSchoolYear?.label,
      });
    }
  };

  renderCurriculumDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { curriculumItems } = this.state;
    const selectedCurriculum: any = curriculumItems.find(
      (curriculum: any) => curriculum?.value === selected
    );
    return selectedCurriculum?.label;
  };

  renderSchoolYearTitleDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select School Year Title";
    }
    const { schoolYearTitleItems } = this.state;
    const selectedSchoolYear: any = schoolYearTitleItems.find(
      (item: any) => item?.value === selected
    );
    return selectedSchoolYear?.label;
  };

  handleDaysPattern = (day: string) => {
    const { weekdays } = this.state;
    const updatedDaysPattern = weekdays.map((item) => {
      if (item.value === day) {
        item.active = !item.active;
      }
      return item;
    });
    this.setState({ weekdays: updatedDaysPattern });
  };

  handleValidateFormData = () => {
    const { selectCurriculum, selectSchoolYear } = this.state;
    let isValid = true;
    if (!selectCurriculum) {
      this.setState({
        selectCurriculumError: true,
        selectCurriculumErrorMsg: "Please select Curriculum!",
      });
      isValid = false;
    }
    if (!selectSchoolYear) {
      this.setState({
        selectSchoolYearError: true,
        selectSchoolYearErrorMsg: "Please select School Year!",
      });
      isValid = false;
    }
    return isValid;
  };

  handleAddSchoolCalendarData = () => {
    if (this.handleValidateFormData()) {
      const { selectCurriculum, selectSchoolYear, holidays, weekdays } =
        this.state;
      const { isEdit } = this.props;
      const dayPatterns = weekdays
        .filter((day) => day?.active)
        .map((item) => {
          return item?.value;
        });
      const holidaysWithId = holidays?.filter((data: any) => data?.id);
      const holidaysNewlyAdded = holidays?.filter((data: any) => !data?.id);
      const holidaysNewlyAddedFinal = holidaysNewlyAdded.filter(
        (item: any) => !item?._destroy
      );
      const holidaysListFinal = [...holidaysWithId, ...holidaysNewlyAddedFinal];
      const holidaysAttributes = holidaysListFinal?.map((item: any) => {
        const holidayObj: any = {
          name: item?.holiday,
          start_date: moment(item?.startDate).format("DD/MM/YYYY"),
          end_date: moment(item?.endDate).format("DD/MM/YYYY"),
        };
        if (item?.id) {
          holidayObj.id = item.id;
          if (item?._destroy) {
            holidayObj._destroy = true;
          }
        }
        return holidayObj;
      });
      const requestObj = {
        data: {
          attributes: {
            school_year_id: parseInt(selectSchoolYear),
            curriculum_id: parseInt(selectCurriculum),
            day_patterns: dayPatterns || [],
            holidays_attributes: holidaysAttributes || [],
          },
        },
      };
      if (isEdit) {
        this.props.handleUpdateSchoolCalendar(requestObj);
      } else {
        this.props.handleAddNewSchoolCalendar(requestObj);
      }
    }
  };

  handlePopulateEditSchoolCalendarData = () => {
    const {
      selectedSchoolCalendarData,
      schoolYearList,
      curriculumList,
      weekdaysList,
    } = this.props;
    const updatedDaysPattern = weekdaysList.map((day) => {
      const isDayActive = selectedSchoolCalendarData?.daysPattern?.find(
        (item: any) => item === day.value
      );
      if (isDayActive) {
        day.active = true;
      } else {
        day.active = false;
      }
      return day;
    });
    const holidayList = selectedSchoolCalendarData?.holidays?.map(
      (item: any, index: number) => {
        return {
          index: index,
          id: item?.id,
          holiday: item?.name,
          startDate: item?.start_date
            ? moment(item?.start_date).format("MMM DD, YYYY")
            : null,
          endDate: item?.end_date
            ? moment(item?.end_date).format("MMM DD, YYYY")
            : null,
        };
      }
    );
    this.setState({
      selectCurriculum: selectedSchoolCalendarData?.curriculumId || "",
      selectSchoolYear: selectedSchoolCalendarData?.schoolYearId || "",
      selectCurriculumTitle: selectedSchoolCalendarData?.curriculum || "",
      selectSchoolYearTitle: selectedSchoolCalendarData?.schoolYearTitle || "",
      weekdays: updatedDaysPattern,
      holidays: [...holidayList],
      selectCurriculumErrorMsg: "",
      selectCurriculumError: false,
      selectSchoolYearErrorMsg: "",
      selectSchoolYearError: false,
      schoolYearTitleItems: schoolYearList,
      curriculumItems: curriculumList,
    });
  };

  handleSetInitialSchoolCalendarValue = (isEdit?: boolean) => {
    const { schoolYearList, curriculumList, weekdaysList } = this.props;
    if (isEdit) {
      this.handlePopulateEditSchoolCalendarData();
    } else {
      this.setState(
        {
          loading: false,
          selectCurriculum: "",
          selectCurriculumErrorMsg: "",
          selectCurriculumError: false,
          curriculumItems: curriculumList,
          selectSchoolYear: "",
          selectSchoolYearErrorMsg: "",
          selectSchoolYearError: false,
          schoolYearTitleItems: schoolYearList,
          holidays: [],
          weekdays: weekdaysList.map((item: any) => {
            item.active = false;
            return item;
          }),
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleClosePopup = () => {
    this.handleSetInitialSchoolCalendarValue();
  };

  handleOpenAddAnotherHoliday = (holidayIndex?: any) => {
    let holidayDetails: any = "";
    const { holidays } = this.state;
    if (holidayIndex >= 0) {
      holidayDetails = holidays.find(
        (item: any, index: number) => index === parseInt(holidayIndex)
      );
      this.setState({
        isEditHoliday: true,
      });
    }
    this.setState(
      {
        selectedHolidayData: holidayDetails,
      },
      () => {
        this.setState({ openAddAnotherHoliday: true });
      }
    );
  };

  handleCloseAddAnotherHoliday = () => {
    this.setState({
      openAddAnotherHoliday: false,
      isEditHoliday: false,
      selectedHolidayData: "",
    });
  };

  getIndex = (index?: any) => {
    const { holidays } = this.state;
    if (index >= 0) {
      return index;
    } else if (holidays?.length) {
      return holidays.length;
    } else {
      return 0;
    }
  };

  handleAddHoliday = (data: any) => {
    const { holidays } = this.state;
    const holidayObj: any = {
      holiday: data?.name,
      startDate: moment(data?.start_date).format("MMM DD, YYYY"),
      endDate: moment(data?.end_date).format("MMM DD, YYYY"),
      index: this.getIndex(data?.index),
    };
    if (data?.id) {
      holidayObj.id = data.id;
    }
    const updatedHolidayList = [...holidays, holidayObj];
    this.setState({
      holidays: updatedHolidayList,
      openAddAnotherHoliday: false,
      isEditHoliday: false,
    });
  };

  handleUpdateHoliday = (data: any) => {
    const { holidays } = this.state;
    const updatedHolidays = holidays.map((item: any, index: number) => {
      if (index === data?.index) {
        item.holiday = data?.name;
        item.startDate = moment(data?.start_date).format("MMM DD, YYYY");
        item.endDate = moment(data?.end_date).format("MMM DD, YYYY");
      }
      return item;
    });
    this.setState({
      holidays: updatedHolidays,
      openAddAnotherHoliday: false,
      isEditHoliday: false,
    });
  };

  handleRemoveHoliday = (holidayIndex: any) => {
    const { holidays } = this.state;
    const updatedHolidays = holidays.map((item: any, index: number) => {
      if (index === holidayIndex) {
        item._destroy = true;
      }
      return item;
    });
    this.setState({ holidays: updatedHolidays });
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { isEdit } = this.props;
    if (isEdit) this.handleSetInitialSchoolCalendarValue(true);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
