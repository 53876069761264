import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { lightThemeImg, darkThemeImg } from "./assets";
import { ThemeAttributes } from "./AppearanceManagementMainController.web";

export const themesList = [
  {
    id: 1,
    name: "Light Theme",
    value: "light_mode",
    image: lightThemeImg,
  },
  {
    id: 2,
    name: "Dark Theme",
    value: "dark_mode",
    image: darkThemeImg,
  },
];

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  handleUpdateTheme: any;
  handleSubmitAppearance:(requestBody:any)=>void
  themeData:ThemeAttributes
  rolePermissions?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedThemeType: any;
  selectedAccentColor: string;
  selectedHighlightColor: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppearanceThemeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedThemeType: "light_mode",
      selectedAccentColor: "#BCA700",
      selectedHighlightColor: "#3CC1FF",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetGetData()
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps:Props) {
    if (prevProps.themeData !== this.props.themeData) {
      this.handleSetGetData(); 
    }
  }

  handleSetGetData = () =>{
    const themeType = this.props.themeData.dark_mode? "dark_mode":"light_mode"
    const highlightsColor=this.props.themeData.higlights_color
    const accentColor= this.props.themeData.accent_color
    console.log("hello",themeType,this.props.themeData.dark_mode)
    this.setState({
      selectedThemeType:themeType,
      selectedHighlightColor:highlightsColor || "#3CC1FF",
      selectedAccentColor:accentColor || "#BCA700"
    })
  }
  // Customizable Area End

  // Customizable Area Start
  handleChangeThemeType = (value: string) => {
    this.setState({ selectedThemeType: value }, () => {
      this.props.handleUpdateTheme(value);
    });
  };

  handleSelectAccentColor = (color: any) => {
    this.setState({ selectedAccentColor: color.color });
  };

  handleSelectHighlightColor = (color: any) => {
    this.setState({ selectedHighlightColor: color.color });
  };

  handleSaveDetails = () => {
    const requestBody = {
       type: "Appearance",
       theme:{
          dark_mode:this.state.selectedThemeType === "dark_mode"?true:false,
          higlights_color:this.state.selectedHighlightColor,
          accent_color:this.state.selectedAccentColor
       }
    }
    this.props.handleSubmitAppearance(requestBody)
  };

  handleDummyBtnAction = () => {
    console.log("dummy btn action");
  };
  // Customizable Area End
}
