// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentMyCoursesController, {
  Props,
} from "./StudentMyCoursesController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import CustomTable from "../../../components/src/CustomTable.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import Pagination from "@material-ui/lab/Pagination";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    topicsGrid: {
      marginTop: "20px",
      marginBottom: "14px",
    },
    subjectLogoBg: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
    },
    subjectIcon: {
      height: "inherit",
      borderRadius: "50%",
      width: "inherit",
      objectFit: "contain",
    },
    courseMainBox: {
      height: "150px",
      padding: "15px",
      width: "85%",
      marginBottom: "5%",
      borderRadius: "12px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    tableBox: {
      padding: "15px",
      borderRadius: "12px",
      width: "95%",
      maxHeight: "100vh",
      overflow: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "90% !important",
      },
    },
    topicHeaderBox: {
      width: "98%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    selectedBoxBorder: {
      border: "2px solid #FBD82C",
    },
    paginationBox: {
      padding: "0px 20px 10px 21px",
      justifyContent: 'end',
      alignItems: 'center',
      display: 'flex',
    },
    paginationRoot: {
      justifyContent: "flex-end",
      disaply: "flex",
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
      '& .Mui-selected': {
        color: "#FFFFFF",
        backgroundColor: '#FFD92E !important',
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentMyCourses extends StudentMyCoursesController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      studentCoursesList,
      selectedCourseId,
      selectedSubjectTopicList,
      topicTableColumns,
      pagination
    } = this.state;

    const getSelectedCourseBoxClass = (courseId: any) => {
      if (parseInt(selectedCourseId) === parseInt(courseId))
        return `${classes.bgColor} ${classes.selectedBoxBorder} ${classes.courseMainBox}`;
      return `${classes.cardBgColor} ${classes.courseMainBox}`;
    };

    const getAvgScoreColor = (score: number) => {
      if (score <= 30) return "#ED1111";
      else if (score > 30 && score <= 60) return "#FBD82C";
      else return "#01C996";
    };

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                My Courses
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Typography
              className={`${classes.fontText32} ${classes.textPrimaryColor} ${classes.fontBold600}`}
              component={"span"}
            >
              My Courses
            </Typography>
          </Grid>
          {/* Subject Courses List */}
          <Grid item xs={12}>
            <Grid container direction="row">
              {studentCoursesList?.length > 0 ? (
                studentCoursesList.map((courseItem: any, index: number) => (
                  <Grid key={courseItem?.id} item xs={12} sm={6} lg={4}>
                    <Box
                      data-test-id={`student-subject-card-${index}`}
                      onClick={() => this.handleSelectCourse(courseItem?.id)}
                      className={getSelectedCourseBoxClass(courseItem?.id)}
                    >
                      <Box
                        display="flex"
                        justifyContent={"center"}
                        flexDirection="column"
                      >
                        {/* Subject Icon & name */}
                        <Box display={"flex"} alignItems="center">
                          <Box
                            mr={"10px"}
                            bgcolor={courseItem?.subjectBgColor}
                            className={classes.subjectLogoBg}
                          >
                            <img
                              src={courseItem?.subjectIcon}
                              className={`${classes.subjectIcon}`}
                              alt="Subject Logo"
                              data-test-id={`subject-icon-${index}`}
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src = require("../assets/invalidImage.svg")
                              }}
                            />
                          </Box>
                          <Box textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"} display={"block"}>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontText22}`}
                              component="span"
                            >
                              {courseItem?.courseTitle}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          marginTop={"20px"}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText18}`}
                              component="span"
                            >
                              Time Spent:
                            </Typography>
                          </Box>
                          <Box ml={"4px"}>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                              component="span"
                            >
                              {this.handleGetTimeSpent(
                                courseItem?.timeSpentInSec
                              )}
                            </Typography>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText16}`}
                              component="span"
                            >
                              {` (hh:mm)`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          marginTop={"20px"}
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography
                              className={`${classes.primaryColor4} ${classes.fontText14} ${classes.fontBold500}`}
                              component="span"
                            >
                              {`${courseItem?.completionProgress}% completed`}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              style={{
                                color: getAvgScoreColor(courseItem?.avgGrade),
                              }}
                              className={`${classes.fontText14} ${classes.fontBold500}`}
                              component="span"
                            >
                              {`${courseItem?.avgGrade}% Avg Grade`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              ) : (
                <CustomNoDataComponent noDataText="No Courses yet." />
              )} 
            </Grid>
          </Grid>
          <Grid item xs={12} style={{padding:"0px 15px"}}>
          {studentCoursesList.length > 0 &&
            <Box className={classes.paginationBox}>
              <Box className={classes.paginationRoot}>
                <Pagination
                  shape="rounded"
                  variant="outlined"
                  count={Math.ceil(pagination?.total_count / pagination?.per_page)}
                  siblingCount={0}
                  data-testid="course-list-pagination"
                  boundaryCount={1}
                  onChange={this.handlePaginationChange}
                  page={pagination?.current_page}
                />
              </Box>
            </Box>
          }
        </Grid>
          {/* Subject topic list table */}
          {selectedCourseId && (
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid className={classes.topicsGrid} item xs={12}>
                  <Box
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    className={classes.topicHeaderBox}
                  >
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
                      component={"span"}
                    >
                      Topics
                    </Typography>
                    <Box>
                      <CustomButton
                        isDisabled={false}
                        btnStyle={journeyBtn}
                        btnText="Resume Journey"
                        buttonId="my-courses-topic-resume-journey"
                        buttonVariant={"contained"}
                        type="button"
                        handleButtonClick={() => this.handleResumeJourney()}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={`${classes.bgColor} ${classes.tableBox}`}>
                    {selectedSubjectTopicList.length > 0 ? (
                      <CustomTable
                        tableHeaderColumns={topicTableColumns}
                        tableBodyData={selectedSubjectTopicList}
                        tableId={"student-topic-table"}
                        showMenuIcon={false}
                      />
                    ) : (
                      <CustomNoDataComponent noDataText="No Topics ." />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const journeyBtn = {
  borderRadius: "10px",
  padding: "13px 5px",
  fontSize: "14px",
  width: "156px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentMyCourses);
// Customizable Area End
