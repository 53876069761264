// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import { emailRegex } from "../assets/mockData";
import { setStorageData } from "../../../framework/src/Utilities";
import { getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  emailAddress: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ForgotAccountPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSendVerificationCode: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      emailAddress: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleGoBackToLogin = () => {
    this.props.navigation.navigate("AccountLogin");
  }

  handleValidateEmail = () => {
    const { emailAddress } = this.state;
    let isValidated = true;
    if (!emailAddress) {
      toast.error(TOASTER_NOTIFICATION_MSG.EMPTY_FORGOT_PWD_EMAIL);
      isValidated = false;
    } else if (!emailRegex.test(emailAddress)) {
      toast.error(TOASTER_NOTIFICATION_MSG.INCORRECT_EMAIL_FORMAT);
      isValidated = false;
    }
    return isValidated;
  };

  handleSendCode = () => {
    if (this.handleValidateEmail()) {
      showCustomLoader();
      const{ emailAddress } = this.state;
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };

      const httpBody = {
        data: {
          attributes: {
            email: emailAddress,
          },
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiSendVerificationCode = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotPasswordEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleGoToOtpAuth= (otp: any) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMobilePhoneOTPMessage)
    );

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      otp
    );

    msg.addData(
      getName(MessageEnum.AuthTokenEmailMessage),
      this.state.emailAddress
    );

    msg.addData(
      getName(MessageEnum.NavigationForgotPasswordOTPMessage),
      true
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(
        message
      );
      // Customizable Area Start
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiSendVerificationCode) {
          hideCustomLoader();
          if (responseJson?.data?.attributes?.otp) {
            await setStorageData("userEmail", this.state.emailAddress)
            toast.success(TOASTER_NOTIFICATION_MSG.CHANGE_PASSWORD_OTP_SENT);
            this.handleGoToOtpAuth(responseJson.data.attributes.otp);
          } else {
            //Check Error Response
            toast.error(responseJson?.message);
          }
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }

      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
