// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const MyAssessmentIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill} fillRule="evenodd">
                <g transform="translate(-40.000000, -392.000000)" fill={props.fill} fillRule="nonzero">
                    <g transform="translate(0.000000, 120.000000)">
                        <g transform="translate(40.000000, 142.000000)">
                            <g transform="translate(1.000000, 131.000000)">
                                <path d="M23.4999844,23.0000156 L0.500015625,23.0000156 C0.224015625,23.0000156 0,23.2239844 0,23.4999844 C0,23.7759844 0.224015625,24 0.500015625,24 L23.5000312,24 C23.7760312,24 24.0000469,23.7759844 24.0000469,23.4999844 C24.0000469,23.2239844 23.7759844,23.0000156 23.4999844,23.0000156 Z" id="Path"></path>
                                <path d="M4.5,17.0000156 L1.5,17.0000156 C1.224,17.0000156 0.999984375,17.2240312 0.999984375,17.5000312 L0.999984375,23.5000312 C0.999984375,23.7759844 1.224,24 1.5,24 L4.5,24 C4.776,24 5.00001562,23.7759844 5.00001562,23.4999844 L5.00001562,17.4999844 C5.00001562,17.2239844 4.776,17.0000156 4.5,17.0000156 Z M3.99998437,23.0000156 L2.00001562,23.0000156 L2.00001562,18 L4.00003125,18 L4.00003125,23.0000156 L3.99998437,23.0000156 Z"></path>
                                <path d="M10.5,12 L7.5,12 C7.224,12 6.99998438,12.2240156 6.99998438,12.5000156 L6.99998438,23.5000312 C6.99998438,23.7760312 7.224,24.0000469 7.5,24.0000469 L10.5,24.0000469 C10.776,24.0000469 11.0000156,23.7760313 11.0000156,23.5000313 L11.0000156,12.5000156 C11.0000156,12.2240156 10.776,12 10.5,12 Z M9.99998438,23.0000156 L7.99996875,23.0000156 L7.99996875,12.9999844 L9.99998438,12.9999844 L9.99998438,23.0000156 Z"></path>
                                <path d="M16.5,14.0000156 L13.5,14.0000156 C13.224,14.0000156 12.9999844,14.2240312 12.9999844,14.5000312 L12.9999844,23.5000312 C12.9999844,23.7760312 13.224,24.0000469 13.5,24.0000469 L16.5,24.0000469 C16.776,24.0000469 17.0000156,23.7760312 17.0000156,23.5000312 L17.0000156,14.5000313 C17.0000156,14.2239844 16.776,14.0000156 16.5,14.0000156 Z M15.9999844,23.0000156 L13.9999688,23.0000156 L13.9999688,15 L15.9999844,15 L15.9999844,23.0000156 L15.9999844,23.0000156 Z"></path>
                                <path d="M22.5,8.00001562 L19.5,8.00001562 C19.224,8.00001562 18.9999844,8.22403125 18.9999844,8.50003125 L18.9999844,23.5000313 C18.9999844,23.7760312 19.224,24.0000469 19.5,24.0000469 L22.5,24.0000469 C22.776,24.0000469 23.0000156,23.7760312 23.0000156,23.5000313 L23.0000156,8.50003125 C23.0000156,8.22398437 22.776,8.00001562 22.5,8.00001562 Z M21.9999844,23.0000156 L19.9999688,23.0000156 L19.9999688,9 L21.9999844,9 L21.9999844,23.0000156 L21.9999844,23.0000156 Z"></path>
                                <path d="M3,9 C1.89698437,9 0.999984375,9.897 0.999984375,11.0000156 C0.999984375,12.1030312 1.89698437,13.0000312 3,13.0000312 C4.10301562,13.0000312 5.00001562,12.1030312 5.00001562,11.0000156 C5.00001562,9.897 4.10301562,9 3,9 Z M3,12 C2.448,12 2.00001563,11.5509844 2.00001563,11.0000156 C2.00001563,10.449 2.448,10.0000312 3,10.0000312 C3.552,10.0000312 3.99998437,10.4490469 3.99998437,11.0000156 C3.99998437,11.5509844 3.552,12 3,12 Z"></path>
                                <path d="M9,3.99998437 C7.89698437,3.99998437 6.99998438,4.89698437 6.99998438,6 C6.99998438,7.10301562 7.89698437,8.00001562 9,8.00001562 C10.1030156,8.00001562 11.0000156,7.10301562 11.0000156,6 C11.0000156,4.89698437 10.1030156,3.99998437 9,3.99998437 Z M9,6.99998437 C8.448,6.99998437 8.00001562,6.55096875 8.00001562,6 C8.00001562,5.44898438 8.448,5.00001562 9,5.00001562 C9.552,5.00001562 9.99998438,5.44903125 9.99998438,6 C9.99998438,6.55101562 9.552,6.99998437 9,6.99998437 Z"></path>
                                <path d="M15,6 C13.8969844,6 12.9999844,6.897 12.9999844,8.00001562 C12.9999844,9.10303125 13.8969844,10.0000312 15,10.0000312 C16.1030156,10.0000312 17.0000156,9.10303125 17.0000156,8.00001562 C17.0000156,6.897 16.1030156,6 15,6 Z M15,9 C14.448,9 14.0000156,8.55098437 14.0000156,8.00001562 C14.0000156,7.449 14.448,7.00003125 15,7.00003125 C15.552,7.00003125 15.9999844,7.44904688 15.9999844,8.00001562 C15.9999844,8.55098437 15.552,9 15,9 Z"></path>
                                <path d="M21,0 C19.8969844,0 18.9999844,0.897 18.9999844,2.00001563 C18.9999844,3.10303125 19.8969844,4.00003125 21,4.00003125 C22.1030156,4.00003125 23.0000156,3.10303125 23.0000156,2.00001563 C23.0000156,0.897 22.1030156,0 21,0 Z M21,3 C20.448,3 20.0000156,2.55098438 20.0000156,2.00001563 C20.0000156,1.449 20.448,1.00003125 21,1.00003125 C21.552,1.00003125 21.9999844,1.44904688 21.9999844,2.00001563 C21.9999844,2.55098438 21.552,3 21,3 Z"></path>
                                <path d="M20.2940156,2.706 C20.0990156,2.511 19.782,2.511 19.587,2.706 L15.7070156,6.58598438 C15.5120156,6.78098438 15.5120156,7.098 15.7070156,7.293 C15.8050313,7.38998438 15.933,7.43901563 16.0610156,7.43901563 C16.188,7.43901563 16.3160156,7.39101563 16.4140313,7.293 L20.2940156,3.41301563 C20.4890156,3.21801563 20.4890156,2.901 20.2940156,2.706 Z"></path>
                                <path d="M13.791,6.846 L10.5789844,5.93001562 C10.3099687,5.85201562 10.0369687,6.00801562 9.96196875,6.27403125 C9.88598437,6.54004687 10.0399687,6.81604687 10.3049531,6.89203125 L13.5169687,7.80801562 C13.5629531,7.821 13.6089844,7.827 13.6539844,7.827 C13.872,7.827 14.0719687,7.68398437 14.1349687,7.464 C14.211,7.19901562 14.0570156,6.92198438 13.791,6.846 Z"></path>
                                <path d="M8.22,6.62301562 C8.04801563,6.40701563 7.734,6.372 7.518,6.543 L3.74798438,9.549 C3.53198438,9.72201562 3.49598438,10.0359844 3.669,10.2519844 C3.768,10.3759688 3.91401563,10.44 4.05998438,10.44 C4.16896875,10.44 4.27898438,10.404 4.371,10.332 L8.14101563,7.326 C8.35701562,7.15298438 8.39301563,6.83901563 8.22,6.62301562 Z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default MyAssessmentIcon;
// Customizable Area End