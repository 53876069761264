// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
import React from "react";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  question: any;
  index: number;
  dataTestId?: any;
  handleUpdateAnswer: any;
  handleUpdateAnswerAttachment:any;
  currentQuestion: any;
  handleOpenAttachmentLink: any;
  handleRemoveAnswerAttachment: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentDueAssignmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.inputRef = React.createRef<HTMLInputElement>()
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.checkDueAssignmentRendered();
    // Customizable Area End
  }

  // Customizable Area Start

  getFileSrc = (file: any) => {
    if (typeof file === "string") return file;
    else return window.URL.createObjectURL(file);
  }

  checkDueAssignmentRendered = () => {
    console.log("due assignment render");
  }

  handleAttachFileButton = async(e: any) => {
    let totalSize = 0;
    let isAllImageTypeFiles = false;
    let uploadedFiles : Array<any> = [];
    let files = e.target.files;
    const keys = Object.keys(files);
    keys?.map((key: any) => {
      const file = files[key];
      totalSize = totalSize + file.size;
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg'
      ) {
        isAllImageTypeFiles = true;
      } else {
        isAllImageTypeFiles = false;
      }
    });
    if (isAllImageTypeFiles) {
      keys?.map(async(key: any) => {
        const fileSrc = this.getFileSrc(files[key]);
        const fileObj: {filename: string, content_type: string, file: any, fileSrc: string} = {
          filename: files[key]?.name,
          content_type: files[key]?.type,
          fileSrc: fileSrc,
          file: files[key],
        };
        uploadedFiles.push(fileObj);
      });
      this.props.handleUpdateAnswerAttachment(uploadedFiles)
    }
    else {
      toast.error("Please upload valid image type file(s)(png , jpg , jpeg)")
    }
  }

  // Customizable Area End
}
// Customizable Area End