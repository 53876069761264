// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const VideoLeftTextRightIcon: any = (props: any) => {
    return (
        <SvgIcon style={{ width: "75px", height: "40px" }} {...props}>
            <rect x="-1.5" y="0" width="70" height="40" rx="3" strokeWidth="1" fill={props.fill} stroke={props.stroke} />
            <path d="M49.8633 11.7726C49.3821 11.7726 48.9916 11.3835 48.9916 10.9042V10.0357H40.2755V10.9042C40.2755 11.3835 39.885 11.7726 39.4038 11.7726C38.9227 11.7726 38.5322 11.3835 38.5322 10.9042V9.16727C38.5322 8.68789 38.9227 8.29883 39.4038 8.29883H49.8633C50.3444 8.29883 50.7349 8.68789 50.7349 9.16727V10.9042C50.7349 11.3835 50.3444 11.7726 49.8633 11.7726Z" fill="#888888" />
            <path d="M44.6333 20.4571C44.1522 20.4571 43.7617 20.068 43.7617 19.5886V9.16727C43.7617 8.68789 44.1522 8.29883 44.6333 8.29883C45.1145 8.29883 45.505 8.68789 45.505 9.16727V19.5886C45.505 20.068 45.1145 20.4571 44.6333 20.4571Z" fill="#888888" />
            <path d="M46.3776 20.4566H42.8911C42.41 20.4566 42.0195 20.0676 42.0195 19.5882C42.0195 19.1088 42.41 18.7197 42.8911 18.7197H46.3776C46.8588 18.7197 47.2492 19.1088 47.2492 19.5882C47.2492 20.0676 46.8588 20.4566 46.3776 20.4566Z" fill="#888888" />
            <path d="M62.0653 10.0357H55.0923C54.6112 10.0357 54.2207 9.64665 54.2207 9.16727C54.2207 8.68789 54.6112 8.29883 55.0923 8.29883H62.0653C62.5464 8.29883 62.9369 8.68789 62.9369 9.16727C62.9369 9.64665 62.5464 10.0357 62.0653 10.0357Z" fill="#888888" />
            <path d="M62.0653 15.2457H55.0923C54.6112 15.2457 54.2207 14.8566 54.2207 14.3772C54.2207 13.8979 54.6112 13.5088 55.0923 13.5088H62.0653C62.5464 13.5088 62.9369 13.8979 62.9369 14.3772C62.9369 14.8566 62.5464 15.2457 62.0653 15.2457Z" fill="#888888" />
            <path d="M62.0653 20.4566H55.0923C54.6112 20.4566 54.2207 20.0676 54.2207 19.5882C54.2207 19.1088 54.6112 18.7197 55.0923 18.7197H62.0653C62.5464 18.7197 62.9369 19.1088 62.9369 19.5882C62.9369 20.0676 62.5464 20.4566 62.0653 20.4566Z" fill="#888888" />
            <path d="M62.0659 25.6676H39.4038C38.9227 25.6676 38.5322 25.2785 38.5322 24.7991C38.5322 24.3197 38.9227 23.9307 39.4038 23.9307H62.0659C62.5471 23.9307 62.9375 24.3197 62.9375 24.7991C62.9375 25.2785 62.5471 25.6676 62.0659 25.6676Z" fill="#888888" />
            <path d="M62.0659 30.8775H39.4038C38.9227 30.8775 38.5322 30.4885 38.5322 30.0091C38.5322 29.5297 38.9227 29.1406 39.4038 29.1406H62.0659C62.5471 29.1406 62.9375 29.5297 62.9375 30.0091C62.9375 30.4885 62.5471 30.8775 62.0659 30.8775Z" fill="#888888" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.6994 18.7369C34.6994 26.4357 28.4582 32.677 20.7594 32.677C13.0604 32.677 6.81934 26.4357 6.81934 18.7369C6.81934 11.038 13.0604 4.79688 20.7594 4.79688C28.4582 4.79688 34.6994 11.038 34.6994 18.7369ZM33.3054 18.7369C33.3054 25.666 27.6884 31.283 20.7594 31.283C13.8304 31.283 8.21331 25.6659 8.21331 18.7369C8.21331 11.8079 13.8304 6.19085 20.7594 6.19085C27.6885 6.19085 33.3054 11.8079 33.3054 18.7369Z" fill="#888888" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.7047 19.5485C26.2844 19.1622 26.2844 18.3109 25.7047 17.9246L18.1192 12.8696C17.4706 12.4373 16.6016 12.9022 16.6016 13.6815V23.7916C16.6016 24.571 17.4706 25.036 18.1192 24.6036L25.7047 19.5485ZM17.9962 14.4629L24.4091 18.7365L17.9962 23.0102V14.4629Z" fill="white" />
        </SvgIcon>
    );
}

export default VideoLeftTextRightIcon;
// Customizable Area End