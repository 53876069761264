// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  emailAddress: string;
  otp: string[];
  minutes: number;
  seconds: number;
  otpError: string;
  otpCodeSent: any;
  otpAuthToken: string;
  filledInputs: any,
  isValid: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class OTPAuthController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiVerifyOtpId: string = "";
  apiResendVerifyCode: string = "";
  otpInterval: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      emailAddress: "",
      otp: ["", "", "", ""],
      minutes: 1,
      seconds: 0,
      otpError: "",
      otpCodeSent: "",
      otpAuthToken: "",
      filledInputs:  new Array(4).fill(false),
      isValid: true
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetEmail();
    this.setOtpInterval();
    // Customizable Area End
  }

  async componentWillUnmount() {
    clearInterval(this.otpInterval);
  }

  setOtpInterval = () => {
    this.otpInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.otpInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  handleGetBackToLogin = () => {
    this.props.navigation.navigate("AccountLogin");
  };

  handleSetEmail = async () => {
    const email = await getStorageData("userEmail");
    this.setState({ emailAddress: email });
  };

  handleValidateCode = () => {
    const { otp, emailAddress } = this.state;
    if (!emailAddress) {
      toast.error(TOASTER_NOTIFICATION_MSG.SEND_OTP_EMPTY_EMAIL);
      this.props.navigation.navigate("ForgotPassword");
      return false;
    } else {
      let isValidated = true;
      otp.forEach((element) => {
        if (!element.trim()) isValidated = false;
      });
      if (!isValidated) toast.error(TOASTER_NOTIFICATION_MSG.INVALID_OTP);
      return isValidated;
    }
  };

  handleGoToCreateNewPassword = (otpToken: string) => {
    const requestMessage: Message = new Message(
      getName(MessageEnum.NavigationNewPasswordMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    requestMessage.addData(getName(MessageEnum.AuthTokenDataMessage), otpToken);
    requestMessage.addData(
      getName(MessageEnum.NavigationNewPasswordMessage),
      true
    );
    this.send(requestMessage);
  };

  handleVerifyCode = () => {
    if (this.handleValidateCode()) {
      this.setState({ minutes: 0, seconds: 0 });
      clearInterval(this.otpInterval);
      this.handleVerifyOTPPin();
    }
  };

  // Customizable Area Start
  handleKeydown = (e: any, ind: any) => {
    const inputs = document.querySelectorAll('input[type="number"]');
    if (e.target.value.length === 0 && e.key === "Backspace") {
      const nextInput = inputs[ind - 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };

  resetOTPValidation = () => {
    this.setState({isValid: true});
  };

  handleOTPPaste = (e: any) => {
    e.preventDefault();
    const otp = e.clipboardData.getData("Text");
    if(Number(parseInt(otp))){
     const otpCode = [ ...this.state.otp];
      otpCode[0] = otp[0] || "";
      otpCode[1] = otp[1] || "" ;
      otpCode[2] = otp[2] || "";
      otpCode[3] = otp[3] || "";
      this.setState({ otp: otpCode });
    }
  };

  handleOTPChange = (e: any, index: number) => {
    this.setState({ otpError: "" });
    const otp = [...this.state.otp];
    otp[index] = e.target.value;
    this.setState({ otp });
    const inputs = document.querySelectorAll('input[type="number"]');

    if (e.target.value.length === 1) {
      const nextInput = inputs[index + 1];
      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
    const { value } = e.target;
    const { filledInputs } = this.state;
    const newOtp = [...otp];
    newOtp[index] = value;

    const newFilledInputs = [...filledInputs];
    newFilledInputs[index] = !!value; // Update filled status based on whether value exists

    this.setState({
      otp: newOtp,
      filledInputs: newFilledInputs,
    });
  };

  handleResendCode = () => {
    this.setState({ minutes: 1, seconds: 0, otp: ["", "", "", ""] }, () => {
      this.setOtpInterval();
    });
    showCustomLoader();
    const { emailAddress } = this.state;
    if (emailAddress) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      const httpBody = {
        data: {
          attributes: {
            email: emailAddress,
          },
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiResendVerifyCode = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resendVerifyCode
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.SEND_OTP_EMPTY_EMAIL);
      this.props.navigation.navigate("ForgotPassword");
    }
  };

  handleVerifyOTPPin = () => {
    showCustomLoader();
    const { otp, emailAddress } = this.state;
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const otpCode = otp.join("");
    const httpBody = {
      data: {
        attributes: {
          email: emailAddress,
          otp: parseInt(otpCode),
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOtpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTPPin
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiVerifyOtpId:
            {
              hideCustomLoader();
              if (responseJson?.token) {
                toast.success(responseJson?.message);
                this.handleGoToCreateNewPassword(responseJson.token);
                await setStorageData("otpAuthToken", responseJson.token);
              } else {
                //Check Error Response
                this.setState({
                  isValid: false,
                })
                toast.error(responseJson?.message);
              }
              this.parseApiCatchErrorResponse(errorResponse);
            }
            break;
          case this.apiResendVerifyCode: {
            hideCustomLoader();
            if (responseJson?.data?.attributes?.otp) {
              this.setState({
                otpCodeSent: responseJson?.data?.attributes?.otp,
              });
              toast.success(TOASTER_NOTIFICATION_MSG.CHANGE_PASSWORD_OTP_SENT);
            } else {
              //Check Error Response
              toast.error(responseJson?.message);
            }
            this.parseApiCatchErrorResponse(errorResponse);
          }
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
