// Customizable Area Start
import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentMyCoursesActivityJourneyBoxController, {
  Props
} from "./StudentMyCoursesActivityJourneyBoxController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import MyCoursesIcon from "../../../components/src/SVGIcons/MyCoursesIcon.web";
import QuestionMarkIcon from "../../../components/src/SVGIcons/QuestionMarkIcon.web";
import AssignmentTaskIcon from "../../../components/src/SVGIcons/AssignmentTaskIcon.web";
import ExamTaskIcon from "../../../components/src/SVGIcons/ExamTaskIcon.web";
import ProjectTaskIcon from "../../../components/src/SVGIcons/ProjectTaskIcon.web";
import ArticleTaskIcon from "../../../components/src/SVGIcons/ArticleTaskIcon.web";
import AudioTaskIcon from "../../../components/src/SVGIcons/AudioTaskIcon.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomCheckmark from "../../../components/src/CustomCheckmark.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Shape } from "../src/assets";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    columnDetailRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 5% 0 0",
      height: "55px",
      cursor: "pointer",
      borderBottom: "1px solid #f1f1f1"
    },
    selectedTopicBox: {
      borderLeft: "2px solid #0D46BC",
    },
    menuIconButton: {
      "&.MuiIconButton-root": {
        padding: "2px"
      }
    },
    title: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    dragIcon: {
      minWidth: "40px",
      height: "55px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      marginRight: "20px",
      backgroundColor: "rgba(0, 157, 255, 0.05)",
    },
})


// Customizable Area End

// Customizable Area Start
export class StudentMyCoursesActivityJourneyBox extends StudentMyCoursesActivityJourneyBoxController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  getSvgIconColor = (selected : boolean) => {
    const isDarkTheme = localStorage.getItem("dark_mode") || JSON.stringify(false);
    const darkThemeIconColor = JSON.parse(isDarkTheme) ? "#D1D1D1" : "#090909";
    return selected ? darkThemeIconColor : "#888888"
  }

  activityIcon = (type: string , selected : boolean) => {
    switch (type) {
      case "video":
        return (<MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={this.getSvgIconColor(selected)} strokeWidth="0.055" fill={this.getSvgIconColor(selected)} />)
      case "text":
        return (<QuestionMarkIcon width="18" height="27" viewBox="0 0 18 27" stroke={this.getSvgIconColor(selected)} strokeWidth="0.055" fill={this.getSvgIconColor(selected)} />)
      case "assignment":
        return (<AssignmentTaskIcon width="20" height="31" viewBox="0 0 20 31" stroke={this.getSvgIconColor(selected)} strokeWidth="1.0" fill={this.getSvgIconColor(selected)} />)
      case "exam":
        return (<ExamTaskIcon width="28" height="25" viewBox="0 0 28 25" stroke={this.getSvgIconColor(selected)} strokeWidth="1.0" fill={this.getSvgIconColor(selected)} />)
      case "project":
        return (<ProjectTaskIcon width="26" height="25" viewBox="0 0 26 25" stroke={this.getSvgIconColor(selected)} strokeWidth="1.0" fill={this.getSvgIconColor(selected)} />)
      case "article":
        return (<ArticleTaskIcon width="21" height="28" viewBox="0 0 21 28" stroke={this.getSvgIconColor(selected)} strokeWidth="1.0" fill={this.getSvgIconColor(selected)} />)
      case "task":
        return (<ArticleTaskIcon width="21" height="28" viewBox="0 0 21 28" stroke={this.getSvgIconColor(selected)} strokeWidth="1.0" fill={this.getSvgIconColor(selected)} />)  
      case "audio":
        return (<AudioTaskIcon width="28" height="22" viewBox="0 0 28 22" stroke={this.getSvgIconColor(selected)} strokeWidth="1.0" fill={this.getSvgIconColor(selected)} />)
      default:
       return (<QuestionMarkIcon width="18" height="27" viewBox="0 0 18 27" stroke={this.getSvgIconColor(selected)} strokeWidth="0.055" fill={this.getSvgIconColor(selected)} />)
    }
  };
  
  progressComponent = (detail : any) => {
    if(detail?.completionProgress === 100){
      return <CustomCheckmark />
    }else{
      return ""
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
   
    const { classes, key, detail, selectedItemId, typeOfActivity , isFromAccordion } = this.props;
    return (
        <Box 
        data-testid={`my-course-activity-box-${detail?.id}`} 
        onClick={() => this.props.handleSelectItem(detail?.id)} 
        key={key} 
        borderRight={isFromAccordion ? "none" : "2px solid #f1f1f1"}
        className={selectedItemId === detail?.id ? `${classes.columnDetailRow} ${classes.selectedTopicBox}` : `${classes.columnDetailRow}`}>
          <Box className={classes.title} display={"flex"} alignContent="center" alignItems="center">
            <Box className={classes.dragIcon} display={"flex"}>
              <img src={Shape} alt="" />
            </Box>
            <Box mr={"5px"}>
              {this.activityIcon(typeOfActivity , (selectedItemId === detail?.id))}
            </Box>
            <Typography className={selectedItemId === detail?.id ? `${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.title}` : `${classes.textCommonColor} ${classes.title} ${classes.fontText16} ${classes.fontBold500}`} component={"span"}>
              {detail?.title || ""}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {this.progressComponent(detail)}
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                className={classes.menuIconButton}
                aria-label="more"
                aria-controls="long-menu"
                id="journeyActivity"
                aria-haspopup="true"
              >
              <MoreVertIcon className={`${classes.textCommonColor}`} />
              </IconButton>
            </Box>
          </Box>
        </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentMyCoursesActivityJourneyBox);
// Customizable Area End
