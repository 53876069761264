import { ScienceColor, MathsColor, HistoryColor, EnglishColor, physicsSubjectIcon } from "../src/assets";

export const tableColumnTypes = {
    TEXT: "text",
    NUMBER: "number",
    NUMBER_LESS_WIDTH: "number_less_width",
    CIRCULAR_DASHED_PROGRESS: "circular_dashed_progress",
    LINEAR_PROGRESS: "linear_progress",
    CHIP: "chip",
    ICON: "icon",
    COLOR_CIRCLE: "color_circle",
    COMPONENT: "component",
    ICON_NAME: "icon_name",
    ProgressBar: 'progressbar',
    PROGRESS_TEXT: 'progresstext',
    ASSIGNED_ICON: 'assignedicon',
    ASSIGNED_ASSESSMENT_AVG_GRADE: "assigned_assessment_avg_grade",
    ASSIGNED_TITLE: 'assignedtitle',
    STUDENT_ICON: 'student_icon',
    
    STUDENT_PROGRESS_BAR: 'student_progress_bar',
    DUE_DATE: 'duedate',
    STUD_PROGRESS_ICON: 'stud_progress_icon',
    PROGRESS_TAB_ICON: 'progress_tab_icon',
    PROGRESS_TAB_ICON_COURSE: 'progress_tab_icon_course',
    RECENT_ACTIVITY_ICON_TEXT: 'recent_activity_icon_text',
    PENDING_ACTIVITY_ICON_TEXT: 'pending_activity_icon_text',
    CATALOG_NAME: "catalogname",
    COURSES_NAME: "coursesname",
    COURSES_NAME_ALERT: "coursesnamealert",
    SUBJECT_ICON_NAME: 'subject_icon_name',
    SUBJECT_ICON_NAME_TEMPLATE: 'subject_icon_name_template',
    TEXT_AUTO: "text_auto",
    STATUS_CHIP: "status_chip",
    COURSE_STATUS: "course_status",
    STUDENT_SUBMISSION_PROGRESS: "student_submission_progress",
    BUTTON: "button",
    GRADEBOOK_PROGRESS_BAR: 'gradebook_progress_bar',
    GRADEBOOK_AVG: "gradebook_avg",
    DOWNLOAD: "download",
    DAY_PATTERN_SM: "days_pattern_sm",
    EVENT_COLOR_TITLE: "event_color_title"
}
const coursesData = [
    {
        id: 1,
        Title: {
            name: "Physics",
            icon: ScienceColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Scheduled',
    },
    {
        id: 2,
        Title: {
            name: "Algebra",
            icon: MathsColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Late',
    },
    {
        id: 3,
        Title: {
            name: "Roman History",
            icon: HistoryColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Completed',
    },
    {
        id: 4,
        Title: {
            name: "Victorian Literature",
            icon: EnglishColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Scheduled',
    },
    {
        id: 5,
        Title: {
            name: "Creative Writing",
            icon: EnglishColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Late',
    },
    {
        id: 6,
        Title: {
            name: "English Poetry",
            icon: EnglishColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Completed',
    },
    {
        id: 7,
        Title: {
            name: "Velocity and Acceleration",
            icon: ScienceColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Scheduled',
    },
    {
        id: 8,
        Title: {
            name: "Calculas",
            icon: MathsColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Late',
    },
    {
        id: 9,
        Title: {
            name: "Industrial Revolution",
            icon: HistoryColor,
        },
        StartDate: 'July 20, 2022',
        DueDate: 'May 20, 2023',
        TopicCount: '10',
        LessonCount: '10',
        ActivityCount: '10',
        Status: 'Completed',
    },
];

const studentDropdownItems = [
    {
        id: 1,
        label: "Milton Perry",
        value: "Milton Perry",
    },
    {
        id: 2,
        label: "Amelia Cabal",
        value: "Amelia Cabal",
    },
    {
        id: 3,
        label: "Isak Johannesson",
        value: "Isak Johannesson",
    },
    {
        id: 4,
        label: "Jason Bailey",
        value: "Jason Bailey",
    },
]

const GradedActivitiesColumny = [
    {
        id: "Title",
        label: "Assessment Title",
        columnId: 1,
        type: tableColumnTypes.STUD_PROGRESS_ICON,
    },
    {
        columnId: 2,
        id: "Grading_Component",
        label: "Grading Component",
        type: tableColumnTypes.TEXT,
    },
    {
        id: "Graded_On",
        columnId: 3,
        type: tableColumnTypes.DUE_DATE,
        label: "Graded On",
    },
    {
        columnId: 4,
        id: "Points",
        label: "Points",
        type: tableColumnTypes.DUE_DATE,
    },
    {
        id: "Grade",
        label: " Grade",
        columnId: 5,
        type: tableColumnTypes.DUE_DATE,
    },
    {
        id: "Average",
        columnId: 5,
        label: "Average",
        type: tableColumnTypes.DUE_DATE,
    },
]

const GradedActivitiesBody = [
    {
        id: 1,
        Title: {
            name: "Fiction Assignment",
            color: "#01C996",
            icon: physicsSubjectIcon,
        },
        Grading_Component: "Exam",
        Graded_On: "July 21, 2022",
        Grade: 18,
        Points: 20,
        Average: "90%",
    },
    {
        id: 2,
        Title: {
            name: "Derivatives Exam",
            icon: physicsSubjectIcon,
            color: "#01C996",
        },
        Grading_Component: "Project",
        Graded_On: "July 20, 2022",
        Points: 100,
        Grade: 43,
        Average: "54%",
    },
    {
        id: 3,
        Title: {
            icon: physicsSubjectIcon,
            name: "Graphing Quiz",
            color: "#01C996",
        },
        Grading_Component: "Quiz",
        Graded_On: "July 20, 2022",
        Points: 100,
        Grade: 54,
        Average: "80%",
    },
    {
        id: 4,
        Title: {
            name: "Tenses Assignment - Part 2",
            icon: physicsSubjectIcon,
            color: "#01C996",
        },
        Grading_Component: "Assignment",
        Graded_On: "July 20, 2022",
        Points: 50,
        Grade: 58,
        Average: "96%",
    },
    {
        id: 5,
        Title: {
            name: "English Assignment - Unit 1",
            icon: physicsSubjectIcon,
            color: "#01C996",
        },
        Grading_Component: "Assignment",
        Graded_On: "July 20, 2022",
        Points: 30,
        Grade: 37,
        Average: "98%",
    },
    {
        id: 6,
        Title: {
            name: "World Map Quiz",
            icon: physicsSubjectIcon,
            color: "#01C996",
        },
        Grading_Component: "Quiz",
        Graded_On: "July 20, 2022",
        Points: 22,
        Grade: 11,
        Average: "95%",
    },
]

export {
    coursesData,
    studentDropdownItems,
    GradedActivitiesColumny,
    GradedActivitiesBody
};