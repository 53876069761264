import React from "react";

import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area Start
import CustomButton from "../../../components/src/CustomButton.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { printIcon } from "./assets";
import ReturnGradesDialogWeb from "./ReturnGradesDialog.web";

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginBottom: "20px",
      marginTop: "15px",
    },
    mainContainerPadding: {
      padding: "40px",
    },
    gradeBookDetailContainer: {
      borderRadius: "10px",
      maxHeight: "700vh",
      overflowX: "hidden",
      overflowY: "hidden",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "center",
        },
        borderBottom: "1px solid #979797",
      },
    },
    tabPanel: {
      padding: "0px",
    },
    tabButton: {
      width: "100%",
      padding: "20px 4px",
      textAlign: "center",
      backgroundColor: "transparent",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      opacity: 1,
      fontSize: "20px",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 500,
      },
      "&.MuiTab-root": {
        minHeight: "48px",
      },
    },
    buttonsContainer: {
      right: "40px",
      position: "absolute",
    },
    printIconContainer: {
      right: "30px",
      marginBottom: "55px",
      position: "absolute",
    },
    backArrowBox: {
      height: "40px",
      width: "40px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
  });
// Customizable Area End

import GradeUserDetailPageController, {
  Props,
  courseDetailTabs,
} from "./GradeUserDetailPageController";
import GradebookGradesTab from "./GradebookGradesTab.web";
import AttendanceBehaviourTab from "./AttendanceBehaviourTab.web";
import SubmitGradeDialog from "./SubmitGradeDialog.web";

export class GradeUserDetailPage extends GradeUserDetailPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const {
      selectedTabIndex,
      selectedTabName,
      openSubmitDialog,
      userRole,
      isDataToBeSubmittedIncomplete,
      openReturnGradesDialog
    } = this.state;
    return (
      // Customizable Area Start
      <>
        <Box
          className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
          height={"100%"}
        >
          <Grid container direction="row">
            <Grid item xs={12}>
              <Box marginTop={"1%"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText12}`}
                  component={"span"}
                >
                  Gradebook
                </Typography>
                {selectedTabName ? (
                  <Typography
                    className={`${classes.fontText12} ${classes.textButton} ${classes.fontBold600}`}
                    component={"span"}
                  >
                    {` / ${selectedTabName}`}
                  </Typography>
                ) : (
                  <Typography
                    className={`${classes.fontText12} ${classes.textButton} ${classes.fontBold600}`}
                    component={"span"}
                  >{` / Milton Perry`}</Typography>
                )}
              </Box>
            </Grid>
            <Grid
              xs={12}
              className={classes.pageName}
              container
              alignItems="center"
              item
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"inherit"}
              >
                <Box display={"flex"}>
                  <Box
                    className={classes.backArrowBox}
                    onClick={this.handleRedirectBack}
                  >
                    <ArrowBackIcon
                      fontSize="large"
                      className={classes.textPrimaryColor}
                    />
                  </Box>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
                    component={"span"}
                  >
                    Milton Perry
                  </Typography>
                </Box>
                <>
                  {userRole === "parentTeacher" && selectedTabIndex === 0 && (
                    <Box className={classes.printIconContainer}>
                      <Button>
                        {" "}
                        <img src={printIcon} alt="print icon" />{" "}
                      </Button>
                    </Box>
                  )}
                  {((userRole === "parentTeacher" && selectedTabIndex === 1) ||
                    userRole === "teacher") && (
                    <Box className={classes.buttonsContainer}>
                      <CustomButton
                        btnText="Return"
                        btnStyle={webStyle.returnButton}
                        buttonId={`registrar-return-gradebook-details`}
                        buttonVariant={"contained"}
                        handleButtonClick={this.handleReturnGradesDialogOpen}
                        type={"button"}
                        isDisabled={false}
                      />
                      <CustomButton
                        btnText="Submit"
                        btnStyle={webStyle.submitButton}
                        buttonId={`add-new-course-button`}
                        buttonVariant={"contained"}
                        type={"button"}
                        handleButtonClick={this.handleOpenSubmitDialog}
                        isDisabled={false}
                      />
                    </Box>
                  )}
                  {userRole === "registrar" && (
                    <Box display={"flex"} alignItems={"center"}>
                      <CustomButton
                        btnText="Return"
                        btnStyle={webStyle.returnButton}
                        buttonId={`registrar-return-gradebook-details`}
                        buttonVariant={"contained"}
                        handleButtonClick={this.handleOpenSubmitDialog}
                        type={"button"}
                        isDisabled={false}
                      />
                      <CustomButton
                        btnText="Lock"
                        btnStyle={webStyle.lockButton}
                        buttonId={`registrar-lock-gradebook-details`}
                        handleButtonClick={this.handleOpenSubmitDialog}
                        buttonVariant={"contained"}
                        type={"button"}
                        isDisabled={false}
                      />
                    </Box>
                  )}
                </>
              </Box>
            </Grid>
            <Grid style={{ marginBottom: "20px" }} item xs={12} container>
              {selectedTabIndex === 0 && userRole === "parentTeacher" ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    width: "100%",
                  }}
                >
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText26} ${classes.fontBold600}`}
                  >
                    School Year: 2021-2022
                  </Typography>
                  <Box display={"flex"} alignItems={"center"}>
                    <CustomButton
                      btnText="Submit"
                      btnStyle={webStyle.submitButton}
                      buttonId={`add-new-course-button`}
                      buttonVariant={"contained"}
                      type={"button"}
                      handleButtonClick={this.handleOpenSubmitDialog}
                      isDisabled={false}
                    />
                    {userRole === "parentTeacher" && (
                      <CustomButton
                        btnText="Very Good"
                        btnStyle={webStyle.veryGoodButton}
                        buttonId={`registrar-very-good-btn-gradebook-details`}
                        handleButtonClick={this.handleCloseSubmitDialog}
                        type={"button"}
                        buttonVariant={"contained"}
                        isDisabled={false}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText26} ${classes.fontBold600}`}
                  >
                    School Year: 2021-2022
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid
                className={`${classes.bgColor} ${classes.courseDetailMainContainer}`}
                container
                direction="row"
              >
                <Grid item xs={12}>
                  <Tabs
                    scrollButtons="auto"
                    variant="fullWidth"
                    value={selectedTabIndex}
                    className={`${classes.tabs}`}
                    data-testid="gradebook-user-detail-tabs"
                    onChange={this.handleChangeTab}
                    aria-label="gradebook-user-detail-tabs"
                    TabIndicatorProps={{
                      style: {
                        background: "none",
                      },
                    }}
                  >
                    {courseDetailTabs.map((tabItem: any, index: number) => (
                      <Tab
                        key={tabItem.id}
                        data-testid={`student-assessment-${tabItem.label}-tab`}
                        label={tabItem.label}
                        {...a11yProps(index)}
                        wrapped
                        className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                      />
                    ))}
                  </Tabs>
                </Grid>
                <Grid xs={12} className={`${classes.tabPanel}`} item>
                  <TabPanel value={selectedTabIndex} index={0}>
                    <GradebookGradesTab />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={1}>
                    <AttendanceBehaviourTab
                      validateDataToBeSubmitted={this.validateDataToBeSubmitted}
                      isRegistrar={userRole === "registrar"}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <SubmitGradeDialog
          open={openSubmitDialog}
          onCancel={this.handleCloseSubmitDialog}
          onConfirm={this.handleCloseSubmitDialog}
          isFromRegistrar={userRole === "registrar"}
          dataIncomplete={isDataToBeSubmittedIncomplete}
        />

        <ReturnGradesDialogWeb
          open={openReturnGradesDialog}
          onCancel={this.closeReturnGradesDialog}
          onConfirm={this.closeReturnGradesDialog}
         />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  submitButton: {
    width: "150px",
    height: "50px",
    padding: "15px 20px",
    backgroundColor: "#0D46BC",
    color: "#ffffff",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    float: "right",
    marginLeft: "20px",
  },
  returnButton: {
    width: "150px",
    height: "50px",
    padding: "15px 20px",
    backgroundColor: "#FF0000",
    color: "#ffffff",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginRight: "20px",
  },
  lockButton: {
    width: "150px",
    height: "50px",
    padding: "15px 20px",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
  },
  veryGoodButton: {
    width: "max-content",
    height: "50px",
    padding: "15px 20px",
    backgroundColor: "rgba(0, 157, 255, 0.05)",
    color: "#009DFF",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    float: "right",
    marginLeft: "20px",
  },
};

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(GradeUserDetailPage);
// Customizable Area End
