// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {  Box, Typography } from '@material-ui/core';
import { customThemeStyles } from '../../CustomThemeStyles.web';
import { combineStyles } from '../../CombineStyles.web';
import  CustomTable, { tableColumnTypes }  from '../../CustomTable.web';

import GradeTableController,{Props} from './GradeTableController';
import { physicsSubjectIcon } from '../../gradebookDetails/assets';


// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) => 
    createStyles({

    })

// Customizable Area End

// Customizable Area Start
const studentListTableColumns = [
    {
      id: "Name",
      columnId: 1,
      label: "Academic Subjects",
      type: tableColumnTypes.SUBJECT_ICON_NAME_TEMPLATE,
      width: "100%",
      maxWidth: "100%", 
    },
    {
      id: "Q1Avg",
      columnId: 3,
      label: "1st Qtr",
      type: tableColumnTypes.GRADEBOOK_AVG,
    },
    {
      id: "Q2Avg",
      columnId: 4,
      label: "2nd Qtr",
      type: tableColumnTypes.GRADEBOOK_AVG,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "Q3Avg",
      columnId: 5,
      label: "3rd Qtr",
    
      type: tableColumnTypes.GRADEBOOK_AVG,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "Q4Avg",
      columnId: 6,
      label: "4th Qtr",
     
      type: tableColumnTypes.GRADEBOOK_AVG,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "GPA",
      columnId: 7,
      label: "GPA",
      
      type: tableColumnTypes.NUMBER,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "Grade",
      columnId: 8,
      label: "Grade",
      type: tableColumnTypes.TEXT,
      width: "100%",
      maxWidth: "100%",
    },
  ];
const AttandanceTableData=[
    {
        id:1,
        Name: {
            name: "Physics",
            icon: physicsSubjectIcon,
            bgColor: "#01C996",
          },
        Q1Avg:86,
        Q2Avg:86,
        Q3Avg:86,
        Q4Avg:86,
        GPA:7,
        Grade:"A"
    },
    {
        id:1,
        Name: {
            name: "Total Grade Point Average",
            
          },
        Q1Avg:86,
        Q2Avg:86,
        Q3Avg:86,
        Q4Avg:86,
        GPA:7,
        Grade:"A"
    },
]
export class GradeTable extends  GradeTableController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes} = this.props;
        const {
         
        } = this.state;
        return (
            <Box style={{width:"100%"}}>
        <Box style={{paddingTop:"14px",paddingBottom:"14px"}}>
         <Box style={{display:'flex',justifyContent:"space-between",padding:"16px"}}>
         <Box>
           <Typography className={`${classes.textPrimaryColor}`}>
            Grades
           </Typography>
         </Box>
        <Box>
        <Typography className={`${classes.textPrimaryColor}`}>
          {this.props.className && this.props.className.trim().length > 0 ? this.props.className : "Class 9A"} | School Year: {this.props.schoolYear && this.props.schoolYear.trim().length > 0 ? this.props.schoolYear : "2021-2022"}
           </Typography>
        </Box> 
        </Box>
        <Box>
          <CustomTable
              tableId={"tenant-setup-teacher-assignment-table"}
              tableHeaderColumns={this.props.gradebookListTableHead.length>0?this.props.gradebookListTableHead:studentListTableColumns}
              tableBodyData={this.props.gradebookTableData.length>0?this.props.gradebookTableData:AttandanceTableData} 
              showMenuIcon={false}          
          />
        </Box>
        </Box>
        </Box>
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    height: "50px",
    padding: "10px 10px",
    fontSize: "18px",
    width: "120px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    border: "1px solid",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(GradeTable);
// Customizable Area End
