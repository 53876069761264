// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme, createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../../components/src/CustomTable.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import TeacherAssignmentController, {
  Props,
} from "./TeacherAssignmentController.web";
import Pagination from "@material-ui/lab/Pagination";
import CustomEmptyResultComponentWeb from "../../../../components/src/CustomEmptyResultComponent.web";
import NewTeacherAssignmentDialogWeb from "./NewTeacherAssignmentDialog.web";
import DeleteTeacherAssignmentConfirmPopupWeb from "./DeleteTeacherAssignmentConfirmPopup.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      padding: "32px 24px",
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: 14,
      marginBottom: "6px",
      marginTop: "10px",
      color: "#888888",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
    searchBtnGrid: {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px"
      }
    },
  });

const teacherAssignmentHead = [
  {
    type: tableColumnTypes.TEXT,
    columnId: 1,
    id: "subjects",
    label: "Subject",
    width: "200px",
    maxWidth: "220px",
  },
  {
    columnId: 2,
    id: "className",
    type: tableColumnTypes.TEXT,
    label: "Class",
    width: "120px",
    maxWidth: "120px",
  },
  {
    columnId: 3,
    id: "curriculum",
    type: tableColumnTypes.TEXT,
    label: "Curriculum",
    width: "100px",
    maxWidth: "120px",
  },
  {
    columnId: 4,
    id: "teachersName",
    type: tableColumnTypes.TEXT,
    label: "Teacher",
    width: "150px",
    maxWidth: "200px",
  },
  {
    columnId: 5,
    id: "createdOn",
    type: tableColumnTypes.TEXT,
    label: "Created On",
    width: "150px",
    maxWidth: "150px",
  },
  {
    columnId: 6,
    id: "modifiedOn",
    type: tableColumnTypes.TEXT,
    label: "Modified On",
    width: "150px",
    maxWidth: "150px",
  },
  {
    columnId: 7,
    id: "status",
    type: tableColumnTypes.CHIP,
    label: "Status",
    width: "100px",
    maxWidth: "120px",
  },
];

const teacherAssignmentTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        paddingRight: "22px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TeacherAssignment extends TeacherAssignmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      selectTeacher,
      teacherAssignmentTableData,
      currentPage,
      openDeleteTeacherAssignmentConfirmModal,
      openEditTeacherAssignmentDialog,
      selectGradeItems,
      perPageRecords,
      selectClass,
      selectClassItems,
      selectCurriculum,
      selectCurriculumItems,
      selectSubject,
      selectSubjectItems,
      selectedTeacherAssignmentDetails,
      showLoaderInTeacherAssignmentDialog,
      teacherAssignmentTableBtnActionConfig,
      teachersMenuList,
      totalNumberOfTeacherAssignmentsRecords,
      classesListBasedOnGradeSubjectsCurriculum,
      curriculumListBasedOnGradeSubjects,
      selectedOtherTeachersId,
      selectedRegularTeachersId,
      subjectsListBasedOnGrade,
      teachersListBasedOnGradeSubjectCurriculum,
      otherTeachersListBasedOnGradeSubjectCurriculum,
    } = this.state;
    const { classes, openNewTeacherAssignmentDialog } = this.props;
    return (
      <Box className={`${classes.bgColor}`}>
        <Box className={classes.searchBox}>
          <Grid
            container
            direction="row"
            data-test-id="RejectedRow"
            alignItems="flex-end"
          >
            <Grid item xs={12} lg={3} md={4}>
              <Box>
                <Typography className={classes.inputLabel}>Subject</Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldValue={selectSubject}
                  fieldId="teacher-assignment-filter-subject"
                  handleChange={this.handleChangeDropDownValue}
                  fieldName="selectSubject"
                  renderValue={this.renderSubjectValue}
                  fieldStyles={{ width: "96%" }}
                  fieldMenuItems={selectSubjectItems}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3} md={4}>
              <Box>
                <Typography className={classes.inputLabel}>Class</Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldValue={selectClass}
                  fieldId="teacher-assignment-filter-class"
                  handleChange={this.handleChangeDropDownValue}
                  fieldName="selectClass"
                  renderValue={this.renderClassValue}
                  fieldStyles={{ width: "96%" }}
                  fieldMenuItems={selectClassItems}
                  placeHolderText="Select Class"
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3} md={4}>
              <Box>
                <Typography className={classes.inputLabel}>
                  Curriculum
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldValue={selectCurriculum}
                  fieldId="teacher-assignment-filter-curriculum"
                  handleChange={this.handleChangeDropDownValue}
                  fieldName="selectCurriculum"
                  renderValue={this.renderCurriculumValue}
                  fieldStyles={{ width: "96%" }}
                  fieldMenuItems={selectCurriculumItems}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3} md={4}>
              <Box>
                <Typography className={classes.inputLabel}>Teacher</Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldValue={selectTeacher}
                  fieldId="teacher-assignment-filter-teacher"
                  handleChange={this.handleChangeDropDownValue}
                  fieldName="selectTeacher"
                  renderValue={this.renderTeacherValue}
                  fieldStyles={{ width: "96%" }}
                  fieldMenuItems={teachersMenuList}
                />
              </Box>
            </Grid>
            <Grid className={classes.searchBtnGrid} item xs={12} lg={3} md={4}>
              <Box>
                <CustomButton
                  btnText="Search"
                  btnStyle={assignmentSearchBtn}
                  buttonId={`teacher-assignment-filter-search`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={() =>
                    this.handleGetTeacherAssignmentList()
                  }
                  isDisabled={false}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{ backgroundColor: "#F1F1F1", height: "4px", width: "100%" }}
        />
        {teacherAssignmentTableData?.length > 0 ? (
          <>
            <Box
              className={`${classes.bgColor} ${classes.classManagementTable}`}
              borderBottom={"1px solid rgba(224, 224, 224, 1)"}
              p={"0px 20px"}
            >
              <ThemeProvider theme={teacherAssignmentTableTheme}>
                <CustomTable
                  tableId={"tenant-setup-teacher-assignment-table"}
                  tableHeaderColumns={teacherAssignmentHead}
                  tableBodyData={teacherAssignmentTableData}
                  showMenuIcon={true}
                  actionButtonConfig={teacherAssignmentTableBtnActionConfig}
                />
              </ThemeProvider>
            </Box>
            <Box className={classes.paginationBox}>
              <Box width={"50%"} marginTop={"20px"}>
                <Box display={"flex"} gridGap={"6px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    Records
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                    component="span"
                  >
                    {totalNumberOfTeacherAssignmentsRecords > perPageRecords
                      ? `${currentPage * perPageRecords - perPageRecords}-
                ${currentPage * perPageRecords}`
                      : `${currentPage * totalNumberOfTeacherAssignmentsRecords -
                      totalNumberOfTeacherAssignmentsRecords
                      }-
                ${currentPage * totalNumberOfTeacherAssignmentsRecords}`}
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    of {totalNumberOfTeacherAssignmentsRecords}
                  </Typography>
                </Box>
              </Box>
              {totalNumberOfTeacherAssignmentsRecords > perPageRecords && (
                <Box className={classes.paginationRoot}>
                  <Pagination
                    count={Math.ceil(
                      totalNumberOfTeacherAssignmentsRecords / perPageRecords
                    )}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    siblingCount={0}
                    page={currentPage}
                    onChange={this.handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <CustomEmptyResultComponentWeb message="No Records yet." />
        )}
        {(openEditTeacherAssignmentDialog ||
          openNewTeacherAssignmentDialog) && (
            <NewTeacherAssignmentDialogWeb
              open={
                openEditTeacherAssignmentDialog || openNewTeacherAssignmentDialog
              }
              classesListBasedOnGradeSubjectCurriculum={
                classesListBasedOnGradeSubjectsCurriculum
              }
              curriculumListBasedOnGradeSubject={
                curriculumListBasedOnGradeSubjects
              }
              gradesList={selectGradeItems}
              isEdit={openEditTeacherAssignmentDialog}
              showLoader={showLoaderInTeacherAssignmentDialog}
              subjectsListBasedOnGrade={subjectsListBasedOnGrade}
              handleAddNewTeacherAssignmentDetails={(isSaveAsDraft?: boolean) =>
                this.handleAddNewTeacherAssignment(isSaveAsDraft)
              }
              handleUpdateAddedTeacherAssignmentDetails={(data: any) =>
                this.handleUpdateAddedTeacherAssignmentDetails(data)
              }
              handleUpdateTeacherAssignmentDetails={(isSaveAsDraft?: boolean) =>
                this.handleUpdateTeacherAssignment(isSaveAsDraft)
              }
              handleClose={() => this.handleCloseTeacherAssignmentDialog()}
              handleGetClassesBasedOnGradeSubjectCurriculum={(
                gradeId: any,
                subjectIds: any,
                curriculumId: any
              ) =>
                this.handleGetClassesListBasedOnGradeSubjectCurriculum(
                  gradeId,
                  subjectIds,
                  curriculumId
                )
              }
              handleGetCurriculumsBasedOnGradeSubjects={(
                gradeId: any,
                subjectIds: any
              ) => this.handleGetCurriculumsList(gradeId, subjectIds)}
              handleGetSubjectsBasedOnGrade={(grade: any) =>
                this.handleGetSubjectsList(grade)
              }
              handleGetTeachersListBasedOnGradeSubjectCurriculum={(
                gradeId: any,
                subjectIds: any,
                curriculumId: any,
                searchName: string,
              ) =>
                this.handleGetRegularOtherTeachersListBasedOnGradeSubjectCurriculum(
                  gradeId,
                  subjectIds,
                  curriculumId,
                  searchName
                )
              }
              teacherAssignmentDetails={selectedTeacherAssignmentDetails}
              teachersBasedOnGradeSubjectCurriculumList={
                teachersListBasedOnGradeSubjectCurriculum
              }
              otherTeachersListBasedOnGradeSubjectCurriculum={otherTeachersListBasedOnGradeSubjectCurriculum}
              handleSetSelectedOtherTeachersList={(selectedTeachers: any) =>
                this.handleSetOtherTeacherList(selectedTeachers)
              }
              handleSetSelectedRegularTeachersList={(selectedTeachers: any) =>
                this.handleSetRegularTeacherList(selectedTeachers)
              }
              selectedOtherTeachersList={selectedOtherTeachersId}
              selectedRegularTeachersList={selectedRegularTeachersId}
            />
          )}
        <DeleteTeacherAssignmentConfirmPopupWeb
          open={openDeleteTeacherAssignmentConfirmModal}
          headingTitle="Teacher Assignment"
          onCancel={() => this.handleCloseDeleteTeacherAssignmentConfirmModal()}
          onConfirm={() => this.handleDeleteTeacherAssignment()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const assignmentSearchBtn = {
  width: "118px",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAssignment);
// Customizable Area End
