// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@material-ui/core";
import TeacherAssignCourseModalController, {
  Props,
} from "./TeacherAssignCourseModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
// const styles = (theme: Theme) => createStyles({});
const styles = (theme: Theme) => createStyles({})
const theme = createTheme({

  overrides: {
    MuiDialog: {
      paper: {
        width: "520px",
      },
      paperWidthSm: {
        maxWidth: "520px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TeacherCopyCourseSuccessModal extends TeacherAssignCourseModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, courseName } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          data-test-id="successmodalCourses"
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          open={open}
          aria-labelledby="copy-course-success-confirm-dialog-title"
        // style={webStyle.posRelative}
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box display={"flex"} justifyContent={"center"} id="delete-id-copy">
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={SuccessIcon} alt="success icon" />
              </Box>
              <Box width={"6%"} style={webStyle.posAbsolute}>
                <IconButton
                  data-test-id="SuccessCloseIcon"
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }}>
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                  data-test-id="CopySubCopied"
                >
                  Course Copied Successfully
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"}>

                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="CopySubCourse"
                >
                  Course
                </Typography>
                <Typography
                  data-test-id="CopySub"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{ fontSize: "16px" }}
                >
                  {` ${courseName} `}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="copiedTo"
                >
                  {" "}
                  copied to{" "}
                </Typography>
                <Box>
                  <Typography
                    data-test-id="MyCourses"
                    className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                    component={"span"}

                  >
                    My Courses {" "}
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="CopyCoursesuccessfully"
                  >
                    {" "} successfully.
                  </Typography>
                </Box>
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
                data-test-id="CopyCourseBoxBtn"
              >
                <CustomButton
                  btnStyle={copyCourseModalBtn}
                  btnText="Okay"
                  buttonId="copy-course-modal-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const copyCourseModalBtn = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const webStyle = {
  posRelative: {
    position: "relative" as "relative",
  },
  posAbsolute: {
    position: "absolute" as "absolute",
    top: "10px",
    right: "20px"
  }
}
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCopyCourseSuccessModal);
// Customizable Area End
