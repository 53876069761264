// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  FIELD_TYPES,
  IDropdownMenuItemProps,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import {
  customField1ListMock,
  customField2ListMock,
} from "../assets/tenantCourseManagementMockdata";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import _ from "lodash";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export interface IStepperStepProps {
  id: any;
  label: string;
  optional?: boolean;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  open: boolean;
  handleClose: any;
  isEdit?: boolean;
  handleAddNewCatalog?: any;
  handleUpdateCatalog?: any;
  catalogId?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  stepperScreen: string;
  steps: Array<IStepperStepProps>;
  selectedGradeLevelCatalogDDValue: any;
  gradeLevelCatalogDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCurriculumCatalogDDValue: any;
  curriculumCatalogDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedSchoolYearCatalogDDValue: any;
  schoolYearCatalogDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCustomField1CatalogDDValue: any;
  customField1CatalogDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCustomField2CatalogDDValue: any;
  customField2CatalogDDMenuItems: Array<IDropdownMenuItemProps>;
  teacherCoursesInputConfigs: Array<IInputConfigProps>;
  catalogNameValue: string;
  subjectsDropdwonListData: Array<IDropdownMenuItemProps>;
  addedCourseIds: Array<any>;
  catalogNameError: boolean;
  catalogNameMsg: string;
  schoolYearError: boolean;
  schoolYearMsg: string;
  gradeError: boolean;
  gradeMsg: string;
  curriculumError: boolean;
  curriculumMsg: string;
  setAddedCourses: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantCourseMngCreateCatalogModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSchoolYearDropdownData: string = "";
  apiGetGradesDropdownData: string = "";
  apiGetCurriculumDropdownData: string = "";
  apiGetSubjectsDropdownData: string = "";
  apiGetCatalogDataById: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
      stepperScreen: "catalog_details",
      steps: [
        {
          id: "catalog_details",
          label: "Catalog Details",
        },
        {
          id: "add_courses",
          label: "Add Courses",
        },
      ],
      selectedGradeLevelCatalogDDValue: "",
      gradeLevelCatalogDDMenuItems: [],
      curriculumCatalogDDMenuItems: [],
      selectedCurriculumCatalogDDValue: "",
      schoolYearCatalogDDMenuItems: [],
      selectedSchoolYearCatalogDDValue: "",
      customField1CatalogDDMenuItems: customField1ListMock,
      selectedCustomField1CatalogDDValue: "",
      customField2CatalogDDMenuItems: customField2ListMock,
      selectedCustomField2CatalogDDValue: "",
      teacherCoursesInputConfigs: [],
      catalogNameValue: "",
      subjectsDropdwonListData: [],
      addedCourseIds: [],
      catalogNameError: false,
      catalogNameMsg: "",
      schoolYearError: false,
      schoolYearMsg: "",
      gradeError: false,
      gradeMsg: "",
      curriculumError: false,
      curriculumMsg: "",
      setAddedCourses: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetInputConfigs();
    this.handleGetSchoolYearDropdownApi();
    this.handleGetGradesDropdownListApi();
    this.handleGetSubjectsDropdownListApi();
    if (this.props.isEdit) {
      this.handleGetCatalogByIdApi();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetSchoolYearDropdownData:
            {
              this.handleSchoolYearDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetGradesDropdownData:
            {
              this.handleGradesDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetCurriculumDropdownData:
            {
              this.handleCurriculumDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetSubjectsDropdownData:
            {
              this.handleSubjectsDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetCatalogDataById:
            {
              this.handleGetCatalogByIdResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }

  handleSetInputConfigs = () => {
    const {
      gradeLevelCatalogDDMenuItems,
      curriculumCatalogDDMenuItems,
      customField1CatalogDDMenuItems,
      customField2CatalogDDMenuItems,
      schoolYearCatalogDDMenuItems,
      catalogNameValue,
      selectedSchoolYearCatalogDDValue,
      selectedGradeLevelCatalogDDValue,
      selectedCurriculumCatalogDDValue,
      catalogNameError,
      catalogNameMsg,
      schoolYearError,
      schoolYearMsg,
      gradeError,
      gradeMsg,
      curriculumError,
      curriculumMsg,
    } = this.state;
    const configs: Array<IInputConfigProps> = [
      {
        fieldType: FIELD_TYPES.TEXT,
        id: "tenant-teacher-course-catalog-name",
        name: "catalogNameValue",
        fieldName: "catalogNameValue",
        value: catalogNameValue,
        label: "Catalog Name",
        placeHolderText: "Enter Catalog Name",
        fieldError: catalogNameError,
        helperText: catalogNameMsg,
      },
      {
        label: "School Year",
        value: selectedSchoolYearCatalogDDValue,
        fieldType: FIELD_TYPES.SELECT,
        menuItems: schoolYearCatalogDDMenuItems,
        id: "tenant-teacher-course-catalog-schoolYear-select",
        name: "selectedSchoolYearCatalogDDValue",
        fieldName: "selectedSchoolYearCatalogDDValue",
        placeHolderText: "School Year",
        fieldError: schoolYearError,
        helperText: schoolYearMsg,
      },
      {
        fieldType: FIELD_TYPES.SELECT,
        id: "tenant-teacher-course-grade-select",
        name: "selectedGradeLevelCatalogDDValue",
        fieldName: "selectedGradeLevelCatalogDDValue",
        value: selectedGradeLevelCatalogDDValue,
        placeHolderText: "Grade Level",
        menuItems: gradeLevelCatalogDDMenuItems,
        label: "Grade Level",
        fieldError: gradeError,
        helperText: gradeMsg,
      },
      {
        placeHolderText: "Curriculum",
        fieldType: FIELD_TYPES.SELECT,
        menuItems: curriculumCatalogDDMenuItems,
        id: "tenant-teacher-course-catalog-curriculum-select",
        name: "selectedCurriculumCatalogDDValue",
        fieldName: "selectedCurriculumCatalogDDValue",
        value: selectedCurriculumCatalogDDValue,
        label: "Curriculum",
        fieldError: curriculumError,
        helperText: curriculumMsg,
        isDisabled: curriculumCatalogDDMenuItems.length === 0,
      },      
    ];
    this.setState({ teacherCoursesInputConfigs: configs });
  };

  setCatalogDDValue = (selected: any, fieldName: string) => {
    const {
      curriculumCatalogDDMenuItems,
      customField1CatalogDDMenuItems,
      customField2CatalogDDMenuItems,
      gradeLevelCatalogDDMenuItems,
      schoolYearCatalogDDMenuItems,
    } = this.state;

    let label: any = "";

    if (fieldName === "selectedCurriculumCatalogDDValue") {
      const selectedItem = curriculumCatalogDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "customField1CatalogDDMenuItems") {
      const selectedItem = customField1CatalogDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "customField2CatalogDDMenuItems") {
      const selectedItem = customField2CatalogDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "selectedGradeLevelCatalogDDValue") {
      const selectedItem = gradeLevelCatalogDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else {
      const selectedItem = schoolYearCatalogDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderCatalogDDMenuItem = (
    selected: any,
    fieldName: string,
    emptyText: string
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${emptyText}`;
    }
    return this.setCatalogDDValue(selected, fieldName);
  };

  handleCatalogSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { teacherCoursesInputConfigs } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const updatedInputConfig = teacherCoursesInputConfigs.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          teacherCoursesInputConfigs: updatedInputConfig,
        };
      });

      if (name === "selectedSchoolYearCatalogDDValue") {
        this.setState({ schoolYearError: false, schoolYearMsg: "" }, () => {
          this.handleSetInputConfigs();
        });
      }

      if (name === "selectedGradeLevelCatalogDDValue") {
        this.setState({ gradeError: false, gradeMsg: "" }, () => {
          this.handleGetCurriculumDropdownApi({ grade_id: fieldValue });
          this.handleSetInputConfigs();
        });
      }

      if (name === "selectedCurriculumCatalogDDValue") {
        this.setState({ curriculumError: false, curriculumMsg: "" }, () => {
          this.handleSetInputConfigs();
        });
      }
    }
  };

  handleCatalogTextChange = (event: any) => {
    const { teacherCoursesInputConfigs } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = teacherCoursesInputConfigs.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        teacherCoursesInputConfigs: updatedInputConfig,
      };
    });

    if (name === "catalogNameValue" && value) {
      this.setState({ catalogNameError: false, catalogNameMsg: "" }, () => {
        this.handleSetInputConfigs();
      });
    }
  };

  handleNextStepper = () => {
    if (this.handleFormValidation()) {
      this.setState({ stepperScreen: "add_courses" });
    }
  };

  handleCancelStepperBtn = () => {
    this.setState({ stepperScreen: "catalog_details" }, () => {
      this.handleResetFormValues();
      this.props.handleClose();
    });
  };

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }

  handleGetSchoolYearDropdownApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSchoolYearDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYearDropdownData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSchoolYearDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const schoolYearDDList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.attributes.id),
            value: item.attributes.id,
            label: item.attributes.title,
            term: item.attributes.semesters,
          };
        }
      );
      this.setState({
        schoolYearCatalogDDMenuItems: schoolYearDDList || [],
      }, () => {
        this.handleSetInputConfigs();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetGradesDropdownListApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.apiGetGradesDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesDropdownData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGradesDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const gradesDataList = responseJson?.data?.map(
        (item: any) => {
          return {
            label: item?.attributes.name,
            value: item.id,
            id: _.uniqueId(item.id),
          };
        }
      );
      this.setState({ gradeLevelCatalogDDMenuItems: gradesDataList }, () => {
        this.handleSetInputConfigs();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetCurriculumDropdownApi = async (query: any) => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.apiGetCurriculumDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurriculumDropdownData + `?grade_id=${query.grade_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCurriculumDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const curriculumDataList = responseJson?.data?.map(
        (item: any) => {
          return {
            id: _.uniqueId(item.attributes.id),
            label: item.attributes.name,
            value: item.id,
          };
        }
      );
      this.setState({ curriculumCatalogDDMenuItems: curriculumDataList || [] }, () => {
        this.handleSetInputConfigs();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleGetSubjectsDropdownListApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.apiGetSubjectsDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsDropdwonData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubjectsDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const subjectsDDList = responseJson?.data?.map(
        (item: any) => {
          return {
            label: item.attributes.name,
            id: _.uniqueId(item.attributes.id),
            grade: item.attributes.grade,
            value: item.attributes.id,
          };
        }
      );
      this.setState({ subjectsDropdwonListData: subjectsDDList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  handleCatalogSave = () => {
    if (this.handleFormValidation()) {
      const {
        catalogNameValue,
        selectedSchoolYearCatalogDDValue,
        selectedGradeLevelCatalogDDValue,
        selectedCurriculumCatalogDDValue,
        addedCourseIds,
      } = this.state;

      const payload = {
        data: {
          attributes: {
            name: catalogNameValue,
            school_year_id: selectedSchoolYearCatalogDDValue,
            grade_id: selectedGradeLevelCatalogDDValue,
            curriculum_id: selectedCurriculumCatalogDDValue,
            course_ids: addedCourseIds,
            remove_course_ids: [],
          }
        }
      }
      console.log("catalog payload: ", payload);

      if (this.props.isEdit) {
        this.props.handleUpdateCatalog(payload);
      } else {
        this.props.handleAddNewCatalog(payload);
      }
    }
  }

  handleAddedCourses = (data: any) => {
    const coursIds = data.map((item: any) => {
      return item.id;
    })
    this.setState({ addedCourseIds: coursIds });
  }

  handleFormValidation = () => {
    let isValid = true;
    const {
      catalogNameValue,
      selectedSchoolYearCatalogDDValue,
      selectedGradeLevelCatalogDDValue,
      selectedCurriculumCatalogDDValue,
    } = this.state;

    if (!catalogNameValue) {
      this.setState({ catalogNameError: true, catalogNameMsg: "Please enter catalog title" }, () => {
        this.handleSetInputConfigs();
      });
      isValid = false;
    }

    if (!selectedSchoolYearCatalogDDValue) {
      this.setState({ schoolYearError: true, schoolYearMsg: "Please select school year" }, () => {
        this.handleSetInputConfigs();
      });
      isValid = false;
    }

    if (!selectedGradeLevelCatalogDDValue) {
      this.setState({ gradeError: true, gradeMsg: "Please select grade" }, () => {
        this.handleSetInputConfigs();
      });
      isValid = false;
    }

    if (!selectedCurriculumCatalogDDValue) {
      this.setState({ curriculumError: true, curriculumMsg: "Please select curriculum" }, () => {
        this.handleSetInputConfigs();
      });
      isValid = false;
    }
    return isValid;
  }

  handlePauseStepper = () => {
    const {
      catalogNameValue,
      selectedSchoolYearCatalogDDValue,
      selectedGradeLevelCatalogDDValue,
      selectedCurriculumCatalogDDValue,
    } = this.state;

    if (!catalogNameValue ||
      !selectedSchoolYearCatalogDDValue ||
      !selectedGradeLevelCatalogDDValue ||
      !selectedCurriculumCatalogDDValue) {
      return true;
    } else {
      return false;
    }
  }

  handleResetFormValues = () => {
    this.setState({
      catalogNameValue: "",
      selectedSchoolYearCatalogDDValue: "",
      selectedGradeLevelCatalogDDValue: "",
      selectedCurriculumCatalogDDValue: "",
      addedCourseIds: [],
    }, () => {
      this.handleSetInputConfigs();
    });
  }

  handleGetCatalogByIdApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCatalogDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewCatalog}/${this.props.catalogId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCatalogByIdResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const catalogDataItem = responseJson?.data?.attributes;
      this.setState({
        catalogNameValue: catalogDataItem?.name,
        selectedSchoolYearCatalogDDValue: catalogDataItem?.school_year?.id,
        selectedGradeLevelCatalogDDValue: (catalogDataItem?.grade?.id)?.toString(),
        selectedCurriculumCatalogDDValue: (catalogDataItem?.curriculum?.id)?.toString(),
        setAddedCourses: catalogDataItem?.courses,
      }, () => {
        const { selectedGradeLevelCatalogDDValue } = this.state;
        if (selectedGradeLevelCatalogDDValue) {
          this.handleGetCurriculumDropdownApi({ grade_id: selectedGradeLevelCatalogDDValue });
        }
        this.handleSetInputConfigs();
      });
      console.log("catalogDataItem: ", catalogDataItem);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };
  // Customizable Area End
}
