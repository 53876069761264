// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG, USER_ROLES_CAMEL } from "../../../components/src/CommonType.web";
import { ChangeEvent } from "react";

// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export const courseDetailsTabList = [
  {
    id: 1,
    label: "Resources",
    values: "resources",
  },
  {
    id: 2,
    label: "Journey",
    values: "resources",
  },
  {
    id: 3,
    label: "Participants",
    values: "participants",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  selectedStudentName: string;
  courseId: string;
  courseName: string;
  selectedTopicName: string;
  selectedLessonName: string;
  selectedCourseSubject: any;
  openAddNewResourceModal: boolean;
  isTeacherCourse: boolean;
  openLearnMore: boolean;
  openAssignCatalogToTeacherPopup: boolean;
  openAssignCatalogConfirmModal: boolean;
  openAssignCatalogSuccessModal: boolean;
  selectedCourseDetail: any;
  resourceTableData: Array<any>;
  isEditResource: boolean;
  editResourceId: number;
  deleteResourceId: number;
  deleteResourceTitle: string;
  openDeleteConfirmDialog: boolean;
  searching: boolean;
  role: string;
  paginationLimit: number;
  resourcePaginationData: any;
  isFromStudentTeacher: boolean;
  studentName: string;
  studentProfileUrl: string;
  requestType: string;
  openPublishCourseConfirmPopup: boolean;
  isRejectConfirmModalOpen: boolean;
  isRejectReasonModalOpen: boolean;
  rejectReason: string;
  StudentId:string;
  courseManagementPermissions:any;
  courseDetailsTabListState:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CourseManagementDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseDetailById: string = "";
  apiPostAddNewResource: string = "";
  apiGetResourceList: string = "";
  apiPutUpdateResource: string = "";
  apiDeleteResource: string = "";
  apiGetStudentInfo: string = "";
  apiPostTeacherPublishCourse: string = "";
  apiReviewRequest: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      tabValue: 0,
      selectedStudentName: "",
      courseId: "",
      StudentId:"",
      courseName: "",
      selectedTopicName: "",
      selectedLessonName: "",
      selectedCourseSubject: null,
      openAddNewResourceModal: false,
      isTeacherCourse: false,
      openLearnMore: false,
      openAssignCatalogToTeacherPopup: false,
      openAssignCatalogConfirmModal: false,
      openAssignCatalogSuccessModal: false,
      selectedCourseDetail: {},
      resourceTableData: [],
      isEditResource: false,
      editResourceId: 0,
      deleteResourceId: 0,
      deleteResourceTitle: "",
      openDeleteConfirmDialog: false,
      searching: false,
      role: "",
      paginationLimit: 10,
      resourcePaginationData: {},
      isFromStudentTeacher: false,
      studentName: "",
      studentProfileUrl: "",
      requestType: "",
      openPublishCourseConfirmPopup: false,
      isRejectConfirmModalOpen: false,
      isRejectReasonModalOpen: false,
      rejectReason: "",
      courseManagementPermissions:{
        courseManagementCourseCreation:{
          read: true,
          write: true,
        },
        courseManagementAddActivity:{
          read: true,
          write: true,
        },
        myRequestReject:{
          read: true,
          write: true,
        },
        myRequestApprove: {
          read: true,
          write: true,
        },
        myRequestPending:{
          read: true,
          write: true,
        },
        catalogueManagementCreation:{
          read: true,
          write: true,
        },
        catalogueManagementAssignment:{
          read: true,
          write: true,
        },
      },
      courseDetailsTabListState:courseDetailsTabList
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetCourseDetailById:
            {
              this.handleCourseListResponse(responseJson, errorResponse);
            }
            break;

          case this.apiGetStudentInfo:
            {
              this.handleStudentProfileInfoResponse(responseJson);
            }
            break;

          case this.apiPostAddNewResource:
            {
              this.handlePostNewResourceDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetResourceList:
            {
              this.handleResourceListResponse(responseJson, errorResponse);
            }
            break;

          case this.apiPutUpdateResource:
            {
              this.handleUpdateResourceDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiDeleteResource:
            {
              this.handleDeleteResourceResponse(responseJson);
            }
            break;

          case this.apiPostTeacherPublishCourse:
            {
              this.handlePostTeacherPublishCourseResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiReviewRequest:
            {
              this.handlePendingRequestResponse(responseJson, errorResponse);
            }
            break;
        }
      }
    }
  }
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({ role: await getStorageData("role") }, () => {
      if (this.state.role === USER_ROLES_CAMEL.TEACHER) {
        this.setState({ isTeacherCourse: true });
      }
    });
    this.handleGetRoleAndPermissionForCourseManagement()
    this.handleSetCourseName();
    // Customizable Area End
  }
  // Customizable Area Start
  extractCourseManagementPermissions(parseData:any) {
    return {
      courseManagementCourseCreation: parseData?.course_management_course_creation?.can,
      courseManagementAddAssessment: parseData?.course_management_add_assessment?.can,
      courseManagementAddActivity:parseData?.course_management_add_activity?.can,
      myRequestReject: parseData?.my_request_reject?.can,
      myRequestApprove: parseData?.my_request_approve?.can,
      myRequestPending:parseData?.my_request_pending?.can,
      catalogueManagementCreation: parseData?.catalogue_management_creation?.can,
      catalogueManagementAssignment:parseData?.catalogue_management_assignment?.can,
    };
  }
  updateCourseManagementTabs(result:any) {
    return this.state.courseDetailsTabListState.map((tab: { label: string; }) => {
      if (tab.label === "Journey" && !result.courseManagementAddActivity?.read) { 
        return { ...tab, isDisabled: true};
      }
      return tab;
    }).filter((tabItem: any) => !tabItem.isDisabled);
  }
  async handleGetRoleAndPermissionForCourseManagement(){
    const getCourseManagement = await getStorageData("roleandpermission")
    if(getCourseManagement){  
      try {
        const parseData = JSON.parse(getCourseManagement);
        if(parseData){
        const result = this.extractCourseManagementPermissions(parseData)
        const updatedCourseManagementTabsList:any = this.updateCourseManagementTabs(result)
        this.setState({
          courseDetailsTabListState:updatedCourseManagementTabsList,
          courseManagementPermissions:result
        })
      }
      } catch (error) {
        console.error("Failed to parse userMangement permission. It might not be valid JSON.", error);
      }
    }
  }
  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabValue: newValue,
      selectedLessonName: "",
      selectedTopicName: "",
      openLearnMore: false,
      searching: false,
    });
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };
  handleSetSubjectLessonName = (lessonTitle: string) => {
    this.setState({ selectedLessonName: lessonTitle });
  };
  handleSetLessonTopicName = (topicTitle: string) => {
    this.setState({ selectedTopicName: topicTitle });
  };
  handleSetCourseName = () => {
    const { navigation } = this.props;
    const courseId = navigation.getParam("courseId");
    const StudentId=navigation.getParam("studentId");
    const courseBy = navigation.getParam("selfCourse");
    const courseName = navigation.getParam("courseName");
    const studentName = navigation.getParam("studentName");
    const requestType = navigation.getParam("requestType");
    const isStudentTeacher = window.location.pathname
      .toLowerCase()
      .includes("studentteacher");
    if (courseName && courseId) {
      this.setState({
        courseId,
        courseName,
        isFromStudentTeacher: isStudentTeacher,
        studentName,
        StudentId
      });
      this.handleGetCourseDataById();
    }
    if (requestType) {
      this.setState({ requestType });
    }
  };
  handleOpenAddNewResourceModal = () => {
    this.setState({
      openAddNewResourceModal: true,
    });
  };
  handleCloseAddNewResourceModal = () => {
    this.setState({
      openAddNewResourceModal: false,
      isEditResource: false,
    });
  };

  handleOpenLearnMore = () => {
    this.setState({ openLearnMore: true });
  };

  handleCloseLearnMore = () => {
    this.setState({ openLearnMore: false });
  };

  handleOpenConfirmAssignCatalogModal = () => {
    this.setState({
      openAssignCatalogToTeacherPopup: false,
      openAssignCatalogConfirmModal: true,
    });
  };

  handleCloseConfirmAssignCatalogModal = () => {
    this.setState({ openAssignCatalogConfirmModal: false });
  };

  handleOpenAssignCatalogModal = () => {
    this.setState({
      openAssignCatalogToTeacherPopup: true,
    });
  };
  handleCloseAssignCatalogModal = () => {
    this.setState({
      openAssignCatalogToTeacherPopup: false,
    });
  };

  handleOpenAssignCatalogSuccessModal = () => {
    this.setState({
      openAssignCatalogSuccessModal: true,
    });
  };
  handleCloseAssignCatalogSuccessModal = () => {
    this.setState({
      openAssignCatalogSuccessModal: false,
      openAssignCatalogConfirmModal: false,
    });
  };

  handleStudentProfileInfoResponse = (responseJson: {
    data: { attributes: { profile_url: string } };
  }) => {
    if (responseJson?.data) {
      this.setState({
        studentProfileUrl: responseJson.data?.attributes?.profile_url || "",
      });
    }
  };

  handleGetStudentInfo = async () => {
    const token = await getStorageData("token");
    const studentId = this.props.navigation.getParam("studentId");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentInfo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${studentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCourseDataById = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCourseDetailById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseListData}/${this.state.courseId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCourseListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.setState(
        {
          selectedCourseDetail: responseJson.data,
        },
        () => {
          const isStudentTeacher = window.location.pathname
            .toLowerCase()
            .includes("studentteacher");
          if (isStudentTeacher) {
            this.handleGetStudentInfo();
            this.handleChangeTab({} as ChangeEvent, 1);
          }
        }
      );
      console.log(responseJson.data,"narayan7")
      this.handleGetResourceListApi({}, {});
    } else if (responseJson?.errors) {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleAddNewResourceOperation = (payload: any) => {
    this.setState({ openAddNewResourceModal: false });
    this.handleAddNewResourceApi(payload);
  };

  handleUpdateNewResourceOperation = (payload: any) => {
    console.log("received update payload: ", payload);
    this.handleCloseAddNewResourceModal();
    this.handleUpdateResourceApi(payload);
  };

  handleAddNewResourceApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewResource = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreateNewResource
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostNewResourceDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { resourceTableData } = this.state;
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.RESOURSE_CREATION_SUCCESS);
      const addedResourceItem = this.handleSetResourceDataItem(
        responseJson.data
      );
      let updatedResourceList = [];
      if (resourceTableData.length < 10) {
        updatedResourceList = [...resourceTableData, addedResourceItem];
      } else {
        updatedResourceList = resourceTableData;
      }
      let pagination = this.state.resourcePaginationData;
      if (pagination.total_count && pagination.total_pages) {
        pagination.total_count += 1;
        pagination.total_pages = Math.ceil(
          pagination.total_count / pagination.per_page
        );
      }
      this.setState({
        resourceTableData: updatedResourceList,
        resourcePaginationData: pagination,
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSetResourceDataItem = (item: any) => {
    const resource = item.attributes;
    return {
      id: item.id,
      Subject_Resourse: resource?.subject ? resource?.subject?.name : "-",
      Title_Resourse: resource?.topic_title,
      LessonsPerDay_Resourse: resource?.no_of_lessons_per_day
        ? resource?.no_of_lessons_per_day
        : "-",
      Status_Resourse: resource?.status ? resource?.status : "-",
      Grade_Resourse: resource?.grade ? resource?.grade?.name : "-",
    };
  };

  handleGetResourceListApi = async (searchQuery: any, pagination: any) => {
    showCustomLoader();
    const baseUrl = `${configJSON.postCreateNewResource}?course_id=${this.state.selectedCourseDetail.id
      }&per_page=${pagination.limit || this.state.paginationLimit}`;
    let requestUrl = baseUrl;

    if (searchQuery.topic_title) {
      requestUrl = requestUrl + `&topic_title=${searchQuery.topic_title}`;
    }

    if (searchQuery.status) {
      requestUrl = requestUrl + `&status=${searchQuery.status}`;
    }

    if (searchQuery.no_of_lessons_per_day) {
      requestUrl =
        requestUrl +
        `&no_of_lessons_per_day=${searchQuery.no_of_lessons_per_day}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetResourceList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleResourceListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      if (responseJson.data.length) {
        const resourcesData = responseJson.data.map((item: any) => {
          const resource = item?.attributes;
          return {
            id: item.id,
            Title_Resourse: resource?.topic_title,
            Subject_Resourse: resource?.subject?.name,
            Grade_Resourse: resource?.grade?.name,
            LessonsPerDay_Resourse: resource?.no_of_lessons_per_day,
            Status_Resourse: resource?.status,
          };
        });
        this.setState({
          resourceTableData: resourcesData,
          resourcePaginationData: responseJson?.meta?.pagination,
        });
      } else {
        this.setState({
          resourceTableData: [],
        });
      }
    } else if (responseJson?.errors) {
      toast.error(responseJson.errors?.[0]?.token);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetEditResourceId = (data: any) => {
    if (data) {
      this.setState({ isEditResource: true, editResourceId: data });
      this.handleOpenAddNewResourceModal();
    }
  };

  handleUpdateResourceApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutUpdateResource = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCreateNewResource}/${this.state.editResourceId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateResourceDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { resourceTableData } = this.state;
    if (responseJson?.data) {
      const updatedResourceItem = this.handleSetResourceDataItem(
        responseJson.data
      );
      const updatedResourceList = resourceTableData.map((item: any) => {
        if (parseInt(item.id) === parseInt(updatedResourceItem.id)) {
          item = { ...updatedResourceItem };
        }
        return item;
      });
      this.setState({ resourceTableData: updatedResourceList });
      toast.success(TOASTER_NOTIFICATION_MSG.RESOURSE_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleResourceSearchQuery = (query: any) => {
    this.setState({ searching: true });
    if (query.topic_title === "All") {
      delete query.topic_title;
      this.handleGetResourceListApi(query, {});
    } else if (query.no_of_lessons_per_day === "All") {
      delete query.no_of_lessons_per_day;
      this.handleGetResourceListApi(query, {});
    } else {
      this.handleGetResourceListApi(query, {});
    }
  };

  handleOpenDeleteConfirmDialog = () => {
    this.setState({ openDeleteConfirmDialog: true });
  };

  handleCloseDeleteConfirmDialog = () => {
    this.setState({ openDeleteConfirmDialog: false });
  };

  handleResourceDeleteOperation = (data: any) => {
    this.setState({
      deleteResourceId: data.id,
      deleteResourceTitle: data.resourceTitle,
    });
    this.handleOpenDeleteConfirmDialog();
  };

  handleDeleteConfirmAction = () => {
    this.handleCloseDeleteConfirmDialog();
    this.handleDeleteResourceApi();
  };

  handleDeleteResourceApi = async () => {
    showCustomLoader();
    const { deleteResourceId, selectedCourseDetail } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteResource = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewResource}/${deleteResourceId}?course_id=${selectedCourseDetail.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteResourceResponse = (responseJson: any) => {
    const { resourceTableData, deleteResourceId } = this.state;
    if (responseJson) {
      const updatedResourceList = resourceTableData.filter(
        (item: any) => item.id !== deleteResourceId
      );
      this.setState({
        resourceTableData: updatedResourceList,
        deleteResourceId: 0,
      });
      toast.success(TOASTER_NOTIFICATION_MSG.RESOURSE_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.RESOURSE_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleIsFromTenant = () => {
    const { role } = this.state;
    let fromTenant = false;
    const excludedRoles = [
      USER_ROLES_CAMEL.STUDENT,
      USER_ROLES_CAMEL.TEACHER,
      USER_ROLES_CAMEL.PARENT,
      USER_ROLES_CAMEL.PARENT_TEACHER,
      USER_ROLES_CAMEL.REGISTRAR,
    ];
    
    if (!excludedRoles.includes(role)) { 
      fromTenant = true;
    }
    return fromTenant;
  };

  handleResourcePaginationChange = (data: number) => {
    this.handleGetResourceListApi(
      {},
      { page: data, limit: this.state.paginationLimit }
    );
  };

  handleOpenPublishCourseConfirmPopup = () => {
    this.setState({ openPublishCourseConfirmPopup: true });
  }

  handleSubmitCourseForApproval = () => {
    const { selectedCourseDetail } = this.state;
    const requestBody = { data: { course_id: selectedCourseDetail?.id } };
    this.handleTeacherPublishCourseApi(requestBody);
  }

  handleClosePublishCourseConfirmPopup = () => {
    this.setState({ openPublishCourseConfirmPopup: false });
  }

  handleTeacherPublishCourseApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostTeacherPublishCourse = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPublishCourse
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostTeacherPublishCourseResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      toast.success(TOASTER_NOTIFICATION_MSG.TEACHER_PUBLISH_COURSE_SUCCESS);
      this.handleClosePublishCourseConfirmPopup();
      this.handleGetCourseDataById();
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  /**
   * Accept or reject a request
   * @param {boolean} isAccept - true = accept, false = reject
   */
  handlePendingRequest = (isAccept: boolean) => async () => {
    showCustomLoader();

    const token = await getStorageData('token');
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiReviewRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const id = this.props.navigation.getParam('id');

    const body: {[key: string]: any} = {
      id: id,
      is_accepted: isAccept,
    };

    if (!isAccept) body["rejection_reason"] = this.state.rejectReason;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewRequestEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  /**
   * Handles error, display toaster message
   */
  handlePendingRequestResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const isAccepted = responseJson.data.attributes.status === "accepted";
      const message = isAccepted ? TOASTER_NOTIFICATION_MSG.REQUEST_ACCEPT_SUCCESS : TOASTER_NOTIFICATION_MSG.REQUEST_REJECT_SUCCESS ;
      
      toast.success(message);
      this.props.navigation.goBack();
    } else {
      displayApiErrorMsg(responseJson.errors, this.props.navigation);
    }

    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  /**
   * Sets the state to close reject confirmation modal
   */
  closeRejectConfirmModal = () => {
    this.setState({ isRejectConfirmModalOpen: false });
  };

  /**
   * Sets the state to open reject confirmation modal
   */
  openRejectConfirmModal = () => {
    this.setState({ isRejectConfirmModalOpen: true });
  };

  /**
   * Sets the state to close reject reason modal
   */
  closeRejectReasonModal = () => {
    this.clearRejectReason();
    this.setState({ isRejectReasonModalOpen: false });
  };

  /**
   * Sets the state to open reject reason modal
   */
  openRejectReasonModal = () => {
    this.clearRejectReason();
    this.setState({ isRejectReasonModalOpen: true });
  };

  /**
   * Sets the reject reason state
   */
  editRejectReason = (event: any) => {
    const reason = event.target.value;
    this.setState({ rejectReason: reason })
  } 

  /**
   * Resets reject reason state
   */
  clearRejectReason = () => {
    this.setState({ rejectReason: "" })
  }
  // Customizable Area End
}
// Customizable Area End
