// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import NewClassDialogController, {
  Props,
} from "./NewClassDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import ClassDetails from "./ClassDetails.web";
import AddStudent from "./AddStudent.web";
import CustomStepperWeb from "../../../components/src/CustomStepper.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dialogCloseIcon: {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(2.6),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(1.5),
        top: theme.spacing(5),
        right: theme.spacing(4),
      },
    },
    paper: {
      maxWidth: "100%",
      width: "990px",
      height: "auto",
      maxHeight: "90%",
      [theme.breakpoints.down("sm")]: {
        width: "440px",
      },
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    stepIcon: {
      "& .MuiStepIcon-root": {
        fontSize: "42px",
      },
      "& .MuiStepIcon-completed": {
        "& .MuiStepLabel-label": {
          color: "#01C996 !important",
        },
        color: "#01C996",
      },
      "& .MuiStepConnector-line": {
        borderColor: "#0D46BC",
      },
      "& .MuiStepLabel-completed": {
        color: "#01C996 !important",
      },
      "& .MuiStepLabel-label": {
        color: "#0D46BC",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
    },
    customLoader: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
      zIndex: 100,
      display: "block",
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewClassDialog extends NewClassDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      open,
      showLoader,
      curriculumListBasedOnGradeSubject,
      subjectsListBasedOnGrade,
      gradesList,
      schoolYears,
      studentsAvailable,
      studentsSelected,
      isEdit,
      classDetails,
    } = this.props;
    const {
      stepperScreen,
      steps,
      classTitle,
      classTitleError,
      classTitleErrorMsg,
      selectedCurriculum,
      selectedCurriculumTitle,
      selectedCurriculumError,
      selectedCurriculumErrorMsg,
      selectedGrade,
      selectedGradeTitle,
      selectedGradeError,
      selectedGradeErrorMsg,
      selectedSchoolYear,
      selectedSchoolYearError,
      selectedSchoolYearErrorMsg,
      selectedSubjects,
      selectedSubjectsError,  
      selectedSubjectsErrorMsg,
      classesCustomFieldConfig,
    } = this.state;
    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.paper }}
        className={classes.dialogRoot}
      >
        <Box>
          {showLoader && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <DialogTitle
            id="new-class-dialog-title"
            className={`${classes.bgColor}`}
          >
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
            >
              {isEdit ? "Edit Class" : "Add New Class"}
            </Typography>
            <CloseIcon
              id="An icon to close Dialog"
              data-test-id="add-edit-class-modal-close-icon"
              className={`${classes.dialogCloseIcon} ${classes.textPrimaryColor}`}
              onClick={this.handleClosePopup}
            />
          </DialogTitle>
          <DialogContent
            style={{ width: "100%", padding: 0 }}
            className={`${classes.bgColor}`}
          >
            <Box>
              <CustomStepperWeb
                finishLabel="Save"
                nextBtnLabel="Next"
                stepperId="tenant-setup-class-mng-stepper"
                prevBtnLabel=""
                hidePreviousBtn
                showSaveAsDraft={!(isEdit && classDetails?.status === "Active")}
                showCancelBtn
                steps={steps}
                handleNext={() => this.handleNextStepper()}
                handleCancelBtn={() => this.handleCancelStepperBtn()}
                handleSaveAsDraft={() => this.handleAddClassDetails(true)}
                handleSave={() => this.handleAddClassDetails()}
                pause={!this.handlePauseStepper()}
              >
                {stepperScreen === "class_details" && (
                  <ClassDetails
                    classTitle={classTitle}
                    classTitleError={classTitleError}
                    classTitleErrorMsg={classTitleErrorMsg}
                    curriculumList={curriculumListBasedOnGradeSubject}
                    subjectsList={subjectsListBasedOnGrade}
                    gradesList={gradesList}
                    schoolYearList={schoolYears}
                    selectedCurriculum={selectedCurriculum}
                    selectedCurriculumError={selectedCurriculumError}
                    selectedCurriculumErrorMsg={selectedCurriculumErrorMsg}
                    selectedGrade={selectedGrade}
                    selectedGradeError={selectedGradeError}
                    selectedGradeErrorMsg={selectedGradeErrorMsg}
                    selectedSchoolYear={selectedSchoolYear}
                    selectedSchoolYearError={selectedSchoolYearError}
                    selectedSchoolYearErrorMsg={selectedSchoolYearErrorMsg}
                    selectedSubjects={selectedSubjects}
                    selectedSubjectsError={selectedSubjectsError}
                    selectedSubjectsErrorMsg={selectedSubjectsErrorMsg}
                    renderSchoolYear={(data: any) =>
                      this.renderSchoolYearValue(data)
                    }
                    renderCurriculum={(data: any) =>
                      this.renderCurriculumValue(data)
                    }
                    renderGrade={(data: any) => this.renderGradeValue(data)}
                    handleChangeDDValue={(event: any) =>
                      this.handleChangeDropdownValue(event, {} as any)
                    }
                    handleChangeSubjects={(data: any) =>
                      this.handleChangeSubject(data)
                    }
                    handleChangeTextField={(event: any) =>
                      this.handleChange(event)
                    }
                    removeSubject={(value: any) =>
                      this.removeSelectedValue(value)
                    }
                    isLoaderOn={showLoader}
                    customFieldConfig={classesCustomFieldConfig}
                    handleChangeCustomField={(e: any) => this.handleCustomFieldTextChange(e)}
                  />
                )}
                {stepperScreen === "available_selected_students" && (
                  <AddStudent
                    selectedCurriculum={selectedCurriculumTitle}
                    selectedGrade={selectedGradeTitle}
                    studentsListAvailable={studentsAvailable}
                    studentsListSelected={studentsSelected}
                  />
                )}
              </CustomStepperWeb>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewClassDialog);
// Customizable Area End
