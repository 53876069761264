// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentCompletedAssessmentMainController, {
  Props,
} from "./StudentCompletedAssessmentMainController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import StudentCompletedAssignment from "./StudentCompletedAssignment.web";
import StudentAssignmentTopInfo from "./StudentAssignmentTopInfo.web";
import StudentGradedAssignmentStatistics from "./StudentGradedAssignmentStatistics.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
    },
    questionPageNum: {
      borderRadius: "12px",
      overflow: "auto",
    },
    topMargin: {
      marginTop: "4%",
    },
    leftMargin: {
      marginLeft: "1%",
    },
    optionGrid: {
      display: "flex",
      alignItems: "center",
    },
    dueQuizMainGrid: {
      width: "98%",
    },
    studentDueAssignmentBox: {
      padding: "0px 25px 0px 25px",
      position: "relative",
      marginBottom: "25px",
    },
    attachmentListBox: {
      display: "flex",
      gap: "8px",
      marginTop: "10px",
      scrollbarWidth: "thin",
      overflowX: "auto",
    },
    remark: {
      borderRadius: "10px",
      backgroundColor: "#0c85d017",
      display: "flex",
      padding: "10px 8px",
      marginTop: "10px",
      flexDirection: "column",
    },
    attachmentItemBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      border: "1px solid #888888",
      borderRadius: "10px",
      width: "155px",
      zIndex: 1,
    },
    answerAttachment: {
      width: "130px",
      height: "169px",
      objectFit: "contain",
      cursor: "pointer",
    },
    reAttemptBtnGrid: {
      padding: "22px 25px 22px 25px",
      marginTop: "20px",
      borderBottomLeftRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    capitalText: {
      textTransform: "capitalize",
    },
    scoreBox: {
      width: "max-content",
      background: "rgba(0, 157, 255, 0.05)",
      color: "#009DFF",
      padding: "19px 25px",
      borderRadius: "12px",
  },
  });

// Customizable Area End

// Customizable Area Start
export class StudentCompletedAssessmentMain extends StudentCompletedAssessmentMainController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      completedAssignmentQuestionAnswerList,
      typeOfAssessment,
      assessmentDetails,
      assessmentTitle,
      assignmentDetails,
      categoryOfAssessment,
      fromStatistics,
      quizStatistics
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        {console.log("hello world here only",assessmentDetails)}
        <Grid className={classes.dueQuizMainGrid} container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"2%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                My Assessments
              </Typography>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.capitalText}`}
                component={"span"}
              >
                {` / ${categoryOfAssessment}`}
              </Typography>
              <Typography
                className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`}
                component={"span"}
              >
                {` / ${assessmentTitle}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box style={{display:"flex",justifyContent:"space-between"}}>
            <Box
              data-testid={"completed-assessment-title-div"}
              onClick={this.handleGoBack}
              className={`${classes.cursorPointer}`}
              display={"flex"}
              alignItems={"center"}
            >
              <Box mr={"8px"}>
                <KeyboardBackspaceIcon
                  fontSize="large"
                  className={`${classes.textPrimaryColor}`}
                />
              </Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
                component={"span"}
              >
                {assessmentTitle}
              </Typography>
            </Box>
            {(  categoryOfAssessment === "graded" ) &&
            fromStatistics === "statistics" &&
              this.state.objectiveType !=="selection" && (
            <Box className={classes.scoreBox}>
            <Typography
                  component={"span"}
                  className={`${classes.fontBold500} ${classes.fontText24}`}
              >

            {assignmentDetails?.percentage === 100 || assignmentDetails?.percentage === 0 
                                ? `${assignmentDetails?.percentage}%` 
                                : `${assignmentDetails?.percentage?.toFixed(2)}%`}
              </Typography>
          </Box>
          )}
            </Box>
          
          </Grid>
          {/* Questions Answer List */}
          <Grid className={classes.topMargin} item xs={12}>
            <Box borderRadius={"12px"} className={`${classes.bgColor}`}>
              <Box className={`${classes.questionPageNum}`}>
                <StudentAssignmentTopInfo 
                  assignmentDetail={assignmentDetails}
                  categoryOfAssessment={categoryOfAssessment}
                />
                {this.state.evaluationType==="objective" && this.state.objectiveType==="selection" && typeOfAssessment !== "project" ? (
                  <StudentGradedAssignmentStatistics 
                    categoryOfQuiz={categoryOfAssessment}
                    titleOfAssessment={assessmentTitle}
                    typeOfAssessment={this.state.typeOfAssessment}
                    navigation={navigation}
                    assessmentId={this.state.assessmentId}
                    quizStatistics={quizStatistics}
                    evaluationType={this.state.evaluationType}
                    objectiveType={this.state.objectiveType}
                  />
                ) : (
                  <>
                    {categoryOfAssessment === "submitted" || fromStatistics === "statistics" ? (
                      assessmentDetails?.map(
                        (currentQuestion: any, indexNumber: number) => (
                          <Box
                            data-testid={`completed-assignment-question-${currentQuestion?.id}`}
                            className={classes.studentDueAssignmentBox}
                            key={currentQuestion?.id}
                          >
                            <StudentCompletedAssignment
                              question={currentQuestion}
                              index={indexNumber}
                              navigation
                            />
                            {currentQuestion?.attachment?.length > 0 && (
                              <Box marginTop={"10px"}>
                                <Typography
                                  className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600}`}
                                  component={"span"}
                                >
                                  Attachments
                                </Typography>
                                <Box className={classes.attachmentListBox}>
                                  {currentQuestion?.attachment?.map(
                                    (attachment: any,index:number) => (
                                      <Box
                                        gridGap={"3px"}
                                        key={index}
                                        display={"flex"}
                                        flexDirection={"column"}
                                      >
                                        <img
                                          data-testid="attachment-1"
                                          onClick={() =>
                                            this.handleOpenAttachmentFile(
                                              attachment.media_file_url
                                            )
                                          }
                                          className={classes.answerAttachment}
                                          key={index}
                                          src={attachment.media_file_url}
                                          alt="Answer Attachment"
                                        />
                                        <Typography
                                          className={`${classes.textCommonColor} ${classes.fontText12} ${classes.fontBold500}`}
                                          component={"span"}
                                        >
                                          {attachment?.filename}
                                        </Typography>
                                        <Typography
                                          className={`${classes.textCommonColor} ${classes.fontText12} ${classes.fontBold500}`}
                                          component={"span"}
                                        >
                                          {attachment?.filesize}
                                        </Typography>
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </Box>
                            )}
                            {categoryOfAssessment === "graded" && <Box className={classes.remark}>
                              <Typography
                                component={"span"}
                                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                              >
                                Remarks
                              </Typography>
                              <Typography
                                component={"span"}
                                dangerouslySetInnerHTML={{ __html: currentQuestion?.remarks }}
                                className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                              >
                               
                              </Typography>
                            </Box>}
                          </Box>
                        )
                      )
                    ) : (
                      <StudentGradedAssignmentStatistics
                        categoryOfQuiz={categoryOfAssessment}
                        titleOfAssessment={assessmentTitle}
                        typeOfAssessment={typeOfAssessment}
                        navigation={navigation}
                        quizStatistics={quizStatistics}
                        assessmentId={this.state.assessmentId}
                        evaluationType={this.state.evaluationType}
                        objectiveType={this.state.objectiveType}
                      />
                    )}
                  </>
                )}
              </Box>
              {/* Submit Button */}
              {(this.state.objectiveType !=="selection") &&
                categoryOfAssessment !== "graded" && (
                  <Box
                    marginTop={"1%"}
                    marginBottom={"2%"}
                    className={`${classes.reAttemptBtnGrid} ${classes.secondaryInfoBgColor}`}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <CustomButton
                      btnStyle={submitbtnStyle}
                      btnText="Re-Attempt"
                      buttonId="assessment-complete-re-attempt"
                      buttonVariant={"contained"}
                      type={"button"}
                      isDisabled={assignmentDetails.noOfAttempts === 0}
                      handleButtonClick={this.handleReAttempt}
                    />
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const submitbtnStyle = {
  width: "155px",
  padding: "12px 20px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCompletedAssessmentMain);
// Customizable Area End
