// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { getMessageData } from "../../../components/src/CommonHelper.web";
import { OptionTypeBase } from "react-select";
import { IDropdownMenuItemProps } from "../../../components/src/CommonType.web";
import { AssessmentTestContext } from "./context/AssessmentTestProvider";
// Customizable Area End

// Customizable Area Start
type Option = {
  value: string | number;
  label: string;
};
interface Subject {
  id: number;
  name: string;
  color: string;
  image: string | null;
  icon: string;
  filename: string;
  content_type: string;
}

interface Student {
  id: number;
  first_name: string;
  last_name: string;
}

interface GradeSubject {
  name: string;
  icon: string;
  bgColor: string;
}

interface GradedAssessment {
  id: string;
  gradeSubject: GradeSubject;
  subjectId: number;
  gradeComponent: string;
  grade: number;
  gradeDueDate: string;
  gradeon: string;
  maxScore: number;
  average: number;
  students: [];  
}

interface AssignedActivity {
  id: string;
  subject: Subject;
  subjectId: number | string;
  gradingComponent: string;
  assignedon: string;
  dueDate: string;
  daysDue: number;
  students?: Student; 
}
export interface OptionType extends OptionTypeBase {
  label: string;
  value: string | number;
}
const configJSON = require("./config.js")
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gradingComponent: Array<any>;
  isDarkMode: boolean;
  studentDropdownData: Array<IDropdownMenuItemProps>;
  tabValue: number;
  tabName: string;
  selectedStudent: string;
  currentRole: string;
  filteredClassId:string[],
  studentSearchDrop:Option[],
  studentId:number | string,
  getSearchStudentData:AssignedActivity[],
  getGradeStudentData:GradedAssessment[],
  search:boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}

// Customizable Area Start
export default class ParentAssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType = AssessmentTestContext;
  // Customizable Area Start
  getStudentListOfParentCallID : string = "";
  getDataForSelectedStudentCallID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      tabValue: 0,
      tabName: '',
      selectedStudent: "",
      gradingComponent: [],
      isDarkMode: false,
      currentRole: "",
      filteredClassId: [],
      studentId: 0,
      studentSearchDrop:[],
      getSearchStudentData:[],
      getGradeStudentData:[],
      search:false,
      studentDropdownData:[],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetStudentForParent();
    this.handleSetGradedTab();
    // Customizable Area End
  }
  componentDidUpdate(prevProps:Props, prevState:S) {
    const currentDarkMode = JSON.parse(localStorage.getItem('dark_mode') || 'false');
    if (prevState.isDarkMode !== currentDarkMode) {
      this.setState({ isDarkMode: currentDarkMode });
    }
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);
      if(apiRequestCallId === this.getStudentListOfParentCallID) {
        if(responseJson.errors) {
          this.setState({studentDropdownData: []})
        }
        if(responseJson.data) {
          const updatedData = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              label: `${item.attributes.first_name} ${item.attributes.last_name}`,
              value: `${item.attributes.first_name} ${item.attributes.last_name}`,
              disabled: false
            }
          })
          this.setState({studentDropdownData: updatedData})
          this.setState({selectedStudent: updatedData[0].value})
          const selectedStudent = this.state.studentDropdownData.find(
            (student: any) => student.value === updatedData[0].value
          );
          this.setState({studentId: Number(selectedStudent?.id)})
        }
      }
      
    }
  }

  handleSetGradedTab = async () => {
    const currentRole = await getStorageData("role");
    this.setState({ currentRole:  currentRole?.toString()?.toLowerCase() })
  }

  renderSelectedStudentName = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Student";
    }
    const { studentDropdownData } = this.state;
    const selectedStudent = studentDropdownData.find(
      (student: any) => student?.value === selected
    );
    return selectedStudent?.label;
  };

  handleGetStudentForParent = async () => {
    const token = await getStorageData("token");
    const header = { token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getStudentListOfParentCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.parentStudentEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleStudentSelect = (event:any) => {
    this.setState({selectedStudent: event.target.value})
    const selectedStudent = this.state.studentDropdownData.find(
      (student: any) => student.value === event.target.value
    );
    this.setState({studentId: Number(selectedStudent?.id)})
  }

  handleTabChange = (event: any, newValue: number) => {
    const tabNames = ['All', 'Assigned', 'Graded'];
    this.setState({
      tabValue: newValue,
      tabName: tabNames[newValue]
    });
  };

  // Customizable Area End
}
// Customizable Area End