// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, Grid } from "@material-ui/core";
import TenantCourseManagementAssignCourseToCatalogModalController, {
  Props,
} from "./TenantCourseManagementAssignCourseToCatalogModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
import {
  FIELD_TYPES,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import CustomSelectDropdownWeb from "../../../components/src/CustomSelectDropdown.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    createCatalogModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    assignCatalogApproveModalMainGrid: {
      borderRadius: "10px",
      width: "800px",
      display: "flex",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      top: "50%",
      margin: "auto",
      left: "50%",
      height: "410px",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        minWidth: "100px",
        maxWidth: "100%",
      },
    },
    assignCourseToCatalogSelectDD: {
      marginBottom: "20px",
    },
    assignCourseToCatalogSelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    closeIconGrid: {
      display: "flex",
      padding: "15px 24px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    btnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "10px 20px",
    },
    inputConfigContainer: {
      padding: "0px 5px 0px 24px",
    },
  });

// Customizable Area End

export class TenantCourseManagementAssignCourseToCatalogModal extends TenantCourseManagementAssignCourseToCatalogModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, approvalDetailsConfig } = this.props;

    return (
      <Modal
        open={open}
        aria-labelledby="assign-catalog-approval-modal"
        className={`${classes.highlightBgColor} ${classes.createCatalogModal}`}
        onClose={this.props.handleClose}
        aria-describedby="assign-catalog-approval-modal"
      >
        <Grid
          className={`${classes.bgColor} ${classes.assignCatalogApproveModalMainGrid}`}
          container
          direction="row"
        >
          <Grid className={`${classes.closeIconGrid}`} item xs={12}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
            >
              Assign Course To Catalog
            </Typography>
            <CloseIcon
              className={`${classes.textCommonColor} ${classes.cursorPointer}`}
              id="assign-catalog-approval-close-modal-icon"
              onClick={this.props.handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={`${classes.inputConfigContainer}`}
              container
              direction="row"
            >
              {approvalDetailsConfig?.map(
                (assignCatalogInputConfigItem: IInputConfigProps) => (
                  <Grid
                    key={assignCatalogInputConfigItem?.id}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Box className={`${classes.assignCourseToCatalogSelectDD}`}>
                      <Box marginBottom={"10px"}>
                        <Typography
                          id={`tenant-course-mng-assign-catalog-${assignCatalogInputConfigItem?.id}`}
                          className={`${classes.textCommonColor} ${classes.fontText16}`}
                          component={"span"}
                        >
                          {assignCatalogInputConfigItem.label}
                        </Typography>
                      </Box>
                      <Box
                        className={`${classes.assignCourseToCatalogSelectDDWidth}`}
                      >
                        {assignCatalogInputConfigItem.fieldType ===
                          FIELD_TYPES.SELECT && (
                          <CustomSelectDropdownWeb
                            fieldId={assignCatalogInputConfigItem.id}
                            fieldStyles={dropdownStyle}
                            fieldValue={assignCatalogInputConfigItem.value}
                            fieldName={assignCatalogInputConfigItem.name}
                            handleChange={(e: any) =>
                              this.props.handleSelect(e)
                            }
                            renderValue={(selected: any) =>
                              this.props.renderMenu(
                                selected,
                                assignCatalogInputConfigItem.fieldName,
                                assignCatalogInputConfigItem.placeHolderText
                              )
                            }
                            fieldMenuItems={
                              assignCatalogInputConfigItem?.menuItems || []
                            }
                            fieldError={false}
                          />
                        )}
                        {assignCatalogInputConfigItem.fieldType ===
                          FIELD_TYPES.TEXT && (
                          <CustomTextFieldWeb
                            fieldName={assignCatalogInputConfigItem.name}
                            fieldWidth={"100%%"}
                            fieldId={assignCatalogInputConfigItem.id}
                            fieldValue={assignCatalogInputConfigItem.value}
                            isDisabled={
                              assignCatalogInputConfigItem?.isDisabled
                            }
                            textFieldStyles={popupTextfieldStyles}
                            placeHolderText={
                              assignCatalogInputConfigItem.placeHolderText
                            }
                            fieldType="text"
                            handleChange={(e: any) => this.props.handleText(e)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.btnBox}`}>
              <CustomButtonWeb
                btnStyle={{ ...bottomBtnStyle, marginRight: "15px" }}
                btnText="Cancel"
                buttonVariant="outlined"
                buttonId="cancel-assign-catalog-btn"
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.props.handleCancel}
              />
              <CustomButtonWeb
                btnStyle={bottomBtnStyle}
                buttonVariant="contained"
                buttonId="approve-assign-catalog-btn"
                btnText="Assign"
                handleButtonClick={this.props.handleAssign}
                isDisabled={false}
                type={"button"}
              />
            </Box>
          </Grid>
        </Grid>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const bottomBtnStyle = {
  padding: "10px 10px",
  borderRadius: "10px",
  width: "160px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const popupTextfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementAssignCourseToCatalogModal
);
// Customizable Area End
