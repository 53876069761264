// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import { IDropdownMenuItemProps } from "../../../components/src/CommonType.web";

// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  coursesTabValue: number;
  selectedStudentName: string;
  courseName: string;
  tabName: string;
  selectedTopicName: string;
  selectedLessonName: string;
  selectCourseSubject: null;
  openSendApprovalReqModal: boolean;
  selectedClassDDValue: string;
  coursesClassDDComponentValues: Array<any>;
  selectedCourseData: any;
  loadCourseTab: boolean;
  openAddActivityModal: boolean;
  studentDropdownData: Array<IDropdownMenuItemProps>;
  currentRole:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherCoursesMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiGetParentTeacherDetails:string="";
  apiGetTeachersClasses: string = "";
  apiGetStudentListOfParent:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      coursesTabValue: 1,
      selectedStudentName: "",
      courseName: "",
      tabName: "",
      selectedTopicName: "",
      selectedLessonName: "",
      selectCourseSubject: null,
      openSendApprovalReqModal: false,
      selectedClassDDValue: "all",
      selectedCourseData: {},
      coursesClassDDComponentValues: [],
      loadCourseTab: true,
      openAddActivityModal: false,
      studentDropdownData: [],
      currentRole:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null && apiRequestCallId === this.apiGetTeachersClasses) {
        this.handleGetTeachersClassesResponse(
          responseJson,
          errorResponse
        );
      }
      else if(apiRequestCallId != null && apiRequestCallId === this.apiGetParentTeacherDetails){
        this.handleGetParentTeacherDetailResponse(responseJson)
      }else if(apiRequestCallId === this.apiGetStudentListOfParent) {
        if(responseJson.errors) {
          this.setState({coursesClassDDComponentValues: []})
        }
        if(responseJson.data) {
          
          const updatedData = responseJson.data.map((item: any) => {
            return {
              id: item.id,
              label: `${item.attributes.first_name} ${item.attributes.last_name}`,
              value: item.id,
              disabled: false
            }
          })
          
          this.setState({coursesClassDDComponentValues: updatedData})
          this.setState({selectedClassDDValue: updatedData[0].value})
        }
        hideCustomLoader()
      }
    }
  }
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleselectStudentName();
    this.handleTabChange("s", 0);
    this.handleSetGradedTab()
   
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetStudentForParent = async () => {
    showCustomLoader()
    const token = await getStorageData("token");
    const header = { token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGetStudentListOfParent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.parentStudentEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetGradedTab = async () => {
    const currentRole = await getStorageData("role");
    this.setState({ currentRole:  currentRole?.toString()?.toLowerCase() })
    if(currentRole === "Parent Teacher") {
     this.handleGetParentTeacherDetailApi()
    }else if(currentRole === "Parent") {
      this.handleGetStudentForParent();
    } else {
      this.handleGetTeachersClassesApi();
    }
  }
  handleTabChange = (event: ChangeEvent<{}> | string, newValue: number, rowTitle?: boolean) => {
    const tabNames = ["Courses", "Journey", "Participants"];
    if (newValue === 1 && !rowTitle) {
      toast.warning('Please click on course title to view journey.');
    }
    else {
      this.setState({
        coursesTabValue: newValue,
        tabName: tabNames[newValue],
        loadCourseTab: false,
      }, () => {
        if (newValue === 0) {
          this.setState({ loadCourseTab: true });
          this.setState({selectedCourseData:""})
        }
      });
    };
  }
  handleGoBacks = () => {
    this.props.navigation.goBack();
  };
  handleSetLessonName = (lessonTitle: string) => {
    this.setState({ selectedLessonName: lessonTitle });
  };
  handleSetTopicName = (topicTitle: string) => {
    this.setState({ selectedTopicName: topicTitle });
  };
  handleselectStudentName = () => {
    const historyLocationState = this.props.navigation?.getHistoryState();
    if (historyLocationState) {
      const { course, selectedStudentName } = historyLocationState;
      this.setState({
        selectedStudentName: selectedStudentName ?? "",
        courseName: course,
      });
    }
  };

  handleChangeClassDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let selectValue: any = value;
    if (selectValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: selectValue,
        };
      });
    }
  };

  renderCoursesClassDropdownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return this.state.currentRole === "parent" ? "Select Student" : "Select Class";
    }
    const { coursesClassDDComponentValues } = this.state;
    const selectedGradingComponents = coursesClassDDComponentValues.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGradingComponents?.label;
  };

  openJourneyForCourse = (courseData: any) => {
    const { selectedClassDDValue,currentRole } = this.state;
    if (selectedClassDDValue == 'all') {
      const message = (currentRole === "parent teacher") ? "student" : "class"
      toast.warning(`Please select a ${message} to open journey`);
    } else {
      this.setState({
        selectedCourseData: {
          id: courseData?.id,
          courseName: courseData.Title && courseData.Title.name,         
          attributes: courseData && courseData.attributes
        }
      }, () => {
        this.handleTabChange("Journey", 1,true);
      });
    }
  }

  handleGetTeachersClassesApi = async () => {
    const requestUrl = configJSON.teacherClassesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersClassesResponse = (response: any, errorResponse: any) => {
    if (response?.data) {
      let classesDropdwonData = [{
        id: 0,
        label: 'All',
        value: 'all',
      }]
      response.data.forEach((item: any) => {
        classesDropdwonData.push({
          label: item?.name,
          value: item?.id,
          id: item?.id,
        });
      }
      );
      this.setState({
        coursesClassDDComponentValues: classesDropdwonData,
      });
    } else {
      displayApiErrorMsg(response?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleOpenAddNewActivityModal = () => {
    this.setState({ openAddActivityModal: true }, () => {
      this.setState({ openAddActivityModal: false });
    });
  }

  handleCondition = (condititon:any, truePart:any, falsePart:any) => {
    return condititon ? truePart : falsePart
  }

  handleGetParentTeacherDetailApi = async () => {
    const userId = await getStorageData("user_id");
    const token = await getStorageData("token");
    const header = { token };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiGetParentTeacherDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${userId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleGetParentTeacherDetailResponse = (responseJson: any) => {
    if (responseJson.data) {
      const userData = responseJson.data.attributes;
  
  
      const StudentDropdwonAll = [{
        id: "all",
        label: "All",
        value: "all",
      }];
       
      let studentsData = userData.children.map(
        (item: any, index: number) => {
          return {
            id: item.id,
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        }
      );
      this.setState({ coursesClassDDComponentValues:StudentDropdwonAll.concat(studentsData) });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  }
  // Customizable Area End
}
// Customizable Area End
