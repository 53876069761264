// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Select, MenuItem, Input } from '@material-ui/core';
import AssignCourseModalController, {
    Props
} from "./AssignCourseModalController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../../components/src/CustomButton.web';
import { combineStyles } from '../../../../components/src/CombineStyles.web';
import AssignCourseConfirmDialog from "./AssignCourseConfirmDialog.web";
import AssignCourseSuccessDialog from './AssignCourseSuccessDialog.web';
import CustomTextField from '../../../../components/src/CustomTextField.web';
import CustomMultiSelectDropDown from '../../../../components/src/CustomMultiSelectDropDown.web';
import { USER_ROLES_CAMEL } from '../../../../components/src/CommonType.web';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        quizViewAnswerConfirmDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                width: "880px",
                maxWidth: "100%",
                minHeight: "500px",
                height: "max-content",
                overflowY: "hidden",
                overflowX: "hidden",
            }
        },
        messageQuestion: {
            backgroundColor: "#3DBB3D",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "65px",
            height: "65px"
        },
        messageQuestionIcon: {
            width: "42px",
            height: "42px"
        },
        mainBox: {
            width: "100%",
        },
        customDropdown: {
            width: "100%",
            height: "50px",
            padding: "17px 15px",
            borderRadius: "12px",
            border: "1px solid #888",
            background: "#FFF",
            color: "#888",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
        },
        customInput: {
            width: "100%",
            height: "50px",
            padding: "17px 15px",
            borderRadius: "12px",
            border: "1px solid #888",
            background: "#FFF",
            color: "#888",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-disabled)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-disabled": {
                background: "#F3F3F3"
            }
        }

    })

// Customizable Area End

// Customizable Area Start
export class AssignCourseModal extends AssignCourseModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open } = this.props;
        const {
            openConfirmDialog,
            openSuccessDialog,
            courseTitle,
            catalogTitle,
            subjectName,
            teacherName,
            selectedClassValue,
            selectedStudentValue,
            selectedClassError,
            selectedClassErrorMsg,
            teacherClassesDropdownData,
            selectedClassName,
            studentDropdownData,
            selectedStudentError,
            selectedStudentErrorMsg
        } = this.state;

        if (openConfirmDialog) {
            return (
                <AssignCourseConfirmDialog
                    open={openConfirmDialog}
                    onCancel={this.handleCloseConfirmDialog}
                    onConfirm={this.handleSaveAssignCourse}
                    classTitle={selectedClassName}
                    courseName={courseTitle}
                />
            );
        } else if (openSuccessDialog) {
            return (
                <AssignCourseSuccessDialog
                    open={openSuccessDialog}
                    onCancel={this.closeSuccessDialogOnYes}
                    onConfirm={this.closeSuccessDialogOnYes}
                    classTitle={''}
                    courseName={''}
                />
            );
        } else {
            return (
                <Dialog className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open}>
                    <Box padding={"30px"} className={`${classes.bgColor}`}>
                        <Grid direction="row" container>
                            <Grid xs={12} item>
                                <Typography className={`${classes.fontText24} ${classes.textPrimaryColor}`} component={"span"}>Assign Course</Typography>
                                <Button onClick={this.props.onCancel} className={classes.textPrimaryColor} style={{ float: "right" }}> X </Button>
                            </Grid>
                        </Grid>
                        <DialogContent style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}>
                            <Grid container spacing={2} direction="row">
                                <Grid xs={6} item>
                                    <Box>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Course Title
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomTextField
                                                placeHolderText="Enter Course Title"
                                                fieldName="courseTitle"
                                                fieldId="course-title-input-field"
                                                textFieldStyles={inputStyle}
                                                fieldValue={courseTitle}
                                                fieldWidth={"99%"}
                                                fieldType="text"
                                                isDisabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Catalog
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomTextField
                                                placeHolderText="Enter Catalog Name"
                                                fieldName="catalogTitle"
                                                fieldId="catalog-name-input-field"
                                                textFieldStyles={inputStyle}
                                                fieldValue={catalogTitle}
                                                fieldWidth={"99%"}
                                                fieldType="text"
                                                isDisabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Subject
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomTextField
                                                placeHolderText="Enter Subject Name"
                                                fieldName="subjectName"
                                                fieldId="subject-name-field"
                                                textFieldStyles={inputStyle}
                                                fieldValue={subjectName}
                                                fieldWidth={"99%"}
                                                fieldType="text"
                                                isDisabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                        {this.props.role !== USER_ROLES_CAMEL.PARENT_TEACHER ? "Teacher" : "Parent Teacher"}
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomTextField
                                                placeHolderText="Enter Teacher Name"
                                                fieldName="teacherName"
                                                fieldId="teacher-name-input-field"
                                                textFieldStyles={inputStyle}
                                                fieldValue={teacherName}
                                                fieldWidth={"99%"}
                                                fieldType="text"
                                                isDisabled={true}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                {this.props.role !== USER_ROLES_CAMEL.PARENT_TEACHER ?
                                <Grid xs={6} item>
                              <Box marginBottom={"10px"} width={"100%"}>
                                  <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                      Class
                                  </Typography>
                                  <Box marginBottom={"10px"}>
                                      <CustomMultiSelectDropDown
                                          label="Select Class"
                                          fieldMenuItems={teacherClassesDropdownData}
                                          fieldStyles={dropdownStyle}
                                          selectedItems={selectedClassValue}
                                          handleChange={this.handleChangeClass}
                                          fieldError={selectedClassError}
                                          helperText={selectedClassErrorMsg}
                                          isDisabled={false}
                                      />
                                  </Box>
                              </Box>
                                </Grid>
                                :
                              <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Student
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomMultiSelectDropDown
                                                label="Select Student"
                                                fieldMenuItems={studentDropdownData}
                                                fieldStyles={dropdownStyle}
                                                selectedItems={selectedStudentValue}
                                                handleChange={this.handleChangeStudent}
                                                fieldError={selectedStudentError}
                                                helperText={selectedStudentErrorMsg}
                                                isDisabled={false}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                }
                                <Grid xs={12} item>
                                    <Box marginBottom={"10px"} style={{ width: "100%" }}>
                                        <CustomButton btnText="Assign" btnStyle={assignButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleAssignCourse} isDisabled={false} />
                                        <CustomButton btnText="Cancel" btnStyle={cancelButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                </Dialog>
            );
        }
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    border: "1px solid",
    fontSize: "18px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "15px",
    fontWeight: 500,
    float: "right",
    marginLeft: "15px",
    marginBottom: "15px",
} as const;

const assignButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    fontSize: "18px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "15px",
    fontWeight: 500,
    float: "right",
    marginLeft: "15px",
    marginBottom: "15px",
} as const;

const dropdownStyle = {
    height: "50px",
    width: "100%",
    padding: "17px 15px",
    borderRadius: "12px",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
} as const;

const inputStyle = {
    padding: "10px 0",
    maxWidth: "100%",
    marginTop: "10px",
    height: "28px",
}
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssignCourseModal);
// Customizable Area End
