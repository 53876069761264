import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from "../../../framework/src/StorageProvider";
import { Attributes, Category, TaskList } from "./Types";
import { Alert } from "react-native";
interface MyEvent {
  target: {
    value: string;
    checked: boolean;
  },
}
interface MyEventAnchor {
  currentTarget: HTMLElement;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  categories: Category[];
  activeTab: string;
  responseData: TaskList[];
  showModal: boolean;
  taskSelected: Attributes;
  noDuesErr: string;
  loaderActive: boolean;
  emptyTask: Attributes;
  completed: boolean;
  startDate: string;
  endDate: string;
  showStartEndDatePicker: boolean;
  showingDatePickerFor: string;
  moreLoading: boolean,
  noMoreTask: boolean,
  page: number,
  initialDateSelection: boolean
  errorMsg: string
  anchorEl: Element | null;
  todayDate: string;
  isScrollbar: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SmartcategorisationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  categoryListApiCallId: string = '';
  markAsCompleteApiCallId: string = '';
  storageToken: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.TaskActionApiResponseMessage),
      // getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      categories: configJSON.categories,
      activeTab: 'today',
      responseData: [],
      showModal: false,
      taskSelected: configJSON.emptyTask,
      noDuesErr: '',
      loaderActive: false,
      emptyTask: configJSON.emptyTask,
      completed: false,
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date().toISOString().split('T')[0],
      showStartEndDatePicker: false,
      showingDatePickerFor: '',
      moreLoading: false,
      noMoreTask: false,
      page: 1,
      initialDateSelection: false,
      errorMsg: '',
      anchorEl: null,
      todayDate: new Date().toISOString().split('T')[0],
      isScrollbar: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.TaskActionApiResponseMessage) === message.id) {
      this.setState({ loaderActive: true }, () => this.getTask(this.state.activeTab, this.state.startDate,
        this.state.endDate))
    }
    this.setState({ loaderActive: false })
    if (apiRequestCallId === this.categoryListApiCallId) {
      if (!responseJson) { return alert('no data found') }
        if (this.isPlatformWeb()) {
         this.handleGetApiDataResponse(responseJson)
      }
      else {
        this.handleGetTaskApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }
  // web events	
 
  // Customizable Area Start
  handleGetTaskApiResponse(responseJson: { data: TaskList[] }) {
    let taskData = this.state.responseData;
    if (responseJson.data.length > 0) {
      taskData = [...taskData, ...responseJson.data]
      this.setState({ responseData: taskData, noDuesErr: '', moreLoading: false })
    }
    else {
      if (this.state.page > 1) {
        this.setState({ noMoreTask: true, moreLoading: false, responseData: taskData })
      } else {
        let errorMsg = configJSON.categories.find((category: Category) => {
          return category.dueHeader == this.state.activeTab
        }).errorrMsg;
        this.setState({ noDuesErr: !this.state.completed ? errorMsg : configJSON.completedTaskErrMsg, responseData: [], moreLoading: false, noMoreTask: false })
      }
    }
  }
  handleGetApiDataResponse(responseJson: { data: TaskList[] }) {
    if (responseJson.data.length >= 20) {
      this.setState({
        isScrollbar: true
      })
      if (this.state.isScrollbar) {
        window.addEventListener("scroll", () => {
          this.handleScroll(this.state.isScrollbar, (loadData: boolean) => {
          })
        });
      }
    }
    if (this.state.page > 1) {
      let oldData = this.state.responseData
      let newData = responseJson.data
      let mergeData = oldData.concat(newData)
      this.setState({
        responseData: mergeData
      })
    } else {
      this.setState((prevState: { responseData: TaskList[] }) => ({
        responseData: [...responseJson.data, ...prevState.responseData,],
      }));

    }
  }
  navigateToCreateTaskScreen = (action: string, item?: Attributes) => {
    this.setState({ responseData: [], showModal: false })
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));

    navMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const updateTask = new Message(getName(MessageEnum.TaskActionMessage));
    updateTask.addData(getName(MessageEnum.TaskActionToPerformMessage), action);
    updateTask.addData(getName(MessageEnum.TaskDataMessage), item)
    navMsg.addData(getName(MessageEnum.NavigationRaiseMessage), updateTask)
    navMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Task");
    this.send(navMsg)
    if (this.isPlatformWeb()) {
      this.handleMenuClose()
    }
  }
  openModal = (item: Attributes) => {
    this.setState({ showModal: true, taskSelected: item })
  }
  closeModal = () => {
    this.setState({ showModal: false }, () => {
    });
  }
  isTaskCompleted = (status: string) => {
    return status == configJSON.completedStatus ? true : false
  }
  iCanMarkTaskAsComplete = (dueDate: string) => {
    return new Date() <= new Date(dueDate) ? true : false
  }
  async componentDidMount() {
    super.componentDidMount();
    this.storageToken = await storage?.get('token');
    this.setState({ token: this.storageToken });
    this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate);
  }

  handleScroll = async (isScrollbar: boolean, callBack: (param: boolean) => void) => {
    const { activeTab, startDate, endDate } = this.state;
    if (this.state.isScrollbar === true) {
      if (window.scrollY >= document.documentElement.scrollHeight - window.innerHeight) {
        callBack(false)
        this.setState({ page: this.state.page + 1, isScrollbar: false }, () => {
          this.getTask(activeTab, startDate, endDate);
        });
      }
    }
  }

  handleDateChange = async (event: MyEvent) => {
    this.setState({ startDate: event.target.value, responseData: [], page: 1 }, () => {
      if ((this.state.endDate >= this.state.startDate) && this.state.startDate !== '' && this.state.endDate !== '') {
        this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate);
      }
    });
  };

  handleEndChange = async (event: MyEvent) => {
    this.setState({ endDate: event.target.value, responseData: [], page: 1 }, () => {
      if ((this.state.endDate >= this.state.startDate) && this.state.startDate !== '' && this.state.endDate !== '') {
        this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate);
      }
    });
  };

  toggleSwitch = (event: MyEvent) => {
    this.setState({
      completed: this.isPlatformWeb() ? event.target.checked : !this.state.completed,
      page: 1,
      responseData: [],
      loaderActive: true
    }, () =>
      this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate))
  }

  handleanchorEl = async (event: MyEventAnchor, data: TaskList) => {
    this.setState({ anchorEl: event.currentTarget, taskSelected: data.attributes });
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }
  handleTabChange = (event: object, newValue: string) => {
    let startDate = '';
    let endDate = '';

    if (newValue === 'past_dues') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      startDate = endDate = yesterday.toISOString().split('T')[0];
    } else if (newValue === 'today') {

      startDate = endDate = new Date().toISOString().split('T')[0];
    } else if (newValue === 'tomorrow') {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      startDate = endDate = tomorrow.toISOString().split('T')[0];
    } else if (newValue === 'future_dues') {

      if (!this.isPlatformWeb()) {
        startDate = endDate = this.state.startDate;
      }
      else {
        endDate = startDate = '';
      }
    }
    if (!this.isPlatformWeb()) {
      newValue !== configJSON.categories[3].dueHeader ?
        this.setState({
          page: 1,
          responseData: [],
          loaderActive: true,
          completed: false,
          noMoreTask: false,
          initialDateSelection: false,
          activeTab: newValue,
          startDate,
          endDate
        }, () =>
          this.getTask(newValue, startDate, endDate)
        ) :
        this.setState({
          activeTab: newValue,
          page: 1,
          responseData: [],
          completed: false,
          noMoreTask: false,
          noDuesErr: '',
          initialDateSelection: true,
          startDate: new Date().toDateString(),
          endDate: new Date().toDateString()
        }, () => this.selectStartDate())
    }
    else {
      this.setState(
        { activeTab: newValue, errorMsg: `No task found for ${newValue}`, startDate, endDate, page: 1, responseData: [], completed: false },
        () => {
          if (newValue !== 'future_dues') {
            this.getTask(newValue, startDate, endDate);
          } else {
            this.setState({ errorMsg: 'Please select start Date & End Date' })
          }
        }
      );
    }
  }
  getTask = (name: string, startDate: string, endDate: string) => {

    let taskToken = this.state.token;
    const header = {
      "Content-Type": configJSON.categoryListApiContentType,
      'token': taskToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let endPoint: string = configJSON.categoryListApiEndPoint
      .replace('{startDate}', startDate)
      .replace('{endDate}', endDate)
      .replace('{filterStatus}', this.state.completed ? configJSON.filterStatus : 'all')
      .replace('{pageNo}', this.state.page)
      .replace('{taskLimit}', 20)
    this.categoryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoryListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getDateTime(date: Date | string) {
    return ({ date: new Date(date).toLocaleDateString(), time: new Date(date).toLocaleTimeString() })
  }

  selectStartDate = () => {
    if (this.state.activeTab == configJSON.categories[3].dueHeader) {
      this.setState({ showingDatePickerFor: 'start', showStartEndDatePicker: true })
    }
  }
  selectEndDate = () => {
    if (this.state.activeTab == configJSON.categories[3].dueHeader) {
      this.setState({ showingDatePickerFor: 'end', showStartEndDatePicker: true })
    }
  }
  handleConfirm = (date: Date) => {
    const dateTimeString = this.dateToString(date);
    if (this.state.showingDatePickerFor == 'start') {
      this.setState({
        startDate: dateTimeString,
        showStartEndDatePicker: false,
        page: 1,
        responseData: [],
        loaderActive: this.state.initialDateSelection ? false : true
      }, () => this.state.initialDateSelection ?
        setTimeout(() =>
          Alert.alert("Start date selected",
            "Please select end date now",
            [
              {
                text: "OK",
                onPress: () =>
                  this.selectEndDate()
              }
            ],
            { cancelable: false }
          ), 1000)
        :
        this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate)
      );
    } else if (this.state.showingDatePickerFor == 'end') {
      this.setState({
        endDate: dateTimeString,
        showStartEndDatePicker: false,
        page: 1,
        responseData: [],
        loaderActive: true,
        initialDateSelection: false
      }, () =>
        this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate)
      );
    }

  }
  dateToString(date: Date) {
    const dateString = date?.toISOString().split("T")[0];
    return dateString;
  }
  handleCancel() {
    this.setState({ showStartEndDatePicker: false, showingDatePickerFor: '' })
  }
  fetchMoreData = () => {
    if (this.state.responseData.length > 10) {
      this.setState({ page: this.state.page + 1, moreLoading: true }, () =>
        this.getTask(this.state.activeTab, this.state.startDate, this.state.endDate)
      )
    }
  }
  //delete /Complete
  performAction = (actionToPerform: string, item: Attributes) => {
    this.setState({ responseData: [], page: 1, showModal: false })
    const updateTask = new Message(getName(MessageEnum.TaskActionMessage));
    updateTask.addData(getName(MessageEnum.TaskActionToPerformMessage), actionToPerform);
    updateTask.addData(getName(MessageEnum.TaskDataMessage), item)
    this.send(updateTask)
    this.handleMenuClose()
  }
  // Customizable Area End
}
