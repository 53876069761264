// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedLessonName: string;
  selectedTopicName: string;
  openAddNewTopicModal: boolean,
  selectedCourseSubject: null,
  openAddNewLessonModal: boolean,
  selectCourseSubject: null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CoursesJourneyTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      selectedLessonName: '',
      selectedTopicName: '',
      openAddNewLessonModal: false,
      openAddNewTopicModal: false,
      selectedCourseSubject: null,
      selectCourseSubject: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  handleSetTopicName = (topicTitle: string) => {
    this.setState({ selectedTopicName: topicTitle })
  }

  handleSetLessonName = (lessonTitle: string) => {
    this.setState({ selectedLessonName: lessonTitle })
  }
  // Customizable Area End
}