import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");
export const courseDetailTabs = [
    {
        id: 1,
        label: "Resources",
        value: "resources"
    },
    {
        id: 2,
        label: "Journey",
        value: "journey"
    },
    {
        id: 3,
        label: "Participants",
        value: "participants"
    }
]

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    selectedTabIndex: number;
    selectedTabName: string;
    currentCourseName: string;
    openSendApprovalReqModal: boolean;
    openAddNewResourceModal: boolean;
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CourseDetailPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtSavedValue: "A",
            selectedTabIndex: 0,
            selectedTabName: "Resources",
            currentCourseName: "",
            openSendApprovalReqModal: false,
            openAddNewResourceModal: false,
            // Customizable Area Start
            // Customizable Area End
        };
        // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.setState({ currentCourseName: 'Physics' })
    }

    handleChangeAssessmentCategoryTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedTabIndex: newValue });
        console.log('tab value: ', newValue);

        if (newValue === 0) {
            this.setState({ selectedTabName: "Resources" });
        } else if (newValue === 1) {
            this.setState({ selectedTabName: "Journey" });
        } else if (newValue === 2) {
            this.setState({ selectedTabName: "Participants" });
        }
    };

    handleAddNewCourse = () => {
        console.log('add new course')
    }

    handleRedirectBack = () => {
        this.props.navigation.navigate('CourseManagement', {});
    }

    handleOpenSendApprovalReq = () => {
        this.setState({ openSendApprovalReqModal: true });
    }

    handleCloseSendApprovalModal = () => {
        this.setState({ openSendApprovalReqModal: false });
    }


    handleOpenAddNewResourceModal = () => {
        this.setState({ openAddNewResourceModal: true });
    }

    handleCloseAddNewResourceModal = () => {
        this.setState({ openAddNewResourceModal: false });
    }
    // Customizable Area End
}
