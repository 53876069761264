// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import NewGradeTitlePopController,{Props} from "./NewGradeTitlePopController.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    requiredNotation: {
      color: "red",
    },
    addCourseBox: {
      "& .MuiDialog-paperWidthSm": {
        overflowX: "hidden",
        width: "880px",
        // minHeight: "500px",
        maxWidth: "100%",
        overflowY: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
      },
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    circularContainer: {
      top: "40%",
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    customLoader: {
      position: "absolute",
      left: 0,
      bottom: -30,
      zIndex: 100,
      top: 0,
      right: 0,
      display: "block",
      backgroundColor: "rgb(0, 0, 0, .4)",
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewGradeTitlePop extends NewGradeTitlePopController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open,onCancel,id,isEdit } = this.props;
    const {
      titleValue,
      titleError,
      titleErrorMsg,
      loading,
      minScore,
      minScoreError,
      minScoreErrorMsg,
      maxScore,
      maxScoreError,
      maxScoreErrorMsg,
    } = this.state;

    return (
      <Box>
        <Dialog
          open={open}
          aria-labelledby="add-new-grade-title-pop"
          scroll={"body"}
          className={`${classes.highlightBgColor} ${classes.addCourseBox}`}
          
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box
            className={`${classes.bgColor}`}
            padding={"30px"}
            data-test-id="grade-pop"
          >
            <Grid direction="row" container>
              <Grid
                xs={12}
                item
                className={`${classes.headerGrid}`}
                data-test-id="grademodalGrid"
              >
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="addgradetitle"
                >
                 Grading Title
                </Typography>
                <Button
                  onClick={onCancel}
                  className={classes.textPrimaryColor}
                  id="add-new-grade-title-close-modal-icon-btn"
                  style={{ float: "right" }}
                >
                  <CloseIcon
                  
                    className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                    data-testid={`${id}-close-button`}
                  />
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
              data-test-id="content"
            >
              <Grid container spacing={2} direction="row" data-test-id="row">
                <Grid xs={12} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Title
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Title"
                      fieldName="titleValue"
                      fieldId="title"
                      fieldValue={titleValue}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={titleError}
                      helperText={titleErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Minimum Score
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Minimum Score"
                      fieldName="minScore"
                      fieldId="min-score"
                      fieldValue={minScore}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={minScoreError}
                      helperText={minScoreErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Maximum Score
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Maximum Score"
                      fieldName="maxScore"
                      fieldId="max-score"
                      fieldValue={maxScore}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={maxScoreError}
                      helperText={maxScoreErrorMsg}
                    />
                  </Box>
                </Grid> 
                <Grid item xs={12}>
                  <Box style={{ width: "100%" }} marginBottom={"10px"}>
                    <CustomButton
                      btnText={isEdit?"Edit":"Add"}
                      buttonId={`submit-add-title-pop`}
                      buttonVariant={"contained"}
                      btnStyle={submitBtnStyles}
                      handleButtonClick={this.handleRequestBody}
                      type={"button"}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      buttonVariant={"outlined"}
                      btnStyle={cancelBtnSTyle}
                      buttonId={`newCourseCanclButton`}
                      isDisabled={false}
                      type={"button"}
                      handleButtonClick={onCancel}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
  
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const cancelBtnSTyle = {
  fontWeight: 500,
  width: "120px",
  height: "50px",
  marginTop: "32px",
  padding: "10px 10px",
  textAlign: "center",
  float: "right",
  borderRadius: "12px",
  fontSize: "18px",
  marginLeft: "15px",
  
} as const;

const submitBtnStyles = {
  height: "50px",
  borderRadius: "12px",
  width: "120px",
  textAlign: "center",
  padding: "10px 10px",
  fontSize: "18px",
  marginTop: "32px",
  marginLeft: "15px",
  float: "right",
  fontWeight: 500,
} as const;
const textfieldStyles = {
  width: "99%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewGradeTitlePop);
// Customizable Area End
