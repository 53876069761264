// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
  toBase64,
} from "../../../components/src/CommonHelper.web";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";

const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  firstName: string;
  firstNameError: boolean;
  firstNameErrorMsg: string;
  lastName: string;
  lastNameError: boolean;
  lastNameErrorMsg: string;
  emailId: string;
  profileImage: any;
  selectedProfileImgName: string;
  selectedProfileImgType: string;
  selectedProfileImgUrlBase64: any;
  selectedProfileImgUrlS3: string;
  removeIcon: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class EditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserInfoId: string = "";
  apiUpdateUserInfoId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      loading: false,
      firstName: "",
      firstNameError: false,
      firstNameErrorMsg: "",
      lastName: "",
      lastNameError: false,
      lastNameErrorMsg: "",
      emailId: "",
      profileImage: "",
      selectedProfileImgName: "",
      selectedProfileImgType: "",
      selectedProfileImgUrlBase64: "",
      selectedProfileImgUrlS3: "",
      removeIcon: false,
    };
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      this.setState({ firstNameError: false, firstNameErrorMsg: "" });
    }
    if (name === "lastName") {
      this.setState({ lastNameError: false, lastNameErrorMsg: "" });
    }
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleProfileImgFileInputChange = async (file: any) => {
    this.setState({
      profileImage: file || "",
      selectedProfileImgName: file?.name || "",
      selectedProfileImgType: file?.type || "",
      selectedProfileImgUrlBase64: file ? await toBase64(file) : "",
      removeIcon: !file,
    });
    // Handle the selected file here
  };

  handleGetUserInfoResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        firstName: responseJson?.data?.attributes?.first_name || "",
        lastName: responseJson?.data?.attributes?.last_name || "",
        emailId: responseJson?.data?.attributes?.email || "NA",
        selectedProfileImgUrlS3:
          responseJson?.data?.attributes?.profile_url || "",
        selectedProfileImgName: responseJson?.data?.attributes?.profile_icon?.filename || "",
        selectedProfileImgType: responseJson?.data?.attributes?.profile_icon?.content_type || "",
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleVerifyUserFullName = () => {
    const { firstName, lastName } = this.state;
    let isValid = true;
    if (!firstName?.trim()) {
      this.setState({
        firstNameError: true,
        firstNameErrorMsg: "Please enter first name.",
      });
      isValid = false;
    }
    if (!lastName?.trim()) {
      this.setState({
        lastNameError: true,
        lastNameErrorMsg: "Please enter last name.",
      });
      isValid = false;
    }
    return isValid;
  };

  handleUpdateUserProfileResponse = async (responseJson: any) => {
    if (responseJson?.data) {
      await setStorageData("user_name", `${responseJson?.data?.attributes?.first_name} ${responseJson?.data?.attributes?.last_name}`);
      await setStorageData("user_profile_img", responseJson?.data?.attributes?.profile_url);
      window.dispatchEvent(new Event("storage"));
      toast.success(TOASTER_NOTIFICATION_MSG.PROFILE_UPDATED_SUCCESS);
    } else {
      toast.error("Something went wrong while updating user details.");
    }
    hideCustomLoader();
  };

  handleVerifyEditProfileData = async () => {
    if (this.handleVerifyUserFullName()) {
      showCustomLoader();
      const { firstName, lastName, selectedProfileImgUrlBase64, selectedProfileImgName, selectedProfileImgType, removeIcon } = this.state;
      const token = await getStorageData("token");
      const userId = await getStorageData("user_id");
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token,
      };
      const requestBody: any = {
        data: {
          attributes: {
            first_name: firstName,
            last_name: lastName,
            profile: selectedProfileImgUrlBase64,
            content_type: selectedProfileImgType,
            filename: selectedProfileImgName,
          },
        },
      };
      if(removeIcon) {
        requestBody.data.attributes.remove_icon = true;
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiUpdateUserInfoId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.userDetailsEndpoint}/${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleGetUserInfo = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const teacherId = await getStorageData("user_id");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserInfoId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${teacherId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetUserInfo();
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetUserInfoId) {
          this.handleGetUserInfoResponse(responseJson);
        } else if (apiRequestCallId === this.apiUpdateUserInfoId) {
          this.handleUpdateUserProfileResponse(responseJson);
        }
      }
      // Customizable Area Start

      // Customizable Area End
    }
  }
  // Customizable Area End
}
// Customizable Area End
