// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import TeacherAddNewLessonController, {
  Props,
} from "./TeacherAddNewLessonController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";
import CustomStepperWeb from "../../../components/src/CustomStepper.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addNewLessonModalContainer: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        width: "70vw",
        minHeight: "500px",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
      },
    },
    requiredFieldsA: {
      color: "red",
    },
    customSteppers: {
      "&.MuiStepper-root": {
        padding: "0px",
        height: "64px",
        marginBottom: "25px",
        position: "absolute",
        left: "0",
        paddingLeft: "22px",
        paddingRight: "22px",
        right: "0",
        color: "red",
      },
      "& .MuiSvgIcon-root": {
        width: "44px",
        height: "44px",
      },
      "& .MuiTypography-body2": {
        fontSize: "20px",
        paddingLeft: "10px",
      },
      "& .MuiStepIcon-text": {
        fontSize: "20px",
        color: "green !important",
      },
    },
    TextareaAutosize: {
      "&::placeholder": {
        fontSize: "16px",
        color: "#888888",
        opacity: "0.9",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    customLoader: {
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
      backgroundColor: "rgb(0, 0, 0, .4)",
      bottom: -30,
      display: "block",
      zIndex: 100,
    },
    circularContainer: {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherAddNewLessonModal extends TeacherAddNewLessonController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit } = this.props;
    const {       
      teacherMaterialsContent,
      studentMaterialsContent,
      teacherInstructionsContent,
      studentInstructionsContent,
      lessonTitleValue,
      lessonTitleError,
      lessonTitileMsg,
      descriptionValue,
      descriptionError,
      descriptionErrorMsg,
      objectivesValue,
      detailsValue,
      customFieldValue,
      tagsValue,
      loading,
      classesCustomFieldConfigList,
    } = this.state;
    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addNewLessonModalContainer}`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
          scroll={"body"}
          data-test-id="DailoqNewLesson"
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box
            className={`${classes.bgColor}`}
            data-test-id="DailoqNewLessonModal"
          >
            <Grid direction="row" container>
              <Grid xs={12} item data-test-id="DailoqNewLessGrid">
                <Box px={"30px"} pt={"30px"}>
                  <Typography
                    className={`${classes.fontText24} ${classes.textPrimaryColor}`}
                    component={"span"}
                    data-test-id="AddNewLessonTitle"
                  >
                    {isEdit ? 'Edit Lesson' : 'Add New Lesson'}
                  </Typography>
                  <Button
                    onClick={this.props.onCancel}
                    className={classes.textPrimaryColor}
                    style={{ float: "right" }}
                    data-test-id="CrossBtn"
                  >
                    {" "}
                    X{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
              data-test-id="DailoqNewLessonContent"
            >
              <Box className={classes.root} data-test-id="DailoqNewLessonRoot">
                <CustomStepperWeb
                  finishLabel="Save"
                  nextBtnLabel="Next"
                  stepperId="tenant-course-mng-create-new-catalog-stepper"
                  prevBtnLabel="Previous"
                  hidePreviousBtn
                  showCancelBtn
                  steps={this.state.steps}
                  handleNext={this.handleNextSteppers}
                  handleSave={this.handleLessonSave}
                  handleCancelBtn={this.handleCancelStepperBtns}
                  pause={this.handlePauseStepper()}
                >
                  {this.state.stepperScreen === "catalog_details" && (
                    <Box px={"30px"}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        data-test-id="DailoqNewLessonContainer"
                      >
                        <Grid
                          style={{ marginTop: "5px" }}
                          xs={12}
                          item
                          data-test-id="DailoqNewLessonItem"
                        >
                          <Grid container spacing={2} direction="row">
                            <Grid xs={6} item data-test-id="DailoqNewLessonChild">
                              <Box
                                marginBottom={"10px"}
                                width={"100%"}
                                data-test-id="TitleBox"
                              >
                                <Typography
                                  component={"span"}
                                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                                  data-test-id="DailoqNewLessonName"
                                >
                                  Lesson Title
                                  <Typography
                                    component={"span"}
                                    className={classes.requiredFieldsA}
                                    data-test-id="ClassOne"
                                  >
                                    *
                                  </Typography>
                                </Typography>
                                <CustomTextField
                                  placeHolderText="Enter Lesson Title"
                                  fieldName="lessonTitleValue"
                                  fieldId="lesson-title-input-field"
                                  textFieldStyles={LessonModalField}
                                  fieldWidth={"100%"}
                                  fieldType="text"
                                  fieldValue={lessonTitleValue}
                                  handleChange={this.handleInputChange}
                                  fieldError={lessonTitleError}
                                  helperText={lessonTitileMsg}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={12} item data-test-id="AddNewBox">
                          <Box
                            marginBottom={"10px"}
                            width={"100%"}
                            data-test-id="AddNewNoN"
                          >
                            <Typography
                              component={"span"}
                              className={`${classes.fontText16} ${classes.textCommonColor}`}
                              data-test-id="DailoqNewLessonDescription"
                            >
                              Description
                              <Typography
                                component={"span"}
                                className={classes.requiredFieldsA}
                                data-test-id="requiredFieldsA"
                              >
                                *
                              </Typography>
                            </Typography>
                            <CustomTextField
                              placeHolderText="Enter Description"
                              fieldName="descriptionValue"
                              multiline
                              fieldId="description-input-field"
                              fieldValue={descriptionValue}
                              textFieldStyles={{ padding: "10px 0", height: "140px", marginTop: "10px" }}
                              helperText={descriptionErrorMsg}
                              fieldWidth={"99%"}
                              fieldError={descriptionError}
                              handleChange={this.handleInputChange}
                              fieldType="text"
                            />
                          </Box>
                        </Grid>
                        <Grid xs={6} item data-test-id="minimumItem">
                          <Box
                            marginBottom={"10px"}
                            width={"100%"}
                            data-test-id="minimumItemBox"
                          >
                            <Typography
                              component={"span"}
                              className={`${classes.fontText16} ${classes.textCommonColor}`}
                              data-test-id="ObjectivesItem"
                            >
                              Objectives
                            </Typography>
                            <CustomTextField
                              fieldName="objectivesValue"
                              placeHolderText="Enter Objectives"
                              multiline
                              fieldValue={objectivesValue}
                              fieldId="objectives-input-field"
                              handleChange={this.handleInputChange}
                              fieldWidth={"99%"}
                              fieldType="text"
                              textFieldStyles={{ padding: "10px 0", height: "140px", marginTop: "10px" }}
                            />
                          </Box>
                        </Grid>
                        <Grid xs={6} item data-test-id="Details">
                          <Box marginBottom={"10px"} width={"100%"}>
                            <Typography
                              component={"span"}
                              className={`${classes.fontText16} ${classes.textCommonColor}`}
                              data-test-id="DetailsName"
                            >
                              Details
                            </Typography>
                            <CustomTextField
                              placeHolderText="Enter Details"
                              fieldName="detailsValue"
                              multiline
                              fieldId="details-input-field"
                              textFieldStyles={{ padding: "10px 0", height: "140px", marginTop: "10px" }}
                              fieldValue={detailsValue}
                              handleChange={this.handleInputChange}
                              fieldWidth={"99%"}
                              fieldType="text"
                            />
                          </Box>
                        </Grid>
                        <Grid xs={6} item data-test-id="DetailsTags">
                          <Box marginBottom={"10px"} width={"100%"}>
                            <Typography
                              component={"span"}
                              className={`${classes.fontText16} ${classes.textCommonColor}`}
                              data-test-id="DetailsTagsNme"
                            >
                              Tags
                            </Typography>
                            <CustomTextField
                              placeHolderText="Enter Value"
                              fieldName="tagsValue"
                              fieldId="tags-input-field"
                              textFieldStyles={LessonModalField}
                              fieldWidth={"100%"}
                              fieldType="text"
                              fieldValue={tagsValue}
                              handleChange={this.handleInputChange}
                            />
                          </Box>
                        </Grid>                        
                             {classesCustomFieldConfigList?.map((classesCustomFieldItem) => (
            <Grid
              key={`${classesCustomFieldItem?.label}-${classesCustomFieldItem?.id}`}
              item
              lg={6}
              md={6}
              sm={12}
            >
              <Box style={{ marginRight: "20px" }}>
                <Typography  className={`${classes.fontText16} ${classes.textCommonColor} ${classes.inputLabel} `} data-test-id="DetailsCustomFiled">
                  {classesCustomFieldItem?.label}
                  
                </Typography>
                <CustomTextField
                  fieldName={classesCustomFieldItem?.name}
                  fieldWidth={"100%%"}
                  fieldId={`class-mng-custom-field-${classesCustomFieldItem?.id}`}
                  fieldValue={classesCustomFieldItem?.value}
                  isDisabled={classesCustomFieldItem?.isDisabled}
                  textFieldStyles={customFieldsStyles}                  
                  placeHolderText={classesCustomFieldItem?.placeHolderText}
                  fieldType="text"                  
                  handleChange={this.handleClassCustomFieldTextChange}
                />
              </Box>
            </Grid>
          ))} 

                      </Grid>
                    </Box>
                  )}

                  {this.state.stepperScreen === "add_courses" && (
                    <Box px={"30px"}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        data-test-id="Steppersecpage"
                      >
                        <Grid xs={12} style={{ marginTop: "5px" }} item></Grid>
                        <Grid xs={6} item data-test-id="StepperGrid">
                          <Typography
                            component={"span"}
                            className={`${classes.fontText16} ${classes.textCommonColor}`}
                            data-test-id="Steppersecpage_one"
                          >
                            Teacher Materials
                          </Typography>
                          <Box
                            height={"fit-content"}
                            mt={"8px"}
                            data-test-id="Stepper_two"
                          >
                            <CustomTextEditor
                              editorContent={teacherMaterialsContent}
                              editorId={`due-assignment-`}
                              onWysEditorStateChange={this.handleTeacherMaterialChange}
                              placeHolderText="Enter Teacher Materials"
                              editorError={false}
                              helperText=""
                            />
                          </Box>
                        </Grid>
                        <Grid xs={6} item data-test-id="Stepper_Three">
                          <Typography
                            component={"span"}
                            className={`${classes.fontText16} ${classes.textCommonColor}`}
                          >
                            Student Materials
                          </Typography>
                          <Box height={"fit-content"} mt={"8px"}>
                            <CustomTextEditor
                              placeHolderText="Enter Student Materials"
                              editorId={`due-assignment-`}
                              onWysEditorStateChange={this.handleStudentMaterialChange}
                              editorError={false}
                              editorContent={studentMaterialsContent}
                              helperText=""
                            />
                          </Box>
                        </Grid>
                        <Grid xs={6} item data-test-id="Stepper_four">
                          <Typography
                            component={"span"}
                            className={`${classes.fontText16} ${classes.textCommonColor}`}
                            data-test-id="Stepper_Instructions"
                          >
                            Teacher Instructions
                          </Typography>
                          <Box mt={"8px"}>
                            <CustomTextEditor
                              helperText=""
                              editorContent={teacherInstructionsContent}
                              editorId={`due-assignment-`}
                              onWysEditorStateChange={this.handleTeacherInstructionChange}
                              placeHolderText="Enter Teacher Instructions"
                              editorError={false}
                            />
                          </Box>
                        </Grid>
                        <Grid xs={6} item data-test-id="Student_Instructions">
                          <Typography
                            component={"span"}
                            className={`${classes.fontText16} ${classes.textCommonColor}`}
                            data-test-id="Student_ions"
                          >
                            Student Instructions
                          </Typography>
                          <Box mt={"8px"}>
                            <CustomTextEditor
                              editorError={false}
                              editorContent={studentInstructionsContent}
                              editorId={`due-assignment-`}
                              onWysEditorStateChange={this.handleStudentInstructionChange}
                              placeHolderText="Enter Student Instructions"
                              helperText=""
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </CustomStepperWeb>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );

    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addnewlessonBtnStyle = {
  padding: "10px 10px",
  height: "50px",
  width: "120px",
  fontSize: "18px",
  textAlign: "center",
  borderRadius: "12px",
  fontWeight: 500,
  marginTop: "32px",
  marginLeft: "15px",
  float: "right",
} as const;

const assignNewLessonStyleBtn = {
  padding: "10px 10px",
  height: "50px",
  width: "120px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  fontWeight: 500,
  marginTop: "32px",
  marginLeft: "15px",
  float: "right",
} as const;

const LessonModalField = {
  width: "100%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;
const LessonDesc = {
  width: "99%",
  height: "143px",
  padding: "8px 0px",
  marginTop: "10px",
} as const;
const customFieldsStyles = {
  width: "100%",
  padding: "8px 8px",
  marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAddNewLessonModal);
// Customizable Area End
