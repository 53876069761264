import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
type User = {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  profile_pic?: string;
};

type Curriculum = {
  id: number;
  name: string;
};

type Grade = {
  id: number;
  name: string;
};

type SchoolYear = {
  id: number;
  title: string;
};

type Course = {
  id: number;
  course_title: string;
};

type TeacherCatalogue = {
  id: string;
  type: string;
  attributes: {
    catalogue_id: number;
    created_at: string;
    updated_at: string;
    teacher: User;
  };
};

type CustomFields = {
  data: []; 
};

type CatalogueAttributes = {
  name: string;
  description: string | null;
  school_id: number;
  created_by: User;
  curriculum: Curriculum;
  grade: Grade;
  school_year: SchoolYear;
  custom_fields: CustomFields;
  courses: Course[];
  teacher_catalogues: {
    data: TeacherCatalogue[];
  };
};
type CourseTableDataItem = {
  id: string;
  title: { id: string; name: string };
  noOfCourses: number;
  grade: string;
  curriculum: string;
  createdBy: string;
  courses: Course[];
};
type Catalogue = {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
};

type MetaPagination = {
  per_page: number;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
};

type CatalogueResponse = {
  data: Catalogue[];
  meta: {
    pagination: MetaPagination;
  };
  errors?: {
    message: string;
    code?: number;   
  }[];
};
// Customizable Area End

export const configJSON = require("./config");
export const assessmentCategoryTabs = [
  {
    id: 1,
    label: "My Courses",
    value: "my-courses",
  },
  {
    id: 2,
    label: "Catalog",
    value: "catalog",
  },
];

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  selectedCatergoryTab: number;
  selectedTab: string;
  openAddNewCourseModal: boolean;
  searchStudentTitle: string;
  // Customizable Area Start
  coursesTableData:CourseTableDataItem[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseManagementParentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      selectedCatergoryTab: 1,
      selectedTab: "My Courses",
      openAddNewCourseModal: false,
      // Customizable Area Start
      searchStudentTitle: "",
      coursesTableData:[]
      // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message); 
              if (apiRequestCallId === this.apiGetCourseList) {
        this.handleCatalogueListResponse(
          responseJson,
          errorResponse
        );
      }     
    }
  }
  async componentDidMount() {
    localStorage.setItem("role", "teacher");
    super.componentDidMount();
    this.handleGetCourseListData()
  }

  handleChangeCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedCatergoryTab: newValue });
    if (newValue === 0) {
      this.setState({ selectedTab: "My Courses" });
    } else if (newValue === 1) {
      this.setState({ selectedTab: "Catalog" },
      () => this.handleGetCourseListData()
      );
    }
  };
  handleGetCourseListData = async () => {
    showCustomLoader();
 
    let apiConfig = configJSON.postAddNewCatalog;
    
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCourseList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiConfig
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetCourseDataItem = (item:Catalogue) => {  
    const catalog =  item.attributes;   
    const grades = catalog.grade  && catalog.grade.name
    const curriculum = catalog.curriculum &&  catalog.curriculum?.name
    return {
      id: item.id,
      title: { id: item?.id, name: catalog?.name },
      noOfCourses: catalog?.courses ? catalog?.courses?.length : 0,
      grade: grades,
      curriculum: curriculum,
      createdBy: `${catalog?.created_by?.first_name || ''} ${catalog?.created_by?.last_name || ''}`,
      courses: catalog?.courses ? catalog?.courses : [],
    }
  };
  handleCatalogueListResponse = (responseJson: CatalogueResponse, errorResponse: string) => {  
    if (responseJson.data) {
      let courseList = responseJson.data && responseJson.data.map(
        (item: Catalogue, index: number) => {
          return this.handleSetCourseDataItem(item);
        }
      );
      this.setState({
        coursesTableData: courseList,
      },);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };
  handleChange = (event: any) => {
    this.setState({
      searchStudentTitle: event.target?.value,
    });
  };

  handleOpenAddCourseModal = () => {
    this.setState({ openAddNewCourseModal: true });
  };

  handleCloseAddCourseModal = () => {
    this.setState({ openAddNewCourseModal: false });
  };

  handleOpenMyRequest = () => {
    this.props.navigation?.navigate("TeacherMyRequest");
  };
  // Customizable Area End
}
