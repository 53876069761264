// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedSubValue: string;
  selectedGradeValues: string;
  selectLessonValue: string;
  selectStatusValue: string;
  selectUtilizationValue: string;
  openAssignModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class MyCoursesTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      openAssignModal: false,
      selectedSubValue: "",
      selectedGradeValues: "",
      selectLessonValue: "",
      selectStatusValue: "",
      selectUtilizationValue: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleSubjectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  getDropdownListArray = (dropdownName: string) => {
    const refArray = [1, 2, 3, 4, 5];
    let dropdownItems: Array<any> = [];
    refArray.forEach((item) => {
      dropdownItems.push({
        id: item,
        label: `${dropdownName}${item}`,
        value: `${dropdownName}${item}`,
      });
    });
    return dropdownItems;
  };

  renderSubjectDDValue = (selected: any) => {
    const dropdownName = "Subject";
    const dropdownItems = this.getDropdownListArray("Subject");
    return this.checkedAndGetSelectedValue(
      selected,
      dropdownName,
      dropdownItems
    );
  };

  renderGradeDDValue = (selected: any) => {
    const dropdownName = "Grade";
    const dropdownItems = this.getDropdownListArray("Grade");
    return this.checkedAndGetSelectedValue(
      selected,
      dropdownName,
      dropdownItems
    );
  };

  renderLessonDropdownValue = (selected: any) => {
    const dropdownName = "Lesson";
    const dropdownItems = this.getDropdownListArray("Lesson");
    return this.checkedAndGetSelectedValue(
      selected,
      dropdownName,
      dropdownItems
    );
  };

  renderStatusDDValue = (selected: any) => {
    const dropdownName = "Status";
    const dropdownItems = this.getDropdownListArray("Status");
    return this.checkedAndGetSelectedValue(
      selected,
      dropdownName,
      dropdownItems
    );
  };

  renderUtilizationDDValue = (selected: any) => {
    const dropdownName = "Utilization";
    const dropdownItems = this.getDropdownListArray("Utilization");
    return this.checkedAndGetSelectedValue(
      selected,
      dropdownName,
      dropdownItems
    );
  };

  checkedAndGetSelectedValue = (
    selected: any,
    dropdownName: string,
    dropdownItems: any
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find(
      (item: any) => item?.value === selected
    );
    return selctedOption?.label;
  };

  handleOpenCourseDetail = (data: any) => {
    this.props.navigation.navigate("StudentProgresDetails", {});
  };

  handlePublishEventDialog = () => {
    this.setState({
      openAssignModal: true,
    });
  };

  UndefinedFunction = () => {
    console.log("data");
  };

  handleCloseAssignCourseModal = () => {
    this.setState({
      openAssignModal: false,
    });
  };
  // Customizable Area End
}
// Customizable Area End
