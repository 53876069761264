// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@material-ui/core";
import ConfirmSendApprovalModalController, {
  Props,
} from "./ConfirmSendApprovalModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  sendApprovalModal: {
    "& .MuiPaper-rounded": {
      borderRadius: "8px",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
      width: "540px",
    }
  },
});
// Customizable Area End

// Customizable Area Start
export class ConfirmSendApprovalModal extends ConfirmSendApprovalModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <Box>
        <Dialog
          open={open}
          className={`${classes.highlightBgColor} ${classes.sendApprovalModal}`}
          data-test-id="confirmSendApprovalModal"
          aria-labelledby="confim-send-approval-modal"
        >
          <Box className={`${classes.bgColor}`} padding={"30px"} data-test-id="confirmSendApprovalModalBox">
            <Box display={"flex"} justifyContent={"center"} id="confirmSendApprovalModal">
              <Box width={"94%"} display={"flex"} justifyContent={"center"} data-test-id="ImgSrc">
                <img src={exclamationIcon} alt="success icon" />
              </Box>
              <Box width={"6%"} data-test-id="IconBtns">
                <IconButton
                  aria-label="more"
                  data-test-id="IconStyleBtn"
                  aria-controls="long-menu"
                  style={{ float: "right" }}
                  onClick={this.props.onCancel}
                  aria-haspopup="true"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }} data-test-id="sendApprovalContent">
              <Box textAlign={"center"} mt={"20px"} mb={"10px"} data-test-id="sendApprovalContentBox">
                <Typography className={`${classes.textPrimaryColor} ${classes.fontText22} ${classes.fontBold600}`}>
                  Approval Required to Assign
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"} data-test-id="SpanText">
                <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                  Since the course content has been edited, you need <br />
                  to get approval in order to assign
                </Typography>
              </Box>
              <Box
                style={{ gap: '10px' }}
                display={"flex"}
                justifyContent={"space-between"}
                data-test-id="ConfirmBtns"
                alignItems={"center"}
              >
                <CustomButton
                  btnText="Cancel"
                  btnStyle={sendApprovalReqBtns}
                  buttonVariant={"outlined"}
                  buttonId="modal-cancel-btn"
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                  isDisabled={false}
                />
                <CustomButton
                  btnText="Send Approval Request"
                  btnStyle={sendApprovalReqBtns}
                  buttonVariant={"contained"}
                  buttonId="modal-send-approval-btn"
                  type={"button"}
                  handleButtonClick={this.props.onConfirm}
                  isDisabled={false}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const sendApprovalReqBtns = {
  width: "50%",
  padding: "5px",
  borderRadius: "10px",
  height: "50px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ConfirmSendApprovalModal);
// Customizable Area End
