// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import WebAppLayoutController, { Props } from "./WebAppLayoutController.web";
import WebHeader from "./WebHeader.web";
import WebSidebar from "./WebSidebar.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      position: "relative",
      display: "flex",
    },
    mainChildWrapper: {
      marginTop: "85px",
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
    },
    expandSidebarIconBox: {
      position: "fixed",
      top: "127px",
      transition: "left 0.4s ease-out",
      width: "20px",
      height: "20px",
      borderRadius: "8px",
      padding: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgb(253, 215, 47)",
      zIndex: 1,
      cursor: "pointer",
    },
  });

const drawerWidth = 250;
const drawerCloseWidth = 55;

// Customizable Area End

export class WebAppLayout extends WebAppLayoutController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      children,
      keepSidebarOpen,
      isDarkMode,
      isSmallScreen,
    } = this.props;
    return (
      <Box
        className={`${classes.mainContainer} ${classes.highlightBgColor}`}
        height="inherit"
      >
        <WebHeader
          navigation={this.props.navigation}
          isDarkMode={isDarkMode}
          showHamburgerIcon={isSmallScreen}
          handleToggleSidebar={this.props.toggleSidebar}
          handleToggleThemeSettings={this.props.handleToggleThemeSettings}
        />
        <WebSidebar
          id="WebSidebar"
          navigation={this.props.navigation}
          isSidebarOpen={keepSidebarOpen}
          isDarkMode={isDarkMode}
          sidebarWidth={keepSidebarOpen ? drawerWidth : drawerCloseWidth}
        />
        {isSmallScreen && (
          <Box
            left={keepSidebarOpen ? "242px" : "50px"}
            className={classes.expandSidebarIconBox}
            onClick={this.props.toggleSidebar}
          >
            {keepSidebarOpen ? (
              <ChevronLeftRoundedIcon />
            ) : (
              <ChevronRightRoundedIcon />
            )}
          </Box>
        )}
        <Box
          className={`${classes.mainChildWrapper} ${classes.secondaryBgColor}`}
          marginLeft={
            !isSmallScreen ? `${drawerWidth}px` : `${drawerCloseWidth}px`
          }
          width={
            !isSmallScreen
              ? `calc(100% - ${drawerWidth}px)`
              : `calc(100% - ${drawerCloseWidth}px)`
          }
        >
          {children}
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(WebAppLayout);
// Customizable Area End
