// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteSubjectConfirmModalController, {
  Props,
} from "./DeleteSubjectConfirmModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { deleteIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
    subjectDelete: {
        "& .MuiPaper-rounded": {
            borderRadius: "8px"
        }
    },
});

// Customizable Area End

// Customizable Area Start
export class DeleteSubjectConfirmModal extends DeleteSubjectConfirmModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, subjectName } = this.props;
    return (
        <Dialog
          className={`${classes.highlightBgColor} ${classes.subjectDelete}`}
          aria-labelledby="setup-subject-delete"
          open={open}
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box display={"flex"} justifyContent={"center"} id="setup-subject-delete-box">
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={deleteIcon} alt="setup-subject-delete" />
              </Box>
              <Box width={"6%"}>
                <IconButton
                  data-test-id="setup-subject-delete-close-icon"
                  style={{ float: "right" }}
                  aria-label="more"
                  aria-haspopup="true"
                  aria-controls="long-menu"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent
              style={{ padding: "0px" }}
              data-test-id="setup-subject-delete-dialog-content"
            >
              <Box textAlign={"center"} mt={"20px"} mb={"2px"}>
                <Typography
                data-test-id="subject-sub-text-1"
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                >
                  Sure want to delete subject?
                </Typography>
              </Box>
              <Box textAlign={"center"} mt={"10px"} mb={"2px"}>
                <Typography
                  data-test-id="subject-sub-text-1"
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                >
                  Are you sure want to delete subject
                </Typography>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.fontBold600}`}
                >
                  {`${subjectName} ?`}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                data-test-id="setup-subject-delete-confirm-btns"
              >
                <CustomButton
                  btnText="No"
                  buttonVariant={"outlined"}
                  btnStyle={subjectDeleteCancelBtn}
                  type={"button"}
                  buttonId={`cancel-delete-subject`}
                  handleButtonClick={this.props.onCancel}
                  isDisabled={false}
                />
                <CustomButton
                  btnStyle={subjectDeleteYesBtn}
                  buttonVariant={"contained"}
                  type={"button"}
                  btnText="Yes,Delete"
                  handleButtonClick={this.props.onConfirm}
                  isDisabled={false}
                  buttonId={`confirm-delete-subject`}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const subjectDeleteCancelBtn = {
  padding: "10px 10px",
  height: "50px",
  fontSize: "18px",
  width: "208px",
  fontWeight: 500,
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  float: "right",
} as const;

const subjectDeleteYesBtn = {
  padding: "10px 10px",
  width: "208px",
  height: "50px",
  textAlign: "center",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(DeleteSubjectConfirmModal);
// Customizable Area End
