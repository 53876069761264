// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { AssessmentTestContext } from "./context/AssessmentTestProvider";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openSubmitDialog: boolean;
    assessmentName: string;
    studentName: string;
    studentId: string;
    assessmentId: string;
    assessmentDetails: Array<any>;
    studentMarks: Array<any>;
    receivedScore: any;
    totalMaxScore: number;
    totalReceivedScore: number;
    totalPercentage: number;
    showSubmitButton: boolean;
    calculateBtnText: string;
    showCalculateButton: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class AssessmentDetailPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    static contextType = AssessmentTestContext;
    // Customizable Area Start
    apiGetProfileData: string = "";
    apiGetStudentAssessment: string = "";
    apiSubmitStudentScore: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            openSubmitDialog: false,
            assessmentName: "",
            studentName: "",
            studentId: "",
            assessmentId: "",
            assessmentDetails: [],
            studentMarks: [],
            receivedScore: {},
            totalMaxScore: 0,
            totalReceivedScore: 0,
            totalPercentage: 0,
            showSubmitButton: false,
            calculateBtnText: 'Calculate Score',
            showCalculateButton: true,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getParamsAndAssessments();
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
            if (apiRequestCallId != null) {
                switch (apiRequestCallId) {
                    case this.apiGetStudentAssessment:
                        {
                            this.handleGetStudentAssessmentResponse(
                                responseJson,
                                errorResponse
                            );
                        }
                        break;

                    case this.apiSubmitStudentScore:
                        {
                            this.handleSubmitStudentScoreResponse(
                                responseJson,
                                errorResponse
                            );
                        }
                        break;
                }
            }
        }
    }

    goBackToAssessments = () => {
        this.props.navigation?.navigate("StudentPerformanceAssessment", {});
        this.context.setTabValue(1);
    }
    handleOpenAttachmentFile = (url : string) => {
        window.open(url , "_blank")
    }
    handleOpenSubmitDialog = () => {
        this.setState({ openSubmitDialog: true });
    }

    handleCloseSubmitDialog = () => {
        this.setState({ openSubmitDialog: false });
    }

    getParamsAndAssessments = () => {
        const { navigation } = this.props;
        const assessmentName = navigation.getParam("assessmentName");
        const studentName = navigation.getParam("studentName");
        const studentId = navigation.getParam("studentId");
        const assessmentId = navigation.getParam("assessmentId");
        this.setState({
            assessmentName,
            studentName,
            studentId,
            assessmentId,
        }, () => { this.handleGetStudentAssessmentApi() });
    }

    handleGetStudentAssessmentApi = async () => {
        showCustomLoader();
        let apiConfig = configJSON.studentAssessmentEndpoint;

        const { studentId, assessmentId } = this.state;
        const requestUrl = apiConfig + `?activity_id=${assessmentId}&student_id=${studentId}`;

        const token = await getStorageData("token");
        const header = {
            token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetStudentAssessment = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            requestUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleGetStudentAssessmentResponse = (responseJson: any, errorResponse: any) => {
        if (responseJson.data) {
            const questionList = responseJson.data.map((item: any, index: number) => {
                const activity = item.attributes;
                return {
                    id: item.id,
                    questionNumber: index + 1,
                    question: activity.question,
                    answer: activity.student_answer ? activity.student_answer.answer : 'NA',
                    attachment: activity.attachments,
                    maxScore: activity?.marks,
                    answerId: activity.student_answer ? activity.student_answer.id : 0,
                }
            });
   
            let receivedScoreDefaultValue: any = {};
            let totalMaxScore = 0;
            let totalPercentage = 0;
            let showCalculateButton = true;
            questionList.forEach((item: any) => {
                totalMaxScore += item.maxScore;
                receivedScoreDefaultValue[item.answerId] = {
                    id: item.answerId,
                    remark: '',
                    received_score: 0,
                }
                if (!item.answerId) {
                    showCalculateButton = false;
                }
            });
            totalPercentage = (this.state.totalReceivedScore * 100) / totalMaxScore;
            this.setState({
                assessmentDetails: questionList,
                receivedScore: receivedScoreDefaultValue,
                totalMaxScore,
                totalPercentage,
                showCalculateButton,
            }, () => {
                console.log("receivedScore: ", this.state.receivedScore);
            });
        } else {
            displayApiErrorMsg(responseJson?.errors, this.props.navigation);
        }
        this.parseApiCatchErrorResponse(errorResponse);
        hideCustomLoader();
    };

    handleReceiveScoreChange = (event: any) => {
        const { name, value } = event.target;
        const { receivedScore } = this.state;
        let scoreToUpdate: any = receivedScore;
        if (value) {
            scoreToUpdate[name].received_score = value;
            this.setState((prev) => {
                return {
                    ...prev,
                    receivedScore: scoreToUpdate,
                };
            });
        }
    };

    handleRemarkChange = (content: any, answerId: string) => {
        const { receivedScore } = this.state;
        let remarkToUpdate = receivedScore;
        remarkToUpdate[answerId].remark = content;
        this.setState((prev) => {
            return {
                ...prev,
                receivedScore: remarkToUpdate,
            };
        });
    }

    handleScoreSubmit = () => {
        const { receivedScore } = this.state;
        let scores = []
        for (const key in receivedScore) {
            if (Object.prototype.hasOwnProperty.call(receivedScore, key)) {
                const element = receivedScore[key];
                scores.push(element)
            }
        }
        const payload = {
            student_answers: scores
        }
        this.handleCloseSubmitDialog();
        this.handleSubmitStudentScoreApi(payload);
    }

    handleCalculateScore = () => {
        const { receivedScore, totalMaxScore, assessmentDetails } = this.state;
        let gainedScore = 0;
        let percentage = 0;
        let isScoreValid = true;
        for (const key in receivedScore) {
            if (Object.prototype.hasOwnProperty.call(receivedScore, key)) {
                const element = receivedScore[key];
                gainedScore += parseInt(element.received_score);
                if (element.received_score > (totalMaxScore / assessmentDetails.length)) {
                    isScoreValid = false;
                }
            }
        }
        percentage = (gainedScore * 100) / totalMaxScore;

        if (isScoreValid) {
            this.setState({
                showSubmitButton: true,
                calculateBtnText: "Re-Calculate Score",
                totalPercentage: Math.ceil(percentage * 100) / 100,
                totalReceivedScore: gainedScore,
            });
        } else {
            toast.error("Please correct received score value.");
        }
    }

    handleSubmitStudentScoreApi = async (requestBody: any) => {
        showCustomLoader();
        const token = await getStorageData("token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSubmitStudentScore = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.studentAssessmentEndpoint}/${this.state.studentId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleSubmitStudentScoreResponse = (
        responseJson: any,
        errorResponse: any
    ) => {
        if (responseJson.data) {
            toast.success("Score submitted successfully!");
        } else {
            //Check Error Response
            displayApiErrorMsg(responseJson?.errors, this.props.navigation);
        }
        hideCustomLoader();
    };
    // Customizable Area End
}
// Customizable Area End