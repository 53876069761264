// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const AudioBottomTextTopIcon: any = (props: any) => {
    return (
        <SvgIcon style={{ width: "45px", height: "70px" }} {...props}>
            <rect x="-1.5" y="0" width="40" height="70" rx="3" strokeWidth="1" fill={props.fill} stroke={props.stroke} />
            <path d="M17.5034 11.2625C17.038 11.2625 16.6603 10.8862 16.6603 10.4225V9.58242H8.22918V10.4225C8.22918 10.8862 7.85147 11.2625 7.38608 11.2625C6.92068 11.2625 6.54297 10.8862 6.54297 10.4225V8.74238C6.54297 8.27868 6.92068 7.90234 7.38608 7.90234H17.5034C17.9688 7.90234 18.3465 8.27868 18.3465 8.74238V10.4225C18.3465 10.8862 17.9688 11.2625 17.5034 11.2625Z" fill="white" />
            <path d="M12.4447 19.6629C11.9793 19.6629 11.6016 19.2865 11.6016 18.8228V8.74238C11.6016 8.27868 11.9793 7.90234 12.4447 7.90234C12.9101 7.90234 13.2878 8.27868 13.2878 8.74238V18.8228C13.2878 19.2865 12.9101 19.6629 12.4447 19.6629Z" fill="white" />
            <path d="M14.1316 19.6625H10.7591C10.2937 19.6625 9.91602 19.2862 9.91602 18.8225C9.91602 18.3588 10.2937 17.9824 10.7591 17.9824H14.1316C14.5969 17.9824 14.9747 18.3588 14.9747 18.8225C14.9747 19.2862 14.5969 19.6625 14.1316 19.6625Z" fill="white" />
            <path d="M29.3067 9.58242H22.5619C22.0965 9.58242 21.7188 9.20608 21.7188 8.74238C21.7188 8.27868 22.0965 7.90234 22.5619 7.90234H29.3067C29.7721 7.90234 30.1498 8.27868 30.1498 8.74238C30.1498 9.20608 29.7721 9.58242 29.3067 9.58242Z" fill="white" />
            <path d="M29.3067 14.6215H22.5619C22.0965 14.6215 21.7188 14.2451 21.7188 13.7814C21.7188 13.3177 22.0965 12.9414 22.5619 12.9414H29.3067C29.7721 12.9414 30.1498 13.3177 30.1498 13.7814C30.1498 14.2451 29.7721 14.6215 29.3067 14.6215Z" fill="white" />
            <path d="M29.3067 19.6625H22.5619C22.0965 19.6625 21.7188 19.2862 21.7188 18.8225C21.7188 18.3588 22.0965 17.9824 22.5619 17.9824H29.3067C29.7721 17.9824 30.1498 18.3588 30.1498 18.8225C30.1498 19.2862 29.7721 19.6625 29.3067 19.6625Z" fill="white" />
            <path d="M29.3069 24.7035H7.38608C6.92068 24.7035 6.54297 24.3272 6.54297 23.8635C6.54297 23.3998 6.92068 23.0234 7.38608 23.0234H29.3069C29.7723 23.0234 30.15 23.3998 30.15 23.8635C30.15 24.3272 29.7723 24.7035 29.3069 24.7035Z" fill="white" />
            <path d="M29.3069 29.7426H7.38608C6.92068 29.7426 6.54297 29.3662 6.54297 28.9025C6.54297 28.4388 6.92068 28.0625 7.38608 28.0625H29.3069C29.7723 28.0625 30.15 28.4388 30.15 28.9025C30.15 29.3662 29.7723 29.7426 29.3069 29.7426Z" fill="white" />
            <path d="M5.81123 43.1445C5.31143 43.1445 4.90625 43.5497 4.90625 44.0495V53.0994C4.90625 53.5992 5.31143 54.0043 5.81123 54.0043C6.31104 54.0043 6.71622 53.5992 6.71622 53.0994V44.0495C6.71622 43.5497 6.31104 43.1445 5.81123 43.1445Z" fill="white" />
            <path d="M9.43038 38.6191C8.93057 38.6191 8.52539 39.0243 8.52539 39.5241V57.6238C8.52539 58.1236 8.93057 58.5288 9.43038 58.5288C9.93018 58.5288 10.3354 58.1236 10.3354 57.6238V39.5241C10.3354 39.0243 9.93018 38.6191 9.43038 38.6191Z" fill="white" />
            <path d="M20.2917 43.1445C19.7919 43.1445 19.3867 43.5497 19.3867 44.0495V53.0994C19.3867 53.5992 19.7919 54.0043 20.2917 54.0043C20.7915 54.0043 21.1967 53.5992 21.1967 53.0994V44.0495C21.1967 43.5497 20.7915 43.1445 20.2917 43.1445Z" fill="white" />
            <path d="M16.6706 38.6191C16.1708 38.6191 15.7656 39.0243 15.7656 39.5241V57.6238C15.7656 58.1236 16.1708 58.5288 16.6706 58.5288C17.1704 58.5288 17.5756 58.1236 17.5756 57.6238V39.5241C17.5756 39.0243 17.1704 38.6191 16.6706 38.6191Z" fill="white" />
            <path d="M13.0515 35C12.5517 35 12.1465 35.4052 12.1465 35.905V61.2445C12.1465 61.7444 12.5517 62.1495 13.0515 62.1495C13.5513 62.1495 13.9565 61.7444 13.9565 61.2445V35.905C13.9565 35.4052 13.5513 35 13.0515 35Z" fill="white" />
            <path d="M31.1511 43.1445C30.6513 43.1445 30.2461 43.5497 30.2461 44.0495V53.0994C30.2461 53.5992 30.6513 54.0043 31.1511 54.0043C31.6509 54.0043 32.0561 53.5992 32.0561 53.0994V44.0495C32.0561 43.5497 31.6509 43.1445 31.1511 43.1445Z" fill="white" />
            <path d="M27.53 38.6191C27.0302 38.6191 26.625 39.0243 26.625 39.5241V57.6238C26.625 58.1236 27.0302 58.5288 27.53 58.5288C28.0298 58.5288 28.435 58.1236 28.435 57.6238V39.5241C28.435 39.0243 28.0298 38.6191 27.53 38.6191Z" fill="white" />
            <path d="M23.9108 35C23.411 35 23.0059 35.4052 23.0059 35.905V61.2445C23.0059 61.7444 23.411 62.1495 23.9108 62.1495C24.4107 62.1495 24.8158 61.7444 24.8158 61.2445V35.905C24.8158 35.4052 24.4107 35 23.9108 35Z" fill="white" />
        </SvgIcon>
    );
}

export default AudioBottomTextTopIcon;
// Customizable Area End