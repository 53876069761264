// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TenantAdminSetupSubjectsController, {
  Props,
} from "./TenantAdminSetupSubjectsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import NewSubjectDialog from "./NewSubjectDialog.web";
import DeleteSubjectConfirmModalWeb from "./DeleteSubjectConfirmModal.web";
// Customizable Area End

// Customizable Area Start
const tenantSubjectColumn = [
  {
    id: "subject",
    columnId: 1,
    label: "Subject Name",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
  },
  {
    id: "color",
    columnId: 2,
    label: "Color",
    type: tableColumnTypes.COLOR_CIRCLE,
  },
  {
    id: "icon",
    columnId: 1,
    label: "Icon",
    type: tableColumnTypes.ICON,
  },
];

const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 3),
      margin: theme.spacing(4, 0),
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
      marginBottom: "8px",
    },
    filterBox: {
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

const subjectsTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "4px !important",
        paddingBottom: "4px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px !important",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupSubjects extends TenantAdminSetupSubjectsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openNewSubjectDialog } = this.props;
    const {
      gradesMenuList,
      selectFilterGrade,
      subjectsList,
      subjectsTableActionButtonConfig,
      openDeleteSubjectPopup,
      openEditNewSubjectPopup,
      selectedSubjectData,
      SubjectPermission
    } = this.state;

    return (
      <Box
        style={{ width: "100%", paddingTop: "1px" }}
        data-test-id="setup-subject"
        className={`${classes.bgColor}`}
      >
        <Grid container className={`${classes.bgColor} ${classes.searchBox}`}>
          <Grid item xs={12} sm={6} md={3}>
            <Box className={`${classes.filterBox}`}>
              <Typography className={classes.inputLabel}>Grade</Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-subject-grade"
                fieldValue={selectFilterGrade}
                fieldName="selectFilterGrade"
                handleChange={this.handleChangeGradeValue}
                renderValue={this.renderGradeValue}
                fieldMenuItems={gradesMenuList}
                fieldStyles={{ width: "95%" }}
                placeHolderText="Select Grade"
              />
            </Box>
          </Grid>
          <CustomButtonWeb
            buttonVariant="contained"
            btnStyle={searchBtn}
            buttonId="subject-grades-filter-search"
            btnText="Search"
            isDisabled={false}
            handleButtonClick={() => this.handleGetSubjectsList()}
            type={"button"}
          />
        </Grid>
        <Box
          style={{ backgroundColor: "#F1F1F1", height: "4px", width: "100%" }}
        />
        <Box
          className={`${classes.bgColor} ${classes.customTableStyles} ${classes.classManagementTable}`}
          p={"0px 20px"}
        >
          <ThemeProvider theme={subjectsTableTheme}>
            <CustomTable
              tableId={"tenant-subject-table"}
              tableHeaderColumns={tenantSubjectColumn}
              tableBodyData={subjectsList}
              showMenuIcon={SubjectPermission?.subject?.write}
              actionButtonConfig={subjectsTableActionButtonConfig}
            />
          </ThemeProvider>
        </Box>
        {(openEditNewSubjectPopup || openNewSubjectDialog) && (
          <NewSubjectDialog
            open={openEditNewSubjectPopup || openNewSubjectDialog}
            handleClose={() => this.handleCloseEditSubjectPopup()}
            handleAddSubject={(data: any) => this.handleAddNewSubject(data)}
            handleEditSubject={(data: any) => this.handleUpdateSubject(data)}
            selectedSubjectData={selectedSubjectData}
            isEdit={openEditNewSubjectPopup}
            gradesList={gradesMenuList || []}
          />
        )}
        <DeleteSubjectConfirmModalWeb
          open={openDeleteSubjectPopup}
          subjectName={selectedSubjectData?.subject?.name || ""}
          onCancel={() => this.handleCloseDeleteSubjectConfirmModal()}
          onConfirm={() => this.handleDeleteSubject()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtn = {
  width: "130px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  textAlign: "center",
  fontWeight: 600,
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupSubjects);
// Customizable Area End
