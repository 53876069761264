// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentCompletedAssignmentController, {
  Props
} from "./StudentCompletedAssignmentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    leftMargin: {
      marginLeft: "1%"
    },
    assignmentDetailsGridMarginBottom: {
      marginBottom:"2%"
    },
    assignmentBox: {
      position: "relative",
    },
  })

// Customizable Area End

// Customizable Area Start
export class StudentCompletedAssignment extends StudentCompletedAssignmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, question, index } = this.props;
    return (
      <Box className={classes.assignmentBox} key={question?.id} display={"flex"} flexDirection={"column"}>
        <Box>
          <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}>{`Question ${index + 1}`}</Typography>
          <Typography className={`${classes.textPrimaryColor} ${classes.fontText20}`}  dangerouslySetInnerHTML={{ __html: question?.question }}></Typography>
        </Box>
        <Box marginTop={"5px"}>
          <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}>{`Answer ${index + 1}`}</Typography>
          <Typography className={`${classes.textPrimaryColor} ${classes.fontText20}` }  dangerouslySetInnerHTML={{ __html: question?.textAnswer }}></Typography>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCompletedAssignment);
// Customizable Area End
