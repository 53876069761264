// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@material-ui/core";
import TeacherAssignCourseModalController, {
  Props,
} from "./TeacherAssignCourseModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  sendApprovalModal: {
    "& .MuiPaper-rounded": {
      borderRadius: "8px",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
      width: "540px",
    }
  },
});
// Customizable Area End

// Customizable Area Start
export class TeacherSendApprovalModal extends TeacherAssignCourseModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <Box>
        <Dialog
          data-test-id="sendApprovalModal"
          className={`${classes.highlightBgColor} ${classes.sendApprovalModal}`}
          open={open}
          aria-labelledby="send-approval-modal-confirm-dialog-title"
        >
          <Box padding={"30px"} className={`${classes.bgColor}`} data-test-id="sendApprovalModalBox">
            <Box display={"flex"} justifyContent={"center"} id="sendApprovalModal-copy">
              <Box display={"flex"} justifyContent={"center"} width={"94%"} data-test-id="ImgSrc">
                <img src={exclamationIcon} alt="success icon" />
              </Box>
              <Box width={"6%"} data-test-id="IconBtns">
                <IconButton
                  data-test-id="IconStyleBtn"
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }} data-test-id="sendApprovalContent">
              <Box textAlign={"center"} mt={"20px"} mb={"10px"} data-test-id="sendApprovalContentBox">
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText22} ${classes.fontBold600}`}
                  data-test-id="requestText"
                >
                  Sure want to send Approval Request?
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"} data-test-id="SpanText">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  data-test-id="areyousuretxt"
                >
                  Are you sure want to send approval request for
                </Typography>
                <Typography
                  data-test-id="Classtxt"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  Physics {" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="nameWrong"
                >
                  {" "}
                  course of{" "}
                </Typography>
                <Typography
                  data-test-id="sceinceCOurse"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}

                >
                  Science{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="SubjectForTxt"
                >
                  {" "} subject for
                </Typography>
                <Typography
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="Grade&Txt"
                >
                  {" "} Grade 7?
                </Typography>
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
                data-test-id="ConfirmBtns"
                style={{ gap: '10px' }}
              >
                <CustomButton
                  btnStyle={sendApprovalReqBtns}
                  btnText="cancel"
                  buttonId="copy-course-modal-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
                <CustomButton
                  btnStyle={sendApprovalReqBtns}
                  btnText="yes"
                  buttonId="copy-course-modal-cancel-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const sendApprovalReqBtns = {
  padding: "5px",
  width: "50%",
  height: "50px",
  borderRadius: "10px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherSendApprovalModal);
// Customizable Area End
