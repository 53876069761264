// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteTeacherAssignmentConfirmPopupController, {
  Props,
} from "./DeleteTeacherAssignmentConfirmPopupController.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import { exclamationIcon } from "../assets";
import CloseIcon from "../../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    deleteConfirmDialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        width: "540px",
        maxWidth: "100%",
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class DeleteTeacherAssignmentConfirmPopup extends DeleteTeacherAssignmentConfirmPopupController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, headingTitle } = this.props;
    return (
      <Box>
        <Dialog
          open={open}
          className={`${classes.highlightBgColor} ${classes.deleteConfirmDialog}`}
          aria-labelledby="delete-confirm-teacher-assignment-dialog"
        >
          <Box className={`${classes.bgColor}`} padding={"30px"}>
            <Box
              id="deleteConfirmTeacherAssignmentDialog"
              display={"flex"}
              justifyContent={"center"}
            >
              <Box display={"flex"} width={"94%"} justifyContent={"center"}>
                <img src={exclamationIcon} alt="exclamation icon" />
              </Box>
              <Box width={"6%"}>
                <IconButton
                  style={{ float: "right" }}
                  aria-label="teacher-assignment-delete-close-icon"
                  onClick={this.props.onCancel}
                  aria-haspopup="true"
                  aria-controls="teacher-assignment-delete-close-icon"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent
              data-test-id="teacher-assignment-delete-confirm-dialog-content"
              style={{ padding: "0px" }}
            >
              <Box
                data-test-id="teacher-assignment-delete-confirm-dialog-content-box"
                textAlign={"center"}
                marginTop={"20px"}
                marginBottom={"10px"}
              >
                <Typography
                  className={` ${classes.fontText22} ${classes.textPrimaryColor} ${classes.fontBold600}`}
                >
                  {headingTitle} will be Deleted
                </Typography>
              </Box>
              <Box
                marginBottom={"30px"}
                marginTop={"10px"}
                textAlign={"center"}
              >
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontBold500} ${classes.fontText18}`}
                >
                  Are you sure want to delete <br />
                  <b>this teacher assignment</b> ?
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ gap: "10px" }}
              >
                <CustomButton
                  btnStyle={buttonStyle}
                  btnText="Cancel"
                  buttonId="teacher-assignment-delete-dialog-cancel-btn"
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                  isDisabled={false}
                  buttonVariant={"outlined"}
                />
                <CustomButton
                  btnStyle={buttonStyle}
                  btnText="Yes"
                  buttonId="teacher-assignment-delete-dialog-yes-btn"
                  type={"button"}
                  buttonVariant={"contained"}
                  handleButtonClick={this.props.onConfirm}
                  isDisabled={false}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const buttonStyle = {
  width: "50%",
  height: "50px",
  padding: "5px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(DeleteTeacherAssignmentConfirmPopup);
// Customizable Area End
