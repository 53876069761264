// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CalendarIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.9554 24H4.20869C2.45313 24 1.02645 22.5733 1.02645 20.8178V4.95998C1.02645 3.20442 2.45313 1.77774 4.20869 1.77774H5.47089V0.888872C5.47089 0.399978 5.87087 0 6.35976 0C6.84866 0 7.24871 0.399978 7.24871 0.888872V1.77774H17.9153V0.888872C17.9153 0.399978 18.3153 0 18.8042 0C19.2931 0 19.6931 0.399978 19.6931 0.888872V1.77774H20.9554C22.7109 1.77774 24.1376 3.20442 24.1376 4.95998V20.8178C24.1376 22.5733 22.7109 24 20.9554 24ZM4.20869 3.55556C3.43538 3.55556 2.80427 4.18667 2.80427 4.95998V20.8178C2.80427 21.5911 3.43538 22.2222 4.20869 22.2222H20.9553C21.7286 22.2222 22.3597 21.5911 22.3597 20.8178V4.95998C22.3597 4.18667 21.7286 3.55556 20.9553 3.55556H19.6931V4.44444C19.6931 4.93333 19.2931 5.33331 18.8042 5.33331C18.3153 5.33331 17.9153 4.93333 17.9153 4.44444V3.55556H7.24864V4.44444C7.24864 4.93333 6.84866 5.33331 6.35976 5.33331C5.87087 5.33331 5.47089 4.93333 5.47089 4.44444V3.55556H4.20869Z" fill="#B8B8B8" />
                <circle cx="7.47093" cy="9.11109" r="1.11109" fill="#B8B8B8" />
                <circle cx="12.3598" cy="9.11109" r="1.11109" fill="#B8B8B8" />
                <circle cx="17.2487" cy="9.11109" r="1.11109" fill="#B8B8B8" />
                <circle cx="7.47093" cy="13.5554" r="1.11109" fill="#B8B8B8" />
                <circle cx="12.3598" cy="13.5554" r="1.11109" fill="#B8B8B8" />
                <circle cx="17.2487" cy="13.5554" r="1.11109" fill="#B8B8B8" />
                <circle cx="7.47093" cy="18" r="1.11109" fill="#B8B8B8" />
                <circle cx="12.3598" cy="18" r="1.11109" fill="#B8B8B8" />
                <circle cx="17.2487" cy="18" r="1.11109" fill="#B8B8B8" />
            </svg>
        </SvgIcon>
    );
}

export default CalendarIcon;
// Customizable Area End




