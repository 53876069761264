// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, hideCustomLoader, showCustomLoader} from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const myPathCategoryTabsList = [
  {
    id: 1,
    label: "Students / Parents",
    value: "student_parent",
    isDisabled:false,
    keyWrite:"userManagementStudentParent",
  },
  {
    id: 2,
    label: "Teachers / Parent Teachers",
    value: "teacher",
    isDisabled:false,
    isWrite:true,
    keyWrite:"userManagementTeacherParentTeacher",
  },
  {
    id: 3,
    label: "Others",
    value: "other",
    isDisabled:false,
    isWrite:true,
    keyWrite:"userManagementOthers",
  },
];
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedUserManagementTab: number;
  openImportCSVPopup: boolean;
  openUserRoleSelectPopup: boolean;
  uploadedCSVFile: any;
  selectedAddNewUserRole: string;
  roleOptions:any[]
  isDarkMode: boolean;
  myPathCategoryTabsListState:any[],
  userManagementPermissions:any,
  triggerAPIs:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantUserManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetRoleListCallId:string=""
  userImportCSVCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectedUserManagementTab: 0,
      openImportCSVPopup: false,
      openUserRoleSelectPopup: false,
      uploadedCSVFile: null,
      selectedAddNewUserRole: "",
      isDarkMode: false,
      roleOptions:[],
      myPathCategoryTabsListState:myPathCategoryTabsList,
      userManagementPermissions:{
        userManagementOthers:{
          read: true,
          write: true,
        },
        userManagementTeacherParentTeacher:{
          read: true,
          write: true,
        },
        userManagementStudentParent:{
          read: true,
          write: true,
        }
      },
      triggerAPIs:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetRoleListApi()
    this.handleGetRoleAndPermissionForUserManagement()
    // Customizable Area End
  }

  // Customizable Area Start
  extractPermissions(parseData: any) {
   return  {
      userManagementOthers: parseData?.user_management_others?.can,
      userManagementTeacherParentTeacher: parseData?.user_management_teacher_parent_teacher?.can,
      userManagementStudentParent:parseData?.user_management_student_parent?.can
    };
  }
  checkTabPermission(tab: any, result: any) {
    if (tab.value === "other" && !result.userManagementOthers?.read) { 
      return { ...tab, isDisabled: true};
    }
    if (tab.value === "student_parent" && !result.userManagementStudentParent?.read) {
      return { ...tab, isDisabled: true };
    }
    if (tab.value === "teacher" &&
      !result.userManagementTeacherParentTeacher?.read
    ) {
      return { ...tab, isDisabled: true };
    }
    return tab;
  }
  filterTabsByPermissions(result: any) {
    return this.state.myPathCategoryTabsListState.map((tab) => this.checkTabPermission(tab, result))
    .filter((tabItem: any) => !tabItem.isDisabled);
  }
  async handleGetRoleAndPermissionForUserManagement(){
    const getUserManagement = await getStorageData("roleandpermission")
    if(getUserManagement){  
      try {
        const parseData = JSON.parse(getUserManagement);
        if(parseData){
        const result = this.extractPermissions(parseData);
        const updatedUserManagementTabsList:any = this.filterTabsByPermissions(result);
        this.setState({
          myPathCategoryTabsListState:updatedUserManagementTabsList,
          userManagementPermissions:result
        })
      }
      } catch (error) {
        console.error("Failed to parse userMangement permission. It might not be valid JSON.", error);
      }
    }
  }
  handleChangeMyPathCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedUserManagementTab: newValue });
  };

  handleOpenAddUserRoleSelectPopup = () => {
    this.setState({ openUserRoleSelectPopup: true });
  };

  handleCloseAddUserRoleSelectPopup = () => {
    this.setState({ openUserRoleSelectPopup: false });
  };

  handleDarkMode = () => {
    this.setState({isDarkMode: false})
  }

  handleLightMode = () => {
    this.setState({isDarkMode: true})
  }

  handleREnderDarkMode = (condition:any, truePart:any, falsePart:any) => {
    return condition ? truePart : falsePart
  }

  handleAddUserFlow = (role: string,roleId:any) => {
    this.setState(
      {
        openUserRoleSelectPopup: false,
        selectedAddNewUserRole: role.toLocaleLowerCase(),
      },
      () => {
        if (role.toLocaleLowerCase() === "student") {
          this.props.navigation?.navigate("AddNewStudentDetails",{},   {
            roleId: roleId,
          });
        } else {
          this.props.navigation?.navigate(
            "TenantUserManagementAddNewUserDetails",
            {},
            {
              role: role,
              roleId: roleId,
            }
          );
        }
      }
    );
  };

  handleFileUpload = (file: any) => {
    this.setState({ uploadedCSVFile: file });
    console.log(file);
  };

  handleOpenImportCsvDialog = () => {
    this.setState({ openImportCSVPopup: true });
  };

  handleCloseImportCsvDialog = () => {
    this.setState({ openImportCSVPopup: false });
  };
  handleGetRoleListApi =async () => {
    showCustomLoader()
    let token =  await getStorageData("token")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };

    this.apiGetRoleListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherParentNmaesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetRoleListResponse =(responseJson:any)=>{
 if(responseJson.data){
  const roleOptions= responseJson?.data?.filter(
    (item: { attributes: { name: string } }) => 
      item.attributes.name !== "School Admin" && 
      item.attributes.name !== "Admin General" &&
      item.attributes.name !== "District Admin"
  ).map(
    (item: { attributes: { name: any }; id: any }) => {
      const name = item.attributes.name;

      return {
        label: name,
        id: item.id,
        value: item.id,
      };
    }
  );
  this.setState({roleOptions:roleOptions})
 }else{
  displayApiErrorMsg(responseJson?.errors, this.props.navigation);
 }

  }

  handleUserImportCSVApi =async (requestBody:any) =>{
    const requestUrl = configJSON.userImportCSVEndpoint
    const token = await getStorageData("token");
      const header = {
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userImportCSVCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        requestBody
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.userCreateAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  resetTrigger =()=>{
    this.setState({triggerAPIs:false})
  }
  handleUserImportCSVResponse = async(responseJson: {
    message:any;
    errors: {message:Array<string>;}})=>{
      if (responseJson?.message) {
        this.handleCloseImportCsvDialog();
        this.setState({triggerAPIs:true})
        toast.success(`${responseJson?.message}`);
      } 
        else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader() 
  }
  downloadUserCSVFile =async () => {
    const token = await getStorageData("token");
    let requestType =configJSON.userDownloadCSVEndpoint
    let requestUrl = `${requestType}?token=${token}`
    window.open(`${baseURL}/${requestUrl}`, '_blank');
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetRoleListCallId) {
            this.handleGetRoleListResponse(responseJson); 
        }else if(apiRequestCallId === this.userImportCSVCallId){
          this.handleUserImportCSVResponse(responseJson);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
