// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  categoryOfQuiz: string;
  titleOfAssessment: string;
  typeOfAssessment?: string;
  quizStatistics?:any
  assessmentId?:string;
  evaluationType?:string;
  objectiveType?:string

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quizStatistics: any;
  openQuizViewAnswerPopup: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentGradedAssignmentStatisticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostDisabledReAttempt:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      quizStatistics: {
        remark: "Needs improvement.You can do better.",
        statistics: {
          correct: 82,
          incorrect: 5,
          missed: 15,
        },
        timeSpentInMins: 182,
        noOfAttemptRemaining: 4,
      },
      openQuizViewAnswerPopup: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoToViewCorrectAnswer = () => {
    const { categoryOfQuiz, navigation, typeOfAssessment } = this.props;
    if (this.props.evaluationType==="objective" && this.props.objectiveType==="selection" && typeOfAssessment !== "project") {
      navigation?.navigate(
        "StudentCompletedQuizMain",
        {},
        {
          category: categoryOfQuiz,
          title: "Correct Answers",
          activityId:this.props.assessmentId
        }
      );
    } else {
      navigation?.navigate(
        "StudentRemarkedAssessmentMain",
        {},
        {
          typeOfAssessment: typeOfAssessment,
          title: "Correct Answers",
          assessmentCategory: "graded",
          fromStatistics: "statistics",
          activityId:this.props.assessmentId,
          evaluationType:this.props.evaluationType,
          objectiveType: this.props.objectiveType || this.props.evaluationType,

        }
      );
    }
  };

  handleReAttempt = () => {
    const { titleOfAssessment, typeOfAssessment,evaluationType ,assessmentId,objectiveType} = this.props;
    this.props.navigation?.navigate(
      "StudentDueAssessmentMain",
      {},
      {
        typeOfAssessment: typeOfAssessment,
        title: titleOfAssessment,
        activityId:assessmentId,
        evaluationType,
        objectiveType: objectiveType || evaluationType,
      }
    );
  };

  handleOpenQuizViewAnswerPopup = () => {
    console.log("hello world addtempt",this.props.quizStatistics)
    if(this.props?.quizStatistics?.isReattemptAllow){
      this.setState({ openQuizViewAnswerPopup: true });
    }else{
      this.handleGoToViewCorrectAnswer();
    }
    // 

    // isReattemptAllow
  };

  handleCloseQuizViewAnswerPopup = () => {
    this.setState({ openQuizViewAnswerPopup: false });
  };

  handleConfirmViewAnswer = () => {
    this.setState({ openQuizViewAnswerPopup: false }, () => {

      console.log("hello world",this.props.assessmentId)
      const payload={
        activity_id: this.props.assessmentId
       }
      this.handleDisabledReAttemptApi(payload)
      this.handleGoToViewCorrectAnswer();
    });
  };

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDisabledReAttemptApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostDisabledReAttempt = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.disabledReAttemptEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDisableReAttemptResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      console.log("assessment resp: ", responseJson?.data);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apiPostDisabledReAttempt) {
            this.handleDisableReAttemptResponse(
              responseJson,
              errorResponse
            );
        }
      }
  }
      console.log("quiz completed apiRequestCallId");

      // Customizable Area Start

      // Customizable Area End
  }
  // Customizable Area End
}
// Customizable Area End
