// Customizable Area Start
import React from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentCourseActivityJourneyBoxController, {
  Props,
} from "./StudentCourseActivityJourneyBoxController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import MyCoursesIcon from "../../../components/src/SVGIcons/MyCoursesIcon.web";
import QuestionMarkIcon from "../../../components/src/SVGIcons/QuestionMarkIcon.web";
import AssignmentTaskIcon from "../../../components/src/SVGIcons/AssignmentTaskIcon.web";
import ProjectTaskIcon from "../../../components/src/SVGIcons/ProjectTaskIcon.web";
import ArticleTaskIcon from "../../../components/src/SVGIcons/ArticleTaskIcon.web";
import AudioTaskIcon from "../../../components/src/SVGIcons/AudioTaskIcon.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { CheckMark, Shape } from "../src/assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExamTaskIcon from "../../../components/src/SVGIcons/ExamTaskIcon.web";
import { ChangeDueDateActivityModal } from "./ChangeDueDateActivityModal.web";
import { MarkAsCompleteSuccessDialog } from "./MarkAsCompleteSuccessDialog.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    activityColumnDetailRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "55px",
      cursor: "pointer",
      position: "relative",
      borderBottom: "1px solid #f1f1f1",
      boxSizing: "border-box",
      width: "100%",
    },
    activityColumnDetailColumn: {
      display: "flex",
      alignItems: "center",
      height: "55px",
      cursor: "pointer",
      position: "relative",
      borderBottom: "1px solid #f1f1f1",
      boxSizing: "border-box",
      width: "100%",
      // paddingRight: "10px",
    },
    selectedTopicBox: {
      borderRight: "2px solid #0D46BC",
    },
    menuIconButton: {
      "&.MuiIconButton-root": {
        paddingLeft: "10px",
      },
    },
    title: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    dragActivityIcon: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "40px",
      padding: "0px",
      position: "absolute",
      top: "0",
      left: "0",
      backgroundColor: "rgba(0, 157, 255, 0.05)",
    },
    moreIcon: {
      padding: "6px",
    },
    activityTitle: {
      width: "100%",
    },
  });
// Customizable Area End

// Customizable Area Start
export class StudentCourseActivityJourneyBox extends StudentCourseActivityJourneyBoxController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  getSvgIconColor = (selected: boolean) => {
    const isDarkTheme =
      localStorage.getItem("dark_mode") || JSON.stringify(false);
    const darkThemeIconColor = JSON.parse(isDarkTheme) ? "#D1D1D1" : "#090909";
    return selected ? darkThemeIconColor : "#888888";
  };

  activityIcon = (type: string, gradeComponent: string, selected: boolean) => {
    let caseCheck = type;
    if(type === "assessment") {
      caseCheck = gradeComponent;
    }
    switch (caseCheck) {
      case "video":
        return (
          <MyCoursesIcon
            width="28"
            height="18"
            viewBox="0 0 28 18"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="0.055"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "text":
      case "exam":
        return (
          <ExamTaskIcon
            width="20"
            height="31"
            viewBox="0 0 20 31"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="1.0"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "quiz":
        return (
          <QuestionMarkIcon
            width="18"
            height="27"
            viewBox="0 0 18 27"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="0.055"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "assignment":
        return (
          <AssignmentTaskIcon
            width="20"
            height="31"
            viewBox="0 0 20 31"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="1.0"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "project":
        return (
          <ProjectTaskIcon
            width="26"
            height="25"
            viewBox="0 0 26 25"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="1.0"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "article":
        return (
          <ArticleTaskIcon
            width="21"
            height="28"
            viewBox="0 0 21 28"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="1.0"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "task":
        return (
          <ArticleTaskIcon
            width="22"
            height="27"
            viewBox="0 0 22 27"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="1.1"
            fill={this.getSvgIconColor(selected)}
          />
        );
      case "audio":
        return (
          <AudioTaskIcon
            width="28"
            height="22"
            viewBox="0 0 28 22"
            stroke={this.getSvgIconColor(selected)}
            strokeWidth="1.0"
            fill={this.getSvgIconColor(selected)}
          />
        );
      default:
        return;
    }
  };

  progressComponent = (detail: any) => {
    if (detail?.completionProgress === 100) {
      return <img src={CheckMark} />;
    } else {
      return "";
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    const {
      classes,
      key,
      detail,
      selectedItemId,
      typesOfActivity,
      gradeComponent,
      isFromTenant,
      isStudent,
    } = this.props;
    return (
      <>
      <MarkAsCompleteSuccessDialog
        onCancel={this.handleMarkClose}
        onConfirm={this.handleMarkAsComplete}
        open={this.state.markCompleteModalOpen}
        detail={this.props.detail}
        classes={this.props.classes}
        courseData={this.props.courseData}
        markAsCompleteSuccessModal={this.state.markAsCompleteSuccessModal}
        type={"activity"}
      />
      <ChangeDueDateActivityModal
          open={this.state.dueDateModalOpen}
          classes={classes}
          onCancel={this.handleCancelClick}
          onConfirm={this.handleConfirmClick}
          getDate={this.setDueDate}
          selectedTopic={this.props.selectedTopic}
          selectedLesson={this.props.selectedLesson}
          selectedActivity={this.props.selectedActivity}
          details={this.props.detail}
          courseData={this.props.courseData}
          dueDate={this.state.dueDate}
          selectType={"activity"}
        />
      <Box display={"flex"} className={`${classes.activityColumnDetailColumn}`}>
        <Box
          data-testid={`my-course-activity-box-${detail?.id}`}
          onClick={() => this.props.handleActivitySelectItem(detail?.id)}
          key={key}
          className={`${classes.activityColumnDetailRow}`}
          paddingLeft={isStudent? "20px" : "50px"}
          paddingRight={isStudent? "10px" : "0px"}
          borderLeft={isStudent && (selectedItemId === detail?.id) ? "2px solid #0D46BC" : ""}
        >
          {!isStudent && (
            <Box
              className={
                selectedItemId === detail?.id
                  ? `${classes.dragActivityIcon} ${classes.selectedTopicBox}`
                  : `${classes.dragActivityIcon}`
              }
            >
              <img src={Shape} alt="" />
            </Box>
          )}
          <Box
            className={classes.title}
            display={"flex"}
            alignContent="center"
            width={"100%"}
          >
            <Box mr={"5px"}>
              {this.activityIcon(
                typesOfActivity, gradeComponent,
                selectedItemId === detail?.id
              )}
            </Box>
            <Typography
              className={
                selectedItemId === detail?.id
                  ? `${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.title} ${classes.activityTitle}`
                  : `${classes.textCommonColor} ${classes.title} ${classes.fontText16} ${classes.fontBold500} ${classes.activityTitle}`
              }
              ref={this.activityTitleRef}
              component={"div"}
              data-testid={`activity-title-${detail?.id}`}
            >
              {detail?.title}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {this.progressComponent(detail)}
          </Box>
        </Box>
        <Box>
          {(!isStudent && this.props.isActivityWrite) && <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e: any) => this.handleActionClick(e)}
            className={`${classes.moreIcon}`}
          >
            <MoreVertIcon className={`${classes.textCommonColor}`} />
          </IconButton>}
          <Menu
            anchorEl={this.state.menuAnchorEl}
            open={Boolean(this.state.menuAnchorEl)}
            onClose={this.handleMenuClose}
          >
            {!isFromTenant && (
              <Box>
                <MenuItem data-test-id="due_date" onClick={() => {
                  this.handleMenuClose();
                  this.handleOptionClick("Change Due Date", detail.id);
                }}>
                  Change Due Date
                </MenuItem>
                <MenuItem data-test-id="mark_as_complete" onClick={() => {
                  this.handleMenuClose();
                  this.handleOptionClick("Mark As Complete", detail.id);
                }}>
                  Mark As Complete
                </MenuItem>
              </Box>
            )}
            {isFromTenant && (
              <Box>
                <MenuItem onClick={this.handleEditMenuOption}>Edit</MenuItem>
                <MenuItem onClick={this.handleDeleteMenuOption}>
                  Delete
                </MenuItem>
              </Box>
            )}
          </Menu>
        </Box>
      </Box>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCourseActivityJourneyBox);
// Customizable Area End
