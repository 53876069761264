// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { mockdata } from "./assets";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}

export interface ITableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  bgColor?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedSubjectId: any;
  schoolSubjectsList: any;
  selectedSubjectCoursesGradesDetails: any;
  subjectCoursesDetailsTableColumns: Array<ITableProps>;
  subjectTableColumns: Array<ITableProps>;
  highlightBarHeight: string;
  highlightCheck: boolean;
  selectedHighlightBar: Array<number>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class GradebookGradesTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedSubjectId: 1,
      schoolSubjectsList: mockdata.schoolSubjectsList,
      subjectTableColumns: mockdata.subjectTableColumns,
      subjectCoursesDetailsTableColumns: mockdata.subjectCoursesDetailsTableColumns,
      selectedSubjectCoursesGradesDetails: [],
      highlightBarHeight: "68vh",
      highlightCheck: false,
      selectedHighlightBar: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetSelectedSchoolSubject(1);
    // Customizable Area End
  }

  // Customizable Area Start
  handleSetSelectedSchoolSubject = (subId: any) => {
    const { schoolSubjectsList, selectedSubjectId } = this.state;

    if (subId === selectedSubjectId) {
      this.setState({ selectedSubjectId: "" });
      this.setState({ highlightBarHeight: "68vh" });
    }
    else {
      const selectedSubjectCoursesGradesDetails: Array<any> = [];
      const selectedSubjectObj = schoolSubjectsList.find(
        (subject: any) => subject?.subjectId === subId
      );

      if (selectedSubjectObj?.coursesDetails) {
        const courseData = selectedSubjectObj.coursesDetails;
        Object.keys(courseData).forEach((courseItemKey: any) => {
          const detailItemObj = {
            title: courseItemKey,
            firstQuarterScore: courseData[courseItemKey].firstQuarterScore,
            secondQuarterScore: courseData[courseItemKey].secondQuarterScore,
            thirdQuarterScore: courseData[courseItemKey].thirdQuarterScore,
            fourthQuarterScore: courseData[courseItemKey].fourthQuarterScore,
            totalScore: courseData[courseItemKey].totalScore,
            weight: `${courseData[courseItemKey].weight}%`,
          };
          selectedSubjectCoursesGradesDetails.push(detailItemObj);
        });
      }
      this.setState({
        selectedSubjectId: subId,
        selectedSubjectCoursesGradesDetails,
      });
      this.setState({ highlightBarHeight: "130vh" });
    }

  };

  handleHighlightBar = (event: any, columnId: number) => {
    const checkbox = event.target.checked;
    if (checkbox) {
      let columnIds = [];
      columnIds = this.state.selectedHighlightBar;
      columnIds.push(columnId);
      this.setState({ selectedHighlightBar: columnIds });
    } else {
      let columnIds = [];
      columnIds = this.state.selectedHighlightBar;
      columnIds = columnIds.filter((value) => value !== columnId);
      console.log('After remove: ', columnIds)
      this.setState({ selectedHighlightBar: columnIds });
    }
    this.setState({ highlightCheck: checkbox });
  }
  // Customizable Area End
}
// Customizable Area End
