Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.apiMethodTypeDelete="DELETE"
exports.apiMethodTypeUpdate="PUT"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfresumejourney";
exports.labelBodyText = "cfresumejourney Body";
exports.EventEndpoint="bx_block_school_calendar/events"
exports.btnExampleTitle = "CLICK ME";
exports.classesEndpoint = "account_block/users/find_teacher_assign_classes";
exports.shiftLessonsEndpoint ="bx_block_school_calendar/school_calendars/shift_lessons"
exports.endpoint=""
exports.userDetailsEndpoint = "account_block/users";
exports.calenderCatchMeEndpoint ="bx_block_school_calendar/school_calendars/catch_me_up";
exports.newSchoolDay="bx_block_school_calendar/school_calendars/create_new_school_day"
exports.showCalenderDataEndpoint ="bx_block_school_calendar/school_calendars/show_of_school_calendar_data"
// Customizable Area End