// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, Grid } from "@material-ui/core";
import TenantCourseManagementRejectAcceptRequestModalController, {
  Props,
} from "./TenantCourseManagementRejectRequestAcceptModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CustomButtonWeb from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    createCatalogModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    modalMainGrid: {
      borderRadius: "10px",
      minWidth: "500px",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      minHeight: "290px",
      overflow: "auto",
      position: "absolute",
      padding: "0px 20px 0px 20px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "auto",
        maxWidth: "100%",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    closeIconGrid: {
      position: "absolute",
      top: "22px",
      right: "20px",
    },
    infoBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    subInfoBox: {
      display: "flex",
      marginBottom: "20px",
    },
    iconBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "30px",
      position: "relative",
      marginBottom: "25px",
    },
    mainSpan: {
      padding: "0px 41px",
      textAlign: "center",
    },
    btnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "20px 40px",
      marginBottom: "20px",
      gap: "20px",
    },
  });

// Customizable Area End

export class TenantCourseManagementRejectAcceptRequestModal extends TenantCourseManagementRejectAcceptRequestModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      open,
      classes,
      rejectRequest,
      detailsForApproval,
      isFromAssignCourseToCatalog,
    } = this.props;

    const getMainText = () => {
      if (rejectRequest) return "Sure want to Reject request?";
      else if (!rejectRequest && !isFromAssignCourseToCatalog)
        return "Sure want to Accept request?";
      else if (isFromAssignCourseToCatalog)
        return "Sure you want to add course to catalog?";
    };

    const getSubText = () => {
      if (rejectRequest) return "Are you sure want to reject request for";
      else if (!rejectRequest && !isFromAssignCourseToCatalog)
        return "Are you sure want to approve request for";
      else if (isFromAssignCourseToCatalog)
        return "Are you sure want to add course";
    };

    return (
      <Modal
        open={open}
        className={`${classes.highlightBgColor} ${classes.createCatalogModal}`}
        onClose={this.props.handleClose}
        aria-labelledby="accept-reject-request-modal"
        aria-describedby="accept-reject-request-modal"
      >
        <Box
          className={`${classes.bgColor} ${classes.modalMainGrid}`}
        >
          <Box>
            <Box className={`${classes.iconBox}`}>
              <img src={exclamationIcon} alt="Exclamation" />
              <CloseIcon
                className={`${classes.textCommonColor} ${classes.closeIconGrid} ${classes.cursorPointer}`}
                onClick={this.props.handleClose}
                id="accept-reject-close-modal-icon"
              />
            </Box>
            <Box className={`${classes.infoBox}`}>
              <Typography
                component={"p"}
                className={`${classes.textPrimaryColor} ${classes.textCenter} ${classes.fontBold600} ${classes.fontText22}`}
              >
                {getMainText()}
              </Typography>
              <Box className={`${classes.subInfoBox}`}>
                <Typography
                  className={`${classes.mainSpan}`}
                  component={"span"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    {getSubText()}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                  >
                    {` ${detailsForApproval.topic} `} <br />
                  </Typography>
                  {!isFromAssignCourseToCatalog ? (
                    <>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText18}`}
                      >
                        course of{" "}
                      </Typography>
                      <Typography
                        component={"span"}
                        className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                      >
                        {` ${detailsForApproval.subject} `}
                      </Typography>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText18}`}
                      >
                        subject for
                      </Typography>
                      <Typography
                        component={"span"}
                        className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                      >
                        {` ${detailsForApproval.grade} `}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText18}`}
                      >
                        to
                      </Typography>
                      <Typography
                        component={"span"}
                        className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                      >
                        {` ${detailsForApproval.catalog} `}
                      </Typography>
                    </>
                  )}
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    ?
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className={`${classes.btnBox}`}>
              <CustomButtonWeb
                btnStyle={btnStyle}
                btnText="Cancel"
                buttonVariant="outlined"
                buttonId="reject-approve-cancel-btn"
                handleButtonClick={this.props.handleCancel}
                isDisabled={false}
                type={"button"}
              />
              <CustomButtonWeb
                btnStyle={btnStyle}
                btnText="Yes"
                buttonVariant="contained"
                buttonId="reject-approve-cancel-btn"
                handleButtonClick={this.props.handleYes}
                isDisabled={false}
                type={"button"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  padding: "10px 10px",
  borderRadius: "10px",
  width: "100%",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementRejectAcceptRequestModal
);
// Customizable Area End
