// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import SchoolYearController, { Props } from "./SchoolYearController.web";
import NewSchoolYearDialog from "./NewSchoolYearDialog.web";
import CustomStatusUpdateWeb from "../../../components/src/CustomStatusUpdate.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import DeleteSchoolYearConfirmModalWeb from "./DeleteSchoolYearConfirmModal.web";
// Customizable Area End

// Customizable Area Start
const studentYearColumn = [
  {
    id: "title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "startDate",
    columnId: 2,
    label: "Start Date",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "endDate",
    columnId: 3,
    label: "End Date",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "noOfGradingPeriod",
    columnId: 4,
    label: "No. of Grading Period",
    type: tableColumnTypes.TEXT,    
  },
  {
    id: "status",
    columnId: 5,
    label: "Status",
    type: tableColumnTypes.CHIP,
  },
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    searchBox: {
      display: "flex",
      alignItems: "flex-end",
      margin: theme.spacing(4, 0),
      padding: theme.spacing(0, 3),
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      marginBottom: "6px",
      marginTop: "10px",
      color: "#888888",
    },
    filterBox: {
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
    },
  });

const schoolYearTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px !important",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class SchoolYear extends SchoolYearController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openNewSchoolYearDialog } = this.props;

    const {
      selectMenuItems,
      selectTitle,
      selectTitleError,
      selectGradePeriodTitle,
      selectGradePeriodTitleError,
      selectStatus,
      selectStatusError,
      selectGradePeriodTitleItems,
      selectStatusItems,
      schoolYearTableActionButtonConfig,
      schoolYearTableData,
      openStatusUpdatePopup,
      selectedStatus,
      openDeleteConfirmPopup,
      selectedSchoolYearData,
      openEditSchoolYearPopup,
      schoolYearPermission
    } = this.state;
    return (
      <Box className={`${classes.bgColor}`} style={{ paddingTop: "1px" }}>
        <Grid container className={`${classes.bgColor} ${classes.searchBox}`}>
          <Grid item xs={12} sm={6} md={3}>
            <Box className={`${classes.filterBox}`}>
              <Typography className={classes.inputLabel}>Title</Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-title"
                fieldValue={selectTitle}
                fieldName="selectTitle"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderTitleDropDownValue}
                fieldError={selectTitleError}
                fieldMenuItems={selectMenuItems}
                fieldStyles={{ width: "95%" }}
                placeHolderText="Select Title"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box className={`${classes.filterBox}`}>
              <Typography className={classes.inputLabel}>
                Grade Period Title
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-grade-period-title"
                fieldValue={selectGradePeriodTitle}
                fieldName="selectGradePeriodTitle"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderGradePeriodTitleDropDownValue}
                fieldError={selectGradePeriodTitleError}
                fieldMenuItems={selectGradePeriodTitleItems}
                fieldStyles={{ width: "95%" }}
                placeHolderText="Select Grade Title"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box className={`${classes.filterBox}`}>
              <Typography className={classes.inputLabel}>Status</Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-status"
                fieldValue={selectStatus}
                fieldName="selectStatus"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderStatusDropDownValue}
                fieldError={selectStatusError}
                fieldMenuItems={selectStatusItems}
                fieldStyles={{ width: "95%" }}
                placeHolderText="Select Status"
              />
            </Box>
          </Grid>
          <CustomButtonWeb
            buttonVariant="contained"
            btnStyle={{
              width: "130px",
              padding: "10px 10px",
              borderRadius: "10px",
              fontSize: "16px",
              fontWeight: 600,
              textAlign: "center",
            }}
            btnText="Search"
            buttonId="school-year-filter-search"
            handleButtonClick={() => this.handleGetSchoolYearList()}
            isDisabled={false}
            type={"button"}
          />
        </Grid>
        <Box
          style={{ backgroundColor: "#F1F1F1", height: "4px", width: "100%" }}
        />
        <Box className={`${classes.bgColor}`} padding={"0px 20px"}>
          <ThemeProvider theme={schoolYearTableTheme}>
            <CustomTable
              tableId={"tenant-school-year-table"}
              tableHeaderColumns={studentYearColumn}
              tableBodyData={schoolYearTableData}
              showMenuIcon={schoolYearPermission.schoolYear.write}
              actionButtonConfig={schoolYearTableActionButtonConfig}
              writePermissions={this.props.permission}
            />
          </ThemeProvider>
        </Box>
        {(openNewSchoolYearDialog || openEditSchoolYearPopup) && (
          <NewSchoolYearDialog
            open={openNewSchoolYearDialog || openEditSchoolYearPopup}
            handleClose={() => {
              this.handleCloseEditSchoolYearPopup();
            }}
            handleAddNewSchoolYear={(data: any) =>
              this.handleAddNewSchoolYear(data)
            }
            handleUpdateSchoolYear={(data: any) =>
              this.handleUpdateSchoolYear(data)
            }
            isEdit={openEditSchoolYearPopup}
            schoolYearDetails={selectedSchoolYearData}
          />
        )}
        <CustomStatusUpdateWeb
          open={openStatusUpdatePopup}
          handleClose={() => this.handleCloseStatusUpdatePopup()}
          handleUpdateSelectedStatus={(status: any) =>
            this.handleUpdateStatus(status)
          }
          handleYes={() => this.handleConfirmUpdateStatus()}
          selectedStatus={selectedStatus}
        />
        <DeleteSchoolYearConfirmModalWeb
          open={openDeleteConfirmPopup}
          onCancel={() => this.handleCloseDeleteConfirmPopup()}
          onConfirm={() => this.handleDeleteSchoolYear()}
          title={selectedSchoolYearData?.title || ""}
          type="school_year"
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(SchoolYear);
// Customizable Area End
