Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcolorindicators5";
exports.labelBodyText = "cfcolorindicators5 Body";

exports.btnExampleTitle = "CLICK ME";
exports.subjectsEndpoint = "bx_block_subject/subjects"
exports.gradesEndpoint = "bx_block_grade/grades";
// Customizable Area End