// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
    id: any;
    label: string;
    value: any;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedSubjectValue: string;
    selectedGradeValue: string;
    selectedLessonValue: string;
    selectedStatusValue: string;
    selectedUtilizationValue: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class MyCoursesTabController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            loading: false,
            selectedSubjectValue: "",
            selectedGradeValue: "",
            selectedLessonValue: "",
            selectedStatusValue: "",
            selectedUtilizationValue: "",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    handleChangeDropdownValue = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };

    getDropdownItemsArray = (dropdownName: string) => {
        const refArray = [1, 2, 3, 4, 5];
        let dropdownItems: Array<any> = [];
        refArray.forEach(item => {
            dropdownItems.push({
                id: item,
                label: `${dropdownName}${item}`,
                value: `${dropdownName}${item}`,
            });
        });
        return dropdownItems;
    }

    renderSubjectDropdownValue = (selected: any) => {
        const dropdownName = "Subject";
        const dropdownItems = this.getDropdownItemsArray('Subject');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderGradeDropdownValue = (selected: any) => {
        const dropdownName = "Grade";
        const dropdownItems = this.getDropdownItemsArray('Grade');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderLessonDropdownValue = (selected: any) => {
        const dropdownName = "Lesson";
        const dropdownItems = this.getDropdownItemsArray('Lesson');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderStatusDropdownValue = (selected: any) => {
        const dropdownName = "Status";
        const dropdownItems = this.getDropdownItemsArray('Status');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderUtilizationDropdownValue = (selected: any) => {
        const dropdownName = "Utilization";
        const dropdownItems = this.getDropdownItemsArray('Utilization');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
        if (!selected || selected.length === 0) {
            return `Select ${dropdownName}`;
        }
        const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
        return selctedOption?.label;
    }

    handleSearchAssessment = () => {
        console.log("search");
    }

    handleChangeDropdownValueGradedAssessment = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };

    handleOpenCourseDetail = (data: any) => {
        console.log('open course detail')
        this.props.navigation.navigate('CourseDetailPage', {});
    }
    // Customizable Area End
}
// Customizable Area End
