// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
  psychologySubjectIcon,
} from "./assets";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const myPathCategoryTabsList = [
  {
    id: 1,
    label: "Today",
    value: "today",
  },
  {
    id: 2,
    label: "Overdue",
    value: "overdue",
  },
  {
    id: 3,
    label: "Upcoming",
    value: "upcoming",
  },
  {
    id: 4,
    label: "Review",
    value: "review",
  },
];
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  studentMyPathCourseList: any;
  studentSubjectsList: any;
  selectedMyPathCategoryTab: number;
  subjectDDMenuItems: Array<IDropdownItems>;
  selectedSubjectDDValue: any;
  filterSubjectId:any;
  paginationDueList: any;
  dueDateSortingList: Array<IDropdownItems>;
  selectedSortByDueDateDDValue: any;
  completionSortingDateList: Array<IDropdownItems>;
  selectedSortByCompletionDateDDValue: any;
  gradingComponent: Array<any>;
  gradingComponentDDMenuItems: Array<IDropdownItems>;
  selectedGradingComponentDDValue: any;
  selectedSortByGradeDDValue: any;
  selectedDateFilterText: string;
  selectedDateReviewFilterText:string;
  filterGradingComponent:any
  filterSortByCompletionDateOrder: any;
  filterSortByGradeOrder:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyPathController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentMyPathListId:string="";
  apiGetStudentMyPathSubjectList:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      studentMyPathCourseList: [],
      studentSubjectsList: [],
      selectedMyPathCategoryTab: 0,
      subjectDDMenuItems: [],
      selectedSubjectDDValue: "",
      paginationDueList: {},
      filterSubjectId:"",
      dueDateSortingList: [
        { id: "asc", value: "asc", label: "Ascending" },
        { id: "desc", value: "desc", label: "Descending" },
      ],
      selectedSortByDueDateDDValue: "",
      completionSortingDateList: [
        { id: "asc", value: "asc", label: "Ascending" },
        { id: "desc", value: "desc", label: "Descending" },
      ],
      selectedSortByCompletionDateDDValue: "",
      gradingComponent: [
        {
          id: 1,
          label: "All",
          value: "all",
        },
        {
          id: 2,
          label: "Quiz",
          value: "quiz",
        },
        {
          id: 3,
          label: "Project",
          value: "project",
        },
        {
          id: 4,
          label: "Exam",
          value: "exam",
        },
        {
          id: 5,
          label: "Assignment",
          value: "assignment",
        },
      ],
      filterGradingComponent:"",
      gradingComponentDDMenuItems: [],
      selectedGradingComponentDDValue: "",
      selectedSortByGradeDDValue: "",
      selectedDateFilterText: "tomorrow",
      selectedDateReviewFilterText:"review_last_week",
      filterSortByCompletionDateOrder: "",
      filterSortByGradeOrder:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetStudentMyPathSubjectListApi()
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps:Props, prevState:S) {
    if (this.state.selectedMyPathCategoryTab === 1 && (prevState.selectedSortByDueDateDDValue !== this.state.selectedSortByDueDateDDValue)) {
      this.handleGetStudentMyPathApi({})
    }
  }

  handleSetSubjectDDItems = () => {
    const { studentSubjectsList } = this.state;
    const subjectDDMenuItems: Array<IDropdownItems> = studentSubjectsList?.map(
      (subject: any) => {
        return {
          id: subject?.id,
          value: subject?.id,
          label: subject?.subjectName,
        };
      }
    );
    this.setState({ subjectDDMenuItems });
  };

  handleChangeMyPathCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedMyPathCategoryTab: newValue },()=>{
      this.handleGetStudentMyPathApi({});
    });
  };

  renderSubjectDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { subjectDDMenuItems } = this.state;
    const selctedSubject = subjectDDMenuItems.find(
      (subject: any) => subject?.id === selected
    );
    return selctedSubject?.label;
  };

  handleSearchMyPathCourse = () => {
    this.setState({filterSubjectId:this.state.selectedSubjectDDValue},()=>{
      this.handleGetStudentMyPathApi({})
    });
  };

  handleChangeDDSelect = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode 
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };
  getDateRange(filterText: string): { start_date: string, end_date: string } {
    const today = moment();
    let start_date: moment.Moment;
    let end_date: moment.Moment;

    switch (filterText) {
        case "tomorrow":
            start_date = today.clone().add(1, 'days'); // Tomorrow's date
            end_date = start_date; // End date is the same as the start date for tomorrow
            break;

        case "this_week":
            start_date = today.clone().startOf('isoWeek'); // Start of the current week (Sunday)
            end_date = today.clone().endOf('isoWeek'); // End of the current week (Saturday)
            break;
        
        case "review_today":
          start_date = today.clone();
          end_date = start_date;
          break;

        case "review_yesterday":
            start_date = today.clone().subtract(1, 'days');
            end_date = start_date;
            break;

        case "review_last_week":
            start_date = today.clone().subtract(1, 'weeks').startOf('isoWeek');
            end_date = today.clone().subtract(1, 'weeks').endOf('isoWeek');
            break;

        case "review_last_month":
            start_date = today.clone().subtract(1, 'months').startOf('month');
            end_date = today.clone().subtract(1, 'months').endOf('month');
            break;

        default:
            throw new Error("Unsupported filter text");
    }

    // Format both dates in DD-MM-YYYY format
    return {
        start_date: start_date.format('DD-MM-YYYY'),
        end_date: end_date.format('DD-MM-YYYY')
    };
}
  handleSetMyPathList=(myPathList: any[],pagination:any)=>{
    const studentMyPathList = myPathList.map((item, index) => {
      const { attributes } = item;
      return {
        id: attributes.id, 
        courseName: attributes.course_name,
        subjectIcon: attributes.subject.icon,
        subjectBgColor: attributes.subject.color,
        subjectId: attributes.subject.id,
        points: attributes.total_marks_activity,
        topicName: attributes.topic_name,
        dueDate: attributes?.due_date ? moment(attributes.due_date).format('DD/MM/YYYY') : "N/A", 
        assignedDate: attributes.assigned_date? moment(attributes.assigned_date).format('DD/MM/YYYY'):"N/A", 
        submittedDate: attributes.student_activity?.student_activity?.submission_date ? moment(attributes.student_activity?.student_activity?.submission_date).format('DD/MM/YYYY') : "N/A",
        score: attributes.marks_recived || 0, 
        typeOfAssessment: attributes.grading_component,
        objectiveType:attributes.objective_type,
        evaluationType:attributes.evolution_type,
        assessmentTitle: attributes.activities_title,
        percentage:attributes?.student_activity?.percentage || 0
      };
    });
    this.setState({studentMyPathCourseList:studentMyPathList,paginationDueList: pagination})
  }
  buildReviewAssessmentsUrl(requestUrl:string,page:any){
    requestUrl += `?review_assessments=true&per_page=9&page=${page}`
    if(this.state.filterSortByCompletionDateOrder){
      requestUrl += `&completion_date_order=${this.state.filterSortByCompletionDateOrder}`
    }
    if(this.state.filterSortByGradeOrder){
      requestUrl += `&grade_order=${this.state.filterSortByGradeOrder}`
    }
    if(this.state.selectedDateReviewFilterText){
      const dueDate =  this.getDateRange(this.state.selectedDateReviewFilterText)
      requestUrl += `&start_due_date=${dueDate.start_date}`
      requestUrl += `&end_due_date=${dueDate.end_date}`
    }
    return requestUrl;
  }
  buildUpcomingAssessmentsUrl(requestUrl:string,page:any){
    requestUrl += `?upcoming_due=true&per_page=9&page=${page}`
    if(this.state.filterGradingComponent && this.state.filterGradingComponent != "all"){
      requestUrl += `&grading_component=${this.state.filterGradingComponent}`
    }
    if(this.state.selectedDateFilterText){
      const dueDate =  this.getDateRange(this.state.selectedDateFilterText)
      requestUrl += `&start_due_date=${dueDate.start_date}`
      requestUrl += `&end_due_date=${dueDate.end_date}`
    }
    return requestUrl;
  }

  handleGetStudentMyPathApi = async (pagination:any) => {
    showCustomLoader();
    const {selectedMyPathCategoryTab} = this.state
    let requestUrl = `${configJSON.studentMyPathEndpoint}`
    const page = pagination.page ? pagination.page : 1;
    if(selectedMyPathCategoryTab === 3){
      requestUrl = this.buildReviewAssessmentsUrl(requestUrl,page)
    }
    else if(selectedMyPathCategoryTab === 2){
      requestUrl = this.buildUpcomingAssessmentsUrl(requestUrl,page)
    }else if(selectedMyPathCategoryTab === 1){
      requestUrl += `?over_due=true&per_page=9&page=${page}`
      if(this.state.selectedSortByDueDateDDValue){
        requestUrl += `&due_date=${this.state.selectedSortByDueDateDDValue}`
      } 
    }else{
      requestUrl += `?todays_due=true&per_page=9&page=${page}`
    }
    if(this.state.filterSubjectId){
      requestUrl += `&subject_id=${this.state.filterSubjectId}`
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentMyPathListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetStudentsMyPathResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetMyPathList(responseJson.data,responseJson?.meta?.pagination);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  handleGetStudentMyPathSubjectListApi = async () => {
    showCustomLoader();
    let requestUrl = `${configJSON.studentCoursesEndpoint}?per_page=100&page=1`
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentMyPathSubjectList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleSetMyPathSubjectList=(courseList: any[])=>{
    const subjectSet = new Set();
    const subjectsList = courseList.reduce((list: { id: any; subjectName: any; }[], course: { attributes: { subject: any; }; }) => {
      const subject = course.attributes.subject;
      if (!subjectSet.has(subject.name)) {
        list.push({
          id: subject.id,
          subjectName: subject.name.trim()
        });
        subjectSet.add(subject.name);
      }
      return list;
    }, []);
    this.setState({studentSubjectsList:subjectsList},()=>{
      this.handleSetSubjectDDItems();
      this.handleGetStudentMyPathApi({})
    })
  }
  handleGetStudentMyPathSubjectListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetMyPathSubjectList(responseJson.data);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  handleMyPathPaginationChange = (event: any, page: number) => {
    this.handleGetStudentMyPathApi({ page: page });
  }

  renderGradingComponent = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponent = gradingComponent.find((grade : any) => grade?.value === selected);
    return selectedGradingComponent?.label;
  };

  renderDueDateComponent = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { dueDateSortingList } = this.state;
    const selectedSortByDueDateDDValue = dueDateSortingList.find(
      (dueDate: any) => dueDate?.id === selected
    );
    return selectedSortByDueDateDDValue?.label;
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleChangeDateFilter = (filterText: string) => {
    this.setState({ selectedDateFilterText: filterText },()=>{
      this.handleGetStudentMyPathApi({})
    });
  };
  
  handleChangeDateReviewFilter = (filterText: string) => {
    this.setState({ selectedDateReviewFilterText: filterText },()=>{
      this.handleGetStudentMyPathApi({})
      console.log("filter search",this.state.selectedDateReviewFilterText)
    });
  };
  handleSearchMyPathCourses = () => {
    this.setState({
      filterGradingComponent:this.state.selectedGradingComponentDDValue
    },()=>{
     this.handleGetStudentMyPathApi({})
    })
  };
  handleSearchMyPathInReview=()=>{
    this.setState({
      filterSortByCompletionDateOrder:this.state.selectedSortByCompletionDateDDValue,
      filterSortByGradeOrder:this.state.selectedSortByGradeDDValue
    },()=>{
      this.handleGetStudentMyPathApi({})
     })
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetStudentMyPathListId:
            this.handleGetStudentsMyPathResponse(
              responseJson,
              errorResponse
            );
            break;
          case this.apiGetStudentMyPathSubjectList:
            this.handleGetStudentMyPathSubjectListResponse(
              responseJson,
              errorResponse
            );
            break;
          }
        }
      }
    }


  // Customizable Area End
}
// Customizable Area End
