// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@material-ui/core";
import CatchMeUpModalSuccessController, {
  Props,
} from "./CatchMeUpModalSuccessController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
 
});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "520px",
      },
      paperWidthSm: {
        maxWidth: "520px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class CatchMeUpModalSuccess extends CatchMeUpModalSuccessController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          data-test-id="catchup"
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          open={open}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          style={webStyle.posRelative}
        >  
          <Box display={"flex"} justifyContent={"flex-end"} style={webStyle.posAbsolute} className={`${classes.bgColor} `}>
            <IconButton
              data-test-id="crossCatch"
              style={{ float: "right" }}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={this.props.onCancel}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              id="Catch-up-confirm-dialog-title"
            >
              <Box>
                <img src={SuccessIcon} alt="success icon" />
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }}>
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                >{this.state.userRole == "teacher" ? "Course Caught Up Successfully" : "Class will now follow adjusted schedule"}
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"}>
                <Typography
                  data-test-id="twoCtach"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  Physics{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                >
                  course for
                </Typography>

                <Typography
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  {this.state.userRole == "teacher" ? "Class 10A" : " Milton Perry"}{" "}
                </Typography>
                <Typography
                  data-test-id="catchUp"
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                >
                  caught up
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                >
                  successfully.
                </Typography>
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
              >
                <CustomButton
                  btnStyle={okayButton}
                  btnText="Okay"
                  buttonId="quiz-view-answer-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const okayButton = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const webStyle={
  posRelative:{
    position:"relative" as "relative",
  },
  posAbsolute:{
    position:"absolute" as "absolute",
    top:"10px",
    right:"20px"
  }
}
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CatchMeUpModalSuccess);
// Customizable Area End
