// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  videoPosition: string;
  textPosition: string;
  video: any;
  description: string;
  activityTitle?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class VideoPreviewDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    console.log("video preview: ", this.props.video);
    // Customizable Area End
  }

  // Customizable Area Start
  getClassToArrangeContent = () => {
    const videoPosition = this.props.videoPosition;
    const textPosition = this.props.textPosition;
    const classes = this.props.classes;
    if (videoPosition === "bottom" && textPosition === "top") {
      return `${classes.previewContent} ${classes.videoBottomTextTop}`;
    } else if (videoPosition === "top" && textPosition === "bottom") {
      return `${classes.previewContent} ${classes.videoTopTextBottom}`;
    } else if (videoPosition === "right" && textPosition === "left") {
      return `${classes.previewContent} ${classes.videoRightTextLeft}`;
    } else if (videoPosition === "left" && textPosition === "right") {
      return `${classes.previewContent} ${classes.videoLeftTextRight}`;
    }
  }

  getWidthOfTextBox = () => {
    const videoPosition = this.props.videoPosition;
    if (videoPosition === 'left' || videoPosition === 'right') {
      return '30%';
    } else {
      return '100%';
    }
  }

  getWidthOfVideoBox = () => {
    const videoPosition = this.props.videoPosition;
    if (videoPosition === 'left' || videoPosition === 'right') {
      return "70%";
    } else {
      return "100%";
    }
  }

  getHeightOfVideoBox = () => {
    const videoPosition = this.props.videoPosition;
    if (videoPosition === 'left' || videoPosition === 'right') {
      return '100%';
    } else {
      return '315px';
    }
  }

  getVideoUrl = (video: any) => {
    if (video) {
      if (video.url) {
        return video.url;
      } else {
        const url = window.URL.createObjectURL(video);
        return url;
      }
    }
  }
  // Customizable Area End
}
// Customizable Area End