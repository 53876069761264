// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal } from "@material-ui/core";
import PublishCourseConfirmDialogController, {
  Props,
} from "./PublishCourseConfirmDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CustomButtonWeb from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    coursePublishConfirmModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    publishModalMainGrid: {
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      width: "auto",
      margin: "auto",
      top: "50%",
      left: "50%",
      minHeight: "290px",
      height: "auto",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "auto",
        maxWidth: "100%",
      },
    },
    closeIconGrid: {
      position: "absolute",
      top: "22px",
      right: "20px",
    },
    textCenter: {
      textAlign: "center",
    },
    publishInfoBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    subpublishInfoBox: {
      display: "flex",
    },
    publishIconBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "30px",
      position: "relative",
      marginBottom: "25px",
    },
    confirmMainSpan: {
      padding: "0px 41px",
      textAlign: "center",
    },
    publishConfirmBtnBox: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    }
  });

// Customizable Area End

export class PublishCourseConfirmDialog extends PublishCourseConfirmDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, detailsForConfirmation } = this.props;

    return (
      <Modal
        open={open}
        className={`${classes.highlightBgColor} ${classes.coursePublishConfirmModal}`}
        aria-labelledby="course-publish-confirm-modal"
        onClose={this.props.handleClose}
        aria-describedby="course-publish-confirm-modal"
      >
        <Box className={`${classes.bgColor} ${classes.publishModalMainGrid}`}>
          <Box>
            <Box className={`${classes.publishIconBox}`}>
              <img src={exclamationIcon} alt="Exclamation" />
              <CloseIcon
                className={`${classes.textCommonColor} ${classes.closeIconGrid} ${classes.cursorPointer}`}
                id="course-publish-close-modal-icon"
                onClick={this.props.handleClose}
              />
            </Box>
            <Box className={`${classes.publishInfoBox}`}>
              <Typography
                component={"p"}
                className={`${classes.textPrimaryColor} ${classes.textCenter} ${classes.fontBold600} ${classes.fontText22}`}
              >
                Sure want to send Approval Request?
              </Typography>
              <Box className={`${classes.subpublishInfoBox}`}>
                <Typography
                  className={`${classes.confirmMainSpan}`}
                  component={"p"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    Are you sure want to send approval request for
                  </Typography>
                </Typography>
              </Box>
              <Box className={`${classes.subpublishInfoBox}`}>
                <Typography
                  className={`${classes.confirmMainSpan}`}
                  component={"p"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.textCapital}`}
                  >
                    {`${detailsForConfirmation.topic} `}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    course of
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.textCapital}`}
                  >
                    {` ${detailsForConfirmation.subject} `}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    subject for
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.textCapital}`}
                  >
                    {` ${detailsForConfirmation.grade} `}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    ?
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={`${classes.publishConfirmBtnBox}`}>
            <CustomButtonWeb
              btnStyle={btnStyle}
              buttonVariant="outlined"
              btnText="Cancel"
              buttonId="course-publish-cancel-btn"
              handleButtonClick={this.props.handleCancel}
              isDisabled={false}
              type={"button"}
            />
            <CustomButtonWeb
              btnStyle={btnStyle}
              btnText="Yes"
              buttonVariant="contained"
              buttonId="course-publish-confirm-btn"
              handleButtonClick={this.props.handleYes}
              isDisabled={false}
              type={"button"}
            />
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  fontSize: "16px",
  padding: "10px 10px",
  borderRadius: "10px",
  width: "48%",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  PublishCourseConfirmDialog
);
// Customizable Area End
