// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  openNewGradeDialog: boolean;
  handleCloseNewGradePopup: any;
  // Customizable Area End
}
// Customizable Area Start
export interface DropdownItems {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  selectGradeItems: Array<DropdownItems>;
  gradesList: Array<any>;
  gradesTableActionButtonConfig: Array<any>;
  selectedGradeData: any;
  selectedGrade: any;
  openEditNewGradePopup: boolean;
  openDeleteGradePopup: boolean;
  token: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminSetupGradeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradesListing: string = "";
  apiPostGrade: string = "";
  apiUpdateGrade: string = "";
  apiDeleteGrade: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectGradeItems: [],
      gradesList: [],
      selectedGradeData: "",
      selectedGrade: "",
      token: "",
      openEditNewGradePopup: false,
      openDeleteGradePopup: false,
      gradesTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenEditGradePopup(value),
        },
        {
          label: "Delete",
          action: (value: any) => this.handleOpenGradeDeleteConfirmModal(value),
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  renderGradeDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Value";
    }
    const { selectGradeItems } = this.state;
    const selectedGrade = selectGradeItems.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGrade?.label;
  };

  handleDropDownValueChange = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleSetGradesDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const gradesList = responseJson?.data?.map((item: any, index: number) => {
        return {
          id: item?.id,
          value: item?.attributes?.name,
          label: item?.attributes?.name,
        };
      });
      this.setState({
        selectGradeItems: gradesList || [],
      });
    }
  };

  handleSetGradeDataItem = (item: any) => {
    return {
      id: item?.id,
      grade: item?.attributes?.name,
    };
  };

  handleSetGradeData = (list: any) => {
    const gradesData = list?.map((item: any) =>
      this.handleSetGradeDataItem(item)
    );
    this.setState({ gradesList: gradesData });
  };

  handleGetGradesList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const { selectedGrade } = this.state;
    const queryString = `?name=${selectedGrade}`;
    let getGradesEndpoint = configJSON.gradesEndpoint;
    if (selectedGrade) {
      getGradesEndpoint = `${getGradesEndpoint}${queryString}`;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradesListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getGradesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetGradesDataResponse = (responseJson: any, errorResponse: any) => {
    const { selectGradeItems } = this.state;
    if (responseJson?.data) {
      this.handleSetGradeData(responseJson.data);
      this.handleSetGradesDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleAddNewGrade = (requestBody: any) => {
    this.props.handleCloseNewGradePopup();
    showCustomLoader();
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gradesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateGrade = (requestBody: any) => {
    this.handleCloseEditGradePopup();
    showCustomLoader();
    const { token, selectedGradeData } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradesEndpoint}/${selectedGradeData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostGradeDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.GRADE_CREATION_SUCCESS);
      this.handleGetGradesList();
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUpdateGradeResponse = (responseJson: any, errorResponse: any) => {
    const { gradesList } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedGradeItem = this.handleSetGradeDataItem(responseJson.data);
      const updatedGradesList = gradesList.map((item) => {
        if (item.id === updatedGradeItem.id) {
          item = { ...updatedGradeItem };
        }
        return item;
      });
      this.setState({ gradesList: updatedGradesList });
      toast.success(TOASTER_NOTIFICATION_MSG.GRADE_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSetUserTokenTenantGrade = async () => {
    const token = await getStorageData("token");
    this.setState({ token });
  };

  handleOpenEditGradePopup = (value: any) => {
    const { gradesList } = this.state;
    const selectedGradeData = gradesList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedGradeData,
      },
      () => {
        this.setState({ openEditNewGradePopup: true });
      }
    );
  };

  handleCloseEditGradePopup = () => {
    this.setState({ openEditNewGradePopup: false });
    this.props.handleCloseNewGradePopup();
  };

  handleDeleteGrade = async () => {
    this.handleCloseDeleteGradeConfirmModal();
    showCustomLoader();
    const { token, selectedGradeData } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradesEndpoint}/${selectedGradeData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteGradeResponse = (responseJson: any) => {
    const { gradesList, selectedGradeData } = this.state;
    if (responseJson) {
      const updatedGradeList = gradesList.filter(
        (item) => item.id !== selectedGradeData.id
      );
      this.setState({
        gradesList: updatedGradeList,
        selectedGradeData: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.GRADE_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.GRADE_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleOpenGradeDeleteConfirmModal = (value?: any) => {
    const { gradesList } = this.state;
    const selectedGradeData = gradesList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedGradeData: selectedGradeData,
      },
      () => {
        this.setState({ openDeleteGradePopup: true });
      }
    );
  };

  handleCloseDeleteGradeConfirmModal = () => {
    this.setState({ openDeleteGradePopup: false });
  };
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetGradesList();
    this.handleSetUserTokenTenantGrade();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetGradesListing:
            {
              this.handleGetGradesDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiPostGrade:
            {
              this.handlePostGradeDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiDeleteGrade:
            {
              this.handleDeleteGradeResponse(responseJson);
            }
            break;
          case this.apiUpdateGrade:
            {
              this.handleUpdateGradeResponse(responseJson, errorResponse);
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
