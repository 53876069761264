// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  studentProfile,
  parent1,
  parent2 as student2,
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
} from "./assets";
import { displayApiErrorMsg, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IUserDetailProps {
  id: any;
  emailId: string;
  status: string;
  profilePic?: any;
  customFields: any;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  teacherFullName: string;
  teacherRole: string;
  parentTeacherDetails: IUserDetailProps;
  subjectHandledList: Array<any>;
  curriculumList: Array<any>;
  gradeLevelList: Array<any>;
  coursesList: Array<any>;
  assignedClassesList: Array<any>;
  assignedStudentsList: Array<any>;
  userId:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewParentTeacherProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getParentTeacherProfileDetailsCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      teacherFullName: "",
      parentTeacherDetails: {
        id: undefined,
        emailId: "",
        status: "",
        customFields: []
      },
      userId:"",
      subjectHandledList: [],
      curriculumList: [],
      gradeLevelList: [],
      coursesList: [
        {
          subjectIcon: physicsSubjectIcon,
          courseId: 1,
          subjectName: "Physics",
          subjectBgColor: "#01C996",
        },
        {
          subjectIcon: physicsSubjectIcon,
          courseId: 2,
          subjectName: "Chemistry",
          subjectBgColor: "#01C996",
        },
        {
          subjectIcon: physicsSubjectIcon,
          courseId: 3,
          subjectName: "Biology",
          subjectBgColor: "#01C996",
        },
        {
          subjectName: "Algebra",
          subjectIcon: mathSubjectIcon,
          courseId: 4,
          subjectBgColor: "#0D46BC",
        },
        {
          subjectName: "Calculus",
          subjectIcon: mathSubjectIcon,
          courseId: 5,
          subjectBgColor: "#0D46BC",
        },
        {
          subjectName: "English",
          courseId: 6,
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
        },
        {
          subjectName: "English Poetry",
          courseId: 7,
          subjectIcon: englishSubjectIcon,
          subjectBgColor: "#019AFC",
        },
        {
          subjectName: "Roman History",
          courseId: 8,
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FBD82C",
        },
        {
          subjectName: "Indian History",
          courseId: 9,
          subjectIcon: historySubjectIcon,
          subjectBgColor: "#FBD82C",
        },
      ],
      assignedClassesList: [],
      assignedStudentsList: [],
      teacherRole: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetParentTeacherDetails();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetParentTeacherDetails = () => {
    const role = this.props.navigation?.getParam("role");
    const nameFromParam = this.props.navigation?.getParam("fullName");
    this.setState({ userId: nameFromParam, teacherRole: role },()=>{
      this.getParentTeacherProfileDetailsById(nameFromParam)
    });
  };

  getParentTeacherProfileDetailsById =async (value: string) => {
    showCustomLoader();
    let token = await getStorageData("token")
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getParentTeacherProfileDetailsCallId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + value
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };
  HandleParentTeacherProfileDetailsByIdResponse(responseJson: {
    data: {
      id: string;
      attributes: {
        user_custom_fields?: {
          data: {
            attributes: {
              field_value: string;
            };
          }[];
        };
        expiring_on: string;
        profile_url: string;
        status: string;
        school_year: { title: string };
        role: { name: string };
        DOB: string;
        id_number: string;
        curriculum: { name: string };
        first_name: string;
        last_name: string;
        email: string;
        grade: { name: string;id:any }[];
        subject:any[];
        children:any[]
        classes_assigned:any[];
        courses_assigned:any[]
      };
    };
    errors:any
  }) {
   if(responseJson?.data){
    let getUserDetails = responseJson?.data.attributes;
    const teacherFullName = `${getUserDetails.first_name} ${getUserDetails.last_name.trim()}`;
    const customFields: any = getUserDetails?.user_custom_fields?.data?.map((item: any) => {
      return {
        id: item?.attributes?.id,
        label: item?.attributes?.label,
        value: item?.attributes?.field_value,
      }
    })
    const parentTeacherDetails ={
      id:this.state.userId,
      emailId:getUserDetails.email || "N/A",
     profilePic:getUserDetails.profile_url || parent1,
     status:getUserDetails.status || "N/A",
     customFields: customFields
    }
    const assignedStudentsList = getUserDetails.children.map(child => ({
      studentId: child.id,
      profilePic: child.profile_url,
      fullName: `${child.first_name} ${child.last_name}`.trim()
    }));
    const curriculumList = [];
    if(getUserDetails?.curriculum){
      const curriculumDetails =  {
        id: 1,
        name: getUserDetails.curriculum.name,
      }
      curriculumList.push(curriculumDetails);
    }
    const gradeLevelList = getUserDetails.grade.map(item => ({
      id: item.id,
      name: item.name
    }));
   
    const subjectHandledList = getUserDetails.subject.map(item => ({
      subjectIcon: item.icon || 'defaultIcon',
      id: item.id,
      subjectName: item.name.trim(),
      subjectBgColor: item.color
  }));
    const assignedClassesList = getUserDetails.classes_assigned.map(classItem => ({
      id: classItem.id,
      name: classItem.name
    }));
    const coursesList = getUserDetails.courses_assigned.map(course => {
      return {
        courseId: course.id,
        subjectName: course.course_title,
        subjectIcon:course?.subject?.icon || 'defaultIcon', 
        subjectBgColor: course?.subject?.bgColor || "#FFFFFF", 
      };
    });
  
    this.setState({coursesList,assignedClassesList,subjectHandledList,gradeLevelList,teacherFullName,parentTeacherDetails,assignedStudentsList,curriculumList})
    }else{
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if(apiRequestCallId !== null) {
      if(apiRequestCallId === this.getParentTeacherProfileDetailsCallId) {
        this.HandleParentTeacherProfileDetailsByIdResponse(responseJson);
      }
    }
  }
  // Customizable Area End
}
// Customizable Area End
