// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
    id: any;
    label: string;
    value: any;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedGradeValue: string;
    selectedTitleValue: string;
    selectedSubjectValue: string;
    selectedAccpetedOnValue: string;
    selectedRequestedOnValue: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class ApprovedRequestsTabController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            selectedSubjectValue: "",
            selectedTitleValue: "",
            selectedRequestedOnValue: "",
            selectedAccpetedOnValue: "",
            selectedGradeValue: "",
        };
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    getDropdownItemsArray = (DDName: string) => {
        const refArray = [1, 2, 3, 4, 5];
        let DDItems: Array<any> = [];
        refArray.forEach(item => {
            DDItems.push({
                id: item,
                label: `${DDName}${item}`,
                value: `${DDName}${item}`,
            });
        });
        return DDItems;
    }

    renderSubjectDropdownValue = (selected: any) => {
        const DDName = "Subject";
        const DDItems = this.getDropdownItemsArray('Subject');
        return this.getSelectedDDValue(selected, DDName, DDItems);
    };

    renderApprovedOnDropdownValue = (selected: any) => {
        const DDName = "Approved On";
        const DDItems = this.getDropdownItemsArray('August');
        return this.getSelectedDDValue(selected, DDName, DDItems);
    };

    renderGradeDropdownValue = (selected: any) => {
        const DDName = "Grade";
        const DDItems = this.getDropdownItemsArray('Grade');
        return this.getSelectedDDValue(selected, DDName, DDItems);
    };

    renderRequestedOnDropdownValue = (selected: any) => {
        const DDName = "Requested On";
        const DDItems = this.getDropdownItemsArray('July');
        return this.getSelectedDDValue(selected, DDName, DDItems);
    };

    handleApprovedRequestsSearch = () => {
        console.log("search");
    }

    renderTitleDropdownValue = (selected: any) => {
        const DDName = "Title";
        const DDItems = this.getDropdownItemsArray('Title');
        return this.getSelectedDDValue(selected, DDName, DDItems);
    };

    getSelectedDDValue = (selected: any, DDName: string, DDItems: any) => {
        if (!selected || selected.length === 0) {
            return `Select ${DDName}`;
        }
        const selctedOption = DDItems.find((item: any) => item?.value === selected);
        return selctedOption?.label;
    }

    handleChangeDropdownValue = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };
    // Customizable Area End
}
