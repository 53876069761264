// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPendingReqTitleValue: string;
  selectedPendingReqSubjectValue: string;
  selectedPendingReqGrade: string;
  selectedRequestedOnValues: string;
  RequestedOnDDComponentValue: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherPendingRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectedPendingReqTitleValue: "",
      selectedPendingReqSubjectValue: "",
      selectedPendingReqGrade: "",
      selectedRequestedOnValues: "",
      RequestedOnDDComponentValue:[
        {
          id: 1,
          label: "Ascending",
          value: 1,
        },
        {
          id: 2,
          label: "Descending",
          value: 2,
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handlePendingSearchBtn = () => {
    console.log("search btn");
  };

  renderPendingReqSubjectDDValue = (selected: any) => {
    const dropdownName = "Subject";
    const dropdownItems = this.getPendingDropdownItemsArray("Subject");
    return this.getSelectedDropdownValue(selected, dropdownName, dropdownItems);
  };

  renderPendingGradeDDValue = (selected: any) => {
    const dropdownName = "Grade";
    const dropdownItems = this.getPendingDropdownItemsArray("Grade");
    return this.getSelectedDropdownValue(selected, dropdownName, dropdownItems);
  };

  getPendingDropdownItemsArray = (dropdownName: string) => {
    const itemsArray = [1, 2, 3, 4, 5];
    let resultItems: Array<any> = [];
    itemsArray.forEach((item) => {
      resultItems.push({
        id: item,
        label: `${dropdownName}${item}`,
        value: `${dropdownName}${item}`,
      });
    });
    return resultItems;
  };
  renderRequestedOnDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Ascending";
    }
    const { RequestedOnDDComponentValue } = this.state;
    const selectedGradingComponents = RequestedOnDDComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  getSelectedDropdownValue = (
    selected: any,
    dropdownName: string,
    dropdownItems: any
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find(
      (item: any) => item?.value === selected
    );
    return selctedOption?.label;
  };

  handleChangePendingDDValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderTitleDDValue = (selected: any) => {
    const dropdownName = "Title";
    const dropdownItems = this.getPendingDropdownItemsArray("Title");
    return this.getSelectedDropdownValue(selected, dropdownName, dropdownItems);
  };
  // Customizable Area End
}
// Customizable Area End
