// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@material-ui/core";
import CustomTextField from "../../../components/src/CustomTextField.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import NewGradeDialogController, {
  Props,
} from "./NewGradeDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(2.6),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        top: theme.spacing(5),
        right: theme.spacing(4),
        width: theme.spacing(1.5),
      },
    },
    inputLabel: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    paper: {
      width: "460px",
      maxWidth: "100%",
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewGradeDialog extends NewGradeDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, handleClose, isEdit } = this.props;
    const { gradeName, gradeNameError, gradeNameErrorMsg } = this.state;
    return (
      <Dialog
        classes={{ paper: classes.paper }}
        className={`${classes.highlightBgColor} ${classes.dialogRoot}`}
        open={open}
        onClose={handleClose}
      >
        <Box>
          <DialogTitle
            id="new-school-year-dialog-title"
            className={`${classes.bgColor}`}
          >
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
            >
              {isEdit ? "Edit Grade" : "Add New Grade"}
            </Typography>
            <CloseIcon
              id="add-edit-grade-close-icon"
              className={`${classes.closeIcon} ${classes.textPrimaryColor}`}
              onClick={this.handleClose}
            />
          </DialogTitle>
          <DialogContent className={`${classes.bgColor}`}>
            <Box>
              <Box>
                <Typography className={classes.inputLabel}>
                  Grade Name
                </Typography>
                <CustomTextField
                  placeHolderText="Enter Grade Name"
                  fieldName="gradeName"
                  fieldId="grade-name-field"
                  textFieldStyles={{ padding: "10px 0" }}
                  fieldValue={gradeName}
                  fieldWidth={"100%"}
                  fieldType="text"
                  fieldError={gradeNameError}
                  helperText={gradeNameErrorMsg}
                  handleChange={this.handleChangeGradeName}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  margin: "30px 0",
                  justifyContent: "end",
                }}
              >
                <CustomButton
                  btnStyle={cancelbtnStyle}
                  btnText="Cancel"
                  buttonId="grade-create-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleClose()}
                />
                <CustomButton
                  btnStyle={addBtnStyle}
                  btnText={isEdit ? "Edit" : "Add"}
                  buttonId="grade-create-add-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleAdd()}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addBtnStyle = {
  borderRadius: "12px",
  padding: "15px 36px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const cancelbtnStyle = {
  marginRight: "15px",
  borderRadius: "12px",
  padding: "15px 36px",
  fontSize: "16px",
  fontWeight: 600,
  border: "1px solid",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewGradeDialog);
// Customizable Area End
