import React from "react";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import CustomLoaderController, { Props } from "./CustomLoaderController.web";
import { combineStyles } from "../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../components/src/CustomThemeStyles.web";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
      zIndex: 100,
      display: "none",
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  });

export class CustomLoader extends CustomLoaderController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <Box id="custom-loader" className={classes.root}>
        <Box className={classes.circularContainer}>
          <CircularProgress className={`${classes.textButton}`} />
        </Box>
      </Box>
    );
  }
}

const combinedStyle = combineStyles(customThemeStyles, useStyles);
export default withStyles(combinedStyle)(CustomLoader);