// Customizable Area Start
import React from "react";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import AddStudentController, {
  Props,
} from "./AddStudentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
// Customizable Area End

// Customizable Area Start
const studentDataColumn = [
  {
    id: "student",
    columnId: 1,
    label: "Student",
    type: tableColumnTypes.STUDENT_ICON,
    width: "300px",
    maxWidth: "320px",
  },
  {
    id: "curriculum",
    columnId: 2,
    label: "Curriculum",
    type: tableColumnTypes.TEXT,
    width: "200px",
    maxWidth: "220px",
  },
  {
    id: "grade",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "customField",
    columnId: 4,
    label: "Custom Field",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "actionButton",
    columnId: 5,
    label: "",
    type: tableColumnTypes.BUTTON,
  },
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "#F1F1F1",
      borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "50%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    searchBox: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(4, 3.2),
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
      marginBottom: theme.spacing(2.25),
    },
    addStudentBtn: {
      width: "100px",
      borderRadius: "12px",
      backgroundColor: "#01C996",
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
    removeStudentBtn: {
      width: "100px",
      borderRadius: "12px",
      border: "1px solid red",
      color: "red",
      textTransform: "capitalize",
    },
    studentTable: {
      "& #tenant-setup-available-students-table": {
        maxHeight: "30rem",
        overflowY: "auto",
      },
      "& #tenant-setup-selected-students-table": {
        maxHeight: "30rem",
        overflowY: "auto",
      },
    },
    borderRadius: {
      borderRadius: "12px",
    },
    tabPanelGrid: {
      paddingTop: "20px",
    },
  });

const studentTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class AddStudent extends AddStudentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      selectedCurriculum,
      selectedGrade,
      studentsListAvailable,
      studentsListSelected,
    } = this.props;
    const { tabValue } = this.state;

    return (
      <Box padding={"0px 20px"} marginTop={"20px"}>
        <Box className={`${classes.secondaryBgColor} ${classes.borderRadius}`}>
          <Tabs
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                background: "none",
              },
            }}
            scrollButtons="auto"
            data-testid="tenant-setup-add-students-tabs"
            className={`${classes.tabs}`}
            value={tabValue}
            onChange={this.handleChange}
          >
            <Tab
              data-testid={`tenant-setup-available-students-tab`}
              wrapped
              className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
              {...a11yProps(0)}
              label={"Available Students"}
            />
            <Tab
              data-testid={`tenant-setup-selected-students-tab`}
              wrapped
              className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
              {...a11yProps(1)}
              label={"Selected Students"}
            />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Box>
              <Box
                data-test-id="available-tab-search-box"
                className={`${classes.searchBox}`}
              >
                <Box style={{ marginRight: "20px" }}>
                  <Typography className={classes.inputLabel}>
                    Curriculum
                  </Typography>
                  <CustomTextFieldWeb
                    fieldValue={selectedCurriculum}
                    fieldId="tenant-setup-available-students-curriculum"
                    fieldName="selectedCurriculum"
                    fieldType="text"
                    fieldWidth="100%"
                    isDisabled={true}
                  />
                </Box>
                <Box style={{ marginRight: "20px" }}>
                  <Typography className={classes.inputLabel}>Grade</Typography>
                  <CustomTextFieldWeb
                    fieldValue={selectedGrade}
                    fieldId="tenant-setup-available-students-grade"
                    fieldName="selectedGrade"
                    fieldType="text"
                    fieldWidth="100%"
                    isDisabled={true}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  height: "4px",
                  backgroundColor: "rgb(89 86 86 / 15%)",
                }}
              />
              <Box
                padding={"15px 20px"}
                data-test-id="tenant-setup-available-students-table-box"
                className={`${classes.studentTable}`}
              >
                <ThemeProvider theme={studentTableTheme}>
                  <CustomTable
                    tableHeaderColumns={studentDataColumn}
                    tableId={"tenant-setup-available-students-table"}
                    showMenuIcon={false}
                    tableBodyData={studentsListAvailable}
                  />
                </ThemeProvider>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box>
              <Box
                data-test-id="selected-tab-search-box"
                className={`${classes.searchBox}`}
              >
                <Box style={{ marginRight: "20px" }}>
                  <Typography className={classes.inputLabel}>
                    Curriculum
                  </Typography>
                  <CustomTextFieldWeb
                    fieldValue={selectedCurriculum}
                    fieldId="tenant-setup-selected-students-curriculum"
                    fieldType="text"
                    fieldName="selectedCurriculumSelected"
                    fieldWidth="100%"
                    isDisabled={true}
                  />
                </Box>
                <Box style={{ marginRight: "20px" }}>
                  <Typography className={classes.inputLabel}>Grade</Typography>
                  <CustomTextFieldWeb
                    fieldValue={selectedGrade}
                    fieldId="tenant-setup-selected-students-grade"
                    fieldType="text"
                    fieldName="selectedGradeSelected"
                    fieldWidth="100%"
                    isDisabled={true}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "rgb(89 86 86 / 15%)",
                  height: "4px",
                }}
              />
              <Box
                padding={"15px 20px"}
                data-test-id="tenant-setup-selected-students-table-box"
                className={`${classes.studentTable}`}
              >
                <ThemeProvider theme={studentTableTheme}>
                  <CustomTable
                    tableId={"tenant-setup-selected-students-table"}
                    tableHeaderColumns={studentDataColumn}
                    tableBodyData={studentsListSelected}
                    showMenuIcon={false}
                  />
                </ThemeProvider>
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddStudent);
// Customizable Area End
