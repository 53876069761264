// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedRequestedOnValue: string;
  selectedGradeValue: string;
  selectedSubjectValue: string;
  selectedAccpetedOnValue: string;
  selectedTitleValue: string;
  ApprovedRequestedOnDDDDComponentValue: Array<any>;
  ApprovedOnDDComponentValue: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherApprovedRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
        selectedAccpetedOnValue: "",
        selectedTitleValue: "",
        selectedRequestedOnValue: "",
        selectedGradeValue: "",
        selectedSubjectValue: "",
        ApprovedRequestedOnDDDDComponentValue:[
          {
            id: 1,
            label: "Ascending",
            value: 1,
          },
          {
            id: 2,
            label: "Descending",
            value: 2,
          },
        ],
        ApprovedOnDDComponentValue:[
          {
            id: 1,
            label: "Ascending",
            value: 1,
          },
          {
            id: 2,
            label: "Descending",
            value: 2,
          },
        ],
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  getApprovedDropdownItemsArray = (DDName: string) => {
    const refArray = [1, 2, 3, 4, 5];
    let DDItems: Array<any> = [];
    refArray.forEach((item) => {
      DDItems.push({
        id: item,
        label: `${DDName}${item}`,
        value: `${DDName}${item}`,
      });
    });
    return DDItems;
  };

  renderApprovedSubjectDropdownValue = (selected: any) => {
    const DDName = "Subject";
    const DDItems = this.getApprovedDropdownItemsArray("Subject");
    return this.getSelectedDDValue(selected, DDName, DDItems);
  };

  renderApprovedOnDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Ascending";
    }
    const { ApprovedOnDDComponentValue } = this.state;
    const selectedGradingComponents = ApprovedOnDDComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderApprovedGradeDropdownValue = (selected: any) => {
    const DDName = "Grade";
    const DDItems = this.getApprovedDropdownItemsArray("Grade");
    return this.getSelectedDDValue(selected, DDName, DDItems);
  };

  renderApprovedRequestedOnDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Ascending";
    }
    const { ApprovedRequestedOnDDDDComponentValue } = this.state;
    const selectedGradingComponents = ApprovedRequestedOnDDDDComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleApprovedRequestsSearch = () => {
    console.log("search");
  };

  renderApprovedTitleDropdownValue = (selected: any) => {
    const DDName = "Title";
    const DDItems = this.getApprovedDropdownItemsArray("Title");
    return this.getSelectedDDValue(selected, DDName, DDItems);
  };

  getSelectedDDValue = (selected: any, DDName: string, DDItems: any) => {
    if (!selected || selected.length === 0) {
      return `Select ${DDName}`;
    }
    const selctedOption = DDItems.find((item: any) => item?.value === selected);
    return selctedOption?.label;
  };

  handleChangeApprovedDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };
  // Customizable Area End
}
