Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.studentAssignActiviesFilterEndpoint="bx_block_dashboard/student_dashboard/assigned_activity_filter"
exports.studentTimeSpentEndpoint = "bx_block_dashboard/student_dashboard/time_spent"
exports.studentLessonEndpoint="bx_block_dashboard/student_dashboard/student_lessons"
exports.studentActivityProgressEndpoint ="bx_block_dashboard/student_dashboard/activity_progress"
exports.studentProgressAndPerformanceEndpoint="bx_block_dashboard/student_dashboard/progress_performance_by_course"
exports.studentAssignedActivityEndpoint = "bx_block_dashboard/student_dashboard/assigned_activity";
exports.studentCompletedActivityEndpoint ="bx_block_dashboard/student_dashboard/completed_activity"
exports.downloadCSVTeacherAssignment="bx_block_teacher_assignment/teacher_assignments/download_csv_template.csv"
exports.downloadCSVClassMangement="bx_block_class_management/class_managements/download_csv.csv"
exports.importCSVClassManagement="bx_block_class_management/class_managements/import_csv"
exports.importCSVTeacherAssignment="bx_block_teacher_assignment/teacher_assignments/import_csv"
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.teacherDashboardClassesEndpoint = "account_block/users/find_teacher_assign_classes";
exports.getTeacherCoursesEndpoint = "bx_block_profile/courses/get_teacher_courses_tab";
exports.teacherClassCourseStudentsApi = "account_block/users/get_class_based_students";
exports.teacherAssessmentEndpoint = "bx_block_activity/activities/assessments";
exports.gradingSystemListEndpoint = "bx_block_grade/grading_systems"
exports.apiMethodTypeAddDetail ="POST"
exports.apiMethodTypeDelete ="DELETE"
exports.apiMethodTypeUpdate="PUT"
exports.gradingComponentListEndpoint="bx_block_grade/grading_component_and_weightages"
exports.behavioursListEndpoint="bx_block_grade/behaviours"
exports.rulesEndpoint="bx_block_grade/grade_book_rules"
exports.GetDashboardStatisticsEndpoint="bx_block_school_year/school_years/dashboard"
exports.gradeBookTenantEndpoint="bx_block_grade/grade_books"
exports.gradebookTableEndpoint = "bx_block_cfviewreportcardofficialandunofficial/grade_book_templates/get_school_year_based_on_curriculum"
exports.curriculumEndpoint ="bx_block_grade/grade_book_rules/get_available_curriculum"
exports.reportCSVEndpoint ="bx_block_school_year/school_years/report.csv"
exports.templateEndpoint ="bx_block_cfviewreportcardofficialandunofficial/grade_book_templates"
// Customizable Area End