// Customizable Area Start
import React from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import ParentTeacherGradedAssessmentController, {
  Props,
} from "./ParentTeacherGradedAssessmentController.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import AddRemarkModal from "./AddRemarkModal.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerWidthAll: {
      height: "5px",
    },
    gradeMainContainer: {
      maxHeight: "100vh",
      overflowX: "auto",
      borderRadius: "0px 0px 10px 10px",
      padding: "0px 20px 15px 20px",
    },
    gradeBookTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }
    },
    tableParentGrid: {
      padding: "15px 20px",
    },
    titleImageBox: {
      width: '34px',
      borderRadius: '50%',
      height: '34px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    titleImage: {
      width: '34px',
      height: '34px',
    },
  });

const gradeSearchTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    MuiFormControl: {
      root: {
        justifyContent: "center",
        height: "35px",
        display: "flex",
        marginLeft: "24px",
        alignItems: "center",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class ParentTeacherGradedAssessment extends ParentTeacherGradedAssessmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      gradeSubjectTableColumns,
      gradeSubjectList,
      openAddRemarkModal,
      menuAnchorEl,
      editGrade,
      selectedRowId,
      selectedRowGradeValue
    } = this.state;

    return (
      <>
        <Box
          className={classes.bgColor}
          flexWrap={"wrap"}
          display={"flex"}
          py={3}
          px={3.5}
        >
          <Box alignItems={"center"} display={"flex"}>
            <Box alignItems={"center"} display={"flex"}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText14}`}
                  data-test-id="gradeText"
                  gutterBottom
                >
                  {`Grading Component`}
                </Typography>
                <CustomSelectDropdown
                  fieldName="selectedClassComponentDDValue"
                  fieldId="select-dropdown-assessment-grading-component"
                  handleChange={this.handleGradedDropdownValues}
                  fieldValue={this.state.selectedClassComponentDDValue}
                  renderValue={this.renderGradedComponenttDDValues}
                  fieldStyles={dropdownStudent}
                  fieldError={false}
                  fieldMenuItems={this.state.gradedAssessmentComponent}
                  iconComponent={
                    <DropdownIcon
                      height="24"
                      width="24"
                      stroke={"#888888"}
                      viewBox="0 0 24 24"
                      fill={"none"}
                      strokeWidth="1.5"
                    />
                  }
                />
              </Box>
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography
                  component={"span"}
                  className={`${classes.fontText14} ${classes.textCommonColor}`}
                  data-test-id={"SearchText"}
                  style={{ marginLeft: "24px" }}
                  gutterBottom
                >
                  {`Search`}
                </Typography>
                <ThemeProvider theme={gradeSearchTheme}>
                  <CustomTextField
                    fieldName="SearchStudents"
                    placeHolderText="Type here to search"
                    fieldValue={this.state.searchStudent}
                    fieldId="search-student"
                    fieldType="text"
                    fieldWidth={"200px"}
                    handleChange={this.handleSearchStudent}
                  />
                </ThemeProvider>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider className={classes.dividerWidthAll} />
        <Grid container>
          <Grid item xs={12} className={`${classes.tableParentGrid} ${classes.bgColor}`}>
            <Box className={`${classes.customTableStyles} ${classes.gradeBookTableRow}`}>
              <TableContainer className={classes.tableContainer} id="gradebook-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      {gradeSubjectTableColumns.map((column: any) => (
                        <TableCell
                          key={column.id}
                        >
                          <Box display={'flex'}>
                            <Typography className={`${classes.fontSize15} ${classes.textCommonColor}`} component={"span"}>
                              {column.label}
                            </Typography>
                            {column?.icon ? <img src={column?.icon} className={classes.columnIcon} /> : ''}
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gradeSubjectList?.length > 0 &&
                      gradeSubjectList.map((row: any, i: any) => {
                        return (
                          <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                            {gradeSubjectTableColumns.map((column: any) => {
                              return (
                                <TableCell
                                  className={`${classes.tableBodyCell}`}
                                  style={{
                                    maxWidth: "auto",
                                    maxHeight: "50px",
                                    minWidth: column.width,
                                  }}
                                  key={`${column.id}-${row.id}`}
                                >
                                  {column.id === "Title" ?
                                    <Box display="flex" alignItems="center">
                                      <Box mr={"10px"} className={`${classes.titleImageBox} ${classes.subjectLogoBg}`}>
                                        <img src={row[column.id]?.icon} className={classes.titleImage} />
                                      </Box>
                                      <Box>
                                        <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                          {row[column.id]?.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    :
                                    <Box>
                                      {
                                        column.id === "grade" && editGrade && selectedRowId === row.id ?
                                          <Box>
                                            <input type="text" style={{ width: "50px" }} onChange={this.handleGradeInputChange} onKeyPress={this.handleGradeInputEnterKey} value={selectedRowGradeValue} />
                                          </Box> :
                                          <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontSize15}`}>
                                            {row[column.id]}
                                          </Typography>
                                      }
                                    </Box>
                                  }
                                </TableCell>
                              );
                            })}
                            <TableCell align="right">
                              <IconButton
                                onClick={(e: any) => this.handleActionClick(e, row.id)}
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                aria-label="more"
                              >
                                <MoreVertIcon className={`${classes.textCommonColor}`} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                anchorEl={menuAnchorEl}
                id={`gradebook-table-menu-action`}
                onClose={this.handleMenuClose}
                open={Boolean(menuAnchorEl)}
              >
                <MenuItem onClick={this.handleEnableEditGrade}>
                  Edit Grade
                </MenuItem>
                <MenuItem onClick={this.handleOpenAddRemarkModal}>
                  Add Remark
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
        <AddRemarkModal
          open={openAddRemarkModal}
          onCancel={this.handleCloseAddRemarkModal}
          onConfirm={this.handleCloseAddRemarkModal}
          activityId={'1'}
          studentId={2}
          studentName={'Student'}
          maxScore={0}
          totalScore={0}
          handleSubmitGrade={() => { }}
        />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStudent = {
  borderRadius: "8px",
  height: "50px",
  width: "218px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentTeacherGradedAssessment);
// Customizable Area End
