// Customizable Area Start
import { SvgIcon } from "@material-ui/core";
import React from "react";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DownloadIcon: any = (props: any) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Page-1"
        stroke="none"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dashboard-Reports"
          transform="translate(-1300.000000, -443.000000)"
          fill={props.fill}
          fillRule="nonzero"
        >
          <g id="Group-4" transform="translate(340.000000, 160.000000)">
            <g id="content" transform="translate(0.000000, 102.000000)">
              <g id="Users" transform="translate(25.000000, 100.000000)">
                <g id="Group-26" transform="translate(25.000000, 25.000000)">
                  <g id="Group-5" transform="translate(835.000000, 54.000000)">
                    <g
                      id="download-icon"
                      transform="translate(75.000000, 2.000000)"
                    >
                      <path
                        d="M16,12.0909091 L16,9.54545455 C16,9.19400061 15.7015232,8.90909091 15.3333333,8.90909091 C14.9651435,8.90909091 14.6666667,9.19400061 14.6666667,9.54545455 L14.6666667,12.0909091 C14.6666667,12.442363 14.3681898,12.7272727 14,12.7272727 L2,12.7272727 C1.63181017,12.7272727 1.33333333,12.442363 1.33333333,12.0909091 L1.33333333,9.54545455 C1.33333333,9.19400061 1.0348565,8.90909091 0.666666667,8.90909091 C0.298476833,8.90909091 0,9.19400061 0,9.54545455 L0,12.0909091 C0,13.1452709 0.8954305,14 2,14 L14,14 C15.1045695,14 16,13.1452709 16,12.0909091 Z M11.7466667,8.76909091 L8.41333333,11.3145455 C8.17103683,11.4973076 7.82896317,11.4973076 7.58666667,11.3145455 L4.25333333,8.76909091 C4.00153171,8.54221222 3.97360851,8.16909375 4.18906767,7.91035321 C4.40452683,7.65161266 4.79163374,7.59339467 5.08,7.77636364 L7.33333333,9.49454545 L7.33333333,0.636363636 C7.33333333,0.284909705 7.63181017,0 8,0 C8.36818983,0 8.66666667,0.284909705 8.66666667,0.636363636 L8.66666667,9.49454545 L10.92,7.77636364 C11.1030502,7.61143147 11.3678141,7.55897766 11.6045065,7.64075236 C11.8411988,7.72252705 12.0094576,7.92458563 12.0395137,8.16314376 C12.0695698,8.4017019 11.9562979,8.63607944 11.7466667,8.76909091 L11.7466667,8.76909091 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default DownloadIcon;
// Customizable Area End
