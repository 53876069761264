// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  open: boolean;
  handleClose: () => void;
  handleAddNewSchoolYear: any;
  handleUpdateSchoolYear: any;
  isEdit: boolean;
  schoolYearDetails: any;
  // Customizable Area End
}

interface SemesterData {
  title: string;
  startDate: any;
  endDate: any;
  id: any;
  fieldError: boolean;
  fieldErrorMsg: string;
  endDateErrorMsg: string;
  endDateError: boolean;
  startDateErrorMsg: string;
  startDateError: boolean;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  title: string;
  titleError: boolean;
  titleErrorMsg: string;
  semesterData: Array<SemesterData>;
  selectGradePeriod: any;
  selectGradePeriodErrorMsg: string;
  selectGradePeriodError: boolean;
  gradePeriodTitle: string;
  gradePeriodTitleError: boolean;
  gradePeriodTitleErrorMsg: string;
  selectStartDate: any;
  startDateError: boolean;
  startDateErrorMsg: string;
  selectEndDate: any;
  endDateError: boolean;
  endDateErrorMsg: string;
  description: string;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewSchoolYearDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      semesterData: [],
      title: "",
      titleError: false,
      titleErrorMsg: "",
      selectGradePeriod: "",
      selectGradePeriodError: false,
      selectGradePeriodErrorMsg: "",
      gradePeriodTitle: "",
      gradePeriodTitleError: false,
      gradePeriodTitleErrorMsg: "",
      selectStartDate: null,
      selectEndDate: null,
      description: "",
      descriptionError: false,
      descriptionErrorMsg: "",
      endDateError: false,
      endDateErrorMsg: "",
      startDateError: false,
      startDateErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleValidateTitleDesc = () => {
    let isValid = true;
    const { title, description, gradePeriodTitle } = this.state;
    if (!title.trim()) {
      this.setState({ titleError: true, titleErrorMsg: "Please enter title" });
      isValid = false;
    }
    if (!description.trim()) {
      this.setState({
        descriptionError: true,
        descriptionErrorMsg: "Please enter description",
      });
      isValid = false;
    }
    if (!gradePeriodTitle.trim()) {
      this.setState({
        gradePeriodTitleError: true,
        gradePeriodTitleErrorMsg: "Please enter grade period title",
      });
      isValid = false;
    }
    return isValid;
  };

  handleValidateNumberOfGradePeriod = () => {
    const { selectGradePeriod } = this.state;
    let isValid = true;
    if (!selectGradePeriod) {
      this.setState({
        selectGradePeriodError: true,
        selectGradePeriodErrorMsg: "Please select number of grading period",
      });
      isValid = false;
    }
    return isValid;
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    if (name === "title") {
      this.setState({ titleError: false, titleErrorMsg: "" });
    }
    if (name === "description") {
      this.setState({ descriptionError: false, descriptionErrorMsg: "" });
    }
    if (name === "gradePeriodTitle") {
      this.setState({
        gradePeriodTitleError: false,
        gradePeriodTitleErrorMsg: "",
      });
    }
  };

  getDateRange = () => {
    const { selectGradePeriod, selectEndDate, selectStartDate, semesterData } =
      this.state;
    const updatedSemesterData = [...semesterData];
    let firstTermEndDate = null;
    let secondTermStartDate = null;
    let secondTermEndDate = null;
    let thirdTermStartDate = null;
    let thirdTermEndDate = null;
    let fourthTermStartDate = null;
    const daysDiff = moment(selectEndDate).diff(
      moment(selectStartDate),
      "days"
    );
    switch (selectGradePeriod) {
      case 1:
        {
          updatedSemesterData[0].startDate = selectStartDate || null;
          updatedSemesterData[0].endDate = selectEndDate || null;
        }
        break;
      case 2:
        {
          if (selectStartDate && selectEndDate && daysDiff >= 12) {
            const daysInterval = Math.floor(daysDiff / 2);
            firstTermEndDate = moment(selectStartDate).add(
              daysInterval,
              "days"
            );
            secondTermStartDate = moment(firstTermEndDate).add(1, "day");
          }
          updatedSemesterData[0].startDate = selectStartDate;
          updatedSemesterData[0].endDate =
            moment(firstTermEndDate).toLocaleString();
          updatedSemesterData[1].startDate =
            moment(secondTermStartDate).toLocaleString();
          updatedSemesterData[1].endDate = selectEndDate;
        }
        break;
      case 3:
        {
          if (selectStartDate && selectEndDate && daysDiff >= 12) {
            const daysInterval = Math.floor(daysDiff / 3);
            firstTermEndDate = moment(selectStartDate).add(
              daysInterval,
              "days"
            );
            secondTermStartDate = moment(firstTermEndDate).add(1, "day");
            secondTermEndDate = moment(secondTermStartDate).add(
              daysInterval,
              "days"
            );
            thirdTermStartDate = moment(secondTermEndDate).add(1, "day");
          }
          updatedSemesterData[0].startDate = selectStartDate;
          updatedSemesterData[0].endDate =
            moment(firstTermEndDate).toLocaleString();
          updatedSemesterData[1].startDate =
            moment(secondTermStartDate).toLocaleString();
          updatedSemesterData[1].endDate =
            moment(secondTermEndDate).toLocaleString();
          updatedSemesterData[2].startDate =
            moment(thirdTermStartDate).toLocaleString();
          updatedSemesterData[2].endDate = selectEndDate;
        }
        break;
      case 4:
        {
          if (selectStartDate && selectEndDate && daysDiff >= 12) {
            const daysInterval = Math.floor(daysDiff / 4);
            firstTermEndDate = moment(selectStartDate).add(
              daysInterval,
              "days"
            );
            secondTermStartDate = moment(firstTermEndDate).add(1, "day");
            secondTermEndDate = moment(secondTermStartDate).add(
              daysInterval,
              "days"
            );
            thirdTermStartDate = moment(secondTermEndDate).add(1, "day");
            thirdTermEndDate = moment(thirdTermStartDate).add(
              daysInterval,
              "days"
            );
            fourthTermStartDate = moment(thirdTermEndDate).add(1, "day");
          }
          updatedSemesterData[0].startDate = selectStartDate;
          updatedSemesterData[0].endDate =
            moment(firstTermEndDate).toLocaleString();
          updatedSemesterData[1].startDate =
            moment(secondTermStartDate).toLocaleString();
          updatedSemesterData[1].endDate =
            moment(secondTermEndDate).toLocaleString();
          updatedSemesterData[2].startDate =
            moment(thirdTermStartDate).toLocaleString();
          updatedSemesterData[2].endDate =
            moment(thirdTermEndDate).toLocaleString();
          updatedSemesterData[3].startDate =
            moment(fourthTermStartDate).toLocaleString();
          updatedSemesterData[3].endDate = selectEndDate;
        }
        break;
    }
    this.setState({ semesterData: updatedSemesterData });
  };

  handleSetStartDate = (date: any) => {
    const setDateFormat = moment(date).toLocaleString();
    const { selectEndDate, selectGradePeriod } = this.state;
    this.setState({
      startDateError: false,
      startDateErrorMsg: "",
      endDateError: false,
      endDateErrorMsg: "",
    });
    if (selectEndDate) {
      const daysDiff = moment(selectEndDate).diff(moment(date), "days", true);
      if (daysDiff < 12) {
        this.setState({
          startDateError: true,
          startDateErrorMsg:
            "Start Date should be at least 12 days before End Date",
        });
      } else if (moment(selectEndDate).isSameOrBefore(moment(date))) {
        this.setState({
          startDateError: true,
          startDateErrorMsg: "Start Date should not be after End Date",
        });
      }
    }
    this.setState({ selectStartDate: setDateFormat }, () => {
      if (selectGradePeriod) {
        this.getDateRange();
      }
    });
  };

  handleSetEndDate = (date: any) => {
    const setDateFormat = moment(date).toLocaleString();
    const { selectStartDate, selectGradePeriod } = this.state;
    this.setState({
      endDateError: false,
      endDateErrorMsg: "",
      startDateError: false,
      startDateErrorMsg: "",
    });
    if (selectStartDate) {
      const daysDiff = moment(date).diff(moment(selectStartDate), "days", true);
      if (daysDiff < 12) {
        this.setState({
          endDateError: true,
          endDateErrorMsg:
            "End Date should be at least 12 days after Start Date",
        });
      } else if (moment(date).isSameOrBefore(moment(selectStartDate))) {
        this.setState({
          endDateError: true,
          endDateErrorMsg: "End Date should not be before Start Date",
        });
      }
    }
    this.setState({ selectEndDate: setDateFormat }, () => {
      if (selectGradePeriod) {
        this.getDateRange();
      }
    });
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let val: any = value;
    if (val) {
      if (name === "selectGradePeriod") {
        this.setState({
          selectGradePeriodError: false,
          selectGradePeriodErrorMsg: "",
        });
      }
      this.setState(
        (prev) => {
          return {
            ...prev,
            [name]: val,
          };
        },
        () => {
          this.handleSemesterData(val);
        }
      );
    }
  };

  renderValueDDTest = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Value";
    }
    return selected;
  };

  handleSemesterData = (selectedGradePeriodNumber: number) => {
    const updatedSemesterData: Array<SemesterData> = [];
    const semesterItem: SemesterData = {
      title: "",
      endDate: null,
      startDate: null,
      id: "",
      fieldErrorMsg: "",
      fieldError: false,
      endDateErrorMsg: "",
      endDateError: false,
      startDateErrorMsg: "",
      startDateError: false,
    };
    [...Array(selectedGradePeriodNumber)].forEach((_, index) => {
      updatedSemesterData.push({ ...semesterItem, id: index + 1 });
    });
    this.setState({ semesterData: updatedSemesterData }, () => {
      this.getDateRange();
    });
  };

  handleUpdateSemesterTitle = (index: number, value: string) => {
    const { semesterData } = this.state;
    let updatedSemesterData = [...semesterData];
    updatedSemesterData.forEach((item, itemIndex) => {
      if (index === itemIndex) {
        item.title = value;
        item.fieldError = false;
        item.fieldErrorMsg = "";
      }
    });
    this.setState({ semesterData: updatedSemesterData });
  };

  handleValidateSemesterData = () => {
    const { semesterData } = this.state;
    let isValid = true;
    const updatedSemesterData = [...semesterData];
    updatedSemesterData.forEach((item) => {
      if (!item?.title?.trim()) {
        item.fieldError = true;
        item.fieldErrorMsg = "Please enter title";
        isValid = false;
      }
      if (!item.startDate) {
        item.startDateError = true;
        item.startDateErrorMsg = "Please select the start date and date above";
        isValid = false;
      }
      if (!item.endDate) {
        item.endDateError = true;
        item.endDateErrorMsg = "Please select the start date and date above";
        isValid = false;
      }
    });
    this.setState({ semesterData: updatedSemesterData });
    return isValid;
  };

  handleValidateDates = () => {
    const { selectEndDate, selectStartDate } = this.state;
    let isValid = true;
    if (!selectEndDate) {
      this.setState({
        endDateError: true,
        endDateErrorMsg: "Please select End date",
      });
      isValid = false;
    }
    if (!selectStartDate) {
      this.setState({
        startDateError: true,
        startDateErrorMsg: "Please select Start date",
      });
      isValid = false;
    }
    if (selectStartDate && selectEndDate) {
      const dayDiff = moment(selectEndDate).diff(
        moment(selectStartDate),
        "days"
      );
      if (dayDiff < 12) {
        this.setState({
          endDateError: true,
          endDateErrorMsg:
            "End Date should be at least 12 days after Start Date",
        });
        isValid = false;
      }
    }
    return isValid;
  };

  handleAddNewSchoolYear = () => {
    if (
      this.handleValidateTitleDesc() &&
      this.handleValidateNumberOfGradePeriod() &&
      this.handleValidateDates() &&
      this.handleValidateSemesterData()
    ) {
      const {
        title,
        selectEndDate,
        selectStartDate,
        selectGradePeriod,
        description,
        gradePeriodTitle,
        semesterData,
      } = this.state;
      const { isEdit } = this.props;

      const semesters_attributes = semesterData.map((item) => {
        const semesterObj: any = {
          title: item.title,
          start_date: moment(item.startDate).format("DD/MM/YYYY"),
          end_date: moment(item.endDate).format("DD/MM/YYYY"),
          _destroy: 1,
        }
        if(item?.id){
          semesterObj.id = item.id
        }
        return semesterObj;
      });
      const requestBody = {
        data: {
          attributes: {
            title,
            description,
            number_of_grading: parseInt(selectGradePeriod),
            grade_period_title: gradePeriodTitle,
            start_date: moment(selectStartDate).format("DD/MM/YYYY"),
            end_date: moment(selectEndDate).format("DD/MM/YYYY"),
            semesters_attributes,
            status: 0,
          },
        },
      };
      if (isEdit) {
        this.props.handleUpdateSchoolYear(requestBody);
      } else {
        this.props.handleAddNewSchoolYear(requestBody);
      }
    }
  };

  handleSetInitialState = (isEdit?: boolean) => {
    if (isEdit) {
      const { schoolYearDetails } = this.props;
      this.setState({
        semesterData: schoolYearDetails?.semestersData || [],
        title: schoolYearDetails?.title,
        titleError: false,
        titleErrorMsg: "",
        selectGradePeriod: schoolYearDetails?.noOfGradingPeriod,
        selectGradePeriodError: false,
        selectGradePeriodErrorMsg: "",
        gradePeriodTitle: schoolYearDetails?.gradePeriodTitle,
        gradePeriodTitleError: false,
        gradePeriodTitleErrorMsg: "",
        selectStartDate: schoolYearDetails?.startDate,
        selectEndDate: schoolYearDetails?.endDate,
        description: schoolYearDetails?.description,
        descriptionError: false,
        descriptionErrorMsg: "",
        endDateError: false,
        endDateErrorMsg: "",
        startDateError: false,
        startDateErrorMsg: "",
      });
    } else {
      this.setState({
        loading: false,
        semesterData: [],
        title: "",
        titleError: false,
        titleErrorMsg: "",
        selectGradePeriod: "",
        selectGradePeriodError: false,
        selectGradePeriodErrorMsg: "",
        gradePeriodTitle: "",
        gradePeriodTitleError: false,
        gradePeriodTitleErrorMsg: "",
        selectStartDate: null,
        selectEndDate: null,
        description: "",
        descriptionError: false,
        descriptionErrorMsg: "",
        endDateError: false,
        endDateErrorMsg: "",
        startDateError: false,
        startDateErrorMsg: "",
      }, () => {
        this.props.handleClose();
      });
    }
  };

  handleClosePopup = () => {
    this.handleSetInitialState();
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { isEdit } = this.props;
    if (isEdit) this.handleSetInitialState(true);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
