// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const assessmentCategoryTabs = [
  {
    id:1,
    label:"Due",
    value:"due"
  },
  {
    id:2,
    label:"Completed",
    value:"completed"
  },
  {
    id:3,
    label:"Graded",
    value:"graded"
  }
]
export interface IDropdownItems {
  id:any;
  label:string;
  value:any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  studentAssessmentsList: any;
  subjectsList:any;
  gradingComponent: Array<any>;
  selectedAssessmentCategoryTab:number;
  subjectDDMenuItems:Array<IDropdownItems>;
  selectedSubjectDDValue:any;
  filterSubjectId:any;
  filterGradingComponent:any;
  gradingComponentDDMenuItems:Array<IDropdownItems>;
  selectedGradingComponentDDValue:any;
  paginationDueList: any;
  gradeList: Array<IDropdownItems>;
  selectedGrade:any;
  sortByList: Array<IDropdownItems>;
  selectedSortBy:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyAssessmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiGetStudentSubjectList:string="";
  apiGetStudentAssessmentsList:string="";
  apiGetGradePeriodList:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      studentAssessmentsList: [],
      subjectsList:[],
      gradingComponent: [
        {
          id: 1,
          label: "Quiz",
          value: "quiz",
        },
        {
          id: 2,
          label: "Project",
          value: "project",
        },
        {
          id: 3,
          label: "Exam",
          value: "exam",
        },
        {
          id: 4,
          label: "Assignment",
          value: "assignment",
        },
      ],
      selectedAssessmentCategoryTab:0,
      subjectDDMenuItems:[],
      selectedSubjectDDValue:"",
      filterSubjectId:"",
      filterGradingComponent:"",
      gradingComponentDDMenuItems:[],
      selectedGradingComponentDDValue:"",
      paginationDueList: {},
      gradeList: [
        {
          id: 1,
          label: "Grade 1",
          value: 1,
        },
        {
          id: 2,
          label: "Grade 2",
          value: 2,
        },
        {
          id: 3,
          label: "Grade 3",
          value: 3,
        },
        {
          id: 3,
          label: "Grade 4",
          value: 4,
        },
      ],
      selectedGrade:"",
      sortByList: [
        {
          id: "Date",
          label: "Date",
          value: "Date",
        },
        {
          id: "Grade",
          label: "Grade",
          value: "Grade",
        }
      ],
      selectedSortBy:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
   
    this.handleGetStudentsSubjectListApi()
    this.handleGetGradePeriodList()
    
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetSubjectDDItems = () => {
    const { subjectsList } = this.state;
    const subjectDDMenuItems : Array<IDropdownItems> = subjectsList?.map((subject : any) => {
      return { id : subject?.id , value : subject?.id , label : subject?.subjectName }
    });
    this.setState({subjectDDMenuItems});
  }

  handleChangeAssessmentCategoryTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({selectedAssessmentCategoryTab : newValue},()=>{
      this.handleGetStudentsAssessmentsApi({})
    });
    
  };

  renderSubjectDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { subjectDDMenuItems } = this.state;
    const selctedSubject = subjectDDMenuItems.find((subject : any) => subject?.id === selected);
    return selctedSubject?.label;
  };

  renderGradingComponenttDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingComponent } = this.state; 
   const selectedGradingComponent = gradingComponent.find((grade : any) => grade?.value === selected);
    return selectedGradingComponent?.label;
  };

  handleChangeDropdownValue = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue : any = value ;
    if(fieldValue){
      this.setState((prev) => {
        return {
            ...prev,
            [name] : fieldValue,
        };
     });
    }
  };
  handleGetStudentsSubjectListApi = async () => {
    showCustomLoader();
    let requestUrl = `${configJSON.studentCoursesEndpoint}?per_page=100&page=1`
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentSubjectList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleSetSubjectList=(courseList: any[])=>{
    const subjectSet = new Set();
    const subjectsList = courseList.reduce((list: { id: any; subjectName: any; }[], course: { attributes: { subject: any; }; }) => {
      const subject = course.attributes.subject;
      if (!subjectSet.has(subject.name)) {
        list.push({
          id: subject.id,
          subjectName: subject.name.trim()
        });
        subjectSet.add(subject.name);
      }
      return list;
    }, []);
    this.setState({subjectsList:subjectsList},()=>{
      this.handleSetSubjectDDItems();
      this.handleGetStudentsAssessmentsApi({})
    })
  }
  handleSetAssessmentsList=(assessmentList: any[],pagination:any)=>{
    const studentAssessmentsList = assessmentList.map((assessment, index) => {
      const { attributes } = assessment;
      return {
        id: attributes.id, 
        courseName: attributes.course_name,
        subjectIcon: attributes.subject.icon,
        subjectBgColor: attributes.subject.color,
        subjectId: attributes.subject.id,
        points: attributes.total_marks_activity,
        topicName: attributes.topic_name,
        dueDate: attributes?.due_date ? moment(attributes.due_date).format('DD/MM/YYYY') : "N/A", 
        assignedDate: attributes.assigned_date? moment(attributes.assigned_date).format('DD/MM/YYYY'):"N/A", 
        submittedDate: attributes.submit_date_activity ? moment(attributes.submit_date_activity).format('DD/MM/YYYY') : "N/A",
        score: attributes.marks_recived || 0, 
        typeOfAssessment: attributes.grading_component,
        objectiveType:attributes.objective_type,
        evaluationType:attributes.evolution_type,
        assessmentTitle: attributes.activities_title,
        GradeOn:attributes?.graded_on ? moment(attributes.graded_on).format('DD/MM/YYYY') : "N/A", 
      };
    });
    this.setState({studentAssessmentsList:studentAssessmentsList,paginationDueList: pagination})
  }
  handleGetStudentsSubjectListResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetSubjectList(responseJson.data);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  handleGetStudentsAssessmentsApi = async (pagination:any) => {
    showCustomLoader();
    const {selectedAssessmentCategoryTab} = this.state
    let requestUrl = `${configJSON.studentsAssessmentsEndpoint}`
    const page = pagination.page ? pagination.page : 1;
    if(selectedAssessmentCategoryTab === 2){
      requestUrl += `?activity_type=graded_activity&per_page=9&page=${page}`

    }else if(selectedAssessmentCategoryTab === 1){
      requestUrl += `?activity_type=completed_activity&per_page=9&page=${page}`
    }else{
      requestUrl += `?activity_type=due_activity&per_page=9&page=${page}`
    }
    if(this.state.filterSubjectId){
      requestUrl += `&subject_id=${this.state.filterSubjectId}`
    }
    if(this.state.filterGradingComponent){
      requestUrl += `&grading_component=${this.state.filterGradingComponent}`
    }
    if(selectedAssessmentCategoryTab === 2){
      if(this.state.selectedSortBy){
        requestUrl += `&sort_by=${this.state.selectedSortBy}`
      }
      if(this.state.selectedGrade){
        requestUrl += `&semester_id=${this.state.selectedGrade}`
      }
     
    }
  
    const token = await getStorageData("token");
    const header = {
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentAssessmentsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetStudentsAssessmentsResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetAssessmentsList(responseJson.data,responseJson?.meta?.pagination);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }; 
  handleSearchAssessment = () => {
    this.setState({ 
      filterSubjectId:this.state.selectedSubjectDDValue,
      filterGradingComponent: this.state.selectedGradingComponentDDValue
    },()=>{
      this.handleGetStudentsAssessmentsApi({})
    })
  }
  handlePaginationChange = (event: any, page: number) => {
    this.handleGetStudentsAssessmentsApi({ page: page });
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetStudentSubjectList:
            this.handleGetStudentsSubjectListResponse(
              responseJson,
              errorResponse
            );
            break;
          case this.apiGetStudentAssessmentsList:
            this.handleGetStudentsAssessmentsResponse(responseJson,errorResponse);
            break;
          case this.apiGetGradePeriodList:{
            this.handleGetGradePeriodDataResponse(
              responseJson
            )
          }
        }
      }
    
      // Customizable Area Start

      // Customizable Area End
    }
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
       
      const {tabOpen} = sessionData
      console.log("session data here",tabOpen)
      this.setState({selectedAssessmentCategoryTab:tabOpen})
      }
      
    }


  }
  renderSortDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { sortByList } = this.state;
    const selectedSortBy = sortByList.find((sort : any) => sort?.id === selected);
    return selectedSortBy?.label;
  };

  renderGradeDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradeList } = this.state;
    const selectedGrade = gradeList.find((subject : any) => subject?.id === selected);
    return selectedGrade?.label;
  };

  handleSearchGradedAssessment = () => {
    console.log("search graded assessment 2" , this.state.selectedGrade , this.state.selectedSortBy);
    this.handleGetStudentsAssessmentsApi({})
  };


  handleChangeDropdownValueGradedAssessment = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue : any = value ;
    if(fieldValue){
      this.setState((prev) => {
        return {
            ...prev,
            [name] : fieldValue,
        };
     });
    }
  };

  handleGetGradePeriodList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradePeriodList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherSchoolYear}?assessment_graded_tab=true `
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetGradePeriodDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        title: string;
        semesters: Array<{
          id: number;
          title: string;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const gradeList = responseJson.data[0].attributes.semesters.map((semester, index) => ({
        id: semester.id,
        label: semester.title.trim(),
        value: semester.id
      }));
      this.setState({
        gradeList
      })
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };
  // Customizable Area End
}
// Customizable Area End
