import React from "react";
import {
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Chart as ChartJS,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CustomDoughnutChartController, {
  Props,
} from "./CustomDoughnutChartController.web";

ChartJS.register(
  ArcElement,
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export class CustomDoughnutChart extends CustomDoughnutChartController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { labels, graphData, chartColors, graphCenterValue } = this.props;
    const data = {
      datasets: [
        {
          data: [...graphData],
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors,
        },
      ],
      labels: [...labels],
    };
    const centerDoughnutPlugin = {
      id: "annotateDoughnutCenter",
      beforeDraw: (chart: any) => {
        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;

        ctx.restore();
        ctx.font = 2 + "em sans-serif";
        ctx.textBaseline = "middle";

        let text = "75%";
        let textX = Math.round((width - ctx.measureText(text).width) / 2);
        let textY = Math.round((height - ctx.measureText(text).height) / 2);

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    };

    const getCenterTextColor = () => {
      const isDarkTheme =
        localStorage.getItem("dark_mode") || JSON.stringify(false);
      return JSON.parse(isDarkTheme) ? "#ffffff" : "#090909";
    };

    const options: any = {
      plugins: {
        legend: {
          display: true,
          position: this.props.chartPosition ? this.props.chartPosition :  "right",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 25,
            color: getCenterTextColor(),
            font: {
              weight: "bold",
              size: 18,
            },
          },
        },
        datalabels: {
          color: "#ffffff",
          clamp: true,
          borderWidth: 0,
          padding: 0,
          font: {
            weight: "bold",
            size: this.props.lableFontSize ? this.props.lableFontSize : 16,
          },
          formatter: function(value: any) {
            if (value < 2) {
              return "";
            } else {
              return Math.round(value) + "%";
            }
          },
        },
      },
      responsive: true,
      cutout: "80%",
      maintainAspectRatio: true,
      animation: {
        animateScale: true,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
        line: {
          fill: false,
        },
        point: {
          hoverRadius: 7,
          radius: 5,
        },
      },
    };

    const textCenter = {
      id: "textCenter",
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        ctx.restore();
        ctx.font = "bold " + 4 + "em sans-serif";
        ctx.textAlign = "center";
        ctx.fillStyle = getCenterTextColor();
        ctx.textBaseline = "middle";
        ctx.fillText(
          `${graphCenterValue || ""}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
        ctx.save();
      },
    };

    return (
      <Doughnut
        plugins={[centerDoughnutPlugin, textCenter]}
        data={data}
        options={options}
      />
    );
  }
}

export default CustomDoughnutChart;
