// Customizable Area Start
import React from "react";
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CustomCalendarIcon: any = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9553 24H3.2086C1.45305 24 0.0263672 22.5733 0.0263672 20.8178V4.95998C0.0263672 3.20442 1.45305 1.77774 3.2086 1.77774H4.4708V0.888872C4.4708 0.399978 4.87078 0 5.35968 0C5.84857 0 6.24862 0.399978 6.24862 0.888872V1.77774H16.9152V0.888872C16.9152 0.399978 17.3152 0 17.8041 0C18.293 0 18.6931 0.399978 18.6931 0.888872V1.77774H19.9553C21.7108 1.77774 23.1375 3.20442 23.1375 4.95998V20.8178C23.1375 22.5733 21.7108 24 19.9553 24ZM3.2086 3.55556C2.43529 3.55556 1.80419 4.18667 1.80419 4.95998V20.8178C1.80419 21.5911 2.43529 22.2222 3.2086 22.2222H19.9552C20.7285 22.2222 21.3596 21.5911 21.3596 20.8178V4.95998C21.3596 4.18667 20.7285 3.55556 19.9552 3.55556H18.693V4.44444C18.693 4.93333 18.293 5.33331 17.8041 5.33331C17.3152 5.33331 16.9152 4.93333 16.9152 4.44444V3.55556H6.24855V4.44444C6.24855 4.93333 5.84857 5.33331 5.35968 5.33331C4.87078 5.33331 4.4708 4.93333 4.4708 4.44444V3.55556H3.2086Z"
        fill={props.fill}
      />
      <circle cx="6.47095" cy="9.11109" r="1.11109" fill={props.fill} />
      <circle cx="11.3596" cy="9.11109" r="1.11109" fill={props.fill} />
      <circle cx="16.2488" cy="9.11109" r="1.11109" fill={props.fill} />
      <circle cx="6.47095" cy="13.5554" r="1.11109" fill={props.fill} />
      <circle cx="11.3596" cy="13.5554" r="1.11109" fill={props.fill} />
      <circle cx="16.2488" cy="13.5554" r="1.11109" fill={props.fill} />
      <circle cx="6.47095" cy="17.9998" r="1.11109" fill={props.fill} />
      <circle cx="11.3596" cy="17.9998" r="1.11109" fill={props.fill} />
      <circle cx="16.2488" cy="17.9998" r="1.11109" fill={props.fill} />
    </SvgIcon>
  );
};

export default CustomCalendarIcon;
// Customizable Area End
