// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import TeacherAssignCourseModalController, {
  Props,
} from "./TeacherAssignCourseModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    assignCourseDailoqBox: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "100%",
        minHeight: "500px",
        height: "max-content",
        overflowY: "hidden",
        overflowX: "hidden",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherAssignCourseModal extends TeacherAssignCourseModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <Dialog
        className={`${classes.highlightBgColor} ${classes.assignCourseDailoqBox}`}
        aria-labelledby="assign-course-student-confirm-dialog-title"
        open={open}
      >
        <Box
          padding={"30px"}
          className={`${classes.bgColor}`}
          data-test-id="ModalCourse"
        >
          <Grid direction="row" container>
            <Grid xs={12} item>
              <Typography
                className={`${classes.fontText24} ${classes.textPrimaryColor} ${classes.fontBold600}`}
                component={"span"}
                data-test-id="ModalCourseText"
              >
                Assign Course
              </Typography>
              <IconButton
                data-test-id="CrossIcon"
                style={{ float: "right" }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.props.onCancel}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent
            style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
            data-test-id="ModalCourseContent"
          >
            <Grid container spacing={2} direction="row">
              <Grid xs={6} item>
                <Box>
                  <Typography
                    component={"span"}
                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                    data-test-id="CourseTitles"
                  >
                    Course Title
                  </Typography>
                  <Box mt={"10px"}>
                    <CustomSelectDropdown
                      fieldId="courseCustomFieldDropdown"
                      handleChange={this.handleDropdownChange}
                      fieldName="selectCourseTitleValue"
                      fieldError={false}
                      fieldValue={this.state.selectCourseTitleValue}
                      renderValue={this.renderCourseTitleDDValues}
                      fieldMenuItems={this.state.CourseTitlevalues}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                      fieldStyles={dropdownStyles}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box
                  marginBottom={"10px"}
                  width={"100%"}
                  data-test-id="CatalogDD"
                >
                  <Typography
                    component={"span"}
                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                    data-test-id="CatalogHeader"
                  >
                    Catalog
                  </Typography>
                  <CustomTextField
                    placeHolderText="Catalog 10"
                    fieldName="entertopiccount"
                    fieldId="topic__count"
                    textFieldStyles={catalogFieldStyle}
                    fieldWidth={"100%"}
                    fieldType="text"
                    isDisabled
                  />
                </Box>
              </Grid>
              <Grid xs={6} item data-test-id="SubjectDDgrid">
                <Box marginBottom={"10px"} width={"100%"}>
                  <Typography
                    component={"span"}
                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                    data-test-id="SubjectHeader"
                  >
                    Subject
                  </Typography>
                  <CustomTextField
                    placeHolderText="Science"
                    fieldName="entertopiccount"
                    fieldId="topic__count"
                    textFieldStyles={textfieldStyles}
                    fieldWidth={"100%"}
                    fieldType="text"
                    isDisabled
                  />
                </Box>
              </Grid>
              <Grid xs={6} item data-test-id="TeacherTextFiled">
                <Box marginBottom={"10px"} width={"100%"}>
                  <Typography
                    component={"span"}
                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                    data-test-id="TeacherTextFiledHeader"
                  >
                    Teacher
                  </Typography>
                  <CustomTextField
                    placeHolderText="Antonin Hafer"
                    fieldName="entertopiccount"
                    fieldId="topic__count"
                    textFieldStyles={catalogFieldStyle}
                    fieldWidth={"100%"}
                    fieldType="text"
                    isDisabled
                  />
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box width={"100%"} data-test-id="StudentBox">
                  <Typography
                    component={"span"}
                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                    data-test-id="StudentBoxHeader"
                  >
                    Student
                  </Typography>
                  <Box mt={"10px"}>
                    <CustomSelectDropdown
                      fieldId="courseCustomFieldDropdown"
                      handleChange={this.handleDropdownChange}
                      fieldName="selectStudentValue"
                      fieldError={false}
                      fieldValue={this.state.selectStudentValue}
                      renderValue={this.renderStudentsDDValues}
                      fieldMenuItems={this.state.studentsDDvalues}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                      fieldStyles={dropdownStyles}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box mb={"10px"} style={{ width: "100%" }}>
                  <CustomButton
                    btnText="Assign"
                    btnStyle={assigntCourseBtnStyle}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleAssignCourse}
                    isDisabled={false}
                  />
                  <CustomButton
                    btnText="Cancel"
                    btnStyle={assignedCourseCancelBtn}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"outlined"}
                    type={"button"}
                    handleButtonClick={this.props.onCancel}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;

const catalogFieldStyle = {
  width: "99%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;

const assignedCourseCancelBtn = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const assigntCourseBtnStyle = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const dropdownStyles = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAssignCourseModal);
// Customizable Area End
