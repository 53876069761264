import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface ISummaryProps {
    summaryHeading:string,
    summaryType?:string;
}

interface IProps {
    navigation:any,
    classes:any,
    children?:any,
    panelId:string,
    keyValue:any;
    expanded:boolean;
    summaryDetail?:ISummaryProps;
    summaryComponent?:any;
    handleSelectItem:any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      width: '100%',
      display:"flex",
      flexDirection:"column",
      padding:"8px 0px"
    },
    summaryType:{
        marginRight:"8px"
    }
  }),
);

const CustomAccordion : React.FC<IProps> = (props) => {
  const { classes , summaryDetail , summaryComponent , children , panelId , keyValue , expanded , handleSelectItem } = props;
  const newClasses = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    handleSelectItem();
  };

  return (
      <Accordion key={keyValue} expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          className={classes.secondaryBgColor}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${panelId}-content`}
          id={panelId}
        >
          {!summaryComponent ? <Box>
            {summaryDetail?.summaryType && <Typography className={`${classes.textPrimaryColor} ${classes.fontText16} ${newClasses.summaryType}`}>{`${summaryDetail?.summaryType} - `}</Typography>}
            <Typography className={`${classes.textPrimaryColor} ${classes.fontText16}`}>{summaryDetail?.summaryHeading}</Typography>
          </Box> :
          <Box width={"100%"}>
            {summaryComponent}
          </Box>
          }
        </AccordionSummary>
        <AccordionDetails className={`${classes.bgColor} ${newClasses.accordionDetails}`}>
          {children}
        </AccordionDetails>
      </Accordion>
  );
}

export default CustomAccordion;