// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start

export interface IJoditEditorProps {

    readonly?: boolean;
    toolbar?: boolean;
    spellcheck?: boolean;
    language?: string;
    toolbarButtonSize?: "middle" | "large" | "small" | "tiny" | "xsmall";
    toolbarAdaptive?: boolean;
    showCharsCounter?: boolean;
    showWordsCounter?: boolean;
    showXPathInStatusbar?: boolean;
    askBeforePasteHTML?: boolean;
    askBeforePasteFromWord?: boolean;
    defaultActionOnPaste:any;
    buttons?: string[];
    uploader?: {
      insertImageAsBase64URI?: boolean;
    };
    width?: string | number;
    height?: string | number;
}
export interface CustomJoditEditorProps extends IJoditEditorProps {
  placeholder?: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  editorContent: any;
  editorError?: boolean;
  editorId: string;
  placeHolderText: string;
  onWysEditorStateChange: any;
  helperText?: string;
  customHeight?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  content: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomTextEditorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      content: this.props.editorContent || "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props) {
    // Check if editorContent prop has changed
    if (prevProps.editorContent !== this.props.editorContent) {
      this.handleEditorContentUpdate();
    }
  }
  handleEditorContentUpdate() {
    const { editorContent } = this.props;
    this.setState({ content: editorContent });
  }

  _uploadImageCallBack(file: any) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = [];

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);
    console.log(uploadedImages);

    return Promise.resolve({ data: { link: imageObject.localSrc } });
  }

  handleChangeEditorContent = (content: string) => {
    this.setState({ content }, () => {
      this.props.onWysEditorStateChange(this.state.content);
    });
  };
  handleEditorContentOnLoad = (evt: any) => {
    const { editorContent }= this.props;
    evt.editor.setData(editorContent);
    evt.editor.updateElement();
    this.setState({ content: editorContent });
  };      
  // Customizable Area End
}
