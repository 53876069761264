import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export const myRequestsTab = [
  {
    id: 1,
    label: "Pending Requests",
    value: "PendingRequests",
  },
  {
    id: 2,
    label: "Approved Requests",
    value: "ApprovedRequests",
  },
  {
    id: 3,
    label: "Rejected Requests",
    value: "RejectedRequests",
  },
];

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  selectedRequestTabIndex: number;
  selectedTabName: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TeacherMyRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      selectedRequestTabIndex: 0,
      selectedTabName: "Pending Requests",
      // Customizable Area Start
      // Customizable Area End
    };
    // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    localStorage.setItem("role", "teacher");
    super.componentDidMount();
  }

  handleGoBack = () => {
    this.props.navigation.navigate("CourseManagementParent");
  };

  handleRequestTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedRequestTabIndex: newValue });
    if (newValue === 0) {
      this.setState({ selectedTabName: "Pending Requests" });
    } else if (newValue === 1) {
      this.setState({ selectedTabName: "Approved Requests" });
    } else if (newValue === 2) {
      this.setState({ selectedTabName: "Rejected Requests" });
    }
  };
  // Customizable Area End
}
