// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { mockdata } from "./assets";
import ArticleActivityTaskBox from "./ArticleActivityTaskBox.web";
import QuizActivityComponent from "./QuizActivityComponent.web";
import ExamActivityComponent from "./ExamActivityComponent.web";
import AssignmentActivityComponent from "./AssignmentActivityComponent.web";
import ProjectActivityComponent from "./ProjectActivityComponent.web";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  currentLessonActivityList: any;
  selectedCourseSubject: any;
  selectedCourseId: any;
  selectedTopic: any;
  selectedTopicId: any;
  selectedLesson: any;
  selectedtLessonId: any;
  selectedActivity: any;
  selectedActivityId: any;
  selectedActivityTaskDetail: any;
  selectedActivityTaskDetailId: any;
  searchTopic: "";
  searchLesson: "";
  searchActivity: "";
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class JourneyCourseActivityDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseActivityJourneyDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      currentLessonActivityList: mockdata.currentLessonActivityList,
      selectedCourseId: null,
      selectedCourseSubject: null,
      selectedtLessonId: null,
      selectedLesson: null,
      selectedTopicId: null,
      selectedTopic: null,
      selectedActivityId: null,
      selectedActivity: null,
      selectedActivityTaskDetailId: null,
      selectedActivityTaskDetail: null,
      searchLesson: "",
      searchActivity: "",
      searchTopic: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSelectActivityTask(1);
    // Customizable Area End
  }

  async componentWillUnmount() {
    const {
      currentLessonActivityList,
      selectedActivityTaskDetailId,
      selectedActivityTaskDetail,
    } = this.state;
    const updatedCurrentLessonActivityList = currentLessonActivityList.map(
      (task: any) => {
        if (
          task.id === selectedActivityTaskDetailId &&
          (task.typeOfActivity === "audio" || task.typeOfActivity === "video")
        ) {
          task.mediaProgress = selectedActivityTaskDetail?.mediaProgress;
        }
        return task;
      }
    );
    this.setState({
      currentLessonActivityList: updatedCurrentLessonActivityList,
    });
  }

  handleSetSelectedActivityTask = () => {
    const { currentLessonActivityList } = this.state;
    this.setState({
      selectedActivityTaskDetail: currentLessonActivityList?.[0],
      selectedActivityTaskDetailId: currentLessonActivityList?.[0]?.id,
    });
  };

  handleGoBack = () => {
    this.props.navigation?.goBack();
  };

  handleSelectActivityTask = (activityTaskId: any) => {
    const {
      selectedActivityTaskDetailId,
      currentLessonActivityList,
      selectedActivityTaskDetail,
    } = this.state;
    const updatedCurrentLessonActivityList = currentLessonActivityList.map(
      (task: any) => {
        if (
          task.id === selectedActivityTaskDetailId &&
          (task.typeOfActivity === "audio" || task.typeOfActivity === "video")
        ) {
          task.mediaProgress = selectedActivityTaskDetail?.mediaProgress;
        }
        return task;
      }
    );
    const currentSelectedActivityTaskDetail: any = currentLessonActivityList.find((task: any) => task.id === activityTaskId);
    this.setState({
      selectedActivityTaskDetailId: activityTaskId,
      currentLessonActivityList: updatedCurrentLessonActivityList,
      selectedActivityTaskDetail: currentSelectedActivityTaskDetail,
    }, () => {
      this.handleExpandCurrentActivity(activityTaskId);
    });
  };

  handleGoBackToMyCourse = () => {
    this.props.navigation?.navigate("StudentMyCourses");
  };

  handleMarkActivityCompleted = () => {
    const { selectedActivityTaskDetail, currentLessonActivityList } =
      this.state;
    const updatedActivityCompletionProgress =
      selectedActivityTaskDetail?.activityCompletionProgress === 100 ? 0 : 100;
    const updatedActivity = {
      ...selectedActivityTaskDetail,
      activityCompletionProgress: updatedActivityCompletionProgress,
    };
    const updatedActivitiesList = currentLessonActivityList.map(
      (activity: any) => {
        if (activity.id === selectedActivityTaskDetail.id) {
          activity.activityCompletionProgress =
            updatedActivityCompletionProgress;
        }
        return activity;
      }
    );
    this.setState({
      currentLessonActivityList: updatedActivitiesList,
      selectedActivityTaskDetail: updatedActivity,
    });
  };

  handleExpandCurrentActivity = (itemId: any) => {
    const { currentLessonActivityList } = this.state;
    const updatedActivityList = currentLessonActivityList?.map((item: any) => {
      if (item.id === itemId) {
        item.expanded = !item?.expanded;
      } else {
        item.expanded = false;
      }
      return item;
    });
    this.setState({ currentLessonActivityList: updatedActivityList });
  };

  handleUpdateAudioProgressTime = (timeUpdate: any) => {
    const { selectedActivityTaskDetail } = this.state;
    const formattedTimeUpdate = Number(timeUpdate.toFixed(2));
    const updatedActivity = {
      ...selectedActivityTaskDetail,
      mediaProgress: formattedTimeUpdate,
    };
    this.setState({ selectedActivityTaskDetail: updatedActivity });
  };

  handleActivityDetailRender = (selectedActivityTaskDetail: any, smallScreen: boolean) => {
    let isInDom: boolean = false;
    const { navigation } = this.props;
    const { selectedLesson } = this.state;
    if (smallScreen) isInDom = selectedActivityTaskDetail?.expanded;

    switch (selectedActivityTaskDetail?.typeOfActivity) {
      case "quiz":
        return (
          <QuizActivityComponent
            navigation={navigation}
            forTeacher={false}
            detail={{
              title: selectedActivityTaskDetail?.activityTitle,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              about: selectedActivityTaskDetail?.about,
              type: selectedActivityTaskDetail?.typeOfActivity,
              course: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              lessonName: selectedLesson?.lessonTitle,
              dueDate: selectedActivityTaskDetail?.dueDate,
              instructions: selectedActivityTaskDetail?.instructions,
              maxScore: selectedActivityTaskDetail?.maxScore,
            }}
          />
        );

      case "exam":
        return (
          <ExamActivityComponent
            navigation={navigation}
            forTeacher={false}
            detail={{
              title: selectedActivityTaskDetail?.activityTitle,
              about: selectedActivityTaskDetail?.about,
              type: selectedActivityTaskDetail?.typeOfActivity,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              objectiveType: selectedActivityTaskDetail?.objectiveType,
              lessonName: selectedLesson?.lessonTitle,
              course: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              maxScore: selectedActivityTaskDetail?.maxScore,
              instructions: selectedActivityTaskDetail?.instructions,
              dueDate: selectedActivityTaskDetail?.dueDate,
            }}
          />
        );

      case "assignment":
        return (
          <AssignmentActivityComponent
            navigation={navigation}
            forTeacher={false}
            detail={{
              title: selectedActivityTaskDetail?.activityTitle,
              about: selectedActivityTaskDetail?.about,
              type: selectedActivityTaskDetail?.typeOfActivity,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              objectiveType: selectedActivityTaskDetail?.objectiveType,
              lessonName: selectedLesson?.lessonTitle,
              course: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              maxScore: selectedActivityTaskDetail?.maxScore,
              instructions: selectedActivityTaskDetail?.instructions,
              dueDate: selectedActivityTaskDetail?.dueDate,
            }}
          />
        );

      case "project":
        return (
          <ProjectActivityComponent
            navigation={navigation}
            forTeacher={false}
            detail={{
              title: selectedActivityTaskDetail?.activityTitle,
              evaluation: selectedActivityTaskDetail?.evaluationType,
              about: selectedActivityTaskDetail?.about,
              type: selectedActivityTaskDetail?.typeOfActivity,
              course: selectedActivityTaskDetail?.typeOfActivity,
              allowedAttempts: selectedActivityTaskDetail?.allowedAttempts,
              lessonName: selectedLesson?.lessonTitle,
              dueDate: selectedActivityTaskDetail?.dueDate,
              instructions: selectedActivityTaskDetail?.instructions,
              maxScore: selectedActivityTaskDetail?.maxScore,
            }}
          />
        );

      default:
        return (
          <ArticleActivityTaskBox
            detail={{
              title: selectedActivityTaskDetail?.activityTitle || "",
              about: selectedActivityTaskDetail?.about || "",
              type: selectedActivityTaskDetail?.typeOfActivity,
              activityImage: selectedActivityTaskDetail?.activityImage || "",
              mediaProgress: selectedActivityTaskDetail?.mediaProgress || 0,
              isCompleted: selectedActivityTaskDetail?.activityCompletionProgress === 100,
            }}
            isInDom={isInDom}
            hideReadCheckbox={true}
            handleMarkActivityComplete={this.handleMarkActivityCompleted}
            handleUpdateMediaProgressTime={this.handleUpdateAudioProgressTime}
          />
        );
    }
  }
  // Customizable Area End
}
