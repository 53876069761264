// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import RemoveStudentDialogController, {
  Props,
} from "./RemoveStudentDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import { trashIcon, exclamationIcon } from "./assets";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    dialogCloseIcon: {
      cursor: "pointer",
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(2.6),
      [theme.breakpoints.down("sm")]: {
        top: theme.spacing(5),
        right: theme.spacing(4),
        width: theme.spacing(1.5),
      },
    },
    paper: {
      width: "500px",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "460px",
      },
    },
    dialogRootWrapper: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    contentSubHeading: {
      fontSize: 17,
      fontWeight: 400,
      color: "#888",
      textAlign: "center",
    },
  });
// Customizable Area End

// Customizable Area Start
export class RemoveStudentDialog extends RemoveStudentDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      open,
      handleClose,
      selectedClassTitle,
      selectedStudentDetails,
    } = this.props;
    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.paper }}
        className={classes.dialogRootWrapper}
      >
        <Box className={`${classes.bgColor}`}>
          <DialogTitle id="new-class-dialog-title">
            <CloseIcon
              id="An icon to close Dialog"
              className={`${classes.dialogCloseIcon} ${classes.textPrimaryColor}`}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent style={{ padding: "30px" }}>
            <Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ width: "70px " }}
                  alt="trash-icon"
                  src={trashIcon}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography className={`${classes.textPrimaryColor} ${classes.fontSize22}`}>
                  {`Sure want to remove ${selectedStudentDetails?.student?.name}?`}
                </Typography>
                <Typography className={classes.contentSubHeading}>
                  {`Are you sure want to remove ${selectedStudentDetails?.student?.name} from ${selectedClassTitle}? Once removed he won’t be able to access ${selectedClassTitle}.`}
                </Typography>
              </Box>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  btnStyle={cancelClassBtn}
                  btnText={"Cancel"}
                  buttonId="class-draft-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.handleClose}
                />
                <CustomButton
                  btnStyle={addClassBtn}
                  btnText={"Remove"}
                  buttonId="class-add-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.handleConfirm}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addClassBtn = {
  width: "120px",
  borderRadius: "12px",
  color: "#FFFFFF",
  padding: "15px 36px",
  backgroundColor: "#ED1111",
  fontWeight: 600,
  fontSize: "16px",
  marginTop: "30px",
} as const;

const cancelClassBtn = {
  width: "120px",
  borderRadius: "12px",
  marginRight: "15px",
  padding: "15px 36px",
  fontWeight: 600,
  fontSize: "16px",
  marginTop: "30px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(RemoveStudentDialog);
// Customizable Area End
