// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import TeacherAssignCourseModalController, {
  Props,
} from "./TeacherAssignCourseModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const assignTheme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "540px",
      },
      paperWidthSm: {
        maxWidth: "540px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TeacherAssignModal extends TeacherAssignCourseModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={assignTheme}>
        <Dialog
          data-test-id="AssignModal"
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          open={open}
          aria-labelledby="assign-modal-success-confirm-dialog-title"
        >
          <Box padding={"30px"} className={`${classes.bgColor}`} data-test-id="assignModalBox">
            <Box display={"flex"} justifyContent={"center"} id="delete-id-assign">
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={exclamationIcon} alt="success icon" />
              </Box>
              <Box width={"6%"} data-test-id="assignModalIconBtn">
                <IconButton
                  data-test-id="SuccessCloseIconAssign"
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }} data-test-id="assignModal_Dailoq">
              <Box textAlign={"center"} mt={"20px"} mb={"10px"} data-test-id="assignModalBoxs">
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                  data-test-id="AssignModalTitle"
                >
                  Approval Required to Assign
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"} data-test-id="AssignModalTitleRow">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="CopySubCourseTitle"
                >
                  Since the course content has been edited, you need
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="copiedToTt"
                >
                  {" "}
                  to get approval in order to assign{" "}
                </Typography>
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
                data-test-id="AssignCloseBtns"
                style={{ gap: "10px" }}
              >
                <CustomButton
                  btnStyle={copyCourseModalBtn}
                  btnText="Cancel"
                  buttonId="assign-course-modal-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
                <CustomButton
                  btnStyle={copyCourseModalBtn}
                  btnText="Send Approval Request"
                  buttonId="copy-assign-modal-cancel-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const copyCourseModalBtn = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAssignModal);
// Customizable Area End
