// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  courseName?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseTitle: string;
  openConfirmDialog: boolean;
  selectCourseTitleValue: string;
  selectStudentValue: string;
  CourseTitlevalues: Array<any>;
  studentsDDvalues: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherAssignCourseModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
      selectedCourseTitle: "",
      openConfirmDialog: false,
      selectCourseTitleValue: "",
      selectStudentValue: "",
      CourseTitlevalues: [
        {
          id: 1,
          label: "Physics",
          value: 1,
        },
        {
          id: 2,
          label: "Chemistry",
          value: 2,
        },
        {
          id: 3,
          label: "Biology",
          value: 3,
        },
        {
          id: 4,
          label: "English",
          value: 4,
        },
      ],
      studentsDDvalues: [
        {
          id: 1,
          label: "Class 10A",
          value: 1,
        },
        {
          id: 2,
          label: "Class 10B",
          value: 2,
        },
        {
          id: 3,
          label: "Class 9A",
          value: 3,
        },
        {
          id: 4,
          label: "Assignment",
          value: 4,
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
  }

  handleDropdownChange = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderCourseTitleDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Physics";
    }
    const { CourseTitlevalues } = this.state;
    const selectedGradingComponents = CourseTitlevalues.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderStudentsDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Class 10A";
    }
    const { studentsDDvalues } = this.state;
    const selectedGradingComponents = studentsDDvalues.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };
  
  handleAssignCourse = () => {
    this.setState({ openConfirmDialog: true });
  };

  // Customizable Area End
}
// Customizable Area End
