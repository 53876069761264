import { englishSubjectIcon, historySubjectIcon, mathSubjectIcon, physicsSubjectIcon, psychologySubjectIcon, profilePic1, man, man2, man3, man4, man5, man6, sort, alert } from "../src/assets";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";

const studentCoursesList = [
    {
        id: 1,
        subjectName: "Science",
        subjectMarks: 76,
        lessonsCovered: 99,
        lessonsTotal: 100,
        status: 'Completed',
        subjectIcon: physicsSubjectIcon,
        timeSpentInMin: 400,
        subjectBgColor: "#01C996",
        completionProgress: 100,

    },
    {
        id: 2,
        subjectName: "Mathematics",
        subjectMarks: 89,
        lessonsCovered: 72,
        lessonsTotal: 100,
        status: 'Late',
        subjectIcon: mathSubjectIcon,
        timeSpentInMin: 500,
        subjectBgColor: "#0D46BC",
        completionProgress: 46,

    },
    {
        id: 3,
        subjectName: "English",
        subjectMarks: 59,
        lessonsCovered: 77,
        lessonsTotal: 100,
        status: 'On Going',
        subjectIcon: englishSubjectIcon,
        timeSpentInMin: 500,
        subjectBgColor: "#019AFC",
        completionProgress: 63,

    },
    {
        id: 4,
        subjectName: "History",
        subjectMarks: 29,
        lessonsCovered: 89,
        lessonsTotal: 100,
        status: 'Scheduled',
        subjectIcon: historySubjectIcon,
        timeSpentInMin: 500,
        subjectBgColor: "#FBD82C",
        completionProgress: 96,

    },
    {
        id: 5,
        subjectName: "Psychology",
        subjectMarks: 39,
        lessonsCovered: 98,
        lessonsTotal: 100,
        status: 'Late',
        subjectIcon: psychologySubjectIcon,
        timeSpentInMin: 1099,
        subjectBgColor: "#FF0000",
        completionProgress: 41,

    }
];

const studentListTableBody = [
    {
        id: 1,
        Name: {
            icon: profilePic1,
            name: "Milton peery",
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 2,
        Name: {
            name: "Prescott MacCaff",
            icon: man5,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 3,
        Name: {
            name: "Isak Johannesson",
            icon: man2,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 4,
        Name: {
            name: "Jason Bailey",
            icon: man4,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 5,
        Name: {
            icon: profilePic1,
            name: "Milton peery",
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 6,
        Name: {
            name: "Prescott MacCaff",
            icon: man5,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 7,
        Name: {
            name: "Isak Johannesson",
            icon: man2,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 8,
        Name: {
            name: "Jason Bailey",
            icon: man4,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 9,
        Name: {
            icon: profilePic1,
            name: "Milton peery",
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Locked In'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 10,
        Name: {
            name: "Prescott MacCaff",
            icon: man5,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'For Registrar Approval'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 11,
        Name: {
            name: "Isak Johannesson",
            icon: man2,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'For Registrar Approval'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 12,
        Name: {
            name: "Jason Bailey",
            icon: man4,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'For Advisor Approval'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
];

const studentListTableColumns = [
    {
        id: "Name",
        columnId: 1,
        label: "Name",
        type: tableColumnTypes.STUDENT_ICON,
        width: "250px",
        maxWidth: "280px"
    },
    {
        id: "Progress",
        columnId: 2,
        label: "Progress",
        icon: sort,
        type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
        isSortingColumn: true,
        sortByOrder: "",
    },
    {
        id: "gpa",
        columnId: 7,
        label: "GPA",
        icon: sort,
        type: tableColumnTypes.PROGRESS_TEXT,
        isSortingColumn: true,
    },
    {
        id: "lastAccess",
        columnId: 8,
        label: "Last Access",
        icon: sort,
        type: tableColumnTypes.DUE_DATE
    },
];

const progressColors = [
    {
        label: "Open",
        color: "#090909"
    },
    {
        label: "For Advisor Approval",
        color: "#FBDD49"
    },
    {
        label: "For Registrar Approval",
        color: "#0947BF"
    },
    {
        label: "Locked In",
        color: "#01C996"
    },
    {
        label: "Rejected",
        color: "#ED1111"
    }
];

export const parentProgressColors = [
    {
        label: "Locked In",
        color: "#01C996"
    },
    {
        label: "For Registrar Approval",
        color: "#0947BF"
    },
    {
        label: "For Advisor Approval",
        color: "#FBDD49"
    },
    {
        label: "Open",
        color: "#090909"
    }
];

const registrarProgressColorsListMock = [
    {
        label: "Open",
        color: "#090909"
    },
    {
        label: "Locked In",
        color: "#01C996"
    },
    {
        label: "Returned",
        color: "#ED1111"
    }
];

const tableActionMenu = [
    {
        label: 'View Student Profile',
        action: () => { }
    },
    {
        label: 'Open Gradebook',
        action: () => { }
    },
    {
        label: 'Print Unofficial',
        action: () => { }
    },
    {
        label: 'Print Official',
        action: () => { }
    }
];
const gradebookTableHead =[{
      id: "Name",
      columnId: 1,
      label: "Academic Subjects",
      type: tableColumnTypes.SUBJECT_ICON_NAME_TEMPLATE,
      width: "100%",
      maxWidth: "100%", 
    },
    {
      id: "Q1Avg",
      columnId: 3,
      label: "1st Qtr",
      type: tableColumnTypes.GRADEBOOK_AVG,
    },
    {
      id: "Q2Avg",
      columnId: 4,
      label: "2nd Qtr",
      type: tableColumnTypes.GRADEBOOK_AVG,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "Q3Avg",
      columnId: 5,
      label: "3rd Qtr",
    
      type: tableColumnTypes.GRADEBOOK_AVG,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "Q4Avg",
      columnId: 6,
      label: "4th Qtr",
     
      type: tableColumnTypes.GRADEBOOK_AVG,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "GPA",
      columnId: 7,
      label: "GPA",
      
      type: tableColumnTypes.NUMBER,
      width: "100%",
      maxWidth: "100%",
    },
    {
      id: "Grade",
      columnId: 8,
      label: "Grade",
      type: tableColumnTypes.TEXT,
      width: "100%",
      maxWidth: "100%",
    },
  ];
const gradebookTableData=[
    {
        id:1,
        Name: {
            name: "Physics",
            icon: physicsSubjectIcon,
            bgColor: "#01C996",
          },
        Q1Avg:86,
        Q2Avg:86,
        Q3Avg:86,
        Q4Avg:86,
        GPA:7,
        Grade:"A"
    },
    {
        id:1,
        Name: {
            name: "Total Grade Point Average",
            
          },
        Q1Avg:86,
        Q2Avg:86,
        Q3Avg:86,
        Q4Avg:86,
        GPA:7,
        Grade:"A"
    },
]
const AttandanceTableHead=[
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "attendance",
        label: "Attendance",
        width: "100%",
        maxWidth:  "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 2,
        id: "qtr1",
        label: "1st Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 3,
        id: "qtr2",
        label: "2nd Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 4,
        id: "qtr3",
        label: "3rd Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 5,
        id: "qtr4",
        label: "4th Qtr",
        width: "100%",
        maxWidth:  "100%",
    },
    {
        columnId: 6,
        id: "total",
        type: tableColumnTypes.NUMBER,
        label: "Total",
        width: "100%",
        maxWidth:"100%",
    },
  ]



  const BehaviorsTableHead = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "title",
        label: "Desirable Behaviors",
        width: "100%",
        maxWidth:  "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 2,
        id: "qtr1",
        label: "1st Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 3,
        id: "qtr2",
        label: "2nd Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 4,
        id: "qtr3",
        label: "3rd Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 5,
        id: "qtr4",
        label: "4th Qtr",
        width: "100%",
        maxWidth:  "100%",
    },
]
const BehaviorsTableData=[
    {
        id:1,
        title:"Focused Attention",
        qtr1:86,
        qtr2:95,
        qtr3:74,
        qtr4:0,
    },
    {
        id:2,
        title:"Focused Obedience",
        qtr1:83,
        qtr2:0,
        qtr3:0,
        qtr4:0,
    },
    {
        id:1,
        title:"Truthfulness",
        qtr1:92,
        qtr2:0,
        qtr3:0,
        qtr4:0,
    }
]
export {
    studentCoursesList,
    studentListTableBody,
    studentListTableColumns,
    progressColors,
    tableActionMenu,
    registrarProgressColorsListMock,
    gradebookTableHead,
    gradebookTableData,
    AttandanceTableHead,
    BehaviorsTableHead,
    BehaviorsTableData
};