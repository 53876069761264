import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"

interface IProps {
    noDataText: string;
}

const useStyles = makeStyles(() => ({
    primaryColor:{
        color:"#888888"
    },
    fontText16: {
        fontSize: "16px"
    },
    boldFont: {
        fontWeight: 600
    },
}))

export const CustomNoDataComponent: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const { noDataText } = props;

    return (
       <Box>
         <Typography className={`${classes.primaryColor} ${classes.fontText16} ${classes.boldFont}`} component={"span"}>
                    {`${noDataText}`}
                </Typography>
       </Box>
    );
}

export default CustomNoDataComponent;
