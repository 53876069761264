// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const RadioBtnIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g filter="url(#filter0_d_0_13738)">
                <circle cx="16" cy="18" r="16" fill="white" />
            </g>
            <circle cx="16" cy="18" r="11" fill={props.fill} />
            <defs>
                <filter id="filter0_d_0_13738" x="-4" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.695114 0 0 0 0 0.695114 0 0 0 0 0.695114 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_13738" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_13738" result="shape" />
                </filter>
            </defs>
        </SvgIcon>
    );
}

export default RadioBtnIcon;
// Customizable Area End