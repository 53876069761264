// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData } from "../../framework/src/Utilities";
import { tenantAdminSetup } from "./WebSidebar.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id?: string;
  classes: any;
  sidebarWidth:number;
  isDarkMode:boolean;
  isSidebarOpen:boolean;
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  loading: boolean;
  selectedScreenId:number;
  companyName:string;
  tenantAdminSetup:any[]
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebSidebarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData:string =""
  // Customizable Area End
    constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]

    this.state = {
      loading: false,
      selectedScreenId:0,
      companyName:"Thynker Tech",
      tenantAdminSetup:tenantAdminSetup
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
  }
  // Customizable Area Start
  getCompanyName =async () => {
    const themeData = await getStorageData("appearanceTheme");

    if (themeData) {
      try {
        const appearanceThemes = JSON.parse(themeData);
          this.setState({companyName:appearanceThemes?.customized_data.company})
      } catch (error) {
        console.error("Failed to parse themeData. It might not be valid JSON.", error);
      }
    } else {
      console.warn("No themeData found in local storage.");
    }
    // this.setState({companyName:"Thynker Tech"})
  };
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.addEventListener('appearanceTheme', this.getCompanyName);
    window.addEventListener('roleandpermission', this.getRoleAndPermissions);
    await this.getCompanyName()
    await this.getRoleAndPermissions()
    // Customizable Area End
  }

 // Customizable Area Start
updateSidebarDisability(
  sidebarOptions: any[],
  permissions: Record<string, { can: { read: boolean; write: boolean } }>
): any[] {
  return sidebarOptions.map(menuItem => {
      const updatedMenuItem = { ...menuItem };
      if (menuItem.isSubMenuPresent && menuItem.subMenuItems) {
          updatedMenuItem.subMenuItems = menuItem.subMenuItems.map((subMenuItem: { permissionKeys: never[]; }) => {
              const permissionKeys = subMenuItem.permissionKeys || [];
              const isDisabled = permissionKeys.length === 0? false: permissionKeys.every(
                (                key: string | number) => !permissions[key]?.can.read 
              )
              return {
                  ...subMenuItem,
                  disabled: isDisabled
              };
          });
      }

      return updatedMenuItem;
  });
}
 async getRoleAndPermissions(){
  const roleandpermission = await getStorageData("roleandpermission");
  if(roleandpermission){
    try {
    const rolePermission = JSON.parse(roleandpermission);
    const updatedCustomAdminOptions =this.updateSidebarDisability(this.state.tenantAdminSetup,rolePermission)
    this.setState({tenantAdminSetup:updatedCustomAdminOptions})
    } catch (error) {
      console.error("Failed to parse roleandpermission. It might not be valid JSON.", error);
    }
  }
 }
 componentWillUnmount(): any {
  window.removeEventListener('appearanceTheme', this.getCompanyName);
  window.removeEventListener('roleandpermission', this.getRoleAndPermissions);
}
 
  handleGotoScreen = (screenName : string) => {
    if(screenName) {
      this.props.navigation.navigate(screenName);
    }
  }

  async receive(from: string, message: Message) {
     
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)


      // Customizable Area Start
       
      // Customizable Area End
    }
  }

 // Customizable Area End
}