import React from "react";
import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area Start
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TeacherPendingRequest from "./TeacherPendingRequest.web";
import TeacherApprovedRequest from "./TeacherApprovedRequest.web";
import TeacherRejectedRequest from "./TeacherRejectedRequest.web";

const styles = (theme: Theme) =>
  createStyles({
    myRequestContainer: {
      padding: "40px",
    },
    mainContainer: {
      overflowX: "hidden",
      maxHeight: "700vh",
      borderRadius: "10px",
      padding: "8px 0px",
      overflowY: "hidden",
    },
    marginsPageNames: {
      marginBottom: "28px",
      marginTop: "16px",
    },
    myRequestTabBtns: {
      padding: "20px 4px",
      backgroundColor: "transparent",
      textDecoration: "none",
      textAlign: "center",
      display: "inline-block",
      textTransform: "capitalize",
      opacity: 1,
      width: "100%",
      fontSize: "20px",
      "&.MuiTab-root": {
        minHeight: "48px",
      },
      "&.Mui-selected": {
        color: "#0D46BC !important",
        backgroundColor: "transparent",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 500,
        outline: "none",
      },
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "center",
        },
      },
    },
    myRequestTabPanel: {
      padding: "15px 0px",
    },
  });
// Customizable Area End

import TeacherMyRequestController, {
  Props,
  myRequestsTab,
} from "./TeacherMyRequestController.web";

export class TeacherMyRequest extends TeacherMyRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { selectedRequestTabIndex, selectedTabName } = this.state;
    return (
      // Customizable Area Start
      <Box
        height={"100%"}
        className={`${classes.highlightBgColor} ${classes.myRequestContainer} ${classes.secondaryBgColor}`}
        data-test-id="MyRequestContainer"
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                component={"span"}
                className={`${classes.textCommonColor} ${classes.fontText12}`}
                data-test-id="Management"
              >
                Course Management
              </Typography>
              <Typography
                component={"span"}
                className={`${classes.textCommonColor} ${classes.fontText12}`}
                data-test-id="CatPath"
              >
                {` / Catalog`}
              </Typography>
              <Typography
                component={"span"}
                className={`${classes.textCommonColor} ${classes.fontText12}`}
                data-test-id="ReqPath"
              >
                {` / My Requests`}
              </Typography>
              {selectedTabName ? (
                <Typography
                  className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`}
                  component={"span"}
                  data-test-id="NamePath"
                >
                  {` / ${selectedTabName}`}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid
            container
            className={classes.marginsPageNames}
            item
            xs={12}
            alignItems="center"
            data-test-id="Griddrpdwns"
          >
            <Button
              onClick={this.handleGoBack}
              style={{ width: "40px", height: "40px" }}
              data-test-id="BackPath"
            >
             <KeyboardBackspaceIcon
                    fontSize="large"
                    className={`${classes.textPrimaryColor}`}
                  />
            </Button>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
              data-test-id="MyRequests"
            >
              My Requests
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={`${classes.bgColor} ${classes.mainContainer}`}
              container
              direction="row"
              data-test-id="Tabgrid"
            >
              <Grid item xs={12}>
                <Tabs
                  variant="fullWidth"
                  scrollButtons="auto"
                  value={selectedRequestTabIndex}
                  className={`${classes.tabs}`}
                  data-test-id="myRequestTab"
                  onChange={this.handleRequestTabChange}
                  aria-label="student-assessment-tabs"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                >
                  {myRequestsTab.map((tabItems: any, index: number) => (
                    <Tab
                      key={tabItems.id}
                      data-testid={`student-assessment-${tabItems.label}-tab`}
                      label={tabItems.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.myRequestTabBtns} ${classes.textPrimaryColor}`}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid className={`${classes.myRequestTabPanel}`} item xs={12}>
                <TabPanel value={selectedRequestTabIndex} index={0}>
                  <TeacherPendingRequest />
                </TabPanel>
                <TabPanel value={selectedRequestTabIndex} index={1}>
                  <TeacherApprovedRequest />
                </TabPanel>
                <TabPanel value={selectedRequestTabIndex} index={2}>
                  <TeacherRejectedRequest />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherMyRequest);
// Customizable Area End
