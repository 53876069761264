// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const SearchIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path strokeWidth={props.strokeWidth} fillRule="evenodd" clipRule="evenodd" d="M11.5247 10.6867L15.8264 14.9884C16.0579 15.2199 16.0579 15.595 15.8264 15.8265C15.7107 15.9422 15.559 16.0001 15.4074 16.0001C15.2559 16.0001 15.1041 15.9422 14.9884 15.8265L10.6867 11.5247C9.55585 12.4679 8.10281 13.037 6.51852 13.037C2.92415 13.037 0 10.1129 0 6.51852C0 2.92415 2.92415 0 6.51852 0C10.1129 0 13.037 2.92415 13.037 6.51852C13.037 8.10281 12.4679 9.55585 11.5247 10.6867ZM6.51852 1.18519C3.57748 1.18519 1.18519 3.57778 1.18519 6.51852C1.18519 9.45926 3.57748 11.8519 6.51852 11.8519C9.45956 11.8519 11.8519 9.45926 11.8519 6.51852C11.8519 3.57778 9.45956 1.18519 6.51852 1.18519Z" fill={props.fill}/>
        </SvgIcon>
    );
}

export default SearchIcon;
// Customizable Area End