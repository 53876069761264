// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import DeleteEditEventCommanPopUpController, {
  Props,
} from "./DeleteEditEventCommanPopUpController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "880px",
      },
      paperWidthSm: {
        maxWidth: "880px",
      },
    },
  },
});
const editTitleTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        height: "38px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        paddingLeft: "5px",
      },
    },
  },
});
const EditTime = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover $notchedOutline": {
          borderColor: "transparent",
        },
        "&.Mui-focused $notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class EditEventPopUp extends DeleteEditEventCommanPopUpController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open} = this.props;
    const {isvalidationEditErrors,validationEditErrors } = this.state
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor}`}
          aria-labelledby="Edit-pop-up"
          style={{ width: "100%" }}
          open={open}
        >
          <Box padding={"21px 30px 30px 30px"} className={`${classes.bgColor}`}>
            <Grid direction="row" container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="editText"
                >
                  Edit Event
                </Typography>
                <IconButton
                  data-test-id="EditPopUp"
                  style={{ float: "right" }}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                  className={classes.textSecondaryColor}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent
              data-test-id="editContent"
              style={{
                padding: "0px",
                marginTop: "24px",
                overflow: "hidden",
              }}
            >
           <Grid container spacing={2} direction="row">
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`Event Title`}
                      </Typography>
                      <ThemeProvider theme={editTitleTheme}>
                        <Box mt={"6px"}>
                          <CustomTextField
                            placeHolderText="Enter Event Title"
                            fieldId="search-students"
                            fieldError={isvalidationEditErrors.searchEditStudent}
                            helperText={validationEditErrors.searchEditStudent}
                            fieldName="EditTitle"
                            fieldValue={this.state.searchEditStudent}
                            fieldWidth={"100%"}
                            fieldType="text"
                            handleChange={this.handleEditChange}
                          />
                        </Box>
                      </ThemeProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                      >
                        {`Event Date`}
                      </Typography>
                      <div
                        className="DateContainers"
                        style={{ marginTop: "6px" }}
                      >
                        <span className="dateInput">
                          {" "}
                          <div style={{ position: 'relative', zIndex: 1000 }}>
                          <DatePicker
                            onChange={this.editEndDate}
                            popperPlacement="left-start"
                            popperClassName="example-datepicker-class"
                            selected={this.state.editEndDate}
                          />
                          </div>
                        </span>
                        <span className="icon">
                          <CalendarIcon />
                        </span>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`Color`}
                      </Typography>
                        <ThemeProvider theme={editTitleTheme}>
                          <Box mt={"9px"}>
                        <CustomColorPickerFieldWeb
                          fieldId="add-event-color-picker"
                          fieldName="selectedColor"
                          handleColorChange={this.colorChangeUpdate}
                          fieldWidth="100%"
                          fieldValue={this.state.selectedColorName}
                          fieldValueHexCode={this.state.colorEditChange}
                          textFieldStyles={{ width: "100%" }}
                          placeHolderText={"Select Color"}
                          fieldError={isvalidationEditErrors.colorEditChange}
                          helperText={validationEditErrors.colorEditChange}
                        />
                        </Box>
                        </ThemeProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.DateName}>
                      <Typography
                        component={"span"}
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                      >
                        {`Event time`}
                      </Typography>
                      <Box style={{ marginTop: "8px" }}>
                        <ThemeProvider theme={EditTime}>
                          <form className={classes.container} noValidate>
                            <TextField
                              fullWidth
                              id="time"
                              type="time"
                              onChange={this.handleEditTimeChange}
                              value={this.state.eventEditTime}
                                error={isvalidationEditErrors.eventEditTime}
                                helperText={validationEditErrors.eventEditTime}
                              style={{
                                height: "50px",
                                width: "99%",
                                borderRadius: "6px",
                              }}
                              variant="outlined"
                              defaultValue={this.getCurrentTime()}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={`${classes.textPrimaryColor} ${classes.bgColor} 
                              ${ this.state.isvalidationEditErrors.eventEditTime ? classes.errorBorder : classes.primaryBorder}`}
                              inputProps={{
                                className: `${classes.textCommonColor}`,
                                style: { height: "15px" },
                                step: 1, 
                              }}
                            />
                          </form>
                        </ThemeProvider>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} item>
                  <Box marginBottom={"10px"} style={{ width: "100%" }}>
                    <CustomButton
                      btnText="Save"
                      btnStyle={editSaveBtn}
                      buttonId={`edit-save-btn`}
                      buttonVariant={"contained"}
                      type={"button"}
                      data-testId="edit-event"
                      handleButtonClick={this.handleEditEvent}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      btnStyle={editCancleBtn}
                      buttonId={`edit-cancel-btn`}
                      buttonVariant={"outlined"}
                      type={"button"}
                      handleButtonClick={this.props.onCancel}
                      isDisabled={false}
                    />
                  </Box>
                  </Grid>
                </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const editCancleBtn = {
  width: "120px",
  marginLeft: "15px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  padding: "10px 10px",
  float: "right",
} as const;

const editSaveBtn = {
  width: "120px",
  textAlign: "center",
  height: "50px",
  marginLeft: "15px",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  padding: "10px 10px",
  fontWeight: 500,
  float: "right",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(EditEventPopUp);
// Customizable Area End
