// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import MyCoursesTabController, {
  Props
} from "./MyCoursesTabController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import CustomTable, { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "../assets";

// Customizable Area End
const coursesTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.COURSES_NAME

  },
  {
    id: "Subject",
    columnId: 2,
    label: "Subject",
    type: tableColumnTypes.TEXT_AUTO
  },
  {
    id: "Grade",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT_AUTO
  },
  {
    id: "Lessons",
    columnId: 4,
    label: "No. of lessons a day",
    type: tableColumnTypes.TEXT_AUTO
  },
  {
    id: "Status",
    columnId: 5,
    label: "Status",
    type: tableColumnTypes.COURSE_STATUS
  },
]
const coursesData = [
  {
    id: 1,
    Title: {
      name: "Physics",
      icon: ScienceColor,
    },
    Subject: 'Science',
    Grade: 'Grade 3',
    Lessons: '2',
    Status: 'Draft'
  },
  {
    id: 2,
    Title: {
      name: "Algebra",
      icon: MathsColor,
    },
    Subject: 'Maths',
    Grade: 'Grade 3',
    Lessons: '1',
    Status: 'For Approval'
  },
  {
    id: 3,
    Title: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject: 'History',
    Grade: 'Grade 3',
    Lessons: '1',
    Status: 'Approved'
  },
  {
    id: 4,
    Title: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    Lessons: '3',
    Status: 'Draft'
  },
  {
    id: 5,
    Title: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    Lessons: '2',
    Status: 'Draft'
  },
  {
    id: 6,
    Title: {
      name: "English Poetry",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    Lessons: '4',
    Status: 'For Approval'
  },
  {
    id: 7,
    Title: {
      name: "Velocity and Accel…",
      icon: ScienceColor,
    },
    Subject: 'Science',
    Grade: 'Grade 3',
    Lessons: '1',
    Status: 'Approved'
  },
  {
    id: 8,
    Title: {
      name: "Calculas",
      icon: MathsColor,
    },
    Subject: 'Maths',
    Grade: 'Grade 3',
    Lessons: '2',
    Status: 'Approved'
  },
  {
    id: 9,
    Title: {
      name: "Industrial Revolu…",
      icon: HistoryColor,
    },
    Subject: 'History',
    Grade: 'Grade 3',
    Lessons: '3',
    Status: 'Draft'
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    statusPill: {
      width: "94px",
      height: "24px",
      borderRadius: "12px",
      opacity: "0.1866",
      background: "#029CFE",
    },
    coursesTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }
    }
  });

// Customizable Area End

// Customizable Area Start
export class MyCoursesTab extends MyCoursesTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedSubjectValue,
      selectedGradeValue,
      selectedLessonValue,
      selectedStatusValue,
      selectedUtilizationValue
    } = this.state;

    return (
      <Box style={{ padding: "12px 25px 25px 25px" }}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  Subject
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedSubjectValue"
                  fieldId="subjectDropdown"
                  fieldError={false}
                  handleChange={this.handleChangeDropdownValue}
                  fieldValue={selectedSubjectValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("Subject")}
                  renderValue={this.renderSubjectDropdownValue}
                  fieldStyles={dropdownStyles}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Grade
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedGradeValue"
                  fieldId="gradesDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  fieldError={false}
                  fieldValue={selectedGradeValue}
                  fieldMenuItems={this.getDropdownItemsArray("Grade")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  renderValue={this.renderGradeDropdownValue}
                  fieldStyles={dropdownStyles}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  No. of lessons a day
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedLessonValue"
                  fieldId="lessonsDropdown"
                  fieldStyles={dropdownStyles}
                  handleChange={this.handleChangeDropdownValue}
                  fieldError={false}
                  fieldValue={selectedLessonValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("Lesson")}
                  renderValue={this.renderLessonDropdownValue}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Status
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedStatusValue"
                  fieldId="statusDropdown"
                  fieldValue={selectedStatusValue}
                  handleChange={this.handleChangeDropdownValue}
                  fieldStyles={dropdownStyles}
                  renderValue={this.renderStatusDropdownValue}
                  fieldError={false}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("Status")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Utilization of the course
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedUtilizationValue"
                  fieldId="utilizationDropdown"
                  fieldValue={selectedUtilizationValue}
                  handleChange={this.handleChangeDropdownValue}
                  fieldStyles={dropdownStyles}
                  fieldMenuItems={this.getDropdownItemsArray("Utilization")}
                  renderValue={this.renderUtilizationDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldError={false}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchAssessment} isDisabled={false} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.dividerLine} />
        <Grid container>
          <Grid item xs={12}>
            <Box className={`${classes.customTableStyles} ${classes.coursesTableRow}`} >
              <CustomTable
                tableId={"coursesTable"}
                tableHeaderColumns={coursesTableColumns}
                tableBodyData={coursesData}
                showMenuIcon={true}
                showMyCoursesMenu={true}
                handleAction={this.handleOpenCourseDetail}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px"
} as const;

const dropdownStyles = {
  width: "100%"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MyCoursesTab);
// Customizable Area End
