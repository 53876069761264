// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import MyCoursesTabController, { Props } from "./TeacherMyCoursesTabController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import TeacherAssignModal from "./TeacherAssignModal.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import {
  ScienceColor,
  MathsColor,
  HistoryColor,
  EnglishColor,
} from "./assets";

// Customizable Area End
const myCourseTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.COURSES_NAME,
  },
  {
    id: "Subject",
    columnId: 2,
    label: "Subject",
    type: tableColumnTypes.TEXT_AUTO,
  },
  {
    id: "Grade",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT_AUTO,
  },
  {
    id: "Lessons",
    columnId: 4,
    label: "No. of lessons a day",
    type: tableColumnTypes.TEXT_AUTO,
  },
  {
    id: "Status",
    columnId: 5,
    label: "Status",
    type: tableColumnTypes.COURSE_STATUS,
  },
];
const myCoursesData = [
  {
    id: 1,
    Title: {
      name: "Physics",
      icon: ScienceColor,
    },
    Subject: "Science",
    Grade: "Grade 3",
    Lessons: "2",
    Status: "Draft",
  },
  {
    id: 2,
    Title: {
      name: "Algebra",
      icon: MathsColor,
    },
    Subject: "Maths",
    Grade: "Grade 3",
    Lessons: "1",
    Status: "For Approval",
  },
  {
    id: 3,
    Title: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject: "History",
    Grade: "Grade 3",
    Lessons: "1",
    Status: "Approved",
  },
  {
    id: 4,
    Title: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject: "English",
    Grade: "Grade 3",
    Lessons: "3",
    Status: "Draft",
  },
  {
    id: 5,
    Title: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject: "English",
    Grade: "Grade 3",
    Lessons: "2",
    Status: "Draft",
  },
  {
    id: 6,
    Title: {
      name: "English Poetry",
      icon: EnglishColor,
    },
    Subject: "English",
    Grade: "Grade 3",
    Lessons: "4",
    Status: "For Approval",
  },
  {
    id: 7,
    Title: {
      name: "Velocity and Approval",
      icon: ScienceColor,
    },
    Subject: "Science",
    Grade: "Grade 3",
    Lessons: "1",
    Status: "Approved",
  },
  {
    id: 8,
    Title: {
      name: "Calculas",
      icon: MathsColor,
    },
    Subject: "Maths",
    Grade: "Grade 3",
    Lessons: "2",
    Status: "Approved",
  },
  {
    id: 9,
    Title: {
      name: "Industrial Revolution",
      icon: HistoryColor,
    },
    Subject: "History",
    Grade: "Grade 3",
    Lessons: "3",
    Status: "Draft",
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerCss: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "30px",
      marginBottom: "5px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    courseTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class MyCoursesTab extends MyCoursesTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedSubValue,
      selectedGradeValues,
      selectLessonValue,
      selectStatusValue,
      selectUtilizationValue,
    } = this.state;

    return (
      <Box px={"25px"} pb={"20px"} data-test-id="TablePage">
        <Grid container spacing={3} direction="row" data-test-id="TableGrid">
          <Grid item xs={12} lg={3} md={4}>
            <Box  data-test-id="BoxPage">
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                  component={"span"}
                  data-test-id="TableHeader"
                >
                  Subject
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedSubValue"
                  fieldId="subjectDropdown"
                  fieldError={false}
                  handleChange={this.handleSubjectDropdownValue}
                  fieldValue={selectedSubValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.getDropdownListArray("Subject")}
                  renderValue={this.renderSubjectDDValue}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="tableGrids">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="gradeText"
                >
                  Grade
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="gradeDropDwn"
              >
                <CustomSelectDropdown
                  fieldName="selectedGradeValues"
                  fieldId="gradesDropdown"
                  handleChange={this.handleSubjectDropdownValue}
                  fieldError={false}
                  fieldValue={selectedGradeValues}
                  fieldMenuItems={this.getDropdownListArray("Grade")}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  renderValue={this.renderGradeDDValue}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="gradeTextLesn"
                >
                  No. of lessons a day
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="LessonDropDown"
              >
                <CustomSelectDropdown
                  fieldName="selectLessonValue"
                  fieldId="lessonsDropdown"
                  fieldStyles={dropdownStyle}
                  handleChange={this.handleSubjectDropdownValue}
                  fieldError={false}
                  fieldValue={selectLessonValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.getDropdownListArray("Lesson")}
                  renderValue={this.renderLessonDropdownValue}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="statusText"
                >
                  Status
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="statusDropDown"
              >
                <CustomSelectDropdown
                  fieldName="selectStatusValue"
                  fieldId="statusDropdown"
                  fieldValue={selectStatusValue}
                  handleChange={this.handleSubjectDropdownValue}
                  fieldStyles={dropdownStyle}
                  renderValue={this.renderStatusDDValue}
                  fieldError={false}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.getDropdownListArray("Status")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="LessonDropDowns"
                >
                  Utilization of the course
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="LessonDrop"
              >
                <CustomSelectDropdown
                  fieldName="selectUtilizationValue"
                  fieldId="utilizationDropdown"
                  fieldValue={selectUtilizationValue}
                  handleChange={this.handleSubjectDropdownValue}
                  fieldStyles={dropdownStyle}
                  fieldMenuItems={this.getDropdownListArray("Utilization")}
                  renderValue={this.renderUtilizationDDValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldError={false}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="Btngrid">
            <Box data-test-id="LessonDropDownBtn">
              <CustomButton
                btnText="Search"
                btnStyle={customButtonStyles}
                buttonId={`my-assessment-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={undefined}
                isDisabled={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.dividerCss} />
        <Grid container>
          <Grid item xs={12} data-test-id="TableData">
            <Box
              className={`${classes.customTableStyles} ${classes.courseTableRow}`}
            >
              <CustomTable
                tableId={"coursesTable"}
                tableHeaderColumns={myCourseTableColumns}
                tableBodyData={myCoursesData}
                showMenuIcon={true}
                // showMyCoursesMenu={true}
                handleAction={this.handleOpenCourseDetail}
                actionButtonConfig={[
                  {
                    label: "Publish",
                    action: this.handlePublishEventDialog,
                  },
                  {
                    label: "Copy",
                    action: this.UndefinedFunction,
                  },
                  {
                    label: "View",
                    action: this.UndefinedFunction,
                  },
                  {
                    label: "Edit",
                    action: this.UndefinedFunction,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        <TeacherAssignModal
          open={this.state.openAssignModal}
          onCancel={this.handleCloseAssignCourseModal}
          onConfirm={this.handleCloseAssignCourseModal}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyles = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MyCoursesTab);
// Customizable Area End
