import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
  message: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    margin: theme.spacing(12.5, 0),
    alignItems: "center",
  },
  text: {
    color: "#888888",
    fontSize: 20,
    fontWeight: 400,
  }
}))

export const EmptyResults: React.FC<IProps> = (props) => {
  const classes = useStyles();
  
  const { message } = props;

  return (
    <Box className={classes.root}>
      <Box>
        <img src={require('./bankrupt.png')} alt='bankrupt-image' />
      </Box>
      <Typography className={classes.text}>{message}</Typography>
    </Box>
  );
}


export default EmptyResults;