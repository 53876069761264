import React, { createContext, Component, ReactNode } from 'react';

interface AssessmentTestState {
  tabValue: number;
}

export interface AssessmentTestContextType extends AssessmentTestState {
  setTabValue: (next: number) => void;
}

interface AssessmentTestProps {
  children: ReactNode;
}

const defaultContextValue: AssessmentTestContextType = {
  tabValue: 0,
  setTabValue: () => {},
};

export const AssessmentTestContext = createContext<AssessmentTestContextType>(
  defaultContextValue
);

export default class AssessmentTestProvider extends Component<
  AssessmentTestProps,
  AssessmentTestState
> {
  constructor(props: AssessmentTestProps) {
    super(props);
    this.state = { tabValue: 0 };
  }

  setTabValue = (newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    return (
      <AssessmentTestContext.Provider
        value={{ ...this.state, setTabValue: this.setTabValue }}
      >
          {this.props.children}
      </AssessmentTestContext.Provider>
    );
  }
}

 
