// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  fieldName:string;
  fieldValue:any;
  fieldError?:boolean;
  helperText?: string;
  fieldId:string;
  fieldMenuItems:any;
  fieldStyles?:any;
  renderValue:any;
  handleChange:any;
  startAdornmentIcon?:any;
  iconComponent?:any;
  placeHolderText?:string;
  isDisabled?: boolean;
  isLoading?: boolean;
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  loading: boolean;
  openMenu:boolean
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomSelectDropdownController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End
    constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

    ]

    this.state = {
      loading: false,
      openMenu:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
   
    // Customizable Area End
  }

 // Customizable Area Start

  handleToggleMenu = (e : any) => {
    this.setState({ openMenu : !this.state.openMenu })
  }

  handleChangeMenu= (e : any) => {
    this.props.handleChange(e)
    this.setState({ openMenu : false });
  }

 // Customizable Area End
}