// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const NotificationBellIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g fill={props.fill} fillRule="evenodd">
                <g transform="translate(-1130.000000, -47.000000)" fillRule="nonzero">
                    <g transform="translate(1121.000000, 40.000000)">
                        <g transform="translate(9.000000, 7.000000)">
                            <path d="M20.9938812,18.3225098 C19.3559875,16.9379272 18.4167328,14.9142151 18.4167328,12.7702942 L18.4167328,9.75 C18.4167328,5.93783572 15.5848847,2.7820587 11.9167328,2.25341797 L11.9167328,1.0832672 C11.9167328,0.484207141 11.4313354,0 10.8332672,0 C10.2353974,0 9.75,0.484207141 9.75,1.0832672 L9.75,2.25341797 C6.08065798,2.7820587 3.25,5.93783572 3.25,9.75 L3.25,12.7702942 C3.25,14.9142151 2.31074523,16.9379272 0.662933375,18.3310394 C0.241607641,18.691864 0,19.2161407 0,19.7707672 C0,20.8163452 0.850387586,21.6667328 1.8957672,21.6667328 L19.7707672,21.6667328 C20.8163452,21.6667328 21.6667328,20.8163452 21.6667328,19.7707672 C21.6667328,19.2161407 21.4251251,18.691864 20.9938812,18.3225098 Z"></path>
                            <path d="M10.8332672,26 C12.7952881,26 14.4365539,24.6013336 14.8134461,22.75 L6.85308838,22.75 C7.23017882,24.6013336 8.87144471,26 10.8332672,26 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default NotificationBellIcon;
// Customizable Area End