// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import CustomTextField from "../../../components/src/CustomTextField.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import NewSchoolYearDialogController, {
  Props,
} from "./NewSchoolYearDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    modalCloseIcon: {
      cursor: "pointer",
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(2.6),
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(4),
        width: theme.spacing(1.5),
        top: theme.spacing(5),
      },
    },
    termTextFieldWrapper: {
      justifyContent: "space-between",
      flexDirection: "row",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    fieldLabel: {
      marginBottom: theme.spacing(1.5),
      marginTop: "27px",
      fontSize: "16px",
      fontWeight: 400,
      color: "#888888",
    },
    dialogContent: {
      height: "800px",
    },
    dateDisableField: {
      width: "260px",
      "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "#8888881A",
      },
    },
    paper: {
      height: "auto",
      width: "880px",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "440px",
      },
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    textField: {
      borderRadius: "8px",
      padding: "5px 0px",
      position: "relative",
    },
    textfieldInput: {
      fontWeight: "normal",
      paddingLeft: "5px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    errorBorder: {
      border: "1px solid red",
    },
    inputAdornment: {
      paddingLeft: "15px",
    },
    textFieldFont: {
      fontSize: "16px",
    },
    dateTextField: {
      backgroundColor: "#FFFFFF",
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        border: "1px solid #888",
      },
    },
    trim: {
      overflow: "hidden",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "110px",
      marginRight: "2px"
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewSchoolYearDialog extends NewSchoolYearDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, handleClose, isEdit } = this.props;
    const {
      title,
      titleError,
      titleErrorMsg,
      gradePeriodTitle,
      gradePeriodTitleError,
      gradePeriodTitleErrorMsg,
      selectGradePeriod,
      selectGradePeriodError,
      selectGradePeriodErrorMsg,
      selectStartDate,
      selectEndDate,
      description,
      descriptionError,
      descriptionErrorMsg,
      semesterData,
      endDateError,
      endDateErrorMsg,
      startDateError,
      startDateErrorMsg,
    } = this.state;
    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        className={classes.dialogRoot}
      >
        <Box>
          <DialogTitle
            id="new-school-year-dialog-title"
            className={`${classes.bgColor}`}
          >
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
            >
              {isEdit ? `Edit` : `Add New`} School Year
            </Typography>
            <CloseIcon
              id="An icon to close Modal"
              className={`${classes.modalCloseIcon} ${classes.textPrimaryColor}`}
              onClick={this.handleClosePopup}
            />
          </DialogTitle>
          <DialogContent className={`${classes.bgColor}`}>
            <Box>
              <Box>
                <Grid container>
                  <Grid item lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Typography className={classes.fieldLabel}>
                      Title
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter First Name"
                      fieldName="title"
                      fieldId="title-field"
                      textFieldStyles={{ padding: "10px 0" }}
                      fieldValue={title}
                      fieldWidth={"100%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={titleError}
                      helperText={titleErrorMsg}
                      disableDarkmode
                    />
                    <Typography className={classes.fieldLabel}>
                      Start Date
                    </Typography>
                    <CustomDatepicker
                      disableFutureDate={false}
                      disablePastDate={true}
                      placeHolderText={"DD/MM/YYYY"}
                      format="DD/MM/YYYY"
                      fieldError={startDateError}
                      fieldErrorMsg={startDateErrorMsg}
                      fieldStyles={{ padding: "10px 0px" }}
                      isDisabled={false}
                      fieldId={"start-date"}
                      selectedDate={selectStartDate}
                      handleChangeDate={(date: any) =>
                        this.handleSetStartDate(date)
                      }
                    />
                    <Typography className={classes.fieldLabel}>
                      End Date
                    </Typography>
                    <CustomDatepicker
                      disableFutureDate={false}
                      disablePastDate={true}
                      placeHolderText={"DD/MM/YYYY"}
                      format="DD/MM/YYYY"
                      fieldError={endDateError}
                      fieldErrorMsg={endDateErrorMsg}
                      isDisabled={false}
                      fieldStyles={{ padding: "10px 0px" }}
                      fieldId={"end-date"}
                      selectedDate={selectEndDate}
                      handleChangeDate={(date: any) =>
                        this.handleSetEndDate(date)
                      }
                    />
                    <Typography
                      className={classes.fieldLabel}
                      style={{ marginTop: "26px", marginBottom: "14px" }}
                    >
                      Select No. of Grading Period
                    </Typography>
                    <CustomSelectDropdown
                      fieldId="number-of-grading-period-select"
                      fieldValue={selectGradePeriod}
                      fieldName="selectGradePeriod"
                      isDisabled={isEdit}
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderValueDDTest}
                      fieldError={selectGradePeriodError}
                      helperText={selectGradePeriodErrorMsg}
                      fieldMenuItems={[
                        { id: 1, label: "1", value: 1 },
                        { id: 2, label: "2", value: 2 },
                        { id: 3, label: "3", value: 3 },
                        { id: 4, label: "4", value: 4 },
                      ]}
                      fieldStyles={{
                        width: "100%",
                        padding: "10px 0px 10px 10px",
                      }}
                      placeHolderText="Select No. of Grading Period"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} style={{ padding: "10px" }}>
                    <Typography className={classes.fieldLabel}>
                      Description
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Instructions"
                      fieldName="description"
                      multiline
                      fieldId="description-field"
                      textFieldStyles={{ padding: "10px 0", height: "265px" }}
                      fieldValue={description}
                      fieldError={descriptionError}
                      helperText={descriptionErrorMsg}
                      fieldWidth={"100%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                    />

                    <Typography className={classes.fieldLabel}>
                      Grade Period Title
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Period Title"
                      fieldName="gradePeriodTitle"
                      fieldId="grade-title-field"
                      textFieldStyles={{ padding: "10px 0" }}
                      fieldValue={gradePeriodTitle}
                      fieldError={gradePeriodTitleError}
                      helperText={gradePeriodTitleErrorMsg}
                      fieldWidth={"100%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider style={{ margin: "30px 0" }} />
              <Box>
                {selectGradePeriod &&
                  semesterData.map((sem, index) => (
                    <Box key={sem.id} className={classes.termTextFieldWrapper}>
                      <Box>
                        <Box display={"flex"} alignItems={"center"}>
                          <Typography
                            component={"span"}
                            className={`${classes.trim} ${classes.fieldLabel}`}
                          >{`${gradePeriodTitle}`}</Typography>
                          <Typography
                            component={"span"}
                            className={`${classes.fieldLabel}`}
                          >
                            {`${selectGradePeriod >= 2 ? index + 1 : ""} Title`}
                          </Typography>
                        </Box>
                        <CustomTextField
                          placeHolderText="Enter First Name"
                          fieldName={`semester-title-${sem.id}`}
                          fieldId={`title-${sem.id}`}
                          textFieldStyles={{
                            padding: "10px 0",
                            width: "260px",
                          }}
                          fieldValue={sem.title}
                          fieldWidth={"100%"}
                          fieldType="text"
                          fieldError={sem.fieldError}
                          helperText={sem.fieldErrorMsg}
                          handleChange={(e: any) =>
                            this.handleUpdateSemesterTitle(
                              index,
                              e.target.value
                            )
                          }
                        />
                      </Box>
                      <Box>
                        <Box alignItems={"center"} display={"flex"}>
                          <Typography
                            component={"span"}
                            className={`${classes.trim} ${classes.fieldLabel}`}
                          >{`${gradePeriodTitle}`}</Typography>
                          <Typography
                            component={"span"}
                            className={classes.fieldLabel}
                          >{`${
                            selectGradePeriod >= 2 ? index + 1 : ""
                          } Start Date`}</Typography>
                        </Box>
                        <CustomDatepicker
                          disableFutureDate={false}
                          placeHolderText={"DD/MM/YYYY"}
                          format="DD/MM/YYYY"
                          fieldStyles={{ padding: "10px 0px " }}
                          fieldError={sem.startDateError}
                          fieldErrorMsg={sem.startDateErrorMsg}
                          isDisabled={true}
                          fieldId={`term-start-date-${index}`}
                          selectedDate={sem.startDate}
                        />
                      </Box>
                      <Box>
                        <Box display={"flex"} alignItems={"center"}>
                          <Typography
                            component={"span"}
                            className={`${classes.trim} ${classes.fieldLabel}`}
                          >{`${gradePeriodTitle}`}</Typography>
                          <Typography
                            className={classes.fieldLabel}
                          >{`${
                            selectGradePeriod >= 2 ? index + 1 : ""
                          } End Date`}</Typography>
                        </Box>
                        <CustomDatepicker
                          disableFutureDate={false}
                          placeHolderText={"DD/MM/YYYY"}
                          format="DD/MM/YYYY"
                          fieldStyles={{ padding: "10px 0px " }}
                          fieldError={sem.endDateError}
                          fieldErrorMsg={sem.endDateErrorMsg}
                          isDisabled={true}
                          fieldId={`term-end-date-${index}`}
                          selectedDate={sem.endDate}
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
              <Box
                style={{
                  margin: "30px 0",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CustomButton
                  btnStyle={schoolYearCancelBtn}
                  btnText="Cancel"
                  buttonId="school-year-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleClosePopup()}
                />
                <CustomButton
                  btnStyle={schoolYearAddBtn}
                  btnText={isEdit ? "Save" : "Add"}
                  buttonId="school-year-add-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleAddNewSchoolYear()}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const schoolYearAddBtn = {
  fontSize: "16px",
  borderRadius: "12px",
  fontWeight: 600,
  padding: "15px 36px",
} as const;

const schoolYearCancelBtn = {
  borderRadius: "12px",
  marginRight: "15px",
  fontSize: "16px",
  fontWeight: 600,
  border: "1px solid",
  padding: "15px 36px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewSchoolYearDialog);
// Customizable Area End
