// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomPaginationComponentController, {
  Props,
} from "./CustomPaginationComponentController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import CustomButton from "./CustomButton.web";
import Pagination from "@material-ui/lab/Pagination";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    paginationBox: {
      padding: "10px 20px 10px 21px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      '& .Mui-selected': {
        color: "#FFFFFF",
        backgroundColor: '#FFD92E !important',
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
    },
  });

// Customizable Area End

export class CustomPaginationComponent extends CustomPaginationComponentController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, paginationData } = this.props;
    return (
      <Grid container direction="row" >
        <Grid item xs={12}>
          <Box className={classes.paginationBox}>
            <Box style={{ marginTop: '23px', width: '50%' }}>
              <Box display={"flex"} gridGap={"6px"}>
                <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                  Records
                </Typography>
                <Typography
                  component="span"
                  className={`${classes.fontBold600} ${classes.textCommonColor} ${classes.fontText18}`}
                >
                  {(paginationData?.current_page * paginationData?.per_page) - paginationData?.per_page}-{paginationData?.current_page * paginationData?.per_page}
                </Typography>
                <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                  of {paginationData?.total_count}
                </Typography>
              </Box>
            </Box>

            <Box>
            </Box>
            <Box className={classes.paginationRoot}>
              <Pagination
                shape="rounded"
                variant="outlined"
                count={Math.ceil(paginationData?.total_count / paginationData?.per_page)}
                siblingCount={0}
                boundaryCount={1}
                onChange={this.props.handlePaginationChange}
                page={paginationData?.current_page}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const actionButtonStyle = {
  minWidth: "225px",
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(
  CustomPaginationComponent
);
// Customizable Area End
