// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export const courseDetailsTabList = [
  {
    id: 1,
    label: "Resources",
    values: "resources",
  },
  {
    id: 2,
    label: "Journey",
    values: "resources",
  },
  {
    id: 3,
    label: "Participants",
    values: "participants",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  tabValue: number;
  courseName: string;
  selectedTopicName: string;
  selectedLessonName: string;
  isTeacherCourse: boolean;
  isFromStudentTeacher: boolean;
  studentName: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CourseManagementDetailsTopHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.courseMngTopHeader();
    // Customizable Area End
  }
  // Customizable Area Start
  courseMngTopHeader = () => {
    console.log("top header course details")
  }
  // Customizable Area End
}
// Customizable Area End
