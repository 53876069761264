// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
type Course = {
  id: number;
  course_title: string;
};
type Title = {
  id: string;
  name: string;
};
type CourseTableDataItem = {
  id: string;
  title: Title;
  noOfCourses: number;
  grade: string;
  curriculum: string;
  createdBy: string;
  courses: Course[];
};

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  tableBody:CourseTableDataItem[]
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openCatalogCourses: boolean;
  selectedCatalogName: string;
  openAssignCourseModal: boolean;
  openCopyCourseSuccessModal : boolean;
  menuAnchorEl: any;
  selectGradeDDValue: any;
  gradeDDvalues: Array<any>;
  curriculumDDValue: any;
  CurriculumComponents: Array<any>;
  customFieldComponentDDValue: any;
  customFieldItems: Array<any>;
  teacherDDValue: Array<any>;
  teacherComponentDDValue: any;
  subjectDDValue: Array<any>;
  subjectComponentDDValue: any;
  courseGradeDDValue: Array<any>;
  courseGradeComponentDDValue: any;
  schoolYearDDValue: Array<any>;
  schoolYearComponentDDValue: any;
  courseUtilizationDDValue: Array<any>;
  courseUtilizationComponentDDValue: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherCatalogTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,
      selectGradeDDValue: "",
      curriculumDDValue: "",
      customFieldComponentDDValue: "",
      teacherComponentDDValue: "",
      subjectComponentDDValue: "",
      courseGradeComponentDDValue: "",
      schoolYearComponentDDValue: "",
      courseUtilizationComponentDDValue: "",
      gradeDDvalues: [
        {
          id: 1,
          label: "Class 10A",
          value: 1,
        },
        {
          id: 2,
          label: "Class 10B",
          value: 2,
        },
        {
          id: 3,
          label: "Class 9A",
          value: 3,
        },
        {
          id: 4,
          label: "Assignment",
          value: 4,
        },
      ],
      CurriculumComponents: [
        {
          id: 1,
          label: "Curriculum A",
          value: 1,
        },
        {
          id: 2,
          label: "Curriculum B",
          value: 2,
        },
        {
          id: 3,
          label: "Curriculam c",
          value: 3,
        },
        {
          id: 4,
          label: "Curriculum D",
          value: 4,
        },
      ],
      customFieldItems: [
        {
          id: 1,
          label: "Custom Field 1",
          value: 1,
        },
        {
          id: 2,
          label: "Custom Field 2",
          value: 2,
        },
        {
          id: 3,
          label: "Custom Field 3",
          value: 3,
        },
        {
          id: 4,
          label: "Custom Field 4",
          value: 4,
        },
      ],
      teacherDDValue: [
        {
          id: 1,
          label: "Teacher 1",
          value: 1,
        },
        {
          id: 2,
          label: "Teacher 2",
          value: 2,
        },
        {
          id: 3,
          label: "Teacher 3",
          value: 3,
        },
        {
          id: 4,
          label: "Teacher 4",
          value: 4,
        },
      ],
      subjectDDValue: [
        {
          id: 1,
          label: "Subject 1",
          value: 1,
        },
        {
          id: 2,
          label: "Subject 2",
          value: 2,
        },
        {
          id: 3,
          label: "Subject 3",
          value: 3,
        },
        {
          id: 4,
          label: "Subject 4",
          value: 4,
        },
      ],
      courseGradeDDValue: [
        {
          id: 1,
          label: "Grade 1",
          value: 1,
        },
        {
          id: 2,
          label: "Grade 2",
          value: 2,
        },
        {
          id: 3,
          label: "Grade 3",
          value: 3,
        },
        {
          id: 4,
          label: "Grade 4",
          value: 4,
        },
      ],
      schoolYearDDValue: [
        {
          id: 1,
          label: "2021",
          value: 1,
        },
        {
          id: 2,
          label: "2022",
          value: 2,
        },
        {
          id: 3,
          label: "2023",
          value: 3,
        },
      ],
      courseUtilizationDDValue: [
        {
          id: 1,
          label: "Course Utilization 1",
          value: 1,
        },
        {
          id: 2,
          label: "Course Utilization 2",
          value: 2,
        },
        {
          id: 3,
          label: "Course Utilization 3",
          value: 3,
        },
      ],
      openCatalogCourses: false,
      selectedCatalogName: "",
      openAssignCourseModal: false,
  openCopyCourseSuccessModal : false,
      menuAnchorEl: null,
    };
    // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
  }
  handleGradeDDValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };

  renderGradeDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { gradeDDvalues } = this.state;
    const selectedGradingComponents = gradeDDvalues.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderCurriculumsDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculam";
    }
    const { CurriculumComponents } = this.state;
    const selectedGradingComponents = CurriculumComponents.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  rendercustomFieldsDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { customFieldItems } = this.state;
    const selectedGradingComponents = customFieldItems.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderTeacherDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Teacher";
    }
    const { teacherDDValue } = this.state;
    const selectedGradingComponents = teacherDDValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderSubjectDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { subjectDDValue } = this.state;
    const selectedGradingComponents = subjectDDValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderCourseGradeDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { courseGradeDDValue } = this.state;
    const selectedGradingComponents = courseGradeDDValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderSchoolYearDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { schoolYearDDValue } = this.state;
    const selectedGradingComponents = schoolYearDDValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderCourseUtilizationDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Course Utilization";
    }
    const { courseUtilizationDDValue } = this.state;
    const selectedGradingComponents = courseUtilizationDDValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };
  handleSearchButton = () => {
    console.log("search");
  };

  switchCatalogCourses = (data: any) => {
    this.setState({ selectedCatalogName: data });
    this.setState({ openCatalogCourses: !this.state.openCatalogCourses });
  };

  handleOpenAssignCoursesModal = () => {
    this.setState({ openAssignCourseModal: true });
    this.setState({ menuAnchorEl: null });
  };

  handleCloseAssignCourseModal = () => {
    this.setState({ openAssignCourseModal: false });
  };

  handleActionIcon = (e: any, rowItemId: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget });
  };

  handleMenuCloseIcon = () => {
    this.setState({ menuAnchorEl: null });
  }; 
   
  handleCopyCourseSuccessModal =()=>{
    this.setState({ openCopyCourseSuccessModal: true });
    this.setState({ menuAnchorEl: null });
  }

  handleCourseSuccessModalModal =()=>{
    this.setState({ openCopyCourseSuccessModal: false });
  }
  // Customizable Area End
}
// Customizable Area End
