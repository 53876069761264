import React from "react";

import {
    Box,
    Input,
    Typography,
    InputAdornment,
    Grid,
    Tabs,
    Tab
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { TabPanel, a11yProps } from "../../../../components/src/CustomTabPanel.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";

// Customizable Area Start
import CustomButton from "../../../../components/src/CustomButton.web";
import MyCoursesTab from "./MyCoursesTab.web";
import CatalogTab from "./CatalogTab.web";
import { searchIcon } from "../assets";
import AddNewCourseModal from "./AddNewCourseModal.web";

const styles = (theme: Theme) =>
    createStyles({
        mainContainerPadding: {
            padding: "40px"
        },
        pageName: {
            marginTop: "16px",
            marginBottom: "28px"
        },
        assessmentListMainContainer: {
            maxHeight: "700vh",
            overflowX: "auto",
            borderRadius: "10px",
            padding: "8px 0px"
        },
        tabButton: {
            backgroundColor: "transparent",
            padding: "20px 4px",
            textAlign: "center",
            textDecoration: "none",
            textTransform: "capitalize",
            display: "inline-block",
            fontSize: "20px",
            opacity: 1,
            width: "100%",
            "&.Mui-selected": {
                backgroundColor: "transparent",
                color: "#0D46BC !important",
                outline: "none",
                borderBottom: "1px solid #0D46BC",
                fontWeight: 500
            },
            "&.MuiTab-root": {
                minHeight: "48px"
            }
        },
        tabPanel: {
            padding: "15px 0px",
            "& #scrollable-auto-tabpanel-0": {
                minHeight: "90vh",
            },
            "& #scrollable-auto-tabpanel-1": {
                minHeight: "90vh",
            },
        },
        tabs: {
            "& div.MuiTabs-scroller": {
                borderBottom: "1px solid #979797",
                "& .MuiTabs-flexContainer": {
                    borderBottom: "none",
                    justifyContent: "center"
                }
            }
        },
        searchInput: {
            width: "180px",
            height: "50px",
            padding: "17px 15px",
            borderRadius: "12px",
            border: "1px solid #888",
            fontSize: "14px",
            fontWeight: 400,
            float: "right",
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-disabled)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            }
        },
    })
// Customizable Area End

import CourseManagementController, {
    Props,
    assessmentCategoryTabs
} from "./CourseManagementController";

export class CourseManagement extends CourseManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes, navigation } = this.props;
        const { selectedAssessmentCategoryTab, selectedTabName, openAddNewCourseModal } = this.state;
        return (
            // Customizable Area Start
            <Box className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`} height={"100%"}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box marginTop={"1%"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
                                Course Management
                            </Typography>
                            {
                                selectedTabName ?
                                    <Typography className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
                                        {` / ${selectedTabName}`}
                                    </Typography>
                                    : ''
                            }
                        </Box>
                    </Grid>
                    <Grid className={classes.pageName} item xs={12}>
                        <Typography className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`} component={"span"}>
                            Course Management

                        </Typography>
                        {
                            selectedAssessmentCategoryTab === 0 ?
                                <CustomButton btnText="Add New Course" btnStyle={webStyle.addNewCourseButton} buttonId={`add-new-course-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleOpenAssignCourseModal} isDisabled={false} />
                                :
                                <CustomButton btnText="My Requests" btnStyle={webStyle.addNewCourseButton} buttonId={`add-new-course-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleOpenRequestPage} isDisabled={false} />
                        }
                        <Input
                            endAdornment={
                                <InputAdornment position="start" disablePointerEvents={true}>
                                    <img src={searchIcon} alt="search icon" />
                                </InputAdornment>
                            }
                            className={`${classes.searchInput} ${classes.bgColor} ${classes.textPrimaryColor}`}
                            type="text"
                            placeholder="Search by Title"
                            inputProps={{
                                disableUnderline: true,
                                variant: 'standard',
                            }}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <Grid className={`${classes.bgColor} ${classes.assessmentListMainContainer}`} container direction="row">
                            <Grid item xs={12}>
                                <Tabs variant="fullWidth" scrollButtons="auto" value={selectedAssessmentCategoryTab} className={`${classes.tabs}`} data-testid="student-assessment-tabs" onChange={this.handleChangeAssessmentCategoryTab} aria-label="student-assessment-tabs"
                                    TabIndicatorProps={{
                                        style: {
                                            background: "none"
                                        }
                                    }}
                                >
                                    {assessmentCategoryTabs.map((tabItem: any, index: number) =>
                                        <Tab key={tabItem.id} data-testid={`student-assessment-${tabItem.label}-tab`} label={tabItem.label} wrapped {...a11yProps(index)} className={`${classes.tabButton} ${classes.textPrimaryColor}`} />
                                    )}
                                </Tabs>
                            </Grid>
                            <Grid className={`${classes.tabPanel}`} item xs={12}>
                                <TabPanel value={selectedAssessmentCategoryTab} index={0}>
                                    <MyCoursesTab navigation={navigation} />
                                </TabPanel>
                                <TabPanel value={selectedAssessmentCategoryTab} index={1}>
                                    <CatalogTab navigation={navigation} />
                                </TabPanel>

                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <AddNewCourseModal
                    open={openAddNewCourseModal}
                    onCancel={this.handleCloseAssignCourseModal}
                    onConfirm={this.handleOpenAssignCourseModal}
                />
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    addNewCourseButton: {
        height: "50px",
        padding: "15px 20px",
        textAlign: "center",
        width: "max-content",
        borderRadius: "12px",
        fontWeight: 500,
        marginLeft: "20px",
        fontSize: "18px",
        float: "right",
    }
};
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CourseManagement);
// Customizable Area End
