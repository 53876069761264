// Customizable Area Start
import React from "react";
import { Box, Grid, Paper, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomStepperWeb from "../../../components/src/CustomStepper.web";

import EditGradeBookTemplateController, {
    Props,
  } from "./EditGradeBookTemplateController";
import  ContentTypeModel  from "./ContentTypeModel.web";
import  AddContentModel  from "./AddContentModel.web";
import  CustomButton  from "../CustomButton.web";


// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    studentAddUserMainBox: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 0px 0px",
    },
    textEditorContent:{
      padding: "14px",
      wordWrap: "break-word", 
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
      overflow: "hidden", 
      maxWidth: "100%", 
    },
    studentAddUserDDGrid: {
      marginBottom: "30px",
      padding: "20px",
      borderBottom: "4px solid #f0f0f0",
    },
    studentSelectDD: {
      marginBottom: "10px",
    },
    studentAddUserPageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    studentSelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    gridItem: {
        height:"100%",
        flex:1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius:"12px",
        cursor: 'pointer',
        boxShadow:'none',
        width:"100%"

      },
      contentPreview: {
        border: "1px solid #ddd",
        borderRadius:"12px",
        // padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        overflow: "auto",
        height: "100%",
        width:"100%"
      },
      addRemarkModal: {
        "& .MuiPaper-rounded": {
            borderRadius: "8px",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "100%",
            overflowY: "hidden",
            // width: "806px",
            // minHeight: "500px",
            overflowX: "hidden",
        }
    },
    customTextarea: {
        borderRadius: "12px",
        width: "96%",
        border: "1px solid #888",
        padding: "17px 15px",
        fontSize: "14px",
        fontWeight: 400,
        marginTop: "10px",
        "&:hover:not(.Mui-)::before": {
            borderRadius: "12px",
            borderBottom: "1px solid #888",
        },
        "&.MuiInputBase-root.Mui-": {
            background: "#F3F3F3"
        },
        "& .MuiInput-underline::before": {
            borderBottom: "0px",
            content: "none",
        },
        "&::before": {
            borderBottom: "0px"
        },
        "&::after": {
            borderBottom: "0px"
        },
        "& .MuiInput-underline::after": {
            borderBottom: "0px",
        },
    },
    customInput: {
        height: "50px",
        borderRadius: "12px",
        width: "100%",
        padding: "17px 15px",
        border: "1px solid #888",
        marginTop: "10px",
        fontWeight: 400,
        fontSize: "14px",
        "&:hover:not(.Mui-)::before": {
            borderBottom: "1px solid #888",
            borderRadius: "12px",
        },
        "&.MuiInputBase-root.Mui-": {
            background: "#F3F3F3"
        },
        "&::before": {
            borderBottom: "0px"
        },
        "&::after": {
            borderBottom: "0px"
        },
    },
  });
// Customizable Area End

// Customizable Area Start
export class EditGradeBookTemplate extends EditGradeBookTemplateController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,templateType} = this.props;
    const {
    
      screenName,
      steps,
      openContentType,
      searchParentText,
      isStepWithMultipleScreens,
      openContentModal
    } = this.state;

    return (
          <Grid item xs={12}>
            <Box
              className={`${classes.bgColor} ${classes.studentAddUserMainBox}`}
            >
              <CustomStepperWeb
                stepperId="add-student-parent-detail-steppper"
                steps={steps}
                finishLabel="Submit"
                nextBtnLabel="Next"
                prevBtnLabel="Previous"
                handleNext={this.handleNextStepper}
                handlePrevious={this.handlePreviousStepper}
                showPreview
                handleShowPreview={this.handleOpenPreview}
                handleSave={this.handleSubmitDetails}
                isStepWithMultipleScreens={isStepWithMultipleScreens}
                pause={false}
              >
                {screenName === "front_back" && (
                  
                <Grid
                className={classes.studentAddUserDDGrid} container  direction={templateType !== "portrait" ? 'row' : 'column'}
                  >
                  <Grid container spacing={3} >
                  {this.state.gridContent.map((item:any, index) => (
                    <Grid item xs={12} sm={templateType !== "portrait" ? 6 : 12} key={index} style={{display:'flex'}}>
                      <Paper 
                      style={{minHeight:templateType !== "portrait" ? (index===2 || index === 3) ? 318 :138 :(index===2 ||index==1|| index === 3) ? 250 :138 }} 
                      onClick={() => this.handleOpenContentType(index,item.blockType, item.blockTypeContent,item.content)} className={classes.gridItem + ' ' + classes.secondaryBgColor }>
                         {["StudentInformation", "Attendance", "Behavior", "Grades"].includes(item.blockType) ? (
                            <>{item.content}</>
                          ) : (
                            <Typography 
                        
                            dangerouslySetInnerHTML={{ __html: item.content }} className={`${classes.commentText} ${classes.textCommonColor} ${classes.textEditorContent}`} 
                           >
                            </Typography>
                          )}
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
                </Grid>
               
                )}
                 {screenName === "back_page" && (
                    <Grid
                    className={classes.studentAddUserDDGrid} container  direction={templateType !== "portrait" ? 'row' : 'column'}
                      >
                      <Grid container spacing={3} >
                      {this.state.gridContentBackPage.map((item:any, index) => (
                        <Grid item xs={12} sm={templateType !== "portrait" ? 6 : 12} key={index} style={{display:'flex'}}>
                          <Paper 
                          style={{minHeight:templateType !== "portrait" ? (index===2 || index === 3) ? 318 :138 :(index===2 ||index==1|| index === 3) ? 250 :138 }}
                          onClick={() => this.handleOpenContentType(index,item.blockType, item.blockTypeContent,item.content)} className={classes.gridItem + ' ' + classes.secondaryBgColor }>
                             {["StudentInformation", "Attendance", "Behavior", "Grades"].includes(item.blockType) ? (
                                <>{item.content}</>
                              ) : (
                                <Typography    
                                
                                dangerouslySetInnerHTML={{ __html: item.content }} 
                                className={`${classes.commentText} ${classes.textEditorContent} ${classes.textCommonColor}`} 
                               >
                                </Typography>
                              )}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                    </Grid>
              
                )}

              </CustomStepperWeb>
            </Box>
            <Box>
                <Dialog className={`${classes.highlightBgColor} ${classes.addRemarkModal}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={this.state.isPreviewOpen} scroll={'body'}>
                    <Box padding={"30px"} className={`${classes.bgColor}`}>
                        <Grid direction="row" container>
                            <Grid xs={12} item>
                                <Typography className={`${classes.fontText24} ${classes.textPrimaryColor}`} component={"span"}>Preview {this.state.screenName === "back_page" ? "Back Page"  :"Front Page"}</Typography>
                                <Button onClick={this.handleClosePreview} className={classes.textPrimaryColor} style={{ float: "right" }}> X </Button>
                            </Grid>
                        </Grid>
                        <DialogContent style={{ marginTop: "24px", overflow: "hidden" }}>
                            <Grid container spacing={2} direction="row">
                                <Grid xs={12} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                    <Grid container spacing={3} className={classes.contentPreview}>
                                    {(screenName === "back_page" ? this.state.gridContentBackPage : this.state.gridContent).map((item:any, index) => {
                                      if (item.content !== "+Edit") {
                                        return (
                                          <Grid style={{display:'flex'}} item xs={12} sm={templateType !== "portrait" ? 6 : 12} key={index}>
                                              <Paper 
                                                className={classes.gridItem + ' ' + classes.secondaryBgColor }>
                                            {["StudentInformation", "Attendance", "Behavior", "Grades"].includes(item.blockType) ? (
                                              <>{item.content}</>
                                            ) : (
                                              <Typography  
                                                            
                                               dangerouslySetInnerHTML={{ __html: item.content }} 
                                               className={`${classes.commentText} ${classes.textEditorContent} ${classes.textCommonColor}`} 
                                            >
                                              </Typography>
                                            )}
                                            </Paper>
                                          </Grid>
                                        );
                                      }
                                      else{
                                        return (
                                          <Grid item xs={12} sm={6} key={index}>
                                        
                                          </Grid>
                                        )
                                      }
                                    })}
                                   
                                     
                                      </Grid>
                                      
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{ width: "100%" }} marginBottom={"10px"}>
                                        <CustomButton btnText="Close" btnStyle={cancelButtonStyle} buttonVariant={"outlined"} buttonId={`add-remark-cancel-button`} type={"button"} handleButtonClick={this.handleClosePreview} isDisabled={false} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                </Dialog>
            </Box>
     
            <ContentTypeModel
            open={openContentType}
            onCancel={this.handleCloseContentType}
            onConfirm={this.handleOpenAddContent}      
            selectedBlockType={this.state.selectedBlockType}
            handleActivityTypeChange={this.handleActivityTypeChange}
            handleDataBlockTypeChange={this.handleDataBlockTypeChange}
            selectedDataBlockPlacement={this.state.selectedDataBlockPlacement}
            handleAddNewActivity={(data: any) =>
             console.warn("data")
            }
            handleUpdateActivity={(data: any) =>
             console.warn("data")
            }
          />
            <AddContentModel  
            open={openContentModal}
            onCancel={this.handleCloseAddContent}
            onConfirm={this.handleSaveDialog}
            dialogInput={this.state.dialogInput}
            handleEditorChange={this.handleEditorChange}
          
          />
         
          </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStyle = {
  width: "100%",
} as const;
const saveButtonStyle = {
  height: "50px",
  padding: "10px 10px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "10px",
  float: "right",
  fontWeight: 500,
  marginLeft: "15px",
} as const;

const cancelButtonStyle = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  borderRadius: "12px",
  border: "1px solid",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "10px",
  float: "right",
  marginLeft: "15px",
} as const;
const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(EditGradeBookTemplate);
// Customizable Area End
