// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogTitle, Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MyCoursesInfoDialogController, {
    Props
} from "./MyCoursesInfoDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from '../../../components/src/CombineStyles.web';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        myCourseInfoDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px"
            }
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })

// Customizable Area End

// Customizable Area Start
export class MyCoursesInfoDialog extends MyCoursesInfoDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, courseInfo, open } = this.props;
        return (
            <Dialog data-testid="my-courses-info-dialog" className={`${classes.highlightBgColor} ${classes.myCourseInfoDialog}`} onClose={this.props.onClose} aria-labelledby="customized-dialog-title" open={open}>
                <Box className={`${classes.bgColor}`}>
                    <DialogTitle id="customized-dialog-title">
                        <Typography gutterBottom component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600}`}>{courseInfo?.title}</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box mb={"10px"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                {courseInfo?.information}
                            </Typography>
                        </Box>
                        <Box mb={"10px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                Objectives:
                            </Typography>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                {courseInfo?.objectives}
                            </Typography>
                        </Box>

                        <Box mb={"10px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                Student Instructions:
                            </Typography>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                {courseInfo?.instruction}
                            </Typography>
                        </Box>

                        <Box mb={"10px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                Student Materials
                            </Typography>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}>
                                {courseInfo?.materials}
                            </Typography>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MyCoursesInfoDialog);
// Customizable Area End
