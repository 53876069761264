// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentPerformanceController, {
  Props,
} from "./StudentPerformanceController.web";
import CustomStudentProgressBar from "../../../components/src/CustomStudentProgressBar.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomEmptyResultComponentWeb from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    chartWrapper: {
      position: "relative",
      "& canvas": {
        position: "absolute",
        left: 0,
        top: 0,
        // pointerEvents: "none",
        maxWidth: "1400px",
        minWidth: "auto",
        height: "auto !important",
      },
    },
    chartAreaWrapper: {
      overflowX: "auto",
      width: "100%",
      position: "relative",
    },
    chartAreaSecondWrapper: {
      position: "relative",
      height: "268px",
    },
  });
// Customizable Area End

// Customizable Area Start
export class StudentPerformance extends StudentPerformanceController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    
    return (
      <Box className={classes.chartWrapper} maxWidth={"100%"} overflow="auto">
        <Box className={classes.chartAreaWrapper}>
          <Box className={classes.chartAreaSecondWrapper}>
            {this.state.studentProgressPerformanceData.length > 0 ? (
              <CustomStudentProgressBar
                progressGraphData={{
                  labels: this.getPerformanceGraphLabels(),
                  datasets: [
                    {
                      data: this.getPerformanceGraphData(),
                      borderRadius: 50,
                      backgroundColor: this.getPerformanceGraphBarBgColor(),
                      barPercentage: 0.6,
                      categoryPercentage: 0.7,
                    },
                  ],
                }}
                progressChartLable="Performance"
                progressTitleColor={this.handlePerformanceGraphColor()}
                progressgridlineColor="#f1f1f1"
                onBarClick={(data: {
                    datasetIndex: number,
                    dataIndex: number,
                    clickedValue: number
                  }) => this.handleBarClick(data)}
              />
            ): <CustomEmptyResultComponentWeb marginStyle="20px 0px !important" message="No Performance data yet." />}
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentPerformance);
// Customizable Area End
