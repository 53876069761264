// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgActivities = require("../assets/image_activities@3x.png");
export const imgLessons = require("../assets/image_lessons@3x.png");
export const imgTimeSpent = require("../assets/time-spent-icon.svg");
export const articleImg = require("../assets/article.png");
export const videoThumbnail = require("../assets/video_thumbnail.png");
export const mathSubjectIcon = require("../assets/math.png");
export const physicsSubjectIcon = require("../assets/physics.png");
export const englishSubjectIcon = require("../assets/ABC.png");
export const psychologySubjectIcon = require("../assets/brain.png");
export const historySubjectIcon = require("../assets/scroll.png");
export const statisticsIcon = require("../assets/statistics.png");
export const profilePic1 = require("../assets/profile-1.png");
export const profilePic2 = require("../assets/profile-2.png");
export const trashIcon = require("../assets/trashIcon.png");
export const exclamationIcon = require("../assets/exclamationIcon.png");
export const man2 = require("../assets/man2.png");
export const man4 = require("../assets/man4.png");
export const man5 = require("../assets/man5.png");
export const man6 = require("../assets/man6.png");
export const alert = require("../assets/redalert.png");
export const sort = require("../assets/sort.png");
export const Oval = require("../assets/Oval.png");
export const TimeSpent = require("../assets/time-spent.png");
export const searchIcon = require("../assets/search.svg");
export const ScienceColor = require("../assets/science-color.svg");
export const HistoryColor = require("../assets/history-color.svg");
export const MathsColor = require("../assets/maths-color.svg");
export const EnglishColor = require("../assets/english-color.svg");
export const ConfirmIcon = require("../assets/confirm-icon.svg");
export const SuccessIcon = require("../assets/success-icon.svg");
export const plus = require("../assets/plus.png");
export const Shape = require("../assets/Shape.png");
export const CheckMark = require("../assets/CheckMark.png");
export const infoIcon = require("../assets/infoIcon.svg");
export const videoPreviewThumbnail = require("../assets/videoPreviewThumbnail.png");
export const previewVideo = require("../assets/previewVideo.mp4");
export const mockdata = require("../assets/mockdata");
export const redTrashIcon = require("../assets/trash.svg");
export const redPenIcon = require("../assets/pen.svg");
export const message = require("../assets/m4.png")
export const invalidImage = require("../assets/invalidImage.svg")

// Customizable Area End