// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const ReadIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.8865 16.7578C21.8865 15.5316 21.0128 14.5058 19.8552 14.2698V11.5273C19.8552 11.2775 19.7327 11.0436 19.5274 10.9013C19.322 10.7589 19.0599 10.7263 18.826 10.8141L17.3722 11.3593C16.4175 10.3668 15.2571 9.61924 13.9854 9.16033C15.235 8.23438 16.0466 6.74934 16.0466 5.07812C16.0466 2.278 13.7686 0 10.9685 0C8.16838 0 5.89038 2.278 5.89038 5.07812C5.89038 6.74934 6.70197 8.23438 7.95159 9.16033C6.67988 9.61929 5.51947 10.3668 4.56479 11.3593L3.11097 10.8141C2.87707 10.7263 2.61494 10.7589 2.40963 10.9013C2.20427 11.0436 2.08179 11.2775 2.08179 11.5273V14.2698C0.924229 14.5058 0.0505371 15.5316 0.0505371 16.7578C0.0505371 17.984 0.924229 19.0099 2.08179 19.2458V22.1914C2.08179 22.5089 2.27877 22.7932 2.57604 22.9046L10.701 25.9515C10.8724 26.0154 11.0646 26.0154 11.2359 25.9515L19.3609 22.9046C19.6582 22.7932 19.8552 22.5089 19.8552 22.1914V19.2458C21.0128 19.0099 21.8865 17.984 21.8865 16.7578ZM7.41382 5.07812C7.41382 3.11807 9.00845 1.52344 10.9685 1.52344C12.9286 1.52344 14.5232 3.11807 14.5232 5.07812C14.5232 7.03818 12.9286 8.63281 10.9685 8.63281C9.00845 8.63281 7.41382 7.03818 7.41382 5.07812ZM15.7876 11.9536C14.4576 10.8012 12.7531 10.1562 10.9685 10.1562C9.18395 10.1562 7.47943 10.8012 6.14942 11.9536L10.9685 13.7607L15.7876 11.9536ZM1.57397 16.7578C1.57397 16.1978 2.02958 15.7422 2.5896 15.7422H3.09741V17.7734H2.5896C2.02958 17.7734 1.57397 17.3178 1.57397 16.7578ZM4.62085 18.0273C4.62085 18.6404 4.18398 19.1534 3.60522 19.2713V21.6636L10.2068 24.1392V15.1021L3.60522 12.6265V14.2443C4.18398 14.3623 4.62085 14.8752 4.62085 15.4883V18.0273ZM11.7302 24.1391V15.1021L18.3318 12.6265V14.2443C17.753 14.3623 17.3162 14.8752 17.3162 15.4883V18.0273C17.3162 18.6404 17.753 19.1534 18.3318 19.2713V21.6635L11.7302 24.1391ZM18.8396 17.7734H19.3474C19.9075 17.7734 20.363 17.3178 20.363 16.7578C20.363 16.1978 19.9074 15.7422 19.3474 15.7422H18.8396V17.7734Z" fill={props.fill} />
        </SvgIcon>
    );
}

export default ReadIcon;
// Customizable Area End




