export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const englishSubjectIcon = require("../assets/ABC.png");
export const historySubjectIcon = require("../assets/scroll.png");
export const mathSubjectIcon = require("../assets/math.png");
export const physicsSubjectIcon = require("../assets/physics.png");
export const psychologySubjectIcon = require("../assets/brain.png");
export const profilePic1 = require("../assets/profile-1.png");
export const profilePic2 = require("../assets/profile-2.png");
export const man = require("../assets/man.png");
export const man2 = require("../assets/man2.png");
export const man3 = require("../assets/man3.png");
export const man4 = require("../assets/man4.png");
export const man5 = require("../assets/man5.png");
export const man6 = require("../assets/man6.png");
export const sort = require("../assets/sort.png");
export const alert = require("../assets/redalert.png");
export const exclamationIcon = require("../assets/exclamationIcon.png");
export const rightTick = require("../assets/righttick.png");

