// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import CustomTextField from "../../../components/src/CustomTextField.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CreateEventDialogController, {
  Props,
} from "./CreateEventDialogController.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomMultiSelectDropDownV2 from "../../../components/src/CustomMultiSelectDropDownV2.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
import CustomDateTimePickerWeb from "../../../components/src/CustomDateTimePicker.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    crossIcon: {
      cursor: "pointer",
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(2.6),
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(4),
        top: theme.spacing(5),
        width: theme.spacing(1.5),
      },
    },
    paperRoot: {
      maxWidth: "100%",
      width: "880px",
    },
    rootDialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    inputLabelText: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    colorOption: {
      display: "flex",
      alignItems: "center",
    },
    colorCircle: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    dateTextField: {
      backgroundColor: "#FFFFFF",
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
      },
      width: "96%",
    },
    boxWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class CreateEventDialog extends CreateEventDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      open,
      editFlag,
      classesList,
      curriculumList,
    } = this.props;
    const {
      eventTitle,
      eventTitleError,
      eventTitleErrorMsg,
      selectedColor,
      selectedColorName,
      selectedColorError,
      selectedColorErrorMsg,
      selectCurriculum,
      selectCurriculumError,
      selectCurriculumErrorMsg,
      selectClass,
      selectClassError,
      selectClassErrorMsg,
      startDateTime,
      startDateTimeError,
      startDateTimeErrorMsg,
      endDateTime,
      endDateTimeError,
      endDateTimeErrorMsg,
    } = this.state;
    return (
      <Dialog
        classes={{ paper: classes.paperRoot }}
        className={`${classes.highlightBgColor} ${classes.rootDialog}`}
        open={open}
      >
        <Box>
          <DialogTitle
            id="new-manage-events-dialog-title"
            className={`${classes.bgColor}`}
          >
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
            >
              {editFlag ? "Edit Event" : "Create an Event"}
            </Typography>
            <CloseIcon
              id="An icon to close Modal"
              className={`${classes.crossIcon} ${classes.textSecondaryColor}`}
              onClick={this.handleClosePopup}
            />
          </DialogTitle>
          <DialogContent className={`${classes.bgColor}`}>
            <Grid container direction="row">
              <Grid item xs={12} sm={12} md={6}>
                <Box width={"100%"}>
                  <Typography className={classes.inputLabelText}>
                    Event Title
                  </Typography>
                  <CustomTextField
                    placeHolderText="Enter Event Title"
                    fieldName="eventTitle"
                    fieldId="event-title-field"
                    textFieldStyles={{
                      width: "96%",
                      padding: "10px 0px",
                    }}
                    fieldValue={eventTitle}
                    fieldWidth={"100%"}
                    fieldType="text"
                    handleChange={this.handleChange}
                    fieldError={eventTitleError}
                    helperText={eventTitleErrorMsg}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box width={"100%"}>
                  <Typography className={classes.inputLabelText}>
                    Color
                  </Typography>
                  <CustomColorPickerFieldWeb
                    fieldId="add-event-color-picker"
                    fieldName="selectedColor"
                    handleColorChange={this.handleColorChange}
                    fieldWidth="100%"
                    fieldValue={selectedColorName}
                    fieldValueHexCode={selectedColor}
                    textFieldStyles={{ width: "96%", padding: "6px 0px" }}
                    placeHolderText={"Select Color"}
                    fieldError={selectedColorError}
                    helperText={selectedColorErrorMsg}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box width={"100%"}>
                  <Typography className={classes.inputLabelText}>
                    Curriculum
                  </Typography>
                  <CustomMultiSelectDropDownV2
                    label="Select Curriculum"
                    fieldMenuItems={curriculumList}
                    fieldStyles={{
                      width: "96%",
                    }}
                    fieldId={"event-curriculum-dropdown"}
                    removeSelectedValue={this.removeSelectedCurriculum}
                    selectedItems={selectCurriculum}
                    handleChange={this.handleChangeCurriculum}
                    fieldError={selectCurriculumError}
                    helperText={selectCurriculumErrorMsg}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box width={"100%"}>
                  <Typography className={classes.inputLabelText}>
                    Class
                  </Typography>
                  <CustomMultiSelectDropDownV2
                    label="Select Class"
                    fieldMenuItems={classesList}
                    fieldStyles={{
                      width: "96%",
                    }}
                    fieldId={"event-class-dropdown"}
                    removeSelectedValue={this.removeSelectedClass}
                    selectedItems={selectClass}
                    handleChange={this.handleChangeClass}
                    fieldError={selectClassError}
                    helperText={selectClassErrorMsg}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.DateName}>
                  <Typography className={`${classes.inputLabelText}`}>
                    {`Start Date & Time`}
                  </Typography>
                  <CustomDateTimePickerWeb
                    selectedDate={startDateTime}
                    fieldError={startDateTimeError}
                    fieldErrorMsg={startDateTimeErrorMsg}
                    fieldId="event-start-date"
                    format="dd/MM/yyyy hh:mm a"
                    placeHolderText="Select Date&Time"
                    isDisabled={false}
                    handleChangeDate={(date: any) => this.selectStartDate(date)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.DateName}>
                  <Typography className={`${classes.inputLabelText}`}>
                    {`End Date & Time`}
                  </Typography>
                  <CustomDateTimePickerWeb
                    selectedDate={endDateTime}
                    fieldError={endDateTimeError}
                    fieldErrorMsg={endDateTimeErrorMsg}
                    fieldId="event-end-date"
                    format="dd/MM/yyyy hh:mm a"
                    placeHolderText="Select Date&Time"
                    isDisabled={false}
                    handleChangeDate={(date: any) => this.selectEndDate(date)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    width: "98%",
                    margin: "30px 0",
                    justifyContent: "end",
                  }}
                >
                  <CustomButton
                    btnStyle={cancelbtnStyle}
                    btnText="Cancel"
                    buttonId="manage-events-cancel-btn"
                    buttonVariant={"outlined"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={() => this.handleClosePopup()}
                  />
                  <CustomButton
                    btnStyle={addBtnStyle}
                    btnText="Save"
                    buttonId="manage-events-add-btn"
                    buttonVariant={"contained"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={() => this.handleAddEvent()}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addBtnStyle = {
  height: "50px",
  width: "120px",
  borderRadius: "12px",
  padding: "15px 36px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const cancelbtnStyle = {
  height: "50px",
  width: "120px",
  borderRadius: "12px",
  marginRight: "15px",
  padding: "15px 36px",
  fontWeight: 600,
  fontSize: "16px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CreateEventDialog);
// Customizable Area End
