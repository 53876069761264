// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCourseType: string;
  openCourseCatalogModal: boolean;
  selectedCurriculamValue: string;
  selectedSubjectValue: string;
  selectedGradeValue: string;
  selectedStatusValue: string;
  selectedSchoolYearValue: string;
  selectedTermValue: string;
  selectedCustomFieldValue: string;
  selectedIcon: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddNewCourseModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      selectedCurriculamValue: "",
      selectedSubjectValue: "",
      selectedGradeValue: "",
      selectedStatusValue: "",
      selectedSchoolYearValue: "",
      selectedTermValue: "",
      selectedCustomFieldValue: "",
      selectedCourseType: "New",
      openCourseCatalogModal: false,
      selectedIcon: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();

  }

  handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    this.setState({
      selectedIcon: file
    })
    // Handle the selected file here
    console.log(file);
  };

  handleFileUpload = () => {
    console.log("Uploading file:", this.state.selectedIcon);
  };

  handleBrowseClick = () => {
    document?.getElementById('file-input')?.click();
  };

  checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
    return selctedOption?.label;
  }

  renderCurriculamDropdownValue = (selected: any) => {
    const dropdownName = "Curriculam";
    const dropdownItems = this.getDropdownItemsArray('Curriculam');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderSubjectDropdownValue = (selected: any) => {
    const dropdownName = "Subject";
    const dropdownItems = this.getDropdownItemsArray('Subject');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };



  renderStatusDropdownValue = (selected: any) => {
    const dropdownName = "Status";
    const dropdownItems = this.getDropdownItemsArray('Status');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderSchoolYearDropdownValue = (selected: any) => {
    const dropdownName = "School Year";
    const dropdownItems = this.getDropdownItemsArray('202');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderTermDropdownValue = (selected: any) => {
    const dropdownName = "Term";
    const dropdownItems = this.getDropdownItemsArray('0');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderCustomFieldDropdownValue = (selected: any) => {
    const dropdownName = "Custom Field";
    const dropdownItems = this.getDropdownItemsArray('Custom Field');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderGradeDropdownValue = (selected: any) => {
    const dropdownName = "Grade";
    const dropdownItems = this.getDropdownItemsArray('Grade');
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  getDropdownItemsArray = (dropdownName: string) => {
    const itemArray = [1, 2, 3, 4, 5];
    let result: Array<any> = [];
    itemArray.forEach(obj => {
      result.push({
        id: obj,
        value: `${dropdownName}${obj}`,
        label: `${dropdownName}${obj}`,
      });
    });
    return result;
  }

  handleCourseTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedCourseType: event.target.value });
  }

  handleOpenBrowseCourseCatalog = () => {
    this.setState({ openCourseCatalogModal: true });
  }

  handleCloseBrowseCourseCatalog = () => {
    this.setState({ openCourseCatalogModal: false });
  }

  handleDropdownChange = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };
  // Customizable Area End
}
// Customizable Area End