// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import { BehaviorDetails} from "./TenantAdminGradeBookController";

 
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;

  // Customizable Area End
}

interface EvaluationKeyAttribute {
  title: string;
}

interface SubSkillAttribute {
  title?: string;
  _destroy?: boolean;
  id?:number
  
}

interface SkillAttribute {
  title: string;
  sub_skills_attributes: SubSkillAttribute[];
}

interface ApiBody {
  // grade_book_id: number;
  obeservation_title: string;
  evaluation_keys_attributes: EvaluationKeyAttribute[];
  skills_attributes: SkillAttribute[];
}
interface RemovedKey {
  id: number;
  _destroy: boolean;
  title?: string;
}
interface RemovedSubSkills{
  id: number;
  title?: string;
  _destroy?: boolean;
}
// Example usage


interface S {
 
  observationTitleValue: string;
 
  observationTitleError: boolean;
  observationTitleErrorMsg: string;
  loading: boolean;
  keys:Array<{ id: number; value: string ,new?:boolean}>;
  userRole:string;
  isEdit:boolean;
  behaviourId:number;
  removedKeys:RemovedKey[];
  removedSkills:RemovedKey[];
  removedSubSkills:RemovedSubSkills[],
  keyError: { [key: number]: boolean };
  gradeBookId:number;
  keyErrorMesg: { [key: number]: string };
  skillErrors: { [skillId: number]: { mainSkillError: boolean, mainSkillErrorMsg: string } };
    subSkillErrors: { [skillId: number]: { [subSkillId: number]: { error: boolean, errorMsg: string } } };
  skills: Array<{ id: number;new?:boolean, mainSkill: string; subSkills: Array<{ id: number; value: string,new?:boolean }> }>; // Skills state
  // Customizable Area End
}
 
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewBehaviourController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPostBehavioursApiCallId:string=""
  apiGetBehaviourDataDetailsById:string=""
  updatePostBehaviourApiCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
    
      observationTitleValue: "",
     
      observationTitleError: false,
      observationTitleErrorMsg: "",
      loading: false,
      keys: [{ id: 1, value: '' }],
      userRole:"",
      skills: [], 
      isEdit:false,
      behaviourId:-1,
      removedKeys:[],
      removedSkills:[],
      removedSubSkills:[],
      keyError:{},
      keyErrorMesg:{},
      skillErrors: {},
      subSkillErrors: {},
      gradeBookId:-1
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.createPostBehavioursApiCallId:
            {
              this.handleCreatePostBehaviourResponse(responseJson)
            }
            break;
          case this.apiGetBehaviourDataDetailsById:{
            this.handleGetBehaviourDataDetailsResponse(responseJson)
          }
          break;
        case this.updatePostBehaviourApiCallId :{
          this.handleUpdatePostBehaviourResponse(responseJson)
        }
        break;
        }
      }
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.handleSetUserRole()
  }

  handleKeyChange = (id: number, value: string) => {
    const keys = this.state.keys.map(key => key.id === id ? { ...key, value } : key);
    this.setState({ keys });
  };

  addKey = () => {
    const newId = this.state.keys.length ? this.state.keys[this.state.keys.length - 1].id + 1 : 1;
    this.setState({ keys: [...this.state.keys, { id: newId, value: '',new:true}] });
  };

  removeKey = (id: number) => {
    this.setState(prevState => ({
      keys: prevState.keys.filter(key => key.id !== id),
      removedKeys: [...prevState.removedKeys, { id, _destroy: true }]
    }));
  };

    handleMainSkillChange = (id: number, value: string) => {
        const skills = this.state.skills.map(skill => skill.id === id ? { ...skill, mainSkill: value } : skill);
        this.setState({ skills });
      };


    
      handleSubSkillChange = (skillId: number, subSkillId: number, value: string) => {
        const skills = this.state.skills.map(skill => {
          if (skill.id === skillId) {
            const subSkills = skill.subSkills.map(subSkill => subSkill.id === subSkillId ? { ...subSkill, value } : subSkill);
            return { ...skill, subSkills };
          }
          return skill;
        });
        this.setState({ skills });
      };
    
      addSkill = () => {
        const newId = this.state.skills.length ? this.state.skills[this.state.skills.length - 1].id + 1 : 1;
        this.setState({ skills: [...this.state.skills, { id: newId, mainSkill: '', new:true, subSkills: [{ id: 1, value: '',new:true }] }] });
      };
    
      removeSkill = (id: number) => {

      this.setState(prevState => ({
        skills: prevState.skills.filter(skill => skill.id !== id),
        removedSkills: [...prevState.removedSkills, { id, _destroy: true }]
      }));
      };
    
      addSubSkill = (skillId: number) => {
        const skills = this.state.skills.map(skill => {
          if (skill.id === skillId) {
            const newId = skill.subSkills.length ? skill.subSkills[skill.subSkills.length - 1].id + 1 : 1;
            const subSkills = [...skill.subSkills, { id: newId, value: '',new:true }];
            return { ...skill, subSkills };
          }
          return skill;
        });
        this.setState({ skills });
      };
    
      removeSubSkill = (skillId: number, subSkillId: number) => {
        const skills = this.state.skills.map(skill => {
          if (skill.id === skillId) {
            const subSkills = skill.subSkills.filter(subSkill => subSkill.id !== subSkillId);
            return { ...skill, subSkills };
          }
          return skill;
        });
        this.setState((prevState=>({ skills,removedSubSkills: [
          ...prevState.removedSubSkills,
          { id: subSkillId, _destroy: true }
        ] })));
 
      };

 
  handleSetUserRole = async() => {
    const roleFromParam = this.props.navigation?.getParam("title");
    const behaviourId = this.props.navigation?.getParam("behaviourId");
    const gradeBookId = this.props.navigation?.getParam("gradebookId");
    if (behaviourId && gradeBookId) {
      this.setState({isEdit:true,behaviourId:behaviourId,gradeBookId:gradeBookId})
     await this.handleGetBehaviourDataDetailsByIdApi(behaviourId,gradeBookId)
    }
    if(gradeBookId){
      this.setState({gradeBookId:gradeBookId})
    }
    if (roleFromParam) {
      this.setState({ userRole: roleFromParam })
    }
  };
  handleBack = ()=>{
    // this.props.navigation.navigate("TenantAdminSetupDashboard",{
    //   tabOpen:7,
    //   showGradeDetails:true,
    //   gradeBookId:this.state.gradeBookId
    // })

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'TenantAdminSetupDashboard'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      tabOpen: 6,
      showGradeDetails:true,
      gradeBookId:this.state.gradeBookId
    })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleChange = (event: any) => {
    const { name, value } = event.target;
    if(name === "observationTitleValue"){
        if (value.length) {
          this.setState({ observationTitleError: false, observationTitleErrorMsg: "" })
        }
        
    }
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  handleEmptyField=()=>{
    this.setState({
      observationTitleValue: "",
      keys: [{ id: 1, value: '' }],
      skills: [],
    })
  }
  handleRequestBody = async () => {
    if (this.handleFormValidation()) {
      const {
        observationTitleValue,
        keys,
        skills,
        isEdit,
        removedKeys,
        removedSkills,
        removedSubSkills
      } = this.state;

      const evaluationKeysAttributes = keys.map(key => ({
        ...((isEdit &&  !key.new) && { id: key.id }),
        title: key.value,
     
    }));
    const mergedKeysAttributes = isEdit 
  ? [...evaluationKeysAttributes, ...removedKeys]
  : evaluationKeysAttributes;

    const removedSubAttributes:any =[...removedSubSkills]
      const skillsAttributes = skills.map(skill => ({
      ...((isEdit &&  !skill.new) && { id: skill.id }),
        title: skill.mainSkill,
        sub_skills_attributes: skill.subSkills.map(subSkill => ({
            ...((isEdit &&  !subSkill.new) && { id: subSkill.id }),
            title: subSkill.value
        })).concat(isEdit ?removedSubAttributes : [])
      }));
      const finalSkillsAttributes = isEdit  ? [...skillsAttributes,...removedSkills] : skillsAttributes
      const requestBody = {
        grade_book_id:this.state.gradeBookId,
        obeservation_title: observationTitleValue,
        evaluation_keys_attributes: mergedKeysAttributes,
        skills_attributes: finalSkillsAttributes
      };
      if(this.state.isEdit){
        this.handleUpdatePostBehaviourApi(requestBody,this.state.behaviourId)
        
      }
      else{
        this.handleCreatePostBehaviourApi(requestBody)
      }
      console.warn("data",requestBody)
    }         
  }
  handleFormValidation = () => {
    let isValid = true;
    const {
        observationTitleValue,
        keys,
        skills,
    } = this.state;

   
    if (!observationTitleValue.trim()) {
        this.setState({ observationTitleError: true, observationTitleErrorMsg: "Please enter observation title" });
        isValid = false;
    } else {
        this.setState({ observationTitleError: false, observationTitleErrorMsg: "" });
    }

   
    keys.forEach((key, index) => {
      if (!key.value.trim()) {
          this.setState(prevState => ({
              keyError: { ...prevState.keyError, [key.id]: true },
              keyErrorMesg: { ...prevState.keyErrorMesg, [key.id]: `Key ${index + 1} is required` }
          }));
          isValid = false;
      } else {
          this.setState(prevState => ({
              keyError: { ...prevState.keyError, [key.id]: false },
              keyErrorMesg: { ...prevState.keyErrorMesg, [key.id]: '' }
          }));
      }
  });

  skills.forEach(skill => {
    if (!skill.mainSkill.trim()) {
        this.setState(prevState => ({
            skillErrors: {
                ...prevState.skillErrors,
                [skill.id]: { mainSkillError: true, mainSkillErrorMsg: 'Main skill is required' }
            }
        }));
        isValid = false;
    } else {
        this.setState(prevState => ({
            skillErrors: {
                ...prevState.skillErrors,
                [skill.id]: { mainSkillError: false, mainSkillErrorMsg: '' }
            }
        }));
    }

    skill.subSkills.forEach(subSkill => {
        if (!subSkill.value.trim()) {
            this.setState(prevState => ({
                subSkillErrors: {
                    ...prevState.subSkillErrors,
                    [skill.id]: {
                        ...prevState.subSkillErrors[skill.id],
                        [subSkill.id]: { error: true, errorMsg: 'Sub skill is required' }
                    }
                }
            }));
            isValid = false;
        } else {
            this.setState(prevState => ({
                subSkillErrors: {
                    ...prevState.subSkillErrors,
                    [skill.id]: {
                        ...prevState.subSkillErrors[skill.id],
                        [subSkill.id]: { error: false, errorMsg: '' }
                    }
                }
            }));
        }
    });
});


    return isValid;
  };
  handleCreatePostBehaviourApi =async (requestBody:any) =>{
    const requestUrl = configJSON.behavioursListEndpoint;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createPostBehavioursApiCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleCreatePostBehaviourResponse = async(responseJson: {
    data: BehaviorDetails
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
        this.handleEmptyField()
      toast.success("Behaviour created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleGetBehaviourDataDetailsByIdApi = async (gradeSystemId:number,gradeBookId:number) => {
    const token = await getStorageData("token");
    const endpoint = `${configJSON.behavioursListEndpoint}/${gradeSystemId}?grade_book_id=${gradeBookId}`;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetBehaviourDataDetailsById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetBehaviourDataDetailsResponse = async(responseJson: {
    data:BehaviorDetails
    errors: Array<{}>;})=>{ 

    if (responseJson?.data) {  
      const responseObj = responseJson?.data
      const transformedSkills = responseObj.attributes.skills.map((skill) => ({
        id: skill.id,
        mainSkill: skill.skill_title,
        subSkills: skill.sub_skills.map((subSkill) => ({
          id: subSkill.id,
          value: subSkill.title,
        })),
      }));
      const transformedKeys = responseObj.attributes.evaluation_keys.map((key) => ({
        id: key.id,
        value: key.title,
      }));
      const transformTitle = responseObj.attributes.obeservation_title
      
      this.setState({ skills:transformedSkills,keys:transformedKeys,observationTitleValue:transformTitle });

    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleUpdatePostBehaviourApi =async (requestBody:any,gradeSystemId: number) =>{
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updatePostBehaviourApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.behavioursListEndpoint + "/" + `${gradeSystemId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdatePostBehaviourResponse = async(responseJson: {
    data: BehaviorDetails
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
        this.handleEmptyField();
        this.handleBack();

      toast.success("Behvaiour updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  // Customizable Area End
}
// Customizable Area End
