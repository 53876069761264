// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const VideoBottomTextTopIcon: any = (props: any) => {
    return (
        <SvgIcon style={{ width: "45px", height: "70px" }} {...props}>
            <rect x="-1.5" y="0" width="40" height="70" rx="3" strokeWidth="1" fill={props.fill} stroke={props.stroke} />
            <path d="M17.6558 11.5164C17.1919 11.5164 16.8154 11.1371 16.8154 10.6698V9.82315H8.41129V10.6698C8.41129 11.1371 8.03478 11.5164 7.57088 11.5164C7.10697 11.5164 6.73047 11.1371 6.73047 10.6698V8.97652C6.73047 8.50918 7.10697 8.12988 7.57088 8.12988H17.6558C18.1197 8.12988 18.4962 8.50918 18.4962 8.97652V10.6698C18.4962 11.1371 18.1197 11.5164 17.6558 11.5164Z" fill="white" />
            <path d="M12.6129 19.9828C12.149 19.9828 11.7725 19.6035 11.7725 19.1361V8.97652C11.7725 8.50918 12.149 8.12988 12.6129 8.12988C13.0768 8.12988 13.4533 8.50918 13.4533 8.97652V19.1361C13.4533 19.6035 13.0768 19.9828 12.6129 19.9828Z" fill="white" />
            <path d="M14.2938 19.9833H10.9322C10.4683 19.9833 10.0918 19.604 10.0918 19.1367C10.0918 18.6693 10.4683 18.29 10.9322 18.29H14.2938C14.7577 18.29 15.1342 18.6693 15.1342 19.1367C15.1342 19.604 14.7577 19.9833 14.2938 19.9833Z" fill="white" />
            <path d="M29.4211 9.82315H22.6978C22.2339 9.82315 21.8574 9.44386 21.8574 8.97652C21.8574 8.50918 22.2339 8.12988 22.6978 8.12988H29.4211C29.885 8.12988 30.2615 8.50918 30.2615 8.97652C30.2615 9.44386 29.885 9.82315 29.4211 9.82315Z" fill="white" />
            <path d="M29.4211 14.9032H22.6978C22.2339 14.9032 21.8574 14.5239 21.8574 14.0566C21.8574 13.5893 22.2339 13.21 22.6978 13.21H29.4211C29.885 13.21 30.2615 13.5893 30.2615 14.0566C30.2615 14.5239 29.885 14.9032 29.4211 14.9032Z" fill="white" />
            <path d="M29.4211 19.9833H22.6978C22.2339 19.9833 21.8574 19.604 21.8574 19.1367C21.8574 18.6693 22.2339 18.29 22.6978 18.29H29.4211C29.885 18.29 30.2615 18.6693 30.2615 19.1367C30.2615 19.604 29.885 19.9833 29.4211 19.9833Z" fill="white" />
            <path d="M29.4215 25.0624H7.57088C7.10697 25.0624 6.73047 24.6831 6.73047 24.2158C6.73047 23.7484 7.10697 23.3691 7.57088 23.3691H29.4215C29.8854 23.3691 30.2619 23.7484 30.2619 24.2158C30.2619 24.6831 29.8854 25.0624 29.4215 25.0624Z" fill="white" />
            <path d="M29.4215 30.1425H7.57088C7.10697 30.1425 6.73047 29.7632 6.73047 29.2959C6.73047 28.8285 7.10697 28.4492 7.57088 28.4492H29.4215C29.8854 28.4492 30.2619 28.8285 30.2619 29.2959C30.2619 29.7632 29.8854 30.1425 29.4215 30.1425Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.635 48.4615C31.635 55.8961 25.608 61.9231 18.1735 61.9231C10.7388 61.9231 4.71191 55.8961 4.71191 48.4615C4.71191 41.0269 10.7388 35 18.1735 35C25.608 35 31.635 41.0269 31.635 48.4615ZM30.2889 48.4615C30.2889 55.1528 24.8646 60.577 18.1735 60.577C11.4823 60.577 6.05804 55.1527 6.05804 48.4615C6.05804 41.7704 11.4823 36.3461 18.1735 36.3461C24.8647 36.3461 30.2889 41.7704 30.2889 48.4615Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9489 49.2452C23.5087 48.8722 23.5087 48.0501 22.9489 47.6771L15.6238 42.7956C14.9974 42.3781 14.1582 42.827 14.1582 43.5796V53.3427C14.1582 54.0953 14.9974 54.5443 15.6238 54.1268L22.9489 49.2452ZM15.505 44.3342L21.6978 48.4611L15.505 52.5881V44.3342Z" fill="#FFD92E" />
        </SvgIcon>
    );
}

export default VideoBottomTextTopIcon;
// Customizable Area End