// Customizable Area Start
import React from "react";
import {
  TextField,
  InputAdornment,
  Box,
  FormHelperText,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CustomColorPickerFieldController, {
  Props,
} from "./CustomColorPickerFieldController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      borderRadius: "8px",
      padding: "5px 0px",
      position: "relative",
      "&. MuiInputBase-root.Mui-disabled": {
        backgroundColor: "grey",
      },
    },
    textfieldInput: {
      fontWeight: "normal",
      paddingRight: "10px !important",
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    inputPadding: {
      paddingLeft: "13px !important",
    },
    textFieldFont: {
      fontSize: "16px",
    },
    colorPickBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      position: "relative",
    },
    clickableDiv: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 100,
      cursor: "pointer",
    },
    colorPicker: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      overflow: "hidden",
      position: "absolute",
      right: "5%",
      top: "42%",
      transform: "translateY(-10px)",
    },
    colorInput: {
      width: "100%",
      height: "100%",
      border: "none !important",
      padding: "0 !important",
      margin: "0 !important",
      outline: "0 !important",
      opacity: 0,
    },
    helperText:{
      color:"red"
    }
  });

// Customizable Area End

export class CustomColorPickerField extends CustomColorPickerFieldController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      fieldName,
      fieldError,
      fieldValue,
      fieldValueHexCode,
      fieldId,
      startAdornmentIcon,
      placeHolderText,
      isDisabled,
      helperText,
      fieldWidth,
      textFieldStyles,
    } = this.props;
    const { selectedColorName, selectedColorHexCode } = this.state;

    const colorSelectTextfieldTheme = createTheme({
      overrides: {
        MuiFormHelperText: {
          root: {
            color: "red",
          },
        },
        MuiSvgIcon: {
          root: {
            width: "16px",
            height: "16px",
          },
        },
        MuiInputBase: {
          input: {
            "&::placeholder": {
              fontSize: "14px",
            },
          },
        },
        MuiFormControl: {
          root: {
            width: "100%",
            height: "38px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            paddingLeft: "5px",
          },
        },
        MuiTextField: {
          root: {
            backgroundColor: "black",
          },
        },
      },
    });

    const getTextfieldClasses = () => {
      if (fieldError) {
        return `${classes.textField} ${classes.primaryBorder} ${
          classes.bgColor
        } ${classes.errorBorder}`;
      } else if (isDisabled) {
        return `${classes.textField} ${classes.primaryBorder}  ${
          classes.secondaryBgColor
        } `;
      } else {
        return `${classes.textField} ${classes.bgColor} ${
          classes.primaryBorder
        }`;
      }
    };

    return (
      <Box>
        <Box className={`${classes.colorPickBox}`}>
          <Box
            className={`${classes.clickableDiv}`}
            onClick={this.openColorPicker}
          />
          <ThemeProvider theme={colorSelectTextfieldTheme}>
            <TextField
              id={fieldId}
              placeholder={"Select Color"}
              name={`${fieldName}`}
              style={{
                width: fieldWidth,
                ...textFieldStyles,
              }}
              className={getTextfieldClasses()}
              disabled={isDisabled}
              type={"text"}
              value={fieldValue}
              onChange={(e: any) => e.preventDefault()}
              error={fieldError}
              InputProps={{
                startAdornment: startAdornmentIcon ? (
                  <InputAdornment
                    className={`${classes.inputPadding}`}
                    position="start"
                  >
                    {startAdornmentIcon}
                  </InputAdornment>
                ) : (
                  ""
                ),
                disableUnderline: true,
              }}
              inputProps={{
                className: startAdornmentIcon
                  ? `${classes.textfieldInput} ${classes.textFieldFont} ${
                      classes.textPrimaryColor
                    } `
                  : `${classes.textfieldInput} ${classes.inputPadding} ${
                      classes.fontText16
                    } ${classes.textCommonColor}`,
              }}
            />
          </ThemeProvider>
          <Box
            className={`${classes.colorPicker}`}
            bgcolor={`${fieldValueHexCode}`}
          >
            <input
              type="color"
              id={`${fieldId}-Color-Picker`}
              onChange={this.colorChangeUpdate}
              className={`${classes.colorInput}`}
              value={fieldValueHexCode}
            />
          </Box>
        </Box>
        {fieldError && (
          <FormHelperText className={`${classes.helperText}`}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(
  CustomColorPickerField
);
// Customizable Area End
