// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { studentTableHeaderColumns } from "../assets/registrarMock";
import { ICustomTableProps } from "../../../components/src/CommonType.web";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export interface IStudentAPIResponseList {
    id: string,
    attributes: {
        first_name: string,
        last_name: string,
        profile_url: {
            url: string,
        },
        progress: {
            completed: number,
            remaining: number,
            not_tackled: number
        },
        last_access: string
        gpa:string;
        semsester_avg:string;
    }
}

export interface IStudentTableList {
    id: string,
    Student: {
        id: string,
        name: string,
        icon: string,
        },
    Progress: {
            value1: number,
            value2: number,
            value3: number
        },
    SemesterAvg: string,
    GPA: string,
    LastAccess: string,
}
// Customizable Area End
export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedGradeDDValue: string;
    classesList: Array<{ id: string, value: string, label: string }>;
    searchStudentText: string;
    registrarStudentListTableBody: Array<IStudentTableList>;
    registrarStudentListTableColumns: Array<ICustomTableProps>;
    currentPage: number;
    perPage: number;
    totalNumberOfStudents: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class RegistrarStudentsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetRegistrarStudentsList: string = "";
    apiGetRegistrarClasses: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            loading: false,
            searchStudentText: '',
            classesList: [],
            selectedGradeDDValue: '',
            registrarStudentListTableBody: [],
            registrarStudentListTableColumns: studentTableHeaderColumns,
            currentPage: 1,
            perPage: 10,
            totalNumberOfStudents: 0,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.handleGetRegistrarClassesApi();
        // Customizable Area End
    }
    // Customizable Area Start

    handlePageChange = (event: any, page: any) => {
        this.setState({ currentPage: page }, () => {
          this.handleGetRegistrarStudentsListApi();
        });
      };

    handleChangeDropdownValues = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValues: any = value;
        if (fieldValues) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValues,
                };
            }, () => {
                this.handleGetRegistrarStudentsListApi();
            });
        }
    };

    renderGrade = (selected: any) => {
        if (!selected || selected.length === 0) {
            return "All";
        }
        const { classesList } = this.state;
        const selectedGradingComponents = classesList.find((grade: any) => grade?.id === selected);
        return selectedGradingComponents?.label;
    };

    handleChange = (event: any) => {
        this.setState({
            searchStudentText: event.target.value
        }, () => {
            setTimeout(() => {
                this.handleGetRegistrarStudentsListApi();
            }, 600);
        })
    };

    handleGoToStudentPerformance = async (e: { id: string, icon: string, name: string }) => {
        const { registrarStudentListTableBody } = this.state;
        const studentDetails = registrarStudentListTableBody.find((item: IStudentTableList) => item?.Student?.id === e?.id);
        if (studentDetails) {
            this.props.navigation.navigate('RegistrarStudentsDetails', {}, {
                studentId: studentDetails.id
            })
        }
    }

    handleGetRegistrarStudentsListApi = async () => {
        showCustomLoader();
        const token = await getStorageData("token");
        const header = { token };
        const { selectedGradeDDValue, searchStudentText, classesList, perPage, currentPage } = this.state;
        let requestUrl = configJSON.registrarStudentsApi;
        if (selectedGradeDDValue !== 'all') {
            requestUrl = requestUrl + `?class_id=[${selectedGradeDDValue}]`;
        }

        if (selectedGradeDDValue === 'all') {
            const classIds = classesList.filter((classItem: { id: string, value: string, label: string }) => classItem.value !== "all").map((item: { id: string, value: string, label: string }) => {
                return item.value
            });
            requestUrl = requestUrl + `?class_id=[${classIds}]`;
        }

        if (searchStudentText) {
            requestUrl = requestUrl + `&name=${searchStudentText}`;
        }
        
        if(!searchStudentText){
        requestUrl = requestUrl + `&page=${currentPage}`
        }
        requestUrl = requestUrl + `&per_page=${perPage}`

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.apiGetRegistrarStudentsList = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            requestUrl
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleGetStudentsListResponse = (responseJson: { data: Array<IStudentAPIResponseList>, errors: any }) => {
        if (responseJson?.data) {
            const studentList: Array<IStudentTableList> = responseJson.data.map((item: IStudentAPIResponseList) => {
                const student = item.attributes;
                return {
                    id: item.id,
                    Student: {
                        id: item?.id,
                        icon: student?.profile_url?.url || "",
                        name: `${student.first_name} ${student.last_name}`,
                    },
                    Progress: {
                        value1: student?.progress?.completed,
                        value2: student?.progress?.remaining,
                        value3: student?.progress?.not_tackled,
                    },
                    SemesterAvg: `${student.semsester_avg}%` || 'NA',
                    GPA: student.gpa || 'NA',
                    LastAccess: student?.last_access ? moment(student?.last_access).format('MMMM DD, YYYY') : 'NA',
                }
            });
            this.setState({ registrarStudentListTableBody: studentList });
        } else {
            displayApiErrorMsg(responseJson?.errors, this.props.navigation);
        }
        hideCustomLoader();
    };

    handleGetRegistrarClassesApi = async () => {
        const requestUrl = configJSON.registrarClassesEndpoint;
        const token = await getStorageData("token");
        const header = {
            token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.apiGetRegistrarClasses = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            requestUrl
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    handleGetRegistrarClassesResponse = (response: any, errorResponse: any) => {
        if (response?.data) {
            const classesDropdownAll = [{
                id: "all",
                label: "All",
                value: "all",
            }];
            let classesDropdownData = response.data?.map(
                (item: { id: string, attributes: { name: string } }) => {
                    return {
                        label: item?.attributes?.name,
                        value: item?.id,
                        id: item?.id,
                    }
                }
            );
            this.setState({
                classesList: classesDropdownAll.concat(classesDropdownData),
                selectedGradeDDValue: "all"
            }, () => {
                this.handleGetRegistrarStudentsListApi();
            });
        } else {
            displayApiErrorMsg(response?.errors, this.props.navigation);
        }
        this.parseApiCatchErrorResponse(errorResponse);
    };


    async receive(from: string, message: Message) {
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, errorResponse, responseJson } =
                getMessageData(message);

            if (apiRequestCallId != null) {
                switch (apiRequestCallId) {
                    case this.apiGetRegistrarStudentsList:
                        {
                            if (responseJson?.meta?.pagination?.total_count) {
                                this.setState({
                                  totalNumberOfStudents: responseJson.meta.pagination.total_count,
                                });
                              }
                            this.handleGetStudentsListResponse(
                                responseJson,
                            );
                        }
                        break;

                    case this.apiGetRegistrarClasses:
                        {
                            this.handleGetRegistrarClassesResponse(
                                responseJson,
                                errorResponse
                            );
                        }
                        break;
                }
            }
        }
    }
    // Customizable Area End
}
// Customizable Area End