// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Input, Paper } from '@material-ui/core';
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import CustomTextEditor from '../../../components/src/CustomTextEditor.web';
import GradebookTemplateDownloadController,{Props} from './GradebookTemplateDownloadController';


// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
   
        customTextarea: {
            borderRadius: "12px",
            width: "96%",
            border: "1px solid #888",
            padding: "17px 15px",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "& .MuiInput-underline::before": {
                borderBottom: "0px",
                content: "none",
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "& .MuiInput-underline::after": {
                borderBottom: "0px",
            },
        },
        customInput: {
            height: "50px",
            borderRadius: "12px",
            width: "100%",
            padding: "17px 15px",
            border: "1px solid #888",
            marginTop: "10px",
            fontWeight: 400,
            fontSize: "14px",
            "&:hover:not(.Mui-)::before": {
                borderBottom: "1px solid #888",
                borderRadius: "12px",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
        },
        gridItem: {
            height:"100%",
            flex:1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius:"12px",
            cursor: 'pointer',
            boxShadow:'none',
            width:"100%"
    
          },
          contentPreview: {
            border: "1px solid #ddd",
            borderRadius:"12px",
            // padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            overflow: "auto",
            height: "100%",
            width:"100%"
          },
          addRemarkModal: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                maxWidth: "100%",
                overflowY: "hidden",
                // width: "806px",
                // minHeight: "500px",
                overflowX: "hidden",
            }
        },
    })

// Customizable Area End

// Customizable Area Start
export class GradebookTemplateDownload extends GradebookTemplateDownloadController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, 
            templateType,
           
            } = this.props;
            console.log("template type",templateType)
        // const {
        //     gridContent,
        //     gridContentBackPage,
        //     isFrontPage
        // } = this.state;
        // const contentToDisplay = isFrontPage ? gridContent:gridContentBackPage;

        return (

            <Grid container spacing={2} direction="row">
                        <Grid xs={12} item>
                        <Box marginBottom={"10px"} width={"100%"}>
                    <Grid container spacing={3}  >
                    {this.props.gridContent.map((item: any, index:any) => {
                        if (item.content !== "+Edit") {
                        return (
                            <Grid  item xs={12} sm={templateType !== "portrait_template" ? 6 : 12} key={index}>
                             <Paper style={{backgroundColor:"#F7F7F7", height:"100%",
                                flex:1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius:"12px",
                                cursor: 'pointer',
                                boxShadow:'none',
                                width:"100%",
                                
                                }}
                                > 
                            {["StudentInformation", "Attendance", "Behavior", "Grades"].includes(item.blockType) ? (
                                <>{item.content}</>
                            )
                             : (
                              
                                <Typography
                                style={{ 
                                    padding: "20px", 
                                    wordBreak: "break-word", 
                                    overflowWrap: "break-word", 
                                    whiteSpace: "pre-wrap" 
                                  }}
                                dangerouslySetInnerHTML={{ __html: item.content }}
                                className={`${classes.commentText} ${classes.textCommonColor}`}
                                >
                                </Typography>
                                
                             
                            )} 
                            </Paper>
                            </Grid>
                        );      
                        } else {
                        return (
                            <Grid item xs={12} sm={6} key={index}></Grid>
                        );
                        }
                    })}
                    </Grid>
                </Box>
               </Grid>
           </Grid>
      
    
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    width: "190px",
    padding: "10px 10px",
    height: "50px",
    borderRadius: "12px",
    fontSize: "18px",
    marginTop: "32px",
    textAlign: "center",
    float: "left",
    fontWeight: 500,
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    border: "1px solid",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(GradebookTemplateDownload);
// Customizable Area End
