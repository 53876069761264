// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import ReschedullingModalController, {
  Props,
} from "./ReschedullingModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CatchMeUpModal from "./CatchMeUpModal.web";
import ShiftLessonsModal from "./ShiftLessonsModal.web";
import NewSchoolModal from "./NewSchoolModal.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "745px",
      },
      paperWidthSm: {
        maxWidth: "745px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class ReschedullingModal extends ReschedullingModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor}`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
        >
          {this.state.modalFlagState.MainModal && (
            <Box
              padding={"21px 30px 30px 30px"}
              className={`${classes.bgColor}`}
              style={{ overflow: "auto" }}
            >
              <Grid direction="row" container>
                <Grid xs={12} item>
                  <Typography
                    className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                    component={"span"}
                  >
                    Reschedule
                  </Typography>
                  <IconButton
                    style={{ float: "right" }}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={this.props.onCancel}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent
                style={{
                  padding: "0px",
                  marginTop: "24px",
                  overflow: "hidden",
                }}
              >
                <Grid container direction="row">
                  <Grid xs={12}>
                    <Box
                      id="Catchs"
                      onClick={() => {
                        this.selectBox(document.getElementById("Catch"));
                      }}
                      style={{
                        padding: "0px 15px 10px",
                        border: `1px solid ${
                          this.state.selectedValue === "Catch"
                            ? "#FFD92E"
                            : "#888888"
                        }`,
                        cursor: "pointer",
                        borderRadius: "10px",
                        backgroundColor: `${
                          this.state.selectedValue === "Catch" ? "#fffdf5" : ""
                        }`,
                      }}
                    >
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={this.state.selectedValue}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="Catch"
                          data-test-id="Catch"
                          control={
                            <Radio
                              id="Catch"
                              style={{
                                color:
                                  this.state.selectedValue === "Catch"
                                    ? "#FFD92E"
                                    : "#888888",
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                color:
                                  this.state.selectedValue === "Catch"
                                    ? "#FFD92E"
                                    : "#888888",
                                fontWeight: "bold",
                              }}
                            >
                              Catch Me Up
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`This feature will reschedule the first incomplete lesson of each course to start on today, or the first valid day after today, extending your projected end dates.`}
                      </Typography>
                    </Box>
                    <Box
                      id="Schools"
                      onClick={() => {
                        this.selectBox(document.getElementById("School"));
                      }}
                      mt={1}
                      style={{
                        border: `1px solid ${
                          this.state.selectedValue === "School"
                            ? "#FFD92E"
                            : "#888888"
                        }`,
                        cursor: "pointer",
                        padding: "0px 15px 10px",
                        backgroundColor: `${
                          this.state.selectedValue === "School" ? "#fffdf5" : ""
                        }`,
                        borderRadius: "10px",
                      }}
                    >
                      <RadioGroup
                        onChange={this.handleChange}
                        name="gender1"
                        value={this.state.selectedValue}
                        aria-label="gender"
                      >
                        <FormControlLabel
                          data-test-id="school"
                          value="School"
                          control={
                            <Radio
                              id="School"
                              style={{
                                color:
                                  this.state.selectedValue === "School"
                                    ? "#FFD92E"
                                    : "#888888",
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                color:
                                  this.state.selectedValue === "School"
                                    ? "#FFD92E"
                                    : "#888888",
                                fontWeight: "bold",
                              }}
                            >
                              Create a New School Day
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`Use this feature if you need to choose a make up day for selected courses and wish to preserve your projected end dates.`}
                      </Typography>
                    </Box>
                    <Box
                      id="Shift"
                      onClick={() => {
                        this.selectBox(document.getElementById("shift"));
                      }}
                      mt={1}
                      style={{
                        border: `1px solid ${
                          this.state.selectedValue === "shift"
                            ? "#FFD92E"
                            : "#888888"
                        }`,
                        cursor: "pointer",
                        borderRadius: "10px",
                        backgroundColor: `${
                          this.state.selectedValue === "shift" ? "#fffdf5" : ""
                        }`,
                        padding: "0px 15px 10px",
                      }}
                    >
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={this.state.selectedValue}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="shift"
                          data-test-id="shift"
                          control={
                            <Radio
                              id="shift"
                              style={{
                                color:
                                  this.state.selectedValue === "shift"
                                    ? "#FFD92E"
                                    : "#888888",
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                color:
                                  this.state.selectedValue === "shift"
                                    ? "#FFD92E"
                                    : "#888888",
                                fontWeight: "bold",
                              }}
                            >
                              Shift Lessons
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14}`}
                        component={"span"}
                      >
                        {`This feature will shift the schedule of lessons for a selected course to start from a valid past or future date you choose`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} item>
                    <Box marginBottom={"10px"} style={{ width: "100%" }}>
                      <CustomButton
                        btnText="Next"
                        btnStyle={reschedulNextBtn}
                        buttonId={`my-assessment-search-mobile`}
                        buttonVariant={"contained"}
                        type={"button"}
                        handleButtonClick={this.submitReschedullingModal}
                        isDisabled={false}
                      />
                      <CustomButton
                        btnText="Cancel"
                        btnStyle={reschedulCancelBtn}
                        buttonId={`my-assessment-search-mobile`}
                        buttonVariant={"outlined"}
                        type={"button"}
                        handleButtonClick={this.props.onCancel}
                        isDisabled={false}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </Box>
          )}
          <CatchMeUpModal
            open={this.state.modalFlagState.Catch}
            onCancel={this.closeReschedullingModal}
            onConfirm={this.closeReschedullingModal}
            isParentTeacher={this.props.isParentTeacher}
            selectedClassComponentDDValue={this.props.selectedClassComponentDDValue}
          />
        {this.state.modalFlagState.shift && 
          <ShiftLessonsModal
            open={this.state.modalFlagState.shift}
            onCancel={this.closeReschedullingModal}
            selectedClassName={this.state.selectedClassName}
            onConfirm={this.closeReschedullingModal}
            isParentTeacher={this.props.isParentTeacher}
            selectedClassComponentDDValue={this.props.selectedClassComponentDDValue}
          />
        }
          {this.state.modalFlagState.School && 
          <NewSchoolModal
          open={this.state.modalFlagState.School}
          onCancel={this.closeReschedullingModal}
          onConfirm={this.closeReschedullingModal}
          isParentTeacher={this.props.isParentTeacher}
          selectedClassComponentDDValue={this.props.selectedClassComponentDDValue}
        />
        }

          
        </Dialog>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
const reschedulCancelBtn = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontWeight: 500,
  textAlign: "center",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  float: "right",
  marginLeft: "15px",
} as const;

const reschedulNextBtn = {
  width: "120px",
  borderRadius: "12px",
  height: "50px",
  fontSize: "18px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  padding: "10px 10px",
  float: "right",
  marginLeft: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ReschedullingModal);
// Customizable Area End
