// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  fieldName: string;
  fieldValue?: any;
  fieldError?: boolean;
  fieldId: string;
  fieldWidth: string;
  fieldType: string;
  isDisabled?: boolean;
  placeHolderText?: string;
  handleChange?: any;
  handleFocus?: any;
  startAdornmentIcon?: any;
  endAdornmentIcon?: any;
  helperText?: string;
  textFieldStyles?: any;
  multiline?: boolean;
  disableDarkmode?: boolean;
  onKeyDown?:(e:{ key: string; preventDefault: () => void; })=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomTextFieldController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleFocusOnField = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    const { fieldId } = this.props;
    const currentNode = document.getElementById(fieldId);
    if (currentNode) {
      currentNode.focus();
    }
  };
  // Customizable Area End
}
