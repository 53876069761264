// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  currentPassword: string;
  currentPasswordError: boolean;
  currentPasswordErrorMsg: string;
  newPassword: string;
  newPasswordError: boolean;
  newPasswordErrorMsg: string;
  confirmPassword: string;
  confirmPasswordError: boolean;
  confirmPasswordErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiChangePasswordId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      newPassword: "",
      confirmPassword: "",
      currentPassword: "",
      confirmPasswordError: false,
      confirmPasswordErrorMsg: "",
      currentPasswordError: false,
      currentPasswordErrorMsg: "",
      newPasswordError: false,
      newPasswordErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    if (name === "currentPassword") {
      this.setState({
        currentPasswordError: false,
        currentPasswordErrorMsg: "",
      });
    }
    if (name === "confirmPassword") {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordErrorMsg: "",
      });
    }
    if (name === "newPassword") {
      this.setState({ newPasswordError: false, newPasswordErrorMsg: "" });
    }
  };

  handleVerifyChangePassword = () => {
    const { confirmPassword, newPassword, currentPassword } = this.state;
    let isValid = true;
    if (!currentPassword.trim()) {
      this.setState({
        currentPasswordError: true,
        currentPasswordErrorMsg: "Please enter old password.",
      });
      isValid = false;
    } else if (!newPassword.trim()) {
      this.setState({
        newPasswordError: true,
        newPasswordErrorMsg: "Please enter new password.",
      });
      isValid = false;
    } else if (!confirmPassword.trim()) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordErrorMsg: "Please enter confirm password",
      });
      isValid = false;
    } else if (!configJSON.passwordRegEx.test(newPassword.replace(" ", "@"))) {
      this.setState({
        newPasswordError: true,
      });
      toast.info(TOASTER_NOTIFICATION_MSG.PASSWORD_STRENGTH);
      isValid = false;
    } else if (confirmPassword !== newPassword) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordErrorMsg: "Password and Confirm Password do not match!",
      });
      isValid = false;
    }
    return isValid;
  };

  handleChangePassword = async () => {
    if (this.handleVerifyChangePassword()) {
      showCustomLoader();
      const { newPassword, currentPassword, confirmPassword } = this.state;
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        token,
      };

      const httpBody = {
        old_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiChangePasswordId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.changePassword
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(
        message
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiChangePasswordId) {
          hideCustomLoader();
          if (responseJson?.data?.attributes) {
            toast.success(TOASTER_NOTIFICATION_MSG.RESET_PASSWORD_SUCCESSFUL);
          } else {
            //Check Error Response
            displayApiErrorMsg(responseJson?.error, this.props.navigation);
          }
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
