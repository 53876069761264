import React from "react";
import {
    Box,
    Typography,
    Grid,
    Tabs,
    Tab,
    Button
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { TabPanel, a11yProps } from "../../../../components/src/CustomTabPanel.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";

// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PendingRequestsTab from "./PendingRequestsTab.web";
import ApprovedRequestsTab from "./ApprovedRequestsTab.web";
import RejectedRequestsTab from "./RejectedRequestsTab.web";

const styles = (theme: Theme) =>
    createStyles({
        mainContainerPadding: {
            padding: "40px"
        },
        assessmentListMainContainer: {
            overflowX: "auto",
            maxHeight: "700vh",
            borderRadius: "10px",
            padding: "8px 0px",
            overflowY: "hidden",
        },
        pageName: {
            marginBottom: "28px",
            marginTop: "16px",
        },
        tabButton: {
            padding: "20px 4px",
            backgroundColor: "transparent",
            textDecoration: "none",
            textAlign: "center",
            display: "inline-block",
            textTransform: "capitalize",
            opacity: 1,
            width: "100%",
            fontSize: "20px",
            "&.MuiTab-root": {
                minHeight: "48px"
            },
            "&.Mui-selected": {
                color: "#0D46BC !important",
                backgroundColor: "transparent",
                borderBottom: "1px solid #0D46BC",
                fontWeight: 500,
                outline: "none",
            },
        },
        tabs: {
            "& div.MuiTabs-scroller": {
                borderBottom: "1px solid #979797",
                "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                    borderBottom: "none",
                }
            }
        },
        tabPanel: {
            padding: "15px 0px"
        },
        searchInput: {
            width: "180px",
            padding: "17px 15px",
            height: "50px",
            border: "1px solid #888",
            borderRadius: "12px",
            color: "#888",
            background: "#FFF",
            fontWeight: 400,
            float: "right",
            fontSize: "14px",
            "&::after": {
                borderBottom: "0px"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-disabled)::before": {
                borderBottom: "1px solid #888",
                borderRadius: "12px",
            }
        }
    })
// Customizable Area End

import MyRequestsPageController, {
    Props,
    myRequestsTabs
} from "./MyRequestsPageController";

export class MyRequestsPage extends MyRequestsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { selectedTabIndex, selectedTabName } = this.state;
        return (
            // Customizable Area Start
            <Box height={"100%"} className={`${classes.highlightBgColor} ${classes.mainContainerPadding} ${classes.secondaryBgColor}`}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box marginTop={"1%"}>
                            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText12}`} >
                                Course Management
                            </Typography>
                            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText12}`} >
                                {` / Catalog`}
                            </Typography>
                            <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText12}`} >
                                {` / My Requests`}
                            </Typography>
                            {
                                selectedTabName ?
                                    <Typography className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
                                        {` / ${selectedTabName}`}
                                    </Typography>
                                    : ''
                            }
                        </Box>
                    </Grid>
                    <Grid container className={classes.pageName} item xs={12} alignItems="center">
                        <Button onClick={this.handleRedirectBack} style={{ width: "40px", height: "40px" }}>
                            <ArrowBackIcon className={classes.textPrimaryColor} fontSize={"large"} />
                        </Button>
                        <Typography className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`} component={"span"}>
                            My Requests
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className={`${classes.bgColor} ${classes.assessmentListMainContainer}`} container direction="row">
                            <Grid item xs={12}>
                                <Tabs variant="fullWidth" scrollButtons="auto" value={selectedTabIndex} className={`${classes.tabs}`} data-testid="student-assessment-tabs" onChange={this.handleTabChange} aria-label="student-assessment-tabs"
                                    TabIndicatorProps={{
                                        style: {
                                            background: "none"
                                        }
                                    }}
                                >
                                    {myRequestsTabs.map((tabItem: any, index: number) =>
                                        <Tab key={tabItem.id} data-testid={`student-assessment-${tabItem.label}-tab`} label={tabItem.label} wrapped {...a11yProps(index)} className={`${classes.tabButton} ${classes.textPrimaryColor}`} />
                                    )}
                                </Tabs>
                            </Grid>
                            <Grid className={`${classes.tabPanel}`} item xs={12}>
                                <TabPanel value={selectedTabIndex} index={0}>
                                    <PendingRequestsTab />
                                </TabPanel>
                                <TabPanel value={selectedTabIndex} index={1}>
                                    <ApprovedRequestsTab />
                                </TabPanel>
                                <TabPanel value={selectedTabIndex} index={2}>
                                    <RejectedRequestsTab />
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MyRequestsPage);
// Customizable Area End
