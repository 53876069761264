// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import AddObjectiveQuestionModalController, {
  Props,
} from "./AddObjectiveQuestionModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";
import { redTrashIcon } from "./assets";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";

// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addNewQuestionModal: {
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "100%",
        minHeight: "500px",
        overflowX: "hidden",
        overflowY: "hidden",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    inputWithDeleteButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      "& input": {
        height: "30px",
      },
    },
    deleteIcon: {
      position: "absolute",
      right: "20px",
      cursor: "pointer",
    },
    customInputBox: {
      "& input": {
        height: "30px",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class AddObjectiveQuestionModal extends AddObjectiveQuestionModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit } = this.props;
    const {
      questionOptionFields,
      marksValue,
      marksError,
      marksErrorMsg,
      selectedCorrectOption,
      selectedCorrectOptionError,
      selectedCorrectOptionErrorMsg,
      questionContent,
      questionContentError,
      questionContentErrorMsg,
      explanationContent,
    } = this.state;

    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addNewQuestionModal}`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
          scroll={"body"}
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Grid direction="row" container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  {isEdit ? "Edit Question 1" : "Add New Question"}
                </Typography>
                <Button
                  data-test-id={"objective-modal-cross-icon"}
                  onClick={() => this.handleSetInitialValue()}
                  className={classes.textCommonColor}
                  style={{ float: "right" }}
                >
                  X{" "}
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
            >
              <Grid container spacing={2} direction="row">
                <Grid xs={12} item>
                  <Box marginBottom={"10px"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Question 1
                    </Typography>
                  </Box>
                 
                    <CustomTextEditor
                      editorId={"question-textbox"}
                      placeHolderText={isEdit?"":"Type Question"}
                      editorContent={questionContent}
                      editorError={questionContentError}
                      helperText={questionContentErrorMsg}
                      onWysEditorStateChange={this.handleQuestionContentChange}
                    />
                </Grid>
                {questionOptionFields.map((field: any) => (
                  <Grid key={field?.id} xs={6} item>
                    <Box marginBottom={"10px"}>
                      <Typography
                        component={"span"}
                        className={`${classes.fontText16} ${classes.textCommonColor}`}
                      >
                        {field.label}
                      </Typography>
                    </Box>
                    <Box className={classes.inputWithDeleteButton}>
                      <CustomTextField
                        textFieldStyles={textInputStyle}
                        placeHolderText={`Enter Option`}
                        fieldName={`Option-${field.optionLetter}-${field.id}`}
                        fieldId={`Option-${field.optionLetter}-${field.id}`}
                        fieldValue={field.inputValue}
                        fieldWidth={"99%"}
                        fieldType="text"
                        fieldError={field.error}
                        helperText={field.errorMsg}
                        handleChange={(event: any) =>
                          this.handleOptionInputChange(event, field.id)
                        }
                      />
                      <img
                        onClick={() => this.handleDeleteOptionField(field.id)}
                        className={classes.deleteIcon}
                        src={redTrashIcon}
                        alt="Delete"
                      />
                    </Box>
                  </Grid>
                ))}
                <Grid xs={12} item>
                  <Box
                    marginBottom={"10px"}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"start"}
                  >
                    <CustomButton
                      btnText="Add another Option"
                      buttonId={`add-another-option-button`}
                      btnStyle={{
                        ...{ width: "49%", marginTop: "20px" },
                        ...cancelButtonStyle,
                      }}
                      buttonVariant={"outlined"}
                      handleButtonClick={this.handleAddOptionField}
                      type={"button"}
                      isDisabled={false}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box marginBottom={"10px"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Correct Option
                    </Typography>
                  </Box>
                  <Box>
                    <CustomSelectDropdown
                      fieldId="objective-question-correct-option"
                      handleChange={this.handleCorrectOptionDropdownChange}
                      fieldName="selectedCorrectOption"
                      fieldValue={selectedCorrectOption}
                      fieldError={selectedCorrectOptionError}
                      helperText={selectedCorrectOptionErrorMsg}
                      fieldMenuItems={questionOptionFields}
                      renderValue={this.renderCorrectOptionDropdownValue}
                      fieldStyles={dropdownStyle}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} item>
                  <Box marginBottom={"10px"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Marks
                    </Typography>
                  </Box>
                  <Box className={classes.customInputBox}>
                    <CustomTextField
                      textFieldStyles={textInputMarksFieldStyle}
                      placeHolderText={"Enter Marks"}
                      fieldName={"Marks"}
                      fieldId={"Marks-Input"}
                      fieldValue={marksValue}
                      fieldWidth={"99%"}
                      fieldType="number"
                      fieldError={marksError}
                      helperText={marksErrorMsg}
                      handleChange={this.handleMarksInputChange}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box marginBottom={"10px"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                    >
                      Explanation
                    </Typography>
                  </Box>
                    <CustomTextEditor
                      editorId={"explanation-textbox"}
                      placeHolderText={isEdit?"":"Write an Explanation"}
                      editorContent={explanationContent}
                      editorError={false}
                      helperText=""
                      onWysEditorStateChange={
                        this.handleExplanationContentChange
                      }
                    />
                </Grid>
                <Grid xs={12} item>
                  <Box
                    style={{ width: "100%" }}
                    display={"flex"}
                    justifyContent={this.getJustifyContentValue()}
                    marginBottom={"10px"}
                  >
                    <Box>
                      <CustomButton
                        btnText="Cancel"
                        buttonId={`question-cancel-button`}
                        btnStyle={{
                          ...{ width: "120px", marginTop: "32px" },
                          ...cancelButtonStyle,
                        }}
                        buttonVariant={"outlined"}
                        handleButtonClick={() => this.handleSetInitialValue()}
                        type={"button"}
                        isDisabled={false}
                      />
                    </Box>
                    <Box display={"flex"}>
                      {isEdit ? (
                        <CustomButton
                          btnText="Delete"
                          buttonId={`question-delete-button`}
                          btnStyle={deleteButton}
                          buttonVariant={"contained"}
                          handleButtonClick={this.props.handleDeleteQuestion}
                          type={"button"}
                          isDisabled={false}
                        />
                      ) : (
                        ""
                      )}
                      <CustomButton
                        btnText={this.getSaveButtonLabel()}
                        buttonId={`question-save-button`}
                        btnStyle={submitButton}
                        buttonVariant={"contained"}
                        handleButtonClick={() => this.handleAddQuestionData()}
                        type={"button"}
                        isDisabled={false}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );

    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const submitButton = {
  width: "120px",
  height: "50px",
  padding: "10px 10px",
  textAlign: "center",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  marginLeft: "15px",
  fontWeight: 500,
  float: "right",
} as const;

const cancelButtonStyle = {
  height: "50px",
  padding: "10px 10px",
  border: "1px solid",
  borderRadius: "12px",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: 500,
  float: "right",
} as const;

const deleteButton = {
  width: "120px",
  height: "50px",
  padding: "10px 10px",
  borderRadius: "12px",
  background: "#ED1111",
  color: "#FFF",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: 500,
  marginLeft: "15px",
  float: "right",
  marginTop: "32px",
} as const;

const textInputStyle = {
  height: "40px",
  padding: "5px 35px 5px 5px",
} as const;

const textInputMarksFieldStyle = {
  height: "40px",
} as const;

const dropdownStyle = {
  width: "100%",
  height: "50px",
  padding: "17px 15px",
  borderRadius: "12px",
  background: "#FFF",
  fontSize: "14px",
  border: "1px solid #888",
  color: "#888",
  fontWeight: 400,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddObjectiveQuestionModal);
// Customizable Area End
