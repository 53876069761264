// Customizable Area Start
import React from "react";
import {
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import LayoutAppearanceController, {
  Props,
} from "./LayoutAppearanceController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import WebAppLayoutWeb from "../../../components/src/WebAppLayout.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class LayoutAppearance extends LayoutAppearanceController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation, children } = this.props;
    const {
      defaultThemeSettingDetails,
      isDarkMode,
      isSmallScreen,
      keepSidebarOpen,
    } = this.state;
    return (
      <MuiThemeProvider theme={defaultThemeSettingDetails}>
        <WebAppLayoutWeb
          isDarkMode={isDarkMode}
          isSmallScreen={isSmallScreen}
          keepSidebarOpen={keepSidebarOpen}
          navigation={navigation}
          handleToggleThemeSettings={this.handleToggleThemeSettings}
          toggleSidebar={this.toggleSidebar}
        >
            {children}
        </WebAppLayoutWeb>
      </MuiThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export default withStyles(customThemeStyles)(LayoutAppearance);
// Customizable Area End
