// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const AudioTaskIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill} fillRule="evenodd">
                <g transform="translate(-365.000000, -630.000000)" fill={props.fill} fillRule="nonzero" strokeWidth="0.3">
                    <g transform="translate(339.000000, 160.000000)">
                        <g id="submitted-assessments" transform="translate(0.000000, 102.000000)">
                            <g transform="translate(0.000000, 71.000000)">
                                <g transform="translate(0.005303, 0.000000)">
                                    <g transform="translate(0.000000, 81.000000)">
                                        <g transform="translate(0.994697, 216.000000)">
                                            <g transform="translate(26.005303, 0.000000)">
                                                <g transform="translate(0.000000, 1.032805)">
                                                    <path d="M13,20.2468906 C14.0584844,20.2468906 14.9196836,19.3857422 14.9196836,18.3272578 L14.9196836,1.91963281 C14.9196836,0.861148438 14.0585352,0 13,0 C11.9414648,0 11.0803672,0.861148438 11.0803672,1.91963281 L11.0803672,18.3272578 C11.0803672,19.3857422 11.9415156,20.2468906 13,20.2468906 Z M11.8420859,1.91963281 C11.8420859,1.28116016 12.3615273,0.76171875 13,0.76171875 C13.6384727,0.76171875 14.1579648,1.28116016 14.1579648,1.91963281 L14.1579648,18.3272578 C14.1579648,18.9657305 13.6385234,19.4851719 13,19.4851719 C12.3614766,19.4851719 11.8420859,18.9657305 11.8420859,18.3272578 L11.8420859,1.91963281 Z"></path>
                                                    <path d="M9.3795,15.7886523 L9.3795,4.45823828 C9.3795,3.39975391 8.51835156,2.53855469 7.45986719,2.53855469 C6.40138281,2.53855469 5.54023438,3.39970313 5.54023438,4.45823828 L5.54023438,15.7886523 C5.54023438,16.8471367 6.40138281,17.7082852 7.45986719,17.7082852 C8.51835156,17.7082852 9.3795,16.8471367 9.3795,15.7886523 Z M6.30190234,15.7886523 L6.30190234,4.45823828 C6.30190234,3.81976563 6.82134375,3.30027344 7.45981641,3.30027344 C8.09828906,3.30027344 8.61773047,3.81971484 8.61773047,4.45823828 L8.61773047,15.7886523 C8.61773047,16.427125 8.09828906,16.9465664 7.45981641,16.9465664 C6.82134375,16.9465664 6.30190234,16.427125 6.30190234,15.7886523 Z"></path>
                                                    <path d="M3.83931641,13.2500469 L3.83931641,6.99684375 C3.83931641,5.93835937 2.97816797,5.07716016 1.91968359,5.07716016 C0.861148437,5.07716016 0,5.93835937 0,6.99684375 L0,13.2500469 C0,14.3085312 0.861148437,15.1696797 1.91968359,15.1696797 C2.97816797,15.1696797 3.83931641,14.3085312 3.83931641,13.2500469 Z M3.07759766,13.2500469 C3.07759766,13.8885195 2.55815625,14.4079609 1.91968359,14.4079609 C1.28121094,14.4079609 0.76171875,13.8885195 0.76171875,13.2500469 L0.76171875,6.99684375 C0.76171875,6.35837109 1.28116016,5.83887891 1.91968359,5.83887891 C2.55820703,5.83887891 3.07759766,6.35832031 3.07759766,6.99684375 L3.07759766,13.2500469 Z"></path>
                                                    <path d="M20.4598164,15.7886523 L20.4598164,4.45823828 C20.4598164,3.39975391 19.598668,2.53855469 18.5401836,2.53855469 C17.4816484,2.53855469 16.6205,3.39970313 16.6205,4.45823828 L16.6205,15.7886523 C16.6205,16.8471367 17.4816484,17.7082852 18.5401836,17.7082852 C19.598668,17.7082852 20.4598164,16.8471367 20.4598164,15.7886523 Z M17.3822187,15.7886523 L17.3822187,4.45823828 C17.3822187,3.81976563 17.9016602,3.30027344 18.5401836,3.30027344 C19.1786562,3.30027344 19.6980977,3.81971484 19.6980977,4.45823828 L19.6980977,15.7886523 C19.6980977,16.427125 19.1786562,16.9465664 18.5401836,16.9465664 C17.9016602,16.9465664 17.3822187,16.427125 17.3822187,15.7886523 Z"></path>
                                                    <path d="M24.0803672,5.07716016 C23.021832,5.07716016 22.1606836,5.93830859 22.1606836,6.99684375 L22.1606836,13.2500469 C22.1606836,14.3085312 23.021832,15.1696797 24.0803672,15.1696797 C25.1388516,15.1696797 26,14.3085312 26,13.2500469 L26,6.99684375 C26,5.93835937 25.1388516,5.07716016 24.0803672,5.07716016 Z M25.2382813,13.2500469 C25.2382813,13.8885195 24.7188398,14.4079609 24.0803672,14.4079609 C23.4418438,14.4079609 22.9224023,13.8885195 22.9224023,13.2500469 L22.9224023,6.99684375 C22.9224023,6.35837109 23.4418438,5.83887891 24.0803672,5.83887891 C24.7188398,5.83887891 25.2382813,6.35832031 25.2382813,6.99684375 L25.2382813,13.2500469 Z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default AudioTaskIcon;
// Customizable Area End