// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Radio} from '@material-ui/core';
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';


import ContentTypeModelController, {
    Props,
  } from "./ContentTypeModelController";

import GradesIcon from "../SVGIcons/GradesIcon.web";

import StudentInfoIcon from "../SVGIcons/StudentInfoIcon.web";
import AttandanceIcon from "../SVGIcons/AttandanceIcon.web";
import ReportCardIcon from "../SVGIcons/ReportCardIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        addNewTopicModal: {
            "& .MuiDialog-paperWidthSm": {
                width: "880px",
                maxWidth: "100%",
                overflowX: "hidden",
                
                overflowY: "hidden",
            },
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
        },
        customInput: {
            height: "50px",
            borderRadius: "12px",
            width: "100%",
            padding: "17px 15px",
            fontSize: "14px",
            border: "1px solid #888",
            fontWeight: 400,
            marginTop: "10px",
            "&::after": {
                borderBottom: "0px"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            }
        },
        customTextarea: {
            borderRadius: "12px",
            width: "96%",
            padding: "17px 15px",
            fontSize: "14px",
            border: "1px solid #888",
            fontWeight: 400,
            marginTop: "10px",
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "& .MuiInput-underline::before": {
                borderBottom: "0px",
                content: "none",
            },
            "& .MuiInput-underline::after": {
                borderBottom: "0px",
            }
        },
        headerRow: {
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "row",
        },
        requiredField: {
            color: "red"
        },
        titleAndSearch: {
            flexFlow: "row",
            display: "flex",
            justifyContent: "start"
        },
        radioButtonLabel: {
            color: "#888",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "20px",
            marginRight: "15px",
        },
        checkedLabelColor: {
            fontWeight: 500,
            color: '#FFD92E',
        },
        radio: {
            height: "24px",
            width: "24px",
            '&$checked': {
                color: '#FFD92E'
            },
            "& .MuiSvgIcon-root": {
                fontSize: "30px !important"
            }
        },
        radioUnchecked: {
            width: "24px",
            strokeWidth: "1px",
            height: "24px",
            color: '#888',
            '&$checked': {
                color: '#888'
            },
            "& .MuiSvgIcon-root": {
                fontSize: "30px !important"
            }
        },
        checked: {},
        dividerGrid: {
            position: "relative",
            marginRight: "-30px",
            marginLeft: "-30px",
        },
        dividerLine: {
            height: "1px",
            margin: "10px -25px 15px -25px",
            background: "#F1F1F1",
        },
        browseTextField: {
            position: "relative",
            maxWidth: "100%",
            borderRadius: "12px",
            marginTop: "10px",
            width: "400px",
            border: "1px solid #888",
            height: "50px",
            "& .MuiInputBase-root": {
                height: "50px",
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px",
            },
        },
        fileInput: {
            display: 'none',
        },
        browseText: {
            width: "max-content",
            color: "#0D46BC",
            cursor: 'pointer',
            fontSize: 14,
            fontWeight: 500,
            display: "block",
        },
        activeShowCorrecAnswer: {
            color: "#009DFF",
            fontWeight: 500,
        },
        customLoader: {
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: -30,
            backgroundColor: "rgb(0, 0, 0, .4)",
            zIndex: 100,
            display: "block",
        },
        circularContainer: {
            position: "absolute",
            left: "50%",
            top: "40%",
            transform: "translate(-50%, -50%)",
        },
    })
// Customizable Area End

// Customizable Area Start
export class ContentTypeModel extends ContentTypeModelController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,open} = this.props;
   

    return (
        <Box>
        <Dialog className={`${classes.highlightBgColor} ${classes.addNewTopicModal}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open} scroll={'body'}>
            <Box padding={"30px"} className={`${classes.bgColor}`}>
                <Grid direction="row" container>
                    <Grid xs={12} item className={classes.headerRow}>
                    <Box className={classes.titleAndSearch}>
                                        <Typography className={`${classes.fontText24} ${classes.fontBold500} ${classes.textPrimaryColor}`} component={"span"}>Content Type</Typography>

                                    </Box>
                        <Button onClick={this.props.onCancel} className={classes.textLightGreyColor} style={{ float: "right" }}> X </Button>
                    </Grid>
                </Grid>
                <DialogContent style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}>
                    <Grid container spacing={2} direction="row">
                        <Grid xs={12} item>
                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                Data Block Type
                            </Typography>
                            <Box marginLeft={"-8px"} marginBottom={"10px"} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box>
                                    <Radio
                                        className={this.handleActivityTypeRadioDynamicClass("DataBlocks")}
                                        checked={this.props.selectedBlockType === 'DataBlocks'}
                                        onChange={this.props.handleActivityTypeChange}
                                        value="DataBlocks"
                                        name="new-activity-type-radio-button"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("DataBlocks")}> Data Blocks</Typography>
                                    <Radio
                                        className={this.handleActivityTypeRadioDynamicClass("TextEditor")}
                                        checked={this.props.selectedBlockType === 'TextEditor'}
                                        onChange={this.props.handleActivityTypeChange}
                                        value="TextEditor"
                                        name="video-activity-type-radio-button"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />
                                    <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("TextEditor")}> Text Editor </Typography>
                                   
                                </Box>
                            </Box>
                        </Grid>

   
                        {this.props.selectedBlockType === "DataBlocks" && (
                            <Grid xs={12} item>
                                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                    Select Standardized Data Block Content
                                </Typography>
                                <Box marginBottom={"10px"} display={"flex"} gridGap={"10px"} flexWrap={"wrap"} justifyContent={"start"}>
                                    <Box className={` ${classes.secondaryBgColor}`} style={{borderRadius:"12px",padding:"20px"}} flexBasis={"27%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                       <Box >
                                        <Radio
                                            className={this.handleDataBlockClass("Grades")}
                                            checked={this.props.selectedDataBlockPlacement === 'Grades'}
                                            onChange={this.props.handleDataBlockTypeChange}
                                            value="Grades"
                                            name="new-activity-type-radio-button"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                         <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("TextEditor")}>Grades</Typography>
                                         </Box>
                                         <Box style={{padding:"9px"}}>
                                         <ReportCardIcon
                                          opacity={1}
                                          fill={this.props.selectedDataBlockPlacement === "Grades" ? "#FBD82C" : "#888888"}
                                         />
                                         </Box>
                                         
                                           

                                    
                                     </Box>
                                    <Box  className={` ${classes.secondaryBgColor}`} flexBasis={"27%"} style={{borderRadius:"12px",padding:"20px"}}display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                        <Box>
                                        <Radio
                                            className={this.handleDataBlockClass("StudentInformation")}
                                            checked={this.props.selectedDataBlockPlacement=== 'StudentInformation'}
                                            onChange={this.props.handleDataBlockTypeChange}
                                            value="StudentInformation"
                                            name="new-activity-type-radio-button"
                                            inputProps={{ 'aria-label': 'B' }}
                                        />
                                        <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("TextEditor")}>Student Information</Typography>
                                        </Box>
                                        <Box style={{padding:"9px"}}>
                                        <StudentInfoIcon viewBox="0 0 40 70"   
                                            opacity={1}
                                            fill={this.props.selectedDataBlockPlacement === "StudentInformation" ? "#FBD82C" : "#888888"}
                                            />
                                        </Box>
                                       

                                    </Box>
                                    <Box  className={` ${classes.secondaryBgColor}`} style={{borderRadius:"12px",padding:"20px"}} flexBasis={"27%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                        <Box>
                                        <Radio
                                            className={this.handleDataBlockClass("Attendance")}
                                            checked={this.props.selectedDataBlockPlacement=== 'Attendance'}
                                            onChange={this.props.handleDataBlockTypeChange}
                                            value="Attendance"
                                            name="new-activity-type-radio-button"
                                            inputProps={{ 'aria-label': 'C' }}
                                        />
                                        <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("TextEditor")}>Attendance</Typography>
                                        </Box>
                                        <Box style={{padding:"9px"}}>
                                        <AttandanceIcon
                                         opacity={1}
                                         fill={this.props.selectedDataBlockPlacement === "Attendance" ? "#FBD82C" : "#888888"}
                                        />
                                        </Box>
                                       
  
                                    </Box>
                                    <Box className={` ${classes.secondaryBgColor}`}  style={{borderRadius:"12px",padding:"20px"}} flexBasis={"27%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                        <Box>
                                        <Radio
                                            className={this.handleDataBlockClass("Behavior")}
                                            checked={this.props.selectedDataBlockPlacement=== 'Behavior'}
                                            onChange={this.props.handleDataBlockTypeChange}
                                            value="Behavior"
                                            name="new-activity-type-radio-button"
                                            inputProps={{ 'aria-label': 'D' }}
                                        />
                                        <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("TextEditor")}>Behavior</Typography>
                                        </Box>
                                        <Box style={{padding:"9px"}}>
                                        <GradesIcon 
                                            opacity={1}
                                            fill={this.props.selectedDataBlockPlacement === "Behavior" ? "#FBD82C" : "#888888"}
                                            />
                                       </Box>
   
                                    </Box>
                                </Box>
                            </Grid>
                        )}
 
                        <Grid xs={12} item>
                            <Box marginBottom={"10px"} style={{ width: "100%" }}>
                                <CustomButton btnText={"Next"} btnStyle={submitButtonStyle} buttonId={`activity-submit-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.props.onConfirm} isDisabled={false} />
                                <CustomButton btnText="Cancel" btnStyle={cancelButtonStyle} buttonId={`activity-canecl-button`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                               
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStyle = {
  width: "100%",
} as const;

const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;
const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    border: "1px solid",
    height: "50px",
    borderRadius: "12px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
    marginLeft: "15px",
    marginTop: "15px",
    float: "right",
} as const;

const submitButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    textAlign: "center",
    fontSize: "18px",
    borderRadius: "12px",
    float: "right",
    marginTop: "15px",
    fontWeight: 500,
    marginLeft: "15px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ContentTypeModel);
// Customizable Area End
