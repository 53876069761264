// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import EditProfileController, { Props } from "./EditProfileController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFileUploadTextFieldWeb from "../../../components/src/CustomFileUploadTextField.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    inputLabel: {
      marginBottom: "8px",
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    addRemoveFile: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
    },
    fileUploadBox: {
      position: "relative",
      display: "inline-block",
      width: "100%",
    },
    fieldValue: {
      color: "#090909",
      fontSize: "16px",
      fontWeight: 600,
    },
    fieldBox: {
      margin: theme.spacing(2.5, 0),
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      padding: theme.spacing(3.2),
      gap: "20px",
    },
    parentHeader: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#090909",
      padding: "25px",
    },
    studentHeader: {
      color: "#090909",
      fontWeight: 600,
      fontSize: "22px",
    },
    profileHeading: {
      fontSize: "34px",
      fontWeight: 700,
      color: "#090909",
    },
    headerWrappper: {
      alignItems: "center",
      display: "flex",
      margin: "15px 0 30px 0",
      justifyContent: "space-between",
    },
    fieldWrapper: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      padding: "25px",
    },
    backIcon: {
      cursor: "pointer",
      fontSize: "34px",
    },
    headLineWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    boxWrapper: {
      padding: "0 25px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    parentHeaderWrapper: {
      backgroundColor: "#F1F1F1",
      height: "1px",
    },
    imageMargin: {
      margin: "0 8px",
    },
    parentBodyWrapper: {
      padding: "0 25px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    contentCenter: {
      display: "flex",
      alignItems: "center",
    },
    flexBox: {
      display: "flex",
      flexDirection: "column",
    },
    rootBox: {
      padding: "40px",
    },
    inputBox: {
      width: "495px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  });
// Customizable Area End

// Customizable Area Start
export class EditProfile extends EditProfileController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { firstName, lastName, emailId, profileImage, firstNameError, firstNameErrorMsg, lastNameError, lastNameErrorMsg, selectedProfileImgName } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor} ${classes.rootBox}`}
        height={"100%"}
      >
        <Box marginTop={"1%"}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Dashboard /`}
          </Typography>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {` My Profile /`}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontText14} ${classes.fontBold600}`}
            component={"span"}
          >
            {` Edit Profile`}
          </Typography>
        </Box>

        <Box className={classes.headerWrappper}>
          <Box
            data-testid="go-back-to-my-profile"
            className={classes.headLineWrapper}
            onClick={this.handleGoBack}
          >
            <KeyboardBackspaceIcon
              className={`${classes.backIcon} ${classes.textPrimaryColor}`}
            />
            <Typography
              className={`${classes.profileHeading} ${classes.textPrimaryColor}`}
            >
              Edit Profile
            </Typography>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          className={`${classes.fieldBox} ${classes.bgColor}`}
        >
          <Box className={classes.inputBox}>
            <Typography style={{ marginTop: 0 }} className={classes.inputLabel}>
              First Name
            </Typography>
            <CustomTextField
              placeHolderText="First Name"
              fieldName="firstName"
              fieldId="firstName"
              textFieldStyles={{ padding: "10px 0", overflow: "visible" }}
              fieldValue={firstName}
              fieldError={firstNameError}
              helperText={firstNameErrorMsg}
              fieldWidth={"100%"}
              fieldType="text"
              handleChange={this.handleChange}
            />
          </Box>

          <Box className={classes.inputBox}>
            <Typography style={{ marginTop: 0 }} className={classes.inputLabel}>
              Last Name
            </Typography>
            <CustomTextField
              placeHolderText="Last Name"
              fieldName="lastName"
              fieldId="lastName"
              textFieldStyles={{ padding: "10px 0", overflow: "visible" }}
              fieldValue={lastName}
              fieldError={lastNameError}
              helperText={lastNameErrorMsg}
              fieldWidth={"100%"}
              fieldType="text"
              handleChange={this.handleChange}
            />
          </Box>

          <Box className={classes.inputBox}>
            <Typography style={{ marginTop: 0 }} className={classes.inputLabel}>
              Email ID
            </Typography>
            <CustomTextField
              placeHolderText="Email ID"
              fieldName="emailId"
              fieldId="emailId"
              textFieldStyles={{ padding: "10px 0", overflow: "visible" }}
              fieldValue={emailId}
              fieldWidth={"100%"}
              fieldType="text"
              isDisabled
              handleChange={this.handleChange}
            />
          </Box>

          <Box className={classes.inputBox}>
            <Typography style={{ marginTop: 0 }} className={classes.inputLabel}>
              Profile Image
            </Typography>
            <Box className={`${classes.fileUploadBox}`}>
              <CustomFileUploadTextFieldWeb
                placeHolderText={`Upload Icon`}
                fieldName={"selectedIcon"}
                removeText="Remove"
                fieldId="profile-icon"
                uploadText="Browse Icon"
                fieldValue={selectedProfileImgName || ""}
                file={profileImage}
                fileType="image"
                fieldWidth={"100%"}
                textFieldStyles={textfieldStyles}
                fieldType="text"
                handleFile={this.handleProfileImgFileInputChange}
                fieldError={false}
                helperText={""}
              />
            </Box>
          </Box>
        </Box>

        <Box
          style={{ display: "flex", margin: "30px 0", justifyContent: "end" }}
        >
          <CustomButton
            btnStyle={cancelbtnStyle}
            btnText="Cancel"
            buttonId="editProfileCancelBtn"
            buttonVariant={"outlined"}
            isDisabled={false}
            type={"button"}
            handleButtonClick={() => this.props.navigation.goBack()}
          />
          <CustomButton
            btnStyle={addBtnStyle}
            btnText="Save"
            buttonId="saveEditProfile"
            buttonVariant={"contained"}
            isDisabled={false}
            type={"button"}
            handleButtonClick={() => this.handleVerifyEditProfileData()}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End
const addBtnStyle = {
  borderRadius: "12px",
  padding: "15px 0",
  fontSize: "18px",
  fontWeight: 600,
  width: "220px",
} as const;

const cancelbtnStyle = {
  marginRight: "15px",
  borderRadius: "12px",
  padding: "15px 0",
  fontSize: "18px",
  fontWeight: 600,
  border: "1px solid",
  width: "220px",
} as const;

const textfieldStyles = {
  padding: "10px 0px",
} as const;
// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(EditProfile);
// Customizable Area End
