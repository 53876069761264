// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedAssessmentId:any;
  completedAssignmentQuestionAnswerList:any;
  assignmentDetails:any;
  typeOfAssessment:string;
  assessmentTitle:string;
  categoryOfAssessment:string;
  fromStatistics: string;
  quizStatistics:any
  assessmentName: string;
  studentName: string;
  studentId: string;
  assessmentId: string;
  assessmentDetails: Array<any>;
  activityData:any
  evaluationType:string;
  objectiveType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCompletedAssessmentMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentAssessmentDetails:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      activityData:{},
      evaluationType:"",
      selectedAssessmentId:null,
      quizStatistics:{},
      objectiveType:"",
      completedAssignmentQuestionAnswerList:[
        {
          id:1,
          question:"What is a surface area for a polygon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:2,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[
            {
            id:1,
            src:"https://images.pexels.com/photos/1319584/pexels-photo-1319584.jpeg?auto=compress&cs=tinysrgb&w=400",
            fileName:"Attachment 1.jpg",
            size:"890.86kb"
            },
            {
            id:2,
            src:"https://images.pexels.com/photos/6238039/pexels-photo-6238039.jpeg?auto=compress&cs=tinysrgb&w=400",
            fileName:"Attachment 2.jpg",
            size:"890.86kb"
            }
          ],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:3,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:4,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:5,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:6,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:7,
          question:"What is surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:8,
          question:"What is the area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:9,
          question:"What is the surface area for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id:10,
          question:"What is the circumference for a 3D hexagon?",
          attachments:[],
          textAnswer:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      ],
      assignmentDetails:{
        lessonName:"Contemporary Fiction",
        courseName:"Creative Writing",
        obtainedScore: 42,
        maxScore:50,
        noOfAttempts:1,
        maxNoOfAttempts:3,
        submittedDate:"Jun 22, 2023",
      },
      typeOfAssessment:"",
      assessmentTitle:"",
      categoryOfAssessment:"",
      fromStatistics: "",
      assessmentName: "",
      studentName: "",
      studentId: "",
      assessmentId: "",
      assessmentDetails: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
   await super.componentDidMount();
    // Customizable Area Start
    this.handleGetCurrentQuestionsList()
    this.getParamsAndAssessments();
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetCurrentQuestionsList = () => {
    const paramAssessmentType = this.props.navigation?.getParam("typeOfAssessment");
    const paramAssessmentTitle = this.props.navigation?.getParam("title");
    const paramAssessmentCategory = this.props.navigation?.getParam("assessmentCategory");
    const paramFromStatistics = this.props.navigation?.getParam("fromStatistics");
    this.setState({ typeOfAssessment : paramAssessmentType , assessmentTitle : paramAssessmentTitle , categoryOfAssessment : paramAssessmentCategory || "submitted" , fromStatistics: paramFromStatistics, assignmentDetails : { ...this.state.assignmentDetails , assessmentType : paramAssessmentType} });
  }

  handleOpenAttachmentFile = (url : string) => {
    window.open(url , "_blank")
  }
  
  handleGoBack = () => {
   
    if(this.state.categoryOfAssessment === "submitted" ||
    this.state.categoryOfAssessment === "graded"
    ){
      const tabOpenValue = this.state.categoryOfAssessment === "submitted" ? 1 : 2;
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'StudentMyAssessments'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        tabOpen:tabOpenValue,
      })
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }else{
      this.props.navigation?.goBack()
    }
  }

  handleReAttempt = () => {
    const { assessmentTitle , typeOfAssessment,assessmentId,evaluationType,objectiveType } = this.state;
    this.props.navigation?.navigate("StudentDueAssessmentMain" , {} , {
      typeOfAssessment : typeOfAssessment,
      title : assessmentTitle,
      activityId:assessmentId,
      // typeOfAssessment : typeOfAssessment,
      evaluationType,
      objectiveType: objectiveType || evaluationType,
      // title : title,
      
    });
  }
  getParamsAndAssessments =async () => {
    const assessmentName =this.props.navigation?.getParam("title");
    const studentName = "test";
    const studentId = await getStorageData("user_id")
    const assessmentId = this.props.navigation?.getParam("activityId");
    const evaluationType =
      this.props.navigation?.getParam("evaluationType");
    const objectiveType = this.props.navigation?.getParam("objectiveType");

    this.setState({
        assessmentName,
        studentName,
        studentId,
        assessmentId,
        evaluationType,
        objectiveType
    }, () => { this.handleGetStudentAssessmentApi() });
}
  handleGetStudentAssessmentApi = async () => {
    showCustomLoader();
    let apiConfig = configJSON.studentAssessmentEndpoint;

    const { studentId, assessmentId } = this.state;
    const requestUrl = apiConfig + `?activity_id=${assessmentId}&student_id=${studentId}`;

    const token = await getStorageData("token");
    const header = {
        token,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentAssessmentDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};


handleGetStudentAssessmentResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
        const questionList = responseJson.data.map((item: any, index: number) => {
            const activity = item.attributes;
            return {
                id: item.id,
                questionNumber: index + 1,
                question: activity.question,
                textAnswer: activity.student_answer ? activity.student_answer.answer : 'NA',
                attachment: activity.attachments,
                maxScore: activity?.marks,
                answerId: activity.student_answer ? activity.student_answer.id : 0,
                remarks:activity.student_answer.remarks
            }
        });
        const activityData = responseJson.data[0].attributes.activity_data;
        const maxScore = responseJson.data[0].attributes.max_score;
        const studentAcitivity= responseJson?.data[0]?.attributes?.student_activity
        const analysisData = responseJson.data[0].attributes.analysis_data
     
        const totalAnswers = analysisData.correct_answers + analysisData.wrong_answers + analysisData.missed_answers;
        const totalAnswers2 = analysisData.answered + analysisData.unanswered;
        const minutes = Math.floor( studentAcitivity?.time_spent / 60);
        const seconds =  studentAcitivity?.time_spent % 60;
        const leftAttempts = (activityData?.allowed_attempts || 0) - (studentAcitivity?.allowed_attempts || 0);
        const percentage = ((studentAcitivity?.total_marks ?? 0) / (maxScore ?? 1) * 100)
        const assignmentDetails={
          lessonName:activityData.lesson_title,
          courseName:activityData.course_title,
          evaluationType:this.state.evaluationType,
          objectiveType:this.state.objectiveType,
          obtainedScore: studentAcitivity?.total_marks,
          maxScore:maxScore,
          noOfAttempts:studentAcitivity?.allowed_attempts,
          maxNoOfAttempts:activityData.allowed_attempts,
          leftAttempts:leftAttempts,
          timeSpent:minutes,
          percentage:percentage,
          submittedDate: moment(studentAcitivity?.submission_date).format("MMM D, YYYY"),
        }
        const quizStatistics = {
          remark: studentAcitivity?.remarks,
          statistics: {
            correctValue: analysisData.correct_answers, 
            incorrectValue: analysisData.wrong_answers,
            missedValue: analysisData.missed_answers,
            minutes:minutes,
            seconds:seconds,
            answered:((analysisData.answered / totalAnswers2) * 100).toFixed(2),
            unanswered:((analysisData.unanswered / totalAnswers2) * 100).toFixed(2),
            correct: ((analysisData.correct_answers / totalAnswers) * 100).toFixed(2), // percentage of correct answers
            incorrect: ((analysisData.wrong_answers / totalAnswers) * 100).toFixed(2), // percentage of incorrect answers
            missed: ((analysisData.missed_answers / totalAnswers) * 100).toFixed(2)    // percentage of missed answers
          },
          timeSpentInMins: analysisData.time_spent,
          noOfAttemptRemaining: studentAcitivity?.allowed_attempts,
          minutes:minutes,
          seconds:seconds,
          isReattemptAllow:studentAcitivity?.is_reattempt_allow
        }
        console.log("hello world",quizStatistics)
        let receivedScoreDefaultValue: any = {};
        let totalMaxScore = 0;
        questionList.forEach((item: any) => {
            totalMaxScore += item.maxScore;
            receivedScoreDefaultValue[item.answerId] = {
                id: item.answerId,
                remark: '',
                received_score: 0,
            }
        });
        console.log("hello world",questionList,assignmentDetails,quizStatistics)
        this.setState({
            assessmentDetails: questionList,
            assignmentDetails: {
              ...this.state.assignmentDetails,
              ...assignmentDetails
          },
          quizStatistics
            
        });
    } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
};

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetStudentAssessmentDetails) {
          this.handleGetStudentAssessmentResponse(
            responseJson,
            errorResponse
        )
        }
      }
  }
   
  }

  // Customizable Area End
}
// Customizable Area End