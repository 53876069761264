// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
import { mathSubjectIcon, physicsSubjectIcon } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
const configJSON = require("./config.js");
// Customizable Area End

// Customizable Area Start
export const completedActivityTabsList = [
  {
    id:1,
    label:"Yesterday",
    value:"yesterday"
  },
  {
    id:2,
    label:"Today",
    value:"today"
  },
  {
    id:3,
    label:"This Week",
    value:"this week"
  }
]
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  fullName: string;
  studentProgressPerformanceData:any;
  assignedActivitiesList:any;
  seventhDateFromCurrentDate:any;
  currentDate:any;
  selectedCompletedActivityTabValue:number;
  timeSpent:{hours:number,min:number},
  studentLesson:{completedLesson:number;totalLesson:number;progess:number};
  studentActivity: {
    completed: number;
    total: number;
    percentage: number;
  };
  filterDay:string;
  pendingDashboardApiCalls:number;
  completedActivitiesList:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiGetStudentTimeSpentCallId:string="";
  apiGetStudentLessonCallId:string="";
  apiGetStudentActivityProgressCallId:string="";
  apiGetStudentProgressAndPerformanceCallId:string="";
  apiGetStudentCompletedActivityCallId:string="";
  apiGetStudentAssignedActivityCallId:string="";
  apiGetStudentAssignedActivityFilterCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      loading: false,
      fullName: "",
      timeSpent:{
        hours: 0,
        min: 0
      },
      filterDay:"today",
      studentLesson:{
        completedLesson: 0,
        totalLesson: 0,
        progess: 0
      },
      pendingDashboardApiCalls:0,
      studentActivity:{
        completed: 0,
        total: 0,
        percentage: 0
      },
      studentProgressPerformanceData:[],
      assignedActivitiesList:[],
      completedActivitiesList:[],
      currentDate:moment(new Date()),
      seventhDateFromCurrentDate:moment(new Date()).add(7,"days"),
      selectedCompletedActivityTabValue:1
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetStudentTimeSpentApi();
    this.handleGetStudenActivityApi();
    this.handleGetStudentLessonApi();
    this.handleGetPerformanceAndProgessApi()
    this.handleGetCompletedActivityApi()
    this.handleGetAssignedActivityApi()
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetGraphColor = () => {
    const isDarkMode = localStorage.getItem("dark_mode") || JSON.stringify(false);
    if(JSON.parse(isDarkMode)) return "#ffffff"
    return "#000"
  }

  calculateProgressPercentage = (completed:number , total:number) => {
    const percentage = (completed/total)*100;
    return Number(percentage.toFixed(2));
  }

  handleSetLessonActivityProgressBarLabels = (maxValue : number) => {
    const labelList : Array<number> = [];
    labelList.push(0);
    labelList.push((maxValue/2));
    labelList.push(maxValue);
    return labelList;
  }

  getProgressPerformanceGraphLabels = () => {
    const { studentProgressPerformanceData } = this.state;
    const labelsList : Array<string> = studentProgressPerformanceData?.map((data:any)=>data.subjectName);
    return labelsList || [];
  }

  getProgressPerformanceGraphData = () => {
    const { studentProgressPerformanceData } = this.state;
    const graphDataList : Array<number> = studentProgressPerformanceData?.map((data:any)=>data.progressPercentage);
    return graphDataList || [];
  }

  getProgressPerformanceGraphBarBgColor = () => {
    const { studentProgressPerformanceData } = this.state;
    const graphBarBgColor : Array<string> = studentProgressPerformanceData?.map((data:any)=>data.subjectBgColor);
    return graphBarBgColor || [];
  }

  handleNextDayBtnClick = (activityId : any,nextDay:any) => {
    if(nextDay <= 8){
        this.setState((prevState) => {
          const updatedSubjects = prevState.assignedActivitiesList.map((subject: { id: any; currentDay: number; }) => {
            if (subject.id === activityId) {
              const newCurrentDay = subject.currentDay + 1;
              return {
                ...subject,
                currentDay: newCurrentDay,
                nextDay: newCurrentDay + 1,
                previousDay: newCurrentDay - 1,
              };
            }
            return subject;
          });
          return { assignedActivitiesList: updatedSubjects };
        },()=>{
          this.handleGetAssignedActivityFilterApi(activityId,nextDay)
        });
    }
  }

  handlePrevDayBtnClick = (activityId : any,nextDay:any) => {
    if(nextDay != null && nextDay >= 0){
    this.setState((prevState) => {
      const updatedSubjects = prevState.assignedActivitiesList.map((subject: { id: any; currentDay: number; }) => {
        if (subject.id === activityId) {
          const newCurrentDay = subject.currentDay - 1;
          return {
            ...subject,
            currentDay: newCurrentDay,
            nextDay: newCurrentDay + 1,
            previousDay: newCurrentDay - 1,
          };
        }
        return subject;
      });
      return { assignedActivitiesList: updatedSubjects };
    },()=>{
      this.handleGetAssignedActivityFilterApi(activityId,nextDay)
    });
  }
  }

  getAssignmentDate = (dueDate : any) => {
    return moment(dueDate , "DD-MM-YYYY").format("MMM D")
  }

  handleChangeCompletedActivityTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({selectedCompletedActivityTabValue : newValue,filterDay:completedActivityTabsList[newValue].value || "today"},()=>{
      this.handleGetCompletedActivityApi()
    });
  };


  handleGetStudentTimeSpentApi = async () => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    const requestUrl = `${configJSON.studentTimeSpentEndpoint}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentTimeSpentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetStudentTimeSpentResponse = async(responseJson: {
    total_time_spent:number
    errors: Array<{}>;})=>{

    if (responseJson?.total_time_spent !== null) {  
      const seconds = responseJson?.total_time_spent;
      const hours = Math.floor(seconds / 3600); 
      const minutes = Math.floor((seconds % 3600) / 60); 
      const timeSpent ={
        hours:hours,
        min:minutes
      }
      this.setState({timeSpent})
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }

  handleGetStudentLessonApi = async () => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    const requestUrl = `${configJSON.studentLessonEndpoint}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentLessonCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetStudentLessonResponse = async(responseJson: {
    lesson_count:number;
    total_complete_lesson: number,
    progress_percentage: number
    errors: Array<{}>;})=>{

    if (responseJson?.lesson_count !== null) {  
      const studentLesson = {
        completedLesson: responseJson?.total_complete_lesson || 0,
        totalLesson: responseJson?.lesson_count || 0,
        progess: responseJson?.progress_percentage || 0
      }
      this.setState({studentLesson})
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }

  handleGetStudenActivityApi = async () => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    const requestUrl = `${configJSON.studentActivityProgressEndpoint}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentActivityProgressCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetStudentActivityResponse = async(responseJson: {
    total_activities: number,
    completed_activities: number,
    progress_percentage: number;
    errors: Array<{}>;})=>{

    if (responseJson?.total_activities !== null) {  
     const studentActivity = {
        completed: responseJson?.completed_activities || 0,
        total: responseJson?.total_activities || 0,
        percentage: responseJson?.progress_percentage || 0
      }
      this.setState({
        studentActivity
      })
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }

  handleGetPerformanceAndProgessApi = async () => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    let requestUrl = configJSON.studentProgressAndPerformanceEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentProgressAndPerformanceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetPerformanceAndProgessResponse = async(responseJson: {
    per_course:any
    errors: Array<{}>;})=>{
    if (responseJson?.per_course) {  
      const studentProgressPerformanceData = responseJson?.per_course?.map((item: { course_id: any; course_name: any; course_score: any;course_completion_percentage:number; subject: { subject: any; }; performance_percentage: any; }) => {
        return {
          id: item?.course_id,
          subjectName: item?.course_name,
          subjectMarks:item?.course_score || 0,
          subjectBgColor: item?.subject?.subject?.color,
          progressPercentage: item?.performance_percentage || 0,
          courseProgress:item?.course_completion_percentage || 0
        };
      });
      this.setState({
        studentProgressPerformanceData
      })
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }

  handleGetCompletedActivityApi = async () => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    let requestUrl = configJSON.studentCompletedActivityEndpoint;
    if(this.state.filterDay){
      requestUrl = `${requestUrl}?day=${this.state.filterDay}`
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentCompletedActivityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
   convertData = (completed_activities: any[]) => {
    return completed_activities.map((course: {course:any; course_name: any; activities: any[];subject:any;topic:any }, index: number) => ({
      id: course?.course?.id,
      subjectName: course.course_name,
      subjectMarks: index % 2 === 0 ? 70 : 85, 
      subjectBgColor: course?.subject?.subject?.color,
      subjectIcon: course?.subject?.subject?.icon || physicsSubjectIcon,
      progressPercentage: index % 2 === 0 ? 49 : 56, 
      selectedDate: moment(new Date()),
      topicId:course?.topic?.id,
      CourseId:course?.course?.id,
      assignmentDetails: course.activities.map((activity: {
        topic: any;
        course: any; created_at: moment.MomentInput; activities_title: any; 
}, activityIndex: number) => ({
        id: activityIndex + 1,
        topicId:activity?.topic?.id,
        CourseId:activity?.course?.id,
        subjectName: course.course_name,
        dueDate: moment(activity.created_at).format("DD/MM/YYYY"),
        assignmentName: activity.activities_title,
        assignmentProgress: 68 + activityIndex * 10, 
        dayCount: 1,
      })),
    }));
  };
  handleGetCompletedActivityResponse = async(responseJson: {
    completed_activities:any
    errors: Array<{}>;})=>{
    if (responseJson?.completed_activities) {  
      const completedActivitiesList = this.convertData(responseJson?.completed_activities);
      this.setState({
        completedActivitiesList
      })
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }

  handleGetAssignedActivityApi = async () => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    let requestUrl = configJSON.studentAssignedActivityEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentAssignedActivityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  generateAssignmentDetails = (course:any) => {
    return course.activities.map((activity: {
      topic: any;
      course: any;completion_score_by_student:number; created_at: moment.MomentInput; activities_title: any; 
}, activityIndex: number) => ({
      id: activityIndex + 1,
      topicId:activity?.topic?.id,
      CourseId:course?.course?.id,
      subjectName: course?.course?.course_title,
      dueDate: moment(activity?.created_at).format("DD/MM/YYYY"),
      assignmentName: activity?.activities_title,
      assignmentProgress: activity?.completion_score_by_student === 100 ? 100 : (Math.round((activity?.completion_score_by_student || 0) * 100) / 100).toFixed(2), 
      dayCount: 1,
    }))
  };
  convertAssignedData = (assigned_activities: any[]) => {
    return assigned_activities.map((course: {course:any; course_name: any; activities: any[];subject:any;topic:any }, index: number) => ({
      id: course?.course?.id,
      subjectName: course?.course?.course_title,
      subjectMarks: index % 2 === 0 ? 70 : 85, 
      subjectBgColor: course?.subject?.subject?.color,
      subjectIcon: course?.subject?.subject?.icon || physicsSubjectIcon,
      progressPercentage: 0, 
      selectedDate: moment(new Date()),
      nextDay:1,
      currentDay:0,
      previousDay:null,
      assignmentDetails:this.generateAssignmentDetails(course) ,
    }));
  };
  handleGetAssignedActivityResponse = async(responseJson: {
    assigned_activities:any
    errors: Array<{}>;})=>{
    if (responseJson?.assigned_activities) {  
      const assignedActivitiesList = this.convertAssignedData(responseJson?.assigned_activities);
      this.setState({
        assignedActivitiesList
      })
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }

  handleResumeJourney = (activitiesData:any) => {
    this.props.navigation?.navigate("StudentMyCoursesSubjectDetails", {}, {
      subjectName: activitiesData?.subjectName || "",
      subjectId: activitiesData?.CourseId || "",
    });
  };

  handleGetAssignedActivityFilterApi = async (filterCourseId:any,filterNext:any) => {
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls + 1 }));
    showCustomLoader()
    let requestUrl = configJSON.studentAssignActiviesFilterEndpoint;
    if(filterCourseId){
      requestUrl = `${requestUrl}?course_id=${filterCourseId}`
    }
    if(filterNext){
       requestUrl = `${requestUrl}&next=${filterNext}`
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiGetStudentAssignedActivityFilterCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetAssignedActivityFilterResponse=async(responseJson: {
    assigned_activities:any
    errors: Array<{}>;})=>{
    if (responseJson?.assigned_activities) {  
      const filterDataId = responseJson.assigned_activities[0]?.course.id
      const filterDataAcitvity = this.generateAssignmentDetails(responseJson.assigned_activities[0])
      this.setState((prevState) => {
        const updatedItems = prevState.assignedActivitiesList.map((item: { id: any; }) =>
          item.id === filterDataId
            ? { ...item, assignmentDetails:filterDataAcitvity }
            : item
        );
        return { assignedActivitiesList: updatedItems };
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState(prevState => ({ pendingDashboardApiCalls: prevState.pendingDashboardApiCalls - 1 }), this.checkAndHideDashboardLoader);
  }
  checkAndHideDashboardLoader = () => {
    if (this.state.pendingDashboardApiCalls === 0) {
      hideCustomLoader();
    }
  };
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId);

      // Customizable Area Start
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetStudentTimeSpentCallId:
              this.handleGetStudentTimeSpentResponse(responseJson)
              break;
          case this.apiGetStudentLessonCallId:
              this.handleGetStudentLessonResponse(responseJson);
              break;
          case this.apiGetStudentActivityProgressCallId:
              this.handleGetStudentActivityResponse(responseJson);
              break;
          case this.apiGetStudentProgressAndPerformanceCallId:
              this.handleGetPerformanceAndProgessResponse(responseJson);
              break;
          case this.apiGetStudentCompletedActivityCallId:
              this.handleGetCompletedActivityResponse(responseJson);
              break;
          case this.apiGetStudentAssignedActivityCallId:
              this.handleGetAssignedActivityResponse(responseJson);
              break;
          case this.apiGetStudentAssignedActivityFilterCallId:
              this.handleGetAssignedActivityFilterResponse(responseJson)
              break;
        }
      }
      // Customizable Area End
    }
  }
  // Customizable Area End
}
// Customizable Area End