// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../../components/src/CommonHelper.web";
import { IDropdownMenuItemProps, USER_ROLES_CAMEL } from "../../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  assignData: any;
  getAssignCoursePaylod: any;
  getSelectedClassName: any;
  role?:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  courseTitle: string;
  openConfirmDialog: boolean;
  openSuccessDialog: boolean;
  catalogTitle: string;
  subjectName: string;
  teacherName: string;
  selectedClassName: string;
  selectedStudentName: string;
  selectedClassValue: Array<any>;
  selectedStudentValue: Array<any>;
  selectedClassError: boolean;
  selectedClassErrorMsg: string;
  teacherClassesDropdownData: Array<IDropdownMenuItemProps>;
  studentDropdownData: Array<IDropdownMenuItemProps>;
  selectedStudentError: boolean;
  selectedStudentErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssignCourseModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTeachersClasses: string = "";
  apiGetParentTeacherDetails:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      openConfirmDialog: false,
      openSuccessDialog: false,
      courseTitle: "",
      catalogTitle: "",
      subjectName: "",
      teacherName: "",
      selectedClassName: "",
      selectedStudentName:"",
      selectedClassValue: [],
      selectedStudentValue:[],
      selectedClassError: false,
      selectedClassErrorMsg: "",
      teacherClassesDropdownData: [],
      studentDropdownData:[],
      selectedStudentError: false,
      selectedStudentErrorMsg:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null ) {
        if(apiRequestCallId === this.apiGetTeachersClasses){
          this.handleGetTeachersClassesResponse(
            responseJson,
            errorResponse
          );
        }
        if(apiRequestCallId === this.apiGetParentTeacherDetails){
          this.handleGetParentTeacherDetailResponse(responseJson)
        }
      
      }
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    const { assignData } = this.props;
    if (assignData) {
      this.setState({
        courseTitle: assignData.courseName,
        catalogTitle: assignData.catalogName,
        subjectName: assignData.subjectName,
        teacherName: assignData.teacherName,
      });
    }

    this.handleGetTeachersClassesApi();
    this.handleGetParentTeacherDetailApi()
  }

  handleAssignCourse = () => {
    if (this.handleFormValidation()) {
      this.setState({ openConfirmDialog: true });
    }
  }

  handleOpenConfirmDialog = () => {
    this.setState({ openConfirmDialog: true });
  }

  handleCloseConfirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  }

  handleOpenSuccessDialog = () => {
    this.setState({ openConfirmDialog: false });
    this.setState({ openSuccessDialog: true });
  }

  handleCloseSuccessDialog = () => {
    this.setState({ openConfirmDialog: false }); 
    this.setState({ openSuccessDialog: false });
  }

  closeSuccessDialogOnYes = () => {
    this.setState({ openConfirmDialog: false });
    this.setState({ openSuccessDialog: false });
    this.props.onCancel();
  }

  handleGetTeachersClassesApi = async () => {

    const requestUrl = configJSON.teacherClassesEndpoint;

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersClassesResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      let classesData = responseJson.data.map(
        (item: any, index: number) => {
          return {
            id: item?.id,
            value: item?.id,
            label: item?.name,
          }
        }
      );
      this.setState({
        teacherClassesDropdownData: classesData,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };


  handleGetParentTeacherDetailApi = async () => {
    const userId = await getStorageData("user_id");
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParentTeacherDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetParentTeacherDetailResponse = (responseJson: any) => {
    if (responseJson.data) {
      const userData = responseJson.data.attributes;
      console.log("parent userdta",userData.children)

      let studentsData = userData.children.map(
        (item: any, index: number) => {
          return {
            id: item.id,
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        }
      );
      this.setState({ studentDropdownData:studentsData });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  }

  handleChangeClass = (event: any) => {
    this.setState({
      selectedClassValue: event.target.value,
      selectedClassError: false,
      selectedClassErrorMsg: "",
    }, () => {
      this.handleUpdateClassTitle();
    });
  };
  handleChangeStudent = (event: any) => {
    this.setState({
      selectedStudentValue: event.target.value,
      selectedStudentError: false,
      selectedClassErrorMsg: "",
    }
    , () => {
      this.handleUpdateStudentTitle();
    }
  );
  };

  handleUpdateClassTitle = async () => {
    const { selectedClassValue, teacherClassesDropdownData } = this.state;
   
    let classNames = ''
    selectedClassValue.forEach((value: number, index: number) => {
      const name = teacherClassesDropdownData.find((item) => item.id === value)?.label;
      const comma = index !== (selectedClassValue).length - 1 ? ', ' : '';
      classNames += (name + comma);
    });
    console.log('classNames: ', classNames);
    this.props.getSelectedClassName(classNames);
    this.setState({ selectedClassName: classNames });
  }
  handleUpdateStudentTitle = async () => {
    const { selectedStudentValue, studentDropdownData } = this.state;
    let studentNames = '';
    selectedStudentValue.forEach((value: number, index: number) => {
      const name = studentDropdownData.find((item) => item.id === value)?.label;
      const comma = index !== (selectedStudentValue).length - 1 ? ', ' : '';
      studentNames += (name + comma);
    });
    this.props.getSelectedClassName(studentNames);
    this.setState({ selectedClassName: studentNames });
  }

  handleFormValidation = () => {
    let isValid = true;
    const { selectedClassValue,selectedStudentValue} = this.state;

    if ((this.props.role !==USER_ROLES_CAMEL.PARENT_TEACHER) && selectedClassValue.length === 0) {
      this.setState({ selectedClassError: true, selectedClassErrorMsg: "Please select class" });
      isValid = false;
    }
    if ((this.props.role === USER_ROLES_CAMEL.PARENT_TEACHER) && selectedStudentValue.length === 0) {
      this.setState({ selectedStudentError: true, selectedStudentErrorMsg: "Please select Student" });
      isValid = false;
    }

    return isValid;
  }

  handleSaveAssignCourse = () => {
    if (this.handleFormValidation()) {
      const { selectedClassValue,selectedStudentValue } = this.state;
      let requestBody;
      const courseId = this.props.assignData.courseId;
      if(this.props.role === USER_ROLES_CAMEL.PARENT_TEACHER){
        requestBody = {
          data:
          {
              course_id:parseInt(courseId),
              student_id:selectedStudentValue
          }
        }
      }
      else{
        requestBody = {
          course_id: courseId,
          class_ids: selectedClassValue,
        }
      }

      this.handleCloseConfirmDialog();
      this.props.getAssignCoursePaylod(requestBody);
    }
  }
  // Customizable Area End
}
// Customizable Area End