import React from "react";

import {
    Box,
    Typography,
    Grid,
    Tabs,
    Tab,
    Button
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { TabPanel, a11yProps } from "../../../../components/src/CustomTabPanel.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";

// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomButton from "../../../../components/src/CustomButton.web";
import ResourcesTab from "./ResourcesTab.web";
import ParticipantsTab from "./ParticipantsTab.web";
import CoursesJourneyTab from "../CoursesJourneyTab.web";
import TeacherSendApprovalModal from "../TeacherSendApprovalModal.web";
import AddNewResourceModal from "../AddNewResourceModal.web";

const styles = (theme: Theme) =>
    createStyles({
        mainContainerPadding: {
            padding: "40px"
        },
        pageName: {
            marginTop: "16px",
            marginBottom: "28px"
        },
        courseDetailMainContainer: {
            overflowX: "auto",
            maxHeight: "700vh",
            overflowY: "hidden",
            borderRadius: "10px",
        },
        tabButton: {
            padding: "20px 4px",
            backgroundColor: "transparent",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            textTransform: "capitalize",
            opacity: 1,
            width: "100%",
            fontSize: "20px",
            "&.Mui-selected": {
                backgroundColor: "transparent",
                outline: "none",
                color: "#0D46BC !important",
                fontWeight: 500,
                borderBottom: "1px solid #0D46BC",
            },
            "&.MuiTab-root": {
                minHeight: "48px"
            }
        },
        tabPanel: {
            padding: "15px 0px 0px 0px",
        },
        tabs: {
            "& div.MuiTabs-scroller": {
                "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                    borderBottom: "none",
                },
                borderBottom: "1px solid #979797",
            }
        },
        buttonsContainer: {
            position: "absolute",
            right: "40px"
        },
        learnMoreLink: {
            position: "absolute",
            right: "40px",
            color: "#0D46BC",
            fontSize: "18px",
        }
    })
// Customizable Area End

import CourseDetailPageController, {
    Props,
    courseDetailTabs
} from "./CourseDetailPageController";

export class CourseDetailPage extends CourseDetailPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes, navigation } = this.props;
        const { selectedTabIndex, selectedTabName, currentCourseName } = this.state;
        return (
            // Customizable Area Start
            <>
                <Box className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`} height={"100%"}>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <Box marginTop={"1%"}>
                                <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
                                    Course Management
                                </Typography>
                                <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
                                    {` / My Courses`}
                                </Typography>
                                <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
                                    {` / ${currentCourseName}`}
                                </Typography>
                                {
                                    selectedTabName ?
                                        <Typography className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
                                            {` / ${selectedTabName}`}
                                        </Typography>
                                        : ''
                                }
                            </Box>
                        </Grid>
                        <Grid className={classes.pageName} item xs={12} container alignItems="center">
                            <Button style={{ width: "40px", height: "40px" }} onClick={this.handleRedirectBack}>
                                <ArrowBackIcon className={classes.textPrimaryColor} fontSize={"large"} />
                            </Button>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`} component={"span"}>
                                Physics
                            </Typography>
                            {
                                selectedTabIndex === 0 ?
                                    <Box className={classes.buttonsContainer}>
                                        <CustomButton btnText="Send Approval Request" btnStyle={webStyle.sendApprovalButton} buttonId={`add-new-course-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleOpenSendApprovalReq} isDisabled={false} />
                                        <CustomButton btnText="Add New Resouce" btnStyle={webStyle.addNewResourceButton} buttonId={`add-new-course-button`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.handleOpenAddNewResourceModal} isDisabled={false} />
                                    </Box>
                                    :
                                    <Typography className={`${classes.learnMoreLink}`} component={"span"}>
                                        Learn More
                                    </Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className={`${classes.bgColor} ${classes.courseDetailMainContainer}`} container direction="row">
                                <Grid item xs={12}>
                                    <Tabs variant="fullWidth" scrollButtons="auto" value={selectedTabIndex} className={`${classes.tabs}`} data-testid="student-assessment-tabs" onChange={this.handleChangeAssessmentCategoryTab} aria-label="student-assessment-tabs"
                                        TabIndicatorProps={{
                                            style: {
                                                background: "none"
                                            }
                                        }}
                                    >
                                        {courseDetailTabs.map((tabItem: any, index: number) =>
                                            <Tab key={tabItem.id} data-testid={`student-assessment-${tabItem.label}-tab`} label={tabItem.label} wrapped {...a11yProps(index)} className={`${classes.tabButton} ${classes.textPrimaryColor}`} />
                                        )}
                                    </Tabs>
                                </Grid>
                                <Grid className={`${classes.tabPanel}`} item xs={12}>
                                    <TabPanel value={selectedTabIndex} index={0}>
                                        <ResourcesTab />
                                    </TabPanel>
                                    <TabPanel value={selectedTabIndex} index={1}>
                                        <CoursesJourneyTab navigation={navigation} />
                                    </TabPanel>
                                    <TabPanel value={selectedTabIndex} index={2}>
                                        <ParticipantsTab />
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <TeacherSendApprovalModal
                    open={this.state.openSendApprovalReqModal}
                    onCancel={this.handleCloseSendApprovalModal}
                    onConfirm={this.handleCloseSendApprovalModal}
                />
                <AddNewResourceModal
                    open={this.state.openAddNewResourceModal}
                    onCancel={this.handleCloseAddNewResourceModal}
                    onConfirm={this.handleCloseAddNewResourceModal}
                    courseData={{}}
                    handleAddNewResource={{}}
                    handleUpdateNewResource={{}}
                />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    sendApprovalButton: {
        width: "max-content",
        height: "50px",
        padding: "15px 20px",
        borderRadius: "12px",
        fontSize: "18px",
        fontWeight: 500,
        textAlign: "center",
        float: "right",
        marginLeft: "20px"
    },
    addNewResourceButton: {
        width: "max-content",
        height: "50px",
        padding: "15px 20px",
        borderRadius: "12px",
        fontSize: "18px",
        fontWeight: 500,
        textAlign: "center",
        float: "right",
        marginLeft: "20px",
        border: "1px solid",
    }
};

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CourseDetailPage);
// Customizable Area End
