import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TenantCourseManagementPendingRequestDetailController, {
  Props,
  pendingRequestsTabsList,
} from "./TenantCourseManagementPendingRequestDetailController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import TenantCourseManagementMainTabComponentWeb from "./TenantCourseManagementMainTabComponent.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomEmptyResultComponentWeb from "../../../components/src/CustomEmptyResultComponent.web";
import TenantCourseManagementRejectAcceptRequestModalWeb from "./TenantCourseManagementRejectAcceptRequestModal.web";

const styles = (theme: Theme) =>
  createStyles({
    topPageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    managePendingRequestsMainContainer: {
      height: "100vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "33.33%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    tabPanel: {
      padding: "15px 0px 15px 0px",
    },
    pendingCourseMngTopHeader: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    topHeaderBox: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    topHeaderButtonBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
      },
    },
    capitalText: {
      textTransform: "capitalize"
    }
  });

// Customizable Area End

export class TenantCourseManagementPendingRequestDetail extends TenantCourseManagementPendingRequestDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedPendingRequestTabIndex,
      managePendingRequestInputConfig,
      tableActionButtonConfig,
      tableBodyData,
      tableHeaderColumns,
      selectedSubjectTopic,
      openRejectApproveModal,
      rejectRequest,
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        id="pending-request-main-box"
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                {`Course Management / Manage Requests / Pending Requests / ${selectedSubjectTopic}`}
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textButton}`}
              >
                {` / ${pendingRequestsTabsList[selectedPendingRequestTabIndex].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.topPageName} item xs={12}>
            <Box className={`${classes.topHeaderBox}`}>
              <Box className={`${classes.pendingCourseMngTopHeader}`}>
                <Box
                  id="pending-request-back-icon"
                  mr={"8px"}
                  onClick={() => this.handleGoBackToManageRequestsMain()}
                >
                  <KeyboardBackspaceIcon
                    fontSize="large"
                    className={`${classes.textPrimaryColor} ${classes.cursorPointer}`}
                  />
                </Box>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600} ${classes.capitalText}`}
                  component={"span"}
                >
                  {selectedSubjectTopic}
                </Typography>
              </Box>
              {selectedPendingRequestTabIndex === 0 && (
                <Box className={`${classes.topHeaderButtonBox}`}>
                  <>
                    <CustomButton
                      btnStyle={{
                        ...topBtnStyle,
                        ...topRejectBtnStyle,
                        marginRight: "15px",
                        width: "170px",
                      }}
                      btnText="Reject"
                      buttonId="tenant-pending-request-reject"
                      buttonVariant={"contained"}
                      handleButtonClick={this.handleRejectRequestBtn}
                      type={"button"}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnStyle={{
                        ...topBtnStyle,
                        width: "170px",
                      }}
                      btnText="Accept"
                      buttonId="tenant-pending-request-accept"
                      buttonVariant={"contained"}
                      handleButtonClick={this.handleApproveRequestBtn}
                      type={"button"}
                      isDisabled={false}
                    />
                  </>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              className={`${classes.bgColor} ${classes.managePendingRequestsMainContainer}`}
            >
              <Box>
                <Tabs
                  value={selectedPendingRequestTabIndex}
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  scrollButtons="auto"
                  data-testid="tenant-pending-request-management-tabs"
                  className={`${classes.tabs}`}
                  onChange={this.handleChangePendingRequestTab}
                  aria-label="tenant-pending-request-management-tabs"
                >
                  {pendingRequestsTabsList.map(
                    (tabItem: any, index: number) => (
                      <Tab
                        key={tabItem.id}
                        data-testid={`tenant-pending-request-management-${tabItem.label}-tab`}
                        label={tabItem.label}
                        wrapped
                        {...a11yProps(index)}
                        className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                      />
                    )
                  )}
                </Tabs>
              </Box>
              <Box className={`${classes.tabPanel}`}>
                <TabPanel value={selectedPendingRequestTabIndex} index={0}>
                  <TenantCourseManagementMainTabComponentWeb
                    handleSearch={this.handleSearchPendingRequests}
                    tableActionButtonConfig={tableActionButtonConfig}
                    handleSelectDropdownValue={this.handleSelectDropdownValue}
                    inputDDConfigs={managePendingRequestInputConfig}
                    renderDDMenuItem={this.renderDDMenuItem}
                    tableBodyData={tableBodyData}
                    addTablePadding
                    tableHeaderColumns={tableHeaderColumns}
                    showMenuIconInTable
                    showTablePagination={false}
                    tenantCourseMngTabCategory="resources"
                  />
                </TabPanel>
                <TabPanel value={selectedPendingRequestTabIndex} index={1}>
                  <CustomEmptyResultComponentWeb message="No journey details at the moment." />
                </TabPanel>
                <TabPanel value={selectedPendingRequestTabIndex} index={2}>
                  <CustomEmptyResultComponentWeb message="No participants at the moment." />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <TenantCourseManagementRejectAcceptRequestModalWeb
          open={openRejectApproveModal}
          handleClose={this.handleRejectRequestBtn}
          detailsForApproval={{
            grade: "Grade 3",
            subject: "Science",
            topic: "Physics",
          }}
          rejectRequest={rejectRequest}
          handleCancel={this.handleRejectApproveAction}
          handleYes={this.handleRejectApproveAction}
          isFromAssignCourseToCatalog={false}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const topBtnStyle = {
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const topRejectBtnStyle = {
  backgroundColor: "#FF0000",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementPendingRequestDetail
);
// Customizable Area End
