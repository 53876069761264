import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
    IGroup,
    IAccount,
    IGroupAccount,
    ITask,
    Status,
    Priority,
    Attributes
} from "./types";
import { Alert } from "react-native";
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  editMode: boolean;
  token: string;
  taskList: ITask[];
  isVisibleModal: boolean;
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];
  taskTitle: string;
  taskDueDate: string;
  taskDesc: string;
  isDateTimePickerVisible: boolean;
  accountID: number;
  taskDetails: Attributes;
  actionToTake: string;
  mounted: boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  deleteOrCompleteTaskApiCallId = ""
  storageToken = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.TaskActionMessage)
     // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        id: "",
        title: "",
        description: "",
        priority: "medium",
        status: "to_do",
        assign_to: "",
        assign_to_type: "",
        editMode: false,
        token: "",
        taskList: [],
        isVisibleModal: false,
        isVisibleAssignModal: false,
        dropdownGroup: false,
        dropdownStatus: false,
        dropdownAccount: false,
        dropdownPriority: false,
        groupList: [],
        selectedAccounts: [],
        accountsData: [],
        statusList: [
            { id: 1, name: "to_do" },
                    { id: 2, name: "in_progress" },
                    { id: 3, name: "complete" },
                ],
                priorityList: [
                    { id: 1, name: "low" },
                    { id: 2, name: "medium" },
                    { id: 3, name: "high" },
                ],
                taskTitle: "",
                taskDesc: "",
                taskDueDate: "YYYY-MM-DD",
                isDateTimePickerVisible: false,
                accountID: 1,
                taskDetails: configJSON.emptyTask,
                actionToTake: '',
                mounted: true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
        this.logout = this.logout.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.setState({ mounted: true });
        this.getToken();
        this.storageToken = await storage?.get('token');
        this.setState({ token: this.storageToken });
    }
    receive = async (from: string, message: Message) => {
        runEngine.debugLog("Message Received", message);
        if (getName(MessageEnum.TaskActionMessage) === message.id) {
            this.handleTaskActionResponse(message);
        }
        else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            runEngine.debugLog("Message Received321", message);
            const token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.getGroups(token);
        }
        else if( getName(MessageEnum.RestAPIResponceMessage) === message.id){
          
         this.handleCheckApiCallId(message);
        }
        // Condition for fetching tasks list.
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getTasksApiCallId != null &&
            this.getTasksApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleGetTaskApiDataResponse(responseJson, message)
        }
        // Condition to delete task.
        if ( 
             getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            (
                this.deleteTaskApiCallId != null &&
                this.deleteTaskApiCallId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) || (this.assignGroupApiCallId != null &&
                this.assignGroupApiCallId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleDeleteTaskDataResponse(responseJson, message)
        }
        // Condition to fetch group list.
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getGroupsApiCallId != null &&
            this.getGroupsApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleFetchGroupListData(responseJson, message)
        }

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAccountsApiCallId != null &&
            this.getAccountsApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleFetchAccountListApiDataResponse(responseJson, message)
        }
        
    };
    handleCheckApiCallId=(message:any)=>{
        if (
    
            this.deleteOrCompleteTaskApiCallId != null &&
            this.deleteOrCompleteTaskApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleCompletorDeletepiResponse(responseJson)
        }
        else if (
    
            this.putTaskApiCallId != null &&
            this.putTaskApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleEditApicallResponse(responseJson)
        } else if (
    
            this.postTaskApiCallId != null &&
            this.postTaskApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.handleCreateApiResponse(responseJson)
        }
    }
    handleEditApicallResponse = (responseJson: any) => {
        if (!responseJson.errors && responseJson.data) {
            this.handleSuccessResponse()
        }
        else if(responseJson.message){
            this.handleFailureResponse(responseJson.message)
        }
        else{
          alert(responseJson.errors)
        }
    }
    handleCreateApiResponse = (responseJson: any) => {
        if (!responseJson.errors && responseJson.data) {

            if (!this.isPlatformWeb()) {

                Alert.alert(
                    "",
                    "Task created successfully",
                    [
                        {
                            text: "OK",
                            onPress: () =>
                                this.sendActionMessage()
                        }
                    ],
                    { cancelable: false }
                );
                this.setState({ isVisibleModal: false });
            } else {
                this.props.navigation.goBack()
            }
        } else {
            if (!this.isPlatformWeb()) {
                Alert.alert(
                    "",
                    responseJson.message,
                    [
                        {
                            text: "OK",
                            onPress: () =>
                                this.props.navigation.goBack()
                        }
                    ],
                    { cancelable: false }
                );
                this.setState({ isVisibleModal: false });
            } else {
                this.props.navigation.goBack()
            }

        }
    }
    handleCompletorDeletepiResponse = (responseJson: any) => {
        if (!responseJson.errors && responseJson.data) {
            this.handleSuccessResponse();
        }
        else if(responseJson.message) {
            this.handleFailureResponse(responseJson.message);
        } else{
          alert(responseJson.errors)
        }
    }
    handleSuccessResponse=()=>{
        if (!this.isPlatformWeb()) {
            let taskDeletedOrCompleted = this.state.actionToTake === 'delete' ? "Task Deleted!" : "Task Completed!" ;
            Alert.alert(
                "",
                this.state.actionToTake === 'edit' ? 'Task Updated successfully' : taskDeletedOrCompleted,
                [
                    {
                        text: "OK",
                        onPress: () => {
                            this.setState({ isVisibleModal: false },()=> this.sendActionMessage());
                        }
                    }
                ],
                { cancelable: false }
            );
            
        } else {
            this.setState({
                isVisibleModal:false
            })
           this.sendActionMessage()
        }
    }
    handleFailureResponse=(message:string)=>{
        if (!this.isPlatformWeb()) {
            Alert.alert(
                "",
                message,
                [
                    {
                        text: "OK",
                        onPress: () => {
                            this.setState({ isVisibleModal: false },()=> this.sendActionMessage());
                        }
                    }
                ],
                { cancelable: false }
            );
        } else {
            this.setState({
                isVisibleModal:false
            })
           this.sendActionMessage()
        }
    }
    sendActionMessage=()=>{
        const updateResponse = new
        Message(getName(MessageEnum.TaskActionApiResponseMessage))
        updateResponse.addData(getName(MessageEnum.TaskActionPerformedMessage), this.state.actionToTake);
        this.send(updateResponse)
        if(this.state.actionToTake === 'edit' || this.state.actionToTake === 'create'){
            this.props.navigation.goBack()
        }
    }
    handleGetTaskApiDataResponse = (responseJson: any, message: any) => {
        if (!responseJson.errors && responseJson.data) {
            this.setState({
                taskList: responseJson.data.sort(
                    (a: ITask, b: ITask) => parseInt(a.id) - parseInt(b.id)
                ),
            });
        } else {
            this.showAlert("Alert", "No Data", "");
            this.setState({ taskList: [] });
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
        }
    }
    handleTaskActionResponse = async (message: any) => {
        
        const data = message.getData(
            getName(MessageEnum.TaskDataMessage)
        );
        const actionToTake =
            message.getData(getName(MessageEnum.TaskActionToPerformMessage));

        if (actionToTake === 'edit') {
            this.setState({
                actionToTake,
                taskDesc: data.description,
                taskTitle: data.title,
                taskDueDate: data.due_date,
                taskDetails: data,
                isVisibleModal: true,
                editMode:true
            });
        } else if (actionToTake === 'delete' || actionToTake === 'complete') {
            
            if (!this.isPlatformWeb()) {
                this.setState({ actionToTake, taskDetails: data }, () => this.deleteOrCompleteTask(data.id));
            }
            else {
                let tokens = await storage?.get('token')
                this.deleteOrCompleteTask(data.id, actionToTake, data, tokens)
            }
        } else if (actionToTake === 'create'){
            this.setState({
                isVisibleModal: true,
                editMode:false,
                actionToTake
           });
        }
    }
    handleFetchAccountListApiDataResponse = (responseJson: any, message: any) => {
        if (!responseJson.errors && responseJson.data) {
            let data = responseJson.data.map((account: IGroupAccount) => {
                if (this.state.selectedAccounts.length > 0) {
                    let itemExist = this.state.selectedAccounts.findIndex(
                        (gAccount: IGroupAccount) => gAccount.id == account.id
                    );
                    if (itemExist != -1) {
                        return { ...account, isSelected: true };
                    } else {
                        return { ...account, isSelected: false };
                    }
                } else {
                    return { ...account, isSelected: false };
                }
            });
            this.setState({
                accountsData: data,
            });
        } else {
            this.showAlert("Alert", "No Data", "");
            this.setState({ accountsData: [] });
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
        }
    }
    handleFetchGroupListData = (responseJson: any, message: any) => {
        if (!responseJson.errors && responseJson.data) {
            this.setState({
                groupList: responseJson.data.sort(
                    (a: IGroup, b: IGroup) => parseInt(a.id) - parseInt(b.id)
                ),
            });
        } else {
            this.setState({ groupList: [] });
        }
    }
    handleDeleteTaskDataResponse = (responseJson: any, message: any) => {
        if (!responseJson.errors && responseJson.message && this.deleteTaskApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            this.getTasks(this.state.token);
        } else if (!responseJson.errors && responseJson.data &&
            this.assignGroupApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            this.getTasks(this.state.token);
            this.setState({ isVisibleAssignModal: false });
        } else {
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorResponse);
        }
    }
    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };
    isStringNullOrBlank = (str: string) => {
        return str === null || str.length === 0;
    };
    hideModal = () => {
        this.setState({ isVisibleModal: false },()=>{this.sendActionMessage()});
    };
    hideAssignModal = () => {
        this.setState({ isVisibleAssignModal: false });
    };
    handleInputTitle = (title: string) => {
        this.setState({ taskTitle:title });
    };
    handleInputDescription = (description: string) => {
        this.setState({ taskDesc:description });
    };
    toggleAssignModal = () => {
        this.getAccounts(this.state.token);
        this.getGroups(this.state.token);
        this.setState({
            dropdownAccount: false,
            dropdownGroup: false,
            isVisibleAssignModal: !this.state.isVisibleAssignModal,
            editMode: true,
        });
    };
    showAddModal = () => {
        this.setState({
            title: "",
            description: "",
            priority: "",
            status: "",
            isVisibleModal: !this.state.isVisibleModal,
            editMode: false,
        });
    };
    showEditModal = () => {
        this.setState({
            isVisibleModal: !this.state.isVisibleModal,
            editMode: true,
        });
    };
    expandGroupView = () => {
        this.setState({
            dropdownGroup: !this.state.dropdownGroup,
        });
    };
    expandStatusView = () => {
        this.setState({
            dropdownStatus: !this.state.dropdownStatus,
        });
    };
    expandPriorityView = () => {
        this.setState({
            dropdownPriority: !this.state.dropdownPriority,
        });
    };
    expandAccountsView = () => {
        this.setState({ dropdownAccount: !this.state.dropdownAccount });
    };
    handleAccountSelect = (dataId: string) => {
        let newData = this.state.accountsData.map((account: IAccount) => {
            if (account.id === dataId) {
                return { ...account, isSelected: !account.isSelected };
            }
            return account;
        });
        this.setState({ accountsData: newData });
    };
    handleSelectPriority = (item: Priority) => {
        this.setState({ priority: item.name, dropdownPriority: false });
    };
    handleSelectStatus = (item: Status) => {
        this.setState({ status: item.name, dropdownStatus: false });
    };
    handleAssignToModal = (item: ITask) => {
        this.setState({
            id: item.id,
            title: item.attributes.title,
            description: item.attributes.description,
            priority: item.attributes.priority,
            status: item.attributes.status,
        });
        if (item.attributes.assigned_to) {
            this.setState({
                assign_to:
                    item.attributes.assigned_to.type === "group"
                        ? item.attributes.assigned_to.attributes.name
                        : item.attributes.assigned_to.attributes?.first_name || "",
                assign_to_type: item.attributes.assigned_to.type,
            });
        }
        this.toggleAssignModal();
    };
    handleEditTask = (item: ITask) => {
        this.setState({
            id: item.id,
            title: item.attributes.title,
            description: item.attributes.description,
            priority: item.attributes.priority,
            status: item.attributes.status,
        });
        this.showEditModal();
    };
    // Function to fetch tasks list from the API
    getTasks = (token: string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTasksApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.tasksApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Function to add new task and send it to API
    addTask = async () => {
        
            if (
                this.isStringNullOrBlank(this.state.taskTitle) ||
                this.isStringNullOrBlank(this.state.taskDesc)
            ) {
                this.showAlert(
                    configJSON.errorTitle,
                    configJSON.errorAllFieldsAreMandatory,
                    ""
                );
            } else {

                let token = this.state.token;
                const header = {
                    "Content-Type": configJSON.apiContentType,
                    token: token,
                };
                const task = {
                    title: this.state.taskTitle,
                    description: this.state.taskDesc,
                    status: this.state.status,
                    priority: this.state.priority,
                    account_id: this.state.accountID,
                    assigned_to_id: this.state.accountID,
                    due_date: this.state.taskDueDate
                }
                const httpBody = {
                    "task": task
                };
                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
                this.postTaskApiCallId = requestMessage.messageId;
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.tasksApiEndPoint
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(httpBody)
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.postApiMethod
                );
                runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
    // Function to update task details and send it to API
    editTask = async (taskId: number) => {
        if (
            this.isStringNullOrBlank(this.state.taskTitle) ||
            this.isStringNullOrBlank(this.state.taskDesc)
        ) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorAllFieldsAreMandatory,
                ""
            );
        } else {
            const header = {
                "Content-Type": configJSON.apiContentType,
                token: this.state.token,
                
            };
            const httpBody = {
                "task": {
                    "title": this.state.taskTitle,
                    "description": this.state.taskDesc,
                    "due_date": this.state.taskDueDate,
                    "status": this.state.taskDetails.status,
                    "priority": this.state.taskDetails.priority
                }
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.putTaskApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.tasksApiEndPoint + "/" + `${taskId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putApiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
    // Function to delete task and send it to API
    deleteTask = (taskId: string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteTaskApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.tasksApiEndPoint + "/" + `${taskId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Function to fetch groups list from the API
    getGroups = (token: string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGroupsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.groupsApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Function to assign task to groups/accounts and sent it to the API
    handleAssignTo = (
        taskId: string,
        assignToType: string,
        assignToId: string
    ) => {
        if (
            this.isStringNullOrBlank(taskId) ||
            this.isStringNullOrBlank(assignToId)
        ) {
            this.showAlert(
                configJSON.errorTitle,
                configJSON.errorAllFieldsAreMandatory,
                ""
            );
        } else {
            const header = {
                "Content-Type": configJSON.apiContentType,
                token: this.state.token,
            };
            const httpBody = {
                assigned_to_type: assignToType,
                assigned_to_id: assignToId,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.assignGroupApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.tasksApiEndPoint + "/" + `${taskId}` + "/assign"
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postApiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
    // Function to fetch accounts list from the API
    getAccounts = (token: string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAccountsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.tasksAccountsApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    selectDueDateTime = () => {
        this.setState({ isDateTimePickerVisible: true })
    }
    handleConfirm(date: any) {
        const dateTimeString = this.dateToString(date);
        this.setState({
            taskDueDate: dateTimeString,
            isDateTimePickerVisible: false,
        });
    }
    dateToString(date: any) {
        const dateString = date?.toISOString().split("T")[0];
        return dateString;
    }
    handleCancel() {
        this.setState({ isDateTimePickerVisible: false });
    }
    clearTaskState = () => {
        this.setState({
            taskTitle: '',
            taskDesc: '',
            taskDueDate: ''
        });
    };
    handleTaskTitle = (text: string) => {
        this.setState({ taskTitle: text })
    };
    handleTaskDesc = (text: string) => {
        this.setState({ taskDesc: text })
    };
    cancelTask =async () => {
        this.props.navigation.goBack();
    }
    logout = () => {
        storage?.remove('token');
    }
    deleteOrCompleteTask =async (taskId: string, actionToTake?: any, data?: any , token?:any) => {
        
        let httpBody;
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.isPlatformWeb() ? token : this.state.token
        };
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteOrCompleteTaskApiCallId = requestMessage.messageId;
        if (!this.isPlatformWeb()) {
            httpBody = {
                "task": {
                    "due_date": this.state.taskDetails.due_date,
                    "status": this.state.actionToTake == 'delete' ? "deleted" : 'complete',
                    "priority": this.state.taskDetails.priority
                }
            };
        } else {
            httpBody = {
                "task": {
                    "due_date": data.due_date,
                    "status": actionToTake == 'delete' ? "deleted" : 'complete',
                    "priority": data.priority
                }
            };
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.tasksApiEndPoint + "/" + `${taskId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
