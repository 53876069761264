import React, { useCallback, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles"
import { getStorageData } from "../../framework/src/Utilities";
interface IProgressDataProps {
    hoverText: string;
    progressPercentage: number;
}

interface IProgressDataPositionProps {
    top: string;
    left: string;
}

interface IProgressRangeColour {
    from: number;
    to: number;
    colour: string;
}

interface IProps {
    progressData: IProgressDataProps;
    progressCircleDimensions: number;
    progressDataPosition: IProgressDataPositionProps;
    centerTextFontSize: string;
    customClasses: any
}

const useStyles = makeStyles(() => ({
    root: {
        position: "relative"
    },
    bottom: {
        color: "#ccc"
    },
    top: {
        animationDuration: "550ms",
        position: "absolute",
        left: 0
    },
    circle: {
        strokeLinecap: "round"
    },
    progressRed: {
        color: "#FF0000"
    },
    progressGreen: {
        color: "#01C996"
    },
    progressYellow: {
        color: "#FC9301"
    },
    boldFont: {
        fontWeight: 600
    }
}))

export const CustomProgressCircle: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const [progressColorClass,setProgressColorClass] =useState("")
    const { progressData, progressCircleDimensions, centerTextFontSize, customClasses } = props;
    
    const getProgressRangeColour =async () => {
        const themeData = await getStorageData("appearanceTheme");

        if (themeData) {
          console.log("Raw themeData from storage:", themeData);
    
          try {
            const appearanceThemes = JSON.parse(themeData);
            return appearanceThemes.progress_range_colour
            
          } catch (error) {
            console.error("Failed to parse themeData. It might not be valid JSON.", error);
          }
        } else {
          console.warn("No themeData found in local storage.");
        }
        return [];
      };
      const getProgressCircleColorClass = (progressPercentage: number,response:IProgressRangeColour[]) => {
        const colorObject = response.find(
            (range) => progressPercentage >= range.from && progressPercentage <= range.to
          );
          return colorObject ? colorObject.colour : '#000000'; 
    }
      const fetchProgressRangeColour = async () => {
        const fetchedProgressRangeColour = await getProgressRangeColour();
        const progressColorClassf:string = getProgressCircleColorClass(progressData?.progressPercentage,fetchedProgressRangeColour)
        setProgressColorClass(progressColorClassf)
      };
    
    useEffect(()=>{
        fetchProgressRangeColour();
    },[])

    return (
        <Box position="relative" display="inline-flex">
            <Box className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={progressCircleDimensions}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    className={`${classes.top}`}
                    style={{ color: progressColorClass }}
                    classes={{
                        circle: classes.circle
                    }}
                    value={progressData.progressPercentage}
                    size={progressCircleDimensions}
                    thickness={4}
                />
            </Box>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Tooltip title={progressData?.hoverText} placement="top">
                    <Typography style={{
                        fontSize: centerTextFontSize
                    }} className={`${customClasses?.textPrimaryColor} ${classes.boldFont}`} component={"span"}>
                        {`${progressData?.progressPercentage}%`}
                    </Typography>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default CustomProgressCircle;
