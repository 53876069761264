// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, handleLogoutUser, hideCustomLoader, showCustomLoader } from "./CommonHelper.web";
import { USER_ROLES } from "./CommonType.web";
import { configJSON } from "./gradebookDetails/GradebookDetailPageController";
import { dummyIcon } from "./gradebook/assets";
const SettingIcon = require("./setting.svg")
const videoCameraIcon = require("./videoCamera.svg")
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id?: string;
  classes: any;
  handleToggleSidebar: any;
  showHamburgerIcon: boolean;
  handleToggleThemeSettings: any;
  isDarkMode: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  fullName: any;
  menuAnchorEl: any;
  profileRole: any;
  logoUrl:string;
  customizedData:boolean;
  anchorNotification: any,
  isNotificationOpen: boolean,
  notificationData:any[]
  isNewNotification:boolean;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiNotificationListId:string="";
  apiUpdateNotificationIdCall:string="";
  intervalId: NodeJS.Timeout | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]

    this.state = {
      loading: false,
      fullName: "",
      menuAnchorEl: null,
      profileRole: '',
      logoUrl:'',
      customizedData:false,
      anchorNotification: null,
    isNotificationOpen: false,
    isNewNotification:false,
    notificationData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.setState({
      profileRole: localStorage.getItem('role'),
      fullName: localStorage.getItem('user_name'),
    })
    window.addEventListener('appearanceTheme', this.getCompanyLogo);
    this.getCompanyLogo()
    this.handleGetNotificationListApi()
    this.intervalId = setInterval(this.handleGetNotificationListApi, 10000);
    // Customizable Area End
  }

  // Customizable Area Start

  handleNotificationModelOpen=(event: { currentTarget: any; })=>{
    this.setState({  anchorNotification: event.currentTarget,
      isNotificationOpen: true,})
  }
  handleCloseNotification = () => {
    this.setState({
      anchorNotification: null,
      isNotificationOpen: false,
    });
  };
  handleViewAll = ()=>{
    this.handleUpdateNotificationApi()
    this.handleCloseNotification()
    this.props.navigation.navigate("Emailnotifications2", {}, {});
  }
  componentWillUnmount(): any {
    window.removeEventListener('appearanceTheme', this.getCompanyLogo);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  getCompanyLogo =async () => {
    const themeData = await getStorageData("appearanceTheme");
    if (themeData) {
      try {
          const appearanceThemes = JSON.parse(themeData);
          if(appearanceThemes.customized_branding){
            this.setState({logoUrl:appearanceThemes?.logo_url?.url})
          }else{
            this.setState({logoUrl:""})
          }
      } catch (error) {
        console.error("Failed to parse themeData. It might not be valid JSON.", error);
      }
    } else {
      console.warn("No themeData found in local storage.");
    }
    // this.setState({companyName:"Thynker Tech"})
  };
  handleActionClick = (e: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget }) 
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  }

  handleGotoProfile = async() => {
    const roleFromStorage = await getStorageData("role");
    if (roleFromStorage.toLowerCase() === USER_ROLES.STUDENT) return this.props.navigation.navigate("StudentProfile");
    if (roleFromStorage.toLowerCase() === USER_ROLES.PARENT) return this.props.navigation.navigate("ParentProfile");
    if (roleFromStorage.toLowerCase() === USER_ROLES.TEACHER) return this.props.navigation.navigate("TeacherProfile");
    if (roleFromStorage.toLowerCase() === USER_ROLES.PARENT_TEACHER) return this.props.navigation.navigate("ParentTeacherProfile");
    else if (roleFromStorage.toLowerCase() === USER_ROLES.REGISTRAR) return this.props.navigation.navigate("RegistrarProfile");
    return this.props.navigation.navigate("StudentProfile");
  }

  handleLogout = async () => {
    const { navigation } = this.props;
    await handleLogoutUser(navigation);
  }

  handleGetNotificationListApi = async () => {
    const requestUrl = `${configJSON.notificationEndpoint}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiNotificationListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetNotificationListResponse = async(responseJson: {
    data: any[]
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const notificationList = responseJson?.data.map((item, index) => ({
        id: item.id,
        attributes: {
          is_read: item.attributes.is_read,
          icon: item?.attributes?.icon_url?.icon || dummyIcon,
          headings: item.attributes.headings.replace("Here are the details:", "").trim(),
          created_at: item.attributes.created_at,
          contents: item.attributes.contents.replace(/\n/g, '<br/>'), 
        },
      }));
      const sortedNotifications = [...notificationList];  

      sortedNotifications.sort((a, b) => {
        const dateA = new Date(a.attributes.created_at).getTime();
        const dateB = new Date(b.attributes.created_at).getTime(); 
        
        return dateB - dateA;  
      });
    const recentNotifications = sortedNotifications.slice(0, 5);
    let isTrue = !recentNotifications.some((item) => item.attributes.is_read === false);
    this.setState({notificationData:recentNotifications,isNewNotification:!isTrue})
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleUpdateNotificationApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateNotificationIdCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationUpdateAll
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleUpdateNotificationResponse =(responseJson: {
    data: any[]
    errors: Array<{}>;})=>{
      if(responseJson?.data){
        console.warn("update data")
      }else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
  }
  async receive(from: string, message: Message) {

    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)


      // Customizable Area Start
      const { responseJson } = getMessageData(
        message
      );
      if(apiRequestCallId !== null){
        if(apiRequestCallId === this.apiNotificationListId){
          this.handleGetNotificationListResponse(responseJson)
        }else if(apiRequestCallId ===  this.apiUpdateNotificationIdCall){
          this.handleUpdateNotificationResponse(responseJson);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area End
}