// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TeacherCourseInfoModalController, {
  Props,
} from "./TeacherCourseInfoModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    myCourseInfoDialogs: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
        minWidth: "550px",
      },
    },
    closeButtons: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherCourseInfoModal extends TeacherCourseInfoModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, title, courseInfo, activityInfo, open, isStudent } =
      this.props;
    const { role } = this.state;
    return (
      <Dialog
        data-testid="my-courses-info-dialogs"
        className={`${classes.highlightBgColor} ${classes.myCourseInfoDialogs}`}
        onClose={this.props.onClose}
        aria-labelledby="customized-dialog-titles"
        open={open}
      >
        <Box className={`${classes.bgColor}`}>
          <DialogTitle id="customized-dialog-titles">
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600}`}
              data-test-id="one"
            >
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButtons}
              onClick={this.props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent data-test-id="info-dialog-content-data">
            {courseInfo && (
              <>
                {role !== "teacher" &&
                  <Box>
                    <Box mb={"10px"}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                        data-test-id="five"
                      >
                        {courseInfo?.description}
                      </Typography>
                    </Box>
                    <Box mb={"10px"}>
                      <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold500}`}
                        data-test-id="four"
                      >
                        Objectives:
                      </Typography>
                      {courseInfo.objectives ? (
                        <Typography
                          className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                          data-test-id="five"
                        >
                          {courseInfo?.objectives}
                        </Typography>
                      ) : (
                        <Typography
                          className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                        >
                          NA
                        </Typography>
                      )}
                    </Box>
                  </Box>
                }
                <Box mb={"10px"}>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold500}`}
                    data-test-id="two"
                  >
                    {isStudent ? "Student" : "Teacher"} Instructions:
                  </Typography>
                  {courseInfo.instructions ||
                    courseInfo?.studentInstructions ? (
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                      data-test-id="there"
                      dangerouslySetInnerHTML={{
                        __html: isStudent
                          ? courseInfo?.studentInstructions
                          : courseInfo?.instructions,
                      }}
                    ></Typography>
                  ) : (
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                    >
                      NA
                    </Typography>
                  )}
                </Box>
                <Box mb={"10px"}>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText14} ${classes.fontBold500}`}
                    data-test-id="four"
                  >
                    {isStudent ? "Student" : "Teacher"} Materials:
                  </Typography>
                  {courseInfo.materials || courseInfo?.studentMaterials ? (
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                      data-test-id="five"
                      dangerouslySetInnerHTML={{
                        __html: isStudent
                          ? courseInfo?.studentMaterials
                          : courseInfo?.materials,
                      }}
                    ></Typography>
                  ) : (
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                    >
                      NA
                    </Typography>
                  )}
                </Box>
              </>
            )}

            {activityInfo && (
              <Box mb={"10px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                  data-test-id="five"
                >
                  {activityInfo}
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCourseInfoModal);
// Customizable Area End
