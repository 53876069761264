import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  registrarStudentGradebookMockData,
  studentBehaviorData,
} from "../assets/mockdata";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area Start
  validateDataToBeSubmitted?: (a: boolean) => void;
  isRegistrar?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  editAttendance: boolean;
  studentBehaviorData: any[];
  commentData: string;
  userRole: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AttendanceBehaviourTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      editAttendance: false,
      studentBehaviorData: studentBehaviorData,
      commentData: "",
      userRole: "",
      // Customizable Area End
    };
    // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleSetData();
    this.setState({userRole: await getStorageData("role")});
    await setStorageData("incompleteGradesSubmittedByTeacher", JSON.stringify(true));
  }

  handleSetData = () => {
    const { isRegistrar } = this.props;
    if (isRegistrar) {
      this.setState({ studentBehaviorData: registrarStudentGradebookMockData });
    }
  };

  onSelectDeselectQuarters = (behaviourItem: any, quarterNumber: string) => {
    const behaviorData = this.state.studentBehaviorData;
    const itemToUpdate = behaviorData.find(
      (item: any) => item.id === behaviourItem.id
    );
    if (itemToUpdate) {
      switch (quarterNumber) {
        case "q1":
          itemToUpdate.isQ1Selected = !!!itemToUpdate.isQ1Selected;
          break;
        case "q2":
          itemToUpdate.isQ2Selected = !!!itemToUpdate.isQ2Selected;
          break;
        case "q3":
          itemToUpdate.isQ3Selected = !!!itemToUpdate.isQ3Selected;
          break;
        case "q4":
          itemToUpdate.isQ4Selected = !!!itemToUpdate.isQ4Selected;
          break;
      }
      this.setState({ studentBehaviorData: behaviorData });
    }
  };

  updateBehaviourTableCellData = (
    newData: any,
    cellTitle: string,
    subskill: any,
    mainSkillItemId: number,
    behaviorItem: any,
    popoverId?: string
  ) => {
    const studentBehaviorData = this.state.studentBehaviorData;
    const subSkillItem = studentBehaviorData.find((behavior) => behavior.id === behaviorItem.id)?.data.find((mainskillItem: any) => mainskillItem.id === mainSkillItemId)?.subSkills.find((subskillItem: any) => subskillItem.id === subskill.id);
    if (subSkillItem) {
      subSkillItem[cellTitle] = newData;
      this.setState({ studentBehaviorData });
      if (popoverId) {
        setTimeout(() => {
          const popoverPlane = document.getElementById(popoverId);
          if (popoverPlane) {
            const popoverDropdown: any = Array.from(popoverPlane.children).find(
              (node) => Array.from(node.classList).includes("MuiPopover-paper")
            );
            if (popoverDropdown) {
              popoverDropdown.style.minWidth = newData.clientWidth;
              popoverDropdown.style.top = popoverDropdown.offsetTop - 10 + "px";
            }
          }
        });
      }
    }
  };

  updateBehaviourValueAndCloseDropdown = (
    newData: any,
    cellTitle: string,
    subskill: any,
    mainSkillId: number,
    behaviorItem: any,
    dropdownTarget: string
  ) => {
    this.updateBehaviourTableCellData(
      newData,
      cellTitle,
      subskill,
      mainSkillId,
      behaviorItem
    );
    this.updateBehaviourTableCellData(
      null,
      dropdownTarget,
      subskill,
      mainSkillId,
      behaviorItem
    );
  };

  showInEditableMode = () => {
    const { isRegistrar } = this.props;
    let quarterYetToBeFilled = false;
    this.state.studentBehaviorData.forEach((behaviorItem) => {
      quarterYetToBeFilled = behaviorItem.data.some((mainSkill: any) => mainSkill.subSkills.some((subskill: any) => !subskill.q1 || !subskill.q2 || !subskill.q3 || !subskill.q4));
    });
    setStorageData("incompleteGradesSubmittedByTeacher", JSON.stringify(quarterYetToBeFilled));
    return isRegistrar ? false : quarterYetToBeFilled;
  };

  onCommentEdit = (commentData: any) => {
    this.setState({ commentData });
  };

  // Customizable Area End
}
