// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { RefObject, createRef } from "react";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start

interface IRoleProps {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  onFileDrop: (file: File) => void;
  open: boolean;
  handleClose: () => void;
  onConfirm?:any;
  downloadCSVFile?: () => Promise<void>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isDragging: boolean;
  uploadedFile: File | null;
  selectOption: string;
  roleOptions: Array<IRoleProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CustomCSVUploadPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  readonly fileInputRef: RefObject<HTMLInputElement>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      isDragging: false,
      uploadedFile: null,
      selectOption: "",
      roleOptions: [
        { id: "teacher", label: "Teacher", value: "teacher" },
        { id: "student", label: "Student", value: "student" },
        {
          id: "course manager",
          label: "Course Manager (Tenant Admin)",
          value: "course manager",
        },
        { id: "registrar", label: "Registrar", value: "registrar" },
        { id: "parent", label: "Parent", value: "parent" },
        {
          id: "parent_teacher",
          label: "Parent Teacher",
          value: "parent_teacher",
        },
      ],
    };
    this.fileInputRef = createRef<HTMLInputElement>();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps:Props) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({ uploadedFile: null });
    }
  }
  renderDDMenuItem = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Role";
    }
    const { roleOptions } = this.state;
    const selectedItem = roleOptions.find(
      (item: IRoleProps) => item.value === selected
    );
    return selectedItem?.label;
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue
        };
      });
    }
  };

  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isDragging: true })
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  handleDragLeave = () => {
    this.setState({ isDragging: false })
  };

  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isDragging: false })
    const file = e.dataTransfer.files[0];
    if (file.type === 'text/csv') {
      this.props.onFileDrop(file);
      this.setState({ uploadedFile: file });
    } else {
      toast.error('Invalid file type. Only CSV files are allowed.');
    }
  };

  handleOpenFileBrowse = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files && e?.target?.files[0];
    if (file?.type === 'text/csv') {
      this.props.onFileDrop(file);
      this.setState({ uploadedFile: file });
    } else {
      toast.error('Invalid file type. Only CSV files are allowed.');
    }
  };

  handleClosePopup = () => {
    this.setState({ isDragging: false, selectOption: "", uploadedFile: null });
    this.props.handleClose()
  }
   handleSubmit =()=>{ 
    if (!this.state.uploadedFile) {
      toast.error("No file uploaded")
      return;
    }
  
    const formData = new FormData();
    formData.append("csv", this.state.uploadedFile);

    this.props.onConfirm(formData) 
  }
  // Customizable Area End
}
// Customizable Area End
