// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogTitle, Box, Typography } from '@material-ui/core';
import AssessmentSubmitConfirmDialogController, {
    Props
} from "./AssessmentSubmitConfirmDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        myCourseInfoDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px"
            }
        },
    })

// Customizable Area End

// Customizable Area Start
export class AssessmentSubmitConfirmDialog extends AssessmentSubmitConfirmDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, diaglogTitle , confirmationText , open } = this.props;
        return (
            <Dialog className={`${classes.highlightBgColor} ${classes.myCourseInfoDialog}`} aria-labelledby="confirm-dialog-title" open={open}>
                <Box className={`${classes.bgColor}`}>
                    <DialogTitle id="confirm-dialog-title">
                        <Typography gutterBottom component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600}`}>{diaglogTitle}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box mb={"10px"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText24} ${classes.fontBold500}`}>
                                {confirmationText}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} width={"97%"}  mb={"20px"}>
                            <CustomButton btnStyle={cancelbtnStyle} btnText='Cancel' buttonId='assessment-submit-cancel-btn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
                            &nbsp;
                            <CustomButton btnStyle={confirmbtnStyle} btnText='Confirm' buttonId='assessment-submit-confirm-btn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={this.props.onConfirm} />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const confirmbtnStyle = {
    width:"100px",
    padding:"5px",
    borderRadius:"10px",
    fontSize:"16px",
    fontWeight:600
} as const;

const cancelbtnStyle = {
    width:"100px",
    padding:"5px",
    borderRadius:"10px",
    fontSize:"16px",
    fontWeight:600,
    marginRight: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentSubmitConfirmDialog);
// Customizable Area End
