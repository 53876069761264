// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Hidden } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import StudentMyCoursesActivityDetailsController, {
  Props,
} from "./StudentMyCoursesActivityDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import StudentMyCoursesActivityJourneyBox from "./StudentMyCoursesActivityJourneyBox.web";
import CustomAccordion from "../../../components/src/CustomAccordion.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    topicLessonBox: {
      borderRadius: "6px",
      maxWidth: "98%",
      width: "98%",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
    },
    columnHeader: {
      backgroundColor: "rgba(0, 157, 255, 0.05)",
      padding: "5% 12%",
    },
    borderRight: {
      borderRight: "2px solid #f1f1f1",
    },
    searchTextBox: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    columnDataList: {
      borderTop: "2px solid #f1f1f1",
    },
    activityListColumn: {
      overflow: "auto",
    },
    activityHeader: {
      padding: "2% 3%",
    },
    activityContainer: {
      borderTop: "2px solid #f1f1f1",
      overflowY: "auto",
    },
    activityDetailGrid: {
      overflow: "auto",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentMyCoursesActivityDetails extends StudentMyCoursesActivityDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      selectedCourseSubject,
      selectedLesson,
      selectedTopic,
      selectedActivity,
      currentLessonActivityList,
      selectedActivityTaskDetailId,
      selectedActivityTaskDetail,
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12}`}
                component={"span"}
              >
                {`My Courses / ${selectedCourseSubject}`}
              </Typography>
              {selectedTopic?.topicTitle && (
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText12}`}
                  component={"span"}
                >
                  {` / ${selectedTopic.topicTitle}`}
                </Typography>
              )}
              {selectedLesson?.lessonTitle && (
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText12}`}
                  component={"span"}
                >
                  {` / ${selectedLesson.lessonTitle}`}
                </Typography>
              )}
              {selectedActivity?.activityTitle && (
                <Typography
                  className={`${classes.primaryColor5} ${classes.fontText12} ${classes.fontBold600}`}
                  component={"span"}
                >
                  {` / ${selectedActivity.activityTitle}`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box
              onClick={this.handleGoBackToMyCourse}
              className={`${classes.cursorPointer}`}
              display={"flex"}
              alignItems={"center"}
            >
              <Box mr={"8px"}>
                <KeyboardBackspaceIcon
                  fontSize="large"
                  className={`${classes.textPrimaryColor}`}
                />
              </Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
                component={"span"}
              >
                {selectedCourseSubject || ""}
              </Typography>
            </Box>
          </Grid>
          {/* Topic Lessons Activity Box */}
          <Grid item xs={12}>
            <Box className={`${classes.bgColor} ${classes.topicLessonBox}`}>
              <Box
                onClick={this.handleGoBack}
                className={`${classes.cursorPointer} ${classes.activityHeader}`}
                display={"flex"}
                alignItems={"center"}
              >
                <Box mr={"8px"}>
                  <KeyboardBackspaceIcon
                    fontSize="small"
                    className={`${classes.textPrimaryColor}`}
                  />
                </Box>
                <Box>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                    component={"span"}
                  >
                    {`${selectedActivity?.activityTitle}, `}
                  </Typography>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold500}`}
                    component={"span"}
                  >
                    {`${selectedLesson?.lessonTitle}, `}
                  </Typography>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold500}`}
                    component={"span"}
                  >
                    {`${selectedTopic?.topicTitle}`}
                  </Typography>
                </Box>
              </Box>
                <Grid
                  className={classes.activityContainer}
                  container
                  direction="row"
                >
                  {/* Column Header */}
                  <Grid
                    className={classes.activityListColumn}
                    item
                    xs={5}
                    sm={3}
                  >
                    <Box className={classes.columnHeader}>
                      <Typography
                        className={`${classes.primaryColor14} ${classes.fontText18} ${classes.fontBold600}`}
                        component={"span"}
                      >
                        Activity
                      </Typography>
                    </Box>
                    {currentLessonActivityList?.length > 0 &&
                      currentLessonActivityList.map((activity: any) => (
                        <StudentMyCoursesActivityJourneyBox
                          isFromAccordion={false}
                          key={activity?.id}
                          detail={{
                            completionProgress:
                              activity?.activityCompletionProgress,
                            id: activity?.id,
                            title: activity?.activityTitle || "",
                          }}
                          handleSelectItem={this.handleSelectActivityTask}
                          selectedItemId={selectedActivityTaskDetailId}
                          typeOfActivity={activity?.typeOfActivity}
                        />
                      ))}
                  </Grid>
                  {/* Activity Detail */}
                  <Grid
                    className={classes.activityDetailGrid}
                    item
                    xs={7}
                    sm={9}
                  >
                    {this.handleActivityDetailRender(
                      selectedActivityTaskDetail,
                      false
                    )}
                  </Grid>
                </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentMyCoursesActivityDetails);
// Customizable Area End
