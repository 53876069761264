// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentMyPathController, {
  Props,
  myPathCategoryTabsList,
} from "./StudentMyPathController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";
import MyPathItemCard from "./MyPathItemCard.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import MyPathOverdue from "./MyPathOverdue.web";
import Pagination from "@material-ui/lab/Pagination";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    myPathCourseListMainContainer: {
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabPanelGraded: {
      padding: "10px 12px 15px 12px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity: 1,
      minWidth: "25%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    tabPanel: {
      padding: "30px 12px 15px 12px",
    },
    dropdownGrid: {
      gap: "12px",
      marginBottom: "30px",
    },
    filterDiv: {
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    subjectFilterDiv: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentMyPath extends StudentMyPathController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      studentMyPathCourseList,
      selectedMyPathCategoryTab,
      selectedSubjectDDValue,
      subjectDDMenuItems,
      paginationDueList
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                My Path
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5}`}
              >
                {` / ${myPathCategoryTabsList[selectedMyPathCategoryTab].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`}
              component={"span"}
            >
              My Path
            </Typography>
          </Grid>
          {/* Select Dropdowns */}
          <Grid className={classes.dropdownGrid} item xs={12}>
            <Box className={classes.filterDiv}>
              <Box className={classes.subjectFilterDiv}>
                <Box marginBottom={"10px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText16}`}
                    component={"span"}
                  >
                    Subject
                  </Typography>
                </Box>
                <Box>
                  <CustomSelectDropdown
                    fieldId="select-dropdown-assessment-subject"
                    fieldValue={selectedSubjectDDValue}
                    fieldName="selectedSubjectDDValue"
                    handleChange={this.handleChangeDDSelect}
                    renderValue={this.renderSubjectDDValue}
                    fieldError={false}
                    fieldMenuItems={subjectDDMenuItems}
                    fieldStyles={selectDropdownStyles}
                    iconComponent={
                      <DropdownIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#888888"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    }
                    placeHolderText="Select Value"
                  />
                </Box>
              </Box>
              <Box marginLeft={"10px"}>
                <CustomButton
                  btnText="Search"
                  btnStyle={btnStyle}
                  buttonId={`my-path-course-search`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.handleSearchMyPathCourse}
                  isDisabled={false}
                />
              </Box>
            </Box>
          </Grid>
          {/* My Path Course List */}
          <Grid item xs={12}>
            <Grid
              className={`${classes.bgColor} ${classes.myPathCourseListMainContainer}`}
              container
              direction="row"
            >
              <Grid item xs={12}>
                <Tabs
                  value={selectedMyPathCategoryTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  className={`${classes.tabs}`}
                  data-testid="student-my-path-tabs"
                  onChange={this.handleChangeMyPathCategoryTab}
                  aria-label="student-my-path-tabs"
                >
                  {myPathCategoryTabsList.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`student-my-path-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabButton} ${classes.textPrimaryColor}`}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid className={`${classes.tabPanel}`} item xs={12}>
                <TabPanel value={selectedMyPathCategoryTab} index={0}>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      {studentMyPathCourseList?.length > 0 ? (
                        studentMyPathCourseList?.map((courseItem: any) => (
                          <React.Fragment key={courseItem?.id}>
                            <MyPathItemCard
                              navigation={navigation}
                              myPathItem={courseItem}
                              myPathCourseCategory="today"
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        <CustomNoDataComponent noDataText="No items yet." />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {studentMyPathCourseList.length > 0 &&
                      <Box className={classes.paginationBox}>
                        <Box className={classes.paginationRoot}>
                          <Pagination
                            shape="rounded"
                            variant="outlined"
                            count={Math.ceil(paginationDueList?.total_count / paginationDueList?.per_page)}
                            siblingCount={0}
                            boundaryCount={1}
                            onChange={this.handleMyPathPaginationChange}
                            page={paginationDueList?.current_page}
                          />
                        </Box>
                      </Box>
                    }
                  </Grid>
                </Grid>
                </TabPanel>
                <TabPanel value={selectedMyPathCategoryTab} index={1}>
                  <MyPathOverdue
                    myPathItemList={studentMyPathCourseList}
                    myPathCourseCategory="overdue"
                    handlePaginationChange={this.handleMyPathPaginationChange} 
                    paginationDueList={paginationDueList}  
                    handleChangeDropdownValue={this.handleChangeDropdownValue}
                    renderDueDateComponent={this.renderDueDateComponent}
                    selectedSortByDueDateDDValue={this.state.selectedSortByDueDateDDValue}
                    
                  />
                </TabPanel>
                <TabPanel value={selectedMyPathCategoryTab} index={2}>
                  <MyPathOverdue
                    myPathItemList={studentMyPathCourseList}
                    myPathCourseCategory="upcoming"
                    handlePaginationChange={this.handleMyPathPaginationChange} 
                    paginationDueList={paginationDueList} 
                    handleChangeDropdownValue={this.handleChangeDropdownValue}
                    renderGradingComponent={this.renderGradingComponent}
                    handleSearchMyPathCourses={this.handleSearchMyPathCourses}
                    handleChangeDateFilter={this.handleChangeDateFilter}
                    selectedDateFilterText={this.state.selectedDateFilterText}
                    selectedGradingComponentDDValue={this.state.selectedGradingComponentDDValue}
                  />
                </TabPanel>
                <TabPanel value={selectedMyPathCategoryTab} index={3}>
                  <MyPathOverdue
                    myPathItemList={studentMyPathCourseList}
                    myPathCourseCategory="review"
                    handlePaginationChange={this.handleMyPathPaginationChange}
                    selectedSortByGradeDDValue={this.state.selectedSortByGradeDDValue} 
                    paginationDueList={paginationDueList}
                    handleSearchMyPathInReview={this.handleSearchMyPathInReview}
                    renderDueDateComponent={this.renderDueDateComponent}
                    handleChangeDropdownValue={this.handleChangeDropdownValue}
                    handleChangeDateReviewFilter={this.handleChangeDateReviewFilter}
                    selectedDateReviewFilterText={this.state.selectedDateReviewFilterText}
                    selectedSortByCompletionDateDDValue={this.state.selectedSortByCompletionDateDDValue}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  width: "130px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  marginBottom: "2px",
} as const;

const selectDropdownStyles = {
  width: "235px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentMyPath);
// Customizable Area End
