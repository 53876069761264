// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTitle: string;
  selectedSubject: string;
  selectedGrade: string;
  selectedRowId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class BrowseCourseCatalogModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      selectedTitle: "",
      selectedSubject: "",
      selectedGrade: "",
      selectedRowId: -1,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();

  }

  renderDropdownValue = (selected: any, dropdownName: string, dropdownItems: any) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
    return selctedOption?.label;
  };

  handleDropdownChange = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  getDropdownItemsArray = (dropdownName: string) => {
    const items = [1, 2, 3, 4, 5];
    let resultArray: Array<any> = [];
    items.forEach(obj => {
      resultArray.push({
        id: obj,
        value: `${dropdownName}${obj}`,
        label: `${dropdownName}${obj}`,
      });
    });
    return resultArray;
  }

  handleSearch = () => {
    console.log("Search");
  }

  handleSelectRow = (tableRowId: number) => {
    if (tableRowId) {
      this.setState({ selectedRowId: tableRowId });
    } else {
      this.setState({ selectedRowId: -1 });
    }
  }
  // Customizable Area End
}
// Customizable Area End