// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, TextField, Input } from '@material-ui/core';
import AddNewCourseModalController, {
    Props
} from "./AddNewCourseModalController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../../components/src/CustomButton.web';
import { combineStyles } from '../../../../components/src/CombineStyles.web';
import Radio from '@material-ui/core/Radio';
import BrowseCourseCatalogModal from './BrowseCourseCatalogModal.web';
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        addCourseContainer: {
            "& .MuiDialog-paperWidthSm": {
                width: "880px",
                maxWidth: "100%",
                minHeight: "500px",
                overflowY: "hidden",
                overflowX: "hidden",
            },
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
        },
        customInput: {
            height: "50px",
            width: "100%",
            borderRadius: "12px",
            padding: "17px 15px",
            border: "1px solid #888",
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: 400,
            "&::after": {
                borderBottom: "0px"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            }
        },
        colorInput: {
            marginRight: "15px",
            "& .MuiInputBase-input": {
                width: "40px",
                height: "40px",
                padding: "0px",
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderBottom: "0px",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            }
        },
        radioButtonLabel: {
            color: "#888",
            fontWeight: 400,
            fontSize: "18px",
            marginRight: "30px",
            lineHeight: "20px",
        },
        checkedLabelColor: {
            color: '#FFD92E',
            fontWeight: 500,
        },
        radio: {
            width: "24px",
            height: "24px",
            '&$checked': {
                color: '#FFD92E'
            },
            "& .MuiSvgIcon-root": {
                fontSize: "30px !important"
            }
        },
        checked: {},
        browseTextField: {
            position: "relative",
            borderRadius: "12px",
            maxWidth: "100%",
            width: "400px",
            marginTop: "10px",
            height: "50px",
            border: "1px solid #888",
            "& .MuiInputBase-root": {
                height: "50px",
            }
        },
        fileInput: {
            display: 'none',
        },
        browseText: {
            width: "max-content",
            cursor: 'pointer',
            color: "#0D46BC",
            display: "block",
            fontSize: 14,
            fontWeight: 500,
        },
        requiredField: {
            color: "red"
        },
        inputColorCircle: {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            overflow: "hidden",
            marginRight: "15px",
        }
    })

// Customizable Area End

// Customizable Area Start
export class AddNewCourseModal extends AddNewCourseModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open } = this.props;
        const {
            selectedCourseType,
            openCourseCatalogModal,
            selectedCurriculamValue,
            selectedSubjectValue,
            selectedGradeValue,
            selectedStatusValue,
            selectedSchoolYearValue,
            selectedTermValue,
            selectedIcon,
            selectedCustomFieldValue
        } = this.state;

        return (
            <Box>
                <Dialog className={`${classes.highlightBgColor} ${classes.addCourseContainer}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open} scroll={'body'}>
                    <Box padding={"30px"} className={`${classes.bgColor}`}>
                        <Grid direction="row" container>
                            <Grid xs={12} item>
                                <Typography className={`${classes.fontText24} ${classes.textPrimaryColor}`} component={"span"}>Add New Course</Typography>
                                <Button onClick={this.props.onCancel} className={classes.textPrimaryColor} style={{ float: "right" }}> X </Button>
                            </Grid>
                        </Grid>
                        <DialogContent style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}>
                            <Grid container spacing={2} direction="row">
                                <Grid xs={12} item>
                                    <Box>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Course Type
                                        </Typography>
                                        <Box marginBottom={"10px"} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Box>
                                                <Radio
                                                    className={`${classes.radio} ${classes.checked}`}
                                                    checked={selectedCourseType === 'New'}
                                                    onChange={this.handleCourseTypeChange}
                                                    value="New"
                                                    name="new-course-radio-button"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                                <Typography component={"span"} className={selectedCourseType === 'New' ? `${classes.radioButtonLabel} ${classes.checkedLabelColor}` : `${classes.radioButtonLabel}`}> New </Typography>
                                                <Radio
                                                    className={`${classes.radio} ${classes.checked}`}
                                                    checked={selectedCourseType === 'Assign from Catalog'}
                                                    onChange={this.handleCourseTypeChange}
                                                    value="Assign from Catalog"
                                                    name="assign-from-catalog-radio-button"
                                                    inputProps={{ 'aria-label': 'B' }}
                                                />
                                                <Typography component={"span"} className={selectedCourseType === 'Assign from Catalog' ? `${classes.radioButtonLabel} ${classes.checkedLabelColor}` : `${classes.radioButtonLabel}`}> Assign from Catalog </Typography>
                                            </Box>
                                            <Box>
                                                {
                                                    selectedCourseType === 'Assign from Catalog' ?
                                                        <CustomButton btnText="Browse Course Catalog" btnStyle={browseCourseCatalogButton} buttonId={`Browse-Course-Catalog`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.handleOpenBrowseCourseCatalog} isDisabled={false} />
                                                        : ''
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Course Title<Typography component={"span"} className={classes.requiredField}>*</Typography>
                                        </Typography>
                                        <Input
                                            className={`${classes.customInput} ${classes.bgColor} ${classes.textPrimaryColor}`}
                                            type="text"
                                            placeholder="Enter Subject"
                                            inputProps={{
                                                disableUnderline: true,
                                                variant: 'standard',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Curriculum
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomSelectDropdown
                                                fieldId="curriculamDropdown"
                                                handleChange={this.handleDropdownChange}
                                                fieldName="selectedCurriculamValue"
                                                fieldValue={selectedCurriculamValue} fieldError={false}
                                                fieldMenuItems={this.getDropdownItemsArray("Curriculam")}
                                                renderValue={this.renderCurriculamDropdownValue}
                                                fieldStyles={dropdownStyle}
                                                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Subject
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomSelectDropdown
                                                fieldId="courseSubjectDropdown"
                                                fieldName="selectedSubjectValue"
                                                fieldValue={selectedSubjectValue}
                                                handleChange={this.handleDropdownChange}
                                                fieldError={false}
                                                fieldStyles={dropdownStyle}
                                                renderValue={this.renderSubjectDropdownValue}
                                                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                                fieldMenuItems={this.getDropdownItemsArray("Subject")}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Grade
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomSelectDropdown
                                                fieldId="courseGradeDropdown"
                                                fieldName="selectedGradeValue"
                                                fieldValue={selectedGradeValue}
                                                handleChange={this.handleDropdownChange}
                                                fieldError={false}
                                                fieldStyles={dropdownStyle}
                                                renderValue={this.renderGradeDropdownValue}
                                                fieldMenuItems={this.getDropdownItemsArray("Grade")}
                                                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={4} item>
                                    <Box>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Status<Typography component={"span"} className={classes.requiredField}>*</Typography>
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomSelectDropdown
                                                fieldId="courseStatusDropdown"
                                                handleChange={this.handleDropdownChange}
                                                fieldName="selectedStatusValue"
                                                fieldError={false}
                                                fieldValue={selectedStatusValue}
                                                renderValue={this.renderStatusDropdownValue}
                                                fieldMenuItems={this.getDropdownItemsArray("Status")}
                                                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                                fieldStyles={dropdownStyle}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={4} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Topic Count
                                        </Typography>
                                        <Input
                                            className={`${classes.customInput} ${classes.bgColor} ${classes.textPrimaryColor}`}
                                            type="text"
                                            placeholder="Enter Topic Count"
                                            inputProps={{
                                                disableUnderline: true,
                                                variant: 'standard',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={4} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Activity Count
                                        </Typography>
                                        <Input
                                            className={`${classes.customInput} ${classes.bgColor} ${classes.textPrimaryColor}`}
                                            type="text"
                                            placeholder="Enter Activity Count"
                                            inputProps={{
                                                disableUnderline: true,
                                                variant: 'standard',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={4} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            No. of lessons a day<Typography component={"span"} className={classes.requiredField}>*</Typography>
                                        </Typography>
                                        <Input
                                            className={`${classes.customInput} ${classes.bgColor} ${classes.textPrimaryColor}`}
                                            type="text"
                                            placeholder="Enter No. of lessons a day*"
                                            inputProps={{
                                                disableUnderline: true,
                                                variant: 'standard',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={4} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            School Year
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomSelectDropdown
                                                fieldId="schoolYearDropdown"
                                                fieldName="selectedSchoolYearValue"
                                                handleChange={this.handleDropdownChange}
                                                fieldValue={selectedSchoolYearValue}
                                                fieldMenuItems={this.getDropdownItemsArray("202")}
                                                fieldError={false}
                                                fieldStyles={dropdownStyle}
                                                renderValue={this.renderSchoolYearDropdownValue}
                                                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={4} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Term
                                        </Typography>
                                        <Box marginBottom={"10px"}>
                                            <CustomSelectDropdown
                                                fieldId="termDropdown"
                                                handleChange={this.handleDropdownChange}
                                                fieldName="selectedTermValue"
                                                fieldValue={selectedTermValue}
                                                fieldError={false}
                                                fieldMenuItems={this.getDropdownItemsArray("0")}
                                                renderValue={this.renderTermDropdownValue}
                                                fieldStyles={dropdownStyle}
                                                iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Color<Typography component={"span"} className={classes.requiredField}>*</Typography>
                                        </Typography>
                                        <Box style={{ padding: "0px", display: "flex", alignItems: "center", justifyContent: "space-between" }} className={`${classes.customInput} ${classes.bgColor} ${classes.textPrimaryColor}`}>
                                            <Typography component={"span"} style={{ marginLeft: "15px" }} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Select Color
                                            </Typography>
                                            <Box className={classes.inputColorCircle}>
                                                <Input
                                                    className={classes.colorInput}
                                                    type="color"
                                                    placeholder="Enter Activity Count"
                                                    inputProps={{
                                                        disableUnderline: true,
                                                        variant: 'standard',
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Icon<Typography component={"span"} className={classes.requiredField}>*</Typography>
                                        </Typography>
                                        <TextField
                                            value={selectedIcon ? selectedIcon.name : ''}
                                            variant="outlined"
                                            className={`${classes.bgColor} ${classes.browseTextField}`}
                                            placeholder='Upload Icon'
                                            inputProps={{
                                                className: `${classes.textfieldInput} ${classes.textFieldFont} ${classes.textPrimaryColor
                                                    }`,
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                },
                                                readOnly: true,
                                                endAdornment: (
                                                    <div className={`${classes.bgColor}`}>
                                                        <input
                                                            type="file"
                                                            id="file-input"
                                                            className={`${classes.bgColor} ${classes.fileInput}`}
                                                            onChange={this.handleFileInputChange}
                                                        />
                                                        <span className={classes.browseText} onClick={this.handleBrowseClick}>
                                                            Browse Icon
                                                        </span>
                                                        {selectedIcon && (
                                                            <span className={classes.browseText} onClick={this.handleFileUpload}>
                                                                Upload
                                                            </span>
                                                        )}
                                                    </div>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Custom Field
                                        </Typography>
                                        <CustomSelectDropdown
                                            fieldId="courseCustomFieldDropdown"
                                            handleChange={this.handleDropdownChange}
                                            fieldName="selectedCustomFieldValue"
                                            fieldError={false}
                                            fieldValue={selectedCustomFieldValue}
                                            renderValue={this.renderCustomFieldDropdownValue}
                                            fieldMenuItems={this.getDropdownItemsArray("Custom Field")}
                                            iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                            fieldStyles={dropdownStyle}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box marginBottom={"10px"} width={"100%"}>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Description
                                        </Typography>
                                        <Input
                                            className={`${classes.customInput} ${classes.bgColor} ${classes.textPrimaryColor}`}
                                            type="text"
                                            placeholder="Enter Description"
                                            inputProps={{
                                                disableUnderline: true,
                                                variant: 'standard',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{ width: "100%" }} marginBottom={"10px"}>
                                        <CustomButton btnText="Submit" buttonId={`newCourseSubmitButton`} buttonVariant={"contained"} btnStyle={assignButtonStyle} handleButtonClick={this.props.onCancel} type={"button"} isDisabled={false} />
                                        <CustomButton btnText="Cancel" buttonVariant={"outlined"} btnStyle={cancelButtonStyle} buttonId={`newCourseCancelButton`} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                </Dialog>
                <BrowseCourseCatalogModal
                    open={openCourseCatalogModal}
                    onConfirm={this.handleCloseBrowseCourseCatalog}
                    onCancel={this.handleCloseBrowseCourseCatalog}
                />
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const cancelButtonStyle = {
    width: "120px",
    padding: "10px 10px",
    height: "50px",
    border: "1px solid",
    borderRadius: "12px",
    fontSize: "18px",
    marginTop: "32px",
    textAlign: "center",
    float: "right",
    marginLeft: "15px",
    fontWeight: 500,
} as const;

const assignButtonStyle = {
    padding: "10px 10px",
    height: "50px",
    width: "120px",
    fontSize: "18px",
    textAlign: "center",
    borderRadius: "12px",
    marginTop: "32px",
    float: "right",
    marginLeft: "15px",
    fontWeight: 500,
} as const;

const browseCourseCatalogButton = {
    padding: "10px 10px",
    height: "56px",
    width: "400px",
    fontSize: "18px",
    border: "1px solid",
    borderRadius: "12px",
    fontWeight: 500,
    float: "right",
    textAlign: "center",
} as const;

const dropdownStyle = {
    height: "50px",
    width: "100%",
    borderRadius: "12px",
    padding: "17px 15px",
    background: "#FFF",
    border: "1px solid #888",
    fontSize: "14px",
    color: "#888",
    marginTop: "10px",
    fontWeight: 400,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewCourseModal);
// Customizable Area End
