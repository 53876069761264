import { tableColumnTypes } from "../../../components/src/CustomTable.web";

const studentTableHeaderColumns = [
  {
    id: "Student",
    columnId: 1,
    label: "Name",
    type: tableColumnTypes.STUDENT_ICON,
    width: "200px",
    maxWidth: "220px"
  },
  {
    id: "Progress",
    columnId: 2,
    label: "Progress",
    type: tableColumnTypes.STUDENT_PROGRESS_BAR,
    width: "auto",
    maxWidth: "auto"
  },
  {
    id: "SemesterAvg",
    columnId: 3,
    label: "Semester Avg",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "120px"
  },
  {
    id: "GPA",
    columnId: 4,
    label: "GPA",
    type: tableColumnTypes.PROGRESS_TEXT,
    width: "100px",
    maxWidth: "120px"
  },
  {
    id: "LastAccess",
    columnId: 5,
    label: "Last Access",
    type: tableColumnTypes.DUE_DATE,
    width: "100px",
    maxWidth: "120px"
  },
];

export { studentTableHeaderColumns };