// Customizable Area Start
import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CloseIcon from "@material-ui/icons/Close";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import AddUserRoleSelectPopupController, {
  Props,
} from "./AddUserRoleSelectPopupController.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    userSelectPopupMainBox: {
      width: "450px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
      },
    },
    closeIcon: {
      cursor: "pointer",
      right: theme.spacing(4),
      top: theme.spacing(2.6),
      [theme.breakpoints.down("sm")]: {
        top: theme.spacing(5),
        width: theme.spacing(1.5),
        right: theme.spacing(4),
      },
    },
    userRoleSelectDialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    textAlignRight: {
      textAlign: "right",
    },
  });

const nextBtnStyle = {
  borderRadius: "12px",
  fontWeight: 600,
  padding: "10px",
  width: "130px",
  height: "50px",
  fontSize: "16px",
} as const;

const cancelBtnStyle = {
  marginRight: "15px",
  fontSize: "16px",
  borderRadius: "12px",
  padding: "10px",
  width: "130px",
  height: "50px",
  fontWeight: 600,
} as const;

// Customizable Area End

// Customizable Area Start
export class AddUserRoleSelectPopup extends AddUserRoleSelectPopupController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes, open, roleOptions } = this.props;
    const { selectedRole } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClosePopup}
        data-testid="user-role-select-dialog-popup"
        className={`${classes.userRoleSelectDialog} ${classes.highlightBgColor}`}
      >
        <Box className={`${classes.bgColor} ${classes.userSelectPopupMainBox}`}>
          <DialogTitle id="user-role-select-dialog-title">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                component={"span"}
                className={`${classes.fontBold600} ${classes.fontText24} ${classes.textPrimaryColor}`}
              >
                Select User Role
              </Typography>
              <CloseIcon
                className={`${classes.textCommonColor} ${classes.closeIcon}`}
                id="user-role-select-close-modal-icon"
                onClick={this.handleClosePopup}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box marginBottom={"20px"}>
              <Box marginBottom={"10px"} display={"flex"}>
                <Typography
                  component={"span"}
                  className={`${classes.fontText14} ${classes.textCommonColor}`}
                >
                  Role
                </Typography>
              </Box>
              <CustomSelectDropdown
                fieldId="role-select-dropdown-csv-popup"
                fieldName="selectedRole"
                renderValue={this.renderDDMenuItem}
                fieldValue={selectedRole}
                handleChange={this.handleSelectDropdownValue}
                fieldError={false}
                fieldStyles={{ width: "100%" }}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
                fieldMenuItems={roleOptions}
              />
            </Box>
            <Box
              margin={"30px 0px"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <CustomButton
                btnStyle={cancelBtnStyle}
                btnText="Cancel"
                buttonId="user-role-select-cancel-btn"
                isDisabled={false}
                buttonVariant={"outlined"}
                type={"button"}
                handleButtonClick={this.handleClosePopup}
              />
              <CustomButton
                btnStyle={nextBtnStyle}
                buttonVariant={"contained"}
                btnText="Next"
                buttonId="user-role-select-next-btn"
                type={"button"}
                handleButtonClick={this.handleNext}
                isDisabled={!selectedRole}
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddUserRoleSelectPopup);
// Customizable Area End
