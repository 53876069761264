// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { CreateGradingComponentRequestBody, GradingListComponent } from "./TenantAdminGradeBookController";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm:(requestBody:CreateGradingComponentRequestBody) => void;
  open: boolean;
  isEdit?: boolean;
  gradeId:number;
  handleUpdateCourse: any;
  handleAddNewCourse: any;
  userGradingDetails?:GradingListComponent
  courseId: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedGradeDDValue: string;
  gradesDropdownList: Array<{ value: string; label: string }>;
  courseTitleValue: string;
  courseTitleValueError: boolean;
  courseTitleValueErrorMsg: string;
  loading: boolean;
  minAttempt: string;
  minAttemptError: boolean;
  minAttemptErrorMsg: string;
  maxAttempt: string;
  maxAttemptError: boolean;
  maxAttemptErrorMsg: string;
  recommendedValue: string;
  recommendedValueError: boolean;
  recommendedValueErrorMsg: string;
  selectedGradeError:boolean,
  selectedGradeErrorMsg:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewGradingComponentPopController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectedGradeDDValue: "",
      gradesDropdownList: [
        { value: "assignment", label: "Assignment" },
        { value: "quiz", label: "Quiz" },
        { value: "exam", label: "Exam" },
        { value: "project", label: "Project" },
      ],
      courseTitleValue: "",
      courseTitleValueError: false,
      courseTitleValueErrorMsg: "",
      loading: false,
      minAttempt: "",
      minAttemptError: false,
      minAttemptErrorMsg: "",
      maxAttempt: "",
      maxAttemptError: false,
      maxAttemptErrorMsg: "",
      recommendedValue: "",
      recommendedValueError: false,
      recommendedValueErrorMsg: "",
      selectedGradeError:false,
      selectedGradeErrorMsg:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on receive==>" + JSON.stringify(message));
  }

  async componentDidMount() {
    await super.componentDidMount();
  }
  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.isEdit &&
      (prevProps.userGradingDetails !== this.props.userGradingDetails ||
        prevProps.isEdit !== this.props.isEdit)
    ) {
      if (this.props.userGradingDetails) {
        this.setState({
          selectedGradeDDValue: this.props.userGradingDetails.titles || "",
          minAttempt: this.props.userGradingDetails.minAttempts.toString() || "",
          maxAttempt: this.props.userGradingDetails.maxAttempts.toString() || "",
          courseTitleValue: this.props.userGradingDetails.weightage.toString() || "",
          recommendedValue:this.props.userGradingDetails.recommended.toString() || "",
        });
      }
    }
   
  }

  checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
    if (!selected || selected.length === 0 || selected === -1) {
      return `Select ${dropdownName}`;
    }
    const selectedOption = dropdownItems.find((item: any) => item?.value === selected);
    return selectedOption?.label;
  };

  handleClosePopup = () => {
    this.handleEmptyField();
    this.props.onCancel();
  };

  handleEmptyField = () => {
    this.setState({
      selectedGradeDDValue: "",
      courseTitleValue: "",
      minAttempt: "",
      maxAttempt: "",
      recommendedValue: "",
    });
  };

  renderGradeDDValues = (selected: any) => {
    const dropdownName = "Title";
    const dropdownItems = this.state.gradesDropdownList;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  getDropdownItemArray = (dropdownName: string) => {
    const itemArray = [1, 2, 3, 4, 5];
    let result: Array<any> = [];
    itemArray.forEach((obj) => {
      result.push({
        id: obj,
        value: `${dropdownName}${obj}`,
        label: `${dropdownName}${obj}`,
      });
    });
    return result;
  };

  handleDropdownValues = (event: React.ChangeEvent<{ name?: any; value: string }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      if (name === "selectedGradeDDValue") {
        if (value.length) {
          this.setState({ selectedGradeError: false, selectedGradeErrorMsg: "" });
        }
      } 
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const isValidNumber = (val: string) => !isNaN(Number(val));
  
    const errorMessages: { [key: string]: string } = {
      courseTitleValue: "Invalid number",
      minAttempt: "Invalid number",
      maxAttempt: "Invalid number",
      recommendedValue: "Invalid number",
    };
  
    const hasError = !isValidNumber(value);
    const errorField = `${name}Error` as keyof  S;
    const errorMsgField = `${name}ErrorMsg` as keyof S;
  
    
    this.setState((prevState) => ({
      ...prevState,
      [errorField]: hasError,
      [errorMsgField]: hasError ? errorMessages[name] : "",
      [name]: value,
    }) as Pick<S, keyof S>);
  };
  
  handleRequestBody = () => {
    if (this.handleFormValidation()) {
      const { courseTitleValue, selectedGradeDDValue,minAttempt,maxAttempt,recommendedValue } = this.state;

      const requestBody = {
        grade_book_id:this.props.gradeId,
        title: selectedGradeDDValue,
        min_attempts:parseInt(minAttempt),
        max_attempts:parseInt(maxAttempt),
        recommended:parseInt(recommendedValue),
        weightage:parseInt(courseTitleValue),
      };
      console.warn("gradecompoent",requestBody);
      this.props.onConfirm(requestBody);
      this.handleEmptyField() 
    }
  };
  handleFormValidation = () => {
    let isValid = true;
    const { courseTitleValue, minAttempt, maxAttempt, recommendedValue, selectedGradeDDValue } = this.state;
  
    const fieldsToValidate = [
      { value: courseTitleValue, errorField: 'courseTitleValueError', errorMsgField: 'courseTitleValueErrorMsg', errorMsg: 'Please enter a valid weightage value' },
      { value: minAttempt, errorField: 'minAttemptError', errorMsgField: 'minAttemptErrorMsg', errorMsg: 'Please enter a valid min Attempt value' },
      { value: maxAttempt, errorField: 'maxAttemptError', errorMsgField: 'maxAttemptErrorMsg', errorMsg: 'Please enter a valid max Attempt value' },
      { value: recommendedValue, errorField: 'recommendedValueError', errorMsgField: 'recommendedValueErrorMsg', errorMsg: 'Please enter a valid recommended value' }
    ];
  
    fieldsToValidate.forEach(field => {
      if (isNaN(parseInt(field.value)) || !field.value.trim()) {
        this.setState(prevState => ({
          ...prevState,
          [field.errorField]: true,
          [field.errorMsgField]: field.errorMsg
        }));
        isValid = false;
      }
    });
  
    if (!selectedGradeDDValue.trim()) {
      this.setState(prevState => ({
        ...prevState,
        selectedGradeError: true,
        selectedGradeErrorMsg: "Please Select Title"
      }));
      isValid = false;
    }
  
    return isValid;
  };
  


  // Customizable Area End
}
// Customizable Area End
