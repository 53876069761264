// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentProfileAssignedActivitiesListController, {
  Props,
} from "./StudentProfileAssignedActivitiesListController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CustomTable from "../../../components/src/CustomTable.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    actBreadCrumbRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    BoxTopBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    capitalText: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentProfileAssignedActivitiesList extends StudentProfileAssignedActivitiesListController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { typeOfActivity, tableBody, tableColumns } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.actBreadCrumbRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Students`}
          </Typography>
          <Typography
            className={`${classes.textButton} ${classes.fontBold600} ${classes.fontText14} ${classes.capitalText}`}
            component={"span"}
          >
            {` / ${typeOfActivity} Activity`}
          </Typography>
        </Box>
        <Box className={classes.BoxTopBtnRoot}>
          <Box
            data-test-id="activity_type"
            className={`${classes.cursorPointer}`}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              onClick={() => this.handleGoBack()}
              data-test-id="activity_type_back_icon"
              mr={"8px"}
            >
              <KeyboardBackspaceIcon
                fontSize="large"
                className={`${classes.textPrimaryColor}`}
              />
            </Box>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600} ${classes.capitalText}`}
              component={"span"}
            >
              {typeOfActivity} Activity
            </Typography>
          </Box>
        </Box>
        <Box className={classes.bgColor} borderRadius={"10px"}>
          <CustomTable
            tableId={`student-${typeOfActivity}-activity-table`}
            tableHeaderColumns={tableColumns}
            tableBodyData={tableBody}
            showMenuIcon={false}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProfileAssignedActivitiesList);
// Customizable Area End
