// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const BookIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path fill="none" d="M32.0837 6.61557C32.0837 4.91557 30.6545 3.65474 28.9191 3.79641H28.8316C25.7691 4.05141 21.117 5.56724 18.5212 7.15391L18.2732 7.30974C17.8503 7.56474 17.1503 7.56474 16.7274 7.30974L16.3628 7.09724C13.767 5.52474 9.12949 4.02307 6.06699 3.78224C4.33158 3.64057 2.91699 4.91557 2.91699 6.6014V23.7147C2.91699 25.0747 4.05449 26.3497 5.45449 26.5197L5.87741 26.5764C9.04199 26.9872 13.9274 28.5456 16.7274 30.0331L16.7857 30.0614C17.1795 30.2739 17.8066 30.2739 18.1857 30.0614C20.9857 28.5597 25.8857 26.9872 29.0649 26.5764L29.5462 26.5197C30.9462 26.3497 32.0837 25.0747 32.0837 23.7147V6.61557Z" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path fill="none" d="M17.5 7.77734V29.0273" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path fill="none" d="M11.3018 12.0273H8.02051" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path fill="none" d="M12.3955 16.2773H8.02051" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default BookIcon;
// Customizable Area End