// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { parent1 } from "./assets";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";

const configJSON = require("./config.js");

interface IRegistrarInfoProps {
  email: string;
  uniqId: string;
  firstName: string;
  lastName: string;
  profileImg: string;
  role: string;
  status: string;
  customFields: Array<{ id?: number; label?: string; value?: string }>;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  registrarDetails: IRegistrarInfoProps;
  profileSettingOption: Array<{ label: string; clickHandle: () => void }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class RegistrarProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetRegistrarProfileId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      registrarDetails: {
        uniqId: "",
        email: "",
        profileImg: "",
        status: "",
        lastName: "",
        firstName: "",
        customFields: [],
        role: "",
      },
      profileSettingOption: [
        {
          label: "Edit Profile",
          clickHandle: () => this.onEditRegistrarProfileBtn(),
        },
        {
          label: "Change Password",
          clickHandle: () => this.handleChangePasswordBtn(),
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  onEditRegistrarProfileBtn = () => {
    this.props.navigation.navigate("EditProfileRegistrar");
  };

  handleChangePasswordBtn = () => {
    this.props.navigation.navigate("RegistrarChangePassword");
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetRegistrarProfileInfo();
    // Customizable Area End
  }
  // Customizable Area Start
  handleGetRegistrarInfoResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const customFields: any = responseJson?.data?.attributes?.user_custom_fields?.data?.map(
        (item: {
          attributes: { id: number; label: string; field_value: string };
        }) => {
          return {
            id: item?.attributes?.id,
            label: item?.attributes?.label,
            value: item?.attributes?.field_value,
          };
        }
      );
      const teacherInfo: IRegistrarInfoProps = {
        uniqId: responseJson?.data?.attributes?.id_number?.toString() || "NA",
        email: responseJson?.data?.attributes?.email || "NA",
        firstName: responseJson?.data?.attributes?.first_name || "",
        lastName: responseJson?.data?.attributes?.last_name || "",
        role: responseJson?.data?.attributes?.role?.name || "",
        status: responseJson?.data?.attributes?.status || "",
        profileImg: responseJson?.data?.attributes?.profile_url || "",
        customFields: customFields,
      };
      this.setState({ registrarDetails: teacherInfo });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetRegistrarProfileInfo = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const teacherId = await getStorageData("user_id");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRegistrarProfileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${teacherId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiGetRegistrarProfileId){
          this.handleGetRegistrarInfoResponse(responseJson)
        }
      }
      // Customizable Area Start

      // Customizable Area End
    }
  }
  // Customizable Area End
}
// Customizable Area End
