// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { CurriculumList } from "../../../blocks/dashboard/src/TenantAdminGradeBookController";


// Customizable Area End

// Customizable Area Start
// export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: (response:any) => void;
  open: boolean;
  isEdit?: boolean;
  handleUpdateCourse: any;
  handleAddNewCourse: any;
  courseId: number;
  curriculumList:CurriculumList[]
  selectedIds:string[];
  gradeId:number;
  selectedCurriculum:CurriculumList[]
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  titleValue: string;
  titleError: boolean;
  titleErrorMsg: string;
  loading: boolean;
  minScore: string;
  minScoreError: boolean;
  minScoreErrorMsg: string;
  maxScore: string;
  maxScoreError: boolean;
  maxScoreErrorMsg: string;
  selectedSubjects: Array<any>;

  selectedGrade: any;
  selectedGradeTitle: string;
  selectedGradeErrorMsg: string;
  selectedGradeError: boolean;
  selectedSubjectsError: boolean;
  selectedSubjectsErrorMsg: string;
  subjectsListBasedOnGrade:any[],
  selectedCurriculum: any;
  selectedCurriculumTitle: string;
  selectedCurriculumError: boolean;
  selectedCurriculumErrorMsg: string;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddGradeBookRulesPopController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {

      titleValue: "",
      titleError: false,
      titleErrorMsg: "",
      loading: false,
      minScore: "",
      minScoreError: false,
      minScoreErrorMsg: "",
      maxScore: "",
      maxScoreError: false,
      maxScoreErrorMsg: "",
      selectedSubjects: [],
      subjectsListBasedOnGrade:[],
      selectedGradeTitle: "",
      selectedGradeError: false,
      selectedGradeErrorMsg: "",
      // selectedSubjects: [],
      selectedGrade: "",
      selectedSubjectsError: false,
      selectedSubjectsErrorMsg: "",
      selectedCurriculum: "",
      selectedCurriculumTitle: "",
      selectedCurriculumError: false,
      selectedCurriculumErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  async componentDidMount() {
    await super.componentDidMount();

  }
  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.isEdit &&
      (prevProps.selectedIds !== this.props.selectedIds ||
        prevProps.isEdit !== this.props.isEdit)
    ) {
      if (this.props.selectedIds) {
        this.setState({
          selectedSubjects:this.props.selectedIds
        });
      }
    }
  }


  handleClosePopup = () => {
    this.handleEmptyField();
    this.props.onCancel();
  };

  handleEmptyField = () => {
    this.setState({
      titleValue: ""
    });
  };
 

  handleChangeSubject = (event: any) => {
    console.log(event.target.value,"hello world")
    this.setState(
      {
        selectedSubjects: event.target.value,
        selectedSubjectsError: false,
        selectedSubjectsErrorMsg: "",
        selectedCurriculum: "",
        selectedCurriculumTitle: "",
        selectedCurriculumError: false,
        selectedCurriculumErrorMsg: "",
      },
    );
  };
  handleValidateFormData = () => {
    const { selectedSubjects} = this.state;
    let isValid = true;
    if (selectedSubjects?.length === 0) {
      this.setState({
        selectedSubjectsError: true,
        selectedSubjectsErrorMsg: "Please select at least 1 curriculum",
      });
      isValid = false;
    }
    return isValid;
  };

  handleAddEvent = () => {
    if (
      this.handleValidateFormData() 
    ) {
      const {
        selectedSubjects,
      } = this.state;
      console.log("hello world",selectedSubjects)
      const requestBody = {
        grade_book_id: this.props.gradeId,
        curriculum_ids: selectedSubjects
      }
      this.props.onConfirm(requestBody)
    }
  };

  removeSelectedValue = (value: any) => {
    const { selectedSubjects, selectedGrade } = this.state;
    const updatedSubjects = selectedSubjects.filter(
      (item: any) => item !== value
    );
    this.setState(
      {
        selectedSubjects: updatedSubjects || [],
        selectedCurriculum: "",
        selectedCurriculumTitle: "",
        selectedCurriculumError: false,
        selectedCurriculumErrorMsg: "",
      },
    );
  };
  // Customizable Area End
}
// Customizable Area End
