import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import {
  mathSubjectIcon,
  physicsSubjectIcon,
  englishSubjectIcon,
  historySubjectIcon,
  psychologySubjectIcon,
} from "../src/assets";


const TemplateData = [
  {
      id:1,
      title: "Offical Template",
      value:"official_template",
      templateId:-1
  },
  {
      id:2,
      title: "Unoffical Template",
      value:"unofficial_template",
      templateId:-1
  }
]
const userTypesListMock = [
  {
    id: "students",
    user_type: "Students",
    active: 8054,
    inactive: 1798,
    new: 50,
    total_count: 10000,
    total: "",
  },
  {
    id: "teachers",
    user_type: "Teachers",
    active: 4100,
    inactive: 143,
    new: 4,
    total_count: 5000,
    total: "",
  },
  {
    id: "parent_teachers",
    user_type: "Parent Teachers",
    active: 4500,
    inactive: 500,
    new: 10,
    total_count: 5000,
    total: "",
  },
  {
    id: "registrars",
    user_type: "Registrars",
    active: 300,
    inactive: 74,
    new: 0o5,
    total_count: 500,
    total: "",
  },
];

const userTypesTableHeaderMock = [
  {
    columnId: 0o1,
    id: "user_type",
    label: "User Type",
    type: tableColumnTypes.TEXT,
    width: "300px",
    maxWidth: "350px",
  },
  {
    columnId: 0o2,
    id: "active",
    label: "Active",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "100px",
  },
  {
    columnId: 0o3,
    id: "inactive",
    label: "Inactive",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "100px",
  },
  {
    columnId: 0o4,
    id: "new",
    label: "New",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "100px",
  },
  {
    columnId: 0o5,
    id: "total",
    label: "Total",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "100px",
  },
];

const coursesDetailsMock = [
  {
    label: "Total",
    count: 500,
  },
  {
    label: "For Approval",
    count: 352,
  },
  {
    label: "Archived",
    count: 148,
  },
  {
    label: "Active",
    count: 352,
  },
  {
    label: "Inactive",
    count: 148,
  },
];

const topTenCoursesListMock = [
  {
    subject: {
      name: "Science 101",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    id: "Science 101",
    noOfLessons: 8,
    noOfParticipants: 854,
  },
  {
    subject: {
      name: "History: European History",
      icon: historySubjectIcon,
      bgColor: "#FBD82C",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    noOfLessons: 8,
    noOfParticipants: 753,
    id: "History: European History",
  },
  {
    subject: {
      name: "Chemistry: Basics",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    id: "Chemistry: Basics",
    noOfLessons: 8,
    noOfParticipants: 725,
  },
  {
    subject: {
      name: "Biology",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    id: "Biology",
    noOfLessons: 8,
    noOfParticipants: 680,
  },
  {
    subject: {
      name: "English Literature 103",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    id: "English Literature 103",
    noOfLessons: 5,
    noOfParticipants: 552,
  },
  {
    subject: {
      name: "Coding: Python",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    id: "Coding: Python",
    noOfLessons: 14,
    noOfParticipants: 410,
  },
  {
    subject: {
      name: "Physics",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    id: "Physics",
    noOfLessons: 14,
    noOfParticipants: 410,
  },
  {
    id: "Calculus",
    subject: {
      name: "Calculus",
      icon: mathSubjectIcon,
      bgColor: "#0D46BC",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    noOfLessons: 14,
    noOfParticipants: 410,
  },
  {
    id: "Geometry",
    subject: {
      name: "Geometry",
      icon: mathSubjectIcon,
      bgColor: "#0D46BC",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    noOfLessons: 14,
    noOfParticipants: 410,
  },
  {
    subject: {
      name: "Human Psychology 101",
      icon: psychologySubjectIcon,
      bgColor: "#C10A1B",
      boxWidth: "28px",
      boxHeight: "28px",
    },
    noOfLessons: 14,
    noOfParticipants: 410,
    id: "Human Psychology 101",
  },
];

const topTenCoursesTableHeaderMock = [
  {
    columnId: 0o1,
    id: "subject",
    label: "Top 10 Courses Title",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
    width: "500px",
    maxWidth: "550px",
  },
  {
    columnId: 0o2,
    id: "noOfLessons",
    label: "No. of Lessons",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "100px",
  },
  {
    columnId: 0o3,
    id: "noOfParticipants",
    label: "No. of Participants",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "100px",
  },
];

const fileStorageDetailsMock = [
  {
    label: "Free",
    count: "35GB 35%",
  },
  {
    label: "Teacher",
    count: "30GB 30% used",
  },
  {
    label: "Parent Teacher",
    count: "20GB 20% used",
  },
  {
    label: "Tenant Admin",
    count: "10GB 10% used",
  },
  {
    label: "Students",
    count: "5GB 5% used",
  },
];

const reportsListMock = [
  {
    report_title: "Student Progress and Performance per SY",
    file_url: "1",
    id: "1"
  },
  {
    report_title: "Student Progress and Performance per Curriculum",
    file_url: "2",
    id: "2"
  },
  {
    report_title: "Student Progress and Performance per grade",
    file_url: "3",
    id: "3"
  },
  {
    report_title: "Student Progress and Performance per class",
    file_url: "4",
    id: "4"
  },
  {
    report_title: "Student Progress",
    file_url: "5",
    id: "5"
  },
  {
    report_title: "Student Progress per grade",
    file_url: "6",
    id: "6"
  },
  {
    report_title: "Student Progress per class",
    file_url: "7",
    id: "7"
  },
  {
    report_title: "Student Performance",
    file_url: "8",
    id: "8"
  },
  {
    report_title: "Student Performance - per Course per grading component",
    file_url: "9",
    id: "9"
  },
  {
    report_title: "Student Enrolled per SY",
    file_url: "10",
    id: "10"
  },
  {
    report_title: "Student Enrolled per SY per Curriculum",
    file_url: "11",
    id: "11"
  },
  {
    report_title: "Student Masterlist",
    file_url: "12",
    id: "12"
  },
  {
    report_title: "Student Approvals",
    file_url: "13",
    id: "13"
  },
];

const reportsTableHeaderMock = [
  {
    columnId: 0o1,
    id: "report_title",
    label: "Report Title",
    type: tableColumnTypes.TEXT,
    width: "550px",
    maxWidth: "600px",
  },
  {
    columnId: 0o2,
    id: "file_url",
    label: "Action",
    type: tableColumnTypes.DOWNLOAD,
    width: "100px",
    maxWidth: "120px",
  }
];
const AttandanceTableHead=[
  {
      type: tableColumnTypes.TEXT,
      columnId: 1,
      id: "attendance",
      label: "Attendance",
      width: "100%",
      maxWidth:  "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 2,
      id: "qtr1",
      label: "1st Qtr",
      width: "100%",
      maxWidth: "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 3,
      id: "qtr2",
      label: "2nd Qtr",
      width: "100%",
      maxWidth: "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 4,
      id: "qtr3",
      label: "3rd Qtr",
      width: "100%",
      maxWidth: "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 5,
      id: "qtr4",
      label: "4th Qtr",
      width: "100%",
      maxWidth:  "100%",
  },
  {
      columnId: 6,
      id: "total",
      type: tableColumnTypes.NUMBER,
      label: "Total",
      width: "100%",
      maxWidth:"100%",
  },
]

const gradebookListTableHead = [
  {
    id: "Name",
    columnId: 1,
    label: "Academic Subjects",
    type: tableColumnTypes.SUBJECT_ICON_NAME_TEMPLATE,
    width: "100%",
    maxWidth: "100%", 
  },
  {
    id: "Q1Avg",
    columnId: 3,
    label: "1st Qtr",
    type: tableColumnTypes.GRADEBOOK_AVG,
  },
  {
    id: "Q2Avg",
    columnId: 4,
    label: "2nd Qtr",
    type: tableColumnTypes.GRADEBOOK_AVG,
    width: "100%",
    maxWidth: "100%",
  },
  {
    id: "Q3Avg",
    columnId: 5,
    label: "3rd Qtr",
  
    type: tableColumnTypes.GRADEBOOK_AVG,
    width: "100%",
    maxWidth: "100%",
  },
  {
    id: "Q4Avg",
    columnId: 6,
    label: "4th Qtr",
   
    type: tableColumnTypes.GRADEBOOK_AVG,
    width: "100%",
    maxWidth: "100%",
  },
  {
    id: "GPA",
    columnId: 7,
    label: "GPA",
    
    type: tableColumnTypes.NUMBER,
    width: "100%",
    maxWidth: "100%",
  },
  {
    id: "Grade",
    columnId: 8,
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "100%",
    maxWidth: "100%",
  },
];
const BehaviorsTableHead = [
  {
      type: tableColumnTypes.TEXT,
      columnId: 1,
      id: "title",
      label: "Desirable Behaviors",
      width: "100%",
      maxWidth:  "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 2,
      id: "qtr1",
      label: "1st Qtr",
      width: "100%",
      maxWidth: "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 3,
      id: "qtr2",
      label: "2nd Qtr",
      width: "100%",
      maxWidth: "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 4,
      id: "qtr3",
      label: "3rd Qtr",
      width: "100%",
      maxWidth: "100%",
  },
  {
      type: tableColumnTypes.NUMBER,
      columnId: 5,
      id: "qtr4",
      label: "4th Qtr",
      width: "100%",
      maxWidth:  "100%",
  },
]
const BehaviorsTableData=[
  {
      id:1,
      title:"Focused Attention",
      qtr1:86,
      qtr2:95,
      qtr3:74,
      qtr4:0,
  },
  {
      id:2,
      title:"Focused Obedience",
      qtr1:83,
      qtr2:0,
      qtr3:0,
      qtr4:0,
  },
  {
      id:1,
      title:"Truthfulness",
      qtr1:92,
      qtr2:0,
      qtr3:0,
      qtr4:0,
  }
]
export {
  userTypesListMock,
  userTypesTableHeaderMock,
  coursesDetailsMock,
  topTenCoursesListMock,
  topTenCoursesTableHeaderMock,
  fileStorageDetailsMock,
  reportsListMock,
  reportsTableHeaderMock,
  TemplateData,
  AttandanceTableHead,
  gradebookListTableHead,
  BehaviorsTableHead,
  BehaviorsTableData
};
