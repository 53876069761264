// Customizable Area Start
import React from "react";
import { TextField, InputAdornment, FormHelperText } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomTextFieldController, {
  Props,
} from "./CustomTextFieldController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    helperText:{
      color:'red'
    },
    textField: {
      borderRadius: "10px",
      padding: "5px 0px",
      position: "relative",
      "&. MuiInputBase-root.Mui-disabled": {
        backgroundColor: "grey",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        bottom: "-20px"
      }
    },
    textfieldInput: {
      fontWeight: "normal",
      paddingRight: "10px !important",
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    inputPadding: {
      paddingLeft: "13px !important",
      cursor:"pointer !important"
    },
    textFieldFont: {
      fontSize: "16px",
    },
  });

// Customizable Area End

export class CustomTextField extends CustomTextFieldController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      fieldName,
      fieldError,
      fieldValue,
      fieldId,
      fieldType,
      startAdornmentIcon,
      endAdornmentIcon,
      placeHolderText,
      isDisabled,
      helperText,
      fieldWidth,
      textFieldStyles,
      multiline,
      onKeyDown
    } = this.props;

    const getTextfieldClasses = () => {
      if (fieldError) {
        return `${classes.textField} ${classes.primaryBorder} ${classes.bgColor
          } ${classes.errorBorder}`;
      } else if (isDisabled) {
        return `${classes.textField} ${classes.primaryBorder} ${classes.secondaryBgColor
          }`;
      } else {
        return `${classes.textField} ${classes.bgColor} ${classes.primaryBorder}`;
      }
    };

    const getTextColorClass = () => {
      if (isDisabled) {
        return `${classes.textCommonColor}`;
      } else {
        return `${classes.textPrimaryColor}`;
      }
    }

    return (
      <>
      <TextField
        id={fieldId}
        placeholder={`${placeHolderText}`}
        name={`${fieldName}`}
        style={{
          width: fieldWidth,
          overflow: "hidden",
          ...textFieldStyles,
        }}
        multiline={multiline ? true : false}
        className={getTextfieldClasses()}
        disabled={isDisabled}
        type={fieldType}
        value={fieldValue}
        onChange={this.props.handleChange}
        onFocus={this.props.handleFocus}
        onClick={this.handleFocusOnField}
        // error={fieldError}
        // helperText={helperText}
        onKeyDown={this.props.onKeyDown}
        InputProps={{
          startAdornment: startAdornmentIcon ? (
            <InputAdornment className={classes.inputPadding} position="start">
              {startAdornmentIcon}
            </InputAdornment>
          ) : (
            ""
          ),
          endAdornment: endAdornmentIcon ? (
            <InputAdornment className={classes.inputPadding} position="start">
              {endAdornmentIcon}
            </InputAdornment>
          ) : (
            ""
          ),
          disableUnderline: true,
        }}
        inputProps={{
          className: startAdornmentIcon ? `${classes.textfieldInput} ${classes.textFieldFont} ${getTextColorClass()}`
            : `${classes.textfieldInput} ${classes.inputPadding} ${classes.textFieldFont} ${getTextColorClass()}`,
        }}
      />
      {fieldError && (
        <FormHelperText className={`${classes.helperText}`}>
          {helperText}
        </FormHelperText>
      )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomTextField);
// Customizable Area End
