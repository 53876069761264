import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  primaryColor: {
    color: "#888888"
  },
  fontText16: {
    fontSize: "16px"
  },
  boldFont: {
    fontWeight: 600
  },
}))

export const CustomPageNotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={`${classes.primaryColor} ${classes.fontText16} ${classes.boldFont}`} component={"span"}>
        {`Page not found`}
      </Typography>
    </Box>
  );
}

export default CustomPageNotFound;
