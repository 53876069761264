// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteSchoolYearConfirmModalController, {
  Props,
} from "./DeleteSchoolYearConfirmModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { trashIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    schoolYearDelete: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class DeleteSchoolYearConfirmModal extends DeleteSchoolYearConfirmModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, title, type } = this.props;
    return (
      <Dialog
        className={`${classes.highlightBgColor} ${classes.schoolYearDelete}`}
        aria-labelledby="school-year-delete"
        open={open}
      >
        <Box padding={"30px"} className={`${classes.bgColor}`}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            id="school-year-delete-box"
          >
            <Box display={"flex"} justifyContent={"center"} width={"94%"}>
              <img src={trashIcon} alt="delete" />
            </Box>
            <Box width={"6%"}>
              <IconButton
                data-test-id="pop"
                aria-label="more"
                style={{ float: "right" }}
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.props.onCancel}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <DialogContent
            style={{ padding: "0px" }}
            data-test-id="school-year-delete-dialog-content"
          >
            <Box textAlign={"center"} mt={"20px"} mb={"2px"}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
              >
                {type === "school_year"
                  ? "Sure want to delete school year?"
                  : "Sure want to delete curriculum?"}
              </Typography>
            </Box>
            <Box textAlign={"center"} mt={"10px"} mb={"2px"}>
              <Typography
                data-test-id="dltId"
                className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
              >
                {type === "school_year"
                  ? "Are you sure want to delete school year"
                  : "Are you sure want to delete curriculum"}
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.fontBold600}`}
              >
                {`${title} ?`}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <CustomButton
                btnText="No"
                buttonVariant={"outlined"}
                btnStyle={deleteCancelBtn}
                type={"button"}
                buttonId={`cancel-delete-school-year`}
                handleButtonClick={this.props.onCancel}
                isDisabled={false}
              />
              <CustomButton
                btnStyle={deleteYesBtn}
                buttonVariant={"contained"}
                type={"button"}
                btnText="Yes,Delete"
                handleButtonClick={this.props.onConfirm}
                isDisabled={false}
                buttonId={`confirm-delete-school-year`}
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const deleteCancelBtn = {
  padding: "10px 10px",
  height: "50px",
  fontSize: "18px",
  width: "208px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
} as const;

const deleteYesBtn = {
  padding: "10px 10px",
  width: "208px",
  height: "50px",
  borderRadius: "12px",
  textAlign: "center",
  fontSize: "18px",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(DeleteSchoolYearConfirmModal);
// Customizable Area End
