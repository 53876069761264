// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import {
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
  displayApiErrorMsg,
} from "../../../components/src/CommonHelper.web";
import {
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
  status,
} from "../../../components/src/CommonType.web";
import moment from "moment";
import _ from "lodash";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface MenuItems {
  id: number;
  value: any;
  label: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  openNewSchoolYearDialog: boolean;
  handleCloseSchoolYearDialog: any;
  permission?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectMenuItems: Array<MenuItems>;
  selectTitle: any;
  selectTitleError: boolean;
  selectTitleErrorMsg: string;

  selectGradePeriodTitle: any;
  selectGradePeriodTitleError: boolean;
  selectGradePeriodTitleErrorMsg: string;
  selectGradePeriodTitleItems: Array<MenuItems>;

  selectStatus: any;
  selectStatusError: boolean;
  selectStatusErrorMsg: string;
  selectStatusItems: Array<any>;
  schoolYearTableData: Array<any>;
  filteredSchoolYearTableData: Array<any>;
  schoolYearTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  selectedSchoolYearData: any;
  token: string;
  openStatusUpdatePopup: boolean;
  selectedStatus: any;
  openDeleteConfirmPopup: boolean;
  openEditSchoolYearPopup: boolean;
  schoolYearPermission:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class SchoolYearController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetSchoolYearData: string = "";
  apiPostNewSchoolYearData: string = "";
  apiUpdateSchoolYear: string = "";
  apiGetDropdownData: string = "";
  apiDeleteSchoolYear: string = "";
  apiUpdateSchoolYearStatus: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectTitle: "",
      selectTitleError: false,
      selectTitleErrorMsg: "",

      selectGradePeriodTitle: "",
      selectGradePeriodTitleError: false,
      selectGradePeriodTitleErrorMsg: "",

      selectStatus: "",
      selectStatusError: false,
      selectStatusErrorMsg: "",

      selectGradePeriodTitleItems: [],
      selectStatusItems: status,
      selectMenuItems: [],
      filteredSchoolYearTableData: [],
      schoolYearTableData: [],
      schoolYearTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenEditSchoolYearPopup(value),
        },
        {
          label: "Delete",
          action: (value: any) => this.handleOpenDeleteConfirmModal(value),
        },
        {
          label: "Update Status",
          action: (value: any) => this.handleOpenStatusModal(value),
        },
      ],
      selectedSchoolYearData: "",
      token: "",
      openStatusUpdatePopup: false,
      selectedStatus: "",
      openDeleteConfirmPopup: false,
      openEditSchoolYearPopup: false,
      schoolYearPermission:{
        schoolYear: {
          read: true,
          write: true
        },
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  renderTitleDropDownValue = (selectedTitle: any) => {
    if (!selectedTitle || selectedTitle.length === 0) {
      return "Select Title";
    }
    const { selectMenuItems } = this.state;
    const selectedValue = selectMenuItems.find(
      (status: any) => status?.value === selectedTitle
    );
    return selectedValue?.label;
  };

  renderGradePeriodTitleDropDownValue = (selectedGradePeriodTitle: any) => {
    if (!selectedGradePeriodTitle || selectedGradePeriodTitle.length === 0) {
      return "Select Grade Period Title";
    }
    const { selectGradePeriodTitleItems } = this.state;
    const selectedGradePeriodValue = selectGradePeriodTitleItems.find(
      (status: any) => status?.value === selectedGradePeriodTitle
    );
    return selectedGradePeriodValue?.label;
  };

  renderStatusDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Status";
    }
    const { selectStatusItems } = this.state;
    const selectedValue = selectStatusItems.find(
      (status: any) => status?.value === selected
    );
    return selectedValue.label;
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let textFieldValue: any = value;
    if (textFieldValue) {
      if (name === "selectTitle") {
        this.setState({
          selectTitleError: false,
          selectTitleErrorMsg: "",
        });
      }
      this.setState((prev) => {
        return {
          ...prev,
          [name]: textFieldValue,
        };
      });
    }
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetSchoolYearRoleAndPermission()
    this.handleGetSchoolYearList();
    this.handleSetUserToken();
    // Customizable Area End
  }
  // Customizable Area Start
  async handleGetSchoolYearRoleAndPermission(){
    const getSchoolYearPermission = await getStorageData("roleandpermission")
    if(getSchoolYearPermission){ 
      try {
        const parseData = JSON.parse(getSchoolYearPermission);
        if(parseData){
          const result = {
            schoolYear: parseData?.school_year?.can,
          };
          this.setState({schoolYearPermission:result})
        }
      } catch (error) {
        console.error("Failed to parse schoolYear. It might not be valid JSON.", error);
      }
    }
  }
  handleSetUserToken = async () => {
    const token = await getStorageData("token");
    this.setState({ token });
  };

  handleGetDropdownList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDropdownData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSchoolYearList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const { selectGradePeriodTitle, selectTitle, selectStatus } = this.state;
    const queryString = `?title=${selectTitle}&grade_period_title=${selectGradePeriodTitle}&status=${selectStatus}`;
    let getSchoolYearEndpoint = configJSON.getSchoolYear;
    if (selectGradePeriodTitle || selectTitle || selectStatus) {
      getSchoolYearEndpoint = `${getSchoolYearEndpoint}${queryString}`;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getSchoolYearEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteSchoolYear = async () => {
    this.handleCloseDeleteConfirmPopup();
    showCustomLoader();
    const { token, selectedSchoolYearData } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteSchoolYear = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSchoolYear}/${selectedSchoolYearData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetSchoolYearSemesterDataItem = (data: any) => {
    return {
      title: data.title,
      endDate: moment(data.end_date),
      startDate: moment(data.start_date),
      id: data.id,
      fieldErrorMsg: "",
      fieldError: false,
      endDateErrorMsg: "",
      endDateError: false,
      startDateErrorMsg: "",
      startDateError: false,
    };
  };

  handleSetSchoolYearDataItem = (item: any) => {
    return {
      id: item?.id,
      title: item?.attributes?.title,
      description: item?.attributes?.description,
      startDate: moment(item?.attributes?.start_date).format("MMM DD, YYYY"),
      endDate: moment(item?.attributes?.end_date).format("MMM DD, YYYY"),
      noOfGradingPeriod: item?.attributes?.number_of_grading,
      gradePeriodTitle: item?.attributes?.grade_period_title,
      status: item?.attributes?.status,
      semestersData: item?.attributes?.semesters.length
        ? item.attributes.semesters.map((data: any) =>
            this.handleSetSchoolYearSemesterDataItem(data)
          )
        : [],
    };
  };

  handleSetTitleDropdownList = (responseJson: any, errorResponse: any) => {
    if (responseJson?.grade_period_title || responseJson?.title) {
      const gradePeriodList = responseJson?.grade_period_title?.map(
        (item: string, index: number) => {
          return {
            id: _.uniqueId(item),
            value: item,
            label: item?.toUpperCase(),
          };
        }
      );
      const titlesList = responseJson?.title?.map(
        (item: string, index: number) => {
          return {
            id: _.uniqueId(item),
            value: item,
            label: item?.toUpperCase(),
          };
        }
      );
      this.setState({
        selectGradePeriodTitleItems: gradePeriodList || [],
        selectMenuItems: titlesList || [],
      });
    } else if (responseJson?.errors) {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleSetSchoolYearData = (list: any) => {
    const schoolYearData = list?.map((item: any) =>
      this.handleSetSchoolYearDataItem(item)
    );
    this.setState({ schoolYearTableData: schoolYearData });
  };

  handleAddNewSchoolYear = (requestBody: any) => {
    this.props.handleCloseSchoolYearDialog();
    requestBody.data?.attributes?.semesters_attributes?.forEach((item: any) => {
      delete item.id;
    });
    showCustomLoader();
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostNewSchoolYearData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewSchoolYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateSchoolYear = (requestBody: any) => {
    this.handleCloseEditSchoolYearPopup();
    showCustomLoader();
    const { token, selectedSchoolYearData } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateSchoolYear = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createNewSchoolYear}/${selectedSchoolYearData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSchoolYearDataResponse = (responseJson: any, errorResponse: any) => {
    this.handleGetDropdownList();
    if (responseJson?.data) {
      this.handleSetSchoolYearData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handlePostSchoolYearDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_YEAR_CREATION_SUCCESS);
      this.handleGetSchoolYearList();
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUpdateSchoolYearDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { schoolYearTableData } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedSchoolYearItem = this.handleSetSchoolYearDataItem(
        responseJson.data
      );
      const updatedSchoolYearList = schoolYearTableData.map((item) => {
        if (item.id === updatedSchoolYearItem.id) {
          item = { ...updatedSchoolYearItem };
        }
        return item;
      });
      this.setState({ schoolYearTableData: updatedSchoolYearList });
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_YEAR_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleDeleteSchoolYearResponse = (responseJson: any) => {
    const { schoolYearTableData, selectedSchoolYearData } = this.state;
    if (!responseJson?.errors) {
      const updatedSchoolYearList = schoolYearTableData.filter(
        (item) => item.id !== selectedSchoolYearData.id
      );
      this.setState({
        schoolYearTableData: updatedSchoolYearList,
        selectedSchoolYearData: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_YEAR_DELETE_SUCCESS);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleOpenStatusModal = (value?: any) => {
    const { schoolYearTableData } = this.state;
    const schoolYear = schoolYearTableData.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      { selectedSchoolYearData: schoolYear, selectedStatus: schoolYear.status },
      () => {
        this.setState({ openStatusUpdatePopup: true });
      }
    );
  };

  handleOpenDeleteConfirmModal = (value?: any) => {
    const { schoolYearTableData } = this.state;
    const selectedSchoolYear = schoolYearTableData.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedSchoolYearData: selectedSchoolYear,
        selectedStatus: selectedSchoolYear.status,
      },
      () => {
        this.setState({ openDeleteConfirmPopup: true });
      }
    );
  };

  handleConfirmUpdateStatus = () => {
    this.handleCloseStatusUpdatePopup();
    showCustomLoader();
    const { token, selectedSchoolYearData, selectStatusItems, selectedStatus } =
      this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const selectedStatusValue: any = selectStatusItems.find(
      (item) => item.id === selectedStatus.toLowerCase()
    );
    const requestBody = {
      status: parseInt(selectedStatusValue.value),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateSchoolYearStatus = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSchoolYear}/${selectedSchoolYearData.id}/update_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateStatus = (value: any) => {
    this.setState({ selectedStatus: value });
  };

  handleUpdateStatusResponse = (responseJson: any) => {
    if (responseJson?.message) {
      const { schoolYearTableData, selectedStatus, selectedSchoolYearData } =
        this.state;
      const updatedSchoolYearList = schoolYearTableData.map((item) => {
        if (item.id === selectedSchoolYearData.id) {
          item.status = selectedStatus;
        }
        return item;
      });
      this.setState({ schoolYearTableData: updatedSchoolYearList });
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_YEAR_STATUS_UPDATE_SUCCESS);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleCloseStatusUpdatePopup = () => {
    this.setState({ openStatusUpdatePopup: false });
  };

  handleCloseDeleteConfirmPopup = () => {
    this.setState({ openDeleteConfirmPopup: false });
  };

  handleOpenEditSchoolYearPopup = (value: any) => {
    const { schoolYearTableData } = this.state;
    const selectedSchoolYearData = schoolYearTableData.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedSchoolYearData,
        selectedStatus: selectedSchoolYearData.status,
      },
      () => {
        this.setState({ openEditSchoolYearPopup: true });
      }
    );
  };

  handleCloseEditSchoolYearPopup = () => {
    this.setState({ openEditSchoolYearPopup: false });
    this.props.handleCloseSchoolYearDialog();
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetSchoolYearData:
            {
              this.handleGetSchoolYearDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiPostNewSchoolYearData:
            {
              this.handlePostSchoolYearDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetDropdownData:
            {
              this.handleSetTitleDropdownList(responseJson, errorResponse);
            }
            break;
          case this.apiDeleteSchoolYear:
            {
              this.handleDeleteSchoolYearResponse(responseJson);
            }
            break;
          case this.apiUpdateSchoolYearStatus:
            {
              this.handleUpdateStatusResponse(responseJson);
            }
            break;
          case this.apiUpdateSchoolYear:
            {
              this.handleUpdateSchoolYearDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
