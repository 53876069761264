// Customizable Area Start
import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherProfileController, {
  Props,
} from "./TeacherProfileController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GearIconDropDown from "../../../components/src/GearIconDropDown.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    fieldValue: {
      color: "#090909",
      fontSize: "16px",
      fontWeight: 600,
    },
    inputLabel: {
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    parentHeader: {
      color: "#090909",
      fontSize: "20px",
      fontWeight: 600,
      padding: "25px",
      borderBottomLeftRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    fieldBox: {
      margin: theme.spacing(2.5, 0),
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
    },
    profileHeading: {
      color: "#090909",
      fontSize: "34px",
      fontWeight: 700,
    },
    studentHeader: {
      color: "#090909",
      fontSize: "22px",
      fontWeight: 600,
    },
    headerWrappper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "15px 0 30px 0",
    },
    backIcon: {
      cursor: "pointer",
      fontSize: "34px",
    },
    headLineWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    fieldWrapper: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      padding: "25px",
    },
    boxWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 25px",
    },
    parentHeaderWrapper: {
      backgroundColor: "#F1F1F1",
      height: "1px",
    },
    contentCenter: {
      display: "flex",
      alignItems: "center",
    },
    parentBodyWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 25px",
    },
    rootBox: {
      padding: "40px",
    },
    imageMargin: {
      margin: "0 8px",
    },
    flexBox: {
      display: "flex",
      flexDirection: "column",
    },
    gridBoxWrapper: {
      padding: "0 25px",
    },
    paper: {
      padding: theme.spacing(2),
      background: "#f1f1f1",
      boxShadow: "none",
      borderRadius: "12px",
    },
    gridElement: {
      background: "#f1f1f1",
      padding: "12px",
      borderRadius: "12px",
    },
    fontBold600: {
      fontWeight: 600,
    },
    profileImgBox: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
    teacherProfileImg: {
      width: "50px",
      height: "50px",
      objectFit: "contain",
    },
    profileImg: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      objectFit: "contain",
    },
    emptyProfileImg: {
      width: "inherit",
      height: "inherit",
      color: "#ccc",
    },
    capitalText: {
      textTransform: "capitalize",
    },
    wordwrap: {
      wordBreak: "break-all",
    },
  });
// Customizable Area End

// Customizable Area Start
export class TeacherProfile extends TeacherProfileController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { teacherInfo } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.secondaryBgColor} ${classes.rootBox} ${classes.highlightBgColor} `}
        height={"100%"}
      >
        <Box marginTop={"1%"}>
          <Typography
            className={`${classes.fontText14} ${classes.textCommonColor} `}
            component={"span"}
          >
            {`Dashboard /`}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontText14} ${classes.fontBold600}`}
            component={"span"}
          >
            {` My Profile `}
          </Typography>
        </Box>
        <Box className={classes.headerWrappper}>
          <Box
            data-testid="Teacher-go-back-box"
            className={classes.headLineWrapper}
            onClick={() => this.handleGoBack()}
          >
            <KeyboardBackspaceIcon
              data-testid="Teacher-go-back"
              className={`${classes.backIcon} ${classes.textPrimaryColor}`}
            />
            <Typography
              className={`${classes.profileHeading} ${classes.textPrimaryColor}`}
            >
              My Profile
            </Typography>
          </Box>
          <GearIconDropDown
            data-testid="profile-settings"
            options={this.state.profileSettingOption}
          />
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.profileImgBox}>
              {teacherInfo?.profileImg ? (
                <CustomImageComponentWeb
                  altText="teacher-profile"
                  src={teacherInfo.profileImg}
                  imgStyles={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50%"
                  }}
                  isUserProfileImg
                />
              ) : (
                <AccountCircleIcon className={`${classes.emptyProfileImg}`} />
              )}
            </Box>
            <Typography
              className={`${classes.studentHeader} ${classes.textPrimaryColor}`}
            >
              {`${teacherInfo.firstName} ${teacherInfo.lastName}`}
            </Typography>
          </Box>
          <Grid
            spacing={2}
            className={classes.gridBoxWrapper}
            container
            direction="row"
          >
            <Grid className={classes.wordwrap} item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.textPrimaryColor} ${classes.fieldValue}`}
                >
                  {teacherInfo.email}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                ID Number:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {teacherInfo.uniqId}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.textPrimaryColor} ${classes.fieldValue} ${classes.capitalText}`}
                >
                  {teacherInfo.role}
                </span>
              </Typography>
            </Grid>
            {teacherInfo.customFields.map((item: any) => (
              <Grid key={item?.id} item xs={12} md={6} lg={4}>
                <Typography className={classes.inputLabel}>
                  {item?.label}:{" "}
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fieldValue} `}
                  >
                    {item?.value || "NA"}
                  </Typography>
                </Typography>
              </Grid>
            ))}
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Status:{" "}
                <span
                  className={`${classes.textPrimaryColor} ${classes.fieldValue} ${classes.capitalText}`}
                >
                  {teacherInfo.status}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`}
            >
              Curriculum
            </Typography>
          </Box>
          <Box className={`${classes.parentHeaderWrapper} `} />
          <Box className={`${classes.bgColor} ${classes.parentHeader}`}>
            <Grid container direction="row">
              {teacherInfo?.curriculums?.name && (
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.fontBold600}>
                      {teacherInfo.curriculums.name}
                    </Typography>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box className={`${classes.bgColor} ${classes.fieldBox} `}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`}
            >
              Class
            </Typography>
          </Box>
          <Box className={classes.parentHeaderWrapper} />
          <Box className={`${classes.bgColor} ${classes.parentHeader}`}>
            <Grid container spacing={2}>
              {teacherInfo?.classesList?.map(
                (item: { id?: number; name?: string }) => (
                  <Grid key={item?.id} item xs={12} sm={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.fontBold600}>
                        {item?.name}
                      </Typography>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Box>
        <Box className={`${classes.bgColor} ${classes.fieldBox} `}>
          <Box>
            <Typography
              className={`${classes.parentHeader} ${classes.textPrimaryColor}`}
            >
              Subjects Handled
            </Typography>
          </Box>
          <Box className={classes.parentHeaderWrapper} />
          <Box className={`${classes.bgColor} ${classes.parentHeader}`}>
            <Grid container spacing={2}>
              {teacherInfo?.subjectsHandled?.map(
                (subject: { id?: number; name?: string }) => (
                  <Grid key={subject?.id} item xs={12} sm={12} md={6} lg={3}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.fontBold600}>
                        {subject?.name}
                      </Typography>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherProfile);
// Customizable Area End
