// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { IStepperStepProps } from "./CommonType.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  stepperId: string;
  nextBtnLabel: string;
  finishLabel: string;
  showSaveAsDraft?: boolean;
  prevBtnLabel: string;
  skip?: boolean;
  handleNext: any;
  handleSave?: any;
  handlePrevious?: any;
  handleSaveAsDraft?: any;
  handleCancelBtn?: any;
  isStepWithMultipleScreens?: any;
  hidePreviousBtn?: boolean;
  showCancelBtn?: boolean;
  steps: Array<IStepperStepProps>;
  pause: boolean;
  showPreview?:boolean;
  handleShowPreview?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  activeStep: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomStepperController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      activeStep: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleNext = async() => {
    const { activeStep } = this.state;
    const { steps, pause } = this.props;
    if (pause) {
      this.props.handleNext();
    } else {
      if (activeStep !== steps.length - 1) {
        this.setState({ activeStep: activeStep + 1 });
        await this.props.handleNext();
      } else {
        this.props.handleSave();
        console.log("Last Step");
      }
    }

  };

  handleBack = () => {
    const { isStepWithMultipleScreens } = this.props;
    const { activeStep } = this.state;
    if (isStepWithMultipleScreens) {
      this.props.handlePrevious();
    } else {
      this.setState({ activeStep: activeStep - 1 }, () => {
        this.props.handlePrevious();
      });
    }
  };

  handleStep = (step?: any) => {
    console.log("stepper evt", step)
  }
  // Customizable Area End
}
