// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import DeleteEventDialogController, {
  Props,
} from "./DeleteEventDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import { trashIcon } from "./assets";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    popUpCloseIcon: {
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(4),
        top: theme.spacing(5),
        width: theme.spacing(1.5),
      },
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(2.6),
      right: theme.spacing(4),
    },
    paper: {
      [theme.breakpoints.down("sm")]: {
        width: "460px",
      },
      maxWidth: "100%",
      width: "500px",
    },
    dialogRootWrapper: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    contentHeading: {
      fontSize: 22,
      fontWeight: 600,
      color: "#090909",
      textAlign: "center",
    },
    contentSubHeading: {
      fontSize: 17,
      fontWeight: 400,
      color: "#888",
      textAlign: "center",
    },
  });
// Customizable Area End

// Customizable Area Start
export class DeleteEventDialog extends DeleteEventDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEvent, title } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth
        classes={{ paper: classes.paper }}
        onClose={this.props.onCancel}
        maxWidth="md"
        className={classes.dialogRootWrapper}
      >
        <Box padding={"30px"} className={`${classes.bgColor}`}>
          <DialogTitle className={`${classes.bgColor}`} id="new-class-dialog-title">
            <CloseIcon
              onClick={this.props.onCancel}
              id="An icon to close Dialog"
              className={`${classes.popUpCloseIcon} ${classes.textCommonColor}`}
            />
          </DialogTitle>
          <DialogContent className={`${classes.bgColor}`} style={{ padding: "0px" }}>
            <Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ width: "70px " }}
                  alt="trash-icon"
                  src={trashIcon}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}>
                  {isEvent
                    ? "Sure want to delete event?"
                    : "Sure want to delete school calendar?"}
                </Typography>
                <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                  {isEvent
                    ? "Are you sure want to delete event titled"
                    : "Are you sure want to delete school calendar?"}
                </Typography>
                {isEvent && (
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.fontBold600}`}
                  >
                    {title}?
                  </Typography>
                )}
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <CustomButton
                  buttonId="event-cancel-btn"
                  type={"button"}
                  btnStyle={cancelClassBtn}
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  btnText={"No"}
                  handleButtonClick={this.props.onCancel}
                />
                <CustomButton
                  buttonId="event-add-btn"
                  type={"button"}
                  btnStyle={addClassBtn}
                  buttonVariant={"contained"}
                  isDisabled={false}
                  btnText={"Yes, Delete"}
                  handleButtonClick={this.props.onConfirm}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addClassBtn = {
  borderRadius: "12px",
  width: "208px",
  padding: "15px 36px",
  fontWeight: 600,
  marginTop: "30px",
  fontSize: "16px",
} as const;

const cancelClassBtn = {
  borderRadius: "12px",
  marginRight: "15px",
  width: "208px",
  fontWeight: 600,
  padding: "15px 36px",
  marginTop: "30px",
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(DeleteEventDialog);
// Customizable Area End
