// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { configJSON } from "./CfresumejourneyController";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  selectedClassComponentDDValue?:any
  selectedClassName?:string
  isParentTeacher?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseTitle: string;
  openConfirmDialog: boolean;
  openSuccessDialog: boolean;
  selectedValue: any;
  selectStartDate: any;
  selectEndDate:any;
  selectedGradingComponentDDValue:any;
  gradingComponent: Array<any>;
  selectedLessonDDValue: any;
  weekDay:any[],
  selectShiftLessonsStartDate: any;
  selectShiftLessonsEndDate:any;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CatchMeUpModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCatchMeUpId:string = ''
  apiGetSchoolDayId:string = ''
  apiGetSchoolCalendarId:string = ''
  apiGetShiftLessonsCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      selectedCourseTitle: "",
      openConfirmDialog: false,
      openSuccessDialog: false,
      selectedValue: null,
      selectStartDate: new Date(),
      selectEndDate:new Date(),
      selectShiftLessonsStartDate:new Date(),
      selectShiftLessonsEndDate:new Date(),
      selectedGradingComponentDDValue:1,
      selectedLessonDDValue:'',
      gradingComponent: [
        {
          value: 1,
          label: "1 activity per day",
          id: 1,
        },
        {
          value: 2,
          label: "2 activities per day",
          id: 2,
        },
        {
          id: 3,
          label: "3 activities per day",
          value: 3,
        },
        {
          value: 4,
          label: "4 activities per day",
          id: 4,
        },
      ],
      weekDay:[
        {day:'Monday',value:false,short:'Mon'},
        {day:'Tuesday',value:false,short:'Tues'},
        {day:'Wednesday',value:false,short:'Wed'},
        {day:'Thursday',value:false,short:'Thurs'},
        {day:'Friday',value:false,short:'Fri'},
        {day:'Saturday',value:false,short:'Sat'},
        {day:'Sunday',value:false,short:'Sun'}
        
      ]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiGetCatchMeUpId) {
              this.handleCatchUpGetResponse(responseJson);  
        }
        if(apiRequestCallId === this.apiGetSchoolDayId){
              this.handleSchoolDayResponse(responseJson)
        }
        if(apiRequestCallId === this.apiGetSchoolCalendarId){
          this.handleCreateNewSchoolDayResponse(responseJson)
        }
        if(apiRequestCallId === this.apiGetShiftLessonsCallId){
          this.handleShiftLessonsResponse(responseJson)
        }
      }
    }
  }

  handleCreateNewSchoolDayResponse=(responseJson:any)=>{
    if(responseJson?.message){
      this.props.onCancel()
      toast.success(responseJson.message);
    }else{
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    } 
    hideCustomLoader()
  }

  handleOpenSuccessDialog = () => {
    this.catchMeUpGetApi(this.props.selectedClassComponentDDValue)
  }

  handleCloseSuccessDialog = () => {
    this.setState({ openConfirmDialog: false });
    this.setState({ openSuccessDialog: false });
  }

  handleChange = async(event: any) => {
    this.setState({
      selectedValue: event.target.value
    })
  };

  selectStartDate = (date: any) => {
    this.setState({
      selectStartDate: date
    })
  }
  selectEndDate = (date: any) => {
    this.setState({
      selectEndDate: date
    })
  }

  handleShiftLessonDropdownValues = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValues : any = value ;
    if(fieldValues){
      this.setState((prev) => {
        return {
            ...prev,
            [name] : fieldValues,
        };
     });
    }
  };
  renderShiftLessonComponenttDDValues = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "3 lessons per day";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find((grade : any) => grade?.id === selected);
    return selectedGradingComponents?.label;
  };


catchMeUpGetApi = async (DDid:any) => {
  showCustomLoader();

  let requestUrl =""
  if(this.props.isParentTeacher){
   requestUrl =  `${configJSON.calenderCatchMeEndpoint}?student_id=${DDid}`
  }else{
    requestUrl =  `${configJSON.calenderCatchMeEndpoint}?class_id=${DDid}`
  }

  const token = await getStorageData("token");
  const header = {
    token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetCatchMeUpId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

handleCatchUpGetResponse = (res:any)=>{
  if(res?.message){
    toast.success(res.message)
    this.props.onCancel()
  }else{
    displayApiErrorMsg(res?.errors, this.props.navigation);
  }
  hideCustomLoader()
   
}

schoolDayGetApi = async (DDid:any) => {
  showCustomLoader();

  let requestUrl=""

  if(this.props.isParentTeacher){
    requestUrl = `${configJSON.showCalenderDataEndpoint}?student_id=${DDid}`
  }else{
    requestUrl = `${configJSON.showCalenderDataEndpoint}?class_id=${DDid}`
  }

  const token = await getStorageData("token");
  const header = {
    token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetSchoolDayId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

handleSchoolDayResponse = (res:any) => {
  let updatedWeekDay 
     if (res) {
      const storedDays = res?.day_patterns?.day_patterns  
      updatedWeekDay = this.state.weekDay.map((day:any)=>{
         const checkKey =storedDays?.find((value:any) => value === day.day)
         if (day.day === checkKey) {
          return {...day, value: true}
         }else{
          return day
         }
      })
      let startSemesterDate = new Date()
      let endSemesterDate = new Date()
      if(res?.semester_data?.length >0){
      const semesterDataLength = res?.semester_data?.length-1;
       startSemesterDate = new Date(res?.semester_data[0].start_date);
       endSemesterDate = new Date(res?.semester_data[semesterDataLength].end_date);
      }
      this.setState({weekDay:updatedWeekDay,
        selectShiftLessonsEndDate:endSemesterDate,
        selectShiftLessonsStartDate:startSemesterDate
      })
     }
  hideCustomLoader()
}

handleDayChange = (event:any)=>{
   let updatedWeekDay 
   updatedWeekDay = this.state.weekDay.map((value:any)=>{
    if(value.day === event.target.name) {
      return {...value, value: event.target.checked}
    }else{
      return value
    }
   })
   this.setState({weekDay:updatedWeekDay})
}

schoolCalendarDataApi = async (days:any) => {
  showCustomLoader();
  const formatedDays = JSON.stringify(days)
  const classId = this.props.selectedClassComponentDDValue
  let requestUrl=""
  if(this.props.isParentTeacher){
    requestUrl = `${configJSON.newSchoolDay}?student_id=${classId}&days=${formatedDays}`
   }
  else{
     requestUrl = `${configJSON.newSchoolDay}?class_id=${classId}&days=${formatedDays}`
  }
  const token = await getStorageData("token");
  const header = {
    token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetSchoolCalendarId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   requestUrl
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};


handleSchoolDataSave = () => {
  const addDays = this.state.weekDay.map((days:any) => days.value === true  && days.short)
  const filterAddDays = addDays.filter((value:any)=> value !== false)
  this.schoolCalendarDataApi(filterAddDays)
}
shiftLessonsApi = async (classId:any) => {
  showCustomLoader();
  let requestUrl=""
  if(this.props.isParentTeacher){
    requestUrl =  `${configJSON.shiftLessonsEndpoint}?student_id=${classId}`;
  }else{
   requestUrl =  `${configJSON.shiftLessonsEndpoint}?class_id=${classId}`;
  }
  if (this.state.selectedGradingComponentDDValue) {
    requestUrl = requestUrl + `&lesson_per_day=${this.state.selectedGradingComponentDDValue}`
  }
  const token = await getStorageData("token");
  const header = {
    token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetShiftLessonsCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handleShiftLessonsResponse=(responseJson:{
  message:string;
  errors: Array<{}>;
})=>{

  if(responseJson?.message){
    this.setState({ openSuccessDialog: true });
  }
  else {
    displayApiErrorMsg(responseJson?.errors, this.props.navigation);
  }
  hideCustomLoader()
}
handleShiftLesson=()=>{
  this.shiftLessonsApi(this.props.selectedClassComponentDDValue)
}
  // Customizable Area End
}
// Customizable Area End