// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import {
  displayApiErrorMsg,
  getFieldType,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openNewCurriculumDialog: boolean;
  handleCloseCurriculumDialog: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  curriculumTableHeader: Array<any>;
  getSubjectLoader: boolean;
  selectCurriculumItems: Array<any>;
  customFieldConfigList: Array<IInputConfigProps>;
  customFieldsList: Array<any>;
  selectCurriculumMenuItems: Array<IDropdownMenuItemProps>;
  selectCurriculumMenu: any;
  selectSubjectItems: Array<IDropdownMenuItemProps>;
  selectSubject: any;
  selectGradeItems: Array<IDropdownMenuItemProps>;
  selectGrade: any;
  subjectsListAsPerGrade: Array<IDropdownMenuItemProps>;
  curriculumTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  openEditNewCurriculumPopup: boolean;
  openDeleteCurriculumPopup: boolean;
  selectedCurriculumData: any;
  token: any;
  selectedGradeInAddCurriculumModal: any;
  currentPage: number;
  perPageRecords: number;
  totalNumberOfCurriculumRecords: number;
  curriculumPermission:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminSetupCurriculum extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiFetchCurriculumFilters: string = "";
  apiGetGradesMenuListing: string = "";
  apiGetSubjectMenusListing: string = "";
  apiGetSubjectsAsperGradeMenusListing: string = "";
  apiGetCurriculumListing: string = "";
  apiGetCustomFields: string = "";
  apiPostCurriculum: string = "";
  apiUpdateCurriculum: string = "";
  apiDeleteCurriculum: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
      getSubjectLoader: false,
      selectCurriculumMenu: "",
      curriculumTableHeader: [
        {
          id: "curriculum",
          columnId: 1,
          label: "Curriculum",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "subjects",
          columnId: 2,
          label: "Subjects",
          type: tableColumnTypes.TEXT,
          width: "200px",
          maxWidth: "220px",
        },
        {
          id: "grade",
          columnId: 3,
          label: "Grade",
          type: tableColumnTypes.TEXT,
        },
      ],
      customFieldsList: [],
      customFieldConfigList: [],
      selectCurriculumItems: [],
      selectCurriculumMenuItems: [],
      selectSubjectItems: [],
      selectSubject: "",
      selectGradeItems: [],
      selectGrade: "",
      curriculumTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenEditGradePopup(value),
        },
        {
          label: "Delete",
          action: (value: any) =>
            this.handleOpenCurriculumDeleteConfirmModal(value),
        },
      ],
      openDeleteCurriculumPopup: false,
      openEditNewCurriculumPopup: false,
      token: "",
      selectedCurriculumData: "",
      selectedGradeInAddCurriculumModal: "",
      subjectsListAsPerGrade: [],
      currentPage: 1,
      perPageRecords: 10,
      totalNumberOfCurriculumRecords: 0,
      curriculumPermission:{
        curriculum: {
          read: true,
          write: true
        },
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async handleGetCurriculumRoleAndPermission(){
    const getSchoolYearPermission = await getStorageData("roleandpermission")
    if(getSchoolYearPermission){ 
      try {
        const parseData = JSON.parse(getSchoolYearPermission);
        if(parseData){
          const result = {
            curriculum: parseData?.curriculum?.can,
          };
          this.setState({curriculumPermission:result})
        }
      } catch (error) {
        console.error("Failed to parse curriculum. It might not be valid JSON.", error);
      }
    }
  }
  handleSetUserTokenTenantSubject = async () => {
    const token = await getStorageData("token");
    this.setState({ token });
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderValueCurriculum = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { selectCurriculumMenuItems } = this.state;
    const selectedCurriculum = selectCurriculumMenuItems.find(
      (grade: any) => grade?.value === selected
    );
    return selectedCurriculum?.label;
  };

  renderValueGrade = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { selectGradeItems } = this.state;
    const selectedGrade = selectGradeItems.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGrade?.label;
  };

  renderValueSubject = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { selectSubjectItems } = this.state;
    const selectedSubject = selectSubjectItems.find(
      (grade: any) => grade?.value === selected
    );
    return selectedSubject?.label;
  };

  handleCustomFieldTextChange = (event: any) => {
    const { customFieldConfigList } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = customFieldConfigList.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        customFieldConfigList: updatedInputConfig,
      };
    });
  };

  handleSetDropdownMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.name,
      };
    });
    return menuItems;
  };

  handleSetFilterDropdownMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    return menuItems;
  };

  handleSetGradesDropdownList = (responseJson: any) => {
    if (responseJson) {
      const gradesMenuList = this.handleSetFilterDropdownMenuItem(responseJson);
      this.setState({
        selectGradeItems: gradesMenuList || [],
      });
    }
  };

  handleSetCurriculumDropdownList = (responseJson: any) => {
    if (responseJson) {
      const curriculumList = this.handleSetFilterDropdownMenuItem(responseJson);
      this.setState({
        selectCurriculumMenuItems: curriculumList || [],
      });
    }
  };

  handleSetSubjectsDropdownList = (responseJson: any) => {
    if (responseJson) {
      const subjectList = this.handleSetFilterDropdownMenuItem(responseJson);
      this.setState({
        selectSubjectItems: subjectList || [],
      });
    }
  };

  handleSetSubjectsAsPerGradeDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const subjectListAsPerGrade = this.handleSetDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        subjectsListAsPerGrade: subjectListAsPerGrade || [],
      });
    }
  };

  handleSetCurriculumDataItem = (item: any) => {
    let dataItemObj: any = {
      id: item?.id,
      curriculum: item?.attributes?.name,
      subjects: item?.attributes?.subject?.map((item: any) => {
        return item?.name;
      }),
      subjectIds: item?.attributes?.subject?.map((item: any) => {
        return item?.id?.toString();
      }),
      grade: item?.attributes?.grade?.name || "",
      gradeId: item?.attributes?.grade?.id || "",
      customField: item?.attributes?.custom_fields?.data?.map((item: any) => {
        return {
          id: item?.id,
          value: item?.attributes?.value,
          label: item?.attributes?.label,
        };
      }),
    };
    dataItemObj.subjects = dataItemObj.subjects?.join(",");
    item?.attributes?.custom_fields?.data?.forEach((data: any) => {
      dataItemObj[data?.attributes?.label] = data?.attributes?.value;
    });
    return dataItemObj;
  };

  handleSetCurriculumData = (list: any) => {
    const curriculumData = list?.map((item: any) =>
      this.handleSetCurriculumDataItem(item)
    );
    this.setState({ selectCurriculumItems: curriculumData });
  };

  handleCurriculumFetchFiltersResponse = (responseJson: any) => {
    if (responseJson) {
      this.handleSetCurriculumDropdownList(
        responseJson?.curriculums
      );
      this.handleSetSubjectsDropdownList(
        responseJson?.subjects
      );
       this.handleSetGradesDropdownList(
        responseJson?.grades
      );
    }
  };

  handleGetFilterDropdownList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchCurriculumFilters = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumFetchFilters
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCurriculumsList = async (isFilterApplied?: boolean) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const { selectGrade, selectSubject, selectCurriculumMenu, currentPage, perPageRecords, customFieldConfigList } = this.state;
    let page = currentPage;
    if(isFilterApplied) {
      this.setState({ currentPage: 1 });
      page = 1;
    }
    let queryString = `?per_page=${perPageRecords}&page=${page}`;
    let curriculumFetchEndpoint = configJSON.curriculumsEndpoint;
    if (selectGrade) {
      queryString += `&grade_id=${selectGrade}`;
    }
    if (selectSubject) {
      queryString += `&subject_id=[${selectSubject}]`;
    }
    if (selectCurriculumMenu) {
      queryString += `&curriculum_id=${selectCurriculumMenu}`;
    }
    let index = 1;
    if(customFieldConfigList?.length) {
      customFieldConfigList.forEach((customField) => {
        if(customField?.value) {
          queryString += `&custom_field_${index}_value=${customField.value}&custom_field_${index}_id=${customField?.id}`
          index += 1;
        }
      })
    }
    curriculumFetchEndpoint = `${curriculumFetchEndpoint}${queryString}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      curriculumFetchEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSubjectsList = async (gradeId?: any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let queryString = "";
    let subjectFetchEndpoint = configJSON.subjectsEndpoint;
    if (gradeId) {
      this.setState({ getSubjectLoader: true });
      queryString = `?grade_id=${gradeId}`;
      subjectFetchEndpoint = `${subjectFetchEndpoint}${queryString}`;
      this.apiGetSubjectsAsperGradeMenusListing = requestMessage.messageId;
    } else {
      this.apiGetSubjectMenusListing = requestMessage.messageId;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      subjectFetchEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCustomFieldsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomFields = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customFieldEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCurriculumDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetCurriculumData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetCurriculumCustomFieldDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data?.length) {
        this.setState({ customFieldsList: responseJson.data });
        const curriculumCustomFields: Array<IInputConfigProps> = responseJson.data.map(
          (item: any, index: number) => {
            const obj = {
              id: item.id,
              fieldName: `curriculum-customField-${index + 1}`,
              value: "",
              label: item?.attributes?.label,
              name: `curriculum-customField-${index + 1}`,
              fieldType: getFieldType(item?.attributes?.custom_field_type),
              placeHolderText: `Please enter ${item?.attributes?.label}`,
              isDisabled: false,
            };
            return obj;
          }
        );
        const curriculumCustomFieldHeaders = curriculumCustomFields.map((item, index: number) => {
          return {
            columnId: `${item?.id}-${item?.label}`,
            id: item?.label,
            label: item?.label,
            type: tableColumnTypes.TEXT,
          };
        });
        this.setState({
          customFieldConfigList: curriculumCustomFields,
          curriculumTableHeader: [
            ...this.state.curriculumTableHeader,
            ...curriculumCustomFieldHeaders,
          ],
        });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.handleGetCurriculumsList();
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetSubjectsAsPerGradeDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetSubjectsAsPerGradeDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ getSubjectLoader: false });
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleAddNewCurriculum = (requestBody: any) => {
    this.props.handleCloseCurriculumDialog();
    showCustomLoader();
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCurriculum = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateCurriculum = (requestBody: any) => {
    this.handleCloseEditCurriculumPopup();
    showCustomLoader();
    const { token, selectedCurriculumData } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateCurriculum = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.curriculumsEndpoint}/${selectedCurriculumData?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostCurriculumDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.CURRICULUM_CREATION_SUCCESS);
      this.handleGetCurriculumsList();
      this.setState({
        subjectsListAsPerGrade: [],
        selectedGradeInAddCurriculumModal: "",
      });
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUpdateCurriculumResponse = (responseJson: any, errorResponse: any) => {
    const { selectCurriculumItems } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedCurriculumItem = this.handleSetCurriculumDataItem(
        responseJson.data
      );
      const updatedCurriculumsList = selectCurriculumItems.map((item) => {
        if (item.id === updatedCurriculumItem.id) {
          item = { ...updatedCurriculumItem };
        }
        return item;
      });
      this.setState({
        selectCurriculumItems: updatedCurriculumsList,
        subjectsListAsPerGrade: [],
        selectedGradeInAddCurriculumModal: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.CURRICULUM_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleOpenEditGradePopup = (value: any) => {
    const { selectCurriculumItems } = this.state;
    const selectedCurriculumData = selectCurriculumItems.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedCurriculumData,
      },
      () => {
        this.setState({ openEditNewCurriculumPopup: true });
      }
    );
  };

  handleCloseEditCurriculumPopup = () => {
    this.setState({
      openEditNewCurriculumPopup: false,
      subjectsListAsPerGrade: [],
      selectedGradeInAddCurriculumModal: "",
    });
    this.props.handleCloseCurriculumDialog();
  };

  handleDeleteCurriculum = async () => {
    this.handleCloseDeleteCurriculumConfirmModal();
    showCustomLoader();
    const { token, selectedCurriculumData } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteCurriculum = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.curriculumsEndpoint}/${selectedCurriculumData?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteCurriculumResponse = (responseJson: any) => {
    const { selectCurriculumItems, selectedCurriculumData } = this.state;
    if (responseJson) {
      const updatedCurriculumList = selectCurriculumItems.filter(
        (item) => item.id !== selectedCurriculumData.id
      );
      this.setState({
        selectCurriculumItems: updatedCurriculumList,
        selectedCurriculumData: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.CURRICULUM_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.CURRICULUM_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleOpenCurriculumDeleteConfirmModal = (value?: any) => {
    const { selectCurriculumItems } = this.state;
    const selectedCurriculumData = selectCurriculumItems.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedCurriculumData: selectedCurriculumData,
      },
      () => {
        this.setState({ openDeleteCurriculumPopup: true });
      }
    );
  };

  handleCloseDeleteCurriculumConfirmModal = () => {
    this.setState({ openDeleteCurriculumPopup: false });
  };

  handleGetSubjectsForGradeSelected = (gradeId: any) => {
    this.setState({ selectedGradeInAddCurriculumModal: gradeId });
    this.handleGetSubjectsList(gradeId);
  };

  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page }, () => {
      this.handleGetCurriculumsList();
    });
  };
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetCurriculumRoleAndPermission()
    this.handleGetFilterDropdownList();
    this.handleGetCustomFieldsList();
    this.handleSetUserTokenTenantSubject();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiFetchCurriculumFilters:
            {
              this.handleCurriculumFetchFiltersResponse(responseJson);
            }
            break;
          case this.apiGetSubjectsAsperGradeMenusListing:
            {
              this.handleGetSubjectsAsPerGradeDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetCurriculumListing:
            {
              if(responseJson?.meta?.pagination?.total_count){
                this.setState({ totalNumberOfCurriculumRecords: responseJson.meta.pagination.total_count })
              }
              this.handleGetCurriculumDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetCustomFields:
            {
              this.handleGetCurriculumCustomFieldDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiPostCurriculum:
            {
              this.handlePostCurriculumDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiDeleteCurriculum:
            {
              this.handleDeleteCurriculumResponse(responseJson);
            }
            break;
          case this.apiUpdateCurriculum:
            {
              this.handleUpdateCurriculumResponse(responseJson, responseJson);
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
