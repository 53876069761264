// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  studentProfile,
  parent1,
  parent2,
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
} from "./assets";
import { configJSON } from "./UserManagementTabComponentController.web";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import moment from "moment";

// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface Parent {
  id: number;
  parent_id: string;
  student_id: number;
  created_at: string;
  updated_at: string;
}

export interface IUserDetailProps {
  id: any;
  emailId: string;
  rollNumber: any;
  curriculum: any;
  dob: any;
  classDetail: string;
  schoolYearTitle: string;
  role: string;
  expiringOn: string;
  status: string;
  profilePic?: any;
  customFields:any[],
  userParentDetails: Array<IParentDetailProps>;
}

export interface IParentDetailProps {
  id: any;
  emailId: string;
  fullName: string;
  relationship: string;
  contactNumber: string;
  profilePic?: any;
  role: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userFullName: string;
  userDetails: IUserDetailProps;
  coursesList: Array<any>;
  performanceProgressDetail: Array<any>; // Customizable Area End
  userId: string;
  token: string,
  setStudData: Array<IUserDetailProps>,
  fullName: string,
  firstParentId: string,
  secondParentId: string;
  progressDetails:any
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewStudentProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getUserDetailId: string = "";
  getProgressDetailsId: string = "";
  getParentTwoDetailsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      userFullName: "",
      progressDetails:[],
      userDetails: {
        id: "",
        emailId: "",
        rollNumber: "",
        curriculum: "",
        dob: "",
        classDetail: "",
        schoolYearTitle: "",
        role: "",
        expiringOn: "",
        status: "",
        customFields:[],
        userParentDetails: [],
      },
     
      coursesList: [],
      performanceProgressDetail: [],
      token: "",
      userId: "",
      setStudData: [],
      fullName: "",
      firstParentId: "",
      secondParentId: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetUsersCreateToken();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetUsersCreateToken = async () => {
    const studentId = this.props.navigation?.getParam("studentId");
    const token = await getStorageData("token");
    if (studentId && token) {
      this.setState({ token: token });
      this.handleSetStudentDetails(token);
      this.getStudentProgressDetails(studentId)
    }
  };

  handleSetStudentDetails = (token: string) => {
    this.setState({ loading: true });
    showCustomLoader()
    const studentId = this.props.navigation?.getParam("studentId");
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailId = getUserStatusMsg.messageId;


    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + studentId
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };

  getStudentProgressDetails = (studentId: string) => {
    this.setState({ loading: true });
    showCustomLoader()
    let requestUrl = `${configJSON.userProgressApiEndpoint}?student_id=${studentId}`
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: this.state.token
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProgressDetailsId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );
    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId == null) {
      return;
    }

    switch (apiRequestCallId) {
      case this.getUserDetailId:
        this.studentParentResponseData(responseJson);
        break;
      case this.getProgressDetailsId:
        this.studentProgressDetailsResponse(responseJson);
        break;
    }
  }

  studentParentResponseData(responseJson?: {
    data: {
      id: string, attributes: {
        user_custom_fields?: {
          data: {
            attributes: {
              field_value: string;
            }
          }[]
        }, parents?: Parent[], expiring_on: string, profile_url: string, status: string, school_year: { title: string }, role: { name: string }, DOB: string, id_number: string, curriculum: { name: string }, first_name: string, last_name: string, email: string, grade: { name: string }
      }
    };
    errors:any
  }) {

    if(responseJson?.data){
      let studData:any = this.setDataFunc(responseJson);
      this.setState({
        userDetails: studData
      })
    }
    else{
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
  }

  getPerformanceData = (
    performanceData: Array<{
      course: {
        title: string;
        subject: {
          color: string;
          name: string;
          id: number;
          icon: string;
        };
      };
      course_score: number;
      percentage: number;
    }>
  ) => {
    const performanceDetails = performanceData?.map((item) => {
      return {
        id: item?.course?.subject?.id,
        subjectName: item?.course?.title,
        subjectMarks: item?.course_score || 0,
        subjectBgColor: item?.course?.subject?.color,
        progressPercentage: item?.percentage || 0,
      };
    });
    return performanceDetails;
  };
   transformCourseWiseProgress(courseWiseProgress:any) {
    return courseWiseProgress.map((course: { id: any; title: string; subject: { icon:string;color: any; }; completed_percentage: number; }) => ({
      id: course.id,
      subjectName: course.title.trim(),
      subjectBgColor: course.subject.color,
      subjectMarks: Math.round((course.completed_percentage * 100) / 100),
      icon:course.subject.icon,
      progressPercentage: course.completed_percentage
    }));
  }
  studentProgressDetailsResponse=(responseJson:any)=>{
    if(responseJson.data){
      const performanceProgressDetail= this.getPerformanceData(responseJson.data.attributes.performance_data)
      const progressDetails = this.transformCourseWiseProgress(responseJson.data.attributes.progress.course_wise_progress)
      this.setState({performanceProgressDetail,progressDetails})
    }else{
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }

    hideCustomLoader();
  }
 
  getParentsList = (
    parentsArr: Array<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      role: {
        name: string;
        role_title: string;
      };
      relationship: string;
      contact_number: string;
      profile_url: string;
    }>
  ) => {
    const parentsList = parentsArr?.map((item) => {
      return {
        id: item?.id,
        fullName: `${item?.first_name || ""} ${item?.last_name || ""}`,
        emailId: item?.email || "NA",
        role: item?.role?.name || "NA",
        relationship: item?.relationship || "NA",
        contactNumber: item?.contact_number || "NA",
        profilePic: item?.profile_url || "",
      };
    });
    return parentsList;
  };
  setDataFunc(responseJson?: {
    data: any
  }) {
    const fullName = responseJson?.data.attributes.first_name + " " + responseJson?.data.attributes.last_name;

    this.setState({
      userFullName: fullName
    })
    let dateOfBirth = moment(responseJson?.data.attributes.DOB).format('DD/MM/YYYY') || 'NA'
    const customFields: any = responseJson?.data.attributes?.user_custom_fields?.data?.map((item: any) => {
      return {
        id: item?.attributes?.id,
        label: item?.attributes?.label,
        value: item?.attributes?.field_value,
      }
    })
    console.log("hello world",responseJson?.data?.attributes?.parents_details)
    const parentDetails = this.getParentsList(responseJson?.data?.attributes?.parents_details)
    console.log("hello world",parentDetails)
    return {
      id: responseJson?.data.id,
      emailId: responseJson?.data.attributes.email || "NA",
      rollNumber: responseJson?.data.attributes.id_number || 'NA',
      curriculum: responseJson?.data.attributes.curriculum?.name || 'NA',
      dob: dateOfBirth,
      classDetail: responseJson?.data.attributes?.student_class[0]?.name || 'NA',
      role: responseJson?.data.attributes.role?.name || 'NA',
      schoolYearTitle: responseJson?.data.attributes.school_year?.title || 'NA',
      status: responseJson?.data.attributes.status || 'NA',
      expiringOn: responseJson?.data.attributes.expiring_on || 'NA',
      profilePic: responseJson?.data.attributes.profile_url || 'NA',
      customFields:customFields,
      userParentDetails:parentDetails
    }
  }

  setParentData(responseJson?: { data: { id: string, attributes: { role?: { name: string }, first_name: string, 
    last_name: string, contact_number: string, relationship: string, profile_url: string, email: string } } }) {
    return {
      id: responseJson?.data.id,
      fullName: responseJson?.data.attributes.first_name + " " + responseJson?.data.attributes.last_name,
      contactNumber: responseJson?.data.attributes.contact_number || 'NA',
      relationship: responseJson?.data.attributes.relationship || 'NA',
      profilePic: responseJson?.data.attributes.profile_url || 'NA',
      emailId: responseJson?.data.attributes.email || "NA",
      role: responseJson?.data.attributes.role?.name || 'NA',
    }
  }

  handleGetGraphColor = () => {
    const isDarkMode =
      localStorage.getItem("dark_mode") || JSON.stringify(false);
    if (JSON.parse(isDarkMode)) return "#ffffff";
    return "#000";
  };

  getProgressPerformanceGraphLabels = () => {
    const { performanceProgressDetail } = this.state;
    const labelsList: Array<string> = performanceProgressDetail?.map(
      (data: any) => data.subjectName
    );
    return labelsList || [];
  };

  getProgressPerformanceGraphData = () => {
    const { performanceProgressDetail } = this.state;
    const graphDataList: Array<number> = performanceProgressDetail?.map(
      (data: any) => data.progressPercentage
    );
    return graphDataList || [];
  };

  getProgressPerformanceGraphBarBgColor = () => {
    const { performanceProgressDetail } = this.state;
    const graphBarBgColor: Array<string> = performanceProgressDetail?.map(
      (data: any) => data.subjectBgColor
    );
    return graphBarBgColor || [];
  };

  // Customizable Area End
}
// Customizable Area End
