import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  progressRange1Mock,
  progressRange2Mock,
  progressRange3Mock,
} from "../assets/themeBlockMock.web";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import { ThemeAttributes } from "./AppearanceManagementMainController.web";
const ntc = require("ntcjs");
export interface IProgressRangeProps {
  title: string;
  inputConfigs: Array<IInputConfigProps>;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  handleSubmitAppearance:(requestBody:any)=>void
  themeData:ThemeAttributes;
  rolePermissions?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  rangeList: Array<IProgressRangeProps>;
  error:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProgressRangeColorTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      rangeList: [
        {
          title: "Range 1",
          inputConfigs: progressRange1Mock,
        },
        {
          title: "Range 2",
          inputConfigs: progressRange2Mock,
        },
        {
          title: "Range 3",
          inputConfigs: progressRange3Mock,
        },
      ],
      error:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if(this.props?.themeData?.progress_range_colour){
      this.handleSetGetData()
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: { rangeList: IProgressRangeProps[]; }) {
    if (prevState.rangeList !== this.state.rangeList) {
      this.validateRanges(this.state.rangeList);
    }
    if (this.props?.themeData?.progress_range_colour && prevProps.themeData !== this.props.themeData) {
      this.handleSetGetData(); 
    }
  }
  handleSetGetData = () =>{
    const { progress_range_colour } = this.props.themeData;

    let newRangeList = [...this.state.rangeList];

    newRangeList = newRangeList.map((range, index) => {
      if (progress_range_colour[index]) {
        range.inputConfigs = range.inputConfigs.map((config) => {
          if (config.name.includes("From")) {
            config.value = (progress_range_colour[index].from === 0 ? "0":progress_range_colour[index].from) || ""
          } else if (config.name.includes("To")) {
            config.value = progress_range_colour[index].to || "";
          } else if (config.name.includes("Color")) {
            const n_match = ntc.name(progress_range_colour[index].colour);
            config.value = progress_range_colour[index].colour || "";
            config.fieldName=n_match[1]
          }
          return config;
        });
      }
      return range;
    });
    this.setState({ rangeList: newRangeList });
  }
  handleSaveDetails = () => {
  const { rangeList } = this.state;

  const progressRangeColour = rangeList
    .filter((rangeItem) => 
      !rangeItem.inputConfigs.some((config) => config.isDisabled) 
    )
    .map((rangeItem) => {
      const from = parseFloat(this.getFieldValue(rangeItem.inputConfigs, "From"));
      const to = parseFloat(this.getFieldValue(rangeItem.inputConfigs, "To"));
      const colour = this.getFieldValue(rangeItem.inputConfigs, "Color");

      return {
        from,
        to,
        colour
      };
    });
    const hasMissingColour = progressRangeColour.some((range) => !range.colour);

    if (hasMissingColour) {
      this.setState({
        error: "All ranges must have a color selected.",
      });
      return;
    }
 
    const sortedProgressRangeColour = [...progressRangeColour].sort((a, b) => a.from - b.from);
    const sortedRanges = sortedProgressRangeColour;

  let hasOverlap = false;
    for (let i = 0; i < sortedRanges.length - 1; i++) {
      if (sortedRanges[i].to >= sortedRanges[i + 1].from) {
        hasOverlap = true;
        break;
      }
    }

    if (hasOverlap) {
      this.setState({
        error: "The ranges should not overlap.",
      });
      return;
    }
  let isFullRangeCovered = true;
  let currentEnd = 0;

  for (const range of sortedRanges) {
    if (range.from > currentEnd + 1) {
      isFullRangeCovered = false;
      break;
    }
    currentEnd = Math.max(currentEnd, range.to);
  }

  isFullRangeCovered = isFullRangeCovered && currentEnd >= 100;

  if (!isFullRangeCovered) {
    this.setState({ error: "The range must cover the entire span from 0 to 100 without gaps." });
    return;
  }

  const dataToSend = {
    type: "Progress",
    theme: {
      progress_range_colour: progressRangeColour,
    },
  };
  this.props.handleSubmitAppearance(dataToSend)
  this.setState({ loading: true });
};
validateRanges = (rangeList: IProgressRangeProps[]) => {
  const sortedRanges = this.getSortedRanges(rangeList);
  
  if (this.hasFullRange(sortedRanges)) return true;
  if (this.hasOverlappingRanges(sortedRanges)) return false;
  if (this.hasOutOfBoundsRanges(sortedRanges)) return false;
  if (this.hasGapsInRanges(sortedRanges)) return false;
  if (this.hasInvalidOrder(sortedRanges)) return false;

  this.clearError();
  return true;
};

getSortedRanges = (rangeList: IProgressRangeProps[]) => {
  return [...rangeList].sort((a, b) => {
    const fromA = parseFloat(this.getFieldValue(a.inputConfigs, "From"));
    const fromB = parseFloat(this.getFieldValue(b.inputConfigs, "From"));
    return fromA - fromB;
  });
};

hasFullRange = (sortedRanges: IProgressRangeProps[]) => {
  const fullRange = sortedRanges.some((range) => {
    const from = parseFloat(this.getFieldValue(range.inputConfigs, "From"));
    const to = parseFloat(this.getFieldValue(range.inputConfigs, "To"));
    return from === 0 && to === 100;
  });

  if (fullRange) {
    this.clearError();
    return true;
  }
  return false;
};

hasOverlappingRanges = (sortedRanges: IProgressRangeProps[]) => {
  for (let i = 0; i < sortedRanges.length - 1; i++) {
    const toCurrent = parseFloat(this.getFieldValue(sortedRanges[i].inputConfigs, "To"));
    const fromNext = parseFloat(this.getFieldValue(sortedRanges[i + 1].inputConfigs, "From"));

    if (toCurrent >= fromNext) {
      this.setError("Ranges overlap. Please adjust the values.");
      return true;
    }
  }
  return false;
};

hasOutOfBoundsRanges = (sortedRanges: IProgressRangeProps[]) => {
  const outOfBounds = sortedRanges.some((range) => {
    const from = parseFloat(this.getFieldValue(range.inputConfigs, "From"));
    const to = parseFloat(this.getFieldValue(range.inputConfigs, "To"));
    return from < 0 || to > 100;
  });

  if (outOfBounds) {
    this.setError("Ranges must be within 0 to 100.");
    return true;
  }
  return false;
};

hasGapsInRanges = (sortedRanges: IProgressRangeProps[]) => {
  const gaps = sortedRanges.some((range, index) => {
    const from = parseFloat(this.getFieldValue(range.inputConfigs, "From"));
    const to = parseFloat(this.getFieldValue(range.inputConfigs, "To"));
    if (index === 0 && from > 0) return true;
    if (index > 0) {
      const toPrevious = parseFloat(this.getFieldValue(sortedRanges[index - 1].inputConfigs, "To"));
      return toPrevious + 1 < from;
    }
    if (index === sortedRanges.length - 1 && to < 100) return true;
    return false;
  });

  if (gaps) {
    this.setError("Ranges must cover the entire span from 0 to 100 without gaps.");
    return true;
  }
  return false;
};

hasInvalidOrder = (sortedRanges: IProgressRangeProps[]) => {
  const invalidOrder = sortedRanges.some((range) => {
    const from = parseFloat(this.getFieldValue(range.inputConfigs, "From"));
    const to = parseFloat(this.getFieldValue(range.inputConfigs, "To"));
    return from >= to;
  });

  if (invalidOrder) {
    this.setError('The "To" value must be greater than the "From" value.');
    return true;
  }
  return false;
};

clearError = () => {
  this.setState({ error: "" });
};

setError = (message: string) => {
  this.setState({ error: message });
};

getFieldValue = (inputConfigs: any[], label: string) => {
  const field = inputConfigs.find((item: { label: string | any[]; }) => item.label.includes(label));
  return field ? field.value : undefined;
};
handleTextChange = (event: { target: { name: any; value: any } }, index: number) => {
  const { rangeList } = this.state;
  const { name, value } = event.target;

  let newRangeList = [...rangeList];
  newRangeList[index].inputConfigs = this.updateInputConfigs(newRangeList[index].inputConfigs, name, value);

  const ranges = this.calculateRanges(newRangeList);

  const isFullRangeCovered = this.checkFullRangeCoverage(ranges);

  newRangeList = this.updateRangeList(newRangeList, index, isFullRangeCovered);

  this.setState({
    rangeList: newRangeList,
  });
};

updateInputConfigs = (inputConfigs: any[], name: string, value: string) => {
  return inputConfigs.map((item) => {
    if (item.name === name) {
      item.value = value;
    }
    return item;
  });
};

calculateRanges = (rangeList: any[]) => {
  return rangeList.map((range) => ({
    from: parseFloat(this.getFieldValue(range.inputConfigs, "From")) || 0,
    to: parseFloat(this.getFieldValue(range.inputConfigs, "To")) || 0,
  }));
};

checkFullRangeCoverage = (ranges: any[]) => {
  let sortedRanges = [...ranges];
  sortedRanges.sort((a, b) => a.from - b.from);

  let isFullRangeCovered = true;
  let currentEnd = 0;

  for (const range of sortedRanges) {
    if (range.from > currentEnd + 1) {
      isFullRangeCovered = false;
      break;
    }
    currentEnd = Math.max(currentEnd, range.to);
    if (currentEnd >= 100) {
      break;
    }
  }

  return isFullRangeCovered && currentEnd >= 100;
};

 updateRangeList = (rangeList: any[], index: number, isFullRangeCovered: boolean) => {
  return rangeList.map((range, i) => {
    range.inputConfigs = range.inputConfigs.map((item: { label: string | string[]; isDisabled: boolean; value: any; }) => {
      if (item.label.includes("Color")) {
        const fromValue = this.getFieldValue(range.inputConfigs, "From");
        const toValue = this.getFieldValue(range.inputConfigs, "To");
        item.isDisabled =
          (!fromValue && !toValue) ||
          (isFullRangeCovered && !fromValue && !toValue);
      } else if (!item.value && i !== index) {
        item.isDisabled = isFullRangeCovered;
      }
      return item;
    });
    return range;
  });
};

handleKeyDown = (event: { key: string; preventDefault: () => void; }) => {
  if (event.key === "." || event.key === "e") {
    event.preventDefault();
  }
};
  handleSetRangeColor = (color: string, name: string, index: number) => {
    const { rangeList } = this.state;
  
    let newRangeList = [...rangeList];
    const isColorUsed = newRangeList.some((range, i) => 
      i !== index && range.inputConfigs.some(item => item.value === color)
    );
    if (isColorUsed) {
      alert('This color is already used in another range. Please select a different color.');
      return;
    }
    const updatedRangeInput = newRangeList[index].inputConfigs.map((item) => {
      if (item.name === name) {
        const n_match = ntc.name(color);
        return { ...item, value: color,fieldName:n_match[1] };
      }
      return item;
    });
    newRangeList[index].inputConfigs = [...updatedRangeInput];
    this.setState({ rangeList: newRangeList });
  };
  // Customizable Area End
}
