// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
  TextareaAutosize,  
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TeacherAddNewCourseModalController, {
  Props,
} from "./TeacherAddNewCourseModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import Radio from "@material-ui/core/Radio";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import TeacherBrowseCourseCatModal from "./TeacherBrowseCourseCatModal.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
import CustomFileUploadTextFieldWeb from "../../../components/src/CustomFileUploadTextField.web";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addCourseBox: {
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "100%",
        minHeight: "500px",
        overflowY: "hidden",
        overflowX: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
      },
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    customInput: {
      height: "50px",
      width: "100%",
      borderRadius: "12px",
      padding: "17px 15px",
      border: "1px solid #888",
      marginTop: "10px",
      fontSize: "14px",
      fontWeight: 400,
      "&::after": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px",
      },
      "&:hover:not(.Mui-)::before": {
        borderRadius: "12px",
        borderBottom: "1px solid #888",
      },
      "&.MuiInputBase-root.Mui-": {
        background: "#F3F3F3",
      },
    },
    radioBtnLable: {
      color: "#888",
      fontWeight: 400,
      fontSize: "18px",
      marginRight: "30px",
      lineHeight: "20px",
    },
    radioCheckedLableColor: {
      color: "#FFD92E",
      fontWeight: 500,
    },
    radio: {
      width: "24px",
      height: "24px",
      "&$checked": {
        color: "#FFD92E",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "30px !important",
      },
    },
    checked: {},
    requiredNotation: {
      color: "red",
    },
    textArea: {
      marginTop: "10px",
      height: "143px",
      width: "402px",
      borderRadius: "9px",
      padding: "10px",
      border: "1px solid #888888",
      resize: "none",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    courseTypeBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
    browseCatalogBtn: {
      width: "400px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        marginTop: "10px",
      },
    },
    headerGrid: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    customLoader: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
      zIndex: 100,
      display: "block",
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
    colorPicker: {
      right: "5%",
      top: "50%",
    }
  });
// Customizable Area End

// Customizable Area Start
export class TeacherAddNewCourseModal extends TeacherAddNewCourseModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit } = this.props;
    const {      
      selectedCourseType,
      selectedCurriculamDDValue,
      selectedSubjectDDValue,
      selectedGradeDDValue,
      selectedStatusDDValue,
      selectedSchoolYearDDValue,
      selectedTermDDValue,
      selectedIcon,
      selectedColor,
      selectedColorName,
      schoolYearDropdownList,
      termDataList,
      curriculumDropdownList,
      subjectsDropdownList,
      gradesDropdownList,
      courseTitleValue,
      topicCountValue,
      activityCountValue,
      noOfLessonsADayValue,
      decriptionValue,
      statusDropdownList,
      courseTitleError,
      courseTitleErrorMsg,
      loading,
      statusError,
      statusErrorMsg,
      lessonPerDayError,
      lessonPerDayErrorMsg,
      classesCustmFieldConfigList,
    } = this.state;
    
    return (
      <Box>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.addCourseBox}`}
          aria-labelledby="add-new-course-modal-title"
          open={open}
          scroll={"body"}
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box
            padding={"30px"}
            className={`${classes.bgColor}`}
            data-test-id="modalBox"
          >
            <Grid direction="row" container>
              <Grid
                xs={12}
                className={`${classes.headerGrid}`}
                item
                data-test-id="modalGrid"
              >
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  data-test-id="addNewCourse"
                >
                  {isEdit ? "Edit Course" : "Add New Course"}
                </Typography>
                <Button
                  onClick={this.handleClosePopup}
                  className={classes.textPrimaryColor}
                  id="add-new-course-close-modal-icon-btn"
                  style={{ float: "right" }}
                >
                  <CloseIcon
                    className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                    id="add-new-course-close-modal-icon"
                  />
                </Button>
              </Grid>
            </Grid>
            <DialogContent
              style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}
              data-test-id="content"
            >
              <Grid container spacing={2} direction="row" data-test-id="row">
                <Grid xs={12} item>
                  <Box>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="courseType"
                    >
                      Course Type
                    </Typography>
                    <Box
                      marginBottom={"10px"}
                      className={`${classes.courseTypeBox}`}
                      data-test-id="addNewCourses"
                    >
                      <Box>
                        <Radio
                          className={`${classes.radio} ${classes.checked}`}
                          checked={selectedCourseType === "New"}
                          onChange={this.handleCourseTypesChange}
                          value="New"
                          name="new-course-radio-button"
                          inputProps={{ "aria-label": "A" }}
                          data-test-id="radioBtn"
                        />
                        <Typography
                          component={"span"}
                          className={
                            selectedCourseType === "New"
                              ? `${classes.radioBtnLable} ${classes.radioCheckedLableColor}`
                              : `${classes.radioBtnLable}`
                          }
                          data-test-id="newName"
                        >
                          {" "}
                          New{" "}
                        </Typography>
                        <Radio
                          className={`${classes.radio} ${classes.checked}`}
                          checked={selectedCourseType === "Assign from Catalog"}
                          onChange={this.handleCourseTypesChange}
                          value="Assign from Catalog"
                          name="assign-from-catalog-radio-button"
                          inputProps={{ "aria-label": "B" }}
                          data-test-id="RadioB"
                        />
                        <Typography
                          component={"span"}
                          className={
                            selectedCourseType === "Assign from Catalog"
                              ? `${classes.radioBtnLable} ${classes.radioCheckedLableColor}`
                              : `${classes.radioBtnLable}`
                          }
                          data-test-id="assignedCat"
                        >
                          {" "}
                          Assign from Catalog{" "}
                        </Typography>
                      </Box>
                      {selectedCourseType === "Assign from Catalog" ? (
                        <Box>
                          <Box className={`${classes.browseCatalogBtn}`}>
                            <CustomButton
                              btnText="Browse Course Catalog"
                              btnStyle={browseCourseCatalogButtons}
                              buttonId={`Browse-Courses-Catalog`}
                              buttonVariant={"outlined"}
                              type={"button"}
                              handleButtonClick={
                                this.handleOpenBrowseCourseCatalog
                              }
                              isDisabled={false}
                            />
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid">
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Title"
                    >
                      Course Title
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Course Title"
                      fieldName="courseTitleValue"
                      fieldId="course-title"
                      fieldValue={courseTitleValue}
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={courseTitleError}
                      helperText={courseTitleErrorMsg}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} item data-test-id="grade">
                  <Box>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="SubjectGrade"
                    >
                      Grade
                    </Typography>
                    <Box marginBottom={"10px"}>
                      <CustomSelectDropdown
                        fieldId="courseGradeDropdown"
                        fieldName="selectedGradeDDValue"
                        fieldValue={selectedGradeDDValue}
                        handleChange={this.handleDropdownValues}
                        fieldError={false}
                        fieldStyles={dropdownStyles}
                        renderValue={this.renderGradeDDValues}
                        fieldMenuItems={gradesDropdownList}
                        isDisabled={false}
                        isLoading={isEdit && gradesDropdownList.length === 0}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} item data-test-id="Subject">
                  <Box>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Subjects"
                    >
                      Subject
                    </Typography>
                    <Box marginBottom={"10px"}>
                      <CustomSelectDropdown
                        fieldId="courseSubjectDropdown"
                        fieldName="selectedSubjectDDValue"
                        fieldValue={selectedSubjectDDValue}
                        handleChange={this.handleDropdownValues}
                        fieldError={false}
                        fieldStyles={dropdownStyles}
                        renderValue={this.renderSubjectDDValues}
                        isDisabled={subjectsDropdownList.length === 0}
                        isLoading={isEdit && subjectsDropdownList.length === 0}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                        fieldMenuItems={subjectsDropdownList}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} item>
                  <Box>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Curriculum"
                    >
                      Curriculum
                    </Typography>
                    <Box marginBottom={"10px"}>
                      <CustomSelectDropdown
                        fieldId="curriculamDropdowns"
                        handleChange={this.handleDropdownValues}
                        fieldName="selectedCurriculamDDValue"
                        fieldValue={selectedCurriculamDDValue}
                        fieldError={false}
                        fieldMenuItems={curriculumDropdownList}
                        renderValue={this.renderCurriculamDDValues}
                        fieldStyles={dropdownStyles}
                        isDisabled={curriculumDropdownList.length === 0}
                        isLoading={isEdit && curriculumDropdownList.length === 0}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item data-test-id="statuss">
                  <Box>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="SubjectStatus"
                    >
                      Status
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                        data-test-id="noatation"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Box marginBottom={"10px"}>
                      <CustomSelectDropdown
                        fieldId="courseStatusDropdowns"
                        handleChange={this.handleDropdownValues}
                        fieldName="selectedStatusDDValue"
                        fieldError={statusError}
                        fieldValue={selectedStatusDDValue}
                        renderValue={this.renderStatusDDValues}
                        fieldMenuItems={statusDropdownList}
                        helperText={statusErrorMsg}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                        fieldStyles={dropdownStyles}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <Box
                    marginBottom={"10px"}
                    width={"100%"}
                    data-test-id="count"
                  >
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="tcount"
                    >
                      Topic Count
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Topic Count"
                      fieldName="topicCountValue"
                      fieldId="topic-count-input"
                      textFieldStyles={textfieldStyles}
                      fieldValue={topicCountValue}
                      fieldWidth={"99%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      isDisabled={true}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <Box
                    marginBottom={"10px"}
                    width={"100%"}
                    data-test-id="ActivityCount"
                  >
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="CountActi"
                    >
                      Activity Count
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Activity Count"
                      fieldName="activityCountValue"
                      fieldId="activity-count-input"
                      textFieldStyles={activityStyle}
                      fieldWidth={"99%"}
                      fieldType="text"
                      fieldValue={activityCountValue}
                      handleChange={this.handleChange}
                      isDisabled={true}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <Box marginBottom={"10px"} width={"100%"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="lessonsCount"
                    >
                      No. of lessons a day
                      <Typography
                        component={"span"}
                        className={classes.requiredNotation}
                      >
                        *
                      </Typography>
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Topic Count"
                      fieldName="noOfLessonsADayValue"
                      fieldId="no-of-lesson-a-day-input"
                      textFieldStyles={textfieldStyles}
                      fieldWidth={"99%"}
                      fieldType="text"
                      fieldValue={noOfLessonsADayValue}
                      handleChange={this.handleChange}
                      fieldError={lessonPerDayError}
                      helperText={lessonPerDayErrorMsg}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <Box
                    marginBottom={"10px"}
                    width={"100%"}
                    data-test-id="lessonsYear"
                  >
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="lessoYear"
                    >
                      School Year
                    </Typography>
                    <Box marginBottom={"10px"}>
                      <CustomSelectDropdown
                        fieldId="schoolYearDropdown"
                        fieldName="selectedSchoolYearDDValue"
                        handleChange={this.handleDropdownValues}
                        fieldValue={selectedSchoolYearDDValue}
                        fieldMenuItems={schoolYearDropdownList}
                        fieldError={false}
                        fieldStyles={dropdownStyles}
                        renderValue={this.renderSchoolYearDDValue}
                        isLoading={isEdit && schoolYearDropdownList.length === 0}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={4} item>
                  <Box
                    marginBottom={"10px"}
                    width={"100%"}
                    data-test-id="TermYear"
                  >
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="TermYear"
                    >
                      Term
                    </Typography>
                    <Box marginBottom={"10px"}>
                      <CustomSelectDropdown
                        fieldId="termDropdown"
                        handleChange={this.handleDropdownValues}
                        fieldName="selectedTermDDValue"
                        fieldValue={selectedTermDDValue}
                        fieldError={false}
                        fieldMenuItems={termDataList}
                        renderValue={this.renderTermDDValues}
                        fieldStyles={dropdownStyles}
                        isLoading={false}
                        iconComponent={
                          <DropdownIcon
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke={"#888888"}
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
                {isEdit && (
                  <>
                    <Grid xs={12} sm={12} md={6} item>
                      <Box
                        marginBottom={"10px"}
                        width={"100%"}
                        data-test-id="TermYear"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="TermYear"
                        >
                          Color
                          <Typography
                            component={"span"}
                            className={classes.requiredNotation}
                          >
                            *
                          </Typography>
                        </Typography>
                        <Box marginBottom={"10px"}>
                          <CustomColorPickerFieldWeb
                            fieldId="add-course-color-picker"
                            fieldName="selectedColor"
                            handleColorChange={this.handleSetColor}
                            fieldWidth="100%"
                            fieldValue={selectedColorName}
                            fieldValueHexCode={selectedColor}
                            textFieldStyles={colorPickerStyles}
                            placeHolderText={"Select Color"}
                            isDisabled={true}
                            classes={classes}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid sm={12} md={6} item>
                      <Box
                        marginBottom={"10px"}
                        width={"100%"}
                        data-test-id="TermYear"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="TermYear"
                        >
                          Icon
                          <Typography
                            component={"span"}
                            className={classes.requiredNotation}
                          >
                            *
                          </Typography>
                        </Typography>
                        <Box marginBottom={"10px"}>
                          <CustomFileUploadTextFieldWeb
                            placeHolderText={`Select File`}
                            fieldName={"selectedIcon"}
                            removeText="Remove"
                            fieldId="add-course-icon"
                            uploadText="Browse Icon"
                            fieldValue={selectedIcon}
                            fieldWidth={"100%%"}
                            textFieldStyles={textfieldStyles}
                            fieldType="text"
                            handleFile={this.handleSetFile}
                            isDisabled={true}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
                 {classesCustmFieldConfigList?.map((classesCustomFieldItem) => (
            <Grid
              key={`${classesCustomFieldItem?.label}-${classesCustomFieldItem?.id}`}
              item
              lg={6}
              md={6}
              sm={12}
            >
              <Box style={{ marginRight: "20px" }}>
                <Typography  className={`${classes.fontText16} ${classes.textCommonColor} ${classes.inputLabel} `}>
                  {classesCustomFieldItem?.label}
                </Typography>
                <CustomTextField
                  fieldName={classesCustomFieldItem?.name}
                  fieldWidth={"100%%"}
                  fieldId={`class-mng-custom-field-${classesCustomFieldItem?.id}`}
                  fieldValue={classesCustomFieldItem?.value}
                  isDisabled={classesCustomFieldItem?.isDisabled}
                  textFieldStyles={textfieldStyles}                  
                  placeHolderText={classesCustomFieldItem?.placeHolderText}
                  fieldType="text"
                  handleChange={ this.handleClassCustomFieldTextChange}
                />
              </Box>
            </Grid>
          ))}               

                <Grid xs={12} sm={12} md={6} item>
                  <Box
                    marginBottom={"10px"}
                    width={"100%"}
                    data-test-id="Description"
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="Descriptions"
                    >
                      Description
                    </Typography>
                    <TextareaAutosize
                      className={`${classes.bgColor} ${classes.textPrimaryColor} ${classes.fontText16} ${classes.textArea} ${classes.customFontFamily}`}
                      aria-label="minimum height"
                      minRows={6}
                      placeholder="Enter Description"
                      value={decriptionValue}
                      name="decriptionValue"
                      onChange={this.handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ width: "100%" }} marginBottom={"10px"}>
                    <CustomButton
                      btnText="Submit"
                      buttonId={`newCourseSubmitButtons`}
                      buttonVariant={"contained"}
                      btnStyle={submitBtnStyles}
                      handleButtonClick={this.handleRequestBody}
                      type={"button"}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnText="Cancel"
                      buttonVariant={"outlined"}
                      btnStyle={cancelBtnSTyle}
                      buttonId={`newCourseCanclButton`}
                      isDisabled={false}
                      type={"button"}
                      handleButtonClick={this.handleClosePopup}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
        <TeacherBrowseCourseCatModal  
         data-test-id="TeacherBrowseCourseCatModal"        
          open={this.state.openBrowseCatalogModal}
          selectedRowid={ (id:number) =>  this.handleSelectedRowId(id)}
          onConfirm={this.handleCloseBrowseCourseCatalog}
          onCancel={this.handleCloseBrowseCourseCatalog}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const cancelBtnSTyle = {
  width: "120px",
  padding: "10px 10px",
  height: "50px",
  borderRadius: "12px",
  fontSize: "18px",
  marginTop: "32px",
  textAlign: "center",
  float: "right",
  marginLeft: "15px",
  fontWeight: 500,
} as const;

const submitBtnStyles = {
  padding: "10px 10px",
  height: "50px",
  width: "120px",
  fontSize: "18px",
  textAlign: "center",
  borderRadius: "12px",
  marginTop: "32px",
  float: "right",
  marginLeft: "15px",
  fontWeight: 500,
} as const;

const browseCourseCatalogButtons = {
  padding: "10px 10px",
  height: "56px",
  fontSize: "18px",
  borderRadius: "12px",
  fontWeight: 500,
  width: "inherit",
  float: "right",
  textAlign: "center",
} as const;

const dropdownStyles = {
  height: "50px",
  width: "100%",
  borderRadius: "12px",
  padding: "17px 15px",
  background: "#FFF",
  border: "1px solid #888",
  fontSize: "16px",
  color: "#888",
  marginTop: "10px",
  fontWeight: 400,
} as const;
const textfieldStyles = {
  padding: "8px 0px",
  width: "99%",
  marginTop: "10px",
} as const;
const activityStyle = {
  width: "99%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;
const colorPickerStyles = {
  marginTop: "10px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAddNewCourseModal);
// Customizable Area End
