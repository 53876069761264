// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ParticipantsTabController, {
  Props
} from "./ParticipantsTabController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import CustomTable, { tableColumnTypes } from "../../../../components/src/CustomTable.web";

// Customizable Area End
const participantsTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "200px",
  },
  {
    id: "Subject",
    columnId: 2,
    label: "Subject",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "Grade",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "TotalUsers",
    columnId: 4,
    label: "Total no. of users",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "ActiveUsers",
    columnId: 5,
    label: "No. of active users",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "LessonsPerDay",
    columnId: 6,
    label: "No. Lessons Per Day",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "CompletionRate",
    columnId: 7,
    label: "Completion Rate",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "TimesModified",
    columnId: 8,
    label: "Times Modified",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
    maxWidth: "150px",
  },
]
const participantsData = [
  {
    id: 1,
    Title: "Physics",
    Subject: "Science",
    Grade: "Grade 3",
    TotalUsers: "60",
    ActiveUsers: "55",
    LessonsPerDay: "10",
    CompletionRate: "80%",
    TimesModified: "10",
  },
  {
    id: 2,
    Title: "Physics",
    Subject: "Science",
    Grade: "Grade 3",
    TotalUsers: "60",
    ActiveUsers: "55",
    LessonsPerDay: "10",
    CompletionRate: "80%",
    TimesModified: "10",
  },
  {
    id: 3,
    Title: "Physics",
    Subject: "Science",
    Grade: "Grade 3",
    TotalUsers: "60",
    ActiveUsers: "55",
    LessonsPerDay: "10",
    CompletionRate: "80%",
    TimesModified: "10",
  },
  {
    id: 4,
    Title: "Physics",
    Subject: "Science",
    Grade: "Grade 3",
    TotalUsers: "60",
    ActiveUsers: "55",
    LessonsPerDay: "10",
    CompletionRate: "80%",
    TimesModified: "10",
  },
  {
    id: 5,
    Title: "Physics",
    Subject: "Science",
    Grade: "Grade 3",
    TotalUsers: "60",
    ActiveUsers: "55",
    LessonsPerDay: "10",
    CompletionRate: "80%",
    TimesModified: "10",
  },
  {
    id: 6,
    Title: "Physics",
    Subject: "Science",
    Grade: "Grade 3",
    TotalUsers: "60",
    ActiveUsers: "55",
    LessonsPerDay: "10",
    CompletionRate: "80%",
    TimesModified: "10",
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "30px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    participantsTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }
    }
  });

// Customizable Area End

// Customizable Area Start
export class ParticipantsTab extends ParticipantsTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedSubjectValue,
      selectedGradeValue,
      selectedNameValue,
      selectedNoOfUsersValue,
      selectedNoOfActiveUsersValue,
      selectedNoOfLessonsPerDayValue,
      selectedCompletionRateValue,
      selectedTimesModifiedValue,
    } = this.state;

    return (
      <Box style={{ padding: "25px" }}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Name
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown fieldId="nameDropdown" fieldName="selectedNameValue" handleChange={this.handleChangeDropdownValue}
                  fieldValue={selectedNameValue} fieldError={false} renderValue={this.renderNameDropdownValue} fieldStyles={dropdownStyle}
                  fieldMenuItems={this.getDropdownItemsArray("Participant Name")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                  Subject
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedSubjectValue"
                  fieldId="subjectDropdown"
                  fieldValue={selectedSubjectValue}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderSubjectDropdownValue}
                  fieldError={false}
                  fieldMenuItems={this.getDropdownItemsArray("Subject")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Grade
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedGradeValue"
                  fieldId="gradesDropdown"
                  fieldValue={selectedGradeValue}
                  handleChange={this.handleChangeDropdownValue}
                  fieldError={false}
                  fieldStyles={dropdownStyle}
                  renderValue={this.renderGradeDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("Grade")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Total no. of users
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown fieldId="selectedNoOfUsersDropdown" fieldName="selectedNoOfUsersValue" handleChange={this.handleChangeDropdownValue}
                  fieldValue={selectedNoOfUsersValue} fieldError={false} renderValue={this.renderNoOfUsersDropdownValue}
                  fieldStyles={dropdownStyle} fieldMenuItems={this.getDropdownItemsArray("0")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  No. of active users
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown fieldId="noOfActiveUsersDropdown" fieldName="selectedNoOfActiveUsersValue"
                  fieldValue={selectedNoOfActiveUsersValue} handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderNoOfActiveUsersValue} fieldError={false} fieldMenuItems={this.getDropdownItemsArray("0")} fieldStyles={dropdownStyle}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  No. of lessons per day
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedNoOfLessonsPerDayValue"
                  fieldId="noOfLessonsPerDayDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  fieldValue={selectedNoOfLessonsPerDayValue}
                  fieldError={false} fieldMenuItems={this.getDropdownItemsArray("0")}
                  renderValue={this.renderNoOfLessonsPerDayValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Completion Rate
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown fieldId="completionRateDropdown" fieldName="selectedCompletionRateValue"
                  fieldValue={selectedCompletionRateValue} handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderCompletionRateValue} fieldError={false} fieldMenuItems={this.getDropdownItemsArray("1")} fieldStyles={dropdownStyle}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Times Modified
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown fieldId="timesModifiedDropdown" fieldName="selectedTimesModifiedValue"
                  fieldValue={selectedTimesModifiedValue} handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderTimesModifiedValue} fieldError={false} fieldMenuItems={this.getDropdownItemsArray("0")} fieldStyles={dropdownStyle}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchParticipantsButton} isDisabled={false} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.dividerLine} />
        <Grid container>
          <Grid item xs={12}>
            <Box className={`${classes.customTableStyles} ${classes.participantsTableRow}`}  >
              <CustomTable
                tableId={"coursesTable"}
                tableHeaderColumns={participantsTableColumns}
                tableBodyData={participantsData}
                showMenuIcon={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
} as const;

const dropdownStyle = {
  width: "100%"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParticipantsTab);
// Customizable Area End
