// Customizable Area Start
import React, { useRef, useState } from 'react';
import { Box, Typography } from "@material-ui/core";
import { makeStyles , Theme } from "@material-ui/core/styles";
import AudioPlayerDisplayTrack from './AudioPlayerDisplayTrack.web';
import AudioPlayerControl from './AudioPlayerControl.web';
import AudioPlayerProgress from './AudioPlayerProgress.web';
// Customizable Area End

// Customizable Area Start
interface IProps {
    currentTrack: any;
    classes: any;
    handleUpdateMediaProgressTime?:any;
}

const useStyles = makeStyles((theme : Theme) => ({
    audioDisplay:{
        width:"90%",
        [theme.breakpoints.down('xs')]: {
            width: "90%",
        },
    },
    audioPlayerCard:{
        border:"1px solid #f1f1f1",
        backgroundColor:"rgba(0, 157, 255, 0.05)",
        borderRadius:"6px",
        width:"94%",
        [theme.breakpoints.down('xs')]: {
            width: "84%",
        }
    }
  }))
// Customizable Area End

// Customizable Area Start
const CustomAudioPlayer: React.FC<IProps> = (props) => {
    const newClasses = useStyles(); 
    const { currentTrack, classes , handleUpdateMediaProgressTime } = props;
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [errorInAudio , setErrorInAudio] = useState(false);
    const audioRef = useRef(null);
    const progressBarRef = useRef(null);

    const handleErrorInAudio = () => {
        setErrorInAudio(true)
    }

    return (
        <Box className={`${newClasses?.audioPlayerCard}`} borderRadius={"6px"} p={"20px 15px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"94%"}>
           {!errorInAudio ? <>
            <Box className={newClasses?.audioDisplay} >
                <AudioPlayerDisplayTrack
                    {...{ currentTrack, audioRef, setDuration, progressBarRef, classes, setTimeProgress , handleErrorInAudio , handleUpdateMediaProgressTime }}
                />
                <AudioPlayerProgress {...{ progressBarRef, audioRef, timeProgress, duration, classes }} />
            </Box>
            <Box>
                <AudioPlayerControl {...{ audioRef, progressBarRef, duration, setTimeProgress, classes }} />
            </Box>
            </>:
            <Box>
                <Typography className={`${classes.primaryColor6}`} component={"h3"}>
                    Audio file could not be loaded.
                </Typography>
            </Box>
            }
        </Box>
    )
}

export default CustomAudioPlayer;
// Customizable Area End