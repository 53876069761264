import React from 'react';
import { Box, Select, MenuItem, Checkbox, ListItemText, InputAdornment, Typography, FormHelperText } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import DropdownIcon from "./SVGIcons/DropdownIcon.web";
import { combineStyles } from "./CombineStyles.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import CustomMultiSelectDropDownController, {
  Props,
} from "./CustomMultiSelectDropDownController.web";

const styles = (theme: Theme) =>
  createStyles({
    selectBootstrapInput: {
      borderRadius: "10px",
      border: "solid 1px #888888",
      padding: "7px 0px 7px 10px",
      fontSize: "16px",
      cursor: "none !important",
      "& .MuiSelect-select": {
        color: "#888888",
        fontWeight: 500,
        paddingRight: "0px !important",
      },
      "& .MuiSelect-icon": {
        color: "#888888",
      },
    },
    errorBorder: {
      border: "1px solid red !important",
    },
  });
export class CustomMultiSelectDropDown extends CustomMultiSelectDropDownController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { fieldMenuItems, label, selectedItems, handleChange, classes, fieldStyles, fieldError, helperText, isDisabled, disabledItemSelection } = this.props;
    const handleClose = this.props.handleClose ? this.props.handleClose : () => console.log('closed');

    const selectClass: any = () => {
      const { fieldError } = this.props;
      if (fieldError) return `${classes.selectBootstrapInput} ${classes.errorBorder} ${classes.bgColor
        }`;
      else if (isDisabled) return `${classes.selectBootstrapInput} ${classes.secondaryBgColor
        }`
      else return `${classes.selectBootstrapInput} ${classes.bgColor
        }`
    }

    return (
      <Box>
        <Select
          style={fieldStyles}
          displayEmpty
          native={false}
          disableUnderline
          disabled={isDisabled}
          className={selectClass()}
          multiple
          value={selectedItems}
          onChange={handleChange}
          onClose={handleClose}
          renderValue={(selected: any) => (
            selected.length === 0 ? <Typography component={"span"} className={`${classes.textCommonColor}`}>{label}</Typography> :
              <Box>
                {(selected).map((value: number, index: number) => (
                  <Typography component={"span"} key={value} className={`${classes.textSecondaryColor}`} >
                    {fieldMenuItems.find((item) => item.id === value)?.label}
                    {index !== (selected).length - 1 ? ', ' : ''}
                  </Typography>
                ))}
              </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                borderRadius: "12px"
              },
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          error={fieldError}
          IconComponent={ArrowDownIconComponent}
        >
          {fieldMenuItems.map((item) => (
            <MenuItem disabled={disabledItemSelection} key={item.id} value={item.value}>
              <Checkbox
                style={{
                  color: "#FFD92E",
                  fontSize: '2rem'
                }}
                checked={selectedItems.includes(item.value)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        {fieldError && <FormHelperText className={`${classes.helperText}`}>{helperText}</FormHelperText>}
      </Box>
    );
  }
}

const ArrowDownIconComponent = () => (
  <InputAdornment position="start">
    <DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />
  </InputAdornment>
);

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CustomMultiSelectDropDown);
