// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}

export interface IDDConfigProps {
  id: any;
  name: string;
  fieldName: string;
  value: any;
  placeHolderText: string;
  menuItems?: any;
  fieldType: string;
  label: string;
  isDisabled?: boolean;
}

export interface IParentInputConfigProps {
  id: any;
  label: string;
  ddConfigs: Array<IDDConfigProps>;
  role: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  parentInputConfigs: Array<IParentInputConfigProps>;
  handleUploadFile: any;
  handleChange: any;
  renderDDMenuItem: any;
  handleSelectDropdownValue: any;
  handleMarkAsParentTeacher: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddNewParentGuardianDetailsStep2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.parentDetailsStepperLoaded();
    // Customizable Area End
  }

  // Customizable Area Start
  parentDetailsStepperLoaded = () => {
    console.log("Parent Details Step 2");
  };
  // Customizable Area End
}
// Customizable Area End
