// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ParentTeacherDashboardViewallController, {
  Props,
} from "./ParentTeacherDashboardViewallController.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import  CustomTable, { tableColumnTypes }  from "../../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";


// Customizable Area End

// Customizable Area Start
const tenantSubjectColumns = [
    {
      id: "Name",
      columnId: 1,
      label: "Name",
      type: tableColumnTypes.STUDENT_ICON,
      width: "250px",
      maxWidth: "280px",
    },
    {
      id: "Progress",
      columnId: 2,
      label: "Progress",
      type: tableColumnTypes.STUDENT_PROGRESS_BAR,
    },
    {
        id: "TermAvg",
        columnId: 2,
        label: "Term Avg",
        type: tableColumnTypes.PROGRESS_TEXT,
      },
      {
        id: "YTD",
        columnId: 3,
        label: "YTD",
        type: tableColumnTypes.PROGRESS_TEXT,
      },
  ];

const styles = (theme: Theme) =>
  createStyles({
    actBreadCrumbRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    BoxTopBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    capitalText: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

// Customizable Area Start
export class ParentTeacherDashboardViewall extends ParentTeacherDashboardViewallController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { typeOfActivity} = this.state;
    const { progressStudentsTableBodys } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.actBreadCrumbRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Dashboard`}
          </Typography>         
        </Box>
        <Box className={classes.BoxTopBtnRoot}>
          <Box
            data-test-id="activity_typee"
            className={`${classes.cursorPointer}`}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              onClick={() => this.handleGoBack()}
              data-test-id="activity_type_back_icon"
              mr={"8px"}
            >
              <KeyboardBackspaceIcon
                fontSize="large"
                className={`${classes.textPrimaryColor}`}
              />
            </Box>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600} ${classes.capitalText}`}
              component={"span"}
            >
              {typeOfActivity} Progress and Performance
            </Typography>
          </Box>
        </Box>
        <Box className={classes.bgColor} borderRadius={"10px"}>       

            <CustomTable
                tableId={"teacher-dashboard-students-progress"}
                tableHeaderColumns={tenantSubjectColumns}
                tableBodyData={progressStudentsTableBodys}
                showMenuIcon={false}
              />


        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentTeacherDashboardViewall);
// Customizable Area End
