import { tableColumnTypes } from "../../CustomTable.web";
import { englishSubjectIcon, historySubjectIcon, mathSubjectIcon, physicsSubjectIcon, psychologySubjectIcon, profilePic1, man, man2, man3, man4, man5, man6, sort, alert } from "../assets";


const studentCoursesList = [
    {
        id: 1,
        subjectName: "Science",
        subjectMarks: 76,
        lessonsCovered: 99,
        lessonsTotal: 100,
        status: 'Completed',
        subjectIcon: physicsSubjectIcon,
        timeSpentInMin: 400,
        subjectBgColor: "#01C996",
        completionProgress: 100,

    },
    {
        id: 2,
        subjectName: "Mathematics",
        subjectMarks: 89,
        lessonsCovered: 72,
        lessonsTotal: 100,
        status: 'Late',
        subjectIcon: mathSubjectIcon,
        timeSpentInMin: 500,
        subjectBgColor: "#0D46BC",
        completionProgress: 46,

    },
    {
        id: 3,
        subjectName: "English",
        subjectMarks: 59,
        lessonsCovered: 77,
        lessonsTotal: 100,
        status: 'On Going',
        subjectIcon: englishSubjectIcon,
        timeSpentInMin: 500,
        subjectBgColor: "#019AFC",
        completionProgress: 63,

    },
    {
        id: 4,
        subjectName: "History",
        subjectMarks: 29,
        lessonsCovered: 89,
        lessonsTotal: 100,
        status: 'Scheduled',
        subjectIcon: historySubjectIcon,
        timeSpentInMin: 500,
        subjectBgColor: "#FBD82C",
        completionProgress: 96,

    },
    {
        id: 5,
        subjectName: "Psychology",
        subjectMarks: 39,
        lessonsCovered: 98,
        lessonsTotal: 100,
        status: 'Late',
        subjectIcon: psychologySubjectIcon,
        timeSpentInMin: 1099,
        subjectBgColor: "#FF0000",
        completionProgress: 41,

    }
];

const studentListTableBody = [
    {
        id: 1,
        Name: {
            icon: profilePic1,
            name: "Milton peery",
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 2,
        Name: {
            name: "Prescott MacCaff",
            icon: man5,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 3,
        Name: {
            name: "Isak Johannesson",
            icon: man2,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 4,
        Name: {
            name: "Jason Bailey",
            icon: man4,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 5,
        Name: {
            icon: profilePic1,
            name: "Milton peery",
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 6,
        Name: {
            name: "Prescott MacCaff",
            icon: man5,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 7,
        Name: {
            name: "Isak Johannesson",
            icon: man2,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 8,
        Name: {
            name: "Jason Bailey",
            icon: man4,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Open'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 9,
        Name: {
            icon: profilePic1,
            name: "Milton peery",
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'Locked In'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 10,
        Name: {
            name: "Prescott MacCaff",
            icon: man5,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'For Registrar Approval'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 11,
        Name: {
            name: "Isak Johannesson",
            icon: man2,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'For Registrar Approval'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
    {
        id: 12,
        Name: {
            name: "Jason Bailey",
            icon: man4,
        },
        Progress: '64%',
        Q1Avg: {value: '80%', status: 'Locked In'},
        Q2Avg: {value: '80%', status: 'Locked In'},
        Q3Avg: {value: '80%', status: 'Locked In'},
        Q4Avg: {value: '80%', status: 'For Advisor Approval'},
        GPA: '85',
        LastAccess: 'July 20, 2022',
        isSelected: false
    },
];

const studentListTableColumns = [
    {
        id: "Name",
        columnId: 1,
        label: "Name",
        type: tableColumnTypes.STUDENT_ICON,
        width: "250px",
        maxWidth: "280px"
    },
    {
        id: "Progress",
        columnId: 2,
        label: "Progress",
        icon: sort,
        type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
        isSortingColumn: true,
        sortByOrder: "",
    },
    {
        id: "gpa",
        columnId: 7,
        label: "GPA",
        icon: sort,
        type: tableColumnTypes.PROGRESS_TEXT,
        isSortingColumn: true,
    },
    {
        id: "lastAccess",
        columnId: 8,
        label: "Last Access",
        icon: sort,
        type: tableColumnTypes.DUE_DATE
    },
];

const progressColors = [
    {
        label: "Open",
        color: "#090909"
    },
    {
        label: "For Advisor Approval",
        color: "#FBDD49"
    },
    {
        label: "For Registrar Approval",
        color: "#0947BF"
    },
    {
        label: "Locked In",
        color: "#01C996"
    },
    {
        label: "Rejected",
        color: "#ED1111"
    }
];

const registrarProgressColorsListMock = [
    {
        label: "Open",
        color: "#090909"
    },
    {
        label: "Locked In",
        color: "#01C996"
    },
    {
        label: "Returned",
        color: "#ED1111"
    }
];

const tableActionMenu = [
    {
        label: 'View Student Profile',
        action: () => { }
    },
    {
        label: 'Open Gradebook',
        action: () => { }
    },
    {
        label: 'Print Unofficial',
        action: () => { }
    },
    {
        label: 'Print Official',
        action: () => { }
    }
];


const studentDataMock = {
    id: "929",
    email: "profile@gmail.com",
    full_name: "Student Profile",
    id_number: "ST444",
    curriculum: "Curriculum A",
    dob: "11/06/1979",
    grade: [],
    role: "Student",
    schoolYearTitle: "2024",
    status: "active",
    expiringOn: "2027-06-11",
    profile_pic: "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbklFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3b8900ebdaff683fc209dee39156cfba8f6d0c9e/images%20(13).jpeg",
    customFields: [
    ]
  }
export {
    studentCoursesList,
    studentListTableBody,
    studentListTableColumns,
    progressColors,
    tableActionMenu,
    registrarProgressColorsListMock,
    studentDataMock
};