// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import CustomTable from "../../../components/src/CustomTable.web";
import TenantAdminSetupCurriculumController, {
  Props,
} from "./TenantAdminSetupCurriculumController.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import DeleteSchoolYearConfirmModalWeb from "./DeleteSchoolYearConfirmModal.web";
import NewCurriculumDialogWeb from "./NewCurriculumDialog.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      display: "flex",
      alignItems: "flex-end",
      padding: "24px",
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
      marginBottom: "6px",
      marginTop: "10px",
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
    searchBtnGrid: {
      display: "flex", 
      alignItems: "center",
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px"
      }
    },
  });

const curriculumTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px !important",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupCurriculum extends TenantAdminSetupCurriculumController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openNewCurriculumDialog } = this.props;

    const {
      selectCurriculumItems,
      selectCurriculumMenu,
      selectSubjectItems,
      selectSubject,
      selectGrade,
      selectGradeItems,
      selectCurriculumMenuItems,
      curriculumTableActionButtonConfig,
      openDeleteCurriculumPopup,
      openEditNewCurriculumPopup,
      selectedCurriculumData,
      curriculumTableHeader,
      customFieldsList,
      customFieldConfigList,
      subjectsListAsPerGrade,
      getSubjectLoader,
      currentPage,
      perPageRecords,
      totalNumberOfCurriculumRecords,
      curriculumPermission
    } = this.state;
    return (
      <Box className={`${classes.bgColor}`} style={{ paddingTop: "1px" }}>
        <Grid container className={`${classes.searchBox}`}>
          <Grid item lg={3} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography
                className={`${classes.inputLabel} ${classes.fontSize16}`}
              >
                Curriculum
              </Typography>
              <CustomSelectDropdown
                fieldId="setup-curriculum-select-dropdown-curriculum"
                fieldValue={selectCurriculumMenu}
                fieldName="selectCurriculumMenu"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderValueCurriculum}
                fieldMenuItems={selectCurriculumMenuItems}
                fieldStyles={{ width: "100%" }}
                placeHolderText="Select Curriculum"
              />
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography
                className={`${classes.inputLabel} ${classes.fontSize16}`}
              >
                Subject
              </Typography>
              <CustomSelectDropdown
                fieldId="setup-curriculum-select-dropdown-subject"
                fieldValue={selectSubject}
                fieldName="selectSubject"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderValueSubject}
                fieldMenuItems={selectSubjectItems}
                fieldStyles={{ width: "100%" }}
                placeHolderText="Select Subject"
              />
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12}>
            <Box style={{ marginRight: "20px" }}>
              <Typography
                className={`${classes.inputLabel} ${classes.fontSize16}`}
              >
                Grade
              </Typography>
              <CustomSelectDropdown
                fieldId="setup-curriculum-select-dropdown-grade"
                fieldValue={selectGrade}
                fieldName="selectGrade"
                handleChange={this.handleChangeDropdownValue}
                renderValue={this.renderValueGrade}
                fieldMenuItems={selectGradeItems}
                fieldStyles={{ width: "100%" }}
                placeHolderText="Select Grade"
              />
            </Box>
          </Grid>
          {/* Custom Fields */}
          {customFieldConfigList.map((fieldItem) => (
            <Grid
              key={`${fieldItem?.label}-${fieldItem?.id}`}
              item
              lg={3}
              md={6}
              sm={12}
            >
              <Box style={{ marginRight: "20px" }}>
                <Typography
                  className={`${classes.inputLabel} ${classes.fontSize16}`}

                >
                  {fieldItem.label}
                </Typography>
                <CustomTextField
                  fieldName={fieldItem.name}
                  fieldWidth={"100%"}
                  fieldId={`curriculum-custom-field-${fieldItem.id}`}
                  fieldValue={fieldItem.value}
                  isDisabled={fieldItem?.isDisabled}
                  textFieldStyles={textfieldStyles}
                  placeHolderText={fieldItem.placeHolderText}
                  fieldType="text"
                  handleChange={(e: any) => this.handleCustomFieldTextChange(e)}
                />
              </Box>
            </Grid>
          ))}
          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            className={`${classes.searchBtnGrid}`}
          >
            <CustomButtonWeb
              buttonId="setup-curriculum-filter-search"
              buttonVariant="contained"
              btnStyle={{
                padding: "10px 10px",
                width: "130px",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "10px",
                textAlign: "center",
              }}
              btnText="Search"
              handleButtonClick={() => this.handleGetCurriculumsList(true)}
              isDisabled={false}
              type={"button"}
            />
          </Grid>
        </Grid>
        <Box
          style={{ backgroundColor: "#F1F1F1", height: "4px", width: "100%" }}
        />
        {selectCurriculumItems?.length > 0 ? (
          <>
            <Box
              className={`${classes.bgColor} ${classes.customTableStyles} ${classes.classManagementTable}`}
              p={"0px 20px"}
            >
              <ThemeProvider theme={curriculumTableTheme}>
                <CustomTable
                  tableId={"tenant-curriculum-table"}
                  tableHeaderColumns={curriculumTableHeader}
                  tableBodyData={selectCurriculumItems}
                  showMenuIcon={curriculumPermission.curriculum.write}
                  actionButtonConfig={curriculumTableActionButtonConfig}
                />
              </ThemeProvider>
            </Box>
            <Box className={classes.paginationBox}>
              <Box width={"50%"} marginTop={"20px"}>
                <Box display={"flex"} gridGap={"6px"}>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    Records
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                    component="span"
                  >
                    {totalNumberOfCurriculumRecords > perPageRecords
                      ? `${currentPage * perPageRecords - perPageRecords}-
                ${currentPage * perPageRecords}`
                      : `${
                          currentPage * totalNumberOfCurriculumRecords -
                          totalNumberOfCurriculumRecords
                        }-
                ${currentPage * totalNumberOfCurriculumRecords}`}
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                    component="span"
                  >
                    of {totalNumberOfCurriculumRecords}
                  </Typography>
                </Box>
              </Box>
              {totalNumberOfCurriculumRecords > perPageRecords && (
                <Box className={classes.paginationRoot}>
                  <Pagination
                    count={Math.ceil(
                      totalNumberOfCurriculumRecords / perPageRecords
                    )}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    siblingCount={0}
                    page={currentPage}
                    onChange={this.handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <CustomEmptyResultComponent message="No Records yet." />
        )}
        {(openEditNewCurriculumPopup || openNewCurriculumDialog) && (
          <NewCurriculumDialogWeb
            open={openEditNewCurriculumPopup || openNewCurriculumDialog}
            handleClose={() => this.handleCloseEditCurriculumPopup()}
            handleAddNewCurriculum={(data: any) =>
              this.handleAddNewCurriculum(data)
            }
            handleUpdateCurriculum={(data: any) =>
              this.handleUpdateCurriculum(data)
            }
            selectedCurriculumData={selectedCurriculumData}
            isEdit={openEditNewCurriculumPopup}
            gradesList={selectGradeItems || []}
            subjectsList={subjectsListAsPerGrade || []}
            customFields={customFieldsList}
            handleGetSubjectsForGradeSelected={(grade: any) =>
              this.handleGetSubjectsForGradeSelected(grade)
            }
            loading={getSubjectLoader}
          />
        )}
        <DeleteSchoolYearConfirmModalWeb
          open={openDeleteCurriculumPopup}
          onCancel={() => this.handleCloseDeleteCurriculumConfirmModal()}
          onConfirm={() => this.handleDeleteCurriculum()}
          title={selectedCurriculumData?.curriculum || ""}
          type="curriculum"
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupCurriculum);
// Customizable Area End
