// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { ITableActionBtnConfigProps, TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { invalidImage } from "./assets";
import { toast } from "react-toastify";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  openNewSubjectDialog: boolean;
  handleCloseNewSubjectPopup: any;
  // Customizable Area End
}
// Customizable Area Start
export interface DropdownItems {
  id: number;
  label: string;
  value: any;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  subjectsList: Array<any>;
  gradesMenuList: Array<DropdownItems>;
  selectFilterGrade: any;
  selectedGradeData: any;
  selectedSubjectData: any;
  openEditNewSubjectPopup: boolean;
  openDeleteSubjectPopup: boolean;
  subjectsTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  SubjectPermission:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminSetupSubjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSubjectsListing: string = "";
  apiPostSubject: string = "";
  apiUpdateSubject: string = "";
  apiDeleteSubject: string = "";
  apiGradesFilterListing: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      token: "",
      gradesMenuList: [],
      subjectsList: [],
      selectedGradeData: "",
      selectFilterGrade: "",
      selectedSubjectData: "",
      openEditNewSubjectPopup: false,
      openDeleteSubjectPopup: false,
      subjectsTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenEditSubjectPopup(value),
        },
        {
          label: "Delete",
          action: (value: any) => this.handleOpenSubjectDeleteConfirmModal(value),
        },
      ],
      SubjectPermission:{
        subject:{
          write:true,
          read:true
        },
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async handleGetSubjectRoleAndPermission(){
    const getSubjectPermission = await getStorageData("roleandpermission")
    if(getSubjectPermission){ 
      try {
        const parseData = JSON.parse(getSubjectPermission);
        if(parseData){
          const result = {
            subject: parseData?.subject?.can,
          };
          this.setState({SubjectPermission:result})
        }
      } catch (error) {
        console.error("Failed to parse subjectPermission. It might not be valid JSON.", error);
      }
    }
  }
  handleSetUserTokenTenantSubject = async () => {
    const token = await getStorageData("token");
    this.setState({ token });
  };

  handleChangeGradeValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { gradesMenuList } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const selectedGradeData = gradesMenuList.find((item) =>  item.value === value)
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          selectedGradeData
        };
      });
    }
  };

  renderGradeValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Value";
    }
    const { gradesMenuList } = this.state;
    const selectedGrade = gradesMenuList.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGrade?.label;
  };

  handleSetGradesFilterList = (responseJson: any) => {
    if (responseJson?.data) {
      const allGradesOption = {
        id: "All",
        value: "All",
        label: "All Grades"
      }
      const gradesList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: item?.id,
            value: item?.id,
            label: item?.attributes?.name,
          };
        }
      );
      this.setState({
        gradesMenuList: [allGradesOption, ...gradesList] || [],
      });
    }
  };

  handleSetSubjectDataItem = (item: any) => {
    return {
      id: item.id,
      subject: {
        name: item?.attributes?.name,
        icon: item?.attributes?.icon_url?.url,
        iconType: item?.attributes?.icon_url?.content_type,
        bgColor: item?.attributes?.color,
        boxWidth: "35px",
        boxHeight: "35px"
      },
      color: item?.attributes?.color,
      icon: {
        name: item?.attributes?.icon_url?.filename,
        icon: invalidImage,
      },
      grade: item?.attributes?.grade?.map((item: any) => {
        return (item?.id).toString();
      })
    };
  };

  handleSetSubjectData = (list: any) => {
    const subjectsData = list?.map((item: any) =>
      this.handleSetSubjectDataItem(item)
    );
    this.setState({ subjectsList: subjectsData });
  };

  handleGetGradesMenuList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessageGrades = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGradesFilterListing = requestMessageGrades.messageId;
    requestMessageGrades.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gradesEndpoint
    );

    requestMessageGrades.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageGrades.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageGrades.id, requestMessageGrades);
    return true;
  };

  handleGetSubjectsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const { selectedGradeData } = this.state;
    const queryString = `?grade_id=${selectedGradeData?.id}`;
    let getSubjectsEndpoint = configJSON.subjectsEndpoint;
    if (selectedGradeData && selectedGradeData.value !== "All") {
      getSubjectsEndpoint = `${getSubjectsEndpoint}${queryString}`;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSubjectsListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getSubjectsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGradesListDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleSetGradesFilterList(responseJson)
    }
  }

  handleGetSubjectsDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetSubjectData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleAddNewSubject = (requestBody: any) => {
    this.handleCloseEditSubjectPopup()
    showCustomLoader();
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subjectsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateSubject = (requestBody: any) => {
    this.handleCloseEditSubjectPopup();
    showCustomLoader();
    const { token, selectedSubjectData } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateSubject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subjectsEndpoint}/${selectedSubjectData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostSubjectDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.SUBJECT_CREATION_SUCCESS);
      this.handleGetSubjectsList();
    } else if(responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUpdateSubjectResponse = (responseJson: any, errorResponse: any) => {
    const { subjectsList } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedSubjectItem = this.handleSetSubjectDataItem(responseJson.data);
      const updatedSubjectsList = subjectsList.map((item) => {
        if (item.id === updatedSubjectItem.id) {
          item = { ...updatedSubjectItem };
        }
        return item;
      });
      this.setState({ subjectsList: updatedSubjectsList });
      toast.success(TOASTER_NOTIFICATION_MSG.SUBJECT_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleOpenEditSubjectPopup = (value: any) => {
    const { subjectsList } = this.state;
    const selectedSubjectData = subjectsList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedSubjectData,
      },
      () => {
        this.setState({ openEditNewSubjectPopup: true });
      }
    );
  };

  handleCloseEditSubjectPopup = () => {
    this.setState({ openEditNewSubjectPopup: false });
    this.props.handleCloseNewSubjectPopup()
  };

  handleDeleteSubject = async () => {
    this.handleCloseDeleteSubjectConfirmModal()
    showCustomLoader()
    const { token, selectedSubjectData } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteSubject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subjectsEndpoint}/${selectedSubjectData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteSubjectResponse = (responseJson: any) => {
    const { subjectsList, selectedSubjectData } = this.state;
    if (!responseJson?.errors) {
      const updatedSubjectList = subjectsList.filter(
        (item) => item.id !== selectedSubjectData.id
      );
      this.setState({
        subjectsList: updatedSubjectList,
        selectedSubjectData: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.SUBJECT_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.SUBJECT_DELETE_FAILURE);
    }
    hideCustomLoader()
  };

  handleOpenSubjectDeleteConfirmModal = (value?: any) => {
    const { subjectsList } = this.state;
    const selectedSubjectData = subjectsList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedSubjectData: selectedSubjectData,
      },
      () => {
        this.setState({ openDeleteSubjectPopup: true });
      }
    );
  };

  handleCloseDeleteSubjectConfirmModal = () => {
    this.setState({ openDeleteSubjectPopup: false });
  }

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetSubjectRoleAndPermission()
    this.handleGetSubjectsList();
    this.handleGetGradesMenuList();
    this.handleSetUserTokenTenantSubject();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGradesFilterListing:
            {
              this.handleGradesListDataResponse(responseJson);
            }
            break;
            case this.apiGetSubjectsListing:
            {
              this.handleGetSubjectsDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiPostSubject:
            {
              this.handlePostSubjectDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiDeleteSubject:
            {
              this.handleDeleteSubjectResponse(responseJson)
            }
            break;
          case this.apiUpdateSubject:
            {
              this.handleUpdateSubjectResponse(responseJson, errorResponse);
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
