// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomTextEditorController, {
  CustomJoditEditorProps,
  IJoditEditorProps,
  Props,
  
} from "./CustomTextEditorController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import JoditEditor from "jodit-react";
// Customizable Area End

// Customizable Area Start

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "lineHeight",
  "|",
  "spellcheck",
  "speechRecognize",
  "symbols",
  "preview",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source"
  
];

const editorConfig:IJoditEditorProps = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "middle",
  toolbarAdaptive: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
};

const styles = (theme: Theme) =>
  createStyles({
    errorBorder: {
      border: "1px solid red",
    },
    normalBorder: {
      border: "1px solid #888",
    },
    errorText: {
      color: "red",
      fontSize: "12px",
    }
  });

// Customizable Area End

export class CustomTextEditor extends CustomTextEditorController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  customEditorConfig: CustomJoditEditorProps = {
    ...editorConfig,
    placeholder: this.props.placeHolderText || "",
    height:this.props.customHeight || "250px"
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, editorError, helperText} = this.props;
    const { content } = this.state;

   
    const setEditorClass = () => {
      if (editorError) {
        return `${classes.bgColor} ${classes.customTextEditor} ${classes.errorBorder}`;
      } else {
        return `${classes.bgColor} ${classes.customTextEditor} ${classes.normalBorder}`;
      }
    }

    return (
      <>
        <Box className={setEditorClass()}>
          <JoditEditor
            value={content}
            config={this.customEditorConfig}
            onChange={this.handleChangeEditorContent}
          />
        </Box>
        {editorError && (
          <Typography component={"span"} className={classes.errorText}>{helperText}</Typography>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomTextEditor);
// Customizable Area End
