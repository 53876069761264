// Customizable Area Start
import React from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import NewSchoolCalendarDialogController, {
  Props,
} from "./NewSchoolCalendarDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import AddAnotherHolidayPopupWeb from "./AddAnotherHolidayPopup.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    closeDialogIcon: {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(2.6),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(1.5),
        right: theme.spacing(4),
        top: theme.spacing(5),
      },
    },
    inputLabel: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    paper: {
      width: "880px",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "440px",
      },
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    formFields: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    checkBox: {
      color: "#FFD92E",
      borderRadius: "6px",
      padding: "0px",
      "& .MuiSvgIcon-root": {
        width: "30px",
        height: "30px",
      },
    },
    weekDayLable: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#888888",
    },
    holidayTitle: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#090909",
    },
    holidayTextBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px",
    },
    holidayTableBox: {
      maxHeight: "245px",
      overflow: "auto",
    },
    daysPatternWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    holidayTableWrapper: {
      backgroundColor: "#D8D8D8",
      borderRadius: "12px",
    },
    checkBoxRoot: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    dividerBox: {
      height: "1px",
      backgroundColor: "#F1F1F1",
    },
    buttonBox: {
      margin: "30px 0",
      display: "flex",
      justifyContent: "end",
    },
  });
const holidayListHeader = [
  {
    id: "holiday",
    columnId: 1,
    label: "Holiday",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "startDate",
    columnId: 2,
    label: "Start Date",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "endDate",
    columnId: 3,
    label: "End Date",
    type: tableColumnTypes.TEXT,
  },
];
// Customizable Area End

// Customizable Area Start
export class NewSchoolCalendarDialog extends NewSchoolCalendarDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, handleClose, isEdit } = this.props;
    const {
      selectCurriculum,
      selectCurriculumError,
      selectCurriculumErrorMsg,
      curriculumItems,
      selectSchoolYear,
      selectSchoolYearError,
      selectSchoolYearErrorMsg,
      schoolYearTitleItems,
      holidayActionButtonConfig,
      holidays,
      weekdays,
      openAddAnotherHoliday,
      isEditHoliday,
      selectedHolidayData,
      selectCurriculumTitle,
      selectSchoolYearTitle,
    } = this.state;
    return (
      <>
        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          className={classes.dialogRoot}
        >
          <Box>
            <DialogTitle
              id="new-curriculum-dialog-title"
              className={`${classes.bgColor}`}
            >
              <Typography
                gutterBottom
                component={"span"}
                className={`${classes.textPrimaryColor} ${classes.modalHeadingText}`}
              >
                {isEdit ? "Edit School Calendar" : "Add New School Calendar"}
              </Typography>
              <CloseIcon
                id="An icon to close Dialog"
                className={`${classes.closeDialogIcon} ${classes.textCommonColor}`}
                onClick={this.handleClosePopup}
              />
            </DialogTitle>
            <DialogContent className={`${classes.bgColor}`}>
              <Box>
                <Box className={classes.formFields}>
                  <Box>
                    <Typography className={classes.inputLabel}>
                      School Year Title
                    </Typography>
                    <CustomSelectDropdown
                      fieldId="select-dropdown-school-year"
                      fieldValue={selectSchoolYear}
                      fieldName="selectSchoolYear"
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderSchoolYearTitleDDValue}
                      fieldError={selectSchoolYearError}
                      helperText={selectSchoolYearErrorMsg}
                      fieldMenuItems={schoolYearTitleItems}
                      fieldStyles={{ maxWidth: "100%", width: "400px" }}
                    />
                  </Box>
                  <Box>
                    <Typography className={classes.inputLabel}>
                      Curriculum
                    </Typography>
                    <CustomSelectDropdown
                      fieldId="select-dropdown-curriculum"
                      fieldValue={selectCurriculum}
                      fieldName="selectCurriculum"
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderCurriculumDDValue}
                      fieldError={selectCurriculumError}
                      helperText={selectCurriculumErrorMsg}
                      fieldMenuItems={curriculumItems}
                      fieldStyles={{ maxWidth: "100%", width: "400px" }}
                    />
                  </Box>
                </Box>
                <Box mb={3.75}>
                  <Typography className={classes.inputLabel}>
                    Days Pattern
                  </Typography>
                  <Box className={classes.daysPatternWrapper}>
                    {weekdays.map((day) => (
                      <Box key={day.id} className={classes.checkBoxRoot}>
                        <Checkbox
                          data-test-id={`day-pattern-checkbox-${day.value}`}
                          style={{ color: "FFD92E" }}
                          className={classes.checkBox}
                          checked={day.active}
                          onChange={() => this.handleDaysPattern(day.value)}
                        />
                        <Typography
                          component={"span"}
                          className={`${classes.textCommonColor} ${classes.fontSize16} ${classes.fontBold500}`}
                        >
                          {day.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box className={classes.holidayTableWrapper}>
                  <Box
                    className={`${classes.bgColor} ${classes.holidayTextBox}`}
                  >
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.holidayTitle}`}
                    >
                      Holiday List
                    </Typography>
                    <CustomButton
                      btnStyle={addAnotherHolidayBtn}
                      btnText="Add Another Holiday"
                      buttonId="add-another-holiday-btn"
                      buttonVariant={"outlined"}
                      isDisabled={false}
                      type={"button"}
                      handleButtonClick={() =>
                        this.handleOpenAddAnotherHoliday()
                      }
                    />
                  </Box>
                  <Box className={classes.dividerBox} />
                  <Box
                    className={`${classes.bgColor} ${classes.holidayTableBox}`}
                    p={3}
                  >
                    <CustomTable
                      tableId={"holiday-list-calendar-table"}
                      tableHeaderColumns={holidayListHeader}
                      tableBodyData={holidays.filter((item: any) => !item?._destroy)}
                      showMenuIcon={true}
                      actionButtonConfig={holidayActionButtonConfig}
                    />
                  </Box>
                </Box>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    btnStyle={cancelCurriculumBtn}
                    btnText="Cancel"
                    buttonId="school-calendar-cancel-btn"
                    buttonVariant={"outlined"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={() => this.handleClosePopup()}
                  />
                  <CustomButton
                    btnStyle={addCurriculumBtn}
                    btnText={isEdit ? "Save" : "Add"}
                    buttonId="school-calendar-add-btn"
                    buttonVariant={"contained"}
                    isDisabled={false}
                    type={"button"}
                    handleButtonClick={() => this.handleAddSchoolCalendarData()}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
        {openAddAnotherHoliday && (
          <AddAnotherHolidayPopupWeb
            open={openAddAnotherHoliday}
            curriculum={selectCurriculumTitle}
            schoolYear={selectSchoolYearTitle}
            handleAddHoliday={(data: any) => this.handleAddHoliday(data)}
            handleClose={() => this.handleCloseAddAnotherHoliday()}
            handleUpdateHoliday={(data: any) => {
              this.handleUpdateHoliday(data);
            }}
            isEdit={isEditHoliday}
            holidayDetails={selectedHolidayData}
          />
        )}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addCurriculumBtn = {
  width: "120px",
  height: "50px",
  borderRadius: "12px",
  padding: "15px 36px",
  fontWeight: 500,
  fontSize: "16px",
} as const;

const cancelCurriculumBtn = {
  width: "120px",
  height: "50px",
  borderRadius: "12px",
  marginRight: "15px",
  padding: "15px 36px",
  fontWeight: 500,
  fontSize: "16px",
} as const;

const addAnotherHolidayBtn = {
  width: "max-content",
  height: "50px",
  borderRadius: "12px",
  marginRight: "15px",
  padding: "15px 20px",
  fontWeight: 600,
  fontSize: "18px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewSchoolCalendarDialog);
// Customizable Area End
