// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  myPathItemList: any;
  myPathCourseCategory: string;
  handlePaginationChange?: (event: any, page: number) => void;
  paginationDueList?: any
  handleChangeDropdownValue?: (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => void;
  renderDueDateComponent?: (selected: any) => string | undefined;
  selectedSortByDueDateDDValue?: any;
  renderGradingComponent?: (selected: any) => any;
  selectedGradingComponentDDValue?: any;
  handleSearchMyPathCourses?: () => void
  handleChangeDateFilter?: (filterText: string) => void | undefined;
  selectedDateFilterText?:string
  selectedSortByCompletionDateDDValue?: any
  selectedSortByGradeDDValue?:any
  handleSearchMyPathInReview?: () => void;
  handleChangeDateReviewFilter?: (filterText: string) => void
  selectedDateReviewFilterText?: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  dueDateSortingList: Array<IDropdownItems>;
  completionSortingDateList: Array<IDropdownItems>;
  gradingComponent: Array<any>;
  gradingComponentDDMenuItems: Array<IDropdownItems>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class MyPathOverdueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      dueDateSortingList: [
        { id: "asc", value: "asc", label: "Ascending" },
        { id: "desc", value: "desc", label: "Descending" },
      ],
      completionSortingDateList: [
        { id: "asc", value: "asc", label: "Ascending" },
        { id: "desc", value: "desc", label: "Descending" },
      ],
      gradingComponent: [
        {
          id: 1,
          label: "All",
          value: "all",
        },
        {
          id: 2,
          label: "Quiz",
          value: "quiz",
        },
        {
          id: 3,
          label: "Project",
          value: "project",
        },
        {
          id: 4,
          label: "Exam",
          value: "exam",
        },
        {
          id: 5,
          label: "Assignment",
          value: "assignment",
        },
      ],
      gradingComponentDDMenuItems: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
