// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import CatchMeUpModalController, {
  Props,
} from "./CatchMeUpModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { ShiftLessonSuccessModal } from "./ShiftLessonSuccessModal.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    quizViewAnswerConfirmDialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        width: "1000px",
        height: "523px",
        overflowY: "hidden",
        overflowX: "hidden",
      },
    },
    customDropdown: {
      width: "100%",
      height: "50px",
      padding: "17px 15px",
      borderRadius: "12px",
      border: "1px solid #888",
      background: "#FFF",
      color: "#888",
      fontSize: "14px",
      fontWeight: 400,
      marginTop: "10px",
    },
  });
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "880px",
      },
      paperWidthSm: {
        maxWidth: "880px",
      },
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "50.1%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiSvgIcon: {
      root: {
        marginRight: "10px",
      },
    },
  },
});
const dropDownTheme = createTheme({
  overrides: {
    MuiSelect: {
      icon: {
        right: "10px",
        color:'#888888'
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class ShiftLessonsModal extends CatchMeUpModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.schoolDayGetApi = this.schoolDayGetApi.bind(this)
  }
  async componentDidMount(){
    await this.schoolDayGetApi(this.props.selectedClassComponentDDValue)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        {!this.state.openSuccessDialog && <Dialog
          className={`${classes.highlightBgColor}`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
        >
          <Box
            padding={"21px 30px 30px 30px"}
            className={`${classes.bgColor} `}
          >
            <Grid direction="row" container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  Shift Lessons
                </Typography>
                <IconButton
                  data-test-id="cross"
                  style={{ float: "right" }}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent
              style={{ marginTop: "24px", overflow: "hidden", padding: "0px" }}
            >
              <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      component={"span"}
                      style={{ marginBottom: "10px" }}
                    >
                      {`No. of lessons per day`}
                    </Typography>
                    <ThemeProvider theme={dropDownTheme}>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="select-dropdown-assessment-grading-component-mobiles"
                        fieldValue={this.state.selectedGradingComponentDDValue}
                        fieldName="selectedGradingComponentDDValue"
                        handleChange={this.handleShiftLessonDropdownValues}
                        renderValue={this.renderShiftLessonComponenttDDValues}
                        fieldError={false}
                        fieldMenuItems={this.state.gradingComponent}
                        fieldStyles={dropdownStudent}
                        iconComponent={
                          <DropdownIcon
                            stroke={"#888888"}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            fill={"none"}
                          />
                        }
                      />
                    </Box>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      component={"span"}
                      style={{ marginBottom: "10px" }}
                    >
                      {`Start Date`}
                    </Typography>
                    <div className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          selected={this.state.selectShiftLessonsStartDate}
                          onChange={this.selectStartDate}
                          popperClassName="example-datepicker-class"
                          popperPlacement="auto"
                          disabled
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      component={"span"}
                      style={{ marginBottom: "10px" }}
                    >
                      {`End Date`}
                    </Typography>
                    <div className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          selected={this.state.selectShiftLessonsEndDate}
                          onChange={this.selectEndDate}
                          dayClassName={() => "example-datepicker-day-class"}
                          popperClassName="example-datepicker-class"
                          popperPlacement="auto"
                          disabled
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Box
                  width={"100%"}
                  mt={2}
                  pl={"12px"}
                  display={"flex"}
                  alignItems={"center"}
                  borderRadius={"10px"}
                  style={{ backgroundColor: "#f2faff", height: "50px" }}
                >
                  <Typography
                    className={`${classes.fontText14}`}
                    component={"span"}
                    style={{ color: "#009DFF" }}
                  >
                    {`Projected End Date: ${moment(this.state.selectShiftLessonsEndDate).format("MMM D, YYYY")}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box marginBottom={"10px"} style={{ width: "100%" }}>
                  <CustomButton
                    btnText="Save"
                    btnStyle={shiftLessonNextBtn}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"contained"}
                    data-testId="shift-lesson-button"
                    type={"button"}
                    handleButtonClick={this.handleShiftLesson}
                    isDisabled={false}
                  />
                  <CustomButton
                    btnText="Cancel"
                    btnStyle={shiftLessonCancelBtn}
                    buttonId={`my-assessment-search-mobile`}
                    buttonVariant={"outlined"}
                    type={"button"}
                    handleButtonClick={this.props.onCancel}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </DialogContent>
          </Box>
         
        </Dialog>
        }
        <ShiftLessonSuccessModal
            navigation={this.props.navigation}
            onCancel={this.handleCloseSuccessDialog}
            onConfirm={this.props.onCancel}
            open={this.state.openSuccessDialog}
            selectedClassName={this.props.selectedClassName}
            classes={this.props.classes}
          />
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
const shiftLessonCancelBtn = {
  width: "120px",
  marginLeft: "15px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  padding: "10px 10px",
  float: "right",
} as const;

const shiftLessonNextBtn = {
  width: "120px",
  textAlign: "center",
  height: "50px",
  marginLeft: "15px",
  fontSize: "18px",
  borderRadius: "12px",
  marginTop: "32px",
  padding: "10px 10px",
  fontWeight: 500,
  float: "right",
} as const;

const dropdownStudent = {
  width: "400px",
  borderRadius: "8px",
  height: "48px",
  fontSize: "16px",
  color: "#888888",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ShiftLessonsModal);
// Customizable Area End
