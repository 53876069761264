// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// import {
//   reportsListMock,
// } from "../assets/tenantDashboardMock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  title:string;
  tableColumnHead:any
  tableBodyData:any
  emptyTableText:string
  showMenuIcon?:any
    actionButtonConfig?:any
    switchChecked?:boolean
    addButton?:boolean
    addButtonClick?:()=>void
    checked?:boolean;
    handleSwitchChange?: (event: { target: { checked: any; }; }) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  checked:boolean
  tableBodyData:any
  assessmentStudentsColumns:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}

// Customizable Area Start
export default class GradeBookCommonTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTenantStatisticsInfo: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
    
      checked:false,
      tableBodyData:[],
      assessmentStudentsColumns:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.reportsListTab();
    // Customizable Area End
  }

  // Customizable Area Start
  reportsListTab = () => {
    console.log("tenant dashboard reports tab");
  };

  downloadReport = (url: any) => {
    console.log("url", url);
  };
  handleSwitchChange = (event: { target: { checked: any; }; }) => {
    this.setState({checked:event.target.checked})
  };
  // Customizable Area End
}
// Customizable Area End
