// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
    id: any;
    label: string;
    value: any;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedSubjectValue: string;
    subjectDDMenuItems: any;
    selectedGradeValue: string;
    openCatalogCourses: boolean;
    selectedCatalogName: string;
    selectedTeacherValue: string;
    selectedSchoolYearValue: string;
    selectedUtilizationOfCourseValue: string;
    selectedCurriculumValue: string;
    selectedCustomFieldValue: string;
    openAssignCourseModal: boolean;
    menuAnchorEl: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class CatalogTabController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.state = {
            loading: false,
            selectedSubjectValue: "",
            subjectDDMenuItems: [
                {
                    id: 0,
                    value: "English",
                    label: "English",
                },
                {
                    id: 3,
                    value: "History",
                    label: "History",
                },
                {
                    id: 1,
                    value: "Physics",
                    label: "Physics",
                },
                {
                    id: 2,
                    value: "Chemistry",
                    label: "Chemistry",
                },
            ],
            selectedGradeValue: "",
            openCatalogCourses: false,
            selectedCatalogName: "",
            selectedTeacherValue: "",
            selectedSchoolYearValue: "",
            selectedUtilizationOfCourseValue: "",
            selectedCurriculumValue: "",
            selectedCustomFieldValue: "",
            openAssignCourseModal: false,
            menuAnchorEl: null,
        };
        // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await super.componentDidMount();
    }

    renderSubjectDDValue = (selected: any) => {
        if (!selected || selected.length === 0) {
            return "Select";
        }
        const { subjectDDMenuItems } = this.state;
        const selctedSubject = subjectDDMenuItems.find((subject: any) => subject?.id === selected);
        return selctedSubject?.label;
    };

    renderDropdownValue = (selected: any, dropdownName: string, dropdownItems: any) => {
        if (!selected || selected.length === 0) {
            return `Select ${dropdownName}`;
        }
        const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
        return selctedOption?.label;
    };

    getDropdownItemsArray = (dropdownName: string) => {
        const refArray = [1, 2, 3, 4, 5];
        let dropdownItems: Array<any> = [];
        refArray.forEach(item => {
            dropdownItems.push({
                id: item,
                label: `${dropdownName}${item}`,
                value: `${dropdownName}${item}`,
            });
        });
        return dropdownItems;
    }

    handleSearchAssessment = () => {
        console.log("search");
    };

    handleChangeDropdownValue = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };

    checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
        if (!selected || selected.length === 0) {
            return `Select ${dropdownName}`;
        }
        const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
        return selctedOption?.label;
    }

    renderSubjectDropdownValue = (selected: any) => {
        const dropdownName = "Subject";
        const dropdownItems = this.getDropdownItemsArray('Subject');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderTeacherDropdownValue = (selected: any) => {
        const dropdownName = "Teacher";
        const dropdownItems = this.getDropdownItemsArray('Teacher');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderGradeDropdownValue = (selected: any) => {
        const dropdownName = "Grade";
        const dropdownItems = this.getDropdownItemsArray('Grade');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    handleOpenAssignCourseModal = () => {
        this.setState({ openAssignCourseModal: true });
        this.setState({ menuAnchorEl: null })
    }

    renderSchoolYearDropdownValue = (selected: any) => {
        const dropdownName = "School Year";
        const dropdownItems = this.getDropdownItemsArray('202');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    toggleCatalogCourses = (data: any) => {
        this.setState({ selectedCatalogName: data });
        this.setState({ openCatalogCourses: !this.state.openCatalogCourses });
    }

    renderUtilizationDropdownValue = (selected: any) => {
        const dropdownName = "Course Utilization";
        const dropdownItems = this.getDropdownItemsArray('Course Utilization');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    handleCloseAssignCourseModal = () => {
        this.setState({ openAssignCourseModal: false });
    }

    handleActionClick = (e: any, rowItemId: any) => {
        this.setState({ menuAnchorEl: e?.currentTarget })
    }

    handleMenuClose = () => {
        this.setState({ menuAnchorEl: null })
    }
    // Customizable Area End
}
// Customizable Area End
