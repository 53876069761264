// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core';
import AssignCourseConfirmDialogController, {
    Props
} from "./AssignCourseConfirmDialogController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../../components/src/CustomButton.web';
import { combineStyles } from '../../../../components/src/CombineStyles.web';
import { ConfirmIcon } from '../assets';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        quizViewAnswerConfirmDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            }
        },
        messageQuestion: {
            backgroundColor: "#3DBB3D",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "65px",
            height: "65px"
        },
        messageQuestionIcon: {
            width: "42px",
            height: "42px"
        }
    })

// Customizable Area End

// Customizable Area Start
export class AssignCourseConfirmDialog extends AssignCourseConfirmDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, courseName, classTitle } = this.props;
        return (
            <Dialog className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open}>
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Box display={"flex"} justifyContent={"center"} id="quiz-view-answer-confirm-dialog-title">
                        <Box>
                            <img src={ConfirmIcon} alt="success icon" />
                        </Box>
                    </Box>
                    <DialogContent style={{ padding: "0px" }}>
                        <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}>
                                Assign Course Confirmation
                            </Typography>
                        </Box>
                        <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                Are you sure want to assign <b>{courseName}</b>
                            </Typography>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                course to <b>{classTitle}</b>?
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <CustomButton btnStyle={cancelButton} btnText='Cancel' buttonId='quiz-view-answer-cancel-btn' buttonVariant={"outlined"} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
                            &nbsp;
                            <CustomButton btnStyle={confirmButton} btnText='Yes, Assign' buttonId='quiz-view-answer-confirm-btn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={this.props.onConfirm} />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const confirmButton = {
    height: "50px",
    width: "214px",
    padding: "5px",
    borderRadius: "10px",
    fontWeight: 600,
    fontSize: "16px",
} as const;

const cancelButton = {
    height: "50px",
    width: "214px",
    padding: "5px",
    border: "1px solid",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssignCourseConfirmDialog);
// Customizable Area End
