// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const PrinterIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill} fillRule="evenodd">
                <g transform="translate(-833.000000, -1204.000000)" fill={props.fill} fillRule="nonzero">
                    <g transform="translate(340.000000, 1197.000000)">
                        <g id="printer" transform="translate(493.000000, 7.000000)">
                            <path d="M18.6427734,5.3125 L17.4953906,5.3125 C17.5021094,5.22679688 17.5000391,5.54878906 17.5000391,1.35722656 C17.5,0.608828125 16.8911719,0 16.1427734,0 L3.85722656,0 C3.10882813,0 2.5,0.608828125 2.5,1.35722656 C2.5,5.53976562 2.49785156,5.22617188 2.50464844,5.3125 L1.35722656,5.3125 C0.608828125,5.3125 2.22044605e-15,5.92132813 2.22044605e-15,6.66972656 L2.22044605e-15,13.9552734 C2.22044605e-15,14.7036719 0.608828125,15.3125 1.35722656,15.3125 L4.6875,15.3125 L4.6875,18.6427734 C4.6875,19.3911719 5.29632813,20 6.04472656,20 L13.9552734,20 C14.7036719,20 15.3125,19.3911719 15.3125,18.6427734 L15.3125,15.3125 L18.6427734,15.3125 C19.3911719,15.3125 20,14.7036719 20,13.9552734 L20,6.66972656 C20,5.92132813 19.3911719,5.3125 18.6427734,5.3125 Z M3.75,5.20527344 L3.75,1.35722656 C3.75,1.29808594 3.79808594,1.25 3.85722656,1.25 L16.1427734,1.25 C16.2019141,1.25 16.25,1.29808594 16.25,1.35722656 L16.25,5.20527344 C16.25,5.26441406 16.2019141,5.3125 16.1427734,5.3125 L3.85722656,5.3125 C3.79808594,5.3125 3.75,5.26441406 3.75,5.20527344 Z M14.0625,18.6427734 C14.0625,18.7019141 14.0144141,18.75 13.9552734,18.75 L6.04472656,18.75 C5.98558594,18.75 5.9375,18.7019141 5.9375,18.6427734 L5.9375,12.2947266 C5.9375,12.2355859 5.98558594,12.1875 6.04472656,12.1875 L13.9552734,12.1875 C14.0144141,12.1875 14.0625,12.2355859 14.0625,12.2947266 L14.0625,18.6427734 Z M18.75,13.9552734 C18.75,14.0144141 18.7019141,14.0625 18.6427734,14.0625 L15.3125,14.0625 L15.3125,12.2947266 C15.3125,11.5463281 14.7036719,10.9375 13.9552734,10.9375 L6.04472656,10.9375 C5.29632812,10.9375 4.6875,11.5463281 4.6875,12.2947266 L4.6875,14.0625 L1.35722656,14.0625 C1.29808594,14.0625 1.25,14.0144141 1.25,13.9552734 L1.25,6.66972656 C1.25,6.61058594 1.29808594,6.5625 1.35722656,6.5625 L18.6427734,6.5625 C18.7019141,6.5625 18.75,6.61058594 18.75,6.66972656 L18.75,13.9552734 Z" id="Shape"></path>
                            <path d="M16.875,8.75 L15.9375,8.75 C15.5923437,8.75 15.3125,8.47015625 15.3125,8.125 C15.3125,7.77984375 15.5923437,7.5 15.9375,7.5 L16.875,7.5 C17.2201563,7.5 17.5,7.77984375 17.5,8.125 C17.5,8.47015625 17.2201563,8.75 16.875,8.75 Z"></path>
                            <path d="M11.875,14.6875 L8.125,14.6875 C7.77984375,14.6875 7.5,14.4076563 7.5,14.0625 C7.5,13.7173437 7.77984375,13.4375 8.125,13.4375 L11.875,13.4375 C12.2201563,13.4375 12.5,13.7173437 12.5,14.0625 C12.5,14.4076563 12.2201563,14.6875 11.875,14.6875 Z"></path>
                            <path d="M11.875,17.5 L8.125,17.5 C7.77984375,17.5 7.5,17.2201563 7.5,16.875 C7.5,16.5298437 7.77984375,16.25 8.125,16.25 L11.875,16.25 C12.2201563,16.25 12.5,16.5298437 12.5,16.875 C12.5,17.2201563 12.2201563,17.5 11.875,17.5 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default PrinterIcon;
// Customizable Area End