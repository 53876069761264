// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  activityData: Array<any>;
  getCopiedActivityId: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedRowId: number;
  selectedActivity: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class SearchExistingActivityModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSearchActivityId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      selectedRowId: -1,
      selectedActivity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
  }

  handleSelectRow = (tableRowId: number) => {
    if (tableRowId) {
      this.setState({ selectedRowId: tableRowId });
    } else {
      this.setState({ selectedRowId: -1 });
    }
  }

  handleSearchActivityDetailsApi = async () => {
    showCustomLoader();
    const baseUrl = `searchActivityDetails`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSearchActivityId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSearchActivityDetailsResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ selectedActivity: responseJson });
    console.log("search activity errorResponse", errorResponse)
  }

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiSearchActivityId) {
          this.handleSearchActivityDetailsResponse(responseJson, errorResponse)
        }
      }
    }
  }

  handleCopyActivity = () => {
    const { selectedRowId } = this.state;
    if (selectedRowId != -1) {
      this.props.getCopiedActivityId(selectedRowId);
      this.props.onCancel();
    } else {
      toast.warning("Please select an activity first.");
    }
  }

  resetSelectedRow = () => {
    this.setState({ selectedRowId: -1 });
  }

  resetSelectedActivity = () => {
    this.setState({ selectedActivity: null });
  }

  resetActivityData = () => {
    this.setState({ selectedRowId: 0, selectedActivity: null });
  }

  // Customizable Area End
}
// Customizable Area End