// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  selectCurriculum: string;
  selectCurriculumError: boolean;
  selectCurriculumErrorMsg: string;
  selectGrade: string;
  selectGradeError: boolean;
  selectGradeErrorMsg: string;
  selectSubjects: Array<any>;
  selectSubjectError: boolean;
  selectSubjectErrorMsg: string;
  selectClass: string;
  selectClassError: boolean;
  selectClassErrorMsg: string;
  selectNoOfStudents: string;
  selectNoOfStudentsError: boolean;
  selectNoOfStudentsErrorMsg: string;
  renderGrade: any;
  renderClasses: any;
  renderCurriculum: any;
  handleChangeDDValue: any;
  handleChangeNoOfStudents: any;
  handleChangeSubjects: any;
  isLoaderOn: boolean;
  gradesList: any;
  subjectsList: any;
  curriculumList: any;
  classesList: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherAssignmentBasicInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
