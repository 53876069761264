// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherAssignmentBasicInformationController, {
  Props,
} from "./TeacherAssignmentBasicInformationController.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomMultiSelectDropDownWeb from "../../../../components/src/CustomMultiSelectDropDown.web";
import CustomTextFieldWeb from "../../../../components/src/CustomTextField.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    inputLabel: {
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2.5),
      fontSize: "16px",
      fontWeight: 400,
      color: "#888888",
    },
    searchBox: {
      padding: "0px 18px 0px 30px",
    },
    requiredField: {
      color: "red",
    },
  });
// Customizable Area End

// Customizable Area Start
export class TeacherAssignmentBasicInformation extends TeacherAssignmentBasicInformationController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      selectGrade,
      selectGradeError,
      selectCurriculum,
      selectCurriculumError,
      selectSubjects,
      selectSubjectError,
      selectSubjectErrorMsg,
      selectClass,
      selectClassError,
      selectNoOfStudents,
      selectNoOfStudentsError,
      classesList,
      curriculumList,
      gradesList,
      isLoaderOn,
      subjectsList,
      selectClassErrorMsg,
      selectCurriculumErrorMsg,
      selectGradeErrorMsg,
      selectNoOfStudentsErrorMsg,
    } = this.props;
    return (
      <Box>
        <Grid container className={`${classes.searchBox}`}>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabel}>
                Grade
                <Typography
                  component={"span"}
                  className={classes.requiredField}
                >
                  *
                </Typography>
              </Typography>
              <CustomSelectDropdown
                fieldId="teacher-assignment-basic-info-select-dropdown-grade"
                fieldValue={selectGrade}
                fieldName="selectedGrade"
                handleChange={this.props.handleChangeDDValue}
                renderValue={this.props.renderGrade}
                fieldError={selectGradeError}
                fieldMenuItems={gradesList}
                fieldStyles={{ width: "96%" }}
                helperText={selectGradeErrorMsg}
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabel}>
                Subject
                <Typography
                  component={"span"}
                  className={classes.requiredField}
                >
                  *
                </Typography>
              </Typography>
              <CustomMultiSelectDropDownWeb
                label="Select Subjects"
                selectedItems={selectSubjects}
                handleChange={this.props.handleChangeSubjects}
                fieldError={selectSubjectError}
                fieldMenuItems={subjectsList}
                disabledItemSelection={isLoaderOn}
                helperText={selectSubjectErrorMsg}
                fieldStyles={{ width: "96%" }}
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabel}>
                Curriculum
                <Typography
                  component={"span"}
                  className={classes.requiredField}
                >
                  *
                </Typography>
              </Typography>
              <CustomSelectDropdown
                fieldId="teacher-assignment-basic-info-select-dropdown-curriculum"
                fieldValue={selectCurriculum}
                fieldName="selectedCurriculum"
                handleChange={this.props.handleChangeDDValue}
                renderValue={this.props.renderCurriculum}
                fieldError={selectCurriculumError}
                fieldMenuItems={curriculumList}
                fieldStyles={{ width: "96%" }}
                helperText={selectCurriculumErrorMsg}
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabel}>
                Class
                <Typography
                  component={"span"}
                  className={classes.requiredField}
                >
                  *
                </Typography>
              </Typography>
              <CustomSelectDropdown
                fieldId="teacher-assignment-basic-info-select-dropdown-class"
                fieldValue={selectClass}
                fieldName="selectedClass"
                handleChange={this.props.handleChangeDDValue}
                renderValue={this.props.renderClasses}
                fieldError={selectClassError}
                fieldMenuItems={classesList}
                helperText={selectClassErrorMsg}
                fieldStyles={{ width: "96%" }}
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabel}>
                No. of Students per class
                <Typography
                  component={"span"}
                  className={classes.requiredField}
                >
                  *
                </Typography>
              </Typography>
              <CustomTextFieldWeb
                fieldId="teacher-assignment-basic-info-no-of-students"
                fieldName="noOfStudents"
                fieldType="number"
                fieldWidth="100%"
                textFieldStyles={{
                  padding: "10px 0",
                  width: "96%",
                  maxWidth: "100%",
                }}
                fieldError={selectNoOfStudentsError}
                handleChange={this.props.handleChangeNoOfStudents}
                helperText={selectNoOfStudentsErrorMsg}
                fieldValue={selectNoOfStudents}
                placeHolderText="Enter No. of Students"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherAssignmentBasicInformation);
// Customizable Area End
