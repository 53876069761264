// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ViewParentTeacherProfileDetailsController, {
  Props,
} from "./ViewParentTeacherProfileDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    teacherInfoBox: {
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    profileInfoGrid: {
      padding: "15px 18px 15px 18px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    userName: {
      padding: "15px 18px 5px 18px",
    },
    leftMargin: {
      marginLeft: "8px",
    },
    detailGridItem: {
      padding: "8px 0px 8px 0px",
    },
    subjectLogoBg: {
      width: "55px",
      display: "flex",
      alignItems: "center",
      height: "55px",
      borderRadius: "50%",
      justifyContent: "center",
      padding: "2px",
    },
    emptyProfileImg: {
      width: "inherit",
      height: "inherit",
      color: "#ccc",
    },
    profilePicImg: {
      height: "55px",
      width: "55px",
      objectFit: "contain",
    },
    card: {
      borderRadius: "10px",
      padding: "15px 22px",
      width: "84%",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        width: "85% !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },
    overflowText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  });
// Customizable Area End

// Customizable Area Start
export class ViewParentTeacherProfileDetails extends ViewParentTeacherProfileDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      teacherFullName,
      teacherRole,
      parentTeacherDetails,
      subjectHandledList,
      assignedClassesList,
      coursesList,
      curriculumList,
      gradeLevelList,
      assignedStudentsList,
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                User Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
              >
                {` / ${teacherRole}`}
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
              >
                {` / ${teacherFullName}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box>
              <Typography
                className={`${classes.fontText34} ${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600}`}
                component={"span"}
              >
                {teacherFullName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.bgColor} ${classes.teacherInfoBox}`}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box
                        className={`${classes.userName}`}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box className={`${classes.subjectLogoBg}`}>
                          

                      {parentTeacherDetails?.profilePic ? (
                            <CustomImageComponentWeb
                              altText="teacher-profile"
                              src={parentTeacherDetails?.profilePic}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%"
                              }}
                              isUserProfileImg
                            />
                          ) : (
                            <AccountCircleIcon className={`${classes.emptyProfileImg}`} />
                          )}

                        </Box>
                        <Typography
                          component={"span"}
                          className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText24}`}
                        >
                          {teacherFullName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        className={`${classes.profileInfoGrid}`}
                        direction="row"
                      >
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box
                            className={`${classes.overflowText}`}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography
                              component={"span"}
                            
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Email ID:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18} ${classes.overflowText}`}
                            >
                              {parentTeacherDetails?.emailId}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          md={6}
                          xs={12}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Role:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {teacherRole}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Status:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {parentTeacherDetails?.status}
                            </Typography>
                          </Box>
                        </Grid>
                        {parentTeacherDetails.customFields.map((item: any) => (
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-customField1-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          key={item.id}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-customField1"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              {item?.label}:{" "}
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                             {item?.value || "NA"}
                            </Typography>
                          </Box>
                        </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {(teacherRole === "parent teacher" && assignedStudentsList.length>0 )&& (
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Box paddingTop={"20px"} paddingBottom={"10px"}>
                    <Typography
                      className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                    >
                      Students
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {assignedStudentsList?.map((student: any) => (
                      <Grid
                        key={`${student?.fullName}-${student?.studentId}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Box
                          className={`${classes.card} ${classes.bgColor}`}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box
                            mr={"10px"}
                            className={`${classes.subjectLogoBg}`}
                          >
                            {student?.profilePic ? (
                              <CustomImageComponentWeb
                                altText="teacher-profile"
                                src={student?.profilePic}
                                imgStyles={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                  borderRadius: "50%"
                                }}
                                isUserProfileImg
                              />
                            ) : (
                              <AccountCircleIcon className={`${classes.emptyProfileImg}`} />
                            )}
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                              component="span"
                            >
                              {student.fullName}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {curriculumList.length>0 &&
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Box paddingTop={"35px"} paddingBottom={"10px"}>
                    <Typography
                      className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                    >
                      Curriculum
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {curriculumList?.map((curriculumItem: any) => (
                      <Grid
                        key={`${curriculumItem.name}-${curriculumItem?.id}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Box
                          className={`${classes.card} ${classes.bgColor}`}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                              component="span"
                            >
                              {curriculumItem.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          {gradeLevelList.length>0 &&
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Box paddingTop={"20px"} paddingBottom={"10px"}>
                    <Typography
                      className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                    >
                      Grades Handled
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {gradeLevelList?.map((gradeItem: any) => (
                      <Grid
                        key={`${gradeItem.name}-${gradeItem?.id}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Box
                          className={`${classes.card} ${classes.bgColor}`}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                              component="span"
                            >
                              {gradeItem.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          {subjectHandledList.length>0 &&
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Box paddingTop={"20px"} paddingBottom={"10px"}>
                    <Typography
                      className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                    >
                      Subjects Handled
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {subjectHandledList?.map((subjectItem: any) => (
                      <Grid
                        key={`${subjectItem.subjectName}-${subjectItem?.id}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <Box
                          className={`${classes.card} ${classes.bgColor}`}
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box
                            mr={"10px"}
                            bgcolor={subjectItem?.subjectBgColor}
                            className={classes.subjectLogoBg}
                          >
                              <CustomImageComponentWeb
                              altText="Subject Logo"
                              src={subjectItem?.subjectIcon}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                              component="span"
                            >
                              {subjectItem.subjectName}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          {assignedClassesList.length>0 &&
          <Grid item xs={12}>
            <Grid container direction={"row"}>
              <Grid item xs={12}>
                <Box paddingTop={"20px"} paddingBottom={"10px"}>
                  <Typography
                    className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                  >
                    Classes Assigned
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {assignedClassesList?.map((assignedClass: any) => (
                    <Grid
                      key={`${assignedClass.name}-${assignedClass?.id}`}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <Box
                        className={`${classes.card} ${classes.bgColor}`}
                        display={"flex"}
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                            component="span"
                          >
                            {assignedClass.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
          {coursesList.length>0 &&
          <Grid item xs={12}>
            <Grid container direction={"row"}>
              <Grid item xs={12}>
                <Box paddingTop={"20px"} paddingBottom={"10px"}>
                  <Typography
                    className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                  >
                    Courses Assigned
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {coursesList?.map((courseItem: any) => (
                    <Grid
                      key={`${courseItem?.subjectName}-${courseItem?.coursesId}`}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <Box
                        className={`${classes.card} ${classes.bgColor}`}
                        display={"flex"}
                        alignItems="center"
                      >
                        <Box
                          mr={"10px"}
                          bgcolor={courseItem?.subjectBgColor}
                          className={classes.subjectLogoBg}
                        >
                              <CustomImageComponentWeb
                              altText="Subject Logo"
                              src={courseItem?.subjectIcon}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                        </Box>
                        <Box>
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                            component="span"
                          >
                            {courseItem.subjectName}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ViewParentTeacherProfileDetails);
// Customizable Area End
