// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Button, Tooltip } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AssignmentActivityComponentController, {
  Props,
} from "./AssignmentActivityComponentController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { infoIcon, redPenIcon, redTrashIcon } from "./assets";
import AddObjectiveQuestionModal from "./AddObjectiveQuestionModal.web";
import AddSubjectiveQuestionModal from "./AddSubjectiveQuestionModal.web";
import DeleteConfirmDialogWeb from "./DeleteConfirmDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    activityDetailBox: {
      minWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
    },
    questionCard: {
      border: "1px solid #f1f1f1",
      borderRadius: "6px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        width: "84%",
      },
      marginBottom: "25px",
    },
    pagePadding: {
      padding: "15px",
    },
    aboutText: {
      marginTop: "5px",
    },
    questionContainer: {
      display: "flex",
      marginTop: "20px",
      gap: "20px",
      flexDirection: "column",
    },
    explanation: {
      padding: "15px 20px",
      borderBottom: "1px solid #f1f1f1",
      borderTop: "1px solid #f1f1f1",
    },
    examTitle: {
      textTransform: "capitalize",
      gap: "7px",
      display: "flex",
      alignItems: "center",
    },
    questionDeleteBtn: {
      height: "32px",
      minWidth: "32px",
      border: "1px solid #ED1111",
      borderRadius: "6px",
      maxWidth: "32px",
    },
    bottomBorder: {
      borderBottom: "1px solid #f1f1f1",
    },
    questionEditBtn: {
      height: "32px",
      minWidth: "32px",
      maxWidth: "32px",
      borderRadius: "6px",
      border: "1px solid #0D46BC",
    },
    questionListParentBox: {
      gridGap: "4px",
      margin: "12px 0",
      display: "flex",
      flexDirection: "column",
    },
    writtenCorrectAnswerBox: {
      display: "flex",
      flexDirection: "column",
    },
    marksParentBox: {
      display: "flex",
      padding: "20px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    footerButtonsBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "5px",
      marginBottom: "25px",
    },
  });

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#EEF8FF",
  },
  tooltip: {
    backgroundColor: '#EEF8FF',
    color: '#009DFF',
    minWidth: '200px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #EEF8FF',
    borderRadius: '10px',
    padding: "9 12 9 12",
    textTransform: 'capitalize',
  },
}))(Tooltip);
// Customizable Area End

// Customizable Area Start
export class AssignmentActivityComponent extends AssignmentActivityComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  toolTipBox() {
    const { detail, classes } = this.props;
    return (
      <Box>
        <Typography className={classes.fontText12}> Assessment Type: <b>{detail?.gradingComponent}</b></Typography>
        <Typography className={classes.fontText12}> Evaluation Type: <b>{detail?.evaluationType}</b></Typography>
        {detail?.objectiveType && <Typography className={classes.fontText12}>Objective Type: <b>{detail?.objectiveType}</b></Typography>}
        {detail?.maxScore && <Typography className={classes.fontText12}> Max Score: <b>{detail?.maxScore}</b></Typography>}
        {detail?.duration && <Typography className={classes.fontText12}> Duration: <b>{detail?.duration} mins</b></Typography>}
        {detail?.showCorrectAnswer && <Typography className={classes.fontText12}> Show Answers to Students: <b>{detail?.showCorrectAnswer ? 'Yes' : 'No'}</b></Typography>}
      </Box>
    );
  }
  myCourse = (isMyCoursesPath:boolean,btnStyle:object , btnOnClick:() => void, testId:string) => {    
    return !isMyCoursesPath ?
     (
       <CustomButton
         btnText="Add Another Question"
         btnStyle={btnStyle}
         buttonId={testId}
         buttonVariant={"outlined"}
         isDisabled={false}
         type={"button"}
         handleButtonClick={btnOnClick}
       />)
       : null
   }
   getImage = (isMyCoursesPath:boolean , redpenFun:(dataId:number|string) => void , redTraFun:(dataId:number|string) => void , dataId:number|string , testEdit:string ,testDelete:string) => {
    return  !isMyCoursesPath && (
        <>
        <img src={redPenIcon} style={{border:"1px solid blue", borderRadius:"5px", padding:"7px" }} alt='redpen' 
       data-test-id={testEdit} onClick={() => redpenFun(dataId)}
      />                         
      <img src={redTrashIcon} alt="" style={{border:"1px solid red", borderRadius:"5px",padding:"7px"}} data-test-id={testDelete}  onClick={() =>redTraFun(dataId)}/>
     </>)
    }
  renderInfoTooltip = (isStudent:boolean) =>{
    return (
      !isStudent && <HtmlTooltip
        placement="right-start"
        title={
          <React.Fragment>
            {this.toolTipBox()}
          </React.Fragment>
        }
      >
        <img src={infoIcon} alt="info" />
      </HtmlTooltip>
    )
  }
  renderObjectiveComponentUI =(classes:any,questionList:any,detail:any,isFromRegistrar:any)=>{
    return (
      <Box className={`${classes.questionContainer}`}>
      {
        questionList && questionList.length > 0 ?
          <Box>
            {questionList?.map((assignmentQuestionObj: any, index:any) => (
              <Box key={assignmentQuestionObj?.id} className={`${classes.questionCard}`}>
                <Box padding={"20px 20px 0 20px"}>
                  <Box>
                    <Typography className={`${classes.textCommonColor}`}>
                      Question {index + 1}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                      dangerouslySetInnerHTML={{
                        __html: assignmentQuestionObj?.question,
                      }}
                    />
                  </Box>
                  {detail.objectiveType === "selection" && (
                    <Box className={classes.questionListParentBox}>
                      {assignmentQuestionObj.options.map((option: any) => (
                        <Box
                          display={"flex"}
                          key={option?.id}
                          className={
                            option?.inputValue ===
                              assignmentQuestionObj?.correctAnswer
                              ? `${classes.primaryColor4}`
                              : `${classes.textCommonColor}`
                          }
                        >
                          <Box marginRight={"5px"}>
                            <Typography className={`${classes.fontBold600}`}> {option?.optionLetter}: </Typography>
                          </Box>
                          <Box>
                            <Typography className={`${classes.fontBold500}`}>{option?.inputValue} </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}

                  {detail.objectiveType === "written" && (
                    <Box className={classes.questionListParentBox}>
                      {
                        <Box
                          className={`${classes.textCommonColor} ${classes.writtenCorrectAnswerBox}`}
                        >
                          <Box marginRight={"5px"}>
                            <Typography className={`${classes.fontBold600}`}>
                              Correct Answer:
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.primaryColor4} ${classes.fontBold500}`}
                            >
                              {assignmentQuestionObj?.correctAnswer}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                  )}
                </Box>

                <Box
                  className={`${classes.textCommonColor} ${classes.explanation}`}
                >
                  <Box>
                    <Typography className={`${classes.fontBold600}`}>
                      Explanation:
                    </Typography>
                  </Box>
                  <Box marginTop={"-2px"}>
                    <Typography
                      data-test-id={`assignment-explanation-${assignmentQuestionObj?.id}`}
                      className={`${classes.fontBold500}`}
                      dangerouslySetInnerHTML={{
                        __html: assignmentQuestionObj?.explanation,
                      }}
                    />
                  </Box>
                </Box>

                <Box className={classes.marksParentBox}>
                  <Box display={"flex"}>
                    <Box marginRight={"5px"} component={"span"}>
                      <Typography className={`${classes.textCommonColor}`}>
                        Marks:
                      </Typography>
                    </Box>
                    <Box
                      data-test-id={`assignment-marks-${assignmentQuestionObj?.id}`}
                      component={"span"}
                    >
                      <Typography className={`${classes.textPrimaryColor}`}>
                        {assignmentQuestionObj?.marks}
                      </Typography>
                    </Box>
                  </Box>
                  {(!isFromRegistrar && this.props?.isActivityWrite )&& <Box display={"flex"} gridGap={"10px"}>
                   {this.getImage(this.state.isMyCoursesPath, (dataId) => this.handleEditObjectiveQuestion(assignmentQuestionObj.id),  (dataId) => this.handleDeleteAssignmentObjectiveQuestion(assignmentQuestionObj.id) , assignmentQuestionObj.id , 'assignment-mcq-question-edit-73' ,'assignment-mcq-question-delete-73')}
                  </Box>}
                </Box>
              </Box>
            ))}
       {(!isFromRegistrar && this.props?.isActivityWrite )&& <Box className={classes.footerButtonsBox}>
        {this.myCourse(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddObjectiveQuestion , 'assignment-add-another-question-button')}
              </Box>
              }
          </Box>
          :
          <CustomEmptyResultComponent
            message="No question added for this Assignment"
            showActionButton={!isFromRegistrar && this.props?.isActivityWrite}
            buttonText="Add Question"
            handleButtonClick={this.handleAddObjectiveQuestion}
          />
      }
    </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, detail, questionList, isStudent, isFromRegistrar } = this.props;

    const {
      openSubjectiveQuestionModal,
      editQuestion,
      openObjectiveQuestionModal,
      selectedQuestionDetail,
      openDeleteQuestionConfirmModal,
    } = this.state;

    return (
      <Box className={classes.activityDetailBox}>
        <Grid direction="column" container className={`${classes.pagePadding}`}>
          <Grid xs={12} className={classes.titleGrid} item>
            <Typography
              component={"span"}
              className={`${classes.fontText20} ${classes.examTitle} ${classes.textPrimaryColor} ${classes.fontBold600}`}
            >
              {`${detail?.activityTitle}`}{" "}
              {this.renderInfoTooltip(isStudent ?? false)}
            </Typography>
          </Grid>

          {detail?.instruction && (
            <Grid xs={12} item className={classes.detailGrid}>
              <Typography
                dangerouslySetInnerHTML={{ __html: detail.instruction }}
                className={`${classes.fontText16} ${classes.textCommonColor} ${classes.fontBold500}`}
                component={"p"}
              ></Typography>
            </Grid>
          )}

          {detail.evaluationType === "objective" && (
            <>
            {this.renderObjectiveComponentUI(classes,questionList,detail,isFromRegistrar)}
            </>
          )}

          {detail.evaluationType === "subjective" && (
            <Box className={`${classes.questionContainer}`}>
              {
                questionList && questionList.length > 0 ?
                  <Box>
                    {questionList?.map((assignmentSubjectiveQuestion: any, index) => (
                      <Box key={assignmentSubjectiveQuestion?.id} className={`${classes.questionCard}`}>
                        <Box p={"20px"} className={classes.bottomBorder}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            <Box>
                              <Typography className={`${classes.textCommonColor}`}>
                                Question {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                              dangerouslySetInnerHTML={{
                                __html: assignmentSubjectiveQuestion?.question,
                              }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.marksParentBox}>
                          <Box display={"flex"}>
                            <Box component={"span"} mr={"5px"}>
                              <Typography className={`${classes.textCommonColor}`}>
                                Marks:
                              </Typography>
                            </Box>
                            <Box component={"span"}>
                              <Typography className={`${classes.textPrimaryColor}`}>
                                {assignmentSubjectiveQuestion?.marks}
                              </Typography>
                            </Box>
                            <Box mr={"5px"} component={"span"} ml={"15px"}>
                              <Typography className={`${classes.textCommonColor}`}>
                                Word Limit:
                              </Typography>
                            </Box>
                            <Box component={"span"}>
                              <Typography className={`${classes.textPrimaryColor}`}>
                                {assignmentSubjectiveQuestion?.wordLimit}
                              </Typography>
                            </Box>
                          </Box>
                          {(!isFromRegistrar && this.props?.isActivityWrite) && <Box display={"flex"} gridGap={"10px"}>
                           {this.getImage(this.state.isMyCoursesPath, (dataId) => this.handleEditSubjectiveQuestion(assignmentSubjectiveQuestion?.id),  (dataId) => this.handleDeleteAssignmentObjectiveQuestion(assignmentSubjectiveQuestion.id) , assignmentSubjectiveQuestion.id , 'subjective-assignment-question-edit-73' ,'subjective-assignment-question-delete-73')}

                          </Box>}
                        
                        </Box>
                      </Box>
                    ))}
                   {(!isFromRegistrar && this.props?.isActivityWrite) && <Box className={classes.footerButtonsBox}>
                      {this.myCourse(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddSubjectiveQuestion , 'add-another-subjective-question-button')}

                      <CustomButton
                        btnText="Save Assignment"
                        btnStyle={addQuestionButton}
                        isDisabled={false}
                        buttonVariant={"contained"}
                        handleButtonClick={this.handleSaveQuiz}
                        buttonId="save-assignment-button"
                        type={"button"}
                      />
                    </Box>}
                  </Box>
                  : 
                  <CustomEmptyResultComponent
                    message="No question added for this Assignment"
                    showActionButton={!isFromRegistrar && this.props?.isActivityWrite}
                    buttonText="Add Question"
                    handleButtonClick={this.handleAddSubjectiveQuestion}
                  />
              }
            </Box>
          )}
        </Grid>

        {openSubjectiveQuestionModal && (
          <AddSubjectiveQuestionModal
            classes={classes}
            open={openSubjectiveQuestionModal}
            isEdit={editQuestion}
            explanation={this.checkObjectiveType()}
            onCancel={this.handleCloseSubjectiveQuestionModal}
            onConfirm={this.handleCloseSubjectiveQuestionModal}
            handleAddSubjectiveQuestion={(data: any) =>
              this.handleAddAssignmentQuestion(data)
            }
            handleUpdateSubjectiveQuestion={(data: any) =>
              this.handleUpdateAssignmentQuestion(data)
            }
            activityId={detail?.activityId}
            selectedSubjectiveQuestionData={selectedQuestionDetail}
            handleDeleteSubjectiveQuestion={() =>
              this.handleDeleteAssignmentObjectiveQuestion(
                selectedQuestionDetail?.id
              )
            }
            forManual={false}
          />
        )}

        {openObjectiveQuestionModal && (
          <AddObjectiveQuestionModal
            classes={classes}
            open={openObjectiveQuestionModal}
            isEdit={editQuestion}
            onCancel={this.handleCloseObjectiveQuestionModal}
            onConfirm={this.handleCloseObjectiveQuestionModal}
            handleAddQuestion={(data: any) =>
              this.handleAddAssignmentQuestion(data)
            }
            handleUpdateQuestion={(data: any) =>
              this.handleUpdateAssignmentQuestion(data)
            }
            activityId={detail?.activityId}
            selectedQuestionData={selectedQuestionDetail}
            handleDeleteQuestion={() =>
              this.handleDeleteAssignmentObjectiveQuestion(
                selectedQuestionDetail?.id
              )
            }
          />
        )}

        <DeleteConfirmDialogWeb
          open={openDeleteQuestionConfirmModal}
          headingTitle="Question"
          onCancel={() => this.handleCloseAssignmentDeleteConfirmModal()}
          itemTitle={"this question"}
          onConfirm={() => this.handleConfirmAssignmentDeleteQuestion()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addQuestionButton = {
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssignmentActivityComponent);
// Customizable Area End
