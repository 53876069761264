// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomDoughnutChart from "../../../components/src/CustomDoughnutChart.web";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import StudentProfileProgressController, {
  Props,
} from "./StudentProfileProgressController.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    StatisticsGraph: {
      width: "80%",
      height: "100%",
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      "& > canvas": {
        display: "block !important",
      },
      [theme.breakpoints.down("lg")]: {
        width: "80%",
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        "& > canvas": {
          display: "block !important",
        },
      },
      [theme.breakpoints.down("md")]: {
        width: "36%",
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        "& > canvas": {
          display: "block !important",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "50%",
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        "& > canvas": {
          display: "block !important",
        },
      },
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    graph: {
      width: "calc(35% - 28px)",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      padding: "10px 0px 10px 0px",
      border: "1px solid rgba(224, 224, 224, 1)",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginBottom: "17px",
      },
    },
    table: {
      width: "65%",
      borderRadius: "10px",
      border: "1px solid rgba(224, 224, 224, 1)",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentProfileProgressStatistics extends StudentProfileProgressController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, courseProgressTable, progressStatisticsForGraph } = this.props;

    const profileProgress = () => {
      return "";
    };

    const profileStatistics = () => {
      return "";
    };

    const profileStatistics2 = () => {
      return "";
    };

    const profileStatistics3 = () => {
      return "";
    };

    return (
      <Box className={classes.container} height={"auto"}>
        <Box
          className={`${classes.bgColor} ${classes.graph}`}
        >
          <Box className={classes.StatisticsGraph}>
            {profileProgress()}
            {profileStatistics()}
            <CustomDoughnutChart
              labels={["Completed", "Remaining", "Not Tackled"]}
              graphData={[
                progressStatisticsForGraph?.statistics?.completed,
                progressStatisticsForGraph?.statistics?.remaining,
                progressStatisticsForGraph?.statistics?.notTackled,
              ]}
              chartColors={["#01C996", "#0D46BC", "#FBD82C"]}
              lableFontSize="13"
              chartPosition="center"
            />
            {profileStatistics2()}
            {profileStatistics3()}
          </Box>
        </Box>
        <Box
          className={`${classes.bgColor} ${classes.table}`}
        >
          <CustomTable
            tableId={"student-progress-table"}
            tableHeaderColumns={this.state.studentProgressColumn}
            tableBodyData={courseProgressTable || []}
            showMenuIcon={false}
            handleAction={this.handleGetStudentprogressDetails}
            navigation={this.props.navigation}
            isHandleCellAction
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProfileProgressStatistics);
// Customizable Area End
