// Customizable Area Start
import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ForgotAccountPasswordController, {
  Props
} from "./ForgotAccountPasswordController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AuthLayout from "../../../components/src/AuthLayout.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    textFieldWrapper: {
      width: "100%",
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
    text: {
      fontSize: 16,
      fontWeight: 400,
      color: '#888888',
      textAlign: "left",
      marginBottom: theme.spacing(1.25)
    },
    wrapperBox: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    loginLink: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      fontWeight: 500,
      fontSize: 16,
      color: "#0D46BC",
      cursor: "pointer"
    }
  })
// Customizable Area End

// Customizable Area Start
export class ForgotAccountPassword extends ForgotAccountPasswordController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {emailAddress} = this.state;
    return (
      <AuthLayout title="Forgot Password" subTitle="Enter your registered email address to receive 4 digit verification code">
        <Box className={classes.wrapperBox}>
          <Box className={classes.textFieldWrapper}>
            <Typography className={classes.text}>Email Address</Typography>
            <CustomTextField
              placeHolderText="Enter Email Address"
              fieldName="emailAddress"
              fieldId="email-address-field"
              textFieldStyles={{ padding: "10px 0"}}
              fieldValue={emailAddress}
              fieldWidth={"100%"}
              fieldType="text"
              handleChange={this.handleChange}
            />
          </Box>
          <CustomButton 
            btnText="Send Code" 
            btnStyle={btnStyle} 
            buttonId={'send-otp-btn'} 
            buttonVariant={"contained"} 
            type={"button"} 
            handleButtonClick={() => this.handleSendCode()} 
            isDisabled={false}
          />
          
            <Typography data-test-id="back-to-login" onClick={() => this.handleGoBackToLogin()} className={classes.loginLink}>
              Back to Login
            </Typography>
        </Box>  
      </AuthLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  padding:"10px 10px",
  backgroundColor:"#0D46BC",
  color:"#ffffff",
  borderRadius:"10px",
  width: "100%",
  heght: "60px",
  fontSize: 20,
  fontWeight: 500,
  maxWidth: "440px",
  marginTop: "36px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ForgotAccountPassword);
// Customizable Area End
