// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import CustomTextField from "../../../components/src/CustomTextField.web";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import NewSubjectDialogController, {
  Props,
} from "./NewSubjectDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomMultiSelectDropDown from "../../../components/src/CustomMultiSelectDropDown.web";
import CustomColorPickerFieldWeb from "../../../components/src/CustomColorPickerField.web";
import CustomFileUploadTextFieldWeb from "../../../components/src/CustomFileUploadTextField.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    schoolCloaseIcon: {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(4.4),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        top: theme.spacing(5),
        right: theme.spacing(4),
        width: theme.spacing(1.5),
      },
    },
    inputLabelText: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    paper: {
      width: "880px",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "440px",
      },
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    textFieldWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    browseTextField: {
      borderRadius: "8px",
      position: "relative",
      width: "400px",
      maxWidth: "100%",
    },
    fileInput: {
      display: "none",
    },
    browseText: {
      color: "#0D46BC",
      cursor: "pointer",
      fontSize: 14,
      width: "150px",
      fontWeight: 500,
    },

    colorOption: {
      display: "flex",
      alignItems: "center",
    },
    colorCircle: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    selectTextField: {
      borderRadius: "12px",
      width: "400px",
      maxWidth: "100%",
      height: "30px",
      backgroundColor: "#FFFFFF",
      "& .MuiSelect-root": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
      "& .MuiSelect-icon": {
        color: "#888888",
      },
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewSubjectDialog extends NewSubjectDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, handleClose, gradesList, isEdit } = this.props;
    const {
      subjectName,
      selectedGrades,
      selectedColor,
      selectedColorName,
      selectedIconName,
      subjectNameError,
      subjectNameErrorMsg,
      selectedColorError,
      selectedColorErrorMsg,
      selectedGradesError,
      selectedGradesErrorMsg,
      selectedIconError,
      selectedIconErrorMsg,
      selectedIcon
    } = this.state;

    return (
      <Dialog
        classes={{ paper: classes.paper }}
        className={`${classes.dialogRoot}`}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          id="new-subject-setup-dialog-title"
          className={`${classes.bgColor}`}
        >
          <Typography
            gutterBottom
            component={"span"}
            className={`${classes.textPrimaryColor} ${
              classes.modalHeadingText
            }`}
          >
            {isEdit? "Edit Subject" : "Add New Subject"}
          </Typography>
          <CloseIcon
            id="An icon to close Modal"
            className={`${classes.schoolCloaseIcon} ${
              classes.textPrimaryColor
            }`}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent className={`${classes.bgColor}`}>
            <Box>
              <Grid container direction="row">
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <Typography className={classes.inputLabelText}>
                      Subject Name
                    </Typography>
                    <CustomTextField
                      placeHolderText="Enter Subject Name"
                      fieldName="subjectName"
                      fieldId="subject-name-field"
                      textFieldStyles={{
                        padding: "10px 0",
                        width: "96%",
                        maxWidth: "100%",
                      }}
                      fieldValue={subjectName}
                      fieldWidth={"100%"}
                      fieldType="text"
                      handleChange={this.handleChange}
                      fieldError={subjectNameError}
                      helperText={subjectNameErrorMsg}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <Typography className={classes.inputLabelText}>
                      Grade
                    </Typography>
                    <CustomMultiSelectDropDown
                      label="Select Grade"
                      fieldMenuItems={gradesList}
                      fieldStyles={{
                        width: "96%"
                      }}
                      selectedItems={selectedGrades}
                      handleChange={this.handleChangeGrade}
                      fieldError={selectedGradesError}
                      helperText={selectedGradesErrorMsg}
                      isDisabled={isEdit}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <Typography className={classes.inputLabelText}>
                      Color
                    </Typography>
                      <CustomColorPickerFieldWeb
                        fieldId="add-subject-color-picker"
                        fieldName="selectedColor"
                        handleColorChange={this.handleColorChange}
                        fieldWidth="100%"
                        fieldValue={selectedColorName}
                        fieldValueHexCode={selectedColor}
                        textFieldStyles={colorPickerStyles}
                        placeHolderText={"Select Color"}
                        fieldError={selectedColorError}
                        helperText={selectedColorErrorMsg}
                      />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <Typography className={classes.inputLabelText}>
                      Icon
                    </Typography>
                    <CustomFileUploadTextFieldWeb
                      placeHolderText={`Upload Icon`}
                      fieldName={"selectedIcon"}
                      removeText="Remove"
                      fieldId="add-subject-icon"
                      uploadText="Browse Icon"
                      fieldValue={selectedIconName || ""}
                      file={selectedIcon}
                      fieldWidth={"100%%"}
                      textFieldStyles={textfieldStyles}
                      fieldType="text"
                      handleFile={this.handleFileInputChange}
                      fieldError={selectedIconError}
                      helperText={selectedIconErrorMsg}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box
                style={{
                  justifyContent: "end",
                  display: "flex",
                  margin: "30px 0",
                }}
              >
                <CustomButton
                  btnStyle={schoolYearCancelBtn}
                  btnText="Cancel"
                  buttonId="subject-setup-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleClose()}
                />
                <CustomButton
                  btnStyle={schoolYearAddBtn}
                  btnText={isEdit ? "Save" : "Add"}
                  buttonId="subject-setup-add-btn"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={() => this.handleAddSubject()}
                />
              </Box>
            </Box>
        </DialogContent>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const colorPickerStyles = {
  width: "96%"
} as const;

const textfieldStyles = {
  padding: "8px 0px",
  width: "96%",
} as const;

const schoolYearAddBtn = {
  borderRadius: "12px",
  padding: "15px 36px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const schoolYearCancelBtn = {
  marginRight: "15px",
  borderRadius: "12px",
  padding: "15px 36px",
  fontSize: "16px",
  border: "1px solid",
  fontWeight: 600,
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewSubjectDialog);
// Customizable Area End
