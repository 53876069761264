// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ParentAssisnedAssesmentController, {
  Props,
} from "./ParentAssignedAssesmentController";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import  CustomTable, { tableColumnTypes }  from "../../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { Box, Typography } from "@material-ui/core";


// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    actBreadCrumbRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    BoxTopBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    capitalText: {
      textTransform: "capitalize",
    },
  });
  const assignTableColumns = [
    {   
      id: "Title",   
      columnId: 1,
      label: "Title",     
      type: tableColumnTypes.ASSIGNED_TITLE,
      
    },
    {  
      id: "Type",    
      columnId: 2,
      label: "Type",     
      type: tableColumnTypes.TEXT,      
    },
    {
      id: "Submitted",       
      columnId: 3,
      label: "Submitted %",   
      type: tableColumnTypes.TEXT,      
    },
    { 
      id: "AvgGrade",    
      columnId: 4,
      label: "Avg Grade",    
      type: tableColumnTypes.ASSIGNED_ASSESSMENT_AVG_GRADE,      
    },
    { 
      id: "DueDate",     
      columnId: 5,
      label: "Due Date",     
      type: tableColumnTypes.DUE_DATE,
     
    },
  ];

// Customizable Area End

// Customizable Area Start
export class ParentAssisnedAssesment extends ParentAssisnedAssesmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { typeOfActivity} = this.state;
    const { assignedAssessmentsList  } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.actBreadCrumbRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Dashboard`}
          </Typography>
          <Typography
            className={`${classes.textButton} ${classes.fontBold600} ${classes.fontText14} ${classes.capitalText}`}
            component={"span"}
          >
            {`${typeOfActivity} `}
          </Typography>
        </Box>
        <Box className={classes.BoxTopBtnRoot}>
          <Box
            data-test-id="activity_type"
            className={`${classes.cursorPointer}`}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              onClick={() => this.handleGoBack()}
              data-test-id="activity_type_back_icon"
              mr={"8px"}
            >
              <KeyboardBackspaceIcon
                fontSize="large"
                className={`${classes.textPrimaryColor}`}
              />
            </Box>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600} ${classes.capitalText}`}
              component={"span"}
            >
              {typeOfActivity} Assigned Assesment
            </Typography>
          </Box>
        </Box>
        <Box className={classes.bgColor} borderRadius={"10px"}> 

    
              <CustomTable
              tableHeaderColumns={assignTableColumns}              
              tableId={"teacher-assigned-assessments-table"}
              showMenuIcon={false}
              tableBodyData={assignedAssessmentsList}
            />


        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentAssisnedAssesment);
// Customizable Area End
