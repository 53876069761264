// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@material-ui/core";
import CatchMeUpModalSuccessController, {
  Props,
} from "./CatchMeUpModalSuccessController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "519px",
      },
      paperWidthSm: {
        maxWidth: "519px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class ShiftLessonSuccessModal extends CatchMeUpModalSuccessController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          data-test-id="Shift"
          className={`${classes.quizViewAnswerConfirmDialog} ${classes.highlightBgColor}`}
          open={open}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          style={webStyle.posRelative}
        >
          <Box className={`${classes.bgColor}`} display={"flex"} justifyContent={"flex-end"}  style={webStyle.posAbsolute}>
            <IconButton
              data-test-id="crossCatch"
              style={{ float: "right" }}
              aria-controls="long-menu"
              aria-label="more"
              onClick={this.props.onCancel}
              aria-haspopup="true"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              id="shift-confirm-dialog-title"
            >
              <Box>
                <img src={SuccessIcon} alt="success icon" />
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }}>
              <Box mt={"20px"} mb={"10px"} textAlign={"center"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                >
                  Activities Shifted Successfully
                </Typography>
              </Box>
              <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                >
                 Activities for
                </Typography>
                <Typography
                data-test-id="textOne"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  {this.props.selectedClassName}{" "}
                </Typography>
                <Typography
                 data-test-id="textTwo"
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                >
                  has been
                </Typography>
                {" "}
                <Typography
                 data-test-id="textTwo"
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                >
                  shifted successfully.
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <CustomButton
                  btnStyle={cancelButton}
                  btnText="Okay"
                  buttonId="quiz-view-answer-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onConfirm}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const cancelButton = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  fontWeight: 600,
  fontSize: "16px",
} as const;
const webStyle={
  posRelative:{
    position:"relative" as "relative",
  },
  posAbsolute:{
    position:"absolute" as "absolute",
    top:"10px",
    right:"20px"
  }
}
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ShiftLessonSuccessModal);
// Customizable Area End
