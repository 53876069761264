// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CoursesTabController, {
  Props
} from "./CoursesTabController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { mockdata } from "./assets";

// Customizable Area End
const coursesTableColumns = [
  {
    columnId: 1,
    id: "Title",
    type: tableColumnTypes.COURSES_NAME,
    label: "Title",

  },
  {
    columnId: 2,
    id: "StartDate",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Start Date",
    width: "150px",
    maxWidth: "200px",
  },
  {
    columnId: 3,
    id: "DueDate",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Due Date",
    width: "150px",
    maxWidth: "200px",
  },
  {
    columnId: 4,
    id: "TopicCount",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Topic Count",
  },
  {
    columnId: 4,
    id: "LessonCount",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Lesson Count",
  },
  {
    columnId: 4,
    id: "ActivityCount",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Activity Count",
  },
  {
    columnId: 5,
    id: "Status",
    type: tableColumnTypes.STATUS_CHIP,
    label: "Status",
  },
]

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    statusPill: {
      width: "94px",
      height: "24px",
      borderRadius: "12px",
      opacity: "0.1866",
      background: "#029CFE",
    },
    coursesTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }
    }
  });

// Customizable Area End

// Customizable Area Start
export class CoursesTab extends CoursesTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedStatusValue,
      selectedTitleValue,
    } = this.state;

    return (
      <Box style={{ padding: "12px 25px 25px 25px" }}>
        <Grid direction="row" spacing={3} container>
          <Grid item lg={3} md={4} xs={12}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>
                  Title
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedTitleValue"
                  fieldId="titleDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldError={false}
                  fieldValue={selectedTitleValue}
                  renderValue={this.renderTitleDropdownValue}
                  fieldStyles={dropdownStyles}
                  fieldMenuItems={this.getDropdownItemsArray("Title")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid md={4} xs={12} lg={3} item>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>
                  Status
                </Typography>
              </Box>
              <Box justifyContent={"flex-start"} display={"flex"}>
                <CustomSelectDropdown
                  fieldId="statusDropdown"
                  fieldName="selectedStatusValue"
                  fieldValue={selectedStatusValue}
                  fieldStyles={dropdownStyles}
                  handleChange={this.handleChangeDropdownValue}
                  renderValue={this.renderStatusDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("Status")}
                  fieldError={false}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchCourse} isDisabled={false} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.dividerLine} />
        <Grid container>
          <Grid item xs={12}>
            <Box className={`${classes.customTableStyles} ${classes.coursesTableRow}`} >
              <CustomTable
                tableId={"coursesTable"}
                showMenuIcon={false}
                tableHeaderColumns={coursesTableColumns}
                tableBodyData={mockdata.coursesData}
                handleAction={this.handleRedirectToJourney}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  padding: "10px 10px",
  width: "100%",
  fontSize: "18px",
  borderRadius: "12px",
  fontWeight: 500,
  marginTop: "32px",
  textAlign: "center",
} as const;

const dropdownStyles = {
  width: "100%"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CoursesTab);
// Customizable Area End
