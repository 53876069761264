// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
    id: any;
    label: string;
    value: any;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedNameValue: string;
    selectedSubjectValue: string;
    selectedGradeValue: string;
    selectedNoOfLessonsPerDayValue: string;
    selectedStatusValue: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class ResourcesTabController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            loading: false,
            selectedNameValue: "",
            selectedSubjectValue: "",
            selectedGradeValue: "",
            selectedNoOfLessonsPerDayValue: "",
            selectedStatusValue: "",
        };
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await super.componentDidMount();
    }

    getDropdownItemsArray = (dropdownName: string) => {
        const refArray = [1, 2, 3, 4, 5];
        let dropdownItems: Array<any> = [];
        refArray.forEach(item => {
            dropdownItems.push({
                id: item,
                label: `${dropdownName}${item}`,
                value: `${dropdownName}${item}`,
            });
        });
        return dropdownItems;
    }

    renderTitleDropdownValue = (selected: any) => {
        const dropdownName = "Title";
        const dropdownItems = this.getDropdownItemsArray('Title');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderNoOfLessonsPerDayValue = (selected: any) => {
        const dropdownName = "Option";
        const dropdownItems = this.getDropdownItemsArray('0');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    renderStatusDropdownValue = (selected: any) => {
        const dropdownName = "Status";
        const dropdownItems = this.getDropdownItemsArray('Status');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    handleChangeDropdownValueGradedAssessment = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };

    renderSubjectDropdownValue = (selected: any) => {
        const dropdownName = "Subject";
        const dropdownItems = this.getDropdownItemsArray('Subject');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    handleChangeDropdownValue = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValue: any = value;
        if (fieldValue) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValue,
                };
            });
        }
    };

    renderGradeDropdownValue = (selected: any) => {
        const dropdownName = "Grade";
        const dropdownItems = this.getDropdownItemsArray('Grade');
        return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
    };

    handleResourceSearchButton = () => {
        console.log("search");
    }

    checkAndGetSelectedValue = (selected: any, dropdownName: string, dropdownItems: any) => {
        if (!selected || selected.length === 0) {
            return `Select ${dropdownName}`;
        }
        const selctedOption = dropdownItems.find((item: any) => item?.value === selected);
        return selctedOption?.label;
    }
    // Customizable Area End
}
