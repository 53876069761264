import React from 'react';
import { Select, MenuItem, Typography, FormHelperText } from '@material-ui/core';
import { Box, Checkbox, ListItemText, InputAdornment } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import DropdownIcon from "./SVGIcons/DropdownIcon.web";
import ClearIcon from '@material-ui/icons/Clear';
import { combineStyles } from "./CombineStyles.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import CustomMultiSelectDropDownV2Controller, {
  Props,
} from "./CustomMultiSelectDropDownV2Controller.web";

const styles = (theme: Theme) =>
  createStyles({
    selectBootstrapInput: {
      borderRadius: "10px",
      border: "solid 1px #888888",
      padding: "7px 0px 7px 10px",
      fontSize: "16px",
      cursor: "none !important",
      "&:hover":{
        outline: "none",
      },
      "& .MuiSelect-select": {
        color: "#888888",
        fontWeight: 500,
        paddingRight: "0px !important",
      },
      "& .MuiSelect-icon": {
        color: "#888888",
      },
      "& .MuiOutlinedInput-input": {
        padding: "6px 0px"
      }
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    fieldWrapper: {
      display: "flex",
      overflow: "auto",
      maxWidth: "400px",
      marginTop: "30px",
    },
    items: {
      alignItems: "center",
      maxWidth: "121px",
      height: "40px",
      padding: "0px 10px",
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #FFD92E",
      borderRadius: "6px",
      color: "#FFD92E",
      margin: "0px 15px 10px 0px",
    },
    itemLabel: {
      fontSize: 16,
      fontWeight: 500,
      color: "#FFD92E",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }
  });

export class CustomMultiSelectDropDownV2 extends CustomMultiSelectDropDownV2Controller {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { fieldMenuItems, label, fieldId, selectedItems, handleChange, removeSelectedValue, classes, isDisabled, fieldError, helperText, fieldStyles, disabledItemSelection } = this.props;

    const selectClass: any = () => {
      const { fieldError } = this.props;
      if (fieldError) return `${classes.selectBootstrapInput} ${classes.errorBorder} ${classes.bgColor
        }`;
      else if (isDisabled) return `${classes.selectBootstrapInput} ${classes.secondaryBgColor
        }`
      else return `${classes.selectBootstrapInput} ${classes.bgColor
        }`
    }

    return (
      <Box>
          <Select
            id={fieldId}
            style={fieldStyles}
            displayEmpty
            native={false}
            disableUnderline
            multiple
            disabled={isDisabled}
            error={fieldError}
            className={selectClass()}
            value={selectedItems}
            onChange={handleChange}
            renderValue={(selected: any) => {
              return(
                selected.length === 0 ? <Typography component={"span"} style={{ color: "#888" }}>{label}</Typography> :
                <Box>
                   {(selected).filter((item: any) => item !== "All").map((value: any) => (
                    <Typography component={"span"} key={value} className={`${classes.textSecondaryColor}`}>{fieldMenuItems.find((item) => item.value === value)?.label}, </Typography>
                  ))}
                </Box>
              )
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  borderRadius: "12px"
                },
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            IconComponent={ArrowDownIconComponent}
          >
            {fieldMenuItems.map((item) => (
              <MenuItem disabled={disabledItemSelection} key={item.id} value={item.id}>
                <Checkbox
                  style={{
                    color: "#FFD92E",
                  }}
                  checked={selectedItems.includes(item.id)} />
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </Select>
          {fieldError && <FormHelperText className={`${classes.helperText}`}>{helperText}</FormHelperText>}
          {
            selectedItems.length > 0 ?
              <Box className={classes.fieldWrapper}>
                {(selectedItems).filter((item: any) => item !== "All").map((value) => (
                  <Box key={value} className={classes.items}>
                    <Typography component={"span"} className={classes.itemLabel}>
                      {fieldMenuItems.find((item) => item.id === value)?.label}
                    </Typography>
                    <ClearIcon onClick={() => removeSelectedValue(value)} style={{ cursor: "pointer" }} />
                  </Box>
                ))}
              </Box>
              : ''
          }
      </Box>
    );
  }
};

const ArrowDownIconComponent = () => (
  <InputAdornment position="start">
    <DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />
  </InputAdornment>
);

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CustomMultiSelectDropDownV2);
