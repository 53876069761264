// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, Grid } from "@material-ui/core";
import TenantCourseManagementAssignSuccessModalController, {
  Props,
} from "./TenantCourseManagementAssignSuccessModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CustomButtonWeb from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    assignSuccessModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    successModalMainGrid: {
      borderRadius: "10px",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      minHeight: "286px",
      width: "500px",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        minWidth: "100px",
        maxWidth: "100%",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    closeIcon: {
      position: "absolute",
      top: "22px",
      right: "20px",
    },
    successMsgInfoBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    subSuccessMsgInfoBox: {
      display: "flex",
    },
    successIconBox: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "25px",
      display: "flex",
      paddingTop: "30px",
      position: "relative",
    },
    mainSpan: {
      textAlign: "center",
      padding: "0px 41px",
    },
    btnBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px",
    },
  });

// Customizable Area End

export class TenantCourseManagementAssignSuccessModal extends TenantCourseManagementAssignSuccessModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, successDetails, mainInfoText } = this.props;

    return (
      <Modal
        open={open}
        aria-labelledby="assign-success-modal"
        className={`${classes.highlightBgColor} ${classes.assignSuccessModal}`}
        onClose={this.props.handleClose}
        aria-describedby="assign-success-modal"
      >
        <Box className={`${classes.bgColor} ${classes.successModalMainGrid}`}>
          <Box>
            <Box className={`${classes.successIconBox}`}>
              <img src={SuccessIcon} alt="Success" />
              <CloseIcon
                className={`${classes.textCommonColor} ${classes.cursorPointer} ${classes.closeIcon}`}
                id="assign-success-modal-icon"
                onClick={this.props.handleClose}
              />
            </Box>
            <Box className={`${classes.successMsgInfoBox}`}>
              <Typography
                component={"p"}
                className={`${classes.textPrimaryColor} ${classes.textCenter} ${classes.fontBold600} ${classes.fontText22}`}
              >
                {mainInfoText}
              </Typography>
              <Box className={`${classes.subSuccessMsgInfoBox}`}>
                <Typography
                  className={`${classes.mainSpan}`}
                  component={"span"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    Course
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                  >
                    {` ${successDetails.topic} `}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    added to
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                  >
                    {` ${successDetails.catalog} `}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    successfully.
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className={`${classes.btnBox}`}>
              <CustomButtonWeb
                btnStyle={btnStyle}
                btnText="Okay"
                buttonVariant="outlined"
                buttonId="success-okay-btn"
                handleButtonClick={this.props.handleClose}
                isDisabled={false}
                type={"button"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementAssignSuccessModal
);
// Customizable Area End
