// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    Box,
    Typography,
    Grid,
    Button,
    Select,
    MenuItem,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
} from '@material-ui/core';
import BrowseCourseCatalogModalController, {
    Props
} from "./BrowseCourseCatalogModalController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../../components/src/CustomButton.web';
import { combineStyles } from '../../../../components/src/CombineStyles.web';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "../assets";
import VisibilityIcon from '@material-ui/icons/Visibility';

// Customizable Area End
const coursesTableColumns = [
    {
        columnId: 1,
        id: "Title",
        type: tableColumnTypes.COURSES_NAME,
        label: "Title",

    },
    {
        columnId: 2,
        id: "Subject",
        type: tableColumnTypes.TEXT,
        label: "Subject",
    },
    {
        id: "Grade",
        columnId: 3,
        label: "Grade",
        type: tableColumnTypes.TEXT
    },
    {
        id: "Type",
        columnId: 4,
        label: "Type",
        type: tableColumnTypes.TEXT
    },
    {
        columnId: 5,
        id: "CreatedOn",
        type: tableColumnTypes.TEXT,
        label: "Created On",
    },
    {
        columnId: 6,
        id: "LastModifiedOn",
        type: tableColumnTypes.TEXT,
        label: "Last Modified On",
    },
]
const coursesData = [
    {
        id: 1,
        Title: {
            name: "Velocity and Acceleration",
            icon: ScienceColor,
        },
        Subject: 'Science',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 2,
        Title: {
            name: "Profit and Loss",
            icon: MathsColor,
        },
        Subject: 'Maths',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 3,
        Title: {
            name: "Roman History",
            icon: HistoryColor,
        },
        Subject: 'History',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 4,
        Title: {
            name: "Victorian Literature",
            icon: EnglishColor,
        },
        Subject: 'English',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 5,
        Title: {
            name: "Creative Writing",
            icon: EnglishColor,
        },
        Subject: 'English',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 6,
        Title: {
            name: "English Poetry",
            icon: EnglishColor,
        },
        Subject: 'English',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 7,
        Title: {
            name: "Velocity and Accel…",
            icon: ScienceColor,
        },
        Subject: 'Science',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 8,
        Title: {
            name: "Profit and Loss",
            icon: MathsColor,
        },
        Subject: 'Maths',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
    {
        id: 9,
        Title: {
            name: "Roman History",
            icon: HistoryColor,
        },
        Subject: 'History',
        Grade: 'Grade 3',
        Type: 'Global',
        CreatedOn: 'July 20, 2022',
        LastModifiedOn: 'July 20, 2022',
    },
];
// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        browseCatalogContainer: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                width: "970px",
                maxWidth: "100%",
                minHeight: "500px",
                overflowY: "hidden",
                overflowX: "hidden",
            }
        },
        customDropdown: {
            width: "100%",
            height: "50px",
            padding: "17px 15px",
            borderRadius: "12px",
            border: "1px solid #888",
            background: "#FFF",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
        },
        dividerLine: {
            height: "4px",
            background: "#F1F1F1",
            marginTop: "25px",
            marginLeft: "-25px",
            marginRight: "-25px",
        },
        catalogCoursesRow: {
            "& td": {
                height: "50px",
                paddingTop: "0px",
                paddingBottom: "0px",
            }
        },
        highlightCourseRow: {
            background: "#FFD92E"
        }
    })

// Customizable Area End

// Customizable Area Start
export class BrowseCourseCatalogModal extends BrowseCourseCatalogModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open } = this.props;
        const {
            selectedRowId,
            selectedTitle,
            selectedSubject,
            selectedGrade,
        } = this.state;

        return (
            <Dialog className={`${classes.highlightBgColor} ${classes.browseCatalogContainer}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open} scroll={'body'}>
                <Box className={`${classes.bgColor}`}>
                    <Grid direction="row" container>
                        <Grid style={{ padding: "30px 30px 0px 30px" }} xs={12} item>
                            <Typography className={`${classes.fontText24} ${classes.textPrimaryColor}`} component={"span"}>Select Course from Catalog</Typography>
                            <Button className={classes.textCommonColor} onClick={this.props.onCancel} style={{ float: "right" }}> X </Button>
                        </Grid>
                    </Grid>
                    <DialogContent style={{ padding: "0px", marginTop: "1px", overflow: "hidden" }}>
                        <Grid container spacing={2} direction="row">
                            <Grid xs={12} item>
                                <Box style={{ height: "1px" }} className={classes.dividerLine} />
                            </Grid>
                            <Grid xs={12} item style={{ padding: "25px 30px 0px 30px" }} >
                                <Grid container spacing={2} direction="row">
                                    <Grid xs={4} item>
                                        <Box>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Title
                                            </Typography>
                                            <Box marginBottom={"10px"}>
                                                <Select
                                                    id="catalogTitleDropdown"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        },
                                                        PaperProps: {
                                                            style: {
                                                                borderRadius: "12px",
                                                                maxHeight: "400px",
                                                            }
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    name="selectedTitle"
                                                    className={`${classes.customDropdown} ${classes.bgColor} ${classes.textSecondaryColor}`}
                                                    value={selectedTitle}
                                                    disableUnderline
                                                    IconComponent={ExpandMoreIcon}
                                                    native={false}
                                                    onChange={this.handleDropdownChange}
                                                    displayEmpty
                                                    renderValue={(event: any) => this.renderDropdownValue(event, "Title", this.getDropdownItemsArray("Title"))}
                                                >
                                                    {this.getDropdownItemsArray("Title").map((item: any) => (
                                                        <MenuItem key={`${item.value}`} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs item>
                                        <Box>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Subject
                                            </Typography>
                                            <Box marginBottom={"10px"}>
                                                <Select
                                                    id="catalogSubjectDropdown"
                                                    name="selectedSubject"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        },
                                                        PaperProps: {
                                                            style: {
                                                                borderRadius: "12px",
                                                                maxHeight: "400px",
                                                            }
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    value={selectedSubject}
                                                    className={`${classes.customDropdown} ${classes.bgColor} ${classes.textSecondaryColor}`}
                                                    disableUnderline
                                                    IconComponent={ExpandMoreIcon}
                                                    native={false}
                                                    onChange={this.handleDropdownChange}
                                                    renderValue={(event: any) => this.renderDropdownValue(event, "Subject", this.getDropdownItemsArray("Subject"))}
                                                    displayEmpty
                                                >
                                                    {this.getDropdownItemsArray("Subject").map((item: any) => (
                                                        <MenuItem key={`${item.value}`} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs item>
                                        <Box>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Grade
                                            </Typography>
                                            <Box marginBottom={"10px"}>
                                                <Select
                                                    id="catalogGradeDropdown"
                                                    name="selectedGrade"
                                                    className={`${classes.customDropdown} ${classes.bgColor} ${classes.textSecondaryColor}`}
                                                    value={selectedGrade}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: "400px",
                                                                borderRadius: "12px",
                                                            }
                                                        },
                                                        anchorOrigin: {
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    renderValue={(event: any) => this.renderDropdownValue(event, "Grade", this.getDropdownItemsArray("Grade"))}
                                                    disableUnderline
                                                    native={false}
                                                    displayEmpty
                                                    IconComponent={ExpandMoreIcon}
                                                    onChange={this.handleDropdownChange}
                                                >
                                                    {this.getDropdownItemsArray("Grade").map((item: any) => (
                                                        <MenuItem key={`${item.value}`} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs item>
                                        <Box>
                                            <CustomButton btnText="Search" btnStyle={{ ...assignButtonStyle, ...{ width: "100%" } }} buttonId={`catalog-search-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearch} isDisabled={false} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} item>
                                <Box className={classes.dividerLine} />
                            </Grid>
                            <Grid xs={12} item style={{ padding: "0px 30px 0px 30px" }} >
                                <Box className={`${classes.customTableStyles} ${classes.catalogCoursesRow}`} >
                                    <TableContainer className={classes.tableContainer} id="catalog-courses-table">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {coursesTableColumns.map((column: any) => (
                                                        <TableCell key={column.id}>
                                                            <Box display={'flex'}>
                                                                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontSize15}`}>
                                                                    {column.label}
                                                                </Typography>
                                                                {column?.icon ? <img src={column?.icon} className={classes.columnIcon} /> : ''}
                                                            </Box>
                                                        </TableCell>
                                                    ))}
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {coursesData?.length > 0 &&
                                                    coursesData.map((row: any, i: any) => {
                                                        return (
                                                            <TableRow onClick={() => this.handleSelectRow(row.id)} key={row.id} tabIndex={-1}
                                                                className={selectedRowId === row.id ? classes.highlightCourseRow : ''}>
                                                                {coursesTableColumns.map((column: any) => {
                                                                    return (
                                                                        <TableCell
                                                                            key={`${column.id}-${row.id}`}
                                                                            style={{
                                                                                minWidth: "80px",
                                                                                maxWidth: "auto"
                                                                            }}
                                                                            className={`${classes.tableBodyCell}`}
                                                                        >
                                                                            {column.id === "Title" ?
                                                                                <Box alignItems="center" display={"flex"}>
                                                                                    <Box className={classes.subjectLogoBg} mr={"10px"}>
                                                                                        <img src={row[column.id]?.icon} />
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography component="span" className={`${classes.textPrimaryColor} ${classes.fontText15}`}>
                                                                                            {row[column.id]?.name}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                :
                                                                                <Typography component={"span"} className={`${classes.fontSize15} ${classes.textPrimaryColor}`}>
                                                                                    {row[column.id]}
                                                                                </Typography>
                                                                            }
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                                <TableCell align="right">
                                                                    <IconButton
                                                                        aria-controls="long-menu"
                                                                        aria-haspopup="true"
                                                                        aria-label="more"
                                                                    >
                                                                        <VisibilityIcon className={`${classes.textCommonColor}`} />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                            <Grid xs={12} item style={{ padding: "0px 30px 30px 30px" }} >
                                <Box marginBottom={"10px"} style={{ width: "100%" }}>
                                    <CustomButton btnText="Copy" btnStyle={assignButtonStyle} buttonId={`catalogCopyButton`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                                    <CustomButton btnText="Cancel" btnStyle={cancelButtonStyle} buttonId={`catalogCancelButton`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const cancelButtonStyle = {
    width: "120px",
    height: "50px",
    padding: "10px 10px",
    fontSize: "18px",
    border: "1px solid",
    borderRadius: "12px",
    marginTop: "32px",
    textAlign: "center",
    float: "right",
    marginLeft: "15px",
    fontWeight: 500,
} as const;

const assignButtonStyle = {
    height: "50px",
    width: "120px",
    padding: "10px 10px",
    fontSize: "18px",
    textAlign: "center",
    borderRadius: "12px",
    marginTop: "32px",
    float: "right",
    marginLeft: "15px",
    fontWeight: 500,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(BrowseCourseCatalogModal);
// Customizable Area End
