// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { mockdata } from "./assets";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValue: number;
    tabName: string
    courseName: string
    selectedStudentName: string
    studentDropdownItems: Array<any>;
    selectedStudentDropdownValue: any;
    GradedActivitiesBody: Array<any>;
    GradedActivitiesColumny: Array<any>;
    searchByStudent: string;
    searchStudent: string;
    openAddNewAssessment: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class ParentTeacherAssessmentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetProfileData: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            selectedStudentName: '',
            tabValue: 1,
            tabName: '',
            courseName: '',
            searchByStudent: '',
            searchStudent: '',
            GradedActivitiesColumny: mockdata.GradedActivitiesColumny,
            studentDropdownItems: mockdata.studentDropdownItems,
            selectedStudentDropdownValue: '',
            GradedActivitiesBody: mockdata.GradedActivitiesBody,
            openAddNewAssessment: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.handleSelectBarName();
        this.handleTabChange('s', 0);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    }

    handleStudentDropdownValue = (event: React.ChangeEvent<{
        name?: any;
        value: unknown;
    }>, child: React.ReactNode) => {
        const { name, value } = event.target;
        let fieldValues: any = value;
        if (fieldValues) {
            this.setState((prev) => {
                return {
                    ...prev,
                    [name]: fieldValues,
                };
            });
        }
    };

    renderStudentDropdownValue = (selected: any) => {
        if (!selected || selected.length === 0) {
            return "All";
        }
        const { studentDropdownItems } = this.state;
        const selectedGradingComponents = studentDropdownItems.find((grade: any) => grade?.value === selected);
        return selectedGradingComponents?.label;
    };

    handleSearch = async (event: any) => {
        this.setState({
            searchByStudent: event.target?.value
        })
    };

    handleChangeSearch = (event: any) => {
        this.setState({
            searchStudent: event.target?.value
        })
    };

    handleTabChange = (event: any, newValue: number) => {
        const tabNames = ['All', 'Assigned', 'Graded'];
        this.setState({
            tabValue: newValue,
            tabName: tabNames[newValue]
        }, () => {
        });
    };

    handleSelectBarName = () => {
        const historyLocationState = this.props.navigation?.getHistoryState();
        if (historyLocationState) {
            const { TabNames, StudentName } = historyLocationState;
            this.setState({ selectedStudentName: StudentName ?? "", courseName: TabNames });
        }
    };

    handleOpenAddNewAssessmentModal = () => {
        this.setState({ openAddNewAssessment: true });
    }

    handleCloseAddNewAssessmentModal = () => {
        this.setState({ openAddNewAssessment: false });
    }
    // Customizable Area End
}
// Customizable Area End