// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  classes?: any;
  navigation?: any;
  onConfirm: () => void;
  onCancel: () => void;
  isEdit: boolean;
  open: boolean;
  explanation: boolean;
  selectedSubjectiveQuestionData: any;
  activityId: any;
  handleAddSubjectiveQuestion: any;
  handleUpdateSubjectiveQuestion: any;
  handleDeleteSubjectiveQuestion: any;
  forManual: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  wordLimitValue: string;
  questionContent: string;
  subjectiveQuestionContentError: boolean;
  subjectiveQuestionContentErrorMsg: string;
  marksValue: string;
  subjectiveMarksError: boolean;
  subjectiveMarksErrorMsg: string;
  correctAnswerValue: string;
  questionExplanation: string;
  showStudent: boolean;
  uploadDocument: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddSubjectiveQuestionModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      wordLimitValue: "",
      questionContent: "",
      subjectiveQuestionContentError: false,
      subjectiveQuestionContentErrorMsg: "",
      marksValue: "",
      subjectiveMarksError: false,
      subjectiveMarksErrorMsg: "",
      correctAnswerValue: "",
      questionExplanation: "",
      showStudent: false,
      uploadDocument: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    const { isEdit } = this.props;
    if (isEdit) this.handleSetInitialValue(true);
  }

  handleQuestionContentChange = (content: any) => {
    this.setState({
      questionContent: content,
      subjectiveQuestionContentError: false,
      subjectiveQuestionContentErrorMsg: "",
    });
  };

  handleQuestionExplanationChange = (content: any) => {
    this.setState({ questionExplanation: content });
  };

  handleMarksInputChange = (event: React.ChangeEvent<any>) => {
    this.setState({
      marksValue: event.target.value,
      subjectiveMarksError: false,
      subjectiveMarksErrorMsg: "",
    });
  };

  handleWorkLimitInputChange = (event: React.ChangeEvent<any>) => {
    this.setState({ wordLimitValue: event.target.value });
  };

  handleCorrectAnswerInputChange = (event: React.ChangeEvent<any>) => {
    this.setState({ correctAnswerValue: event.target.value });
  };

  getSaveButtonLabel() {
    switch (this.props.isEdit) {
      case false:
        return "Add";

      case true:
        return "Save";
    }
  }

  getJustifyContentValue() {
    switch (this.props.isEdit) {
      case false:
        return "end";

      case true:
        return "space-between";
    }
  }

  handleVerifySubjectiveQuestionContentData = () => {
    const { questionContent, marksValue } = this.state;
    let isValid = true;
    if (!questionContent.trim()) {
      isValid = false;
      this.setState({
        subjectiveQuestionContentError: true,
        subjectiveQuestionContentErrorMsg: "Please enter question.",
      });
    }
    if (!marksValue) {
      isValid = false;
      this.setState({
        subjectiveMarksError: true,
        subjectiveMarksErrorMsg: "Please enter marks.",
      });
    }
    return isValid;
  };

  handleAddSubjectiveQuestionData = () => {
    if (this.handleVerifySubjectiveQuestionContentData()) {
      const {
        questionContent,
        marksValue,
        correctAnswerValue,
        wordLimitValue,
        questionExplanation,
        showStudent,
        uploadDocument,
      } = this.state;
      const { activityId, isEdit } = this.props;
      const requestObj: any = {
        data: {
          attributes: {
            activity_id: activityId,
            question: questionContent,
            marks: parseInt(marksValue),
          },
        },
      };
      if (correctAnswerValue) {
        requestObj.data.attributes.correct_option = correctAnswerValue;
      }
      if (wordLimitValue) {
        requestObj.data.attributes.word_limit = wordLimitValue;
      }
      if (questionExplanation) {
        requestObj.data.attributes.explanation = questionExplanation;
      }
      if (this.props.forManual) {
        requestObj.data.attributes.show_student = showStudent;
        requestObj.data.attributes.upload_document = uploadDocument;
      }
      if (isEdit) {
        this.props.handleUpdateSubjectiveQuestion(requestObj);
      } else {
        this.props.handleAddSubjectiveQuestion(requestObj);
      }
    }
  };

  handleSetInitialValue = (isEdit?: boolean) => {
    if (isEdit) {
      const { selectedSubjectiveQuestionData } = this.props;
      this.setState({
        wordLimitValue: selectedSubjectiveQuestionData?.wordLimit,
        questionContent: selectedSubjectiveQuestionData?.question,
        subjectiveQuestionContentError: false,
        subjectiveQuestionContentErrorMsg: "",
        marksValue: selectedSubjectiveQuestionData?.marks,
        subjectiveMarksError: false,
        subjectiveMarksErrorMsg: "",
        correctAnswerValue: selectedSubjectiveQuestionData?.correctAnswer,
        questionExplanation: selectedSubjectiveQuestionData?.explanation,
      });
    } else {
      this.setState({
        wordLimitValue: "",
        questionContent: "",
        subjectiveQuestionContentError: false,
        subjectiveQuestionContentErrorMsg: "",
        marksValue: "",
        subjectiveMarksError: false,
        subjectiveMarksErrorMsg: "",
        correctAnswerValue: "",
        questionExplanation: "",
      }, () => {
        this.props.onCancel();
      });
    }
  };

  handleShowCorrectAnswerDynamicClass = () => {
    const classes = this.props.classes;
    if (this.state.showStudent) {
      return `${classes.showActiveOption} ${classes.fontText15}`;
    } else {
      return `${classes.fontText15} ${classes.textCommonColor}`;
    }
  }

  handleShowStudentChange = () => {
    this.setState({ showStudent: !this.state.showStudent });
  }

  handleUploadDocumentDynamicClass = () => {
    const classes = this.props.classes;
    if (this.state.uploadDocument) {
      return `${classes.showActiveOption} ${classes.fontText15}`;
    } else {
      return `${classes.fontText15} ${classes.textCommonColor}`;
    }
  }

  handleUploadDocumentChange = () => {
    this.setState({ uploadDocument: !this.state.uploadDocument });
  }
  // Customizable Area End
}
// Customizable Area End
