// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TeacherPendingRequestControler, {
  Props,
} from "./TeacherPendingRequestController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "./assets";

// Customizable Area End
const teacherPendingReqTableColumns = [
  {
    id: "Title_name",
    columnId: 1,
    type: tableColumnTypes.COURSES_NAME,
    label: "Title",
    width: "260px",
    maxWidth: "300px",
  },
  {
    id: "Subject_name",
    columnId: 2,
    type: tableColumnTypes.TEXT,
    label: "Subject",
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "Grade_id",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "Requested_On",
    columnId: 4,
    label: "Requested On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "180px",
  },
  {
    id: "Created_On",
    columnId: 5,
    label: "Created On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "180px",
  },
  {
    id: "Last_ModifiedOn",
    columnId: 6,
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "200px",
  },
];

const TeacherPendingRequestsData = [
  {
    id: 1,
    Title_name: {
      name: "Velocity and Acceleration",
      icon: ScienceColor,
    },
    Subject_name: "Science",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 2,
    Title_name: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject_name: "Maths",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 3,
    Title_name: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject_name: "History",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 4,
    Title_name: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject_name: "English",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 5,
    Title_name: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject_name: "English",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 6,
    Title_name: {
      name: "English Poetry",
      icon: EnglishColor,
    },
    Subject_name: "English",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 7,
    Title_name: {
      name: "Velocity",
      icon: ScienceColor,
    },
    Subject_name: "Science",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 8,
    Title_name: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject_name: "Maths",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
  {
    id: 9,
    Title_name: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject_name: "History",
    Grade_id: "Grade 3",
    Requested_On: "July 20, 2022",
    Created_On: "July 20, 2022",
    Last_ModifiedOn: "July 20, 2022",
  },
];

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pendingDivider: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "20px",
      marginBottom: "5px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
  });
const coursePendingableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TeacherPendingRequest extends TeacherPendingRequestControler {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedPendingReqTitleValue,
      selectedPendingReqSubjectValue,
      selectedPendingReqGrade,
      selectedRequestedOnValues,
    } = this.state;

    return (
      <Box px={"24px"} pb={"20px"} data-test-id="MainGrid">
        <Grid
          container
          spacing={3}
          direction="row"
          data-test-id="TitlePendingGrid"
        >
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                  component={"span"}
                  data-test-id="TitlePending"
                >
                  Title
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldId="titleDropdown"
                  fieldName="selectedPendingReqTitleValue"
                  handleChange={this.handleChangePendingDDValue}
                  fieldValue={selectedPendingReqTitleValue}
                  fieldError={false}
                  renderValue={this.renderTitleDDValue}
                  fieldStyles={pendingRequestDropDwnStyle}
                  fieldMenuItems={this.getPendingDropdownItemsArray("Title")}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="RequestedOnGrid">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  data-test-id="RequestedOn"
                >
                  Requested On
                </Typography>
              </Box>
              <Box justifyContent={"flex-start"} display={"flex"}>
                <CustomSelectDropdown
                  fieldName="selectedRequestedOnValues"
                  fieldId="requestedOnDropdown"
                  handleChange={this.handleChangePendingDDValue}
                  fieldValue={selectedRequestedOnValues}
                  fieldError={false}
                  fieldMenuItems={this.state.RequestedOnDDComponentValue}
                  renderValue={this.renderRequestedOnDDValue}
                  fieldStyles={pendingRequestDropDwnStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="SubjectPendingGrid">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  data-test-id="SubjectPending"
                >
                  Subject
                </Typography>
              </Box>
              <Box justifyContent={"flex-start"} display={"flex"}>
                <CustomSelectDropdown
                  fieldName="selectedPendingReqSubjectValue"
                  fieldId="subjectDropdown"
                  fieldValue={selectedPendingReqSubjectValue}
                  fieldError={false}
                  handleChange={this.handleChangePendingDDValue}
                  renderValue={this.renderPendingReqSubjectDDValue}
                  fieldStyles={pendingRequestDropDwnStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.getPendingDropdownItemsArray("Subject")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box data-test-id="gradeValuesDDBox">
              <Box marginBottom={"8px"}>
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  data-test-id="gradeValues"
                >
                  Grade
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedPendingReqGrade"
                  fieldId="gradeDropdown"
                  handleChange={this.handleChangePendingDDValue}
                  fieldValue={selectedPendingReqGrade}
                  fieldError={false}
                  renderValue={this.renderPendingGradeDDValue}
                  fieldMenuItems={this.getPendingDropdownItemsArray("Grade")}
                  fieldStyles={pendingRequestDropDwnStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <CustomButton
                btnText="Search"
                btnStyle={pendingTabSearchBtn}
                buttonId={`pending-request-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handlePendingSearchBtn}
                isDisabled={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.pendingDivider} />
        <Grid item xs={12}>
          <Box
            className={`${classes.customTableStyles} ${classes.pendingTableRows}`}
          >
            <ThemeProvider theme={coursePendingableTheme}>
              <CustomTable
                tableHeaderColumns={teacherPendingReqTableColumns}
                tableId={"coursesTable"}
                tableBodyData={TeacherPendingRequestsData}
                showMenuIcon={true}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const pendingTabSearchBtn = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
} as const;

const pendingRequestDropDwnStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherPendingRequest);
// Customizable Area End
