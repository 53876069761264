// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
import { defaultQuestionOptions } from "../../../components/src/CommonType.web";
import { handleGetOptionLetter } from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start
const initialsOptions = [
  {
    id: 1,
    optionLetter: "A",
    label: "Option A",
    value: "A",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
  {
    id: 2,
    optionLetter: "B",
    label: "Option B",
    value: "B",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
  {
    id: 3,
    optionLetter: "C",
    label: "Option C",
    value: "C",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
  {
    id: 4,
    optionLetter: "D",
    label: "Option D",
    value: "D",
    inputValue: "",
    error: false,
    errorMsg: "",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  isEdit: boolean;
  selectedQuestionData: any;
  activityId: any;
  handleAddQuestion: any;
  handleUpdateQuestion: any;
  handleDeleteQuestion: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  questionOptionFields: Array<any>;
  marksValue: string;
  marksError: boolean;
  marksErrorMsg: string;
  selectedCorrectOption: string;
  selectedCorrectOptionError: boolean;
  selectedCorrectOptionErrorMsg: string;
  questionContent: string;
  questionContentError: boolean;
  questionContentErrorMsg: string;
  explanationContent: string;
  deletedOptionsList: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddObjectiveQuestionModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      marksValue: "",
      marksError: false,
      marksErrorMsg: "",
      selectedCorrectOption: "",
      selectedCorrectOptionError: false,
      selectedCorrectOptionErrorMsg: "",
      questionContent: "",
      questionContentError: false,
      questionContentErrorMsg: "",
      explanationContent: "",
      questionOptionFields: [...initialsOptions],
      deletedOptionsList: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    const { isEdit } = this.props;
    if (isEdit) this.handleSetInitialValue(true);
  }

  handleOptionInputChange = (
    event: React.ChangeEvent<any>,
    fieldId: number
  ) => {
    const optionsArray = this.state.questionOptionFields;
    let selectedField = optionsArray.filter(
      (item: any) => item.id === fieldId
    )[0];
    const indexOfSelectedField = optionsArray.indexOf(selectedField);
    selectedField.inputValue = event.target.value;
    optionsArray[indexOfSelectedField] = { ...selectedField };
    this.setState({ questionOptionFields: [...optionsArray] });
  };

  handleAddOptionField = () => {
    const lastOption =
      this.state.questionOptionFields[
        this.state.questionOptionFields.length - 1
      ];
    let updatedOptions = [...this.state.questionOptionFields];
    const nextLetter = String.fromCharCode(
      lastOption.optionLetter.charCodeAt(0) + 1
    );
    updatedOptions.push({
      id: lastOption.id + 1,
      optionLetter: nextLetter,
      label: "Option " + nextLetter,
      value: nextLetter,
      inputValue: "",
      error: false,
      errorMsg: "",
    });
    this.setState({ questionOptionFields: [...updatedOptions] });
  };

  handleDeleteOptionField = (fieldId: number) => {
    const { questionOptionFields, selectedCorrectOption } = this.state;
    let deletedItem = questionOptionFields?.find(
      (item: any) => parseInt(item.id) === fieldId
    );
    let filteredFieldOptions = questionOptionFields.filter(
      (item: any) => item.id !== fieldId
    );
    if (selectedCorrectOption === deletedItem.value) {
      this.setState({
        selectedCorrectOption: "",
        selectedCorrectOptionError: true,
        selectedCorrectOptionErrorMsg: "Please select correct option.",
      });
    }
    let character = "A";
    filteredFieldOptions.forEach((option: any, index) => {
      option.optionLetter = String.fromCharCode(
        character.charCodeAt(0) + index
      );
      option.label =
        "Option " + String.fromCharCode(character.charCodeAt(0) + index);
      option.value = String.fromCharCode(character.charCodeAt(0) + index);
    });
    if (filteredFieldOptions.length < 2) {
      toast.info("You need to keep minimum 2 options.");
    } else {
      this.setState({ questionOptionFields: [...filteredFieldOptions] });
      if (deletedItem?.optionId) {
        deletedItem._destroy = true;
        this.setState({
          deletedOptionsList: [
            ...this.state.deletedOptionsList,
            { ...deletedItem },
          ],
        });
      }
    }
  };

  handleMarksInputChange = (event: React.ChangeEvent<any>) => {
    this.setState({
      marksValue: event.target.value,
      marksError: false,
      marksErrorMsg: "",
    });
  };

  handleCorrectOptionDropdownChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          selectedCorrectOptionError: false,
          selectedCorrectOptionErrorMsg: "",
        };
      });
    }
  };

  renderCorrectOptionDropdownValue = (selected: any) => {
    const dropdownName = "Correct Option";
    const dropdownItems = this.state.questionOptionFields;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  checkAndGetSelectedValue = (
    selected: any,
    dropdownName: string,
    dropdownItems: any
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find(
      (item: any) => item?.value === selected
    );
    if (!selctedOption) {
      this.setState({
        selectedCorrectOption: "",
        selectedCorrectOptionError: true,
        selectedCorrectOptionErrorMsg: "Please select correct option.",
      });
    }
    return selctedOption?.label;
  };

  handleQuestionContentChange = (content: any) => {
    this.setState({
      questionContent: content,
      questionContentError: false,
      questionContentErrorMsg: "",
    });
  };

  handleExplanationContentChange = (content: any) => {
    this.setState({ explanationContent: content });
  };

  getJustifyContentValue() {
    switch (this.props.isEdit) {
      case true:
        return "space-between";

      case false:
        return "end";
    }
  }

  getSaveButtonLabel() {
    switch (this.props.isEdit) {
      case true:
        return "Save";

      case false:
        return "Add";
    }
  }

  handleVerifyQuestionOptions = () => {
    const { questionOptionFields } = this.state;
    let isValid = true;
    const updatedQuestionOptionFields = questionOptionFields.map(
      (item: any) => {
        if (!item.inputValue?.trim()) {
          item.error = true;
          item.errorMsg = "Please enter answer.";
          isValid = false;
        }
        return item;
      }
    );
    this.setState({ questionOptionFields: [...updatedQuestionOptionFields] });
    return isValid;
  };

  handleVerifyQuestionContentData = () => {
    const { questionContent, selectedCorrectOption, marksValue } = this.state;
    let isValid = true;
    if (!questionContent.trim()) {
      isValid = false;
      this.setState({
        questionContentError: true,
        questionContentErrorMsg: "Please enter question.",
      });
    }
    if (!selectedCorrectOption) {
      isValid = false;
      this.setState({
        selectedCorrectOptionError: true,
        selectedCorrectOptionErrorMsg: "Please select correct option.",
      });
    }
    if (!marksValue) {
      isValid = false;
      this.setState({ marksError: true, marksErrorMsg: "Please enter marks." });
    }
    return isValid;
  };

  handleAddQuestionData = () => {
    if (
      this.handleVerifyQuestionContentData() &&
      this.handleVerifyQuestionOptions()
    ) {
      const {
        questionContent,
        marksValue,
        questionOptionFields,
        selectedCorrectOption,
        explanationContent,
        deletedOptionsList,
      } = this.state;
      const { activityId, isEdit } = this.props;
      const optionsList = questionOptionFields?.map((item: any) => {
        const obj: any = {
          text: item?.inputValue,
        };
        if (item?.optionId) {
          obj.id = item.optionId;
        }
        return obj;
      });
      const deletedOptions = deletedOptionsList?.map((item: any) => {
        const obj: any = {
          text: item?.inputValue,
          id: item.optionId,
          _destroy: true,
        };
        return obj;
      });
      const selectedOptionValue = questionOptionFields?.find(
        (item: any) => item.optionLetter === selectedCorrectOption
      );
      const requestObj = {
        data: {
          attributes: {
            activity_id: activityId,
            question: questionContent,
            marks: parseInt(marksValue),
            options_attributes: [...optionsList, ...deletedOptions],
            correct_option: selectedOptionValue?.inputValue,
            explanation: explanationContent,
          },
        },
      };
      if (isEdit) {
        this.props.handleUpdateQuestion(requestObj);
      } else {
        this.props.handleAddQuestion(requestObj);
      }
    }
  };

  handleSetInitialValue = (isEdit?: boolean) => {
    if (isEdit) {
      const { selectedQuestionData } = this.props;
      const correctOption = selectedQuestionData?.correctAnswer;
      const questionOptionsList: Array<any> = [];
      selectedQuestionData.options?.forEach(
        (optionItem: any, index: number) => {
          const lastOptionLetter = questionOptionsList[index - 1]?.optionLetter;
          const obj = {
            ...optionItem,
            optionLetter: handleGetOptionLetter(index, lastOptionLetter),
            label: `Option ${handleGetOptionLetter(index, lastOptionLetter)}`,
            value: handleGetOptionLetter(index, lastOptionLetter),
          };
          questionOptionsList.push(obj);
        }
      );
      const correctOptionLetter = questionOptionsList?.find(
        (item: any) => item.inputValue === correctOption
      );
      this.setState({
        marksValue: selectedQuestionData?.marks || 0,
        marksError: false,
        marksErrorMsg: "",
        selectedCorrectOption: correctOptionLetter.value,
        selectedCorrectOptionError: false,
        selectedCorrectOptionErrorMsg: "",
        questionContent: selectedQuestionData?.question,
        questionContentError: false,
        questionContentErrorMsg: "",
        explanationContent: selectedQuestionData?.explanation,
        questionOptionFields: [...questionOptionsList],
      });
    } else {
      const questionOptionsList: Array<any> = [];
      defaultQuestionOptions.forEach((option: any, index: number) => {
        const lastOptionLetter = questionOptionsList[index - 1]?.optionLetter;
        const obj = {
          ...option,
          optionLetter: handleGetOptionLetter(index, lastOptionLetter),
          label: `Option ${handleGetOptionLetter(index, lastOptionLetter)}`,
          value: handleGetOptionLetter(index, lastOptionLetter),
          inputValue: "",
        };
        questionOptionsList.push(obj);
      });
      this.setState(
        {
          marksValue: "",
          marksError: false,
          marksErrorMsg: "",
          selectedCorrectOption: "",
          selectedCorrectOptionError: false,
          selectedCorrectOptionErrorMsg: "",
          questionContent: "",
          questionContentError: false,
          questionContentErrorMsg: "",
          explanationContent: "",
          questionOptionFields: [...defaultQuestionOptions],
        },
        () => {
          this.props.onCancel();
        }
      );
    }
  };
  // Customizable Area End
}
// Customizable Area End
