// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import NewTeacherAssignmentDialogController, {
  Props,
} from "./NewTeacherAssignmentDialogController.web";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomStepperWeb from "../../../../components/src/CustomStepper.web";
import TeacherAssignmentBasicInformation from "./TeacherAssignmentBasicInformation.web";
import RegularTeacherWeb from "./RegularTeacher.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(4),
        width: theme.spacing(1.5),
        top: theme.spacing(5),
      },
      cursor: "pointer",
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(2.6),
    },
    paper: {
      maxWidth: "100%",
      width: "880px",
    },
    dialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "12px",
      },
    },
    stepIcon: {
      backgroundColor: "#F1F1F1",
      "& .MuiStepIcon-root": {
        fontSize: "42px",
      },
      "& .MuiStepConnector-line": {
        borderColor: "#0D46BC",
      },
      "& .MuiStepLabel-label": {
        color: "#0D46BC",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
      "& .MuiStepIcon-completed": {
        color: "#01C996",
      },
      "& .MuiStepLabel-completed": {
        color: "#01C996",
        fontWeight: 500,
      },
      "& .MuiStepConnector-completed": {
        "& .MuiStepConnector-line": {
          borderColor: "#01C996",
        },
      },
    },
    customLoader: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: -30,
      backgroundColor: "rgb(0, 0, 0, .4)",
      zIndex: 100,
      display: "block",
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  });
// Customizable Area End

// Customizable Area Start
export class NewTeacherAssignmentDialog extends NewTeacherAssignmentDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      open,
      classesListBasedOnGradeSubjectCurriculum,
      curriculumListBasedOnGradeSubject,
      teachersBasedOnGradeSubjectCurriculumList,
      selectedOtherTeachersList,
      selectedRegularTeachersList,
      isEdit,
      gradesList,
      subjectsListBasedOnGrade,
      showLoader,
      teacherAssignmentDetails,
      otherTeachersListBasedOnGradeSubjectCurriculum,
    } = this.props;
    const {
      steps,
      noOfStudents,
      noOfStudentsError,
      noOfStudentsErrorMsg,
      selectedClass,
      selectedClassError,
      selectedClassErrorMsg,
      selectedCurriculum,
      selectedCurriculumError,
      selectedCurriculumErrorMsg,
      selectedGrade,
      selectedGradeError,
      selectedGradeErrorMsg,
      selectedSubjects,
      selectedSubjectsError,
      selectedSubjectsErrorMsg,
      stepperScreen,
    } = this.state;

    return (
      <Dialog
        classes={{ paper: classes.paper }}
        data-test-id="add-edit-teacher-assignment-dialog"
        className={`${classes.dialogRoot}`}
        open={open}
        onClose={() => this.handleClosePopup()}
      >
        <Box>
          {showLoader && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <DialogTitle
            className={classes.bgColor}
            id="new-teacher-assignment-dialog-title"
          >
            <Typography
              gutterBottom
              component={"span"}
              className={`${classes.modalHeadingText} ${classes.textPrimaryColor}`}
            >
              {`${isEdit ? "Edit" : "Add New"} Teacher Assignment`}
            </Typography>
            <CloseIcon
              id="An icon to close Modal"
              className={`${classes.closeIcon} ${classes.textCommonColor}`}
              onClick={this.handleClosePopup}
            />
          </DialogTitle>
          <DialogContent style={{ padding: 0 }} className={classes.bgColor}>
            <Box>
              <CustomStepperWeb
                finishLabel="Save"
                nextBtnLabel="Next"
                stepperId="tenant-setup-teacher-assignment-stepper"
                prevBtnLabel=""
                hidePreviousBtn
                showSaveAsDraft={
                  !(isEdit && teacherAssignmentDetails?.status === "Active")
                }
                showCancelBtn
                steps={steps}
                handleCancelBtn={() => this.handleCancelStepperBtn()}
                handleNext={() => this.handleNextStepper()}
                handleSave={() => this.handleAddTeacherAssignmentDetails()}
                handleSaveAsDraft={() =>
                  this.handleAddTeacherAssignmentDetails(true)
                }
                pause={this.handlePauseStepper()}
              >
                {stepperScreen === "basic_information" && (
                  <TeacherAssignmentBasicInformation
                    selectNoOfStudents={noOfStudents}
                    selectNoOfStudentsError={noOfStudentsError}
                    selectNoOfStudentsErrorMsg={noOfStudentsErrorMsg}
                    curriculumList={curriculumListBasedOnGradeSubject}
                    subjectsList={subjectsListBasedOnGrade}
                    gradesList={gradesList}
                    classesList={classesListBasedOnGradeSubjectCurriculum}
                    selectCurriculum={selectedCurriculum}
                    selectCurriculumError={selectedCurriculumError}
                    selectCurriculumErrorMsg={selectedCurriculumErrorMsg}
                    selectGrade={selectedGrade}
                    selectGradeError={selectedGradeError}
                    selectGradeErrorMsg={selectedGradeErrorMsg}
                    selectClass={selectedClass}
                    selectClassError={selectedClassError}
                    selectClassErrorMsg={selectedClassErrorMsg}
                    selectSubjects={selectedSubjects}
                    selectSubjectError={selectedSubjectsError}
                    selectSubjectErrorMsg={selectedSubjectsErrorMsg}
                    renderClasses={(data: any) => this.renderClassValue(data)}
                    renderCurriculum={(data: any) =>
                      this.renderCurriculumValue(data)
                    }
                    renderGrade={(data: any) => this.renderGradeValue(data)}
                    handleChangeDDValue={(event: any) =>
                      this.handleChangeDropdownValue(event, {} as any)
                    }
                    handleChangeSubjects={(data: any) =>
                      this.handleChangeSubject(data)
                    }
                    handleChangeNoOfStudents={(event: any) =>
                      this.handleChange(event)
                    }
                    isLoaderOn={showLoader}
                  />
                )}
                {stepperScreen === "regular_teachers" && (
                  <RegularTeacherWeb
                    isRegularTeacher
                    handleGetTeachersList={(searchName: string) =>
                      this.props.handleGetTeachersListBasedOnGradeSubjectCurriculum(
                        selectedGrade,
                        selectedSubjects,
                        selectedCurriculum,
                        searchName
                      )
                    }
                    handleSetSelectedTeachersList={
                      this.props.handleSetSelectedRegularTeachersList
                    }
                    selectedTeachersList={selectedRegularTeachersList}
                    allTeachersList={teachersBasedOnGradeSubjectCurriculumList}
                  />
                )}
                {stepperScreen === "other_teachers" && (
                  <RegularTeacherWeb
                    handleGetTeachersList={(searchName: string) =>
                      this.props.handleGetTeachersListBasedOnGradeSubjectCurriculum(
                        selectedGrade,
                        selectedSubjects,
                        selectedCurriculum,
                        searchName
                      )
                    }
                    handleSetSelectedTeachersList={
                      this.props.handleSetSelectedOtherTeachersList
                    }
                    selectedTeachersList={selectedOtherTeachersList}
                    allTeachersList={otherTeachersListBasedOnGradeSubjectCurriculum}
                  />
                )}
              </CustomStepperWeb>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewTeacherAssignmentDialog);
// Customizable Area End
