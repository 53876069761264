// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  activityId: string;
  studentId: number;
  studentName: string;
  handleSubmitGrade: any;
  maxScore: number;
  totalScore: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentNameValue: string;
  gradeValue: string;
  gradeError: boolean;
  gradeMsg: string;
  remarkValue: string;
  remarkError: boolean;
  remarkMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddRemarkModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      studentNameValue: "",
      gradeValue: "",
      remarkValue: "",
      gradeError: false,
      gradeMsg: '',
      remarkError: false,
      remarkMsg: '',
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.handleSetStudentName();
  }
  // Customizable Area End

  handleInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    }, () => {
      if (name === "gradeValue" && value) {
        this.setState({ gradeError: false, gradeMsg: '' }, () => {
          if (value > this.props.maxScore) {
            toast.warning(`You cannot set grade value more than max score ${this.props.maxScore}`);
            this.setState({ gradeError: true });
          }
        });
      }
    });
  }

  handleEditorChange = (content: any) => {
    this.setState({ remarkValue: content });
    if (content) {
      this.setState({ remarkError: false, remarkMsg: '' });
    }
  }

  handleSetStudentName = () => {
    const { studentName, totalScore } = this.props;
    this.setState({ studentNameValue: studentName, gradeValue: totalScore.toString() });
  }

  handleSaveRemark = () => {
    if (this.checkFromValidation()) {
      const { gradeValue, remarkValue } = this.state;
      const { studentId, activityId } = this.props;
      const payload = {
        activity_id: activityId,
        student_id: studentId,
        total_marks: gradeValue,
        remarks: remarkValue,
      }
      this.props.handleSubmitGrade(payload);
    }
  }

  checkFromValidation = () => {
    let isValid = true;
    const { gradeValue, remarkValue } = this.state;

    if (!gradeValue) {
      this.setState({ gradeError: true, gradeMsg: 'Please enter grade' });
      isValid = false;
    }

    if (!remarkValue) {
      this.setState({ remarkError: true, remarkMsg: 'Please enter remark' });
      isValid = false;
    }

    return isValid;
  }
}
// Customizable Area End