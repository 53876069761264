// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  navigation?: any;
  onCancel: () => void;
  onConfirm: () => void;
  classes?: any;
  open: boolean;
  audioPosition: string;
  textPosition: string;
  audio: any;
  description: string;
  handleUpdateMediaProgressTime?: any;
  activityTitle?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AudioPreviewDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  getHeightOfVideoBox = () => {
    const audioPosition = this.props.audioPosition;
    if (audioPosition === 'right' || audioPosition === 'left') {
      return '100%';
    } else {
      return '315px';
    }
  }

  getClassToArrangeContent = () => {
    const classes = this.props.classes;
    const textPosition = this.props.textPosition;
    const audioPosition = this.props.audioPosition;
    if (audioPosition === "bottom" && textPosition === "top") {
      return `${classes.previewContent} ${classes.videoBottomTextTop}`;
    } else if (audioPosition === "top" && textPosition === "bottom") {
      return `${classes.previewContent} ${classes.videoTopTextBottom}`;
    }
  }

  getWidthOfAudioBox = () => {
    const audioPosition = this.props.audioPosition;
    if (audioPosition === 'right' || audioPosition === 'left') {
      return "500px";
    } else {
      return "100%";
    }
  }

  getWidthOfTextBox = () => {
    const audioPosition = this.props.audioPosition;
    if (audioPosition === 'right' || audioPosition === 'left') {
      return '30%';
    } else {
      return '100%';
    }
  }

  handleMediaProgressTime = () => {
    console.log("Handle media progress");
  }

  getAudioUrl = (audio: any) => {
    if (audio) {
      if (audio.url) {
        return audio.url;
      } else {
        const url = window.URL.createObjectURL(audio);
        return url;
      }
    }
  }
  // Customizable Area End

}
// Customizable Area End