Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.registrarStudentsApi = "account_block/users/get_class_based_students";
exports.registrarClassesEndpoint = "bx_block_class_management/class_managements?page=1&per_page=100";
// Customizable Area End