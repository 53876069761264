// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Input, Paper } from '@material-ui/core';
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import ViewReportCardModelController,{Props} from './ViewReportCardModelController';

// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        textEditorContent:{
            wordWrap: "break-word", 
            padding: "14px",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            maxWidth: "100%", 
            overflow: "hidden", 
          },
        customTextarea: {
            borderRadius: "12px",
            width: "96%",
            border: "1px solid #888",
            padding: "17px 15px",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "& .MuiInput-underline::before": {
                borderBottom: "0px",
                content: "none",
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "& .MuiInput-underline::after": {
                borderBottom: "0px",
            },
        },
        customInput: {
            height: "50px",
            borderRadius: "12px",
            width: "100%",
            padding: "17px 15px",
            border: "1px solid #888",
            marginTop: "10px",
            fontWeight: 400,
            fontSize: "14px",
            "&:hover:not(.Mui-)::before": {
                borderBottom: "1px solid #888",
                borderRadius: "12px",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
        },
        gridItem: {
            height:"100%",
            flex:1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius:"12px",
            cursor: 'pointer',
            boxShadow:'none',
            width:"100%"
    
          },
          contentPreview: {
            // border: "1px solid #ddd",
            borderRadius:"12px",
            // padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            overflow: "auto",
            height: "100%",
            // width:"100%"
          },
          addRemarkModal: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialogContent-root":{
                padding:0
            },
            "& .MuiDialog-paperWidthSm": {
                maxWidth: "100%",
                overflowY: "hidden",
            
                // width: "806px",
                // minHeight: "500px",
                overflowX: "hidden",
            }
        },
    })

// Customizable Area End

// Customizable Area Start
export class ViewReportCardModel extends ViewReportCardModelController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, 
            isOpen,
            handleClose,
            screenName,
            templateType,
           
            } = this.props;
        const {
            gridContent,
            gridContentBackPage,
            isFrontPage
        } = this.state;
        const contentToDisplay = isFrontPage ? gridContent:gridContentBackPage;

        return (
            <Box>
              <Dialog
                className={`${classes.highlightBgColor} ${classes.addRemarkModal}`}
                aria-labelledby="quiz-view-answer-confirm-dialog-title"
                open={isOpen}
                scroll={'body'}
                >
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Grid direction="row" container>
                    <Grid xs={12} item>
                        <Typography
                        className={`${classes.fontText24} ${classes.textPrimaryColor}`}
                        component={"span"}
                        >
                        View Report Card
                        </Typography>
                        <Button onClick={handleClose} className={classes.textPrimaryColor} style={{ float: "right" }}> X </Button>
                    </Grid>
                    </Grid>
                    <DialogContent style={{ marginTop: "24px", overflow: "hidden" }}>
                    <Grid container spacing={2} direction="row">
                        <Grid xs={12} item>
                        <Box marginBottom={"10px"} width={"100%"}>
                            <Grid container spacing={3} className={classes.contentPreview}>
                            {contentToDisplay.map((item: any, index) => {
                                if (item.content !== "+Edit") {
                                return (
                                    <Grid item xs={12} sm={templateType !== "portrait_template" ? 6 : 12} key={index} style={{display:"flex"}}>
                                         <Paper
                                                className={classes.gridItem + ' ' + classes.secondaryBgColor }>
                                    {["StudentInformation", "Attendance", "Behavior", "Grades"].includes(item.blockType) ? (
                                        <>{item.content}</>
                                    ) : (
                                        <Typography
                                        dangerouslySetInnerHTML={{ __html: item.content }}
                                        className={`${classes.commentText} ${classes.textEditorContent} ${classes.textCommonColor}`}
                                        >
                                        </Typography>
                                    )}
                                    </Paper>
                                    </Grid>
                                );
                                } else {
                                return (
                                    <Grid item xs={12} sm={6} key={index}>
                                       <Paper
                                        className={classes.gridItem + ' ' + classes.secondaryBgColor }>
                                        </Paper>
                                    </Grid>
                                );
                                }
                            })}
                            </Grid>
                        </Box>
                        </Grid>
                        <Grid item xs={12}>
                        <Box style={{ width: "100%" }} marginBottom={"10px"}>
                            <CustomButton
                            btnText="Close"
                            btnStyle={saveButtonStyle}
                          
                            buttonVariant={"outlined"}
                            buttonId={`add-remark-cancel-button`}
                            type={"button"}
                            handleButtonClick={handleClose}
                            isDisabled={false}
                            />
                            <CustomButton
                            btnText={this.state.isFrontPage ? "Next" : "Previous"}
                            btnStyle={cancelButtonStyle}
                            buttonVariant={"contained"}
                            buttonId={`add-remark-cancel-button`}
                            type={"button"}
                            handleButtonClick={this.togglePage}
                            isDisabled={false}
                            />
                        </Box>
                        </Grid>
                    </Grid>
                    </DialogContent>
                </Box>
                </Dialog>
            </Box>
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    width: "120px",
    padding: "10px 10px",
    height: "50px",
    borderRadius: "12px",
    fontSize: "18px",
    marginTop: "10px",
    textAlign: "center",
    float: "left",
    fontWeight: 500,
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ViewReportCardModel);
// Customizable Area End
