// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Divider,
} from "@material-ui/core";
import CatchMeUpModalController, {
  Props,
} from "./CatchMeUpModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { CatchMeUpModalSuccess } from "./CatchMeUpModalSuccess.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import { blue } from "@material-ui/core/colors";
import { informationIcon } from "./assets";
import moment from "moment";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    DateName: {
      display: "flex",
      flexDirection: "column",
    },
    topSpacing: {
      marginTop: "22px"
    },
    ProjectedEnd: {
      backgroundColor: "#f2faff",
      height: "50px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
    },
    bottomSpacing: {
      marginBottom: "10px"
    },
  });
const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiDialog: {
      paper: {
        width: "880px",
      },
      paperWidthSm: {
        maxWidth: "880px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class CatchMeUpModal extends CatchMeUpModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.schoolDayGetApi = this.schoolDayGetApi.bind(this)
  }
  async componentDidMount(){
    await this.schoolDayGetApi(this.props.selectedClassComponentDDValue)
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, open } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          open={open}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          style={{ width: "100%", overflow: "auto" }}
        >
          <Box padding={"21px 30px 30px 30px"} className={`${classes.bgColor}`}>
            <Grid direction="row" container>
              <Grid xs={12} item>
                <Typography
                  className={`${classes.fontText24} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  Catch Me Up
                </Typography>
                <IconButton
                  data-test-id="crossCatch"
                  style={{ float: "right" }}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent
              style={{
                padding: "0px",
                marginTop: "24px",
                overflow: "hidden",
              }}
            >
              <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                  <Box className={classes.DateName}>
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText14} ${classes.bottomSpacing}`}
                    >
                      {`Current Date`}
                    </Typography>
                    <div className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          onChange={this.selectStartDate}
                          selected={this.state.selectStartDate}
                          popperPlacement="auto"
                          popperClassName="Catch-datepicker-class"
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.DateName}>
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText14}`}
                      style={{ marginBottom: "10px" }}
                    >
                      {`End Date`}
                    </Typography>
                    <div className="DateContainers">
                      <span className="dateInput">
                        {" "}
                        <DatePicker
                          onChange={this.selectEndDate}
                          popperPlacement="left-start"
                          popperClassName="example-datepicker-class"
                          selected={this.state.selectEndDate}
                        />
                      </span>
                      <span className="icon">
                        <CalendarIcon />
                      </span>
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box pl={"12px"}  className={classes.ProjectedEnd}>
                    <Typography
                      className={`${classes.fontText14} `}
                      component={"span"}
                      style={{ color: "#009DFF" }}
                    >
                      {`Projected End Date: ${moment(this.state.selectShiftLessonsEndDate).format("MMM D, YYYY")}`}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  <Box marginBottom={"10px"} style={{ width: "100%" }}>
                    <CustomButton
                      btnText="Save"
                      buttonVariant={"contained"}
                      btnStyle={nextButton}
                      type={"button"}
                      data-testId="catchme-button"
                      buttonId={`my-assessment-search-mobile`}
                      handleButtonClick={this.handleOpenSuccessDialog}
                      isDisabled={false}
                    />
                    <CustomButton
                      btnStyle={cancelButton}
                      buttonVariant={"outlined"}
                      type={"button"}
                      btnText="Cancel"
                      handleButtonClick={this.props.onCancel}
                      isDisabled={false}
                      buttonId={`my-assessment-search-mobile`}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
          <CatchMeUpModalSuccess
            navigation={this.props.navigation}
            onCancel={this.handleCloseSuccessDialog}
            onConfirm={() => { }}
            open={this.state.openSuccessDialog}
            classes={this.props.classes}
          />
        </Dialog>
      </ThemeProvider >
    );
  }
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
const cancelButton = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const nextButton = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const lessonDDStyles = {
  width: "100%",
  borderRadius: '8px',
  height: '48px',
  color: "#888888",
  background:"transparent"
} as const;







const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CatchMeUpModal);
// Customizable Area End
