import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
  psychologySubjectIcon,
} from "../src/assets";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { FIELD_TYPES } from "../../../components/src/CommonType.web";

const customBrandingDetailsInputConfigMock = [
  {
    id: "tenant-custom-branding-company-name",
    name: "customCompanyName",
    fieldName: "customCompanyName",
    value: "",
    menuItems: [],
    label: "Company",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Company Name",
  },
  {
    id: "tenant-custom-branding-address",
    name: "customAddress",
    fieldName: "customAddress",
    value: "",
    menuItems: [],
    label: "Address",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Address",
  },
  {
    id: "tenant-custom-branding-contact-number",
    name: "customContactNumber",
    fieldName: "customContactNumber",
    value: "",
    menuItems: [],
    label: "Contact Number",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Contact Number",
  },
  {
    id: "tenant-custom-branding-email",
    name: "customEmail",
    fieldName: "customEmail",
    value: "",
    menuItems: [],
    label: "Email",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Email",
  },
  {
    id: "tenant-custom-branding-website",
    name: "customWebsite",
    fieldName: "customWebsite",
    value: "",
    menuItems: [],
    label: "Website",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Website",
  },
];

const availableSubjectListMock = [
  {
    subject: {
      name: "History",
      icon: historySubjectIcon,
      bgColor: "#FBD82C",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    color: "#FBD82C",
    id: "History",
    iconName: "history.png",
  },
  {
    id: "Maths",
    subject: {
      name: "Maths",
      icon: mathSubjectIcon,
      bgColor: "#0D46BC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    color: "#0D46BC",
    iconName: "maths.png",
  },
  {
    subject: {
      name: "Science",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "Science",
    color: "#01C996",
    iconName: "science.png",
  },
  {
    subject: {
      name: "English",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "English",
    color: "#019AFC",
    iconName: "english.png",
  },
  {
    subject: {
      name: "Psychology",
      icon: psychologySubjectIcon,
      bgColor: "#C10A1B",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    color: "#C10A1B",
    id: "Psychology",
    iconName: "psychology.png",
  },
];

const subjectTabTableHeaderMock = [
  {
    id: "subject",
    columnId: 1,
    label: "Subject Name",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
  },
  {
    id: "color",
    columnId: 2,
    label: "Color",
    type: tableColumnTypes.COLOR_CIRCLE,
  },
  {
    id: "icon",
    columnId: 1,
    label: "Icon",
    type: tableColumnTypes.ICON,
  },
];

const progressRange1Mock = [
  {
    id: "tenant-appearance-mng-progress-range-1-percentage-from",
    name: "range1PercentageFrom",
    fieldName: "range1PercentageFrom",
    value: "",
    menuItems: [],
    label: "Percentage From",
    fieldType: FIELD_TYPES.TEXT,
    isDisabled:false,
    placeHolderText: "Enter Percentage From",
  },
  {
    id: "tenant-appearance-mng-progress-range-1-percentage-to",
    name: "range1PercentageTo",
    fieldName: "range1PercentageTo",
    value: "",
    menuItems: [],
    label: "Percentage To",
    isDisabled:false,
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Percentage To",
  },
  {
    id: "tenant-appearance-mng-progress-range-1-color",
    name: "range1Color",
    fieldName: "",
    value: "",
    menuItems: [],
    label: "Color",
    fieldType: FIELD_TYPES.COLOR,
    isDisabled:false,
    placeHolderText: "Select Color",
  },
];

const progressRange2Mock = [
  {
    id: "tenant-appearance-mng-progress-range-2-percentage-from",
    name: "range2PercentageFrom",
    fieldName: "range2PercentageFrom",
    value: "",
    menuItems: [],
    label: "Percentage From",
    fieldType: FIELD_TYPES.TEXT,
    isDisabled:false,
    placeHolderText: "Enter Percentage From",
  },
  {
    id: "tenant-appearance-mng-progress-range-2-percentage-to",
    name: "range2PercentageTo",
    fieldName: "range2PercentageTo",
    value: "",
    menuItems: [],
    label: "Percentage To",
    fieldType: FIELD_TYPES.TEXT,
    isDisabled:false,
    placeHolderText: "Enter Percentage To",
  },
  {
    id: "tenant-appearance-mng-progress-range-2-color",
    name: "range2Color",
    fieldName: "",
    value: "",
    menuItems: [],
    label: "Color",
    fieldType: FIELD_TYPES.COLOR,
    isDisabled:false,
    placeHolderText: "Select Color",
  },
];

const progressRange3Mock = [
  {
    id: "tenant-appearance-mng-progress-range-3-percentage-from",
    name: "range3PercentageFrom",
    fieldName: "range3PercentageFrom",
    value: "",
    menuItems: [],
    label: "Percentage From",
    fieldType: FIELD_TYPES.TEXT,
    isDisabled:false,
    placeHolderText: "Enter Percentage From",
  },
  {
    id: "tenant-appearance-mng-progress-range-3-percentage-to",
    name: "range3PercentageTo",
    fieldName: "range3PercentageTo",
    value: "",
    menuItems: [],
    label: "Percentage To",
    fieldType: FIELD_TYPES.TEXT,
    isDisabled:false,
    placeHolderText: "Enter Percentage To",
  },
  {
    id: "tenant-appearance-mng-progress-range-3-color",
    name: "range3Color",
    fieldName: "",
    value: "",
    menuItems: [],
    label: "Color",
    fieldType: FIELD_TYPES.COLOR,
    isDisabled:false,
    placeHolderText: "Select Color",
    
  },
];

const accentColors = [
  {
    id: "1",
    color: "#BCA700",
  },
  {
    id: "2",
    color: "#0D45BC",
  },
  {
    id: "3",
    color: "#2415A6",
  },
  {
    id: "4",
    color: "#2FBC0D",
  },
  {
    id: "5",
    color: "#0AB395",
  },
  {
    id: "6",
    color: "#0BB295",
  },
  {
    id: "7",
    color: "#B35F23",
  },
  {
    id: "8",
    color: "#A21BA9",
  },
  {
    id: "9",
    color: "#1C84A9",
  },
  {
    id: "10",
    color: "#762C67",
  },
  {
    id: "11",
    color: "#BB0D0C",
  },
];

const highlightColors = [
  {
    id: "1",
    color: "#3CC1FF",
  },
  {
    id: "2",
    color: "#FFD92E",
  },
  {
    id: "3",
    color: "#BEB6FF",
  },
  {
    id: "4",
    color: "#53FD32",
  },
  {
    id: "5",
    color: "#FF7B7F",
  },
  {
    id: "6",
    color: "#FE8C8A",
  },
  {
    id: "7",
    color: "#FE8D3F",
  },
  {
    id: "8",
    color: "#89F8A8",
  },
  {
    id: "9",
    color: "#A2E0FF",
  },
  {
    id: "10",
    color: "#66D9FF",
  },
  {
    id: "11",
    color: "#FB9DEB",
  },
];

export {
  customBrandingDetailsInputConfigMock,
  availableSubjectListMock,
  subjectTabTableHeaderMock,
  progressRange1Mock,
  progressRange2Mock,
  progressRange3Mock,
  accentColors,
  highlightColors
};
