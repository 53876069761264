// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  coursesDetailsMock,
  fileStorageDetailsMock,
  topTenCoursesListMock,
  userTypesListMock,
} from "../assets/tenantDashboardMock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userTypesList: Array<any>;
  topTenCoursesList: Array<any>;
  coursesDetails: Array<any>;
  fileStorageDetails: Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantStatisticsDashboardTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTenantStatisticsInfo: string = "";
  apiGetTenantStatisticsTabDetails:string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      userTypesList: [],
      coursesDetails: [],
      fileStorageDetails: [],
      topTenCoursesList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetStatisticsTabApi()
    // Customizable Area End
  }

  // Customizable Area Start
  setStatisticsData = () => {
    const { userTypesList } = this.state;
    const updatedUserTypesList = userTypesList.map((item) => {
      item.total = `${item.active + item.inactive} / ${
        item.total_count
      }`;
      return item;
    });
    this.setState({ userTypesList: updatedUserTypesList });
  }; 
  handleUserTypeList =(responseJson: { attributes: { [x: string]: any; }; })=>{
    const includedKeys:any = {
      students: "Students",
      teachers: "Teachers",
      parent_teachers: "Parent Teachers",
      registrar: "Registrars"
    };

    const typeForCapacity:any = {
      parent_teachers: "Parent Teachers",
      students: "Student",
      registrar: "Registrar",
      teachers: "Teacher",
    };
    
    const userTypesListMock = Object.keys(responseJson.attributes)
      .filter(key => includedKeys.hasOwnProperty(key))
      .map(key => {
        const data = responseJson.attributes[key];
        const type = includedKeys[key];
        const typeCap = typeForCapacity[key]
        const activeKey = `Active ${type}`;
        const inactiveKey = `Inactive ${type}`;
        const newKey = `New ${type}`;
        const totalKey = `Total ${type}`;
        const capacity = `${typeCap} Capacity`
        return {
          id: key,
          user_type: type,
          active: data[activeKey],
          inactive: data[inactiveKey],
          new: data[newKey],
          total_count: data[totalKey],
          total: `${data[totalKey]} / ${data[capacity]}` 
        };
      });
      return userTypesListMock;
  }
  handleCourseDetails=(courses: { [x: string]: any; })=>{
    const coursesDetailsMock = [
      {
        label: "Total",
        count: courses["Total Courses"],
      },
      {
        label: "For Approval",
        count: courses["For Approval Courses"],
      },
      {
        label: "Archived",
        count: courses["Archieved Courses"],
      },
      {
        label: "Active",
        count: courses["Active Course"],
      },
      {
        label: "Draft",
        count: courses["Draft Courses"],
      },
    ];
    return coursesDetailsMock;
  }
  handleFileStorageDetails=(file_storage: any[])=>{
    const getUserStorageDetails = (user: string) => {
      const userData = file_storage.find((item) => item.user === user);
      return userData ? `${userData.total_storage}GB ${userData.percentage_used}% used` : "0GB 0% used"; // Fallback in case userData is not found
    };
    
    const fileStorageDetailsMock = [
      { label: "Free", count: getUserStorageDetails("Free") },
      { label: "Teacher", count: getUserStorageDetails("Teacher") },
      { label: "Parent Teacher", count: getUserStorageDetails("Parent Teacher") },
      { label: "Tenant Admin", count: getUserStorageDetails("Tenant Admin") },
      { label: "Students", count: getUserStorageDetails("Student") },
    ];
    return fileStorageDetailsMock
  }
  handleTopTenCourse=(top_10_courses: { Subject: {image:string; name: string | number; color: any; icon: string; }; Course: { course_title: any; }; Lesson_Count: any; Students_Count: any; }[])=>{
    const topTenCoursesListMock = top_10_courses.map((course: { Subject: { name: string | number; color: any;icon:string ;image:string}; Course: { course_title: any; }; Lesson_Count: any; Students_Count: any; }) => ({
      subject: {
        name: course.Course.course_title,
        icon: course.Subject.icon || "defaultIcon",
        bgColor: course.Subject.color || "#000",
        boxWidth: "28px",
        boxHeight: "28px"
      },
      id: `${course.Subject.name}: ${course.Course.course_title}`,
      noOfLessons: course.Lesson_Count,
      noOfParticipants: course.Students_Count
    }));
    return topTenCoursesListMock
  }
  handleSetTenantStatisticsInfo(responseJson:any){
    const courses = responseJson.attributes.courses;
    const file_storage = responseJson.attributes.file_storage;
    const top_10_courses = responseJson.attributes.top_10_courses
    const userTypesListMock = this.handleUserTypeList(responseJson)
    const coursesDetailsMock = this.handleCourseDetails(courses)
    const fileStorageDetailsMock = this.handleFileStorageDetails(file_storage)
    const topTenCoursesListMock = this.handleTopTenCourse(top_10_courses)
    
    this.setState({
      topTenCoursesList:topTenCoursesListMock,
      userTypesList:userTypesListMock,
      coursesDetails:coursesDetailsMock,
      fileStorageDetails:fileStorageDetailsMock
    })
  }
  handleGetStatisticsTabApi = async () => {
    showCustomLoader();
    let requestUrl = `${configJSON.GetDashboardStatisticsEndpoint}`
    const token = await getStorageData("token");
    const header = {
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTenantStatisticsTabDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetStatisticsTabResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetTenantStatisticsInfo(responseJson.data);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.apiGetTenantStatisticsTabDetails) {
            this.handleGetStatisticsTabResponse(responseJson,errorResponse)
          }
        }
      }
    }
  // Customizable Area End
}
// Customizable Area End
