// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  selectedClassComponentDDValue?:any;
  cancle?:boolean;
  allClasses?:any[]
  isParentTeacher?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseTitle: string;
  openConfirmDialog: boolean;
  openSuccessDialog: boolean;
  selectedValue: any;
  modalFlagState:{School:boolean,Catch:boolean,shift:boolean, MainModal:boolean};
  openCatchUpModal:boolean;
  selectedClassName:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ReschedullingModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      selectedCourseTitle: "",
      openConfirmDialog: false,
      openSuccessDialog: false,
      selectedValue: null,
      openCatchUpModal:false,
      modalFlagState:{School:false,Catch:false,shift:false,MainModal:true},
      selectedClassName:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    console.log("hello world",this.props.selectedClassComponentDDValue , this.props.allClasses)
    if(this.props.selectedClassComponentDDValue && this.props.allClasses){
      const selectedGradingComponents = this.props.allClasses.find(
        (grade: any) => grade?.id === this.props.selectedClassComponentDDValue
      );
     
      this.setState({selectedClassName:selectedGradingComponents.label}) 
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
     if (prevProps.cancle !== this.props.cancle) {
         this.setState({selectedValue:''})
     }
  }
  
  submitReschedullingModal = () =>{
    if(this.state.selectedValue === null){
      toast.warning('Please select one Reschedule type')
    }else{
      this.setState({modalFlagState:{School:false,Catch:false,shift:false,MainModal:false,[this.state.selectedValue]:true}})
    }
   
  }

  closeReschedullingModal = () =>{
    this.props.onCancel()
    setTimeout(() => {
      this.setState({modalFlagState:{School:false,Catch:false,shift:false,MainModal:true}})
    }, 1000);
  }

  handleChange =async (event: any) => {
    this.setState({
      selectedValue: event.target.value
    })
  };
  selectBox=async(param:any)=>{
    if (param) {
      param.click();
    }
  }
  // Customizable Area End
}
// Customizable Area End