// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  fieldId: string;
  itemId: any;
  handleClick: any;
  isShowBtnOnHoverEffect?: boolean;
  showOutlineBorderOnSelect?: boolean;
  handleMouseEnter?: any;
  handleMouseLeave?: any;
  isSelected?: boolean;
  widthOfCard: string;
  heightOfCard: string;
  hoverButtonText: string;
  hoverButtonText2: string;
  isHoverBtnDisabled?: boolean;
  hoverButtonHandle: any;
  hoverButtonStyle: any;
  hoverButtonStyle2?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  hoveredCardId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomCardWithHoverEffectController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      hoveredCardId: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleMouseEnter = () => {
    const { itemId } = this.props;
    this.setState({ hoveredCardId: itemId });
  };
  handleMouseLeave = () => {
    this.setState({ hoveredCardId: null });
  };

  handleCardBodyClick = () => {
    const { isShowBtnOnHoverEffect } = this.props;
    if (!isShowBtnOnHoverEffect) {
      this.props.handleClick();
    }
  };
  // Customizable Area End
}
