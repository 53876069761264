// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  isEdit: boolean;
  open: boolean;
  handleAddNewTopic: any;
  handleUpdateTopic: any;
  topicId?: number;
  courseId?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  topicTitleValue: string;
  topicTitleError: boolean;
  topicTitleMsg: string;
  subTopicValue: string;
  descriptionValue: string;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherAddNewTopicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTopicDataById: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      topicTitleValue: "",
      topicTitleError: false,
      topicTitleMsg: "",
      subTopicValue: "",
      descriptionValue: "",
      descriptionError: false,
      descriptionErrorMsg: "",
      loading: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null && apiRequestCallId === this.apiGetTopicDataById) {
        this.handleGetTopicDataByIdResponse(responseJson, errorResponse);
      }
      // Customizable Area End
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    if (this.props.isEdit) {
      this.handleGetTopicDataByIdApi();
    }
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (name === "topicTitleValue" && value) {
      this.setState({ topicTitleError: false, topicTitleMsg: "" });
    }

    if (name === "descriptionValue" && value) {
      this.setState({ descriptionError: false, descriptionErrorMsg: "" });
    }
  }

  handleSubmitForm = () => {
    if (this.handleFormValidation()) {
      const {
        topicTitleValue,
        subTopicValue,
        descriptionValue
      } = this.state;

      const requestBody = {
        topic_title: topicTitleValue,
        sub_topic: subTopicValue,
        description: descriptionValue,
      };

      if (this.props.isEdit) {
        this.props.handleUpdateTopic(requestBody);
      } else {
        this.props.handleAddNewTopic(requestBody);
      }
    }
  }

  handleFormValidation = () => {
    let isValid = true;
    const { topicTitleValue, descriptionValue } = this.state;

    if (!topicTitleValue.trim()) {
      this.setState({ topicTitleError: true, topicTitleMsg: "Please enter topic title" });
      isValid = false;
    }

    if (!descriptionValue.trim()) {
      this.setState({ descriptionError: true, descriptionErrorMsg: "Please enter description", });
      isValid = false;
    }

    return isValid;
  }

  handleGetTopicDataByIdApi = async () => {
    this.showModalLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTopicDataById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewTopic}/${this.props.topicId}?course_id=${this.props.courseId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTopicDataByIdResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const topic = responseJson?.data?.attributes;
      this.setState({
        topicTitleValue: topic.topic_title,
        subTopicValue: topic.sub_topic,
        descriptionValue: topic.description,
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    this.hideModalLoader();
  };

  showModalLoader = () => {
    this.setState({ loading: true });
  }

  hideModalLoader = () => {
    this.setState({ loading: false });
  }
  // Customizable Area End
}
// Customizable Area End
