// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import RejectedRequestsTabController, {
  Props
} from "./RejectedRequestsTabController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import CustomTable, { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "../assets";

// Customizable Area End
const rejectedRequestsTableColumns = [
  {
    columnId: 1,
    id: "Title",
    label: "Title",
    type: tableColumnTypes.COURSES_NAME

  },
  {
    columnId: 2,
    id: "Subject",
    label: "Subject",
    type: tableColumnTypes.TEXT_AUTO,
    width: "100px",
  },
  {
    columnId: 3,
    id: "Grade",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Grade",
    width: "100px",
  },
  {
    id: "RequestedOn",
    columnId: 4,
    label: "Requested On",
    type: tableColumnTypes.TEXT_AUTO,
    width: "150px",
  },
  {
    columnId: 5,
    id: "RejectedOn",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Rejected On",
    width: "150px",
  },
  {
    columnId: 6,
    id: "CreatedOn",
    label: "Created On",
    type: tableColumnTypes.TEXT_AUTO,
    width: "150px",
  },
];

const rejectedRequestsData = [
  {
    id: 1,
    Title: {
      name: "Velocity and Acceleration",
      icon: ScienceColor,
    },
    Subject: 'Science',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 2,
    Title: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject: 'Maths',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 3,
    Title: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject: 'History',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 4,
    Title: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 5,
    Title: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 6,
    Title: {
      name: "English Poetry",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 7,
    Title: {
      name: "Velocity and Accel…",
      icon: ScienceColor,
    },
    Subject: 'Science',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 8,
    Title: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject: 'Maths',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
  {
    id: 9,
    Title: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject: 'History',
    Grade: 'Grade 3',
    RequestedOn: 'July 20, 2022',
    RejectedOn: 'July 20, 2022',
    CreatedOn: 'July 20, 2022',
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    rejectedRequestsTableRow: {
      "& td": {
        paddingTop: "0px",
        paddingBottom: "0px",
        height: "50px",
      }
    }
  });

// Customizable Area End

// Customizable Area Start
export class RejectedRequestsTab extends RejectedRequestsTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedTitleValue,
      selectedSubjectValue,
      selectedGradeValue,
      selectedRequestedOnValue,
      selectedAccpetedOnValue
    } = this.state;

    return (
      <Box style={{ padding: "12px 25px 25px 25px" }}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`}>
                  Title
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectedTitleValue"
                  fieldId="titleDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  fieldValue={selectedTitleValue}
                  fieldError={false}
                  renderValue={this.renderTitleDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldStyles={dropdownStyle}
                  fieldMenuItems={this.getDropdownItemsArray("Title")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Requested On
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="requestedOnDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  fieldName="selectedRequestedOnValue"
                  fieldError={false}
                  fieldValue={selectedRequestedOnValue}
                  fieldStyles={dropdownStyle}
                  renderValue={this.renderRequestedOnDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("July")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                  Rejected On
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="rejectedOnDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  fieldName="selectedAccpetedOnValue"
                  fieldValue={selectedAccpetedOnValue}
                  fieldError={false}
                  fieldStyles={dropdownStyle}
                  renderValue={this.renderRejectedOnDropdownValue}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                  fieldMenuItems={this.getDropdownItemsArray("August")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>
                  Subject
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectedSubjectValue"
                  fieldValue={selectedSubjectValue}
                  fieldId="subjectDropdown"
                  handleChange={this.handleChangeDropdownValue}
                  fieldError={false}
                  renderValue={this.renderSubjectDropdownValue}
                  fieldStyles={dropdownStyle}
                  fieldMenuItems={this.getDropdownItemsArray("Subject")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <Box marginBottom={"8px"}>
                <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>
                  Grade
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldId="gradeDropdown"
                  fieldValue={selectedGradeValue} fieldError={false}
                  fieldName="selectedGradeValue"
                  renderValue={this.renderGradeDropdownValue} fieldStyles={dropdownStyle}
                  handleChange={this.handleChangeDropdownValue}
                  fieldMenuItems={this.getDropdownItemsArray("Grade")}
                  iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box marginBottom={"10px"}>
              <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`requestsTabSearchButton`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleRejectedRequestsSearch} isDisabled={false} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.dividerLine} />
        <Grid container>
          <Grid item xs={12}>
            <Box className={`${classes.customTableStyles} ${classes.rejectedRequestsTableRow}`}>
              <CustomTable
                tableId={"rejectedRequestesTable"}
                tableHeaderColumns={rejectedRequestsTableColumns}
                tableBodyData={rejectedRequestsData}
                showMenuIcon={true}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px"
} as const;

const dropdownStyle = {
  width: "100%"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(RejectedRequestsTab);
// Customizable Area End
