import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography , Box } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width:"75%"
  }
}))

const getProgressBarColor = (progressPercentage: number) => {
  if (progressPercentage <= 10) {
    return "#FF0000"
  }
  else if (progressPercentage > 10 && progressPercentage < 50) {
    return "#FC9301"
  }
  else return "#01C996"
}

const getBarSecondaryBackgroundColor = (progressPercentage : number, alpha : number) => {
  const hex = getProgressBarColor(progressPercentage);
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

interface IProps { progressPercentage: number, barColor: string , customClasses?:any };

export const CustomLinearProgressBar: React.FC<IProps> = (props) => {
  const { progressPercentage, customClasses } = props;
  const classes = useStyles();

  const barStyles = makeStyles(() => ({
    barPrimaryColor: {
     backgroundColor : getProgressBarColor(progressPercentage),
     borderRadius:5
    },
    colorPrimary:{
      backgroundColor : getBarSecondaryBackgroundColor(progressPercentage,0.27)
    }
  }));

  const barClasses = barStyles();

  return (
    <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
      <LinearProgress classes={{
        root: classes.root,
        barColorPrimary: barClasses.barPrimaryColor,
        colorPrimary:barClasses.colorPrimary
      }} variant="determinate" value={progressPercentage} />
      <Typography className={`${customClasses?.fontSize15} ${customClasses?.textPrimaryColor}`} style={{paddingLeft:"7px",width:"70px"}} component={"span"}>
          {`${progressPercentage}%`}
      </Typography>
    </Box>
  );
}

export default CustomLinearProgressBar;