// Customizable Area Start
import React from "react";
import { Checkbox } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomCheckBoxComponentController, { Props } from "./CustomCheckBoxComponentController.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        checkBox: {
            borderRadius: "8px",
            padding:"5px",
            "&.MuiCheckbox-colorSecondary.Mui-checked":{
                color: "#FBD82C",
            },
            "&.select-record.Mui-checked":{
                color: "#FBD82C",
                padding: "0 5px",
            },
            "&.select-record.Mui-checked svg":{
                height: "26px",
                width: "26px"
            },
        },
    })

// Customizable Area End

export class CustomCheckBoxComponent extends CustomCheckBoxComponentController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { fieldId, checked, uncheckIcon , classes, customClass, styles ,disabled} = this.props;
        return (
            <Checkbox 
            id={fieldId}
            icon={uncheckIcon}
            disabled={disabled}
            className={`${classes.checkBox} ${customClass}`}
            checked={checked} 
            onChange={this.props.handleChange}
            style={styles}
            />
        );
        // Customizable Area End
    }

}


// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomCheckBoxComponent);
// Customizable Area End