// Customizable Area Start
import React from "react";
import { Box, Grid, Tabs, Tab, CircularProgress } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantCourseMngCreateCatalogStep2Controller, {
  Props,
  step2TabsList,
} from "./TenantCourseMngCreateCatalogStep2Controller.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import TenantCourseManagementMainTabComponentWeb from "./TenantCourseManagementMainTabComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    createCatalogStep2SelectDD: {
      marginBottom: "10px",
    },
    createCatalogStep2SelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    createCatalogStep2MainGridItem: {
      borderBottom: "4px solid #f0f0f0",
      padding: "24px 26px 24px 22px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "50%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    step2MainGrid: {
      padding: "15px 20px 0px 20px",
    },
    tabPanelGrid: {
      paddingTop: "20px",
    },
    borderRadius: {
      borderRadius: "12px"
    },
    customLoader: {
      height: "160%",
      position: "absolute",
      display: "block",
      top: 0,
      left: 0,
      bottom: -30,
      right: 0,
      zIndex: 999,
      backgroundColor: "rgb(0, 0, 0, .4)",
    },
    circularContainer: {
      transform: "translate(-50%, -50%)",
      position: "absolute",
      top: "40%",
      left: "50%",
    },
  });
// Customizable Area End

export class TenantCourseMngCreateCatalogStep2 extends TenantCourseMngCreateCatalogStep2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      loading,
      selectedStep2TabIndex,
      tableHeaderColumns,
      step2InputConfigs,
      availableCoursesListData,
      availableCoursePagination,
      addedCoursePagination,
      filteredCoursesList,
    } = this.state;
    return (
      <Box className={`${classes.step2MainGrid}`}>
        <Grid
          className={`${classes.secondaryBgColor} ${classes.borderRadius}`}
          container
          direction="row"
        >
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Grid item xs={12}>
            <Tabs
              value={selectedStep2TabIndex}
              variant="scrollable"
              TabIndicatorProps={{
                style: {
                  background: "none",
                },
              }}
              scrollButtons="auto"
              data-testid="tenant-course-management-create-catalog-step-2-tabs"
              className={`${classes.tabs}`}
              onChange={this.handleChangeStep2Tab}
              aria-label="tenant-course-management-create-catalog-step-2-tabs"
            >
              {step2TabsList.map((tabItem: any, index: number) => (
                <Tab
                  key={tabItem.id}
                  data-testid={`tenant-course-management-create-catalog-step-2-${tabItem.label}-tab`}
                  label={tabItem.label}
                  wrapped
                  className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid className={`${classes.tabPanelGrid}`} item xs={12}>
            <TabPanel value={selectedStep2TabIndex} index={0}>
              <TenantCourseManagementMainTabComponentWeb
                tenantCourseMngTabCategory="available_courses"
                handleSelectDropdownValue={this.handleStep2SelectDropdownValue}
                inputDDConfigs={step2InputConfigs}
                handleSearch={this.handleAvailableCourseSearch}
                renderDDMenuItem={this.renderStep2DDMenuItem}
                tableBodyData={availableCoursesListData}
                showTablePagination
                tableHeaderColumns={tableHeaderColumns}
                showMenuIconInTable={false}
                pagination={availableCoursePagination}
                getPaginationNumber={(page: number) => this.handleAvailableCoursePagination(page)}
              />
            </TabPanel>
            <TabPanel value={selectedStep2TabIndex} index={1}>
              <TenantCourseManagementMainTabComponentWeb
                handleSelectDropdownValue={this.handleStep2SelectDropdownValue}
                tenantCourseMngTabCategory="added_courses"
                handleSearch={this.handleAddedCourseSearch}
                renderDDMenuItem={this.renderStep2DDMenuItem}
                tableBodyData={filteredCoursesList}
                inputDDConfigs={step2InputConfigs}
                showTablePagination
                tableHeaderColumns={tableHeaderColumns}
                showMenuIconInTable={false}
                pagination={addedCoursePagination}
                getPaginationNumber={(page: number) => this.handleAddedCoursePagination(page)}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantCourseMngCreateCatalogStep2);
// Customizable Area End
