Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "smartcategorisation";
exports.labelBodyText = "smartcategorisation Body";
exports.categoryListApiEndPoint = 'bx_block_smartcategorisation/smartcategorisations/task_categorisation?start_date={startDate}&end_date={endDate}&filter_status={filterStatus}&page={pageNo}&limit={taskLimit}'
exports.categoryListApiMethod = 'GET';
exports.categoryListApiContentType = 'application/json';
exports.markAsCompleteApiEndPoint = 'bx_block_smartcategorisation/smartcategorisations/task_update?id='
exports.putApiMethod = 'PUT';
exports.apiContentType = 'application/json';


exports.btnExampleTitle = "CLICK ME";
exports.activeTab = 'today';
exports.categories = [{
  dueHeader: 'past_dues', title: 'Yesterday', color: '#FC4F00', errorrMsg:'No dues for yesterday.'
},{
  dueHeader: 'today', title: 'Today',color: 'orange',errorrMsg:'No dues for Today.'
},{
  dueHeader: 'tomorrow', title: 'Tomorrow', color:'#F0A04B',errorrMsg:'No dues for Tomorrow.'
},{
  dueHeader: 'future_dues', title: 'Custom', color:'#ABC270', errorrMsg:'No dues for selected date range.'
}]
exports.emptyTask = {
  "account_id":0,
  "assigned_to": 0,
  "created_at": '',
  "description": '',
  "due_date": '',
  "id": 0,
  "priority": '', 
  status: '', 
  "task_list_ids": [], 
  title: '', 
  "updated_at": '',
};
exports.errorMsgs = ['No dues for yesterday.','No dues for Today.','No dues for Tomorrow.','No dues for selected date range.']
exports.completedStatus = 'complete'
exports.filterStatus = 'completed'
exports.completedTaskErrMsg = "Didn't found completed task!"
exports.toggleBtnText = "show completed tasks"
exports.toggleText = "View Completed Tasks"
exports.noMoreTaskFooter="No more tasks due at the moment."
// Customizable Area End