// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const VideoTopTextBottomIcon: any = (props: any) => {
    return (
        <SvgIcon style={{ width: "45px", height: "70px" }} {...props}>
            <rect x="-1.5" y="0" width="40" height="70" rx="3" strokeWidth="1" fill={props.fill} stroke={props.stroke} />
            <path d="M17.3619 42.9719C16.9002 42.9719 16.5255 42.5986 16.5255 42.1386V41.3053H8.16197V42.1386C8.16197 42.5986 7.78728 42.9719 7.32561 42.9719C6.86395 42.9719 6.48926 42.5986 6.48926 42.1386V40.472C6.48926 40.012 6.86395 39.6387 7.32561 39.6387H17.3619C17.8236 39.6387 18.1983 40.012 18.1983 40.472V42.1386C18.1983 42.5986 17.8236 42.9719 17.3619 42.9719Z" fill="#888888" />
            <path d="M12.3442 51.305C11.8825 51.305 11.5078 50.9317 11.5078 50.4717V40.472C11.5078 40.012 11.8825 39.6387 12.3442 39.6387C12.8058 39.6387 13.1805 40.012 13.1805 40.472V50.4717C13.1805 50.9317 12.8058 51.305 12.3442 51.305Z" fill="#888888" />
            <path d="M14.0167 51.3053H10.6713C10.2096 51.3053 9.83496 50.932 9.83496 50.472C9.83496 50.012 10.2096 49.6387 10.6713 49.6387H14.0167C14.4784 49.6387 14.8531 50.012 14.8531 50.472C14.8531 50.932 14.4784 51.3053 14.0167 51.3053Z" fill="#888888" />
            <path d="M29.0712 41.3053H22.3803C21.9186 41.3053 21.5439 40.932 21.5439 40.472C21.5439 40.012 21.9186 39.6387 22.3803 39.6387H29.0712C29.5328 39.6387 29.9075 40.012 29.9075 40.472C29.9075 40.932 29.5328 41.3053 29.0712 41.3053Z" fill="#888888" />
            <path d="M29.0712 46.3053H22.3803C21.9186 46.3053 21.5439 45.932 21.5439 45.472C21.5439 45.012 21.9186 44.6387 22.3803 44.6387H29.0712C29.5328 44.6387 29.9075 45.012 29.9075 45.472C29.9075 45.932 29.5328 46.3053 29.0712 46.3053Z" fill="#888888" />
            <path d="M29.0712 51.3053H22.3803C21.9186 51.3053 21.5439 50.932 21.5439 50.472C21.5439 50.012 21.9186 49.6387 22.3803 49.6387H29.0712C29.5328 49.6387 29.9075 50.012 29.9075 50.472C29.9075 50.932 29.5328 51.3053 29.0712 51.3053Z" fill="#888888" />
            <path d="M29.0709 56.3053H7.32561C6.86395 56.3053 6.48926 55.932 6.48926 55.472C6.48926 55.012 6.86395 54.6387 7.32561 54.6387H29.0709C29.5326 54.6387 29.9072 55.012 29.9072 55.472C29.9072 55.932 29.5326 56.3053 29.0709 56.3053Z" fill="#888888" />
            <path d="M29.0709 61.3053H7.32561C6.86395 61.3053 6.48926 60.932 6.48926 60.472C6.48926 60.012 6.86395 59.6387 7.32561 59.6387H29.0709C29.5326 59.6387 29.9072 60.012 29.9072 60.472C29.9072 60.932 29.5326 61.3053 29.0709 61.3053Z" fill="#888888" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.6194 21.1642C31.6194 28.5515 25.6306 34.5403 18.2433 34.5403C10.8558 34.5403 4.86719 28.5515 4.86719 21.1642C4.86719 13.7767 10.8558 7.78809 18.2433 7.78809C25.6306 7.78809 31.6194 13.7767 31.6194 21.1642ZM30.2818 21.1642C30.2818 27.813 24.892 33.2027 18.2433 33.2027C11.5946 33.2027 6.20477 27.8128 6.20477 21.1642C6.20477 14.5155 11.5946 9.12567 18.2433 9.12567C24.8921 9.12567 30.2818 14.5155 30.2818 21.1642Z" fill="#888888" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9888 21.9435C23.545 21.5728 23.545 20.756 22.9888 20.3853L15.7102 15.5348C15.0877 15.12 14.2539 15.566 14.2539 16.3139V26.015C14.2539 26.7628 15.0877 27.209 15.7102 26.7941L22.9888 21.9435ZM15.5921 17.0637L21.7456 21.1643L15.5921 25.2651V17.0637Z" fill="white" />
        </SvgIcon>
    );
}

export default VideoTopTextBottomIcon;
// Customizable Area End