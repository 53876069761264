// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js")
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  selectedStudentName:string
  courseName:string
  tabName:string
  selectedTopicName : string;
  selectedLessonName:string;
  selectedCourseSubject: null,
  openSendApprovalReqModal:boolean,
  openResourceModel:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentProgressDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
        tabValue:1,
        selectedStudentName:'',
        courseName:'',
        tabName:'',
        selectedTopicName:'',
        selectedLessonName:'',
        selectedCourseSubject: null,
        openSendApprovalReqModal:false,
        openResourceModel:false,
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleselectStudentName()
    this.handleTabChange('s',1)
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  handleTabChange = (event: any, newValue: number) => {
    const tabNames = ['Resourses', 'Journey', 'Participants']; 
    this.setState({
      tabValue: newValue,
      tabName:tabNames[newValue]
    },()=>{

    });
  };
  handleGoBacks = () => {
    this.props.navigation.goBack()
  }
  handleSetLessonName = (lessonTitle : string) => {
    this.setState({ selectedLessonName : lessonTitle})
  }
  handleSetTopicName = (topicTitle : string) => {
    this.setState({ selectedTopicName : topicTitle})
  }
  handleselectStudentName = async() => {
    const historyLocationState = this.props.navigation?.getHistoryState(); 
    const isRegistrar = window.location.pathname
      .toLowerCase()
      .includes("registrar");
    const currentRole = isRegistrar ? "registrar" : "teacher";
    if(historyLocationState){
      const { course ,selectedStudentName} = historyLocationState;
      this.setState({ selectedStudentName: selectedStudentName ?? "",courseName:course});
      await setStorageData("role", currentRole);
    } else {
      if (isRegistrar) {
        this.props.navigation?.navigate("RegistrarStudentsList");
      } else {
        this.props.navigation?.navigate("StudentTeacher");
      }
    }
  }
  handleSendApprovalReq =()=>{
    this.setState({
      openSendApprovalReqModal:true
    })
  }
  handleCloseSendApprovalModal=()=>{
    this.setState({
      openSendApprovalReqModal:false
    })
  }
  handleResourceModal=()=>{
    this.setState({
      openResourceModel:true
    })
  }
  handleCloseResouceModel=()=>{
    this.setState({
      openResourceModel:false
    })
  }
  // Customizable Area End
}
// Customizable Area End