import PropTypes from 'prop-types';
import React, { Component } from 'react';
type MyProps = { progressWidth: number, barColor:string };

export class CustomProgressBar extends Component<MyProps> {
  static propTypes = {
    progressWidth: PropTypes.number,
    barColor: PropTypes.string,
  };

  constructor(props: any) {
    super(props);

  }

  render() {

    const { progressWidth , barColor} = this.props;

    const fillerStyles = {
      height: '100%',
      width: `${progressWidth}%`,
      backgroundColor: barColor,
      borderRadius: 'inherit',
      textAlign: 'right'
    } as const

    const containerStyles = {
      height: 20,
      width: '100%',
      borderRadius: 50,
    } as const
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
        </div>
      </div>
    );
  }
}

export default CustomProgressBar;