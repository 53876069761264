// Customizable Area Start
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton } from "@material-ui/core";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import { makeStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
interface IProps {
  audioRef: any;
  progressBarRef: any;
  duration: any;
  setTimeProgress: any;
  classes:any;
}

const useStyles = makeStyles(() => ({
  controlBtnBg : {
    backgroundColor :"#0D46BC",
    padding:"8px",
    borderRadius:"50%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    "&:hover":{
      backgroundColor :"#0D46BC",
    }
  },
  iconCss:{
    color:"#ffffff"
  }
}))

// Customizable Area End

// Customizable Area Start
const AudioPlayerControl: React.FC<IProps> = (props) => {
  const newClasses = useStyles();
  const { audioRef, progressBarRef , duration , setTimeProgress } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const playAnimationRef = useRef(0);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const repeat = useCallback(() => {
    if(audioRef?.current){
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);
      progressBarRef.current.value = currentTime;
      progressBarRef.current.style.setProperty(
        '--range-progress',
        `${(progressBarRef.current.value / duration) * 100}%`);
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    audioRef.current.onended = () => {
      setIsPlaying(false);
    };
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  return (
    <Box className="controls-wrapper">
      <Box className="controls">
        <IconButton className={newClasses.controlBtnBg} onClick={togglePlayPause}>
          {isPlaying ? <PauseRoundedIcon className={newClasses.iconCss} fontSize='medium' /> : <PlayArrowRoundedIcon className={newClasses.iconCss} fontSize='medium' />}
        </IconButton>
      </Box>
    </Box>
  )
}

export default AudioPlayerControl;
// Customizable Area End