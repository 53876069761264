// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TeacherApprovedRequestController, {
  Props,
} from "./TeacherApprovedRequestController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "./assets";

// Customizable Area End
const teacherApprovedRequestsTableColumns = [
  {
    id: "Title_Approved",
    columnId: 1,
    type: tableColumnTypes.COURSES_NAME,
    label: "Title",
  },
  {
    id: "Subject_Approved",
    columnId: 2,
    type: tableColumnTypes.TEXT,
    label: "Subject",
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "Grade_Approved",
    columnId: 3,
    type: tableColumnTypes.TEXT,
    label: "Grade",
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "RequestedOn_Approved",
    columnId: 4,
    type: tableColumnTypes.TEXT,
    label: "Requested On",
    width: "130px",
    maxWidth: "180px",
  },
  {
    id: "ApprovedOn_Approved",
    columnId: 5,
    label: "Approved On",
    type: tableColumnTypes.TEXT,
    width: "130px",
    maxWidth: "180px",
  },
  {
    id: "CreatedOn_Approved",
    columnId: 6,
    type: tableColumnTypes.TEXT,
    label: "Created On",
    width: "130px",
    maxWidth: "180px",
  },
  {
    id: "Last_Modified_On",
    columnId: 7,
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "130px",
    maxWidth: "200px",
  },
];

const teacherApprovedRequestsData = [
  {
    id: 1,
    Title_Approved: {
      name: "Velocity and Acceleration",
      icon: ScienceColor,
    },
    Subject_Approved: "Science",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 2,
    Title_Approved: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject_Approved: "Maths",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 3,
    Title_Approved: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject_Approved: "History",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 4,
    Title_Approved: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject_Approved: "English",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 5,
    Title_Approved: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject_Approved: "English",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 6,
    Title_Approved: {
      name: "English Poetry",
      icon: EnglishColor,
    },
    Subject_Approved: "English",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 7,
    Title_Approved: {
      name: "Velocity and Accelator",
      icon: ScienceColor,
    },
    Subject_Approved: "Science",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 8,
    Title_Approved: {
      name: "Profit and Loss",
      icon: MathsColor,
    },
    Subject_Approved: "Maths",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
  {
    id: 9,
    Title_Approved: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject_Approved: "History",
    Grade_Approved: "Grade 3",
    RequestedOn_Approved: "July 20, 2022",
    ApprovedOn_Approved: "July 20, 2022",
    CreatedOn_Approved: "July 20, 2022",
    Last_Modified_On: "July 20, 2022",
  },
];

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    approvedDividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "24px",
      marginBottom: "4px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
  });

const courseApprovedTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px !important",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TeacherApprovedRequest extends TeacherApprovedRequestController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedTitleValue,
      selectedSubjectValue,
      selectedGradeValue,
      selectedRequestedOnValue,
      selectedAccpetedOnValue,
    } = this.state;

    return (
      <Box px={"24px"} pb={"19px"} data-test-id="ApprovedTable">
        <Grid
          container
          spacing={3}
          direction="row"
          data-test-id="ApprovedTableOnGrid"
        >
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                  component={"span"}
                  data-test-id="ApprovedTableTitle"
                >
                  Title
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldId="titleDropdown"
                  handleChange={this.handleChangeApprovedDropdownValue}
                  fieldName="selectedTitleValue"
                  fieldValue={selectedTitleValue}
                  fieldError={false}
                  fieldMenuItems={this.getApprovedDropdownItemsArray("Title")}
                  renderValue={this.renderApprovedTitleDropdownValue}
                  fieldStyles={approvedDropDownStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ApprovedTableGrid">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ApprovedTableGrids"
                >
                  Requested On
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="Ddvalue"
              >
                <CustomSelectDropdown
                  fieldId="requestedOnDropdown"
                  fieldName="selectedRequestedOnValue"
                  handleChange={this.handleChangeApprovedDropdownValue}
                  renderValue={this.renderApprovedRequestedOnDDValue}
                  fieldValue={selectedRequestedOnValue}
                  fieldError={false}
                  fieldStyles={approvedDropDownStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={
                    this.state.ApprovedRequestedOnDDDDComponentValue
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ApprovedTabless">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ApprovedTableGridTitle"
                >
                  Approved On
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ApprovedTableGridDD"
              >
                <CustomSelectDropdown
                  fieldName="selectedAccpetedOnValue"
                  fieldId="approvedOnDropdown"
                  handleChange={this.handleChangeApprovedDropdownValue}
                  fieldError={false}
                  fieldValue={selectedAccpetedOnValue}
                  fieldMenuItems={this.state.ApprovedOnDDComponentValue}
                  renderValue={this.renderApprovedOnDDValues}
                  fieldStyles={approvedDropDownStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ApprovedSubjectTitle">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ApprovedSubjectTitles"
                >
                  Subject
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ddSubject"
              >
                <CustomSelectDropdown
                  fieldId="subjectDropdown"
                  fieldName="selectedSubjectValue"
                  fieldValue={selectedSubjectValue}
                  handleChange={this.handleChangeApprovedDropdownValue}
                  fieldError={false}
                  fieldStyles={approvedDropDownStyle}
                  renderValue={this.renderApprovedSubjectDropdownValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.getApprovedDropdownItemsArray("Subject")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ddSubjectGrade">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                >
                  Grade
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ddSubjectGradeBox"
              >
                <CustomSelectDropdown
                  fieldName="selectedGradeValue"
                  fieldId="gradeDropdown"
                  fieldValue={selectedGradeValue}
                  fieldError={false}
                  handleChange={this.handleChangeApprovedDropdownValue}
                  renderValue={this.renderApprovedGradeDropdownValue}
                  fieldStyles={approvedDropDownStyle}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.getApprovedDropdownItemsArray("Grade")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <CustomButton
                btnText="Search"
                btnStyle={approvedSearchBtnStyle}
                buttonId={`my-approved-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleApprovedRequestsSearch}
                isDisabled={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.approvedDividerLine} />

        <Grid item xs={12} data-test-id="containers">
          <Box
            className={`${classes.customTableStyles} ${classes.teacherApprovedRequestsTableRow}`}
          >
            <ThemeProvider theme={courseApprovedTableTheme}>
              <CustomTable
                tableId={"approvedRequestsTable"}
                tableHeaderColumns={teacherApprovedRequestsTableColumns}
                tableBodyData={teacherApprovedRequestsData}
                showMenuIcon={true}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const approvedSearchBtnStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px",
} as const;

const approvedDropDownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherApprovedRequest);
// Customizable Area End
