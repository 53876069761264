// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TeacherCoursesTabController, {
  Props,
} from "./TeacherCoursesTabController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import Pagination from "@material-ui/lab/Pagination";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const coursesTableColumns = [
  {
    columnId: 1,
    id: "Title",
    type: tableColumnTypes.COURSES_NAME,
    label: "Title",
  },
  {
    columnId: 2,
    id: "StartDate",
    type: tableColumnTypes.TEXT,
    label: "Start Date",
    width: "130px",
    maxWidth: "180px",
  },
  {
    columnId: 3,
    id: "DueDate",
    type: tableColumnTypes.TEXT,
    label: "Due Date",
    width: "130px",
    maxWidth: "180px",
  },
  {
    columnId: 4,
    id: "TopicCount",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Topic Count",
  },
  {
    columnId: 4,
    id: "LessonCount",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Lesson Count",
  },
  {
    columnId: 4,
    id: "ActivityCount",
    type: tableColumnTypes.TEXT_AUTO,
    label: "Activity Count",
  },
  {
    columnId: 5,
    id: "Status",
    type: tableColumnTypes.STATUS_CHIP,
    label: "Status",
  },
];

const styles = (theme: Theme) =>
  createStyles({
    courseTabDividers: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "10px",
      marginBottom: "5px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    paginationBox: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: "10px 20px 10px 21px",
      alignItems: 'center',
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
      '& .Mui-selected': {
        backgroundColor: '#FFD92E !important',
        color: "#FFFFFF",
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
    },
  });

const coursesTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TeacherCoursesTab extends TeacherCoursesTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedTitleValue, selectedStatusValue, coursesTableData, pagination, loading } = this.state;
    console.log('coursesTableData', coursesTableData, loading)
    return (
      <Box py={"24px"} px={"20px"} className={`${classes.bgColor}`}>
        {(coursesTableData.length > 0 || loading) &&
          <>
            <Grid direction="row" spacing={3} container data-test-id="CoursesContainers">
              <Grid item lg={3} md={4} xs={12}>
                <Box marginBottom={"10px"} data-test-id="CoursesContainerGrids">
                  <Box marginBottom={"8px"} data-test-id="CoursesContainerBoxas">
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      data-test-id="CoursesDdTitleas"
                    >
                      Title
                    </Typography>
                  </Box>
                  <Box data-test-id="CoursesDdTitleBoxtr">
                    <CustomSelectDropdown
                      fieldName="selectedTitleValue"
                      fieldId="subjectDropdownew"
                      handleChange={this.handleChangeDropdownValue}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                      fieldError={false}
                      fieldValue={selectedTitleValue}
                      renderValue={this.renderCoursesTitleDropdownValue}
                      fieldStyles={coursesDDStyles}
                      fieldMenuItems={this.state.coursesTitleComponentValues}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid md={4} xs={12} lg={3} item data-test-id="CoursesItemGridwer">
                <Box marginBottom={"10px"} data-test-id="CoursesDdTitlesrew">
                  <Box marginBottom={"8px"}>
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      data-test-id="CoursesDdStatustre"
                    >
                      Status
                    </Typography>
                  </Box>
                  <Box justifyContent={"flex-start"} display={"flex"}>
                    <CustomSelectDropdown
                      fieldId="statusDropdownew"
                      fieldName="selectedStatusValue"
                      fieldValue={selectedStatusValue}
                      fieldStyles={coursesDDStyles}
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderCoursesStatusDropdownValue}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                      fieldMenuItems={this.state.coursesStatusComponentValue}
                      fieldError={false}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={3} md={4} data-test-id="CoursesBtnuh">
                <Box marginBottom={"10px"} data-test-id="CoursesDdTitlebtn">
                  <CustomButton
                    btnText="Search"
                    btnStyle={courseBtnStyles}
                    buttonId={`my-yuo-search-mobile`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleSearchCourse}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.courseTabDividers} />
          </>
        }
        {coursesTableData.length > 0 ?
          <Grid container>
            <Grid item xs={12} data-test-id="CoursesDdTitlefter">
              <Box className={`${classes.customTableStyles} ${classes.coursesTableRow}`}>
                <ThemeProvider theme={coursesTableTheme}>
                  <CustomTable
                    tableId={"coursesTables"}
                    showMenuIcon={false}
                    tableHeaderColumns={coursesTableColumns}
                    tableBodyData={coursesTableData}
                    handleAction={(rowData: any) => this.handleRedirectToJourney(rowData)}
                  />
                </ThemeProvider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.paginationBox}>

                <Box style={{ marginTop: '23px', width: '50%' }}>
                  <Box display={"flex"} gridGap={"6px"}>
                    <Typography
                      component="span"
                      className={`${classes.fontText18} ${classes.textCommonColor}`}
                    >
                      Records
                    </Typography>
                    <Typography
                      component="span"
                      className={`${classes.fontBold600} ${classes.textCommonColor} ${classes.fontText18}`}
                    >
                      {(pagination?.current_page * pagination?.per_page) - pagination?.per_page}-{pagination?.current_page * pagination?.per_page}
                    </Typography>
                    <Typography component="span" className={`${classes.fontText18} ${classes.textCommonColor}`}>
                      of {pagination?.total_count}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                </Box>
                <Box className={classes.paginationRoot}>
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    count={Math.ceil(pagination?.total_count / pagination?.per_page)}
                    siblingCount={0}
                    boundaryCount={1}
                    onChange={this.handlePaginationChange}
                    page={pagination?.current_page}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          :
          <Box>
            <CustomEmptyResultComponent message="No record found" />
          </Box>
        }
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const courseBtnStyles = {
  padding: "10px 10px",
  width: "100%",
  fontSize: "18px",
  borderRadius: "12px",
  fontWeight: 500,
  marginTop: "32px",
  textAlign: "center",
} as const;

const coursesDDStyles = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCoursesTab);
// Customizable Area End
