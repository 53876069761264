// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ClassDetailsController, { Props } from "./ClassDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomMultiSelectDropDownV2 from "../../../components/src/CustomMultiSelectDropDownV2.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    termTextFieldRoot: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    termTextField: {
      width: theme.spacing(2.5),
    },
    inputLabelText: {
      fontWeight: 400,
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2.5),
      fontSize: "16px",
      color: "#888888",
    },
    formFieldsBox: {
      width: "96%",
      justifyContent: "space-between",
      flexDirection: "row",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    searchBox: {
      padding: "0px 24px",
    },
  });
// Customizable Area End

// Customizable Area Start
export class ClassDetails extends ClassDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      selectedCurriculum,
      classTitle,
      classTitleError,
      classTitleErrorMsg,
      curriculumList,
      gradesList,
      schoolYearList,
      selectedCurriculumError,
      selectedCurriculumErrorMsg,
      selectedGrade,
      selectedGradeError,
      selectedGradeErrorMsg,
      selectedSchoolYear,
      selectedSchoolYearError,
      selectedSchoolYearErrorMsg,
      selectedSubjects,
      selectedSubjectsError,
      selectedSubjectsErrorMsg,
      subjectsList,
      isLoaderOn,
      customFieldConfig,
    } = this.props;
    return (
      <Box>
        <Grid container className={`${classes.searchBox}`}>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabelText}>
                Class Name
              </Typography>
              <CustomTextFieldWeb
                fieldId="tenant-class-setup-class-title"
                fieldName="classTitle"
                fieldType="text"
                fieldWidth="100%"
                textFieldStyles={{
                  padding: "10px 0",
                  width: "96%",
                  maxWidth: "100%",
                }}
                fieldError={classTitleError}
                handleChange={this.props.handleChangeTextField}
                helperText={classTitleErrorMsg}
                fieldValue={classTitle}
                placeHolderText="Class Name"
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography className={classes.inputLabelText}>
                School Year
              </Typography>
              <CustomSelectDropdown
                fieldId="tenant-class-setup-school-year"
                fieldValue={selectedSchoolYear}
                fieldName="selectedSchoolYear"
                handleChange={this.props.handleChangeDDValue}
                renderValue={this.props.renderSchoolYear}
                fieldError={selectedSchoolYearError}
                helperText={selectedSchoolYearErrorMsg}
                fieldMenuItems={schoolYearList}
                fieldStyles={{ maxWidth: "100%", width: "96%" }}
                placeHolderText="Select School Year"
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabelText}>Grade</Typography>
              <CustomSelectDropdown
                fieldId="tenant-class-setup-grade"
                fieldValue={selectedGrade}
                fieldName="selectedGrade"
                handleChange={this.props.handleChangeDDValue}
                renderValue={this.props.renderGrade}
                fieldError={selectedGradeError}
                helperText={selectedGradeErrorMsg}
                fieldMenuItems={gradesList}
                fieldStyles={{ maxWidth: "100%", width: "96%" }}
                placeHolderText="Select Grade"
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabelText}>
                Subjects
              </Typography>
              <CustomMultiSelectDropDownV2
                label="Select Subjects"
                fieldMenuItems={subjectsList}
                fieldStyles={{
                  width: "96%",
                }}
                fieldId={"tenant-class-setup-subjects"}
                removeSelectedValue={this.props.removeSubject}
                selectedItems={selectedSubjects}
                handleChange={this.props.handleChangeSubjects}
                fieldError={selectedSubjectsError}
                helperText={selectedSubjectsErrorMsg}
                isDisabled={false}
                disabledItemSelection={isLoaderOn}
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography className={classes.inputLabelText}>
                Curriculum
              </Typography>
              <CustomSelectDropdown
                fieldId="tenant-class-setup-curriculum"
                fieldValue={selectedCurriculum}
                fieldName="selectedCurriculum"
                handleChange={this.props.handleChangeDDValue}
                renderValue={this.props.renderCurriculum}
                fieldError={selectedCurriculumError}
                helperText={selectedCurriculumErrorMsg}
                fieldMenuItems={curriculumList}
                fieldStyles={{ maxWidth: "100%", width: "96%" }}
                placeHolderText="Select Curriculum"
              />
            </Box>
          </Grid>
          {/* Custom Fields */}
          {customFieldConfig.map((configItem, index) => (
            <Grid
              key={`${configItem?.label}-${configItem?.id}`}
              item
              lg={6}
              md={6}
              sm={12}
            >
              <Box>
                <Typography className={classes.inputLabelText}>
                  {configItem.label}
                </Typography>
                <CustomTextFieldWeb
                  fieldName={configItem.name}
                  fieldWidth={"100%"}
                  fieldId={`class-management-custom-field-${configItem.id}`}
                  fieldValue={configItem.value}
                  isDisabled={configItem?.isDisabled}
                  textFieldStyles={{
                    width: "96%",
                    padding: "8px 0px",
                  }}
                  placeHolderText={`${configItem.placeHolderText}`}
                  fieldType="text"
                  handleChange={this.props.handleChangeCustomField}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ClassDetails);
// Customizable Area End
