// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const AttachmentIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <mask id="mask0_0_12938" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0.693335L27.3067 0.693335L27.3067 28L0 28L0 0.693335Z" fill="white" />
            </mask>
            <g mask="url(#mask0_0_12938)">
                <path fill="none" d="M25.0957 12.0344L13.1431 23.9869C10.3197 26.8104 5.7419 26.8104 2.91838 23.9869V23.9869C0.0949147 21.1634 0.0949147 16.5856 2.91838 13.7621L14.0899 2.59063" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
                <path fill="none" d="M10.3027 13.1934L18.2789 5.21715" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
                <path fill="none" d="M20.9067 9.40678L9.73524 20.5783C8.79407 21.5195 7.26809 21.5195 6.32697 20.5783C5.3858 19.6371 5.3858 18.1112 6.32697 17.17L10.3032 13.1937" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
                <path fill="none" d="M18.2793 5.21765C20.1616 3.33535 23.2135 3.33535 25.0958 5.21765H25.0958C26.9781 7.09994 26.9781 10.1518 25.0958 12.0342" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </SvgIcon>
    );
}

export default AttachmentIcon;
// Customizable Area End