import React from "react";
import { Box, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  children: React.ReactNode,
  title: string,
  subTitle: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
    },
    imageWrapper: {
      display: "flex",
      height: "100%",
    },
    image: {
      width: "100%",
      height: "100vh",
      objectFit: "cover",
    },
    rightGrid: {
      overflow: "auto",
      height: "100vh",
    },
    logo: {
      maxWidth: theme.spacing(23),
      width: "100%",
      marginBottom: theme.spacing(3)
    },
    title: {
      fontSize: 30,
      fontWeight: 700,
      textAlign: "center",
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
    subTitle: {
      fontSize: 18,
      fontWeight: 400,
      color: '#888888',
      marginBottom: theme.spacing(4),
      textAlign: "center",
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
  }),
);

export const AuthLayout: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { children, title, subTitle } = props;

  return (
    <Box>
      <Grid container>
        <Hidden only={["xs", "sm"]}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.imageWrapper}>
              <img
                alt="auth-feature-image"
                className={classes.image}
                src={require("./loginLeftImage.png")}
              />
            </Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.rightGrid}>
          <Box className={classes.root}>
            <img
              className={classes.logo}
              src={require('./YUDU-logo.png')}
              alt="yudu-logo"
            />
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subTitle}>{subTitle}</Typography>
            <div style={{ width: "100%" }}>
              {children}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AuthLayout;
