// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  selectedGrade: any;
  selectedGradeError: boolean;
  selectedGradeErrorMsg: any;
  selectedSubjects: Array<any>;
  selectedSubjectsError: boolean;
  selectedSubjectsErrorMsg: any;
  selectedCurriculum: any;
  selectedCurriculumError: boolean;
  selectedCurriculumErrorMsg: any;
  classTitle: string;
  classTitleError: boolean;
  classTitleErrorMsg: any;
  selectedSchoolYear: any;
  selectedSchoolYearError: boolean;
  selectedSchoolYearErrorMsg: any;
  renderGrade: any;
  renderSchoolYear: any;
  renderCurriculum: any;
  handleChangeDDValue: any;
  handleChangeTextField: any;
  handleChangeCustomField: any;
  handleChangeSubjects: any;
  removeSubject: any;
  gradesList: any;
  curriculumList: any;
  subjectsList: any;
  schoolYearList: any;
  isLoaderOn: boolean;
  customFieldConfig: Array<any>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ClassDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
