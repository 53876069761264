// Customizable Area Start
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  courseTab?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectParticipantNameValue: string;
  selectParticipantSubjectValue: string;
  selectParticipantGradeValue: string;
  selectParticipantUsersValue: string;
  selectedActiveUsersValue: string;
  selectedLessonsPerDayValue: string;
  selectCompletionRateValue: string;
  timesModifiedDDValue: Array<any>;
  selectTimesModifiedValue: string;
  participantsData: Array<any>;
  participantsTableColumns: Array<any>;
  participantsTitleDropdownData: Array<any>;
  subjectsDropdownData: Array<any>;
  gradesDropdownData: Array<any>;
  searchQuery: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherParticipantsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetParticipantsData: string = "";
  apiGetSubjectsData: string = "";
  apiGetGradesData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectParticipantNameValue: "",
      selectParticipantSubjectValue: "",
      selectParticipantGradeValue: "",
      selectParticipantUsersValue: "",
      selectedActiveUsersValue: "",
      selectedLessonsPerDayValue: "",
      selectCompletionRateValue: "",
      selectTimesModifiedValue: "",
      participantsData: [],
      participantsTitleDropdownData: [],
      subjectsDropdownData: [],
      gradesDropdownData: [],
      searchQuery: {},
      timesModifiedDDValue: [
        {
          id: 1,
          label: "00:20",
          value: 1,
        },
        {
          id: 2,
          label: "20:22",
          value: 2,
        },
        {
          id: 3,
          label: "20:23",
          value: 3,
        },
      ],
      participantsTableColumns: [
        {
          id: "Title_Participants",
          columnId: 1,
          label: "Title",
          type: tableColumnTypes.TEXT,
          width: "80px",
          maxWidth: "120px",
        },
        {
          id: "Subject_Participants",
          columnId: 2,
          label: "Subject",
          type: tableColumnTypes.TEXT,
          width: "100px",
          maxWidth: "150px"
        },
        {
          id: "Grade_Participants",
          columnId: 3,
          label: "Grade",
          type: tableColumnTypes.TEXT,
          width: "100px",
          maxWidth: "150px"
        },
        {
          id: "TotalUsers_Participants",
          columnId: 4,
          label: "Total no. of users",
          type: tableColumnTypes.TEXT,
          width: "80px",
          maxWidth: "80px",
        },
        {
          id: "ActiveUsers_Participants",
          columnId: 5,
          label: "No. of active users",
          type: tableColumnTypes.TEXT,
          width: "80px",
          maxWidth: "120px",
        },
        {
          id: "LessonsPerDay_Participants",
          columnId: 6,
          label: "No. Lessons Per Day",
          type: tableColumnTypes.TEXT,
          width: "80px",
          maxWidth: "80px"
        },
        {
          id: "CompletionRate_Participants",
          columnId: 7,
          label: "Completion Rate",
          type: tableColumnTypes.TEXT,
          width: "80px",
          maxWidth: "80px"
        },
        {
          id: "TimesModified_Participants",
          columnId: 8,
          label: "Times Modified",
          type: tableColumnTypes.TEXT,
          width: "80px",
          maxWidth: "80px"
        },
      ]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetParticipantsData:
            {
              this.handleGetParticipantsDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetSubjectsData:
            {
              this.handleGetSubjectsDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetGradesData:
            {
              this.handleGetGradesDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetSubjectsDataApi();
    this.handleGetGradesDataApi();
    this.handleGetParticipantsDataApi(); 
    // Customizable Area End
  }

  checkAndGetSelectedValue = (
    selected: any,
    dropdownName: string,
    dropdownItems: any
  ) => {
    if (!selected || selected.length === 0) {
      return `Select ${dropdownName}`;
    }
    const selctedOption = dropdownItems.find(
      (item: any) => item?.value === selected
    );
    return selctedOption?.label;
  };

  getParticipantsDropdownItemsArray = (dropdownName: string) => {
    const refArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let dropdownItems: Array<any> = [];
    refArray.forEach((item) => {
      dropdownItems.push({
        id: item,
        label: `${dropdownName}${item}`,
        value: `${dropdownName}${item}`,
      });
    });
    return dropdownItems;
  };

  renderParticipantsNameDropdownValue = (selected: any) => {
    const dropdownName = "Name";
    const dropdownItems = this.state.participantsTitleDropdownData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderParticipantUsersDropdownValue = (selected: any) => {
    const dropdownName = "Option";
    const dropdownItems = this.getParticipantsDropdownItemsArray("");
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderParticipantActiveUsersValue = (selected: any) => {
    const dropdownName = "Option";
    const dropdownItems = this.getParticipantsDropdownItemsArray("");
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderParticipantSubjectDropdownValue = (selected: any) => {
    const dropdownName = "Subject";
    const dropdownItems = this.state.subjectsDropdownData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderParticipantLessonsPerDayValue = (selected: any) => {
    const dropdownName = "Option";
    const dropdownItems = this.getParticipantsDropdownItemsArray("");
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderParticipantCompletionRateValue = (selected: any) => {
    const dropdownName = "Option";
    const dropdownItems = this.getParticipantsDropdownItemsArray("");
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  renderParticipantGradeDropdownValue = (selected: any) => {
    const dropdownName = "Grade";
    const dropdownItems = this.state.gradesDropdownData;
    return this.checkAndGetSelectedValue(selected, dropdownName, dropdownItems);
  };

  handleParticipantChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderParticipantTimesModifiedValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const dropdownItems = this.getParticipantsDropdownItemsArray("");
    return this.checkAndGetSelectedValue(selected, 'Option', dropdownItems);
  };

  handleSearchParticipantsBtn = () => {
    const {
      selectParticipantNameValue,
      selectParticipantSubjectValue,
      selectParticipantGradeValue,
      selectParticipantUsersValue,
      selectedActiveUsersValue,
      selectedLessonsPerDayValue,
      selectCompletionRateValue,
      selectTimesModifiedValue,
    } = this.state;
    const search = {
      title: selectParticipantNameValue,
      subject: selectParticipantSubjectValue,
      grade: selectParticipantGradeValue,
      totalUsers: selectParticipantUsersValue,
      activeUsers: selectedActiveUsersValue,
      lessonsPerDay: selectedLessonsPerDayValue,
      completionRate: selectCompletionRateValue,
      modifiedCount: selectTimesModifiedValue,
    }
    console.log("search: ", search);

    this.setState({ searchQuery: search }, () => {
      this.handleGetParticipantsDataApi();
    });
  };

  handleGetParticipantsDataApi = async () => {
    showCustomLoader();
    let requestUrl =`${configJSON.participantsApiEndpoint}`
    if(this.props.courseTab){
      requestUrl +=`?course_tab=true`
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParticipantsData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  createTitleDropdownData = (participantsList: any[]) => {
    const titleSet = new Set();
    return participantsList
      .filter((item: any) => {
        if (!titleSet.has(item.Title_Participants)) {
          titleSet.add(item.Title_Participants);
          return true;
        }
        return false;
      })
      .map((item: any, index: any) => ({
        id: index,
        label: item.Title_Participants,
        value: item.Title_Participants,
      }));
  };
  handleGetParticipantsDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const participantsList = responseJson.data.map((item: any,index:any) => {
        const participant = item.attributes;
        return {
          id: index,
          Title_Participants: participant?.course_title,
          Subject_Participants: participant?.subject?.name,
          Grade_Participants: participant?.grade?.name,
          TotalUsers_Participants: participant?.total_users?.all_users_count,
          ActiveUsers_Participants: participant?.total_users?.active_user_count,
          LessonsPerDay_Participants: participant?.no_of_lession_a_day,
          CompletionRate_Participants: participant?.total_users?.completion_rate,
          TimesModified_Participants: participant?.modified_count,
        }
      });
      const titleDropdownData = this.createTitleDropdownData(participantsList);
      let filteredParticipantsData: any = participantsList;
      const {
        selectParticipantNameValue,
        selectParticipantSubjectValue,
        selectParticipantGradeValue,
        selectParticipantUsersValue,
        selectedActiveUsersValue,
        selectedLessonsPerDayValue,
        selectCompletionRateValue,
        selectTimesModifiedValue,
      } = this.state;

      if (selectParticipantNameValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.Title_Participants == selectParticipantNameValue;
        });
      }

      if (selectParticipantSubjectValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.Subject_Participants == selectParticipantSubjectValue;
        });
      }

      if (selectParticipantGradeValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.Grade_Participants == selectParticipantGradeValue;
        });
      }

      if (selectParticipantUsersValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.TotalUsers_Participants == selectParticipantUsersValue;
        });
      }

      if (selectedActiveUsersValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.ActiveUsers_Participants == selectedActiveUsersValue;
        });
      }

      if (selectedLessonsPerDayValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.LessonsPerDay_Participants == selectedLessonsPerDayValue;
        });
      }

      if (selectCompletionRateValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.CompletionRate_Participants == selectCompletionRateValue;
        });
      }

      if (selectTimesModifiedValue) {
        filteredParticipantsData = filteredParticipantsData.filter((item: any) => {
          return item.TimesModified_Participants == selectTimesModifiedValue.toString();
        });
      }
      this.setState({ participantsData: filteredParticipantsData, participantsTitleDropdownData: titleDropdownData });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetSubjectsDataApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSubjectsData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsDropdwonData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSubjectsDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const subjectsDDList = responseJson?.data?.map(
        (item: any) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name,
            label: item.attributes.name,
          };
        }
      );
      this.setState({
        subjectsDropdownData: subjectsDDList,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetGradesDataApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradesData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesDropdownData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetGradesDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const gradesList = responseJson?.data?.map(
        (item: any) => {
          return {
            id: item.id,
            label: item?.attributes.name,
            value: item?.attributes.name,
          };
        }
      );
      this.setState({
        gradesDropdownData: gradesList,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleInputChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }
  // Customizable Area End
}
