// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const VideoRightTextLeftIcon: any = (props: any) => {
    return (
        <SvgIcon style={{ width: "75px", height: "40px" }} {...props}>
            <rect x="-1.5" y="0" width="70" height="40" rx="3" strokeWidth="1" fill={props.fill} stroke={props.stroke} />
            <path d="M17.9679 11.5733C17.4908 11.5733 17.1035 11.1842 17.1035 10.7047V9.83599H8.45931V10.7047C8.45931 11.1842 8.07205 11.5733 7.59489 11.5733C7.11773 11.5733 6.73047 11.1842 6.73047 10.7047V8.96731C6.73047 8.4878 7.11773 8.09863 7.59489 8.09863H17.9679C18.4451 8.09863 18.8323 8.4878 18.8323 8.96731V10.7047C18.8323 11.1842 18.4451 11.5733 17.9679 11.5733Z" fill="#888888" />
            <path d="M12.7814 20.2601C12.3043 20.2601 11.917 19.871 11.917 19.3914V8.96731C11.917 8.4878 12.3043 8.09863 12.7814 8.09863C13.2586 8.09863 13.6458 8.4878 13.6458 8.96731V19.3914C13.6458 19.871 13.2586 20.2601 12.7814 20.2601Z" fill="#888888" />
            <path d="M14.5106 20.2598H11.0529C10.5757 20.2598 10.1885 19.8706 10.1885 19.3911C10.1885 18.9116 10.5757 18.5225 11.0529 18.5225H14.5106C14.9877 18.5225 15.375 18.9116 15.375 19.3911C15.375 19.8706 14.9877 20.2598 14.5106 20.2598Z" fill="#888888" />
            <path d="M30.0698 9.83599H23.1545C22.6773 9.83599 22.29 9.44682 22.29 8.96731C22.29 8.4878 22.6773 8.09863 23.1545 8.09863H30.0698C30.547 8.09863 30.9342 8.4878 30.9342 8.96731C30.9342 9.44682 30.547 9.83599 30.0698 9.83599Z" fill="#888888" />
            <path d="M30.0698 15.0479H23.1545C22.6773 15.0479 22.29 14.6587 22.29 14.1792C22.29 13.6997 22.6773 13.3105 23.1545 13.3105H30.0698C30.547 13.3105 30.9342 13.6997 30.9342 14.1792C30.9342 14.6587 30.547 15.0479 30.0698 15.0479Z" fill="#888888" />
            <path d="M30.0698 20.2598H23.1545C22.6773 20.2598 22.29 19.8706 22.29 19.3911C22.29 18.9116 22.6773 18.5225 23.1545 18.5225H30.0698C30.547 18.5225 30.9342 18.9116 30.9342 19.3911C30.9342 19.8706 30.547 20.2598 30.0698 20.2598Z" fill="#888888" />
            <path d="M30.0698 25.4717H7.59489C7.11773 25.4717 6.73047 25.0826 6.73047 24.6031C6.73047 24.1235 7.11773 23.7344 7.59489 23.7344H30.0698C30.547 23.7344 30.9342 24.1235 30.9342 24.6031C30.9342 25.0826 30.547 25.4717 30.0698 25.4717Z" fill="#888888" />
            <path d="M30.0698 30.6836H7.59489C7.11773 30.6836 6.73047 30.2945 6.73047 29.815C6.73047 29.3355 7.11773 28.9463 7.59489 28.9463H30.0698C30.547 28.9463 30.9342 29.3355 30.9342 29.815C30.9342 30.2945 30.547 30.6836 30.0698 30.6836Z" fill="#888888" />
            <path fillRule="evenodd" clipRule="evenodd" d="M63.269 18.7685C63.269 26.5424 57.0913 32.8446 49.4709 32.8446C41.8504 32.8446 35.6729 26.5424 35.6729 18.7685C35.6729 10.9944 41.8504 4.69238 49.4709 4.69238C57.0913 4.69238 63.269 10.9944 63.269 18.7685ZM61.8892 18.7685C61.8892 25.7652 56.3293 31.437 49.4709 31.437C42.6125 31.437 37.0526 25.7651 37.0526 18.7685C37.0526 11.7719 42.6125 6.09996 49.4709 6.09996C56.3295 6.09996 61.8892 11.7719 61.8892 18.7685Z" fill="#888888" />
            <path fillRule="evenodd" clipRule="evenodd" d="M54.3659 19.588C54.9397 19.1979 54.9397 18.3383 54.3659 17.9482L46.8577 12.8439C46.2156 12.4074 45.3555 12.8767 45.3555 13.6637V23.8725C45.3555 24.6595 46.2156 25.129 46.8577 24.6924L54.3659 19.588ZM46.7359 14.4528L53.0835 18.768L46.7359 23.0834V14.4528Z" fill="white" />
        </SvgIcon>
    );
}

export default VideoRightTextLeftIcon;
// Customizable Area End