// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DropdownIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
           <path d="M12 16.7273C11.814 16.7273 11.6279 16.6562 11.4859 16.5142L4.21314 9.24145C3.92895 8.95727 3.92895 8.49709 4.21314 8.21309C4.49732 7.92909 4.9575 7.92891 5.2415 8.21309L12 14.9716L18.7586 8.21309C19.0428 7.92891 19.503 7.92891 19.787 8.21309C20.071 8.49727 20.0711 8.95745 19.787 9.24145L12.5142 16.5142C12.3722 16.6562 12.186 16.7273 12 16.7273Z" fill={props.fill} strokeWidth={props.strokeWidth} fillOpacity="0.6"/>
        </SvgIcon>
    );
}

export default DropdownIcon;
// Customizable Area End