import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  registrarStudentGradebookMockData,
  studentBehaviorData,
} from "./assets/mockDataTemp";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { AttendanceDataColumns } from "./GradebookDetailPageController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area Start
  validateDataToBeSubmitted?: (a: boolean) => void;
  isRegistrar?: boolean;
  isParentTeacher?:boolean;
  attendanceData:AttendanceDataColumns[]
  handleChange:(index:number,event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  commentData: string;
  onCommentEdit:(comment:string)=>void;
  quarters:any[]
  studentBehaviorData:any[]
  onSelectDeselectQuarters:(behaviourItem: any, quarterNumber: string)=>void
  updateBehaviourTableCellData:(
    newData: any,
    cellTitle: string,
    subskill: any,
    mainSkillItemId: number,
    behaviorItem: any,
    popoverId?: string
  )=>void
  updateBehaviourValueAndCloseDropdown:(    newData: any,
    cellTitle: string,
    subskill: any,
    mainSkillId: number,
    behaviorItem: any,
    dropdownTarget: string)=>void
    calculateTotal:()=>number;
    evaluationKeysData:any[];
    maxEvaluationKeys:number;
    teacherComment :boolean
    showInEditableMode: () => boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  editAttendance: boolean;
  studentBehaviorData: any[];
  
  userRole: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AttendanceBehaviourTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      editAttendance: false,
      studentBehaviorData: studentBehaviorData,
      userRole: "",
    
      // Customizable Area End
    };
    // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleSetData();
    this.setState({userRole: await getStorageData("role")});
    
  }
 


  handleSetData = () => {
    const { isRegistrar } = this.props;
    if (isRegistrar) {
      this.setState({ studentBehaviorData: registrarStudentGradebookMockData });
    }
  };

  onSelectDeselectQuarters = (behaviourItem: any, quarterNumber: string) => {
    const behaviorData = this.state.studentBehaviorData;
    const itemToUpdate = behaviorData.find(
      (item: any) => item.id === behaviourItem.id
    );
    if (itemToUpdate) {
      switch (quarterNumber) {
        case "q1":
          itemToUpdate.isQ1Selected = !!!itemToUpdate.isQ1Selected;
          break;
        case "q2":
          itemToUpdate.isQ2Selected = !!!itemToUpdate.isQ2Selected;
          break;
        case "q3":
          itemToUpdate.isQ3Selected = !!!itemToUpdate.isQ3Selected;
          break;
        case "q4":
          itemToUpdate.isQ4Selected = !!!itemToUpdate.isQ4Selected;
          break;
      }
      this.setState({ studentBehaviorData: behaviorData });
    }
  };


  


  // Customizable Area End
}
