import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");
export const assessmentCategoryTabs = [
    {
        id: 1,
        label: "My Courses",
        value: "my-courses"
    },
    {
        id: 2,
        label: "Catalog",
        value: "catalog"
    }
]

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    selectedAssessmentCategoryTab: number;
    selectedTabName: string;
    openAddNewCourseModal: boolean;
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CourseManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtSavedValue: "A",
            selectedAssessmentCategoryTab: 0,
            selectedTabName: "My Courses",
            openAddNewCourseModal: false,
            // Customizable Area Start
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    handleChangeAssessmentCategoryTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedAssessmentCategoryTab: newValue });
        if (newValue === 0) {
            this.setState({ selectedTabName: "My Courses" });
        } else if (newValue === 1) {
            this.setState({ selectedTabName: "Catalog" });
        }
    };

    handleOpenAssignCourseModal = () => {
        this.setState({ openAddNewCourseModal: true });
    }

    handleCloseAssignCourseModal = () => {
        this.setState({ openAddNewCourseModal: false });
    }

    handleOpenRequestPage = () => {
        this.props.navigation?.navigate("MyRequestsPage");
    }
    // Customizable Area End
}
