// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AddNewUserDetailsController, {
  IDDConfigProps,
  Props,
} from "./AddNewUserDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { FIELD_TYPES } from "../../../components/src/CommonType.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomFileUploadTextFieldWeb from "../../../components/src/CustomFileUploadTextField.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";
import CustomMultiSelectDropDown from "../../../components/src/CustomMultiSelectDropDown.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addUserMainBox: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 0px 0px",
    },
    dropdownGrid: {
      padding: "20px",
    },
    selectDD: {
      marginBottom: "10px",
    },
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    selectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    searchBtn: {
      display: "flex",
      alignItems: "flex-end",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    addBtnBox: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      paddingTop: "12px"
    },
    textCapital: {
      textTransform: "capitalize",
    },
  });
// Customizable Area End

// Customizable Area Start
export class AddNewUserDetails extends AddNewUserDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { dropdownConfigs, userRole,isEdit } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                User Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
              >
                {` / ${userRole}`}
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
              >
                {this.returnHeadingString()}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box className={`${classes.userMngTopHeader}`}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
              >
                  {isEdit ? `Edit New User` : `Add New User`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.bgColor} ${classes.addUserMainBox}`}>
              <Grid
                className={`${classes.dropdownGrid}`}
                container
                direction="row"
              >
                {dropdownConfigs.map((ddItem: IDDConfigProps) => (
                  <Grid key={ddItem.id} item xs={12} sm={6} md={4} lg={4}>
                    <Box className={`${classes.selectDD}`}>
                      <Box marginBottom={"10px"}>
                        <Typography
                          className={`${classes.textCommonColor} ${classes.fontText16}`}
                          component={"span"}
                        >
                          {ddItem.label}
                        </Typography>
                      </Box>
                      <Box className={`${classes.selectDDWidth}`}>
                        {ddItem.fieldType === FIELD_TYPES.SELECT && ddItem.name !== 'subjectHandled' && ddItem.name !== 'curriculum' && (
                          <CustomSelectDropdown
                            fieldId={ddItem.id}
                            fieldStyles={dropdownStyle}
                            fieldValue={ddItem.value}
                            fieldName={ddItem.name}
                            handleChange={this.handleSelectDropdownValue}
                            renderValue={(selected: any) =>
                              this.renderDDMenuItem(
                                selected,
                                ddItem.fieldName,
                                ddItem.placeHolderText
                              )
                            }
                            isDisabled={ddItem.isDisabled}
                            fieldError={false}
                            fieldMenuItems={ddItem?.menuItems || []}
                            iconComponent={
                              <DropdownIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#888888"}
                                strokeWidth="1.5"
                                fill={"none"}
                              />
                            }
                          />
                        )}
                {ddItem.fieldType === FIELD_TYPES.SELECT && ddItem.name == 'curriculum' && (
                          <CustomSelectDropdown
                            fieldId={ddItem.id}
                            fieldStyles={dropdownStyle}
                            fieldValue={ddItem.value}
                            fieldName={ddItem.name}
                            handleChange={this.handleSelectDropdownValue}
                            renderValue={(selected: any) =>
                              this.renderDDMenuItem(
                                selected,
                                ddItem.fieldName,
                                ddItem.placeHolderText
                              )
                            }
                            isDisabled={this.state.selectedSubject.length == 0}
                            fieldError={false}
                            fieldMenuItems={ddItem?.menuItems || []}
                            iconComponent={
                              <DropdownIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#888888"}
                                strokeWidth="1.5"
                                fill={"none"}
                              />
                            }
                          />
                        )}
                        {ddItem.name === 'subjectHandled' && (
                          <CustomMultiSelectDropDown 
                            label={"Select Subjects"}
                            fieldMenuItems={ddItem?.menuItems || []}
                            handleClose={(event: any) => this.getCurriculumList(ddItem?.menuItems)}
                            handleChange={(event: any) => {
                              this.handleChangeSubject(event , ddItem?.menuItems)
                            }}
                            isDisabled={this.state.gradeSubjectId  == 0}
                            selectedItems={this.state.selectedSubject}
                            fieldStyles={{
                              width: "100%",
                              height: "50%"
                            }}
                          />
                        )}
                        {ddItem.fieldType === FIELD_TYPES.TEXT && (
                          <CustomTextField
                            placeHolderText={ddItem.placeHolderText}
                            fieldName={ddItem.name}
                            fieldId={ddItem.id}
                            fieldValue={(ddItem.label !== "Role" && ddItem.value !== "Course Manager") ? ddItem.value : this.state.userRole}
                            textFieldStyles={textfieldStyles}
                            fieldWidth={"100%%"}
                            fieldType="text"
                            isDisabled={ddItem?.isDisabled}
                            handleChange={this.handleChange}
                          />
                        )}
                        {ddItem.fieldType === FIELD_TYPES.FILE_UPLOAD_TEXT && (
                          <CustomFileUploadTextFieldWeb
                            placeHolderText={`Select File`}
                            fieldName={ddItem.name}
                            fieldId={ddItem.id}
                            fieldValue={ddItem.value.fileName}
                            fileType={"image"}
                            fieldWidth={"100%%"}
                            textFieldStyles={textfieldStyles}
                            fieldType="text"
                            removeText="Remove"
                            uploadText="Upload"
                            handleFile={(file: any) =>
                              this.handleUploadFile(file, ddItem.fieldName)
                            }
                          />
                        )}
                        {ddItem.fieldType === FIELD_TYPES.DATE && (
                          <CustomDatepicker
                            disableFutureDate={false}
                            disablePastDate={true}
                            selectedDate={ddItem.value}
                            format="DD/MM/YYYY"
                            fieldError={false}
                            placeHolderText={"DD/MM/YYYY"}
                            id="parent-teacher-expiringOn"
                            isDisabled={false}
                            fieldId={ddItem.id}
                            handleChangeDate={(date: any) =>
                              this.handleDateChange(date, ddItem.name)
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid className={`${classes.searchBtn}`} item xs={12}>
            <Box className={`${classes.addBtnBox}`} marginTop={"20px"}>
              {!this.state.isEdit &&
              <CustomButton
                buttonId={`tenant-user-mng-save-as-draft-user-btn`}
                btnStyle={saveAsDraftBtn}
                btnText="Save As Draft"
                buttonVariant={"outlined"}
                handleButtonClick={() => this.handleAddUser(true)}
                isDisabled={false}
                type={"button"}
              />
              }
              <CustomButton
                buttonId={`tenant-user-mng-add-user-btn`}
                btnStyle={addUserBtn}
                btnText={!this.state.isEdit?"Submit":"Edit"}
                buttonVariant={"contained"}
                handleButtonClick={() => this.handleAddUser(false)}
                isDisabled={false}
                type={"button"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addUserBtn = {
  width: "160px",
  height: "50px",
  padding: "10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const saveAsDraftBtn = {
  width: "160px",
  padding: "10px",
  height: "50px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  marginRight: "15px",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewUserDetails);
// Customizable Area End
