// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentProgressJourneyDesktopController, {
  Props,
} from "./StudentProgressJourneyDesktopController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import StudentCourseJourneyBox from "./StudentCourseJourneyBox.web";
import StudentCourseActivityJourneyBox from "./StudentCourseActivityJourneyBox.web";
import TeacherCourseInfoModal from "./TeacherCourseInfoModal.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { plus } from "../src/assets";
import TeacherAddNewTopicModal from "./TeacherAddNewTopicModal.web";
import TeacherAddNewLessonModal from "./TeacherAddNewLessonModal.web";
import StudentCourseActivityJourneyDetails from "./StudentCourseActivityJourneyDetails.web";
import AddNewActivityModal from "./AddNewActivityModal.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import DeleteConfirmDialog from "./DeleteConfirmDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    topicLessonParentBox: {
      maxWidth: "100%",
      maxHeight: "600px",
      width: "100%",
      borderRadius: "10px"
    },
    allColumnDataList: {
      borderTop: "2px solid #f1f1f1",
      height: "85vh",
      overflowY: "auto",
    },
    borderRight: {
      borderRight: "2px solid #f1f1f1",
    },
    customGrid: {
      maxWidth: "31%",
      flexBasis: "31%",
    },
    columnTitleHeader: {
      padding: "15px 15px",
      backgroundColor: "rgba(0, 157, 255, 0.05)",
    },
    customGrid2: {
      maxWidth: "38%",
      flexBasis: "38%",
    },
    leftMargin: {
      marginLeft: "5px",
    },
    leftSIdeMargin28: {
      marginRight: "24px",
    },
    studentSearchBox: {
      padding: "20px 0px",
      borderBottom: "1px solid #f1f1f1",
      textAlign: "center",
    },
    courseJourneyMngMainContainer: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 0px 0px",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentProgressJourneyDesktop extends StudentProgressJourneyDesktopController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End
  renderTopics =(classes: { customGrid: any; borderRight: any; columnTitleHeader: string | undefined; fontBold600: any; fontText18: any; leftMargin: any; primaryColor14: any; studentSearchBox: string | undefined; columnDataList: string | undefined; },isStudentCourse: boolean | undefined,searchTopicValue: string,subjectTopicList: any[],isFromTenant: boolean | undefined,selectedTopicId: any) =>{
    return (
      <Grid
      className={`${classes.customGrid} ${classes.borderRight}`}
      item
      xs={4}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box
            className={classes.columnTitleHeader}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={!isStudentCourse ? "2px solid #F1F1F1" : ""}
          >
            <Typography
              className={`${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin} ${classes.primaryColor14}`}
              component={"span"}
            >
              Topics
            </Typography>
          
            
            {(!isStudentCourse && this.props.isActivityWrite) && (   <img
                src={plus}
                data-testId='imagplus'
                style={{ cursor: "pointer" }}
                alt="Plus Icon"
                onClick={this.handleOpenAddNewTopicModal}
              />
            )}
            
          </Box>
          {isStudentCourse && (
            <Box className={classes.studentSearchBox}>
              <CustomTextField
                fieldName="searchTopicValue"
                placeHolderText="Search Topics"
                fieldId="student-search-topic-input"
                textFieldStyles={{
                  padding: "6px 0",
                  maxWidth: "100%",
                  width: "82%",
                }}
                fieldValue={searchTopicValue}
                fieldType="text"
                fieldWidth={"100%"}
                handleChange={this.handleInputChange}
                disableDarkmode
                startAdornmentIcon={
                  <SearchIcon
                    fill="#888888"
                    stroke="#888888"
                    height="18"
                    viewBox="0 0 18 18"
                    strokeWidth="0.45"
                    width="18"
                  />
                }
              />
            </Box>
          )}
        </Grid>
        {subjectTopicList.length > 0 ? (
          <Grid className={classes.columnDataList} item xs={12}>
            <DragDropContext
              data-test-id="topicdrag"
              onDragEnd={this.handleDragEnd}
            >
              <Droppable
                droppableId="selectedTopicList"
                type="SELECTED_TOPIC"
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {subjectTopicList?.map(
                      (topic: any, index: number) => (
                        <Draggable
                          key={topic.id.toString()}
                          draggableId={topic.id.toString()}
                          index={index}
                          data-test-id="topicData"
                        >
                          {(providedDraggable) => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <StudentCourseJourneyBox
                                showInfo={false}
                                isActivityWrite={this.props.isActivityWrite}
                                courseData={this.props.courseData}
                                key={topic.id}
                                index={index}
                                detail={{
                                  completionProgress:
                                    topic?.topicCompProgress || 0,
                                  id: topic.id,
                                  title: topic.topicTitle || "",
                                }}
                                isFromTenant={isFromTenant}
                                isStudent={isStudentCourse}
                                handleSelectedItem={
                                  this.handleSelectTopics
                                }
                                selectedItemId={selectedTopicId}
                                menuType={"Topic"}
                                getMenuItem={(menu: any) => {
                                  this.handleMenuItemEvent(menu);
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        ) : (
          <CustomEmptyResultComponent message="No topics added" />
        )}
      </Grid>
    </Grid>
    )
  }
  renderLessions=(classes: { borderRight: any; customGrid: any; columnTitleHeader: string | undefined; fontBold600: any; fontText18: any; primaryColor14: any; leftMargin: any; studentSearchBox: string | undefined; allColumnDataList: string | undefined; },isStudentCourse: boolean | undefined,searchLessonValue: any,topicLessonsCompleteList: any[],isFromTenant: any,selectedtLessonId: any)=>{
    return(
      <Grid
      className={`${classes.borderRight} ${classes.customGrid}`}
      item
      xs={4}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box
            className={classes.columnTitleHeader}
            display={"flex"}
            alignItems={"center"}
            borderBottom={!isStudentCourse ? "2px solid #F1F1F1" : ""}
            justifyContent={"space-between"}
          >
            <Typography
              className={`${classes.fontBold600} ${classes.fontText18} ${classes.primaryColor14} ${classes.leftMargin}`}
              component={"span"}
            >
              Lessons
            </Typography>
           
            {(!isStudentCourse &&  this.props.isActivityWrite) &&(
              <img
                src={plus}
                alt=""
                data-testId='openlesson'
                style={{ cursor: "pointer" }}
                onClick={this.handleOpenAddNewLessonsModal}
              />
            )}
          </Box>
          {isStudentCourse && (
            <Box className={classes.studentSearchBox}>
              <CustomTextField
                placeHolderText="Search Lessons"
                fieldId="student-search-lesson-input"
                fieldName="searchLessonValue"
                fieldValue={searchLessonValue}
                fieldWidth={"100%"}
                textFieldStyles={{
                  maxWidth: "100%",
                  padding: "6px 0",
                  width: "82%",
                }}
                fieldType="text"
                handleChange={this.handleInputChange}
                startAdornmentIcon={
                  <SearchIcon
                    fill="#888888"
                    stroke="#888888"
                    strokeWidth="0.45"
                    viewBox="0 0 18 18"
                    height="18"
                    width="18"
                  />
                }
              />
            </Box>
          )}
        </Grid>
       
        {topicLessonsCompleteList.length > 0 ? (
          <Grid
            className={
              !isStudentCourse ? classes.allColumnDataList : ""
            }
            item
            xs={12}
          >
            <DragDropContext
              data-test-id="lesson"
              onDragEnd={this.handleDragEndLessons}
            >
              <Droppable
                data-test-id="lesson2"
                droppableId="topicLessonsList"
                type="TOPIC_LESSON"
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {topicLessonsCompleteList?.map(
                      (lesson: any, index: number) => (
                        <Draggable
                          key={lesson.id.toString()}
                          draggableId={lesson.id.toString()}
                          index={index}
                          data-test-id={`lesson3-${index}`}
                        >
                          {(providedDraggable) => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              
                              <StudentCourseJourneyBox
                                showInfo={true}
                                key={lesson.id}
                                isActivityWrite={this.props.isActivityWrite}
                                index={index}
                                topicId={this.state.selectedTopicId}
                                courseData={this.props.courseData}
                                detail={{
                                  completionProgress:
                                    lesson?.lessonComplProgress || 0,
                                  id: lesson.id,
                                  title: lesson.lessonTitle || "",
                                }}
                                isFromTenant={isFromTenant}
                                isStudent={isStudentCourse}
                                handleSelectedItem={
                                  this.handleSelectLessons
                                }
                                selectedItemId={selectedtLessonId}
                                menuType={"lessons"}
                                getMenuItem={(menu: any) => {
                                  this.handleMenuItemEvent(menu);
                                }}
                                handleInfoDialog={(data: any) => {
                                  this.handleCourseInfoDialog(data);
                                }}
                                callActivityApi={this.handleActivityCall}
                                callLessonsApi={this.handleGetLessonListApi}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        ) : (
          <CustomEmptyResultComponent message="No lesson added" />
        )}
      </Grid>
    </Grid>
    )
  }
  renderActivity=(classes: { customGrid2: string | undefined; columnTitleHeader: string | undefined; fontBold600: any; primaryColor14: any; fontText18: any; leftSIdeMargin28: string | undefined; studentSearchBox: string | undefined; allColumnDataList: string | undefined; },isStudentCourse: boolean | undefined,searchActivityValue: string,lessonsActivityCompleteList: any[],selectedActivityId: any,isFromTenant: boolean | undefined,classId:string | undefined)=>{
    return(
      <Grid item xs={4} className={classes.customGrid2}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box
            className={classes.columnTitleHeader}
            display={"flex"}
            borderBottom={!isStudentCourse ? "2px solid #F1F1F1" : ""}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              className={`${classes.fontBold600} ${classes.primaryColor14} ${classes.fontText18}`}
              component={"span"}
            >
              Activity
            </Typography>
            
            {(!isStudentCourse && this.props.isActivityWrite) && (
              <img
                src={plus}
                alt=""
                data-testId='openaction'
                className={classes.leftSIdeMargin28}
                style={{ cursor: "pointer" }}
                onClick={this.handleOpenAddNewActivityModal}
              />
            )}
           
          </Box>
          {isStudentCourse && (
            <Box className={classes.studentSearchBox}>
              <CustomTextField
                placeHolderText="Search Activity"
                fieldName="searchActivityValue"
                fieldId="student-search-activity-input"
                textFieldStyles={{
                  width: "83%",
                  maxWidth: "100%",
                  padding: "6px 0px",
                }}
                fieldValue={searchActivityValue}
                fieldWidth={"100%"}
                fieldType="text"
                startAdornmentIcon={
                  <SearchIcon
                    fill="#888888"
                    height="18"
                    stroke="#888888"
                    strokeWidth="0.45"
                    viewBox="0 0 18 18"
                    width="18"
                  />
                }
                handleChange={this.handleInputChange}
              />
            </Box>
          )}
        </Grid>
      
        {lessonsActivityCompleteList.length > 0 ? (
          <Grid
            className={
              !isStudentCourse ? classes.allColumnDataList : ""
            }
            item
            xs={12}
          >
            <DragDropContext onDragEnd={this.handleDragEndActivities}>
              <Droppable droppableId="activityList" type="ACTIVITY">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {lessonsActivityCompleteList?.map(
                      (activity: any, activityIndex: number) => (
                        <Draggable
                          key={activity.id.toString()}
                          draggableId={activity.id.toString()}
                          index={activityIndex}
                        >
                          {(providedDraggable) => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <StudentCourseActivityJourneyBox
                              isActivityWrite={this.props.isActivityWrite}
                              selectedTopic={this.state.selectedTopic}
                              selectedLesson={this.state.selectedLesson}
                              courseData={this.props.courseData}
                              role={this.props.role}
                              selectedActivity={this.state.lessonsActivityCompleteList}
                                isFromAccordions={false}
                                key={activity.id}
                                index={activityIndex}
                                detail={{
                                  completionProgress:
                                    activity.activityCompletionProgress ||
                                    0,
                                  id: activity.id,
                                  title: activity.activityTitle || "",
                                  dueDate: activity.activityDueDate || ""
                                }}
                                handleActivitySelectItem={
                                  this.handleSelectActivity
                                }
                                selectedItemId={selectedActivityId}
                                typesOfActivity={
                                  activity.typeOfActivity
                                }
                                gradeComponent={
                                  activity?.gradingComponent
                                }
                                isFromTenant={isFromTenant}
                                isStudent={isStudentCourse}
                                menuType={"Activity"}
                                getMenuItem={(menu: any) => {
                                  this.handleMenuItemEvent(menu);
                                }}
                                callActivityApi={this.handleActivityCall}
                                classId={classId}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        ) : (
          <CustomEmptyResultComponent message="No activity added" />
        )}
      </Grid>
    </Grid>
    )
  }
  render() {
    // Customizable Area Start
    const {
      classes,
      isTeacherCourse,
      isFromTenant,
      courseData,
      isStudentCourse,
      isFromRegistrar,
      classId
    } = this.props;
    const {
      openCourseInfoDialog,
      lessonsActivityCompleteList,
      selectedTopicId,
      openAddNewTopicModal,
      selectedtLessonId,
      topicLessonsCompleteList,
      subjectTopicList,
      selectedActivityId,
      selectedTopic,
      selectedLesson,
      searchTopicValue,
      searchLessonValue,
      searchActivityValue,
      editTopic,
      editTopicId,
      openAddNewLessonsModal,
      editLessonId,
      editLesson,
      openDeleteConfirmDialog,
      deleteItemTitle,
      deleteItemType,
      openAddNewActivityModal,
      selectedActivity,
      isActivityOpen,
      editActivity,
      editActivityId,
      refreshActivityDetail,
      courseInfoContent,
    } = this.state;
    return (
      <>
        {/* Topic Lessons Activities Box */}
        {isActivityOpen ? (
          <StudentCourseActivityJourneyDetails
            isActivityWrite={this.props.isActivityWrite}
            activityList={lessonsActivityCompleteList}
            selectedActivity={selectedActivity}
            courseDetails={{
              courseId: courseData?.id,
              courseTitle: courseData?.courseTitle,
            }}
            isFromRegistrar={isFromRegistrar}
            selectedLesson={selectedLesson?.lessonTitle}
            selectedTopic={selectedTopic?.topicTitle}
            isFromTenant={isFromTenant}
            navigation={this.props.navigation}
            isStudent={isStudentCourse}
            goBack={this.GoJourneyBack}
            openAddNewActivity={this.handleOpenAddNewActivityModal}
            handleMenuAction={(menu: any) => {
              this.handleMenuItemEvent(menu);
            }}
            refreshSelectedActivity={refreshActivityDetail}
            getSelectedActivityId={(data: any) => {
              this.handleGetSelectedActivityId(data);
            }}
            handleMarkActivityAsCompleted={(activityId: any, activityTimeSpent: number, isCompleted?: boolean) =>
              this.handleUpdateActivityMarkCompleteApi(activityId, activityTimeSpent, isCompleted)
            }
          />
        ) : (
          <Box className={`${classes.bgColor} ${classes.topicLessonParentBox}`}>
            <Grid
              className={classes.courseJourneyMngMainContainer}
              container
              direction="row"
            >
              {isFromTenant && (
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid
                      className={classes.customGrid}
                      item
                      xs={4}
                      style={{ padding: "25px" }}
                    >
                      <CustomTextField
                        placeHolderText="Search Topics"
                        fieldName="searchTopicValue"
                        fieldId="search-topic-input"
                        textFieldStyles={{
                          padding: "10px 0",
                          width: "400px",
                          maxWidth: "100%",
                        }}
                        fieldValue={searchTopicValue}
                        fieldWidth={"100%"}
                        fieldType="text"
                        handleChange={this.handleInputChange}
                        disableDarkmode
                        startAdornmentIcon={
                          <SearchIcon
                            fill="#888888"
                            height="18"
                            stroke="#888888"
                            strokeWidth="0.45"
                            viewBox="0 0 18 18"
                            width="18"
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      className={classes.customGrid}
                      item
                      xs={4}
                      style={{ padding: "25px" }}
                    >
                      <CustomTextField
                        placeHolderText="Search Lessons"
                        fieldName="searchLessonValue"
                        fieldId="search-leson-input"
                        textFieldStyles={{
                          padding: "10px 0",
                          width: "400px",
                          maxWidth: "100%",
                        }}
                        fieldValue={searchLessonValue}
                        fieldWidth={"100%"}
                        fieldType="text"
                        handleChange={this.handleInputChange}
                        disableDarkmode
                        startAdornmentIcon={
                          <SearchIcon
                            fill="#888888"
                            height="18"
                            stroke="#888888"
                            strokeWidth="0.45"
                            viewBox="0 0 18 18"
                            width="18"
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      className={classes.customGrid2}
                      item
                      xs={4}
                      style={{ padding: "25px" }}
                    >
                      <CustomTextField
                        placeHolderText="Search Activity"
                        fieldName="searchActivityValue"
                        fieldId="search-activity-input"
                        textFieldStyles={{
                          padding: "10px 0",
                          width: "400px",
                          maxWidth: "100%",
                        }}
                        fieldValue={searchActivityValue}
                        fieldWidth={"100%"}
                        fieldType="text"
                        handleChange={this.handleInputChange}
                        disableDarkmode
                        startAdornmentIcon={
                          <SearchIcon
                            fill="#888888"
                            height="18"
                            stroke="#888888"
                            strokeWidth="0.45"
                            viewBox="0 0 18 18"
                            width="18"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {this.renderTopics(classes,isStudentCourse,searchTopicValue,subjectTopicList,isFromTenant,selectedTopicId)}
              {this.renderLessions(classes,isStudentCourse,searchLessonValue,topicLessonsCompleteList,isFromTenant,selectedtLessonId)}
              {this.renderActivity(classes,isStudentCourse,searchActivityValue,lessonsActivityCompleteList,selectedActivityId,isFromTenant,classId)}
            </Grid>
          </Box>
        )}

        {/* Lesson info Dialog */}
        {openCourseInfoDialog && (
          <TeacherCourseInfoModal
            isStudent={isStudentCourse}
            open={openCourseInfoDialog}
            title={courseInfoContent.title}
            courseInfo={courseInfoContent}
            activityInfo={null}
            onClose={this.handleCloseCourseInfoDialogs}
          />
        )}

        {openAddNewTopicModal && (
          <TeacherAddNewTopicModal
            open={openAddNewTopicModal}
            onCancel={this.handleCloseAddNewTopicModal}
            onConfirm={this.handleCloseAddNewTopicModal}
            isEdit={editTopic}
            topicId={editTopicId}
            courseId={courseData.id}
            handleAddNewTopic={(data: any) => {
              this.handleAddNewTopicPayload(data);
            }}
            handleUpdateTopic={(data: any) => {
              this.handleUpdateTopicPayload(data);
            }}
          />
        )}

        {openAddNewLessonsModal && (
          <TeacherAddNewLessonModal
            open={openAddNewLessonsModal}
            onCancel={this.handleCloseAddNewLessonsModal}
            onConfirm={this.handleCloseAddNewLessonsModal}
            isEdit={editLesson}
            lessonId={editLessonId}
            handleAddNewLesson={(data: any) => {
              this.handleAddNewLessonPayload(data);
            }}
            handleUpdateLesson={(data: any) => {
              this.handleUpdateLessonPayload(data);
            }}
          />
        )}

        {openAddNewActivityModal && (
          <AddNewActivityModal
            classId={classId}
            open={openAddNewActivityModal}
            onCancel={this.handleCloseAddNewActivityModal}
            onConfirm={this.handleCloseAddNewActivityModal}
            isEdit={editActivity}
            activityId={editActivityId}
            lesson={selectedLesson}
            topic={selectedTopic}
            course={courseData}
            handleAddNewActivity={(data: any) =>
              this.handleAddNewActivityEvent(data)
            }
            handleUpdateActivity={(data: any) =>
              this.handleUpdateActivityEvent(data)
            }
          />
        )}

        {openDeleteConfirmDialog && (
          <DeleteConfirmDialog
            open={openDeleteConfirmDialog}
            onCancel={this.handleCloseDeleteConfirmDialog}
            onConfirm={this.handleDeleteItem}
            headingTitle={deleteItemType}
            itemTitle={deleteItemTitle}
          />
        )}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProgressJourneyDesktop);
// Customizable Area End
