// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  ICustomTableProps,
  IDropdownMenuItemProps,
  IInputConfigProps,
  ITableActionBtnConfigProps,
} from "../../../components/src/CommonType.web";
import {
  pendingRequestCoursesListMock,
  gradeLevelListMock,
  subjectsListMock,
  titleListMock,
  pendingRequestsCourseDetailInputConfigMock,
  pendingRequestCourseDetailTableHeaderColumnsMock,
  countListMock,
  statusListMock1,
} from "../assets/tenantCourseManagementMockdata";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export const pendingRequestsTabsList = [
  {
    id: 1,
    label: "Resources",
    value: "resources",
  },
  {
    id: 2,
    label: "Journey",
    value: "journey",
  },
  {
    id: 3,
    label: "Participants",
    value: "participants",
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedPendingRequestTabIndex: number;
  topicTitlesList: Array<any>;
  topicTitleDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedTitleDDValue: any;
  gradeLevelList: Array<any>;
  selectedGradeLevelDDValue: any;
  gradeLevelDDMenuItems: Array<IDropdownMenuItemProps>;
  statusDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedStatusDDValue: any;
  noOfLessonsPerDayDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedNoOfLessonsPerDayDDValue: any;
  subjectHandledList: Array<any>;
  subjectHandledDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedSubjectHandledDDValue: any;
  topicsList: Array<any>;
  tableBodyData: Array<any>;
  managePendingRequestInputConfig: Array<IInputConfigProps>;
  tableHeaderColumns: Array<ICustomTableProps>;
  tableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  selectedSubjectTopic: any;
  openRejectApproveModal: boolean;
  rejectRequest: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantCourseManagementPendingRequestDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedPendingRequestTabIndex: 0,
      topicTitlesList: [],
      topicTitleDDMenuItems: titleListMock,
      selectedTitleDDValue: "",
      gradeLevelDDMenuItems: gradeLevelListMock,
      gradeLevelList: [],
      selectedGradeLevelDDValue: "",
      statusDDMenuItems: statusListMock1,
      selectedStatusDDValue: "",
      subjectHandledList: [],
      subjectHandledDDMenuItems: subjectsListMock,
      selectedSubjectHandledDDValue: "",
      noOfLessonsPerDayDDMenuItems: countListMock,
      selectedNoOfLessonsPerDayDDValue: "",
      topicsList: pendingRequestCoursesListMock,
      tableBodyData: [],
      tableHeaderColumns: [],
      managePendingRequestInputConfig: [],
      tableActionButtonConfig: [],
      selectedSubjectTopic: "",
      openRejectApproveModal: false,
      rejectRequest: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetCurrentCourse();
    this.handleSetManagePendingRequestsListData();
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetCurrentCourse = () => {
    const paramCourse = this.props.navigation?.getParam("course");
    this.setState({ selectedSubjectTopic: paramCourse });
  };

  handleChangePendingRequestTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({ selectedPendingRequestTabIndex: newValue }, () => {
      this.handleSetManagePendingRequestsListData();
    });
  };

  setPendingRequestDDValue = (selected: any, fieldName: string) => {
    const {
      topicTitleDDMenuItems,
      gradeLevelDDMenuItems,
      noOfLessonsPerDayDDMenuItems,
      subjectHandledDDMenuItems,
      statusDDMenuItems,
    } = this.state;

    let label: any = "";

    if (fieldName === "topicTitleDDMenuItems") {
      const selectedItem = topicTitleDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradeLevelDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "noOfLessonsPerDayDDMenuItems") {
      const selectedItem = noOfLessonsPerDayDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "statusDDMenuItems") {
      const selectedItem = statusDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else {
      const selectedItem = subjectHandledDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderDDMenuItem = (selected: any, fieldName: string, emptyText: string) => {
    if (!selected || selected.length === 0) {
      return `Select ${emptyText}`;
    }
    return this.setPendingRequestDDValue(selected, fieldName);
  };

  handleSetManagePendingRequestsListData = () => {
    const { selectedPendingRequestTabIndex } = this.state;
    const tenantCourseMngTabCategory =
      pendingRequestsTabsList[selectedPendingRequestTabIndex].value;
    const { topicsList } = this.state;
    let managePendingRequestInputConfigs: Array<IInputConfigProps> = [];
    if (tenantCourseMngTabCategory === "resources") {
      managePendingRequestInputConfigs =
        pendingRequestsCourseDetailInputConfigMock;
      this.setState({
        tableBodyData: topicsList,
        tableHeaderColumns: pendingRequestCourseDetailTableHeaderColumnsMock,
        managePendingRequestInputConfig: managePendingRequestInputConfigs,
        tableActionButtonConfig: [
          {
            label: "View",
            action: (value: any) => this.handleManageRequestTableAction(value),
          },
        ],
      });
    } else {
      this.setState({
        tableBodyData: [],
        tableHeaderColumns: [],
        managePendingRequestInputConfig: [],
        tableActionButtonConfig: [],
      });
    }
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { managePendingRequestInputConfig } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const updatedDDConfig = managePendingRequestInputConfig.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          managePendingRequestInputConfig: updatedDDConfig,
        };
      });
    }
  };

  handleSearchPendingRequests = () => {
    this.setState({ loading: true });
    console.log("search pending course!");
  };

  handleManageRequestTableAction = (value?: any) => {
    console.log("pending table action", value);
  };

  handleGoBackToManageRequestsMain = () => {
    this.props.navigation?.goBack();
  };

  handleRejectRequestBtn = () => {
    this.setState({
      openRejectApproveModal: !this.state.openRejectApproveModal,
      rejectRequest: true,
    });
  };

  handleApproveRequestBtn = () => {
    this.setState({
      openRejectApproveModal: !this.state.openRejectApproveModal,
      rejectRequest: false,
    });
  };

  handleRejectApproveAction = () => {
    this.setState({
      openRejectApproveModal: false,
    });
  };

  // Customizable Area End
}
