// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  hideCustomLoader,
  showCustomLoader,
  toBase64,
} from "../../../components/src/CommonHelper.web";
import { IMAGE_FILETYPES } from "../../../components/src/CommonType.web";

const ntc = require("ntcjs");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: () => void;
  handleAddSubject: any;
  handleEditSubject: any;
  selectedSubjectData: any;
  isEdit?: any;
  gradesList: Array<any>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  subjectName: string;
  subjectNameError: boolean;
  subjectNameErrorMsg: string;
  selectedGrades: Array<any>;
  selectedGradesError: boolean;
  selectedGradesErrorMsg: string;
  selectedColor: string;
  selectedColorName: string;
  selectedColorError: boolean;
  selectedColorErrorMsg: string;
  selectedIcon: any;
  selectedIconName: string;
  selectedIconUrl: string;
  selectedIconType: string;
  selectedIconError: boolean;
  selectedIconErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewSubjectDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      subjectName: "",
      subjectNameError: false,
      subjectNameErrorMsg: "",
      selectedGrades: [],
      selectedGradesError: false,
      selectedGradesErrorMsg: "",
      selectedColor: "",
      selectedColorName: "",
      selectedColorError: false,
      selectedColorErrorMsg: "",
      selectedIcon: "",
      selectedIconName: "",
      selectedIconType: "",
      selectedIconUrl: "",
      selectedIconError: false,
      selectedIconErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleFileInputChange = (file: any) => {
    this.setState({
      selectedIcon: file || "",
      selectedIconName: file?.name || "",
      selectedIconType: file?.type || "",
      selectedIconError: false,
      selectedIconErrorMsg: "",
    });
    // Handle the selected file here
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "subjectName") {
      this.setState({ subjectNameError: false, subjectNameErrorMsg: "" });
    }
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleColorChange = (color: any) => {
    const n_match = ntc.name(color);
    this.setState({
      selectedColor: color,
      selectedColorName: n_match[1],
      selectedColorError: false,
      selectedColorErrorMsg: "",
    });
  };

  handleValidateIcon = () => {
    const { selectedIconType } = this.state;
    let isValid = true;
    if (selectedIconType) {
      if (!IMAGE_FILETYPES.includes(selectedIconType)) {
        isValid = false;
        this.setState({
          selectedIconError: true,
          selectedIconErrorMsg: "Please upload a valid image file!",
        });
      }
    }
    return isValid;
  };

  handleVerifySubjectData = () => {
    const {
      subjectName,
      selectedColor,
      selectedGrades,
      selectedIcon,
      selectedIconName,
    } = this.state;
    const { isEdit } = this.props;
    let isValid = true;
    if (!subjectName.trim()) {
      this.setState({
        subjectNameError: true,
        subjectNameErrorMsg: "Please enter subject name",
      });
      isValid = false;
    }
    if (!selectedColor) {
      this.setState({
        selectedColorError: true,
        selectedColorErrorMsg: "Please enter subject color",
      });
      isValid = false;
    }
    if (!selectedGrades.length) {
      this.setState({
        selectedGradesError: true,
        selectedGradesErrorMsg: "Please select at least 1 grade",
      });
      isValid = false;
    }
    if (!isEdit) {
      if (!selectedIcon) {
        this.setState({
          selectedIconError: true,
          selectedIconErrorMsg: "Please upload subject icon",
        });
        isValid = false;
      }
    } else {
      if (!selectedIconName) {
        this.setState({
          selectedIconError: true,
          selectedIconErrorMsg: "Please upload subject icon",
        });
        isValid = false;
      }
    }
    return isValid;
  };

  handleAddSubject = async () => {
    if (this.handleVerifySubjectData() && this.handleValidateIcon()) {
      const {
        subjectName,
        selectedColor,
        selectedIcon,
        selectedGrades,
        selectedIconType,
        selectedIconName,
      } = this.state;
      const { isEdit } = this.props;
      let base64Url: any = "";
      if (selectedIcon) {
        base64Url = await toBase64(selectedIcon);
      }
      const requestObj: any = {
        data: {
          type: "subject",
          attributes: {
            name: subjectName,
            content_type: selectedIconType,
            filename: selectedIconName,
            grade_id: selectedGrades?.map((grade: any) => {
              return parseInt(grade);
            }),
            color: selectedColor,
          },
        },
      };
      if (base64Url) {
        requestObj.data.attributes.icon = base64Url;
      }
      if (isEdit) {
        this.props.handleEditSubject(requestObj);
      } else {
        this.props.handleAddSubject(requestObj);
      }
    }
  };

  handleSetSubjectInitialState = async (isEdit?: boolean) => {
    if (isEdit) {
      showCustomLoader();
      const { selectedSubjectData } = this.props;
      const colorName = ntc.name(selectedSubjectData?.color);
      this.setState({
        subjectName: selectedSubjectData?.subject?.name,
        subjectNameError: false,
        subjectNameErrorMsg: "",
        selectedColor: selectedSubjectData?.color,
        selectedColorName: colorName[1],
        selectedIcon: "",
        selectedIconName: selectedSubjectData?.icon?.name || "",
        selectedIconUrl: selectedSubjectData?.subject?.icon || "",
        selectedGrades: selectedSubjectData?.grade,
        selectedColorError: false,
        selectedColorErrorMsg: "",
        selectedGradesError: false,
        selectedGradesErrorMsg: "",
        selectedIconError: false,
        selectedIconErrorMsg: "",
        selectedIconType: selectedSubjectData?.subject?.iconType || "",
      });
      hideCustomLoader();
    } else {
      this.setState(
        {
          subjectName: "",
          subjectNameError: false,
          subjectNameErrorMsg: "",
          selectedColor: "",
          selectedColorError: false,
          selectedColorErrorMsg: "",
          selectedGrades: [],
          selectedGradesError: false,
          selectedGradesErrorMsg: "",
          selectedIcon: "",
          selectedIconError: false,
          selectedIconErrorMsg: "",
          selectedIconName: "",
          selectedIconType: "",
          selectedIconUrl: "",
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleClose = () => {
    this.handleSetSubjectInitialState();
  };

  handleChangeGrade = (event: any) => {
    const { gradesList } = this.props;
    if (event.target.value.includes("All")) {
      this.setState({
        selectedGrades: gradesList.map((item) => {
          return item.id;
        }),
      });
    } else {
      this.setState({
        selectedGrades: event.target.value,
        selectedGradesError: false,
        selectedGradesErrorMsg: "",
      });
    }
    this.setState({
      selectedGradesError: false,
      selectedGradesErrorMsg: "",
    });
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { isEdit } = this.props;
    if (isEdit) this.handleSetSubjectInitialState(true);
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  // Customizable Area End
}
// Customizable Area End
