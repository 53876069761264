// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CatalogTabController, {
  Props
} from "./CatalogTabController";
import { customThemeStyles } from "../../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../../components/src/CombineStyles.web";
import CustomButton from "../../../../components/src/CustomButton.web";
import DropdownIcon from "../../../../components/src/SVGIcons/DropdownIcon.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomSelectDropdown from "../../../../components/src/CustomSelectDropdown.web";
import CustomTable, { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "../assets";
import AssignCourseModal from "./AssignCourseModal.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';


// Customizable Area End
const catalogTableColumns = [
  {
    columnId: 11,
    id: "CatalogName",
    label: "Catalog Name fdbgf",
    type: tableColumnTypes.CATALOG_NAME

  },
  {
    columnId: 12,
    id: "Courses",
    label: "No. of Courses",
    type: tableColumnTypes.TEXT
  },
  {
    columnId: 13,
    id: "Grade",
    label: "Grade",
    type: tableColumnTypes.TEXT
  },
  {
    id: "Curriculum",
    columnId: 14,
    label: "Curriculum",
    type: tableColumnTypes.TEXT
  },
  {
    columnId: 15,
    id: "CustomField",
    label: "Custom Field",
    type: tableColumnTypes.TEXT
  },
  {
    id: "CreatedBy",
    columnId: 6,
    label: "Created By",
    type: tableColumnTypes.TEXT
  },
]
const catalogData = [
  {
    id: 1,
    CatalogName: 'Catalog 1',
    Courses: '90',
    Grade: 'Grade 3',
    Curriculum: 'Curriculum 1',
    CustomField: 'Tag 1',
    CreatedBy: 'Thynker Tech',
  },
  {
    id: 2,
    CatalogName: 'Catalog 2',
    Courses: '90',
    Grade: 'Grade 3',
    Curriculum: 'Curriculum 1',
    CustomField: 'Tag 1',
    CreatedBy: 'Thynker Tech',
  },
  {
    id: 3,
    CatalogName: 'Catalog 3',
    Courses: '90',
    Grade: 'Grade 3',
    Curriculum: 'Curriculum 1',
    CustomField: 'Tag 1',
    CreatedBy: 'Thynker Tech',
  },
  {
    id: 4,
    CatalogName: 'Catalog 4',
    Courses: '90',
    Grade: 'Grade 3',
    Curriculum: 'Curriculum 1',
    CustomField: 'Tag 1',
    CreatedBy: 'Thynker Tech',
  },
  {
    id: 5,
    CatalogName: 'Catalog 5',
    Courses: '90',
    Grade: 'Grade 3',
    Curriculum: 'Curriculum 1',
    CustomField: 'Tag 1',
    CreatedBy: 'Thynker Tech',
  },
  {
    id: 6,
    CatalogName: 'Catalog 6',
    Courses: '90',
    Grade: 'Grade 3',
    Curriculum: 'Curriculum 1',
    CustomField: 'Tag 1',
    CreatedBy: 'Thynker Tech',
  },
];

const catalogCoursesTableColumns = [
  {
    columnId: 11,
    id: "Title",
    label: "Title",
    type: tableColumnTypes.COURSES_NAME

  },
  {
    columnId: 12,
    id: "Subject",
    label: "Subject",
    type: tableColumnTypes.TEXT
  },
  {
    columnId: 13,
    id: "Grade",
    label: "Grade",
    type: tableColumnTypes.TEXT
  },
  {
    id: "LessonsDay",
    columnId: 14,
    label: "No. of lessons / day",
    type: tableColumnTypes.TEXT
  },
  {
    columnId: 15,
    id: "NoOfLessons",
    label: "Total No. of lessons",
    type: tableColumnTypes.TEXT
  },
  {
    id: "ModifiedBy",
    columnId: 16,
    label: "Modified By",
    type: tableColumnTypes.TEXT
  },
  {
    id: "CreatedBy",
    columnId: 17,
    label: "Created By",
    type: tableColumnTypes.TEXT
  },
];
const catalogCoursesData = [
  {
    id: 1,
    Title: {
      name: "Physics",
      icon: ScienceColor,
    },
    Subject: 'Science',
    Grade: 'Grade 3',
    LessonsDay: '1 Lesson / day',
    NoOfLessons: '47',
    ModifiedBy: 'Bairam Frootan',
    CreatedBy: 'Conan Matusov',
  },
  {
    id: 2,
    Title: {
      name: "Algebra",
      icon: MathsColor,
    },
    Subject: 'Maths',
    Grade: 'Grade 3',
    LessonsDay: '1 Lesson / day',
    NoOfLessons: '47',
    ModifiedBy: 'Bairam Frootan',
    CreatedBy: 'Conan Matusov',
  },
  {
    id: 3,
    Title: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subject: 'History',
    Grade: 'Grade 3',
    LessonsDay: '1 Lesson / day',
    NoOfLessons: '47',
    ModifiedBy: 'Bairam Frootan',
    CreatedBy: 'Conan Matusov',
  },
  {
    id: 4,
    Title: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    LessonsDay: '1 Lesson / day',
    NoOfLessons: '47',
    ModifiedBy: 'Bairam Frootan',
    CreatedBy: 'Conan Matusov',
  },
  {
    id: 5,
    Title: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subject: 'English',
    Grade: 'Grade 3',
    LessonsDay: '1 Lesson / day',
    NoOfLessons: '47',
    ModifiedBy: 'Bairam Frootan',
    CreatedBy: 'Conan Matusov',
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "25px",
      marginBottom: "25px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    catalogTitle: {
      color: "#090909",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "normal",

    },
    catalogTableRow: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }
    }
  });

// Customizable Area End

// Customizable Area Start
export class CatalogTab extends CatalogTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      selectedGradeValue,
      openCatalogCourses,
      selectedCatalogName,
      selectedCurriculumValue,
      selectedCustomFieldValue,
      openAssignCourseModal,
      menuAnchorEl,
      selectedSubjectValue,
      selectedTeacherValue,
      selectedSchoolYearValue,
      selectedUtilizationOfCourseValue,
    } = this.state;
    const { classes } = this.props;

    return (
      <Box style={{ padding: "12px 25px 25px 25px" }}>
        {
          !openCatalogCourses ?
            <Box>
              <Grid container spacing={3} direction="row">
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                        Grade
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldName="selectedGradeValue"
                        fieldId="gradeDropdown"
                        handleChange={this.handleChangeDropdownValue}
                        fieldValue={selectedGradeValue}
                        fieldError={false}
                        fieldStyles={dropdownStyles}
                        renderValue={(event: any) => this.renderDropdownValue(event, "Grade", this.getDropdownItemsArray("Grade"))}
                        fieldMenuItems={this.getDropdownItemsArray("Grade")}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>
                        Curriculum
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                      <CustomSelectDropdown
                        fieldId="curriculumDropdown"
                        fieldName="selectedCurriculumValue"
                        handleChange={this.handleChangeDropdownValue}
                        fieldValue={selectedCurriculumValue}
                        fieldError={false}
                        fieldStyles={dropdownStyles}
                        renderValue={(event: any) => this.renderDropdownValue(event, "Curriculum", this.getDropdownItemsArray("Curriculum"))}
                        fieldMenuItems={this.getDropdownItemsArray("Curriculum")}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`} >
                        Custom Field
                      </Typography>
                    </Box>
                    <Box justifyContent={"flex-start"} display={"flex"}>
                      <CustomSelectDropdown
                        fieldId="customFieldDropdown"
                        fieldName="selectedCustomFieldValue"
                        handleChange={this.handleChangeDropdownValue}
                        fieldValue={selectedCustomFieldValue}
                        fieldError={false}
                        fieldStyles={dropdownStyles}
                        renderValue={(event: any) => this.renderDropdownValue(event, "Custom Field", this.getDropdownItemsArray("Custom Field"))}
                        fieldMenuItems={this.getDropdownItemsArray("Custom Field")}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>

                  <Box marginTop={"30px"} marginBottom={"10px"}>
                    <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchAssessment} isDisabled={false} />
                  </Box>
                  {/* </Box> */}
                </Grid>
              </Grid>
              <Box className={classes.dividerLine} />
              <Grid container>
                <Grid item xs={12}>
                  <Box className={`${classes.customTableStyles} ${classes.catalogTableRow}`} >
                    <CustomTable tableId={"catalog-table"} tableHeaderColumns={catalogTableColumns}
                      tableBodyData={catalogData} showMenuIcon={false} handleAction={this.toggleCatalogCourses}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            :
            <Box>
              <Grid direction="row" container style={{ marginTop: "-15px" }}>
                <Grid xs={12} item>
                  <Button onClick={this.toggleCatalogCourses}>
                    <ArrowBackIcon className={classes.textPrimaryColor} fontSize={"medium"} />
                  </Button>
                  <Typography className={`${classes.catalogTitle} ${classes.textPrimaryColor}`} component={"span"}>{selectedCatalogName}</Typography>
                </Grid>
              </Grid>
              <Box className={classes.dividerLine} style={{ marginTop: "25px" }} />
              <Grid direction="row" container spacing={3} >
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                        Teacher
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="calatlog-teacher-dropdown"
                        fieldName="selectedTeacherValue"
                        fieldStyles={dropdownStyles}
                        handleChange={this.handleChangeDropdownValue}
                        fieldError={false}
                        fieldValue={selectedTeacherValue}
                        renderValue={this.renderTeacherDropdownValue}
                        fieldMenuItems={this.getDropdownItemsArray("Teacher")}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                        Subject
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldId="calatlog-subject-dropdown"
                        fieldValue={selectedSubjectValue}
                        fieldName="selectedSubjectValue"
                        renderValue={this.renderSubjectDropdownValue}
                        handleChange={this.handleChangeDropdownValue}
                        fieldMenuItems={this.getDropdownItemsArray("Subject")}
                        fieldError={false}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                        fieldStyles={dropdownStyles}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                        Grade
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                        fieldName="selectedGradeValue"
                        fieldId="catalog-grade-dropdown"
                        fieldValue={selectedGradeValue}
                        handleChange={this.handleChangeDropdownValue}
                        fieldError={false}
                        renderValue={this.renderGradeDropdownValue}
                        fieldStyles={dropdownStyles}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                        fieldMenuItems={this.getDropdownItemsArray("Grade")}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`}>
                        School Year
                      </Typography>
                    </Box>
                    <Box justifyContent={"flex-start"} display={"flex"}>
                      <CustomSelectDropdown
                        fieldName="selectedSchoolYearValue"
                        fieldId="catalog-school-year-dropdown"
                        handleChange={this.handleChangeDropdownValue}
                        fieldError={false} fieldStyles={dropdownStyles}
                        fieldValue={selectedSchoolYearValue}
                        fieldMenuItems={this.getDropdownItemsArray("202")}
                        renderValue={this.renderSchoolYearDropdownValue}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText16}`} >
                        Utilization of the course
                      </Typography>
                    </Box>
                    <Box justifyContent={"flex-start"} display={"flex"}>
                      <CustomSelectDropdown
                        fieldId="catalog-utilization-course-dropdown"
                        handleChange={this.handleChangeDropdownValue}
                        fieldName="selectedUtilizationOfCourseValue"
                        fieldError={false} fieldStyles={dropdownStyles}
                        fieldValue={selectedUtilizationOfCourseValue}
                        fieldMenuItems={this.getDropdownItemsArray("Course Utilization")}
                        renderValue={this.renderUtilizationDropdownValue}
                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} lg={3} md={4} item>
                  <Box marginBottom={"10px"} paddingTop={"32px"}>
                    <CustomButton btnText="Search" btnStyle={customButtonStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchAssessment} isDisabled={false} />
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.dividerLine} />
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.customTableStyles} >
                    <TableContainer className={classes.tableContainer} id="catalog-courses-table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            {catalogCoursesTableColumns.map((column: any) => (
                              <TableCell
                                key={column.id}
                              >
                                <Box display={'flex'}>
                                  <Typography className={`${classes.fontSize15} ${classes.textCommonColor}`} component={"span"}>
                                    {column.label}
                                  </Typography>
                                  {column?.icon ? <img src={column?.icon} className={classes.columnIcon} /> : ''}
                                </Box>
                              </TableCell>
                            ))}
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {catalogCoursesData?.length > 0 &&
                            catalogCoursesData.map((row: any, i: any) => {
                              return (
                                <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                                  {catalogCoursesTableColumns.map((column: any) => {
                                    return (
                                      <TableCell
                                        className={`${classes.tableBodyCell}`}
                                        style={{
                                          minWidth: "100px",
                                          maxWidth: "100%"
                                        }}
                                        key={`${column.id}-${row.id}`}
                                      >
                                        {column.id === "Title" ?
                                          <Box display={"flex"} alignItems="center">
                                            <Box mr={"10px"} className={classes.subjectLogoBg}>
                                              <img src={row[column.id]?.icon} />
                                            </Box>
                                            <Box>
                                              <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                                {row[column.id]?.name}
                                              </Typography>
                                            </Box>
                                          </Box>
                                          :
                                          <Typography className={`${classes.fontSize15} ${classes.textPrimaryColor}`} component={"span"}>
                                            {row[column.id]}
                                          </Typography>

                                        }
                                      </TableCell>
                                    );
                                  })}
                                  <TableCell align="right">
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(e: any) => this.handleActionClick(e, row.id)}
                                    >
                                      <MoreVertIcon className={`${classes.textCommonColor}`} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Menu
                      id={`course-table-menu-action`}
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={this.handleMenuClose}
                    >
                      <MenuItem onClick={this.handleOpenAssignCourseModal}>
                        Assign to Students
                      </MenuItem>
                      <MenuItem onClick={this.handleMenuClose}>
                        Copy to My Courses
                      </MenuItem>
                      <MenuItem onClick={this.handleMenuClose}>
                        View
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
            </Box>
        }
        <AssignCourseModal
          open={openAssignCourseModal}
          onCancel={this.handleCloseAssignCourseModal}
          onConfirm={this.handleCloseAssignCourseModal}
          assignData={{}}
          getAssignCoursePaylod={() => { }}
          getSelectedClassName={() => { }}
        />
      </Box >
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  padding: "10px 10px",
  width: "100%",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  fontWeight: 500,
} as const;

const dropdownStyles = {
  width: "100%",
  height: "50px",
  borderRadius: "12px",
  border: "solid 1px #888888",
  backgroundColor: "#fff",
  padding: "8px 15px",
  fontSize: "16px",
  cursor: "none !important",
  "& .MuiSelect-select": {
    color: "#888888",
    fontWeight: 500,
    minWidth: "215px",
    paddingRight: "0px !important"
  }
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CatalogTab);
// Customizable Area End
