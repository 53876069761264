// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  sort,
} from "./assets";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { ICustomTableProps } from "../../../components/src/CommonType.web";
import {
  AttandanceTableHead,
  BehaviorsTableHead,
  gradebookTableHead,
  studentListTableColumns,subjectCoursesDetailsTableColumns,subjectTableColumns
} from "../assets/mockdata"; 
import { createRef } from "react";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}

export interface ITableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  bgColor?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  schoolSubjectsList: any;
  schoolYearsList: Array<{
    id: string;
    value: string;
    label: string;
  }>;
  selectedSubjectCoursesGradesDetails: any;
  selectedSubjectId: any;
  schoolYearDDMenuItems: Array<IDropdownItems>;
  selectedSchoolYearDDValue: any;
  subjectTableColumns: Array<ITableProps>;
  subjectCoursesDetailsTableColumns: Array<ITableProps>;
  entireSchoolYearDataList: Array<{
    id: number | string;
    semesters: Array<{
      id: number;
      title: string;
    }>;
  }>;
  studentListTableColumns: Array<ICustomTableProps>;
  gradebookTableSemestersColumn:any

  selectedSchoolYearValue: number | string;
  teacherSchoolYearsList: Array<{
    id: string;
    value: string;
    label: string;
  }>;
  selectedStudentValue:string;
  rowWidth:number;
  tableHeight:number,
  totalAvgOfAllCourses:number;
  currentTermAvg:number
  schoolYear:string;
  rejectedComments:any[];
  quarters:any[],
  isViewOpen:boolean,
  AttandanceTableHead:any,
  AttandanceTableData:any,
  gradebookListTableHead:any,
  gradebookTableData:any,
  behaviourTableHead:any,  
  behaviourTableData:any,
  schoolYearData:any,
  selectedTemplateData:any,
  behaviorTableDataLength:number,
  studentData:any,
  isDisabled:boolean,
  className:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyGradebookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSchoolYearList:string=""      
  apiGetGradebookDetails:string=""
  apiGetGradebookAssignmentDetails:string=""
  apiGetRejectGradeCommentsId:string="";
  apiGetReportCardApiCallId:string="";
  tableRef: any;
  rowRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.tableRef = createRef();
    this.rowRef = createRef()

    this.state = {
      loading: false,
      gradebookTableSemestersColumn:[],
      totalAvgOfAllCourses:0,
      currentTermAvg:0,
      schoolSubjectsList: [],
      schoolYearsList: [],
      schoolYear:"",
      selectedSubjectId: 1, 
      selectedSubjectCoursesGradesDetails: [],
      schoolYearDDMenuItems: [],
      selectedSchoolYearDDValue: "",
      subjectTableColumns: subjectTableColumns,
      subjectCoursesDetailsTableColumns:subjectCoursesDetailsTableColumns,
      entireSchoolYearDataList:[],
      studentListTableColumns:studentListTableColumns,
      schoolYearData:"",

      selectedSchoolYearValue: "",
      selectedStudentValue:"",
      teacherSchoolYearsList: [],
      rejectedComments:[],
      rowWidth:0,
      tableHeight:0,
      quarters:[],
      isViewOpen:false,
      AttandanceTableHead:[],
      AttandanceTableData:[],
      gradebookListTableHead:[],
      gradebookTableData:[],
      behaviourTableHead:[],  
      behaviourTableData:[],
      selectedTemplateData:{},
      behaviorTableDataLength:0,
      studentData:{},
      isDisabled:true,
      className:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // const studentId = await 
    this.setTableHeight();
    window.addEventListener('resize', this.handleResize);
    this.handleSetSchoolYearDDItems();
    this.handleSetSelectedSchoolSubject(1);
    this.handleGetTeacherSchoolYearList()
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const { apiRequestCallId, responseJson } = getMessageData(message);
  
        if (apiRequestCallId != null) {
          switch (apiRequestCallId) {
              case this.apiGetSchoolYearList:
                  {
                    this.handleGetTeacherSchoolYearDataResponse(responseJson);
                  }
                  break;
              case this.apiGetGradebookDetails:{
                this.handleGetGradeBookDataResponse(responseJson)
              }
              break; 
              case this.apiGetGradebookAssignmentDetails:{
                this.handleGetGradeBookAssignmentDetailsDataResponse(responseJson)
              }
              break;
              case this.apiGetRejectGradeCommentsId:{
                this.handleGetRejectGradesCommentsResponse(responseJson)
              }
              break;
              case this.apiGetReportCardApiCallId:{
                this.handleGetReportCardResponse(responseJson)
              }
          }
        }
      }

  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.selectedSubjectId !== this.state.selectedSubjectId) {
      this.setTableHeight();
    }
    if (prevState.schoolSubjectsList !== this.state.schoolSubjectsList) {
      this.setTableHeight();
    }
    if (prevState.selectedSubjectCoursesGradesDetails !== this.state.selectedSubjectCoursesGradesDetails) {
      this.setTableHeight();
    }
  }
  handleCloseView=()=>{
    this.setState({
      isViewOpen:false
    })
  }
  handleOpenView=()=>{
    this.setState({isViewOpen:true})
  }
  handleResize = () => {
    this.setTableHeight();
  };

  setTableHeight = () => {
    if (this.tableRef.current) {
      let height = this.tableRef.current.clientHeight -10;
      this.setState({ tableHeight: height });
    }
    if (this.rowRef.current) {
      let width = this.rowRef.current.offsetWidth - 415;
      this.setState({ rowWidth: width });
    }
  };
  handleSetSelectedSchoolSubject = (subjectId: any) => {
    const { selectedSubjectId } = this.state;
  
    if (subjectId === selectedSubjectId) {
      this.setState({
        selectedSubjectId: "",
        selectedSubjectCoursesGradesDetails: [],
      
      });
    } 
    else {
     
      this.setState({
        selectedSubjectId: subjectId,
        selectedSubjectCoursesGradesDetails: [], 
      }, async () => {
        const userId = await getStorageData("user_id")
        await this.handleGetGradebookStudentsAssignmentDetails(userId, subjectId, this.state.selectedSchoolYearValue);
  
      
      });
    }
  };

  handleSetSchoolYearDDItems = () => {
    const { schoolYearsList } = this.state;
    const schoolYearDDMenuItems: Array<IDropdownItems> = schoolYearsList?.map(
      (item: any) => {
        return {
          id: item?.id,
          value: item?.year,
          label: item?.year,
        };
      }
    );
    this.setState({ schoolYearDDMenuItems });
  };

  renderSchoolYearDDValue = (selected: any) => {
   
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { schoolYearsList } = this.state;
    const selectedValue = schoolYearsList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };


  onDropdownValueChange = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
   
    if (fieldValue) {
      this.setState(
        (prev) => {
          return {
            ...prev,
            [name]: fieldValue,
          };
        },
        () => {
          if (
            name === "selectedSchoolYearValue"
          ) {
          
            this.setState({ selectedStudentValue: "" });
            this.handleSetSemesterColumns();
          }
        }
      );
    }
  };
  renderSchoolYearDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { teacherSchoolYearsList } = this.state;
    const selectedValue = teacherSchoolYearsList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };
  handleSearchMyGradebook = () => {
    console.log("search gradebook", this.state.selectedSchoolYearDDValue);
  };

  handleChangeDDSelect = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      },()=>{
        if (
          name === "selectedSchoolYearDDValue" 
        ) {
          this.setState({ selectedSchoolYearDDValue: "" });
          this.handleSetSemesterColumns();
        }
      });
    }
  };
  handleSetSemesterColumns = () => {
    const { selectedSchoolYearValue, entireSchoolYearDataList } = this.state;
    const selectedSchoolYearSemesterList =
      entireSchoolYearDataList.find(
        (item) => item?.id === selectedSchoolYearValue
      )?.semesters || [];
    const semesterList: Array<any> = selectedSchoolYearSemesterList?.map(
      (semesterItem) => {
        return {
          columnId: `${semesterItem?.id}`,
          id: semesterItem?.title,
          label: semesterItem?.title ,
          type: tableColumnTypes?.GRADEBOOK_AVG,
          isSelected: false,
          isSortingColumn: true,
          sortByOrder: "",
          
        };
      }
    );
    const semesterListForAttendance= selectedSchoolYearSemesterList?.map(

      (semester) => {
        return {
          columnId: semester.id, 
          id: semester.title,
          label: semester.title,
          type: tableColumnTypes.GRADEBOOK_AVG,
          value:""
        };
      }
    );
    let columns = [...studentListTableColumns];
    columns.splice(1, 0, ...semesterList);
    const columnDetails:any = [...subjectCoursesDetailsTableColumns]
    columnDetails.splice(2, 0, ...semesterList);
    const findObj = this.state.teacherSchoolYearsList.find((objdata)=>objdata.value === this.state.selectedSchoolYearValue) 
    const schoolYear = findObj?.label || "";
    this.setState(
      {
        quarters:semesterListForAttendance,
        gradebookTableSemestersColumn: semesterList,
        studentListTableColumns: [...columns],
        schoolYear:schoolYear,
        subjectCoursesDetailsTableColumns:[...columnDetails],
      },
      async() => {
        const studentId = await getStorageData("user_id");
        this.handleGetGradebookStudentsDetails(studentId,this.state.selectedSchoolYearValue);
        this.handleGetRejectGradesCommentsApi(studentId,this.state.selectedSchoolYearValue);
        this.handleGetReportCardApi(studentId,this.state.selectedSchoolYearValue)
      }
    );
  };
  handleGetTeacherSchoolYearList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSchoolYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherSchoolYearDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        title: string;
        semesters: Array<{
          id: number;
          title: string;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const { studentListTableColumns } = this.state;
      const semesterList = responseJson?.data?.[0]?.attributes?.semesters?.map(
        (semester) => {
          return {
            columnId: semester?.id,
            id: semester?.title,
            label: semester?.title,
            type: tableColumnTypes?.GRADEBOOK_AVG,
            icon: sort,
            isSelected: false,
            isSortingColumn: true,
            sortByOrder: "",
          };
        }
      );
      const semesterListForAttendance =  responseJson?.data?.[0]?.attributes?.semesters?.map(
        (semester) => {
          return {
            columnId: semester.id, 
            id: semester.title,
            label: semester.title,
            type: tableColumnTypes.GRADEBOOK_AVG,
            value:""
          };
        }
      );
      const columns = [...studentListTableColumns];
      columns.splice(1, 0, ...semesterList);
      const columnDetails:any = [...subjectCoursesDetailsTableColumns]
      columnDetails.splice(2, 0, ...semesterList);
      const schoolYearList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          value: item?.id,
          label: item?.attributes?.title,
        };
      });
      const allSchoolYearList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          semesters: item?.attributes?.semesters,
        };
      });
    
   
      this.setState(
        {
          quarters:semesterListForAttendance,
          teacherSchoolYearsList: schoolYearList,
          subjectCoursesDetailsTableColumns:[...columnDetails],
          selectedSchoolYearValue: schoolYearList?.[0]?.id || "",
          schoolYear:schoolYearList?.[0]?.label || "",
          studentListTableColumns: [...columns],
          selectedStudentValue: "",
          entireSchoolYearDataList: allSchoolYearList,
          gradebookTableSemestersColumn: semesterList,
        },
          async() => {
            const studentId = await getStorageData("user_id");
            this.handleGetGradebookStudentsDetails(studentId,this.state.selectedSchoolYearValue);
            this.handleGetRejectGradesCommentsApi(studentId,this.state.selectedSchoolYearValue)
            this.handleGetReportCardApi(studentId,this.state.selectedSchoolYearValue)
          }
      );
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };
  handleGetGradebookStudentsDetails = async (student_id:any,school_id:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradebookStudentDetailsEndpoint}?student_id=${student_id}&school_year_id=${school_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradeBookDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const tablebodydata = this.transformData(responseJson)
      let totalAvgOfAllCoursesData = responseJson.data.attributes.courses_averages.find(
        (course: any) => course.total_avgs_of_all_courses
      )?.total_avgs_of_all_courses || 0;
      totalAvgOfAllCoursesData = parseFloat(totalAvgOfAllCoursesData.toFixed(2));

      let currenTermAvgData= responseJson.data.attributes.courses_averages.find(
        (course: any) => course.current_semester_avg
      )?.current_semester_avg || 0;
      currenTermAvgData = parseFloat(currenTermAvgData.toFixed(2));


     this.setState({schoolSubjectsList:tablebodydata,totalAvgOfAllCourses:totalAvgOfAllCoursesData,currentTermAvg:currenTermAvgData})
        
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };
  transformData = (apiData:any) => {
    const courses = apiData.data.attributes.courses_averages.filter((course:any) => !course.total_avgs_of_all_courses && course.avgs_of_courses);
  
    return courses.map((course:any, index:any) => {
      const mapSemesterScoresToQuarters = (avgs:any) => {
        const quarterScores:any = [];
        if (Array.isArray(avgs)) {
          avgs.forEach((avg, i) => {
            quarterScores.push({
              quarter: `quarterScore${i + 1}`,
              score: avg.semester_avg || 0,
              status:avg.status
            });
          });
        }
  
        return quarterScores;
      };
  
      
      const quarters = mapSemesterScoresToQuarters(course.avgs_of_courses || []);
      const quarterScores = quarters.reduce((acc:any, curr:any) => {
        acc[curr.quarter] = curr.score;
        acc[`${curr.quarter}Status`] = curr.status;
        return acc;
      }, {});
  
      return {
        id: index + 1,
        subject: {
          name: course.course_title,
          bgColor:course.course_image.color,
          icon:course.course_image.icon 
        },
        subjectId: course.course_id,
        ...quarterScores,
        progress:course.course_progress?.completed === 100 ? "100" : `${(Math.round((course.course_progress?.completed || 0) * 100) / 100).toFixed(2)}`,
        totalScore: course.total_avg_of_course,
        grade:course.grade === "No grading_system for this value" ? "NA" : course.grade,
        coursesDetails: {
          assignment: {
            firstQuarterScore: 0,
            secondQuarterScore: 0,
            thirdQuarterScore: 0,
            fourthQuarterScore: 0,
            totalScore: 0,
            weight: 15,
          },
        },
      };
    });
  };
  handleGetGradebookStudentsAssignmentDetails = async (student_id:any,course_id:any,school_year_id:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGradebookAssignmentDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradebookStudentAssignmentDetailsEndpoint}?student_id=${student_id}&course_id=${course_id}&school_year_id=${school_year_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  transformDataForAssignment = (responseJson:any) => {
  const data = responseJson.data;
  const selectedSubjectCoursesGradesDetails:any = {};

 
  const mapSemesterScoresToQuarters = (gradingData:any) => {
    const quarterScores:any = {};

    gradingData.forEach((grade:any, index:any) => {
      quarterScores[`quarter${index + 1}Score`] = (Number.isInteger(grade.semester_avg) ? grade.semester_avg : parseFloat(grade.semester_avg.toFixed(2)) ) || 0;
    });

    return quarterScores;
  };


  data.forEach((item:any) => {
    const gradingComponent = item.attributes.grading_component;
    const gradingComponentAverages = item.attributes.grading_component_averages;

   
    if (gradingComponentAverages && gradingComponentAverages.grading && Array.isArray(gradingComponentAverages.grading)) {
     
      gradingComponentAverages.grading.forEach((average:any,index:any) => {
        if (!selectedSubjectCoursesGradesDetails[gradingComponent]) {
          selectedSubjectCoursesGradesDetails[gradingComponent] = {
            id: average.id,
            title: gradingComponent,
            totalScore: Number.isInteger(gradingComponentAverages.total_grading_average) 
            ? gradingComponentAverages.total_grading_average 
            : parseFloat(gradingComponentAverages.total_grading_average.toFixed(2)),
            weight: `${item.attributes.weightage}%`,
            ...mapSemesterScoresToQuarters(gradingComponentAverages.grading), 
          };
        } 
      });
    } else {
      console.error('grading_component_averages.grading is not an array:', gradingComponentAverages);
    }
  });

  
  const transformedDataArray = Object.values(selectedSubjectCoursesGradesDetails);

  return transformedDataArray;
  };
  handleGetGradeBookAssignmentDetailsDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const selectedSubjectCoursesGradesDetails = this.transformDataForAssignment(responseJson);
      this.setState({
          selectedSubjectCoursesGradesDetails: selectedSubjectCoursesGradesDetails,
        });
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };


  handleGetRejectGradesCommentsApi = async (student_id:any,school_year_id:any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRejectGradeCommentsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rejectCommentsEndpoint}?student_id=${student_id}&school_year_id=${school_year_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetRejectGradesCommentsResponse = (responseJson: any) => {
    if (responseJson?.data) {

      const formattedData = responseJson?.data?.attributes?.return_grade_reasons?.map((reason: { course: { course_title: any; }; rejection_reason: any;id:any }) => {
        return {
          id:reason.id,
          subjectName: reason.course.course_title, 
          rejectionReason: reason.rejection_reason  
        };
      }) || [];
      this.setState({rejectedComments:formattedData}) 
    } 
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };
  getRejectedQuartersAndMessage = (subjectItem: any) => {
    const rejectedQuarters = Object.keys(subjectItem)
      .filter(key => key.startsWith('quarterScore') && !key.endsWith('Status'))
      .filter(quarterKey => {
        const statusKey = `${quarterKey}Status`;
        return subjectItem[statusKey] === 'rejected';
      })
      .map(quarterKey => this.state.quarters[parseInt(quarterKey.replace('quarterScore', ''))-1].label || quarterKey.replace('quarterScore', '')); 
  
    const rejectionMessage = rejectedQuarters.length > 0
      ? `${rejectedQuarters.join(', ')} submission for ${subjectItem.subject.name} has been rejected`
      : null;
  
    return rejectionMessage;
  };
  handleGetReportCardApi = async (student_id:any,school_year_id:any) => {
    const requestUrl = `${configJSON.getTemplateDataEndpoint}?student_id=${student_id}&school_year_id=${school_year_id}&template_type=official_template`;
    const token = await getStorageData("token");
    const header = {
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    this.apiGetReportCardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleStudentData = (responseJson: any,student_profile_image:any) => {
    if (!responseJson) return {};
  
    let base64Url = "";
    if (student_profile_image.url) {
      const iconImage = student_profile_image.url.trim();
      base64Url = `data:${student_profile_image.content_type};base64,${iconImage}`;
    }
    let dateOfBirth = moment(responseJson?.data.attributes.DOB).format('DD/MM/YYYY') || 'NA'
    const customFields = responseJson?.data.attributes.user_custom_fields?.data.map((field: { attributes: { label: any; field_value: any; }; }) => ({
      label: field.attributes.label,
      value: field.attributes.field_value || 'NA'
    })) || [];
    return {
      id: responseJson?.data.id,
      email: responseJson?.data.attributes.email || "NA",
      full_name: responseJson?.data.attributes.first_name + " " + responseJson?.data.attributes.last_name,
      id_number: responseJson?.data.attributes.id_number || 'NA',
      curriculum: responseJson?.data.attributes.curriculum?.name || 'NA',
      dob: dateOfBirth,
      grade: responseJson?.data.attributes.grade || [],
      role: responseJson?.data.attributes.role?.name || 'NA',
      schoolYearTitle: responseJson?.data.attributes.school_year?.title || 'NA',
      status: responseJson?.data.attributes.status || 'NA',
      expiringOn: responseJson?.data.attributes.expiring_on || 'NA',
      profile_pic: base64Url,
      customFields: customFields,
    };
  }
  
  handleBehaviorData = (behaviours: any[], dynamicBehaviourColumns: any[]) => {
    if (!behaviours) return [];
  
    const result: any[][] = []; 
    const skillTitles: string[] = [];
  
    behaviours.forEach((item) => {
      const skill = item.skill;
      const skillBehaviours = item.behaviours;
  
      skillTitles.push(skill)
      const skillDataArray = skillBehaviours.map((behaviour: { subskill_title: string; student_behaviours: any; }) => {
        const subskillTitle = behaviour.subskill_title.trim(); 
        const studentBehaviours = behaviour.student_behaviours; 
  
        return {
          title: subskillTitle,
          ...dynamicBehaviourColumns.reduce((acc: { [key: string]: any }, quarter: { id: string }, colIndex: number) => {
            const semesterType = quarter.id; 
            const studentBehaviour = studentBehaviours.find((b: any) => b.semester_type === semesterType);
            acc[semesterType] = studentBehaviour ? studentBehaviour.total_subskill_data : ""; 
            return acc;
          }, {})
        };
      });
  
      result.push(skillDataArray);
    });
    return [result,skillTitles]; 
  };
  handleAttendanceData = (attendance: any, dynamicColumns: any[]) => {
    if (!attendance) return [];
  
    const attendanceValues = JSON.parse(attendance.quarterly_attendance);
    return [{
      id: 1,
      attendance: "No of School Days",
      ...dynamicColumns.reduce((acc, quarter, index) => {
        const dynamicKey = quarter.id;
        acc[dynamicKey] = attendanceValues[index] || 0;
        return acc;
      }, {}),
      total: attendance.total
    }];
  }
  handleGradebookData = (gradesData: any, dynamicColumns: any[]) => {
    if (!gradesData || !gradesData.data) return [];
  
    const gradeBookData = gradesData.data;
    const totalGrade = gradesData.total_grade_point_avg;
    const totalGradeAvg = gradesData.total_avg_grade;
    const gpaTotal = totalGrade.reduce((sum: number, item: { avg: number }) => sum + item.avg, 0) / totalGrade.length;
    const calculateGPA = (avgsOfCourses: number[]): number => {
      if (avgsOfCourses.length === 0) return 0;
      const sum = avgsOfCourses.reduce((total, value) => total + value, 0);
      return sum / avgsOfCourses.length;
    };
    const gradebookTableData = gradeBookData.map((courseData: { avgs_of_courses: any; course: { id: any; title: any; subject: { icon: any; color: any; content_type: string }; }; grade: any; }, _courseIndex: any) => {
      const grades = courseData.avgs_of_courses;
      let base64Url = "";
      if (courseData.course.subject.icon) {
        const iconImage = courseData.course.subject.icon.trim();
        base64Url = `data:${courseData.course.subject.content_type};base64,${iconImage}`;
      }
      return {
        id: courseData.course.id,
        Name: {
          name: courseData.course.title,
          icon: base64Url,
          bgColor: courseData.course.subject.color
        },
        ...dynamicColumns.reduce((acc, quarter, index) => {
          const dynamicKey = quarter.id;
          acc[dynamicKey] = parseFloat((grades[index]).toFixed(2)) || 0;
          return acc;
        }, {}),
        GPA: parseFloat((calculateGPA(grades)).toFixed(2)),
        Grade: courseData.grade
      };
    });
  
    const totalGradePointAvg = {
      id: gradeBookData.length + 1,
      Name: {
        name: "Total Grade Point Average",
      },
      ...dynamicColumns.reduce((acc, quarter, index) => {
        const dynamicKey = quarter.id;
        acc[dynamicKey] = parseFloat((totalGrade[index]?.avg).toFixed(0)) || 0;
        return acc;
      }, {}),
      GPA: parseFloat(gpaTotal.toFixed(2)),
      Grade: totalGradeAvg
    };
    gradebookTableData.push(totalGradePointAvg);
  
    return gradebookTableData;
  }
  getHandleDynamicColumns = (gradesData: any) => {
    if (!gradesData || gradesData.total_grade_point_avg.length === 0) return [];
  
    return gradesData.total_grade_point_avg.map((quarter: { sem_title: string; }, index: number) => ({
      type: tableColumnTypes.NUMBER,
      columnId: index + 2, 
      id: quarter.sem_title, 
      label: quarter.sem_title,
      width: "100%",
      maxWidth: "100%",
    }));
  }
  getHandleDynamicBehaviourColumns = (gradesData: any) => {
    if (!gradesData || gradesData.total_grade_point_avg.length === 0) return [];
  
    return gradesData.total_grade_point_avg.map((quarter: { sem_title: string; }, index: number) => ({
      type: tableColumnTypes.TEXT,
      columnId: index + 2, 
      id: quarter.sem_title, 
      label: quarter.sem_title,
      width: "100%",
      maxWidth: "100%",
    }));
  }
  createSkillsHandleTableColumns = (baseColumns: any[], skillsArray: any[]) => {
   
    if (skillsArray.length === 0) {
      return baseColumns;
    }
  
    return skillsArray.map((skill: any) => {
      return baseColumns.map((column: { id: string; }) => {
          if (column.id === "title") {
              return {
                  ...column,
                  label: skill 
              };
          }
          return column; 
      });
  });
  };
  handleGetReportCardResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const selectedTemplateData = responseJson.data.template;
   
      if (selectedTemplateData?.data.length > 0 && responseJson.data?.grades_data?.total_grade_point_avg.length >0) {
          const orientationType = selectedTemplateData.data[0].attributes?.orientation_type;
          const getData = selectedTemplateData.data[0]?.attributes.template_content_types;
          const resData = {
            templateType:orientationType,
            getDataOfTemplate:getData
          }
        
          const studentData = this.handleStudentData(responseJson.data.student,responseJson.data.student_profile_image);
          let AttandanceTableHeadData = [...AttandanceTableHead]
          let gradebookTableHeadData =[...gradebookTableHead]
          let BehaviourTableHeadData = [...BehaviorsTableHead]
          const dynamicColumns = this.getHandleDynamicColumns(responseJson.data.grades_data);
          const dynamicBehaviourColumns = this.getHandleDynamicBehaviourColumns(responseJson.data.grades_data);
          AttandanceTableHeadData.splice(1, 4, ...dynamicColumns);
          gradebookTableHeadData.splice(1,4,...dynamicColumns)
          BehaviourTableHeadData.splice(1,4,...dynamicBehaviourColumns)
  
          const className = responseJson.data.class_name || "";
          let schoolYear = this.renderSchoolYearDropDownValue(this.state.selectedSchoolYearValue)
          const AttandanceTableData = this.handleAttendanceData(responseJson.data.attendance, dynamicColumns);
          const gradebookTableData = this.handleGradebookData(responseJson.data.grades_data, dynamicColumns);
          const behaviorTableArray = this.handleBehaviorData(responseJson.data.behaviours, dynamicBehaviourColumns);
          const behaviorTableData =behaviorTableArray[0];
          const behaviorTableSkillsData = behaviorTableArray[1]
          const behaviourDynamicHeader = this.createSkillsHandleTableColumns(BehaviourTableHeadData,behaviorTableSkillsData)
          const behaviorTableDataLength =behaviorTableData.length
          this.setState({
            gradebookListTableHead:gradebookTableHeadData,
            AttandanceTableData:AttandanceTableData,
            AttandanceTableHead:AttandanceTableHeadData,
            gradebookTableData:gradebookTableData, 
            behaviourTableHead:behaviourDynamicHeader,
            behaviourTableData:behaviorTableData,
            schoolYearData:schoolYear,
            selectedTemplateData:resData,
            behaviorTableDataLength:behaviorTableDataLength,
            studentData:studentData,
            isDisabled:false,
            className:className
          })
      } else {
          this.setState({isDisabled:true});
      }
    }
    else if(responseJson.errors==="grade book template not found" ||responseJson.errors=== "class not found for given school year id") {
      this.setState({
        isDisabled:true
      })
    }
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader()
  };
  // Customizable Area End
}
// Customizable Area End
