// Customizable Area Start
import React from "react";
import {  Radio } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomRadioButtonController, { Props } from "./CustomRadioButtonController.web";
import RadioBtnIcon from "./SVGIcons/RadioBtnIcon.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        buttonCss: {
            textTransform: "capitalize"
        }
    })

// Customizable Area End

export class CustomRadioButton extends CustomRadioButtonController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { fieldName , checked , isCorrect , isDisabled , isDueQuiz } = this.props
        return (
            <Radio
            checked={checked}
            onChange={this.props.handleChange}
            name={fieldName}
            disabled={isDisabled}
            icon={<RadioBtnIcon width="36" height="36" viewBox="0 0 36 36" stroke={"#D8D8D8"} fill={"#D8D8D8"} />}
            checkedIcon={isDueQuiz ? <RadioBtnIcon width="36" height="36" viewBox="0 0 36 36" stroke={"#01C996"} fill={"#01C996"} /> : <RadioBtnIcon width="36" height="36" viewBox="0 0 36 36" stroke={isCorrect ?"#01C996" : "#CF0000"} fill={isCorrect ?"#01C996" : "#CF0000"} />}
            inputProps={{ 'aria-label': fieldName }}
          />
        );
        // Customizable Area End
    }

}


// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomRadioButton);
// Customizable Area End