import { FIELD_TYPES } from "../../../components/src/CommonType.web";

const subjectsListMock = [
  { id: 2, label: "Mathematics", value: 2 },
  { id: 1, label: "Physics", value: 1 },
  { id: 3, label: "History", value: 3 },
];

const schoolYearListMock = [
  { id: 1, label: "2022-2023", value: 1 },
  { id: 2, label: "2021-2022", value: 2 },
];

const customField1ListMock = [
  { id: 1, label: "Tag 1", value: 1 },
  { id: 2, label: "Tag 2", value: 2 },
];

const customField2ListMock = [
  { id: 1, label: "Tag 3", value: 1 },
  { id: 2, label: "Tag 4", value: 2 },
];

const courseUtilizationListMock = [
  { id: 1, label: "Option 1", value: 1 },
  { id: 2, label: "Option 2", value: 2 },
];

const teacherNameListMock = [
  { id: 1, label: "Anthony Teacher", value: 1 },
  { id: 2, label: "Sam Teacher", value: 2 },
];

const gradeLevelListMock = [
  { id: 1, label: "Grade Level 1", value: 1 },
  { id: 2, label: "Grade Level 2", value: 2 },
];

const curriculumListMock = [
  { id: 1, label: "Curriculum 1", value: 1 },
  { id: 2, label: "Curriculum 2", value: 2 },
];

const titleListMock = [
  { id: 1, label: "Title 1", value: 1 },
  { id: 2, label: "Title 2", value: 2 },
];

const countListMock = [
  { id: 1, label: "1", value: 1 },
  { id: 2, label: "2", value: 2 },
  { id: 3, label: "3", value: 3 },
  { id: 4, label: "4", value: 4 },
  { id: 5, label: "5", value: 5 },
  { id: 6, label: "6", value: 6 },
];

const statusListMock1 = [
  { id: "new", label: "New", value: "new" },
  { id: "modified", label: "Modified", value: "modified" },
];

const acsDescMock = [
  { id: "ascending", label: "Ascending", value: "ascending" },
  { id: "descending", label: "Descending", value: "descending" },
];

const relationshipMock = [
  { id: "father", label: "Father", value: "father" },
  { id: "mother", label: "Mother", value: "mother" },
];

const registrarInputConfigMock = [
  {
    id: "registrar-first-name",
    fieldName: "firstName",
    label: "First Name",
    fieldType: FIELD_TYPES.TEXT,
    name: "firstName",
    value: "",
    placeHolderText: "Enter First Name",
  },
  {
    fieldName: "lastName",
    id: "registrar-last-name",
    fieldType: FIELD_TYPES.TEXT,
    label: "Last Name",
    name: "lastName",
    value: "",
    placeHolderText: "Enter Last Name",
  },
  {
    fieldName: "emailId",
    id: "registrar-emailId",
    fieldType: FIELD_TYPES.TEXT,
    label: "Email ID",
    name: "emailId",
    value: "",
    placeHolderText: "Enter Email ID",
  },
  {
    fieldName: "profileImage",
    id: "registrar-profile-mage",
    fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
    label: "Profile Image",
    name: "profileImage",
    value: "",
    placeHolderText: "Select File",
  },
  {
    fieldName: "curriculumDDMenuItems",
    id: "registrar-curriculum",
    fieldType: FIELD_TYPES.SELECT,
    label: "Curriculum",
    name: "curriculum",
    value: "",
    menuItems: curriculumListMock,
    placeHolderText: "Select Curriculum",
  },
  {
    fieldName: "role",
    id: "registrar-role",
    fieldType: FIELD_TYPES.TEXT,
    label: "Role",
    name: "role",
    value: "Registrar",
    isDisabled: true,
    placeHolderText: "Role",
  },
  {
    fieldName: "userId",
    id: "registrar-userId",
    fieldType: FIELD_TYPES.TEXT,
    label: "ID",
    name: "userId",
    value: "",
    placeHolderText: "Enter Value",
  },
];

const courseManagerInputConfigMock = [
  {
    id: "course_manager-first-name",
    fieldType: FIELD_TYPES.TEXT,
    fieldName: "firstName",
    label: "First Name",
    name: "firstName",
    value: "",
    placeHolderText: "Enter First Name",
  },
  {
    fieldName: "lastName",
    id: "course_manager-last-name",
    fieldType: FIELD_TYPES.TEXT,
    label: "Last Name",
    value: "",
    name: "lastName",
    placeHolderText: "Enter Last Name",
  },
  {
    id: "course_manager-emailId",
    fieldName: "emailId",
    fieldType: FIELD_TYPES.TEXT,
    label: "Email ID",
    name: "emailId",
    value: "",
    placeHolderText: "Enter Email ID",
  },
  {
    fieldName: "profileImage",
    id: "course_manager-profile-mage",
    fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
    label: "Profile Image",
    value: "",
    name: "profileImage",
    placeHolderText: "Select File",
  },
  {
    fieldName: "role",
    id: "course_manager-role",
    label: "Role",
    fieldType: FIELD_TYPES.TEXT,
    name: "role",
    value: "Course Manager",
    isDisabled: true,
    placeHolderText: "Role",
  },
  {
    fieldName: "baseRole",
    id: "course_manager-role",
    fieldType: FIELD_TYPES.TEXT,
    label: "Base Role",
    name: "baseRole",
    value: "Tenant Admin",
    isDisabled: true,
    placeHolderText: "Base Role",
  },
  {
    fieldName: "userId",
    id: "course_manager-userId",
    label: "ID",
    fieldType: FIELD_TYPES.TEXT,
    name: "userId",
    value: "",
    placeHolderText: "Enter Value",
  },
];

const teacherInputConfigMock = [
  {
    id: "teacher-first-name",
    fieldName: "firstName",
    label: "First Name",
    name: "firstName",
    value: "",
    placeHolderText: "Enter First Name",
    fieldType: FIELD_TYPES.TEXT,
  },
  {
    fieldName: "lastName",
    id: "teacher-last-name",
    fieldType: FIELD_TYPES.TEXT,
    label: "Last Name",
    value: "",
    name: "lastName",
    placeHolderText: "Enter Last Name",
  },
  {
    label: "Email ID",
    id: "teacher-emailId",
    fieldName: "emailId",
    fieldType: FIELD_TYPES.TEXT,
    name: "emailId",
    value: "",
    placeHolderText: "Enter Email ID",
  },
  {
    fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
    fieldName: "profileImage",
    fileType: "image",
    id: "teacher-profile-mage",
    label: "Profile Image",
    value: {
      profile: '',
      fileName: '',
      contentType: ''
    },
    name: "profileImage",
    placeHolderText: "Select File",
  },
  {
    fieldName: "gradeLevelDDMenuItems",
    id: "registrar-gradeLevel",
    fieldType: FIELD_TYPES.SELECT,
    label: "Grade Level",
    name: "gradeLevel",
    value: "",
    placeHolderText: "Select Grade Level",
    menuItems: [],
  },
  {
    fieldName: "subjectHandledDDMenuItems",
    id: "registrar-subjectHandled",
    fieldType: FIELD_TYPES.SELECT,
    name: "subjectHandled",
    label: "Subject Handled",
    placeHolderText: "Select Subject Handled",
    value: "",
    menuItems: [],
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "registrar-curriculum",
    label: "Curriculum",
    name: "curriculum",
    value: "",
    menuItems: [],
    placeHolderText: "Select Curriculum",
    fieldName: "curriculumDDMenuItems",
  },
  {
    fieldName: "role",
    id: "teacher-role",
    label: "Role",
    fieldType: FIELD_TYPES.TEXT,
    name: "role",
    value: "Teacher",
    isDisabled: true,
    placeHolderText: "Role",
  },
  {
    fieldName: "userId",
    fieldType: FIELD_TYPES.TEXT,
    id: "teacher-userId",
    name: "userId",
    value: "",
    label: "ID",
    placeHolderText: "Enter Value",
  },
];

const parentTeacherInputConfigMock = [
  {
    customLabel: "test",
    id: "parent-teacher-first-name",
    fieldType: FIELD_TYPES.TEXT,
    fieldName: "firstName",
    label: "First Name",
    name: "firstName",
    customLabel2: "test",
    value: "",
    placeHolderText: "Enter First Name",
  },
  {
    customLabel2: "test",
    fieldName: "lastName",
    id: "parent-teacher-last-name",
    fieldType: FIELD_TYPES.TEXT,
    label: "Last Name",
    value: "",
    name: "lastName",
    placeHolderText: "Enter Last Name",
  },
  {
    id: "parent-teacher-emailId",
    customLabel2: "test",
    fieldName: "emailId",
    fieldType: FIELD_TYPES.TEXT,
    label: "Email ID",
    name: "emailId",
    value: "",
    placeHolderText: "Enter Email ID",
  },
  {
    fieldName: "relationshipDDMenuItems",
    fieldType: FIELD_TYPES.SELECT,
    customLabel2: "test",
    id: "parent-teacher-relationship",
    label: "Relationship",
    name: "relationship",
    value: "",
    menuItems: relationshipMock,
    placeHolderText: "Select Relationship",
  },
  {
    fieldName: "profileImage",
    id: "parent-teacher-profile-image",
    fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
    customLabel2: "test",
    label: "Profile Image",
    fileType: "image",
    value: "",
    name: "profileImage",
    placeHolderText: "Select File",
  },
  {
    fieldName: "role",
    id: "parent-teacher-role",
    label: "Role",
    customLabel2: "test",
    fieldType: FIELD_TYPES.TEXT,
    name: "role",
    value: "Parent Teacher",
    isDisabled: true,
    placeHolderText: "Role",
  },
    {
    fieldName: "childrenCount",
    id: "parent-teacher-childrenCount",
    label: "Children",
    fieldType: FIELD_TYPES.TEXT,
    name: "childrenCount",
    value: "",
    customLabel2: "test",
    placeHolderText: "No. of Child",
  },
  {
    fieldName: "contactNumber",
    id: "parent-teacher-contactNumber",
    label: "Contact Number",
    fieldType: FIELD_TYPES.TEXT,
    customLabel2: "test",
    name: "contactNumber",
    value: "",
    placeHolderText: "Enter Contact Number",
  },
];

const parentInputConfigMock = [
  {
    fieldType: FIELD_TYPES.TEXT,
    fieldName: "firstName",
    id: "parent-first-name",
    customLabel: "test",
    label: "First Name",
    customLabel2: "test",
    name: "firstName",
    value: "",
    placeHolderText: "Enter First Name",
  },
  {
    fieldName: "lastName",
    customLabel: "test",
    label: "Last Name",
    id: "parent-last-name",
    fieldType: FIELD_TYPES.TEXT,
    name: "lastName",
    placeHolderText: "Enter Last Name",
    value: "",
  },
  {
    customLabel: "test",
    fieldName: "emailId",
    fieldType: FIELD_TYPES.TEXT,
    name: "emailId",
    label: "Email ID",
    value: "",
    placeHolderText: "Enter Email ID",
    id: "parent-emailId",
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    label: "Relationship",
    customLabel: "test",
    fieldName: "relationshipDDMenuItems",
    name: "relationship",
    value: "",
    id: "parent-relationship",
    menuItems: relationshipMock,
    placeHolderText: "Select Relationship",
  },
  {
    customLabel: "test",
    label: "Contact Number",
    fieldType: FIELD_TYPES.TEXT,
    name: "contactNumber",
    fieldName: "contactNumber",
    id: "parent-contactNumber",
    value: "",
    placeHolderText: "Enter Contact Number",
  },
  {
    fieldType: FIELD_TYPES.FILE_UPLOAD_TEXT,
    label: "Profile Image",
    fieldName: "profileImage",
    value: "",
    fileType: 'image',
    customLabel: "test",
    name: "profileImage",
    id: "parent-profile-image",
    placeHolderText: "Select File",
  },
  {
    name: "role",
    fieldName: "role",
    value: "Parent",
    customLabel: "test",
    fieldType: FIELD_TYPES.TEXT,
    label: "Role",
    isDisabled: true,
    id: "parent-role",
    placeHolderText: "Role",
  },
];

export {
  acsDescMock,
  countListMock,
  courseUtilizationListMock,
  curriculumListMock,
  customField1ListMock,
  customField2ListMock,
  gradeLevelListMock,
  schoolYearListMock,
  statusListMock1,
  subjectsListMock,
  teacherNameListMock,
  titleListMock,
  relationshipMock,
  registrarInputConfigMock,
  courseManagerInputConfigMock,
  teacherInputConfigMock,
  parentTeacherInputConfigMock,
  parentInputConfigMock,
};
