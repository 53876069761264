 // Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { IDropdownMenuItemProps } from "./CommonType.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  fieldMenuItems: Array<IDropdownMenuItemProps>,
  fieldStyles?: any;
  label: string;
  selectedItems: Array<any>;
  handleChange: (event: any) => void;
  helperText?: string;
  fieldError?: boolean;
  isDisabled?: boolean;
  disabledItemSelection?: boolean;
  handleClose?: (event: any) => void;
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  loading: boolean;
  openMenu:boolean
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomMultiSelectDropDownController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End
    constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

    ]

    this.state = {
      loading: false,
      openMenu:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
   
    // Customizable Area End
  }

 // Customizable Area Start

  handleToggleMenu = (e : any) => {
    this.setState({ openMenu : !this.state.openMenu })
  }

  handleChangeMenu= (e : any) => {
    this.props.handleChange(e)
    this.setState({ openMenu : false });
  }

 // Customizable Area End
}