import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomSelectDropdown from "./CustomSelectDropdown.web";
import DropdownIcon from "./SVGIcons/DropdownIcon.web";
import CustomButton from "./CustomButton.web";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import CustomCSVUploadPopupController, {
  Props,
} from "./CustomCSVUploadPopupController.web";

const styles = (theme: Theme) =>
  createStyles({
    dropzone: {
      border: `3px dashed #FFD92E`,
      borderRadius: "12px",
      padding: theme.spacing(2),
      width: 460,
      height: 260,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
      },
    },
    dragging: {
      borderColor: theme.palette.text.disabled,
    },
    dropIcon: {
      fontSize: 18,
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
    fileInput: {
      display: "none",
    },
    closeIcon: {
      cursor: "pointer",
      top: theme.spacing(2.6),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        top: theme.spacing(5),
        right: theme.spacing(4),
        width: theme.spacing(1.5),
      },
    },
    csvDialogRoot: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    fileImage: {
      marginBottom: "20px",
    },
    textAlignRight: {
      textAlign: "right",
    },
  });

const uploadBtnStyle = {
  height: "50px",
  borderRadius: "12px",
  fontWeight: 600,
  width: "130px",
  fontSize: "16px",
  padding: "10px",
} as const;

const cancelBtnStyle = {
  marginRight: "15px",
  borderRadius: "12px",
  padding: "10px",
  fontSize: "16px",
  height: "50px",
  border: "1px solid",
  width: "130px",
  fontWeight: 600,
} as const;

class CustomCSVUploadPopup extends CustomCSVUploadPopupController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes, open } = this.props;
    const { selectOption, uploadedFile, isDragging, roleOptions } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClosePopup}
        data-testid="csv-upload-dialog-popup"
        className={`${classes.csvDialogRoot} ${classes.highlightBgColor}`}
      >
        <Box className={`${classes.bgColor}`}>
          <DialogTitle id="csv-upload-dialog-title">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                component={"span"}
                className={`${classes.fontText24} ${classes.fontBold600} ${
                  classes.textPrimaryColor
                }`}
              >
                Import CSV
              </Typography>
              <CloseIcon
                id="close-modal-icon"
                className={`${classes.textCommonColor} ${classes.closeIcon}`}
                onClick={this.handleClosePopup}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box marginBottom={"20px"}>
              <Box
                marginBottom={"10px"}
                display={"flex"}
                justifyContent={"end"}
              >
                <Typography
                  component={"span"}
                  onClick={this.props.downloadCSVFile}
                  className={`${classes.fontText14} ${classes.fontBold600} ${classes.customizedColor} ${classes.cursorPointer} ${classes.textAlignRight}`}
                >
                  Download CSV Template
                </Typography>
              </Box>
            </Box>
            <Box
              className={`${classes.dropzone} ${
                isDragging ? classes.dragging : ""
              }`}
              onDragEnter={this.handleDragEnter}
              onDragLeave={this.handleDragLeave}
              onDragOver={this.handleDragOver}
              onDrop={this.handleDrop}
              onClick={this.handleOpenFileBrowse}
            >
              {isDragging ? (
                <Box className={classes.dropIcon}>
                  <img
                    className={classes.fileImage}
                    alt="csv-file-icon"
                    src={require("./csv-icon.png")}
                  />
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${
                      classes.textPrimaryColor
                    } ${classes.fontBold600}`}
                  >
                    Browse CSV file to upload{" "}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.fontText18} ${
                      classes.textCommonColor
                    }`}
                  >
                    or drag and drop it here
                  </Typography>
                </Box>
              ) : (
                <>
                  {uploadedFile ? (
                    <>
                      <Box  className={`${classes.fontText18} ${classes.textCommonColor
                        } ${classes.dropIcon}`}>
                        {uploadedFile.name}
                      </Box>
                    </>
                  ) : (
                    <Box
                      className={`${classes.dropIcon} ${classes.defaultIcon}`}
                    >
                      <img
                        className={classes.fileImage}
                        alt="csv-file-icon"
                        src={require("./csv-icon.png")}
                      />
                      <Typography
                        className={`${classes.fontText20} ${
                          classes.textPrimaryColor
                        } ${classes.fontBold600}`}
                      >
                        Browse CSV file to upload{" "}
                      </Typography>
                      <Typography
                        className={`${classes.fontText18} ${
                          classes.textCommonColor
                        }`}
                      >
                        or drag and drop it here
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              <input
                type="file"
                ref={this.fileInputRef}
                className={classes.fileInput}
                accept=".csv"
                onChange={this.handleFileInputChange}
              />
            </Box>
            <Box
              display={"flex"}
              margin={"30px 0px"}
              justifyContent={"flex-end"}
            >
              <CustomButton
                btnStyle={cancelBtnStyle}
                btnText="Cancel"
                buttonId="csv-upload-cancel-btn"
                buttonVariant={"outlined"}
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.handleClosePopup}
              />
              <CustomButton
                btnStyle={uploadBtnStyle}
                btnText="Upload"
                buttonId="csv-upload-add-btn"
                buttonVariant={"contained"}
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.handleSubmit}
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
  }
}

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CustomCSVUploadPopup);
