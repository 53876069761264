// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ViewStudentProfileDetailsController, {
  IParentDetailProps,
  Props,
} from "./ViewStudentProfileDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomBarGraph from "../../../components/src/CustomBarGraph.web";
import CustomProgressCircle from "../../../components/src/CustomProgressCircle.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    studentInfoBox: {
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    studentPageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    studentProfileInfoGrid: {
      padding: "15px 18px 15px 18px",
    },
    studentProfileInfoGridLast: {
      padding: "15px 18px 15px 18px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    userName: {
      padding: "15px 18px 5px 18px",
    },
    leftMargin: {
      marginLeft: "8px",
    },
    detailGridItem: {
      padding: "8px 0px 8px 0px",
    },
    subjectLogoBg: {
      width: "55px",
      borderRadius: "50%",
      display: "flex",
      height: "55px",
      justifyContent: "center",
      alignItems: "center",
      padding: "2px",
    },
    subjectImg: {
      objectFit: "contain",
    },
    subjectCard: {
      padding: "15px 22px",
      borderRadius: "10px",
      width: "84%",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        width: "85% !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },
    overflowText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    chartWrapper: {
      position: "relative",
      "& canvas": {
        position: "absolute",
        left: 0,
        pointerEvents: "none",
        top: 0,
        minWidth: "auto",
        height: "auto !important",
      },
    },
    chartAreaWrapper: {
      width: "100%",
      overflowX: "auto",
      position: "relative",
    },
    chartAreaSecondWrapper: {
      position: "relative",
      height: "268px",
    },
    progressPercentageMainBox: {
      display: "flex",
      padding: "0% 5%",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    progressPerformanceContainer: {
      padding: "15px 22px",
      borderRadius: "10px",
    },
    emptyProfileImg: {
      width: "inherit",
      height: "inherit",
      color: "#ccc",
    },
    profileImgBox: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
    },
    profileImg: {
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
      borderRadius: "50%",
    },
  });
// Customizable Area End

// Customizable Area Start
export class ViewStudentProfileDetails extends ViewStudentProfileDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      userFullName,
      userDetails,
      coursesList,
      performanceProgressDetail,
      progressDetails
    } = this.state;

    return (
      <Box
        id="view-student-profile-main-box"
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid
          id="view-student-profile-main-container"
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                User Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
              >
                {` / Student`}
              </Typography>
              <Typography
                component={"span"}
                id="view-student-profile-top-breadcrumbs"
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
              >
                {` / ${userFullName}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.studentPageName} item xs={12}>
            <Box>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.textCapital} ${classes.fontBold600}`}
                id="view-student-profile-main-userFullName"
                component={"span"}
              >
                {userFullName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              id="view-student-profile-infoBox"
              className={`${classes.bgColor} ${classes.studentInfoBox}`}
            >
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box
                        className={`${classes.userName}`}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box className={classes.profileImgBox}>
                          {userDetails?.profilePic ? (
                            <CustomImageComponentWeb
                              altText="Student Profile Pic"
                              src={userDetails.profilePic}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              isUserProfileImg
                            />
                          ) : (
                            <AccountCircleIcon
                              className={`${classes.emptyProfileImg}`}
                            />
                          )}
                        </Box>
                        <Typography
                          component={"span"}
                          id="view-student-profile-userFullName-header"
                          className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText24}`}
                        >
                          {userFullName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        className={`${classes.studentProfileInfoGrid}`}
                        container
                        direction="row"
                      >
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-student-profile-emailId-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box
                            className={`${classes.overflowText}`}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography
                              component={"span"}
                              id="view-student-profile-emailId"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Email ID:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18} ${classes.overflowText}`}
                            >
                              {userDetails?.emailId}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-student-profile-idNumber-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-idNumber"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              ID Number:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.rollNumber}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-student-profile-curriculum-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-curriculum"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Curriculum:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.curriculum}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-student-profile-dob-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-dob"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Date of Birth:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.dob}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                          id="view-student-profile-class-grid"
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-class"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Class:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.classDetail}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          id="view-student-profile-schoolYear-grid"
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-schoolYear"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              School Year Title:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.schoolYearTitle}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          id="view-student-profile-role-grid"
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-role"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Role:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18}`}
                            >
                              {userDetails?.role}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          id="view-student-profile-expiringOn-grid"
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-expiringOn"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Expiring On:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.expiringOn}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          id="view-student-profile-status-grid"
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-student-profile-status"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Status:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {userDetails?.status}
                            </Typography>
                          </Box>
                        </Grid>
                        {userDetails.customFields.map((item: any) => (
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-user-profile-customField1-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          key={item.id}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-user-profile-customField1"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              {item?.label}:{" "}
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                             {item?.value || "NA"}
                            </Typography>
                          </Box>
                        </Grid>
                        ))}

                      </Grid>
                    </Grid>
                    {userDetails.userParentDetails?.map(
                      (parentDetail: IParentDetailProps, index: number) => (
                        <Grid key={parentDetail.id} item xs={12}>
                          <Grid
                            className={
                             `${classes.studentProfileInfoGrid}`}
                            style={{borderTop: "2px solid #f0f0f0",}}
                            container
                            direction="row"
                          >
                            <Grid item xs={12}>
                              <Typography
                                component={"span"}
                                className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                              >
                                {`Parent ${index + 1} Details`}
                              </Typography>
                            </Grid>
                            <Grid
                              className={`${classes.detailGridItem}`}
                              item
                              id={`Parent-${parentDetail.id}`}
                              xs={12}
                              md={6}
                              sm={12}
                              lg={4}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textCommonColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  Name:
                                </Typography>
                                <Box
                                  className={`${classes.leftMargin}`}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Box className={classes.profileImgBox}>
                                    {parentDetail?.profilePic ? (
                                      <CustomImageComponentWeb
                                        altText="Parent Profile Pic"
                                        src={parentDetail.profilePic}
                                        imgStyles={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                        }}
                                        isUserProfileImg
                                      />
                                    ) : (
                                      <AccountCircleIcon
                                        className={`${classes.emptyProfileImg}`}
                                      />
                                    )}
                                  </Box>
                                  <Typography
                                    component={"span"}
                                    className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18}`}
                                  >
                                    {parentDetail.fullName}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid
                              className={`${classes.detailGridItem}`}
                              item
                              xs={12}
                              md={6}
                              sm={12}
                              lg={4}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  Relationship:
                                </Typography>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  {parentDetail?.relationship}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              className={`${classes.detailGridItem}`}
                              item
                              xs={12}
                              md={6}
                              sm={12}
                              lg={4}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  Contact Number:
                                </Typography>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  {parentDetail?.contactNumber}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              className={`${classes.detailGridItem}`}
                              item
                              xs={12}
                              md={6}
                              sm={12}
                              lg={4}
                            >
                              <Box
                                className={`${classes.overflowText}`}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <Typography
                                  component={"span"}
                                  className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  Email ID:
                                </Typography>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.overflowText} ${classes.fontText18}`}
                                >
                                  {parentDetail?.emailId}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              className={`${classes.detailGridItem}`}
                              item
                              xs={12}
                              md={6}
                              sm={12}
                              lg={4}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  Role:
                                </Typography>
                                <Typography
                                  component={"span"}
                                  className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                                >
                                  {parentDetail?.role}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {progressDetails.length >0 &&
          <Grid item xs={12}>
            <Grid container direction={"row"}>
              <Grid item xs={12}>
                <Box paddingTop={"20px"} paddingBottom={"10px"}>
                  <Typography
                    className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                  >
                    Courses
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {progressDetails?.map((subject: any) => (
                    <Grid key={subject?.id} item xs={12} sm={6} md={4}>
                      <Box
                        className={`${classes.subjectCard} ${classes.bgColor}`}
                        display={"flex"}
                        alignItems="center"
                      >
                        <Box
                          mr={"10px"}
                          bgcolor={subject?.subjectBgColor}
                          className={classes.subjectLogoBg}
                        >
                             <CustomImageComponentWeb
                              altText="Subject Logo"
                              src={subject?.icon}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                        </Box>
                        <Box>
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
                            component="span"
                          >
                            {subject.subjectName}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
          {progressDetails.length >0 &&
          <Grid item xs={12}>
            <Grid container direction={"row"}>
              <Grid item xs={12}>
                <Box paddingTop={"20px"} paddingBottom={"10px"}>
                  <Typography
                    className={`${classes.fontBold600} ${classes.fontText28} ${classes.textPrimaryColor}`}
                  >
                    Performance and Progress
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  className={`${classes.bgColor} ${classes.progressPerformanceContainer}`}
                  container
                  direction="row"
                >
                  <Grid item xs={12}>
                    <Box paddingTop={"20px"} paddingBottom={"10px"}>
                      <Typography
                        className={`${classes.fontBold600} ${classes.fontText18} ${classes.textPrimaryColor}`}
                      >
                        Performance
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      className={classes.chartWrapper}
                      maxWidth={"100%"}
                      overflow="auto"
                    >
                      <Box className={classes.chartAreaWrapper}>
                        <Box className={classes.chartAreaSecondWrapper}>
                          {performanceProgressDetail?.length > 0 && (
                            <CustomBarGraph
                              graphData={{
                                labels:
                                  this.getProgressPerformanceGraphLabels(),
                                datasets: [
                                  {
                                    data: this.getProgressPerformanceGraphData(),
                                    borderRadius: 50,
                                    barPercentage: 0.6,
                                    backgroundColor:
                                      this.getProgressPerformanceGraphBarBgColor(),
                                  },
                                ],
                              }}
                              chartLabel="Performance"
                              titleColor={this.handleGetGraphColor()}
                              gridlineColor="#f1f1f1"
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box paddingTop={"20px"} paddingBottom={"10px"}>
                      <Typography
                        className={`${classes.fontBold600} ${classes.fontText18} ${classes.textPrimaryColor}`}
                      >
                        Progress
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      className={classes.progressPercentageMainBox}
                      mt={"15px"}
                    >
                      {progressDetails?.length > 0 &&
                        progressDetails.map((data: any) => (
                          <React.Fragment key={data.id}>
                            <CustomProgressCircle
                              centerTextFontSize="12px"
                              progressDataPosition={{
                                top: "6px",
                                left: "6px",
                              }}
                              progressCircleDimensions={60}
                              progressData={{
                                hoverText: data?.subjectName || "",
                                progressPercentage:
                                  data?.progressPercentage || 0,
                              }}
                              customClasses={classes}
                            />
                          </React.Fragment>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ViewStudentProfileDetails);
// Customizable Area End
