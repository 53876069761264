// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import  GradeTable from "./tables/GradeTable.web";
import BehaviourTable  from "./tables/BehaviourTable.web";
import React from "react";
import  AttandanceTable  from "./tables/AttandanceTable.web";
import  StudentDetails  from "./tables/StudentDetails.web";

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  templateType: string;
  // getDataOfTemplate:any
  gridContent?:any


  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gridContent: React.ReactNode[]; 
  gridContentBackPage:React.ReactNode[]; 
  isFrontPage:boolean;
 
  screenName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class GradebookTemplateDownloadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    const typeMode = this.props.templateType === "portrait" ? 5 :6

    this.state = {
      gridContent: Array(typeMode).fill({ content: '+Edit', blockType: null }),
      gridContentBackPage: Array(typeMode).fill({ content: '+Edit', blockType: null }),
      screenName:"front_back",
      isFrontPage:true
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    // console.log("hello world jji",this.props.getDataOfTemplate,this.props.templateType)
    // this.tranformGridContent()
   
  }
  // componentDidUpdate(prevProps: Props) {
  //   // Check if the prop `getDataOfTemplate` has changed
  //   if (prevProps.getDataOfTemplate !== this.props.getDataOfTemplate ||
  //       prevProps.templateType !== this.props.templateType) {
  //     // Call the `tranformGridContent` method if either prop has changed
  //     this.tranformGridContent();
  //   }
  // }
  togglePage = () => {
    this.setState((prevState) => ({
      isFrontPage: !prevState.isFrontPage,
    }));
  };
  
  handleGetBlockTypeConvert = (selectedDataBlockPlacement:string)=>{
    let newContent = "";
    if (selectedDataBlockPlacement === "grades") {
      newContent = "Grades";
    } else if (selectedDataBlockPlacement === "behaviour") {
      newContent = "Behavior";
    } else if (selectedDataBlockPlacement === "attendence") {
      newContent = "Attendance";
    } else if (selectedDataBlockPlacement === "student_information") {
      newContent = "StudentInformation";
    }else if(selectedDataBlockPlacement === "TextEditor"){
      newContent = ""
    }
    return newContent
  }
  handleGetConvertBlockTypeContent = (selectedValue:string) => {
    let newContent = "";
    if (selectedValue === "data_blocks") {
      newContent = "DataBlocks";
    } else if (selectedValue === "text_editor") {
      newContent = "TextEditor";
    }
    return newContent;
  }
  handleGetContentForType = (selectedDataBlockPlacement:string,description:string)=>{
    let newContent;
    if (selectedDataBlockPlacement === "Grades") {
      newContent = <GradeTable />;
    } else if (selectedDataBlockPlacement === "Behavior") {
      newContent = <BehaviourTable />;
    } else if (selectedDataBlockPlacement === "Attendance") {
      newContent = <AttandanceTable />;
    } else if (selectedDataBlockPlacement === "StudentInformation") {
      newContent = <StudentDetails />;
    } else {
      newContent = description || "+Edit"
    }
    return newContent
  }
//   tranformGridContent = () => {
//     const backPageBlocks:any =[];
//     const frontPageBlocks:any=[];

//     if (Array.isArray(this.props.getDataOfTemplate)) {
//         this.props.getDataOfTemplate.forEach((item: any) => {
//           const index = this.props.templateType==="landscape" ? (item.row_number * 2 + item.column_number) : item.row_number;
//           const blockType = this.handleGetBlockTypeConvert(item.data_block_content_type)
//           const contentType = this.handleGetContentForType(blockType,item.description);
//           const blockTypeContent = this.handleGetConvertBlockTypeContent(item.data_block_type)
//             const block = {
//                 content:contentType,
//                 blockType: blockType,
//                 blockTypeContent:blockTypeContent
//             };

//             if (item.page_type === "back_page") {
//                 backPageBlocks[index] = block;
//             } else if (item.page_type === "front_page") {
//                 frontPageBlocks[index] = block;
//             }
//         });
//     } else {
//         console.error("getDataOfTemplate is not an array or is undefined.");
//     }
//     console.log("gridcontent",frontPageBlocks)
//     this.setState({gridContent:frontPageBlocks,gridContentBackPage:backPageBlocks})
// }
  // Customizable Area End

  
}
// Customizable Area End