// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { IDropdownMenuItemProps } from "../../../components/src/CommonType.web";
import moment from "moment";

const ntc = require("ntcjs");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  open: boolean;
  handleClose: any;
  handleUpdateEvent: any;
  handleAddEvent: any;
  selectedEvent: any;
  editFlag: boolean;
  curriculumList: Array<IDropdownMenuItemProps>;
  classesList: Array<IDropdownMenuItemProps>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  eventTitle: string;
  eventTitleError: boolean;
  eventTitleErrorMsg: string;
  selectedColor: string;
  selectedColorName: string;
  selectedColorError: boolean;
  selectedColorErrorMsg: string;
  selectCurriculum: Array<any>;
  selectCurriculumError: boolean;
  selectCurriculumErrorMsg: string;
  selectClass: Array<any>;
  selectClassError: boolean;
  selectClassErrorMsg: string;
  startDateTime: any;
  startDateTimeError: boolean;
  startDateTimeErrorMsg: string;
  endDateTime: any;
  endDateTimeError: boolean;
  endDateTimeErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CreateEventDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      eventTitle: "",
      eventTitleError: false,
      eventTitleErrorMsg: "",
      selectedColor: "",
      selectedColorName: "",
      selectedColorError: false,
      selectedColorErrorMsg: "",
      selectCurriculum: [],
      selectCurriculumError: false,
      selectCurriculumErrorMsg: "",
      selectClass: [],
      selectClassError: false,
      selectClassErrorMsg: "",
      startDateTime: null,
      startDateTimeError: false,
      startDateTimeErrorMsg: "",
      endDateTime: null,
      endDateTimeError: false,
      endDateTimeErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        eventTitleError: false,
        eventTitleErrorMsg: "",
      };
    });
  };

  selectStartDate = (date: any) => {
    const { endDateTime } = this.state;
    this.setState({
      startDateTimeError: false,
      startDateTimeErrorMsg: "",
      endDateTimeError: false,
      endDateTimeErrorMsg: "",
    });
    if (moment(date).isBefore(moment(new Date()))) {
      this.setState({
        startDateTimeError: true,
        startDateTimeErrorMsg: "Start Date cannot be a previous date & time",
      });
    } else if (endDateTime) {
      if (moment(date).isAfter(moment(endDateTime))) {
        this.setState({
          startDateTimeError: true,
          startDateTimeErrorMsg: "Start Date cannot be after End Date",
        });
      }
    }
    this.setState({ startDateTime: date });
  };

  selectEndDate = (date: any) => {
    const { startDateTime } = this.state;
    this.setState({
      endDateTimeError: false,
      endDateTimeErrorMsg: "",
      startDateTimeError: false,
      startDateTimeErrorMsg: "",
    });
    if (startDateTime) {
      if (moment(date).isBefore(moment(startDateTime))) {
        this.setState({
          endDateTimeError: true,
          endDateTimeErrorMsg: "End Date cannot be before Start Date",
        });
      }
    }
    this.setState({
      endDateTime: date,
    });
  };

  handleColorChange = (color: any) => {
    const n_match = ntc.name(color);
    this.setState({
      selectedColor: color,
      selectedColorName: n_match[1],
      selectedColorError: false,
      selectedColorErrorMsg: "",
    });
  };

  handleSetInitialEventValue = (isEdit?: boolean) => {
    const { selectedEvent } = this.props;
    if (isEdit) {
      const colorName = ntc.name(selectedEvent?.event?.color);
      this.setState({
        eventTitle: selectedEvent?.event?.title,
        eventTitleError: false,
        eventTitleErrorMsg: "",
        selectClass: selectedEvent?.classId || [],
        selectClassError: false,
        selectClassErrorMsg: "",
        selectCurriculum: selectedEvent?.curriculumId || [],
        selectCurriculumError: false,
        selectCurriculumErrorMsg: "",
        selectedColor: selectedEvent?.event?.color,
        selectedColorName: colorName[1],
        selectedColorError: false,
        selectedColorErrorMsg: "",
        startDateTime: new Date(selectedEvent?.startDate),
        startDateTimeError: false,
        startDateTimeErrorMsg: "",
        endDateTime: new Date(selectedEvent?.endDate),
        endDateTimeError: false,
        endDateTimeErrorMsg: "",
      });
    } else {
      this.setState(
        {
          eventTitle: "",
          eventTitleError: false,
          eventTitleErrorMsg: "",
          selectedColor: "",
          selectedColorName: "",
          selectedColorError: false,
          selectedColorErrorMsg: "",
          selectCurriculum: [],
          selectCurriculumError: false,
          selectCurriculumErrorMsg: "",
          selectClass: [],
          selectClassError: false,
          selectClassErrorMsg: "",
          startDateTime: null,
          startDateTimeError: false,
          startDateTimeErrorMsg: "",
          endDateTime: null,
          endDateTimeError: false,
          endDateTimeErrorMsg: "",
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleClosePopup = () => {
    this.handleSetInitialEventValue();
  };

  handleAddEvent = () => {
    if (
      this.handleValidateFormData() &&
      this.handleValidateColor() &&
      this.handleValidateDates()
    ) {
      const {
        eventTitle,
        selectClass,
        selectCurriculum,
        selectedColor,
        endDateTime,
        startDateTime,
      } = this.state;
      const { editFlag } = this.props;
      const requestBody = {
        data: {
          title: eventTitle,
          color: selectedColor,
          curriculum_id: selectCurriculum,
          class_management_id: selectClass,
          start_date_and_time: startDateTime,
          end_date_and_time: endDateTime,
        },
      };
      if (editFlag) {
        this.props.handleUpdateEvent(requestBody);
      } else {
        this.props.handleAddEvent(requestBody);
      }
    }
  };

  handleValidateColor = () => {
    const { selectedColor } = this.state;
    let isValid = true;
    if (!selectedColor) {
      this.setState({
        selectedColorError: true,
        selectedColorErrorMsg: "Please select a color",
      });
      isValid = false;
    }
    return isValid;
  };

  handleValidateDates = () => {
    const { endDateTime, startDateTime } = this.state;
    let isValid = true;
    if (!startDateTime) {
      this.setState({
        startDateTimeError: true,
        startDateTimeErrorMsg: "Please select start date time!",
      });
      isValid = false;
    }
    if (!endDateTime) {
      this.setState({
        endDateTimeError: true,
        endDateTimeErrorMsg: "Please select end date time!",
      });
      isValid = false;
    }
    if (startDateTime && endDateTime) {
      if (moment(endDateTime).isBefore(moment(startDateTime))) {
        this.setState({
          endDateTimeError: true,
          endDateTimeErrorMsg: "End Date cannot be before Start Date",
        });
        isValid = false;
      }
    }
    return isValid;
  };

  handleValidateFormData = () => {
    const { eventTitle, selectClass, selectCurriculum } = this.state;
    let isValid = true;
    if (!eventTitle.trim()) {
      this.setState({
        eventTitleError: true,
        eventTitleErrorMsg: "Please enter a title",
      });
      isValid = false;
    }
    if (selectClass?.length === 0) {
      this.setState({
        selectClassError: true,
        selectClassErrorMsg: "Please select at least 1 class",
      });
      isValid = false;
    }
    if (selectCurriculum?.length === 0) {
      this.setState({
        selectCurriculumError: true,
        selectCurriculumErrorMsg: "Please select at least 1 curriculum",
      });
      isValid = false;
    }
    return isValid;
  };

  handleChangeCurriculum = (event: any) => {
    const { curriculumList } = this.props;
    if (event.target.value.includes("All")) {
      this.setState({
        selectCurriculum: curriculumList.map((item) => {
          return item.id;
        }),
      });
    } else {
      this.setState({
        selectCurriculum: event.target.value,
      });
    }
    this.setState({
      selectCurriculumError: false,
      selectCurriculumErrorMsg: "",
    });
  };

  handleChangeClass = (event: any) => {
    const { classesList } = this.props;
    if (event.target.value.includes("All")) {
      this.setState({
        selectClass: classesList.map((item) => {
          return item.id;
        }),
      });
    } else {
      this.setState({
        selectClass: event.target.value,
      });
    }
    this.setState({
      selectClassError: false,
      selectClassErrorMsg: "",
    });
  };

  removeSelectedCurriculum = (value: any) => {
    const { selectCurriculum } = this.state;
    let updatedValues = selectCurriculum.filter((item: any) => item !== value);
    updatedValues = updatedValues.filter((item: any) => item !== "All");
    this.setState({
      selectCurriculum: updatedValues,
    });
  };

  removeSelectedClass = (value: any) => {
    const { selectClass } = this.state;
    let updatedValues = selectClass.filter((item: any) => item !== value);
    updatedValues = updatedValues.filter((item: any) => item !== "All");
    this.setState({
      selectClass: updatedValues,
    });
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { editFlag } = this.props;
    if (editFlag) {
      this.handleSetInitialEventValue(true);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
