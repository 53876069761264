// Customizable Area Start
import React from "react";
import { Select, MenuItem, FormHelperText, CircularProgress, Typography, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomSelectDropdownController, {
  Props,
} from "./CustomSelectDropdownController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      borderRadius: "10px",
      border: "solid 2px #f4f4f4",
      padding: "18px 0px",
    },
    textfieldInput: {
      fontWeight: "normal",
      paddingLeft: "5px",
    },
    errorBorder: {
      border: "1px solid red !important",
    },
    inputAdornment: {
      paddingLeft: "15px",
    },
    textFieldFont: {
      fontSize: "16px",
    },
    selectBootstrapInput: {
      borderRadius: "10px",
      border: "solid 1px #888888",
      padding: "9px 0px 7px 10px",
      fontSize: "16px",
      cursor: "none !important",
      "& .MuiSelect-select": {
        color: "#888888",
        fontWeight: 500,
        paddingRight: "0px !important",
        textTransform: "capitalize",
      },
      "& .MuiSelect-icon": {
        color: "#888888",
      },
    },
    notSelectedMenuitem: {
      color: "#888888",
      textTransform: "capitalize",
    },
    selectedMenuitem: {
      color: "#888888",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    dropdownIcon: {
      zIndex: 1,
    },
    loader: {
      height: "48px",
      width: "99%",
      borderRadius: "12px",
      padding: "0px",
      background: "#FFF",
      border: "1px solid #888",
      fontSize: "16px",
      color: "#888",
      marginTop: "10px",
      fontWeight: 400,
    }
  });

// Customizable Area End

export class CustomSelectDropdown extends CustomSelectDropdownController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      fieldName,
      fieldError,
      helperText,
      fieldValue,
      fieldId,
      fieldMenuItems,
      fieldStyles,
      isDisabled,
      isLoading,
    } = this.props;

    const applyClass: any = (item: any) => {
      if (item.value === fieldValue) return `${classes.selectedMenuitem}`;
      return `${classes.notSelectedMenuitem}`;
    };

    const selectClass: any = () => {
      const { fieldError } = this.props;
      if (fieldError) return `${classes.selectBootstrapInput} ${classes.errorBorder} ${classes.bgColor
        }`;
      else if (isDisabled) return `${classes.selectBootstrapInput} ${classes.secondaryBgColor
        }`
      else return `${classes.selectBootstrapInput} ${classes.bgColor
        }`
    }

    return (
      <>
        {
          !isLoading &&
          <Select
            id={fieldId}
            value={fieldValue}
            name={fieldName}
            disabled={isDisabled}
            className={selectClass()}
            style={fieldStyles}
            onChange={this.handleChangeMenu}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "400px",
                  borderRadius: "10px",
                },
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
            error={fieldError}
            renderValue={this.props.renderValue}
            disableUnderline
            displayEmpty
            native={false}
            IconComponent={ExpandMoreIcon}
          >
            {fieldMenuItems.map((item: any) => (
              <MenuItem
                className={applyClass(item)}
                key={`${item.id}`}
                value={item.value}
                disabled={item?.disabled}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        }
        {
          isLoading &&
          <Box className={classes.loader} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"row"} gridGap={"10px"}>
            <CircularProgress style={{ width: "15px", height: "15px" }} /> <Typography component={"span"}>Loading...</Typography>
          </Box>
        }
        {fieldError && <FormHelperText className={`${classes.helperText}`}>{helperText}</FormHelperText>}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);

export default withStyles(combinedStyle, { withTheme: true })(
  CustomSelectDropdown
);
// Customizable Area End
