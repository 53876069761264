// Customizable Area Start
import React from 'react';
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
interface IProps {
  currentTrack  : any;
  audioRef : any;
  setDuration : any;
  setTimeProgress:any;
  handleErrorInAudio:any;
  handleUpdateMediaProgressTime:any;
  progressBarRef : any;
  classes:any;
}
// Customizable Area End

// Customizable Area Start
const AudioPlayerDisplayTrack  : React.FC<IProps> = (props) => {
    const { currentTrack , audioRef , setDuration , progressBarRef , setTimeProgress , handleErrorInAudio , handleUpdateMediaProgressTime } = props;

    const onLoadedMetadata = () => {
      const seconds = Number(audioRef.current.duration?.toFixed(2));
      setDuration(seconds);
      audioRef.current.currentTime = currentTrack.audioProgress;
      setTimeProgress(currentTrack.audioProgress);
      progressBarRef.current.max = seconds;
    };

    const onEnded = () => {
        if(audioRef?.current && progressBarRef?.current)
        audioRef.current.currentTime = 0;
        setTimeProgress(0);
    }

    const onError = () => {
      toast.error("Something went wrong ! Audio could not be loaded !")
      handleErrorInAudio();
    }

  return (
    <Box p={"0px 8px"} width={"100%"}>
       <audio src={currentTrack.src} ref={audioRef} preload='metadata' onTimeUpdate={(e:any) => {handleUpdateMediaProgressTime(audioRef?.current?.currentTime)}} onLoadedMetadata={onLoadedMetadata} onEnded={onEnded} onError={onError} />
    </Box>
  )
}

export default AudioPlayerDisplayTrack;
// Customizable Area End 