// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import  GradeTable from "./tables/GradeTable.web";
import BehaviourTable  from "./tables/BehaviourTable.web";
import React from "react";
import  AttandanceTable  from "./tables/AttandanceTable.web";
import  StudentDetails  from "./tables/StudentDetails.web";
import { studentDataMock } from "../gradebookDetails/assets/mockdata";

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  isOpen: boolean;
  handleClose: () => void;
  screenName: string;
  templateType: string;
  getDataOfTemplate:any
  AttandanceTableHead:any;
  AttandanceTableData:any
  gradebookListTableHead:any;
  gradebookTableData:any
  behaviourTableHead:any;
  behaviourTableData:any;
  schoolYearData:string
  behaviorTableDataLength:number
  studentData:any;
  className:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gridContent: React.ReactNode[]; 
  gridContentBackPage:React.ReactNode[]; 
  isFrontPage:boolean;
 
  screenName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewReportCardModelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    const typeMode = this.props.templateType === "portrait_template" ? 5 :6

    this.state = {
      gridContent: Array(typeMode).fill({ content: '+Edit', blockType: null }),
      gridContentBackPage: Array(typeMode).fill({ content: '+Edit', blockType: null }),
      screenName:"front_back",
      isFrontPage:true
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.tranformGridContent()
   
  }
  componentDidUpdate(prevProps: Props) {
    // Check if the prop `getDataOfTemplate` has changed
    if (
        prevProps.AttandanceTableData !== this.props.AttandanceTableData ||
        prevProps.AttandanceTableHead !== this.props.AttandanceTableHead ||
        prevProps.gradebookListTableHead !== this.props.gradebookListTableHead ||
        prevProps.gradebookTableData !== this.props.gradebookTableData ||
        prevProps.behaviourTableHead !== this.props.behaviourTableHead ||
        prevProps.behaviourTableData !== this.props.behaviourTableData ||
        prevProps.getDataOfTemplate !== this.props.getDataOfTemplate ||
        prevProps.behaviorTableDataLength !== this.props.behaviorTableDataLength ||
        prevProps.studentData !== this.props.studentData ||
        prevProps.className !== this.props.className ||
        prevProps.templateType !== this.props.templateType) {
      // Call the `tranformGridContent` method if either prop has changed
      this.tranformGridContent();
    }
  }
  togglePage = () => {
    this.setState((prevState) => ({
      isFrontPage: !prevState.isFrontPage,
    }));
  };
  
  handleGetBlockTypeConvert = (selectedDataBlockPlacement:string)=>{
    let newContent = "";
    if (selectedDataBlockPlacement === "grades") {
      newContent = "Grades";
    } else if (selectedDataBlockPlacement === "behaviour") {
      newContent = "Behavior";
    } else if (selectedDataBlockPlacement === "attendence") {
      newContent = "Attendance";
    } else if (selectedDataBlockPlacement === "student_information") {
      newContent = "StudentInformation";
    }else if(selectedDataBlockPlacement === "TextEditor"){
      newContent = ""
    }
    return newContent
  }
  handleGetConvertBlockTypeContent = (selectedValue:string) => {
    let newContent = "";
    if (selectedValue === "data_blocks") {
      newContent = "DataBlocks";
    } else if (selectedValue === "text_editor") {
      newContent = "TextEditor";
    }
    return newContent;
  }
  handleGetContentForType = (selectedDataBlockPlacement:string,description:string,index:number)=>{
    let newContent;
    if (selectedDataBlockPlacement === "Grades") {
      newContent = <GradeTable 
        schoolYear={this.props.schoolYearData} 
        gradebookTableData={this.props.gradebookTableData} 
        gradebookListTableHead={this.props.gradebookListTableHead}
        className={this.props.className}
        />;
    } else if (selectedDataBlockPlacement === "Behavior") {
      newContent = <BehaviourTable 
       behaviourTableHead={this.props.behaviourTableHead[index%this.props.behaviorTableDataLength]} 
       behaviourTableData={this.props.behaviourTableData[index%this.props.behaviorTableDataLength]}
       />;
    } else if (selectedDataBlockPlacement === "Attendance") {
      newContent = <AttandanceTable AttandanceTableHead={this.props.AttandanceTableHead} AttandanceTableData={this.props.AttandanceTableData}/>;
    } else if (selectedDataBlockPlacement === "StudentInformation") {
      newContent = <StudentDetails 
                    className={this.props.className} 
                    schoolYear={this.props.schoolYearData} 
                    studentData={this.props.studentData}
                  />;
    } else {
      newContent = description || "+Edit"
    }
    return newContent
  }
  tranformGridContent = () => {
    const backPageBlocks:any =[];
    const frontPageBlocks:any=[];
    let indexBehv = 0
    if (Array.isArray(this.props.getDataOfTemplate)) {
        this.props.getDataOfTemplate.forEach((item: any) => {
          const index = this.props.templateType==="landscape_template" ? (item.row_number * 2 + item.column_number) : item.row_number;
          const blockType = this.handleGetBlockTypeConvert(item.data_block_content_type)
          const contentType = this.handleGetContentForType(blockType,item.description,indexBehv);
          const blockTypeContent = this.handleGetConvertBlockTypeContent(item.data_block_type)
          if(blockType === "Behavior"){
            indexBehv++;
          }
            const block = {
                content:contentType,
                blockType: blockType,
                blockTypeContent:blockTypeContent
            };

            if (item.page_type === "back_page") {
                backPageBlocks[index] = block;
            } else if (item.page_type === "front_page") {
                frontPageBlocks[index] = block;
            }
        });
    } else {
        console.error("getDataOfTemplate is not an array or is undefined.");
    }
    this.setState({gridContent:frontPageBlocks,gridContentBackPage:backPageBlocks})
}
  // Customizable Area End

  
}
// Customizable Area End