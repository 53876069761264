// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherCatalogTabController, {
  Props,
} from "./TeacherCatalogTabController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import { ScienceColor, MathsColor, HistoryColor, EnglishColor } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TeacherAssignCourseModal from "./TeacherAssignCourseModal.web";
import TeacherCopyCourseSuccessModal from "./TeacherCopyCourseSuccessModal.web";

// Customizable Area End
const TeachercatalogTableColumns = [
  {
    columnId: 11,
    id: "title",
    label: "Catalog Name",
    type: tableColumnTypes.CATALOG_NAME,
  },
  {
    columnId: 12,
    id: "noOfCourses",
    label: "No. of Courses",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 13,
    id: "grade",
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "curriculum",
    columnId: 14,
    label: "Curriculum",
    type: tableColumnTypes.TEXT,
  },
 
  {
    id: "createdBy",
    columnId: 6,
    label: "Created By",
    type: tableColumnTypes.TEXT,
  },
];


const teacherCatalogCourseTableColumn = [
  {
    columnId: 11,
    id: "Titles",
    label: "Title",
    type: tableColumnTypes.COURSES_NAME,
  },
  {
    columnId: 12,
    id: "Subjects",
    label: "Subject",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 13,
    id: "Grades",
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "LessonDays",
    columnId: 14,
    label: "No. of lessons / day",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 15,
    id: "NoOf_Lessons",
    label: "Total No. of lessons",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Modify_By",
    columnId: 16,
    label: "Modified By",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Create_By",
    columnId: 17,
    label: "Created By",
    type: tableColumnTypes.TEXT,
  },
];
const teacherCatalogCoursesData = [
  {
    id: 1,
    Titles: {
      name: "Physics",
      icon: ScienceColor,
    },
    Subjects: "Science",
    Grades: "Grade 3",
    LessonDays: "1 Lesson / day",
    NoOf_Lessons: "47",
    Modify_By: "Bairam Frootans",
    Create_By: "Conan Matusovs",
  },
  {
    id: 2,
    Titles: {
      name: "Roman History",
      icon: HistoryColor,
    },
    Subjects: "History",
    Grades: "Grade 3",
    LessonDays: "1 Lesson / day",
    NoOf_Lessons: "47",
    Modify_By: "Bairam Frootan",
    Create_By: "Conan Matusov",
  },
  {
    id: 3,
    Titles: {
      name: "Algebra",
      icon: MathsColor,
    },
    Subjects: "Maths",
    Grades: "Grade 3",
    LessonDays: "1 Lesson / day",
    NoOf_Lessons: "47",
    Modify_By: "Bairam Frootan",
    Create_By: "Conan Matusov",
  },
  {
    id: 4,
    Titles: {
      name: "Creative Writing",
      icon: EnglishColor,
    },
    Subjects: "English",
    Grades: "Grade 3",
    LessonDays: "1 Lesson / day",
    NoOf_Lessons: "47",
    Modify_By: "Bairam Frootan",
    Create_By: "Conan Matusov",
  },
  {
    id: 5,
    Titles: {
      name: "Victorian Literature",
      icon: EnglishColor,
    },
    Subjects: "English",
    Grades: "Grade 3",
    LessonDays: "1 Lesson / day",
    NoOf_Lessons: "47",
    Modify_By: "Bairam Frootan",
    Create_By: "Conan Matusov",
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    horizontalDividerfirst: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "19px",
      marginBottom: "5px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    horizontalDividerTwo: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "13px",
      marginBottom: "15px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    horizontalDividerThree: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "19px",
      marginBottom: "5px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    CatlgTitles: {
      color: "#090909",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "normal",
    },
    catalogTableRows: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    unWrapped:{
      whiteSpace:"nowrap"
    }
  });
// Customizable Area End

// Customizable Area Start
export class TeacherCatalogTab extends TeacherCatalogTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openCatalogCourses, selectedCatalogName, menuAnchorEl } =
      this.state;
    const { classes, tableBody } = this.props;

    return (
      <Box px={"25px"} pb={"20px"} data-test-id="MainRows">
        {!openCatalogCourses ? (
          <Box>
            <Grid container spacing={3} direction="row" alignItems="center">
              <Grid xs={12} lg={3} md={4} item>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    data-test-id="flexBox"
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    gutterBottom
                  >
                    {`Grade`}
                  </Typography>
                  <CustomSelectDropdown
                    fieldId="select-dropdown-catalog-component"
                    fieldValue={this.state.selectGradeDDValue}
                    fieldName="selectGradeDDValue"
                    handleChange={this.handleGradeDDValues}
                    renderValue={this.renderGradeDDValues}
                    fieldError={false}
                    fieldMenuItems={this.state.gradeDDvalues}
                    fieldStyles={dropdownStyle}
                    iconComponent={
                      <DropdownIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#888888"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    data-test-id="CurriculamBox1"
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    gutterBottom
                  >
                    {`Curriculum`}
                  </Typography>
                  <CustomSelectDropdown
                    fieldId="select-dropdown-assessment-grading-component"
                    fieldValue={this.state.curriculumDDValue}
                    fieldName="curriculumDDValue"
                    handleChange={this.handleGradeDDValues}
                    renderValue={this.renderCurriculumsDDValues}
                    fieldError={false}
                    fieldMenuItems={this.state.CurriculumComponents}
                    fieldStyles={dropdownStyle}
                    iconComponent={
                      <DropdownIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#888888"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    data-test-id="Fields"
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    gutterBottom
                  >
                    {`Custom Field`}
                  </Typography>
                  <CustomSelectDropdown
                    fieldId="select-dropdown-assessment-Status-component"
                    fieldValue={this.state.customFieldComponentDDValue}
                    fieldName="customFieldComponentDDValue"
                    handleChange={this.handleGradeDDValues}
                    renderValue={this.rendercustomFieldsDDValues}
                    fieldError={false}
                    fieldMenuItems={this.state.customFieldItems}
                    fieldStyles={dropdownStyle}
                    iconComponent={
                      <DropdownIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#888888"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item data-test-id="columBtn">
                <Box marginBottom={"10px"} data-test-id="columBtnBox">
                  <CustomButton
                    btnText="Search"
                    btnStyle={searchBtnCss}
                    buttonId={`my-Btn-search-mobile`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleSearchButton}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.horizontalDividerfirst} />
            <Grid container>
              <Grid item xs={12}>
                <Box
                  className={`${classes.customTableStyles} ${classes.catalogTableRows}`}
                >
                  <CustomTable
                    tableId={"teacher-catalog-table"}
                    tableHeaderColumns={TeachercatalogTableColumns}
                    tableBodyData={tableBody}
                    showMenuIcon={false}
                    handleAction={this.switchCatalogCourses}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Grid
              direction="row"
              container
              data-test-id="secondPagination"
            >
              <Grid xs={12} item alignItems={"center"} justifyContent={"center"}>
                <Button onClick={this.switchCatalogCourses}>
                  <KeyboardBackspaceIcon
                    fontSize="large"
                    className={`${classes.textPrimaryColor}`}
                  />
                </Button>
                <Typography
                  className={`${classes.CatlgTitle} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  {selectedCatalogName}
                </Typography>
              </Grid>
            </Grid>
            <Box
              className={classes.horizontalDividerTwo}
            />
            <Grid
              direction="row"
              container
              spacing={3}
              data-test-id="TwoGridBox"
            >
              <Grid xs={12} lg={3} md={4} item>
                <Box>
                  <Box marginBottom={"8px"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="TeaNames"
                    >
                      Teacher
                    </Typography>
                  </Box>
                  <Box>
                    <CustomSelectDropdown
                      fieldId="select-dropdown-teacher-dd-component"
                      fieldValue={this.state.teacherComponentDDValue}
                      fieldName="teacherComponentDDValue"
                      handleChange={this.handleGradeDDValues}
                      renderValue={this.renderTeacherDDValues}
                      fieldError={false}
                      fieldMenuItems={this.state.teacherDDValue}
                      fieldStyles={CourseListigDDCss}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item data-test-id="GradeDropDownBox">
                <Box data-test-id="BoxDD">
                  <Box marginBottom={"8px"} data-test-id="BoxDD_one">
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="BoxTitle"
                    >
                      Subject
                    </Typography>
                  </Box>
                  <Box>
                    <CustomSelectDropdown
                      fieldId="select-dropdown-teacher-dd-component"
                      fieldValue={this.state.subjectComponentDDValue}
                      fieldName="subjectComponentDDValue"
                      handleChange={this.handleGradeDDValues}
                      renderValue={this.renderSubjectDDValues}
                      fieldError={false}
                      fieldMenuItems={this.state.subjectDDValue}
                      fieldStyles={CourseListigDDCss}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item data-test-id="BoxDDGrade">
                <Box data-test-id="BoxGradeDD">
                  <Box marginBottom={"8px"} data-test-id="TiteBox">
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${classes.textCommonColor}`}
                      data-test-id="GradeItems"
                    >
                      Grade
                    </Typography>
                  </Box>
                  <Box>
                    <CustomSelectDropdown
                      fieldId="select-dropdown-teacher-grade-component"
                      fieldValue={this.state.courseGradeComponentDDValue}
                      fieldName="courseGradeComponentDDValue"
                      handleChange={this.handleGradeDDValues}
                      renderValue={this.renderCourseGradeDDValues}
                      fieldError={false}
                      fieldMenuItems={this.state.courseGradeDDValue}
                      fieldStyles={CourseListigDDCss}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item data-test-id="schoolYrs">
                <Box data-test-id="schollYears">
                  <Box marginBottom={"8px"} data-test-id="My_name">
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      data-test-id="schoolItms"
                    >
                      School Year
                    </Typography>
                  </Box>
                  <Box justifyContent={"flex-start"} display={"flex"}>
                    <CustomSelectDropdown
                      fieldId="select-dropdown-teacher-grade-component"
                      fieldValue={this.state.schoolYearComponentDDValue}
                      fieldName="schoolYearComponentDDValue"
                      handleChange={this.handleGradeDDValues}
                      renderValue={this.renderSchoolYearDDValues}
                      fieldError={false}
                      fieldMenuItems={this.state.schoolYearDDValue}
                      fieldStyles={CourseListigDDCss}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item data-test-id="UtilizationDD">
                <Box data-test-id="UtilizationBox">
                  <Box marginBottom={"8px"} data-test-id="titleItem">
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      data-test-id="UtilizationsItem"
                    >
                      Utilization of the course
                    </Typography>
                  </Box>
                  <Box
                    justifyContent={"flex-start"}
                    display={"flex"}
                    data-test-id="selectDDItemBox"
                  >
                    <CustomSelectDropdown
                      fieldId="select-dropdown-teacher-grade-component"
                      fieldValue={this.state.courseUtilizationComponentDDValue}
                      fieldName="courseUtilizationComponentDDValue"
                      handleChange={this.handleGradeDDValues}
                      renderValue={this.renderCourseUtilizationDDValues}
                      fieldError={false}
                      fieldMenuItems={this.state.courseUtilizationDDValue}
                      fieldStyles={CourseListigDDCss}
                      iconComponent={
                        <DropdownIcon
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke={"#888888"}
                          strokeWidth="1.5"
                          fill={"none"}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} lg={3} md={4} item data-test-id="btnSearchs">
                <Box data-test-id="btnBox">
                  <CustomButton
                    btnText="Search"
                    btnStyle={searchBtnCss}
                    buttonId={`my-assessment-search-Btns`}
                    buttonVariant={"contained"}
                    type={"button"}
                    handleButtonClick={this.handleSearchButton}
                    isDisabled={false}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.horizontalDividerThree} data-test-id="divider" />
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.customTableStyles}>
                  <TableContainer
                    className={classes.tableContainer}
                    id="teacher-catalog-courses-table"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          {teacherCatalogCourseTableColumn.map(
                            (column: any) => (
                              <TableCell key={column.id}>
                                <Box display={"flex"}>
                                  <Typography
                                    className={`${classes.fontSize15} ${classes.textCommonColor}`}
                                    component={"span"}
                                  >
                                    {column.label}
                                  </Typography>
                                  {column?.icon ? (
                                    <img
                                      src={column?.icon}
                                      className={classes.columnIcon}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </TableCell>
                            )
                          )}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teacherCatalogCoursesData?.length > 0 &&
                          teacherCatalogCoursesData.map((row: any, i: any) => {
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                {teacherCatalogCourseTableColumn.map(
                                  (column: any) => {
                                    return (
                                      <TableCell
                                        className={`${classes.tableBodyCell}`}
                                        style={{
                                          minWidth: "100px",
                                          maxWidth: "100%",
                                        }}
                                        key={`${column.id}-${row.id}`}
                                      >
                                        {column.id === "Titles" ? (
                                          <Box
                                            display={"flex"}
                                            alignItems="center"
                                          >
                                            <Box
                                              mr={"10px"}
                                              className={classes.subjectLogoBg}
                                            >
                                              <img src={row[column.id]?.icon} />
                                            </Box>
                                            <Box>
                                              <Typography
                                                className={`${classes.textPrimaryColor} ${classes.fontText15} ${classes.unWrapped} `}
                                                component="span"
                                                data-test-id="columData"
                                              >
                                                {row[column.id]?.name}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        ) : (
                                          <Typography
                                            className={`${classes.fontSize15} ${classes.textPrimaryColor} ${classes.unWrapped}`}
                                            component={"span"}
                                            data-test-id="rowId"
                                          >
                                            {row[column.id]}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                  }
                                )}
                                <TableCell align="right">
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    data-test-id={`IconBtn-${i}`}
                                    onClick={(e: any) =>
                                      this.handleActionIcon(e, row.id)
                                    }
                                  >
                                    <MoreVertIcon
                                      className={`${classes.textCommonColor}`}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Menu
                    id={`courses-table-menu-action`}
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={this.handleMenuCloseIcon}
                  >
                    <MenuItem onClick={this.handleOpenAssignCoursesModal}>
                      Assign to Students
                    </MenuItem>
                    <MenuItem onClick={this.handleCopyCourseSuccessModal}>
                      Copy to My Courses
                    </MenuItem>
                    <MenuItem onClick={this.handleMenuCloseIcon}>View</MenuItem>
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <TeacherAssignCourseModal
          open={this.state.openAssignCourseModal}
          onCancel={this.handleCloseAssignCourseModal}
          onConfirm={this.handleCloseAssignCourseModal}
        />
          <TeacherCopyCourseSuccessModal
          open={this.state.openCopyCourseSuccessModal}
          onCancel={this.handleCourseSuccessModalModal}
          onConfirm={this.handleCourseSuccessModalModal}
         />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtnCss = {
  padding: "10px 10px",
  width: "100%",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
} as const;

const CourseListigDDCss = {
  width: "100%",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCatalogTab);
// Customizable Area End
