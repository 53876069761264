// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ManageEventsController, { Props } from "./ManageEventsController.web";
import CreateEventDialog from "./CreateEventDialog.web";
import DeleteEventDialog from "./DeleteEventDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    colorCircle: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    manageEventRoot: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px",
    },
    arrowIcon: {
      height: "28px",
      width: "28px",
      marginRight: "10px",
    },
    manageEventText: {
      fontSize: "34px",
      fontWeight: 700,
    },
    tableWrapper: {
      borderRadius: "10px",
      padding: "15px 1px",
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
  });

const tableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
      },
      head: {
        paddingTop: "0px !important",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px !important",
      },
    },
  },
});

const eventsTableHeader = [
  {
    id: "event",
    columnId: 1,
    label: "Event Title",
    type: tableColumnTypes.EVENT_COLOR_TITLE,
    width: "250px",
    maxWidth: "280px"
  },
  {
    id: "curriculumTitle",
    columnId: 2,
    label: "Curriculum",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "classTitle",
    columnId: 3,
    label: "Class",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "startDate",
    columnId: 4,
    label: "Start Date & Time",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "endDate",
    columnId: 5,
    label: "End Date & Time",
    type: tableColumnTypes.TEXT,
  },
];

// Customizable Area End

// Customizable Area Start
export class ManageEvents extends ManageEventsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      openCreateEventDialog,
      openDeleteEventDialog,
      editEventFlag,
      manageEventsActionBtnConfig,
      eventsList,
      selectedEventDetails,
      curriculumsListing,
      classesListing,
      totalNumberOfEvents,
      currentPage,
      perPageEvents,
    } = this.state;

    return (
      <Box
        className={`${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
        style={{ padding: "40px" }}
      >
        <Box className={classes.breadCrumbRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText12}`}
            component={"span"}
          >
            {`Setup / School Year`}
          </Typography>
          <Typography
            className={`${classes.textButton} ${classes.fontBold600} ${classes.fontText12}`}
            component={"span"}
          >
            {` / Manage Events`}
          </Typography>
        </Box>
        <Box className={classes.manageEventRoot}>
          <Box>
            <ArrowBackIcon
              className={`${classes.textPrimaryColor} ${classes.arrowIcon} ${classes.cursorPointer}`}
              data-test-id="manage-events-go-back-btn"
              onClick={() => this.handleGoBackToPreviousScreen()}
            />
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
              component={"span"}
            >
              {`Manage Events`}
            </Typography>
          </Box>
          {this.state.calendarPermission.manageCalender.write &&
          <CustomButton
            btnText={"Create an Event"}
            buttonId={"create-an-event-btn"}
            buttonVariant={"contained"}
            isDisabled={false}
            type={"button"}
            handleButtonClick={() => this.handleOpenCreateEventDialog()}
            btnStyle={btnStyle}
          />
          }
        </Box>
        <Box className={`${classes.bgColor} ${classes.tableWrapper}`}>
          {eventsList.length > 0 ? (
            <>
              <ThemeProvider theme={tableTheme}>
                <CustomTable
                  tableId={"tenant-manage-events-table"}
                  tableHeaderColumns={eventsTableHeader}
                  tableBodyData={eventsList}
                  showMenuIcon={this.state.calendarPermission.manageCalender.write}
                  actionButtonConfig={manageEventsActionBtnConfig}
                />
              </ThemeProvider>
              <Box className={classes.paginationBox}>
                <Box width={"50%"} marginTop={"20px"}>
                  <Box display={"flex"} gridGap={"6px"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18}`}
                      component="span"
                    >
                      Records
                    </Typography>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                      component="span"
                    >
                      {totalNumberOfEvents > perPageEvents
                        ? `${currentPage * perPageEvents - perPageEvents}-
                ${currentPage * perPageEvents}`
                        : `${
                            currentPage * totalNumberOfEvents -
                            totalNumberOfEvents
                          }-
                ${currentPage * totalNumberOfEvents}`}
                    </Typography>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18}`}
                      component="span"
                    >
                      of {totalNumberOfEvents}
                    </Typography>
                  </Box>
                </Box>
                {totalNumberOfEvents > perPageEvents && (
                  <Box className={classes.paginationRoot}>
                    <Pagination
                      count={Math.ceil(totalNumberOfEvents / perPageEvents)}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      siblingCount={0}
                      page={currentPage}
                      onChange={this.handlePageChange}
                    />
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <CustomEmptyResultComponent message="No Events yet." />
          )}
        </Box>
        {openCreateEventDialog && (
          <CreateEventDialog
            open={openCreateEventDialog}
            handleClose={() => this.handleCloseCreateEditEvent()}
            handleAddEvent={(data: any) => this.handleAddNewEvent(data)}
            handleUpdateEvent={(data: any) => this.handleUpdateEvent(data)}
            selectedEvent={selectedEventDetails}
            editFlag={editEventFlag}
            curriculumList={curriculumsListing}
            classesList={classesListing}
          />
        )}
        <DeleteEventDialog
          open={openDeleteEventDialog}
          onCancel={() => this.handleCloseDeleteEvent()}
          onConfirm={() => this.handleDeleteEvent()}
          title={selectedEventDetails?.event?.title}
          isEvent
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End
const btnStyle = {
  borderRadius: "12px",
  padding: "15px",
  textTransform: "none",
  fontSize: 18,
  fontWeight: 600,
} as const;
// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ManageEvents);
// Customizable Area End
