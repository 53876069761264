// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteEditEventCommanPopUpController, {
  Props,
} from "./DeleteEditEventCommanPopUpController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { DeleteIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "490px",
      },
      paperWidthSm: {
        maxWidth: "490px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class DeleteEventPopUp extends DeleteEditEventCommanPopUpController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor}`}
          aria-labelledby="delete-id"
          open={open}
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box display={"flex"} justifyContent={"center"} id="delete-id">
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={DeleteIcon} alt="DeleteIcon" />
              </Box>
              <Box width={"6%"}>
                <IconButton
                  data-test-id="pop"
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent
              style={{ padding: "0px" }}
              data-test-id="deletePopUp"
            >
              <Box textAlign={"center"} mt={"20px"} mb={"2px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                >
                  Sure want to delete event?
                </Typography>
              </Box>
              <Box textAlign={"center"} mt={"10px"} mb={"2px"}>
                <Typography
                  data-test-id="dltId"
                  className={`${classes.textCommonColor} ${classes.fontText17} ${classes.fontBold500}`}
                >
                  Are you sure want to delete event titled
                </Typography>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText17} ${classes.fontBold500}`}
                >
                  {this.props?.details?.title}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <CustomButton
                  btnText="No"
                  buttonVariant={"outlined"}
                  btnStyle={deleteCancleBtn}
                  type={"button"}
                  buttonId={`my-assessment-search-mobile`}
                  handleButtonClick={this.props.onCancel}
                  isDisabled={false}
                />
                <CustomButton
                  btnStyle={deleteNextBtn}
                  buttonVariant={"contained"}
                  type={"button"}
                  btnText="Yes,Delete"
                  handleButtonClick={this.props.onConfirm}
                  isDisabled={false}
                  buttonId={`my-assessment-search-mobile`}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const deleteCancleBtn = {
  padding: "10px 10px",
  width: "208px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
} as const;

const deleteNextBtn = {
  padding: "10px 10px",
  width: "208px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(DeleteEventPopUp);
// Customizable Area End
