// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// import { mockdataTemp } from "./assets";
import {schoolSubjectsList,subjectCoursesDetailsTableColumns,subjectTableColumns} from "./assets/mockDataTemp"
import { displayApiErrorMsg, getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { createRef } from "react";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export interface IDropdownItems {
  id: number;
  label: string;
  value: any;
}

export interface ITableProps {
  isSelected?: boolean;
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string ;
  maxWidth?: string ;
  bgColor?: string;
  
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  subjectTableColumns: ITableProps[];
  selectedHighlightBar: number[];
  handleHighlightBar: (event: any, columnId: number) => void;
  schoolSubjectsList: any[];
  handleSetSelectedSchoolSubject: (subId: any) => Promise<void>;
  selectedSubjectId: any;
  selectedSubjectCoursesGradesDetails: any[];
  totalAvgOfAllCourses: number;
  subjectCoursesDetailsTableColumns: ITableProps[];
  gradeStudenData:any
  quarters:any
  isRegistrar:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedSubjectId: any;
  schoolSubjectsList: any;
  selectedSubjectCoursesGradesDetails: any;
  subjectCoursesDetailsTableColumns: Array<ITableProps>;
  subjectTableColumns: Array<ITableProps>;
  highlightBarHeight: string;
  highlightCheck: boolean;
  selectedHighlightBar: Array<number>;
  tableHeight: any,
  rowWidth:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class GradebookGradesTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetGradebookDetails: string = "";
  apiGetSchoolYearList:string = "";
  tableRef: any;
  rowRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.tableRef = createRef();
    this.rowRef = createRef()
    // ref={rowRef}
    this.state = {
      loading: false,
      selectedSubjectId: 1,
      schoolSubjectsList: schoolSubjectsList,
      subjectTableColumns: subjectTableColumns,
      subjectCoursesDetailsTableColumns: subjectCoursesDetailsTableColumns,
      selectedSubjectCoursesGradesDetails: [],
      highlightBarHeight: "68vh",
      highlightCheck: false,
      selectedHighlightBar: [],
      tableHeight: 0,
      rowWidth:0

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const { apiRequestCallId, responseJson } = getMessageData(message);
  
        if (apiRequestCallId != null) {
          switch (apiRequestCallId) {

          }
        }
      }
  }


  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    this.setTableHeight();
    window.addEventListener('resize', this.handleResize);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevProps.selectedSubjectId !== this.props.selectedSubjectId) {
      this.setTableHeight();
    }
    if (prevProps.schoolSubjectsList !== this.props.schoolSubjectsList) {
      this.setTableHeight();
    }
    if (prevProps.selectedSubjectCoursesGradesDetails !== this.props.selectedSubjectCoursesGradesDetails) {
      this.setTableHeight();
    }
  }

  handleResize = () => {
    this.setTableHeight();
  };

  setTableHeight = () => {
    if (this.tableRef.current) {
      let height = this.tableRef.current.clientHeight - 50;
      this.setState({ tableHeight: height });
    }
    if (this.rowRef.current) {
      let width = this.rowRef.current.offsetWidth - 183;
      this.setState({ rowWidth: width });
    }
  };

  getRejectedQuartersAndMessage = (subjectItem: any, username: string) => {
    const rejectedQuarters = Object.keys(subjectItem)
      .filter(key => key.startsWith('quarterScore') && !key.endsWith('Status'))
      .filter(quarterKey => {
        const statusKey = `${quarterKey}Status`;
        return subjectItem[statusKey] === 'rejected';
      })
      .map(quarterKey => this.props.quarters[parseInt(quarterKey.replace('quarterScore', ''))-1].label || quarterKey.replace('quarterScore', '')); // Adjust if needed
  
    const rejectionMessage = rejectedQuarters.length > 0
      ? `${this.props.gradeStudenData.name}'s ${rejectedQuarters.join(', ')} submission for ${subjectItem.subject.name} has been rejected`
      : null;
  
    return rejectionMessage;
  };
 

  // Customizable Area End
}
// Customizable Area End
