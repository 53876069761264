// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
  tagsRemoveReg,
  toBase64,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  questionsList: Array<any>;
  totalPageNumber: number;
  currentPage: number;
  openAssessmentSubmitConfirmDialog: boolean;
  evaluationType: string;
  objectiveType: string;
  typeOfAssessment: string;
  assessmentTitle: string;
  activityId: any;
  studentAttemptTimeInSec: number;
  isCompleted:boolean
  shouldCallSubmitApi:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentDueAssessmentMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetQuestionsList: string = "";
  apiSubmitAnswersId: string = "";
  apiMarkAssessmentActivityAsCompleted: string = "";
  timer: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      questionsList: [],
      totalPageNumber: 1,
      currentPage: 1,
      openAssessmentSubmitConfirmDialog: false,
      typeOfAssessment: "",
      assessmentTitle: "",
      activityId: "",
      evaluationType: "",
      objectiveType: "",
      studentAttemptTimeInSec: 0,
      isCompleted:false,
      shouldCallSubmitApi:false
    };
    this.handleUpdateTime = this.handleUpdateTime.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleGetActivityDetails();
    this.handleStartTimer();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    if(!this.state.isCompleted){
      this.handleUpdateTimeTakenInActivity();
    }
    clearInterval(this.timer);
  }

  handleUpdateTime = () => {
    this.setState((state) => ({
      studentAttemptTimeInSec: state.studentAttemptTimeInSec + 1,
    }));
  };

  handleStartTimer = () => {
    this.timer = setInterval(() => {
      this.handleUpdateTime();
    }, 1000);
  };

  handleGetActivityDetails = async () => {
    const paramAssessmentType =
      this.props.navigation?.getParam("typeOfAssessment");
    const paramEvaluationType =
      this.props.navigation?.getParam("evaluationType");
    const paramObjectiveType = this.props.navigation?.getParam("objectiveType");
    const paramAssessmentTitle = this.props.navigation?.getParam("title");
    const paramActivityId = this.props.navigation?.getParam("activityId");
    this.setState(
      {
        typeOfAssessment: paramAssessmentType,
        assessmentTitle: paramAssessmentTitle,
        activityId: paramActivityId,
        evaluationType: paramEvaluationType,
        objectiveType: paramObjectiveType,
      },
      async () => {
        await this.handleGetQuestionsListing();
      }
    );
  };

  handleSelectAnswer = (questionId: any, answerId: any) => {
    const { questionsList } = this.state;
    let updatedQuestionList = [...questionsList];
    updatedQuestionList = updatedQuestionList?.map((question: any) => {
      if (question?.id === questionId) {
        question.attempted = true;
        question.answers = question?.answers?.map((answer: any) => {
          if (answer?.id === answerId) {
            answer.correct_answer = true;
          } else {
            answer.correct_answer = false;
          }
          return answer;
        });
      }
      return question;
    });
    this.setState({ questionsList: updatedQuestionList });
  };

  handleUpdateTextAnswer = (questionId: any, answer: any) => {
    const { questionsList } = this.state;
    let updatedQuestionList = [...questionsList];
    updatedQuestionList = updatedQuestionList?.map((question: any) => {
      if (question?.id === questionId && answer?.trim()) {
        question.attempted = true;
        question.textAnswer = answer;
      }
      return question;
    });
    this.setState({ questionsList: updatedQuestionList });
  };

  handleUpdateAnswerAttachment = (
    questionId: any,
    answerAttachmentFiles: Array<any>
  ) => {
    const { questionsList } = this.state;
    let updatedQuestionList = [...questionsList];
    updatedQuestionList = updatedQuestionList?.map((question: any) => {
      if (question?.id === questionId) {
        question.attachments = [
          ...question?.attachments,
          ...answerAttachmentFiles,
        ];
        question.attempted = true;
      }
      return question;
    });
    this.setState({ questionsList: updatedQuestionList });
  };

  handleRemoveAnswerAttachment = (questionId: any, answerAttachmentId: any) => {
    const { questionsList } = this.state;
    let updatedQuestionList = [...questionsList];
    updatedQuestionList = updatedQuestionList?.map((question: any) => {
      if (question?.id === questionId) {
        question.attachments = question?.attachments?.filter(
          (item: any, index: number) => index !== answerAttachmentId
        );
      }
      return question;
    });
    this.setState({ questionsList: updatedQuestionList });
  };

  handleOpenAttachmentLink = (url: string) => {
    window.open(url, "_blank");
  };

  handleGoBack = () => {
    this.props.navigation?.goBack();
  };

  handleOpenAssessmentSubmitDialog = () => {
    this.setState({ openAssessmentSubmitConfirmDialog: true });
  };

  handleCloseAssessmentSubmitDialog = () => {
    this.setState({ openAssessmentSubmitConfirmDialog: false });
  };

  handleCheckAllQuestionsAttempted = (index: number) => {
    const { questionsList } = this.state;
    const isAttempted = questionsList[index]?.attempted;
    return isAttempted;
  };

  handleSetQuestionsItem = (item: any) => {
    const optionsList: Array<any> = [];
    item?.attributes?.options.forEach((option: any, index: number) => {
      const obj = {
        id: option?.id,
        option: option?.text,
        correct_answer: false,
      };
      optionsList.push(obj);
    });
    const questionObj = {
      id: item?.id,
      question: item?.attributes?.question,
      answers: [...optionsList],
      attachments: [],
      textAnswer: "",
      attempted: false,
      wordLimit: parseInt(item?.attributes?.word_limit) || 0,
    };
    return questionObj;
  };

  handleCheckWordCountLimit = (questionId: string) => {
    const { questionsList } = this.state;
    const currentQuestion = questionsList.find(
      (question: any) => question.id === questionId
    );
    const textAnswerWithoutTags = currentQuestion.textAnswer.replaceAll("&nbsp;", " ").replace(tagsRemoveReg,
      ""
    );
    const words = textAnswerWithoutTags
      ?.trim()
      .split(/\s+/)
      .filter((word: string) => word.length > 0);
    return currentQuestion.wordLimit
      ? words?.length > currentQuestion?.wordLimit
      : false;
  };

  handleSetQuestionList = (list: any) => {
    const questionsData = list?.map((item: any) =>
      this.handleSetQuestionsItem(item)
    );
    this.setState({ questionsList: [...questionsData] });
  };

  handleGetQuestionsListDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetQuestionList(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetQuestionsListing = async () => {
    showCustomLoader();
    const { activityId } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetQuestionsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionEndpoint}?id=${activityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetAttachmentsList = async (
    attachments: Array<{
      filename: string;
      content_type: string;
      file: any;
      fileSrc: string;
    }>
  ) => {
    const attachmentsList: Array<any> = attachments?.map(
      async (file: {
        filename: string;
        content_type: string;
        file: any;
        fileSrc: string;
      }) => {
        const media = await toBase64(file.file);
        return {
          content_type: file.content_type,
          filename: file.filename,
          media_file: media,
        };
      }
    );
    return Promise.all(attachmentsList);
  };

  handleUpdateTimeTakenInActivity = async (isCompleted?: boolean) => {
    const { activityId, studentAttemptTimeInSec } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const requestBody: {
      activity_id: number;
      time_spent: number;
      is_completed?: boolean;
    } = {
      activity_id: parseInt(activityId),
      time_spent: studentAttemptTimeInSec || 0,
    };

    if (isCompleted) {
      requestBody.is_completed = true;
      this.setState({ studentAttemptTimeInSec: 0 });
      clearInterval(this.timer);
    }

    this.apiMarkAssessmentActivityAsCompleted = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.studentMarkActivityCompleted}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleAssessmentActivityAsCompletedResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      if(this.state.shouldCallSubmitApi){
        this.handleSubmitAnswersApi()
      }
    } else {
      toast.error(
        TOASTER_NOTIFICATION_MSG.STUDENT_SUBMIT_ASSESSMENT_ANSWER_FAILURE
      );
    }
  };
  handleCheckAllAnswersWithinWordLimit = () => {
    let isAllQuestionWithinLimit = true;
    const { questionsList } = this.state;
    questionsList
      .filter((question: any) => (question?.attempted && question?.wordLimit))
      .forEach((item: any) => {
        isAllQuestionWithinLimit = !this.handleCheckWordCountLimit(item?.id);
      });
    return !isAllQuestionWithinLimit;
  };

  handleSubmitAnswers = async () => {
    showCustomLoader();
    this.setState({
      shouldCallSubmitApi:true
    },async ()=>{
      await this.handleUpdateTimeTakenInActivity(true);
    })
    
  };
  handleSubmitAnswersApi = async()=>{
    const { questionsList, evaluationType, typeOfAssessment, objectiveType } =
    this.state;
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": "application/json",
    };

    let questionAnswerList: Array<any> = [];

    const correctAnswer = (options: any) => {
      const findCorrectAnswer = options?.find(
        (item: any) => item?.correct_answer
      );
      return findCorrectAnswer?.option || "";
    };

    if (
      evaluationType === "objective" &&
      objectiveType !== "written" &&
      typeOfAssessment !== "project"
    ) {
      questionAnswerList = questionsList?.map((question: any) => {
        return {
          question_id: question?.id,
          answer: correctAnswer(question?.answers),
        };
      });
    } else {
      const questionAnsList = questionsList?.map(async (question: any) => {
        return {
          question_id: question?.id,
          answer: question?.textAnswer,
          attachments: await this.handleGetAttachmentsList(
            question?.attachments
          ),
        };
      });
      questionAnswerList = await Promise.all(questionAnsList);
    }

    const requestBody = {
      student_answers: [...questionAnswerList],
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubmitAnswersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.submitAnswersApiEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmitAnswerResponse = (responseJson: any, errorResponse: any) => {
    this.handleCloseAssessmentSubmitDialog();
    if (responseJson?.data) {
      toast.success(
        TOASTER_NOTIFICATION_MSG.STUDENT_SUBMIT_ASSESSMENT_ANSWER_SUCCESS
      );
      this.setState({isCompleted:true,shouldCallSubmitApi:false})
      this.handleGoBack();
    } else {
      toast.error(
        TOASTER_NOTIFICATION_MSG.STUDENT_SUBMIT_ASSESSMENT_ANSWER_FAILURE
      );
    }
    hideCustomLoader();
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetQuestionsList:
            this.handleGetQuestionsListDataResponse(
              responseJson,
              errorResponse
            );
            break;
          case this.apiSubmitAnswersId:
            this.handleSubmitAnswerResponse(responseJson, errorResponse);
            break;
          case this.apiMarkAssessmentActivityAsCompleted:
            this.handleAssessmentActivityAsCompletedResponse(responseJson, errorResponse);
            break;
        }
      }
      // Customizable Area Start

      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
