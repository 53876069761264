// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  IInputConfigProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
} from "../../../components/src/CommonType.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import {
  displayApiErrorMsg,
  getFieldType,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import moment from "moment";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";

export const configJSON = require("./config");
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openNewClassManagementDialog: boolean;
  handleCloseNewClassManagementDialog: any;
  resetCSVTrigger?:any;
  triggerCSVAPIs?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  classTitleMenuList: Array<IDropdownMenuItemProps>;
  classTitleList: Array<any>;
  selectedClassTitle: any;
  selectGrade: any;
  selectGradeItems: Array<IDropdownMenuItemProps>;
  selectSchoolYear: any;
  selectSchoolYearItems: Array<IDropdownMenuItemProps>;
  selectSubjectsBasedOnGrade: Array<any>;
  selectSubjectsBasedOnGradeItems: Array<IDropdownMenuItemProps>;
  selectCurriculumsBasedOnGradeSubject: any;
  selectCurriculumsBasedOnGradeSubjectItems: Array<IDropdownMenuItemProps>;
  classesTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  customFieldsList: Array<any>;
  classesCustomFieldConfigList: Array<IInputConfigProps>;
  allStudentsList: Array<any>;
  classManagementTableHeader: Array<any>;
  availableStudentsList: Array<any>;
  selectedStudentsList: Array<any>;
  removedStudentsList: Array<any>;
  selectCustomField: any;
  selectCustomFieldItems: Array<IDropdownMenuItemProps>;
  openEditClassManagementDialog: boolean;
  currentPage: number;
  perPageRecords: number;
  totalNumberOfClassRecords: number;
  openDeleteClassDialog: boolean;
  showLoaderInDialog: boolean;
  selectedClassDetails: any;
  selectedRemovedStudent: any;
  openRemoveStudentConfirmPopup: boolean;
  classDetailsFromStep1: any;
  selectedGradeId:any;
  selectedCurriculumId:any;
  classManagementPermission:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminSetupClassManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetClassesListing: string = "";
  apiPostClassDetails: string = "";
  apiUpdateClassDetails: string = "";
  apiDeleteClassDetails: string = "";
  apiGetAvailableStudentsListing: string = "";
  apiGetEditSelectedStudentListingId:string=""
  apiGetSchoolYearList: string = "";
  apiGradesListing: string = "";
  apiGetCurriculumsListing: string = "";
  apiGetSubjectsListing: string = "";
  apiGetCustomFields: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      selectedClassTitle: "",
      classTitleList: [],
      classTitleMenuList: [],
      selectGrade: "",
      selectGradeItems: [],
      selectSchoolYear: "",
      selectSchoolYearItems: [],
      selectCurriculumsBasedOnGradeSubject: "",
      selectCurriculumsBasedOnGradeSubjectItems: [],
      selectSubjectsBasedOnGrade: [],
      selectSubjectsBasedOnGradeItems: [],
      selectCustomField: "",
      selectCustomFieldItems: [{ id: 1, value: "Option 1", label: "Option 1" }],
      classesTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenEditClassPopup(value),
        },
        {
          label: "Delete",
          action: (value: any) => this.handleOpenClassDeleteConfirmModal(value),
        },
      ],
      customFieldsList: [],
      classesCustomFieldConfigList: [],
      classManagementTableHeader: [
        {
          id: "className",
          columnId: 1,
          label: "Class Name",
          type: tableColumnTypes.TEXT,
          width: "150px",
          maxWidth: "180px",
        },
        {
          id: "noOfStudents",
          columnId: 2,
          label: "No Of Students",
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
          width: "150px",
          maxWidth: "180px",
        },
        {
          id: "grade",
          columnId: 3,
          label: "Grade",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "curriculum",
          columnId: 4,
          label: "Curriculum",
          type: tableColumnTypes.TEXT,
          width: "250px",
          maxWidth: "280px",
        },
        {
          id: "schoolYearTitle",
          columnId: 6,
          label: "School Year Title",
          type: tableColumnTypes.TEXT,
          width: "150px",
          maxWidth: "150px",
        },
        {
          id: "createdOn",
          columnId: 7,
          label: "Created On",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "modifiedOn",
          columnId: 8,
          label: "Modified On",
          type: tableColumnTypes.TEXT,
        },
        {
          id: "status",
          columnId: 9,
          label: "Status",
          type: tableColumnTypes.CHIP,
        },
      ],
      allStudentsList: [],
      availableStudentsList: [],
      selectedStudentsList: [],
      removedStudentsList: [],
      openEditClassManagementDialog: false,
      currentPage: 1,
      perPageRecords: 10,
      totalNumberOfClassRecords: 0,
      openDeleteClassDialog: false,
      showLoaderInDialog: false,
      selectedClassDetails: "",
      selectedRemovedStudent: "",
      openRemoveStudentConfirmPopup: false,
      classDetailsFromStep1: "",
      selectedGradeId:-1,
      selectedCurriculumId:-1,
      classManagementPermission:{
        classManagement:{
          read: true,
          write: true
        }
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  extractClassManagementData=(parseData:any)=>{
    return {
      classManagement: parseData?.class_management?.can,
    };
  }
  async handleGetClassManagementRoleAndPermission(){
    const getClassManagementPermission = await getStorageData("roleandpermission")
    if(getClassManagementPermission){ 
      try {
        const parseData = JSON.parse(getClassManagementPermission);
        if(parseData){
          const result = this.extractClassManagementData(parseData);
          this.setState({classManagementPermission:result})
        }
      } catch (error) {
        console.error("Failed to parse classManagementPermission. It might not be valid JSON.", error);
      }
    }
  }
  handleChangeDropDownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderClassName = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Class";
    }
    const { classTitleMenuList } = this.state;
    const selectedClass = classTitleMenuList.find(
      (classItem: any) => classItem?.value === selected
    );
    return selectedClass?.label;
  };

  renderGrade = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { selectGradeItems } = this.state;
    const selectedGrade = selectGradeItems.find(
      (gradeItem: any) => gradeItem?.value === selected
    );
    return selectedGrade?.label;
  };

  renderSchoolYear = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { selectSchoolYearItems } = this.state;
    const selectedSchoolYear = selectSchoolYearItems.find(
      (schoolYearItem: any) => schoolYearItem?.value === selected
    );
    return selectedSchoolYear?.label;
  };

  handleCloseClassManagementDialog = () => {
    this.setState({
      openEditClassManagementDialog: false,
    });
    this.props.handleCloseNewClassManagementDialog();
  };

  handleOpenEditClassPopup = (value: any) => {
    const { classTitleList } = this.state;
    const selectedClassDetails = classTitleList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );

    this.setState(
      {
        selectedClassDetails,
      },
      () => {
        this.setState({ openEditClassManagementDialog: true });
      }
    );
  };

  handleSetDropdownMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.name,
      };
    });
    return menuItems;
  };

  handleSetSchoolYearDropdownMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.title,
      };
    });
    return menuItems;
  };

  handleSetClassesDataItem = (item: any) => {
    let classDataItemObj: any = {
      id: item?.id,
      className: item?.attributes?.name,
      noOfStudents: item?.attributes?.no_of_students,
      grade: item?.attributes?.grade?.name,
      gradeId: item?.attributes?.grade?.id?.toString(),
      curriculum: item?.attributes?.curriculum?.name,
      curriculumId: item?.attributes?.curriculum?.id?.toString(),
      customField: item?.attributes?.custom_fields?.data?.map((item: any) => {
        return {
          id: item?.id,
          value: item?.attributes?.value,
          label: item?.attributes?.label,
        };
      }),
      subjectIds: item?.attributes?.subject?.map((subject: any) => {
        return subject?.id?.toString();
      }),
      subjects: item?.attributes?.subject?.map((item: any) => {
        return item?.name;
      }),
      schoolYearTitle: item?.attributes?.school_year?.title,
      schoolYearId: item?.attributes?.school_year?.id?.toString(),
      createdOn: moment(item?.attributes?.created_at).format("MMM DD, YYYY"),
      modifiedOn: moment(item?.attributes?.updated_at).format("MMM DD, YYYY"),
      status: item?.attributes?.status,
    };
    item?.attributes?.custom_fields?.data?.forEach((data: any) => {
      classDataItemObj[data?.attributes?.label] = data?.attributes?.value;
    });
    return classDataItemObj;
  };

  handleSetClassesData = (list: any) => {
    const classesData = list?.map((item: any) =>
      this.handleSetClassesDataItem(item)
    );
    console.log("class list", classesData);
    this.setState({ classTitleList: classesData });
  };

  handleSetClassesDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const classesList = this.handleSetDropdownMenuItem(responseJson?.data);
      this.setState({
        classTitleMenuList: classesList || [],
      });
    }
  };

  handleGetClassesDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetClassesData(responseJson.data);
      this.handleSetClassesDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetClassesList = async (isFilterApplied?: boolean) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const {
      selectGrade,
      selectSchoolYear,
      selectedClassTitle,
      perPageRecords,
      currentPage,
      classesCustomFieldConfigList,
    } = this.state;
    let queryString = "";
    let page = currentPage;
    if (isFilterApplied) {
      this.setState({ currentPage: 1 });
      page = 1;
    }
    let classFetchEndpoint = configJSON.classManagementEndpoint;
    if (selectGrade || selectSchoolYear || selectedClassTitle) {
      queryString = `&grade_id=${selectGrade}&school_year_id=${selectSchoolYear}&class_id=${selectedClassTitle}`;
    }
    let index = 1;
    if(classesCustomFieldConfigList?.length) {
      classesCustomFieldConfigList.forEach((customField) => {
        if(customField?.value) {
          queryString += `&custom_field_${index}_value=${customField.value}&custom_field_${index}_id=${customField?.id}`
          index += 1;
        }
      })
    }
    classFetchEndpoint = `${classFetchEndpoint}?page=${page}&per_page=${perPageRecords}${queryString}`;
    this.apiGetClassesListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      classFetchEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddNewClass = async (isSaveAsDraft?: boolean) => {
    this.props.handleCloseNewClassManagementDialog();
    showCustomLoader();
    const { classDetailsFromStep1, selectedStudentsList, removedStudentsList } =
      this.state;
    const requestBody: any = {
      data: {
        attributes: {
          name: classDetailsFromStep1?.title,
          subject_id: classDetailsFromStep1?.subjects,
          student_id: selectedStudentsList?.map((student: any) => {
            return student?.id;
          }),
          remove_student_id: removedStudentsList?.map((student: any) => {
            return student?.id;
          }),
          grade_id: classDetailsFromStep1?.grade,
          curriculum_id: classDetailsFromStep1?.curriculum,
          school_year_id: classDetailsFromStep1?.schoolYear,
          class_management_custom_fields_attributes:
            classDetailsFromStep1?.classesCustomAttributes || [],
          status: isSaveAsDraft ? "Draft" : "Active",
        },
      },
    };
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostClassDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.classManagementEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateClass = async (isSaveAsDraft?: boolean) => {
    this.handleCloseClassManagementDialog();
    showCustomLoader();
    const token = await getStorageData("token");
    const { selectedClassDetails } = this.state;
    const { classDetailsFromStep1, selectedStudentsList, removedStudentsList } =
      this.state;
    const editRequestBody = {
      data: {
        attributes: {
          name: classDetailsFromStep1?.title,
          subject_id: classDetailsFromStep1?.subjects,
          student_id: selectedStudentsList?.map((student: any) => {
            return student?.id;
          }),
          remove_student_id: removedStudentsList?.map((student: any) => {
            return student?.id;
          }),
          grade_id: classDetailsFromStep1?.grade,
          curriculum_id: classDetailsFromStep1?.curriculum,
          school_year_id: classDetailsFromStep1?.schoolYear,
          class_management_custom_fields_attributes:
            classDetailsFromStep1?.classesCustomAttributes || [],
          status: isSaveAsDraft ? "Draft" : "Active",
        },
      },
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateClassDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.classManagementEndpoint}/${selectedClassDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(editRequestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteClass = async () => {
    this.handleCloseDeleteClassConfirmModal();
    showCustomLoader();
    const { selectedClassDetails } = this.state;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteClassDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.classManagementEndpoint}/${selectedClassDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteClassResponse = (responseJson: any) => {
    if (responseJson) {
      toast.success(TOASTER_NOTIFICATION_MSG.CLASS_DELETE_SUCCESS);
      this.handleGetClassesList();
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.CLASS_DELETE_FAILURE);
    }
  };

  handleOpenClassDeleteConfirmModal = (value?: any) => {
    const { classTitleList } = this.state;
    const selectedClass = classTitleList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedClassDetails: selectedClass,
      },
      () => {
        this.setState({ openDeleteClassDialog: true });
      }
    );
  };

  handleCloseDeleteClassConfirmModal = () => {
    this.setState({ openDeleteClassDialog: false });
  };

  handlePostClassDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.CLASS_CREATION_SUCCESS);
      this.handleGetClassesList();
      this.setState({
        selectedStudentsList: [],
        availableStudentsList: [],
        allStudentsList: [],
        removedStudentsList: [],
      });
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
  };

  handleUpdateClassDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data?.attributes) {
      this.handleGetClassesList();
      this.setState({
        selectedStudentsList: [],
        availableStudentsList: [],
        allStudentsList: [],
        removedStudentsList: [],
      });
      toast.success(TOASTER_NOTIFICATION_MSG.CLASS_UPDATE_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };

  handleGetClassCustomFieldDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data?.length) {
        this.setState({ customFieldsList: responseJson.data });
        const customFields: Array<IInputConfigProps> = responseJson.data.map(
          (item: any, index: number) => {
            const obj = {
              id: item.id,
              name: `class-management-customField-${index + 1}`,
              fieldName: `class-management-customField-${index + 1}`,
              value: "",
              label: item?.attributes?.label,
              placeHolderText: `Please enter ${item?.attributes?.label}`,
              fieldType: getFieldType(item?.attributes?.custom_field_type),
              isDisabled: false,
            };
            return obj;
          }
        );
        const customFieldHeaders = customFields.map((item, index: number) => {
          return {
            id: item?.label,
            columnId: `${item?.id}-${item?.label}`,
            label: item?.label,
            type: tableColumnTypes.TEXT,
          };
        });
        this.setState({
          classesCustomFieldConfigList: customFields,
          classManagementTableHeader: [
            ...this.state.classManagementTableHeader,
            ...customFieldHeaders,
          ],
        });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.handleGetClassesList();
  };

  handleGetCustomFieldsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomFields = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFieldEndpoint}?model_type=Class_Management`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetSchoolYearDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const schoolYearDDList = this.handleSetSchoolYearDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        selectSchoolYearItems: schoolYearDDList || [],
      });
    }
  };

  handleGetSchoolYearDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetSchoolYearDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };

  handleGetSchoolYearList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYearDropdownData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetGradesDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const gradesDDMenuList = this.handleSetDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        selectGradeItems: gradesDDMenuList || [],
      });
    }
  };

  handleGetGradesFilterDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetGradesDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };

  handleGetGradesList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGradesListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesDropdownData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetCurriculumBasedOnGradeSubjectDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const curriculumList = this.handleSetDropdownMenuItem(responseJson?.data);
      this.setState({
        selectCurriculumsBasedOnGradeSubjectItems: curriculumList || [],
        showLoaderInDialog: false,
      });
    }
  };

  handleGetCurriculumDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetCurriculumBasedOnGradeSubjectDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInDialog: false });
  };

  handleGetCurriculumsList = async (gradeId?: any, subjectIds?: any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInDialog: true });
    let queryString = "";
    let curriculumFetchEndpoint = configJSON.getCurriculumDropdownData;
    queryString = `?grade_id=${gradeId}`;
    if (subjectIds.length > 0) {
      queryString += `&subject_id=[${subjectIds}]`;
    }
    curriculumFetchEndpoint = `${curriculumFetchEndpoint}${queryString}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumsListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      curriculumFetchEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetSubjectsAsPerGradeDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const subjectListAsPerGrade = this.handleSetDropdownMenuItem(
        responseJson?.data
      );
      this.setState({
        selectSubjectsBasedOnGradeItems: subjectListAsPerGrade || [],
        showLoaderInDialog: false,
      });
    }
  };

  handleGetSubjectsAsPerGradeDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetSubjectsAsPerGradeDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInDialog: false });
  };

  handleGetSubjectsList = async (gradeId?: any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInDialog: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let queryString = "";
    let subjectFetchEndpoint = configJSON.getSubjectsDropdwonData;
    if (gradeId) {
      queryString = `?grade_id=${gradeId}`;
      subjectFetchEndpoint = subjectFetchEndpoint + queryString;
    }
    this.apiGetSubjectsListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      subjectFetchEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetStudentListItem = (item: any) => {
    let studentItemObj = {
      id: item?.id,
      student: {
        name: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
        icon: item?.attributes?.profile_url || "",
      },
      gradeId: item?.attributes?.grade?.[0]?.id,
      grade: item?.attributes?.grade?.[0]?.name,
      curriculum: item?.attributes?.curriculum?.name,
      curriculumId: item?.attributes?.curriculum?.id,
      customField: "NA",
      // selectedForClass: item?.attributes?.class_status === "selected",
      selectedForClass:false
    };
    return studentItemObj;
  };

  handleSetAvailableStudentList = (availableList: any) => {
    const availableStudents = availableList?.map((item: any) => {
      let actionButton: any = {
        btnStyle: {
          height: "50px",
          padding: "10px",
          fontSize: "16px",
          backgroundColor: "#00c996",
          color: "#ffffff",
          borderRadius: "10px",
          width: "160px",
          textAlign: "center",
          fontWeight: 600,
        },
        btnText: "Add",
        btnId: "tenant-class-student-add",
        isDisabled: false,
        btnVariant: "contained",
        btnType: "button",
        itemId: item?.id,
        handleAction: (value?: any) =>
          this.handleAddStudentToSelectedList(value),
      };
      return { ...item, actionButton };
    });
    this.setState({ availableStudentsList: availableStudents });
  };

  handleSetSelectedStudentList = (selectedStudents: any) => {
    const selectedStudentsArr = selectedStudents?.map((item: any) => {
      let actionButton: any = {
        btnText: "Remove",
        btnStyle: {
          height: "50px",
          border: "1px solid #FF0000",
          width: "160px",
          color: "#FF0000",
          padding: "10px",
          borderRadius: "10px",
          fontSize: "16px",
          fontWeight: 600,
          backgroundColor: "inherit",
          textAlign: "center",
        },
        btnId: "tenant-class-student-remove",
        isDisabled: false,
        btnVariant: "outlined",
        btnType: "button",
        itemId: item?.id,
        handleAction: (value?: any) => this.handleConfirmRemoveStudent(value),
      };
      return { ...item, actionButton };
    });
    this.setState({ selectedStudentsList: selectedStudentsArr });
  };

  handleGetStudents=(gradeId: any, curriculumId: any)=>{
    if(this.state.openEditClassManagementDialog){
      this.setState({selectedGradeId:gradeId,selectedCurriculumId:curriculumId},()=>{
        this.handleFetchEditSelectedStudents()
      })
    }else{
      this.setState({selectedStudentsList:[]},()=>{
        this.handleFetchStudents(gradeId, curriculumId);
      })
     
    }
  }

  handleSetAvailableSelectedStudentsList = (list: any) => {
    const allStudents = list?.map((item: any) => {
      const studentObj = this.handleSetStudentListItem(item);
      return { ...studentObj };
    });
    const availableStudents = allStudents?.filter(
      (student: any) => !student?.selectedForClass
    );
    if(this.state.openEditClassManagementDialog){
      const {selectedStudentsList}  = this.state
      const updatedAvailableStudentsList = availableStudents?.filter(
          (availableStudent: any) => 
            !selectedStudentsList.some((student: any) => student.id === availableStudent.id)
        );
      this.handleSetAvailableStudentList(updatedAvailableStudentsList);
    }else{
      this.handleSetAvailableStudentList(availableStudents);
    }
    this.setState({
      allStudentsList: [...allStudents],
    });
  };

  handleStudentListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetAvailableSelectedStudentsList(responseJson.data);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInDialog: false });
  };

  handleSetEditSelectedStudentsList = (list: any) => {
    const allStudents = list?.map((item: any) => {
      const studentObj = this.handleSetStudentListItem(item);
      return { ...studentObj };
    });
    this.handleSetSelectedStudentList(allStudents);
  };
  handleEditStudentListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetEditSelectedStudentsList(responseJson.data);
      this.handleFetchStudents(this.state.selectedGradeId,this.state.selectedCurriculumId)
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.setState({ showLoaderInDialog: false });
  };

  handleFetchStudents = async (gradeId: any, curriculumId: any) => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInDialog: true });
    let queryString = "";
    let fetchStudentEndpoint = configJSON.fetchStudentsEndpointNew;
    queryString = `?grade_id=[${gradeId}]&curriculum_id=${curriculumId}`;
    fetchStudentEndpoint = `${fetchStudentEndpoint}${queryString}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAvailableStudentsListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      fetchStudentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleFetchEditSelectedStudents = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };
    this.setState({ showLoaderInDialog: true });
    let queryString = "";
    let fetchStudentEndpoint = configJSON.fetchStudentsEndpointNew;
    queryString = `?class_id=${this.state.selectedClassDetails.id}`;
    fetchStudentEndpoint = `${fetchStudentEndpoint}${queryString}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEditSelectedStudentListingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      fetchStudentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAddStudentToSelectedList = (studentId: any) => {
    const { availableStudentsList, allStudentsList, selectedStudentsList } =
      this.state;
    const availableStudentList = availableStudentsList?.filter(
      (student: any) => student.id !== studentId
    );
    const selectedStudent = allStudentsList?.find(
      (student: any) => student?.id === studentId
    );
    const selectedStudents = [
      ...selectedStudentsList,
      { ...selectedStudent, selectedForClass: true },
    ];
    this.handleSetAvailableStudentList(availableStudentList);
    this.handleSetSelectedStudentList(selectedStudents);
  };

  handleConfirmRemoveStudent = (studentId: any) => {
    const { allStudentsList } = this.state;
    const removedStudent = allStudentsList?.find(
      (student: any) => student?.id === studentId
    );
    this.setState(
      {
        selectedRemovedStudent: removedStudent,
      },
      () => {
        this.setState({ openRemoveStudentConfirmPopup: true });
      }
    );
  };

  handleCloseRemoveStudentConfirmPopup = () => {
    this.setState({
      openRemoveStudentConfirmPopup: false,
      selectedRemovedStudent: "",
    });
  };

  handleRemoveStudentFromSelectedList = () => {
    const {
      availableStudentsList,
      allStudentsList,
      selectedStudentsList,
      selectedRemovedStudent,
      removedStudentsList,
    } = this.state;
    const updatedSelectedStudentsList = selectedStudentsList?.filter(
      (student: any) => student.id !== selectedRemovedStudent?.id
    );
    const removedStudent = allStudentsList?.find(
      (student: any) => student?.id === selectedRemovedStudent?.id
    );
    const removedStudentList = [...removedStudentsList, { ...removedStudent }];
    const availableStudents = [
      ...availableStudentsList,
      { ...removedStudent, selectedForClass: false },
    ];
    this.setState({ removedStudentsList: removedStudentList });
    this.handleSetAvailableStudentList(availableStudents);
    this.handleSetSelectedStudentList(updatedSelectedStudentsList);
    this.handleCloseRemoveStudentConfirmPopup();
  };

  handleUpdateAddedClassDetails = (details: any) => {
    this.setState({ classDetailsFromStep1: details });
  };

  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page }, () => {
      this.handleGetClassesList();
    });
  };

  handleClassCustomFieldTextChange = (event: any) => {
    const { classesCustomFieldConfigList } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = classesCustomFieldConfigList.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        classesCustomFieldConfigList: updatedInputConfig,
      };
    });
  };

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleFetchAllApis()
    // Customizable Area End
  }
  // Customizable Area Start
  handleFetchAllApis=()=>{
    this.handleGetClassManagementRoleAndPermission()
    this.handleGetCustomFieldsList();
    this.handleGetGradesList();
    this.handleGetSchoolYearList();
  }
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(this.props?.triggerCSVAPIs){
      this.props?.resetCSVTrigger();
      this.handleFetchAllApis();
    }
  }
  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetClassesListing:
            {
              if (responseJson?.meta?.pagination?.total_count) {
                this.setState({
                  totalNumberOfClassRecords: responseJson.meta.pagination.total_count,
                });
              }
              this.handleGetClassesDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetSubjectsListing:
            {
              this.handleGetSubjectsAsPerGradeDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGradesListing:
            {
              this.handleGetGradesFilterDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetSchoolYearList:
            {
              this.handleGetSchoolYearDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetCurriculumsListing:
            {
              this.handleGetCurriculumDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiPostClassDetails:
            {
              this.handlePostClassDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiDeleteClassDetails:
            {
              this.handleDeleteClassResponse(responseJson);
            }
            break;
          case this.apiUpdateClassDetails:
            {
              this.handleUpdateClassDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetAvailableStudentsListing:
            {
              this.handleStudentListResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetEditSelectedStudentListingId:{
            this.handleEditStudentListResponse(responseJson,errorResponse)
          }
          break;
          case this.apiGetCustomFields:
            {
              this.handleGetClassCustomFieldDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
