// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Input } from '@material-ui/core';
import { customThemeStyles } from '../../CustomThemeStyles.web';
import { combineStyles } from '../../CombineStyles.web';
import  CustomTable, { tableColumnTypes }  from '../../CustomTable.web';
import BehaviourTableController,{Props} from './BehaviourTableController';


// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) => 
    createStyles({

    })

// Customizable Area End

// Customizable Area Start
const AttandanceTableHead = [
    {
        type: tableColumnTypes.TEXT,
        columnId: 1,
        id: "title",
        label: "Desirable Behaviors",
        width: "100%",
        maxWidth:  "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 2,
        id: "qtr1",
        label: "1st Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 3,
        id: "qtr2",
        label: "2nd Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 4,
        id: "qtr3",
        label: "3rd Qtr",
        width: "100%",
        maxWidth: "100%",
    },
    {
        type: tableColumnTypes.NUMBER,
        columnId: 5,
        id: "qtr4",
        label: "4th Qtr",
        width: "100%",
        maxWidth:  "100%",
    },
]
const AttandanceTableData=[
    {
        id:1,
        title:"Focused Attention",
        qtr1:86,
        qtr2:95,
        qtr3:74,
        qtr4:0,
    },
    {
        id:2,
        title:"Focused Obedience",
        qtr1:83,
        qtr2:0,
        qtr3:0,
        qtr4:0,
    },
    {
        id:1,
        title:"Truthfulness",
        qtr1:92,
        qtr2:0,
        qtr3:0,
        qtr4:0,
    }
]
export class BehaviourTable extends  BehaviourTableController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes} = this.props;
        const {
         
        } = this.state;
        return (
            <Box style={{width:"100%"}}>
                <Box style={{paddingTop:"14px",paddingBottom:"14px"}}>
                  <Box style={{display:'flex',justifyContent:"space-between",padding:"16px"}}>
                <Box>
                <Typography className={`${classes.textPrimaryColor}`}>
                Behaviors
                </Typography>
                </Box>
                </Box>
                <Box>
                <CustomTable
                    tableId={"tenant-setup-teacher-assignment-table"}
                    tableHeaderColumns={this.props.behaviourTableHead.length>0?this.props.behaviourTableHead:AttandanceTableHead}
                    tableBodyData={this.props.behaviourTableData.length>0?this.props.behaviourTableData:AttandanceTableData} 
                    showMenuIcon={false}          
                />
                </Box>
                </Box>
            </Box>
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    height: "50px",
    padding: "10px 10px",
    fontSize: "18px",
    width: "120px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    border: "1px solid",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(BehaviourTable);
// Customizable Area End
