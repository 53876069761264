// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  permissionsListMock,
  roleDetailsInputConfigMock,
  rolePermissionsListMock,
  titleMap,
} from "../assets/tenantRolesAndPermissionsMock.web";
import {
  FIELD_TYPES,
  IInputConfigProps,
} from "../../../components/src/CommonType.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData, hideCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  roleTitle: string;
  permissionsList: Array<any>;
  permissionsInputConfig: Array<IInputConfigProps>;
  isEdit: boolean;
  isView: boolean;
  roleId: string;
  roleFromUrl: string;
  openDeleteConfirmModal: boolean;
  paramsRoleId:any;
  roleAndPermissionViewState:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AddEditTenantRolesAndPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createRoleAndPermissionCallId:string="";
  apiGetRoleDetailsCallId:string="";
  apiDeleteRoleCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      roleTitle: "",
      permissionsList: permissionsListMock,
      isEdit: false,
      isView: false,
      roleFromUrl: "",
      roleId: "",
      permissionsInputConfig: roleDetailsInputConfigMock,
      openDeleteConfirmModal: false,
      paramsRoleId:"",
      roleAndPermissionViewState:{
        rolesCreation:{
          read:true,
          write:true
        }
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetRoleDetails();
    this.handleGetViewRoleAndPermission()
    // Customizable Area End
  }

  // Customizable Area Start
  renderExtractViewRoleAndPermission=(parseData:any)=>{
    return {
      rolesCreation: parseData?.roles_creation?.can,
    };
  }
  async handleGetViewRoleAndPermission(){
    const getMyRequest = await getStorageData("roleandpermission")
    if(getMyRequest){  
      try {
        const parseData = JSON.parse(getMyRequest);
        if(parseData){
          const result = this.renderExtractViewRoleAndPermission(parseData)
          this.setState({
            roleAndPermissionViewState:result
          })
      }
      } catch (error) {
        console.error("Failed to parse myRequest permission. It might not be valid JSON.", error);
      }
    }
  }
  handleGoBackToMainScreen = () => {
    this.props.navigation?.navigate("TenantRolesAndPermissions");
  };

  convertPermissions = (permissions: any[]) => {
    const permittedModules:any = {};
    const titleMapArr:any = titleMap
    permissions.forEach((category: { subItems: any[]; subCategory: any[]; }) => {
      category.subItems.forEach((subItem: { subItemTitle: string | number; isReadChecked: any; isWriteChecked: any; }) => {
        const mappedTitle = titleMapArr[subItem.subItemTitle];
        if (mappedTitle) {
          permittedModules[mappedTitle] = {
            can: {
              read: subItem.isReadChecked,
              write: subItem.isWriteChecked,
            },
          };
        }
      });
  
      category.subCategory.forEach((subCategory: { subItems: any[]; }) => {
        subCategory.subItems.forEach((subItem: { subItemTitle: string | number; isReadChecked: any; isWriteChecked: any; }) => {
          const mappedTitle = titleMapArr[subItem.subItemTitle];
          if (mappedTitle) {
            permittedModules[mappedTitle] = {
              can: {
                read: subItem.isReadChecked,
                write: subItem.isWriteChecked,
              },
            };
          }
        });
      });
    });
  
    return { permitted_modules: permittedModules };
  };
  convertRolesPermissionsTitles = (roles: any[]) => {
    const convertedRoles:any = {};

    const fieldMap:any = {
        "Role Title": "name",
        "Base Role": "base_role"
    }; 

    roles.forEach((role: { label: string | number; value: any; }) => {
        const mappedKey = fieldMap[role.label];
        if (mappedKey) {
            convertedRoles[mappedKey] = role.value;
        }
    });

    return convertedRoles;
};

  
 
  handleAddRoleAndPermissions=()=>{
   
    const nameTitleInput = this.convertRolesPermissionsTitles(this.state.permissionsInputConfig)
    if (!nameTitleInput.name || nameTitleInput.name.trim() === "") {
      toast.error("Role Title cannot be empty");
      return;
    }
    const permissionsListInput:any = this.convertPermissions(this.state.permissionsList);
    const requestBody ={
      role:{...permissionsListInput,...nameTitleInput}
    }
    this.handleCreateRoleAndPermissionApi(requestBody)
  }
  handleEditNewRole = () => {
    const { roleId } = this.state;
    this.props.navigation?.navigate(
      "EditTenantRolesAndPermissions",
      {},
      {
        roleId: roleId,
      }
    );
  };

  handleDeleteRolePermissionConfirm = () => {
    this.setState({ openDeleteConfirmModal: false }, () => {
      this.handleGoBackToMainScreen();
    });
  };

  handleOpenDeleteRolePermission = () => {
    this.setState({ openDeleteConfirmModal: true });
  };

  handleDeleteRolePermissionCancel = () => {
    this.setState({ openDeleteConfirmModal: false });
  };

  handleSetRoleDetails = () => {
    const paramRoleId = this.props.navigation?.getParam("roleId");
    if (paramRoleId) {
      this.setState({
        roleId:paramRoleId
      },()=>{
        this.handleGetRolesByIdApi(paramRoleId)
      })
    }
  };

  handleGetUpdatedSubCategoryList = (
    list: any,
    subCategoryId: any,
    subItemId: any,
    isRead?: boolean
  ) => {
    const updatedList = list.subCategory.map((subCategoryItem: any) => {
      if (subCategoryItem.id === subCategoryId) {
        subCategoryItem.subItems.map((subItem: any) => {
          if (subItem.id === subItemId) {
            if (isRead) subItem.isReadChecked = !subItem.isReadChecked;
            else subItem.isWriteChecked = !subItem.isWriteChecked;
          }
          return subItem;
        });
      }
      return subCategoryItem;
    });
    return updatedList;
  };

  handleGetUpdatedSubItemsList = (
    list: any,
    subItemId: any,
    isRead?: boolean
  ) => {
    const updatedList = list.subItems.map((subItem: any) => {
      if (subItem.id === subItemId) {
        if (isRead) subItem.isReadChecked = !subItem.isReadChecked;
        else subItem.isWriteChecked = !subItem.isWriteChecked;
      }
      return subItem;
    });
    return updatedList;
  };

  handleGetUpdatedPermissionsList = (
    permissionId: any,
    subItemId: any,
    isRead?: boolean,
    subCategoryId?: any
  ) => {
    const { permissionsList } = this.state;
    const updatedPermissionsList = permissionsList.map((item) => {
      if (item.id === permissionId) {
        if (subCategoryId) {
          this.handleGetUpdatedSubCategoryList(
            item,
            subCategoryId,
            subItemId,
            isRead
          );
        } else {
          this.handleGetUpdatedSubItemsList(item, subItemId, isRead);
        }
      }
      return item;
    });
    return updatedPermissionsList;
  };

  handleUpdateReadPermissions = (
    permissionId: any,
    subItemId: any,
    subCategoryId?: any
  ) => {
    const updatedList = this.handleGetUpdatedPermissionsList(
      permissionId,
      subItemId,
      true,
      subCategoryId
    );
    this.setState({ permissionsList: updatedList });
  };

  handleUpdateWritePermissions = (
    permissionId: any,
    subItemId: any,
    subCategoryId?: any
  ) => {
    const updatedList = this.handleGetUpdatedPermissionsList(
      permissionId,
      subItemId,
      false,
      subCategoryId
    );
    this.setState({ permissionsList: updatedList });
  };

  handleTextChange = (event: any) => {
    const { permissionsInputConfig } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = permissionsInputConfig.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        permissionsInputConfig: updatedInputConfig,
      };
    });
  };
  handleCreateRoleAndPermissionApi =async (requestBody:any) =>{
    const requestUrl = this.state.isEdit? `${configJSON.getrolesandpermissionEndPoint}/${this.state.roleId}`  : configJSON.getrolesandpermissionEndPoint;
    const methodType = this.state.isEdit ? configJSON.updateAPiMethod:  configJSON.exampleAPiMethod
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createRoleAndPermissionCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleCreateRolesPermissionResponse = (responseJson: {
    data: any
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const message = this.state.isEdit ? "Role updated successfully" : "New role added successfully"
      toast.success(message);
      this.handleGoBackToMainScreen()
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };


 generatePermissionsList = (permittedModules: { dashboard: { can: { read: any; write: any; }; }; }) => {
  const permissionsListMock = [
    {
      id: "1",
      baseCategoryTitle: "Dashboard",
      icon: "dashboard",
      subCategory: [],
      subItems: [
        {
          id: "1",
          subItemTitle: "Dashboard",
          isReadChecked: permittedModules.dashboard.can.read,
          isWriteChecked: permittedModules.dashboard.can.write,
        },
      ],
    },
    {
      baseCategoryTitle: "Setup",
      id: "2",
      icon: "setup",
      subItems: [
        this.generateSubItem("School Year", "school_year", permittedModules,false),
        this.generateSubItem("Subjects", "subject", permittedModules,false),
        this.generateSubItem("Curriculum", "curriculum", permittedModules,false),
        this.generateSubItem("Class Management", "class_management", permittedModules,false),
        this.generateSubItem("Teacher Management", "teacher_management", permittedModules,false),
        this.generateSubItem("Gradebook", "gradebook", permittedModules,false),
        this.generateSubItem("School Calendar", "school_calender", permittedModules,false),
      ],
      subCategory: [],
    },
    {
      subItems: [
        this.generateSubItem("Students / Parents", "user_management_student_parent", permittedModules,false),
        this.generateSubItem("Teachers / Parent Teachers", "user_management_teacher_parent_teacher", permittedModules,false),
        this.generateSubItem("Others", "user_management_others", permittedModules,false),
      ],
      baseCategoryTitle: "User Management",
      id: "3",
      icon: "user_management",
      subCategory: [],
    
    },
    {
      baseCategoryTitle: "Course Management",
      id: "4",
      subCategory: [
        {
          subItems: [
            this.generateSubItem("Courses Creation", "course_management_course_creation", permittedModules,false),
            this.generateSubItem("Add Activity", "course_management_add_activity", permittedModules,false),
            this.generateSubItem("Add Assessments", "course_management_add_assessment", permittedModules,true),
            this.generateSubItem("Course Assignment (to class)", "course_management_course_assignment_to_class", permittedModules,true),
          ],
          id: "1",
          subCategoryTitle: "My Courses",
        },
        {
          subCategoryTitle: "My Requests",
          id: "2",
          subItems: [
            this.generateSubItem("Pending", "my_request_pending", permittedModules,false),
            this.generateSubItem("Approved", "my_request_approve", permittedModules,false),
            this.generateSubItem("Rejected", "my_request_reject", permittedModules,false),
          ],
        },
        {
          subCategoryTitle: "Catalog Management",
          id: "3",
          subItems: [
            this.generateSubItem("Creation Assignment", "catalogue_management_creation", permittedModules,false),
            this.generateSubItem("Assignment", "catalogue_management_assignment", permittedModules,true),
          ],
        },
      ],
      subItems: [],
      icon: "course_management",
    },
    {
      icon: "roles_permissions",
      id: "4",
      baseCategoryTitle: "Roles and Permissions",
      subItems: [
        this.generateSubItem("Role Creation", "roles_creation", permittedModules,false),
      ],
      subCategory: [],
     
    },
    {
      id: "5",
      subCategory: [],
      baseCategoryTitle: "Appearance Management",
      subItems: [
        this.generateSubItem("Branding", "appreance_management", permittedModules,false),
        this.generateSubItem("Themes", "appreance_management_2", permittedModules,false), 
      ],
      icon: "appearance_management",
    },
  ];

  return permissionsListMock;
};

 generateSubItem = (title: any, moduleKey: string | number, permittedModules: { [x: string]: { can: { write: any;read:any }; }; },showDisabled:any) => {
  return {
    id: `${moduleKey}_${title}`,
    subItemTitle: title,
    isReadChecked: permittedModules[moduleKey]?.can?.read || false,
    isWriteChecked: permittedModules[moduleKey]?.can?.write || false,
    showDisabled:showDisabled
  };
};

  handleGetRolesByIdApi = async (selectedRoleId:any) => {
    const token = await getStorageData("token");
    const header = { token };

    let requestUrl = `${configJSON.getrolesandpermissionEndPoint}/${selectedRoleId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetRoleDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetRoleByIdResponse = (responseJson: {
    data:any
    errors: Array<{}>;
  }) => {
    if (responseJson.data) {
      const pathName = window.location.pathname;
      const rolePermissionDetail = responseJson.data.attributes
        const roleDetailsInputConfigMock = [
        {
          id: "tenant-roles-permissions-role-title",
          name: "roleTitle",
          fieldName: "roleTitle",
          value: rolePermissionDetail?.name || "",
          menuItems: [],
          label: "Role Title",
          fieldType: FIELD_TYPES.TEXT,
          isDisabled: true,
          placeHolderText: "Enter Role Title",
        },
        {
          id: "tenant-roles-permissions-base-role",
          name: "baseRole",
          fieldName: "baseRole",
          value:rolePermissionDetail?.base_role || "Tenant Admin",
          menuItems: [],
          label: "Base Role",
          fieldType: FIELD_TYPES.TEXT,
          isDisabled: true,
          placeHolderText: "Enter Base Role",
        },
      ];
      const permissionsList = this.generatePermissionsList(rolePermissionDetail.permitted_modules);
         this.setState({
        roleFromUrl: rolePermissionDetail?.name || "",
        isEdit: pathName.toLowerCase().includes("edit"),
        isView: pathName.toLowerCase().includes("view"),
      });
      if (
        pathName.toLowerCase().includes("view") ||
        pathName.toLowerCase().includes("edit")
      ) {
        this.setState({
          permissionsInputConfig: roleDetailsInputConfigMock,
          permissionsList:permissionsList
        });
      }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };


  handleDeleteRoleResponse = (responseJson: {
    message:string
    errors: Array<{}>;
  }) => {
    if (responseJson.message) {
      toast.success(responseJson?.message)
      this.handleDeleteRolePermissionConfirm()
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleDeleteRoleApi = async (selectedId:any) => {
    const token = await getStorageData("token");
    const header = { token };

    let requestUrl = `${configJSON.getrolesandpermissionEndPoint}/${selectedId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiDeleteRoleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.createRoleAndPermissionCallId:
            this.handleCreateRolesPermissionResponse(responseJson);
            break;
          case this.apiGetRoleDetailsCallId:
            this.handleGetRoleByIdResponse(responseJson);
            break; 
          case this.apiDeleteRoleCallId:
            this.handleDeleteRoleResponse(responseJson);
            break;
        }
      }

      // Customizable Area End
    }
  }
  // Customizable Area End
}
// Customizable Area End
