// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal } from "@material-ui/core";
import CreateCourseConfirmDialogController, {
  Props,
} from "./CreateCourseConfirmDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CustomButtonWeb from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    publishModalMainGrid: {
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      width: "auto",
      minWidth: "500px",
      margin: "auto",
      left: "50%",
      top: "50%",
      minHeight: "290px",
      overflow: "auto",
      position: "absolute",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        maxWidth: "100%",
        minWidth: "100px",
      },
    },
    coursePublishConfirmModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    closeIconGrid: {
      position: "absolute",
      right: "20px",
      top: "22px",
    },
    publishInfoBox: {
      display: "flex",
      justifyContent: "center",
      paddingRight: "20px",
      flexDirection: "column",
      paddingLeft: "20px",
      alignItems: "center",
    },
    publishIconBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      paddingTop: "30px",
      marginBottom: "25px",
      alignItems: "center",
      position: "relative",
    },
    subpublishInfoBox: {
      display: "flex",
    },
    confirmMainSpan: {
      textAlign: "center",
      padding: "0px 41px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    publishConfirmBtnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "20px",
      marginTop: "20px",
      padding: "10px 20px",
    },
  });

// Customizable Area End

export class CreateCourseConfirmDialog extends CreateCourseConfirmDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, detailsForConfirmation } = this.props;

    return (
      <Modal
        open={open}
        className={`${classes.highlightBgColor} ${classes.coursePublishConfirmModal}`}
        aria-labelledby="course-publish-confirm-modal"
        onClose={this.props.handleClose}
        aria-describedby="course-publish-confirm-modal"
      >
        <Box className={`${classes.publishModalMainGrid} ${classes.bgColor}`}>
          <Box>
            <Box className={`${classes.publishIconBox}`}>
              <img alt="Exclamation Mark" src={exclamationIcon} />
              <CloseIcon
                onClick={this.props.handleClose}
                className={`${classes.closeIconGrid} ${classes.textCommonColor} ${classes.cursorPointer}`}
                id="create-course-confirm-dialog-close-icon"
              />
            </Box>
            <Box className={`${classes.publishInfoBox}`}>
              <Typography
                className={`${classes.fontText22} ${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCenter}`}
                component={"p"}
              >
                <Typography className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCenter} ${classes.fontText22} ${classes.textCapital}`}
                  component={"span"}>
                  {detailsForConfirmation.topic + ' '}
                </Typography>
                course will be published
              </Typography>
              <Box className={`${classes.subpublishInfoBox}`}>
                <Typography
                  className={`${classes.confirmMainSpan}`}
                  component={"p"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText18}`}
                  >
                    Are you sure want to publish
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.textCapital}`}
                  >
                    {` ${detailsForConfirmation.topic} `}
                  </Typography>
                </Typography>
              </Box>
              <Box className={`${classes.subpublishInfoBox}`}>
                <Typography
                  className={`${classes.confirmMainSpan}`}
                  component={"p"}
                >
                  <Typography component={"span"} className={`${classes.textCommonColor} ${classes.fontText18}`}>
                    course of
                  </Typography>
                  <Typography className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontText18}`} component={"span"}>
                    {` ${detailsForConfirmation.grade} `}
                  </Typography>
                  <Typography component={"span"} className={`${classes.fontText18} ${classes.textCommonColor}`}>
                    ?
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={`${classes.publishConfirmBtnBox}`}>
            <CustomButtonWeb
              buttonVariant="outlined"
              btnStyle={btnStyle}
              buttonId="create-course-confirm-dialog-cancel-btn"
              type={"button"}
              btnText="Cancel"
              isDisabled={false}
              handleButtonClick={this.props.handleCancel}
            />
            <CustomButtonWeb
              buttonVariant="contained"
              btnText="Yes"
              handleButtonClick={this.props.handleYes}
              buttonId="create-course-confirm-dialog-confirm-btn"
              type={"button"}
              isDisabled={false}
              btnStyle={btnStyle}
            />
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  fontSize: "16px",
  borderRadius: "10px",
  padding: "10px 10px",
  fontWeight: 600,
  width: "48%",
  textAlign: "center",
  height: "45px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  CreateCourseConfirmDialog
);
// Customizable Area End
