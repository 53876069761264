export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const physicsSubjectIcon = require("../assets/physics.png");
export const ABC = require("../assets/ABC.png");
export const ScrollIcon = require("../assets/scroll.png");
export const MathIcon = require("../assets/math.png");
export const Alert = require("../assets/redalert.png");
export const Man = require("../assets/man4.png");
export const Man2 = require("../assets/man2.png");
export const Man1 = require("../assets/man1.png");
export const Man3 = require("../assets/man3.png");
export const Man5 = require("../assets/man5.png");
export const teacherProfilePicture = require("../assets/teacher-profile.png");
export const ScienceColor = require("../assets/science.svg");
export const MathsColor = require("../assets/maths-circle.svg");
export const HistoryColor = require("../assets/history.svg");
export const EnglishColor = require("../assets/english.svg");
export const mockdata = require("../assets/mockdata");
export const attach1 = require("../assets/attach1.png");
export const attach2 = require("../assets/attach2.png");
export const attach3 = require("../assets/attach3.png");
export const exclamationIcon = require("../assets/exclamationIcon.png");
