// Customizable Area Start
import React from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import TabPanel from "../../../components/src/TabPanel.web";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import StudentProfileProgressController, {
  completedActivityTabsList,
  Props,
} from "./StudentProfileProgressController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import {
  TimeSpent,
} from "./assets";
import StudentProfileProgressStatisticsWeb from "./StudentProfileProgressStatistics.web";
import StudentPerformance from "./StudentPerformance.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
import CustomEmptyResultComponentWeb from "../../../components/src/CustomEmptyResultComponent.web";

// Customizable Area End

// Customizable Area Start
const studentListTableColumns = [
  {
    id: "Title",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.RECENT_ACTIVITY_ICON_TEXT,
  },
  {
    id: "Type",
    columnId: 2,
    label: "Type",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "Time_Spent",
    columnId: 3,
    label: "Time Spent",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "lastActivity",
    columnId: 43,
    label: "Last Activity",
    type: tableColumnTypes.DUE_DATE,
  },
];
const studentTimeSpentColumn = [
  {
    id: "Course",
    columnId: 1,
    label: "Course",
    type: tableColumnTypes.PROGRESS_TAB_ICON,
  },
  {
    id: "time_spent",
    columnId: 3,
    label: "Time Spent",
    type: tableColumnTypes.TEXT,
  },
];

const styles = (theme: Theme) =>
  createStyles({
    studBreadCrumbRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    BoxtopBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    RescheduledBtn: {
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    dividerWidth: {
      margin: `5px 0 20px 0px`,
      color: "#888888",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    root: {
      width: "97%",
    },
    boxRoot: {
      borderRadius: "10px",
    },
    appBar: {
      borderRadius: "12px 12px 0 0",
      borderBottom: "1px solid #f1f1f1",
    },
    activeTab: {
      color: "#0D46BC",
      fontWeight: 500,
      fontSize: 20,
      textTransform: "capitalize",
      width: "33.33% !important",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabLabel: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      // color: "#090909",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0),
    },
    tableTitle: {
      fontSize: 28,
      fontWeight: "bold",
    },
    tabsButton: {
      backgroundColor: "transparent",
      border: "1px solid #888888",
      color: "#888888",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "12px",
      borderRadius: "6px",
      marginRight: "5px",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        outline: "none",
      },
      "&.MuiTab-root": {
        minWidth: "50px",
        minHeight: "40px",
      },
    },
    timeSpentImgBg: {
      height: "60px",
      width: "60px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    timespentCardMainBox: {
      padding: "34px 15px",
      borderRadius: "10px",
      width: "86%",
      border: "1px solid rgba(224, 224, 224, 1)",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    timeSpentTextBgBox: {
      position: "relative",
      height: "67px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    timeSpentBgBox: {
      position: "absolute",
      bottom: 0,
      width: "90px",
      height: "51%",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      backgroundColor: "#0D47BE",
      opacity: 0.06,
    },
    timeBox: {
      width: "80px",
      textAlign: "center",
      padding: "7px",
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "33.33%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    studentParentBox: {
      height: "50px",
      marginBottom: "12px",
      display: "flex",
      alignItems: "center",
    },
  });
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: "10px",
      },
    },
  },
});
function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
// Customizable Area End

// Customizable Area Start
export class StudentProfileProgress extends StudentProfileProgressController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      tabValue,
      gradedActivitiesBody,
      GradedActivitiesColumn,
      pendingActivitiesList,
      PendingLessonsColumn,
      profileData,
      selectedCompletedActivityTabValue,
      selectedStudentName,
      progressStatistics,
      studentPerformanceData,
      studentTimeSpentTableBody,
      totalTimeSpentInHHMM,
      recentActivitiesList,
    } = this.state;

    const profileprogress = () => {
      return "";
    };

    const profileprogress2 = () => {
      return "";
    };
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.studBreadCrumbRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Students`}
          </Typography>
          <Typography
            className={`${classes.textButton} ${classes.fontBold600} ${classes.fontText12}`}
            component={"span"}
          >
            {` / ${selectedStudentName}`}
          </Typography>
        </Box>
        <Box className={classes.BoxtopBtnRoot}>
          <Box
            data-test-id="Stud_name"
            onClick={this.handleGoBack}
            className={`${classes.cursorPointer}`}
            display={"flex"}
            alignItems={"center"}
          >
            <Box mr={"8px"}>
              <KeyboardBackspaceIcon
                fontSize="large"
                className={`${classes.textPrimaryColor}`}
              />
            </Box>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
            >
              {selectedStudentName}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            marginRight: "33px",
            borderRadius: "12px",
          }}
          className={classes.bgColor}
        >
          {profileprogress()}
          {profileprogress2()}
          <Grid container style={{ margin: "10px" }}>
            <Grid item lg={12} md={12} xs={12} style={{ marginTop: "5px" }}>
              <Box marginBottom={"20px"} display={"flex"} alignItems={"center"}>
                <CustomImageComponentWeb
                  imgStyles={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  altText="Student Profile"
                  src={profileData?.profileUrl || ""}
                  isUserProfileImg
                />
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText22}`}
                  style={{ fontWeight: "bold", marginLeft: "12px" }}
                >
                  {selectedStudentName}
                </Typography>
              </Box>
            </Grid>
            {profileData && (
              <>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Email ID:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      ID Number:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.idNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Curriculum:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.curriculum.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Date of Birth:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.dob}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Grade Level:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.gradeDetails.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      School Year Title:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.schoolYear}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Role:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.role}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Class:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.classDetail.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Expiring On:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.expiringDate}
                    </Typography>
                  </Box>
                </Grid>
                {profileData.customFields.map((item) => (
                  <Grid key={item.id} item xs={12} md={6} lg={4}>
                    <Box mb={1.5}>
                      <Typography
                        className={`${classes.textCommonColor} ${classes.fontText16}`}
                        component="span"
                      >
                        {item.label}:
                      </Typography>
                      <Typography
                        className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                        style={{ fontWeight: "bold", marginLeft: "5px" }}
                        component="span"
                      >
                        {item.value}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={12} md={6} lg={4}>
                  <Box mb={1.5}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Status:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                      component="span"
                    >
                      {profileData.status}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Divider className={`${classes.dividerWidth}`} />
          {profileData?.parentsList?.map((parent, index: number) => (
            <React.Fragment key={parent.id}>
              <Grid container style={{ margin: "10px" }}>
                <Grid item xs={12}>
                  <Box marginBottom={"20px"} display={"flex"} alignItems={"center"}>
                    {/* <img src={Oval} alt="" /> */}
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText20}`}
                      style={{ fontWeight: "bold" }}
                    >
                      {`Parent ${index + 1} Details`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className={classes.studentParentBox}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Name:
                    </Typography>
                    <CustomImageComponentWeb
                      altText="Parent Profile"
                      imgStyles={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        marginLeft: "7px",
                      }}
                      src={parent.profileUrl}
                      isUserProfileImg
                    />
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                      component="span"
                    >
                      {parent.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className={classes.studentParentBox}>
                    <Typography
                      className={`${classes.textCommonColor} 
                    ${classes.fontText16}`}
                      component="span"
                    >
                      Relationship:
                    </Typography>
                    <Typography
                      className={`${classes.fontText16} 
                    ${classes.textPrimaryColor}`}
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                      }}
                      component="span"
                    >
                      {parent.relationship}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className={classes.studentParentBox}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16}`}
                      component="span"
                    >
                      Contact Number:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                      }}
                      component="span"
                    >
                      {parent.contactNum}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box marginBottom={"12px"}>
                    <Typography
                      className={`${classes.textCommonColor}
                     ${classes.fontText16}`}
                      component="span"
                    >
                      Email ID:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                      component="span"
                    >
                      {parent.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box marginBottom={"12px"}>
                    <Typography
                      className={`${classes.fontText16} 
                    ${classes.textCommonColor}`}
                      component="span"
                    >
                      Role:
                    </Typography>
                    <Typography
                      className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                      component="span"
                    >
                      {parent.role}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider className={classes.dividerWidth} />
            </React.Fragment>
          ))}
        </Box>
        {/* tabs */}
        <Box className={classes.root} mt={6}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "99%" }}
            mb={1.5}
          >
            <Typography
              className={`${classes.tableTitle} ${classes.textSecondaryColor}`}
            >
              Analysis
            </Typography>
            <Box>
              <Tabs
                value={selectedCompletedActivityTabValue}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    background: "none",
                  },
                }}
                id="activity-date-tab-change"
                onChange={this.handleChangeCompletedActivityTab}
                aria-label="student-completed-activity-tabs"
              >
                {completedActivityTabsList.map(
                  (tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabsButton} ${classes.customTabButton} ${classes.customTabBorderButton}`}
                    />
                  )
                )}
              </Tabs>
            </Box>
          </Box>
          <Box className={`${classes.bgColor} ${classes.boxRoot}`}>
            <Tabs
              id="tab-change-wrapper"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  background: "none",
                },
              }}
              value={tabValue}
              className={`${classes.tabs}`}
              onChange={this.handleChange}
            >
              <Tab
                id="school-year-tab"
                wrapped
                className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                label={"Progress"}
              />
              <Tab
                id="performance"
                wrapped
                className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                label={"Performance"}
              />
              <Tab
                id="subject-tab"
                wrapped
                className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                label={"Time Spent"}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              {progressStatistics?.statistics?.completed ||
              progressStatistics?.statistics?.notTackled ||
              progressStatistics?.statistics?.remaining ? (
                <Box p={3}>
                  <StudentProfileProgressStatisticsWeb
                    isFirstRender={true}
                    key={progressStatistics?.statistics ? progressStatistics.statistics.completed ||
                      progressStatistics.statistics.notTackled ||
                      progressStatistics.statistics.remaining: "progress-chart"}
                    progressStatisticsForGraph={progressStatistics}
                    courseProgressTable={this.state.studentProgressBody}
                    navigation={this.props.navigation}
                    selectedStudentName={selectedStudentName}
                    selectedStudentId={this.state.selectedStudentId}
                  />
                </Box>
              ) : (
                <CustomEmptyResultComponentWeb marginStyle="20px 0px !important" message="No Progress data yet" />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box p={3}>
                <StudentPerformance
                  key={studentPerformanceData?.find((item) =>  item.progressPercentage)?.progressPercentage}
                  navigation={this.props.navigation}
                  studentName={selectedStudentName}
                  studentClassId={profileData?.classDetail.id}
                  selectedStudentId={this.state.selectedStudentId}
                  studentPerformanceData={studentPerformanceData}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Box p={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Box
                      display={"flex"}
                      alignItems="center"
                      flexDirection={"column"}
                      className={`${classes.bgColor} ${classes.timespentCardMainBox}`}
                    >
                      <Box marginBottom={"10px"}>
                        <Box
                          bgcolor={"#0D47BE"}
                          className={classes.timeSpentImgBg}
                        >
                          <img src={TimeSpent} alt="Time Spent" />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          className={`${classes.textSecondaryColor} ${classes.fontText20} ${classes.fontBold600}`}
                          component={"span"}
                        >
                          Total Time Spent
                        </Typography>
                      </Box>
                      <Box
                        mt={"16px"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        <Box>
                          <Box className={classes.timeSpentTextBgBox}>
                            <Box className={classes.timeSpentBgBox}></Box>
                            <Box
                              className={classes.timeBox}
                              padding="2px"
                              borderRadius={"8px"}
                            >
                              <Typography
                                className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`}
                                component="p"
                              >
                                {totalTimeSpentInHHMM.split(":")[0]}
                              </Typography>
                            </Box>
                          </Box>
                          <Box textAlign={"center"}>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText12}`}
                              component="p"
                            >
                              Hours
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            className={`${classes.textCommonColor} ${classes.fontText12} ${classes.timeSpentDivider}`}
                            component="p"
                          >
                            :
                          </Typography>
                        </Box>
                        <Box>
                          <Box className={classes.timeSpentTextBgBox}>
                            <Box className={classes.timeSpentBgBox}></Box>
                            <Box
                              className={classes.timeBox}
                              padding="2px"
                              borderRadius={"8px"}
                            >
                              <Typography
                                className={`${classes.textSecondaryColor} ${classes.fontBold600} ${classes.fontText26}`}
                                component="p"
                              >
                                {totalTimeSpentInHHMM.split(":")[1]}
                              </Typography>
                            </Box>
                          </Box>
                          <Box textAlign={"center"}>
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontText12}`}
                              component="p"
                            >
                              Minutes
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={8}>
                    <Box
                      className={classes.bgColor}
                      borderRadius={"10px"}
                      height={"270px"}
                      overflow={"auto"}
                      border={"1px solid rgba(224, 224, 224, 1)"}
                    >
                      <CustomTable
                        tableId={"student-timespent-table"}
                        tableHeaderColumns={studentTimeSpentColumn}
                        tableBodyData={studentTimeSpentTableBody}
                        isHandleCellAction={false}
                        showMenuIcon={false}
                        handleAction={() => console.log("time spent table item")}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </Box>
        </Box>
        <ThemeProvider theme={theme}>
          <Box mt={6}>
            <Box style={{ width: "97%" }}>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="space-between"
                style={{ width: "99%" }}
                mb={1.5}
              >
                <Typography
                  className={`${classes.tableTitle} ${classes.textSecondaryColor}`}
                >
                  Recent Activities
                </Typography>
                <Typography
                  className={`${classes.fontText14} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
                  component={"span"}
                  data-test-id="recent_activity_view_all"
                  onClick={() => this.handleViewActivityList("recent")}
                >
                  {`View All`}
                </Typography>
              </Box>
              <Box className={classes.bgColor} borderRadius={"10px"}>
                <CustomTable
                  tableId={"recent-activities-table"}
                  tableHeaderColumns={studentListTableColumns}
                  tableBodyData={recentActivitiesList}
                  showMenuIcon={false}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={6}>
            <Box style={{ width: "97%" }}>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="space-between"
                style={{ width: "99%" }}
                mb={1.5}
              >
                <Typography
                  className={`${classes.tableTitle} ${classes.textSecondaryColor}`}
                >
                  Pending Activities
                </Typography>
                <Typography
                  className={`${classes.fontText14} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
                  component={"span"}
                  data-test-id="pending_activity_view_all"
                  onClick={() => this.handleViewActivityList("pending")}
                >
                  {`View All`}
                </Typography>
              </Box>
              <Box className={classes.bgColor} borderRadius={"10px"}>
                <CustomTable
                  tableId={"pending-lessons-table"}
                  tableHeaderColumns={PendingLessonsColumn}
                  tableBodyData={pendingActivitiesList}
                  showMenuIcon={false}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={6}>
            <Box style={{ width: "97%" }}>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="space-between"
                style={{ width: "99%" }}
                mb={1.5}
              >
                <Typography
                  className={`${classes.tableTitle} ${classes.textSecondaryColor}`}
                >
                  Graded Activities
                </Typography>
                <Typography
                  className={`${classes.fontText14} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
                  component={"span"}
                  data-test-id="graded_activity_view_all"
                  onClick={() => this.handleViewActivityList("graded")}
                >
                  {`View All`}
                </Typography>
              </Box>
              <Box className={classes.bgColor} borderRadius={"10px"}>
                <CustomTable
                  tableId={"graded_activity_table"}
                  tableHeaderColumns={GradedActivitiesColumn}
                  tableBodyData={gradedActivitiesBody}
                  showMenuIcon={false}
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProfileProgress);
// Customizable Area End
