// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core';
import QuizViewAnswerConfirmDialogController, {
    Props
} from "./QuizViewAnswerConfirmDialogController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import MessageQuestionIcon from '../../../components/src/SVGIcons/MessageQuestionIcon.web';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        quizViewAnswerConfirmDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            }          
        },
        messageQuestion:{
            backgroundColor:"#3DBB3D",
            borderRadius:"50%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            width:"65px",
            height:"65px"
        },
        messageQuestionIcon: {
            width: "42px",
            height: "42px"
        }
    })

// Customizable Area End

// Customizable Area Start
export class QuizViewAnswerConfirmDialog extends QuizViewAnswerConfirmDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, isFromAssessment } = this.props;
        return (
            <Dialog className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open}>
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Box display={"flex"} justifyContent={"center"} id="quiz-view-answer-confirm-dialog-title">
                        <Box className={`${classes.messageQuestion}`}>
                         <MessageQuestionIcon className={classes.messageQuestionIcon} width="80" height="76" viewBox="0 0 80 76" fill={"#ffffff"} />
                        </Box>
                    </Box>
                    <DialogContent style={{padding: "0px"}}>
                        <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}>
                                Sure you want to view answers?
                            </Typography>
                        </Box>
                        <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                {isFromAssessment ? "Are you sure you want to view the answers of the assessment?" : "Are you sure you want to view the answers of the quiz?"}
                            </Typography>
                            <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                You won't be able to re-attempt after clicking on yes.
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <CustomButton btnStyle={cancelViewCorrectBtnStyle} btnText='No' buttonId='quiz-view-answer-cancel-btn' buttonVariant={"outlined"} isDisabled={false} type={"button"} handleButtonClick={this.props.onCancel} />
                            &nbsp;
                            <CustomButton btnStyle={viewCorrectAnswerConfirmBtnStyle} btnText='Yes' buttonId='quiz-view-answer-confirm-btn' buttonVariant={"contained"} isDisabled={false} type={"button"} handleButtonClick={this.props.onConfirm} />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const viewCorrectAnswerConfirmBtnStyle = {
    width:"214px",
    height: "50px",
    padding:"5px",
    borderRadius:"10px",
    fontSize:"16px",
    fontWeight:600
} as const;

const cancelViewCorrectBtnStyle = {
    width:"214px",
    height: "50px",
    padding:"5px",
    borderRadius:"10px",
    fontSize:"16px",
    fontWeight:600
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(QuizViewAnswerConfirmDialog);
// Customizable Area End
