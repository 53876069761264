// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {

    DialogContent,
    Box,
    Typography,
    Grid,
    Button,
    
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

import CustomTextField from "../../../components/src/CustomTextField.web";

import CircularProgress from "@material-ui/core/CircularProgress";

import NewBehaviourController, { Props } from "./NewBehaviourController";
import { ArrowBack, Delete } from "@material-ui/icons";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        
        headerGrid: {
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
        },
        pageName: {
            marginTop: "16px",
            marginBottom: "28px",
        },
        backArrowBox: {
            cursor: "pointer",
          },
        mainPagePadding: {
            paddingRight: "40px",
        },
        requiredNotation: {
            color: "red",
        },
       

        customLoader: {
            display: "block",
            right: 0,
            bottom: -30,
            position: "absolute",
            backgroundColor: "rgb(0, 0, 0, .4)",
            left: 0,
            top: 0,
            zIndex: 100,
        },
        circularContainer: {
            position: "absolute",
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "40%",
        },
        dividerLine: {
            height: "1px",
            background: "#F1F1F1",

        },
    });
// Customizable Area End

// Customizable Area Start
export class NewBehaviour extends NewBehaviourController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            keyError,
            keyErrorMesg,
            observationTitleValue,

            observationTitleError,
            observationTitleErrorMsg,
            loading,
            userRole,
            isEdit

        } = this.state;

        return (


            <Box
                className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
                height={"100%"}
            >
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box marginTop={"1%"}>
                            <Typography
                                className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText12} ${classes.smallTopHeader}`}
                                component={"span"}
                            >
                                Setup / Grade Book
                            </Typography>
                            <Typography
                                component={"span"}
                                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textCommonColor} ${classes.textCapital}`}
                            >
                                {` / ${userRole}`}
                            </Typography>
                            <Typography
                                component={"span"}
                                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5} ${classes.textCapital}`}
                            >
                               {isEdit ?  ` / Edit New Behaviour` : ` / Add New Behaviour` }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid className={classes.pageName} item xs={12}>
                    <Box display={"flex"}>
                  <Box
                    className={classes.backArrowBox}
                    onClick={this.handleBack}
                    id="back-button"
                  >
                    <ArrowBack
                      fontSize="large"
                      className={classes.textPrimaryColor}
                    />
                  </Box>
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
                    component={"span"}
                  >
                    {isEdit ?  `Edit New Behaviour` : `Add New Behaviour` }
                  </Typography>
                </Box>

                    </Grid>
                    <Grid item xs={12}>
                        <Box>

                            {loading && (
                                <Box className={classes.customLoader}>
                                    <Box className={classes.circularContainer}>
                                        <CircularProgress className={`${classes.textButton}`} />
                                    </Box>
                                </Box>
                            )}
                            <Box
                                padding={"25px"}
                                className={`${classes.bgColor}`}
                                data-test-id="modalBox"
                                style={{borderRadius:"12px"}}
                            >

                                <DialogContent
                                    style={{ padding: "0px", overflow: "hidden" }}
                                    data-test-id="content"
                                >
                                    <Grid container spacing={2} direction="row" data-test-id="row">


                                        <Grid xs={12} item data-test-id="sixGrid">
                                            <Box marginBottom={"10px"} width={"100%"}>
                                                <Typography
                                                    component={"span"}
                                                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                                                    data-test-id="Title"
                                                >
                                                    Observation Title
                                                    <Typography
                                                        component={"span"}
                                                        className={classes.requiredNotation}
                                                    >
                                                        *
                                                    </Typography>
                                                </Typography>
                                                <CustomTextField
                                                    placeHolderText="Enter Title"
                                                    fieldName="observationTitleValue"
                                                    fieldId="course-title"
                                                    fieldValue={observationTitleValue}
                                                    textFieldStyles={textfieldStyles}
                                                    fieldWidth={"99%"}
                                                    fieldType="text"
                                                    handleChange={this.handleChange}
                                                    fieldError={observationTitleError}
                                                    helperText={observationTitleErrorMsg}
                                                />
                                            </Box>
                                        </Grid>
                                        <Box style={{ paddingLeft: "25px",paddingRight:"25px"}}>
                                        <Box className={classes.dividerLine} />
                                        </Box>
                                        <Grid xs={12} item data-test-id="sixGrid">
                                            <Box  width={"100%"}>
                                                <Typography
                                                    component={"span"}
                                                    className={`${classes.fontText16} ${classes.textCommonColor}`}
                                                    data-test-id="Title"
                                                >
                                                    Evaluation Keys
                                                </Typography>

                                            </Box>
                                        </Grid>
                                        {this.state.keys.map((key, index) => (
                                            <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid" key={key.id}>
                                                <Box marginBottom={"10px"} width={"100%"}>
                                                    <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} data-test-id="Title">
                                                        Key {index + 1}
                                                        <Typography component={"span"} className={classes.requiredNotation}>
                                                            *
                                                        </Typography>
                                                    </Typography>
                                                    <CustomTextField
                                                        placeHolderText={`Enter Evaluation Key`}
                                                        fieldName={`key-${key.id}`}
                                                        fieldId={`key-${key.id}`}
                                                        fieldValue={key.value}
                                                        textFieldStyles={textfieldStyles}
                                                        fieldWidth={"99%"}
                                                        fieldType="text"
                                                        handleChange={(e: any) => this.handleKeyChange(key.id, e.target.value)}
                                                        fieldError={keyError[key.id]}
                                                        helperText={keyErrorMesg[key.id]}
                                                        endAdornmentIcon={<Delete id="rm-evaluation-key" style={{ color: 'red',cursor: "pointer"}} onClick={() => this.removeKey(key.id)} />}
                                                    />     
                                                </Box>
                                            </Grid>
                                        ))}
                                        <Grid xs={12} item data-test-id="sixGrid">
                                            <CustomButton
                                                btnText="Add another Evaluation Key"
                                                buttonId={`add-evaluation-key`}
                                                buttonVariant={"outlined"}
                                                btnStyle={addKeysStyle}
                                                handleButtonClick={this.addKey}
                                                type={"button"}
                                                isDisabled={false}

                                            />
                                        </Grid>

                                    </Grid>
                                </DialogContent>
                            </Box>

                            {this.state.skills.length > 0 && this.state.skills.map((skill, index) => (
                                <Box
                                    padding={"25px"}
                                    className={`${classes.bgColor}`}
                                    data-test-id="modalBox"
                                    style={{ marginTop: "20px" ,borderRadius:"12px"}}
                                    key={skill.id}
                            
                                >

                                    <Grid direction="row" container>
                                        <Grid
                                            xs={12}
                                            className={`${classes.headerGrid}`}
                                            item
                                            data-test-id="modalGrid"
                                        >
                                            <Button
                                                onClick={() => this.removeSkill(skill.id)}
                                                className={classes.textPrimaryColor}
                                                id="remove-main-skill"
                                                style={{ float: "right" }}
                                            >
                                                <CloseIcon
                                                    className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                                                    id="add-new-course-close-modal-icon"
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <DialogContent
                                        style={{ padding: "0px", overflow: "hidden" }}
                                        data-test-id="content"
                                    >
                                        <Grid container spacing={2} direction="row" data-test-id="row">


                                            <Grid xs={12} item data-test-id="sixGrid">
                                                <Box marginBottom={"10px"} width={"100%"}>
                                                    <Typography
                                                        component={"span"}
                                                        className={`${classes.fontText16} ${classes.textCommonColor}`}
                                                        data-test-id="Title"
                                                    >
                                                        Main Skill {index + 1}
                                                        <Typography
                                                            component={"span"}
                                                            className={classes.requiredNotation}
                                                        >
                                                            *
                                                        </Typography>
                                                    </Typography>
                                                    <CustomTextField
                                                        placeHolderText="Enter Title"
                                                        fieldName="courseTitleValue"
                                                        fieldId={`main-skill-${skill.id}`}
                                                        fieldValue={skill.mainSkill}
                                                        textFieldStyles={textfieldStyles}
                                                        fieldWidth={"99%"}
                                                        fieldType="text"
                                                        handleChange={(e: any) => this.handleMainSkillChange(skill.id, e.target.value)}
                                                        fieldError={this.state.skillErrors[skill.id]?.mainSkillError}
                                                        helperText={this.state.skillErrors[skill.id]?.mainSkillErrorMsg}
                                                    />
                                                </Box>
                                            </Grid>



                                            {skill.subSkills.map((subSkill, idx) => (
                                               
                                                <Grid xs={12} sm={12} md={6} item data-test-id="sixGrid" key={subSkill.id}>
                                                    <Box marginBottom={"10px"} width={"100%"}>
                                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} data-test-id="Title">
                                                            Sub Skill {idx + 1}
                                                            <Typography component={"span"} className={classes.requiredNotation}>
                                                                *
                                                            </Typography>
                                                        </Typography>
                                                        <CustomTextField
                                                            placeHolderText="Enter Sub Skill"
                                                            fieldName={`key-${skill.id}-${subSkill.id}`}
                                                            fieldId={`sub-${skill.id}-${subSkill.id}`}
                                                            fieldValue={subSkill.value}
                                                            textFieldStyles={textfieldStyles}
                                                            fieldWidth={"99%"}
                                                            fieldType="text"
                                                            handleChange={(e: any) => this.handleSubSkillChange(skill.id, subSkill.id, e.target.value)}
                                                            fieldError={this.state.subSkillErrors[skill.id]?.[subSkill.id]?.error}
                                                            helperText={this.state.subSkillErrors[skill.id]?.[subSkill.id]?.errorMsg}
                                                            endAdornmentIcon={<Delete data-testid={`remove-sub-${skill.id}-${subSkill.id}`} style={{ color: 'red' }} onClick={() => this.removeSubSkill(skill.id, subSkill.id)} />}
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                            <Grid xs={12} item data-test-id="sixGrid">
                                                <CustomButton
                                                    btnText="Add another Sub Skill"
                                                    buttonId={`add-sub-skill`}
                                                    buttonVariant={"outlined"}
                                                    btnStyle={addKeysStyle}
                                                    handleButtonClick={() => this.addSubSkill(skill.id)}
                                                    type={"button"}
                                                    isDisabled={false}

                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </Box>
                            ))}
                            <Grid item xs={12}>
                                <Box style={{ width: "100%" }} marginBottom={"10px"}>
                                    <CustomButton
                                        btnText={isEdit ? "Edit" :"Add"}
                                        buttonId={`submit-data`}
                                        buttonVariant={"contained"}
                                        btnStyle={submitBtnStyles}
                                        handleButtonClick={this.handleRequestBody}
                                        type={"button"}
                                        isDisabled={false}
                                    />
                                    <CustomButton
                                        btnText={this.state.skills.length > 0 ? "Add another Skill" : "Add Skill"}
                                        buttonVariant={"outlined"}
                                        btnStyle={cancelBtnSTyle}
                                        buttonId={`add-main-skill`}
                                        isDisabled={false}
                                        type={"button"}
                                        handleButtonClick={this.addSkill}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const cancelBtnSTyle = {
    width: "190px",
    padding: "10px 10px",
    height: "50px",
    borderRadius: "12px",
    fontSize: "18px",
    marginTop: "32px",
    textAlign: "center",
    float: "left",
    fontWeight: 500,
} as const;

const submitBtnStyles = {
    padding: "10px 10px",
    height: "50px",
    width: "120px",
    fontSize: "18px",
    textAlign: "center",
    borderRadius: "12px",
    marginTop: "32px",
    float: "right",
    marginLeft: "15px",
    fontWeight: 500,
} as const;

const addKeysStyle = {
    padding: "10px 10px",
    height: "50px",
    width: "50%",
    fontSize: "18px",
    textAlign: "center",
    borderRadius: "12px",
    marginTop: "32px",

    fontWeight: 500,
} as const;




const textfieldStyles = {
    padding: "8px 0px",
    width: "99%",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewBehaviour);
// Customizable Area End
