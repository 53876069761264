// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import RegistrarProfileController, {
  Props,
} from "./RegistrarProfileController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import GearIconDropDown from "../../../components/src/GearIconDropDown.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    registrarInfoBox: {
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    mainPageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    profileInfoGrid: {
      padding: "15px 18px 15px 18px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    userName: {
      padding: "15px 18px 5px 18px",
    },
    leftMargin: {
      marginLeft: "8px",
    },
    detailGridItem: {
      padding: "8px 0px 8px 0px",
    },
    backIcon: {
      cursor: "pointer",
      fontSize: "34px",
    },
    profileBg: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
    emptyProfileImg: {
      width: "inherit",
      height: "inherit",
      color: "#ccc",
    },
    profilePicImg: {
      width: "55px",
      height: "55px",
      objectFit: "contain",
    },
    registrarOverflowText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  });
// Customizable Area End

// Customizable Area Start
export class RegistrarProfile extends RegistrarProfileController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { registrarDetails } = this.state;

    return (
      <Box
        id="view-registrar-profile-main-box"
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid
          id="view-registrar-profile-main-container"
          direction="row"
          container
        >
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.fontText14} ${classes.textCommonColor} `}
                component={"span"}
              >
                Registrar
              </Typography>
              <Typography
                component={"span"}
                className={`${classes.primaryColor5} ${classes.fontText14} ${classes.fontBold600}`}
              >
                {` / My Profile`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.mainPageName} item xs={12}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <KeyboardBackspaceIcon
                  data-testid="registrar-go-back"
                  onClick={() => this.handleGoBack()}
                  className={`${classes.backIcon} ${classes.textPrimaryColor}`}
                />
                <Typography
                  id="view-registrar-profile-username"
                  className={`${classes.textCapital} ${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                  component={"span"}
                >
                  My Profile
                </Typography>
              </Box>
              <GearIconDropDown
                data-testid="registrar-profile-settings"
                options={this.state.profileSettingOption}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              id="view-registrar-profile-registrarInfoBox"
              className={`${classes.bgColor} ${classes.registrarInfoBox}`}
            >
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box
                        className={`${classes.userName}`}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <Box className={`${classes.profileBg}`}>
                          {registrarDetails?.profileImg ? (
                            <CustomImageComponentWeb
                              altText="registrar-profile"
                              src={registrarDetails.profileImg}
                              imgStyles={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              isUserProfileImg
                            />
                          ) : (
                            <AccountCircleIcon
                              className={`${classes.emptyProfileImg}`}
                            />
                          )}
                        </Box>
                        <Typography
                          component={"span"}
                          id="view-registrar-profile-userFullName"
                          className={`${classes.textCapital} ${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText24}`}
                        >
                          {`${registrarDetails.firstName ||
                            ""} ${registrarDetails.lastName || ""}`}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        className={`${classes.profileInfoGrid}`}
                        container
                        direction="row"
                      >
                        <Grid
                          item
                          id="view-registrar-profile-emailid"
                          className={`${classes.detailGridItem}`}
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box
                            className={`${classes.registrarOverflowText}`}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography
                              component={"span"}
                              id="view-registrar-profile-email"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Email ID:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              {registrarDetails.email}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          id="view-registrar-profile-emailid"
                          className={`${classes.detailGridItem}`}
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box
                            className={`${classes.registrarOverflowText}`}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography
                              component={"span"}
                              id="view-registrar-profile-id"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              ID:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.leftMargin} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              {registrarDetails.uniqId}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-registrar-profile-role-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-registrar-profile-role"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Role:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.textCapital} ${classes.fontBold600} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              Registrar
                            </Typography>
                          </Box>
                        </Grid>
                        {registrarDetails?.customFields?.map((item) => (
                          <Grid
                            className={`${classes.detailGridItem}`}
                            key={item?.id}
                            item
                            xs={12}
                            md={6}
                            lg={4}
                          >
                            <Typography
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              {item?.label}:{" "}
                              <Typography
                                component={"span"}
                                className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18} ${classes.leftMargin}`}
                              >
                                {item?.value || "NA"}
                              </Typography>
                            </Typography>
                          </Grid>
                        ))}
                        <Grid
                          className={`${classes.detailGridItem}`}
                          item
                          id="view-registrar-profile-status-grid"
                          xs={12}
                          md={6}
                          sm={12}
                          lg={4}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography
                              component={"span"}
                              id="view-registrar-profile-status"
                              className={`${classes.textCommonColor} ${classes.fontBold600} ${classes.fontText18}`}
                            >
                              Status:
                            </Typography>
                            <Typography
                              component={"span"}
                              className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.textCapital} ${classes.fontText18} ${classes.leftMargin}`}
                            >
                              {registrarDetails.status}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(RegistrarProfile);
// Customizable Area End
