// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AddNewParentGuardianDetailsStep2Controller, {
  IDDConfigProps,
  IParentInputConfigProps,
  Props,
} from "./AddNewParentGuardianDetailsStep2Controller.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import { FIELD_TYPES } from "../../../components/src/CommonType.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomFileUploadTextFieldWeb from "../../../components/src/CustomFileUploadTextField.web";
import CheckboxUnCheckIcon from "../../../components/src/SVGIcons/CheckboxUnCheckIcon.web";
import CustomCheckBoxComponentWeb from "../../../components/src/CustomCheckBoxComponent.web";
import CheckboxCheckIcon from "../../../components/src/SVGIcons/CheckboxCheckIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addUserMainBox: {
      height: "100vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    parentGuardianSelectDD: {
      marginBottom: "10px",
    },
    parentGuardianSelectDDWidth: {
      width: "92%",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    parentGuardianMainGridItem: {
      borderBottom: "4px solid #f0f0f0",
      padding: "24px 26px 24px 22px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    textCapital: {
      textTransform: "capitalize",
    },
    labelBox: {
      display: "flex",
      justifyContent: "space-between",
    },
  });
// Customizable Area End

// Customizable Area Start
export class AddNewParentGuardianDetailsStep2 extends AddNewParentGuardianDetailsStep2Controller {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, parentInputConfigs } = this.props;

    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box className={`${classes.bgColor} ${classes.addUserMainBox}`}>
            <Grid container direction="row">
              {parentInputConfigs?.map((item: IParentInputConfigProps) => (
                <Grid
                  id={`Parent-Details-${item.id}`}
                  key={item.id}
                  item
                  className={`${classes.parentGuardianMainGridItem}`}
                  xs={12}
                >
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Box className={`${classes.labelBox}`}>
                        <Typography
                          component={"span"}
                          className={`${classes.textPrimaryColor} ${classes.fontBold600}`}
                        >
                          {item.label}
                        </Typography>
                        <Box display={"flex"} alignItems={"center"}>
                          <CustomCheckBoxComponentWeb
                            fieldId={`set-as-parent-teacher-${item.id}`}
                            checked={
                              item.role.toLowerCase() === "parent_teacher"
                            }
                            checkedIcon={
                              <CheckboxCheckIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#fff"}
                                strokeWidth="1.5"
                                fill={"#FBD82C"}
                              />
                            }
                            uncheckIcon={
                              <CheckboxUnCheckIcon
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke={"#FBD82C"}
                                strokeWidth="1.5"
                                fill={"none"}
                              />
                            }
                            handleChange={() =>
                              this.props.handleMarkAsParentTeacher(item.id)
                            }
                          />
                          <Typography
                            className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                            component={"span"}
                          >
                            Set As Parent Teacher
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction={"row"}>
                        {item?.ddConfigs?.map(
                          (parentGuardianInputItem: IDDConfigProps) => (
                            <Grid
                              key={parentGuardianInputItem?.id}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                            >
                              <Box
                                className={`${classes.parentGuardianSelectDD}`}
                              >
                                <Box marginBottom={"10px"}>
                                  <Typography
                                    className={`${classes.textCommonColor} ${classes.fontText16}`}
                                    id={`parent-guardian-${parentGuardianInputItem?.id}`}
                                    component={"span"}
                                  >
                                    {parentGuardianInputItem.label}
                                  </Typography>
                                </Box>
                                <Box
                                  className={`${classes.parentGuardianSelectDDWidth}`}
                                >
                                  {parentGuardianInputItem.fieldType ===
                                    FIELD_TYPES.SELECT && (
                                    <CustomSelectDropdown
                                      fieldId={parentGuardianInputItem.id}
                                      fieldValue={parentGuardianInputItem.value}
                                      fieldName={parentGuardianInputItem.name}
                                      fieldStyles={dropdownStyle}
                                      handleChange={(e: any) =>
                                        this.props.handleSelectDropdownValue(
                                          e,
                                          item.id
                                        )
                                      }
                                      renderValue={(selected: any) =>
                                        this.props.renderDDMenuItem(
                                          selected,
                                          parentGuardianInputItem.fieldName,
                                          parentGuardianInputItem.placeHolderText
                                        )
                                      }
                                      fieldMenuItems={
                                        parentGuardianInputItem?.menuItems || []
                                      }
                                      fieldError={false}
                                    />
                                  )}
                                  {parentGuardianInputItem.fieldType ===
                                    FIELD_TYPES.FILE_UPLOAD_TEXT && (
                                    <CustomFileUploadTextFieldWeb
                                      placeHolderText={`Select File`}
                                      fieldName={parentGuardianInputItem.name}
                                      removeText="Remove"
                                      fieldId={parentGuardianInputItem.id}
                                      uploadText="Upload"
                                      fieldValue={parentGuardianInputItem.value.fileName}
                                      fieldWidth={"100%%"}
                                      textFieldStyles={textfieldStyles}
                                      fieldType="text"
                                      fileType="image"
                                      handleFile={(file: any) =>
                                        this.props.handleUploadFile(
                                          file,
                                          parentGuardianInputItem.fieldName,
                                          item.id
                                        )
                                      }
                                    />
                                  )}
                                  {parentGuardianInputItem.fieldType ===
                                    FIELD_TYPES.TEXT && (
                                    <CustomTextField
                                      placeHolderText={
                                        parentGuardianInputItem.placeHolderText
                                      }
                                      fieldName={parentGuardianInputItem.name}
                                      fieldWidth={"100%%"}
                                      fieldId={parentGuardianInputItem.id}
                                      fieldValue={parentGuardianInputItem.value}
                                      textFieldStyles={textfieldStyles}
                                      fieldType="text"
                                      isDisabled={
                                        parentGuardianInputItem?.isDisabled
                                      }
                                      handleChange={(e: any) =>
                                        this.props.handleChange(e, item.id)
                                      }
                                    />
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const textfieldStyles = {
  width: "100%",
  padding: "8px 0px",
} as const;

const dropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewParentGuardianDetailsStep2);
// Customizable Area End
