// Customizable Area Start
import React, { useRef , useState } from 'react';
import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import ReactPlayer from "react-player"
import { toast } from 'react-toastify';
// Customizable Area End

// Customizable Area Start
interface IVideoProps{
    src:any;
    isCompleted:boolean;
    videoProgress:number;
    thumbnail:any;
}

interface IProps {
    currentVideo: IVideoProps;
    classes: any;
    handleUpdateMediaProgressTime?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    videoPlayerBox: {
        width:"97%",
        height:"400px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        borderRadius: "8px",
        "& video": {
            borderRadius: "8px"
        },
    },
    playIcon:{
        color:"#ffffff"
    }
}))
// Customizable Area End

// Customizable Area Start
const CustomVideoPlayer: React.FC<IProps> = (props) => {
    const newClasses = useStyles();
    const { currentVideo, classes, handleUpdateMediaProgressTime } = props;
    const [isPlaying, setIsPlaying] = useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const [errorInVideo, setErrorInVideo] = useState(false);
    const playerRef : any = useRef();

    const onReady = React.useCallback(() => {
        if (!isReady) {
          const timeToStart = currentVideo.videoProgress;
          playerRef.current.seekTo(timeToStart, "seconds");
          setIsReady(true);
        }
      }, [isReady]);

    const togglePlay = (e:any) => {
        e.preventDefault()
        setIsPlaying((isPlaying) => !isPlaying);
    };
    const handleErrorInVideo = () => {
        toast.error("There was some error in playing the video.")
        setErrorInVideo(true)
    }

    const checkProgress = (e : any) => {
        const timestamp = e.playedSeconds;
        handleUpdateMediaProgressTime(timestamp);
    }

    return (
        <Box className={`${newClasses.videoPlayerBox}`} >
            {!errorInVideo ? (<ReactPlayer
                ref={playerRef}
                url={currentVideo.src}
                playing={isPlaying}
                config={{
                    file: {
                        attributes: {
                            controlsList: "nodownload",
                        },
                    },
                }}
                light={currentVideo.thumbnail}
                width="100%"
                height="inherit"
                controls
                onProgress={checkProgress}
                onError={handleErrorInVideo}
                onReady={onReady}
                playIcon={<PlayCircleOutlineRoundedIcon className={`${newClasses.playIcon}`} fontSize='large' onClick={togglePlay} />}
            />):
            <Typography className={`${classes.primaryColor6}`} component={"h3"}>
                    Video could not be loaded.
            </Typography>
            }
        </Box>
    )
}

export default CustomVideoPlayer;
// Customizable Area End