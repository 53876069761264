// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import SubmitGradesConfirmationModalController, {
  Props,
} from "./SubmitGradesConfirmationModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    closeIcon: {
      "& svg path": {
        fill: "#fff",
      },
    },
  });

const modalTheme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "520px",
      },
      paperWidthSm: {
        maxWidth: "520px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class SubmitGradesConfirmationModal extends SubmitGradesConfirmationModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isDarkMode, isLockConfirm } = this.props;

    return (
      <ThemeProvider theme={modalTheme}>
        <Dialog
          data-test-id="submitGradesConfirmDialog"
          className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
          open={open}
        >
          <Box
            padding={"30px"}
            className={`${classes.bgColor}`}
            data-test-id="submitGradesConfirmDialogBox"
          >
            <Box display={"flex"} justifyContent={"center"}>
              <Box display={"flex"} justifyContent={"center"} width={"94%"}>
                <img src={exclamationIcon} alt="success icon" />
              </Box>
              <Box
                className={`${isDarkMode ? classes.closeIcon : ""}`}
                width={"6%"}
              >
                <IconButton
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }}>
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                  data-test-id="GradesSubmitNote"
                >
                  {isLockConfirm
                    ? "Grades will be locked"
                    : "Grades will be submitted"}
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"}>
                {isLockConfirm ? (
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  >
                    You are about to lock the grades of the selected <br />
                    grading period of the selected students, <br />
                    please click confirm to continue.
                  </Typography>
                ) : (
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="gradeSubmitConfirmation"
                  >
                    Are you sure want to submit Grades for selected students?
                  </Typography>
                )}
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
                data-test-id="SubmitOrCancelBtns"
                style={{ gap: "25px" }}
              >
                <CustomButton
                  btnStyle={customButtonStyle}
                  btnText="Cancel"
                  buttonId="cancelGradesSubmission"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
                <CustomButton
                  btnStyle={customButtonStyle}
                  btnText={isLockConfirm ? "Confirm" : "Yes"}
                  buttonId="confirmGradesSubmission"
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onConfirm}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const customButtonStyle = {
  height: "50px",
  padding: "5px",
  width: "100%",
  fontWeight: 600,
  borderRadius: "10px",
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(SubmitGradesConfirmationModal);
// Customizable Area End
