// Customizable Area Start
import React from "react";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ParentTeacherAssessmentsController, {
    Props,
} from "./ParentTeacherAssessmentsController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import TabPanel from "../../../components/src/TabPanel.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import AssessmentsAll from "./AssessmentsAll.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AssessmentAssigned from "./AssessmentAssigned.web";
import ParentTeacherGradedAssessment from "./ParentTeacherGradedAssessment.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        boxRoot: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
            marginTop: "30px",
        },
        assesmentBreadcrumb: {
            margin: theme.spacing(2, 0, 2, 0),
        },
        profileActiveTab: {
            fontWeight: 500,
            color: "#0D46BC",
            textTransform: "capitalize",
            fontSize: 20,
        },
        assessmentAppBar: {
            backgroundColor: "#ffffff",
            borderBottom: '1px solid #F1F1F1',
            borderRadius: "12px 12px 0 0",
        },
        profileNameBox: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
        },
        tabLabel: {
            fontWeight: 400,
            fontSize: 20,
            color: "#090909",
            lineHeight: "23px",
            padding: theme.spacing(2.5, 0),
            textTransform: "capitalize",
        },
        tabButton: {
            padding: "10px 4px",
            backgroundColor: "transparent",
            textDecoration: "none",
            textAlign: "center",
            display: "inline-block",
            textTransform: "capitalize",
            opacity: 1,
            minWidth: "25%",
            fontSize: "16px",
            "&.Mui-selected": {
                color: "#0D46BC !important",
                backgroundColor: "transparent",
                borderBottom: "1px solid #0D46BC",
                fontWeight: 600,
                outline: "none",
            },
            "&.MuiTab-root": {
                minHeight: "60px",
            },
        },
    });
// Customizable Area End

// Customizable Area Start
export class ParentTeacherAssessments extends ParentTeacherAssessmentsController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { tabValue, openAddNewAssessment } = this.state;
        const { classes, navigation } = this.props;

        return (
            <>
                <Box
                    className={`${classes.highlightBgColor} ${classes.childMainWrapperPadding} ${classes.secondaryBgColor}`}
                    height={"100%"}
                >
                    <Box className={classes.assesmentBreadcrumb}>
                        <Typography
                            component={"span"}
                            className={`${classes.fontText14} ${classes.textCommonColor}`}
                        >
                            {`Assessment `}
                        </Typography>
                        <Typography
                            component={"span"}
                            className={`${classes.primaryColor5} ${classes.fontText12} ${classes.fontBold600}`}
                        >
                            {`/ ${this.state.tabName}`}
                        </Typography>
                    </Box>
                    <Box className={classes.profileNameBox}>
                        <Box>
                            <Box alignItems={"center"} display={"flex"}>
                                <Typography
                                    component={"span"}
                                    className={`${classes.textPrimaryColor} ${classes.fontText34}`}
                                    style={{ fontWeight: 700 }}
                                >
                                    {`Assessment`}
                                </Typography>
                            </Box>
                        </Box> 
                        <Box>
                            <CustomButton
                                btnStyle={addNewSchoolBtn}
                                buttonId={`parent-teacher-assessment-search-mobile`}
                                btnText="Add New Assessment"
                                type={"button"}
                                buttonVariant={"contained"}
                                isDisabled={false}
                                handleButtonClick={this.handleOpenAddNewAssessmentModal}
                            />
                        </Box>
                    </Box>
                    <Box mt={"30px"} display={"flex"}>
                        <Box flexDirection={"column"} display={"flex"}>
                            <Typography
                                component={"span"}
                                gutterBottom
                                className={`${classes.textCommonColor} ${classes.fontText14}`}
                            >
                                {`Student`}
                            </Typography>
                            <CustomSelectDropdown
                                fieldId="assessment-student-dorpdown"
                                fieldName="selectedStudentDropdownValue"
                                fieldValue={this.state.selectedStudentDropdownValue}
                                handleChange={this.handleStudentDropdownValue}
                                renderValue={this.renderStudentDropdownValue}
                                fieldStyles={dropdownStudent}
                                fieldMenuItems={this.state.studentDropdownItems}
                                fieldError={false}
                                iconComponent={
                                    <DropdownIcon
                                        viewBox="0 0 24 24"
                                        height="24"
                                        width="24"
                                        stroke={"#888888"}
                                        strokeWidth="1.5"
                                        fill={"none"}
                                    />
                                }
                            />
                        </Box>
                    </Box>
                    <Box style={{ width: "98%" }} mt={3}>
                        <Box className={classes.boxRoot}>
                            <AppBar
                                position="static"
                                color="default"
                                className={`${classes.bgColor} ${classes.assessmentAppBar}`}
                            >
                                <Tabs
                                    variant="fullWidth"
                                    id="tab-change-wrapper"
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#0D46BC",
                                        },
                                    }}
                                    value={tabValue}
                                    onChange={this.handleTabChange}
                                    classes={{ indicator: classes.profileActiveTab }}
                                >
                                    <Tab
                                        id="All"
                                        classes={{
                                            root:
                                                tabValue === 0
                                                    ? classes.profileActiveTab
                                                    : classes.tabLabel,
                                        }}
                                        label={"All"}
                                        className={`${classes.tabButton} ${classes.textPrimaryColor}`}
                                    />
                                    <Tab
                                        id="Assigned"
                                        label={"Assigned"}
                                        classes={{
                                            root:
                                                tabValue === 1
                                                    ? classes.profileActiveTab
                                                    : classes.tabLabel,
                                        }}
                                        className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                                    />
                                    <Tab
                                        id="Graded"
                                        label={"Graded"}
                                        classes={{
                                            root:
                                                tabValue === 2
                                                    ? classes.profileActiveTab
                                                    : classes.tabLabel,
                                        }}
                                        className={`${classes.textPrimaryColor} ${classes.tabButton}`}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel index={0} value={tabValue} >
                                <AssessmentsAll currentRole="Parent Teacher" searchByStudent="" classId={"1"} navigation={navigation} gradingComponentsdata={undefined} studentIds={""} />
                            </TabPanel>
                            <TabPanel index={1} value={tabValue} >
                                <AssessmentAssigned currentRole="Parent Teacher" searchByStudent="" classId={"1"} navigation={navigation} studentIds={""} getStudentData={[]} searchstu={false} />
                            </TabPanel>
                            <TabPanel index={2} value={tabValue}>
                                <ParentTeacherGradedAssessment navigation={navigation} classes={classes} />
                            </TabPanel>
                        </Box>
                    </Box>
                </Box>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const addNewSchoolBtn = {
    textAlign: "center",
    width: "241px",
    marginRight: "25px",
    height: "50px",
    fontWeight: 500,
    borderRadius: "12px",
    fontSize: "18px",
    padding: "10px 10px",
} as const;
const dropdownStudent = {
    height: "50px",
    borderRadius: "8px",
    width: "218px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ParentTeacherAssessments);
// Customizable Area End
