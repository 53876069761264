// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    Box,
    Typography,
    Grid,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import SearchExistingActivityModalController, {
    Props
} from "./SearchExistingActivityModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { ScienceColor} from "./assets";
import CustomEmptyResultComponent from '../../../components/src/CustomEmptyResultComponent.web';
import {message} from '../src/assets'

// Customizable Area End

const activityTableColumns = [
    {
        columnId: 1,
        id: "Title",
        type: tableColumnTypes.SUBJECT_ICON_NAME,
        label: "Title",
        minWidth: "150px",
    },
    {
        columnId: 2,
        id: "Lesson",
        type: tableColumnTypes.TEXT,
        label: "Lesson",
        minWidth: "80px",
    },
    {
        id: "Topic",
        columnId: 3,
        label: "Topic",
        type: tableColumnTypes.TEXT,
        minWidth: "80px",
    },
    {
        id: "Course",
        columnId: 4,
        label: "Course",
        type: tableColumnTypes.TEXT,
        minWidth: "80px",
    },
    {
        id: "Subject",
        columnId: 5,
        label: "Subject",
        type: tableColumnTypes.TEXT,
        minWidth: "80px",
    },
    {
        columnId: 6,
        id: "Grade",
        type: tableColumnTypes.TEXT,
        label: "Grade",
    },
]

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        existingActivityContainer: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
            "& .MuiDialog-paperWidthSm": {
                maxWidth: "100%",
                width: "900px",
                overflowX: "hidden",
                overflowY: "hidden",
            }
        },
        highlightCourseRow: {
            background: "#FFD92E"
        },
        dividerLine: {
            background: "#F1F1F1",
            height: "4px",
            marginTop: "25px",
            marginRight: "-25px",
            marginLeft: "-25px",
        },
        catalogCoursesRow: {
            "& td": {
                paddingTop: "0px",
                height: "50px",
                paddingBottom: "0px",
            }
        },
        dialogContent: {
            padding: "0px",
            marginTop: "1px",
            overflow: "hidden",
        },
        subjectLogoBg: {
            borderRadius: "50%",
        },
        pointerCursor: {
            cursor: "pointer",
        }
    })

// Customizable Area End

// Customizable Area Start
export class SearchExistingActivityModal extends SearchExistingActivityModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, activityData } = this.props;
        const { selectedRowId } = this.state;

        return (
            <Dialog className={`${classes.existingActivityContainer} ${classes.highlightBgColor}`} aria-labelledby="existing-activity-modal-title" scroll={'body'} open={open}>
                <Box className={`${classes.bgColor}`}>
                    <Grid container direction="row">
                        <Grid xs={12} style={{ padding: "30px 30px 0px 30px" }} item>
                            <Typography component={"span"} className={`${classes.fontText24} ${classes.textPrimaryColor}`}>{activityData[0]?.Lesson}</Typography>
                            <Button className={classes.textCommonColor} style={{ float: "right" }} onClick={this.props.onCancel}> X </Button>
                        </Grid>
                    </Grid>
                    <DialogContent className={classes.dialogContent}>
                        <Grid spacing={2} direction="row" container>
                            <Grid xs={12} item>
                                <Box className={classes.dividerLine} style={{ height: "1px" }} />
                            </Grid>
                            <Grid item xs={12} style={{ padding: "0px 8px 0px 8px" }} >
                                <Box className={`${classes.customTableStyles} ${classes.catalogCoursesRow}`} >
                                    <TableContainer className={classes.tableContainer} id="existing-activity-table">
                                        {activityData?.length > 0 ?
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {activityTableColumns.map((column: any) => (
                                                            <TableCell key={column.id}>
                                                                <Box display={'flex'}>
                                                                    <Typography className={`${classes.fontSize15} ${classes.textCommonColor}`} component={"span"}>
                                                                        {column.label}
                                                                    </Typography>
                                                                    {column?.icon ? <img src={column?.icon} className={classes.columnIcon} /> : ''}
                                                                </Box>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {activityData.map((row: any, i: any) => {
                                                        return (
                                                            <TableRow onClick={() => this.handleSelectRow(row.id)} tabIndex={-1} key={row.id}
                                                                className={`${selectedRowId === row.id ? classes.highlightCourseRow : ''} ${classes.pointerCursor}`}>
                                                                {activityTableColumns.map((column: any) => {
                                                                    return (
                                                                        <TableCell
                                                                            key={`${column.id}-${row.id}`}
                                                                            style={{
                                                                                maxWidth: "auto",
                                                                                minWidth: column.minWidth,
                                                                            }}
                                                                            className={`${classes.tableBodyCell}`}
                                                                        >
                                                                            {column.id === "Title" ?
                                                                                <Box display={"flex"} alignItems="center">
                                                                                    <Box mr={"10px"} className={classes.subjectLogoBg} >
                                                                                        <img style={{ padding: "5px" }} width={"32px"} src={message} />
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography className={`${classes.textPrimaryColor} ${classes.fontText15}`} component="span">
                                                                                            {row[column.id]?.name}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                :
                                                                                <Typography component={"span"} className={`${classes.fontSize15} ${classes.textPrimaryColor}`}>
                                                                                    {row[column.id]}
                                                                                </Typography>
                                                                            }
                                                                        </TableCell>
                                                                       
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                            :
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                <CustomEmptyResultComponent
                                                    message="No activity found. Search again!"
                                                    showActionButton={true}
                                                    buttonText={"Go Back"}
                                                    handleButtonClick={this.props.onCancel}
                                                />
                                            </Box>
                                        }
                                    </TableContainer>
                                </Box>
                            </Grid>
                            {activityData?.length > 0 &&
                                <Grid xs={12} item style={{ padding: "0px 30px 30px 30px" }} >
                                    <Box marginBottom={"10px"} style={{ width: "100%" }}>
                                        <CustomButton btnText="Copy" btnStyle={copyButtonStyle} buttonId={`activityCopyButton`} type={"button"} buttonVariant={"contained"} handleButtonClick={this.handleCopyActivity} isDisabled={false} />
                                        <CustomButton btnText="Cancel" btnStyle={cancelButtonStyle} buttonVariant={"outlined"} buttonId={`activityCancelButton`} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const cancelButtonStyle = {
    height: "50px",
    width: "120px",
    fontSize: "18px",
    padding: "10px 10px",
    border: "1px solid",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "32px",
    float: "right",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const copyButtonStyle = {
    width: "120px",
    height: "50px",
    padding: "10px 10px",
    textAlign: "center",
    fontSize: "18px",
    borderRadius: "12px",
    marginTop: "32px",
    marginLeft: "15px",
    fontWeight: 500,
    float: "right",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(SearchExistingActivityModal);
// Customizable Area End
