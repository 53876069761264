// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, IconButton } from "@material-ui/core";
import CatchMeUpModalSuccessController, {
  Props,
} from "./CatchMeUpModalSuccessController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "520px",
      },
      paperWidthSm: {
        maxWidth: "520px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class NewSchoolSuccesModal extends CatchMeUpModalSuccessController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          className={`${classes.highlightBgColor} ${
            classes.quizViewAnswerConfirmDialog
          }`}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
          open={open}
          style={webStyle.posRelative}
        >
          <Box className={`${classes.bgColor}`} display={"flex"} justifyContent={"flex-end"} style={webStyle.posAbsolute} >
            <IconButton
              data-test-id="crossCatch"
              aria-controls="long-menu"
              aria-label="more"
              style={{ float: "right" }}
              onClick={this.props.onCancel}
              aria-haspopup="true"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              id="quiz-view-answer-confirm-dialog-title"
            >
              <Box>
                <img src={SuccessIcon} alt="success icon" />
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }}>
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${
                    classes.fontText24
                  } ${classes.fontBold600}`}
                >
                  New School Day Created Successfully
                </Typography>
              </Box>
              <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                <Typography
                  className={`${classes.textCommonColor} ${
                    classes.fontText18
                  } ${classes.fontBold500}`}
                  component={"span"}
                >
                 Class
                </Typography>
                <Typography
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{fontSize:'16px'}}
                >
                 {" "}10A{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${
                    classes.fontText18
                  } ${classes.fontBold500}`}
                  component={"span"}
                >
                 will now hold  lessons on days select
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <CustomButton
                  buttonId="quiz-view-answer-cancel-btn"
                  btnStyle={schoolCancelButton}
                  btnText="Okay"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const schoolCancelButton = {
  padding: "5px",
  color: "#0D46BC",
  width: "100%",
  backgroundColor: "inherit",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #0D46BC",
  fontWeight: 600,
  fontSize: "16px",
} as const;
const webStyle={
  posRelative:{
    position:"relative" as "relative",
  },
  posAbsolute:{
    position:"absolute" as "absolute",
    top:"10px",
    right:"20px"
  }
}
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(NewSchoolSuccesModal);
// Customizable Area End
