import { FIELD_TYPES } from "../../../components/src/CommonType.web";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";

const roleOptionsMock = [
  { id: "teacher", label: "Teacher", value: "teacher" },
  { id: "student", label: "Student", value: "student" },
  {
    id: "course manager",
    label: "Course Manager (Tenant Admin)",
    value: "course manager",
  },
  { id: "registrar", label: "Registrar", value: "registrar" },
  { id: "parent", label: "Parent", value: "parent" },
  {
    id: "parent_teacher",
    label: "Parent Teacher",
    value: "parent_teacher",
  },
];

const rolePermissionsListMock = [
  {
    role_title: "Course Manager",
    base_role: "Tenant Admin",
    modifiedOn: "May 01, 2022",
    id: "1",
  },
  {
    role_title: "Gradebook Manager",
    base_role: "Tenant Admin",
    modifiedOn: "May 01, 2022",
    id: "2",
  },
  {
    role_title: "Appearance Manager",
    base_role: "Tenant Admin",
    modifiedOn: "May 01, 2022",
    id: "3",
  },
  {
    role_title: "Setup Manager",
    base_role: "Tenant Admin",
    modifiedOn: "May 01, 2022",
    id: "4",
  },
  {
    role_title: "User Manager",
    base_role: "Tenant Admin",
    modifiedOn: "May 01, 2022",
    id: "5",
  },
];

const rolePermissionsTableHeaderMock = [
  {
    columnId: 1,
    id: "name",
    label: "Role Title",
    type: tableColumnTypes.TEXT,
    width: "300px",
    maxWidth: "350px",
  },
  {
    columnId: 2,
    id: "base_role",
    label: "Base Role",
    type: tableColumnTypes.TEXT,
    width: "150px",
    maxWidth: "220px",
  },
  {
    columnId: 3,
    id: "modifiedOn",
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "120px",
  },
];

const roleDetailsInputConfigMock = [
  {
    id: "tenant-roles-permissions-role-title",
    name: "roleTitle",
    fieldName: "roleTitle",
    value: "",
    menuItems: [],
    label: "Role Title",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "Enter Role Title",
  },
  {
    id: "tenant-roles-permissions-base-role",
    name: "baseRole",
    fieldName: "baseRole",
    value: "Tenant Admin",
    menuItems: [],
    label: "Base Role",
    fieldType: FIELD_TYPES.TEXT,
    isDisabled: true,
    placeHolderText: "Enter Base Role",
  },
];

const permissionsListMock = [
  {
    id: "1",
    baseCategoryTitle: "Dashboard",
    icon: "dashboard",
    subCategory: [],
    subItems: [
      {
        id: "1",
        subItemTitle: "Dashboard",
        isReadChecked: true,
        isWriteChecked: true,
      },
    ],
  },
  {
    id: "2",
    baseCategoryTitle: "Setup",
    icon: "setup",
    subCategory: [],
    subItems: [
      {
        id: "1",
        subItemTitle: "School Year",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "2",
        subItemTitle: "Subjects",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "3",
        subItemTitle: "Curriculum",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "4",
        subItemTitle: "Class Management",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "5",
        subItemTitle: "Teacher Management",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "6",
        subItemTitle: "Gradebook",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "7",
        subItemTitle: "School Calendar",
        isReadChecked: false,
        isWriteChecked: false,
      },
    ],
  },
  {
    id: "3",
    baseCategoryTitle: "User Management",
    icon: "user_management",
    subCategory: [],
    subItems: [
      {
        id: "1",
        subItemTitle: "Students / Parents",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "2",
        subItemTitle: "Teachers / Parent Teachers",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "3",
        subItemTitle: "Others",
        isReadChecked: false,
        isWriteChecked: false,
      },
    ],
  },
  {
    id: "4",
    baseCategoryTitle: "Course Management",
    icon: "course_management",
    subCategory: [
      {
        id: "1",
        subCategoryTitle: "My Courses",
        subItems: [
          {
            id: "1",
            subItemTitle: "Courses Creation",
            isReadChecked: false,
            isWriteChecked: false,
          },
          {
            id: "2",
            subItemTitle: "Add Activity",
            isReadChecked: false,
            isWriteChecked: false,
          },
          {
            id: "3",
            subItemTitle: "Add Assessments",
            isReadChecked: false,
            isWriteChecked: false,
            showDisabled:true
          },
          {
            id: "4",
            subItemTitle: "Course Assignment (to class)",
            isReadChecked: false,
            isWriteChecked: false,
            showDisabled:true
          },
        ],
      },
      {
        id: "2",
        subCategoryTitle: "My Requests",
        subItems: [
          {
            id: "1",
            subItemTitle: "Pending",
            isReadChecked: false,
            isWriteChecked: false,
          },
          {
            id: "2",
            subItemTitle: "Approved",
            isReadChecked: false,
            isWriteChecked: false,
          },
          {
            id: "3",
            subItemTitle: "Rejected",
            isReadChecked: false,
            isWriteChecked: false,
          },
        ],
      },
      {
        id: "3",
        subCategoryTitle: "Catalog Management",
        subItems: [
          {
            id: "1",
            subItemTitle: "Creation Assignment",
            isReadChecked: false,
            isWriteChecked: false,
          },
          {
            id: "2",
            subItemTitle: "Assignment",
            isReadChecked: false,
            isWriteChecked: false,
            showDisabled:true
          },
        ],
      },
    ],
    subItems: [],
  },
  {
    id: "4",
    baseCategoryTitle: "Roles and Permissions",
    icon: "roles_permissions",
    subCategory: [],
    subItems: [
      {
        id: "1",
        subItemTitle: "Role Creation",
        isReadChecked: false,
        isWriteChecked: false,
      },
    ],
  },
  {
    id: "5",
    baseCategoryTitle: "Appearance Management",
    icon: "appearance_management",
    subCategory: [],
    subItems: [
      {
        id: "1",
        subItemTitle: "Branding",
        isReadChecked: false,
        isWriteChecked: false,
      },
      {
        id: "2",
        subItemTitle: "Themes",
        isReadChecked: false,
        isWriteChecked: false,
      },
    ],
  },
];

const participantsTableColumns = [
  {
    id: "Title_Participants",
    columnId: 1,
    label: "Title",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "120px",
  },
  {
    id: "Subject_Participants",
    columnId: 2,
    label: "Subject",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px"
  },
  {
    id: "Grade_Participants",
    columnId: 3,
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px"
  },
  {
    id: "TotalUsers_Participants",
    columnId: 4,
    label: "Total no. of users",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px",
  },
  {
    id: "ActiveUsers_Participants",
    columnId: 5,
    label: "No. of active users",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "120px",
  },
  {
    id: "LessonsPerDay_Participants",
    columnId: 6,
    label: "No. Lessons Per Day",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px"
  },
  {
    id: "CompletionRate_Participants",
    columnId: 7,
    label: "Completion Rate",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px"
  },
  {
    id: "TimesModified_Participants",
    columnId: 8,
    label: "Times Modified",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "80px"
  },
];

const participantsData = [
  {
    id: 1,
    Title_Participants: "Physics",
    Subject_Participants: "Science",
    Grade_Participants: "Grade 3",
    TotalUsers_Participants: "60",
    ActiveUsers_Participants: "55",
    LessonsPerDay_Participants: "10",
    CompletionRate_Participants: "80%",
    TimesModified_Participants: "10",
  },
  {
    id: 2,
    Title_Participants: "Physics",
    Subject_Participants: "Science",
    Grade_Participants: "Grade 3",
    TotalUsers_Participants: "60",
    ActiveUsers_Participants: "55",
    LessonsPerDay_Participants: "10",
    CompletionRate_Participants: "80%",
    TimesModified_Participants: "10",
  },
  {
    id: 3,
    Title_Participants: "Physics",
    Subject_Participants: "Science",
    Grade_Participants: "Grade 3",
    TotalUsers_Participants: "60",
    ActiveUsers_Participants: "55",
    LessonsPerDay_Participants: "10",
    CompletionRate_Participants: "80%",
    TimesModified_Participants: "10",
  },
  {
    id: 4,
    Title_Participants: "Physics",
    Subject_Participants: "Science",
    Grade_Participants: "Grade 3",
    TotalUsers_Participants: "60",
    ActiveUsers_Participants: "55",
    LessonsPerDay_Participants: "10",
    CompletionRate_Participants: "80%",
    TimesModified_Participants: "10",
  },
  {
    id: 5,
    Title_Participants: "Physics",
    Subject_Participants: "Science",
    Grade_Participants: "Grade 3",
    TotalUsers_Participants: "60",
    ActiveUsers_Participants: "55",
    LessonsPerDay_Participants: "10",
    CompletionRate_Participants: "80%",
    TimesModified_Participants: "10",
  },
  {
    id: 6,
    Title_Participants: "Physics",
    Subject_Participants: "Science",
    Grade_Participants: "Grade 3",
    TotalUsers_Participants: "60",
    ActiveUsers_Participants: "55",
    LessonsPerDay_Participants: "10",
    CompletionRate_Participants: "80%",
    TimesModified_Participants: "10",
  },
];
const titleMap = {
  "Dashboard": "dashboard",
  "School Year": "school_year",
  "Subjects": "subject",
  "Curriculum": "curriculum",
  "Class Management": "class_management",
  "Teacher Management": "teacher_management",
  "Gradebook": "gradebook",
  "School Calendar": "school_calender",
  "Students / Parents": "user_management_student_parent",
  "Teachers / Parent Teachers": "user_management_teacher_parent_teacher",
  "Others": "user_management_others",
  "Courses Creation": "course_management_course_creation",
  "Add Activity": "course_management_add_activity",
  "Add Assessments": "course_management_add_assessment",
  "Course Assignment (to class)": "course_management_course_assignment_to_class",
  "Pending": "my_request_pending",
  "Approved": "my_request_approve",
  "Rejected": "my_request_reject",
  "Creation Assignment": "catalogue_management_creation",
  "Assignment": "catalogue_management_assignment",
  "Role Creation": "roles_creation",
  "Branding": "appreance_management",
  "Themes": "appreance_management_2"
};

const restrictedRoles = [
  "Teacher",
  "Student",
  "Registrar",
  "Parent",
  "Parent Teacher",
  "District Admin",
  "Admin General",
  "School Admin",
];
export {
  roleOptionsMock,
  rolePermissionsListMock,
  rolePermissionsTableHeaderMock,
  roleDetailsInputConfigMock,
  permissionsListMock,
  participantsTableColumns,
  participantsData,
  titleMap,
  restrictedRoles
};
