import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export const courseDetailTabs = [
    {
        id: 1,
        label: "Grades",
        value: "grades"
    },
    {
        id: 2,
        label: "Attendance & Behaviour",
        value: "attendance_behaviour"
    },
]

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtSavedValue: string;
    selectedTabIndex: number;
    selectedTabName: string;
    openSubmitDialog: boolean;
    // Customizable Area Start
    userRole: string;
    isDataToBeSubmittedIncomplete: boolean;
    openReturnGradesDialog: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GradeUserDetailPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];
        
        this.state = {
            txtSavedValue: "A",
            selectedTabIndex: 0,
            selectedTabName: "Grades",
            openSubmitDialog: false,
            // Customizable Area Start
            userRole: "",
            isDataToBeSubmittedIncomplete: false,
            openReturnGradesDialog: false,
            // Customizable Area End
        };
        // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await super.componentDidMount();
        this.handleSetRole();
    }

    handleSetRole = async() => {
        const navigationParams = this.props.navigation?.getHistoryState();
        const userRole = navigationParams.userRole ? navigationParams.userRole : 'parentTeacher';
        this.setState({userRole});
        if(userRole === "registrar") this.setState({selectedTabName: ""});
        await setStorageData("role", userRole);
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ selectedTabIndex: newValue });
        if (newValue === 0) {
            this.setState({ selectedTabName: "Grades" });
        } else if (newValue === 1) {
            this.setState({ selectedTabName: "Attendance & Behaviour" });
        }
    };

    handleRedirectBack = () => {
        this.props.navigation?.goBack();
    }

    handleOpenSubmitDialog = async () => {
        const isDataIncomplete = await getStorageData('incompleteGradesSubmittedByTeacher');
        this.setState({ openSubmitDialog: true, isDataToBeSubmittedIncomplete: isDataIncomplete === 'true'});
    }

    handleCloseSubmitDialog = () => {
        this.setState({ openSubmitDialog: false });
    }

    validateDataToBeSubmitted = (isDataToBeSubmittedIncomplete: boolean) => {
        this.setState({isDataToBeSubmittedIncomplete})
    };

    handleReturnGradesDialogOpen = () => {
        this.setState({openReturnGradesDialog: true});
    };

    closeReturnGradesDialog = () => {
        this.setState({openReturnGradesDialog: false});
    };

    // Customizable Area End
}
