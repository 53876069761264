// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ArticleActivityTaskBoxController, {
  Props,
} from "./ArticleActivityTaskBoxController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomAudioPlayer from "../../../components/src/CustomAudioPlayer.web";
import { videoThumbnail, infoIcon } from "./assets";
import CustomVideoPlayer from "../../../components/src/CustomVideoPlayer.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomCheckBoxComponent from "../../../components/src/CustomCheckBoxComponent.web";
import CheckboxCheckIcon from "../../../components/src/SVGIcons/CheckboxCheckIcon.web";
import CheckboxUnCheckIcon from "../../../components/src/SVGIcons/CheckboxUnCheckIcon.web";
import TeacherCourseInfoModal from "./TeacherCourseInfoModal.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    title: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    activityImg: {
      objectFit: "contain",
      width: "-webkit-fill-available",
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
    },
    titleGrid: {
      marginBottom: "15px",
    },
    detailGrid: {
      marginBottom: "10px",
    },
    activityDetailBox: {
      minWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
    },
    completeLecture: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    completeLectureMargin: {
      marginLeft: "5px",
    },
    videoBottomTextTop: {
      flexDirection: "column",
    },
    videoTopTextBottom: {
      flexDirection: "column-reverse",
    },
    videoRightTextLeft: {
      flexDirection: "row",
    },
    videoLeftTextRight: {
      flexDirection: "row-reverse",
    },
    articlePreviewContent: {
      width: "100%",
      marginTop: "20px",
      padding: "0px",
      display: "flex",
      gap: "20px",
      overflow: "hidden",
    },
    infoIcon: {
      paddingLeft: "5px",
    },
  });

// Customizable Area End

// Customizable Area Start
export class ArticleActivityTaskBox extends ArticleActivityTaskBoxController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, detail, hideReadCheckbox, isStudent } =
      this.props;
    const { openInfoDialog } = this.state;
    return (
      <>
        <Box className={classes.activityDetailBox} p={"15px 12px"}>
          <Grid container direction="row">
            {detail?.activityTitle && (
              <Grid className={classes.titleGrid} item xs={12}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600}`}
                  component={"span"}
                >
                  {detail?.activityTitle}{" "}
                </Typography>
              </Grid>
            )}
            {detail?.typeOfActivity === "task" && detail?.task && (
              <Grid className={classes.detailGrid} item xs={12}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`}
                  component={"p"}
                  dangerouslySetInnerHTML={{ __html: detail.task }}
                ></Typography>
              </Grid>
            )}

            {detail?.typeOfActivity === "audio" &&
              detail?.audioDescPlacement && (
                <Box
                  className={`${
                    classes.detailGrid
                  } ${this.getClassToArrangeAudioContent(detail)}`}
                >
                  <Box>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`}
                      component={"p"}
                      dangerouslySetInnerHTML={{ __html: detail.description }}
                    ></Typography>
                  </Box>
                  <Box>
                    <CustomAudioPlayer
                      classes={classes}
                      currentTrack={{
                        src: detail?.mediaFile?.url,
                        isCompleted: detail?.isCompleted,
                        audioProgress: detail?.mediaProgress ?? 0,
                      }}
                      handleUpdateMediaProgressTime={
                        this.props.handleUpdateMediaProgressTime
                      }
                    />
                  </Box>
                </Box>
              )}

            {detail?.typeOfActivity === "video" &&
              detail?.videoDescPlacement && (
                <Box
                  className={`${
                    classes.detailGrid
                  } ${this.getClassToArrangeVideoContent(detail)}`}
                >
                  <Box width={this.getWidthOfVideoTextBox(detail)}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`}
                      component={"p"}
                      dangerouslySetInnerHTML={{ __html: detail.description }}
                    ></Typography>
                  </Box>
                  <Box width={this.getWidthOfVideoBox(detail)}>
                    <CustomVideoPlayer
                      currentVideo={{
                        src: detail?.mediaFile?.url,
                        isCompleted: detail?.isCompleted ?? false,
                        videoProgress: detail?.mediaProgress ?? 0,
                        thumbnail: videoThumbnail,
                      }}
                      classes={classes}
                      handleUpdateMediaProgressTime={
                        this.props.handleUpdateMediaProgressTime
                      }
                    />
                  </Box>
                </Box>
              )}

            {!hideReadCheckbox && (
              <Grid
                className={`${classes.detailGrid} ${classes.completeLecture}`}
                item
                xs={12}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <CustomCheckBoxComponent
                    fieldId={`${detail.title}`}
                    checked={detail?.activityCompletionProgress === 100}
                    checkedIcon={
                      <CheckboxCheckIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#fff"}
                        strokeWidth="1.5"
                        fill={"#FBD82C"}
                      />
                    }
                    uncheckIcon={
                      <CheckboxUnCheckIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#FBD82C"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    }
                    handleChange={() => this.handleMarkActivityComplete(true)}
                  />
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14} ${classes.fontBold500}`}
                    component={"span"}
                  >
                    I have completed this lecture
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>

        <TeacherCourseInfoModal
          open={openInfoDialog}
          title={'General Information'}
          courseInfo={null}
          activityInfo={"Lorem ipsum dolor sit amet, Fusce commodo augue lectus, sed vestibulum ipsum tristique non. Lorem consectetur adipiscing elit. Fusce commodo augue lectus, sed vestibulum ipsum tristique non."}
          onClose={this.handleCloseInfoDialogs}
        />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ArticleActivityTaskBox);
// Customizable Area End
