// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TeacherRejectedRequestController, {
  Props,
} from "./TeacherRejectedRequestController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import {
  ScienceColor,
  MathsColor,
  HistoryColor,
  EnglishColor,
  alert,
} from "./assets";

// Customizable Area End
const rejectedRequestsTableColumns = [
  {
    columnId: 1,
    id: "Title_rejected",
    label: "Title",
    type: tableColumnTypes.COURSES_NAME_ALERT,
  },
  {
    columnId: 2,
    id: "Subject_rejected",
    label: "Subject",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    columnId: 3,
    id: "Grade_rejected",
    type: tableColumnTypes.TEXT,
    label: "Grade",
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "RequestedOn_rejected",
    columnId: 4,
    label: "Requested On",
    type: tableColumnTypes.TEXT,
    width: "130px",
    maxWidth: "180px",
  },
  {
    columnId: 5,
    id: "RejectedOn_rejected",
    type: tableColumnTypes.TEXT,
    label: "Rejected On",
    width: "130px",
    maxWidth: "180px",
  },
  {
    columnId: 6,
    id: "CreatedOn_rejected",
    label: "Created On",
    type: tableColumnTypes.TEXT,
    width: "130px",
    maxWidth: "180px",
  },
  {
    id: "Last_Modi_On",
    columnId: 7,
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "130px",
    maxWidth: "200px",
  },
];

const rejectedRequestsData = [
  {
    id: 1,
    Title_rejected: {
      name: "Velocity and Acceleration",
      icon: ScienceColor,
      alertIcon: alert,
    },
    Subject_rejected: "Science",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 2,
    Title_rejected: {
      name: "Profit and Loss",
      icon: MathsColor,
      alertIcon: alert,
    },
    Subject_rejected: "Maths",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 3,
    Title_rejected: {
      name: "Roman History",
      icon: HistoryColor,
      alertIcon: alert,
    },
    Subject_rejected: "History",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 4,
    Title_rejected: {
      name: "Victorian Literature",
      icon: EnglishColor,
      alertIcon: alert,
    },
    Subject_rejected: "English",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 5,
    Title_rejected: {
      name: "Creative Writing",
      icon: EnglishColor,
      alertIcon: alert,
    },
    Subject_rejected: "English",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 6,
    Title_rejected: {
      name: "English Poetry",
      icon: EnglishColor,
      alertIcon: alert,
    },
    Subject_rejected: "English",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 7,
    Title_rejected: {
      name: "Velocity and Accelator",
      icon: ScienceColor,
      alertIcon: alert,
    },
    Subject_rejected: "Science",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 8,
    Title_rejected: {
      name: "Profit and Loss",
      icon: MathsColor,
      alertIcon: alert,
    },
    Subject_rejected: "Maths",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
  {
    id: 9,
    Title_rejected: {
      name: "Roman History",
      icon: HistoryColor,
      alertIcon: alert,
    },
    Subject_rejected: "History",
    Grade_rejected: "Grade 3",
    RequestedOn_rejected: "July 20, 2022",
    RejectedOn_rejected: "July 20, 2022",
    CreatedOn_rejected: "July 20, 2022",
    Last_Modi_On: "July 21, 2022",
  },
];
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rejectedDividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "30px",
      marginBottom: "4px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
  });

const courseRejectedTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
      },
      head: {
        padding: "16px 5px !important",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TeacherRejectedRequest extends TeacherRejectedRequestController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectRejectedTitleValue,
      selectRejectedSubjectValue,
      selectRejectedGradeValue,
      selectRejectedRequestedOnValue,
      selectRejectedAccpetedOnValue,
    } = this.state;

    return (
      <Box px={"24px"} pb={"19px"} data-test-id="RejectedValues">
        <Grid container spacing={3} direction="row" data-test-id="RejectedRow">
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <Box marginBottom={"8px"} data-test-id="RejectedValuesBox">
                <Typography
                  component={"span"}
                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                  data-test-id="RejectedTitle"
                >
                  Title
                </Typography>
              </Box>
              <Box>
                <CustomSelectDropdown
                  fieldName="selectRejectedTitleValue"
                  fieldId="titleDropdown"
                  handleChange={this.handleRejectedChangeDropdownValue}
                  fieldValue={selectRejectedTitleValue}
                  fieldError={false}
                  renderValue={this.renderRejectedTitleDropdownValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldStyles={RejectedDropdownStyle}
                  fieldMenuItems={this.getRejectedDropdownItemsArray("Title")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="RejectedDDGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="RejectedDDBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="RejectedDDRequested"
                >
                  Requested On
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="RejectedDDRequestedValues"
              >
                <CustomSelectDropdown
                  fieldId="requestedOnDropdown"
                  handleChange={this.handleRejectedChangeDropdownValue}
                  fieldName="selectRejectedRequestedOnValue"
                  fieldError={false}
                  fieldValue={selectRejectedRequestedOnValue}
                  fieldStyles={RejectedDropdownStyle}
                  renderValue={this.renderRejectedRequestedOnDDValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.state.RejectedRequestedComponentValue}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="RejectedDateValues">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="RejectedDateValuesTitle"
                >
                  Rejected On
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="RejectedDateValuesDD"
              >
                <CustomSelectDropdown
                  fieldId="rejectedOnDropdown"
                  handleChange={this.handleRejectedChangeDropdownValue}
                  fieldName="selectRejectedAccpetedOnValue"
                  fieldValue={selectRejectedAccpetedOnValue}
                  fieldError={false}
                  fieldStyles={RejectedDropdownStyle}
                  renderValue={this.renderRejectedOnDDValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={this.state.RejectedOnDDComponentValue}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="RejectedSubjectBox">
            <Box>
              <Box marginBottom={"8px"} data-test-id="RejectedSubjectCantainer">
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  data-test-id="RejectedSubjectBoxTitle"
                >
                  Subject
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"flex-start"}>
                <CustomSelectDropdown
                  fieldName="selectRejectedSubjectValue"
                  fieldValue={selectRejectedSubjectValue}
                  fieldId="subjectDropdown"
                  handleChange={this.handleRejectedChangeDropdownValue}
                  fieldError={false}
                  renderValue={this.renderRejectedSubjectDropdownValue}
                  fieldStyles={RejectedDropdownStyle}
                  fieldMenuItems={this.getRejectedDropdownItemsArray("Subject")}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="GradeGride">
            <Box>
              <Box marginBottom={"8px"} data-test-id="GradeGridBox">
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  data-test-id="GradeGrideTitle"
                >
                  Grade
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="GradeGrideDD"
              >
                <CustomSelectDropdown
                  fieldId="gradeDropdown"
                  fieldValue={selectRejectedGradeValue}
                  fieldError={false}
                  fieldName="selectRejectedGradeValue"
                  renderValue={this.renderRejectedGradeDropdownValue}
                  fieldStyles={RejectedDropdownStyle}
                  handleChange={this.handleRejectedChangeDropdownValue}
                  fieldMenuItems={this.getRejectedDropdownItemsArray("Grade")}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <CustomButton
                btnText="Search"
                btnStyle={RejectedCustomButtonStyle}
                buttonId={`requestsTabSearchButtons`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleRejectRequestsSearch}
                isDisabled={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.rejectedDividerLine} />
        <Grid item xs={12} data-test-id="ContainerTableGrid">
          <Box
            className={`${classes.customTableStyles} ${classes.rejectRequestsTableRow}`}
            data-test-id="ContainerTableBox"
          >
            <ThemeProvider theme={courseRejectedTableTheme}>
              <CustomTable
                tableId={"rejectedRequestesTable"}
                tableHeaderColumns={rejectedRequestsTableColumns}
                tableBodyData={rejectedRequestsData}
                showMenuIcon={true}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const RejectedCustomButtonStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginTop: "32px",
} as const;

const RejectedDropdownStyle = {
  width: "100%",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherRejectedRequest);
// Customizable Area End
