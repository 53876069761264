// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  studentProfile,
  parent1,
  parent2 as student2,
} from "./assets";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start

export interface IUserDetailProps {
  id: any;
  emailId: string;
  status: string;
  profilePic?: any;
  customFields: any[];

}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userFullName: string;
  userRole: string;
  userDetails: IUserDetailProps;
  curriculumList: Array<any>;
  wardsList: Array<any>;
  userId:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ViewUserProfileDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileDetailsCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      userFullName: "",
      userDetails: {
        id: undefined,
        emailId: "",
        status: "",
        customFields: []
      },
      curriculumList: [
        {
          id: 1,
          name: "Curriculum 1",
        },
        {
          name: "Curriculum 2",
          id: 2,
        },
        {
          id: 3,
          name: "Curriculum 3",
        },
        {
          id: 4,
          name: "Curriculum 4",
        },
        {
          name: "Curriculum 5",
          id: 5,
        },
        {
          id: 6,
          name: "Curriculum 6",
        },
      ],
      wardsList: [
        {
          studentId: 1,
          profilePic: studentProfile,
          fullName: "Ruby Cameron",
        },
        {
          studentId: 2,
          fullName: "John Cameron",
          profilePic: student2,
        },
        
      ],
      userRole: "",
      userId:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetParentTeacherDetails();
    // Customizable Area End
  }

  // Customizable Area Start

  handleSetParentTeacherDetails = () => {
    const nameFromParam = this.props.navigation?.getParam("fullName");
    const role = this.props.navigation?.getParam("role");
    this.setState({ userId: nameFromParam, userRole: role },()=>{
      this.getUserProfileDetailsById(nameFromParam)
    });
  };


  getUserProfileDetailsById =async (value: string) => {
    showCustomLoader();
    let token = await getStorageData("token")
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileDetailsCallId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont + "/" + value 
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  };
  HandleUserProfileDetailsByIdResponse(responseJson: {
    data: {
      id: string;
      attributes: {
        user_custom_fields?: {
          data: {
            attributes: {
              field_value: string;
            };
          }[];
        };
        expiring_on: string;
        profile_url: string;
        status: string;
        school_year: { title: string };
        role: { name: string };
        DOB: string;
        id_number: string;
        curriculum: { name: string };
        first_name: string;
        last_name: string;
        email: string;
        grade: { name: string };
        children:any[]
      };
    };
    errors:any
  }) {
    if(responseJson?.data){
    let getUserDetails = responseJson?.data.attributes;
    const userFullName = `${getUserDetails.first_name} ${getUserDetails.last_name.trim()}`;
    const customFields: any = getUserDetails?.user_custom_fields?.data?.map((item: any) => {
      return {
        id: item?.attributes?.id,
        label: item?.attributes?.label,
        value: item?.attributes?.field_value,
      }
    })
    const userDetails ={
      id:this.state.userId,
      emailId:getUserDetails.email || "N/A",
     profilePic:getUserDetails.profile_url || parent1,
     status:getUserDetails.status || "N/A",
     customFields: customFields
    }
    const wardsList = getUserDetails.children.map(child => ({
      studentId: child.id,
      profilePic: child.profile_url,
      fullName: `${child.first_name} ${child.last_name}`.trim()
    }));
    const curriculumList = [];
    if(getUserDetails?.curriculum){
      const curriculumDetails =  {
        id: 1,
        name: getUserDetails.curriculum.name,
      }
      curriculumList.push(curriculumDetails);
    }
    this.setState({userFullName,userDetails,wardsList,curriculumList})
    }
    else{
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == null) {
      return;
    }

    if (apiRequestCallId !== null) {
      if( apiRequestCallId===this.getUserProfileDetailsCallId){
        this.HandleUserProfileDetailsByIdResponse(responseJson);
      }
    }
  }
  // Customizable Area End
}
// Customizable Area End
