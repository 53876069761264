// Customizable Area Start
import React from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import StudentCourseJourneyBoxController, {
  Props,
} from "./StudentCourseJourneyBoxController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomProgressCircle from "../../../components/src/CustomProgressCircle.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { Shape, CheckMark } from "../src/assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChangeDueDateTopicJourneyModal from "./ChangeDueDateTopicJourneyModal.web";
import ChangeDueDateLessonModal from "./ChangeDueDateLessonModal.web";
import { MarkAsCompleteSuccessDialog } from "./MarkAsCompleteSuccessDialog.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    columnDetailRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "55px",
      width: "100%",
      borderBottom: "1px solid #f1f1f1",
      position: "relative",
      boxSizing: "border-box",
    },
    selectedTopicBox: {
      borderLeft: "3px solid #0D46BC",
    },
    menuIconButton: {
      "&.MuiIconButton-root": {
        padding: "4px",
      },
    },
    titles: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    dragIcon: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "40px",
      padding: "0px",
      position: "absolute",
      top: "0",
      left: "0",
      backgroundColor: "rgba(0, 157, 255, 0.05)",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentCourseJourneyBox extends StudentCourseJourneyBoxController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  renderUIToolChip=(isFromTenant: any,isStudent: any,classes: { dragIcon: string | undefined; })=>{
    return (
      !(isFromTenant || isStudent) && (
        <Box className={classes?.dragIcon}>
          <img src={Shape} alt="" />
        </Box>
      )
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, key, detail, selectedItemId, showInfo, isFromTenant, isStudent,isActivityWrite } =
      this.props;
    return (
      <Box
        key={key}
        paddingLeft={(isFromTenant || isStudent) ? '20px' : '54px'}
        paddingRight={isStudent ? "10px": "0px"}
        className={
          selectedItemId === detail?.id
            ? `${classes?.columnDetailRow} ${classes?.selectedTopicBox}`
            : `${classes?.columnDetailRow}`
        }
      >
        {this.renderUIToolChip(isFromTenant,isStudent,classes)}
        <Box className={classes?.titles} display={"flex"} alignItems={"center"}>
          <Typography
            data-testid={`journey-box-${detail?.title}`}
            onClick={() => this.props.handleSelectedItem(detail?.id)}
            className={
              selectedItemId === detail?.id
                ? `${classes?.textPrimaryColor} ${classes?.fontText16} ${classes?.fontBold600} ${classes?.titles} ${classes?.cursorPointer}`
                : `${classes?.textPrimaryColor} ${classes?.titles} ${classes?.fontText16} ${classes?.fontBold500} ${classes?.cursorPointer}`
            }
            component={"span"}
          >
            {detail?.title && (
              <>
                {detail?.title.split(" & ").map((part, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {part}
                  </React.Fragment>
                ))}
              </>
            )}
          </Typography>
          {!isFromTenant && showInfo && (
            <Box ml={"5px"}>
              <InfoOutlinedIcon
                data-test-id={`journeyone`}
                className={`${classes?.primaryColor14} ${classes?.cursorPointer}`}
                onClick={(event: any) => this.handleInfoIconClick(event)}
              />
            </Box>
          )}
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          {!isFromTenant && (
            <>
              {detail?.completionProgress < 100 ? (
                <CustomProgressCircle
                  centerTextFontSize="9px"
                  progressDataPosition={{
                    left: "6px",
                    top: "6px",
                  }}
                  progressCircleDimensions={42}
                  progressData={{
                    progressPercentage: detail?.completionProgress || 0,
                    hoverText: detail?.title || "",
                  }}
                  customClasses={classes}
                />
              ) : (
                <img src={CheckMark} style={{ marginRight: "10px" }} />
              )}
            </>
          )}
          {(!isStudent &&  isActivityWrite)  &&<IconButton
            className={classes?.menuIconButton}
            aria-label="more"
            aria-controls="long-menu"
            id="journeyNew"
            aria-haspopup="true"
            onClick={(e: any) => this.handleEditActionClick(e, detail.id)}
          >
            <MoreVertIcon className={`${classes?.textCommonColor}`} />
          </IconButton>}
        </Box>
        <Menu
          id={`course-table-menu-action`}
          anchorEl={this.state.menuAnchorEl}
          open={Boolean(this.state.menuAnchorEl)}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {
            !isFromTenant && (
              <Box>
                <MenuItem data-test-id="handleMenuModalOpen" onClick={()=>{
                  this.handleMenuModalOpen()
                  this.handleOptionClick("Change Due Date", detail.id);
                }}>
                  Change due date
                </MenuItem>
                <MenuItem data-test-id="handleCoursesMarkCompleteModal" onClick={()=>{
                this.handleCoursesMarkCompleteModal()
                this.handleOptionClick("Mark As Complete", detail.id);
                }}>
                  Mark As Complete
                </MenuItem>
              </Box>
            )
          }
          {
            isFromTenant && (
              <Box>
                <MenuItem onClick={this.handleEditMenuOption} >Edit</MenuItem>
                <MenuItem onClick={this.handleDeleteMenuOption} >Delete</MenuItem>
              </Box>
            )
          }
        </Menu>
        <ChangeDueDateLessonModal
          open={this.state.openChangeDueDatelessonModal}
          onCancel={this.handleCloseChangeDueDateModal}
          onConfirm={this.handleConfirmClick}
          getDate={this.dueDateChange}
          menuType={this.props.menuType}
        />
        <MarkAsCompleteSuccessDialog
        open={this.state.openMarkCompletedModal}
        onCancel={this.handleCloseMarkCompletedModal}
        onConfirm={this.handleMarkAsCompleteConfirm}
        detail={this.props.detail}
        classes={this.props.classes}
        courseData={this.props.courseData}
        markAsCompleteSuccessModal={this.state.markAsCompleteSuccessModal}
        type={this.props.menuType}
      />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentCourseJourneyBox);
// Customizable Area End
