import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
// Customizable Area Start
import {
  AttandanceTableHead,
  BehaviorsTableHead,
  gradebookTableHead,
  parentProgressColors,
  progressColors,
  registrarProgressColorsListMock,
  studentListTableColumns,
} from "../assets/mockdata";
import React from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import {
  ICustomTableProps,
  ITableActionBtnConfigProps,
} from "../../../components/src/CommonType.web";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { sort } from "./assets";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
import  AttandanceTable  from "../../../components/src/gradebook/tables/AttandanceTable.web";
import GradeTable  from "../../../components/src/gradebook/tables/GradeTable.web";
import  GradebookTemplateDownload  from "../../../components/src/gradebook/GradebookTemplateDownload.web";
import  BehaviourTable  from "../../../components/src/gradebook/tables/BehaviourTable.web";
import  StudentDetails  from "../../../components/src/gradebook/tables/StudentDetails.web";
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studentsDDList: Array<{
    id: number | string;
    value: number | string;
    label: string;
  }>;
  studentListTableBody: Array<any>;
  studentListTableColumns: Array<ICustomTableProps>;
  openGradesSubmissionModal: boolean;
  entireSchoolYearDataList: Array<{
    id: number | string;
    semesters: Array<{
      id: number;
      title: string;
    }>;
  }>;
  teacherSchoolYearsList: Array<{
    id: string;
    value: string;
    label: string;
  }>;
  teacherCurriculumsList: Array<{
    id: string;
    value: string;
    label: string;
  }>;
  gradeApprovalList: Array<{
    id: string;
    label: string;
    value: string;
  }>;
  selectedSchoolYearValue: number | string;
  selectedCurriculumValue: number | string;
  selectedStudentValue: number | string;
  selectedSortByGpaValue: string;
  selectedGradeApprovalValue: string;
  currentRole: string;
  progressColorOptions: Array<any>;
  sortByGPA: Array<{
    id: string;
    label: string;
    value: string;
  }>;
  studentGradebookActionBtnConfig: Array<ITableActionBtnConfigProps>;
  gradebookTableSemestersColumn: Array<ICustomTableProps>;
  semesterAvgSubmitted: Array<number>;
  selectedTemplateData:any;
  cachedPdfContent:any;
  isParent: boolean;
  // Customizable Area End
}

export const parentTableColumns = [
  {
      id: "Name",
      columnId: 1,
      label: "Name",
      type: tableColumnTypes.STUDENT_ICON,
      width: "250px",
      maxWidth: "280px"
  },
  {
      id: "Progress",
      columnId: 2,
      label: "Progress",
      icon: sort,
      type: tableColumnTypes.GRADEBOOK_PROGRESS_BAR,
      isSortingColumn: true,
      sortByOrder: "",
  },
  {
      id: "q1Avg",
      columnId: 3,
      label: "Q1 Avg",
      icon: sort,
      type: tableColumnTypes.PROGRESS_TEXT,
      isSortingColumn: true,
      styles: { color: "#01C996", fontWeight: "bold" }
  },
  {
      id: "q2Avg",
      columnId: 4,
      label: "Q2 Avg",
      icon: sort,
      type: tableColumnTypes.PROGRESS_TEXT,
      isSortingColumn: true,
      styles: { color: "#01C996", fontWeight: "bold" }
  },
  {
      id: "q3Avg",
      columnId: 5,
      label: "Q3 Avg",
      icon: sort,
      type: tableColumnTypes.PROGRESS_TEXT,
      isSortingColumn: true,
      styles: { color: "#01C996", fontWeight: "bold" }
  },
  {
      id: "q4Avg",
      columnId: 6,
      label: "Q4 Avg",
      icon: sort,
      type: tableColumnTypes.PROGRESS_TEXT,
      isSortingColumn: true,
      styles: { color: "#01C996", fontWeight: "bold" }
  },
  {
      id: "gpa",
      columnId: 7,
      label: "GPA",
      icon: sort,
      type: tableColumnTypes.PROGRESS_TEXT,
      isSortingColumn: true,
  },
  {
      id: "lastAccess",
      columnId: 8,
      label: "Last Access",
      icon: sort,
      type: tableColumnTypes.DUE_DATE
  },
];


export default class TeacherGradebookController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCurriculumList: string = "";
  apiGetSchoolYearList: string = "";
  apiGetStudentsDDList: string = "";
  apiGetStudentsGradebookList: string = "";
  apiPostSubmitGradebook: string = "";
  apiGetTemplateApiCallId:string= "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      studentsDDList: [],
      cachedPdfContent:"",
      studentListTableBody: [],
      studentListTableColumns: studentListTableColumns,
      openGradesSubmissionModal: false,
      teacherCurriculumsList: [],
      teacherSchoolYearsList: [],
      gradeApprovalList: [
        {
          id: "open",
          label: "Open",
          value: "open",
        },
        {
          id: "waiting_teacher_approval",
          label: "For Advisor Approval",
          value: "waiting_teacher_approval",
        },
        {
          id: "waiting_registrar_approval",
          label: "For Registrar Approval",
          value: "waiting_registrar_approval",
        },
        {
          id: "lockedin",
          label: "Locked In",
          value: "lockedin",
        },
        {
          id: "rejected",
          label: "Rejected",
          value: "rejected",
        },
      ],
      entireSchoolYearDataList: [],
      selectedSchoolYearValue: "",
      selectedCurriculumValue: "",
      selectedStudentValue: "",
      selectedSortByGpaValue: "",
      selectedGradeApprovalValue: "",
      currentRole: "",
      selectedTemplateData:{},
      progressColorOptions: progressColors,
      sortByGPA: [
        {
          id: "ascending",
          label: `Ascending`,
          value: `ascending`,
        },
        {
          id: "descending",
          label: `Descending`,
          value: `descending`,
        },
      ],
      studentGradebookActionBtnConfig: [
        {
          label: "View Student Profile",
          action: (rowId: string) => this.handleGoToStudentProfile(rowId),
        },
        {
          label: "Open Gradebook",
          action: (rowId: string) => this.goToUserDetailPage(rowId),
          // isDisabled: true,
        },
        {
          label: "Print Unofficial",
          action: (rowId: string) => this.handleGetTemplateApi("unofficial_template",rowId),
          isDisabled: false,
        }, 
        {
          label: "Print Official",
          action: (rowId: string) => this.handleOfficalPrintTeample(rowId),
          isDisabled: false,
        },
      ],
      gradebookTableSemestersColumn: [],
      semesterAvgSubmitted: [],
      isParent: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setupViewByRole();
    this.handleSetRoleInStorage();
    this.handleGetTeacherSchoolYearList();
    this.handleGetCurriculumsList();
  }

  setupViewByRole = async () => {
    const role = await getStorageData('role');
    
    if (role === "Parent") this.setState({ isParent: true, progressColorOptions: parentProgressColors })
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetSchoolYearList:
            {
              this.handleGetTeacherSchoolYearDataResponse(responseJson);
            }
            break;
          case this.apiGetCurriculumList:
            {
              this.handleGetTeacherCurriculumDataResponse(responseJson);
            }
            break;
          case this.apiGetStudentsDDList:
            {
              this.handleGetGradebookStudentDDDataResponse(responseJson);
            }
            break;
          case this.apiGetStudentsGradebookList:
            {
              this.handleGetGradebookStudentTableDataResponse(responseJson);
            }
            break;
          case this.apiPostSubmitGradebook:
            {
              this.handlePostTeacherGradebookResponse(responseJson);
            }
            break;
          case this.apiGetTemplateApiCallId:
            {
              this.handleGetTemplateResponse(responseJson)
            }
        }
      }
    }
  }
  handleOfficalPrintTeample = (studentId: any)=>{
    const selectedStudent = this.state.studentListTableBody.find((student: any) => student.id === studentId);
    for (const key in selectedStudent) {
      if (key.toLowerCase().includes('avg')) {
        const quarterStatus = selectedStudent[key]?.status;
        if (quarterStatus === 'lockedin') {
          this.handleGetTemplateApi("official_template",studentId)
          return;
        }
      }
    }
    toast.error("The gradebook has not been locked by the registrar.");
  }
  handleSetRoleInStorage = async () => {
    const currentRole = await getStorageData("role");
    if (currentRole === "Registrar") {
      this.setState({
        currentRole: "registrar",
        progressColorOptions: registrarProgressColorsListMock,
      });
    } else {
      this.setState({ currentRole: "teacher" });
    }
  };

  generatePDF = async (data1: any, data2: any, pageTypeName: any) => {
    const marginLeft = 10;
    const marginRight = 10;
    const marginTop = 10;
    const marginBottom = 10;
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.top = "-9999px";
    container.style.width = "100%";
    container.style.height = "auto";
    container.style.overflow = "hidden";
    
    const content1 = document.createElement("div");
    const content2 = document.createElement("div");


    container.appendChild(content1);
    container.appendChild(content2);

    content1.innerHTML = data1;
    content2.innerHTML = data2;

    document.body.appendChild(container);

    const pdfOptions = {
        scale: 2,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        windowWidth: container.scrollWidth,
        windowHeight: container.scrollHeight
    };
    await html2canvas(content1, pdfOptions).then((canvas1) => {
        const imgData1 = canvas1.toDataURL("image/jpeg");

        const pdf = new jsPDF(pageTypeName, "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const canvasWidth1 = canvas1.width;
        const canvasHeight1 = canvas1.height;
        const imgHeight1 = (canvasHeight1 * (pdfWidth - marginLeft - marginRight)) / canvasWidth1;

        const fitScale1 = Math.min(1, (pdfHeight - marginTop - marginBottom) / imgHeight1);
         const scaledImgHeight1 = imgHeight1 * fitScale1;
            pdf.addImage(
                imgData1,
                "JPEG",
                marginLeft,
                marginTop,
                pdfWidth - marginLeft - marginRight,
                scaledImgHeight1
            );
        const pdfOptions2 = {
            ...pdfOptions,
            width: content2.scrollWidth,
            height: content2.scrollHeight,
            windowWidth: container.scrollWidth,
            windowHeight: container.scrollHeight,
        };

        html2canvas(content2, pdfOptions2).then((canvas2) => {
            const imgData2 = canvas2.toDataURL("image/jpeg");

            const canvasWidth2 = canvas2.width;
            const canvasHeight2 = canvas2.height;

            const imgHeight2 = (canvasHeight2 * (pdfWidth - marginLeft - marginRight)) / canvasWidth2;
            const fitScale2 = Math.min(1, (pdfHeight - marginTop - marginBottom) / imgHeight2);
            const scaledImgHeight2 = imgHeight2 * fitScale2;

          
            pdf.addPage();
            pdf.addImage(
                imgData2,
                "JPEG",
                marginLeft,
                marginTop,
                pdfWidth - marginLeft - marginRight,
                scaledImgHeight2
            );

          
            pdf.save("download.pdf");

           
            document.body.removeChild(container);
        });
    });
};


  handleGoToStudentProfile = (studentId?: string) => {
    const { currentRole, studentListTableBody } = this.state;
    const selectedStudent = studentListTableBody.find((student: any) => student.id === studentId);
    if(selectedStudent){
      if (currentRole === "teacher") {
        this.props.navigation.navigate(
          "StudentProfileProgress",
          {},
          {
            studentId: selectedStudent?.Name?.studentId,
          }
        );
      }
    }
  };

  renderSortByGPADropdownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Sort By";
    }
    const { sortByGPA } = this.state;
    const selectedValue = sortByGPA.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };

  renderGradeApprovalDropdownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade Approval";
    }
    const filteredList = this.getFilteredGradeApprovalList();
    const selectedValue = filteredList.find(
      (status) => status?.value === selected
    );
    if (this.state.currentRole === "registrar" && selected === "waiting_registrar_approval") {
      return "Open";
    }
    
    return selectedValue?.label;
  };

  getFilteredGradeApprovalList = () => {
    const { gradeApprovalList, currentRole } = this.state;
    const valuesToExclude = [
      "waiting_teacher_approval",
      "waiting_registrar_approval"
    ];
    if (currentRole === "registrar") {
      return gradeApprovalList.filter(status => !valuesToExclude.includes(status.value));
    }
    return gradeApprovalList;
  };
  renderSchoolYearDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { teacherSchoolYearsList } = this.state;
    const selectedValue = teacherSchoolYearsList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };

  renderCurriculumDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { teacherCurriculumsList } = this.state;
    const selectedValue = teacherCurriculumsList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };

  renderStudentsDropDownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Student";
    }
    const { studentsDDList } = this.state;
    const selectedValue = studentsDDList.find(
      (status) => status?.value === selected
    );
    return selectedValue?.label;
  };

  onDropdownValueChange = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    _child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;

    if (name === "selectedGradeApprovalValue") {
      const { currentRole } = this.state;
      if (currentRole === "registrar" && fieldValue === "open") {
        fieldValue = "waiting_registrar_approval";
      }
    }

    if (fieldValue) {
      this.setState(
        (prev) => {
          return {
            ...prev,
            [name]: fieldValue,
          };
        },
        () => {
          this.handleStateChange(name)
        }
      );
    }
  };
  handleStateChange(name: string | undefined) {
    if (
      name === "selectedSchoolYearValue" ||
      name === "selectedCurriculumValue"
    ) {
      this.setState({ selectedStudentValue: "" });
      this.handleSetSemesterColumns();
    }
    if (name === "selectedCurriculumValue") {
      this.handleGetGradebookStudentsDDList();
    }
    if (name === "selectedStudentValue") {
      this.handleGetGradebookStudentsTableList();
    }
    if (name === "selectedSortByGpaValue") {
      this.handleSortByGPA();
    }
    if (name === "selectedGradeApprovalValue") {
      this.handleGetGradebookStudentsTableList();
    }
  }

  handleSortByGPA = () => {
    const { selectedSortByGpaValue, studentListTableBody, studentListTableColumns } = this.state;
    const sortedStudentsList = _.sortBy(studentListTableBody, "gpa");
    const updatedColumns = studentListTableColumns.map((column) => {
      if(column.id === "gpa"){
        column.sortByOrder = selectedSortByGpaValue
      }
      return column;
    })
    this.setState({ studentListTableColumns: updatedColumns })
    if (selectedSortByGpaValue === "ascending") {
      this.setState({ studentListTableBody: sortedStudentsList });
    } else {
      const descendingSortedList = sortedStudentsList.reverse();
      this.setState({ studentListTableBody: descendingSortedList });
    }
  };

  goToUserDetailPage = (studentId?: string) => {
    const { studentListTableBody } = this.state;
    const selectedStudent = studentListTableBody.find((student: any) => student.id === studentId);
    const data = selectedStudent?.Name;
 
    this.openUserDetailPage(data);
   
  };

  onStudentSelectDeselect = (data: any) => {
    const students = this.state.studentListTableBody;
    const selectedStudent = students.find((student) => student.id === data.id);
    if (selectedStudent) {
      selectedStudent.isSelected = !!!selectedStudent.isSelected;
      this.setState({
        studentListTableBody: students,
      });
    }
  };

  onColumnSelectDeselect = (data: any) => {
    const columns = this.state.studentListTableColumns;
    const activeColumn = columns?.find(
      (column) => column.columnId === data.columnId
    );
    if (activeColumn) {
      activeColumn.isSelected = !!!activeColumn.isSelected;
      this.setState({
        studentListTableColumns: columns,
      });
    }
  };

  onColumnSort = (data: any) => {
    const { studentListTableBody, studentListTableColumns } = this.state;
    const updatedStudentTableColumns = studentListTableColumns.map((column) => {
      if (data?.id === column?.id) {
        if (!data?.sortByOrder || data?.sortByOrder === "descending") {
          column.sortByOrder = "ascending";
        } else {
          column.sortByOrder = "descending";
        }
      }
      return column;
    });
    const selectedColumn = updatedStudentTableColumns.find(
      (column) => column.id === data?.id
    );
    this.setState(
      { studentListTableColumns: updatedStudentTableColumns },
      () => {
        if (selectedColumn?.sortByOrder === "ascending") {
          const ascSortedStudentList = _.sortBy(
            studentListTableBody,
            (item) => {
              if (typeof item[data?.id] === "object")
                return item[data?.id]?.value;
              else return item[data?.id];
            }
          );
          this.setState({ studentListTableBody: ascSortedStudentList });
        } else {
          const descSortedStudentList = _.sortBy(
            studentListTableBody,
            (item) => {
              if (typeof item[data?.id] === "object")
                return item[data?.id]?.value;
              else return item[data?.id];
            }
          ).reverse();
          this.setState({ studentListTableBody: descSortedStudentList });
        }
      }
    );
  };

  onSelectAllStudents = (event: any) => {
    const selected = event.currentTarget.checked;
    const students = this.state.studentListTableBody;
    students.forEach((student) => (student.isSelected = selected));
    this.setState({
      studentListTableBody: students,
    });
  };

  openSubmitConfirmationModal = () => {
    this.setState({
      openGradesSubmissionModal: true,
    });
  };

  cancelGradesSubmission = () => {
    this.setState({
      openGradesSubmissionModal: false,
    });
  };

  submitStudentGrades = () => {
    const { studentListTableBody, studentListTableColumns } = this.state;
    const selectedColumn = studentListTableColumns.filter(
      (column) => column?.isSelected
    );
    const selectedStudents = studentListTableBody.filter(
      (student) => student.isSelected
    );
    let semesterAvgList: Array<number> = [];
    selectedStudents.forEach((student) => {
      selectedColumn.forEach((column) => {
        if (student.hasOwnProperty(column.label)) {
          semesterAvgList.push(student?.[column.label]?.semesterAvgId);
        }
      });
    });
    this.setState({
      semesterAvgSubmitted: semesterAvgList,
      openGradesSubmissionModal: false,
    }, () => this.handlePostTeacherGradebook());
  };

  handleSetSemesterColumns = () => {
    const { selectedSchoolYearValue, entireSchoolYearDataList } = this.state;
    const selectedSchoolYearSemesterList =
      entireSchoolYearDataList.find(
        (item) => item?.id === selectedSchoolYearValue
      )?.semesters || [];
    const semesterList: Array<any> = selectedSchoolYearSemesterList?.map(
      (semesterItem) => {
        return {
          columnId: `${semesterItem?.id}`,
          id: `${semesterItem?.title} Avg`,
          label: `${semesterItem?.title} Avg` ,
          type: tableColumnTypes?.GRADEBOOK_AVG,
          icon: sort,
          isSelected: false,
          isSortingColumn: true,
          sortByOrder: "",
          
        };
      }
    );
    let columns = [...studentListTableColumns];
    columns.splice(2, 0, ...semesterList);
    this.setState(
      {
        gradebookTableSemestersColumn: semesterList,
        studentListTableColumns: [...columns],
      },
      () => {
        this.handleGetGradebookStudentsDDList();
      }
    );
  };

  handleGetTeacherSchoolYearList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherSchoolYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherSchoolYearDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        title: string;
        semesters: Array<{
          id: number;
          title: string;
        }>;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const { studentListTableColumns } = this.state;
      const semesterList = responseJson?.data?.[0]?.attributes?.semesters?.map(
        (semester) => {
          return {
            columnId: `${semester?.id}`,
            id: `${semester?.title} Avg`,
            label: `${semester?.title} Avg`,
            type: tableColumnTypes?.GRADEBOOK_AVG,
            icon: sort,
            isSelected: false,
            isSortingColumn: true,
            sortByOrder: "",
          };
        }
      );
      const columns = [...studentListTableColumns];
      columns.splice(2, 0, ...semesterList);
      const schoolYearList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          value: item?.id,
          label: item?.attributes?.title,
        };
      });
      const allSchoolYearList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          semesters: item?.attributes?.semesters,
        };
      });
      this.setState(
        {
          teacherSchoolYearsList: schoolYearList,
          selectedSchoolYearValue: schoolYearList?.[0]?.id || "",
          studentListTableColumns: [...columns],
          selectedStudentValue: "",
          entireSchoolYearDataList: allSchoolYearList,
          gradebookTableSemestersColumn: semesterList,
        },
        () => {
          this.handleGetGradebookStudentsDDList();
        }
      );
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };

  handleGetCurriculumsList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teacherCurriculumsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherCurriculumDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        name: string;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const curriculumList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          value: item?.id,
          label: item?.attributes?.name,
        };
      });
      this.setState({ teacherCurriculumsList: curriculumList });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };

  handleGetGradebookStudentsDDList = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const { selectedCurriculumValue, selectedSchoolYearValue } = this.state;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentsDDList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teacherGradebookDDEndpoint}?curriculum_id=${selectedCurriculumValue}&school_year_id=${selectedSchoolYearValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetGradebookStudentDDDataResponse = (responseJson: any) => {
    if (responseJson?.data?.length) {
      const studentsDropdownAll: Array<{
        id: number | string;
        value: number | string;
        label: string;
      }> = [
          {
            id: "all",
            label: "All Students",
            value: "all",
          },
        ];
      const studentsDDList = responseJson.data?.map(
        (item: {
          id: number | string;
          first_name: string;
          last_name: string;
        }) => {
          return {
            id: item?.id,
            value: item?.id,
            label: `${item?.first_name} ${item?.last_name}`,
          };
        }
      );
      this.setState(
        {
          studentsDDList: studentsDropdownAll.concat(studentsDDList),
        },
        () => {
          if (responseJson.data?.length > 0) {
            this.setState({ selectedStudentValue: "all" }, () => {
              this.handleGetGradebookStudentsTableList();
            });
          }
        }
      );
    } else {
      this.setState({ studentsDDList: [], studentListTableBody: [] });
      displayApiErrorMsg({}, this.props.navigation);
    }
  };

  handleGetGradebookStudentsTableList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const { selectedStudentValue, studentsDDList, selectedGradeApprovalValue } =
      this.state;
    let requestUrl = configJSON.teacherGradebookStudentsListEndpoint;
    if (selectedStudentValue !== "all") {
      requestUrl = requestUrl + `?student_id=[${selectedStudentValue}]`;
    }

    if (selectedStudentValue === "all") {
      const classIds = studentsDDList
        .filter((student) => student.value !== "all")
        .map((item) => {
          return item.value;
        });
      requestUrl = requestUrl + `?student_id=[${classIds}]`;
    }

    if (selectedGradeApprovalValue) {
      requestUrl = requestUrl + `&grade_approval=${selectedGradeApprovalValue}`;
    }else{
      requestUrl = requestUrl + `&grade_approval=`;
    }
    requestUrl = requestUrl + `&school_year_id=${this.state.selectedSchoolYearValue}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStudentsGradebookList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetGradebookStudentTableDataResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        first_name: string;
        last_name: string;
        id: string;
        profile_url: string;
        error_msg?:string;
        progress: {
          completed: string;
        };
        gpa: number;
        semsester_avg: Array<{
          id: number;
          status: string;
          semester_avg: number;
        }>;
        last_access: string;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const {currentRole, gradebookTableSemestersColumn, selectedSortByGpaValue } =
        this.state;
      const gradebookStudentsList: Array<any> = [];
      responseJson?.data?.forEach((student) => {
        const errorMsg = (student.attributes.error_msg !== 'No message') ? student.attributes.error_msg : undefined;
        let studentObj: any = {
          id: student?.id,
          Name: {
            name: `${student?.attributes?.first_name} ${student?.attributes?.last_name}`,
            icon: student?.attributes?.profile_url,
            studentId: student?.attributes?.id, 
            error_msg: errorMsg,           
          },
          Progress: student?.attributes?.progress?.completed || 0,
          gpa: student?.attributes?.gpa || 0,
          lastAccess: student?.attributes?.last_access
            ? moment(student.attributes.last_access).format("MMM DD, YYYY")
            : "NA",
          isSelected: false,
          q1Avg: 12,
          q2Avg: 12,
          q3Avg: 12,
          q4Avg: 12
        };
        gradebookTableSemestersColumn?.forEach((column, index) => {
        const semesterAvg = student?.attributes?.semsester_avg?.[index];
        const status = semesterAvg?.status;

        const displayStatus = this.getDisplayStatus(currentRole, status);
          studentObj[column.label] = {
            value:
              student?.attributes?.semsester_avg?.[index]?.semester_avg || 0,
            status: displayStatus,
            semesterId: column?.columnId,
            semesterAvgId: student?.attributes?.semsester_avg?.[index]?.id,
          };
        });
        gradebookStudentsList.push(studentObj);
      });
      const sortedStudentsTableList = _.sortBy(gradebookStudentsList, "gpa");
      if (selectedSortByGpaValue === "ascending") {
        this.setState({ studentListTableBody: sortedStudentsTableList });
      } else {
        const descendingSortedList = sortedStudentsTableList.reverse();
        this.setState({
          studentListTableBody: descendingSortedList,
        });
      }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
  getDisplayStatus = (role: string, status: string | undefined) => 
    role === 'registrar' && status === 'waiting_registrar_approval' ? 'open' : status;
  handlePostTeacherGradebook = async () => {
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const { semesterAvgSubmitted } = this.state;

    const requestPayload: {
      data: {
        semesteravgs_id: Array<number>
      }
    } = {
      data: {
        semesteravgs_id: semesterAvgSubmitted
      }
    };

    let requestUrl = this.state.currentRole === "registrar" ? configJSON.registrarSubmitGradebookEndpoint: configJSON.teacherSubmitGradebookEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubmitGradebook = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestPayload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostTeacherGradebookResponse = (responseJson: {
   message: Array<string>,
   errors: Array<{}>;
  }) => {
    if (responseJson?.message) {
       this.handleGetGradebookStudentsTableList();
       toast.success(responseJson.message?.[0]);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  };

  openUserDetailPage = async (data:any) => {
    const { currentRole, studentListTableBody,selectedSchoolYearValue,teacherSchoolYearsList,studentListTableColumns } = this.state;
    
    const studentDetails = studentListTableBody.find((item) => item?.Name?.studentId === data?.studentId);
   
    const schoolYear = teacherSchoolYearsList.find(
      (status) => status?.value === selectedSchoolYearValue
    );

    function getIsSelected(columnId:any) {
      let column = studentListTableColumns.find(col => col.columnId.toString() === columnId.toString());
      return column ? column.isSelected : undefined;
    }
  
    const semesters: { semesterId: string, semesterAvgId: number }[] = [];
    let selectedArray = []

  for (const key in studentDetails) {
    if (studentDetails.hasOwnProperty(key)) {
      const item = studentDetails[key];
      if (item && item.semesterId && item.semesterAvgId) {
        let isSelectedValue = getIsSelected(item.semesterId)
        if(isSelectedValue){
          selectedArray.push(parseInt(item.semesterId))
        }
        semesters.push({ semesterId: item.semesterId, semesterAvgId: item.semesterAvgId });
      }
    }
  }

  await setStorageData("semesters", JSON.stringify(semesters));
  await setStorageData("selectedArray", JSON.stringify(selectedArray));

    const datagradebook ={
      SchoolYear:schoolYear?.label,
      SchoolId:schoolYear?.value,
      name:studentDetails.Name.name,
      StudentId:studentDetails.Name.studentId,

    }
    await this.setGradebookDataToStorage(datagradebook);

    const navigation =  (currentRole === "registrar") ? "RegistrarGradebookStudentDetails" : "StudentGradesDetailPage"

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      navigation
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
 

  }
  setGradebookDataToStorage = async (data: any) => {
    await setStorageData("gradebookData", JSON.stringify(data));
};
handleGetTemplateApi = async (templateType:string,studentId:string) => {
  const selectedStudent = this.state.studentListTableBody.find((student: any) => student.id === studentId);
  const studentDataId= selectedStudent?.Name?.studentId

  const requestUrl = `${configJSON.getTemplateDataEndpoint}?student_id=${studentDataId}&school_year_id=${this.state.selectedSchoolYearValue}&template_type=${templateType}`;;
  const token = await getStorageData("token");
  const header = {
    token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  this.apiGetTemplateApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handleGetBlockTypeConvert = (selectedDataBlockPlacement: string): string => {
  switch (selectedDataBlockPlacement) {
    case "grades":
      return "Grades";
    case "behaviour":
      return "Behavior";
    case "attendence":
      return "Attendance";
    case "student_information":
      return "StudentInformation";
    case "TextEditor":
      return "";
    default:
      return ""; 
  }
}
 handleGetConvertBlockTypeContent = (selectedValue:string) => {
  let newContent = "";
  if (selectedValue === "data_blocks") {
    newContent = "DataBlocks";
  } else if (selectedValue === "text_editor") {
    newContent = "TextEditor";
  }
  return newContent;
}
 formatDate = (dateString:string) =>{
  const date = new Date(dateString);
  
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  return `${month} ${day}, ${year}`;
}
processStudentData = (responseJson: any,student_profile_image:any) => {
  if (!responseJson) return {};

  let base64Url = "";
  if (student_profile_image.url) {
    const iconImage = student_profile_image.url.trim();
    base64Url = `data:${student_profile_image.content_type};base64,${iconImage}`;
  }
  let dateOfBirth = moment(responseJson?.data.attributes.DOB).format('DD/MM/YYYY') || 'NA'
  const customFields = responseJson?.data.attributes.user_custom_fields?.data.map((field: { attributes: { label: any; field_value: any; }; }) => ({
    label: field.attributes.label,
    value: field.attributes.field_value || 'NA'
  })) || [];
  return {
    id: responseJson?.data.id,
    email: responseJson?.data.attributes.email || "NA",
    full_name: responseJson?.data.attributes.first_name + " " + responseJson?.data.attributes.last_name,
    id_number: responseJson?.data.attributes.id_number || 'NA',
    curriculum: responseJson?.data.attributes.curriculum?.name || 'NA',
    dob: dateOfBirth,
    grade: responseJson?.data.attributes.grade || [],
    role: responseJson?.data.attributes.role?.name || 'NA',
    schoolYearTitle: responseJson?.data.attributes.school_year?.title || 'NA',
    status: responseJson?.data.attributes.status || 'NA',
    expiringOn: responseJson?.data.attributes.expiring_on || 'NA',
    profile_pic: base64Url,
    customFields: customFields,
  };
}


 tranformGridContent = (resData: { templateType: any; getDataOfTemplate: any; },handleGetContentForType: { (selectedDataBlockPlacement: string, description: string,indexBehv:number): string | JSX.Element; (arg0: string, arg1: any,arg13:any): any; }) => {
  const backPageBlocks:any =[];
  const frontPageBlocks:any=[];
  let indexBehv =0 ;

  if (Array.isArray(resData.getDataOfTemplate)) {
    resData.getDataOfTemplate.forEach((item: any) => {
        const index =resData.templateType==="landscape_template" ? (item.row_number * 2 + item.column_number) : item.row_number;
        const blockType = this.handleGetBlockTypeConvert(item.data_block_content_type)
      
        const contentType = handleGetContentForType(blockType,item.description,indexBehv);
     
        const blockTypeContent = this.handleGetConvertBlockTypeContent(item.data_block_type)
        if(blockType === "Behavior"){
          indexBehv++;
        }
          const block = {
              content:contentType,
              blockType: blockType,
              blockTypeContent:blockTypeContent
          };

         

          if (item.page_type === "back_page") {
              backPageBlocks[index] = block;
          } else if (item.page_type === "front_page") {
              frontPageBlocks[index] = block;
          }
      });
  } else {
      console.error("getDataOfTemplate is not an array or is undefined.");
  }
  return [frontPageBlocks,backPageBlocks]
}


processBehaviorData = (behaviours: any[], dynamicBehaviourColumns: any[]) => {
  if (!behaviours) return [];

  const result: any[][] = []; 
  const skillTitles: string[] = [];

  behaviours.forEach((item) => {
    const skill = item.skill;
    const skillBehaviours = item.behaviours;

    skillTitles.push(skill)
    const skillDataArray = skillBehaviours.map((behaviour: { subskill_title: string; student_behaviours: any; }) => {
      const subskillTitle = behaviour.subskill_title.trim(); 
      const studentBehaviours = behaviour.student_behaviours; 

      return {
        title: subskillTitle,
        ...dynamicBehaviourColumns.reduce((acc: { [key: string]: any }, quarter: { id: string }, colIndex: number) => {
          const semesterType = quarter.id; 
          const studentBehaviour = studentBehaviours.find((b: any) => b.semester_type === semesterType);
          acc[semesterType] = studentBehaviour ? studentBehaviour.total_subskill_data : ""; 
          return acc;
        }, {})
      };
    });

    result.push(skillDataArray);
  });
  return [result,skillTitles]; 
};



processAttendanceData = (attendance: any, dynamicColumns: any[]) => {
  if (!attendance) return [];

  const attendanceValues = JSON.parse(attendance.quarterly_attendance);
  return [{
    id: 1,
    attendance: "No of School Days",
    ...dynamicColumns.reduce((acc, quarter, index) => {
      const dynamicKey = quarter.id;
      acc[dynamicKey] = attendanceValues[index] || 0;
      return acc;
    }, {}),
    total: attendance.total
  }];
}
processGradebookData = (gradesData: any, dynamicColumns: any[]) => {
  if (!gradesData || !gradesData.data) return [];

  const gradeBookData = gradesData.data;
  const totalGrade = gradesData.total_grade_point_avg;
  const totalGradeAvg = gradesData.total_avg_grade;
  const gpaTotal = totalGrade.reduce((sum: number, item: { avg: number }) => sum + item.avg, 0) / totalGrade.length;
  const calculateGPA = (avgsOfCourses: number[]): number => {
    if (avgsOfCourses.length === 0) return 0;
    const sum = avgsOfCourses.reduce((total, value) => total + value, 0);
    return sum / avgsOfCourses.length;
  };
  const gradebookTableData = gradeBookData.map((courseData: { avgs_of_courses: any; course: { id: any; title: any; subject: { icon: any; color: any; content_type: string }; }; grade: any; }, _courseIndex: any) => {
    const grades = courseData.avgs_of_courses;
    let base64Url = "";
    if (courseData.course.subject.icon) {
      const iconImage = courseData.course.subject.icon.trim();
      base64Url = `data:${courseData.course.subject.content_type};base64,${iconImage}`;
    }
    return {
      id: courseData.course.id,
      Name: {
        name: courseData.course.title,
        icon: base64Url,
        bgColor: courseData.course.subject.color
      },
      ...dynamicColumns.reduce((acc, quarter, index) => {
        const dynamicKey = quarter.id;
        acc[dynamicKey] = parseFloat((grades[index]).toFixed(2)) || 0;
        return acc;
      }, {}),
      GPA: parseFloat((calculateGPA(grades)).toFixed(2)),
      Grade: courseData.grade
    };
  });

  const totalGradePointAvg = {
    id: gradeBookData.length + 1,
    Name: {
      name: "Total Grade Point Average",
    },
    ...dynamicColumns.reduce((acc, quarter, index) => {
      const dynamicKey = quarter.id;
      acc[dynamicKey] = parseFloat((totalGrade[index]?.avg).toFixed(0)) || 0;
      return acc;
    }, {}),
    GPA: parseFloat(gpaTotal.toFixed(2)),
    Grade: totalGradeAvg
  };
  gradebookTableData.push(totalGradePointAvg);

  return gradebookTableData;
}
getDynamicColumns = (gradesData: any) => {
  if (!gradesData || gradesData.total_grade_point_avg.length === 0) return [];

  return gradesData.total_grade_point_avg.map((quarter: { sem_title: string; }, index: number) => ({
    type: tableColumnTypes.NUMBER,
    columnId: index + 2, 
    id: quarter.sem_title, 
    label: quarter.sem_title,
    width: "100%",
    maxWidth: "100%",
  }));
}


getDynamicBehaviourColumns = (gradesData: any) => {
  if (!gradesData || gradesData.total_grade_point_avg.length === 0) return [];

  return gradesData.total_grade_point_avg.map((quarter: { sem_title: string; }, index: number) => ({
    type: tableColumnTypes.TEXT,
    columnId: index + 2, 
    id: quarter.sem_title, 
    label: quarter.sem_title,
    width: "100%",
    maxWidth: "100%",
  }));
}
getPageTypeName = (orientationType: string): string => {
  return orientationType === "landscape_template" ? "landscape" : "portrait";
};
 createSkillsTableColumns = (baseColumns: any[], skillsArray: any[]) => {
 
  if (skillsArray.length === 0) {
    return baseColumns;
  }

  return skillsArray.map((skill: any) => {
    return baseColumns.map((column: { id: string; }) => {
        if (column.id === "title") {
            return {
                ...column,
                label: skill 
            };
        }
        return column; 
    });
});
};
handleGetTemplateResponse = async(responseJson: {
  data: any;
  errors: Array<{}>;})=>{

  if (responseJson?.data) {  
   
    const selectedTemplateData = responseJson.data.template;
   
    if (selectedTemplateData?.data.length > 0) {
        const orientationType = selectedTemplateData.data[0].attributes?.orientation_type;
        const pageTypeName = this.getPageTypeName(orientationType);

        const getData = selectedTemplateData.data[0]?.attributes.template_content_types;
        const resData = {
          templateType:orientationType,
          getDataOfTemplate:getData
        }
      
        const studentData = this.processStudentData(responseJson.data.student,responseJson.data.student_profile_image);
        let AttandanceTableHeadData = [...AttandanceTableHead]
        let gradebookTableHeadData =[...gradebookTableHead]
        let BehaviourTableHeadData = [...BehaviorsTableHead]
        const dynamicColumns = this.getDynamicColumns(responseJson.data.grades_data);
        const dynamicBehaviourColumns = this.getDynamicBehaviourColumns(responseJson.data.grades_data);
        AttandanceTableHeadData.splice(1, 4, ...dynamicColumns);
        gradebookTableHeadData.splice(1,4,...dynamicColumns)
        BehaviourTableHeadData.splice(1,4,...dynamicBehaviourColumns)

        const className = responseJson.data.class_name || "";
        let schoolYear = this.renderSchoolYearDropDownValue(this.state.selectedSchoolYearValue)
        const AttandanceTableData = this.processAttendanceData(responseJson.data.attendance, dynamicColumns);
        const gradebookTableData = this.processGradebookData(responseJson.data.grades_data, dynamicColumns);
        const behaviorTableArray = this.processBehaviorData(responseJson.data.behaviours, dynamicBehaviourColumns);
        const behaviorTableData =behaviorTableArray[0];
        const behaviorTableSkillsData = behaviorTableArray[1]
        const behaviourDynamicHeader = this.createSkillsTableColumns(BehaviourTableHeadData,behaviorTableSkillsData)
        const behaviorTableDataLength =behaviorTableData.length
        const handleGetContentForType = (selectedDataBlockPlacement:string,description:string,index:number)=>{
          let newContent;
        
          if (selectedDataBlockPlacement === "Grades") {
            newContent = <GradeTable gradebookListTableHead={gradebookTableHeadData}
            gradebookTableData={gradebookTableData} className={className} schoolYear={schoolYear}/>;
          } else if (selectedDataBlockPlacement === "Behavior") {
            newContent = <BehaviourTable behaviourTableHead={behaviourDynamicHeader[index%behaviorTableDataLength]} behaviourTableData={behaviorTableData[index%behaviorTableDataLength]}/>;
          } else if (selectedDataBlockPlacement === "Attendance") {
            newContent = <AttandanceTable AttandanceTableHead={AttandanceTableHeadData} AttandanceTableData={AttandanceTableData}/>;
          } else if (selectedDataBlockPlacement === "StudentInformation") {
            newContent = <StudentDetails className={className} schoolYear={schoolYear} studentData={studentData} />;
          } else {
            newContent = description || "+Edit"
          }
          return newContent
        }
      const datahtmlFront = this.tranformGridContent(resData,handleGetContentForType)
    
      this.setState({selectedTemplateData:datahtmlFront[0]})
        const htmlContentFrontPage = ReactDOMServer.renderToString( 
          <GradebookTemplateDownload
            data-testId="close-preview"
            templateType={resData.templateType}
            gridContent={datahtmlFront[0]}
          />
        )
        const htmlContentBackPage = ReactDOMServer.renderToString( 
          <GradebookTemplateDownload
            data-testId="close-preview"
            templateType={resData.templateType}
            gridContent={datahtmlFront[1]}
          />
        )
    

      await this.generatePDF(htmlContentFrontPage, htmlContentBackPage, pageTypeName)
    } else {
        this.setState({   selectedTemplateData: null});
    }
  } else {
    displayApiErrorMsg(responseJson?.errors, this.props.navigation);
  }
}
  // Customizable Area End
}
