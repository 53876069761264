// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: () => void;
  handleAddGrade: any;
  handleEditGrade: any;
  selectedGradeData: any;
  isEdit?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  gradeName: string;
  gradeNameError: boolean;
  gradeNameErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewGradeDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      gradeName: "",
      gradeNameError: false,
      gradeNameErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { isEdit } = this.props;
    if(isEdit) this.handleSetGradeInitialState(true)
    // Customizable Area End
  }
  // Customizable Area Start

  handleChangeGradeName = (event: any) => {
    const { name, value } = event.target;
    this.setState({ gradeNameError: false, gradeNameErrorMsg: "" });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleValidateGrade = () => {
    const { gradeName } = this.state;
    let isValid = true;
    if (!gradeName.trim()) {
      this.setState({
        gradeNameError: true,
        gradeNameErrorMsg: "Please enter grade name",
      });
      isValid = false;
    }
    return isValid;
  };

  handleAdd = () => {
    const { isEdit } = this.props;
    const { gradeName } = this.state;
    if(this.handleValidateGrade()){
      const addGradeDetails = {
        data: {
          attributes: {
            name: gradeName,
          },
        },
      };
      if (isEdit) {
        this.props.handleEditGrade(addGradeDetails);
      } else {
        this.props.handleAddGrade(addGradeDetails);
      }
      this.handleClose();
    }
  };

  handleSetGradeInitialState = (isEdit?: boolean) => {
    if (isEdit) {
      const { selectedGradeData } = this.props;
      this.setState({
        gradeName: selectedGradeData.grade,
        gradeNameError: false,
        gradeNameErrorMsg: ""
      });
    } else {
      this.setState({
        loading: false,
        gradeName: "",
        gradeNameError: false,
        gradeNameErrorMsg: ""
      }, () => {
        this.props.handleClose();
      });
    }
  };

  handleClose = () => {
   this.handleSetGradeInitialState()
  }

  // Customizable Area End
}
// Customizable Area End
