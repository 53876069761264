// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const ntc = require("ntcjs");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  fieldName: string;
  fieldValue?: any;
  fieldValueHexCode?: any;
  fieldError?: boolean;
  fieldId: string;
  fieldWidth: string;
  isDisabled?: boolean;
  placeHolderText?: string;
  handleColorChange: any;
  startAdornmentIcon?: any;
  helperText?: string;
  textFieldStyles?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedColorName: string;
  selectedColorHexCode: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomColorPickerFieldController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedColorName: "Red",
      selectedColorHexCode: "#ff0000",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  openColorPicker = () => {
    const { fieldId, isDisabled } = this.props;
    if (!isDisabled) {
      let el = document.getElementById(`${fieldId}-Color-Picker`);
      if (el) {
        el.click();
      }
    }
  };

  colorChangeUpdate = async (event: any) => {
    const { isDisabled } = this.props;
    if (!isDisabled) {
      const n_match = ntc.name(event.target.value);
      this.setState(
        {
          selectedColorName: n_match[1],
          selectedColorHexCode: event.target.value,
        },
        () => {
          this.props.handleColorChange(this.state.selectedColorHexCode);
        }
      );
    }
  };
  // Customizable Area End
}
