import React from "react";
// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantCourseLearnMoreController, {
  Props,
} from "./TenantCourseLearnMoreController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";

const styles = (theme: Theme) =>
  createStyles({
    learnMoreBox: {
      padding: "30px 35px",
      position: "relative",
    },
    gridItemsBox: {
      padding: "10px 0px",
    },
    topCourseBox: {
      marginBottom: "25px",
    },
    buttonBox: {
      position: "absolute",
      bottom: "82px",
      width: "85%",
      [theme.breakpoints.down("xs")]: {
        bottom: "-180px",
      },
    },
  });

// Customizable Area End

export class TenantCourseLearnMore extends TenantCourseLearnMoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, courseName ,isFromTenant} = this.props;
    const { course } = this.state;

    return (
      <Box
        className={`${classes.learnMoreBox} ${classes.highlightBgColor}`}
        height={"100vh"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className={`${classes.topCourseBox}`}
        >
          <Typography
            className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
            data-test-id="subject-name-learn-more"
          >
            {courseName}
          </Typography>
          <CloseIcon
            onClick={this.props.handleClose}
            className={`${classes.textCommonColor} ${classes.cursorPointer}`}
          />
        </Box>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="short-code-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="short-code"
              >
                Short Code
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="short-code-value"
              >
                {course?.short_code || 0}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="status-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="status"
              >
                Status
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="status-value"
              >
                {course?.status}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="subject-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="subject"
              >
                Subject
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="subject-value"
              >
                {course?.subject?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box data-test-id="grade-box" className={`${classes.gridItemsBox}`}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="grade"
              >
                Grade
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="grade-value"
              >
                {course?.grade?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="grade-no-of-lessons-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="grade-no-of-lessons"
              >
                No. of lessons / day
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="grade-no-of-lessons-value"
              >
                {course?.no_of_lession_a_day} lessons / day
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="grade-total-no-of-lessons-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="grade-total-no-of-lessons"
              >
                Total no. of lessons
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="grade-total-no-of-lessons-value"
              >
                {course?.total_no_of_lessons}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="modified-by-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="modified-by"
              >
                Modified By
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="modified-by-value"
              >
                {`${course?.modified_by?.first_name} ${course?.modified_by?.last_name}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="created-by-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="created-by"
              >
                Created By
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="created-by-value"
              >
                {`${course?.user?.first_name} ${course?.user?.last_name}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="school-year-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="school-year"
              >
                School Year
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="school-year-value"
              >
                {course?.school_year?.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="curriculum-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="curriculum"
              >
                Curriculum
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="curriculum-value"
              >
                {course?.curriculum?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              data-test-id="no-of-participants-box"
              className={`${classes.gridItemsBox}`}
            >
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="no-of-participants"
              >
                Current no. of Participants
              </Typography>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`}
                data-test-id="no-of-participants-value"
              >
               {course?.participants_count}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          className={`${classes.buttonBox}`}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <CustomButton
            btnText="Edit"
            btnStyle={topBtnStyle}
            buttonId={`learn-more-edit`}
            buttonVariant={"outlined"}
            type={"button"}
            handleButtonClick={this.props.handleEdit}
            isDisabled={false}
          />
         {isFromTenant && <CustomButton
            btnText="Assign"
            btnStyle={topBtnStyle}
            buttonId={`learn-more-assign`}
            buttonVariant={"contained"}
            type={"button"}
            handleButtonClick={this.props.handleAssign}
            isDisabled={false}
          />
         }
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const topBtnStyle = {
  padding: "10px 10px",
  fontWeight: 600,
  borderRadius: "10px",
  fontSize: "16px",
  height: "45px",
  textAlign: "center",
  width: "220px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantCourseLearnMore);
// Customizable Area End
