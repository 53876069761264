import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, Tab, Tabs } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import TenantDashboardMainController, {
  Props,
  tenantDashboardTabsList,
} from "./TenantDashboardMainController.web";
import TenantStatisticsDashboardTabWeb from "./TenantStatisticsDashboardTab.web";
import TenantReportsDashboardTabWeb from "./TenantReportsDashboardTab.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    tenantDashboardMainContainer: {
      height: "100vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      minWidth: "50%",
      textTransform: "capitalize",
      fontSize: "16px",
      textAlign: "center",
    },
    tenantDashboardTabPanel: {
      padding: "15px 0px 15px 0px",
    },
    tenantDashboardTopHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
  });
// Customizable Area End

export class TenantDashboardMain extends TenantDashboardMainController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedTenantDashboardTab } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                My Dashboard
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.textButton}`}
              >
                {` / ${tenantDashboardTabsList[selectedTenantDashboardTab].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box className={`${classes.tenantDashboardTopHeader}`}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
              >
                My Dashboard
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              className={`${classes.bgColor} ${classes.tenantDashboardMainContainer}`}
            >
              <Box>
                <Tabs
                  value={selectedTenantDashboardTab}
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  scrollButtons="auto"
                  data-testid="tenant-admin-dashboard-tabs"
                  className={`${classes.tabs}`}
                  onChange={this.handleChangeDashboardTab}
                  aria-label="tenant-admin-dashboard-tabs"
                >
                  {tenantDashboardTabsList.map(
                    (tabItem: any, index: number) => (
                      <Tab
                        key={tabItem.id}
                        data-testid={`tenant-admin-dashboard-${tabItem.label}-tab`}
                        label={tabItem.label}
                        {...a11yProps(index)}
                        wrapped
                        className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                      />
                    )
                  )}
                </Tabs>
              </Box>
              <Box className={`${classes.tenantDashboardTabPanel}`}>
                <TabPanel
                  value={selectedTenantDashboardTab}
                  index={0}
                >
                  <TenantStatisticsDashboardTabWeb />
                </TabPanel>
                <TabPanel
                  value={selectedTenantDashboardTab}
                  index={1}
                >
                  <TenantReportsDashboardTabWeb />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantDashboardMain);
// Customizable Area End
