// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import AddSubjectiveQuestionModalController, {
  Props,
} from "./AddSubjectiveQuestionModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import Switch from '@material-ui/core/Switch';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    addNewQuestionModal: {
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "100%",
        minHeight: "500px",
        overflowX: "hidden",
        overflowY: "hidden",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    customInputBox: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      "& input": {
        height: "30px",
      }
    },
    customMarksInputBox: {
      "& input": {
        height: "30px",
      }
    },
    deleteIcon: {
      position: "absolute",
      right: "20px",
      cursor: "pointer",
    },
    dialogContent: {
      padding: "0px",
      marginTop: "24px",
      overflow: "hidden"
    },
    showActiveOption: {
      color: "#009DFF",
      fontWeight: 500,
    },
    toggleTitleMargin: {
      marginRight: "30px",
    }
  });

// Customizable Area End

// Customizable Area Start
export class AddSubjectiveQuestionModal extends AddSubjectiveQuestionModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  AntSwitch = withStyles((theme) => ({
    root: {
      width: 46,
      height: 26,
      borderRadius: "18.5px",
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: "#888",
      transform: 'translateX(1px)',
      '&$checked': {
        '& + $track': {
          opacity: 1,
          backgroundColor: "#009DFF",
          borderColor: "#009DFF",
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      boxShadow: 'none',
      background: "#FFF",
    },
    track: {
      border: `1px solid #888`,
      borderRadius: "18.5px",
      opacity: 1,
      backgroundColor: "#888",
    },
    checked: {},
  }))(Switch);
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isEdit, explanation, forManual } = this.props;
    const {
      wordLimitValue,
      questionContent,
      marksValue,
      correctAnswerValue,
      questionExplanation,
      subjectiveMarksError,
      subjectiveMarksErrorMsg,
      subjectiveQuestionContentError,
      subjectiveQuestionContentErrorMsg,
      showStudent,
      uploadDocument,
    } = this.state;

    return (
      <Box>
        <Dialog
          className={`${classes.addNewQuestionModal} ${classes.highlightBgColor} `}
          open={open}
          scroll={"body"}
          aria-labelledby="quiz-view-answer-confirm-dialog-title"
        >
          <Box className={`${classes.bgColor}`} padding={"30px"}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography component={"span"} className={`${classes.fontBold600} ${classes.fontText24} ${classes.textPrimaryColor}`}>
                  {isEdit ? 'Edit Question 1' : 'Add New Question'}
                </Typography>
                <Button
                  data-test-id={"subjective-modal-cross-icon"}
                  onClick={() => this.handleSetInitialValue()}
                  style={{ float: "right" }}
                  className={classes.textPrimaryColor}
                >
                  X
                </Button>
              </Grid>
            </Grid>
            <DialogContent className={classes.dialogContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Box marginBottom={"10px"}>
                    <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                      Question 1
                    </Typography>
                  </Box>
                  <CustomTextEditor
                    placeHolderText={isEdit?"":"Type Question"}
                    editorId="subjective-question-textbox"
                    editorError={subjectiveQuestionContentError}
                    editorContent={questionContent}
                    onWysEditorStateChange={this.handleQuestionContentChange}
                    helperText={subjectiveQuestionContentErrorMsg}
                  />
                </Grid>
                {
                  explanation && (
                    <Grid xs={6} item>
                      <Box marginBottom={"10px"}>
                        <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                          Correct Answer
                        </Typography>
                      </Box>
                      <Box className={classes.customInputBox}>
                        <CustomTextField
                          textFieldStyles={textInputStyle}
                          fieldId={"Correct-Answer-Written"}
                          placeHolderText={"Enter Correct Answer"}
                          fieldName={"correctAnswerValue"}
                          fieldWidth={"99%"}
                          handleChange={this.handleCorrectAnswerInputChange}
                          fieldType="text"
                          fieldValue={correctAnswerValue}
                        />
                      </Box>
                    </Grid>
                  )
                }
                {
                  forManual &&
                  <>
                    <Grid xs={6} item>
                      <Box mt={"10px"} display={"flex"} flexDirection={"row"} gridGap={"12px"} justifyContent={"start"} alignItems={"center"}>
                        <Box>
                          <Typography className={`${classes.fontText15} ${classes.textCommonColor} ${classes.toggleTitleMargin}`} component={"span"}>
                            Show the student?
                          </Typography>
                          <Typography className={`${classes.fontText15} ${classes.textCommonColor}`} component={"span"}>
                            No
                          </Typography>
                        </Box>
                        <Box>
                          <this.AntSwitch checked={showStudent} onChange={this.handleShowStudentChange} name="showStudent" />
                        </Box>
                        <Box>
                          <Typography className={this.handleShowCorrectAnswerDynamicClass()}>
                            Yes
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid xs={6} item>
                      <Box mt={"10px"} display={"flex"} flexDirection={"row"} gridGap={"12px"} justifyContent={"start"} alignItems={"center"}>
                        <Box>
                          <Typography className={`${classes.fontText15} ${classes.textCommonColor} ${classes.toggleTitleMargin}`} component={"span"}>
                            Document upload?
                          </Typography>
                          <Typography className={`${classes.fontText15} ${classes.textCommonColor}`} component={"span"}>
                            No
                          </Typography>
                        </Box>
                        <Box>
                          <this.AntSwitch checked={uploadDocument} onChange={this.handleUploadDocumentChange} name="uploadDocument" />
                        </Box>
                        <Box>
                          <Typography className={this.handleUploadDocumentDynamicClass()}>
                            Yes
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                }
                <Grid xs={6} item>
                  <Box marginBottom={"10px"}>
                    <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                      Marks
                    </Typography>
                  </Box>
                  <Box className={classes.customMarksInputBox}>
                    <CustomTextField
                      placeHolderText={"Enter Marks"}
                      textFieldStyles={textInputMarksStyle}
                      fieldId={"Marks-Input-subjective"}
                      fieldName={"Marks"}
                      fieldWidth={"99%"}
                      fieldValue={marksValue}
                      handleChange={this.handleMarksInputChange}
                      fieldType="number"
                      fieldError={subjectiveMarksError}
                      helperText={subjectiveMarksErrorMsg}
                    />
                  </Box>
                </Grid>
                {!explanation && !forManual && (
                  <Grid xs={6} item>
                    <Box marginBottom={"10px"}>
                      <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                        Word Limit
                      </Typography>
                    </Box>
                    <Box className={classes.customInputBox}>
                      <CustomTextField
                        placeHolderText={"Enter Word Limit (in numbers)"}
                        textFieldStyles={textInputStyle}
                        fieldId={"Word-Limit-Input"}
                        fieldName={"Word-Limit"}
                        fieldWidth={"99%"}
                        fieldValue={wordLimitValue}
                        handleChange={this.handleWorkLimitInputChange}
                        fieldType="number"
                      />
                    </Box>
                  </Grid>
                )}
                {explanation && !forManual && (
                  <Grid item xs={12}>
                    <Box marginBottom={"10px"}>
                      <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                        Explanation
                      </Typography>
                    </Box>
                    <CustomTextEditor
                      placeHolderText={isEdit?"":"Write an explanation"}
                      editorId="subjective-explanation-textbox"
                      editorError={false}
                      editorContent={questionExplanation}
                      onWysEditorStateChange={this.handleQuestionExplanationChange}
                      helperText=""
                    />
                  </Grid>
                )}
                <Grid xs={12} item>
                  <Box width={"100%"} display={"flex"} justifyContent={this.getJustifyContentValue()} marginBottom={"10px"}>
                    <Box>
                      <CustomButton
                        buttonId={`question-cancel-button`}
                        btnText="Cancel"
                        buttonVariant={"outlined"}
                        btnStyle={{ ...{ width: "120px" }, ...cancelButtonStyle }}
                        type={"button"}
                        isDisabled={false}
                        handleButtonClick={() => this.handleSetInitialValue()}
                      />
                    </Box>
                    <Box display={"flex"}>
                      {
                        isEdit ?
                          <CustomButton
                            buttonId={`question-delete-button`}
                            btnText="Delete"
                            buttonVariant={"contained"}
                            btnStyle={deleteButton}
                            type={"button"}
                            isDisabled={false}
                            handleButtonClick={this.props.handleDeleteSubjectiveQuestion}
                          /> : ''
                      }
                      <CustomButton
                        buttonId={`question-save-button`}
                        btnText={this.getSaveButtonLabel()}
                        buttonVariant={"contained"}
                        btnStyle={submitButton}
                        type={"button"}
                        isDisabled={false}
                        handleButtonClick={() => this.handleAddSubjectiveQuestionData()}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );

    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const textInputStyle = {
  height: "40px",
  padding: "5px 35px 5px 5px",
} as const;

const textInputMarksStyle = {
  height: "40px",
} as const;

const deleteButton = {
  height: "50px",
  width: "120px",
  borderRadius: "12px",
  padding: "10px 10px",
  color: "#FFF",
  background: "#ED1111",
  fontSize: "18px",
  textAlign: "center",
  marginLeft: "15px",
  fontWeight: 500,
  float: "right",
  marginTop: "32px",
} as const;

const cancelButtonStyle = {
  padding: "10px 10px",
  height: "50px",
  borderRadius: "12px",
  border: "1px solid",
  fontSize: "18px",
  textAlign: "center",
  float: "right",
  fontWeight: 500,
  marginTop: "32px",
} as const;

const submitButton = {
  height: "50px",
  width: "120px",
  textAlign: "center",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  marginLeft: "15px",
  marginTop: "32px",
  float: "right",
  fontWeight: 500,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddSubjectiveQuestionModal);
// Customizable Area End
