// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  navigation?: any;
  handleUpdateAnswer: any;
  events?: any;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gradingComponent: Array<any>;
  selectedClassComponentDDValue: any;
  selectStartDate: any;
  openAssignCourseModal: boolean;
  isCreateModal: boolean;
  selectEndDate: any;
  colorChange: any;
  searchStudent: string;
  selectEventPopUp: boolean;
  openEditPopUp: boolean;
  openDeletePopUp: boolean;
  openCreateSuccessPopUp: boolean;
  selectEventDetails: {
    title: string;
    date: any;
    desc: any;
    textColor: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ParentTeacherCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start    
    this.state = {
      openDeletePopUp: false,
      selectEventPopUp: false,
      openCreateSuccessPopUp: false,
      openEditPopUp: false,
      openAssignCourseModal: false,
      selectedClassComponentDDValue: "",
      selectEndDate: new Date(),
      selectStartDate: new Date(),
      searchStudent: "",
      colorChange: "#ff0000",
      isCreateModal: false,
      selectEventDetails: {
        title: "",
        date: "",
        desc: "",
        textColor: "",
      },
      gradingComponent: [
        {
          id: 0,
          label: "All",
          value: "All",
        },
        {
          id: 1,
          label: "Milton peery",
          value: "Milton peery",
        },
        {
          id: 2,
          label: "Amelia Cabel",
          value: "Amelia Cabel",
        },
        {
          id: 3,
          label: "Isak Johannesson",
          value: "Isak Johannesson",
        },
        {
          id: 4,
          label: "Jason Bailey",
          value: "Jason Bailey",
        },
        {
          id: 5,
          label: "Vicente de la Cruz",
          value: "Vicente de la Cruz",
        },
      ],
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  renderCalendarComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Milton Perry";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGradingComponents?.label;
  };

  handleCalendarDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };

  handleCloseAssignCourseModal = () => {
    this.setState({ openAssignCourseModal: false });
  };

  handleOpenReschedulModal = () => {
    this.setState({ openAssignCourseModal: true });
  };

  selectEndDate = (date: any) => {
    this.setState({
      selectEndDate: date,
    });
  };

  selectStartDate = (date: any) => {
    this.setState({
      selectStartDate: date,
    });
  };

  closeCreateModal = () => {
    this.setState({
      isCreateModal: false,
    });
  };

  createEventModal = () => {
    this.setState({
      isCreateModal: true,
    });
  };

  colorChangeUpdate = async (event: any) => {
    this.setState({
      colorChange: event.target.value,
    });
  };

  openPIckCOlor = () => {
    let el = document.getElementById("ColorChange");
    if (el) {
      el.click();
    }
  };

  handleChange = async (event: any) => {
    this.setState({
      searchStudent: event.target.value,
    });
  };

  closeHandleEventSelect = () => {
    this.setState({
      selectEventPopUp: false,
    });
  };

  handleEventSelect = (selectedEvent: any) => {
    const startDate = moment(selectedEvent.start);
    const formattedStartDate = startDate.format("MMM DD, YYYY");
    this.setState({
      selectEventPopUp: true,
      selectEventDetails: {
        date: formattedStartDate,
        title: selectedEvent.title,
        desc: selectedEvent.desc,
        textColor: selectedEvent.textColor,
      },
    });
  };

  handleCloseDeletePopUp = () => {
    this.setState({ openDeletePopUp: false });
  };

  handleDeletePopUp = () => {
    this.setState({
      openDeletePopUp: true,
      selectEventPopUp: false,
    });
  };

  handleCloseEditPopUp = () => {
    this.setState({
      openEditPopUp: false,
    });
  };

  handleEditPopUp = () => {
    this.setState({
      openEditPopUp: true,
      selectEventPopUp: false,
    });
  };

  handleUpdateAnswer = () => {
    console.log('error')
  }

  CloseEventSuccessPopUp = () => {
    this.setState({
      openCreateSuccessPopUp: false,
    });
  };

  CreateEventSuccessPopUp = () => {
    this.setState({
      openCreateSuccessPopUp: true,
    });
  };
  // Customizable Area End
}
// Customizable Area End
