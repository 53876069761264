import React from "react";

// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import AppearanceThemeController, {
  Props,
  themesList,
} from "./AppearanceThemeController.web";
import CustomColorRadioButtonWeb from "../../../components/src/CustomColorRadioButton.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import { manAvatar } from "./assets";
import { accentColors, highlightColors } from "../assets/themeBlockMock.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    themeMainGrid: {
      padding: "10px 20px",
    },
    themeMainGridItem: {
      padding: "0px 0px 30px 0px",
      marginBottom: "25px",
    },
    themeMainGridItemWithBottomBorder: {
      borderBottom: "1px solid #f1f1f1",
    },
    leftMargin: {
      marginLeft: "10px",
    },
    themeOptions: {
      display: "flex",
    },
    themeOptionRadioBox: {
      display: "flex",
      alignItems: "center",
    },
    themeOptionBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    defaultDetailsBox: {
      display: "flex",
      padding: "0px 10px",
      marginBottom: "8px",
    },
    defaultLogo: {
      width: "100px",
      objectFit: "contain",
    },
    labelBottomMargin: {
      marginBottom: "10px",
    },
    saveBtnGrid: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "15px",
    },
    themeImgBox: {
      borderRadius: "10px",
      marginRight: "20px",
    },
    themeImgBoxSelected: {
      border: "3px solid #FBD82C",
    },
    colorCircle: {
      display: "inline-block",
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    accentColorBox: {
      padding: "25px 15px",
      display: "flex",
      marginTop: "20px",
      width: "fit-content",
      borderRadius: "10px",
    },
    avatarBox: {
      borderRadius: "50%",
      "& img": {
        height: "45px",
      },
    },
    themeImg: {
      width: "300px",
      height: "200px",
    },
    accentHighlightItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "45px",
    },
    accentHighlightLabel: {
      marginBottom: "15px",
    },
  });
// Customizable Area End

export class AppearanceTheme extends AppearanceThemeController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedThemeType, selectedAccentColor, selectedHighlightColor } =
      this.state;
    return (
      <Grid className={`${classes.themeMainGrid}`} container direction="row">
        <Grid className={`${classes.themeMainGridItem} ${classes.themeMainGridItemWithBottomBorder}`} item xs={12}>
          <Box className={`${classes.labelBottomMargin}`}>
            <Typography
              id={`appearance-management-theme-interface-label`}
              className={`${classes.fontText18} ${classes.fontBold600} ${classes.textPrimaryColor}`}
            >
              Interface Themes
            </Typography>
          </Box>
          <Box className={`${classes.themeOptions}`}>
            {themesList.map((themeItem: any) => (
              <Box key={themeItem.id} className={`${classes.themeOptionBox}`}>
                <Box
                  className={`${classes.themeImgBox} ${
                    selectedThemeType === themeItem.value
                      ? classes.themeImgBoxSelected
                      : ""
                  }`}
                >
                  <img
                    className={`${classes.themeImg}`}
                    src={themeItem.image}
                    alt={themeItem.name}
                  />
                </Box>
                <Box className={`${classes.themeOptionRadioBox}`}>
                  <CustomColorRadioButtonWeb
                    checked={selectedThemeType === themeItem.value}
                    radioColor="#FBD82C"
                    fieldName={themeItem.name}
                    handleChange={(e: any) =>
                      this.handleChangeThemeType(themeItem.value)
                    }
                  />
                  <Typography
                    className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16} ${classes.leftMargin}`}
                    component={"span"}
                  >
                    {themeItem.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid className={`${classes.themeMainGridItem} ${classes.themeMainGridItemWithBottomBorder}`} item xs={12}>
          <Box className={`${classes.labelBottomMargin}`}>
            <Typography
              id={`appearance-management-theme-accent-color-label`}
              className={`${classes.fontText18} ${classes.fontBold600} ${classes.textPrimaryColor}`}
            >
              Accent Color
            </Typography>
          </Box>
          <Box display={"flex"}>
            {accentColors.map((color: any) => (
              <Box
                border={
                  selectedAccentColor === color.color
                    ? `2px solid ${selectedAccentColor}`
                    : ""
                }
                padding={"3px"}
                key={color.id}
                borderRadius={"50%"}
                marginRight={"10px"}
              >
                <Box
                  onClick={() => this.handleSelectAccentColor(color)}
                  data-testid={`accent-color-${color.color}`}
                  className={`${classes.colorCircle}`}
                  bgcolor={color.color}
                />
              </Box>
            ))}
          </Box>
          <Box
            className={`${classes.secondaryBgColor} ${classes.accentColorBox}`}
          >
            <Box className={`${classes.accentHighlightItem}`}>
              <Typography
                id={`appearance-management-theme-accent-color-avatar`}
                className={`${classes.fontText12} ${classes.fontBold600} ${classes.textPrimaryColor} ${classes.accentHighlightLabel}`}
              >
                Avatar
              </Typography>
              <Box
                border={
                  selectedAccentColor ? `3px solid ${selectedAccentColor}` : ""
                }
                className={`${classes.avatarBox}`}
              >
                <img src={manAvatar} alt="Avatar" />
              </Box>
            </Box>
            <Box className={`${classes.accentHighlightItem}`}>
              <Typography
                id={`appearance-management-theme-accent-color-menu`}
                className={`${classes.fontText12} ${classes.fontBold600} ${classes.accentHighlightLabel} ${classes.textPrimaryColor}`}
              >
                Menu
              </Typography>
              <CustomButtonWeb
                btnText="Students"
                btnStyle={{
                  ...saveBtn,
                  borderColor: selectedAccentColor,
                  color: selectedAccentColor,
                }}
                buttonId="appearance-management-dummy-1-btn"
                buttonVariant="outlined"
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.handleDummyBtnAction}
              />
            </Box>
            <Box className={`${classes.accentHighlightItem}`}>
              <Typography
                id={`appearance-management-theme-accent-color-menu`}
                className={`${classes.accentHighlightLabel} ${classes.fontText12} ${classes.fontBold600} ${classes.textPrimaryColor}`}
              >
                Action button
              </Typography>
              <CustomButtonWeb
                btnText="Students"
                btnStyle={{ ...saveBtn, backgroundColor: selectedAccentColor }}
                buttonId="appearance-management-dummy-2-btn"
                buttonVariant="contained"
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.handleDummyBtnAction}
              />
            </Box>
          </Box>
        </Grid>
        <Grid className={`${classes.themeMainGridItem}`} item xs={12}>
          <Box className={`${classes.labelBottomMargin}`}>
            <Typography
              id={`appearance-management-theme-interface-label`}
              className={`${classes.fontText18} ${classes.fontBold600} ${classes.textPrimaryColor}`}
            >
              Highlights
            </Typography>
          </Box>
          <Box display={"flex"}>
            {highlightColors.map((highlightColor: any) => (
              <Box
                border={
                  selectedHighlightColor === highlightColor.color
                    ? `2px solid ${selectedHighlightColor}`
                    : ""
                }
                padding={"3px"}
                borderRadius={"50%"}
                key={highlightColor.id}
                marginRight={"10px"}
              >
                <Box
                  onClick={() =>
                    this.handleSelectHighlightColor(highlightColor)
                  }
                  data-testid={`highlight-color-${highlightColor.color}`}
                  className={`${classes.colorCircle}`}
                  bgcolor={highlightColor.color}
                />
              </Box>
            ))}
          </Box>
          <Box
            className={`${classes.secondaryBgColor} ${classes.accentColorBox}`}
          >
            <Box className={`${classes.accentHighlightItem}`}>
              <Typography
                id={`appearance-management-theme-highlight-text`}
                className={`${classes.fontText12} ${classes.fontBold600} ${classes.textPrimaryColor} ${classes.accentHighlightLabel}`}
              >
                Borders
              </Typography>
              <Box
                border={
                  selectedHighlightColor
                    ? `1px solid ${selectedHighlightColor}`
                    : ""
                }
                height={"45px"}
                width={"160px"}
                borderRadius={"10px"}
                className={`${classes.bgColor} ${classes.highlightBoxBorder}`}
              />
            </Box>
            <Box className={`${classes.accentHighlightItem}`}>
              <Typography
                id={`appearance-management-theme-highlight-text`}
                className={`${classes.fontText12} ${classes.fontBold600} ${classes.textPrimaryColor} ${classes.accentHighlightLabel}`}
              >
                Highlights
              </Typography>
              <Typography
                id={`appearance-management-theme-highlight-text-1`}
                className={`${classes.fontText16} ${classes.textPrimaryColor}`}
              >
                The Quick Brown Fox
                <Typography
                  id={`appearance-management-theme-highlight-text-2`}
                  component={"span"}
                  style={{
                    background: selectedHighlightColor
                      ? selectedHighlightColor
                      : "",
                  }}
                  className={`${classes.fontText16} ${classes.textPrimaryColor}`}
                >
                  {` Jumped over `}
                </Typography>
              </Typography>
              <Typography
                id={`appearance-management-theme-highlight-text-3`}
                className={`${classes.fontText16} ${classes.textPrimaryColor}`}
              >
                the Lazy Dog
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className={`${classes.saveBtnGrid}`} item xs={12}>
          <CustomButtonWeb
            btnText="Save"
            btnStyle={saveBtn}
            buttonId="appearance-management-theme-save-btn"
            buttonVariant="contained"
            isDisabled={!this.props.rolePermissions?.themesPermission?.write}
            type={"button"}
            handleButtonClick={this.handleSaveDetails}
          />
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const saveBtn = {
  width: "160px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  padding: "10px 10px",
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AppearanceTheme);
// Customizable Area End
