// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  isRegularTeacher?: boolean;
  handleGetTeachersList: any;
  selectedTeachersList: Array<any>;
  handleSetSelectedTeachersList: any;
  allTeachersList: Array<any>;
  // Customizable Area End
}

interface TeachersDataInterface {
  id: number;
  name: string;
  email: string;
  role: string;
  profileImg: string;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  searchField: string;
  hoverTeacher: any;
  hoverSelectedTeacher: any;
  teachersData: Array<TeachersDataInterface>;
  selectedTeachers: Array<TeachersDataInterface> | any;
  openUserRoleSelectPopup: boolean;
  selectedAddNewUserRole: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class RegularTeacherController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      searchField: "",
      hoverTeacher: null,
      hoverSelectedTeacher: null,
      openUserRoleSelectPopup: false,
      selectedAddNewUserRole: "",
      teachersData: [],
      selectedTeachers: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleRemoveTeacher = (id: any) => {
    const { teachersData, selectedTeachers } = this.state;
    const removedTeacher = selectedTeachers.find(
      (teacher: any) => teacher.id === id
    );
    const updatedTeachersList = selectedTeachers.filter(
      (item: any) => item.id !== id
    );
    this.setState({
      selectedTeachers: updatedTeachersList,
      teachersData: [...teachersData, { ...removedTeacher }],
    });
    this.props.handleSetSelectedTeachersList(updatedTeachersList);
  };

  handleTeacherAdd = (id: any) => {
    const { teachersData, selectedTeachers } = this.state;
    const addedTeacher = teachersData.find((teacher) => teacher.id === id);
    if (!selectedTeachers?.some((teacher: any) => teacher.id === id)) {
      this.setState({
        selectedTeachers: [...selectedTeachers, { ...addedTeacher }],
        teachersData: teachersData?.filter((teacher) => teacher?.id !== id),
      });
      this.props.handleSetSelectedTeachersList([
        ...selectedTeachers,
        { ...addedTeacher },
      ]);
    }
  };

  handleGetTeachersList = () => {
    const { searchField } = this.state;
    this.setState({ loading: true });
    this.props.handleGetTeachersList(searchField);
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleMouseEnter = (id: any) => {
    this.setState({ hoverTeacher: id });
  };

  handleMouseLeave = () => {
    this.setState({ hoverTeacher: null, hoverSelectedTeacher: "" });
  };

  handleMouseEnterSelectedTeacher = (id: any) => {
    this.setState({ hoverSelectedTeacher: id });
  };

  handleMouseLeaveSelectedTeacher = () => {
    this.setState({ hoverSelectedTeacher: null });
  };

  handleSetTeachersData = () => {
    const { allTeachersList, selectedTeachersList } = this.props;
    const remainingTeachers = allTeachersList.filter(teacher => selectedTeachersList.find(selectedTeacher => parseInt(selectedTeacher.id) === parseInt(teacher.id)) === undefined);
    this.setState({
      teachersData: [ ...remainingTeachers],
      selectedTeachers: [...selectedTeachersList],
    });
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any) {
    if (this.props.allTeachersList !== prevProps.allTeachersList) {
      this.handleSetTeachersData();
    }
  }
  // Customizable Area End
}
// Customizable Area End
