// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  ICustomTableProps,
  gradedActivitiesColumn,
  pendingActivitiesColumn,
  recentActivityTableColumns,
} from "../../../components/src/CommonType.web";
import { Message } from "../../../framework/src/Message";
import {
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  tableColumns: Array<ICustomTableProps>;
  tableBody: Array<{}>;
  typeOfActivity: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentProfileAssignedActivitiesListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetActivityList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      tableBody: [],
      tableColumns: [],
      typeOfActivity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleSetTypeOfActivity();
    // Customizable Area End
  }

  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleSetTypeOfActivity = () => {
    const typeOfActivityList =
      this.props.navigation?.getParam("activityListType");
    let tableColumns: Array<ICustomTableProps> = [];
    if (typeOfActivityList === "recent") {
      tableColumns = [...recentActivityTableColumns];
    }
    if (typeOfActivityList === "pending") {
      tableColumns = [...pendingActivitiesColumn];
    }
    if (typeOfActivityList === "graded") {
      tableColumns = [...gradedActivitiesColumn];
    }
    this.setState({ typeOfActivity: typeOfActivityList, tableColumns }, () => {
      this.handleGetActivitiesList();
    });
  };

  getTimeSpentInHHMM = (timeSpent: number) => {
    return new Date(timeSpent * 1000).toISOString().substring(11, 16);
  };

  getLastActivity = (lastActivityPerformDate: string) => {
    const lastActivityDate = lastActivityPerformDate
      ? moment(lastActivityPerformDate).format("MMMM DD, YYYY")
      : "NA";
    return lastActivityDate;
  };

  getDueDate = (dueDate: string) => {
    const date = dueDate ? moment(dueDate).format("MMMM DD, YYYY") : "NA";
    return date;
  };

  getAssignedOn = (assignedOnDate: string) => {
    const assignedDate = assignedOnDate
      ? moment(assignedOnDate).format("MMMM DD, YYYY")
      : "NA";
    return assignedDate;
  };

  getGradedOn = (gradedOnDate: string) => {
    const gradedDate = gradedOnDate
      ? moment(gradedOnDate).format("MMMM DD, YYYY")
      : "NA";
    return gradedDate;
  };

  handleActivitiesResponse = (responseJson: {
    data: Array<{
      id: string;
      attributes: {
        activities_title: string;
        subject: { icon: string; color: string };
        time_spent: number;
        activity_type: string;
        due_date: string;
        last_activity_perform_at: string;
        due_days: number;
        assigned_on: string;
        grading_component: string;
        max_score: number;
        average: number;
        student_received_grade: {
          graded_on: string;
          grade: number;
          avg: number;
        };
      };
    }>;
  }) => {
    if (responseJson?.data) {
      const activitiesList = responseJson?.data?.map((item) => {
        return {
          id: item?.id,
          Title: {
            icon: item?.attributes?.subject?.icon,
            name: item?.attributes?.activities_title,
            color: item?.attributes?.subject?.color,
          },
          Type: item?.attributes?.activity_type,
          Time_Spent: this.getTimeSpentInHHMM(item?.attributes?.time_spent),
          lastActivity: this.getLastActivity(
            item?.attributes?.last_activity_perform_at
          ),
          DueDate: this.getDueDate(item?.attributes?.due_date),
          Due_Days: item?.attributes?.due_days || 0,
          Assigned_On: this.getAssignedOn(item?.attributes?.assigned_on),
          Grading_Component: item?.attributes?.grading_component,
          Graded_On: this.getGradedOn(
            item?.attributes?.student_received_grade?.graded_on
          ),
          Points: item?.attributes?.max_score || 0,
          Grade: item?.attributes?.student_received_grade?.grade || 0,
          Average: item?.attributes?.student_received_grade?.avg || 0,
        };
      });
      this.setState({ tableBody: activitiesList });
    } else {
      this.setState({ tableBody: [] });
    }
    hideCustomLoader();
  };

  handleGetActivitiesList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const studentId = this.props.navigation?.getParam("studentId");
    const { typeOfActivity } = this.state;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const activityMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiGetActivityList = activityMsg.messageId;

    activityMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentWiseActivities +
        `?student_id=${studentId}&student_activity=${typeOfActivity}_activity`
    );

    activityMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    activityMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(activityMsg.id, activityMsg);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetActivityList) {
          this.handleActivitiesResponse(responseJson);
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
