// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DashboardIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g id="Dashboard" stroke={props.stroke} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Assessment---Due---Quiz" transform="translate(-38.000000, -204.000000)" stroke={props.stroke}>
                    <g id="side-menu" transform="translate(0.000000, 120.000000)">
                        <g id="dashboard-apps" transform="translate(39.000000, 85.000000)">
                            <path d="M16.5,1.37500003 C16.5,0.615615 17.1155875,0 17.875,0 L20.625,0 C21.3844125,0 22,0.615615 22,1.37500003 L22,6.87499973 C22,7.634385 21.3844125,8.25 20.625,8.25 L17.875,8.25 C17.1155875,8.25 16.5,7.634385 16.5,6.87499973 L16.5,1.37500003 Z M0,15.125 C0,14.3655874 0.615615,13.75 1.375,13.75 L4.125,13.75 C4.884385,13.75 5.5,14.3655874 5.5,15.125 L5.5,20.6249997 C5.5,21.3844122 4.884385,22 4.125,22 L1.375,22 C0.615615,22 0,21.3844122 0,20.6249997 L0,15.125 Z M0,1.37500003 C0,0.615615 0.615615,0 1.375,0 L9.625,0 C10.3844125,0 11,0.615615 11,1.37500003 L11,6.87499973 C11,7.634385 10.3844125,8.25 9.625,8.25 L1.375,8.25 C0.615615,8.25 0,7.634385 0,6.87499973 L0,1.37500003 Z M11,15.125 C11,14.3655874 11.6155875,13.75 12.375,13.75 L20.625,13.75 C21.3844125,13.75 22,14.3655874 22,15.125 L22,20.6249997 C22,21.3844122 21.3844125,22 20.625,22 L12.375,22 C11.6155875,22 11,21.3844122 11,20.6249997 L11,15.125 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default DashboardIcon;
// Customizable Area End