// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherParticipantsController, {
  Props,
} from "./TeacherParticipantsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable from "../../../components/src/CustomTable.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import CustomTextField from "../../../components/src/CustomTextField.web";

// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    participantDividerLine: {
      height: "4px",
      background: "#F1F1F1",
      marginTop: "34px",
      marginBottom: "10px",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    participantsTableRows: {
      "& td": {
        height: "50px",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class TeacherParticipantsTab extends TeacherParticipantsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectParticipantSubjectValue,
      selectParticipantGradeValue,
      selectParticipantNameValue,
      selectParticipantUsersValue,
      selectedActiveUsersValue,
      selectedLessonsPerDayValue,
      selectCompletionRateValue,
      participantsTableColumns,
      participantsData,
      participantsTitleDropdownData,
      subjectsDropdownData,
      gradesDropdownData,
      selectTimesModifiedValue,
    } = this.state;

    return (
      <Box
        style={{ padding: "22px 25px 25px 25px" }}
        className={`${classes.bgColor}`}
        data-test-id="ParticipantsComponent"
      >
        <Grid
          container
          spacing={3}
          direction="row"
          data-test-id="ParticipantsGrid"
        >
          <Grid
            item
            xs={12}
            lg={3}
            md={4}
            data-test-id="ParticipantsGridChiild"
          >
            <Box data-test-id="ParticipantsBox">
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ParticipantTitle"
                >
                  Name
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ParticipantsTitleDD"
              >
                <CustomSelectDropdown
                  fieldId="nameDropdown"
                  fieldName="selectParticipantNameValue"
                  handleChange={this.handleParticipantChangeDropdownValue}
                  fieldValue={selectParticipantNameValue}
                  fieldError={false}
                  renderValue={this.renderParticipantsNameDropdownValue}
                  fieldStyles={participantsDropdownStyle}
                  fieldMenuItems={participantsTitleDropdownData}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ParticipantsSubDD">
            <Box>
              <Box marginBottom={"8px"}>
                <Typography
                  className={`${classes.fontText16} ${classes.textCommonColor}`}
                  component={"span"}
                  data-test-id="ParticipantsSub"
                >
                  Subject
                </Typography>
              </Box>
              <Box data-test-id="ParticipantsSubDDBox">
                <CustomSelectDropdown
                  fieldName="selectParticipantSubjectValue"
                  fieldId="subjectDropdown"
                  fieldValue={selectParticipantSubjectValue}
                  handleChange={this.handleParticipantChangeDropdownValue}
                  renderValue={this.renderParticipantSubjectDropdownValue}
                  fieldError={false}
                  fieldMenuItems={subjectsDropdownData}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldStyles={participantsDropdownStyle}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ParticipantsGradeGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="ParticipantsGradeBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ParticipantsGradeTitle"
                >
                  Grade
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ParticipantsGradeDDBox"
              >
                <CustomSelectDropdown
                  fieldName="selectParticipantGradeValue"
                  fieldId="gradesDropdown"
                  fieldValue={selectParticipantGradeValue}
                  handleChange={this.handleParticipantChangeDropdownValue}
                  fieldError={false}
                  fieldStyles={participantsDropdownStyle}
                  renderValue={this.renderParticipantGradeDropdownValue}
                  iconComponent={
                    <DropdownIcon
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                  }
                  fieldMenuItems={gradesDropdownData}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ParticipantsTotalGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="ParticipantsTotalBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ParticipantsTotalBoxTitle"
                >
                  Total no. of users
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ParticipantsTotalBoxDD"
              >
                <CustomTextField
                  placeHolderText="Enter no of users"
                  fieldName="selectParticipantUsersValue"
                  fieldId="selectedNoOfUsersDropdown"
                  textFieldStyles={customInputStyle}
                  fieldWidth={"100%"}
                  fieldType="number"
                  fieldValue={selectParticipantUsersValue}
                  handleChange={this.handleInputChange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            md={4}
            data-test-id="ParticipantsActiveUserGrid"
          >
            <Box>
              <Box marginBottom={"8px"} data-test-id="ParticipantsActiveBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ParticipantsActiveUserName"
                >
                  No. of active users
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ParticipantsActiveUserDDGrid"
              >
                <CustomTextField
                  placeHolderText="Enter no of active users"
                  fieldName="selectedActiveUsersValue"
                  fieldId="noOfActiveUsersDropdown"
                  textFieldStyles={customInputStyle}
                  fieldWidth={"100%"}
                  fieldType="number"
                  fieldValue={selectedActiveUsersValue}
                  handleChange={this.handleInputChange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="ParticipantLessonGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="ParticipantLessonBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="ParticipantLessonTitle"
                >
                  No. of lessons per day
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="ParticipantLessonDD"
              >
                <CustomTextField
                  placeHolderText="Enter no of lessons per day"
                  fieldName="selectedLessonsPerDayValue"
                  fieldId="noOfLessonsPerDayDropdown"
                  textFieldStyles={customInputStyle}
                  fieldWidth={"100%"}
                  fieldType="number"
                  fieldValue={selectedLessonsPerDayValue}
                  handleChange={this.handleInputChange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="participantRateGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="participantRateBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="participantRateTitle"
                >
                  Completion Rate
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="participantRateDD"
              >
                <CustomTextField
                  placeHolderText="Enter completion rate"
                  fieldName="selectCompletionRateValue"
                  fieldId="completionRateDropdown"
                  textFieldStyles={customInputStyle}
                  fieldWidth={"100%"}
                  fieldType="number"
                  fieldValue={selectCompletionRateValue}
                  handleChange={this.handleInputChange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} data-test-id="modifiedGrid">
            <Box>
              <Box marginBottom={"8px"} data-test-id="modifiedBox">
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                  component={"span"}
                  data-test-id="modifiedName"
                >
                  Times Modified
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                data-test-id="modifiedDD"
              >
                <CustomTextField
                  placeHolderText="Enter completion rate"
                  fieldName="selectTimesModifiedValue"
                  fieldId="timesModifiedDropdown"
                  textFieldStyles={customInputStyle}
                  fieldWidth={"100%"}
                  fieldType="number"
                  fieldValue={selectTimesModifiedValue}
                  handleChange={this.handleInputChange}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4}>
            <Box>
              <CustomButton
                btnText="Search"
                btnStyle={participantsBtnStyle}
                buttonId={`my-aparticipant-search-mobile`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleSearchParticipantsBtn}
                isDisabled={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.participantDividerLine} />
        {participantsData.length > 0 ?
          <Grid container data-test-id="PartiTable">
            <Grid item xs={12} data-test-id="PartiTableGrid">
              <Box
                className={`${classes.customTableStyles} ${classes.participantsTableRows}`}
              >
                <CustomTable
                  tableId={"coursesTable"}
                  tableHeaderColumns={participantsTableColumns}
                  tableBodyData={participantsData}
                  showMenuIcon={false}
                />
              </Box>
            </Grid>
          </Grid>
          :
          <CustomEmptyResultComponent message="No record found" />
        }
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const participantsBtnStyle = {
  width: "100%",
  padding: "10px 10px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
} as const;

const participantsDropdownStyle = {
  width: "100%",
} as const;

const customInputStyle = {
  width: "100%",
  height: "36px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherParticipantsTab);
// Customizable Area End
