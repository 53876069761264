import React from "react";

import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area Start
import CustomButton from "../../../components/src/CustomButton.web";
import MyCoursesTab from "./TeacherMyCoursesTab.web";
import TeacherAddNewCourseModal from "./TeacherAddNewCourseModal.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import TeacherCatalogTab from "./TeacherCatalogTab.web";

const styles = (theme: Theme) =>
  createStyles({
    mainContainerPadding: {
      padding: "40px 40px 5px 40px",
    },
    pagePadding: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    courseListMainContainer: {
      maxHeight: "700vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "8px 0px",
    },
    tabBtn: {
      backgroundColor: "transparent",
      padding: "20px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "20px",
      opacity: 1,
      width: "100%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 500,
      },
      "&.MuiTab-root": {
        minHeight: "48px",
      },
    },
    tabPanels: {
      padding: "15px 0px",
    },
    tabDsn: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "center",
        },
      },
    },
  });
const searchBox = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "17.7%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
});
// Customizable Area End

import CourseManagementParentController, {
  Props,
  assessmentCategoryTabs,
} from "./TeacherCourseManagementParentController.web";

export class CourseManagementParent extends CourseManagementParentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, navigation } = this.props;
    const { selectedCatergoryTab, selectedTab, openAddNewCourseModal } =
      this.state;
    return (
      // Customizable Area Start
      <Box
        className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row" data-test-id="CourseMngTab">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12}`}
                component={"span"}
                data-test-id="CourseMngTabText"
              >
                Course Management
              </Typography>
              {selectedTab ? (
                <Typography
                  className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`}
                  component={"span"}
                  data-test-id="CourseMngText"
                >
                  {` / ${selectedTab}`}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid
            className={classes.pagePadding}
            item
            xs={12}
            data-test-id="CourseMain"
          >
            <Box display={"flex"} justifyContent={""}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
                component={"span"}
                style={{ flexGrow: 1 }}
              >
                Course Management
              </Typography>
              <ThemeProvider theme={searchBox}>
                <CustomTextField
                  placeHolderText="Search by Title"
                  fieldName="searchStudentTitle"
                  fieldId="search-title"
                  fieldValue={this.state.searchStudentTitle}
                  fieldWidth={"17.7%"}
                  fieldType="text"
                  handleChange={this.handleChange}
                  startAdornmentIcon={
                    <SearchIcon
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      stroke={"#888888"}
                      strokeWidth="0.45"
                      fill={"#888888"}
                    />
                  }
                />
              </ThemeProvider>
              {selectedCatergoryTab === 0 ? (
                <CustomButton
                  btnText="Add New Course"
                  btnStyle={webStyle.addNewCourseButton}
                  buttonId={`add-new-course-btn`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.handleOpenAddCourseModal}
                  isDisabled={false}
                />
              ) : (
                <CustomButton
                  btnText="My Requests"
                  btnStyle={webStyle.addNewCourseButton}
                  buttonId={`add-new-course-btn`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={this.handleOpenMyRequest}
                  isDisabled={false}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={`${classes.bgColor} ${classes.courseListMainContainer}`}
              container
              direction="row"
              data-test-id="CourseMngtabs"
            >
              <Grid item xs={12}>
                <Tabs
                  variant="fullWidth"
                  scrollButtons="auto"
                  value={selectedCatergoryTab}
                  className={`${classes.tabDsn}`}
                  data-test-id="Coursetabs"
                  onChange={this.handleChangeCategoryTab}
                  aria-label="student-assessment-tabs"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                >
                  {assessmentCategoryTabs.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`student-assessment-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabBtn} ${classes.textPrimaryColor}`}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid className={`${classes.tabPanels}`} item xs={12}>
                <TabPanel value={selectedCatergoryTab} index={0}>
                  <MyCoursesTab navigation={navigation} />
                </TabPanel>
                <TabPanel value={selectedCatergoryTab} index={1}>
                  <TeacherCatalogTab navigation={navigation} tableBody={this.state.coursesTableData} data-test-id='catalog'/>
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TeacherAddNewCourseModal
          open={openAddNewCourseModal}
          onCancel={this.handleCloseAddCourseModal}
          onConfirm={this.handleOpenAddCourseModal}
          handleAddNewCourse={() => { }}
          handleUpdateCourse={() => { }}
          courseId={2}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  addNewCourseButton: {
    width: "max-content",
    height: "50px",
    padding: "15px 20px",
    borderRadius: "12px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    float: "right",
    marginLeft: "20px",
  },
};
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CourseManagementParent);
// Customizable Area End
