// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData, hideCustomLoader } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import { ActionItem } from "./TenantAdminGradeBookController";
// Customizable Area End
const configJSON = require("./config.js");
// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  showNewGrade:boolean
  handleClose:()=>void
  handleEditGrade:(id:number)=>void
  gradeId:number;
  permission?:boolean
  // Customizable Area End
}
export interface GradeBook {
  id: number;
  type: string;
  attributes: {
      id: number;
      title: string | null;
      school_id: number;
      reporting_type: string | null;
      teacher_comments: boolean;
      grading_system_type:string;
      created_at: string;
      updated_at: string;
      grading_systems: {
          data: []; // Adjust type as needed
      };
      behaviours: {
          data: [];
      };
      grading_component_and_weightages: {
          data: [];
      };
      grade_book_rule: {
          data: []; 
      };
  };
}
interface S {
  // Customizable Area Start
  loading: boolean;
  tableBodyData:{
    id: number;
    titles: string | null;
    reportingType: string;
    numOfGradingComponent: 0;
  }[] 
  assessmentStudentsColumns:any;
  gradesTableActionButtonConfig:ActionItem[]
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminGradeBookListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigradeBookTenantListId:string=""
  deleteGradeBookTenantCallId:string=""
  createGradeBookTenantApiCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      tableBodyData:[],
      assessmentStudentsColumns:[],
      gradesTableActionButtonConfig :[
        {
            label: "Edit",
            action: (value: any) => this.props.handleEditGrade(value),
        },
        {
            label: "Delete",
            action: (value: number) => this.handleDeleteGradeBookTenantApi(value),
        },
    ]
    
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGradebookTenantApi()
    this.props.handleClose()
    // Customizable Area End
  }
  async componentDidUpdate(prevProps: Props, prevState: Readonly<{}>) {
    if ((this.props.showNewGrade && !prevProps.showNewGrade) || !this.props.showNewGrade && prevProps.showNewGrade) {
     this.handleGradebookTenantApi()
    }
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apigradeBookTenantListId:
              this.handleGetGradeBookTenatListResponse(responseJson)
              break;
          case this.deleteGradeBookTenantCallId:
              this.handleDeleteGradeBookTenantResponse(responseJson)
        }
      }
    }
  }
  handleGradebookTenantApi = async () => {

    const requestUrl = `${configJSON.gradeBookTenantEndpoint}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apigradeBookTenantListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradeBookTenatListResponse = async(responseJson: {
    data: GradeBook[]
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const transformedData = responseJson.data.map(item => ({
        id: item.attributes.id,
        titles: item.attributes.title,
        reportingType: item.attributes.reporting_type === "progress_based" ? "Progress based" : "Time based",
        numOfGradingComponent: item.attributes.grading_component_and_weightages.data.length
      }));
    
      this.setState({ tableBodyData: transformedData });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }

  handleDeleteGradeBookTenantApi  = async (gradeSystemId: number) => {
    const requestUrl = `${configJSON.gradeBookTenantEndpoint}/${gradeSystemId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGradeBookTenantCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteGradeBookTenantResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      await this.handleGradebookTenantApi();
      toast.success("Grade book Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };
  // Customizable Area End
}
// Customizable Area End
