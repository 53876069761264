// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Hidden } from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import JourneyCourseActivityDetailController, {
  Props
} from "./JourneyCourseActivityDetailController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import StudentMyCoursesActivityJourneyBox from "./StudentMyCoursesActivityJourneyBox.web";
import CustomAccordion from "../../../components/src/CustomAccordion.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginBottom: "28px",
      marginTop: "16px",
    },
    topicLessonBox: {
      maxWidth: "98%",
      width: "98%",
      borderRadius: "6px",
      [theme.breakpoints.down('sm')]: {
        width: "95%",
      },
    },
    borderRight: {
      borderRight: "2px solid #f1f1f1"
    },
    columnDataList: {
      borderTop: "2px solid #f1f1f1"
    },
    searchTextBox: {
      marginBottom: "20px",
      marginTop: "20px",
    },
    activityListColumn: {
      maxHeight: "700px",
    },
    activityContainer: {
      maxHeight: "800px",
      overflowY: "auto",
      borderTop: "2px solid #f1f1f1",
    },
    activityHeader: {
      padding: "2% 3%",
    },
    activityDetailGrid: {
      overflow: "auto",
      maxHeight: "700px",
    },
  })

// Customizable Area End

// Customizable Area Start
export class JourneyCourseActivityDetail extends JourneyCourseActivityDetailController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { currentLessonActivityList, selectedActivityTaskDetailId, selectedActivityTaskDetail } = this.state;
    return (
      <Box className={`${classes.highlightBgColor} ${classes.secondaryBgColor}`}>
        <Grid container direction="row">
          <Grid xs={12} item>
            <Box className={`${classes.topicLessonBox} ${classes.bgColor}`}>
              <Hidden only={["xs", "sm"]}>
                <Grid direction="row" className={classes.activityContainer} container>
                  {/* Column Header */}
                  <Grid item className={classes.activityListColumn} xs={5} sm={3}>
                    <Box style={{ padding: "5% 12%", backgroundColor: "rgba(0, 157, 255, 0.05)", }}>
                      <Typography component={"span"} className={`${classes.primaryColor14} ${classes.fontText18} ${classes.fontBold600}`} >
                        Activity
                      </Typography>
                    </Box>
                    {
                      currentLessonActivityList?.length > 0 && currentLessonActivityList.map((activity: any) => <StudentMyCoursesActivityJourneyBox
                        key={activity?.id} isFromAccordion={false} detail={{
                          id: activity?.id,
                          completionProgress: activity?.activityCompletionProgress,
                          title: activity?.activityTitle || ""
                        }}
                        selectedItemId={selectedActivityTaskDetailId}
                        typeOfActivity={activity?.typeOfActivity}
                        handleSelectItem={this.handleSelectActivityTask}
                      />)
                    }
                  </Grid>
                  {/* Activity Detail */}
                  <Grid item className={classes.activityDetailGrid} xs={7} sm={9}>
                    {this.handleActivityDetailRender(selectedActivityTaskDetail, false)}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={["md", "lg", "xl"]}>
                <Box width={"inherit"} p={"8px"}>
                  {currentLessonActivityList?.length > 0 && currentLessonActivityList.map((activity: any) => <CustomAccordion
                    key={activity.id}
                    panelId={`${activity.activityTitle}-${activity.id}`} keyValue={activity.id}
                    expanded={activity?.expanded}
                    summaryComponent={<StudentMyCoursesActivityJourneyBox isFromAccordion key={activity?.id} detail={{
                      id: activity?.id,
                      title: activity?.activityTitle || "",
                      completionProgress: activity?.activityCompletionProgress || 0,
                    }}
                      typeOfActivity={activity?.typeOfActivity} handleSelectItem={this.handleSelectActivityTask}
                    />
                    }
                    navigation={navigation}
                    handleSelectItem={() => this.handleSelectActivityTask(activity.id)}
                    classes={classes}
                  >
                    {this.handleActivityDetailRender(activity, true)}
                  </CustomAccordion>)}
                </Box>
              </Hidden>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(JourneyCourseActivityDetail);
// Customizable Area End
