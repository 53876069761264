import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";

// Customizable Area Start

import { getStorageData } from "../../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../../components/src/CommonHelper.web";
import { sort } from "../assets";
import { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import moment from "moment";
import { toast } from "react-toastify";

// Customizable Area End
// Customizable Area Start
const configJSON = require("./../config.js");
// Customizable Area End

// Customizable Area Start
type SortOrder = 'ascending' | 'descending' | undefined;
interface Column {
  id: string;
  sortByOrder?: SortOrder;  
}
interface OnColumnSortData {
  id: string;
  sortByOrder?: SortOrder;
}

interface Item {
  [key: string]: string | number | Date | Record<string, null> | null;
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  selectedCourseId: any;  
  studentCoursesList: any;
  selectedSubjectTopicList: any;
  selectedCourse: any;
  gradingComponent: Array<any>;
  selectedGradingComponentDDValue: any;
  studentListTableBody: Array<any>;
  searchStudent: string;
  hoveredCourse: any;
  studentListTableColumns: Array<any>;

  pagination: any;
  studentPagination: any;
  courseName:string;
  openRescheduleModalBox: boolean;
  // Customizable Area End
}

export default class StudentPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiGetTeachersAssignedClasses: string = "";
  apiGetStudentList: string = "";

  apiGetStudentCourses: string = "";
  apiGetStudentsList: string = "";
  apiGetTeachersClasses: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {     
      studentPagination: {},
      pagination: {},
      courseName:'', 
      openRescheduleModalBox: false,
      searchStudent: '',
      hoveredCourse: null,
      selectedCourseId: null,
      selectedCourse: null,
      selectedSubjectTopicList: [],
      selectedGradingComponentDDValue: '',      
      studentCoursesList: [],    
      gradingComponent: [],
      studentListTableBody: [],      
      studentListTableColumns: [
        {
          id: "Name",
          columnId: 1,
          label: "Name",
          type: tableColumnTypes.STUDENT_ICON
        },
        {
          id: "Progress",
          columnId: 2,
          label: "Progress",
          icon: sort,
          type: tableColumnTypes.STUDENT_PROGRESS_BAR
        },
        {
          id: "SemesterAvg",
          columnId: 3,
          label: "Semester Avg",
          icon: sort,
          type: tableColumnTypes.PROGRESS_TEXT
        },
        {
          id: "GPA",
          columnId: 4,
          label: "GPA",
          icon: sort,
          type: tableColumnTypes.PROGRESS_TEXT
        },
        {
          id: "LastAccess",
          columnId: 5,
          label: "Last Access",
          icon: sort,
          type: tableColumnTypes.DUE_DATE
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetTeachersClassesApi();
     // Customizable Area End    
  }  

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetStudentCourses:
            {
              this.handleGetStudentCoursesResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetStudentsList:
            {
              this.handleGetStudentsListResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetTeachersClasses:
            {
              this.handleGetTeachersClassesResponse(
                responseJson,
                errorResponse
              );
            }
            break;
        }
      }
    }
  }   
    
    getDataFromPath(data: any, path: string) {
      return path.split('.').reduce((acc, part) => acc && acc[part], data);
    } 

  handleStudentCourse = (courseId: any) => {
    const { studentCoursesList } = this.state;
    const selectedCourse: any = studentCoursesList.find((course: any) => course?.id === courseId);
    this.setState({ selectedCourseId: courseId, selectedCourse })
    
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));

    const path = 'CourseManagement';

    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      path
    );
    
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    const payload = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    payload.addData(MessageEnum.NavigationPayLoadMessage, {
      tabIndex: 1,
    });
    
    navigationMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      payload
    );
    
    this.send(navigationMessage); 
  }

  renderGradingComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find((grade: any) => grade?.id === selected);
    return selectedGradingComponents?.label;
  };

  handleChangeDropdownValues = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
          selectedCourse: null,
          selectedCourseId: "",
        };
      }, () => {
        this.handleGetStudentsListApi({},{}); 
        this.handleGetStudentCoursesApi({});     
      });
    }
  };

  handleclick = async (e: any) => {
    const { studentListTableBody } = this.state;
    const studentDetails = studentListTableBody.find((item: any) => item?.id === e?.id);
    if (studentDetails) {
      this.props.navigation.navigate('StudentProfileProgress', {}, {
        studentId: studentDetails.id
      })
    }
  }

  

   handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    }, () => {
      setTimeout(() => {
        this.handleGetStudentsListApi({}, {});
      }, 400);
    });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredCourse: null });
  };

  handleMouseEnter = (courseId: any) => {
    this.setState({ hoveredCourse: courseId });
  }; 


  handleGetStudentCoursesApi = async (pagination: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };
    const { selectedGradingComponentDDValue, gradingComponent } = this.state;
    const page = pagination.page ? pagination.page : 1;
    let requestUrl = configJSON.getTeacherCoursesEndpoint;

    if (selectedGradingComponentDDValue && selectedGradingComponentDDValue !== 'all') {
      requestUrl = requestUrl + `?student_id=${selectedGradingComponentDDValue}`;
    }

    if (selectedGradingComponentDDValue && selectedGradingComponentDDValue == 'all') {
      const classIds = gradingComponent.filter((classItem: { id: string, value: string, label: string }) => classItem.value !== "all").map((item: { id: string, value: string, label: string }) => {
        return item.value
      });      
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetStudentCourses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetStudentCoursesResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const courseList = responseJson.data.map((item: any) => {
        const studentCourse = item.attributes;
        const lessonsCompleted = studentCourse?.completed_lessons || 0;
        const totalLessons = studentCourse?.total_no_of_lessons;
        const lessonCompletionPercent =
          lessonsCompleted === 0
            ? 0
            : Math.ceil((lessonsCompleted / totalLessons) * 100);
        return {
          id: item.id,
          subjectName: studentCourse?.course_title,
          subjectMarks: 10,
          lessonsCovered: studentCourse?.completed_lessons || 0,
          lessonsTotal: studentCourse?.total_no_of_lessons,
          status: studentCourse?.student_course_status,
          subjectIcon: studentCourse?.subject?.icon,
          timeSpentInMin: studentCourse?.time_spent || 0,
          subjectBgColor: studentCourse?.subject?.color,
          completionProgress: lessonCompletionPercent,
          dueDate: moment(studentCourse?.school_year?.end_date).format('MMMM DD, YYYY'),
          startDate: moment(studentCourse?.school_year?.start_date).format('MMMM DD, YYYY'),
          isAssigned: studentCourse?.is_assigned,
        }
      });
      this.setState({ studentCoursesList: courseList, pagination: responseJson?.meta?.pagination });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };


  handleGetStudentsListApi = async (params: any, pagination: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };
    const { selectedGradingComponentDDValue, gradingComponent, searchStudent } = this.state;
    const page = pagination.page ? pagination.page : 1;
    let requestUrl = configJSON.teacherClassStudentsApi ;

    if (selectedGradingComponentDDValue && selectedGradingComponentDDValue !== 'all') {
      requestUrl = requestUrl + `?student_id=${selectedGradingComponentDDValue}`;
    }

    if (selectedGradingComponentDDValue && selectedGradingComponentDDValue == 'all') {
      const classIds = gradingComponent.filter((classItem: { id: string, value: string, label: string }) => classItem.value !== "all").map((item: { id: string, value: string, label: string }) => {
        return item.value
      });     
    }

    if (searchStudent) {
      requestUrl = requestUrl + `?name=${searchStudent}`;
    }

    if (params.courseId) {
      requestUrl = requestUrl + `?course_id=${params.courseId}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetStudentsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetStudentsListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const studentList = responseJson.data.map((item: any) => {
        const student = item.attributes;
        const progressTableBody = student.progress.course_wise_progress || [];
        const filterProcess = progressTableBody.filter((item:{
          id: number;
          title: string;
          completed_percentage: number;
          remaining_percentage: number;
          not_tackled_percentage: number;
        }) =>  item && item.title && item.title.trim() === this.state.courseName.trim()) 
        let progressObject;
        if (filterProcess.length > 0) {
          const firstProgressItem = filterProcess[0];
          progressObject = {
            value1: firstProgressItem.completed_percentage,
            value2: firstProgressItem.remaining_percentage ,
            value3: firstProgressItem.not_tackled_percentage,
          };
        } else {
          progressObject = {
            value1: student.progress.completed ,
            value2:  student.progress.remaining,
            value3:student.progress.not_tackled,
          };          
   }
        return {
          id: item.id,
          Name: {
            icon: student?.profile_url?.url || "",            
            name: (() => {
              const fullName = `${student?.first_name} ${student?.last_name}`;
              return fullName.length > 20 ? fullName.slice(0, 20) + "..." : fullName;
            })(),
            id: item.id,
          },
          Progress: progressObject,
          SemesterAvg: this.trimDemicalNumber(student?.semsester_avg),
          GPA: this.trimDemicalNumber(student?.gpa),
          LastAccess: student?.last_access ? moment(student?.last_access).format('MMMM DD, YYYY') : 'NA',
        }
      });
      this.setState({ studentListTableBody: studentList, studentPagination: responseJson?.meta?.pagination   });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetTeachersClassesApi = async () => {
    showCustomLoader();
    const requestUrl = configJSON.teacherClassesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersClassesResponse = (response: any, errorResponse: any) => {
    if (response?.data) {
      const classesDropdwonAll = [{
        id: "all",
        label: "All",
        value: "all",
      }];
      let classesDropdwonData = response.data.map(
        (item: any) => {
          return {
            label: item?.attributes?.first_name +  item?.attributes?.last_name,
            value: item?.id,
            id: item?.id,
          }
        }
      );
      this.setState({
        gradingComponent: classesDropdwonAll.concat(classesDropdwonData),
        selectedCourse: null,
          selectedCourseId: "",
      }, () => {
        this.handleGetStudentCoursesApi({});
        this.handleGetStudentsListApi({}, {});
      });
    } else {
      displayApiErrorMsg(response?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleLoadCourseStudents = (course: any) => {
    this.setState({courseName:course.subjectName})
    if (course.isAssigned) {
      this.setState({ selectedCourseId: course.id
        , courseName:course.subjectName, 
       });
      this.handleGetStudentsListApi({ courseId: course.id }, {});
    }
  }

  trimDemicalNumber(value: number) {
    if (value) {
      const str = value.toString();
      const dotIndex = str.indexOf('.');
      if (dotIndex === -1) {
        return value;
      }
      const integerPart = str.slice(0, dotIndex);
      const decimalPart = str.slice(dotIndex + 1, dotIndex + 3);
      return parseFloat(integerPart + '.' + decimalPart);
    } else {
      return 0;
    }
  }


  handleOpenReschedulModalBox = () => {
    if (this.state.selectedGradingComponentDDValue !== '' && this.state.selectedGradingComponentDDValue !== "all") {
    this.setState({ openRescheduleModalBox: true });
    }else {
      toast.warning('Please select student first')
    }
  };
  handleCloseAssignCourseModalBox = () => {
    this.setState({ openRescheduleModalBox: false });
  };

  getSelectedCourseBoxClass = (courseId: any) => {
    if (courseId) {
      const { selectedCourseId } = this.state;
      const { classes } = this.props;
      if (parseInt(selectedCourseId) === parseInt(courseId))
        return `${classes.bgColor} ${classes.selectedBoxBorder} ${classes.coursesMainBox}`;
      return `${classes.bgColor} ${classes.coursesMainBox}`;
    } else {
      return '';
    }
  };

  getAvgScoreColor = (status: string) => {
    const { classes } = this.props;
    if (status === "Completed") return `${classes.statusCompletions} `;
    else if (status === "Late") return `${classes.statusLateColor} `;
    else if (status === "On Going") return `${classes.onGoingStatus} `;
    else return `${classes.scheduledStatus}`;
  };

  handlePaginationChange = (event: any, page: number) => {
    this.handleGetStudentCoursesApi({ page: page });
  }

  getSortByOrder = (column: Column, sortByColumnId: string): SortOrder => {
    if (column.id === sortByColumnId) {
      return column.sortByOrder === "ascending" ? "descending" : "ascending";
    }
    return undefined;
  };

  getValueForSorting = (item: Item, columnId: string): any => {
    const value = item[columnId];
    if (typeof value === "object" && value !== null) {
      return Object.values(value)[0];
    }
    return value;
  };

  compareValues = (aValue: number, bValue: number)  => {
    if (aValue > bValue) return 1;
    if (aValue < bValue) return -1;
    return 0;
  };

  onColumnSort = (data: OnColumnSortData) => {
    const { studentListTableBody, studentListTableColumns } = this.state;  
  
    const updatedStudentTableColumns = studentListTableColumns.map((column) => ({
      ...column,
      sortByOrder: this.getSortByOrder(column, data.id),
    }));
  
    const selectedColumn = updatedStudentTableColumns.find((column) => column.id === data.id);
    if (!selectedColumn) return;
    
    const sortOrder = selectedColumn.sortByOrder === "ascending" ? 1 : -1;
  
    const sortedStudentList = studentListTableBody.slice().sort((a, b) => {
      const aValue = this.getValueForSorting(a, data.id);
      const bValue = this.getValueForSorting(b, data.id);
   
      const aTime = data.id === "LastAccess" ? new Date(aValue).getTime() : aValue;
      const bTime = data.id === "LastAccess" ? new Date(bValue).getTime() : bValue;
    
      if (aTime === undefined) return 1;
      if (bTime === undefined) return -1;
      
      return this.compareValues(aTime, bTime) * sortOrder;
    });
    this.setState({
      studentListTableBody: sortedStudentList,
      studentListTableColumns: updatedStudentTableColumns,
    });
  };

  handlePagination = (event: any, page: number) => {
    const { selectedCourseId } = this.state;
    this.handleGetStudentsListApi({ courseId: selectedCourseId }, { page: page });
  }
  isCourseAssignable = (startDate: Date) => {
    let startDateObject: Date = startDate;

    const isNotDateObject = !(startDate instanceof Date);
    if (isNotDateObject) startDateObject = new Date(startDate);

    const currentDate = new Date();
    const isStartDateAhead = startDateObject > currentDate;

    return isStartDateAhead;
  }
  
  // Customizable Area End
}