// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Hidden,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CoursesJourneyTabController, {
  Props,
} from "./CoursesJourneyTabController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import StudentProgressJourneyMobileView from "./StudentProgressJourneyMobileView.web";
import StudentProgressJourneyDesktop from "./StudentProgressJourneyDesktop.web";

import JourneyDesktop from "./JourneyDesktop.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
  });
// Customizable Area End

// Customizable Area Start
export class CoursesJourneyTab extends CoursesJourneyTabController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { selectedCourseSubject, selectCourseSubject } = this.state;
    return (
      <Box
        className={`${classes.highlightBgColor} ${classes.secondaryBgColor}`}
      >
        <Grid container direction="row">
          {/* Topic Lessons Activity Box */}
          <Hidden only={["lg", "md", "xl"]}>
            <Grid item xs={12}>
              <StudentProgressJourneyMobileView
                selectedSubjectName={selectedCourseSubject}
                handleSetTopicName={this.handleSetTopicName}
                navigation={navigation}
                handleSetLessonName={this.handleSetLessonName}
                courseData={{}}
              />
            </Grid>
          </Hidden>
          <Hidden only={["sm", "xs"]}>
            <Grid xs={12} item>
              <StudentProgressJourneyDesktop
                isActivityWrite={true}
                handleSetLessonName={this.handleSetLessonName}
                navigation={navigation}
                handleSetTopicName={this.handleSetTopicName}
                selectedSubjectName={selectCourseSubject}
                courseData={{}}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CoursesJourneyTab);
// Customizable Area End
