// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentDueQuizController, {
  Props,
} from "./StudentDueQuizController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    answerColorBox: {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    iconBtn: {
      width: "inherit",
      height: "inherit",
      color: "#ffffff",
    },
    topMargin: {
      marginTop: "25px",
    },
    leftMargin: {
      marginLeft: "1%",
    },
    optionGrid: {
      display: "flex",
      alignItems: "center",
    },
  });

// Customizable Area End

// Customizable Area Start
export class StudentDueQuiz extends StudentDueQuizController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, question, index } = this.props;
    return (
      <Box
        marginBottom={"3%"}
        padding={"0px 25px"}
        key={question?.id}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display={"flex"} alignItems="baseline">
          <Typography
            className={`${classes.textPrimaryColor} ${classes.fontText20}`}
          >{`Q${index + 1}.`}</Typography>

          <Typography
            className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.leftMargin}`}
            component={"p"}
            dangerouslySetInnerHTML={{ __html: question?.question }}
          ></Typography>
        </Box>
        {/* Answers list */}
        <Box marginTop={"1%"}>
          <Grid container direction="row">
            {question?.answers?.map((answer: any, indexTwo: number) => (
              <Grid
                className={classes.optionGrid}
                key={`${question?.id}-${answer?.id}`}
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <Box marginRight={"2px"}>
                  <CustomRadioButton
                    isDueQuiz
                    checked={answer?.correct_answer}
                    fieldName={`${answer?.option}-${indexTwo}`}
                    handleChange={() =>
                      this.props.handleSelectAnswer(answer?.id)
                    }
                  />
                </Box>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.leftMargin}`}
                >
                  {answer?.option}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentDueQuiz);
// Customizable Area End
