// Customizable Area Start
import React from "react";
import { Box, FormHelperText } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomDateTimePickerController, {
  Props,
} from "./CustomDateTimePickerController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import DatePicker from "react-datepicker";
import CalendarIcon from "./SVGIcons/CalendarIcon.web";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    errorBorder: {
      border: "1px solid red !important",
    },
    inputAdornment: {
      paddingLeft: "15px",
    },
    inputAdornmentEnd: {
      paddingRight: "15px",
    },
  });

// Customizable Area End

export class CustomDateTimePicker extends CustomDateTimePickerController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      selectedDate,
      format,
      fieldId,
      placeHolderText,
      fieldError,
      isDisabled,
      fieldStyles,
      fieldErrorMsg
    } = this.props;

    const getTextfieldClasses = () => {
      if (fieldError) {
        return `$${classes.primaryBorder} ${
          classes.bgColor
        } ${classes.errorBorder}`;
      } else if (isDisabled) {
        return `$${classes.primaryBorder} ${
          classes.secondaryBgColor
        }`;
      } else {
        return `$${classes.bgColor} ${
          classes.primaryBorder
        }`;
      }
    };

    return (
        <Box
        className="DateContainer"
        style={{ marginTop: "6px", width: "96%" }}
        id={fieldId}
      >
        <span className="dateInput">
          <DatePicker
            id={`date-time-picker-${fieldId}`}
            disabled={isDisabled}
            onChange={this.handleSelectDate}
            className={getTextfieldClasses()}
            popperPlacement="top-start"
            popperClassName="datetime-picker"
            selected={selectedDate}
            showTimeSelect
            placeholderText={placeHolderText}
            dateFormat={format}
          />
        </span>
        <span className="icon">
          <CalendarIcon />
        </span>
        {fieldError && <FormHelperText className={`${classes.helperText}`}>{fieldErrorMsg}</FormHelperText>}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomDateTimePicker);
// Customizable Area End
