// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Typography,
  Hidden,
  Menu,
  MenuItem,
  Dialog,
  Badge,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { createStyles, withStyles, Theme, styled } from "@material-ui/core/styles";
import WebHeaderController, { Props } from "./WebHeaderController.web";
import NotificationBellIcon from "./SVGIcons/NotificationBellIcon.web";
import ThemeNightIcon from "./SVGIcons/ThemeNightIcon.web";
import WbSunnyRoundedIcon from "@material-ui/icons/WbSunnyRounded";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import CustomImageComponentWeb from "./CustomImageComponent.web";
import { borderRadius } from "react-select/src/theme";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";
import CustomEmptyResultComponentWeb from "./CustomEmptyResultComponent.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    topNavbar: {
      padding: "10px",
      height: "85px",
      position: "fixed",
      top: "0px",
      right: 0,
      marginLeft: `calc(100% - 200px)`,
      zIndex: 2,
    },
    headerLogo: {
      padding: "0px 16px",
      height: "60px",
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
    },
    headerLogoGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    headerProfilePicImg: {
      width: "inherit",
      height: "inherit",
      objectFit: "contain",
    },
    headerProfilePicImgDiv: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
    },
    headerProfileGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    headerProfileGridItems: {
      marginLeft: "2%",
    },
    themeIconBox: {
      borderRadius: "50%",
      padding: "6px",
      cursor: "pointer",
    },
    notificationIconBox: {
      paddingLeft: "9px",
      paddingRight: "5px",
      paddingTop: "7px",
      paddingBottom: "5px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    hamburgerIcon: {
      cursor: "pointer",
      marginRight: "2%",
    },
    fontText20: {
      fontSize: "20px",
    },
    fontText16: {
      fontSize: "16px",
    },
    moreOptionArrow: {
      marginLeft: "5px",
      cursor: "pointer",
    },
    roleCapital: {
      textTransform: "capitalize",
    },
    subjectLogoBg: {
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "30px",
      minHeight: "30px",
      height: "30px",
      flexShrink:0,
      width: "30px",
  },
  subjectIcon: {
      width: "30px",
      height: "30px",
      objectFit: "contain",
  },
  badgeUI:{
    "& .MuiBadge-anchorOriginTopRightRectangle":{
      right:5
    },
    "& .MuiBadge-dot":{
      width:"13px",
      height:"13px",
      borderRadius:"50%",
      border:"1px solid white"
    }
  }
  });

// Customizable Area End

export class WebHeader extends WebHeaderController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { classes, isDarkMode } = this.props;
    const userFullName = JSON.parse(
      JSON.stringify(localStorage.getItem("user_name") || "User Name")
    );
    const userProfileUrl = JSON.parse(
      JSON.stringify(localStorage.getItem("user_profile_img") || "")
    );
    const { menuAnchorEl,logoUrl } = this.state;
    return (
      <Grid
        className={`${classes.topNavbar} ${classes.highlightBgColor} ${
          classes.bgColor
        }`}
        container
        direction="row"
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid className={classes.headerLogoGrid} item xs={4}>
              <img
                src={logoUrl ? logoUrl : require("./YUDU-logo.png")}
                alt="logo"
                className={classes.headerLogo}
              />
            </Grid>
            <Grid className={classes.headerProfileGrid} item xs={8}>
              <Box
                bgcolor={isDarkMode ? "#888888" : "#f1f1f1"}
                onClick={this.props.handleToggleThemeSettings}
                className={`${classes.themeIconBox} ${
                  classes.headerProfileGridItems
                }`}
              >
                {isDarkMode ? (
                  <WbSunnyRoundedIcon
                    stroke={isDarkMode ? "#000" : "#888888"}
                    fill={isDarkMode ? "#000" : "#888888"}
                  />
                ) : (
                  <ThemeNightIcon
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    stroke={isDarkMode ? "#000" : "#888888"}
                    strokeWidth="1.0"
                    fill={isDarkMode ? "#000" : "#888888"}
                  />
                )}
              </Box>
              <Box
                bgcolor={isDarkMode ? "#888888" : "#f1f1f1"}
                className={`${classes.notificationIconBox} ${
                  classes.headerProfileGridItems
                }`}
              >
                {this.state.isNewNotification ?
                <Badge color="secondary" badgeContent={" "} variant="dot" className={classes.badgeUI}>

                <NotificationBellIcon
                  width="22"
                  onClick={this.handleNotificationModelOpen}
                  height="26"
                  viewBox="0 0 28 34"
                  stroke={isDarkMode ? "#000" : "#888888"}
                  strokeWidth="1.0"
                  fill={isDarkMode ? "#000" : "#888888"}
                />
                </Badge>
               :  <NotificationBellIcon
               width="22"
               onClick={this.handleNotificationModelOpen}
               height="26"
               viewBox="0 0 28 34"
               stroke={isDarkMode ? "#000" : "#888888"}
               strokeWidth="1.0"
               fill={isDarkMode ? "#000" : "#888888"}
             /> }
               
               
              </Box>
              <Box ml={"2%"} pr={"3%"} display={"flex"} alignItems="center">
                <Hidden only={"xs"}>
                  <Box
                    className={`${classes.headerProfilePicImgDiv} ${
                      classes.headerProfileGridItems
                    }`}
                  >
                    {userProfileUrl ? (
                      <CustomImageComponentWeb
                        src={userProfileUrl}
                        altText="Profile Image"
                        imgStyles={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "cover",
                          borderRadius: "50%"
                          
                        }}
                        isUserProfileImg
                      />
                    ) : (
                      <AccountCircleIcon
                        style={{
                          color: isDarkMode ? "#888888" : "#ccc",
                        }}
                        className={`${classes.headerProfilePicImg}`}
                      />
                    )}
                  </Box>
                </Hidden>
                <Box
                  ml={"4%"}
                  display={"flex"}
                  justifyContent="center"
                  whiteSpace={"nowrap"}
                  flexDirection={"column"}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.fontText20} ${
                      classes.textPrimaryColor
                    }`}
                  >
                    {userFullName}
                  </Typography>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      component={"span"}
                      className={`${classes.fontText16} ${
                        classes.textCommonColor
                      } ${classes.roleCapital}`}
                    >
                      {this.state.profileRole}
                    </Typography>
                    <ArrowDropDownCircleOutlinedIcon
                      className={`${classes.textCommonColor} ${
                        classes.moreOptionArrow
                      }`}
                      onClick={this.handleActionClick}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id={`header-menu-action`}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem onClick={this.handleGotoProfile}>Profile</MenuItem>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu> 
        <NotificationMenu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={this.state.anchorNotification}
          open={this.state.isNotificationOpen}
          onClose={this.handleCloseNotification}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{style:webStyles.notificationList}}
          PaperProps= {{style:webStyles.notificationPopover}}
        >
          <Box className={classes.secondaryBgColor} style={webStyles.notificationMainBox} >
            <Typography className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600}`} >Notifications</Typography>
            {this.state.notificationData.length > 0 &&
            <Typography  onClick={this.handleViewAll}  className={`${classes.fontText12} ${classes.fontBold600} ${classes.textButton} ${classes.cursorPointer}`}
                  component={"span"}>
                    View All
            </Typography>
            }
          </Box>
          <Box className={classes.bgColor} style={webStyles.notificationUlBox} >
            {
              this.state.notificationData.length > 0 && this.state.notificationData.map((element:  any, index: React.Key | undefined)=> (
                <Box  style={{...webStyles.notificationDetails, borderTop: "1px solid rgba(224, 224, 224, 1)",}}
                 className={`${!element.attributes.is_read ? `${classes.highlightBgColor} ${classes.secondaryBgColor}` : ''}`}
                  key={element.id}> 
                  <Box
                    className={classes.subjectLogoBg}
                    mr={"10px"}
                  >
                    <img
                      src={element.attributes.icon}
                      alt="Subject Logo"  
                      className={classes.subjectIcon}

                    />
                  </Box>
                  <Box   style={webStyles.notificationDetailsBox}>              
                    <Typography 
                    component="span"
                     className={`${classes.textPrimaryColor} 
                     ${classes.fontText14} ${classes.commanFontSize}`} 
                     style={webStyles.notificationDescH} 
                     dangerouslySetInnerHTML={{ __html: element?.attributes?.headings}}></Typography>
                  </Box>                                
                </Box>
              ))
            }
            {
              this.state.notificationData.length === 0 && (
              <CustomEmptyResultComponentWeb 
                message="<p style='font-size: 14px;'>You don't have any notification.</p>" 
              />
              )
            }
          </Box> 
        </NotificationMenu>  
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const NotificationMenu = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    width: '320px',

    '@media (max-width: 600px)': {
    width: '320px', 
      padding: '10px',
    }
  },
  "& .MuiPaper-rounded": {
    borderRadius: '12px',
    top:"66px !important"
  },
  "& .MuiList-padding": {
    

  },
}));
const webStyles = {
  dashboardHeader: {
    boxShadow: "0px 2px 16px 2px rgba(0,0,0,0.08)",
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    '@media (max-width: 600px)': {
      padding: '10px',
    }
  },
  btnrow: {
    display: "flex",
    gap: "10px",

  },
  notification: {
    width: "44px",
    height: "44px",
    background: "#F2F3F7",
    borderRadius: "50px",
    overflow: "hidden",
    padding: "0",
    border: "none",
    cursor: "pointer",
    '@media (max-width: 600px)': {
      width: "36px",
      height: "36px",
    }
  },
  headtext: {
    color: "#2B3D7D",
    letterSpacing: "0.32px",
    margin: 0,
    fontFamily: "Poppins",
    textTransform: 'capitalize',
    fontWeight: 600,
    '@media (max-width: 600px)': {
      marginLeft:30
    }
  },
  jointext: {
    fontSize: "20px",
    color: "#2B3D7D",
    letterSpacing: "0.32px",
    margin: 0,
    cursor:"pointer",
    marginTop:"10px",
    fontFamily: "Poppins",
    textTransform: 'capitalize',
    fontWeight: 600,
    '@media (max-width: 600px)': {
      fontSize: "16px",
    }
  },
  dropdownmore: {
    display: "block",
    gap: "10px",
    borderRadius: "6px",
    padding: "15px 0",
    width: "max-content",
    '@media (max-width: 600px)': {
      width: "100%",
    }
  },
  moreBtnMenus: {
    fontSize: '16px',
    color: '#1c1c1e',
    fontWeight: 400,
    display: 'flex',
    gap: '10px',
    backgroundColor: '#fff',
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '12px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '14px',
      padding: '10px',
    }
  },
  moreBtnMenuActive: {
    // background: gradientBackground,
    fontSize: '16px',
    color: '#fff',
    fontWeight: 400,
    display: 'flex',
    gap: '10px',
    height: "45px",
    alignItems: 'center',
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '12px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '14px',
      padding: '10px',
    }
  },
  profileImage: {
    width: "100%",
    '@media (max-width: 600px)': {
      width: "36px",
      height: "36px",
    }
  },
  defaultImage: {
    width: "44px",
    height: "44px",
    color: "gray",
    '@media (max-width: 600px)': {
      width: "36px",
      height: "36px",
    }
  },
  notificationMainBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
  },
  notificationMainTitle: {
    // fontFamily: 'Roboto-Medium',
    '@media (max-width: 600px)': {
      fontSize: '16px',
    }
  },
  notificationTopRightBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      width: '100%',
      marginTop: '10px',
    }
  },
  markAllRead: {
    marginRight: '20px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '#2B3D7D',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '12px',
    }
  },
  closeNotificationIcon: {
    cursor: 'pointer',
  },
  notificationDetails: {
    display: 'flex',
    boxSizing: 'border-box' as 'border-box',
    padding: '16px 14px',
    marginBottom: '1px',
    alignItems:"center",
    position: 'relative' as 'relative',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  notificationUlBox: {
    maxHeight: "calc(100vh - 175px)",
    overflowY:"auto" as "auto"
  },
  notificationImg: {
    width: '30px',
    height: '30px',
    marginRight: '14px',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px 0px #00000014',
  },
  notificationDetailsBox: {
    flex: 1,
  },
  notificationUnread: {
    backgroundColor: '#f7f7f7',
  },
  notificationDescH: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    'max-width': '253px',
    'overflow': 'hidden',
    'word-wrap': 'break-word',
    'white-space': 'normal',
    '@media (max-width: 600px)': {
      fontSize: '10px',
    }
  },
  notificationMarkAsReadHeader: {
    fontFamily: 'Poppins',
    lineHeight: '16px',
    fontWeight: 400,
    fontSize: '12px',
    color: '#2B3D7D',
    textDecoration: 'underline',
    "text-underline-offset": '2px',
    marginTop: '8px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      fontSize: '10px',
    }
  },
 
  deleteIconImg: {
    cursor: 'pointer',
    marginLeft: "10px",
  },
  viewAllNotificationBtn: {
    display: 'block',
    width: '380px',
    height: '48px',
    margin: '16px auto 20px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#2B3D7D',
    border: '1px solid #2B3D7D',
    background: 'none',
    borderRadius: '8px',
    '@media (max-width: 600px)': {
      width: '100%',
      fontSize: '14px',
    },
    cursor:"pointer"
  },
  notificationDetailTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationBottomLink: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationTitle:{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    color: '#282829',
    flex: 1,
    marginRight: "10px",
    '@media (max-width: 600px)': {
      fontSize: '14px',
    }
  },
  notificationTime:{
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#282829',
    position: 'absolute' as 'absolute',
    right: 28,
    '@media (max-width: 600px)': {
      fontSize: '10px',
      right:10
    }
  },
  notificationList:{
    padding: '0',
  },
  notificationPopover: {
    overflow: "hidden",
    maxHeight: "unset",
  }
};

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(WebHeader);
// Customizable Area End
  