// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import { displayApiErrorMsg, getFieldType, getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  renderDDCatalogMenuItem: any;  
  handleTextChange: any;
  handleSelectChange: any;
  inputConfigs: Array<IInputConfigProps>;
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  customFieldsList: Array<any>;
  inputConfigss: Array<IInputConfigProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantCourseMngCreateCatalogStep1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCatalogCustomField: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      inputConfigss:[],
      customFieldsList:[],
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId === this.apiGetCatalogCustomField) {
        this.handleGetCatalogCustomFieldDataResponse(
          responseJson,
          errorResponse
        );        
      }
    }
  }

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.catalogCreateStep1();
    this.handleGetCatalogCustomFieldsList();
    // Customizable Area End
  }



  // Customizable Area Start
  catalogCreateStep1 = () => {
    console.log("Create Catalog Step 1");
  };

  handleClassCustomFieldTextChange = (event: any) => {
    const { inputConfigss } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = inputConfigss.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        inputConfigs: updatedInputConfig,
      };
    });
  };


  handleGetCatalogCustomFieldDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      if (responseJson.data?.length) {
        this.setState({ customFieldsList: responseJson.data });
        const custmFields: Array<IInputConfigProps> = responseJson.data.map(
          (item: any, index: number) => {
            const obj = {
              id: item.id,
              name: `class-management-customField-${index + 1}`,
              fieldName: `class-management-customField-${index + 1}`,
              value: "",
              label: item?.attributes?.label,
              placeHolderText: `Please enter ${item?.attributes?.label}`,
              fieldType: getFieldType(item?.attributes?.custom_field_type),
              isDisabled: false,
            };
            return obj;
          }
        );       
        this.setState({
          inputConfigss: custmFields,          
        });
      }
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }    
  };


  handleGetCatalogCustomFieldsList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCatalogCustomField = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.catalogCustomFieldEndPoint}?model_type=Catalog`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Customizable Area End
}
