import React from "react";
import { Box, Chip, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
  availableDays: boolean[];
}

const useStyles = makeStyles((theme: Theme) => ({
  boxWrapper: {
    width: theme.spacing(30.5),
    display: "flex",
    justifyContent: "space-between",
  },
  dayBox: {
    borderRadius: "6px",
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
}));

export const DaysPattern: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { availableDays } = props;
  const weekday = [
    {
      id: "M",
      value: "Monday",
      label: "M",
    },
    {
      id: "T",
      value: "Tuesday",
      label: "T",
    },
    {
      id: "W",
      value: "Wednesday",
      label: "W",
    },
    {
      id: "TH",
      value: "Thursday",
      label: "TH",
    },
    {
      id: "F",
      value: "Friday",
      label: "F",
    },
    {
      id: "S",
      value: "Saturday",
      label: "S",
    },
    {
      id: "SU",
      value: "Sunday",
      label: "SU",
    },
  ];

  const getAvailableDayColor = (day: string) => {
    const isDayPresent = availableDays.find((item: any) => item === day);
    if (isDayPresent) {
      return "#FFD92E";
    } else {
      return "#D5D5D5";
    }
  };

  return (
    <Box className={classes.boxWrapper}>
      {weekday.map((day) => {
        return (
          <Box
            key={`${day.value}`}
            className={classes.dayBox}
            style={{ backgroundColor: getAvailableDayColor(day.value) }}
          >
            {day.label}
          </Box>
        );
      })}
    </Box>
  );
};

export default DaysPattern;
