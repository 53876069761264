export const logo = require("../assets/YUDU-logo.png");
export const mathSubjectIcon = require("../assets/math.png");
export const physicsSubjectIcon = require("../assets/physics.png");
export const englishSubjectIcon = require("../assets/ABC.png");
export const psychologySubjectIcon = require("../assets/brain.png");
export const historySubjectIcon = require("../assets/scroll.png");
export const darkThemeImg = require("../assets/dark_theme.png");
export const lightThemeImg = require("../assets/light_theme.png");
export const manAvatar = require("../assets/man.png");
export const sampleCSV = require("../assets/sample1.csv");
export const invalidImage = require("../assets/invalidImage.svg")