// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, Grid } from "@material-ui/core";
import TenantCourseManagementRejectReasonModalController, {
  Props,
} from "./TenantCourseManagementRejectReasonModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import CustomTextFieldWeb from "../../../components/src/CustomTextField.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    createCatalogModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    requestRejectReasonModalMainGrid: {
      borderRadius: "10px",
      width: "500px",
      display: "flex",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      top: "50%",
      margin: "auto",
      left: "50%",
      height: "410px",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        minWidth: "100px",
        maxWidth: "100%",
      },
    },
    assignCourseToCatalogSelectDDWidth: {
      padding: "0px 30px",
      [theme.breakpoints.down("xs")]: {
        width: "96% !important",
      },
    },
    requestRejectCloseIconGrid: {
      display: "flex",
      padding: "15px 24px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    requestRejectBtnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "10px 20px",
    },
    inputConfigContainer: {
      padding: "0px 5px 0px 24px",
    },
  });

// Customizable Area End

export class TenantCourseManagementRejectReasonModal extends TenantCourseManagementRejectReasonModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, rejectionReason } = this.props;

    return (
      <Modal
        aria-labelledby="request-reject-reason-modal"
        open={open}
        className={`${classes.highlightBgColor} ${classes.createCatalogModal}`}
        onClose={this.props.handleClose}
        aria-describedby="request-reject-reason-modal"
      >
        <Grid
          className={`${classes.bgColor} ${classes.requestRejectReasonModalMainGrid}`}
          container
          direction="row"
        >
          <Grid
            className={`${classes.requestRejectCloseIconGrid}`}
            item
            xs={12}
          >
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
            >
              Rejection Reason
            </Typography>
            <CloseIcon
              id="request-reject-reason-close-modal-icon"
              className={`${classes.textCommonColor} ${classes.cursorPointer}`}
              onClick={this.props.handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.assignCourseToCatalogSelectDDWidth}`}>
              <CustomTextFieldWeb
                fieldName={"request-reject-reason-textfield"}
                fieldWidth={"100%"}
                fieldId={"request-reject-reason-textfield"}
                fieldValue={rejectionReason}
                isDisabled={false}
                multiline={true}
                textFieldStyles={popupTextfieldStyles}
                placeHolderText={"Enter Rejection Reason"}
                fieldType="text"
                handleChange={this.props.handleText}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={`${classes.requestRejectBtnBox}`}>
              <CustomButtonWeb
                btnStyle={{
                  ...rejectionReasonPopupBottomBtnStyle,
                  marginRight: "15px",
                }}
                buttonVariant="outlined"
                btnText="Cancel"
                buttonId="request-reject-reason-cancel-btn"
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.props.handleCancel}
              />
              <CustomButtonWeb
                btnStyle={rejectionReasonPopupBottomBtnStyle}
                buttonVariant="contained"
                buttonId="request-reject-reason-add-btn"
                btnText="Add"
                handleButtonClick={this.props.handleAdd}
                isDisabled={false}
                type={"button"}
              />
            </Box>
          </Grid>
        </Grid>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const rejectionReasonPopupBottomBtnStyle = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const popupTextfieldStyles = {
  width: "100%",
  padding: "8px 0px",
  height: "150px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(
  TenantCourseManagementRejectReasonModal
);
// Customizable Area End
