// Customizable Area Start
import React from "react"
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const DeleteIcon: any = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
        <path opacity="0.6" d="M7.61962 5.07227C7.43852 5.07227 7.29175 5.21904 7.29175 5.40013V11.5969C7.29175 11.7778 7.43852 11.9247 7.61962 11.9247C7.80071 11.9247 7.94749 11.7778 7.94749 11.5969V5.40013C7.94749 5.21904 7.80071 5.07227 7.61962 5.07227Z" fill="#888888"/>
        <path opacity="0.6" d="M3.75072 5.07227C3.56962 5.07227 3.42285 5.21904 3.42285 5.40013V11.5969C3.42285 11.7778 3.56962 11.9247 3.75072 11.9247C3.93182 11.9247 4.07859 11.7778 4.07859 11.5969V5.40013C4.07859 5.21904 3.93182 5.07227 3.75072 5.07227Z" fill="#888888"/>
        <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M0.931079 12.246V4.16799C0.323113 4.00662 -0.0708422 3.41927 0.0104847 2.79542C0.0919396 2.1717 0.623318 1.70513 1.25242 1.705H2.93108V1.29517C2.92916 0.95052 3.06543 0.619578 3.30941 0.376109C3.55339 0.132769 3.88484 -0.00273276 4.22949 8.48638e-05H7.14086C7.48551 -0.00273276 7.81697 0.132769 8.06095 0.376109C8.30493 0.619578 8.4412 0.95052 8.43928 1.29517V1.705H10.1179C10.747 1.70513 11.2784 2.1717 11.3599 2.79542C11.4412 3.41927 11.0472 4.00662 10.4393 4.16799V12.246C10.4393 12.7234 10.2642 13.1718 9.95836 13.4936C9.65406 13.8162 9.23039 13.9993 8.78687 14.0001H2.58349C2.1401 13.9993 1.71643 13.8162 1.412 13.4936C1.10616 13.1718 0.931079 12.7234 0.931079 12.246ZM2.58349 13.3443H8.78687C9.34745 13.3443 9.78354 12.8628 9.78354 12.246V4.19681H1.58682V12.246C1.58682 12.8628 2.02291 13.3443 2.58349 13.3443ZM3.58682 1.29517C3.58464 1.12444 3.65175 0.960126 3.77291 0.839608C3.89394 0.719091 4.05864 0.652877 4.22949 0.655823H7.14086C7.31171 0.652877 7.47642 0.719091 7.59745 0.839608C7.7186 0.959998 7.78572 1.12444 7.78354 1.29517V1.705H3.58682V1.29517ZM10.1179 2.36074H1.25242C0.926468 2.36074 0.662252 2.62496 0.662252 2.9509C0.662252 3.27685 0.926468 3.54107 1.25242 3.54107H10.1179C10.4439 3.54107 10.7081 3.27685 10.7081 2.9509C10.7081 2.62496 10.4439 2.36074 10.1179 2.36074Z" fill="#888888"/>
        <path opacity="0.6" d="M5.68517 5.07227C5.50407 5.07227 5.3573 5.21904 5.3573 5.40013V11.5969C5.3573 11.7778 5.50407 11.9247 5.68517 11.9247C5.86626 11.9247 6.01304 11.7778 6.01304 11.5969V5.40013C6.01304 5.21904 5.86626 5.07227 5.68517 5.07227Z" fill="#888888"/>
        </svg>
    );
}

export default DeleteIcon;
// Customizable Area End