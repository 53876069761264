// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, Radio } from "@material-ui/core";
import CustomStatusUpdateController, {
  Props,
} from "./CustomStatusUpdateController.web";
import { customThemeStyles } from "../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../components/src/CombineStyles.web";
import CustomButtonWeb from "../../components/src/CustomButton.web";
import { status } from "./CommonType.web";
import RadioBtnIcon from "./SVGIcons/RadioBtnIcon.web";
import { getBgColor, getColor } from "./CommonHelper.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    statusUpdateConfirmModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    statusUpdateModalMainGrid: {
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      width: "500px",
      margin: "auto",
      top: "50%",
      left: "50%",
      height: "430px",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "auto",
        maxWidth: "100%",
      },
    },
    closeIconGrid: {
      position: "absolute",
      top: "22px",
      right: "20px",
    },
    textCenter: {
      textAlign: "center",
    },
    statusListBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    statusUpdateHeaderBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      marginBottom: "25px",
    },
    statusUpdateBtnBox: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 20px",
    },
    statusBox: {
      padding: "20px",
      borderRadius: "12px",
      width: "100%",
      marginLeft: "10px"
    },
    capitalText: {
        textTransform: "capitalize"
    }
  });

// Customizable Area End

export class CustomStatusUpdate extends CustomStatusUpdateController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, selectedStatus } = this.props;
    return (
      <Modal
        open={open}
        className={`${classes.highlightBgColor} ${
          classes.statusUpdateConfirmModal
        }`}
        aria-labelledby="status-update-modal"
        onClose={this.props.handleClose}
        aria-describedby="status-update-modal"
      >
        <Box
          className={`${classes.bgColor} ${classes.statusUpdateModalMainGrid}`}
        >
          <Box padding={"25px 20px"}>
            <Box className={`${classes.statusUpdateHeaderBox}`}>
              <Typography
                component={"span"}
                className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText24}`}
              >
                Update Status
              </Typography>
              <CloseIcon
                className={`${classes.textCommonColor} ${
                  classes.cursorPointer
                }`}
                id="status-update-close-modal-icon"
                onClick={this.props.handleClose}
              />
            </Box>
            <Box className={`${classes.statusListBox}`}>
              {status.map((item) => (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  marginBottom={"15px"}
                  width={"100%"}
                >
                  <Radio
                    checked={item.label === selectedStatus}
                    onChange={() =>
                      this.props.handleUpdateSelectedStatus(item.label)
                    }
                    name={`status-${item.label}`}
                    className={`${classes.textCommonColor}`}
                    checkedIcon={
                      <RadioBtnIcon
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        stroke={"#FDD73A"}
                        fill={"#FDD73A"}
                      />
                    }
                    inputProps={{ "aria-label": `status-${item.label}` }}
                  />
                  <Box
                    bgcolor={getBgColor(item.label)}
                    color={getColor(item.label)}
                    className={`${classes.statusBox}`}
                  >
                    <Typography
                      component={"span"}
                      className={`${
                        classes.fontText18
                      } ${classes.capitalText}`}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box>
            <Box className={`${classes.statusUpdateBtnBox}`}>
              <CustomButtonWeb
                btnStyle={{ ...btnStyle, marginRight: "15px"}}
                buttonVariant="outlined"
                btnText="Cancel"
                buttonId="status-update-cancel-btn"
                handleButtonClick={this.props.handleClose}
                isDisabled={false}
                type={"button"}
              />
              <CustomButtonWeb
                btnStyle={btnStyle}
                btnText="Save"
                buttonVariant="contained"
                buttonId="status-update-confirm-btn"
                handleButtonClick={this.props.handleYes}
                isDisabled={false}
                type={"button"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const btnStyle = {
  fontSize: "16px",
  padding: "10px 10px",
  borderRadius: "10px",
  width: "130px",
  fontWeight: 600,
  height: "45px",
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CustomStatusUpdate);
// Customizable Area End
