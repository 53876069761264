// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IInputConfigProps,
  IStepperStepProps,
} from "../../../components/src/CommonType.web";
import { getFieldType } from "../../../components/src/CommonHelper.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: () => void;
  handleAddNewClassDetails: any;
  handleUpdateClassDetails: any;
  handleUpdateAddedClassDetails: any;
  isEdit: boolean;
  classDetails: any;
  showLoader: boolean;
  handleGetSubjectsBasedOnGrade: any;
  handleGetCurriculumsBasedOnGradeSubjects: any;
  gradesList: Array<any>;
  schoolYears: Array<any>;
  subjectsListBasedOnGrade: Array<any>;
  curriculumListBasedOnGradeSubject: Array<any>;
  studentsAvailable: Array<any>;
  studentsSelected: Array<any>;
  handleGetStudentsList: any;
  customFields: Array<any>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperScreen: string;
  steps: Array<IStepperStepProps>;
  classTitle: string;
  classTitleError: boolean;
  classTitleErrorMsg: string;
  selectedGrade: any;
  selectedGradeTitle: string;
  selectedGradeErrorMsg: string;
  selectedGradeError: boolean;
  selectedSubjects: Array<any>;
  selectedSubjectsError: boolean;
  selectedSubjectsErrorMsg: string;
  selectedSchoolYear: any;
  selectedSchoolYearError: boolean;
  selectedSchoolYearErrorMsg: string;
  selectedCurriculum: any;
  selectedCurriculumTitle: string;
  selectedCurriculumError: boolean;
  selectedCurriculumErrorMsg: string;
  classesCustomFieldConfig: Array<IInputConfigProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewClassDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      stepperScreen: "class_details",
      steps: [
        {
          id: "class_details",
          label: "Class Details",
        },
        {
          id: "available_selected_students",
          label: "Add Students",
        },
      ],
      selectedGrade: "",
      selectedGradeTitle: "",
      selectedGradeError: false,
      selectedGradeErrorMsg: "",
      selectedSubjects: [],
      selectedSubjectsError: false,
      selectedSubjectsErrorMsg: "",
      selectedCurriculum: "",
      selectedCurriculumTitle: "",
      selectedCurriculumError: false,
      selectedCurriculumErrorMsg: "",
      classTitle: "",
      classTitleError: false,
      classTitleErrorMsg: "",
      selectedSchoolYear: "",
      selectedSchoolYearError: false,
      selectedSchoolYearErrorMsg: "",
      classesCustomFieldConfig: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleNextStepper = () => {
    if (this.handleVerifyClassDetails()) {
      const {
        selectedCurriculum,
        selectedGrade,
        classTitle,
        selectedSubjects,
        selectedSchoolYear,
        classesCustomFieldConfig,
      } = this.state;
      const customFieldAttributes: Array<any> = classesCustomFieldConfig?.map(
        (item) => {
          const obj = {
            value: item?.value,
            custom_field_id: item.id,
          };
          return obj;
        }
      );
      const classDetails = {
        title: classTitle,
        grade: selectedGrade,
        curriculum: selectedCurriculum,
        subjects: selectedSubjects,
        schoolYear: selectedSchoolYear,
        classesCustomAttributes: customFieldAttributes,
      };
      this.setState({ stepperScreen: "available_selected_students" }, () => {
        this.props.handleUpdateAddedClassDetails(classDetails);
        this.props.handleGetStudentsList(selectedGrade, selectedCurriculum);
      });
    }
  };

  handleCancelStepperBtn = () => {
    this.setState({ stepperScreen: "class_details" }, () => {
      this.handleClosePopup();
    });
  };

  handleVerifyCurriculum = () => {
    const { selectedCurriculum } = this.state;
    let isValid = true;
    if (!selectedCurriculum) {
      this.setState({
        selectedCurriculumError: true,
        selectedCurriculumErrorMsg: "Please select curriculum.",
      });
      isValid = false;
    }
    return isValid;
  };

  handleVerifySubject = () => {
    const { selectedSubjects } = this.state;
    let isValid = true;
    if (!selectedSubjects?.length) {
      this.setState({
        selectedSubjectsError: true,
        selectedSubjectsErrorMsg: "Please select at least 1 subject.",
      });
      isValid = false;
    } else {
      isValid = this.handleVerifyCurriculum();
    }
    return isValid;
  };

  handleVerifyClassDetails = () => {
    const { classTitle, selectedGrade, selectedSchoolYear } = this.state;
    let isValid = true;
    if (!classTitle?.trim()) {
      this.setState({
        classTitleError: true,
        classTitleErrorMsg: "Please enter class title.",
      });
      isValid = false;
    }
    if (!selectedSchoolYear) {
      this.setState({
        selectedSchoolYearError: true,
        selectedSchoolYearErrorMsg: "Please select a school year.",
      });
      isValid = false;
    }
    if (!selectedGrade) {
      this.setState({
        selectedGradeError: true,
        selectedGradeErrorMsg: "Please select a grade.",
      });
      isValid = false;
    } else {
      isValid = this.handleVerifySubject();
    }
    return isValid;
  };

  handlePauseStepper = () => {
    const {
      classTitle,
      selectedCurriculum,
      selectedGrade,
      selectedSchoolYear,
      selectedSubjects,
    } = this.state;
    return !!(
      classTitle &&
      selectedCurriculum &&
      selectedGrade &&
      selectedSchoolYear &&
      selectedSubjects?.length
    );
  };

  handleAddClassDetails = (isSaveAsDraft?: boolean) => {
    const { isEdit } = this.props;
    if (isEdit) {
      this.props.handleUpdateClassDetails(isSaveAsDraft);
    } else {
      this.props.handleAddNewClassDetails(isSaveAsDraft);
    }
  };

  handleSetClassesCustomFields = () => {
    const { customFields, isEdit } = this.props;
    const customFieldsList: Array<IInputConfigProps> = customFields.map(
      (item: any, index: number) => {
        const obj = {
          id: item.id,
          name: `customField-class-mng-${index + 1}`,
          fieldName: `customField-class-mng-${index + 1}`,
          value: "",
          label: item?.attributes?.label,
          placeHolderText: `Please enter ${item?.attributes?.label}`,
          fieldType: getFieldType(item?.attributes?.custom_field_type),
          isDisabled: false,
        };
        return obj;
      }
    );
    this.setState(
      { classesCustomFieldConfig: [...customFieldsList] || [] },
      () => {
        if (isEdit) this.handleSetInitialValue(true);
      }
    );
  };

  handleCustomFieldTextChange = (event: any) => {
    const { classesCustomFieldConfig } = this.state;
    const { name, value } = event.target;
    const updatedInputConfig = classesCustomFieldConfig.map((item) => {
      if (item.name === name) {
        item.value = value;
      }
      return item;
    });
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        classesCustomFieldConfig: updatedInputConfig,
      };
    });
  };

  handleSetInitialValue = async (isEdit?: boolean) => {
    const { classDetails } = this.props;
    const { classesCustomFieldConfig } = this.state;
    if (isEdit) {
      await this.props.handleGetSubjectsBasedOnGrade(classDetails?.gradeId);
      await this.props.handleGetCurriculumsBasedOnGradeSubjects(
        classDetails?.gradeId,
        classDetails?.subjectIds
      );
      const updatedCustomFieldConfigs = classesCustomFieldConfig.map(
        (item: any) => {
          if (item?.label in classDetails)
            item.value = classDetails[item?.label] || "";
          return item;
        }
      );
      this.setState({
        classTitle: classDetails?.className,
        selectedGrade: classDetails?.gradeId,
        selectedGradeTitle: classDetails?.grade,
        selectedCurriculumTitle: classDetails?.curriculum,
        selectedCurriculum: classDetails?.curriculumId,
        selectedSubjects: classDetails?.subjectIds,
        selectedSchoolYear: classDetails?.schoolYearId,
        classesCustomFieldConfig: updatedCustomFieldConfigs,
      });
    } else {
      this.setState(
        {
          stepperScreen: "class_details",
          selectedGrade: "",
          selectedGradeTitle: "",
          selectedGradeError: false,
          selectedGradeErrorMsg: "",
          selectedSubjects: [],
          selectedSubjectsError: false,
          selectedSubjectsErrorMsg: "",
          selectedCurriculum: "",
          selectedCurriculumTitle: "",
          selectedCurriculumError: false,
          selectedCurriculumErrorMsg: "",
          classTitle: "",
          classTitleError: false,
          classTitleErrorMsg: "",
          selectedSchoolYear: "",
          selectedSchoolYearError: false,
          selectedSchoolYearErrorMsg: "",
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { gradesList, curriculumListBasedOnGradeSubject } = this.props;
    let fieldValue: any = value;
    if (fieldValue) {
      if (name === "selectedGrade") {
        const findGrade = gradesList?.find(
          (item: any) => item?.value === fieldValue
        );
        this.setState({
          selectedGradeTitle: findGrade?.label,
          selectedGradeError: false,
          selectedGradeErrorMsg: "",
          selectedSubjects: [],
          selectedCurriculum: "",
          selectedCurriculumError: false,
          selectedCurriculumErrorMsg: "",
          selectedSubjectsError: false,
          selectedSubjectsErrorMsg: "",
        });
        this.props.handleGetSubjectsBasedOnGrade(value);
      }
      if (name === "selectedSchoolYear") {
        this.setState({
          selectedSchoolYearError: false,
          selectedSchoolYearErrorMsg: "",
        });
      }
      if (name === "selectedCurriculum") {
        const findCurriculum = curriculumListBasedOnGradeSubject?.find(
          (item: any) => item?.value === fieldValue
        );
        this.setState({
          selectedCurriculumTitle: findCurriculum?.label,
          selectedCurriculumError: false,
          selectedCurriculumErrorMsg: "",
        });
      }
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        classTitleError: false,
        classTitleErrorMsg: "",
      };
    });
  };

  renderGradeValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { gradesList } = this.props;
    const selectedGrade = gradesList.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGrade?.label;
  };

  renderCurriculumValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { curriculumListBasedOnGradeSubject } = this.props;
    const selectedCurriculum = curriculumListBasedOnGradeSubject.find(
      (curriculum: any) => curriculum?.value === selected
    );
    return selectedCurriculum?.label;
  };

  renderSchoolYearValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select School Year";
    }
    const { schoolYears } = this.props;
    const selectedSchoolYear = schoolYears.find(
      (schoolYear: any) => schoolYear?.value === selected
    );
    return selectedSchoolYear?.label;
  };

  handleChangeSubject = (event: any) => {
    this.setState(
      {
        selectedSubjects: event.target.value,
        selectedSubjectsError: false,
        selectedSubjectsErrorMsg: "",
        selectedCurriculum: "",
        selectedCurriculumTitle: "",
        selectedCurriculumError: false,
        selectedCurriculumErrorMsg: "",
      },
      () => {
        this.props.handleGetCurriculumsBasedOnGradeSubjects(
          this.state.selectedGrade,
          event.target.value
        );
      }
    );
  };

  removeSelectedValue = (value: any) => {
    const { selectedSubjects, selectedGrade } = this.state;
    const updatedSubjects = selectedSubjects.filter(
      (item: any) => item !== value
    );
    this.setState(
      {
        selectedSubjects: updatedSubjects || [],
        selectedCurriculum: "",
        selectedCurriculumTitle: "",
        selectedCurriculumError: false,
        selectedCurriculumErrorMsg: "",
      },
      () => {
        this.props.handleGetCurriculumsBasedOnGradeSubjects(
          selectedGrade,
          updatedSubjects
        );
      }
    );
  };

  handleClosePopup = () => {
    this.handleSetInitialValue();
  };

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetClassesCustomFields();
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
