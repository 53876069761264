// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const MyPathIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill}fillRule="evenodd">
                <g transform="translate(-42.000000, -304.000000)" fill={props.fill} fillRule="nonzero">
                    <g transform="translate(0.000000, 120.000000)">
                        <g transform="translate(40.000000, 142.000000)">
                            <g transform="translate(2.024251, 42.020313)">
                                <path d="M2.9170455,22 L18.2681548,22 C19.8774114,22 21.1852003,20.6922111 21.1852003,19.0829545 L21.1852003,4.5466449 C21.1852003,2.93738828 19.8774114,1.62959939 18.2681548,1.62959939 L17.1111338,1.62959939 L17.1111338,0.814799696 C17.1111338,0.366646256 16.7444195,0 16.2962661,0 C15.8481126,0 15.4814664,0.366646256 15.4814664,0.814799696 L15.4814664,1.62959939 L5.70373394,1.62959939 L5.70373394,0.814799696 C5.70373394,0.366646256 5.33701965,0 4.88886621,0 C4.44071277,0 4.07406651,0.366646256 4.07406651,0.814799696 L4.07406651,1.62959939 L2.9170455,1.62959939 C1.30778889,1.62959939 0,2.93738828 0,4.5466449 L0,19.0829545 C0,20.6922111 1.30778889,22 2.9170455,22 Z M1.62966743,4.5466449 C1.62966743,3.83777732 2.20817793,3.25926682 2.9170455,3.25926682 L4.07406651,3.25926682 L4.07406651,4.07406651 C4.07406651,4.52221995 4.44071277,4.88886621 4.88886621,4.88886621 C5.33701965,4.88886621 5.70366591,4.52221995 5.70366591,4.07406651 L5.70366591,3.25926682 L15.4814664,3.25926682 L15.4814664,4.07406651 C15.4814664,4.52221995 15.8481126,4.88886621 16.2962661,4.88886621 C16.7444195,4.88886621 17.1110658,4.52221995 17.1110658,4.07406651 L17.1110658,3.25926682 L18.2680868,3.25926682 C18.9769543,3.25926682 19.5554648,3.83777732 19.5554648,4.5466449 L19.5554648,19.0829545 C19.5554648,19.7918221 18.9769543,20.3703326 18.2680868,20.3703326 L2.9170455,20.3703326 C2.20817793,20.3703326 1.62966743,19.7918221 1.62966743,19.0829545 L1.62966743,4.5466449 Z"></path>
                                <circle cx="5.90743387" cy="8.35183295" r="1.01849962"></circle>
                                <circle cx="10.3889002" cy="8.35183295" r="1.01849962"></circle>
                                <circle cx="14.8703666" cy="8.35183295" r="1.01849962"></circle>
                                <circle cx="5.90743387" cy="12.4258995" r="1.01849962"></circle>
                                <circle cx="10.3889002" cy="12.4258995" r="1.01849962"></circle>
                                <circle cx="14.8703666" cy="12.4258995" r="1.01849962"></circle>
                                <circle cx="5.90743387" cy="16.499966" r="1.01849962"></circle>
                                <circle cx="10.3889002" cy="16.499966" r="1.01849962"></circle>
                                <circle cx="14.8703666" cy="16.499966" r="1.01849962"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default MyPathIcon;
// Customizable Area End