// Customizable Area Start
import React from "react";
import { Button, Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomButtonController, { Props } from "./CustomButtonController.web";
import { combineStyles } from "./CombineStyles.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    buttonCss: {
      textTransform: "capitalize",
    },
  });

// Customizable Area End

export class CustomButton extends CustomButtonController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      btnText,
      btnStyle,
      buttonId,
      type,
      buttonVariant,
      isDisabled,
      btnIcon,
    } = this.props;

    const getButtonCss = () => {
      if (buttonVariant === "outlined") {
        return `${classes.buttonCss} ${classes.outlinedButton}`;
      } else if (buttonVariant === "contained") {
        return `${classes.buttonCss} ${classes.containedButton}`;
      } else return `${classes.buttonCss} ${classes.textButton}`;
    };

    return (
      <Button
        variant={buttonVariant}
        type={type}
        id={buttonId}
        disabled={isDisabled}
        className={getButtonCss()}
        onClick={this.props.handleButtonClick}
        style={{
          opacity: isDisabled ? 0.5 : 1,
          ...btnStyle,
        }}
      >
        <>
          {btnIcon && <Box marginRight={"4px"}>{btnIcon}</Box>}
          {btnText}
        </>
      </Button>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomButton);
// Customizable Area End
