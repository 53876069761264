// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomEmptyResultComponentController, {
  Props,
} from "./CustomEmptyResultComponentController.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import CustomButton from "./CustomButton.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      margin: theme.spacing(12.5, 0),
      alignItems: "center",
      justifyContent: "center",
      height: "inherit",
      width: "inherit",
    },
    textCenter: {
      textAlign: "center",
    }
  });

// Customizable Area End

export class CustomEmptyResultComponent extends CustomEmptyResultComponentController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { message, classes, showActionButton, buttonText, marginStyle } = this.props;
    return (
      <Box margin={marginStyle} className={classes.root}>
        <Box>
          {buttonText !== "Add Another Question" &&
          <img src={require("./bankrupt.png")} alt="bankrupt-image" />
    }
        </Box>
        <Typography
          component={"p"}
          className={`${classes.textCommonColor} ${classes.fontText20} ${classes.textCenter}`}
          dangerouslySetInnerHTML={{ __html: message }}
        >
        </Typography>
        {
          showActionButton &&
          <CustomButton
            btnText={buttonText || "Add Item"}
            btnStyle={actionButtonStyle}
            buttonId="empty-component-action-button"
            isDisabled={false}
            buttonVariant={"outlined"}
            type={"button"}
            handleButtonClick={() => this.props.handleButtonClick()}
          />
        }
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const actionButtonStyle = {
  minWidth: "225px",
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(
  CustomEmptyResultComponent
);
// Customizable Area End
