// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomCardWithHoverEffectController, {
  Props,
} from "./CustomCardWithHoverEffectController.web";
import CustomButtonWeb from "./CustomButton.web";
import { combineStyles } from "./CombineStyles.web";
import { customThemeStyles } from "./CustomThemeStyles.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      padding: "15px",
    },
    hoveredCard: {
      position: "relative",
      "&:before": {
        content: '""',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 1,
        opacity: 0,
        transition: "background-color 0.3s ease-in-out",
        borderRadius: "12px",
        pointerEvents: "none",
      },
      "&:hover": {
        backgroundColor: "#666666", // Darker transparent color on hover
        cursor: "pointer",
      },
      "&:hover:before": {
        opacity: 1,
      },
    },
    hoveredButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      cursor: "pointer",
      transition: "opacity 0.3s",
      pointerEvents: "auto",
    },
    selectedBoxBorder: {
      border: "2px solid #FBD82C",
    },
  });

// Customizable Area End

export class CustomCardWithHoverEffect extends CustomCardWithHoverEffectController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      fieldId,
      classes,
      children,
      heightOfCard,
      widthOfCard,
      hoverButtonText,
      hoverButtonText2,
      isSelected,
      itemId,
      hoverButtonStyle,
      hoverButtonStyle2,
      isHoverBtnDisabled,
      isShowBtnOnHoverEffect,
      showOutlineBorderOnSelect
    } = this.props;
    const { hoveredCardId } = this.state;
    const getSelectedCardClass = () => {
      if (isSelected && showOutlineBorderOnSelect)
        return `${classes.cardBgColor02} ${classes.selectedBoxBorder} ${
          classes.root
        } ${classes.hoveredCard}`;
      return `${classes.cardBgColor02} ${classes.hoveredCard} ${classes.root}`;
    };

    return (
      <Box
        data-test-id={fieldId}
        width={widthOfCard}
        height={heightOfCard}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={getSelectedCardClass()}
        onClick={this.handleCardBodyClick}
      >
        {children}
        {isShowBtnOnHoverEffect && itemId === hoveredCardId && (
          <Box className={`${classes.hoveredButton}`}>
            <CustomButtonWeb
              btnStyle={isSelected ? hoverButtonStyle2 : hoverButtonStyle}
              btnText={isSelected ? hoverButtonText2 : hoverButtonText}
              buttonId={`hover-btn-${fieldId}`}
              buttonVariant={"contained"}
              isDisabled={isHoverBtnDisabled}
              handleButtonClick={this.props.hoverButtonHandle}
              type={"button"}
            />
          </Box>
        )}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(
  CustomCardWithHoverEffect
);
// Customizable Area End
