// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
} from "@material-ui/core";
import SubmitGradeDialogController, {
  Props,
} from "./SubmitGradeDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { exclamationIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    quizViewAnswerConfirmDialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    closeIcon: {
      "& svg path": {
        fill: "#fff",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class SubmitGradeDialog extends SubmitGradeDialogController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open, isFromRegistrar, dataIncomplete } = this.props;
    return (
      <Dialog
        className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
        aria-labelledby="quiz-view-answer-confirm-dialog-title"
        open={open}
      >
        <Box padding={"30px"} className={`${classes.bgColor}`}>
          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <Box className={`${classes.cursorPointer}`} onClick={this.props.onCancel}>
              <CloseIcon
                className={`${classes.textCommonColor}`}
                id="grade-submit-close-modal-icon"
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            id="quiz-view-answer-confirm-dialog-title"
          >
            <Box>
              <img src={exclamationIcon} alt="success icon" />
            </Box>
          </Box>
          <DialogContent style={{ padding: "0px" }}>
            <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
              >
                {isFromRegistrar ? (
                  "Grades will be locked"
                ) : (
                  <>
                    {dataIncomplete && "Grades submitted are incomplete"}
                    {!dataIncomplete && "Grades will be submitted"}
                  </>
                )}
              </Typography>
            </Box>
            <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
              {isFromRegistrar ? (
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                >
                  You are about to lock the grades of the selected <br />
                  grading period of the selected students, <br />
                  please click confirm to continue.
                </Typography>
              ) : (
                <>
                  {!dataIncomplete && (
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    >
                      You are about to submit the grades and behaviors of <br />
                      the selected student. Please click confirm <br />
                      to continue.
                    </Typography>
                  )}
                  {dataIncomplete && (
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    >
                      The grades and behaviors selected are incomplete. <br />
                      Kindly fill out the necessary fields to proceed.
                      <br />
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"20px"}
              justifyContent={"space-between"}
            >
              <CustomButton
                btnStyle={cancelButton}
                btnText="Cancel"
                buttonId="quiz-view-answer-cancel-btn"
                buttonVariant={"outlined"}
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.props.onCancel}
              />
              <CustomButton
                btnStyle={confirmButton}
                btnText="Confirm"
                buttonId="quiz-view-answer-cancel-btn"
                buttonVariant={"contained"}
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.props.onCancel}
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const confirmButton = {
  height: "50px",
  width: "100%",
  padding: "5px",
  fontSize: "16px",
  borderRadius: "10px",
  fontWeight: 600,
} as const;

const cancelButton = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(SubmitGradeDialog);
// Customizable Area End
