// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CreateNewPasswordController, {
  Props
} from "./CreateNewPasswordController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import AuthLayout from "../../../components/src/AuthLayout.web";
import { checkMarkIconYellow } from "./assets";
import { combineStyles } from "../../../components/src/CombineStyles.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    wrapperBox: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    textFieldWrapper: {
      maxWidth: theme.spacing(55),
      width: "100%",
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
    text: {
      fontSize: 16,
      fontWeight: 400,
      color: '#888888',
      textAlign: "left",
      marginBottom: theme.spacing(1.25)
    },
    title: {
      fontSize: 22,
      fontWeight: 700,
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
    subTitle: {
      fontSize: 18,
      fontWeight: 400,
      color: '#888888',
      marginBottom: theme.spacing(4),
      textAlign: "center",
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
    loginBtn: {
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      width: "100%",
      height: theme.spacing(7.5),
      borderRadius: "12px",
      fontSize: 20,
      fontWeight: 500,
      textTransform: "capitalize",
      marginTop: theme.spacing(5),
      maxWidth: theme.spacing(55),
      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(40),
      },
    },
    checkMarkImage: {
      width: theme.spacing(7.5),
      marginBottom: theme.spacing(3.5),
    }
  })
// Customizable Area End

// Customizable Area Start
export class CreateNewPassword extends CreateNewPasswordController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { newPassword, confirmPassword, isPasswordSaved } = this.state;
    return isPasswordSaved ? (
      <AuthLayout title="" subTitle="">
        <Box className={classes.wrapperBox}>
          <img alt="check-mark-icon-yellow" className={classes.checkMarkImage} src={checkMarkIconYellow} />
          <Typography className={classes.title}>Password Updated Successfully</Typography>
          <Typography className={classes.subTitle}>Your password updated successfully. You will need to login with new password.</Typography>
          <Button variant="contained" className={classes.loginBtn} onClick={this.handleLogin}>Login</Button>
        </Box>
      </AuthLayout>
    ) : (
      <AuthLayout title="Create New Password" subTitle="Your new password must be different from previous password">
        <Box className={classes.wrapperBox}>
          <div className={classes.textFieldWrapper}>
            <Typography className={classes.text}>New Password</Typography>
            <CustomTextField
              placeHolderText="Enter New Password"
              fieldName="newPassword"
              fieldId="new-password-field"
              textFieldStyles={{ padding: "10px 0" }}
              fieldValue={newPassword}
              fieldWidth={"100%"}
              fieldType="password"
              handleChange={(e: any) => this.handleChange(e)}
            />
            <Typography className={classes.text} style={{ marginTop: "30px" }}>Confirm Password</Typography>
            <CustomTextField
              placeHolderText="Confirm Password"
              fieldName="confirmPassword"
              fieldId="confirm-password-field"
              textFieldStyles={{ padding: "10px 0" }}
              fieldValue={confirmPassword}
              fieldWidth={"100%"}
              fieldType="password"
              handleChange={(e: any) => this.handleChange(e)}
            />
          </div>
          <Button data-test-id="reset-pwd-save-btn" variant="contained" className={classes.loginBtn} onClick={() => this.handleSave()} >Save</Button>
        </Box>
      </AuthLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CreateNewPassword);
// Customizable Area End
