// Customizable Area Start
import React from 'react';
import { Box , Typography } from "@material-ui/core";
import "./CustomAudioPlayer.web.css"
// Customizable Area End

// Customizable Area Start
interface IProps {
  progressBarRef  : any;
  audioRef : any;
  timeProgress :any;
  duration : any;
  classes:any;
}
// Customizable Area End

// Customizable Area Start
const AudioPlayerProgress : React.FC<IProps> = (props) => {
  const { progressBarRef , audioRef , timeProgress , duration , classes } = props;

  const formatTime = (time : any) => {
    if (time && !isNaN(time)) {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor(time / 60) % 60;
      const formatHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formatMinutes =
        minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds =
        seconds < 10 ? `0${seconds}` : `${seconds}`;
      if(hours) return `${formatHours}:${formatMinutes}:${formatSeconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return '00:00';
  };
  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  return (
    <Box className="progress">
      <Box mb={"5px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
      <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText14}`}>{formatTime(timeProgress)}</Typography>
      <Typography component={"span"} className={`${classes.textPrimaryColor} ${classes.fontText14}`}>{formatTime(duration)}</Typography>
      </Box>
      <input type="range"
        ref={progressBarRef}
        defaultValue="0"
        onChange={handleProgressChange} />
    </Box>
  )
}

export default AudioPlayerProgress;
// Customizable Area End