// Customizable Area Start
import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AssessmentsAllController, {
  Props,
} from "./AssessmentsAllController.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomPaginationComponent from "../../../components/src/CustomPaginationComponent.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerWidthAll: {
      height: "5px",
    }
  });
// Customizable Area End

// Customizable Area Start
export class AssessmentsAll extends AssessmentsAllController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { AssessmentTableBody, paginationData } = this.state;
    return (
      <>
        <Box display={"flex"} px={3.5} py={3} className={classes.bgColor}>
          <Box display={"flex"} alignItems={"center"} data-test-id="BoxGrd">
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                data-test-id="GradingComponent"
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
              >
                {`Grading Component`}
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-assessment-component"
                fieldValue={this.state.selectedClassComponentDDValue}
                fieldName="selectedClassComponentDDValue"
                handleChange={this.handleClassDropdownValues}
                renderValue={this.renderClassComponenttDDValues}
                fieldError={false}
                fieldMenuItems={this.state.gradingComponent}
                fieldStyles={GradeDropdown}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            ml={3}
            data-test-id="DueDate"
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                data-test-id="Due_date"
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
              >
                {`Due date`}
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-assessment-grading-component"
                fieldValue={this.state.selectedDueDateComponentDDValue}
                fieldName="selectedDueDateComponentDDValue"
                handleChange={this.handleDueDateDropdownValues}
                renderValue={this.renderDueDateComponenttDDValues}
                fieldError={false}
                fieldMenuItems={this.state.DueDateComponent}
                fieldStyles={GradeDropdown}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            ml={3}
            data-test-id="StatusBox"
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                data-test-id="Status"
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
              >
                {`Status`}
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-assessment-Status-component"
                fieldValue={this.state.selectedStatusComponentDDValue}
                fieldName="selectedStatusComponentDDValue"
                handleChange={this.handleStatusDropdownValues}
                renderValue={this.renderStatusComponenttDDValues}
                fieldError={false}
                fieldMenuItems={this.state.StatusComponent}
                fieldStyles={GradeDropdown}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
              />
            </Box>
          </Box>
          <Box alignItems={"center"} ml={3} pt={"24px"}>
            <CustomButton
              btnText="Search"
              btnStyle={searchBtn}
              buttonId={`my-assessment-search-mobile`}
              buttonVariant={"contained"}
              type={"button"}
              handleButtonClick={this.handleSearchFilter}
              isDisabled={false}
            />
          </Box>
        </Box>
        <Divider className={classes.dividerWidthAll} />
        <Box   
          className={classes.bgColor}
          style={{
              borderRadius: "0px 0px 10px 10px",
              padding: "0px 10px",
            }}  
          >
       
          <Box>
            {AssessmentTableBody.length > 0 ?
            <CustomTable
              tableId={"graded_activity_table"}
              tableHeaderColumns={this.state.AssessmentAllTableColumn}
              tableBodyData={AssessmentTableBody}
              showEditMenuIcon={false}
              showMenuIcon={false}
            />
            :
            <Box style={{height: "268px"}}>
            <CustomEmptyResultComponent marginStyle="0px 0px !important" message="No record found." />
            </Box>
            }
          </Box>
         
        
        {AssessmentTableBody.length > 0 && (
          <CustomPaginationComponent
            paginationData={paginationData}
                  
            handlePaginationChange={this.handlePagination}
          />
        )}
        </Box>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtn = {
  width: "120px",
  textAlign: "center",
  height: "50px",
  marginLeft: "15px",
  fontSize: "18px",
  borderRadius: "12px",
  padding: "10px 10px",
  fontWeight: 500,
  float: "right",
} as const;
const GradeDropdown = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentsAll);
// Customizable Area End
