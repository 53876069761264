// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const MyCoursesIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth={props.strokeWidth} fill={props.fill} fillRule="evenodd">
                <g transform="translate(-39.000000, -351.000000)" fill={props.fill}fillRule="nonzero" strokeWidth={props.strokeWidth}>
                    <g transform="translate(0.000000, 120.000000)">
                        <g transform="translate(40.000000, 142.000000)">
                            <g transform="translate(0.000000, 89.790625)">
                                <path d="M15.4476562,-0.05 C16.1213181,-0.05 16.7321987,0.224016316 17.1746556,0.666473274 C17.6171126,1.10893023 17.8911289,1.71981078 17.8911289,2.39347266 L17.8911289,4.45807042 L24.9368163,1.63981558 C25.1869305,1.5398132 25.4702702,1.57033457 25.6933341,1.72131665 C25.916394,1.87235017 26.05,2.12414575 26.05,2.39347266 L26.05,13.8159023 C26.05,14.0937826 25.9078302,14.3528926 25.6719551,14.5020507 L17.8911289,11.7481889 L17.8911289,13.8159023 C17.8911289,14.4895642 17.6171126,15.1004448 17.1746556,15.5429017 C16.7321987,15.9853587 16.1213181,16.259375 15.4476562,16.259375 L2.39352344,16.259375 C1.71985842,16.259375 1.10896235,15.985356 0.666492143,15.5428952 C0.224026225,15.1004387 -0.05,14.4895608 -0.05,13.8159023 L-0.05,2.39347266 C-0.05,1.71981421 0.224026225,1.10893633 0.666492143,0.666479796 C1.10896235,0.224018973 1.71985842,-0.05 2.39352344,-0.05 L15.4476562,-0.05 Z M15.4476563,1.5734375 L2.39352344,1.5734375 C2.16743852,1.5734375 1.96242657,1.66543047 1.81392895,1.81391942 C1.66543678,1.96240293 1.5734375,2.16739591 1.5734375,2.39347266 L1.5734375,13.8159023 C1.5734375,14.0419791 1.66543678,14.2469721 1.81392895,14.3954556 C1.96242657,14.5439445 2.16743852,14.6359375 2.39352344,14.6359375 L15.4476563,14.6359375 C15.6737367,14.6359375 15.878733,14.5439479 16.0272174,14.3954635 C16.1757018,14.2469791 16.2676914,14.0419828 16.2676914,13.8159023 L16.2676914,2.39347266 C16.2676914,2.16739222 16.1757018,1.96239588 16.0272174,1.81391149 C15.878733,1.6654271 15.6737367,1.5734375 15.4476563,1.5734375 Z M24.4265625,3.592383 L17.8911289,6.20656644 L17.8911289,10.0028086 L24.4265625,12.616992 L24.4265625,3.592383 Z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default MyCoursesIcon;
// Customizable Area End