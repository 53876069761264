import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root :{ 
    "&.MuiSvgIcon-root" : {
      fill:"#01C996"
    }
  }
}))

export const CustomCheckmark: React.FC = () => {

  const classes = useStyles();

  return (
    <CheckCircleRoundedIcon fontSize='medium' classes={{
      root : classes.root
    }} />
  );
}

export default CustomCheckmark;