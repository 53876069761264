import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartEvent,
    ActiveElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { withStyles } from "@material-ui/core";
import { customThemeStyles } from "./CustomThemeStyles.web";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface IProps {
    classes?: any;
    progressGraphData: any;
    progressChartLable: string;
    progressTitleColor: any;
    progressgridlineColor:string;
    onBarClick: (data: any) => void;
}

const alignTitle: any = "end";
const titlePosition: any = "top";

export const CustomStudentProgressBar: React.FC<IProps> = (props) => {

    const { progressGraphData, progressChartLable, progressTitleColor , progressgridlineColor,onBarClick } = props;
    const options = {
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: { 
                display: true,
                anchor: alignTitle,
                align: alignTitle, 
                formatter: (value:any) => Math.round(value) + "%", 
              },
            title: {
                display: false,
                text: progressChartLable,
                position: titlePosition,
                align: alignTitle,
                color: progressTitleColor,
                padding: 20
            },
            tooltip: {
                enabled: true,

            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder:false,
                },
                ticks: {
                    color: progressTitleColor,
                    beginAtZero: true,
                },
            },
            y: {
                ticks: {
                    color: progressTitleColor,
                    stepSize: 20,
                    beginAtZero: true,
                },
                grid: {
                    drawTicks: false,
                    color:'#888888',
                    borderDash: [4,4],
                },
                border: {
                    dash: [2,4],
                }, 
            },
        },
        animation: {
            duration: 2000,
        },
        onClick: (event: ChartEvent, active: ActiveElement[], chart: any) => {
            if (active.length > 0) {
                const element = active[0]; // Use the first active element in case of multiple elements
                const datasetIndex = element.datasetIndex; // Index of the dataset containing the clicked bar
                const dataIndex = element.index; // Index of the clicked bar in the dataset
                const datasetLabel = progressGraphData.datasets[datasetIndex].label; // Label of the dataset containing the clicked bar
                const clickedValue = progressGraphData.datasets[datasetIndex].data[dataIndex]; // Value of the clicked bar
                // Do something with the clicked information
                console.log("Clicked datasetdd:", datasetLabel);
                console.log("Clicked value:", clickedValue);
                onBarClick({ datasetIndex, dataIndex, datasetLabel, clickedValue }); // Call the parent's onClick handler with the clicked information
            }
        },
        onHover: (event:any, elements:any) => {
            const chartInstance = event.chart;
            const canvasParent = chartInstance.canvas.parentNode;
            if (elements.length > 0) {
              canvasParent.style.cursor = 'pointer';
            } else {
              canvasParent.style.cursor = 'default';
            }
          },
    };

    return <Bar options={options} data={progressGraphData} />;
}

export default withStyles(customThemeStyles)(CustomStudentProgressBar);
