// Customizable Area Start
import React from "react";
import { Step, Stepper, StepLabel, Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomStepperController, { Props } from "./CustomStepperController.web";
import CustomButton from "./CustomButton.web";
import { combineStyles } from "./CombineStyles.web";
import { customThemeStyles } from "./CustomThemeStyles.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    stepperBtnBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    nextStepperBtnBox: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
      },
    },
    nextStepperBtnBoxWithCancelBtn: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
      },
    },
    stepRoot: {
      "&.MuiStep-horizontal": {
        wordBreak: "break-all",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
      "& .MuiStepLabel-root": {
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      },
    },
    stepLabel: {
      "& .MuiStepIcon-root": {
        fontSize: "36px",
        color: "#ffffff",
        borderRadius: "50%",
        border: "1px solid #B6B6B6",
      },
      "& span .MuiStepLabel-completed": {
        color: "#0D46BC !important",
      },
      "& span .MuiStepLabel-active": {
        color: "#0D46BC !important",
      },
      "& .MuiStepIcon-active": {
        color: "#0D46BC !important",
        border: "none",
        "& text": {
          fill: "#ffffff !important",
        },
      },
      "& .MuiStepIcon-completed": {
        color: "#0D46BC",
      },
      "& .MuiStepIcon-text": {
        fill: "#B6B6B6",
      },
      "& .MuiStepConnector-root .MuiStepConnector-active": {
        "& span": {
          borderColor: "#0D46BC !important",
        },
      },
      "& .MuiStepLabel-label" : {
        color: "#0D46BC !important"
    },
    },
  });

// Customizable Area End

export class CustomStepper extends CustomStepperController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      stepperId,
      steps,
      prevBtnLabel,
      nextBtnLabel,
      finishLabel,
      showSaveAsDraft,
      children,
      showCancelBtn,
      hidePreviousBtn,
      showPreview,
      handleShowPreview
    } = this.props;
    const { activeStep } = this.state;

    return (
      <Box className={`${classes.root}`}>
        <Stepper
          className={`${classes.secondaryInfoBgColor}`}
          id={stepperId}
          activeStep={activeStep}
        >
          {steps.map((step, index) => {
            return (
              <Step onClick={this.handleStep} className={`${classes.stepRoot}`} key={step.id}>
                <StepLabel
                  className={`${classes.stepLabel}`}
                >
                  <Typography
                    component={"span"}
                    className={`${classes.fontBold600} ${classes.fontText20} ${classes.stepLabel}`}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box>
          {children}
          <Box className={`${classes.stepperBtnBox}`}>
            <Box style={{display:'flex',gap:"15px"}}>
            {activeStep !== 0 && !hidePreviousBtn && (
              <CustomButton
                handleButtonClick={this.handleBack}
                btnStyle={prevBtn}
                btnText={prevBtnLabel}
                buttonId={`${stepperId}-previous-btn`}
                buttonVariant={"outlined"}
                isDisabled={false}
                type={"button"}
              />
            )}
            {showPreview && (
                <CustomButton
                  handleButtonClick={handleShowPreview}
                  btnStyle={prevBtn}
                  btnText={"Preview"}
                  buttonId={`${stepperId}-mid-btn`}
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                />
              )}
            </Box>
            <Box className={(showSaveAsDraft && showCancelBtn && activeStep === steps.length - 1) ? `${classes.nextStepperBtnBoxWithCancelBtn}` : `${classes.nextStepperBtnBox}`}>
              {showCancelBtn && (
                <CustomButton
                  handleButtonClick={this.props.handleCancelBtn}
                  btnStyle={{ ...prevBtn, marginRight: "10px" }}
                  btnText={"Cancel"}
                  buttonId={`${stepperId}-cancel-btn`}
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                />
              )}
              <Box display={"flex"} >
              {activeStep === steps.length - 1 && showSaveAsDraft && (
                <CustomButton
                  handleButtonClick={this.props.handleSaveAsDraft}
                  btnStyle={saveAsDraftBtn}
                  btnText={"Save As Draft"}
                  buttonId={`${stepperId}-mid-btn`}
                  buttonVariant={"contained"}
                  isDisabled={false}
                  type={"button"}
                />
              )}
              <CustomButton
                buttonVariant="contained"
                handleButtonClick={this.handleNext}
                btnStyle={nextBtn}
                btnText={
                  activeStep === steps.length - 1 ? finishLabel : nextBtnLabel
                }
                buttonId={`${stepperId}-next-btn`}
                isDisabled={false}
                type={"button"}
              />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const nextBtn = {
  width: "160px",
  height: "50px",
  padding: "10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const prevBtn = {
  width: "160px",
  padding: "10px",
  height: "50px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;



const saveAsDraftBtn = {
  width: "160px",
  padding: "10px",
  height: "50px",
  backgroundColor: "#00c996",
  color: "#ffffff",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
  marginRight: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle, { withTheme: true })(CustomStepper);
// Customizable Area End
