// Customizable Area Start
import React from "react"
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CloseIcon: any = (props: any) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.6" d="M0.709508 13.9728C0.43352 13.9729 0.184684 13.8066 0.0790607 13.5516C-0.0265624 13.2967 0.0318339 13.0032 0.227013 12.808L12.8352 0.199856C13.1017 -0.0666187 13.5337 -0.0666187 13.8002 0.199856C14.0667 0.466331 14.0667 0.898372 13.8002 1.16485L1.192 13.773C1.06415 13.9012 0.89051 13.973 0.709508 13.9728Z" fill="#888888" />
            <path opacity="0.6" d="M13.3178 13.9728C13.1368 13.973 12.9631 13.9012 12.8353 13.773L0.227078 1.16485C-0.039397 0.898372 -0.039397 0.466331 0.227078 0.199856C0.493552 -0.0666187 0.925593 -0.0666187 1.19207 0.199856L13.8003 12.808C13.9954 13.0032 14.0538 13.2967 13.9482 13.5516C13.8426 13.8066 13.5937 13.9729 13.3178 13.9728Z" fill="#888888" />
        </svg>
    );
}

export default CloseIcon;
// Customizable Area End