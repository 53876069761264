// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectRejectedTitleValue: string;
  selectRejectedSubjectValue: string;
  selectRejectedGradeValue: string;
  selectRejectedRequestedOnValue: string;
  selectRejectedAccpetedOnValue: string;
  RejectedRequestedComponentValue: Array<any>;
  RejectedOnDDComponentValue : Array<any>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherRejectedRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      selectRejectedTitleValue: "",
      selectRejectedSubjectValue: "",
      selectRejectedGradeValue: "",
      selectRejectedAccpetedOnValue: "",
      selectRejectedRequestedOnValue: "",
      RejectedRequestedComponentValue:[
        {
          id: 1,
          label: "Ascending",
          value: 1,
        },
        {
          id: 2,
          label: "Descending",
          value: 2,
        },
      ],
      RejectedOnDDComponentValue:[
        {
          id: 1,
          label: "Ascending",
          value: 1,
        },
        {
          id: 2,
          label: "Descending",
          value: 2,
        },
      ],
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  handleRejectRequestsSearch = () => {
    console.log("search");
  };

  renderRejectedSubjectDropdownValue = (selected: any) => {
    const ddName = "Subject";
    const ddItems = this.getRejectedDropdownItemsArray("Subject");
    return this.getSelectedDropDownValue(selected, ddName, ddItems);
  };

  getRejectedDropdownItemsArray = (ddName: string) => {
    const ref = [1, 2, 3, 4, 5];
    let ddItems: Array<any> = [];
    ref.forEach((item) => {
      ddItems.push({
        id: item,
        label: `${ddName}${item}`,
        value: `${ddName}${item}`,
      });
    });
    return ddItems;
  };

  renderRejectedGradeDropdownValue = (selected: any) => {
    const ddName = "Grade";
    const ddItems = this.getRejectedDropdownItemsArray("Grade");
    return this.getSelectedDropDownValue(selected, ddName, ddItems);
  };

  renderRejectedRequestedOnDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Ascending";
    }
    const { RejectedRequestedComponentValue } = this.state;
    const selectedGradingComponents = RejectedRequestedComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderRejectedTitleDropdownValue = (selected: any) => {
    const ddName = "Title";
    const ddItems = this.getRejectedDropdownItemsArray("Title");
    return this.getSelectedDropDownValue(selected, ddName, ddItems);
  };

  renderRejectedOnDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Ascending";
    }
    const { RejectedOnDDComponentValue } = this.state;
    const selectedGradingComponents = RejectedOnDDComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleRejectedChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  getSelectedDropDownValue = (selected: any, ddName: string, ddItems: any) => {
    if (!selected || selected.length === 0) {
      return `Select ${ddName}`;
    }
    const selctedOption = ddItems.find((item: any) => item?.value === selected);
    return selctedOption?.label;
  };
  // Customizable Area End
}
