// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { IStepperStepProps } from "../../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("../config");

const stepsArr = [
  {
    id: "basic_information",
    label: "Basic Information",
  },
  {
    id: "regular_teachers",
    label: "Regular Teacher",
  },
  {
    id: "other_teachers",
    label: "Other Teachers",
  },
];
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  handleClose: () => void;
  handleAddNewTeacherAssignmentDetails: any;
  handleUpdateTeacherAssignmentDetails: any;
  handleUpdateAddedTeacherAssignmentDetails: any;
  isEdit: boolean;
  teacherAssignmentDetails: any;
  showLoader: boolean;
  handleGetSubjectsBasedOnGrade: any;
  handleGetCurriculumsBasedOnGradeSubjects: any;
  handleGetClassesBasedOnGradeSubjectCurriculum: any;
  gradesList: Array<any>;
  subjectsListBasedOnGrade: Array<any>;
  curriculumListBasedOnGradeSubject: Array<any>;
  classesListBasedOnGradeSubjectCurriculum: Array<any>;
  teachersBasedOnGradeSubjectCurriculumList: Array<any>;
  otherTeachersListBasedOnGradeSubjectCurriculum: Array<any>;
  selectedRegularTeachersList: Array<any>;
  selectedOtherTeachersList: Array<any>;
  handleGetTeachersListBasedOnGradeSubjectCurriculum: any;
  handleSetSelectedRegularTeachersList: any;
  handleSetSelectedOtherTeachersList: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperScreen: string;
  steps: Array<IStepperStepProps>;
  selectedGrade: any;
  selectedGradeErrorMsg: string;
  selectedGradeError: boolean;
  selectedSubjects: Array<any>;
  selectedSubjectsError: boolean;
  selectedSubjectsErrorMsg: string;
  selectedClass: any;
  selectedClassError: boolean;
  selectedClassErrorMsg: string;
  selectedCurriculum: any;
  selectedCurriculumError: boolean;
  selectedCurriculumErrorMsg: string;
  noOfStudents: any;
  noOfStudentsError: boolean;
  noOfStudentsErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class NewTeacherAssignmentDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      noOfStudents: "",
      noOfStudentsError: false,
      noOfStudentsErrorMsg: "",
      selectedClass: "",
      selectedClassError: false,
      selectedClassErrorMsg: "",
      selectedCurriculum: "",
      selectedCurriculumError: false,
      selectedCurriculumErrorMsg: "",
      selectedGrade: "",
      selectedGradeError: false,
      selectedGradeErrorMsg: "",
      selectedSubjects: [],
      selectedSubjectsError: false,
      selectedSubjectsErrorMsg: "",
      stepperScreen: stepsArr[0].id,
      steps: stepsArr,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleNextStepper = () => {
    const {
      selectedCurriculum,
      selectedGrade,
      noOfStudents,
      selectedSubjects,
      selectedClass,
      stepperScreen,
    } = this.state;
    if (stepperScreen === stepsArr[0].id) {
      if (this.handleVerifyGradeDetails() && this.handleVerifyNoOfStudents()) {
        const classDetails = {
          noOfStudents: noOfStudents,
          grade: selectedGrade,
          curriculum: selectedCurriculum,
          subjects: selectedSubjects,
          class: selectedClass,
        };
        this.setState({ stepperScreen: stepsArr[1].id }, () => {
          this.props.handleUpdateAddedTeacherAssignmentDetails(classDetails);
        });
      }
    } else if (stepperScreen === stepsArr[1].id) {
      this.setState({ stepperScreen: stepsArr[2].id });
    }
  };

  handleCancelStepperBtn = () => {
    this.setState({ stepperScreen: "basic_information" }, () => {
      this.handleClosePopup();
    });
  };

  handleVerifyClass = () => {
    const { selectedClass } = this.state;
    let isValid = true;
    if (!selectedClass) {
      this.setState({
        selectedClassError: true,
        selectedClassErrorMsg: "Please select a class",
      });
      isValid = false;
    }
    return isValid;
  };

  handleVerifySubject = () => {
    const { selectedSubjects } = this.state;
    let isValid = true;
    if (!selectedSubjects?.length) {
      this.setState({
        selectedSubjectsError: true,
        selectedSubjectsErrorMsg: "Please select at least 1 subject.",
      });
      isValid = false;
    } else {
      isValid = this.handleVerifyCurriculum();
    }
    return isValid;
  };

  handleVerifyCurriculum = () => {
    const { selectedCurriculum } = this.state;
    let isValid = true;
    if (!selectedCurriculum) {
      this.setState({
        selectedCurriculumError: true,
        selectedCurriculumErrorMsg: "Please select curriculum.",
      });
      isValid = false;
    } else {
      isValid = this.handleVerifyClass();
    }
    return isValid;
  };

  handleVerifyGradeDetails = () => {
    const { selectedGrade } = this.state;
    let isValid = true;
    if (!selectedGrade) {
      this.setState({
        selectedGradeError: true,
        selectedGradeErrorMsg: "Please select a grade.",
      });
      isValid = false;
    } else {
      isValid = this.handleVerifySubject();
    }
    return isValid;
  };

  handleVerifyNoOfStudents = () => {
    const { noOfStudents } = this.state;
    let isValid = true;
    if (!noOfStudents?.trim()) {
      this.setState({
        noOfStudentsError: true,
        noOfStudentsErrorMsg: "Please enter the no. of students",
      });
      isValid = false;
    } else if (!configJSON.numberRegex.test(parseInt(noOfStudents))) {
      this.setState({
        noOfStudentsError: true,
        noOfStudentsErrorMsg: "Please enter a valid number",
      });
      isValid = false;
    }
    return isValid;
  };

  handlePauseStepper = () => {
    const {
      noOfStudents,
      selectedClass,
      selectedCurriculum,
      selectedGrade,
      selectedSubjects,
    } = this.state;
    if (
      noOfStudents &&
      selectedClass &&
      selectedGrade &&
      selectedSubjects.length > 0 &&
      selectedCurriculum
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleAddTeacherAssignmentDetails = (isSaveAsDraft?: boolean) => {
    const { isEdit } = this.props;
    if (isEdit) {
      this.props.handleUpdateTeacherAssignmentDetails(isSaveAsDraft);
    } else {
      this.props.handleAddNewTeacherAssignmentDetails(isSaveAsDraft);
    }
  };

  handleSetInitialValue = async (isEdit?: boolean) => {
    const { teacherAssignmentDetails } = this.props;
    if (isEdit) {
      await this.props.handleGetSubjectsBasedOnGrade(
        teacherAssignmentDetails?.gradeId
      );
      await this.props.handleGetCurriculumsBasedOnGradeSubjects(
        teacherAssignmentDetails?.gradeId,
        teacherAssignmentDetails?.subjectIds
      );
      await this.props.handleGetClassesBasedOnGradeSubjectCurriculum(
        teacherAssignmentDetails?.gradeId,
        teacherAssignmentDetails?.subjectIds,
        teacherAssignmentDetails?.curriculumId
      );
      this.setState({
        noOfStudents: teacherAssignmentDetails?.noOfStudents,
        selectedGrade: teacherAssignmentDetails?.gradeId,
        selectedCurriculum: teacherAssignmentDetails?.curriculumId,
        selectedSubjects: teacherAssignmentDetails?.subjectIds,
        selectedClass: teacherAssignmentDetails?.classId,
      });
    } else {
      this.setState(
        {
          stepperScreen: stepsArr[0].id,
          selectedGrade: "",
          selectedGradeError: false,
          selectedGradeErrorMsg: "",
          selectedSubjects: [],
          selectedSubjectsError: false,
          selectedSubjectsErrorMsg: "",
          selectedCurriculum: "",
          selectedCurriculumError: false,
          selectedCurriculumErrorMsg: "",
          noOfStudents: "",
          noOfStudentsError: false,
          noOfStudentsErrorMsg: "",
          selectedClass: "",
          selectedClassError: false,
          selectedClassErrorMsg: "",
        },
        () => {
          this.props.handleClose();
        }
      );
    }
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      if (name === "selectedGrade") {
        this.setState({
          selectedGradeError: false,
          selectedGradeErrorMsg: "",
          selectedSubjects: [],
          selectedCurriculum: "",
          selectedCurriculumError: false,
          selectedCurriculumErrorMsg: "",
          selectedSubjectsError: false,
          selectedSubjectsErrorMsg: "",
          selectedClass: "",
          selectedClassError: false,
          selectedClassErrorMsg: "",
        });
        this.props.handleGetSubjectsBasedOnGrade(value);
      }
      if (name === "selectedClass") {
        this.setState({
          selectedClassError: false,
          selectedClassErrorMsg: "",
        });
      }
      if (name === "selectedCurriculum") {
        this.setState({
          selectedCurriculumError: false,
          selectedCurriculumErrorMsg: "",
          selectedClass: "",
          selectedClassError: false,
          selectedClassErrorMsg: "",
        });
        this.props.handleGetClassesBasedOnGradeSubjectCurriculum(
          this.state.selectedGrade,
          this.state.selectedSubjects,
          value
        );
      }
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        noOfStudentsError: false,
        noOfStudentsErrorMsg: "",
      };
    });
  };

  renderGradeValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { gradesList } = this.props;
    const selectedGrade = gradesList.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGrade?.label;
  };

  renderCurriculumValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { curriculumListBasedOnGradeSubject } = this.props;
    const selectedCurriculum = curriculumListBasedOnGradeSubject.find(
      (curriculum: any) => curriculum?.value === selected
    );
    return selectedCurriculum?.label;
  };

  renderClassValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Class";
    }
    const { classesListBasedOnGradeSubjectCurriculum } = this.props;
    const selectedClass = classesListBasedOnGradeSubjectCurriculum.find(
      (classItem: any) => classItem?.value === selected
    );
    return selectedClass?.label;
  };

  handleChangeSubject = (event: any) => {
    this.setState(
      {
        selectedSubjects: event.target.value,
        selectedSubjectsError: false,
        selectedSubjectsErrorMsg: "",
        selectedCurriculum: "",
        selectedCurriculumError: false,
        selectedCurriculumErrorMsg: "",
        selectedClass: "",
        selectedClassError: false,
        selectedClassErrorMsg: "",
      },
      () => {
        this.props.handleGetCurriculumsBasedOnGradeSubjects(
          this.state.selectedGrade,
          event.target.value
        );
        // add function to empty class and curriculum list
      }
    );
  };

  handleClosePopup = () => {
    this.handleSetInitialValue();
  };
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const { isEdit } = this.props;
    if (isEdit) this.handleSetInitialValue(true);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area End
