// Customizable Area Start
import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import AssessmentActivityTaskBoxController, {
  Props,
} from "./AssessmentActivityTaskBoxController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CalendarIcon from "../../../components/src/SVGIcons/CalendarIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    divMarginBottom: {
      marginBottom: "2%",
    },
    activityDetailBox: {
      minWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
    },
    leftMargin: {
      marginLeft: "5px",
    },
    topMargin: {
      marginTop: "10px",
    },
    assessmentTopInfo: {
      padding: "15px 15px 10px 15px",
    },
    paddingSides: {
      padding: "0px 15px",
    },
    letsStartBtnGrid: {
      padding: "15px 15px 15px 15px",
      marginTop: "20px",
    },
    questionCard: {
      border: "1px solid #f1f1f1",
      borderRadius: "6px",
      width: "94%",
      [theme.breakpoints.down("xs")]: {
        width: "84%",
      },
    },
    pagePadding: {
      padding: "15px",
    },
    questionContainer: {
      gap: "20px",
      marginTop: "20px",
    },
    aboutText: {
      marginTop: "5px",
    },
    examTitle: {
      display: "flex",
      alignItems: "center",
      gap: "7px",
      textTransform: "capitalize",
    },
    explanation: {
      borderTop: "1px solid #f1f1f1",
      borderBottom: "1px solid #f1f1f1",
      padding: "15px 20px",
    },
    capitalText: {
      textTransform: "capitalize",
    }
  });

// Customizable Area End

// Customizable Area Start
export class AssessmentActivityTaskBox extends AssessmentActivityTaskBoxController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, detail, forTeacher } = this.props;
    return (
      <Box className={classes.activityDetailBox}>
        {((detail?.gradingComponent !== "exam" && detail?.gradingComponent !== "quiz") ||
          !forTeacher) && (
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              className={`${classes.assessmentTopInfo} ${classes.secondaryInfoBgColor}`}
            >
              <Grid container direction="row">
                <Grid className={classes.divMarginBottom} item xs={12} sm={6}>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText16}`}
                  >
                    Lesson:{" "}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}
                  >
                    {detail?.lessonTitle}
                  </Typography>
                </Grid>
                <Grid className={classes.divMarginBottom} item xs={12} sm={6}>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText16}`}
                  >
                    Course:{" "}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}
                  >
                    {detail?.courseDetails?.courseTitle}
                  </Typography>
                </Grid>
                <Grid className={classes.divMarginBottom} item xs={12} sm={6}>
                  <Typography
                    component={"span"}
                    className={`${classes.textCommonColor} ${classes.fontText16}`}
                  >
                    Assessment:{" "}
                  </Typography>
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16} ${classes.capitalText}`}
                  >
                    {`${detail?.gradingComponent} (${detail?.evaluationType})`}
                  </Typography>
                </Grid>
                <Grid className={classes.divMarginBottom} item xs={12} sm={6}>
                  <Box display={"flex"} alignItems={"center"}>
                    <CalendarIcon
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      stroke={"#888888"}
                      strokeWidth="1.5"
                      fill={"none"}
                    />
                    <Typography
                      component={"span"}
                      className={`${classes.textCommonColor} ${classes.fontText16} ${classes.leftMargin}`}
                    >
                      Date:
                    </Typography>
                    <Typography
                      component={"span"}
                      className={`${classes.textPrimaryColor} ${classes.fontText16} ${classes.fontBold600} ${classes.leftMargin}`}
                    >
                      {detail?.dueDate ? moment(detail?.dueDate).format("MMM DD, YYYY") : "--"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.paddingSides} item xs={12}>
              <Box marginTop={"4%"}>
                <Typography
                  component={"span"}
                  className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText18}`}
                >
                  Instructions
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                marginTop={"10px"}
                marginBottom={"20px"}
              >
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`}
                  component={"p"}
                  dangerouslySetInnerHTML={{ __html: detail?.instruction }}
                ></Typography>
              </Box>
            </Grid>
              <Grid className={classes.paddingSides} item xs={12}>
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                >
                  Allowed Attempts:{" "}
                </Typography>
                <Typography
                  component={"span"}
                  className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}
                >
                  {detail?.allowedAttempts} Attempts
                </Typography>
              </Grid>
            <Grid
              className={`${classes.topMargin} ${classes.paddingSides}`}
              item
              xs={12}
            >
              <Typography
                component={"span"}
                className={`${classes.textCommonColor} ${classes.fontText16}`}
              >
                Max Score:{" "}
              </Typography>
              <Typography
                component={"span"}
                className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}
              >
                {detail?.maxScore} Points
              </Typography>
            </Grid>
            {(detail?.gradingComponent === "project" || detail?.gradingComponent === "exam") && (
              <Grid
                className={`${classes.topMargin} ${classes.paddingSides}`}
                item
                xs={12}
              >
                <Typography
                  component={"span"}
                  className={`${classes.textCommonColor} ${classes.fontText16}`}
                >
                  Duration:{" "}
                </Typography>
                <Typography
                  component={"span"}
                  className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText16}`}
                >
                  {detail?.gradingComponent === "exam"
                  ? `${detail?.duration * 60} minutes`
                  : `${detail?.duration} days`}
                </Typography>
              </Grid>
            )}
            <Grid
              className={`${classes.letsStartBtnGrid} ${classes.secondaryInfoBgColor}`}
              item
              xs={12}
            >
              <Box
                marginTop={"20px"}
                width={"97%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <CustomButton
                  btnText="Let's Start"
                  btnStyle={btnStyle}
                  buttonId={`my-course-assessment-${detail?.gradingComponent}`}
                  buttonVariant={"contained"}
                  type={"button"}
                  handleButtonClick={() =>
                    this.handleGoToAssessmentDueScreen(
                      detail?.gradingComponent,
                      detail?.evaluationType,
                      detail?.activityTitle,
                      detail?.activityId,
                      detail?.objectiveType,
                    )
                  }
                  isDisabled={
                    (
                      !(
                        detail.typeOfActivity === "assessment" &&
                        detail.evaluationType === "objective" &&
                        (
                          (detail.objectiveType === "selection" &&
                            (detail.gradingComponent === "quiz" ||
                              detail.gradingComponent === "exam" ||
                              detail.gradingComponent === "assignment" ||
                              detail.gradingComponent === "project")) ||
                          (detail.objectiveType === "written" &&
                            ( 
                              detail.gradingComponent === "quiz" ||
                              detail.gradingComponent === "project"))
                        )
                      ) && detail.totalMarks !== null
                    ) || detail?.allowedAttempts <= 0
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  width: "130px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentActivityTaskBox);
// Customizable Area End
