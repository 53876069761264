// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { IDropdownMenuItemProps } from "./CommonType.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  fieldMenuItems: Array<IDropdownMenuItemProps>;
  fieldId?: string;
  fieldStyles?: any;
  label: string;
  selectedItems: Array<any>;
  handleChange: (event: any) => void;
  removeSelectedValue: (value: any) => void;
  classes?: any;
  fieldError?: boolean;
  isDisabled?: boolean;
  disabledItemSelection?: boolean;
  helperText?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openMenu: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomMultiSelectDropDownV2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
      openMenu: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  handleToggleMenu = (e: any) => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  handleChangeMenu = (e: any) => {
    this.props.handleChange(e);
    this.setState({ openMenu: false });
  };

  renderValue = (selected: any) => {
    const { fieldMenuItems } = this.props;
    const selectedItems: Array<any> = [];
    selected?.map((item: any) => {
        const selectedItem = fieldMenuItems.find((data: any) => parseInt(data.value) === parseInt(item));
        selectedItems.push(selectedItem);
    })
    const selectedLabels = selectedItems?.map((item: any) => {
        return item.label;
    });
    return selectedLabels?.join(",");
  }

  // Customizable Area End
}
