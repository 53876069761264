// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const MessageQuestionIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.3337 7.69434C13.3337 7.69434 6.66699 14.0277 6.66699 23.5277V42.5277C6.66699 52.0277 13.3337 58.361 23.3337 58.361V65.106C23.3337 67.6393 26.3003 69.1593 28.5003 67.7343L43.3337 58.361H56.667C66.667 58.361 73.3337 52.0277 73.3337 42.5277V23.5277C73.3337 14.0277 66.667 7.69434 56.667 7.69434H23.3337ZM37.5003 43.8577C37.5003 42.5593 38.6003 41.4827 40.0003 41.4827C41.4003 41.4827 42.5003 42.5593 42.5003 43.8577C42.5003 45.156 41.4003 46.2327 40.0003 46.2327C38.6003 46.2327 37.5003 45.156 37.5003 43.8577ZM42.5003 35.3393V36.0043C42.5003 37.3027 41.367 38.3793 40.0003 38.3793C38.6337 38.3793 37.5003 37.3027 37.5003 36.0043V35.3393C37.5003 31.666 40.3337 29.861 41.4003 29.1643C42.6337 28.3727 43.0337 27.8343 43.0337 27.011C43.0337 25.4277 41.667 24.1293 40.0003 24.1293C38.3337 24.1293 36.967 25.4277 36.967 27.011C36.967 28.3093 35.8337 29.386 34.467 29.386C33.1003 29.386 31.967 28.3093 31.967 27.011C31.967 22.7993 35.567 19.3793 40.0003 19.3793C44.4337 19.3793 48.0337 22.7993 48.0337 27.011C48.0337 30.621 45.2337 32.426 44.2003 33.091C42.9003 33.9143 42.5003 34.4527 42.5003 35.3393Z" fill={props.fill}/>
        </SvgIcon>
    );
}

export default MessageQuestionIcon;
// Customizable Area End