// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ChangePasswordController, {
  Props,
} from "./ChangePasswordController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    fieldBox: {
      margin: theme.spacing(2.5, 0),
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
      padding: theme.spacing(3.2),
    },
    inputLabel: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.5),
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    },
    headerWrappper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "15px 0 30px 0",
    },
    headLineWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    backIcon: {
      cursor: "pointer",
      fontSize: "34px",
    },
    profileHeading: {
      color: "#090909",
      fontSize: "34px",
      fontWeight: 700,
    },
    inputBox: {
      width: "495px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  });
// Customizable Area End

// Customizable Area Start
export class ChangePassword extends ChangePasswordController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      newPassword,
      confirmPassword,
      currentPassword,
      confirmPasswordError,
      confirmPasswordErrorMsg,
      currentPasswordError,
      currentPasswordErrorMsg,
      newPasswordError,
      newPasswordErrorMsg,
    } = this.state;
    return (
      <Box
        style={{ padding: "40px" }}
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box marginTop={"1%"}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Dashboard / My Profile /`}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontText14} ${classes.fontBold600}`}
            component={"span"}
          >
            {` Change Password`}
          </Typography>
        </Box>
        <Box className={classes.headerWrappper}>
          <Box
            className={classes.headLineWrapper}
            onClick={this.handleGoBack}
          >
            <KeyboardBackspaceIcon
              className={`${classes.backIcon} ${classes.textPrimaryColor}`}
            />
            <Typography
              className={`${classes.profileHeading} ${classes.textPrimaryColor}`}
            >
              Change Password
            </Typography>
          </Box>
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box className={classes.inputBox}>
            <Typography style={{ marginTop: 0 }} className={classes.inputLabel}>
              Current Password
            </Typography>
            <CustomTextField
              placeHolderText="Current Password"
              fieldName="currentPassword"
              fieldId="current-password-field"
              textFieldStyles={{ padding: "10px 0", overflow: "visible" }}
              fieldValue={currentPassword}
              fieldError={currentPasswordError}
              helperText={currentPasswordErrorMsg}
              fieldWidth={"100%"}
              fieldType="password"
              handleChange={this.handleChange}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Typography className={classes.inputLabel}>New Password</Typography>
            <CustomTextField
              placeHolderText="New Password"
              fieldName="newPassword"
              fieldError={newPasswordError}
              helperText={newPasswordErrorMsg}
              fieldId="new-password-field"
              textFieldStyles={{ padding: "10px 0", overflow: "visible" }}
              fieldValue={newPassword}
              fieldWidth={"100%"}
              fieldType="password"
              handleChange={(e: any) => this.handleChange(e)}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Typography className={classes.inputLabel}>
              Confirm Password
            </Typography>
            <CustomTextField
              placeHolderText="Confirm Password"
              fieldName="confirmPassword"
              fieldId="confirm-password-field"
              fieldError={confirmPasswordError}
              helperText={confirmPasswordErrorMsg}
              textFieldStyles={{ padding: "10px 0", overflow: "visible" }}
              fieldValue={confirmPassword}
              fieldWidth={"100%"}
              fieldType="password"
              handleChange={this.handleChange}
            />
          </Box>
        </Box>
        <Box
          style={{ display: "flex", margin: "30px 0", justifyContent: "end" }}
        >
          <CustomButton
            btnStyle={cancelbtnStyle}
            btnText="Cancel"
            buttonId="change-password-cancel-btn"
            buttonVariant={"outlined"}
            isDisabled={false}
            type={"button"}
            handleButtonClick={() => this.handleGoBack()}
          />
          <CustomButton
            btnStyle={addBtnStyle}
            btnText="Change Password"
            buttonId="change-password-btn"
            buttonVariant={"contained"}
            isDisabled={false}
            type={"button"}
            handleButtonClick={() => this.handleChangePassword()}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addBtnStyle = {
  borderRadius: "12px",
  padding: "15px 0",
  fontSize: "18px",
  fontWeight: 600,
  width: "220px",
} as const;

const cancelbtnStyle = {
  marginRight: "15px",
  borderRadius: "12px",
  padding: "15px 0",
  fontSize: "18px",
  fontWeight: 600,
  border: "1px solid",
  width: "220px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ChangePassword);
// Customizable Area End
