// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import RegistrarStudentsListController, {
  Props,
} from "./RegistrarStudentsListController.web";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import CustomEmptyResultComponentWeb from "../../../components/src/CustomEmptyResultComponent.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    studentSearchTextfieldBox: {
      width: "185px",
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
    },
    breadCrumbsRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    gradeSelectDDBox: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    topBtn: {
      width: "184px",
      height: "50px",
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    customTableStyle: {
      borderRadius: "10px",
      width: "98%",
    },
    paginationBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px 10px 21px",
    },
    registrarStudentsTable: {
      padding: "0px 20px",
    },
    headingBox: {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      padding: "15px 30px 20px 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });
const themes = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        padding: "10px 6px !important",
      },
    },
  },
});

const studentsTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class RegistrarStudentsList extends RegistrarStudentsListController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      registrarStudentListTableBody,
      registrarStudentListTableColumns,
      selectedGradeDDValue,
      classesList,
      searchStudentText,
      perPage,
      totalNumberOfStudents,
      currentPage,
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.breadCrumbsRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Students`}
          </Typography>
        </Box>
        <Box marginBottom={"17px"}>
          <Box marginBottom={"13px"}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
              component={"span"}
            >
              Students
            </Typography>
          </Box>
        </Box>
        <Box className={classes.gradeSelectDDBox}>
          <CustomSelectDropdown
            fieldId="registrar-select-dropdown-grades"
            fieldValue={selectedGradeDDValue}
            fieldName="selectedGradeDDValue"
            handleChange={this.handleChangeDropdownValues}
            renderValue={this.renderGrade}
            fieldError={false}
            fieldMenuItems={classesList}
            fieldStyles={dropdownStudent}
          />
        </Box>
        <Box
          mt={4.5}
          className={`${classes.customTableStyle} ${classes.bgColor}`}
        >
          <Box className={classes.headingBox}>
            <Typography
              component={"span"}
              className={`${classes.fontText28} ${classes.textPrimaryColor} ${classes.fontBold600}`}
            >
              Students
            </Typography>
            <Box className={`${classes.studentSearchTextfieldBox}`}>
              <ThemeProvider theme={themes}>
                <CustomTextField
                  placeHolderText="Search by Students"
                  fieldId="search-students"
                  fieldValue={searchStudentText}
                  fieldName="SearchStudents"
                  fieldWidth={"94%"}
                  fieldType="text"
                  handleChange={this.handleChange}
                  startAdornmentIcon={
                    <SearchIcon
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      stroke={"#888888"}
                      strokeWidth="0.45"
                      fill={"#888888"}
                    />
                  }
                />
              </ThemeProvider>
            </Box>
          </Box>

          {registrarStudentListTableBody.length > 0 ? (
            <>
              <Box
                className={`${classes.bgColor} ${classes.registrarStudentsTable}`}
              >
                <ThemeProvider theme={studentsTableTheme}>
                  <CustomTable
                    tableId={"registrar-student-listing-table"}
                    tableHeaderColumns={registrarStudentListTableColumns}
                    tableBodyData={registrarStudentListTableBody}
                    showMenuIcon={false}
                    isHandleCellAction
                    handleAction={(e: any) =>
                      this.handleGoToStudentPerformance(e)
                    }
                  />
                </ThemeProvider>
              </Box>
              <Box
                id="registrar-students-pagination-box"
                className={classes.paginationBox}
              >
                <Box width={"50%"} marginTop={"20px"}>
                  <Box display={"flex"} gridGap={"6px"}>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18}`}
                      component="span"
                    >
                      Records
                    </Typography>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold600}`}
                      component="span"
                    >
                      {totalNumberOfStudents > perPage
                        ? `${currentPage * perPage - perPage}-
                ${currentPage * perPage}`
                        : `${
                            currentPage * totalNumberOfStudents -
                            totalNumberOfStudents
                          }-
                ${currentPage * totalNumberOfStudents}`}
                    </Typography>
                    <Typography
                      className={`${classes.textCommonColor} ${classes.fontText18}`}
                      component="span"
                    >
                      of {totalNumberOfStudents}
                    </Typography>
                  </Box>
                </Box>
                {totalNumberOfStudents > perPage && (
                  <Box className={classes.paginationRoot}>
                    <Pagination
                      count={Math.ceil(totalNumberOfStudents / perPage)}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      siblingCount={0}
                      page={currentPage}
                      onChange={this.handlePageChange}
                    />
                  </Box>
                )}
              </Box>{" "}
            </>
          ) : (
            <CustomEmptyResultComponentWeb message="No Students yet." />
          )}
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStudent = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(RegistrarStudentsList);
// Customizable Area End
