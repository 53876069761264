// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseSubject: any;
  selectedCourseId: any;
  selectedCourseDetails: any;
  selectedLessonName: string;
  selectedTopicName: string;
  selectedStudentId: string;
  selectedStudentName: string;
  isFromRegistrar: boolean;
  isFromTeacher: boolean;
  isStudentTeacher:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentMyCoursesSubjectDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseDetails: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedCourseSubject: null,
      selectedCourseId: null,
      selectedCourseDetails: "",
      selectedLessonName: "",
      selectedTopicName: "",
      isFromRegistrar: false,
      selectedStudentId: "",
      selectedStudentName: "",
      isFromTeacher: false,
      isStudentTeacher:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    if (window.location.pathname.toLowerCase().includes("registrar")) {
      this.handleSetStudentsCourseJourneyDetails();
    } else {
      this.handleSetSelectedCourseSubject();
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleSetSelectedCourseSubject = () => {
    const paramSubjectName = this.props.navigation?.getParam("subjectName");
    const paramSubjectId = this.props.navigation?.getParam("subjectId");
    if (paramSubjectName && paramSubjectId) {
      this.setState({
        selectedCourseSubject: paramSubjectName ?? "",
        selectedCourseId: paramSubjectId ?? null,
      });
    } else {
      this.props.navigation?.navigate("StudentMyCourses");
    }
  };

  handleSetStudentsCourseJourneyDetails = () => {
    this.setState({ isFromRegistrar: true });
    const paramSubjectName = this.props.navigation?.getParam("subjectName");
    const paramSubjectId = this.props.navigation?.getParam("subjectId");
    const paramStudentName = this.props.navigation?.getParam("studentName");
    const paramStudentId = this.props.navigation?.getParam("studentId");
    const isStudentTeacher = window.location.pathname
    .toLowerCase()
    .includes("student");
    if (paramSubjectName && paramSubjectId) {
      this.setState({
        selectedCourseSubject: paramSubjectName ?? "",
        selectedCourseId: paramSubjectId ?? null,
      });
      if (paramStudentName && paramStudentId) {
        this.setState({
          selectedStudentName: paramStudentName,
          isStudentTeacher:isStudentTeacher,
          selectedStudentId: paramStudentId,
        });
      }
    }
  };

  handleGoBack = () => {
    this.props.navigation?.goBack();
  };

  handleSetTopicName = (topicTitle: string) => {
    this.setState({ selectedTopicName: topicTitle });
  };

  handleSetLessonName = (lessonTitle: string) => {
    this.setState({ selectedLessonName: lessonTitle });
  };

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  // Customizable Area End
}
// Customizable Area End
